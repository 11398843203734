import React, { useState, useEffect, useCallback, SetStateAction } from "react";
import { useRequest } from "ahooks";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../../store/configureStore";
import { getBase64 } from "../../../helpers/modelHelpers";
import { useIntl } from "react-intl";
import replaceState from "../../../helpers/reolaceState";
import { Input, Row, Col, Spin, message, Form, Space } from "antd";
import { UploadFile, RcCustomRequestOptions } from "antd/lib/upload/interface";
import Checkbox from "../../../wrappers/checkbox";
import { PrimaryButton } from "../../../wrappers/button";
import Modal from "../../../wrappers/modal";
import Upload from "../../../wrappers/upload";
import { useTheme } from "styled-components";
import Icon from "../../../components/icon";
import Price from "../../../components/highlight";
import debounce from "lodash/debounce";
import uniqueId from "lodash/uniqueId";
import { fibonacci } from "../../../helpers/sequence.helper";
import * as styles from "./index.scss";
import classnames from "classnames";

const AgentForm = (props: {
  type: number;
  payType?: number;
  agent?: any;
  page?: string;
  confirmInfo?: any;
  isHaveTelephone?: boolean;
  isSeparatePage?: boolean;
  isDisabled?: boolean;
  agentCode?: string;
  paymentType?: number;
  verifyPay?: boolean;
}) => {
  const dispatch = useDispatch<Dispatch>();
  const { formatMessage: f } = useIntl();
  const {
    type,
    agent,
    page = "",
    confirmInfo,
    isHaveTelephone,
    isSeparatePage = false,
    isDisabled = false,
    agentCode,
    payType,
    paymentType,
    verifyPay,
  } = props;
  const handleUploadImgDelay = useRequest(dispatch.AccountPage.handleUploadImg);
  const handleUploadImgDelaya = useRequest(
    dispatch.AccountPage.handleUploadImg,
  );
  const handleUploadImgDelayb = useRequest(
    dispatch.AccountPage.handleUploadImg,
  );
  const handleCaseImgDelay = useRequest(
    dispatch.AccountPage.handleUploadDesignerCase,
  );
  const { is_pay } = useSelector((state: RootState) => state.URLSearch);
  const theme = useTheme();
  const [form] = Form.useForm();

  const [phoneDsiabled, setPhoneDsiabled] = useState(false);

  useEffect(() => {
    if (type === 3) {
      form.setFieldsValue({
        referrer_agent_code: agent.referrer_agent_code,
        agent_name: agent.agent_name,
        telephone: agent.telephone,
        identity_card_number: agent.identity_card_number,
        // identity_card_front: agent.identity_card_front,
        // identity_card_back: agent.identity_card_back,
        // identity_card_handheld: agent.identity_card_handheld,
        designer_case: agent.designer_case ?? "",
        is_designer: agent.is_designer,
      });
      setCaseChecked(agent.is_designer);
      if (agent.designer_case) {
        setCaseFile([
          { url: agent.designer_case, uid: "1", name: "1" } as UploadFile,
        ]);
      }
      // setFileList([
      //   { url: agent.identity_card_front, uid: "1", name: "1" } as UploadFile,
      // ]);
      // setFileLista([
      //   { url: agent.identity_card_back, uid: "2", name: "2" } as UploadFile,
      // ]);
      // setFileListb([
      //   {
      //     url: agent.identity_card_handheld,
      //     uid: "3",
      //     name: "3",
      //   } as UploadFile,
      // ]);
    }
  }, [agent]);

  useEffect(() => {
    if (type === 2 && confirmInfo) {
      form.setFieldsValue({
        agent_name: confirmInfo.agent_name,
        telephone: confirmInfo.telephone,
        identity_card_number: confirmInfo.identity_card_number,
      });
    }
  }, [confirmInfo]);

  useEffect(() => {
    if (isSeparatePage) {
      const data = {
        callback: (res: any) => {
          if (res.phonenumber && res.phonenumber !== "") {
            form.setFieldsValue({
              telephone: res.phonenumber,
            });
            setPhoneDsiabled(true);
          }
        },
      };
      dispatch.AccountPage.getUserInfo(data);
    }
  }, []);

  useEffect(() => {
    if (is_pay) {
      setIsOpen(true);
      replaceState.set({ is_pay: undefined });
      setPay(true);
    }
  }, [is_pay]);

  const [isOpen, setIsOpen] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileLista, setFileLista] = useState<UploadFile[]>([]);
  const [fileListb, setFileListb] = useState<UploadFile[]>([]);
  const [caseFile, setCaseFile] = useState<UploadFile[]>([]);
  const [checked, setChecked] = useState(true);
  const [caseChecked, setCaseChecked] = useState(false);
  const [isConfirmPayVisible, setConfirmPayVisible] = useState(false);
  const [pay, setPay] = useState(verifyPay ? true : false);
  const [qrCode, setQrCode] = useState("");
  const [wxModal, setWxModal] = useState(false);
  const [overdue, setOverdue] = useState(false);

  const handleCloseModal = () => {
    if (isSeparatePage) {
      replaceState.linkTo("/account");
    }
    setIsOpen(false);
  };

  const handleOpenModal = () => {
    if (type === 1 && isSeparatePage && payType === 1) {
      const data = {
        agent_code: agentCode,
        callback: (res: number) => {
          if (res) {
            form.setFieldsValue({
              referrer_agent_code: agentCode,
            });
            setIsOpen(true);
          } else {
            message.error("推荐码错误");
          }
        },
      };
      dispatch.AccountPage.checkAgentCode(data);
    } else if (type === 1 && isSeparatePage && payType === 2) {
      // paymentType 1:zfb 2:wx
      if (paymentType === 1) {
        const data = {
          callback: (res: string) => {
            if (res === "") {
              message.error("暂时不允许支付");
            } else {
              // 弹窗显示支付状态，并且开始查询支付结果
              replaceState.linkTo(res, "_blank");
              setConfirmPayVisible(true);
              getAliPayrResult(7);
            }
          },
        };
        dispatch.AccountPage.getZfbUrl(data);
      } else {
        const data = {
          callback: (res: string) => {
            if (res === "") {
              message.error("暂时不允许支付");
            } else {
              // 弹窗显示支付状态，并且开始查询支付结果
              setQrCode(res);
              setWxModal(true);
              // 获取到url后将到期状态改为false
              setOverdue(false);

              let remaining = 9;
              // 延时10秒请求支付结果
              setTimeout(() => {
                setInterval(() => {
                  remaining = remaining - 1;
                  if (remaining > 0) {
                    // 请求微信结果
                    handleGetWxPayResult({
                      callback: (res: any) => {
                        if (res && !pay) {
                          remaining = 1;
                          setPay(() => true);
                          handleCloseWxModal();
                          setIsOpen(true);
                        }
                      },
                    });
                  }
                }, 5000);
              }, 5000);

              // 1分钟后过期
              setTimeout(() => {
                setOverdue(true);
              }, 60000);
            }
          },
        };
        dispatch.AccountPage.getWxUrl(data);
      }
    } else {
      setIsOpen(true);
    }
  };

  const getAliPayrResult = (checkIndx: number = 7) => {
    // 斐波那契数列1、1、2、3、5、8、13、21、34、……
    // 从第七个数开始，隔n秒后执行一次查询

    setTimeout(() => {
      const data = {
        callback: (res: any) => {
          if (res) {
            if (isOpen && pay && !isConfirmPayVisible) return;
            setConfirmPayVisible(false);
            setIsOpen(true);
            setPay(true);
          }
        },
      };
      handleGetPayResult(data);

      getAliPayrResult(checkIndx + 1);
    }, fibonacci(checkIndx) * 1000);
  };

  const handleClose = () => {
    setConfirmPayVisible(false);
  };

  const handleSubmitForm = (value: any) => {
    // value.identity_card_front = fileList.map(m => m.url)[0];
    // value.identity_card_back = fileLista.map(m => m.url)[0];
    // value.identity_card_handheld = fileListb.map(m => m.url)[0];
    value.designer_case = caseFile.map(m => m.url)[0];
    if (!value.referrer_agent_code) {
      value.referrer_agent_code = "";
    }
    if (type === 1) {
      const data = {
        action: "apply",
        ...value,
        callback: () => {
          if (!isSeparatePage) {
            const datas = {
              callback: () => {
                setIsOpen(false);
              },
            };
            dispatch.AccountPage.getUserInfo(datas);
          } else {
            replaceState.linkTo("/account");
          }
        },
      };
      dispatch.AccountPage.agentOperation(data);
    }

    if (type === 2) {
      const data = {
        action: "confirm",
        application_id: confirmInfo.application_id,
        agent_name: value.agent_name,
        identity_card_number: value.identity_card_number,
        // identity_card_front: value.identity_card_front,
        // identity_card_back: value.identity_card_back,
        // identity_card_handheld: value.identity_card_handheld,
        designer_case: value.designer_case,
        is_designer: value.is_designer,
        callback: () => {
          const datas = {
            callback: () => {
              setIsOpen(false);
            },
          };
          dispatch.AccountPage.getUserInfo(datas);
        },
      };
      dispatch.AccountPage.completionInfo(data);
    }

    if (type === 3) {
      delete value.telephone;
      const data = {
        action: "updateAgent",
        agent_authentication_id: agent.agent_authentication_id,
        ...value,
        callback: () => {
          const datas = {
            callback: () => {
              setIsOpen(false);
            },
          };
          dispatch.AccountPage.getUserInfo(datas);
        },
      };
      dispatch.AccountPage.agentOperation(data);
    }
  };

  const requiredText = f({
    id: "Required",
  });
  const handleCustomRequest = useCallback(
    async (
      request: RcCustomRequestOptions,
      callback: (file: UploadFile) => void,
      useRequest,
    ) => {
      if (!(request.file.size < 1024 * 1024 * 5)) {
        return message.error(
          f({ id: "UploadFileSizeCannotExceedNumberMb" }, { number: 5 }),
        );
      }

      if (
        !["image/jpeg", "image/png", "image/jpg"].includes(request.file.type)
      ) {
        return message.error("请上传正确的文件类型");
      }

      const fileBase64 = await getBase64(request.file);
      const fileData = {
        name: request.file.name,
        uid: uniqueId("uid"),
      };
      const data = {
        base64: fileBase64,
        callback: (url: string) =>
          callback({
            ...fileData,
            url,
          } as UploadFile),
      };

      useRequest.run(data);
    },
    [],
  );

  const handleCaseRequest = useCallback(
    async (
      request: RcCustomRequestOptions,
      callback: (file: UploadFile) => void,
      useRequest,
    ) => {
      if (!(request.file.size < 1024 * 1024 * 10)) {
        return message.error(
          f({ id: "UploadFileSizeCannotExceedNumberMb" }, { number: 10 }),
        );
      }

      if (
        !["image/jpeg", "image/png", "application/pdf", "image/jpg"].includes(
          request.file.type,
        )
      ) {
        return message.error("请上传正确的文件类型");
      }
      const fileBase64 = await getBase64(request.file);
      const fileData = {
        name: request.file.name,
        uid: uniqueId("uid"),
      };
      const data = {
        base64: fileBase64,
        callback: (url: string) =>
          callback({
            ...fileData,
            url,
          } as UploadFile),
      };

      useRequest.run(data);
    },
    [],
  );

  const handleRemoveimg = (
    file: UploadFile,
    setState: (value: SetStateAction<UploadFile<any>[]>) => void,
  ) => {
    setState(preState => preState.filter(item => item.uid !== file.uid));
  };

  const removeIcon = <Icon type="iconcuowu1-copy"></Icon>;
  const uploadLabel = (
    <span>
      {f({
        id: "IdCardPhoto",
      })}
      <span style={{ color: "#888888" }}>(上传单张图片小于5MB)</span>
    </span>
  );

  const validateCardFront = (rule: any, value: any, callback: any) => {
    if (typeof value === "string") {
      callback();
    }
    if (!value || (value.fileList && value.fileList.length < 1)) {
      callback(rule.message);
    } else {
      callback();
    }
  };

  const validateDesignerCase = (rule: any, value: any, callback: any) => {
    if (caseFile.length < 1 && caseChecked) {
      callback(rule.message);
    } else {
      callback();
    }
  };

  const handleGetPayResult = debounce((data: any) => {
    dispatch.AccountPage.getPayResult(data);
  }, 1000);

  const handleGetWxPayResult = debounce((data: any) => {
    dispatch.AccountPage.getWxPayResult(data);
  }, 1000);
  const handleCloseWxModal = () => {
    setWxModal(false);
  };
  return (
    <>
      <div
        style={{
          position: page === "info" ? "absolute" : "initial",
          top: page === "info" ? 70 : 0,
          left: page === "info" ? 130 : 0,
          zIndex: page === "info" ? 2 : 0,
        }}
      >
        <PrimaryButton
          icon={type === 1 ? <Icon type="iconhuangguan222"></Icon> : null}
          onClick={() => handleOpenModal()}
          disabled={isDisabled}
          bgColor={!isDisabled ? theme.primaryColor : "#bdbcbc"}
          style={{
            color: isDisabled ? "white" : "",
          }}
        >
          {f({
            id:
              type === 1
                ? "AsAgent"
                : type === 3
                ? "AgainCertification"
                : "ConfirmInformation",
          })}
        </PrimaryButton>
      </div>
      <Modal
        visible={wxModal}
        onCancel={() => {
          handleCloseWxModal();
        }}
        footer={false}
        width="600px"
        title="微信支付"
      >
        <div className={styles[`compute-modal`]}>
          <Row gutter={50}>
            <Col span={10} className={styles[`compute-modal-header`]}>
              二维码有效时长为
              <span style={{ color: theme.primaryColor }}>60</span>
              秒，请尽快完成支付！过期后请刷新重新获取二维码!
            </Col>
            <Col span={14}></Col>
          </Row>
          <Row gutter={50} style={{ marginTop: "10px" }}>
            <Col span={10}>
              <div className={styles[`compute-modal-qrcode`]}>
                <img src={qrCode} alt="" />
                <div
                  className={classnames(styles[`compute-modal-qrcode-mask`], {
                    [styles[`compute-modal-qrcode-show`]]: overdue,
                  })}
                >
                  {overdue && (
                    <div onClick={() => handleOpenModal()}>
                      <Icon type="icont1_shuaxin"></Icon>
                      <p>已失效!</p>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles[`compute-modal-prompt`]}>
                <Icon type="iconsaomiao"></Icon>
                <div className={styles[`compute-modal-prompt-text`]}>
                  <p>请使用微信扫一扫</p>
                  <p>扫描二维码</p>
                </div>
              </div>
            </Col>
            <Col span={14}>
              <div
                style={{
                  color: "#4B4B4B",
                  marginTop: 15,
                  fontSize: "1.2em",
                }}
              >
                数莓派推广者 (代理) 审核费用
              </div>
              <Space
                style={{
                  alignItems: "baseline",
                  marginBottom: 10,
                }}
              >
                <span
                  style={{
                    fontSize: "1.8em",
                    color: theme.primaryColor,
                  }}
                >
                  <Price text={"¥298.00"} />
                </span>
                <span
                  style={{
                    fontSize: "1.2em",
                    color: "#4B4B4B",
                    textDecoration: "line-through",
                  }}
                >
                  <Price text={"¥598.00"} fontSize={"1.2em"} />
                </span>
              </Space>
              <Row
                style={{
                  margin: "15px 0px",
                  color: "#888888",
                  fontSize: "0.6em",
                }}
              >
                该审核费用用于审核您的推广者实名认证资质，为一次性费用。认证资质后即可享受优惠折扣和领取推荐奖励。
              </Row>
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal
        visible={isConfirmPayVisible}
        onCancel={() => handleClose()}
        width={400}
        footer={false}
      >
        <div
          style={{
            padding: "40px 20px 20px 20px",
            textAlign: "center",
          }}
        >
          <div
            style={{
              color: "#4B4B4B",
              marginTop: 15,
            }}
          >
            数莓派推广者 (代理) 审核费用
          </div>
          <Space
            style={{
              alignItems: "baseline",
              marginBottom: 10,
            }}
          >
            <span
              style={{
                fontSize: "1.8em",
                color: theme.primaryColor,
              }}
            >
              <Price text={"¥298.00"} />
            </span>
            <span
              style={{
                fontSize: "1.2em",
                color: "#4B4B4B",
                textDecoration: "line-through",
              }}
            >
              <Price text={"¥598.00"} fontSize={"1.2em"} />
            </span>
          </Space>
          <Row gutter={10}>
            <Col span={12}>
              <PrimaryButton
                block
                onClick={() => {
                  setConfirmPayVisible(false);
                }}
                bgColor={theme.primaryColor}
              >
                稍后支付
              </PrimaryButton>
            </Col>
            <Col span={12}>
              <PrimaryButton
                block
                onClick={() => {
                  handleGetPayResult({
                    callback: (res: any) => {
                      if (res) {
                        setConfirmPayVisible(false);
                        setIsOpen(true);
                        setPay(true);
                      }
                    },
                  });
                }}
                bgColor={theme.primaryColor}
              >
                我已支付
              </PrimaryButton>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal
        visible={isOpen}
        onCancel={() => handleCloseModal()}
        width={600}
        footer={false}
        title={f({
          id:
            type === 1
              ? "AgentCertification"
              : type === 3
              ? "AgentCertification"
              : "CompletionInformation",
        })}
      >
        <Form
          form={form}
          initialValues={{ remember: true }}
          onFinish={handleSubmitForm}
          style={{ padding: "26px" }}
          layout="vertical"
        >
          {type === 1 && !pay && (
            <Form.Item
              name="referrer_agent_code"
              label={f({
                id: "RecommendedCode",
              })}
            >
              <Input
                placeholder={f({
                  id: "PleaseEnter",
                })}
              ></Input>
            </Form.Item>
          )}

          <Form.Item
            name="agent_name"
            label={f({
              id: "Name",
            })}
            rules={[{ required: true, message: requiredText }]}
          >
            <Input
              placeholder={f({
                id: "PleaseEnter",
              })}
            ></Input>
          </Form.Item>

          {(type !== 1 || !isHaveTelephone) && (
            <Form.Item
              name="telephone"
              label={f({
                id: "ContactPhoneNumber",
              })}
              rules={[
                { required: true, message: requiredText },
                {
                  pattern: /^1[3|4|5|7|8][0-9]{9}$/,
                  message: f({ id: "PleaseEnterTelePhone" }),
                },
              ]}
            >
              <Input
                placeholder={f({
                  id: "PleaseEnter",
                })}
                disabled={type === 3 || type === 2 || phoneDsiabled}
              ></Input>
            </Form.Item>
          )}

          <Form.Item
            name="identity_card_number"
            label={f({
              id: "IdCardNumber",
            })}
            rules={[
              { required: true, message: requiredText },
              {
                pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
                message: f({ id: "PleaseEnterIdCardNumber" }),
              },
            ]}
          >
            <Input
              placeholder={f({
                id: "PleaseEnter",
              })}
            ></Input>
          </Form.Item>
          {/*
          <Form.Item label={uploadLabel} required>
            <Row gutter={20}>
              <Col span={7}>
                <Form.Item
                  name="identity_card_front"
                  rules={[
                    {
                      validator: validateCardFront,
                      message: requiredText,
                    },
                  ]}
                >
                  <Upload
                    fileList={fileList}
                    customRequest={req =>
                      handleCustomRequest(
                        req,
                        file => setFileList([file]),
                        handleUploadImgDelay,
                      )
                    }
                    onRemove={val => {
                      handleRemoveimg(val, setFileList);
                    }}
                    showUploadList={{
                      showPreviewIcon: false,
                      showDownloadIcon: false,
                      removeIcon,
                    }}
                  >
                    {fileList.length < 1 && (
                      <Spin spinning={handleUploadImgDelay.loading}>
                        <span
                          style={{
                            color: "#CCCCCC",
                            display: "block",
                          }}
                        >
                          {f({ id: "PeopleSurfac" })}
                        </span>
                        <br />
                        <PrimaryButton bgColor={theme.primaryColor}>
                          {f({ id: "UploadImg" })}
                        </PrimaryButton>
                      </Spin>
                    )}
                  </Upload>
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item
                  name="identity_card_back"
                  rules={[
                    {
                      validator: validateCardFront,
                      message: requiredText,
                    },
                  ]}
                >
                  <Upload
                    listType="picture-card"
                    fileList={fileLista}
                    customRequest={req =>
                      handleCustomRequest(
                        req,
                        file => setFileLista([file]),
                        handleUploadImgDelaya,
                      )
                    }
                    onRemove={val => handleRemoveimg(val, setFileLista)}
                    showUploadList={{
                      showPreviewIcon: false,
                      showDownloadIcon: false,
                      removeIcon,
                    }}
                  >
                    {fileLista.length < 1 && (
                      <Spin spinning={handleUploadImgDelaya.loading}>
                        <span
                          style={{
                            color: "#CCCCCC",
                            display: "block",
                          }}
                        >
                          {f({ id: "NationalEmblemPage" })}
                        </span>
                        <br />
                        <PrimaryButton bgColor={theme.primaryColor}>
                          {f({ id: "UploadImg" })}
                        </PrimaryButton>
                      </Spin>
                    )}
                  </Upload>
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item
                  name="identity_card_handheld"
                  rules={[
                    {
                      validator: validateCardFront,
                      message: requiredText,
                    },
                  ]}
                >
                  <Upload
                    listType="picture-card"
                    fileList={fileListb}
                    customRequest={req =>
                      handleCustomRequest(
                        req,
                        file => setFileListb([file]),
                        handleUploadImgDelayb,
                      )
                    }
                    onRemove={val => handleRemoveimg(val, setFileListb)}
                    showUploadList={{
                      showPreviewIcon: false,
                      showDownloadIcon: false,
                      removeIcon,
                    }}
                  >
                    {fileListb.length < 1 && (
                      <Spin spinning={handleUploadImgDelayb.loading}>
                        <span
                          style={{
                            color: "#CCCCCC",
                            display: "block",
                          }}
                        >
                          {f({ id: "HandheldPeopleSurfac" })}
                        </span>
                        <br />
                        <PrimaryButton bgColor={theme.primaryColor}>
                          {f({ id: "UploadImg" })}
                        </PrimaryButton>
                      </Spin>
                    )}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item> */}

          <Form.Item name="is_designer">
            <Checkbox
              shape="circle"
              style={{
                marginRight: 5,
              }}
              checked={caseChecked}
              onChange={e => {
                form.setFieldsValue({
                  is_designer: e.target.checked,
                });
                setCaseChecked(e.target.checked);
              }}
            >
              我是设计师
              <span style={{ color: "#888888" }}>(上传案例小于10MB)</span>
            </Checkbox>
          </Form.Item>

          <Form.Item
            name="designer_case"
            wrapperCol={{ span: 6 }}
            style={{
              position: "relative",
            }}
            rules={[
              {
                validator: validateDesignerCase,
                message: requiredText,
              },
            ]}
          >
            <Upload
              fileList={caseFile}
              customRequest={req =>
                handleCaseRequest(
                  req,
                  file => setCaseFile([file]),
                  handleCaseImgDelay,
                )
              }
              onRemove={val => handleRemoveimg(val, setCaseFile)}
              showUploadList={{
                showPreviewIcon: false,
                showDownloadIcon: false,
                removeIcon,
              }}
            >
              {caseFile.length < 1 && (
                <Spin spinning={handleCaseImgDelay.loading}>
                  <span
                    style={{
                      color: "#CCCCCC",
                      display: "block",
                    }}
                  >
                    设计案例
                  </span>
                  <br />
                  <PrimaryButton bgColor={theme.primaryColor}>
                    上传案例
                  </PrimaryButton>
                </Spin>
              )}
            </Upload>
            <span
              style={{
                position: "absolute",
                left: "150px",
                bottom: "10px",
                width: "300px",
              }}
            >
              案例文件格式支持：jpg、jpeg、png、pdf
            </span>
          </Form.Item>

          <Row style={{ marginTop: 10 }}>
            <Col span={18}>
              <Checkbox
                checked={checked}
                shape="circle"
                onChange={e => {
                  setChecked(e.target.checked);
                }}
                style={{
                  marginRight: 5,
                }}
              ></Checkbox>
              我已阅读并同意{" "}
              <u
                style={{
                  color: theme.linkColor,
                  cursor: "pointer",
                }}
                onClick={() =>
                  replaceState.linkTo("/page/promotion/", "_blank")
                }
              >
                数莓派推广者(代理)规范条款{" "}
              </u>
              <u
                style={{
                  color: theme.linkColor,
                  cursor: "pointer",
                  fontSize: "15px",
                  marginLeft: 10,
                }}
                onClick={() =>
                  replaceState.linkTo("/page/freelancer/", "_blank")
                }
              >
                自由职业者服务协议
              </u>{" "}
            </Col>
            <Col span={6} style={{ textAlign: "right" }}>
              {" "}
              <PrimaryButton
                htmlType="submit"
                bgColor={theme.primaryColor}
                disabled={!checked}
                style={{
                  color: !checked ? "white" : "",
                }}
              >
                {f({ id: "Submit" })}
                <Icon type="icont1_correct" />
              </PrimaryButton>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default AgentForm;
