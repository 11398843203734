import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, Dispatch } from "../../../store/configureStore";
import Icon from "../../../components/icon";
import {
  List,
  Breadcrumb,
  Typography,
  Input,
  Row,
  Col,
  message as Messages,
  Spin,
} from "antd";
import { RcCustomRequestOptions } from "antd/lib/upload/interface";

import { PrimaryButton, TextButton } from "../../../wrappers/button";
import Modal from "../../../wrappers/modal";
import Upload from "../../../wrappers/upload";
import { Text } from "../../../wrappers/typography";
import Renderer from "../../../components/irender";
import AutoImage from "../../../components/image";
import { useIntl } from "react-intl";
import { Product } from "../../../models/Product";
import { getBase64, dataURItoBlob } from "../../../helpers/modelHelpers";
import { useTheme } from "styled-components";
import styles from "../style.scss";

export interface RenderProps {
  render?: Renderer;
  handleAddToCanvas: (
    item: Product,
    position?: number[],
    isBg?: boolean,
  ) => void;
  getDragProps?: any;

  isManagementEnd: boolean;
}

const UserUpdate: React.FC<RenderProps> = (props, ref) => {
  const { root_id } = useSelector((state: RootState) => state.URLSearch);
  const theme = useTheme();
  const { render, getDragProps, handleAddToCanvas, isManagementEnd } = props;
  const { formatMessage: f } = useIntl();

  const dispatch = useDispatch<Dispatch>();
  const uploadFile = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => ({
    initialize: () => {
      handleBackUploadedList();
    },
  }));
  const [uploadedBreadcrumbs, setUploadedBreadcrumbs] = useState([
    {
      id: 0,
      title: "目录",
    },
  ]);
  const [uploadedPage, setUploadedPage] = useState(1);
  const [updatedDirectory, setUpdatedDirectory] = useState<any[]>([]);
  const [addUploadModalVisible, setAddUploadModalVisible] = useState(false);
  const [uploadPicture, setUploadPicture] = useState<any[]>([]);
  const [directoryName, setDirectoryName] = useState("");
  const [selectedUploadDirectoryID, setSelectedUploadDirectoryID] = useState<
    number
  >();

  useEffect(() => {
    const data = {
      root_id,
      callback: (res: any) => {
        const resData = [
          {
            picture_tag_id: 0,
            picture_tag_name: "新建",
          },
          ...res,
        ];
        setUpdatedDirectory(resData);
      },
    };
    dispatch.DesignIdea.getUpdatedDirectoryAsync(data);
  }, []);

  const handleBackUploadedList = () => {
    // 调用目录接口并且刷新数据
    setUploadedPage(1);
    setUploadPicture([]);
    setUploadedBreadcrumbs([
      {
        id: 0,
        title: "目录",
      },
    ]);
    const data = {
      root_id,
      callback: (res: any) => {
        const resData = [
          {
            picture_tag_id: 0,
            picture_tag_name: "新建",
          },
          ...res,
        ];
        setUpdatedDirectory(resData);
      },
    };
    dispatch.DesignIdea.getUpdatedDirectoryAsync(data);
  };

  const handleDeleteUpload = (type: string, id: number) => {
    const data = {
      root_id,
      action: type,
      picture_tag_id: id,
      picture_id: id,
      mutationType: type === "deletePic" ? "Picture" : "PictureTag",
      callback: (res: any) => {
        if (res.message) {
          Messages.success(res.message);
        }

        if (type === "deletePic") {
          const newUploadPicture = uploadPicture.filter(
            m => m.picture_id !== id,
          );
          setUploadPicture(newUploadPicture);
        }
        if (type === "deleteTag") {
          const newDirectoryList = updatedDirectory.filter(
            m => m.picture_tag_id !== id,
          );
          setUpdatedDirectory(newDirectoryList);
        }
      },
    };
    if (type === "deleteTag") {
      delete data.picture_id;
    }
    if (type === "deletePic") {
      delete data.picture_tag_id;
    }
    dispatch.DesignIdea.deleteUploaded(data);
  };
  const backgroundColorData = [
    "#F6B01B",
    "#AE52F4",
    "#5262F4",
    "#F6591B",
    "#F6591B",
  ];
  const handleViewInfo = (title: string, id: number) => {
    const breadcrumb = [
      {
        id: 0,
        title: "目录",
      },
      {
        id: 1,
        title: title,
      },
    ];
    setUploadedBreadcrumbs(breadcrumb);
    const data = {
      root_id,
      picture_tag_id: id,
      callback: (res: any) => {
        const newData = [{ id: 0 }, ...res];
        setUploadPicture(newData);
        setUploadedPage(2);
        setSelectedUploadDirectoryID(id);
      },
    };
    dispatch.DesignIdea.getUpdatedPictureAsync(data);
  };

  const [uploading, setUploading] = useState(false);
  const handleFileUpdate = async (info: RcCustomRequestOptions) => {
    const file = info.file;

    if (file.size / 1024 / 1024 > 5) {
      return Messages.error("上传图片最大为5m");
    }

    setUploading(true);

    // const actions = Array.from(files!).map(async file => {
    const base64 = await getBase64(file);

    const data = {
      base64,
      file: dataURItoBlob(base64),
      root_id,
      pictureTagId: selectedUploadDirectoryID,
      callback: (res: any) => {
        const newPic = isManagementEnd ? res : JSON.parse(res.data);
        setUploadPicture(item => [...item, newPic]);
      },
    };

    dispatch.DesignIdea.setUpdatedPictureAsync(data).finally(() =>
      setUploading(false),
    );
    // });

    // Promise.all(actions)
    // .then(() => Messages.success("上传成功！"))
    // .finally(() => setUploading(false));
  };

  const handleCreateFolder = () => {
    if (!directoryName || directoryName === "") {
      return Messages.error("请输入文件夹名称");
    }

    const data = {
      root_id,
      tagName: directoryName,
      callback: (res: any) => {
        if (res.message) {
          Messages.success(res.message);
        }
        const newDirectory = isManagementEnd ? res : JSON.parse(res.data);
        setUpdatedDirectory(item => [...item, newDirectory]);
        setAddUploadModalVisible(false);
        setDirectoryName("");
      },
    };
    dispatch.DesignIdea.setUpdatedDirectoryAsync(data);
  };

  return (
    <div>
      {uploadedBreadcrumbs.length > 0 && (
        <Breadcrumb
          separator=">"
          style={{
            margin: "10px 0px",
          }}
          className="materials-breadcrumb"
        >
          {uploadedBreadcrumbs.map((b, index) => (
            <Breadcrumb.Item>
              {index === uploadedBreadcrumbs.length - 1 ? (
                <span>{b.title}</span>
              ) : (
                <a onClick={() => handleBackUploadedList()}>{b.title}</a>
              )}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      )}
      {uploadedPage === 1 && (
        <>
          <List
            grid={{ gutter: 10, column: 4 }}
            dataSource={updatedDirectory}
            renderItem={(item, index) => (
              <List.Item>
                {index === 0 && (
                  <div
                    className={styles["materials-uploaded-item"]}
                    onClick={() => setAddUploadModalVisible(true)}
                  >
                    <a
                      style={{
                        border: "2px dashed white",
                        boxSizing: "border-box",
                      }}
                    >
                      <Icon type="icont1_jia"></Icon>
                      <Text color="#fff">{item.picture_tag_name}</Text>
                    </a>
                  </div>
                )}
                {index > 0 && (
                  <div className={styles["materials-uploaded-item"]}>
                    {item.picture_tag_id > 0 && (
                      <Icon
                        type="iconcuowu1-copy"
                        className={styles["materials-uploaded-item-icon"]}
                        onClick={() => {
                          if (Number(item.total) > 0) {
                            return Messages.error("已有上传文件，不可删除");
                          }

                          handleDeleteUpload("deleteTag", item.picture_tag_id);
                        }}
                      ></Icon>
                    )}
                    <a
                      style={{
                        backgroundColor:
                          backgroundColorData[
                            index % backgroundColorData.length
                          ],
                      }}
                      onClick={() =>
                        handleViewInfo(
                          item.picture_tag_name,
                          item.picture_tag_id,
                        )
                      }
                    >
                      <Text color="#fff">{item.picture_tag_name}</Text>
                    </a>
                  </div>
                )}
              </List.Item>
            )}
          />

          <Modal
            color={theme.designPrimaryColor}
            visible={addUploadModalVisible}
            onCancel={() => setAddUploadModalVisible(false)}
            width={400}
            title={f({ id: "CreateFolder" })}
            footer={
              <PrimaryButton
                bgColor={theme.designPrimaryColor}
                onClick={() => handleCreateFolder()}
              >
                提交
              </PrimaryButton>
            }
            bodyStyle={{ padding: "0px 26px", margin: "20px 0" }}
          >
            <Input
              addonBefore="文件夹名称"
              value={directoryName}
              onChange={e => setDirectoryName(e.target.value)}
            />
          </Modal>
        </>
      )}

      {uploadedPage === 2 && (
        <Spin spinning={uploading}>
          <List
            className={styles["materials-box"]}
            grid={{ gutter: 10, column: 4 }}
            dataSource={uploadPicture}
            renderItem={(item, index) => (
              <List.Item>
                {index === 0 && (
                  <Upload
                    customRequest={handleFileUpdate}
                    accept="image/*"
                    showUploadList={false}
                    multiple
                    height="auto"
                    background="transparent"
                    border="2px dashed #fff"
                    hoverBorderColor="#fff"
                    borderRadius="5px"
                  >
                    <div className={styles["materials-uploaded-item"]}>
                      <a>
                        <Icon type="icont1_jia"></Icon>
                        <Text color="#fff">添加</Text>
                      </a>
                    </div>
                  </Upload>
                )}
                {index > 0 && (
                  <div
                    className={`${styles["materials-uploaded-pic"]} ${styles["product-list-item"]}`}
                  >
                    {index > 0 && (
                      <Icon
                        type="iconcuowu1-copy"
                        className={styles["materials-uploaded-item-icon"]}
                        onClick={() =>
                          handleDeleteUpload("deletePic", item.picture_id)
                        }
                      ></Icon>
                    )}
                    <AutoImage
                      width={1}
                      height={1}
                      src={item.picture_url}
                      onClick={() => {
                        if (item.picture_tag_id !== -2) return;
                        handleAddToCanvas(
                          { ...item, product_picture: item.picture_url },
                          undefined,
                          true,
                        );
                      }}
                      {...getDragProps({
                        shop_product_id: 0,
                        product_picture: item.picture_url,
                        type: "uploaded",
                        isBg: item.picture_tag_id === -2 ? true : false,
                      })}
                    >
                      <TextButton
                        onClick={e => {
                          handleAddToCanvas(
                            { ...item, product_picture: item.picture_url },
                            undefined,
                            item.picture_tag_id === -2,
                          );
                        }}
                        className={styles["product-item-btn"]}
                      >
                        <Icon type="icondaoru" rotate={180} />
                      </TextButton>
                    </AutoImage>
                  </div>
                )}
              </List.Item>
            )}
          />
        </Spin>
      )}
    </div>
  );
};
export default forwardRef<Renderer, RenderProps>(UserUpdate);
