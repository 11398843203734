import { createModel } from "@rematch/core";
import { Order, OrderTotal, TobOrderDetails } from "../../../models/Order";
import { getFirstItem } from "../../../helpers/modelHelpers";
import {
  GraphQLRequest,
  GraphqlQuery,
  GraphqlMutation,
} from "../../../helpers/request.helper";

export interface ToBOrderDetailsPage {
  breadcrumbs: { url: string; title: string }[];
  detailData: TobOrderDetails;
}

export default createModel({
  state: {} as ToBOrderDetailsPage,
  reducers: {
    setDetail: (state: ToBOrderDetailsPage, detailData) => ({
      ...state,
      detailData,
    }),
  },
  effects: {
    async setOrderDetails(payload) {
      const { tobOrderNumber } = payload;
      const data = new TobOrderDetails({ order_number: tobOrderNumber });
      const res = await GraphqlQuery("TobOrders", data);
      this.setDetail(getFirstItem(res));
    },
  },
});
