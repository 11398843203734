import React from "react";
import { Dispatch, RootState } from "../../store/configureStore";
import { useSelector, useDispatch } from "react-redux";
import {
  EmailVerification,
  LoginForm,
  RegisterForm,
  ResetPassword,
  ResetPasswordVerifyEmail,
} from "../LogInSignUp/components";
import styles from "./index.scss";

const LoginContent: React.FC = () => {
  const {
    isLogin,
    email,
    emailVerficationType,
    password,
    modalAction = "Login",
  } = useSelector((state: RootState) => state.User);
  const dispatch = useDispatch<Dispatch>();

  const renderForm = () => {
    switch (modalAction) {
      case "Login":
        return (
          <LoginForm
            insideModal={false}
            toSiginUp={email =>
              dispatch.User.changeUserState({
                modalAction: "EmailVerification",
                email,
                emailVerficationType: "register",
              })
            }
            toResetPassword={password => {
              dispatch.User.changeUserState({
                password,
              });
            }}
          />
        );

      case "SignUp":
        return (
          <RegisterForm
            insideModal={false}
            toEmailVerfication={(email, emailVerficationType) => {
              dispatch.User.changeUserState({
                modalAction: "EmailVerification",
                email,
                emailVerficationType,
              });
            }}
          />
        );

      case "EmailVerification":
        return (
          <EmailVerification
            insideModal={false}
            emailVerficationType={emailVerficationType}
            email={email}
            password={password}
          />
        );

      case "ResetPasswordVerifyEmail":
        return (
          <ResetPasswordVerifyEmail
            insideModal={false}
            toEmailVerfication={(email, type) =>
              dispatch.User.changeUserState({
                email,
                emailVerficationType: type,
                modalAction: "EmailVerification",
              })
            }
          />
        );

      case "ResetPassword":
        return <ResetPassword email={email} />;
      default:
        return null;
    }
  };

  return (
    <div
      className={styles["login-ct"]}
      style={{
        border: "1px solid #DDDDDD",
      }}
    >
      <div className={styles["login-form"]}>{renderForm()}</div>
    </div>
  );
};

export default React.memo(LoginContent);
