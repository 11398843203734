import React from "react";
import { IdeaDetalis } from "../models";
import cx from "classnames";
import styles from "../index.scss";
import { RootState } from "../../../store/configureStore";
import { FormattedMessage, useIntl } from "react-intl";
import { BusinessContext } from "../../BusinessContext";
import Icon from "../../../components/icon";
import Price from "../../../components/highlight";
import Button from "../../../wrappers/button";
import { Link } from "../../../wrappers/typography";
import replaceState from "../../../helpers/reolaceState";
import { useTheme } from "styled-components";

const mapStateToProps = (state: RootState) => ({
  isLogin: state.User.isLogin,
  search: state.URLSearch.search,
});

const mapDispatch = (dispatch: any) => ({
  openLoginModal: dispatch.User.changeUserState,
});

type ProductCardProps = IdeaDetalis["designIdeaDetails"]["products"][0] & {
  img: string;
  isPreview: boolean;
  onChangeProductIsLike: () => void;
  isMobile?: boolean;
  shopUrl: string;
  agentCode: any;
  isMaterial: any;
} & ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatch>;

const ProductCard = (props: ProductCardProps) => {
  const { formatMessage: f } = useIntl();
  const { primaryColor, bussinessPrimaryColor } = useTheme();
  const { isToBusiness } = React.useContext(BusinessContext);
  const { search = "", isPreview = false } = props;
  const [hover, setHover] = React.useState(false);

  const { shopUrl = "", isLogin } = props;

  const handleHover = () => {
    if (hover) {
      setHover(false);
    } else {
      setHover(true);
    }
  };
  return (
    <div
      className={styles["ideaDetalis-pdCard"]}
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
      style={{ borderRadius: "0px" }}
    >
      <a
        href={`${props.url}&${search.substr(1)}`}
        target="_blank"
        className={cx(styles["ideaDetalis-pdCard-left"])}
      >
        <img src={props.img} />
      </a>
      <div
        className={styles["ideaDetalis-pdCard-childtime-userlike"]}
        style={{
          display: hover ? "" : "none",
        }}
      >
        {isLogin && !isPreview && (
          <Button
            shape={"circle"}
            size="small"
            onClick={props.onChangeProductIsLike}
            icon={
              <Icon
                type={props.is_collect ? "icont2_shoucang1" : "iconshoucang1"}
              />
            }
            color={isToBusiness ? bussinessPrimaryColor : primaryColor}
          ></Button>
        )}
      </div>
      <a
        href={`${props.url}&${search.substr(1)}`}
        target="_blank"
        style={{ width: "calc(100% - 150px)" }}
      >
        <div className={cx(styles["ideaDetalis-pdCard-right"])}>
          <div>
            <p
              className={cx(styles["ideaDetalis-pdCard-name"])}
              onClick={() => replaceState.linkTo(props.url)}
            >
              {props.product_name}
            </p>
            <p>
              <Link href={`${shopUrl}${search}`}>{props.shop_name}</Link>
            </p>

            <p style={{ margin: "0 5px", fontSize: "1.3rem" }}>
              <Price text={props.tag_price_value} />
            </p>
            {props.original_price_value && props.original_price_value !== "" && (
              <p style={{ margin: "0 5px" }}>
                <span style={{ fontSize: "1.2em" }}>
                  <FormattedMessage id="OriginPrice" />：
                </span>
                <Price text={props.original_price_value} />
              </p>
            )}
          </div>
        </div>
      </a>
    </div>
  );
};

export default React.memo(ProductCard);
