import styled from "styled-components";
import { Select } from "antd";

const SelectStyled = styled(Select)<{
  backgroundColor?: string;
  borderRadius?: string;
}>`
  &.ant-select {
    .ant-select-selector {
      background-color: ${props => props.backgroundColor};
      border-radius: ${props => props.borderRadius ?? "5px"};
    }
  }

  .ant-select-dropdown {
    background-color: ${props => props.backgroundColor};
  }
`;

const DesignSelector = styled(SelectStyled).attrs({
  backgroundColor: "#fff",
  borderRadius: "17px",
})``;

export { DesignSelector };

export default SelectStyled;
