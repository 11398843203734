import { GraphqlField } from "../helpers/decorators";
import BaseModel from "./BaseModel";
import { BooleanLike } from "./Common";

export enum AgentStatus {
  NotApplied = 1,
  Authenticating = 2,
  Succeed = 4,
}

export default class User extends BaseModel {
  @GraphqlField()
  public customer_id: number;
  @GraphqlField()
  public customer_name: string;
  @GraphqlField()
  public customer_avatar: string;
  @GraphqlField()
  public customer_lastname: string;
  @GraphqlField()
  public customer_firstname: string;
  @GraphqlField()
  public gender: number;
  @GraphqlField()
  public birthday: string;
  @GraphqlField()
  public phonenumber: string;
  @GraphqlField()
  public email: string;
  @GraphqlField()
  public agent_status: AgentStatus;
  @GraphqlField()
  public is_agent: BooleanLike;
  @GraphqlField()
  public is_shop_agent: BooleanLike;
  @GraphqlField()
  public agent_code: string;
  @GraphqlField()
  public is_complete: BooleanLike;

  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}

export class AutoComplete extends BaseModel {
  @GraphqlField()
  public text: string;
}
