import { GraphqlField, GraphqlRefField } from "../helpers/decorators";
import BaseModel from "./BaseModel";

export class ShopCategory {
  public category_id: number;
  @GraphqlField()
  public url: string;
  @GraphqlField()
  public title: string;
  @GraphqlField()
  public media_url: string;
}

export class ShopFooter {
  public title: string;

  public link: string;

  public icon: string;

  public children: ShopFooter[];
}

export class RecommendProduct {
  @GraphqlField()
  public product_name: string;
  @GraphqlField()
  public default_media_url: string;
  @GraphqlField()
  public url: string;
  @GraphqlField()
  public price_low: string;
  @GraphqlField()
  public price_high: string;
}

export default class Shop extends BaseModel {
  @GraphqlField("default", "ShopCollect", "AgentRate")
  public shop_id: number;
  @GraphqlField("default", "ShopCollect")
  public shop_name: string;
  @GraphqlField("default", "ShopCollect")
  public shop_slogan: string;
  @GraphqlField("default", "ShopCollect")
  public shop_logo: string;
  @GraphqlField("default", "ShopCollect")
  public shop_background: string;
  @GraphqlField()
  public established_date: Date;
  @GraphqlField()
  public shop_description: string;
  @GraphqlField()
  public has_search: boolean;
  @GraphqlField()
  public has_category: boolean;
  @GraphqlField()
  public shop_address: string;
  @GraphqlField()
  public shop_video: string;

  @GraphqlField()
  public is_tob: boolean;

  @GraphqlField("ShopCollect")
  public shop_url: string;
  @GraphqlField("ShopCollect")
  public shop_introduction: string;

  public url: string;
  public shop_category_data: ShopCategory[] = [];
  public recommend_products: RecommendProduct[] = [];

  @GraphqlField("AgentRate")
  public commission_rate: number;
  @GraphqlField("AgentRate")
  public agent_type: number;
  @GraphqlField()
  public shop_activity_url: string;

  /**
   *
   */
  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}

export class ShopDetail extends BaseModel {
  @GraphqlField()
  public shop_id: number;
  @GraphqlRefField(ShopCategory)
  public shop_category_data: ShopCategory[] = [];

  @GraphqlRefField(RecommendProduct)
  public recommend_product_focus: RecommendProduct[] = [];

  @GraphqlRefField(RecommendProduct)
  public recommend_product_list: RecommendProduct[] = [];

  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}

export class ShopActivity extends BaseModel {
  @GraphqlField()
  public activity_id: number;
  @GraphqlField()
  public start_time: string;
  @GraphqlField()
  public end_time: string;
  @GraphqlField()
  public now_time: string;
  @GraphqlField()
  public activity_type: number;
  @GraphqlField()
  public activity_type_text: number;
  @GraphqlField()
  public activity_name: string;
  @GraphqlField()
  public activity_web_picture: string;

  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}

export type AgentRate = Pick<
  Shop,
  "shop_id" | "commission_rate" | "agent_type"
>;
