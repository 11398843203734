import * as React from "react";
import debounce from "lodash/debounce";
import "./index.scss";

export const WaterfallContent = React.createContext<{
  onWaterFall: () => void;
}>({
  onWaterFall: () => {
    throw Error("please provide onWaterFall function");
  },
});

interface WaterfallProps {
  gap: number;
  itemWidth: number;
  children: React.ReactNode;
  fullWidth?: boolean;
}

interface WaterfallState {
  didMount: boolean;
}

class Waterfall extends React.Component<WaterfallProps, WaterfallState> {
  constructor(props: WaterfallProps) {
    super(props);
    this.state = {
      didMount: false,
    };
  }
  public static defaultProps = {
    gap: 10,
    itemWidth: 450,
  };

  public waterfallBox = React.createRef<HTMLDivElement>();

  public componentDidMount() {
    // 在服务端渲染的时候保证不会被调用
    this.setState({
      didMount: true,
    });

    window.addEventListener("resize", this.waterFall);
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.waterFall);
  }
  public getClient = () => {
    const { current } = this.waterfallBox;
    if (current) {
      return {
        width: current.clientWidth,
        height: current.clientHeight,
      };
    } else {
      return {
        width: 0,
        height: 0,
      };
    }
  };

  public getScrollTop = () => {
    const { current } = this.waterfallBox;

    if (current) {
      return current.scrollTop;
    } else {
      return 0;
    }
  };
  public waterFall = debounce(() => {
    const { width: boxWidth } = this.getClient();
    const { itemWidth, gap, fullWidth } = this.props;
    const columns = fullWidth ? 1 : Math.floor(boxWidth / (itemWidth + gap)); // 处理一下,实现一行一个
    if (this.waterfallBox.current) {
      const items = this.waterfallBox.current.children as any;
      const arr: string[] = [];
      for (let i = 0; i < items.length; i++) {
        if (i < columns) {
          items[i].style.top = 0;
          items[i].style.left = (itemWidth + gap) * i + "px";
          items[i].style.width = (itemWidth || boxWidth) + "px";
          arr.push(items[i].offsetHeight);
        } else {
          // 其他行
          // 3- 找到数组中最小高度  和 它的索引
          // 找到的最高高度, 用来给box赋值
          let minHeight = arr[0];

          let index = 0;
          for (let j = 0; j < arr.length; j++) {
            if (minHeight > arr[j]) {
              minHeight = arr[j];
              index = j;
            }
          }
          // 4- 设置下一行的第一个盒子位置
          // top值就是最小列的高度 + gap
          items[i].style.top = arr[index] + gap + "px";
          // left值就是最小列距离左边的距离
          items[i].style.left = items[index].offsetLeft + "px";
          items[i].style.width = (itemWidth || boxWidth) + "px";
          // 5- 修改最小列的高度

          arr[index] = arr[index] + items[i].offsetHeight + gap;
        }

        let maxHeight = arr[0];

        // 求最大高度
        for (let j = 0; j < arr.length; j++) {
          if (maxHeight < arr[j]) {
            maxHeight = arr[j];
          }
        }
        // 最小列的高度 = 当前自己的高度 + 拼接过来的高度 + 间隙的高度
        this.waterfallBox.current.style.height = maxHeight + gap + "px";
      }
    }
  }, 100);

  public render() {
    return (
      <WaterfallContent.Provider value={{ onWaterFall: this.waterFall }}>
        <div ref={this.waterfallBox} className="waterfall">
          {this.state.didMount && this.props.children}
        </div>
      </WaterfallContent.Provider>
    );
  }
}

export default Waterfall;
