import { renderReactRedux } from "./helpers/hypernova.helper";
import configureStore from "./store/configureStore";
import PDP from "./pages/ProductDetails/";
import ProductDetails2B from "./pages/ProductDetails/index.2b";
import PL from "./pages/ProductList";
import ProductList2B from "./pages/ProductList/index.2b";
import Shop from "./pages/Shop";
import Shop2B from "./pages/Shop/index.2b";

import Home from "./pages/Home";

// footer page
// import FAQ from "./pages/FAQ";
// import AboutUs from "./pages/AboutUs";
// import Partner from "./pages/Partner";
// import Marketing from "./pages/Marketing";
// import ContactUs from "./pages/ContactUs";
// import Manufacturer from "./pages/Manufacturer";
// import Advertising from "./pages/Advertising";
import Account from "./pages/UserCenter";
// import UserCenter from "./pages/UserCenter";
import Message from "./pages/Message";
import OrderDetails from "./pages/OrderDetails";
import Address from "./pages/Address";
import Cart from "./pages/Cart";
import ToBCart from "./pages/ToBCart";
import ShareCart from "./pages/ToBCart/ShareCart";
import ShareOrder from "./pages/ToBOrderHistory/ShareOrder";
import PayResult from "./pages/PayResult";
import AfterSale from "./pages/AfterSale";

import OrderHistory from "./pages/OrderHistory";
import MeagBook from "./pages/MegaBook/";
import IdeaDetalis from "./pages/IdeaDetalis";
import IdeaList from "./pages/IdeaList";
// import UnsubscribeEmail from "./pages/UnsubscribeEmail";
import Reservation from "./pages/Reservation";
import Commission from "./pages/Commission";
import ToBOrderHistory from "./pages/ToBOrderHistory";
import ToBOrderDetails from "./pages/ToBOrderHistory/OrderDetails";
import VerifyAgent from "./pages/VerifyAgent";

import { VerifyEmailFailed, VerifyEmailSucceed } from "./pages/VerifyEmail";

import Login from "./pages/Login";

// import "./styles/core/index.scss";

import PageNotFound from "./pages/Alone/PageNotFound";
import SystemError from "./pages/Alone/SystemError";
import PrivacyPolicy from "./pages/Alone/PrivacyPolicy";
import AboutUs from "./pages/Alone/AboutUs";
import Withdrawal from "./pages/Withdrawal";
import DesignIdea from "./pages/DesignIdea";
// import GaeaDesignIdea from "./pages/DesignIdea/index.gaea";
import UserDesign from "./pages/UserDesign";
import Recommend from "./pages/Recommend";
import Provider from "./pages/Provider";
import GaeaDesignIdea from "./pages/DesignIdea/index.gaea";
import UserDesign from "./pages/UserDesign";
import Recommend from "./pages/Recommend";
import Provider from "./pages/Provider";
import ShopActivity from "./pages/ShopActivity";

export default {
  /* tslint:disable object-literal-key-quotes*/
  PageNotFound: renderReactRedux("PageNotFound", PageNotFound, configureStore), // 404
  SystemError: renderReactRedux("SystemError", SystemError, configureStore), // 500
  AboutUs: renderReactRedux("AboutUs", AboutUs, configureStore), // 关于我们

  PrivacyPolicy: renderReactRedux(
    "PrivacyPolicy",
    PrivacyPolicy,
    configureStore,
  ), // 隐私政策

  ["product-details"]: renderReactRedux("product-details", PDP, configureStore), // PDP
  ["product-details-2b"]: renderReactRedux(
    "product-details-2b",
    ProductDetails2B,
    configureStore,
  ), // PDP
  // GaeaPdP: renderReactRedux("GaeaPdP", GaeaPDP, configureStore), // Gaea 调用的pdp
  "product-list": renderReactRedux("product-list", PL, configureStore), // PL
  "product-list-2b": renderReactRedux(
    "product-list-2b",
    ProductList2B,
    configureStore,
  ), // PL
  homepage: renderReactRedux("homepage", Home, configureStore), // 首页
  // faq: renderReactRedux("faq", FAQ, configureStore), // faq
  // "about-us": renderReactRedux("about-us", AboutUs, configureStore), // aboutUS
  // partner: renderReactRedux("partner", Partner, configureStore), // 合作伙伴
  // marketing: renderReactRedux("marketing", Marketing, configureStore), // 市场营销
  // "contact-us": renderReactRedux("contact-us", ContactUs, configureStore), //  联系我们
  // manufacturer: renderReactRedux("manufacturer", Manufacturer, configureStore), // 制造商
  // advertising: renderReactRedux("advertising", Advertising, configureStore), // ad 广告
  account: renderReactRedux("account", Account, configureStore), // 个人中心
  // usercenter: renderReactRedux("usercenter", UserCenter, configureStore), // 个人中心
  messages: renderReactRedux("messages", Message, configureStore), // 消息页面
  "order-details": renderReactRedux(
    "order-details",
    OrderDetails,
    configureStore,
  ), // 订单详情
  address: renderReactRedux("address", Address, configureStore), // 地址
  cart: renderReactRedux("cart", Cart, configureStore), // 购物车
  "tob-cart": renderReactRedux("tob-cart", ToBCart, configureStore), // tob购物车
  "share-cart": renderReactRedux("share-cart", ShareCart, configureStore), // tob购物车分享落地
  "share-order": renderReactRedux("share-order", ShareOrder, configureStore), // tob购物车分享落地
  "after-sale": renderReactRedux("after-sale", AfterSale, configureStore), // 售后
  reservation: renderReactRedux("reservation", Reservation, configureStore), // 预约
  "tob-order": renderReactRedux("tob-order", ToBOrderHistory, configureStore), // 2b订单列表
  commission: renderReactRedux("commission", Commission, configureStore), // 带单

  "tob-order-details": renderReactRedux(
    "tob-order-details",
    ToBOrderDetails,
    configureStore,
  ), // 2b订单详情

  "order-history": renderReactRedux(
    "order-history",
    OrderHistory,
    configureStore,
  ), // 历史订单,
  // 邮箱验证两个页面
  VerifyEmailFailed: renderReactRedux(
    "VerifyEmailFailed",
    VerifyEmailFailed,
    configureStore,
  ), // 邮箱验证成功
  VerifyEmailSucceed: renderReactRedux(
    "VerifyEmailSucceed",
    VerifyEmailSucceed,
    configureStore,
  ), // 邮箱验证失败
  Login: renderReactRedux("Login", Login, configureStore),
  MegaBook: renderReactRedux("MegaBook", MeagBook, configureStore),
  IdeaDetalis: renderReactRedux("IdeaDetalis", IdeaDetalis, configureStore),
  IdeaList: renderReactRedux("IdeaList", IdeaList, configureStore),

  Shop: renderReactRedux("Shop", Shop, configureStore),
  Shop2B: renderReactRedux("Shop2B", Shop2B, configureStore),
  "pay-result": renderReactRedux("pay-result", PayResult, configureStore),
  withdrawal: renderReactRedux("withdrawal", Withdrawal, configureStore),
  designidea: renderReactRedux("designidea", DesignIdea, configureStore),
  // "gaea-designidea": renderReactRedux(
  //   "gaea-designidea",
  //   GaeaDesignIdea,
  //   configureStore,
  // ),
  "gaea-designidea": renderReactRedux(
    "gaea-designidea",
    GaeaDesignIdea,
    configureStore,
  ),
  "account-design": renderReactRedux(
    "account-design",
    UserDesign,
    configureStore,
  ),
  Recommend: renderReactRedux("Recommend", Recommend, configureStore),
  "verify-agent": renderReactRedux("verify-agent", VerifyAgent, configureStore),
  Provider: renderReactRedux("Provider", Provider, configureStore),
  ShopActivity: renderReactRedux("ShopActivity", ShopActivity, configureStore),

  // unsubscribeEmail: renderReactRedux(    "unsubscribeEmail",    UnsubscribeEmail,    configureStore,  ),
  /* tslint:enable object-literal-key-quotes*/
};

if (module.hot) {
  module.hot.accept();
}
