import React from "react";
import styled, { css } from "styled-components";
import { List, Pagination } from "antd";

const complexMixin = css<{
  color?: string;
  activeColor?: string;
}>`
  font-size: 20px;

  .ant-pagination-item {
    margin: 0;
    border-radius: 5px;
    a {
      color: ${props => props.color ?? props.theme.textColor};
    }
  }

  .ant-pagination-item-active {
    background-color: ${props => props.activeColor ?? props.theme.primaryColor};
    border-width: 0;
    a {
      color: #fff;
      border-radius: 5px;
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    margin: 0;

    .ant-pagination-item-link {
      background-color: #f1f1f1;
      border-radius: 5px;
    }
  }

  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    margin: 0;
  }
`;

const PaginationStyled = styled(Pagination)<{
  color?: string;
  activeColor?: string;
}>`
  ${complexMixin}
`;

const ListStyled = styled(List)<{ color?: string; activeColor?: string }>`
  .ant-pagination {
    ${complexMixin}
  }
`;

export default ListStyled;

export { PaginationStyled as Pagination };
