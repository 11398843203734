import styled from "styled-components";
import { Upload } from "antd";

const StyledUpload = styled(Upload).attrs({ listType: "picture-card" })<{
  hoverBorderColor?: string;
  width?: string;
  height?: string;
  background?: string;
  border?: string;
  borderRadius?: string;
}>`
  & .ant-upload {
    width: ${props => props.width ?? "100%"};
    height: ${props => props.height};
  }

  & .ant-upload-select-picture-card {
    background: ${props => props.background};
    border: ${props => props.border};
    border-radius: ${props => props.borderRadius};
    &:hover {
      border-color: ${props =>
        props.hoverBorderColor ?? props.theme.primaryColor};
    }
  }

  & .ant-upload-list-picture-card-container {
    width: ${props => props.width ?? "100%"};

    .ant-upload-list-item-list-type-picture-card {
      width: 100%;
      padding: 0;
    }
  }

  & .ant-upload-list-item-actions {
    top: 0;
    left: 100%;
  }
`;

export default StyledUpload;

const UploadList = styled(Upload).attrs({ listType: "picture-card" })<{
  span: number;
  gutter: number;
}>`
  & .ant-upload-list-picture-card {
    display: flex;
    flex-flow: row wrap;
    margin: -${props => props.gutter / 2}px -${props => props.gutter / 2}px
      ${props => props.gutter / 2}px;

    .ant-upload-list-picture-card-container {
      flex: 0 0 ${props => 100 / props.span}%;
      width: auto;
      height: auto;
      margin: 0;
      padding: ${props => props.gutter / 2}px;
    }
  }
`;

export { UploadList };
