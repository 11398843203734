import React, { useContext, useState } from "react";
import { ChromePicker } from "react-color";
import getColor from "../../../helpers/colorTransform";
import Icon from "../../../components/icon";
import Image from "../../../components/image";
import { Tag } from "antd";
import ColorButton from "../../../static/img/Color.png";
import groupBy from "lodash/groupBy";
import classname from "classnames";
import { BusinessContext } from "../../BusinessContext";
import { useTheme } from "styled-components";

import * as styles from "./index.scss";

interface ColorPickerProps {
  color: string;
  onChnage: (e: any) => void;
  colorName: string;
  visible: boolean;
  onClose: () => void;
  classNamePrefix: "" | "mobile";
}

const ColorPicker = React.memo<ColorPickerProps>(props => {
  const {
    color,
    onChnage,
    colorName,
    visible,
    onClose,
    classNamePrefix,
  } = props;
  return (
    <>
      {visible && (
        <div className={styles["ColorScreening-ColorPicker"]}>
          <div
            className={
              styles[`${classNamePrefix}ColorScreening-ColorPicker-box`]
            }
          >
            <ChromePicker color={color} onChangeComplete={onChnage} />
            <div className={styles["ColorScreening-ColorPicker-showClassName"]}>
              <div style={{ background: color }} />
              <span>{colorName}</span>
            </div>
            <Icon
              type="iconcuowu1-copy"
              onClick={onClose}
              className={
                styles[`${classNamePrefix}ColorScreening-ColorPicker-close`]
              }
            />
          </div>
        </div>
      )}
    </>
  );
});

interface ColorBlockProsp {
  checked: boolean;
  oncheck: () => void;
  color: string;
}
const ColorBlock = React.memo((props: ColorBlockProsp) => {
  const { checked, oncheck } = props;

  return (
    <div
      className={styles["ColorScreening-color-item"]}
      style={{ background: props.color, borderRadius: "50%" }}
      onClick={() => oncheck()}
    >
      <div
        className={classname(
          styles["ColorScreening-color-checkend"],
          styles["ColorScreening-template-color-checkend"],
        )}
        style={{ opacity: checked ? 1 : 0 }}
      >
        <Icon type="icont1_correct" />
      </div>
    </div>
  );
});

const ColorScreening: React.FC<{
  list: {
    checked: boolean;
    color_id: number;
    color_name: string;
    color_group: string;
    color_value: string;
  }[];
  onChange: (bool: boolean, index: number) => void;
  colorValue: string;
  colorName: string;
  onChangeOtherColor: (colorValue: string, colorName: string) => void;
  classNamePrefix?: "" | "mobile";
}> = props => {
  const { secondaryColor, bussinessPrimaryColor } = useTheme();
  const { classNamePrefix = "" } = props;
  const { isToBusiness } = useContext(BusinessContext);
  const [colorPickerVisible, setColorPickerVisible] = useState(false);

  const handStateColorObj = (color: any) => {
    const [colorValue, colorName] = getColor(color.hex);
    props.onChangeOtherColor(colorValue, colorName);
  };

  const listDic = groupBy(props.list, c => c.color_group);

  return (
    <div
      style={{ position: "relative" }}
      className={styles[`${classNamePrefix}ColorScreening`]}
    >
      {Object.getOwnPropertyNames(listDic).map((key, pi) => (
        <div className={styles["ColorScreening-color-box"]} key={key}>
          {listDic[key].map((item, index) => (
            <ColorBlock
              key={index}
              color={item.color_value}
              checked={item.checked}
              oncheck={() => props.onChange(!item.checked, item.color_id)}
            />
          ))}
        </div>
      ))}
      <div>
        <div
          onClick={() => setColorPickerVisible(vis => !vis)}
          className={styles["ColorScreening-color"]}
        >
          <div className={styles["ColorScreening-color-btn"]}>
            <Image src={ColorButton} width={1} height={1} />
          </div>

          {/* <span>{props.colorName}</span> */}
        </div>
        <ColorPicker
          visible={colorPickerVisible}
          colorName={props.colorName}
          color={props.colorValue}
          onChnage={handStateColorObj}
          onClose={() => setColorPickerVisible(vis => !vis)}
          classNamePrefix={classNamePrefix}
        />
      </div>
      <div
        className={styles[`${classNamePrefix}ColorScreening-color-Selected`]}
      >
        {props.list.map((item, index) => {
          if (item.checked) {
            return (
              <Tag
                color={isToBusiness ? bussinessPrimaryColor : secondaryColor}
                key={index}
              >
                {item.color_name}
              </Tag>
            );
          } else {
            return "";
          }
        })}
      </div>
    </div>
  );
};

export default ColorScreening;
