import * as React from "react";
import megahomeScheduler from "../../helpers/scheduler";
import classNames from "classnames";
import BaseImage from "./BaseImage";
import "./index.scss";

export type StoresImageProps = {
  style?: any;
  src: string;
  alt: string;
  srcset: string;
  sizes: string;
  name: string;
  preserveCommandOrder: boolean;
  height: number;
  width: number;
  imageOverlay: boolean;
  imageCommon: boolean;
  lazyLoadImage: boolean;
  lazyLoadSrcset: boolean;
  draggable: true | false;
  isSelfContained: boolean;
  isCentered: boolean;
  className?: string;
  imageClass?: string;
  imageStyle?: React.CSSProperties;
  children?: any;
};
type StoresImageState = {
  showImage: boolean;
  loadSrcset: boolean;
};

class StoresImage extends React.Component<StoresImageProps, StoresImageState> {
  public static defaultProps = {
    height: 0,
    width: 0,
    src: "",
    alt: "",
    srcset: [],
    sizes: [],
    imageOverlay: false,
    imageCommon: false, // 常见非绝对定位的image
    commands: {},
    extension: "jpg",
    name: "default_name",
    preserveCommandOrder: false,
    lazyLoadImage: false,
    lazyLoadSrcset: false,
    draggable: false,
    isSelfContained: false,
    isCentered: true,
  };

  public state: StoresImageState = {
    showImage: false,
    loadSrcset: false,
  };

  private hasMounted: boolean = false;

  public componentDidMount() {
    this.hasMounted = true;

    megahomeScheduler.queuePostLoadTask(
      () => {
        if (this.hasMounted) {
          this.setState((state, props) => {
            return {
              ...state,
              showImage: props.lazyLoadImage,
              loadSrcset: props.lazyLoadSrcset,
            };
          });
        }
      },
      this,
      NaN,
    );
  }

  public render() {
    const {
      style,
      height,
      width,
      src,
      alt,
      srcset,
      sizes,
      imageOverlay,
      imageCommon,
      name,
      preserveCommandOrder,
      lazyLoadImage,
      lazyLoadSrcset,
      draggable,
      isSelfContained,
      isCentered,
      className,
      children,
      imageStyle,
      ...other
    } = this.props;

    const getPaddingBottom = () => {
      if (imageOverlay) {
        return {};
      }

      return {
        paddingBottom: `${100 * (height / width)}%`,
        ...style,
      };
    };

    const cls = classNames(
      {
        ImageComponent: true,
        "ImageComponent-overlay": imageOverlay,
      },
      className,
    );
    const imageComponent = (
      <div className={cls} style={getPaddingBottom()}>
        {(!lazyLoadImage || this.state.showImage) && (
          <BaseImage
            classes={classNames({
              "ImageComponent-image": !imageCommon,
            })}
            alt={alt}
            src={src}
            srcset={srcset}
            sizes={sizes}
            imageName={name}
            preserveCommandOrder={preserveCommandOrder}
            draggable={draggable}
            style={imageStyle}
            {...other}
          />
        )}
        {children}
      </div>
    );
    /**
     * There are some cases where we need the image component to be self-contained,
     * i.e. sitting inside a containing element which has the width of the image asset.
     *
     * We might not want the image to be as big as the parent container we're dropping it into.
     * Which will happen by default, due to the padding-bottom aspect ratio trick we use.
     */

    if (isSelfContained) {
      return (
        <div
          className={classNames({
            "ImageComponent-wrap": true,
            "ImageComponent-wrap--centered": isCentered,
          })}
          style={{
            width,
            height,
          }}
        >
          {imageComponent}
        </div>
      );
    } else {
      return imageComponent;
    }
  }
}

export default StoresImage;
