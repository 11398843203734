import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import { Dispatch, RootState } from "../../store/configureStore";
import { useSelector, useDispatch } from "react-redux";
import LoginSignUp from "../LogInSignUp";
import replaceState from "../../helpers/reolaceState";
import { useIntl } from "react-intl";
import Navigation from "./component/Navigation";
import Icon from "../../components/icon";
import classNames from "classnames";
import styles from "./index.scss";
import debounce from "lodash/debounce";
import { BusinessContext } from "../BusinessContext";
import { AutoComplete, Space, Breadcrumb, Layout, Divider, Affix } from "antd";
import { Link } from "../../wrappers/typography";
import Button, { PrimaryButton } from "../../wrappers/button";
import { useTheme } from "styled-components";
import { Route } from "antd/lib/breadcrumb/Breadcrumb";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

const { Option } = AutoComplete;
const { Header } = Layout;

const LayoutHeader: React.FC<{
  anonymous?: boolean;
  breadcrumbs?: { url: string; title: string }[];
}> = ({ breadcrumbs = [], anonymous }) => {
  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch<Dispatch>();
  const theme = useTheme();

  const { host_id } = useSelector((state: RootState) => state.URLSearch);
  const { isPrivate, shopLogo, shopName } = useSelector(
    (state: RootState) => state.CommonData || {},
  );
  const { isToBusiness } = useContext(BusinessContext);

  const { searchOption = [] } = useSelector(
    (state: RootState) => state.Navigation || {},
  );

  const itemRender = (
    route: Route,
    params: any,
    routes: Route[],
    paths: string[],
  ) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return last || route.path === "" ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link onClick={() => replaceState.linkTo(route.path)}>
        {route.breadcrumbName}
      </Link>
    );
  };

  const routes = breadcrumbs.map(b => ({
    path: b.url,
    breadcrumbName: b.title,
  }));

  const [isExpand, setIsExpand] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const searchInputRef = useRef(null);

  const expandSearchInput = useCallback((visible, duration = 300) => {
    setIsExpand(visible);

    setTimeout(() => {
      setIsSearchVisible(visible);

      if (visible) {
        searchInputRef.current?.input?.focus();
      }
    }, duration);
  }, []);

  const handleSearch = useCallback(
    (data: string) => {
      const keyword = data;

      if (keyword) {
        replaceState.linkTo(`${isToBusiness ? "/tob" : ""}/search`, "_blank", {
          keyword,
        });
      }
    },
    [isToBusiness],
  );

  const onSearch = debounce((searchText: string) => {
    dispatch.Navigation.handleAutoComplete({ keyword: searchText });
  }, 500);

  const screens = useBreakpoint();

  return (
    <>
      <Affix>
        <Header className={styles["layout-header"]}>
          <div className={styles[`layout-header-left`]}>
            {isPrivate && host_id ? (
              <div className={styles[`layout-header-isolation-logo`]}>
                <img src={shopLogo} alt="" />
              </div>
            ) : (
              <div className={styles[`layout-header-logo`]}></div>
            )}
            {!anonymous && (
              <div className={styles[`layout-header-menu`]}>
                <Navigation />
              </div>
            )}
          </div>
          {!anonymous && (
            <Space size={screens["xxl"] ? 20 : "middle"}>
              <AutoComplete
                ref={searchInputRef}
                onSelect={handleSearch}
                onSearch={onSearch}
                allowClear={true}
                onBlur={() => expandSearchInput(false)}
                placeholder={f({ id: "SearchItems" })}
                className={classNames({
                  [styles["action-search-input"]]: isExpand,
                  [styles["action-search-input-blur"]]: !isExpand,
                })}
                style={{
                  width: 160,
                  display: isSearchVisible ? "flex" : "none",
                }}
              >
                {searchOption?.map((item: any) => {
                  return (
                    <Option style={{ background: "white" }} value={item.text}>
                      {item.text}
                    </Option>
                  );
                })}
              </AutoComplete>
              <PrimaryButton
                key="search"
                shape="circle"
                icon={<Icon type="iconsousuo-tianchongxian" />}
                onClick={() =>
                  isSearchVisible
                    ? handleSearch("")
                    : expandSearchInput(true, 0)
                }
                bgColor="#666"
              ></PrimaryButton>
              <Divider
                type="vertical"
                style={{ borderColor: "#666", opacity: 0.28 }}
              />
              {!isToBusiness && (
                <PrimaryButton
                  key="design"
                  onClick={() => replaceState.linkTo(`/design-idea/new`)}
                  shape="circle"
                  icon={<Icon type="iconsheji" />}
                  bgColor="#666"
                ></PrimaryButton>
              )}
              <PrimaryButton
                key="cart"
                onClick={() =>
                  replaceState.linkTo(`${isToBusiness ? "/tob" : ""}/cart`)
                }
                shape="circle"
                icon={<Icon type="icongouwuchexian" />}
                bgColor="#666"
              ></PrimaryButton>
              <PrimaryButton
                key="megabook"
                shape="circle"
                onClick={() => replaceState.linkTo("/account/megabook")}
                icon={
                  <Icon
                    type="iconshoucangxian-01"
                    style={{ transform: "translateX(2px) translateY(1px)" }}
                  />
                }
                bgColor="#666"
              ></PrimaryButton>
              <Divider
                type="vertical"
                style={{ borderColor: "#666", opacity: 0.28 }}
              />
              <LoginSignUp key="account" />
            </Space>
          )}
        </Header>
      </Affix>
      {routes.length > 0 && (
        <Breadcrumb
          itemRender={itemRender}
          routes={routes}
          className={`layout-breadcrumb`}
        />
      )}
    </>
  );
};

export default React.memo(LayoutHeader);
