import queryString from "query-string";
import { Store, RootState } from "../store/configureStore";
export type ParamsType = { [n: string]: any };

type eventFun = ((
  preState: RootState["URLSearch"],
  nextStae: RootState["URLSearch"],
) => void)[];

class ReplaceState {
  // tslint:disable-next-line: no-shadowed-variable
  constructor(public ignoreList: { [n: string]: boolean }) {
    this.ignoreList = ignoreList;
  }

  public liten: Store | undefined;

  private eventList: eventFun = [];

  /**
   * 修改url参数 进行时间通知
   */
  public set = (params: ParamsType = {}) => {
    const parsed = { ...queryString.parse(location.search), ...params };

    if (Object.keys(parsed).length === 0) return;
    const filterParesd = Object.keys(parsed)
      .filter(item => !this.ignoreList[item])
      .reduce((pre: { [n: string]: string }, cur) => {
        pre[cur] = parsed[cur];
        return pre;
      }, {});

    window.history.replaceState({}, "", `?${queryString.stringify(parsed)}`);

    const nextURLSearch = {
      ...parsed,
      search:
        Object.keys(filterParesd).length === 0
          ? ""
          : `?${queryString.stringify(filterParesd)}`,
    };

    if (this.liten) {
      const state = this.liten.getState();
      this.onSet(nextURLSearch);
      this.eventList.forEach(item => {
        if (typeof item === "function") {
          item(state.URLSearch, nextURLSearch);
        }
      });
    } else {
      this.onSet(nextURLSearch);
    }
  };
  private onSet = (params: ParamsType) => {
    if (this.liten) {
      this.liten.dispatch({ type: "URLSearch/CHANGE_PARAMS", payload: params });
    }
  };

  public getLinkToUrl = (url: string = "", params: ParamsType = {}) => {
    const parsed = queryString.parse(location.search);

    const filterParesd = Object.keys(parsed)
      .filter(item => !this.ignoreList[item])
      .reduce((pre: { [n: string]: string }, cur) => {
        pre[cur] = parsed[cur];
        return pre;
      }, {});

    const parsedObj = queryString.parseUrl(url);

    const finalObj = {
      ...filterParesd,
      ...parsedObj.query,
      ...params,
    };

    const newUrl = `${parsedObj.url}?${queryString.stringify(finalObj)}`;

    return newUrl;
  };

  public linkTo = (
    url: string,
    target: string = "",
    params: ParamsType = {},
  ) => {
    const newUrl = this.getLinkToUrl(url, params);

    target === "_blank" ? window.open(newUrl) : (location.href = newUrl);
  };

  /**
   * 添加等待通知的事件
   */
  public addEvent = (fn: eventFun[0]) => {
    this.eventList.push(fn);
  };
}

/**
 * 全局忽略url参数,忽略是指不加入redux中但是url中还是会存在以及体现
 */
const ignoreList = {
  id: true, // 目前为了解决 因为pid 被添加到其他页面的url 导致干扰其他商品的原因,现在忽略掉 pid 这个参数
  shop_id: true, //原来设置为true，现因店铺模板取消
  keyword: true,
  pid: true,
  currency: true,
  toreview: true,
  layout: true,
  sid: true,
};

const replaceState = new ReplaceState(ignoreList);

export default replaceState;
