export default {
  Messages: "أخبار",
  CurrentOrder: "طلب",
  OrderHistory: "تاريخ الطلب",
  Review: "التعليقات",
  YouDoNotHaveAnyMessageToShow: "ليس لديك أي رسالة لإظهارها.",
  YouHaveNotPLaceAnyOrderYetPeriod: "لم تقم بتقديم أي طلب حتى الآن.",
  ViewOrderDetails: "معاينة تفاصيل الطلب",
  ThereIsNoHistoricalOrder:
    "لم تقم بتقديم طلب حتى الآن ، لا يوجد طلب تاريخي ، اذهب وانتق المنتج المناسب!",
  ToSpeedUptheCheckOut:
    "لتسريع عملية السحب ، لإضافة عنوان آخر ، ما عليك سوى النقر فوق ’إضافة عنوان جديد‘ ، واملأ النموذج ، وانقر فوق الزر’حفظ‘.إذا كنت ترغب في تعديل أو حذف أي من عناوينك الحالية ، فما عليك سوى النقر فوق الرابطالمناسب لعنوانك الحالي.",
  More: "أكثر",
  Binding: "ربط",
  BindingReferenceCode: "ملزم رمز المرجع",
  ChangePassword: "تغيير كلمة السر",
  ReferenceCodeColon: "كود المرجع:",
  PersonalInformation: "معلومات شخصية",
  FirstName: "الاسم الاول",
  LastName: "اسم العائلة",
  Gender: "جنس",
  DateOfBirth: "تاريخ الميلاد",
  Email: "البريد الإلكتروني",
  CellphoneNumber: "رقم الهاتف المحمول",
  CurrentPassword: "كلمة المرور الحالية",
  NewPassword: "كلمة مرور جديدة",
  ConfirmPassword: "تؤكد",
  Password: "كلمه السر",
  PleaseInputValidDateWithFormat:
    "الرجاء إدخال تاريخ صالح مع التنسيق ‘XXXX-XX-XX’",
  PasswordDoesNotMatch: "كلمة السر غير متطابقة",
  BindingSuccessed: "نجاح ملزم",
  UnbindingSuccessed: "نجاح لازم",
  TheReferenceCodeReachesTheUpperLimitOf:
    "يصل كود المرجع إلى الحد الأقصى وهو 20.",
  ReferenceCodeHasBeenBound: "تم ربط الكود المرجعي ، يرجى المحاولة مرة أخرى.",
  InvalidReferenceCodePeriodPleaseEnterValidNumber:
    "رمز المرجع غير صالح. الرجاء إدخال أرقام وأحرف صالحة.",
  Name: "الاسم الأول",
  Address: "عنوان",
  PhoneNumber: "رقم الهاتف",
  Alias: "الاسم المستعار",
  Organization: "اسم الشركة",
  PaymentMethod: "طريقة الدفع",
  TotalInToal: "{المجموع} في المجموع",
  OrderDetails: "تفاصيل الطلب",
  ConfirmTheOrder: "تأكد من الأمر",
  PlseaseConfirmYourOrder: "يرجى تأكيد طلبك.",
  CancelTheOrder: "الغي الطلب",
  AreyouConfirmToCancelYourOrder: "هل أنت متأكد من إلغاء طلبك.",
  YouCanAddUpToTwentyAddress:
    "يمكنك إضافة ما يصل إلى 20 عنوانًا. لقد أضفت عناوين {num}",
  AddYourAddressByClicking: "أضف عنوانك عن طريق النقر على 'إضافة عنوان جديد'",
  AddingShippingAddress: "مضيفا عنوان الشحن",
  LineOne: "العنوان: السطر الأول",
  LineTwo: "السطر الثاني",
  City: "مدينة",
  StateSlashProvince: "المحافظة",
  PostalCode: "الكود البريدى",
  Country: "بلد",
  CountryCode: "الرقم الدولي",
  Note: "اترك رسالة",
  CouponSlashReferenceCode: "الكوبون / الكود المرجعي",
  EnterEmailAddress: "أدخل عنوان البريد الالكتروني",
  OrderStatus: "حالة الطلب",
  ConsigneeInformation: "معلومات المرسل إليه",
  PaymentInformaton: "معلومات الدفع",
  Quotation: "عرض أسعار",
  ReviewProduct: "مراجعة المنتج",
  TotalPrice: "السعر الكلي}",
  PriceSlashItem: "{precio} / artículo",
  AssemblyManualLeftParenthesisComingSoonRightParenthesis:
    "دليل التجميع (قريبًا)",
  CustomerService: "خدمة الزبائن",
  WelcomeBack: "مرحبا بعودتك",
  SignUp: "سجل",
  EmailVerification: "تأكيد بواسطة البريد الالكتروني",
  ForgotPasswordDashStepTwo: "نسيت كلمة المرور - الخطوة 2",
  ForgotPasswordDashStepOne: "نسيت كلمة المرور - الخطوة 1",
  ResetPassword: "إعادة تعيين كلمة المرور",
  ForgotPassword: "هل نسيت كلمة المرور",
  InvalidEmailOrPassword: "البريد الإلكتروني وكلمة المرور غير صالحين",
  WelcomeToMegaHome: "مرحبًا بك في MegaHome",
  AlreadyHaveAnAccountQuestionMark: "هل لديك حساب؟",
  PleaseLogin: "الرجاء تسجيل الدخول",
  EnterThePassword: "أدخل كلمة المرور",
  Industry: "صناعة",
  PleaseCheckYourEmail: "يرجى التحقق من بريدك الإلكتروني",
  IfYouDoNotReciveTheEmail:
    "إذا لم تتلق بريدنا الإلكتروني في غضون بضع دقائق ، فالرجاء التحقق من صندوق البريد العشوائي",

  IHaveReadAndAcceptedMegahomeTermsOfService:
    "لقد قرأت وقبلت <a> بنود خدمة MegaHome </a>.",
  WeHaveSentYouAConfirmationEmailEmail:
    "لقد أرسلنا لك رسالة تأكيد بالبريد الإلكتروني إلى '<span> {email} </span>'. يرجى التحقق من صندوق بريدك الإلكتروني وتأكيد الحساب.",
  WeHaveSentYouAResetLink:
    "لقد أرسلنا إليك رابط إعادة التعيين عبر البريد الإلكتروني ‘<span>{email}</span> ’. يرجى التحقق من صندوق البريد الإلكتروني الخاص بك والنقر فوق رابط إعادة التعيين.",
  DidNotRevicedEmailQuestionMark: "لم تتلقى البريد الإلكتروني؟",
  ThisEmailAddressHasAlreadyBeenRegistered:
    "تم بالفعل تسجيل عنوان البريد الإلكتروني هذا ، تسجيل الدخول.",

  BackToMainMenu: "العودة إلى القائمة الرئيسية",
  Back: "تراجع",
  Homepage: "المنزل",
  ComingSoon: "قريبا",
  ShopTheBrand: "متجر العلامة التجارية",
  Account: "الحساب",

  SignOut: "خروج",
  SendAgain: "أعد الإرسال",
  BackToHomepage: "العودة إلى الصفحة الرئيسية",
  TermOfService: "شروط الخدمة",
  NextStep: "الخطوة التالية",
  MarketplaceHome: "الصفحة الرئيسية",
  Category: "الفئة",
  Room: "غرفة",
  NotAvailable: "منسحب",
  ToReview: "للتقييم",
  ByUsingThisSiteYouAgreeToThe:
    "باستخدام هذا الموقع ، فإنك توافق على استخدام ملفات تعريف الارتباط للتحليل والمحتوى المخصص والإعلان.",
  FindOutMore: "اكتشف المزيد",
  Metric: "وحدة متري",
  Imperial: "إمبراطوري",
  Home: "الصفحة الرئيسية",
  Firm: "حازم",
  ReadAll: "اجعل الكل مقروء",
  DeleteAll: "حذف الكل",
  AddNewAddress: "إضافة عنوان جديد",
  Confirm: "تؤكد",
  Cancel: "إلغاء",
  SetAsDefault: "تعيين كافتراضي",
  Default: "إفتراضي",
  Required: "مطلوب",
  TleLengthOfTheInputExceedNumCharacters:
    "لا يمكن أن يتجاوز طول الإدخال {num} من الأحرف",
  TheLengthOfTheInputMustBeAtLeast:
    "يجب أن يبلغ طول الإدخال {num} حرفًا على الأقل",
  PleaseEnterAValidEmailAddressExclamationMark:
    "يرجى إدخال عنوان بريد إلكتروني صالح!",
  Loading: "جار التحميل",
  Male: "ذكر",
  Female: "أنثى",
  Other: "آخرون",
  ByShopName: "تأتي من",
  Odm: "ODM",
  Oem: "OEM",
  Customization: "Customization",
  GetThePriceCommaSpecificationsCommaShipping:
    "احصل على السعر والمواصفات والشحن والمزيد من المعلومات مجانًا!",
  SignUpNow: "اشترك الآن",
  Quantity: "كمية",
  Submit: "إرسال",
  Collection: "المفضلة",

  AddToCart: "أضف إلى السلة",
  PleaseSelectOptions: "يرجى اختيار خيار",
  ContinueToCart: "أدخل عربة التسوق",
  DisabledPeople: "معطل",
  OopsCommaUploadFileExceedsMaximunSize:
    "يتجاوز حجم الملف الذي قمت بتحميله 10 ملايين. يرجى التحديد مرة أخرى.",
  OopsCommaUploadFileIsNotAValidImage:
    "الملف الذي قمت بتحميله ليس تنسيق صورة صالحًا ، يمكنك فقط استخدام ملفات JPG و PNG. يرجى تحميل مرة أخرى.",
  Cart: "عربة التسوق",
  LeftParenthesisPricePerItemRigthParenthesis: "({price} لكل منتج)",
  AddedToCart: "يضاف إلى العربة",
  Specifications: "مواصفات",
  Shipping: "معلومات الشحن",
  Price: "السعر",
  PrintLabel: "تسمية الطباعة",
  PrintQrCode: "طباعة رمز الاستجابة السريعة",
  YouCanSave: "يمكنك حفظ",
  Product: "نتاج",
  ToDo: "العمليات",
  Total: "مجموع",
  ContactUs: "يرجى الاتصال بنا",
  Quote: "اقتبس",
  RemoveFromCart: "إزالة من العربة",
  SelectAll: "اختر الكل",
  SignIn: "دخول",
  ContinueShopping: "استمر في التسوق",
  Login: "تسجيل الدخول",

  SectionsInMegahomeMarketplace: "من فئة MegaHome Marketplace",
  ItemsInMegahomeMarketplace: "منتجات من MegaHome Marketplace",
  MegaHomeRecommended: "توصية",
  Viewed: "تصفح الأوقات",
  Size: "حجم",
  Material: "مادة",
  Color: "اللون",
  Style: "أسلوب",
  MoreFilters: "المزيد من المرشحات",
  Clear: "واضح",
  CountResults: "النتيجة{count}",
  NewArrivals: "منتج جديد",
  Selected: "مختار",
  DashDashNoPreferenceDashDash: "-- يرجى اختيار --",
  DesignIdeas: "أفكار التصميم",
  NumIdeas: " {num} أفكار التصميم",
  Hide: "اختباء",
  Show: "عرض",
  ConnectWithUs: "تواصل معنا",
  MegaHomeUsAddress: "39055 Hastings Street, Suite 208 Fremont, CA 94538",
  WeHaveRevicedYourFeedback:
    "لقد تلقينا تعليقاتك. سنعود إليك في أقرب وقت ممكن.",
  SendAMessage: "إرسال رسالة",
  TellUsAboutYourNeeds: "يرجى الإبلاغ عن احتياجاتك",
  Inquire: "استعلام",
  Feedback: "ردود الفعل",
  Partnership: "شراكة",
  ItHelp: "الدعم الفني",
  Others: "آخرون",
  HotelVendor: "فندق مزود",
  Retailers: "تجار التجزئة",
  Designers: "مصمم",
  Suppliers: "الموردين",
  About: "معلومات",
  AboutUs: "معلومات عنا",
  Join: "للانضمام",
  Marketing: "تسويق",
  Disclaimer: "تنصل",
  Apply: "تطبيق",
  Return: "عودة",
  LookingForMoreStylesQuestionMark: "رؤية المزيد من الأنماط؟",
  FindMore: "اكتشف المزيد",
  Share: "مشاركة أفكاري",
  AddToCartSuccessfullyExclamationMark: "أضف للسلة بنجاح!",
  ProductDetails: "تفاصيل المنتج",
  Description: "وصف",
  WarningColonLeftBracketCaliforniaPropositionSixtyFive:
    "تحذير: [اقتراح كاليفورنيا 65] يرجى تأكيد مع المورد لسلامة المنتج.",
  Features: "المميزات",
  ProductFeatures: "مواصفات المنتج",
  ComponentsData: "مكونات البيانات",
  Compare: "قارن",
  Reviews: "التعليقات",
  SimilarProducts: "منتجات مماثلة",
  BuyTogether: "شراء معا",
  ComparewithSimilarPorducts: "مقارنة مع منتجات مماثلة",
  CustomerRating: "تقييم العملاء",
  SortByMostRecently: "الترتيب حسب الأحدث",
  SortByRating: "الترتيب حسب التقييم",
  ReviewProductFromOrder: "مراجعة المنتج من النظام",
  PleaseReviewOurProductsComma:
    "يرجى تقييم منتجاتنا ومشاركة تجربتك مع العملاء الآخرين.",
  AsteriskSignYouDoNotHaveFinishedOrder: "* ليس لديك المنتج النهائي للمراجعة.",
  MoreReviews: "المزيد من المراجعات",
  MadeInCountry: "صنع في {بلد}",
  PriceIncluded: "السعر مشمول",
  MinPeriod: "الحد الأدنى",
  MaxPeriod: "أقصى",
  MyShopingCart: "سلة التسوق الخاصة بي",
  CountItems: "{عدد} المنتجات",
  PleaseConfirm: "يرجى تأكيد",
  AreYouConfirmToRemoveTheseItems:
    "هل أنت متأكد أنك تريد إزالة هذه العناصر من السلة؟",
  YouHaveItemAddedWithDifferentCurrencyType:
    "عفوًا ، تمت إضافة عنصر (عناصر) بنوع عملة مختلف. يرجى اختيار عملة واحدة فقط للاقتباس.",
  OopsExclamationMarkYouShoppingCartIsEmpty:
    "وجه الفتاة! سلة التسوق الخاصة بك فارغة.",
  SignInToSeeWhatYouHaveAddedOrSaved: "سجّل الدخول لترى ما أضفته أو قمت بحفظه.",
  WeHaveALotOfSpecialProductOffers:
    "يمكننا تقديم الكثير من المنتجات المخصصة ، ويمكنك الحصول على المزيد من الإلهام من موقعنا على الإنترنت.",
  SelectedColonCountItems: "المحدد: {count} المنتجات",
  StartFrom: "يبدأ من",
  ContainersFittingEstimate: "حاويات تركيب تقدير",
  QuoteInformation: "معلومات التحقيق",
  SubmittedExclamationMark: "قدمت!",
  SelectFromSavedAddress: "حدد من العنوان المحفوظ",
  WeWillGetBackToYou: "سوف نعود إليك في أقرب وقت ممكن!",

  OopsExclamationMark: "آسف!",
  WeCannotFindAnyResultThatMatchYour: "لا توجد نتائج تطابق بحثك",
  ThePageTitleStillUnderConstructionPeriod:
    "الصفحة {title} لا تزال قيد الإنشاء. شكرا لك على صبرك!",
  SometingYouMayLikeColon: "شيء قد يعجبك:",
  OopsExclamationMarkCurrentFilterOptionsDoNot:
    "عذرا! لا يوجد حاليا أي نتائج في خيارات التصفية. يرجى اختيار مرة أخرى.",
  SortAndFilter: "فرز وتصفية",
  Supplier: "المورد",
  Shop: "تسوق",
  ShopEstablishedDate: "تاريخ تأسيس المحل",
  ShopRecommendedProducts: "تسوق المنتجات الموصى بها",
  FurnitureAndDecor: "الأثاث والديكور",
  SearchInThisShop: "البحث في هذا المحل",
  Copyright: "حقوق النشر",
  SearchItemsFromShop: "ابحث عن المنتجات في {shop}",
  Categories: "الفئة الموصى بها",
  Products: "قائمة المنتجات",
  OptionNotAvailable: "الخيار غير متوفر",
};
