import React, { useState, useEffect, useContext, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, Dispatch } from "../../../store/configureStore";
import * as styles from "../index.scss";
import Icon from "../../../components/icon";
import { useIntl } from "react-intl";
import Messages from "../../../components/messages";
import { getFirstItem } from "../../../helpers/modelHelpers";
import { BusinessContext } from "../../BusinessContext";
import { Row, Col, Form, Select } from "antd";
import Modal from "../../../wrappers/modal";
import Input from "../../../wrappers/input";
import Button, { PrimaryButton } from "../../../wrappers/button";
import { useTheme } from "styled-components";

const { Option } = Select;
const PaymentType = (props: { type: number; agentAccountId?: number }) => {
  const dispatch = useDispatch<Dispatch>();
  const { formatMessage: f } = useIntl();
  const { statistics, accounts = [] } = useSelector(
    (state: RootState) => state.WithdrawalPage,
  );
  const { type, agentAccountId } = props;

  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);

  const { primaryColor, panelColor, linkColor } = useTheme();

  const handleGetData = () => {
    const data = {
      agent_account_id: agentAccountId,
      callback: (res: any) => {
        form.setFieldsValue({
          account_username: res.account_username,
          account_bank: res.account_bank,
          account_number: res.account_number,
          bank_of_deposit: res.bank_of_deposit,
        });
      },
    };
    dispatch.WithdrawalPage.getAgentAccountInfo(data);
  };

  const renderTitle = (title: string) => (
    <Row type="flex" justify="space-between" style={{ padding: "13px 26px" }}>
      <Col>{title}</Col>
      <Col>
        <Icon
          type="icont1_close"
          onClick={() => setModalVisible(false)}
          style={{ color: primaryColor }}
        />
      </Col>
    </Row>
  );

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleSubmitForm = (value: any) => {
    const bankCode = getFirstItem(
      bankData.filter(m => m.name === value.account_bank),
    ).code;

    const data = {
      action: type === 1 ? "add" : "edit",
      agent_account_id: type === 2 ? agentAccountId : "",
      account_type: "bank",
      ...value,
      account_bank_code: bankCode,
      callback: (res: any) => {
        dispatch.WithdrawalPage.getAgentAccountAsync();
        setModalVisible(false);
      },
    };

    console.log(data, "datadata");

    dispatch.WithdrawalPage.changePaymentMethod(data);
  };

  const bankData = [
    {
      name: "工商银行",
      code: "ICBC",
    },
    {
      name: "农业银行",
      code: "ABC",
    },
    {
      name: "建设银行",
      code: "CBC",
    },
    {
      name: "招商银行",
      code: "CMBC",
    },
    {
      name: "民生银行",
      code: "CMSB",
    },
  ];

  return (
    <>
      {type === 1 && (
        <PrimaryButton
          onClick={() => setModalVisible(true)}
          bgColor={primaryColor}
        >
          <Icon type="icont1_jia"></Icon>
          {f({ id: "AddWithdrawalMethod" })}
        </PrimaryButton>
      )}
      {type === 2 && (
        <Icon
          type="iconbianji"
          onClick={() => {
            handleGetData();
            setModalVisible(true);
          }}
        ></Icon>
      )}

      <Modal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        width={400}
        footer={null}
        title={f({
          id: type === 1 ? "AddWithdrawalMethod" : "EditWithdrawalMethod",
        })}
      >
        <Form
          form={form}
          initialValues={{ remember: true }}
          onFinish={handleSubmitForm}
          onFinishFailed={onFinishFailed}
          style={{ padding: "0px 40px 30px" }}
        >
          <div style={{ fontSize: "0.6em", color: "#4B4B4B" }}>
            <span style={{ color: "red" }}>*</span> 请确保信息填写无误,
            否则将无法提现。
          </div>
          <Form.Item
            name="account_username"
            label={f({ id: "Name" })}
            rules={[
              {
                required: true,
                message: f({
                  id: "Required",
                }),
              },
            ]}
          >
            <Input
              placeholder={f({
                id: "PleaseEnter",
              })}
            />
          </Form.Item>
          <Form.Item
            name="account_bank"
            label={f({ id: "Bank" })}
            rules={[
              {
                required: true,
                message: f({
                  id: "Required",
                }),
              },
            ]}
          >
            <Select
              placeholder={f({
                id: "PleaseSelectOptions",
              })}
            >
              {bankData.map((item: any) => {
                return <Option value={item.name}>{item.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="bank_of_deposit"
            label={f({ id: "DepositBank" })}
            rules={[
              {
                required: true,
                message: f({
                  id: "Required",
                }),
              },
            ]}
          >
            <Input
              placeholder={f({
                id: "PleaseEnter",
              })}
            />
          </Form.Item>
          <Form.Item
            name="account_number"
            label={f({ id: "BankCardNumber" })}
            rules={[
              {
                required: true,
                message: f({
                  id: "Required",
                }),
              },
            ]}
          >
            <Input
              placeholder={f({
                id: "PleaseEnter",
              })}
            />
          </Form.Item>
          <Row
            style={{ display: "flex", marginTop: 30, justifyContent: "center" }}
          >
            <PrimaryButton htmlType="submit" bgColor={primaryColor}>
              {type === 1 ? f({ id: "Add" }) : f({ id: "Edit" })}
              <Icon type="icont1_correct" />
            </PrimaryButton>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default PaymentType;
