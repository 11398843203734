import * as React from "react";
import Layout from "../Layout";
import Icon from "../../components/icon";

import Content from "./Content";

import * as styles from "./index.scss";

function HandleComponent(action: "Succeed" | "Failed") {
  return function VerifyEmail() {
    return (
      <Layout>
        <Content
          href={action === "Succeed" ? "/authentication/signup/" : "/"}
          render={() =>
            action === "Succeed" ? (
              <>
                <span className={styles["VerifyEmail-succeed-icon"]}>
                  <Icon type="iconarrowDown"></Icon>
                </span>
                <span>Your email has successfully been verified. </span>
              </>
            ) : (
              <>
                <span className={styles["VerifyEmail-Failed-icon"]}>!</span>
                <span>Oops, your link has expired. please try again</span>
              </>
            )
          }
        />
      </Layout>
    );
  };
}

const VerifyEmailSucceed = HandleComponent("Succeed");

const VerifyEmailFailed = HandleComponent("Failed");

export { VerifyEmailSucceed, VerifyEmailFailed };
