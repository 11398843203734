import "reflect-metadata";

// export function MutationResult(createResultType: any): ClassDecorator {
//   return target => {
//     // 在类上定义元数据，key 为 `classMetaData`，value 为 `a`
//     Reflect.defineMetadata("classMetaData", "a", target);
//   };
// }

// export function Column(target: any, propertyName: string): void {
//   Reflect.defineMetadata(propertyName, propertyName, target);
// }

export function GraphqlField(...metadataKeys: string[]): PropertyDecorator {
  if (metadataKeys.length === 0) {
    metadataKeys.push("default");
  }

  return (target, propName) => {
    metadataKeys.forEach(metadataKey => {
      const keyMetadata = Reflect.getOwnMetadata(metadataKey, target);

      Reflect.defineMetadata(
        metadataKey,
        keyMetadata ? [...keyMetadata, propName] : [propName],
        target,
      );
    });
  };
}

// export function RefColumn(metadataValue: any): PropertyDecorator {
//   return (target, key) => {
//     Reflect.defineMetadata(key, new metadataValue(), target);
//   };
// }

export function GraphqlRefField(
  metadataValue: any,
  ...metadataKeys: string[]
): PropertyDecorator {
  if (metadataKeys.length === 0) {
    metadataKeys.push("default");
  }

  return (target, propName) => {
    metadataKeys.forEach(metadataKey => {
      const keyMetadata = Reflect.getOwnMetadata(metadataKey, target);
      Reflect.defineMetadata(
        metadataKey,
        keyMetadata ? [...keyMetadata, propName] : [propName],
        target,
      );
      Reflect.defineMetadata(propName, new metadataValue(), target);
    });
  };
}
