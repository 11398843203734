import * as React from "react";
import axios, { AxiosRequestConfig } from "axios";
import { message } from "antd";
import {
  buildQueryString,
  buildMultiQueryString,
  buildPostString,
} from "./buildGraphql";
import { ActionResult } from "../models/BaseModel";

const instance = axios.create({
  url: "/graphql",
  timeout: 15000,
});
instance.interceptors.request.use(
  config => {
    if (localStorage.getItem("mh_time") && localStorage.getItem("mh_token")) {
      config.headers["store_time"] = localStorage.getItem("mh_time");
      config.headers["store_token"] = localStorage.getItem("mh_token");
    }

    config.headers["store_lang"] =
      localStorage.getItem("store-currentLang") || "en";

    return config;
  },
  err => {
    // tslint:disable-next-line: no-console
    console.log(err);
  },
);

instance.interceptors.response.use(
  respnse => {
    // 判断本地是否存有token 和时间戳
    const mhTime = localStorage.getItem("mh_time");
    const mhToken = localStorage.getItem("store_token");

    // 如果不存在
    if (!mhTime && !mhToken) {
      // 并且响应中存在,那么存在本地并且拿去用
      if (respnse.headers["store_time"] && respnse.headers["store_token"]) {
        localStorage.setItem("mh_time", respnse.headers["store_time"]);
        localStorage.setItem("mh_token", respnse.headers["store_token"]);
      }
    }

    if (respnse.data.errors) {
      if (Array.isArray(respnse.data.errors)) {
        if (process.env.NODE_ENV === "development") {
          message.error(<>{respnse.data.errors[0].message}</>, 3);
        } else {
          message.error(<>出错啦，请稍后重试 </>, 3);
        }
      } else {
        if (process.env.NODE_ENV === "development") {
          message.error(<>{respnse.data.errors.message}</>, 3);
        } else {
          message.error(<>出错啦，请稍后重试 </>, 3);
        }
      }
      return Promise.reject(respnse);
    }
    return respnse;
  },
  error => {
    // tslint:disable-next-line: no-console
    if (process.env.NODE_ENV === "development") {
      message.error(<>{error.toString()}</>, 3);
    } else {
      message.error(<>出错啦，请稍后重试 </>, 3);
    }
  },
);

type methodNames = "get" | "post" | "put" | "delete" | "upload";

export const GraphQLRequest = (
  method: methodNames = "post",
  query: string | FormData | { [key: string]: any },
  config?: AxiosRequestConfig,
) => {
  if (method === "get") {
    return instance({ method: method, params: { query: query }, ...config });
  } else if (method === "upload") {
    return instance({ method: "post", data: query, ...config });
  }

  // if (__ENV__ === "local") {
  //   let data = query;
  //   if (typeof query === "string") {
  //     data = {
  //       operationName: null,
  //       query: query.replace(/query[^{]*/, ""),
  //       variables: {},
  //     };
  //   }
  //   return instance({ method: method, data, ...config });
  // }

  return instance({ method: method, data: { query }, ...config });
};

/**
 * 获取模型列表数据
 * @param method 方法名称
 * @param query 查询条件
 * @param args 其它查询参数
 */
export async function GraphqlQuery<T>(
  method: string,
  query: T,
  args?: { [key: string]: any },
  metadataKey?: string,
): Promise<T[]> {
  const requestStr = buildQueryString(method, query, args, metadataKey);

  const {
    data: { data },
  } = await GraphQLRequest("post", requestStr);

  return data[method];
}

/**
 * 获取多个模型列表数据
 * @param method 方法名称
 * @param model 查询条件
 * @param args 其它查询参数
 */
export async function GraphqlQueryMulti(
  ...queries: {
    method: string;
    model: { [key: string]: any };
    args?: { [key: string]: any };
    metadataKey?: string;
  }[]
): Promise<{ [key: string]: any }> {
  const requestStr = buildMultiQueryString(queries);

  const {
    data: { data },
  } = await GraphQLRequest("post", requestStr);

  return data;
}

/**
 * 获取模型列表数据
 * @param method 方法名称
 * @param query 查询条件
 * @param args 其它查询参数
 */
export async function GraphqlPost<T>(
  method: string,
  model: { [key: string]: any },
  actionResult: new () => T,
): Promise<T> {
  const result = new actionResult();

  const requestStr = buildPostString(method, model, result);

  const {
    data: { data },
  } = await GraphQLRequest("post", requestStr);

  return data[method];
}

/**
 * 获取模型列表数据
 * @param method 方法名称
 * @param query 查询条件
 * @param args 其它查询参数
 */
export async function GraphqlMutation(
  method: string,
  model: { [key: string]: any },
) {
  const res = await GraphqlPost(method, model, ActionResult);
  if (res.status_code !== 200) {
    message.error(res.message, 3);
    throw new Error(res.message);
  }
  return res;
}

export async function GraphqlUpload<T>(
  form: FormData,
  actionResult: new (obj: any) => T,
): Promise<T> {
  const {
    data: { data },
  } = await GraphQLRequest("upload", form);

  return new actionResult(data);
}
