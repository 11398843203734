import React, { useState, useRef, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import * as styles from "./index.scss";
import { RootState, Dispatch } from "../../store/configureStore";
import { CommonProps } from "../../typings/component";
import { getBase64 } from "../../helpers/modelHelpers";
import replaceState from "../../helpers/reolaceState";
import { Row, Col, Divider, Form, Avatar, message } from "antd";
import Icon from "../../components/icon";
import AutoImage from "../../components/image";
import AgentForm from "./components/AgentForm";
import DatePicker from "../../wrappers/date-picker";
import Modal from "../../wrappers/modal";
import Input from "../../wrappers/input";
import Select from "../../wrappers/select";
import { PrimaryButton } from "../../wrappers/button";
import { AgentStatus } from "../../models/User";
import { AuditStatus } from "../../models/Agent";
import { BooleanLike } from "../../models/Common";
import { useIntl } from "react-intl";
import { useTheme } from "styled-components";
import * as queryString from "query-string";

// 主体组件
const AccountPersonalInfo: React.FC<CommonProps> = (props: CommonProps) => {
  const { formatMessage: f } = useIntl();
  const { primaryColor, panelColor, linkColor } = useTheme();
  const { AccountPage: rematch } = useDispatch<Dispatch>();
  const { user, agent, confirmInfo } = useSelector(
    (state: RootState) => state.AccountPage,
  );
  const { verifyPay } = useSelector((state: RootState) => state.User);
  const { isPrivate } = useSelector((state: RootState) => state.CommonData);
  const { host_id } = useSelector((state: RootState) => state.URLSearch);
  const [formAvatarUrl, setFormAvatarUrl] = useState("");
  const [formFirstName, setFormFirstName] = useState("");
  const [formLastName, setFormLastName] = useState("");
  const [personallnfoType, setPersonallnfoType] = useState(1);
  const [isUpdatePasswordShow, setPasswordShow] = useState(false);
  const [imgModal, setImgModal] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [CustomerModel, setCustomerModel] = useState(false);
  const [defaultCardNumber, setDefaultCardNumber] = useState("");
  const [identity_card_number, setCardNumber] = useState("");
  const [isShowCardNumber, SetShowCardNumber] = useState(false);

  const [form] = Form.useForm();

  const requiredText = f({ id: "Required" });

  const handleChildTimeAvatarToggle = () => {
    const data = {
      callback: (res: any) => {
        form.setFieldsValue({
          customer_avatar: res.customer_avatar || "",
          customer_firstname: res.customer_firstname,
          customer_lastname: res.customer_lastname,
          gender: res.gender || "",
          birthday: res.birthday ? dayjs(res.birthday) : "",
          email: res.email || "",
          phonenumber: res.phonenumber,
          old_password: res.old_password,
          password: res.password,
          confirm: res.confirm,
        });
        setFormAvatarUrl(res.customer_avatar);
        setFormFirstName(res.customer_firstname);
        setFormLastName(res.customer_lastname);
      },
    };
    rematch.getUserInfo(data);
  };

  const onThemeFileChange = async (e: any) => {
    const file = e.target.files[0];

    const isJPG = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJPG) {
      message.error(f({ id: "OopsCommaUploadFileIsNotAValidImage" }), 3);

      return;
    }

    if (file.size > 8 * 1024 * 1024) {
      message.error(f({ id: "OopsCommaUploadFileExceedsMaximunSize" }), 3);

      return;
    }

    const fileBase64 = await getBase64(file);
    const data = {
      base64: fileBase64,
      callback: (res: any) => {
        setFormAvatarUrl(res);
      },
    };
    rematch.getUserAvater(data);
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const handleSubmitForm = (value: any) => {
    if (value.birthday && value.birthday !== "") {
      value.birthday = value.birthday.format("YYYY-MM-DD");
    }
    value.customer_name = value.customer_lastname + value.customer_firstname;
    value.customer_avatar = formAvatarUrl;
    rematch.setUserInfoAsync(value);
  };

  const formItemLayout = {
    labelCol: {
      span: 4,
      sm: { span: 6 },
    },
    wrapperCol: {
      span: 18,
      sm: { span: 16 },
    },
  };
  useEffect(() => {
    handleChildTimeAvatarToggle();
  }, []);

  useEffect(() => {
    if (!agent) return;
    setDefaultCardNumber(agent.identity_card_number);
    setCardNumber(
      agent.identity_card_number.replace(
        /^(.{6})(?:\d+)(.{4})$/,
        "$1********$2",
      ),
    );
  }, [agent]);

  const picPreview = (pic: string) => {
    return (
      <>
        <div
          onClick={() => {
            setImgModal(true);
            setPreviewImage(pic);
          }}
          style={{
            width: 160,
            height: 120,
            float: "left",
          }}
        >
          <AutoImage
            style={{ cursor: "pointer" }}
            width={1}
            height={1}
            src={pic}
          />
        </div>

        <Modal
          visible={imgModal}
          onCancel={() => setImgModal(false)}
          width={400}
        >
          <img src={previewImage} alt="" style={{ width: "100%" }} />
        </Modal>
      </>
    );
  };

  const rootAgentPage = () => {
    return (
      <div style={{ padding: 10, background: "white" }}>
        <div className={styles["PersonalInfo-agent-completion"]}>
          <div
            className={styles["PersonalInfo-agent-completion-customer-default"]}
          >
            <Icon type="icondianpu2"></Icon>
          </div>
          <div className={styles["PersonalInfo-agent-completion-content"]}>
            <p
              className={styles["PersonalInfo-agent-completion-title"]}
              style={{ color: primaryColor }}
            >
              {(confirmInfo && confirmInfo.root_name) || ""}
            </p>
            <p>
              {f({ id: "Address" })}：
              {(confirmInfo && confirmInfo.root_address) || ""}
            </p>
            <p>
              {f({ id: "ContactPhoneNumber" })}：
              {(confirmInfo && confirmInfo.platform_phone) || ""}
            </p>
          </div>
        </div>
        <Divider />
        <div>
          <Row style={{ marginBottom: 10 }}>
            <Col
              span={12}
              style={{ color: primaryColor }}
              className={styles["PersonalInfo-agent-userinfo-text"]}
            >
              {f({
                id:
                  "YouHaveBeenAddedAsTheDirectAgentPleaseCompleteTheTnformation",
              })}
            </Col>
            <Col
              span={12}
              style={{
                textAlign: "right",
                color: linkColor,
                cursor: "pointer",
              }}
              onClick={() => setCustomerModel(true)}
            >
              {f({ id: "WrongInformationContactCustomerServiceToModify" })}
            </Col>
          </Row>
          <div className={styles["PersonalInfo-agent-userinfo"]}>
            <Row
              gutter={10}
              className={styles["PersonalInfo-agent-userinfo-text"]}
            >
              <Col span={3} style={{ textAlign: "right" }}>
                {f({ id: "Name" })}:
              </Col>
              <Col span={20}>
                {(confirmInfo && confirmInfo.agent_name) || "-"}
              </Col>
            </Row>
            <Row
              gutter={10}
              className={styles["PersonalInfo-agent-userinfo-text"]}
            >
              <Col span={3} style={{ textAlign: "right" }}>
                {f({ id: "ContactPhoneNumber" })}:
              </Col>
              <Col span={20}>
                {(confirmInfo && confirmInfo.telephone) || "-"}
              </Col>
            </Row>
            <Row
              gutter={10}
              className={styles["PersonalInfo-agent-userinfo-text"]}
            >
              <Col span={3} style={{ textAlign: "right" }}>
                {f({ id: "IdCardNumber" })}:
              </Col>
              <Col span={20}>
                {(confirmInfo && confirmInfo.identity_card_number) || "-"}
              </Col>
            </Row>
            <Row>
              <Col style={{ textAlign: "right" }}>
                <AgentForm type={2} confirmInfo={confirmInfo} />
              </Col>
            </Row>
          </div>
        </div>

        <Modal
          visible={CustomerModel}
          onCancel={() => setCustomerModel(false)}
          width={500}
        >
          <Row
            type="flex"
            justify="space-between"
            style={{ padding: "13px 26px 0px", color: "#4B4B4B" }}
          >
            <Col></Col>
            <Col>
              <Icon
                type="icont1_close"
                onClick={() => setCustomerModel(false)}
                style={{ color: primaryColor }}
              />
            </Col>
          </Row>
          <Row className={styles["PersonalInfo-agent-completion-customer"]}>
            {confirmInfo && !!confirmInfo.shop_logo ? (
              <img src={confirmInfo && confirmInfo.shop_logo}></img>
            ) : (
              <div
                className={
                  styles["PersonalInfo-agent-completion-customer-default"]
                }
              >
                <Icon type="icondianpu2"></Icon>
              </div>
            )}

            <div
              className={
                styles["PersonalInfo-agent-completion-customer-content"]
              }
            >
              <p
                className={
                  styles["PersonalInfo-agent-completion-customer-title"]
                }
              >
                {(confirmInfo && confirmInfo.root_name) || "-"}
              </p>
              <p>
                <span
                  className={
                    styles["PersonalInfo-agent-completion-customer-span"]
                  }
                >
                  {f({ id: "RootContactPhone" })}：
                  {(confirmInfo && confirmInfo.contact_phone) || "-"}
                </span>
              </p>
              <p>
                <span
                  className={
                    styles["PersonalInfo-agent-completion-customer-span"]
                  }
                >
                  {f({ id: "PlatformPhone" })}：
                  {(confirmInfo && confirmInfo.platform_phone) || "-"}
                </span>
              </p>
            </div>
          </Row>
        </Modal>
      </div>
    );
  };
  const isHaveTelephone = user && !!user.phonenumber;
  return (
    <>
      {host_id || isPrivate ? (
        <></>
      ) : (
        <Row
          style={{
            height: "70px",
            lineHeight: "70px",
            textAlign: "center",
            cursor: "pointer",
          }}
        >
          <Col
            span={12}
            style={{
              borderBottom:
                personallnfoType === 1 ? "2px solid " + primaryColor : "",

              height: "100%",
            }}
            onClick={() => {
              setPersonallnfoType(1);
            }}
          >
            <h3 style={{ color: personallnfoType === 1 ? primaryColor : "" }}>
              {f({ id: "PersonalInformation" })}
            </h3>
          </Col>
          <Col
            span={12}
            style={{
              borderBottom:
                personallnfoType === 2 ? "2px solid " + primaryColor : "",
              color: personallnfoType === 2 ? primaryColor : "",
              height: "100%",
            }}
            onClick={() => {
              setPersonallnfoType(2);
            }}
          >
            <h3 style={{ color: personallnfoType === 2 ? primaryColor : "" }}>
              {f({ id: "AgentInformation" })}
            </h3>
          </Col>
        </Row>
      )}

      {personallnfoType === 1 && (
        <Col span={24}>
          <Form
            name="moonlight"
            form={form}
            {...formItemLayout}
            initialValues={{ remember: true, layout: "horizontal" }}
            onFinish={handleSubmitForm}
            onFinishFailed={onFinishFailed}
            style={{ padding: "30px 48px 96px 48px" }}
          >
            <Col span={12} style={{ position: "relative" }}>
              {" "}
              {user?.agent_status === AgentStatus.NotApplied &&
                (!verifyPay ? (
                  <div
                    style={{
                      position: "absolute",
                      top: 70,
                      left: 130,
                      zIndex: 2,
                    }}
                  >
                    <PrimaryButton
                      icon={<Icon type="iconhuangguan222"></Icon>}
                      onClick={() =>
                        replaceState.linkTo("/account/verify/agent")
                      }
                      bgColor={primaryColor}
                    >
                      {f({ id: "AsAgent" })}
                    </PrimaryButton>
                  </div>
                ) : (
                  <AgentForm
                    type={
                      user.is_complete === BooleanLike.True
                        ? 2
                        : user.agent_status === AgentStatus.NotApplied
                        ? 1
                        : 3
                    }
                    page={"info"}
                    confirmInfo={confirmInfo}
                    verifyPay={true}
                    isHaveTelephone={isHaveTelephone}
                  />
                ))}
              <Form.Item name="customer_avatar">
                <div
                  className={styles["PersonalInfo-form-avatar"]}
                  style={{ top: "0", borderRadius: "0px" }}
                >
                  <label>
                    {formAvatarUrl && (
                      <Avatar
                        style={{ width: "100%", height: "100%" }}
                        src={formAvatarUrl}
                      />
                    )}
                    {!formAvatarUrl && (
                      <div>
                        {formFirstName &&
                          formFirstName.charAt(0).toLocaleUpperCase()}
                        {formLastName &&
                          formLastName.charAt(0).toLocaleUpperCase()}
                      </div>
                    )}
                    <input type="file" onChange={e => onThemeFileChange(e)} />
                  </label>
                </div>
              </Form.Item>
              <Form.Item
                name="customer_lastname"
                label={f({
                  id: "LastName",
                })}
                rules={[{ required: true, message: requiredText }]}
              >
                <Input
                  name="customer_lastname"
                  placeholder={f({
                    id: "LastName",
                  })}
                ></Input>
              </Form.Item>
              <Form.Item
                name="customer_firstname"
                label={f({
                  id: "FirstName",
                })}
                rules={[{ required: true, message: requiredText }]}
              >
                <Input
                  placeholder={f({
                    id: "FirstName",
                  })}
                ></Input>
              </Form.Item>
              <Form.Item
                name="phonenumber"
                label={f({
                  id: "CellphoneNumber",
                })}
                // rules={[{ required: true, message: requiredText }]}
              >
                <Input
                  disabled={true}
                  placeholder={f({
                    id: "CellphoneNumber",
                  })}
                ></Input>
              </Form.Item>
              <Form.Item
                name="email"
                label={f({
                  id: "Email",
                })}
                rules={[
                  {
                    max: 60,
                    message: f(
                      { id: "TleLengthOfTheInputExceedNumCharacters" },
                      {
                        num: 60,
                      },
                    ),
                  },
                  {
                    type: "email",
                    message: f({
                      id: "PleaseEnterAValidEmailAddressExclamationMark",
                    }),
                  },
                ]}
              >
                <Input
                  disabled={true}
                  name="email"
                  placeholder={f({
                    id: "Email",
                  })}
                ></Input>
              </Form.Item>
              <Form.Item
                name="gender"
                label={f({
                  id: "Gender",
                })}
              >
                <Select
                  placeholder={f({
                    id: "Gender",
                  })}
                  style={{ width: "100%" }}
                >
                  <Select.Option value={"1"}>{f({ id: "Male" })}</Select.Option>
                  <Select.Option value={"2"}>
                    {f({ id: "Female" })}
                  </Select.Option>
                  <Select.Option value={"3"}>
                    {f({ id: "Secret" })}
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="birthday"
                label={f({
                  id: "DateOfBirth",
                })}
              >
                <DatePicker
                  onChange={val => console.log(val)}
                  style={{
                    width: "100%",
                    background: panelColor,
                  }}
                  format="YYYY-MM-DD"
                />
              </Form.Item>
              {form.getFieldValue("email") !== "" && (
                <Form.Item
                  label={f({
                    id: "Password",
                  })}
                >
                  <PrimaryButton
                    onClick={() => setPasswordShow(true)}
                    bgColor={primaryColor}
                  >
                    {f({
                      id: "ChangePassword",
                    })}
                  </PrimaryButton>
                </Form.Item>
              )}
              {isUpdatePasswordShow && (
                <>
                  <Form.Item
                    name="old_password"
                    label={f({
                      id: "CurrentPassword",
                    })}
                  >
                    <Input
                      placeholder={f({
                        id: "CurrentPassword",
                      })}
                      type="password"
                    ></Input>
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label={f({
                      id: "NewPassword",
                    })}
                    rules={[
                      {
                        min: 6,
                        message: f(
                          { id: "TheLengthOfTheInputMustBeAtLeast" },
                          {
                            num: 6,
                          },
                        ),
                      },
                      {
                        max: 20,
                        message: f(
                          { id: "TleLengthOfTheInputExceedNumCharacters" },
                          {
                            num: 20,
                          },
                        ),
                      },
                      {
                        pattern: /[0-9a-zA-Z_-]{1,}$/,
                        message: f(
                          { id: "TleLengthOfTheInputExceedNumCharacters" },
                          {
                            num: 20,
                          },
                        ),
                      },
                    ]}
                  >
                    <Input
                      placeholder={f({
                        id: "NewPassword",
                      })}
                      type="password"
                    ></Input>
                  </Form.Item>
                  <Form.Item
                    name="confirm"
                    label={f({
                      id: "ConfirmPassword",
                    })}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            f({ id: "PasswordDoesNotMatch" }),
                          );
                        },
                      }),
                    ]}
                  >
                    <Input
                      placeholder={f({
                        id: "ConfirmPassword",
                      })}
                      type="password"
                    ></Input>
                  </Form.Item>
                </>
              )}
            </Col>
            <Col span={24} style={{ textAlign: "right", margin: "20px 0" }}>
              <PrimaryButton
                htmlType="submit"
                style={{
                  width: "150px",
                  height: "50px",
                  fontSize: "1.4em",
                }}
                bgColor={primaryColor}
              >
                {f({ id: "Submit" })}
                <Icon type="icont1_correct" />
              </PrimaryButton>
            </Col>
          </Form>
        </Col>
      )}

      {personallnfoType === 2 && (
        <Col span={24}>
          {user?.is_complete === BooleanLike.True && rootAgentPage()}

          {user &&
            user.is_complete === BooleanLike.False &&
            user.agent_status === AgentStatus.NotApplied && (
              <div className={styles["PersonalInfo-agent-no"]}>
                <div className={styles["PersonalInfo-agent-no-icon"]}>
                  {" "}
                  <Icon type="iconkaihuweichenggong"></Icon>
                </div>

                <div className={styles["PersonalInfo-agent-no-text"]}>
                  {f({ id: "YouAreNotAnAgentNowPleaseBecomeOneSoon" })}
                </div>

                {!verifyPay ? (
                  <PrimaryButton
                    icon={<Icon type="iconhuangguan222"></Icon>}
                    onClick={() => replaceState.linkTo("/account/verify/agent")}
                    bgColor={primaryColor}
                  >
                    {f({ id: "AsAgent" })}
                  </PrimaryButton>
                ) : (
                  <AgentForm
                    type={1}
                    isHaveTelephone={isHaveTelephone}
                    verifyPay={true}
                  />
                )}
              </div>
            )}
          {user &&
            user.is_complete === BooleanLike.False &&
            user.agent_status === AgentStatus.Authenticating && (
              <div className={styles["PersonalInfo-agent-certification"]}>
                <div
                  style={{ color: primaryColor }}
                  className={styles["PersonalInfo-agent-certification-title"]}
                >
                  {(agent && agent.audit_status_text) || ""}
                  <span style={{ float: "right" }}>
                    <u
                      style={{
                        color: linkColor,
                        cursor: "pointer",
                        fontSize: "0.5em",
                      }}
                      onClick={() =>
                        replaceState.linkTo("/page/promotion/", "_blank")
                      }
                    >
                      数莓派推广者(代理)规范条款{" "}
                    </u>
                    <u
                      style={{
                        color: linkColor,
                        cursor: "pointer",
                        fontSize: "15px",
                        marginLeft: 10,
                      }}
                      onClick={() =>
                        replaceState.linkTo("/page/freelancer/", "_blank")
                      }
                    >
                      自由职业者服务协议
                    </u>{" "}
                  </span>
                </div>
                <div className={styles["PersonalInfo-agent-certification-txt"]}>
                  {agent &&
                    agent.audit_status === AuditStatus.Auditing &&
                    "正在认证中, 预计1-2个工作日内完成认证。"}
                  {agent &&
                    agent.audit_status === AuditStatus.Reedit &&
                    agent.remark}
                </div>
                <div className={styles["PersonalInfo-agent-certification-txt"]}>
                  {f({ id: "RecommendedCode" })}:
                  {agent &&
                  agent.referrer_agent_code &&
                  agent.referrer_agent_code !== ""
                    ? agent.referrer_agent_code
                    : "暂无"}
                </div>
                {agent && agent.audit_status === AuditStatus.Reedit && (
                  <Row>
                    <Col span={24} style={{ textAlign: "right" }}>
                      <AgentForm type={3} agent={agent} />
                    </Col>
                  </Row>
                )}

                <Divider />
                <div className={styles["PersonalInfo-agent-userinfo"]}>
                  <div className={styles["PersonalInfo-agent-userinfo-title"]}>
                    {f({ id: "PersonalInformation" })}
                  </div>
                  <Row
                    gutter={10}
                    className={styles["PersonalInfo-agent-userinfo-text"]}
                  >
                    <Col span={3} style={{ textAlign: "right" }}>
                      {f({ id: "Name" })}:
                    </Col>

                    <Col span={20}>
                      {agent && agent.is_designer === 1 && (
                        <Icon
                          type="iconbadge"
                          style={{
                            fontSize: "1.3em",
                            color: primaryColor,
                            marginRight: 5,
                          }}
                        ></Icon>
                      )}

                      {(agent && agent.agent_name) || "-"}
                    </Col>
                  </Row>
                  <Row
                    gutter={10}
                    className={styles["PersonalInfo-agent-userinfo-text"]}
                  >
                    <Col span={3} style={{ textAlign: "right" }}>
                      {f({ id: "ContactPhoneNumber" })}:
                    </Col>
                    <Col span={20}>{(agent && agent.telephone) || "-"}</Col>
                  </Row>
                  <Row
                    gutter={10}
                    className={styles["PersonalInfo-agent-userinfo-text"]}
                  >
                    <Col span={3} style={{ textAlign: "right" }}>
                      {f({ id: "IdCardNumber" })}:
                    </Col>
                    <Col span={20}>
                      {/* {(agent && agent.identity_card_number) || "-"} */}
                      {isShowCardNumber
                        ? defaultCardNumber
                        : identity_card_number}
                      <Icon
                        type={
                          isShowCardNumber ? "iconbuxianshi" : "iconxianshi"
                        }
                        style={{ marginLeft: 10 }}
                        onClick={() => SetShowCardNumber(!isShowCardNumber)}
                      ></Icon>
                    </Col>
                  </Row>
                  {/* <Row
                    gutter={10}
                    className={styles["PersonalInfo-agent-userinfo-text"]}
                  >
                    <Col span={3} style={{ textAlign: "right" }}>
                      {f({ id: "IdCardPhoto" })}:
                    </Col>
                    <Col span={20}>
                      <div
                        className={styles["PersonalInfo-agent-userinfo-img"]}
                      >
                        {agent &&
                          agent.identity_card_front &&
                          agent.identity_card_front !== "" &&
                          picPreview(agent.identity_card_front)}
                        {agent &&
                          agent.identity_card_back &&
                          agent.identity_card_back !== "" &&
                          picPreview(agent.identity_card_back)}
                        {agent &&
                          agent.identity_card_handheld &&
                          agent.identity_card_handheld !== "" &&
                          picPreview(agent.identity_card_handheld)}
                      </div>
                    </Col>
                  </Row> */}
                  {agent && agent.designer_case && agent.designer_case !== "" && (
                    <Row
                      gutter={10}
                      className={styles["PersonalInfo-agent-userinfo-text"]}
                      style={{ margin: "10px 0px" }}
                    >
                      <Col span={3} style={{ textAlign: "right" }}>
                        设计案例:
                      </Col>
                      <Col span={20}>
                        <div
                          className={styles["PersonalInfo-agent-userinfo-img"]}
                        >
                          {agent &&
                          agent.designer_case.indexOf("pdf") === -1 ? (
                            picPreview(agent.designer_case)
                          ) : (
                            <span
                              style={{ color: linkColor, cursor: "pointer" }}
                              onClick={() => {
                                window.open(agent.designer_case);
                              }}
                            >
                              点击下载
                            </span>
                          )}
                        </div>
                      </Col>
                    </Row>
                  )}
                </div>
              </div>
            )}
          {user &&
            user.is_complete === BooleanLike.False &&
            user.agent_status === AgentStatus.Succeed && (
              <div className={styles["PersonalInfo-agent-certification"]}>
                <div className={styles["PersonalInfo-agent-userinfo"]}>
                  <Row
                    gutter={10}
                    className={styles["PersonalInfo-agent-userinfo-agent"]}
                  >
                    <Col span={3} style={{ textAlign: "right" }}>
                      {f({ id: "AgentCode" })}:
                    </Col>
                    <Col span={20}>
                      {(agent && agent.agent_code) || "-"}{" "}
                      {user && user.is_agent === 1 && (
                        <span
                          className={
                            styles["PersonalInfo-agent-userinfo-agent-span"]
                          }
                          style={{ background: primaryColor }}
                        >
                          {f({ id: "PlatformAgent" })}
                        </span>
                      )}
                      {user && user.is_shop_agent === 1 && (
                        <span
                          className={
                            styles["PersonalInfo-agent-userinfo-agent-span"]
                          }
                          style={{ background: primaryColor }}
                        >
                          {f({ id: "ShopAgent" })}
                        </span>
                      )}
                      <span style={{ float: "right" }}>
                        <u
                          style={{
                            color: linkColor,
                            cursor: "pointer",
                            fontSize: "0.8em",
                          }}
                          onClick={() =>
                            replaceState.linkTo("/page/promotion/", "_blank")
                          }
                        >
                          数莓派推广者(代理)规范条款{" "}
                        </u>
                        <u
                          style={{
                            color: linkColor,
                            cursor: "pointer",
                            fontSize: "15px",
                            marginLeft: 10,
                          }}
                          onClick={() =>
                            replaceState.linkTo("/page/freelancer/", "_blank")
                          }
                        >
                          自由职业者服务协议
                        </u>{" "}
                      </span>
                    </Col>
                  </Row>
                  <Row
                    gutter={10}
                    className={styles["PersonalInfo-agent-userinfo-texts"]}
                  >
                    <Col span={3} style={{ textAlign: "right" }}>
                      {f({ id: "Name" })}:
                    </Col>
                    <Col span={20}>
                      {agent && agent.is_designer === 1 && (
                        <Icon
                          type="iconbadge"
                          style={{
                            fontSize: "1.3em",
                            color: primaryColor,
                            marginRight: 5,
                          }}
                        ></Icon>
                      )}
                      {(agent && agent.agent_name) || "-"}
                    </Col>
                  </Row>
                  <Row
                    gutter={10}
                    className={styles["PersonalInfo-agent-userinfo-texts"]}
                  >
                    <Col span={3} style={{ textAlign: "right" }}>
                      {f({ id: "ContactPhoneNumber" })}:
                    </Col>
                    <Col span={20}>{(agent && agent.telephone) || "-"}</Col>
                  </Row>
                  <Row
                    gutter={10}
                    className={styles["PersonalInfo-agent-userinfo-texts"]}
                  >
                    <Col span={3} style={{ textAlign: "right" }}>
                      {f({ id: "IdCardNumber" })}:
                    </Col>
                    <Col span={20}>
                      {isShowCardNumber
                        ? defaultCardNumber
                        : identity_card_number}
                      <Icon
                        type={
                          isShowCardNumber ? "iconbuxianshi" : "iconxianshi"
                        }
                        style={{ marginLeft: 10 }}
                        onClick={() => SetShowCardNumber(!isShowCardNumber)}
                      ></Icon>
                    </Col>
                  </Row>
                  {/* <Row
                    gutter={10}
                    className={styles["PersonalInfo-agent-userinfo-texts"]}
                  >
                    <Col span={3} style={{ textAlign: "right" }}>
                      {f({ id: "IdCardPhoto" })}:
                    </Col>
                    <Col span={20}>
                      <div
                        className={styles["PersonalInfo-agent-userinfo-img"]}
                      >
                        {agent &&
                          agent.identity_card_front &&
                          agent.identity_card_front !== "" &&
                          picPreview(agent.identity_card_front)}
                        {agent &&
                          agent.identity_card_back &&
                          agent.identity_card_back !== "" &&
                          picPreview(agent.identity_card_back)}
                        {agent &&
                          agent.identity_card_handheld &&
                          agent.identity_card_handheld !== "" &&
                          picPreview(agent.identity_card_handheld)}
                      </div>
                    </Col>
                  </Row> */}
                  <Row
                    gutter={10}
                    className={styles["PersonalInfo-agent-userinfo-texts"]}
                  >
                    <Col span={3} style={{ textAlign: "right" }}>
                      {f({ id: "PaymentAccount" })}:
                    </Col>
                    {agent && (
                      <Col span={20}>{`${
                        agent.account_type === "bank" ? agent.account_bank : ""
                      } ${agent.account_number}`}</Col>
                    )}
                  </Row>
                </div>
              </div>
            )}
        </Col>
      )}
    </>
  );
};

export default AccountPersonalInfo;
