import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "../../../store/configureStore";
import {
  DragDropContext,
  DropResult,
  Droppable,
  DraggableLocation,
  DroppableProvided,
  DraggableProvided,
  Draggable,
  DraggableStateSnapshot,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import { useDrop } from "ahooks";
import Renderer, {
  ItemType,
  RenderItem,
  KeyValuePairs,
} from "../../../components/irender";
import Icon from "../../../components/icon";
import {
  Input,
  Dropdown,
  List,
  Space,
  Tag,
  Typography,
  Spin,
  Tooltip,
} from "antd";
import Button, { PrimaryButton } from "../../../wrappers/button";
import { DesignCheckbox as Checkbox } from "../../../wrappers/checkbox";
import RcImage from "../../../components/image";
import { Product } from "../../../models/Product";
import omit from "lodash/omit";
import debounce from "lodash/debounce";
import InfiniteScroll from "react-infinite-scroller";
import { useTheme } from "styled-components";
import styles from "../style.scss";

export interface GroupItemProps {
  index: number;

  groupIndex: number;

  id: string;

  item?: RenderItem;

  render?: Renderer;

  setGroupList: (value: React.SetStateAction<string[][]>) => void;

  setRenderItems: (value: React.SetStateAction<RenderItem[]>) => void;

  setEditedItem: (value: React.SetStateAction<RenderItem>) => void;
}

const GroupItem: React.FC<GroupItemProps> = ({
  index,
  groupIndex,
  id,
  item,
  render,
  setGroupList,
  setRenderItems,
  setEditedItem,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch<Dispatch>();
  const {
    tagProducts = {},
    searchProductList = [],
    hasMoreSearchProducts = true,
  } = useSelector((state: RootState) => state.DesignIdea);

  const { root_id, host_id: shop_id } = useSelector(
    (state: RootState) => state.URLSearch,
  );

  const { isPrivate } = useSelector((state: RootState) => state.CommonData);

  const { searchProductListAsync: searchLoading } = useSelector(
    (state: RootState) => state.loading.effects.DesignIdea,
  );

  const [showAssociateForm, setAssociateForm] = useState(false);

  const [selectedProducts, setSelectedProducts] = useState<Product[]>(
    tagProducts[id] || [],
  );

  const [searchList, setSearchList] = useState<string[]>([]);

  const { Text, Link } = Typography;

  const handleAssociateClick = () => {
    dispatch.DesignIdea.setTagProducts({
      ...tagProducts,
      [id]: selectedProducts,
    });

    setAssociateForm(false);

    if (selectedProducts.length > 0) {
      const { shop_product_id, product_picture } = selectedProducts[0];
      render?.update(id, {
        additionalHash: { shop_product_id, product_picture },
      });

      setRenderItems(render?.objects);
    }
  };

  const handleAssociateCancel = () => {
    setSelectedProducts([]);
    dispatch.DesignIdea.setTagProducts(omit(tagProducts, id));

    setAssociateForm(false);

    render?.update(id, { additionalHash: null });
  };

  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(1);
  const handleSearchClick = useCallback(() => {
    if (keyword) {
      setSearchList(list =>
        list.includes(keyword) ? list : [...list, keyword],
      );
    }

    dispatch.DesignIdea.searchProductListAsync({
      root_id,
      action: "designidea",
      reset: true,
      page: 1,
      keyword,
      shop_id,
      private: isPrivate,
    });

    setPage(1);
  }, [keyword, page]);

  const itemIcons = {
    [ItemType.Text]: "iconwenzi",
    [ItemType.Tag]: "iconbiaoqian1",
    [ItemType.Line]: "iconxian",
    [ItemType.Circle]: "iconyuanxing",
    [ItemType.Rect]: "iconchangfangxing",
    [ItemType.Polygon]: "iconwubianxing",
    [ItemType.ItemScaleX]: "iconbiaochi",
    [ItemType.ItemScaleY]: "iconshuxiangbiaochi",
  };

  const AssociateProductForm = (
    <div className={styles["designer-grouped-associate"]}>
      <Space direction="vertical">
        <Space>
          <Input.Search
            value={keyword}
            onChange={e => setKeyword(e.target.value)}
            placeholder="请输入需要关联商品"
            onSearch={handleSearchClick}
            style={{ width: "275px" }}
            enterButton
            className="designer-input-searcher"
          />
          <Icon type="iconchazi" onClick={() => setAssociateForm(false)} />
        </Space>

        <div className={styles["designer-grouped-associate-tags"]}>
          {searchList.map((word, idx) => (
            <Tag
              key={idx}
              closable
              onClose={() =>
                setSearchList(list => list.filter(str => str !== word))
              }
              color="#bbb"
              onClick={() => {
                setKeyword(word);
                handleSearchClick();
              }}
              style={{ borderRadius: "11px", marginBottom: "3px" }}
            >
              {word}
            </Tag>
          ))}
        </div>
        <div className={styles["designer-grouped-associate-products"]}>
          <InfiniteScroll
            initialLoad={false}
            pageStart={1}
            loadMore={debounce(() => {
              setPage(page + 1);
              dispatch.DesignIdea.searchProductListAsync({
                action: "designidea",
                root_id,
                page: page + 1,
                keyword,
                shop_id,
                private: isPrivate,
              });
            }, 1000)}
            hasMore={!searchLoading && hasMoreSearchProducts}
            useWindow={false}
          >
            <List
              grid={{
                gutter: 5,
                column: 2,
              }}
              dataSource={searchProductList}
              renderItem={(item, index) => (
                <List.Item
                  className={styles["designer-grouped-associate-item"]}
                >
                  <RcImage height={84} width={146} src={item.product_picture}>
                    <Checkbox
                      value={item}
                      checked={selectedProducts.some(
                        p =>
                          Number(p.shop_product_id) ===
                          Number(item.shop_product_id),
                      )}
                      onChange={e =>
                        setSelectedProducts(e.target.checked ? [item] : [])
                      }
                    ></Checkbox>
                    <div
                      className={
                        styles["designer-grouped-associate-productname"]
                      }
                    >
                      <Text
                        ellipsis
                        style={{ fontSize: "0.8em", color: "#fff" }}
                      >
                        {item.product_name}
                      </Text>
                    </div>
                  </RcImage>
                </List.Item>
              )}
            >
              {searchLoading && hasMoreSearchProducts && (
                <div className={styles["infinite-loading-container"]}>
                  <Spin />
                </div>
              )}
            </List>
          </InfiniteScroll>
        </div>
        <PrimaryButton
          block
          onClick={handleAssociateClick}
          bgColor={theme.designPrimaryColor}
        >
          关联
        </PrimaryButton>
        <Button
          block
          onClick={handleAssociateCancel}
          color={theme.designPrimaryColor}
        >
          取消关联
        </Button>
      </Space>
    </div>
  );

  const [newImage, setNewImage] = useState("");

  const [imgLoading, setImgLoading] = useState(false);

  const [dropProps, { isHovering }] = useDrop({
    onDom: (content: any, e) => {
      e?.stopPropagation();

      if (typeof content === "object") {
        const { shop_product_id, product_picture, type } = content as Product;

        setImgLoading(true);

        const newImage = new Image();
        newImage.src = product_picture + "?" + new Date().getTime();
        newImage.crossOrigin = "";
        newImage.onload = () => {
          setImgLoading(false);

          const itemAttr = {
            width: newImage.width,
            height: newImage.height,
            type: ItemType.Image,
            value: newImage,
            show: true,
            center: [
              Math.floor(render?.canvas.width / 2),
              Math.floor(render?.canvas.height / 2),
            ],
            additionalHash: { shop_product_id, product_picture },
          } as RenderItem;

          render?.update(id, itemAttr);

          setNewImage(newImage.src);

          if (!type) {
            dispatch.DesignIdea.setSelectedProducts([
              ...selectedProducts,
              content,
            ]);
          }

          setRenderItems([...render?.objects]);
          setGroupList([...render?.sets]);
        };
      }
    },
  });

  const handleClickItem = (item: RenderItem) => {
    render?.currentTargetId = item.id;

    render?.restore();

    setEditedItem(item);
  };

  return (
    <Draggable key={id} draggableId={id} index={index}>
      {(
        dragProvided: DraggableProvided,
        dragSnapshot: DraggableStateSnapshot,
      ) => (
        <div
          className={styles["designer-grouped-item"]}
          ref={dragProvided.innerRef}
          {...dragProvided.draggableProps}
          {...dragProvided.dragHandleProps}
        >
          <div className={styles["designer-grouped-item-show"]}>
            {item?.type !== ItemType.EmptyValue ? (
              <Tooltip title="图层显示">
                <a
                  style={{
                    visibility: index === 0 ? "hidden" : "visible",
                  }}
                  onClick={() => {
                    render?.moveSetTargetId(id, groupIndex, groupIndex, 0);

                    setGroupList([...render?.sets]);
                    setRenderItems([...render?.objects]);
                  }}
                >
                  <Icon type="icondaochu1" />
                </a>
              </Tooltip>
            ) : (
              item.value && (
                <Dropdown
                  trigger={["click"]}
                  placement="bottomRight"
                  visible={showAssociateForm}
                  onVisibleChange={() => setAssociateForm(vis => !vis)}
                  overlay={AssociateProductForm}
                >
                  <Tooltip title="关联单品">
                    <Icon type="iconlianjie" />
                  </Tooltip>
                </Dropdown>
              )
            )}
          </div>

          {item?.type !== ItemType.EmptyValue ? (
            <div
              className={styles["designer-grouped-item-img"]}
              onClick={() => index === 0 && handleClickItem(item)}
              style={{ cursor: index === 0 ? "pointer" : "" }}
            >
              {item?.type === ItemType.Image ? (
                <img
                  src={(item?.value as HTMLImageElement)?.src + "?" + item?.id}
                ></img>
              ) : (
                <Icon type={itemIcons[item?.type]}></Icon>
              )}
            </div>
          ) : (
            <div
              className={styles["designer-grouped-item-tag"]}
              onClick={() => index === 0 && handleClickItem(item)}
              {...dropProps}
              style={{
                border: isHovering ? "5px solid #1890ff" : "",
                backgroundImage: `url(${item.additionalHash?.product_picture})`,
              }}
            >
              <Spin spinning={imgLoading}>
                <Icon type={item.value ? "icont2_biaoqian" : "icont1_jia"} />
              </Spin>
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
};

export default React.memo(GroupItem);
