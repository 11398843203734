import React, { useState, useEffect, useContext } from "react";
import { RootState, Dispatch } from "../../store/configureStore";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import Image from "../../components/image";
import styles from "./index.scss";
import last from "lodash/last";
import { Row, Col, Divider, Tag, Collapse, message, Card, Space } from "antd";
import { useIntl } from "react-intl";
import Icon from "../../components/icon";
import PayContent from "./components/pay";
import replaceState from "../../helpers/reolaceState";
import Modal from "../../wrappers/modal";
import Checkbox from "../../wrappers/checkbox";
import { Link } from "../../wrappers/typography";
import Steps from "../../wrappers/steps";
import Button, { PrimaryButton } from "../../wrappers/button";
import { useTheme } from "styled-components";
import { useMount, useSetState } from "ahooks";
import { Order } from "../../models/Order";

const { Panel } = Collapse;

interface OrderStatus {
  orderProductId: number;
  handleLogisticsGet: (orderProductId: number) => void;
  logisticsData: {
    order_product_id: number;
    provider_name: string;
    provider_address: string;
    delivery_contact_telephone: string;
    logistics_express_number: string;
    delivery_contact_person: string;
    estimated_delivery_date: string;
    logistics_express_company: string;
    shipping_method: string;
    traces: {
      logistics_time: string;
      logistics_state: string;
    }[];
  };
}

const OrderStatus = React.memo<OrderStatus>((props: OrderStatus) => {
  const { orderProductId, handleLogisticsGet, logisticsData } = props;
  const { formatMessage: f } = useIntl();
  const { primaryColor } = useTheme();
  const [dsd, dsaid] = useState("logistics");
  return (
    <Collapse
      style={{
        marginBottom: "20px",
        borderRadius: "5px",
      }}
      defaultActiveKey={dsd}
    >
      <Panel
        showArrow={false}
        key="logistics"
        header={
          <Row>
            <Col span={20}>
              <span>{f({ id: "LogisticsInformation" })}</span>
            </Col>
          </Row>
        }
      >
        {!logisticsData && (
          <PrimaryButton
            onClick={() => {
              if (!logisticsData) {
                dsaid("logistics");
                handleLogisticsGet(orderProductId);
              }
            }}
            bgColor={primaryColor}
          >
            获取物流信息
          </PrimaryButton>
        )}
        {logisticsData && logisticsData.shipping_method === "self" && (
          <>
            <p>{`${f({ id: "DistributionorName" })} : ${
              logisticsData.delivery_contact_person
            }`}</p>
            <p>{`${f({ id: "DistributionorPhone" })} : ${
              logisticsData.delivery_contact_telephone
            }`}</p>
            <p>{`${f({ id: "EstimatedTimeOfSrrival" })} : ${
              logisticsData.estimated_delivery_date
            }`}</p>
          </>
        )}
        {logisticsData && logisticsData.shipping_method === "third" && (
          <Steps progressDot direction="vertical" current={0} status="finish">
            {logisticsData.traces.map((item: any, index: number) => {
              return (
                <Steps.Step
                  key={index}
                  title={item.logistics_time}
                  description={item.logistics_state}
                />
              );
            })}
          </Steps>
        )}
      </Panel>
    </Collapse>
  );
});

// Payment info

interface PaymentInfo {
  order_amount: null | string;
  carriage: null | string;
  discount: null | string;
  payment_price: null | string;
  paymentMethod: null | string;
  orderStatus: number;
}
const PaymentInfo = React.memo((props: PaymentInfo) => {
  const { formatMessage: f } = useIntl();
  return (
    <div className={classnames(styles["CurrentOrder-compute-price"])}>
      <h4>{f({ id: "PaymentInformaton" })}</h4>
      <Row gutter={10} style={{ marginBottom: "5px" }}>
        <Col span={6}>{f({ id: "OrderPrice" })}:</Col>
        <Col span={18} style={{ textAlign: "right" }}>
          ¥{props.order_amount}
        </Col>
      </Row>
      <Row gutter={10} style={{ marginBottom: "5px" }}>
        <Col span={6}>{f({ id: "FreightPrice" })}:</Col>
        <Col span={18} style={{ textAlign: "right" }}>
          ¥{props.carriage}
        </Col>
      </Row>

      <Row gutter={10} style={{ marginBottom: "5px" }}>
        <Col span={6}>{f({ id: "DiscountPrice" })}:</Col>
        <Col span={18} style={{ textAlign: "right" }}>
          ¥{props.discount}
        </Col>
      </Row>
      {props.orderStatus > 1 && (
        <Row gutter={10}>
          <Col span={6}>支付方式:</Col>
          <Col span={18} style={{ textAlign: "right" }}>
            {props.paymentMethod}
          </Col>
        </Row>
      )}
      <Row gutter={10}>
        <Col span={6}>{f({ id: "ActualPrice" })}:</Col>
        <Col span={18} style={{ textAlign: "right" }}>
          ¥{props.payment_price}
        </Col>
      </Row>
    </div>
  );
});

// 商品card

interface OrderItemProps {
  order_product_id: number;

  product_name: string;
  product_status_text: string;
  product_picture: string;
  express_number: string;
  express_method: string;
  provider_name: string;
  customer_rating: number;
  quantity: string;
  url: string;
  agent_code: string;
  series_name: string;
  search: string;
  attribute: string;
  original_price: string;
  original_price_value: string;
  brand_name: string;
  tag_price: string;
  tag_price_value: string;
  shop_name: string;
  shop_product_id: number;
  orderId: number;
  activity_type_text: string;
  activity_type: number;
  handleConfirmGoods: (payload: any) => void;
  handleRefash: () => void;
  collection: { data_id: number }[];

  handleChangeCollect: (payload: { type: boolean; dataId: number }) => void;
}
const OrderItem = React.memo((props: OrderItemProps) => {
  const { formatMessage: f } = useIntl();
  const {
    search = "",
    product_name,
    product_picture,
    express_type, //快递类型
    express_number, //快递单号
    express_method, //配送方式
    provider_name, //服务点名称
    url,
    customer_rating, //评价
    quantity,
    series_name, //品牌系列
    attribute,
    brand_name, //品牌
    original_price,
    original_price_value,
    tag_price,
    shop_name,
    collection = [],
    order_product_id,
    shop_product_id,
    handleChangeCollect,
    after_show_status, // 是否有售后结果
    after_sale_flag, //是否支持售后
    data,
    agent_code,
    orderNumber,
    tag_price_value,
    shopUrl,
    orderId,
    handleConfirmGoods,
    handleRefash,
    activity_type,
    activity_type_text,
  } = props;

  const { primaryColor, secondaryColor } = useTheme();

  const orderData = {
    ...data,
  };

  const handleConfirmGood = (orderID: number, orderProductId: number) => {
    const data = {
      order_id: orderID,
      order_product_id: orderProductId,
      callback: res => {
        message.success(res.message);
        handleRefash();
      },
    };
    handleConfirmGoods(data);
  };
  orderData.orderNumber = orderNumber;
  return (
    <Card style={{ background: "white", marginBottom: "10px" }}>
      <div className={classnames(styles["CurrentOrder-p"])}>
        <Row>
          <span
            style={{
              position: "absolute",
              right: "20px",
              bottom: "0px",
            }}
          >
            {!!agent_code && `代理码：${agent_code}`}
          </span>
          <Col span={4} style={{ textAlign: "center" }}>
            <div className={styles[`CurrentOrder-item-img`]}>
              {Boolean(activity_type) && (
                <div
                  className={styles["CurrentOrder-item_activity"]}
                  style={{ background: primaryColor }}
                >
                  {activity_type_text}
                </div>
              )}
              <a
                href={`${url}${search.replace("?", "&")}`}
                onClick={e => {
                  e.preventDefault();
                  replaceState.linkTo(url, "_target");
                }}
              >
                <Image src={product_picture} width={1} height={1} />
              </a>
            </div>
          </Col>
          <Col span={7} className={styles["CurrentOrder-item-name-box"]}>
            <div>
              <p style={{ fontSize: "1.2em", lineHeight: "1.1" }}>
                {product_name}
              </p>
              {/* <p>
                    <Rating
                      value={(customer_rating * 5) / 100}
                      color={primaryColor}
                      disabled
                    />
                  </p> */}
              <p style={{ marginTop: "5px" }}>
                {brand_name && brand_name !== "" && (
                  <Tag color={secondaryColor} style={{ borderRadius: 30 }}>
                    {brand_name}
                  </Tag>
                )}
                {series_name && series_name !== "" && (
                  <Tag color={secondaryColor} style={{ borderRadius: 30 }}>
                    {series_name}
                  </Tag>
                )}
              </p>
            </div>
            <div>
              <p>
                {shop_name && shop_name !== "" && (
                  <div
                    style={{ color: primaryColor, cursor: "pointer" }}
                    onClick={() => {
                      replaceState.linkTo(shopUrl, "_target");
                    }}
                  >
                    <Icon
                      type="icondianpu2"
                      style={{ marginRight: "5px" }}
                    ></Icon>
                    {shop_name}
                  </div>
                )}
              </p>
              <p>{attribute}</p>
            </div>
          </Col>
          <Col span={2} style={{ textAlign: "left" }}>
            <span>*{quantity}</span>
          </Col>
          <Col span={4} style={{ textAlign: "left" }}>
            <span style={{ fontSize: "1.2em" }}>
              总价：¥{parseInt(quantity) * parseFloat(tag_price)}
            </span>
            <br />({tag_price_value} / 每件)
          </Col>
          <Col
            span={3}
            style={{
              textAlign: "center",
            }}
          >
            <span
              style={{
                color:
                  data.product_status === 2 || data.product_status === 3
                    ? "#c21d44"
                    : data.product_status === 0
                    ? "#bdbcbc"
                    : "",
              }}
            >
              {data.product_status_text}
            </span>
            <p>
              {data.product_status < 3 && orderData.logistics_delivery_text}
            </p>
          </Col>
          <Col span={4} style={{ textAlign: "center" }}>
            <Space direction="vertical" size={5}>
              {data.product_status === 3 && (
                <>
                  <Button
                    onClick={() => {
                      handleConfirmGood(orderId, orderData.order_product_id);
                    }}
                    color={primaryColor}
                  >
                    {f({
                      id: "ConfirmTheGoods",
                    })}
                  </Button>
                  <br />
                </>
              )}
              {data.product_status === 4 && data.is_review === 0 && (
                <>
                  <PrimaryButton
                    onClick={() =>
                      replaceState.linkTo(url, "", { toreview: 1 })
                    }
                    bgColor={primaryColor}
                  >
                    {f({ id: "ImmediatelyReview" })}
                  </PrimaryButton>
                  <br />
                </>
              )}
              {after_sale_flag === 1 && (
                <Button
                  color={primaryColor}
                  onClick={() => {
                    replaceState.linkTo(
                      "/account/aftersale/" +
                        orderNumber +
                        "/" +
                        order_product_id,
                    );
                  }}
                >
                  {f({ id: "ApplyAfterSale" })}
                </Button>
              )}
              {after_show_status === 1 && (
                <Button
                  color={primaryColor}
                  onClick={() => {
                    replaceState.linkTo(
                      "/account/aftersale/" +
                        orderNumber +
                        "/" +
                        order_product_id,
                    );
                  }}
                >
                  {f({ id: "ViewAfterSaleDetails" })}
                </Button>
              )}
            </Space>
          </Col>
        </Row>
      </div>
    </Card>
  );
});

interface CurrentOrderState {
  payment: number;
  serviceVisible: boolean;
  remainingTime: string;
  orderNumber: string;
}

const CurrentOrderContent: React.FC<{
  classNamePrefix?: "" | "mobile";
  orderNumber: "";
}> = props => {
  const { formatMessage: f } = useIntl();
  const { primaryColor } = useTheme();
  const { order: orderData, collectIds: collection } = useSelector(
    (root: RootState) => root.OrderDetailsPage,
  );
  const dispatch = useDispatch<Dispatch>();

  const [state, setState] = useSetState<CurrentOrderState>({
    payment: 1,
    serviceVisible: false,
    remainingTime: "",
    orderNumber: "",
  });

  useMount(() => {
    const orderNumber = last(location.pathname.split("/"));
    dispatch.OrderDetailsPage.getOrderDetailAsync(orderNumber);
    setState({
      orderNumber: orderNumber,
    });

    dispatch.OrderDetailsPage.getCollect();
  });

  /**
   * 记录选择的支付方式
   */
  const handleCheckBoxChange = (type: number) => {
    setState({
      payment: type,
    });
  };

  /**
   * 转换时间戳
   */
  const handlegetTime = (time: string) => {
    var date = time.substring(0, 19);
    let dates = date.replace(/-/g, "/");
    var timestamp = new Date(dates).getTime();
    return timestamp;
  };

  /**
   * 获取剩余时间
   */
  const handleChangeTime = (expiredAt: string, nowAt: string) => {
    if (!nowAt) return;
    if (!expiredAt) return;
    const notTime = Date.parse(new Date()) / 1000,
      expiredTime = handlegetTime(expiredAt) / 1000;
    const minute = parseInt((expiredTime - notTime) / 60);
    let second = parseInt((expiredTime - notTime) % 60);
    if (state.remainingTime !== "") return;
    if (second < 10) {
      second = "0" + second;
    }
    setState({
      remainingTime: "" + minute + ":" + second,
    });

    timeOut(handlegetTime(expiredAt));
  };

  const timeOut = (expiredTime: number) => {
    const timeRef = setTimeout(() => {
      const nowTime = Date.parse(new Date());

      const minutes = parseInt((expiredTime - nowTime) / 1000 / 60);
      let seconds = parseInt(((expiredTime - nowTime) / 1000) % 60);
      if (minutes == 0 && seconds == 0) {
        clearTimeout(timeRef);
        props.initRequest(state.orderNumber);
      }
      if (Number(seconds) < 10) {
        seconds = "0" + seconds;
      }

      setState({
        remainingTime: "" + minutes + ":" + seconds,
      });
      timeOut(expiredTime);
    }, 1000);
  };

  if (orderData) {
    handleChangeTime(orderData.expired_at, orderData.now_at);
  }

  return orderData ? (
    <>
      <div className={styles[`CurrentOrder-nav`]}>
        <Row>
          <Col span={8} style={{ fontWeight: 500 }}>
            {f({ id: "OrderNumber" })} : {orderData.order_number}
          </Col>
          <Col span={12}>
            <span
              style={{
                color:
                  orderData.order_status === 2 || orderData.order_status === 3
                    ? "#c21d44"
                    : orderData.order_status === 0
                    ? "#bdbcbc"
                    : "",
              }}
            >
              {orderData.order_status_text}
            </span>
            {orderData.order_status === 1 && (
              <span style={{ marginRight: "10px" }}>
                {" "}
                剩余时间:
                <span style={{ color: primaryColor }}>
                  {state.remainingTime}
                </span>
              </span>
            )}
          </Col>
          <Col span={8} style={{ textAlign: "right" }}>
            {/* <PrimaryButton
                      shape="circle"
                      size="small"
                      icon={<Icon type={"icont3_erweima"}></Icon>}
                    ></PrimaryButton>
                    <span style={{ marginLeft: "10px" }}>
                      {f({ id: "Share" })}
                    </span> */}
          </Col>
        </Row>
        <Row
          gutter={10}
          style={{
            background: "white",
            marginTop: "10px",
          }}
        >
          <Col span={12}>
            <Row>
              <div className={styles[`CurrentOrder-address`]}>
                <Icon
                  type="icont1_dizhi"
                  style={{ color: primaryColor }}
                ></Icon>
                <div>
                  {`${
                    (orderData.address &&
                      orderData.address[0].recipient_name) ||
                    ""
                  } ${
                    (orderData.address &&
                      orderData.address[0].recipient_telephone) ||
                    ""
                  }`}
                  <br />
                  {`${
                    (orderData.address &&
                      orderData.address[0].recipient_address) ||
                    ""
                  }`}
                </div>
              </div>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <PaymentInfo
                order_amount={orderData.order_amount}
                carriage={orderData.carriage}
                discount={orderData.discount}
                payment_price={orderData.payment_price}
                paymentMethod={orderData.payment_method_text}
                orderStatus={orderData.order_status}
              />
              {orderData.remark && (
                <div
                  className={classnames(styles["CurrentOrder-compute-price"])}
                >
                  <h4>{f({ id: "Note" })}</h4>
                  <Row gutter={10}>{orderData.remark}</Row>
                </div>
              )}
              {orderData.order_status === 1 && (
                <div
                  className={classnames(styles["CurrentOrder-compute-price"])}
                >
                  {" "}
                  <h4>支付方式</h4>
                  <p
                    className={classnames(
                      styles["CurrentOrder-compute-price-payment"],
                    )}
                  >
                    <Checkbox
                      shape="circle"
                      borderWidth="2px"
                      checked={state.payment === 1}
                      onChange={() => handleCheckBoxChange(1)}
                    >
                      <Icon type="iconzhifubao" />
                      支付宝支付
                    </Checkbox>
                    <Checkbox
                      shape="circle"
                      borderWidth="2px"
                      onChange={() => handleCheckBoxChange(2)}
                      checked={state.payment === 2}
                    >
                      <Icon type="iconweixinzhifu1" />
                      微信支付
                    </Checkbox>
                  </p>
                </div>
              )}
            </Row>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={6} style={{ padding: "0px" }}>
            <PrimaryButton
              block
              bgColor={primaryColor}
              onClick={() => {
                replaceState.linkTo("/account/orders");
                dispatch.OrderDetailsPage.setOrder(new Order({}));
              }}
            >
              {f({ id: "BackToList" })}
            </PrimaryButton>
          </Col>
          <Col span={6}></Col>
          <Col span="12" style={{ padding: "0px" }}>
            {orderData.order_status !== 1 && orderData.order_status < 5 && (
              <Col span={6}>
                <PrimaryButton
                  block
                  bgColor={primaryColor}
                  onClick={() => {
                    setState({
                      serviceVisible: true,
                    });
                  }}
                >
                  {f({ id: "CustomerService" })}
                  <Icon type={"iconcustomerService"} />
                </PrimaryButton>
              </Col>
            )}
            {orderData.order_status === 1 && (
              <>
                <Col span={12}>
                  <PayContent
                    address={orderData.address && orderData.address[0]}
                    handleGetQrcode={payload =>
                      dispatch.OrderDetailsPage.getQrCodeAsync(payload)
                    }
                    handleGetPayType={payload =>
                      dispatch.OrderDetailsPage.getPayResult(payload)
                    }
                    order_id={Number(orderData.order_id)}
                    orderNumber={state.orderNumber}
                    pirce={orderData.payment_price}
                    remark={orderData.remark}
                    payment={state.payment}
                    handleRefash={() =>
                      dispatch.OrderDetailsPage.getOrderDetailAsync(
                        state.orderNumber,
                      )
                    }
                  ></PayContent>
                </Col>
                <Col span={12}>
                  {/* <PrimaryButton
                          block
                          style={{
                            color: primaryColor,
                            background: "white",
                            border: "1px solid " + primaryColor,
                          }}
                        >
                          取消订单
                        </PrimaryButton> */}
                </Col>
              </>
            )}
          </Col>
        </Row>
      </div>
      <Row gutter={10}>
        <Col span={24}>
          <Card bordered={false}>
            {orderData.product &&
              orderData.product.map((item, index) => {
                return (
                  <>
                    <Row className={styles[`CurrentOrder-product-info`]}>
                      <span style={{ marginRight: "20px" }}>
                        {f({ id: "ServicePoint" })} : {item.provider_name}
                      </span>
                      {orderData.order_status !== 2 && (
                        <>
                          {item.logistics &&
                            item.logistics.shipping_method === "third" && (
                              <span style={{ marginRight: "20px" }}>
                                {f({ id: "CourierNumber" })} :{" "}
                                {item.express_number}
                              </span>
                            )}
                          <span style={{ marginRight: "20px" }}>
                            {f({ id: "InDistribution" })} :{" "}
                            {item.express_method}
                          </span>
                        </>
                      )}
                    </Row>
                    <Divider />
                    <Row style={{ height: "30px", lineHeight: "18px" }}>
                      <Col span={11} style={{ paddingLeft: "50px" }}>
                        {f({ id: "ProductInfomation" })}
                      </Col>
                      <Col span={2}>{f({ id: "Quantity" })}</Col>
                      <Col span={4}>{f({ id: "Price" })}</Col>
                      <Col span={2} style={{ textAlign: "center" }}>
                        {f({ id: "Status" })}
                      </Col>
                      <Col span={4} style={{ textAlign: "center" }}>
                        {f({ id: "ToDo" })}
                      </Col>
                    </Row>
                    <OrderItem
                      {...item}
                      key={index}
                      collection={collection}
                      handleChangeCollect={
                        dispatch.OrderDetailsPage.handleChangeCollect
                      }
                      data={item}
                      shopUrl={orderData.shop_url}
                      orderNumber={orderData.order_number}
                      orderId={Number(orderData.order_id)}
                      handleConfirmGoods={
                        dispatch.OrderHistoryPage.handleConfirmGoods
                      }
                      handleRefash={() =>
                        dispatch.OrderDetailsPage.INIT_DATA(state.orderNumber)
                      }
                    />
                    {orderData.order_status >= 3 && (
                      <OrderStatus
                        handleLogisticsGet={
                          dispatch.OrderDetailsPage.handleLogisticsGet
                        }
                        orderProductId={item.order_product_id}
                        logisticsData={item.logistics}
                      />
                    )}
                  </>
                );
              })}
          </Card>
        </Col>
      </Row>

      <Modal
        title={f({ id: "CustomerService" })}
        visible={state.serviceVisible}
        onCancel={() => {
          setState({
            serviceVisible: false,
          });
        }}
        width="486px"
        footer={null}
      >
        <div className={styles["CurrentOrder-Service"]}>
          <div className={styles[`CurrentOrder-Service-content`]}>
            <Row className={styles[`CurrentOrder-Service-content-row`]}>
              <Icon type="icondianpu-xian"></Icon>
              {f({ id: "Shop" })} : {orderData.shop_name}
            </Row>

            <Row className={styles[`CurrentOrder-Service-content-row`]}>
              <Icon type="icondianhua2"></Icon>
              {f({ id: "StoreCustomerService" })} : {orderData.shop_phone}
            </Row>
            <Row className={styles[`CurrentOrder-Service-content-row`]}>
              <Icon type="icondianhua2"></Icon>
              {f({ id: "PlatformCustomerService" })} :{" "}
              {orderData.platform_phone}
            </Row>
            <Row className={styles[`CurrentOrder-Service-content-row`]}>
              <Icon type="iconkefushijian"></Icon>
              客服时间 : {orderData.business_time}
            </Row>
            <Divider />
            <Row>
              <Col span={9}>
                <img
                  src="https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/const/cs/asg.jpg"
                  alt=""
                  style={{ width: 150, height: 150 }}
                />
              </Col>
              <Col span={15}>
                <div style={{ marginTop: 25 }}>产品咨询/优惠促销</div>
                <div style={{ marginTop: 20, color: "#4b4b4b" }}>
                  微信扫一扫，咨询产品信息
                </div>
                <div style={{ color: "#4b4b4b" }}>获取最新优惠。</div>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </>
  ) : null;
};

export default React.memo(CurrentOrderContent);
