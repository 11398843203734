import React from "react";
import { PrimaryButton } from "../button";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import Icon from "../../components/icon";

export interface CarouselArrowProps {
  className?: string;
  bgColor?: string;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  size?: SizeType;
}
export const DefaultPrevArrow: React.FC<CarouselArrowProps> = props => {
  const { className, bgColor, onClick, size } = props;

  return (
    <div className={className}>
      <PrimaryButton
        onClick={onClick}
        shape="circle"
        size={size}
        icon={<Icon type="iconyou"></Icon>}
        style={{ backgroundColor: bgColor, color: "black" }}
      />
    </div>
  );
};

DefaultPrevArrow.defaultProps = { size: "large" };

export const DefaultNextArrow: React.FC<CarouselArrowProps> = props => {
  const { className, bgColor, onClick, size } = props;

  return (
    <div className={className}>
      <PrimaryButton
        onClick={onClick}
        shape="circle"
        size={size}
        icon={<Icon type="iconzuo"></Icon>}
        style={{ backgroundColor: bgColor, color: "black" }}
      />
    </div>
  );
};

DefaultNextArrow.defaultProps = { size: "large" };
