import React, { useEffect, useContext, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, Dispatch } from "../../../store/configureStore";
import * as styles from "./index.scss";
import { useIntl } from "react-intl";
import { Row, Col, message, Space } from "antd";
import Button, { PrimaryButton } from "../../../wrappers/button";
import { Document, Page } from "react-pdf";
import SignatureCanvas from "react-signature-canvas";
import { useSize } from "ahooks";
import { signPdf, uint8arrayToBase64 } from "../../../helpers/file.helper";
import { ShareOrder } from "../../../models/Order";
import { useTheme } from "styled-components";

interface ContractSignProps {
  handleClose: (signResult: boolean) => void;

  token: string;

  isToBusiness: boolean;

  shareData: ShareOrder;
}

const ContractSign: React.FC<ContractSignProps> = ({
  handleClose,
  token,
  isToBusiness,
  shareData,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const { formatMessage: f } = useIntl();
  const esignRef = useRef<HTMLDivElement>(null);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isSign, setIsSign] = useState(false);

  const { primaryColor, bussinessPrimaryColor } = useTheme();
  const protocolRef = useRef<HTMLDivElement>(null);
  const size = useSize(protocolRef);
  const [numPages, setNumPages] = useState(0);

  const [protocolContent, setProtocolContent] = useState<ArrayBuffer>();
  const [defaultProtocolContent, setDefaultProtocolContent] = useState<
    ArrayBuffer
  >();

  const handleSignPdf = async () => {
    setProtocolContent(() => defaultProtocolContent);

    const singData = eval(shareData.sign_data);
    const data = [
      { ...singData[0], text: shareData.contract_customer_name },
      { ...singData[1], text: shareData.contract_address },
      { ...singData[2] },
    ];

    const signResult = await signPdf(
      protocolContent,
      esignRef.current?.toDataURL(),
      data,
    );
    setIsSign(true);
    setProtocolContent(signResult);
  };

  const handleSave = () => {
    if (!isSign) return message.error("请签名后重试！");
    const base64 = uint8arrayToBase64(protocolContent);
    const data = {
      order_contract_id: shareData.order_contract_id,
      action: isToBusiness ? "2b" : "2c",
      signed_contract_file: base64,
      token: token,
      callback: (res: any) => {
        message.success(res.message);
        setIsConfirm(true);

        if (handleClose) {
          handleClose(true);
        }
      },
    };
    dispatch.ShareTobOrder.saveOrderAsync(data);
  };

  useEffect(() => {
    if (!shareData) return;
    const data = {
      url: shareData.contract_file,
      callback: (buffer: any) => {
        setProtocolContent(buffer);
        setDefaultProtocolContent(buffer);
      },
    };
    dispatch.ShareCart.getProtocolAsync(data);
  }, [shareData]);

  const handleGetPages = () => {
    const placeholderArray = [];
    for (let a = 1; a < numPages + 1; a++) {
      placeholderArray.push(a);
    }
    return (
      <>
        {placeholderArray.map(item => {
          return <Page pageNumber={item} width={size.width} />;
        })}
      </>
    );
  };

  return (
    <div className={styles["esign-wrapper"]}>
      <div className={styles["protocol-container"]}>
        <Document
          file={{ data: protocolContent }}
          onLoadSuccess={pdf => {
            setNumPages(pdf.numPages);
          }}
          loading="文件加载中，请稍后···"
        >
          {handleGetPages()}
        </Document>
      </div>
      <div className={styles["esign-container"]}>
        <p>
          请确认上述文件内容，及订单、商品、数量和地址信息，确认无误请在下方签字区签名并保存！
        </p>
        <div className={styles["esign-canvas"]} ref={protocolRef}>
          <SignatureCanvas
            ref={esignRef}
            penColor="#444"
            canvasProps={{
              width: size?.width - 40,
              height: 200,
            }}
          />
        </div>
        <div className={styles["esign-operation"]}>
          <Space>
            <Button
              onClick={() => {
                esignRef.current?.clear();
                setProtocolContent(defaultProtocolContent);
                setIsSign(false);
              }}
              color={isToBusiness ? bussinessPrimaryColor : primaryColor}
              disabled={shareData?.contract_status === 2 || isConfirm}
            >
              重置
            </Button>
            <PrimaryButton
              onClick={() => {
                handleSignPdf();
              }}
              bgColor={isToBusiness ? bussinessPrimaryColor : primaryColor}
              disabled={shareData?.contract_status === 2 || isConfirm}
            >
              签名
            </PrimaryButton>
            <PrimaryButton
              onClick={handleSave}
              bgColor={isToBusiness ? bussinessPrimaryColor : primaryColor}
              disabled={shareData?.contract_status === 2 || isConfirm}
            >
              保存
            </PrimaryButton>
          </Space>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ContractSign);
