export function matchDistrictByCode<T, K extends keyof T>(
  districts: T[],
  target: string,
  fieldName: K,
  codeSplitCount = 2,
): T | undefined {
  let i = 0;
  let result: T | undefined,
    slicedTarget = target;
  // console.log(districts);
  while (!result && slicedTarget.length > 0) {
    slicedTarget = target.slice(0, target.length - i++ * codeSplitCount);
    // console.log(slicedTarget);
    result = districts.find(d =>
      (d[fieldName] as any)?.startsWith(slicedTarget),
    );
  }

  return result;
}

export function matchDistrictsByCode<T, K extends keyof T>(
  districts: T[],
  target: string,
  fieldName: K,
  codeSplitCount = 2,
): T[] {
  let i = 0;
  let result = new Array<T>(),
    slicedTarget = target;
  // console.log(districts);
  while (slicedTarget.length > 0) {
    slicedTarget = target.slice(0, target.length - i++ * codeSplitCount);
    // console.log(slicedTarget);
    result = districts.filter(d =>
      (d[fieldName] as any)?.startsWith(slicedTarget),
    );
  }

  return result;
}
