import React, { useEffect, useContext, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, Dispatch } from "../../../store/configureStore";
import * as styles from "./index.scss";
import { useIntl } from "react-intl";
import { Row, Col, message, Tag, Space, Card } from "antd";
import Icon from "../../../components/icon";
import Rate from "../../../wrappers/rate";
import Modal from "../../../wrappers/modal";
import { PrimaryButton } from "../../../wrappers/button";
import { useTheme } from "styled-components";
import ContractSign from "./ContractSign";

const ShareDown = () => {
  const dispatch = useDispatch<Dispatch>();
  const { formatMessage: f } = useIntl();
  const { shareData } = useSelector((state: RootState) => state.ShareTobOrder);
  const { isLogin } = useSelector((state: RootState) => state.User);
  const [isConfirm, setIsConfirm] = useState(false);
  const [token, setToken] = useState("");
  const [isToBusiness, setIsToBusiness] = useState(false);
  useEffect(() => {
    if (!shareData) {
      const tokentText = location.pathname.split("/");
      const isToB = location.href.indexOf("toborder") !== -1;
      setIsToBusiness(isToB);
      dispatch.ShareTobOrder.getShareData({
        token: tokentText[tokentText.length - 1],
      });
      setToken(tokentText[tokentText.length - 1]);
    }
  }, []);
  const handleAddtoCart = () => {
    let ids = "",
      quantity = "";
    shareData.share_data.map(m => {
      ids = ids + m.shop_product_id + "#";
      quantity = quantity + m.quantity + "#";
    });
    const data = {
      shop_product_id: ids,
      quantity: quantity,
      agent_code: shareData.share_data[0].share_customer_agent_code,
      callback: (res: any) => {
        message.success(res.message);
        setTimeout(
          () => (location.href = isToBusiness ? "/tob/cart" : "/cart"),
          2000,
        );
      },
    };
    dispatch.ShareTobOrder.addToCartAsync(data);
  };

  const handleGetQuantity = () => {
    if (!shareData) return;
    let num = 0;
    shareData.share_data.map(m => {
      num = num + parseFloat("" + m.quantity);
    });
    return num;
  };

  const { primaryColor, bussinessPrimaryColor } = useTheme();
  const [esignVisable, setEsignVisable] = useState(false);

  return (
    <div className={styles["ShareTobOrder-box"]}>
      <Row gutter={10}>
        <Col span={16}>
          <div
            style={{
              padding: "10px",
              background: "white",
              borderRadius: "5px",
            }}
          >
            {shareData && (
              <Card
                title={
                  <Row gutter={10}>
                    <Col span={12}>
                      <Icon
                        type="icondianpu2"
                        style={{
                          marginRight: "5px",
                          color: isToBusiness
                            ? bussinessPrimaryColor
                            : primaryColor,
                        }}
                      ></Icon>
                      <span
                        style={{
                          color: isToBusiness
                            ? bussinessPrimaryColor
                            : primaryColor,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          (location.href = `/tob/${shareData.shop_url}`)
                        }
                      >
                        {shareData.shop_name}
                      </span>
                      <span style={{ marginLeft: "20px" }}>
                        {f({ id: "OrderNumber" })}: {shareData.order_number}
                      </span>
                      <span style={{ marginLeft: "20px" }}>
                        {shareData.order_time}
                      </span>
                    </Col>
                  </Row>
                }
              >
                {shareData.share_data.map((m: any, mIndex: number) => {
                  return (
                    <Row
                      align={"middle"}
                      style={{
                        padding: "15px",
                        borderBottom:
                          shareData.share_data.length - 1 === mIndex
                            ? ""
                            : "1px solid #bdbcbc",
                      }}
                    >
                      <Col span={5}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {m.url && m.url !== "" ? (
                            <a href={m.url}>
                              <img
                                src={m.product_picture}
                                style={{ width: "80%" }}
                              />
                            </a>
                          ) : (
                            <img
                              src={m.product_picture}
                              style={{ width: "80%" }}
                            />
                          )}
                        </div>
                      </Col>
                      <Col span={7} style={{ height: "170px" }}>
                        <p>
                          <span style={{ marginRight: 10, fontSize: 15 }}>
                            {m.product_name}
                          </span>
                          {m.customer_rating > 0 && (
                            <Rate
                              value={(m.customer_rating * 5) / 100}
                              disabled
                            />
                          )}
                        </p>
                        <p className={styles["table-MHS"]}>
                          {m.brand_name && m.brand_name !== "" && (
                            <Tag
                              color={
                                isToBusiness
                                  ? bussinessPrimaryColor
                                  : primaryColor
                              }
                            >
                              {m.brand_name}
                            </Tag>
                          )}
                          {m.series_name && m.series_name !== "" && (
                            <Tag
                              color={
                                isToBusiness
                                  ? bussinessPrimaryColor
                                  : primaryColor
                              }
                            >
                              {m.series_name}
                            </Tag>
                          )}
                        </p>
                      </Col>
                      <Col span={4}>
                        {m.attribute &&
                          m.attribute.split(",").map((z: string) => {
                            return (
                              <>
                                <Tag
                                  color={
                                    isToBusiness
                                      ? bussinessPrimaryColor
                                      : primaryColor
                                  }
                                  style={{ marginBottom: "5px" }}
                                >
                                  {z}
                                </Tag>
                                <br />
                              </>
                            );
                          })}
                      </Col>
                      <Col span={4}>
                        <p>X{m.quantity}</p>
                      </Col>
                      {m.is_active ? (
                        <Col span={3}>
                          {!Number(m.has_tag_price_range) ? (
                            <>
                              ¥
                              {(parseFloat(m.tag_price) * m.quantity).toFixed(
                                2,
                              )}
                            </>
                          ) : (
                            <>{`¥${(
                              m.quantity * parseFloat(m.tag_price_low)
                            ).toFixed(2)} - ¥${(
                              m.quantity * parseFloat(m.tag_price_high)
                            ).toFixed(2)}`}</>
                          )}
                        </Col>
                      ) : (
                        <Col span={3}></Col>
                      )}
                    </Row>
                  );
                })}
              </Card>
            )}
          </div>
        </Col>
        <Col span={8}>
          <Space direction="vertical">
            <div className={styles["ShareTobOrder-box-right"]}>
              <div className={styles["ShareTobOrder-box-right-content"]}>
                <h3>您正在访问一个共享的订单！</h3>
                <p>来自: {shareData && shareData.recipient_name}</p>
              </div>

              <div className={styles["ShareTobOrder-box-right-content"]}>
                {shareData && (
                  <>
                    <Row>
                      <Col span={24}>{`${
                        isToBusiness
                          ? shareData?.contract_customer_name
                          : shareData?.recipient_name
                      }  ${shareData?.recipient_telephone}`}</Col>
                    </Row>
                    <Row>
                      <Col span={24}>{shareData?.contract_address}</Col>
                    </Row>
                  </>
                )}
              </div>
              <div className={styles["ShareTobOrder-box-right-content"]}>
                <h3>价格信息</h3>
                <Row>
                  <Col span={12}>已选商品数</Col>
                  <Col span={12} style={{ textAlign: "right" }}>
                    {handleGetQuantity()}
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>总价</Col>
                  <Col span={12} style={{ textAlign: "right" }}>
                    {shareData && shareData.payment_amount}
                  </Col>
                </Row>
              </div>
              <Row>
                <PrimaryButton
                  bgColor={isToBusiness ? bussinessPrimaryColor : primaryColor}
                  block
                  onClick={() => {
                    if (isLogin) {
                      handleAddtoCart();
                    } else {
                      dispatch.User.changeUserState({
                        modalAction: "Login",
                        isOpen: false,
                      });
                    }
                  }}
                >
                  {isToBusiness ? "加入采购清单" : "加入购物车"} &nbsp;
                  <Icon type="icont1_correct"></Icon>
                </PrimaryButton>
              </Row>
            </div>
            {shareData?.has_contract && (
              <PrimaryButton
                bgColor={isToBusiness ? bussinessPrimaryColor : primaryColor}
                block
                onClick={() => {
                  if (isLogin) {
                    setEsignVisable(true);
                  } else {
                    dispatch.User.changeUserState({
                      modalAction: "Login",
                      isOpen: false,
                    });
                  }
                }}
              >
                {isConfirm || shareData?.contract_status === 2
                  ? "查看合同"
                  : "客户确认"}
                <Icon type="icont1_correct"></Icon>
              </PrimaryButton>
            )}
          </Space>
          <Modal
            visible={esignVisable}
            width="80%"
            onCancel={() => setEsignVisable(false)}
            style={{ top: "20px" }}
            title="订单确认"
            footer={null}
            color={isToBusiness ? bussinessPrimaryColor : primaryColor}
          >
            <ContractSign
              shareData={shareData}
              handleClose={() => {
                setIsConfirm(true);
                setEsignVisable(false);
              }}
              isToBusiness={isToBusiness}
              token={token}
            />
          </Modal>
        </Col>
      </Row>
    </div>
  );
};

export default ShareDown;
