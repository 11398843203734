import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";
import styles from "./index.scss";
import { useMount } from "ahooks";
import { GraphQLRequest } from "../../helpers/request.helper";
import Product from "./Component/Product";
import Waterfall from "../../components/waterfall";
import IdeaCard from "../../pages/IdeaList/component/Card";
import Icon from "../../components/icon";
import * as queryString from "query-string";

import { CommonProps } from "../../typings/component";
import { useIntl } from "react-intl";
import { RootState, Dispatch } from "../../store/configureStore";
import { useTheme } from "styled-components";
import { BusinessContext } from "../BusinessContext";
import Button from "../../wrappers/button";
import { Row, Col } from "antd";
import List, { Pagination } from "../../wrappers/list";

const MegaBookContent: React.FC<CommonProps> = ({ classNamePrefix = "" }) => {
  const dispatch = useDispatch<Dispatch>();
  const { formatMessage: f } = useIntl();

  const { Collections: collection = [] } = useSelector(
    (state: RootState) => state,
  );
  const {
    ProductCollect: productCollect = [],
    DesignCollect: designCollect = [],
    CollectTotal: collectTotal,
    ShopCollect: shopCollect = [],
  } = useSelector((state: RootState) => state.MegaBookPage);
  const { host_id } = useSelector((state: RootState) => state.URLSearch);
  const { isPrivate } = useSelector((state: RootState) => state.CommonData);

  const [type, setType] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [navData, setNavData] = useState<any>([]);

  const {
    primaryColor,
    secondaryColor,
    textColor,
    bussinessPrimaryColor,
  } = useTheme();
  const { isToBusiness } = useContext(BusinessContext);

  useMount(() => {
    const shop_id = queryString.parse(window.location.search).host_id;
    const data = { page: 1, shop_id, private: isPrivate };

    dispatch.MegaBookPage.setProductCollect(data);
    // 初始化tabs颜色
    handleTabsChange("0");

    dispatch.Collections.getCollectionAsync();
  });

  const handleTabsChange = (key?: string) => {
    const otherNumber: number = parseInt(key) === 0 ? 1 : 0;
    const domData = document.querySelectorAll(".mega-tabs-tab");
    const borderDom = document.querySelector(".mega-tabs-ink-bar");

    if (borderDom) {
      borderDom.style.backgroundColor = "#346C78";
      domData[parseInt(key)].style.color = "#346C78";
      domData[parseInt(otherNumber)].style.color = "#444";
    }
  };
  const handleUserLike = (
    isLike: boolean,
    productId: number,
    skuId: number,
    object: string,
    callBack?: (isLike: boolean) => void,
  ) => {
    const type = isLike ? "cancel" : "like";
    GraphQLRequest(
      "post",
      `mutation customer {
        Collect(action:"${type}", object:"${object}", data_id:"${productId}") {
          message
          status_code
          data
        }
      }`,
    ).then(() => {
      if (callBack) {
        callBack(!isLike);
      }
    });
  };

  const handleGetOtherList = (page: number, types: number) => {
    setCurrentPageNumber(page);
    const data = { page: page, shop_id: host_id };

    if (types === 1) {
      dispatch.MegaBookPage.setDesignCollect(data);
    } else {
      dispatch.MegaBookPage.setShopCollect(page);
    }
    window.scroll(0, 0);
  };

  const handleShopLike = (index: number, like: boolean, shopId: number) => {
    handleUserLike(like, shopId, 0, "shop");
    dispatch.MegaBookPage.setShopCollect(1);
  };

  const renderComponent = (type: number) => {
    switch (type) {
      case 0:
        return (
          <Product
            list={productCollect || []}
            onUserLike={handleUserLike}
            collection={collection}
          />
        );

      case 1:
        return (
          <div style={{ position: "relative" }}>
            <Waterfall itemWidth={300}>
              {designCollect?.map((item: any) => (
                <IdeaCard
                  src={item.design_idea_picture}
                  key={item.design_idea_id}
                  name={item.design_idea_name}
                  shopName={item.shop_name}
                  url={item.url}
                  designerName={item.designer_name}
                  designerAvatar={item.designer_avatar}
                  tags={item.coordinates}
                  isCollect={item.is_collect}
                  isMegaBook={true}
                  onChangeUserLike={
                    () => {
                      dispatch.MegaBookPage.changeUserLike({
                        dataId: item.design_idea_id,
                        nowtype: item.is_collect,
                        objType: "designIdea",
                      });
                    }
                    // this.props.changeUserLike(item.design_idea_id)
                  }
                  showName
                  shopUrl={item.shop_url}
                />
              ))}
            </Waterfall>

            {designCollect && designCollect.length > 0 && (
              <Row
                style={{
                  marginBottom: "15px",
                }}
                justify="end"
              >
                <Pagination
                  current={currentPageNumber}
                  total={collectTotal.total}
                  pageSize={10}
                  onChange={val => {
                    handleGetOtherList(val, 1);
                  }}
                  showSizeChanger={false}
                />
              </Row>
            )}
          </div>
        );

      case 2:
        return (
          <List
            dataSource={shopCollect || []}
            split={false}
            grid={{ gutter: 10, column: 4 }}
            renderItem={(item: any, index) => (
              <List.Item key={index}>
                <div
                  key={index}
                  className={styles["MegaBook-shop-box"]}
                  style={{
                    padding: "0px",
                    backgroundImage: `url(${item.shop_background})`,
                  }}
                >
                  <a href={`${isToBusiness ? "/tob" : ""}${item.shop_url}`}>
                    <Row>
                      <Col span={8}>{""}</Col>
                      <Col
                        span={16}
                        className={styles["MegaBook-shop-box-right"]}
                      >
                        <div className={styles["MegaBook-shop-box-right-logo"]}>
                          <img src={item.shop_logo} />
                        </div>
                        <div
                          className={styles["MegaBook-shop-box-right-slogan"]}
                        >
                          <span style={{ color: primaryColor }}>
                            {item.shop_slogan}
                          </span>
                        </div>
                        <div className={styles["MegaBook-shop-box-right-like"]}>
                          <Button
                            onClick={e => {
                              e.preventDefault();
                              dispatch.MegaBookPage.changeUserLike({
                                dataId: item.shop_id,
                                nowtype: item.like,
                                objType: "shop",
                              });
                            }}
                            icon={
                              <Icon
                                type={
                                  item.like
                                    ? "icont2_shoucang1"
                                    : "iconshoucang1"
                                }
                              />
                            }
                            shape="circle"
                            color={
                              isToBusiness
                                ? bussinessPrimaryColor
                                : primaryColor
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                  </a>
                </div>
              </List.Item>
            )}
            pagination={{
              current: currentPageNumber,
              total: collectTotal.total,
              pageSize: 10,
              showSizeChanger: false,
              onChange: val => handleGetOtherList(val, 2),
              hideOnSinglePage: true,
            }}
          />
        );

      default:
        return null;
    }
  };

  const handleGetData = (index: number) => {
    switch (index) {
      case 0:
        const data = { page: 1, shop_id: host_id, private: isPrivate };
        dispatch.MegaBookPage.setProductCollect(data);

        return;
      case 1:
        const diData = { page: 1, shop_id: host_id };
        dispatch.MegaBookPage.setDesignCollect(diData);
        return;
      case 2:
        dispatch.MegaBookPage.setShopCollect(1);
        return;
    }
  };

  const defaultNav = [
    f({ id: "Product" }),
    f({ id: "DesignIdeas" }),
    f({ id: "Shop" }),
  ];
  const disolationNav = [f({ id: "Product" }), f({ id: "DesignIdeas" })];

  useEffect(() => {
    setNavData(isPrivate && host_id ? disolationNav : defaultNav);
  }, [isPrivate, host_id]);
  return (
    <div className={styles["MegaBook-box"]}>
      <div className={styles["MegaBook-childtime-nav"]}>
        {navData?.map((item: React.ReactNode, index: number) => (
          <span
            style={{
              background: type === index ? primaryColor : secondaryColor,
            }}
            onClick={() => {
              setType(index);
              setCurrentPageNumber(1);
              handleGetData(index);
            }}
            key={index}
          >
            {item}
          </span>
        ))}
      </div>

      {renderComponent(type)}
    </div>
  );
};
export default React.memo(MegaBookContent);
