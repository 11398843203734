import { createModel } from "@rematch/core";
import produce from "immer";
import {
  GraphQLRequest,
  GraphqlMutation,
  GraphqlQuery,
  GraphqlQueryMulti,
} from "../../helpers/request.helper";
import Payment from "../../models/Payment";
import { getFirstItem } from "../../helpers/modelHelpers";
import { Cart } from "../../models/Cart";
import { Collection } from "../../models/Collection";
import Address from "../../models/Address";
import replaceState from "../../helpers/reolaceState";

export type CartPageStateType = {
  cart: Cart[];
  breadcrumbs: { url: string; title: string }[];
  collectIds: { data_id: number }[];
  quoteID: number;
  qrCodeUrl: string;
  minute: number;
  second: number;
  paytype: boolean;
  time: number;
  overdue: boolean;
  selectedAddress: Address;
};

export default createModel({
  state: {
    paytype: false,
    time: 3,
  } as CartPageStateType,
  reducers: {
    // 初始化请求数据
    INIT_STATE(
      state: CartPageStateType,
      cart: Cart[],
      collectIds: CartPageStateType["collectIds"],
    ) {
      return { ...state, cart, collectIds };
    },
    setSelectedAddress(state: CartPageStateType, selectedAddress: Address) {
      return { ...state, selectedAddress };
    },
    // 在state中写入请求回来的quote_id
    changeQuoteId(state: CartPageStateType, quoteID: number) {
      return { ...state, quoteID };
    },
    // 修改商品数量
    changeQuantity(
      state: CartPageStateType,
      payload: { indexs: number; index: number; number: number },
    ) {
      if (payload.number >= 0 && payload.number <= 9999) {
        return produce(state, draft => {
          draft.cart[payload.indexs].product[payload.index].quantity =
            payload.number;
        });
      } else {
        return state;
      }
    },
    // 存储二维码url
    INIT_QRCODE(state: CartPageStateType, qrCodeUrl: string) {
      return {
        ...state,
        qrCodeUrl,
      };
    },
    // 获取二维码url成功后开始30分钟倒计时
    ININ_TIME_INTERVAL(
      state: CartPageStateType,
      minute: number,
      second: number,
    ) {
      return {
        ...state,
        minute,
        second,
      };
    },
    // state中插入是否已支付的值
    ININ_HAVE_TO_PAY(state: CartPageStateType, paytype: boolean) {
      return { ...state, paytype };
    },
    // 60秒超时二维码变成灰的
    INIT_OVDERDUE(state: CartPageStateType, overdue: boolean) {
      return { ...state, overdue };
    },
    // 三秒倒计时跳转到订单
    INIT_LINK_TIME(state: CartPageStateType, time: number) {
      return { ...state, time };
    },
  },
  effects: {
    // 初始化请求
    async initCartDataAsync(payload, root: any) {
      const extraParams = {
        private: root.CommonData.isPrivate,
        shop_id: root.URLSearch.host_id,
      };
      const data = await GraphqlQueryMulti(
        {
          method: "Carts",
          model: new Cart({}),
          args: { ...payload, ...extraParams },
          metadataKey: "cart",
        },
        {
          method: "CollectIds",
          model: new Collection(),
          args: { collect: "product" },
        },
      );

      this.INIT_STATE(data.Carts);
    },
    async delCart(cartId: number | string) {
      const res = await GraphqlMutation("Cart", {
        action: "remove",
        store_cart_id: cartId,
      });
      this.initCartDataAsync();
    },

    async handleAddOrder(payload: any, state: any) {
      const { closeLoading, callback, type, ...rest } = payload;

      try {
        const res = await GraphqlMutation("Order", {
          action: "add",
          ...rest,
        });
        if (type === 1 && callback) {
          callback(JSON.parse(res.data).quote_id);
        }
        if (type === 2) {
          this.RESQUEST_QRCODE({
            quoteId: JSON.parse(res.data).quote_id,
            private: payload.private,
          });

          //倒计时30分钟跳转
          const timeLong = 1800,
            date = JSON.parse(res.data).expired_at.substring(0, 19),
            dates = date.replace(/-/g, "/"),
            expiredAt = new Date(dates).getTime();

          this.timeOut(expiredAt);
        }
      } catch (e) {
        closeLoading();
      }
    },
    timeOut(expiredAt) {
      const timeRef = setTimeout(() => {
        const nowTime = Date.parse(new Date());
        const minute = parseInt((expiredAt - nowTime) / 1000 / 60),
          second = parseInt(((expiredAt - nowTime) / 1000) % 60);
        this.ININ_TIME_INTERVAL(minute, second);
        if (minute == 0 && second == 0) {
          clearTimeout(timeRef);

          replaceState.linkTo("/account/orders");
        }
        this.timeOut(expiredAt);
      }, 1000);
    },
    async getZfbUrl(payload) {
      const { callback, ...rest } = payload;
      const data = new Payment({});
      const res = await GraphqlQuery("AliPay", data, rest, "AliPay");
      callback && callback(getFirstItem(res).url);
    },
    async getPayResult(payload) {
      const { callback, ...rest } = payload;
      const data = new Payment({});
      const res = await GraphqlQuery("PayResult", data, {
        action: "alipay",
        ...rest,
      });
      callback && callback(getFirstItem(res).result);
    },
    // 生成订单后获取二维码的url
    async RESQUEST_QRCODE(
      payload: { quoteId: number; private?: any },
      state: any,
    ) {
      const { data } = await GraphQLRequest(
        "post",
        `
        query pay{
          PayCode(quote_id:"${payload.quoteId}",${
          payload.private ? `private:"1"` : `private:"0"`
        }){
            url
           }
         }
        `,
      );

      if (data.data.PayCode[0].url !== "") {
        this.INIT_QRCODE(data.data.PayCode[0].url);
        this.changeQuoteId(payload.quoteId);

        // 获取到url后将到期状态改为false
        this.INIT_OVDERDUE(false);

        let remaining = 9;
        // 延时10秒请求支付结果
        setTimeout(() => {
          setInterval(() => {
            remaining = remaining - 1;
            if (remaining > 0) {
              this.RESQUEST_PAY_RESULT(payload.quoteId);
            }
          }, 5000);
        }, 5000);

        // 1分钟后过期
        setTimeout(() => {
          this.INIT_OVDERDUE(true);
        }, 60000);
      } else {
        replaceState.linkTo("/account/orders");
      }
    },
    // 支付开始展示后需要调用支付结果接口
    async RESQUEST_PAY_RESULT(quoteId: number, state: CartPageStateType) {
      if (state.cart && !state.cart.paytype) return;
      const { data } = await GraphQLRequest(
        "post",
        `
        query customer{
          PayResult(quote_id:"${quoteId}"){
            result
           }
         }
        `,
      );

      if (data.data.PayResult[0].result) {
        this.ININ_HAVE_TO_PAY(true);
        // 三秒后跳转到订单
        let time = 3;
        setInterval(() => {
          time = time - 1;
          if (time > 0) {
            this.INIT_LINK_TIME(time);
          } else {
            replaceState.linkTo("/account/orders");
          }
        }, 1000);
      }
    },
    async handleChangeOrderQuantity(payload) {
      const { callback, quantity, store_cart_id } = payload;

      try {
        const data = await GraphqlMutation("Cart", {
          store_cart_id: store_cart_id,
          quantity: quantity,
          action: "update",
        });
        callback && callback(true);
      } catch (err) {
        callback && callback(false);
      }
    },
  },
});
