import React from "react";
import styled from "styled-components";
import { Modal } from "antd";
import Icon from "../../components/icon";

const ModalStyled = styled(Modal).attrs(props => ({
  closeIcon: (
    <Icon
      type="icont1_close"
      style={{
        color: props.color ?? props.theme.primaryColor,
      }}
    />
  ),
}))<{ color?: string; bgColor?: string }>`
  .ant-modal-content {
    background: ${props => props.bgColor};
  }
  .ant-modal-header {
    background: ${props => props.bgColor};
    border-width: 0;
    border-radius: 5px 5px 0 0;
    .ant-modal-title {
      color: #666;
      font-weight: normal;
    }
  }
  .ant-modal-body {
    padding: 0;

    ${props => (props.footer ? "" : "border-radius: 0 0 5px 5px;")};
  }
  .ant-modal-footer {
    border-width: 0;
    ${props => (props.footer ? "border-radius: 0 0 5px 5px;" : "")};
  }
`;

export default ModalStyled;
