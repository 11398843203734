import React, { useEffect, useState, useContext } from "react";
import styles from "./index.scss";
import IdeaCard from "./component/Card";
import { Dispatch, RootState } from "../../store/configureStore";
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";
import { useUpdateEffect, useMount } from "ahooks";
import { FormattedMessage } from "react-intl";
import { CommonProps } from "../../typings/component";
import Icon from "../../components/icon";
import Checkbox from "../../wrappers/checkbox";
import { BusinessContext } from "../BusinessContext";

import InfiniteScroll from "react-infinite-scroller"; // 下拉加载
import debounce from "lodash/debounce";
import { Spin, Affix, Popover, Tag, Collapse } from "antd";
import { useTheme } from "styled-components";
import * as queryString from "query-string";

const { Panel } = Collapse;

const IdeasContent: React.FC<CommonProps> = ({ isMobile }) => {
  const dispatch = useDispatch<Dispatch>();

  const {
    total = 0,
    list,
    ideaLikeList = [],
    pickList,
    hasMore = false,
  } = useSelector((state: RootState) => state.IdeaList);
  const { isLogin } = useSelector((state: RootState) => state.User);
  const { setIdeaData: ideaListLoading } = useSelector(
    (state: RootState) => state.loading.effects.IdeaList,
  );
  // const handleSetIdeaData = useRequest(dispatch.IdeaList.setIdeaData);
  const [loading, setLoading] = useState(true);
  const [ideaPage, setIdeaPage] = useState(1);

  const [ideaFilters, setIdeaFilters] = useState<any>({});
  const [selectProvince, setProvince] = useState<any>();

  const maskCount = new Array(isMobile ? 2 : 10).fill(1);
  const { isToBusiness } = useContext(BusinessContext);

  const { primaryColor, bussinessPrimaryColor } = useTheme();

  const { isPrivate } = useSelector(
    (state: RootState) => state.CommonData || {},
  );
  const { host_id } = useSelector((state: RootState) => state.URLSearch);

  useMount(() => {
    const shop_id = queryString.parse(window.location.search).host_id;
    if (!total && !list) {
      dispatch.IdeaList.setIdeaData({
        page: 1,
        shop_id: isPrivate && shop_id ? shop_id : null,
      });
    } else {
      const hasMoreIdeas = 10 < total;
      dispatch.IdeaList.setHasMore(hasMoreIdeas);
    }
    dispatch.IdeaList.setIdeaLike();
    dispatch.IdeaList.setPickListAsync({
      shop_id: isPrivate && shop_id ? shop_id : null,
    });
  });

  useUpdateEffect(() => {
    const filters = getSearchString();
    const shopId = queryString.parse(window.location.search).host_id;
    const shop_id = isPrivate && shopId ? shopId : null;

    dispatch.IdeaList.setIdeaData({
      ...filters,
      page: ideaPage,
      shop_id,
    });
  }, [ideaPage, ideaFilters]);

  const getUserLike = (id: number) => {
    if (ideaLikeList.length === 0) return false;
    return ideaLikeList.some(m => m === id);
  };

  const ideaListFilters = [
    {
      key: "RecommendType",
      value: "recommend_type",
      text: "推荐类型",
      data: [
        {
          label: "最新发布",
          value: "1",
          key: "recommend_type",
          id: "1",
        },
        {
          label: "点赞最多",
          value: "2",
          key: "recommend_type",
          id: "2",
        },
      ],
    },
    {
      key: "HouseType",
      value: "house_type",
      text: "户型",
      data: pickList?.HouseType.map(t => ({
        label: t.house_type_name,
        value: t.house_type,
        key: "house_type",
        id: t.house_type,
      })),
    },
    {
      key: "HouseArea",
      value: "house_area",
      text: "面积",
      data: pickList?.HouseArea.map(t => ({
        label: t.area_name,
        value: `${t.area_low}-${t.area_high}`,
        key: "area_low",
        id: t.area_low,
      })),
    },
    {
      key: "Room",
      value: "room",
      text: "房间",
      data: pickList?.Room.map(t => ({
        label: t.room_name,
        value: t.room_id,
        key: "room_id",
        id: t.room_id,
      })),
    },
    {
      key: "Style",
      value: "style",
      text: "风格",
      data: pickList?.Style.map(t => ({
        label: t.style_name,
        value: t.style_id,
        key: "style_id",
        id: t.style_id,
      })),
    },
    {
      key: "Color",
      value: "color",
      text: "颜色",
      data: pickList?.Color.map(t => ({
        label: t.color_name,
        value: t.color_id,
        key: "color_id",
        id: t.color_id,
      })),
    },
    {
      key: "HouseCost",
      value: "house_cost",
      text: "花费",
      data: pickList?.HouseCost.map(t => ({
        label: t.cost_name,
        value: `${t.cost_low}-${t.cost_high}`,
        key: "cost_low",
        id: t.cost_low,
      })),
    },
    // {
    //   key: 7,
    //   value: "position",
    //   text: "位置",
    //   data: transform(
    //     groupBy(pickList?.Location, "province"),
    //     (prev, value, key) => {
    //       prev.push({
    //         label: key,
    //         value: key,
    //         children: value.map(v => ({ label: v.city, value: v.city })),
    //       });
    //     },
    //     [] as { label: string; value: string; children: any }[],
    //   ),
    // },
  ];

  const getCheckStatus = (
    filterProp: string,
    filterValue: string | string[],
  ) => {
    let isChecked = false;

    if (filterProp === "position") {
      if (ideaFilters.city?.some(m => m === filterValue)) {
        isChecked = true;
      }
    } else if (filterProp === "house_area") {
      const [area_low, area_high] = filterValue?.split("-") || [];

      if (
        ideaFilters.area_low?.some(m => m === area_low) &&
        ideaFilters.area_high?.some(m => m === area_high)
      ) {
        isChecked = true;
      }
    } else if (filterProp === "house_cost") {
      const [cost_low, cost_high] = filterValue?.split("-") || [];

      if (
        ideaFilters.cost_low?.some(m => Number(m) === Number(cost_low)) &&
        ideaFilters.cost_high?.some(m => Number(m) === Number(cost_high))
      ) {
        isChecked = true;
      }
    } else {
      if (ideaFilters[filterProp]?.some(m => m === filterValue)) {
        isChecked = true;
      }
    }
    return isChecked;
  };

  const handleChangeSearch = (
    filterProp: string,
    filterValue: string | string[],
    checked: boolean,
  ) => {
    setIdeaPage(1);
    if (checked) {
      if (filterProp === "position") {
        setIdeaFilters(f => ({
          ...f,
          city: ideaFilters.city
            ? [...ideaFilters.city, filterValue]
            : [filterValue],
        }));
      } else if (filterProp === "house_area") {
        const [area_low, area_high] = filterValue?.split("-") || [];

        setIdeaFilters(f => ({
          ...f,
          area_low: ideaFilters.area_low
            ? [...ideaFilters.area_low, area_low]
            : [area_low],
          area_high: ideaFilters.area_high
            ? [...ideaFilters.area_high, area_high]
            : [area_high],
        }));
      } else if (filterProp === "house_cost") {
        const [cost_low, cost_high] = filterValue?.split("-") || [];

        setIdeaFilters(f => ({
          ...f,
          cost_low: ideaFilters.cost_low
            ? [...ideaFilters.cost_low, cost_low]
            : [cost_low],
          cost_high: ideaFilters.cost_high
            ? [...ideaFilters.cost_high, cost_high]
            : [cost_high],
        }));
      } else if (filterProp === "recommend_type") {
        setIdeaFilters(f => ({
          ...f,
          recommend_type: [filterValue],
        }));
      } else {
        setIdeaFilters(f => ({
          ...f,
          [filterProp]: ideaFilters[filterProp]
            ? [...ideaFilters[filterProp], filterValue]
            : [filterValue],
        }));
      }
    } else {
      if (filterProp === "position") {
        setIdeaFilters(f => ({
          ...f,
          city: ideaFilters.city.filter((m: any) => m !== filterValue),
        }));
      } else if (filterProp === "house_area") {
        const [area_low, area_high] = filterValue?.split("-") || [];

        setIdeaFilters(f => ({
          ...f,
          area_low: ideaFilters.area_low.filter(
            (m: any) => Number(m) !== Number(area_low),
          ),
          area_high: ideaFilters.area_high.filter(
            (m: any) => Number(m) !== Number(area_high),
          ),
        }));
      } else if (filterProp === "house_cost") {
        const [cost_low, cost_high] = filterValue?.split("-") || [];
        setIdeaFilters(f => ({
          ...f,
          cost_low: ideaFilters.cost_low.filter(
            (m: any) => Number(m) !== Number(cost_low),
          ),
          cost_high: ideaFilters.cost_high.filter(
            (m: any) => Number(m) !== Number(cost_high),
          ),
        }));
      } else {
        setIdeaFilters(f => ({
          ...f,
          [filterProp]: ideaFilters[filterProp].filter(
            (m: any) => m !== filterValue,
          ),
        }));
      }
    }
  };

  const getSearchString = () => {
    let searchStringData = {
      publish: "",
      color: "",
      style: "",
      room: "",
      house_type: "",
      cost_low: "",
      cost_high: "",
      area_low: "",
      area_high: "",
      city: "",
    };
    Object.keys(ideaFilters).forEach(function (key) {
      searchStringData[key] = ideaFilters[key].join("#");
    });

    if (searchStringData.cost_low !== "") {
      const newCostLow = searchStringData.cost_low
        .split("#")
        .map(m => {
          return Number(m);
        })
        .sort(function (a, b) {
          return a - b;
        });
      searchStringData.cost_low = String(newCostLow[0]);
    }

    if (searchStringData.cost_high !== "") {
      const newCostLow = searchStringData.cost_high
        .split("#")
        .map(m => {
          return Number(m);
        })
        .sort(function (a, b) {
          return b - a;
        });
      searchStringData.cost_high = String(newCostLow[0]);
    }

    if (searchStringData.area_low !== "") {
      const newCostLow = searchStringData.area_low
        .split("#")
        .map(m => {
          return Number(m);
        })
        .sort(function (a, b) {
          return a - b;
        });
      searchStringData.area_low = String(newCostLow[0]);
    }

    if (searchStringData.area_high !== "") {
      const newCostLow = searchStringData.area_high
        .split("#")
        .map(m => {
          return Number(m);
        })
        .sort(function (a, b) {
          return b - a;
        });
      searchStringData.area_high = String(newCostLow[0]);
    }
    return searchStringData;
  };

  const getTagDataList = () => {
    const newTagData = {};
    Object.keys(ideaFilters).forEach(function (key) {
      let newFilter;
      if (
        key !== "area_low" &&
        key !== "area_high" &&
        key !== "cost_low" &&
        key !== "cost_high"
      ) {
        if (!ideaFilters[key] || ideaFilters[key].length === 0) {
          newTagData[key] = [];
        } else {
          newFilter = ideaFilters[key].map(item => {
            ideaListFilters.forEach(m => {
              if (m.value === key) {
                m?.data.forEach(z => {
                  if (z.id === item) {
                    item = z.label;
                  }
                });
              }
            });

            return item;
          });
          newTagData[key] = newFilter;
        }
      } else {
        newTagData[key] = ideaFilters[key];
      }
    });
    return newTagData;
  };

  const getTagData = (data: any) => {
    if (!data) return {};
    if (data.area_low && data.area_low.length > 0) {
      data.area = [];
      data.area.push("" + data.area_low + "-" + data.area_high);
      delete data.area_low;
      delete data.area_high;
    }

    if (data.cost_low && data.cost_low.length > 0) {
      data.cost = [];
      data.cost.push("" + data.cost_low + "-" + data.cost_high);
      delete data.cost_low;
      delete data.cost_high;
    }
    return data;
  };

  const getSearchData = () => {
    const tagData = getTagData(getTagDataList());

    let tagValue: any = [];
    Object.keys(tagData).forEach(function (key) {
      if (key === "area" && tagData[key].length !== 0) {
        const arr = getTangeData(tagData[key][0].split("-"));
        const newString = "" + arr[0] + "m²-" + arr[1] + "m²";
        tagValue = [
          ...tagValue,
          {
            data: newString,
            key: "area",
          },
        ];
      } else if (key === "cost" && tagData[key].length !== 0) {
        const arr = getTangeData(tagData[key][0].split("-"));
        const newString = "¥" + arr[0] + "-¥" + arr[1];
        tagValue = [
          ...tagValue,
          {
            data: newString,
            key: "cost",
          },
        ];
      } else {
        tagValue = [
          ...tagValue,
          {
            data: tagData[key].join(","),
            key: key,
          },
        ];
      }
    });
    return (
      <>
        {tagValue.map((item: any) => {
          if (item.data !== "") {
            return (
              <Tag
                style={{
                  background: primaryColor,
                  color: "white",
                  margin: "0px 5px",
                  padding: "0 10px",
                  border: primaryColor,
                  fontSize: "0.8rem",
                }}
              >
                {item.data}

                <span className={styles["ideas-title-close"]}>
                  <Icon
                    type="icont1_close"
                    style={{
                      cursor: "pointer",
                      fontSize: "0.8rem",
                    }}
                    onClick={() => handleCencalSearch(item.key)}
                  ></Icon>
                </span>
              </Tag>
            );
          } else {
            return <></>;
          }
        })}
      </>
    );
  };

  const getTangeData = (data: any) => {
    const newArr = data.map(item => {
      const newItem = item.split(",");
      return newItem;
    });
    const newData = flatten(newArr)
      .map(m => {
        return Number(m);
      })
      .sort(function (a, b) {
        return a - b;
      });
    const showData = [newData[0], newData[newData.length - 1]];
    return showData;
  };

  const flatten = (arr: any) => {
    return [].concat(...arr.map(x => (Array.isArray(x) ? flatten(x) : x)));
  };

  const handleCencalSearch = (deleKey: string) => {
    let low = deleKey,
      high = deleKey;

    if (deleKey === "area") {
      low = "area_low";
      high = "area_high";
    }
    if (deleKey === "cost") {
      low = "cost_low";
      high = "cost_high";
    }
    setIdeaPage(1);
    setIdeaFilters((x: any) => {
      return { ...x, [low]: [], [high]: [] };
    });
  };

  useEffect(() => {
    if (!list || list.length === 0) return;
    advanceWidth();
  }, [list]);
  const advanceWidth = async () => {
    const elem = document.querySelector(".pages_hoc");
    const Masonry = (await import("masonry-layout")).default; // 实现瀑布流
    // tslint:disable-next-line:no-unused-expression
    const msnry = new Masonry(elem, {
      itemSelector: ".imgBox", // 要布局的网格元素
      columnWidth: ".imgBox", // 自适应
      fitWidth: true, // 设置网格容器宽度等于网格宽度
      gutter: 10,
      resize: true,
    });
  };

  const panelStyle: React.CSSProperties = {
    backgroundColor: "#fff",
    fontSize: "1.2em",
    marginBottom: "13px",
    borderRadius: "5px",
  };

  return (
    <div className={classnames(styles["ideas"])}>
      <p className={styles["ideas-title"]}>
        <b className={styles["ideas-title-fontWeight"]}>
          <FormattedMessage id="DesignIdeas" />
        </b>
        <span className={classnames({ [styles["mobileSpan"]]: isMobile })}>
          <FormattedMessage id="NumIdeas" values={{ num: total }} />
        </span>
        {getSearchData()}
      </p>

      <div className={classnames(styles["ideas-content"])}>
        <div className={classnames(styles["ideas-content-left"])}>
          <Affix offsetTop={85} offsetBottom={10}>
            <Collapse
              expandIconPosition="right"
              expandIcon={panelProps => (
                <Icon
                  type={panelProps.isActive ? "icont1_jian" : "icont1_jia"}
                  style={{ color: "#c6bdb9", fontSize: "1em" }}
                />
              )}
              ghost
            >
              {ideaListFilters.map((item, index) => (
                <Panel
                  header={item.text}
                  key={item.value}
                  forceRender={true}
                  style={panelStyle}
                >
                  {item.value !== "position" &&
                    item.data?.map(m => (
                      <div style={{ marginBottom: 10 }}>
                        <Checkbox
                          shape="circle"
                          borderWidth="2px"
                          color={
                            isToBusiness ? bussinessPrimaryColor : primaryColor
                          }
                          onChange={e => {
                            handleChangeSearch(
                              item.value,
                              m.value,
                              e.target.checked,
                            );
                          }}
                          checked={getCheckStatus(item.value, m.value)}
                        >
                          {m.label}
                        </Checkbox>
                      </div>
                    ))}

                  {item.value === "position" && (
                    <Popover
                      getPopupContainer={triggerNode =>
                        triggerNode.parentElement!
                      }
                      content={
                        <>
                          {item.data
                            .filter(z => z.value === selectProvince)[0]
                            ?.children.map(m => (
                              <div style={{ marginBottom: 10 }}>
                                <Checkbox
                                  shape="circle"
                                  borderWidth="2px"
                                  onChange={e => {
                                    handleChangeSearch(
                                      item.value,
                                      m.value,
                                      e.target.checked,
                                    );
                                  }}
                                  checked={getCheckStatus(item.value, m.value)}
                                >
                                  {m.label}
                                </Checkbox>
                              </div>
                            ))}
                        </>
                      }
                      trigger="click"
                    >
                      {item.data.map(m => {
                        return (
                          <Tag
                            onClick={e => {
                              e.preventDefault();
                              setProvince(m.value);
                            }}
                            style={{
                              backgroundColor:
                                selectProvince === m.value
                                  ? primaryColor
                                  : "white",
                              color:
                                selectProvince === m.value
                                  ? "white"
                                  : primaryColor,
                              border:
                                selectProvince === m.value
                                  ? ""
                                  : "1px solid " + primaryColor,
                              cursor: "pointer",
                              boxSizing: "border-box",
                            }}
                            checked={getCheckStatus(item.value, m.value)}
                          >
                            {m.value}
                          </Tag>
                        );
                      })}
                    </Popover>
                  )}
                </Panel>
              ))}
            </Collapse>
          </Affix>
        </div>

        <div className={classnames(styles["ideas-content-right"])}>
          <InfiniteScroll
            initialLoad={false} // 不让它进入直接加载
            // pageStart={1} // 设置初始化请求的页数
            loadMore={debounce(() => {
              if (ideaListLoading || !hasMore) return;
              setIdeaPage(p => p + 1);
            }, 1000)}
            hasMore={!ideaListLoading && hasMore} // 是否继续监听滚动事件 true 监听 | false 不再监听
            // useWindow={true} // 不监听 window 滚动条
          >
            <Spin spinning={!!ideaListLoading}>
              <div
                style={{
                  height: "100%",
                  margin: "0 auto",
                }}
                className="pages_hoc"
              >
                {list &&
                  list.map(item => (
                    <IdeaCard
                      showName={isMobile}
                      likeNum={item.like_num}
                      showSwitch={isMobile}
                      isMobile={isMobile}
                      src={item.design_idea_picture}
                      key={item.design_idea_id}
                      name={item.design_idea_name}
                      shopName={item.shop_name}
                      url={item.url}
                      tags={item.coordinates}
                      designerName={item.designer_name}
                      designerAvatar={item.designer_avatar}
                      isCollect={item.is_collect}
                      isLike={getUserLike(item.design_idea_id)}
                      description={item.design_idea_description}
                      onChangeUserLike={() => {
                        if (isLogin) {
                          dispatch.IdeaList.changeCollect({
                            ideaId: item.design_idea_id,
                            isLike: item.is_collect,
                          });
                        } else {
                          dispatch.User.changeUserState({
                            modalAction: "Login",
                            isOpen: false,
                          });
                        }
                      }}
                      onChangeLikeIdea={() => {
                        if (isLogin) {
                          dispatch.IdeaList.changeLike({
                            ideaId: item.design_idea_id,
                            isLike: getUserLike(item.design_idea_id),
                          });
                        } else {
                          dispatch.User.changeUserState({
                            modalAction: "Login",
                            isOpen: false,
                          });
                        }
                      }}
                      shopUrl={item.shop_url}
                      htmlMounted={() => {
                        if (loading) {
                          setLoading(false);
                        }
                      }}
                    />
                  ))}
              </div>
            </Spin>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default React.memo(IdeasContent);
