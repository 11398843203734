import BaseModel from "./BaseModel";
import { GraphqlField, GraphqlRefField } from "../helpers/decorators";
import { File } from "./Common";

export default class AfterSaleInfo extends BaseModel {
  public order_product_id: string;

  @GraphqlField()
  public after_sale_id: number;
  @GraphqlField()
  public after_sale_type: number;
  @GraphqlField()
  public after_sale_type_text: string;
  @GraphqlField()
  public after_status: number;
  @GraphqlField()
  public after_status_text: string;
  @GraphqlField()
  public after_sale_reason: string;
  @GraphqlField()
  public after_sale_amount: string;
  @GraphqlField()
  public after_sale_amount_text: string;
  @GraphqlField()
  public return_order_id: number;
  @GraphqlField()
  public remark: string;
  @GraphqlField()
  public quantity: number;
  @GraphqlField()
  public shipping_method: string;
  @GraphqlField()
  public refund_type_text: string;
  @GraphqlField()
  public merchant_recipient_name: string;
  @GraphqlField()
  public merchant_recipient_address: string;
  @GraphqlField()
  public merchant_recipient_telephone: string;
  @GraphqlField()
  public logistics_type: string;
  @GraphqlField()
  public logistics_express_number: string;
  @GraphqlField()
  public logistics_express_company: string;
  @GraphqlField()
  public logistics_express_company_code: string;
  @GraphqlField()
  public pickup_contact_telephone: string;
  @GraphqlField()
  public pickup_contact: string;
  @GraphqlField()
  public pickup_contact_address: string;
  @GraphqlField()
  public return_status: number;
  @GraphqlField()
  public return_status_text: string;
  @GraphqlField()
  public after_sale_number: string;
  @GraphqlField()
  public audit_status: string;
  @GraphqlField()
  public audit_status_text: string;
  @GraphqlRefField(File)
  public after_sale_files: File[] = [];

  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}
