import React, { FC, useContext, useState, useEffect } from "react";

import { BusinessContext } from "../../BusinessContext";
import Image from "../../../components/image";
import Icon from "../../../components/icon";

import Card, { FloatLayer } from "../../../wrappers/card";
import Rate from "../../../wrappers/rate";
import Button from "../../../wrappers/button";
import { Text, Link } from "../../../wrappers/typography";

import { FormattedMessage, useIntl } from "react-intl";
import { Product, Sku, ProductDetail } from "../../../models/Product";

import { Row, Col, Avatar, Space } from "antd";
import { useTheme } from "styled-components";
import styles from "./index.scss";
import take from "lodash/take";

import reolaceState from "../../../helpers/reolaceState";

type Props = Sku &
  Product & {
    mobile: boolean;
    isLogin: boolean;
    isSearch?: boolean;
    isProvider?: boolean;
    collection: number[];
    productDetails: ProductDetail;
    onChangeUserLike: (payload: {
      shop_product_id: string;
      isLike: boolean;
    }) => void;
    layout: string;
    noBorder: boolean;
  };

const ProductListCard: FC<Props> = ({
  product: products,
  mobile,
  isLogin,
  collection = [],
  onChangeUserLike,
  is_new = 0,
  is_discount = 0,
  product_name,
  manufacture_name_en,
  original_price,
  original_price_value,
  tag_price,
  tag_price_value,
  customer_rating,
  shop_name,
  isSearch,
  brand_name,
  series_name,
  media_list,
  url,
  shop_url,
  default_product_id,
  layout = "grid",
  productDetails = {},
  product_picture,
  has_tag_price_range,
  shop_product_id,
  shop_sku_id,
  sku_type,
  isProvider,
  noBorder,
  is_active,
  activity_type,
  activity_type_text,
}) => {
  const { formatMessage: f } = useIntl();
  const {
    bussinessPrimaryColor,
    primaryColor,
    borderColor,
    secondaryColor,
    linkColor,
  } = useTheme();
  const { isToBusiness } = useContext(BusinessContext);

  const [pid, setPid] = useState(0);

  useEffect(() => {
    setPid(default_product_id);
  }, [default_product_id]);

  const product: Product[] =
    media_list?.map(m => ({ ...m, shop_product_id: m.product_media_id })) ||
    products?.map(m => ({ ...m, media_url: m.product_picture }));

  const media = product.find(m => m.shop_product_id == pid);

  const handleClickMedia = (e: React.SyntheticEvent, item: Product) => {
    e.stopPropagation();
    e.preventDefault();
    setPid(isSearch ? item.product_media_id : item.shop_product_id);
  };

  const isCollection = collection.includes(Number(shop_product_id || pid));

  const rating = ((media?.customer_rating || customer_rating) * 5) / 100;

  const tagPrice = media?.tag_price || tag_price;

  const originalPrice = media?.original_price || original_price;

  const originalPriceValue =
    media?.has_tag_price_range || has_tag_price_range
      ? ""
      : originalPrice > tagPrice
      ? media?.original_price_value || original_price_value
      : "";

  const partMedias = take(product, 3);
  const CardCover = () => (
    <Image src={media?.media_url || product_picture} width={1} height={1}>
      {Boolean(media?.activity_type) || activity_type ? (
        <div
          className={styles["product-card_activity"]}
          style={{ background: primaryColor }}
        >
          {media?.activity_type_text ?? activity_type_text}
        </div>
      ) : (
        <FloatLayer $position="top" style={{ margin: "10px" }}>
          <Space size="small">
            {Boolean(is_discount) && (
              <Avatar
                style={{
                  background: isToBusiness
                    ? bussinessPrimaryColor
                    : secondaryColor,
                }}
              >
                <FormattedMessage id="Discount"></FormattedMessage>
              </Avatar>
            )}
            {Boolean(is_new) && (
              <Avatar
                style={{
                  background: isToBusiness
                    ? bussinessPrimaryColor
                    : secondaryColor,
                }}
              >
                <FormattedMessage id="New"></FormattedMessage>
              </Avatar>
            )}
          </Space>
        </FloatLayer>
      )}
      {isLogin && (
        <FloatLayer
          $position="top"
          $hoverable
          style={{ margin: "10px", right: 0 }}
        >
          {sku_type === 1 && (
            <Button
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                onChangeUserLike({
                  shop_product_id: shop_product_id || pid,
                  isLike: isCollection,
                });
              }}
              icon={
                <Icon
                  type={isCollection ? "icont2_shoucang1" : "iconshoucang1"}
                />
              }
              shape="circle"
              color={isToBusiness ? bussinessPrimaryColor : primaryColor}
            />
          )}
        </FloatLayer>
      )}

      {layout !== "list" && (
        <FloatLayer
          $position="middle"
          $stretchX
          $hoverable
          className={styles["product-card-price"]}
        >
          <Space size={5}>
            <Text size="20px">{media?.tag_price_value || tag_price_value}</Text>

            <Text delete color={linkColor}>
              {originalPriceValue}
            </Text>
          </Space>
        </FloatLayer>
      )}

      <FloatLayer
        $position="bottom"
        $hoverable
        $stretchX
        className={styles["product-card-media"]}
      >
        <Space size={5}>
          {partMedias.map(item => (
            <a
              key={item.shop_product_id}
              onClick={e => handleClickMedia(e, item)}
            >
              <Avatar
                size={45}
                shape={"square"}
                src={item.media_url}
                style={{
                  borderColor: item.shop_product_id == pid ? primaryColor : "",
                }}
                className={styles["product-card-media-avatar"]}
              />
            </a>
          ))}
          {product.length > 3 && (
            <div style={{ padding: "7px" }}>
              <a href={url}>+{product.length - 3}</a>
            </div>
          )}
        </Space>
      </FloatLayer>
    </Image>
  );

  return layout === "list" ? (
    <Card
      style={{
        borderColor,
      }}
      bodyStyle={{ padding: 0 }}
    >
      <Row>
        <Col span={6}>
          <a
            href={url}
            target="_blank"
            onClick={e => {
              e.preventDefault();
              reolaceState.linkTo(url, "_blank");
            }}
          >
            <CardCover />
          </a>
        </Col>
        <Col
          span={6}
          style={{
            padding: "15px",
            display: "flex",
            flexFlow: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            {media?.product_name || product_name}
            <p>
              <Link
                href={`${isToBusiness ? "/tob" : ""}${shop_url}`}
                target="_blank"
                onClick={e => {
                  e.preventDefault();
                  reolaceState.linkTo(
                    `${isToBusiness ? "/tob" : ""}${shop_url}`,
                    "_target",
                  );
                }}
              >
                {shop_name || media?.shop_name}
              </Link>
            </p>
            <p>{series_name || media?.series_name}</p>
            <Rate
              value={rating}
              // defaultColor="#c8c8c8"
              color={
                isToBusiness || sku_type === 2
                  ? bussinessPrimaryColor
                  : primaryColor
              }
              disabled
              allowHalf
            />
          </div>
          <Space size={5}>
            <Text size="20px">{media?.tag_price_value || tag_price_value}</Text>

            <Text color={linkColor} delete>
              {originalPriceValue}
            </Text>
          </Space>
        </Col>
        <Col span={6} style={{ padding: "15px" }}>
          {productDetails &&
            productDetails.specification &&
            productDetails.specification.length !== 0 && (
              <div>
                <span>
                  {f({
                    id: "Specifications",
                  })}
                </span>
                <ul style={{ padding: "16px" }}>
                  {(productDetails.specification.length > 5
                    ? productDetails.specification.slice(0, 5)
                    : productDetails.specification
                  ).map((item, index) => (
                    <li key={index}>
                      {item.title}: {item.value}
                    </li>
                  ))}
                </ul>
              </div>
            )}
        </Col>
      </Row>
    </Card>
  ) : (
    <Card
      $hidePlaceholder
      $hoverableBody
      bordered={false}
      cover={
        <a
          href={url}
          target="_blank"
          style={{ border: noBorder ? "" : `1px solid ${borderColor}` }}
          onClick={e => {
            e.preventDefault();
            __ENV__ === "development"
              ? reolaceState.linkTo("/product-details", "_blank", {
                  sid: shop_sku_id,
                  pid: shop_product_id,
                })
              : reolaceState.linkTo(url, "_blank");
          }}
        >
          <CardCover />
        </a>
      }
      bodyStyle={{
        opacity: 0.8,
        backgroundColor: "#eaeaea",
        padding: "10px 5px",
      }}
    >
      <Card.Meta
        title={media?.product_name || product_name}
        description={
          <>
            <Space size={5}>
              <Link
                href={`${isToBusiness ? "/tob" : ""}${shop_url}`}
                target="_blank"
                onClick={e => {
                  e.preventDefault();
                  reolaceState.linkTo(
                    `${isToBusiness ? "/tob" : ""}${shop_url}`,
                    "_target",
                  );
                }}
              >
                {shop_name}
              </Link>

              <span> {series_name}</span>
            </Space>
            <Rate
              value={rating}
              zeroColor="#c8c8c8"
              color={
                isToBusiness || sku_type === 2
                  ? bussinessPrimaryColor
                  : primaryColor
              }
              disabled
              allowHalf
            />
          </>
        }
      ></Card.Meta>
      {/* {is_active === null && <div className={styles["Card-mask"]} />} */}
    </Card>
  );
};

export default React.memo(ProductListCard);
