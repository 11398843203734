import { Dayjs } from "dayjs";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";
import styled from "styled-components";

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

const StyledDatePicker = styled(DatePicker)<{ borderRadius?: string }>`
  border-radius: ${props => props.borderRadius ?? "5px"};
`;

export default StyledDatePicker;
