export default {
  Messages: "뉴스",
  CurrentOrder: "현재 주문",
  OrderHistory: "주문 내역",
  Review: "리뷰",
  YouDoNotHaveAnyMessageToShow: "표시 할 메시지가 없습니다.",
  YouHaveNotPLaceAnyOrderYetPeriod: "아직 주문하지 않습니다.",
  ViewOrderDetails: "주문 내역보기",
  ThereIsNoHistoricalOrder:
    "아직 주문을하지 않았습니다. 과거 주문이 없습니다. 가서 올바른 제품을 선택하십시오!",
  ToSpeedUptheCheckOut:
    "결제 속도를 높이려면 다른 주소를 추가하려면 ‘새 주소 추가’를 클릭하고 양식을 작성한 다음 ‘저장’버튼을 클릭하십시오.기존 주소를 편집하거나 삭제하려면 기존 주소에 해당하는 링크를 클릭하십시오.",
  More: "더",
  Binding: "제본",
  BindingReferenceCode: "바인딩 참조 코드",
  ChangePassword: "비밀번호 변경",
  ReferenceCodeColon: "참조 코드 :",
  PersonalInformation: "개인 정보",
  FirstName: "이름",
  LastName: "성",
  Gender: "성별",
  DateOfBirth: "생년월일",
  Email: "이메일",
  CellphoneNumber: "핸드폰 번호",
  CurrentPassword: "현재 비밀번호",
  NewPassword: "새 비밀번호",
  ConfirmPassword: "비밀번호 확인",
  Password: "암호",
  PleaseInputValidDateWithFormat:
    "‘XXXX-XX-XX’형식으로 유효한 날짜를 입력하십시오",
  PasswordDoesNotMatch: "비밀번호가 일치하지 않습니다",
  BindingSuccessed: "바인딩 성공",
  UnbindingSuccessed: "무한한 성공",
  TheReferenceCodeReachesTheUpperLimitOf: "참조 코드는 20의 상한에 도달합니다.",
  ReferenceCodeHasBeenBound: "참조 코드가 바인딩되었습니다. 다시 시도하십시오.",
  InvalidReferenceCodePeriodPleaseEnterValidNumber:
    "유효하지 않은 참조 코드. 유효한 숫자와 문자를 입력하십시오.",
  Name: "이름",
  Address: "주소",
  PhoneNumber: "전화 번호",
  Alias: "별명",
  Organization: "회사 명",
  PaymentMethod: "결제 방법",
  TotalInToal: "총 {total}",
  OrderDetails: "주문 정보",
  ConfirmTheOrder: "주문 확인",
  PlseaseConfirmYourOrder: "주문을 확인하십시오.",
  CancelTheOrder: "주문 취소",
  AreyouConfirmToCancelYourOrder: "주문을 취소 하시겠습니까?",
  YouCanAddUpToTwentyAddress:
    "최대 20 개의 주소를 추가 할 수 있습니다. {num} 주소를 추가했습니다",
  AddYourAddressByClicking: "새 주소 추가'를 클릭하여 주소를 추가하십시오",
  AddingShippingAddress: "배송지 주소 추가",
  LineOne: "주소 : 첫 줄",
  LineTwo: "주소 : 두 번째 줄",
  City: "시티",
  StateSlashProvince: "주",
  PostalCode: "우편 번호",
  Country: "국가",
  CountryCode: "국가 코드",
  Note: "메시지를 남겨주세요",
  CouponSlashReferenceCode: "쿠폰 / 참조 코드",
  EnterEmailAddress: "이메일 주소를 입력하십시오",
  OrderStatus: "주문 상태",
  ConsigneeInformation: "수취인 정보",
  PaymentInformaton: "결제 정보",
  Quotation: "가격 견적",
  ReviewProduct: "제품 검토",
  TotalPrice: "합계 {가격}",
  PriceSlashItem: "{가격} / 조각",
  AssemblyManualLeftParenthesisComingSoonRightParenthesis:
    "조립 설명서 (출시 예정)",
  CustomerService: "고객 서비스",
  WelcomeBack: "다시 오신 것을 환영합니다",
  SignUp: "가입하기",
  EmailVerification: "이메일 확인",
  ForgotPasswordDashStepTwo: "비밀번호 분실-2 단계",
  ForgotPasswordDashStepOne: "비밀번호 분실-1 단계",
  ResetPassword: "암호를 재설정",
  ForgotPassword: "비밀번호를 잊으 셨나요",
  InvalidEmailOrPassword: "잘못된 이메일 또는 비밀번호",
  WelcomeToMegaHome: "MegaHome에 오신 것을 환영합니다",
  AlreadyHaveAnAccountQuestionMark: "이미 계정이 있습니까?",
  PleaseLogin: "로그인 해주세요",
  EnterThePassword: "비밀번호를 입력하십시오",
  Industry: "산업",
  PleaseCheckYourEmail: "이메일을 확인하십시오",
  IfYouDoNotReciveTheEmail:
    "몇 분 안에 이메일을받지 못하면 스팸 폴더를 확인하십시오.",

  IHaveReadAndAcceptedMegahomeTermsOfService:
    "<a>MegaHome 서비스 약관</a> 을 읽고 동의했습니다.",
  WeHaveSentYouAConfirmationEmailEmail:
    "‘<span>{email}</span> ’(으)로 확인 이메일을 보냈습니다. 이메일 박스를 확인하고 계정을 확인하십시오.",
  WeHaveSentYouAResetLink:
    "이메일 ‘<span>{email}</span> ’을 통해 재설정 링크를 보냈습니다. 이메일 상자를 확인하고 재설정 링크를 클릭하십시오.",
  DidNotRevicedEmailQuestionMark: "이메일을받지 못했습니까?",
  ThisEmailAddressHasAlreadyBeenRegistered:
    "이 이메일 주소는 이미 등록되어 있습니다. 로그인하십시오.",

  BackToMainMenu: "메인 메뉴로 돌아 가기",
  Back: "다시",
  Homepage: "홈페이지",
  ComingSoon: "출시 예정",
  ShopTheBrand: "매장 브랜드",
  Account: "계정",

  SignOut: "로그 아웃",
  SendAgain: "다시 보내기",
  BackToHomepage: "홈페이지로 돌아 가기",
  TermOfService: "서비스 약관",
  NextStep: "다음 단계",
  MarketplaceHome: "홈페이지",
  Category: "범주",
  Room: "방",
  NotAvailable: "철회",
  ToReview: "리뷰 쓰기",
  ByUsingThisSiteYouAgreeToThe:
    "이 사이트를 사용함으로써 귀하는 분석, 개인화 된 컨텐츠 및 광고를위한 쿠키 사용에 동의하게됩니다.",
  FindOutMore: "더 찾아 봐",
  Metric: "미터법",
  Imperial: "임페리얼",
  Home: "집",
  Firm: "상사",
  ReadAll: "모든 읽은 상태로 표시",
  DeleteAll: "모두 삭제",
  AddNewAddress: "새로운 주소를 추가",
  Confirm: "확인",
  Cancel: "취소",
  SetAsDefault: "기본값으로 설정",
  Default: "기본",
  Required: "필수",
  TleLengthOfTheInputExceedNumCharacters:
    "입력 길이는 {num} 자를 초과 할 수 없습니다",
  TheLengthOfTheInputMustBeAtLeast: "입력 길이는 {num} 자 이상이어야합니다",
  PleaseEnterAValidEmailAddressExclamationMark:
    "유효한 이메일 주소를 입력하십시오!",
  Loading: "로드 중",
  Male: "남성",
  Female: "여성",
  Other: "기타",
  ByShopName: "부터",
  Odm: "ODM",
  Oem: "OEM",
  Customization: "커스터마이징",
  GetThePriceCommaSpecificationsCommaShipping:
    "가격, 사양, 배송 및 추가 정보를 무료로 받으십시오!",
  SignUpNow: "지금 등록하세요",
  Quantity: "수량",
  Submit: "제출하다",
  Collection: "좋아하는",

  AddToCart: "장바구니에 담기",
  PleaseSelectOptions: "옵션을 선택하십시오",
  ContinueToCart: "장바구니에 들어간다",
  DisabledPeople: "비활성화",
  OopsCommaUploadFileExceedsMaximunSize:
    "업로드 한 파일이 10M을 초과합니다. 다시 선택하십시오.",
  OopsCommaUploadFileIsNotAValidImage:
    "업로드 한 파일이 유효한 사진 형식이 아니며 JPG 및 PNG 파일 만 사용할 수 있습니다. 다시 업로드하십시오.",
  Cart: "카트",
  LeftParenthesisPricePerItemRigthParenthesis: "(항목 당 {price})",
  AddedToCart: "장바구니에 추가했습니다",
  Specifications: "사양",
  Shipping: "배송 정보",
  Price: "가격",
  PrintLabel: "라벨 인쇄",
  PrintQrCode: "QR 코드를 인쇄",
  YouCanSave: "당신은 저장할 수 있습니다",
  Product: "상품",
  ToDo: "운영",
  Total: "합계",
  ContactUs: "문의",
  Quote: "문의",
  RemoveFromCart: "장바구니에서 삭제되었습니다",
  SelectAll: "모두 선택",
  SignIn: "로그인",
  ContinueShopping: "계속 쇼핑",
  Login: "로그인",

  SectionsInMegahomeMarketplace:
    "<a href='/termsofservice/#disclaimer'>MegaHome Marketplace</a> 카테고리에서",
  ItemsInMegahomeMarketplace:
    "<a href='/termsofservice/#disclaimer'>MegaHome Marketplace</a> 의 제품",
  MegaHomeRecommended: "MegaHome추천",
  Viewed: "찾아보기 시간",
  Size: "사이즈",
  Material: "재료",
  Color: "색깔",
  Style: "스타일",
  MoreFilters: "더 많은 필터",
  Clear: "명확한",
  CountResults: "{count} 결과",
  NewArrivals: "신제품",
  Selected: "선택된",
  DashDashNoPreferenceDashDash: "-- 선택하세요 --",
  DesignIdeas: "공동의 아이디어",
  NumIdeas: " {num} 디자인 아이디어",
  Hide: "숨기기",
  Show: "표시",
  ConnectWithUs: "우리와 연결",
  MegaHomeUsAddress: "39055 Hastings Street, Suite 208 Fremont, CA 94538",
  WeHaveRevicedYourFeedback:
    "귀하의 의견을 받았습니다. 최대한 빨리 연락 드리겠습니다.",
  SendAMessage: "메시지 보내기",
  TellUsAboutYourNeeds: "요구를보고하십시오",
  Inquire: "상담",
  Feedback: "피드백",
  Partnership: "협력 관계",
  ItHelp: "기술 지원",
  Others: "기타",
  HotelVendor: "호텔 공급 업체",
  Retailers: "소매점",
  Designers: "디자이너",
  Suppliers: "공급 업체",
  About: "정보",
  AboutUs: "우리 관해서는",
  Join: "우리와 함께",
  Marketing: "마케팅",
  Disclaimer: "면책 조항",
  Apply: "적용",
  Return: "취소",
  LookingForMoreStylesQuestionMark: "더 많은 스타일을 찾고 계십니까?",
  FindMore: "더 찾기",
  Share: "내 생각을 공유",
  AddToCartSuccessfullyExclamationMark: "장바구니에 추가했습니다!",
  ProductDetails: "제품 세부 정보",
  Description: "기술",
  WarningColonLeftBracketCaliforniaPropositionSixtyFive:
    "경고 : [캘리포니아 법안 65] 제품 안전에 대해서는 공급 업체에 확인하십시오.",
  Features: "풍모",
  ProductFeatures: "제품 특징",
  ComponentsData: "구성 요소 데이터",
  Compare: "비교",
  Reviews: "리뷰",
  SimilarProducts: "유사한 제품",
  BuyTogether: "함께 구매",
  ComparewithSimilarPorducts: "유사한 제품과 비교",
  CustomerRating: "고객 평가",
  SortByMostRecently: "가장 최근에 정렬",
  SortByRating: "등급으로 정렬",
  ReviewProductFromOrder: "주문에서 제품 검토",
  PleaseReviewOurProductsComma:
    "당사 제품, 귀하의 경험을 검토하고 다른 사용자와 공유하십시오.",
  AsteriskSignYouDoNotHaveFinishedOrder: "* 검토 할 주문 제품이 없습니다.",
  MoreReviews: "더 많은 리뷰",
  MadeInCountry: "{country}에서 제작",
  PriceIncluded: "가격 포함",
  MinPeriod: "최저",
  MaxPeriod: "최고",
  MyShopingCart: "내 장바구니",
  CountItems: "{count} 아이템",
  PleaseConfirm: "확인 해주세요",
  AreYouConfirmToRemoveTheseItems: "장바구니에서이 항목을 제거 하시겠습니까?",
  YouHaveItemAddedWithDifferentCurrencyType:
    "항목 유형이 다른 통화가 추가되었습니다. 인용 할 통화를 하나만 선택하십시오.",
  OopsExclamationMarkYouShoppingCartIsEmpty:
    "죄송합니다! 귀하의 쇼핑 바구니가 비어 있습니다.",
  SignInToSeeWhatYouHaveAddedOrSaved:
    "추가하거나 저장 한 내용을 보려면 로그인하십시오.",
  WeHaveALotOfSpecialProductOffers:
    "우리는 많은 특별한 제품 제안을 가지고 있거나 웹 사이트에서 영감을 얻을 수 있습니다.",
  SelectedColonCountItems: "선택 : {count} 개의 제품",
  StartFrom: "에서 시작하다",
  ContainersFittingEstimate: "컨테이너 피팅 견적",
  QuoteInformation: "문의 정보",
  SubmittedExclamationMark: "제출되었습니다！",
  SelectFromSavedAddress: "저장된 주소에서 선택",
  WeWillGetBackToYou: "최대한 빨리 연락 드리겠습니다!",

  OopsExclamationMark: "죄송합니다！",
  WeCannotFindAnyResultThatMatchYour:
    "검색과 일치하는 결과를 찾을 수 없습니다:",
  ThePageTitleStillUnderConstructionPeriod:
    "{title} 페이지가 아직 건설 중입니다. 양해 해 주셔서 감사합니다.",
  SometingYouMayLikeColon: "당신이 좋아할만한 것 :",
  OopsExclamationMarkCurrentFilterOptionsDoNot:
    "죄송합니다! 현재 필터 옵션에 결과가 없습니다. 다시 선택하십시오.",
  SortAndFilter: "정렬 및 필터",
  Supplier: "공급 업체",
  Shop: "쇼핑",
  ShopEstablishedDate: "매장 설립일",
  ShopRecommendedProducts: "추천 제품 쇼핑",
  FurnitureAndDecor: "가구와 장식",
  SearchInThisShop: "이 가게에서 검색",
  Copyright: "저작권",
  SearchItemsFromShop: "{shop} 에서 제품 검색",
  Categories: "추천 카테고리",
  Products: "제품 목록",
  OptionNotAvailable: "사용할 수없는 옵션",
};
