import React, { useContext, useState } from "react";
import classnames from "classnames";
import { Dispatch, RootState } from "../../../store/configureStore";
import { useSelector, useDispatch } from "react-redux";
import Image from "../../../components/image";
import styles from "../index.scss";

import { useIntl } from "react-intl";
import { BusinessContext } from "../../BusinessContext";
import { Dropdown } from "antd";
import { useTheme } from "styled-components";
import NavigationMaxkBox from "./NavigationMaskBox";

const Item = React.memo<{
  title: string;
  link: string;
  child: { title: string; link: string }[];
  search: string;
}>(props => {
  const { search } = props;
  const { isToBusiness } = useContext(BusinessContext);

  return (
    <div className={styles["Header-navigation-store-section"]}>
      <a
        className={classnames(styles["Header-navigation-store-linkTiele"], {
          [styles["Header-navigation-store-disable"]]: props.link === "#",
          [styles["Header-navigation-store-tob-link"]]: isToBusiness,
        })}
        href={
          props.link === "#"
            ? void 0
            : __ENV__ === "local"
            ? "/product-list"
            : `${props.link}${search}`
        }
      >
        {props.title}
      </a>
      <ul className={styles["Header-navigation-store-links"]}>
        {props.child &&
          props.child.map((v, i) => (
            <li
              className={classnames({
                [styles["Header-navigation-store-disable"]]: v.link === "#",
                [styles["Header-navigation-store-tob-link"]]: isToBusiness,
              })}
              key={i}
            >
              <a href={v.link === "#" ? void 0 : `${v.link}${search}`}>
                {v.title}
              </a>
            </li>
          ))}
      </ul>
    </div>
  );
});

interface NavigationComponentProps {
  name: string;
  link: string;
  footer: {
    title: string;
    link: string;
    picture: string;
    logo: string;
  };
  isShowComingSoon?: boolean;
  list: any;

  pkid: string;
}

const NavigationComponent: React.FC<NavigationComponentProps> = props => {
  const { bussinessPrimaryColor = "" } = useTheme();
  const { search = "" } = useSelector((state: RootState) => state.URLSearch);
  const { isToBusiness } = useContext(BusinessContext);

  const { isShowComingSoon = false } = props;

  const { formatMessage: f } = useIntl();

  const menuOverlay = (
    <div className={classnames(styles["Header-navigation-store"])}>
      <div className={styles["Header-navigation-store-innerwrap"]}>
        {isShowComingSoon && (
          <div className={styles["Header-navigation-construction"]}>
            * {f({ id: "ComingSoon" })}
          </div>
        )}
        {props.list.length > 0 &&
          props.list.map((item, index) => {
            return (
              <div
                key={index}
                className={styles["Header-navigation-store-gridItem"]}
              >
                {item.map((v, i) => {
                  return <Item {...v} key={i} search={search} />;
                })}
              </div>
            );
          })}
      </div>
      {!isToBusiness && (
        <div className={styles["Header-navigation-bg"]}>
          <div className={styles["Header-navigation-bg-child"]}>
            <div className={styles["Header-navigation-bg-title"]}>
              {props.footer.title}
            </div>
            <div className={styles["Header-navigation-bg-mg"]}>
              <img src={props.footer.logo} height="100%" />
            </div>
            {props.footer.url && (
              <a
                className={styles["Header-navigation-fixed-title"]}
                href={props.footer.url}
              >
                {f({ id: "ShopTheBrand" })} &gt;
              </a>
            )}
            <div className={styles["Header-navigation-img-wrapper"]}>
              <div>
                <Image src={props.footer.picture} width={163} height={90} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  const [showMenus, setShowMenus] = useState(false);

  return (
    <>
      <li className={styles["Header-navigation-item"]}>
        <Dropdown
          overlay={menuOverlay}
          overlayClassName="fixed-position-popup"
          onVisibleChange={() => setShowMenus(vis => !vis)}
          visible={showMenus}
          className="layout-navigation-dropdown"
        >
          <a
            className={classnames([styles["Header-navigation-item-linke"]], {
              [styles["Header-navigation-item-linke-after"]]: showMenus,
            })}
            style={{ color: isToBusiness ? bussinessPrimaryColor : "" }}
          >
            <p>{props.name}</p>
          </a>
        </Dropdown>
      </li>
      <NavigationMaxkBox isOpen={showMenus}>
        <div className={styles["Header-navigation-mask"]} />
      </NavigationMaxkBox>
    </>
  );
};

export default React.memo(NavigationComponent);
