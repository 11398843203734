import { createModel } from "@rematch/core";
import {
  GraphqlQuery,
  GraphqlMutation,
  GraphQLRequest,
} from "../../helpers/request.helper";
import { getFirstItem } from "../../helpers/modelHelpers";
import User from "../../models/User";
import { message } from "antd";
import Agent from "../../models/Agent";
import { AgentStatus } from "../../models/User";
import { BooleanLike, Verify } from "../../models/Common";
import Payment from "../../models/Payment";
export interface AccoutState {
  user: User;
  agent: Agent;

  confirmInfo: Agent;

  breadcrumbs: { url: string; title: string }[];
}

export default createModel({
  state: {} as AccoutState,
  reducers: {
    changeUserInfo: (state: AccoutState, payload: AccoutState["user"]) => ({
      ...state,
      user: {
        ...payload,
        ...state.user,
      },
    }),
    CHANGE_USER_LOGIN(
      state: AccoutState,
      payload: {
        isLogin: boolean;
        userId: string | null;
        email: string | null;
        modalAction: null | string;
      },
    ) {
      return { ...state, ...payload };
    },
    setAgent: (state: AccoutState, payload: Agent) => ({
      ...state,
      agent: {
        ...payload,
      },
    }),
    setConfirm: (state: AccoutState, payload: Agent) => ({
      ...state,
      confirmInfo: {
        ...payload,
      },
    }),
  },
  effects: {
    async getUserInfo(payload) {
      const { callback } = payload;
      const data = new User({});
      const res = await GraphqlQuery("Customer", data);
      callback && callback(getFirstItem(res));
      this.changeUserInfo(getFirstItem(res));
      if (getFirstItem(res).agent_status === AgentStatus.Authenticating) {
        this.handleGetApplyResult();
      } else if (getFirstItem(res).agent_status === AgentStatus.Succeed) {
        this.handleGetAgentInfo();
      }

      if (getFirstItem(res).is_complete === BooleanLike.True) {
        this.GetBrandConfirmInfo();
      }
    },
    async getUserAvater(payload) {
      const { callback, base64 } = payload;
      const res = await GraphqlMutation("Upload", {
        action: "avatar",
        base64: base64,
      });
      callback && callback(res.data);
    },
    async setUserInfoAsync(payload) {
      const { phonenumber, email, confirm, ...dat } = payload;
      const res = await GraphqlMutation("Customer", { action: "edit", ...dat });
      message.success(res.message);
      this.changeUserInfo(payload);
    },

    // 上传凭证图
    async handleUploadImg(payload) {
      const { callback, base64 } = payload;
      const res = await GraphqlMutation("Upload", {
        base64: base64,
        action: "agent",
      });
      if (callback) {
        callback(res.data);
      }
    },
    // 上传设计师案例图
    async handleUploadDesignerCase(payload) {
      const { callback, base64 } = payload;
      const res = await GraphqlMutation("Upload", {
        base64: base64,
        action: "file",
      });
      if (callback) {
        callback(res.data);
      }
    },
    // 申请成为代理
    async agentOperation(payload) {
      const { callback, ...data } = payload;
      const res = await GraphqlMutation("AgentFlow", data);
      message.success(res.message);
      if (callback) {
        callback();
      }
    },
    // 获取代理申请结果
    async handleGetApplyResult() {
      const data = new Agent({ action: "applyResult" });
      const res = await GraphqlQuery("Agent", data, {}, "applyResult");
      this.setAgent(getFirstItem(res));
    },
    // 获取代理信息(已通过状态)
    async handleGetAgentInfo() {
      const data = new Agent({ action: "agent" });
      const res = await GraphqlQuery("Agent", data, {}, "agent");
      this.setAgent(getFirstItem(res));
    },
    // 获取品牌确认信息
    async GetBrandConfirmInfo() {
      const data = new Agent({ action: "confirm" });
      const res = await GraphqlQuery("Agent", data, {}, "confirm");
      this.setConfirm(getFirstItem(res));
    },
    // 补全信息
    async completionInfo(payload) {
      const { callback, ...data } = payload;
      const res = await GraphqlMutation("AgentFlow", data);
      message.success(res.message);
      if (callback) {
        callback();
      }
    },
    async getZfbUrl(payload) {
      const { callback } = payload;
      const data = new Payment({});
      const res = await GraphqlQuery(
        "AliPay",
        data,
        { business: "verify" },
        "AliPay",
      );
      if (callback) {
        callback(getFirstItem(res).url);
      }
    },
    async getPayResult(payload) {
      const { callback } = payload;
      const data = new Payment({});
      const res = await GraphqlQuery("PayResult", data, {
        action: "alipay",
        business: "verify",
      });
      if (callback) {
        callback(getFirstItem(res).result);
      }
    },
    // 获取代理298二维码图
    async getWxUrl(payload) {
      const { callback } = payload;
      const data = new Payment({});
      const res = await GraphqlQuery(
        "PayCode",
        data,
        { business: "verify" },
        "AliPay",
      );
      if (callback) {
        callback(getFirstItem(res).url);
      }
    },
    async getWxPayResult(payload) {
      const { callback } = payload;
      const data = new Payment({});
      const res = await GraphqlQuery("PayResult", data, {
        action: "default",
        business: "verify",
      });
      if (callback) {
        callback(getFirstItem(res).result);
      }
    },
    // 校验推荐码
    async checkAgentCode(payload) {
      const { callback, agent_code } = payload;
      const data = new Verify();
      const res = await GraphqlQuery("Verify", data, {
        agent_code: agent_code,
      });
      if (callback) {
        callback(getFirstItem(res).flag);
      }
    },
  },
});
