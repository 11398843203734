import * as React from "react";
import Layout from "../../Layout";
import Content from "./Content";
import { RootState } from "../../../store/configureStore";
import { useSelector } from "react-redux";

const TobCart: React.FC = () => {
  const { breadcrumbs = [] } = useSelector(
    (state: RootState) => state.IdeaList,
  );

  return (
    <Layout breadcrumbs={[]}>
      <Content />
    </Layout>
  );
};

export default TobCart;
