import { init, RematchRootState } from "@rematch/core";
import createLoadingPlugin from "@rematch/loading";
import reducers from "../reducers";
import replaceState from "../helpers/reolaceState";

const options = {}; // see options API https://github.com/rematch/rematch/tree/master/plugins/loading

const loading = createLoadingPlugin(options);

interface LoadingPlugin {
  loading: {
    models: RematchRootState<typeof reducers>;
    effects: Dispatch;
  };
}

// 调试原因先改成 false
const isDisabled = false; // process.env.NODE_ENV === "production";

function configureStore(preloadedState?: RootState) {
  const store = init({
    models: reducers,
    plugins: [loading],
    redux: {
      initialState: preloadedState,
      devtoolOptions: {
        disabled: isDisabled,
      },
    },
  });

  if (process.env.ENV !== "server") {
    // 外面设置了环境变量,如果是server 则不能执行这个函数否则会报错
    // 添加监听函数
    replaceState.liten = store;
  }

  return store;
}

export type Store = ReturnType<typeof configureStore>;
export type Dispatch = Store["dispatch"];
export type RootState = RematchRootState<typeof reducers> & LoadingPlugin;

export default configureStore;
