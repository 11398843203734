import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, Dispatch } from "../../store/configureStore";
import { FormattedMessage } from "react-intl";
import OrderList from "./component/OrderList";
import * as styles from "./index.scss";
import Input from "../../wrappers/input";
import DatePicker from "../../wrappers/date-picker";
import Icon from "../../components/icon";
import { useIntl } from "react-intl";
import { Row, Col, message, Card } from "antd";
import dayjs from "dayjs";
import replaceState from "../../helpers/reolaceState";
import { useTheme } from "styled-components";

const OrderHistoryContent = () => {
  const dispatch = useDispatch<Dispatch>();
  const { formatMessage: f } = useIntl();
  const { primaryColor, textColor } = useTheme();
  const { orderCount, orderHistory } = useSelector(
    (state: RootState) => state.OrderHistoryPage,
  );

  const { agentCode } = useSelector((state: RootState) => state.User);

  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [navIndex, setNavIndex] = useState(0);
  const [searchOrderNumber, setSearchOrderNumber] = useState("");
  const [startTime, setStartTime] = useState("");
  const [stopTime, setStopTime] = useState("");
  const [selectedType, setSelectedType] = useState("all");
  const { search } = useSelector((state: RootState) => state.URLSearch);
  useEffect(() => {
    // if (!orderHistory) {
    dispatch.OrderHistoryPage.setOrderData({});
    // }
  }, []);

  const handleGetOtherList = (page: number) => {
    setCurrentPageNumber(page);

    dispatch.OrderHistoryPage.setOrderData({
      page: page,
      action: selectedType,
      stopTime: stopTime,
      startTime: startTime,
    });
    window.scroll(0, 0);
  };

  const pagination = {
    current: currentPageNumber,
    total: orderCount,
    pageSize: 10,
    showSizeChanger: false,
    onChange: handleGetOtherList,
  };

  const titleData = [
    {
      label: f({ id: "AllOrders" }),
      value: "all",
    },
    {
      label: f({ id: "ForThePayment" }),
      value: "waitPay",
    },
    {
      label: f({ id: "WaitSend" }),
      value: "waitSend",
    },
    {
      label: f({ id: "ForTheGoods" }),
      value: "waitReceive",
    },
    {
      label: f({ id: "ToEvaluate" }),
      value: "waitComment",
    },
    {
      label: f({ id: "AfterSale" }),
      value: "after",
    },
  ];

  const handleSearch = () => {
    // 这里按订单号调接口查
    dispatch.OrderHistoryPage.setOrderData({
      page: 1,
      orderNumber: searchOrderNumber,
    });
  };

  const handleStartTimeSearch = (value: any) => {
    // 这里按时间，状态搜索
    if (value) {
      setStartTime(value.format("YYYY-MM-DD"));
      dispatch.OrderHistoryPage.setOrderData({
        page: 1,
        action: selectedType,
        stopTime: stopTime,
        startTime: value.format("YYYY-MM-DD"),
      });
    } else {
      setStartTime("");
      dispatch.OrderHistoryPage.setOrderData({
        page: 1,
        action: selectedType,
        stopTime: stopTime,
        startTime: "",
      });
    }
  };
  const handleStopTimeSearch = (value: any) => {
    // 这里按时间，状态搜索
    if (value) {
      setStopTime(value.format("YYYY-MM-DD"));
      dispatch.OrderHistoryPage.setOrderData({
        page: 1,
        action: selectedType,
        stopTime: value.format("YYYY-MM-DD"),
        startTime: startTime,
      });
    } else {
      setStopTime("");
      dispatch.OrderHistoryPage.setOrderData({
        page: 1,
        action: selectedType,
        stopTime: "",
        startTime: startTime,
      });
    }
  };

  const handleListGet = (type: string) => {
    setCurrentPageNumber(1);
    setSelectedType(type);
    setStartTime("");
    setStopTime("");
    dispatch.OrderHistoryPage.setOrderData({ page: 1, action: type });
  };

  const handleCancelOrder = (orderID: number) => {
    const data = {
      order_id: orderID,
      callback: (res: any) => {
        message.success(res.message);
        dispatch.OrderHistoryPage.setOrderData({
          page: currentPageNumber,
          action: selectedType,
        });
      },
    };
    dispatch.OrderHistoryPage.handleCancelOrder(data);
  };

  const handleConfirmGoods = (orderID: number, orderProductId: number) => {
    const data = {
      order_id: orderID,
      order_product_id: orderProductId,
      callback: (res: any) => {
        message.success(res.message);
        dispatch.OrderHistoryPage.setOrderData({
          page: currentPageNumber,
          action: selectedType,
        });
      },
    };
    dispatch.OrderHistoryPage.handleConfirmGoods(data);
  };

  const handleAddToCart = (
    shopProductId: number,
    quantity: number,
    providerId: number,
  ) => {
    const data = {
      shopProductId: shopProductId,
      quantity: quantity,
      provider_id: providerId,
      agent_code: agentCode,
      callback: (res: any) => {
        message.success(res.message);
        replaceState.linkTo("/cart");
      },
    };
    dispatch.OrderHistoryPage.handleAddToCart(data);
  };

  return (
    <div
      className={styles["OrderHistory-box"]}
      style={{
        width: "100%",
        padding: "0px 20px",
      }}
    >
      <div className={styles["OrderHistory-content-box"]}>
        <Row className={styles["OrderHistory-nav"]}>
          {titleData.map((item, index) => {
            return (
              <Col
                span={18 / titleData.length}
                key={index}
                style={{ textAlign: "center" }}
              >
                <div
                  className={styles["OrderHistory-nav-div"]}
                  onClick={() => {
                    if (selectedType === item.value) return;
                    setNavIndex(index);
                    handleListGet(item.value);
                  }}
                  style={{
                    borderBottom:
                      navIndex === index ? "2px solid " + primaryColor : "",
                  }}
                >
                  <h3
                    style={{
                      color: navIndex === index ? primaryColor : "",
                    }}
                  >
                    {item.label}
                  </h3>
                </div>
              </Col>
            );
          })}
        </Row>
        <Row gutter={10} className={styles["OrderHistory-search"]}>
          {f({ id: "OrderNumber" })}
          <div
            style={{
              display: "inline-block",
              width: "300px",
              margin: "0 10px",
            }}
          >
            <Input
              placeholder={f({ id: "PleaseEnterOrderNumberSearch" })}
              addonAfter={
                <Icon onClick={handleSearch} type="icont1_fangdajing"></Icon>
              }
              onChange={e => setSearchOrderNumber(e.target.value)}
              onPressEnter={handleSearch}
            ></Input>
          </div>
          {f({ id: "TimeFiltering" })}
          <div
            style={{
              display: "inline-block",
              width: "200px",
              margin: "0 10px",
            }}
          >
            <DatePicker
              style={{
                width: "100%",
              }}
              placeholder={f({ id: "PleaseEnterStartTime" })}
              onChange={value => {
                handleStartTimeSearch(value);
              }}
              value={startTime !== "" ? dayjs(startTime, "YYYY-MM-DD") : null}
            />
          </div>
          <div
            style={{
              display: "inline-block",
              width: "200px",
              margin: "0 10px",
            }}
          >
            <DatePicker
              style={{
                width: "100%",
              }}
              placeholder={f({ id: "PleaseEnterStopTime" })}
              onChange={value => {
                handleStopTimeSearch(value);
              }}
              value={stopTime !== "" ? dayjs(stopTime, "YYYY-MM-DD") : null}
            />
          </div>
        </Row>
        {orderHistory && orderHistory.length > 0 ? (
          <OrderList
            data={orderHistory}
            pagination={pagination}
            handleRefreshData={() =>
              dispatch.OrderHistoryPage.setOrderData({
                page: currentPageNumber,
              })
            }
            handleCancelOrder={(orderId: number) => {
              handleCancelOrder(orderId);
            }}
            handleConfirmGoods={(orderId: number, orderProductId: number) => {
              handleConfirmGoods(orderId, orderProductId);
            }}
            handleAddToCart={(
              shopProductId: number,
              quantity: number,
              providerId: number,
            ) => {
              handleAddToCart(shopProductId, quantity, providerId);
            }}
          />
        ) : (
          <div className={styles["OrderHistory-empty"]}>
            <FormattedMessage id="ThereIsNoOrder" />
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderHistoryContent;
