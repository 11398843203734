import React, { useState, useEffect, useContext, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, Dispatch } from "../../store/configureStore";
import * as styles from "./index.scss";
import Icon from "../../components/icon";
import AutoImage from "../../components/image";
import { getBase64 } from "../../helpers/modelHelpers";
import { useIntl } from "react-intl";
import {
  Row,
  Col,
  Divider,
  Cascader,
  Tag,
  message,
  Form,
  Card,
  Space,
} from "antd";
import Upload from "../../wrappers/upload";
import Button, { PrimaryButton } from "../../wrappers/button";
import { UploadFile } from "antd/lib/upload/interface";
import { addressDatas } from "../../helpers/addressData";
import { getFirstItem } from "../../helpers/modelHelpers";
import replaceState from "../../helpers/reolaceState";
import Input from "../../wrappers/input";
import Select from "../../wrappers/select";
import { useTheme } from "styled-components";

type AfterSaleTsType = {
  order_product_id: string;
  after_sale_id: number;
  after_sale_type: number;
  after_sale_type_text: string;
  after_status: number;
  after_status_text: string;
  after_sale_reason: string;
  after_sale_amount: string;
  after_sale_amount_text: string;
  return_order_id: number;
  remark: string;
  quantity: number;
  shipping_method: string;
  refund_type_text: string;
  merchant_recipient_name: string;
  merchant_recipient_address: string;
  logistics_type: string;
  logistics_express_number: string;
  logistics_express_company: string;
  logistics_express_company_code: string;
  pickup_contact_telephone: string;
  pickup_contact: string;
  pickup_contact_address: string;
  return_status: number;
  return_status_text: string;
  after_sale_number: string;
  after_sale_files: { file: string }[];
};

const { TextArea } = Input;

const OrderHistoryContent = () => {
  const { primaryColor, secondaryColor } = useTheme();
  const dispatch = useDispatch<Dispatch>();
  const { order } = useSelector((state: RootState) => state.OrderDetailsPage);
  const [orderData, setOrderData] = useState(order);
  const [orderNumber, setOrderNumber] = useState("");

  useEffect(() => {
    const [, , , orderNumber, orderProductId] = window.location.pathname.split(
      "/",
    );
    setOrderNumber(orderNumber);
    dispatch.OrderDetailsPage.getOrderDetailAsync(orderNumber);
  }, []);

  useEffect(() => {
    const [, , , orderNumber, orderProductId] = window.location.pathname.split(
      "/",
    );
    if (order) {
      const product = getFirstItem(
        order?.product.filter(
          val => val.order_product_id === Number(orderProductId),
        ),
      );
      setOrderData(product);
      setQuantity(product.quantity);
      form.setFieldsValue({
        after_sale_type: 1,
        apply_project: 1,
        quantity: product.quantity,
        logistics_type: "logistics",
      });
    }
  }, [order]);

  const { formatMessage: f } = useIntl();
  const [afterSaleType, setAfterSaleType] = useState(0);
  const [applyProject, setApplyProject] = useState(0);
  const [quantity, setQuantity] = useState(orderData?.quantity || 1);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [afterSaleInfoData, setAfterSaleInfoData] = useState<AfterSaleTsType>(
    {},
  );
  const [form] = Form.useForm();

  useEffect(() => {
    if (orderData?.after_show_status === 1) {
      const data = {
        order_product_id: orderData.order_product_id,
        callback: (res: any) => {
          setAfterSaleInfoData(res);
        },
      };
      dispatch.AfterSalePage.handleGetAfterSaleInfo(data);
    }
  }, [orderData]);

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleSubmitForm = (value: any) => {
    const fileData = fileList.map(item => {
      return item.url;
    });
    value.after_sale_files = fileData.join(",");
    value.order_product_id = orderData.order_product_id;

    const data = {
      ...value,
      callback: (res: any) => {
        message.success(res.message);
        const datas = {
          order_product_id: orderData.order_product_id,
          callback: (result: any) => {
            replaceState.linkTo("/account/orders/" + orderNumber);
            setAfterSaleInfoData(result);
          },
        };
        dispatch.AfterSalePage.handleGetAfterSaleInfo(datas);
      },
    };

    dispatch.AfterSalePage.handleApplyAfterSale(data);
  };

  const handlePerfectInformation = (value: any) => {
    if (afterSaleInfoData.shipping_method === "self") {
      value.pickup_contact_address =
        value.pickup_contact_address + value.address_details;
      delete value.address_details;
      const data = {
        ...value,
        shipping_method: afterSaleInfoData.shipping_method,
        return_order_id: afterSaleInfoData.return_order_id,
        callback: (res: any) => {
          const datas = {
            order_product_id: orderData.order_product_id,
            callback: (res: any) => {
              setAfterSaleInfoData(res);
            },
          };
          dispatch.AfterSalePage.handleGetAfterSaleInfo(datas);
        },
      };
      dispatch.AfterSalePage.handleConfirmAddress(data);
    } else {
      const data = {
        ...value,
        shipping_method: afterSaleInfoData.shipping_method,
        return_order_id: afterSaleInfoData.return_order_id,
        callback: (res: any) => {
          const datas = {
            order_product_id: orderData.order_product_id,
            callback: (res: any) => {
              setAfterSaleInfoData(res);
            },
          };
          dispatch.AfterSalePage.handleGetAfterSaleInfo(datas);
        },
      };
      dispatch.AfterSalePage.handleConfirmAddress(data);
    }
  };

  //取消申请回详情
  const handleCancelApply = () => {
    const data = {
      order_product_id: orderData.order_product_id,
      callback: (res: any) => {
        message.success(res.message);
        replaceState.linkTo("/account/orders/" + orderNumber);
      },
    };
    dispatch.AfterSalePage.handleCancelApply(data);
  };

  const requiredText = f({
    id: "Required",
  });
  const formItemLayout = {
    labelCol: {
      span: 3,
    },
    wrapperCol: {
      span: 19,
    },
  };
  const afterSaleTypeData = [
    f({ id: "Refund" }),
    f({ id: "ReturnGoodsRefund" }),
  ];
  const afterSaleTypeDataa = [f({ id: "Refund" })];

  const afterSaleOptionData = [f({ id: "All" }), f({ id: "PartOfThe" })];

  const handleCustomRequest = useCallback(
    async (files: any) => {
      const fileBase64 = await getBase64(files.file);
      const fileData = {
        name: files.file.name,
        uid: files.file.uid,
      };
      const data = {
        base64: fileBase64,
        callback: (res: string) => {
          const newFile = {
            ...fileData,
            url: res,
          };
          setFileList([...fileList, newFile]);
        },
      };
      dispatch.AfterSalePage.handleUploadImg(data);
    },
    [fileList],
  );

  const handleRemoveimg = (file: UploadFile) => {
    const fileData = fileList.filter(item => item.uid !== file.uid);
    setFileList(fileData);
  };

  const getIcon = () => {
    return <Icon type="iconcuowu1-copy"></Icon>;
  };

  return (
    <>
      <div className={styles[`AfterSale-header`]}>
        {f({ id: "OrderNumber" })} :{orderNumber}
      </div>
      <div className={styles[`AfterSale-product`]}>
        <Row style={{ margin: "10px" }}>
          <Col span={12}>{f({ id: "ProductInfomation" })}</Col>
          <Col span={6}>{f({ id: "Quantity" })}</Col>
          <Col span={6}>{f({ id: "Price" })}</Col>
        </Row>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Card style={{ background: "white" }}>
            <Row>
              <Col span={4}>
                <AutoImage
                  width={58}
                  height={50}
                  src={orderData?.product_picture}
                />
              </Col>
              <Col span={8} style={{ height: "160px", padding: "0 10px" }}>
                <p>{orderData?.product_name}</p>
                <p>
                  {orderData?.brand_name && orderData.brand_name !== "" && (
                    <Tag color={secondaryColor}>{orderData.brand_name}</Tag>
                  )}
                  {orderData?.series_name && orderData.series_name !== "" && (
                    <Tag color={secondaryColor}>{orderData.series_name}</Tag>
                  )}
                </p>
                <p>{orderData?.attribute}</p>
              </Col>
              <Col span={6}>
                <p>*{orderData?.quantity}</p>
              </Col>
              <Col span={6}>
                <p>
                  {f({ id: "PriceTotal" })}：¥
                  {(orderData?.tag_price * orderData?.quantity).toFixed(2)}
                </p>
                {f(
                  { id: "LeftParenthesisPricePerItemRigthParenthesis" },
                  { price: orderData?.tag_price_value },
                )}
              </Col>
            </Row>
          </Card>
          {orderData?.after_show_status === 0 && (
            <Form
              form={form}
              initialValues={{ remember: true }}
              {...formItemLayout}
              onFinish={handleSubmitForm}
              onFinishFailed={onFinishFailed}
            >
              <Card style={{ background: "white", marginTop: "10px" }}>
                {orderData.product_status !== 2 && (
                  <Form.Item
                    name="after_sale_type"
                    label={f({ id: "AfterSaleType" })}
                    rules={[{ required: true, message: requiredText }]}
                  >
                    {afterSaleTypeData.map((item, index) => {
                      return (
                        <span
                          key={index}
                          style={{
                            border:
                              afterSaleType === index
                                ? "1px solid " + primaryColor
                                : "1px solid #bdbcbc",
                            color: afterSaleType === index ? primaryColor : "",
                          }}
                          className={styles[`AfterSale-form-span`]}
                          onClick={() => {
                            setAfterSaleType(index);
                            form.setFieldsValue({
                              after_sale_type: index + 1,
                            });
                          }}
                        >
                          {item}
                        </span>
                      );
                    })}
                  </Form.Item>
                )}

                {orderData.product_status === 2 && (
                  <Form.Item
                    name="after_sale_type"
                    label={f({ id: "AfterSaleType" })}
                    rules={[{ required: true, message: requiredText }]}
                  >
                    {afterSaleTypeDataa.map((item, index) => {
                      return (
                        <span
                          key={index}
                          style={{
                            border:
                              afterSaleType === index
                                ? "1px solid " + primaryColor
                                : "1px solid #bdbcbc",
                            color: afterSaleType === index ? primaryColor : "",
                          }}
                          className={styles[`AfterSale-form-span`]}
                          onClick={() => {
                            setAfterSaleType(index);
                            form.setFieldsValue({
                              after_sale_type: index + 1,
                            });
                          }}
                        >
                          {item}
                        </span>
                      );
                    })}
                  </Form.Item>
                )}

                <Form.Item
                  name="apply_project"
                  label={f({ id: "ApplyProject" })}
                  rules={[{ required: true, message: requiredText }]}
                >
                  {afterSaleOptionData.map((item, index) => {
                    return (
                      <span
                        key={index}
                        style={{
                          border:
                            applyProject === index
                              ? "1px solid " + primaryColor
                              : "1px solid #bdbcbc",
                          color: applyProject === index ? primaryColor : "",
                        }}
                        className={styles[`AfterSale-form-span`]}
                        onClick={() => {
                          setApplyProject(index);
                          form.setFieldsValue({
                            apply_project: index + 1,
                          });
                          if (index === 0) {
                            setQuantity(orderData.quantity);
                          }
                        }}
                      >
                        {item}
                      </span>
                    );
                  })}
                </Form.Item>

                <Form.Item
                  name="quantity"
                  label={f({ id: "ApplyQuantity" })}
                  rules={[{ required: true, message: requiredText }]}
                >
                  <Space size={5}>
                    <PrimaryButton
                      disabled={applyProject === 0}
                      icon={<Icon type="icont1_jian" />}
                      onClick={() => {
                        if (quantity - 1 < 1) return;
                        setQuantity(quantity - 1);
                        form.setFieldsValue({
                          quantity: quantity - 1,
                        });
                      }}
                      bgColor={primaryColor}
                    ></PrimaryButton>
                    <Input
                      value={quantity}
                      disabled={applyProject === 0}
                      onChange={e => {
                        if (
                          Number(e.target.value) > orderData.quantity ||
                          Number(e.target.value) < 1
                        ) {
                          return;
                        }
                        form.setFieldsValue({
                          quantity: Number(e.target.value),
                        });
                        setQuantity(Number(e.target.value));
                      }}
                      style={{
                        border: "1px solid " + primaryColor,
                        width: "50px",
                        textAlign: "center",
                      }}
                    />
                    <PrimaryButton
                      disabled={applyProject === 0}
                      icon={<Icon type="icont1_jia" />}
                      onClick={() => {
                        if (quantity + 1 > orderData.quantity) return;
                        setQuantity(quantity + 1);
                        form.setFieldsValue({
                          quantity: quantity + 1,
                        });
                      }}
                      bgColor={primaryColor}
                    ></PrimaryButton>
                  </Space>
                </Form.Item>

                <Form.Item name="refundAmount" label={f({ id: "ApplyAmount" })}>
                  ¥{(Number(orderData.tag_price) * Number(quantity)).toFixed(2)}
                </Form.Item>

                <Form.Item
                  name="after_sale_reason"
                  label={f({ id: "AfterSaleSeason" })}
                  rules={[{ required: true, message: requiredText }]}
                >
                  <TextArea
                    rows={4}
                    placeholder={`${f({
                      id: "PleaseEnterAfterSaleSeason",
                    })}*`}
                  />
                </Form.Item>

                <Form.Item
                  name="after_sale_files"
                  label={f({ id: "Credentials" })}
                >
                  <Upload
                    width="100px"
                    fileList={fileList}
                    customRequest={handleCustomRequest}
                    onRemove={handleRemoveimg}
                    showUploadList={{
                      showPreviewIcon: false,
                      showDownloadIcon: false,
                      removeIcon: getIcon(),
                    }}
                  >
                    {fileList.length < 5 && f({ id: "AddPicture" })}
                  </Upload>
                </Form.Item>
              </Card>
              <Row
                className={styles["AfterSale-form-submit"]}
                justify="space-between"
              >
                <Col span={4}>
                  <Button
                    block
                    onClick={() => {
                      replaceState.linkTo("/account/orders/" + orderNumber);
                    }}
                    color={primaryColor}
                  >
                    {f({ id: "BackToOrderDetails" })}
                  </Button>
                </Col>
                <Col span={16} style={{ textAlign: "right" }}>
                  <Space>
                    <Button
                      onClick={() => {
                        replaceState.linkTo("/account/orders/" + orderNumber);
                      }}
                      color={primaryColor}
                    >
                      {f({ id: "CancelApply" })}
                    </Button>

                    <PrimaryButton htmlType="submit" bgColor={primaryColor}>
                      {f({ id: "SubmitApply" })}
                    </PrimaryButton>
                  </Space>
                </Col>
              </Row>
            </Form>
          )}

          {orderData?.after_show_status === 1 && (
            <Form
              form={form}
              initialValues={{ remember: true }}
              {...formItemLayout}
              onFinish={handlePerfectInformation}
              onFinishFailed={onFinishFailed}
            >
              <Card style={{ background: "white" }}>
                <Row gutter={10}>
                  <Col span={4} style={{ textAlign: "right" }}>
                    {f({ id: "AfterSaleType" })}:
                  </Col>
                  <Col span={20}>
                    {afterSaleInfoData.after_sale_type_text || ""}
                  </Col>
                </Row>
                <Row gutter={10}>
                  <Col span={4} style={{ textAlign: "right" }}>
                    {f({ id: "ApplyProject" })}:
                  </Col>
                  <Col span={20}>
                    {afterSaleInfoData.refund_type_text || ""}
                  </Col>
                </Row>
                <Row gutter={10}>
                  <Col span={4} style={{ textAlign: "right" }}>
                    {f({ id: "ApplyQuantity" })}:
                  </Col>
                  <Col span={20}>{afterSaleInfoData.quantity || ""}</Col>
                </Row>
                <Row gutter={10}>
                  <Col span={4} style={{ textAlign: "right" }}>
                    {f({ id: "AfterSaleSeason" })}:
                  </Col>
                  <Col span={20}>
                    {afterSaleInfoData.after_sale_reason || ""}
                  </Col>
                </Row>
                <Row gutter={10}>
                  <Col span={4} style={{ textAlign: "right" }}>
                    {f(
                      { id: "CredentialsNumber" },
                      {
                        num:
                          afterSaleInfoData &&
                          afterSaleInfoData.after_sale_files
                            ? afterSaleInfoData.after_sale_files.length
                            : 0,
                        total: 4,
                      },
                    )}
                    :
                  </Col>

                  <Col span={20}>
                    {afterSaleInfoData.after_sale_files &&
                      afterSaleInfoData.after_sale_files.map(item => {
                        return (
                          <img
                            src={item.file}
                            alt=""
                            className={styles[`AfterSale-img`]}
                          />
                        );
                      })}
                  </Col>
                </Row>
                <Row gutter={10}>
                  <Col span={4} style={{ textAlign: "right" }}>
                    {f({ id: "BusinessAfterSaleStatus" })}:
                  </Col>
                  <Col span={20}>
                    {afterSaleInfoData.audit_status_text || ""}
                  </Col>
                </Row>
                {afterSaleInfoData.return_status === 1 && (
                  <>
                    <Divider />
                    {afterSaleInfoData.shipping_method === "self" && (
                      <>
                        <Form.Item label={f({ id: "BusinessPickupAddress" })}>
                          <span>
                            {f({
                              id:
                                "ThisAddressIsTheDoorToFoorPickUpAddressOfTheMerchant",
                            })}
                          </span>
                        </Form.Item>

                        <Form.Item
                          name="pickup_contact"
                          label={f({ id: "Contactor" })}
                          rules={[{ required: true, message: requiredText }]}
                        >
                          <Col span={8}>
                            <Input
                              placeholder={`${f({
                                id: "PleaseEnter",
                              })}*`}
                            />
                          </Col>
                        </Form.Item>
                        <Form.Item
                          name="pickup_contact_telephone"
                          label={f({ id: "CellphoneNumber" })}
                          rules={[{ required: true, message: requiredText }]}
                        >
                          <Col span={8}>
                            <Input
                              placeholder={`${f({
                                id: "PleaseEnter",
                              })}*`}
                            />
                          </Col>
                        </Form.Item>

                        <Form.Item
                          name="pickup_contact_address"
                          label={f({ id: "Address" })}
                          rules={[{ required: true, message: requiredText }]}
                        >
                          <Col span={8}>
                            <Cascader
                              options={addressDatas}
                              placeholder={f({ id: "PleaseSelectOptions" })}
                              onChange={val => {
                                form.setFieldsValue({
                                  pickup_contact_address: val.join(""),
                                });
                              }}
                              style={{ background: "white" }}
                            ></Cascader>
                          </Col>
                        </Form.Item>

                        <Form.Item
                          name="address_details"
                          label={f({ id: "DetailedAddress" })}
                          rules={[{ required: true, message: requiredText }]}
                        >
                          <Col span={8}>
                            <Input
                              placeholder={`${f({
                                id: "PleaseEnter",
                              })}*`}
                            />
                          </Col>
                        </Form.Item>
                      </>
                    )}
                    {afterSaleInfoData.shipping_method === "third" && (
                      <>
                        <Row gutter={10}>
                          <Col span={4} style={{ textAlign: "right" }}>
                            <h3>{f({ id: "BusinessAddress" })}</h3>
                          </Col>
                          <Col span={20}>
                            {f({
                              id:
                                "ThisAddressIsTheAddressYouDendBackToTheMerchant",
                            })}
                          </Col>
                        </Row>
                        <Row gutter={10}>
                          <Col span={4} style={{ textAlign: "right" }}>
                            {f({ id: "Consignee" })}:
                          </Col>
                          <Col span={20}>
                            {afterSaleInfoData.merchant_recipient_name}
                          </Col>
                        </Row>
                        <Row gutter={10}>
                          <Col span={4} style={{ textAlign: "right" }}>
                            {f({ id: "ContactPhoneNumber" })}:
                          </Col>
                          <Col span={20}>
                            {afterSaleInfoData.merchant_recipient_telephone}
                          </Col>
                        </Row>
                        <Row gutter={10}>
                          <Col span={4} style={{ textAlign: "right" }}>
                            {f({ id: "ShippingAddress" })}:
                          </Col>
                          <Col span={20}>
                            {afterSaleInfoData.merchant_recipient_address}
                          </Col>
                        </Row>
                        <Divider />

                        <Form.Item
                          name="logistics_type"
                          label={f({ id: "LogisticsType" })}
                          rules={[{ required: true, message: requiredText }]}
                        >
                          <Col span={8}>
                            <Select
                              defaultValue={"logistics"}
                              onChange={val => {
                                form.setFieldsValue({
                                  logistics_type: val,
                                });
                              }}
                            >
                              <Select.Option value={"logistics"}>
                                {f({ id: "Logistics" })}
                              </Select.Option>
                              <Select.Option value={"express"}>
                                {f({ id: "Courier" })}
                              </Select.Option>
                            </Select>
                          </Col>
                        </Form.Item>

                        <Form.Item
                          name="logistics_express_company"
                          label={f({ id: "LogisticsCourierFirmName" })}
                          rules={[{ required: true, message: requiredText }]}
                        >
                          <Col span={8}>
                            <Input
                              placeholder={`${f({
                                id: "PleaseEnter",
                              })}*`}
                            />
                          </Col>
                        </Form.Item>
                        <Form.Item
                          name="logistics_express_number"
                          label={f({ id: "LogisticsTypeCourierNumber" })}
                          rules={[{ required: true, message: requiredText }]}
                        >
                          <Col span={8}>
                            <Input
                              placeholder={`${f({
                                id: "PleaseEnter",
                              })}*`}
                            />
                          </Col>
                        </Form.Item>
                      </>
                    )}
                  </>
                )}
                {afterSaleInfoData.return_status === 2 && (
                  <>
                    <Divider />
                    {afterSaleInfoData.shipping_method === "self" && (
                      <>
                        <Row>
                          <Col span={4} style={{ textAlign: "right" }}>
                            {f({ id: "BusinessPickupAddress" })}：
                          </Col>
                          <Col span={20}>
                            {" "}
                            {f({
                              id:
                                "ThisAddressIsTheDoorToFoorPickUpAddressOfTheMerchant",
                            })}
                          </Col>
                        </Row>
                        <Row>
                          <Col span={4} style={{ textAlign: "right" }}>
                            {f({ id: "Contactor" })}：
                          </Col>
                          <Col span={20}>
                            {afterSaleInfoData.pickup_contact}
                          </Col>
                        </Row>
                        <Row>
                          <Col span={4} style={{ textAlign: "right" }}>
                            {f({ id: "CellphoneNumber" })}：
                          </Col>
                          <Col span={20}>
                            {afterSaleInfoData.pickup_contact_telephone}
                          </Col>
                        </Row>
                        <Row>
                          <Col span={4} style={{ textAlign: "right" }}>
                            {f({ id: "Address" })}：
                          </Col>
                          <Col span={20}>
                            {afterSaleInfoData.pickup_contact_address}
                          </Col>
                        </Row>
                      </>
                    )}

                    {afterSaleInfoData.shipping_method === "third" && (
                      <>
                        <Row gutter={10}>
                          <Col span={4} style={{ textAlign: "right" }}>
                            <h3>{f({ id: "BusinessAddress" })}</h3>
                          </Col>
                          <Col span={20}>
                            {f({
                              id:
                                "ThisAddressIsTheAddressYouDendBackToTheMerchant",
                            })}
                          </Col>
                        </Row>
                        <Row gutter={10}>
                          <Col span={4} style={{ textAlign: "right" }}>
                            {f({ id: "Consignee" })}:
                          </Col>
                          <Col span={20}>
                            {afterSaleInfoData.merchant_recipient_name}
                          </Col>
                        </Row>
                        <Row gutter={10}>
                          <Col span={4} style={{ textAlign: "right" }}>
                            {f({ id: "ContactPhoneNumber" })}:
                          </Col>
                          <Col span={20}>
                            {afterSaleInfoData.merchant_recipient_telephone}
                          </Col>
                        </Row>
                        <Row gutter={10}>
                          <Col span={4} style={{ textAlign: "right" }}>
                            {f({ id: "ShippingAddress" })}:
                          </Col>
                          <Col span={20}>
                            {afterSaleInfoData.merchant_recipient_address}
                          </Col>
                        </Row>
                        <Divider />
                        <Row gutter={10}>
                          <Col span={4} style={{ textAlign: "right" }}>
                            {f({ id: "LogisticsType" })}:
                          </Col>
                          <Col span={20}>
                            {afterSaleInfoData.logistics_type}
                          </Col>
                        </Row>
                        <Row gutter={10}>
                          <Col span={4} style={{ textAlign: "right" }}>
                            {f({ id: "LogisticsTypeCourierNumber" })}:
                          </Col>
                          <Col span={20}>
                            {afterSaleInfoData.logistics_express_company}
                          </Col>
                        </Row>

                        <Row gutter={10}>
                          <Col span={4} style={{ textAlign: "right" }}>
                            {f({ id: "FirmName" })}:
                          </Col>
                          <Col span={20}>
                            {afterSaleInfoData.logistics_express_number}
                          </Col>
                        </Row>
                      </>
                    )}
                  </>
                )}
              </Card>

              <Row
                className={styles["AfterSale-form-submit"]}
                justify="space-between"
              >
                <Col>
                  <Button
                    block
                    color={primaryColor}
                    onClick={() => {
                      replaceState.linkTo("/account/orders/" + orderNumber);
                    }}
                  >
                    {f({ id: "BackToOrderDetails" })}
                  </Button>
                </Col>
                <Col style={{ textAlign: "right" }}>
                  <Space>
                    {orderData.after_show_status === 1 &&
                      afterSaleInfoData.after_status !== 0 &&
                      afterSaleInfoData.after_status !== 98 &&
                      afterSaleInfoData.after_status !== 99 && (
                        <Button
                          color={primaryColor}
                          onClick={handleCancelApply}
                        >
                          {f({ id: "CancelApply" })}
                        </Button>
                      )}
                    {afterSaleInfoData.return_status === 1 && (
                      <PrimaryButton htmlType="submit" bgColor={primaryColor}>
                        {f({ id: "ConfirmAddress" })}
                      </PrimaryButton>
                    )}
                  </Space>
                </Col>
              </Row>
            </Form>
          )}
        </Space>
      </div>
    </>
  );
};

export default OrderHistoryContent;
