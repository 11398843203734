import * as React from "react";
import * as styles from "./index.scss";

const Header = () => {
  return (
    <div className={styles["Header"]}>
      <div className={styles["Header-logo"]}></div>
    </div>
  );
};

export default React.memo(Header);
