import React, { useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Carousel, List, Row, Col } from "antd";
import Image from "../../components/image";
import { Text, Link } from "../../wrappers/typography";
import Icon from "../../components/icon";
import * as styles from "./index.scss";
import replaceState from "../../helpers/reolaceState";
import { RootState, Dispatch } from "../../store/configureStore";
import { CommonProps } from "../../typings/component";
import { BusinessContext } from "../BusinessContext";
import IdeaCard from "../IdeaList/component/Card";
import { useIntl } from "react-intl";
import classNames from "classnames";

const HomeContent: React.FC<CommonProps> = () => {
  const {
    BannerData,
    StyleData,
    DesignIdeaData,
    CategoryData,
    RoomData,
    PartnerData,
    FeatureData,
    ActiveData,
  } = useSelector((state: RootState) => state.HomePage);

  const { search = "" } = useSelector((state: RootState) => state.URLSearch);

  const dispatch = useDispatch<Dispatch>();

  const { formatMessage: f } = useIntl();

  const { isToBusiness } = useContext(BusinessContext);

  useEffect(() => {
    if (!isToBusiness) {
      dispatch.HomePage.getDesignIdeaAsync();
    }
  }, [isToBusiness]);

  return (
    <div>
      {BannerData && (
        <Carousel autoplay>
          {BannerData?.child.map((item, index) => (
            <div key={index}>
              <a href={item.link}>
                <Image
                  alt={item.title}
                  src={item.picture}
                  width={1920}
                  height={463}
                />
              </a>
            </div>
          ))}
        </Carousel>
      )}

      <div className={styles["Home-main"]}>
        {!isToBusiness && ActiveData && (
          <List
            className={styles["Home-column"]}
            grid={{ column: 2, gutter: 13 }}
            dataSource={ActiveData.child}
            renderItem={(item, index) => (
              <List.Item key={index}>
                <Row
                  align="middle"
                  justify="space-between"
                  className={styles["Home-banner"]}
                  style={{
                    backgroundImage: `url(${item.picture})`,
                  }}
                  onClick={() => replaceState.linkTo(item.link)}
                >
                  <Col>{item.title}</Col>
                  <Col>前往 &gt;</Col>
                </Row>
              </List.Item>
            )}
          ></List>
        )}
        {CategoryData && (
          <List
            className={styles["Home-column"]}
            header={
              <Row
                className={styles["Home-column-title"]}
                align="middle"
                justify="space-between"
              >
                <Col>{CategoryData.title}</Col>
                <Col>
                  <Link
                    underline
                    size="1.4rem"
                    onClick={e => {
                      e.preventDefault();
                      replaceState.linkTo(
                        `${
                          isToBusiness ? "/tob" : ""
                        }/recommend/category/index.html`,
                      );
                    }}
                  >
                    更多
                  </Link>
                </Col>
              </Row>
            }
            split={false}
            grid={{ column: 5, gutter: 8 }}
            dataSource={CategoryData.child}
            renderItem={(item, index) => (
              <List.Item key={index}>
                <a
                  href={`${item.link}${search}`}
                  className={styles["Category-item"]}
                >
                  <Image src={item.picture} width={1} height={1}>
                    <div className={styles[`Category-title`]}>{item.title}</div>
                  </Image>
                </a>
              </List.Item>
            )}
          ></List>
        )}
        {!isToBusiness && DesignIdeaData && (
          <List
            className={styles["Home-column"]}
            header={
              <Row
                className={styles["Home-column-title"]}
                align="middle"
                justify="space-between"
              >
                <Col>{DesignIdeaData.title}</Col>
                <Col>
                  <Link
                    underline
                    size="1.4rem"
                    onClick={() =>
                      replaceState.linkTo("/design-idea-list/index.html")
                    }
                  >
                    更多
                  </Link>
                </Col>
              </Row>
            }
            split={false}
            grid={{ column: 4, gutter: 14 }}
            dataSource={DesignIdeaData.child}
            renderItem={(item, index) => (
              <List.Item key={index} className={styles["matchdesign-item"]}>
                <IdeaCard
                  src={item.design_idea_picture}
                  key={item.design_idea_id}
                  name={item.design_idea_name}
                  shopName={item.shop_name}
                  url={item.url}
                  tags={item.coordinates}
                  isCollect={item.is_collect}
                  shopUrl={item.shop_url}
                  showTitle={false}
                  showCollection={false}
                />
              </List.Item>
            )}
          ></List>
        )}
        {StyleData && (
          <List
            className={styles["Home-column"]}
            header={
              <Row
                className={styles["Home-column-title"]}
                align="middle"
                justify="space-between"
              >
                <Col>{StyleData.title}</Col>
                <Col>
                  <Link
                    underline
                    size="1.4rem"
                    onClick={() =>
                      replaceState.linkTo(
                        `${
                          isToBusiness ? "/tob" : ""
                        }/recommend/style/index.html`,
                      )
                    }
                  >
                    更多
                  </Link>
                </Col>
              </Row>
            }
            split={false}
            grid={{ column: 5, gutter: 14 }}
            dataSource={StyleData.child}
            renderItem={(item, index) => (
              <List.Item key={index}>
                <a
                  href={item.link}
                  className={classNames(styles["Style-item"], {
                    [styles["Style-box"]]: !!item.link,
                  })}
                >
                  <img src={item.picture} />
                  <span>{item.title}</span>
                </a>
                {!item.link && (
                  <div className={styles["Style-mask"]}>
                    <div className={styles["Style-mask-alert"]}>
                      {f({ id: "StayTuned" })}
                    </div>
                  </div>
                )}
              </List.Item>
            )}
          ></List>
        )}
        {RoomData && (
          <List
            className={styles["Home-column"]}
            header={
              <Row
                className={styles["Home-column-title"]}
                align="middle"
                justify="space-between"
              >
                <Col>{RoomData.title}</Col>
                <Col>
                  <Link
                    underline
                    size="1.4rem"
                    onClick={() =>
                      replaceState.linkTo(
                        `${
                          isToBusiness ? "/tob" : ""
                        }/recommend/room/index.html`,
                      )
                    }
                  >
                    更多
                  </Link>
                </Col>
              </Row>
            }
            split={false}
            grid={{ column: 5, gutter: 14 }}
            dataSource={RoomData.child}
            renderItem={(item, index) => (
              <List.Item key={index}>
                <a
                  key={index}
                  className={styles[`Room-card-item`]}
                  href={`${item.link}${search}`}
                >
                  <Image src={item.picture} width={1} height={1} />
                  <div className={styles[`Room-card-name`]}>
                    <span>{item.title}</span>
                  </div>
                </a>
              </List.Item>
            )}
          ></List>
        )}
        {PartnerData && (
          <List
            className={styles["Home-column"]}
            header={
              <Row className={styles["Home-column-title"]} align="middle">
                <Col>{PartnerData.title}</Col>
                {/* <a href="">更多</a> */}
              </Row>
            }
            split={false}
            grid={{ column: 5, gutter: 14 }}
            dataSource={PartnerData.child}
            renderItem={(item, index) => (
              <List.Item key={index}>
                <div
                  className={styles[`PartnerShoperoom-card-box`]}
                  key={index}
                >
                  <a href={item.link}>
                    <Image src={item.picture} width={1} height={1} />
                    <div className={styles[`PartnerShoperoom-Partner-box`]}>
                      {item.title}
                    </div>
                  </a>
                </div>
              </List.Item>
            )}
          ></List>
        )}
      </div>

      {FeatureData && (
        <List
          className={styles["Home-welfare"]}
          grid={{ column: 4, gutter: 20 }}
          dataSource={FeatureData.child}
          renderItem={(item, index) => (
            <List.Item key={index}>
              <div className={styles["Home-welfare-box"]}>
                <div className={styles["Home-welfare-box-icon"]}>
                  <Icon type={item.icon}></Icon>
                </div>
                <div className={styles["Home-welfare-box-title"]}>
                  {item.title}
                </div>
                <div className={styles["Home-welfare-box-descripion"]}>
                  {item.description}
                </div>
              </div>
            </List.Item>
          )}
        ></List>
      )}
    </div>
  );
};

export default React.memo(HomeContent);
