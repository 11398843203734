import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";

import classnames from "classnames";
import * as styles from "./index.scss";
import { RootState } from "../../../store/configureStore";
import { FormattedMessage } from "react-intl";
import { Row, Col, Tag, Space } from "antd";
import Icon from "../../../components/icon";
import Image from "../../../components/image";
import Rate from "../../../wrappers/rate";
import Checkbox from "../../../wrappers/checkbox";
import Input from "../../../wrappers/input";
import { Link, Text } from "../../../wrappers/typography";
import Button, { PrimaryButton } from "../../../wrappers/button";

import { BusinessContext } from "../../BusinessContext";
import { AgentRate } from "../../../models/Shop";
import { Product } from "../../../models/Product";
import { useTheme } from "styled-components";
import { useUpdateEffect } from "ahooks";
import debounce from "lodash/debounce";

import replaceState from "../../../helpers/reolaceState";
interface ProductItemProps {
  isMobile?: boolean;
  checked: boolean;
  onCheck: (e: any) => void;
  img: string;
  number: number;
  product_option: string;
  onChangeNumber: (n: number) => void;
  onBlurChange: (n: number) => void;
  name: string;
  manufacturer: string;
  SKU: string;
  userLike: boolean; // 用户是否收藏 0 是未收藏
  delCartItem: () => void; // 单项删除
  onChangeUserLike: (type: boolean) => void; // 改变单个商品收藏状态
  onChangeInput: (n: number) => void;

  url: string; // 跳转链接
  isActive: number; // 1 为上架, 0 为下架
  symbol: string;
  shopUrl: string;

  agentCode: string;
  customerRating: number;
  attribute: string;
  originalPrice: string;
  originalPriceValue: string;
  tagPrice: string;
  brandName: string;
  seriesName: string;
  productMoq: number;
  index: number;
  proLenght: number;
  logisticsDeliveryText: string;
  template?: string;

  agentRates?: AgentRate[];

  shopId: number;

  item: Product;

  handleDeliveryAvailable: () => boolean;
}

const ProductItem = (props: ProductItemProps) => {
  const { search = "" } = useSelector((state: RootState) => state.URLSearch);
  const { selectedAddress } = useSelector((state: RootState) => state.Cart);

  const { primaryColor, secondaryColor, bussinessPrimaryColor } = useTheme();
  const {
    isMobile,
    shopUrl = "",
    tagPrice,
    agentRates = [],
    shopId,
    agentCode,
    item,
  } = props;
  const { isToBusiness } = useContext(BusinessContext);

  let rate = agentRates.find(r => r.shop_id === Number(shopId));
  if (!rate) {
    rate = agentRates.find(r => r.shop_id === 0);
  }

  const [providerAvailable, setProviderAvailable] = useState(true);

  const notAvailable = props.isActive === 0 || !providerAvailable;

  useUpdateEffect(() => {
    if (selectedAddress && selectedAddress.district_adcode) {
      const matchedDelivery = props.handleDeliveryAvailable();

      setProviderAvailable(matchedDelivery);
    }
  }, [selectedAddress]);

  return (
    <>
      <Row
        align={"middle"}
        className={styles["product"]}
        style={{
          borderBottom:
            props.index !== props.proLenght - 1 ? "1px solid #f0f0f0" : "",
        }}
      >
        <Col span={24} className={styles["agent-box"]}>
          <Space>
            <Text>{`${item.provider_name}提供商品服务`}</Text>
            {!providerAvailable && (
              <Text color={primaryColor} size="0.8em">
                * 请选择其他服务点或配送地址
              </Text>
            )}
          </Space>
          <div>
            {agentCode && (
              <>
                <FormattedMessage id="AgentCode" />：
                <span className={styles["agent-box-code"]}>{agentCode}</span>
              </>
            )}

            {rate && !Boolean(item.activity_type) && (
              <>
                <FormattedMessage id="ExpectedIncome" />：
                <span style={{ color: primaryColor }}>
                  {`¥${(
                    (Number(tagPrice) * rate.commission_rate * props.number) /
                    100
                  ).toFixed(2)}`}
                </span>
              </>
            )}
            {rate &&
              rate.agent_type === 2 &&
              Boolean(item.activity_type) &&
              item.has_commission_flag && (
                <>
                  <FormattedMessage id="ExpectedIncome" />：
                  <span style={{ color: primaryColor }}>
                    {`¥${(
                      (Number(item.activity_price) *
                        Number(rate.commission_rate) *
                        props.number) /
                      100
                    ).toFixed(2)}`}
                  </span>
                </>
              )}
          </div>
        </Col>
        <Col span={5}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              shape="circle"
              borderWidth="2px"
              style={{ marginRight: 10 }}
              disabled={notAvailable}
              checked={props.checked}
              onChange={props.onCheck}
            />
            <div className={classnames(styles[`table-img`])}>
              <a href={`${props.url}${search.replace("?", "&")}`}>
                <Image src={props.img} width={1} height={1}>
                  {!notAvailable && Boolean(item.activity_type) && (
                    <>
                      <div
                        className={styles["table-img_activity-top"]}
                        style={{ background: primaryColor }}
                      >
                        {item.activity_type_text}
                      </div>
                      <div
                        className={styles["table-img_activity-bottom"]}
                        style={{ background: primaryColor }}
                      >
                        {item.activity_type_text}进行中
                      </div>
                    </>
                  )}
                  {notAvailable && (
                    <div className={styles["table-img-mask"]}>
                      <Text color="#fff">
                        <FormattedMessage
                          id={
                            !providerAvailable
                              ? "NotToDelivery"
                              : "NotAvailable"
                          }
                        />
                      </Text>
                    </div>
                  )}
                </Image>
              </a>
            </div>
          </div>
        </Col>
        <Col span={7} style={{ height: "105px" }}>
          <Space size={5} direction="vertical">
            <p>
              <Link
                style={{ marginRight: 10, fontSize: 15 }}
                href={`${props.url}${search.replace("?", "&")}`}
                onClick={e => {
                  e.preventDefault();
                  replaceState.linkTo(props.url);
                }}
              >
                {props.name}
              </Link>
              {props.customerRating > 0 && (
                <Rate
                  value={(props.customerRating * 5) / 100}
                  disabled
                  color={isToBusiness ? bussinessPrimaryColor : primaryColor}
                />
              )}
            </p>
            <p>
              {props.brandName && props.brandName !== "" && (
                <Tag color={secondaryColor}>{props.brandName}</Tag>
              )}
              {props.seriesName && props.seriesName !== "" && (
                <Tag color={secondaryColor}>{props.seriesName}</Tag>
              )}
            </p>
            <p>{props.attribute}</p>
          </Space>
        </Col>
        <Col span={5} style={{ textAlign: "center" }}>
          <div
            className={styles["table-moonlight-input"]}
            style={{ padding: "0px", justifyContent: "center" }}
          >
            <PrimaryButton
              disabled={
                notAvailable ||
                props.number === 1 ||
                props.number === props.productMoq
              }
              icon={<Icon type="icont1_jian" />}
              onClick={debounce(
                () => props.onChangeNumber(props.number - 1),
                800,
              )}
              bgColor={props.number <= 1 ? "#eaeaea" : primaryColor}
            ></PrimaryButton>
            <Input
              disabled={notAvailable}
              value={props.number}
              onBlur={e => props.onBlurChange(Number(e.target.value))}
              onChange={e => props.onChangeInput(Number(e.target.value))}
              style={{
                border: "1px solid " + primaryColor,
                margin: "0px 5px",
                width: "50px",
              }}
            />
            <PrimaryButton
              disabled={notAvailable}
              icon={<Icon type="icont1_jia" />}
              onClick={debounce(
                () => props.onChangeNumber(props.number + 1),
                800,
              )}
              bgColor={primaryColor}
            ></PrimaryButton>
          </div>
          {props.logisticsDeliveryText}
        </Col>
        <Col span={3}>
          <p style={{ fontSize: "1.2em" }}>
            ¥
            {/* {(
              Number(
                Number(props.originalPrice) !== 0
                  ? props.originalPrice
                  : props.tagPrice,
              ) * Number(props.number)
            ).toFixed(2)} */}
            {(
              Number(
                Boolean(item.activity_type)
                  ? item.activity_price
                  : props.tagPrice,
              ) * Number(props.number)
            ).toFixed(2)}
          </p>
          <p>
            <FormattedMessage
              id="LeftParenthesisPricePerItemRigthParenthesis"
              values={{
                price:
                  "¥" +
                  Number(
                    Boolean(item.activity_type)
                      ? item.activity_price
                      : props.tagPrice,
                  ).toFixed(2),
              }}
            />
          </p>
          {Number(props.originalPrice) > Number(props.tagPrice) && (
            <p>
              {/* <FormattedMessage id={"DiscountLabel"} />
            &nbsp; ¥
            {(Number(props.tagPrice) * Number(props.number)).toFixed(2)} */}
              <FormattedMessage id="OriginPrice" /> ¥{" "}
              {Number(props.originalPrice).toFixed(2)}
            </p>
          )}
        </Col>
        <Col span={4} style={{ textAlign: "center" }}>
          <Space size={5}>
            {!notAvailable && (
              <Button
                shape={"circle"}
                onClick={() => {
                  props.onChangeUserLike(props.userLike);
                }}
                icon={
                  <Icon
                    type={props.userLike ? "icont2_shoucang1" : "iconshoucang1"}
                  />
                }
                color={isToBusiness ? bussinessPrimaryColor : primaryColor}
              />
            )}
            <PrimaryButton
              shape={"circle"}
              onClick={props.delCartItem}
              bgColor={primaryColor}
              icon={<Icon type="icondelete" />}
            />
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(ProductItem);
