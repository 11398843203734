import * as React from "react";
import { RootState, Dispatch } from "../../../store/configureStore";
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";
import * as styles from "./index.scss";
import { FormattedMessage } from "react-intl";
import ArrowNav from "./ArrowNav";
import AddressContent from "./Address/index";
import Icon from "../../../components/icon";
import Drawer from "../../../wrappers/drawer";
import Button, { PrimaryButton } from "../../../wrappers/button";
import { Row, Col, message } from "antd";
import TobAddressForm, {
  TobAddressValuesType,
} from "../../Address/TobAddressForm";
import { useMount, useSetState } from "ahooks";
import { useTheme } from "styled-components";

type ComputeModuleProps = {
  selectedIds: string;
  selected?: number;
  classNamePrefix: string;
  quantity: number;
  quantitys: string;
  createOrder: (payload: {
    tobCartId: string;
    tobAddressId: string;
    quantitys: string;
  }) => void;
  changeAddress: (data: any) => void;
  address?: any;
};

type ComputeModuleState = {
  btnclick: boolean;
  drawerVisible: boolean;
  nowSelect: number;
  addressFormOpen: boolean;
};

const ComputeModule: React.FC<ComputeModuleProps> = props => {
  const dispatch = useDispatch<Dispatch>();
  const [state, setState] = useSetState<ComputeModuleState>({
    btnclick: false,
    drawerVisible: false,
    nowSelect: 0,
    addressFormOpen: false,
  });

  /**
   * 获取当前选中的数量合
   */

  const handleAddAddressItem = (addressValues: TobAddressValuesType) => {
    const data = {
      ...addressValues,
      callback: () => {
        setState({
          addressFormOpen: false,
        });
        dispatch.AddressPage.setTobAddress();
      },
    };

    dispatch.AddressPage.addTobAddress(data);
  };

  const { quantitys, selectedIds, changeAddress } = props;

  const { bussinessPrimaryColor } = useTheme();
  const address = props.address;
  address?.map((item: any, index: number) => {
    if (index === state.nowSelect) {
      item.is_select = 1;
    }
  });
  const handleAddressChange = (nowIndex: number) => {
    address.map((item: any, index: number) => {
      if (index === nowIndex) {
        item.is_select = 1;
      } else {
        item.is_select = 0;
      }
    });
  };

  return (
    <>
      <ArrowNav
        id={"address-arrow"}
        leftIcon={<Icon type="icont1_dizhi" />}
        onClick={() => {
          if (address.length === 0) {
            setState({
              addressFormOpen: true,
            });
          } else {
            setState({
              drawerVisible: true,
            });
          }
        }}
      >
        {address && address.length > 0 && (
          <>
            <div>
              <div style={{ fontSize: "1.1rem" }}>{`${
                address[state.nowSelect].recipient_name
              } ${address[state.nowSelect].recipient_telephone}`}</div>
              <div>{address[state.nowSelect].recipient_email}</div>
              <div>{address[state.nowSelect].recipient_company}</div>
              <div>
                {address[state.nowSelect].province}
                {address[state.nowSelect].city}
                {address[state.nowSelect].area}
                {address[state.nowSelect].address_details}
              </div>
            </div>
          </>
        )}
        {(!address || address.length === 0) && (
          <span className={classnames(styles[`compute-nocontact`])}>
            <FormattedMessage id="PleaseSelectUserContact" />
          </span>
        )}
      </ArrowNav>

      <PrimaryButton
        style={{
          fontSize: "1.4em",
        }}
        bgColor={props.selected === 0 ? "#888888" : bussinessPrimaryColor}
        disabled={props.selected === 0}
        className={classnames(styles[`compute-computer-btm`])}
        onClick={() => {
          if (!address || address.length === 0 || !address[state.nowSelect]) {
            return message.error("请选择联系方式");
          }
          props.createOrder({
            tobCartId: selectedIds,
            tobAddressId: address[state.nowSelect].tob_address_id,
            quantitys: quantitys,
          });
        }}
      >
        <FormattedMessage id="ImmediatelyQuote" />
        &nbsp;
        <Icon type="icont1_correct"></Icon>
      </PrimaryButton>
      <TobAddressForm
        isOpen={state.addressFormOpen}
        onClose={() => {
          setState({
            addressFormOpen: false,
          });
        }}
        onSubmit={handleAddAddressItem}
      />
      <Drawer
        color={bussinessPrimaryColor}
        visible={state.drawerVisible}
        onClose={() => {
          setState({
            drawerVisible: false,
          });
        }}
        title={"选择地址"}
        width={"600px"}
      >
        <AddressContent
          changeAddress={(index: number) => {
            setState({
              nowSelect: index,
              drawerVisible: false,
            });
            handleAddressChange(index);
            changeAddress(address[index]);
          }}
        />
      </Drawer>
    </>
  );
};

export default React.memo(ComputeModule);
