import { GraphqlField, GraphqlRefField } from "../helpers/decorators";
import BaseModel from "./BaseModel";
export class OrderProduct extends BaseModel {
  @GraphqlField("order")
  public refund_type: number;
  @GraphqlField("order")
  public after_sale_flag: number;
  @GraphqlField("order")
  public after_show_status: number;
  @GraphqlField("order")
  public product_status: number;
  @GraphqlField("order")
  public product_status_text: number;

  @GraphqlField("toborder", "order")
  public order_product_id: number;
  @GraphqlField("toborder", "order")
  public shop_product_id: number;
  @GraphqlField("toborder", "order")
  public product_name: string;
  @GraphqlField("toborder", "order")
  public product_picture: string;
  @GraphqlField("order")
  public provider_id: number;
  @GraphqlField("toborder", "order")
  public quantity: number;
  @GraphqlField("toborder", "order")
  public series_name: string;
  @GraphqlField("toborder", "order")
  public attribute: string;
  @GraphqlField("toborder", "order")
  public brand_name: string;
  @GraphqlField("toborder", "order")
  public original_price: string;
  @GraphqlField("toborder", "order")
  public original_price_value: string;
  @GraphqlField("toborder", "order")
  public tag_price: string;
  @GraphqlField("toborder")
  public has_tag_price_range: number;
  @GraphqlField("toborder", "order")
  public tag_price_value: string;
  @GraphqlField("toborder")
  public tag_price_low: string;
  @GraphqlField("toborder")
  public tag_price_high: string;
  @GraphqlField("order")
  public is_review: string;
  @GraphqlField("order")
  public activity_type: string;
  @GraphqlField("order")
  public activity_type_text: string;

  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}
export class Order extends BaseModel {
  public page: string;
  public action: string;
  public start_time: string;

  public end_time: string;
  @GraphqlField("order")
  public order_id: string;

  @GraphqlField("toborder")
  public tob_order_id: number;

  @GraphqlField("toborder", "order")
  public shop_id: number;
  @GraphqlField("toborder", "order")
  public shop_name: string;

  @GraphqlField("order")
  public shop_url: string;

  @GraphqlField("toborder", "order")
  public order_status: number;
  @GraphqlField("toborder", "order")
  public order_status_text: string;

  @GraphqlField("toborder", "order")
  public recipient_name: string;

  @GraphqlField("order")
  public has_return: number;

  @GraphqlField("order")
  public has_return_text: string;
  @GraphqlField("order")
  public has_refund: number;
  @GraphqlField("order")
  public has_refund_text: string;
  @GraphqlField("order")
  public order_amount: string;
  @GraphqlField("order")
  public order_amount_text: string;

  @GraphqlField("order")
  public review_status: number;

  @GraphqlField("toborder", "order")
  public created_at: string;
  @GraphqlField("order")
  public expired_at: string;
  @GraphqlField("order", "toborder")
  public order_number: string;
  @GraphqlField("order")
  public remark: string;

  @GraphqlRefField(OrderProduct, "toborder", "order")
  public product: OrderProduct[] = [];
  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}

export class OrderStatus extends BaseModel {
  public order_id: string;
  @GraphqlField()
  public order_status: string;
  @GraphqlField()
  public updated_at: string;
  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}

export class LogisticsTraces extends BaseModel {
  @GraphqlField()
  logistics_time: string;
  @GraphqlField()
  logistics_state: string;
}
export class OrderLogistics extends BaseModel {
  @GraphqlField()
  public order_product_id: number;

  @GraphqlField()
  public provider_address: string;
  @GraphqlField()
  public delivery_contact_telephone: string;
  @GraphqlField()
  public logistics_express_number: string;
  @GraphqlField()
  public delivery_contact_person: string;
  @GraphqlField()
  public estimated_delivery_date: string;
  @GraphqlField()
  public logistics_express_company: string;
  @GraphqlField()
  public shipping_method: string;

  @GraphqlRefField(LogisticsTraces)
  public traces: LogisticsTraces[] = [];

  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}

export class OrderQrcode extends BaseModel {
  public order_id: number;

  @GraphqlField()
  public url: string;

  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}

export class OrderPayType extends BaseModel {
  public action: string;
  public order_id: number;

  @GraphqlField()
  public result: string;
  @GraphqlField()
  public beyond_limit: string;

  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}

export class OrderTotal extends BaseModel {
  public action: string;
  public start_time: string;
  public end_time: string;
  public order_number: string;

  @GraphqlField()
  public total: string;

  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}

export class OrderCollect extends BaseModel {
  public collect: string;

  @GraphqlField()
  public data_id: string;

  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}

export class OrderDetailsAddress extends BaseModel {
  @GraphqlField()
  public recipient_name: string;

  @GraphqlField()
  public recipient_telephone: string;

  @GraphqlField()
  public recipient_address: string;

  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}

export class TobOrderDetailsAddress extends BaseModel {
  @GraphqlField()
  public recipient_name: string;

  @GraphqlField()
  public recipient_telephone: string;

  @GraphqlField()
  public recipient_address: string;
  @GraphqlField()
  public recipient_email: string;
  @GraphqlField()
  public recipient_company: string;

  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}

export class OrderDetailsProduct extends BaseModel {
  @GraphqlField()
  public order_product_id: number;

  @GraphqlField()
  public shop_product_id: number;
  @GraphqlField()
  public product_name: string;
  @GraphqlField()
  public product_status: number;
  @GraphqlField()
  public product_status_text: string;
  @GraphqlField()
  public product_picture: string;
  @GraphqlField()
  public express_type: string;
  @GraphqlField()
  public express_number: string;
  @GraphqlField()
  public express_method: string;
  @GraphqlField()
  public provider_id: number;
  @GraphqlField()
  public provider_name: string;
  @GraphqlField()
  public url: string;
  @GraphqlField()
  public customer_rating: number;
  @GraphqlField()
  public quantity: number;
  @GraphqlField()
  public series_name: string;
  @GraphqlField()
  public attribute: string;
  @GraphqlField()
  public brand_name: string;
  @GraphqlField()
  public original_price: string;
  @GraphqlField()
  public sku_sn: string;
  @GraphqlField()
  public agent_code: string;
  @GraphqlField()
  public shop_name: string;
  @GraphqlField()
  public original_price_value: string;
  @GraphqlField()
  public tag_price: string;
  @GraphqlField()
  public tag_price_value: string;
  @GraphqlField()
  public is_review: string;
  @GraphqlField()
  public after_sale_flag: number;
  @GraphqlField()
  public after_show_status: number;
  @GraphqlField()
  public logistics_delivery_text: string;
  @GraphqlField()
  public activity_type: string;
  @GraphqlField()
  public activity_type_text: string;

  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}

export class ToOrderDetailsProduct extends BaseModel {
  @GraphqlField()
  public product_name: string;

  @GraphqlField()
  public product_picture: string;
  @GraphqlField()
  public shop_product_id: number;
  @GraphqlField()
  public express_type: string;
  @GraphqlField()
  public agent_code: string;
  @GraphqlField()
  public express_number: string;
  @GraphqlField()
  public express_method: string;
  @GraphqlField()
  public provider_id: number;
  @GraphqlField()
  public provider_name: string;
  @GraphqlField()
  public url: string;
  @GraphqlField()
  public customer_rating: number;
  @GraphqlField()
  public quantity: number;
  @GraphqlField()
  public series_name: string;
  @GraphqlField()
  public attribute: string;
  @GraphqlField()
  public brand_name: string;
  @GraphqlField()
  public original_price: string;

  @GraphqlField()
  public shop_name: string;

  @GraphqlField()
  public tag_price: string;
  @GraphqlField()
  public tag_price_value: string;
  @GraphqlField()
  public tag_price_low: string;
  @GraphqlField()
  public tag_price_high: string;
  @GraphqlField()
  public has_tag_price_range: number;

  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}

export class OrderDetails extends BaseModel {
  @GraphqlField()
  public order_number: string;
  @GraphqlField()
  public order_id: number;
  @GraphqlField()
  public shop_id: number;
  @GraphqlField()
  public shop_name: string;
  @GraphqlField()
  public shop_phone: string;
  @GraphqlField()
  public platform_phone: string;
  @GraphqlField()
  public business_time: string;
  @GraphqlField()
  public shop_url: string;
  @GraphqlField()
  public payment_method: string;
  @GraphqlField()
  public payment_method_text: string;
  @GraphqlField()
  public order_status: number;
  @GraphqlField()
  public order_status_text: string;
  @GraphqlField()
  public recipient_name: string;
  @GraphqlField()
  public order_amount: string;
  @GraphqlField()
  public agent_code: string;

  @GraphqlField()
  public created_at: string;
  @GraphqlField()
  public review_status: number;
  @GraphqlField()
  public carriage: number;
  @GraphqlField()
  public discount: number;
  @GraphqlField()
  public payment_price: string;
  @GraphqlField()
  public expired_at: string;
  @GraphqlField()
  public now_at: string;
  @GraphqlField()
  public remark: string;

  @GraphqlRefField(OrderDetailsProduct)
  public product: OrderDetailsProduct[] = [];
  @GraphqlRefField(OrderDetailsAddress)
  public address: OrderDetailsAddress[] = [];

  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}

export class TobOrderDetails extends BaseModel {
  @GraphqlField()
  public order_number: string;
  @GraphqlField()
  public tob_order_id: number;
  @GraphqlField()
  public shop_id: number;
  @GraphqlField()
  public shop_name: string;
  @GraphqlField()
  public created_at: string;
  @GraphqlField()
  public platform_phone: string;
  @GraphqlField()
  public business_time: string;
  @GraphqlField()
  @GraphqlRefField(ToOrderDetailsProduct)
  public product: ToOrderDetailsProduct[] = [];
  @GraphqlRefField(TobOrderDetailsAddress)
  public address: TobOrderDetailsAddress[] = [];

  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}

export class ShareOrderData extends BaseModel {
  @GraphqlField()
  public is_active: number;
  @GraphqlField()
  public quantity: number;
  @GraphqlField()
  public shop_product_id: number;
  @GraphqlField()
  public share_customer_agent_code: string;
  @GraphqlField()
  public shop_sku_id: number;

  @GraphqlField()
  public product_name: string;
  @GraphqlField()
  public attribute: string;
  @GraphqlField()
  public product_picture: string;
  @GraphqlField()
  public brand_name: string;
  @GraphqlField()
  public url: string;
  @GraphqlField()
  public series_name: string;
  @GraphqlField()
  public tag_price: string;
  @GraphqlField()
  public tag_price_low: string;
  @GraphqlField()
  public tag_price_high: string;
  @GraphqlField()
  public has_tag_price_range: number;

  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}

export class ShareOrder extends BaseModel {
  public token: string;
  @GraphqlField()
  public shop_id: number;
  @GraphqlField()
  public shop_name: string;
  @GraphqlField()
  public shop_url: string;
  @GraphqlField()
  public order_time: string;
  @GraphqlField()
  public order_number: string;
  @GraphqlField()
  public recipient_name: string;
  @GraphqlField()
  public payment_amount: string;
  @GraphqlRefField(ShareOrderData)
  public share_data: ShareOrderData[] = [];

  @GraphqlField()
  has_contract: boolean;

  @GraphqlField()
  sign_data: string;
  @GraphqlField()
  contract_file: string;
  @GraphqlField()
  contract_customer_name: string;
  @GraphqlField()
  contract_address: string;
  @GraphqlField()
  order_contract_id: number;
  @GraphqlField()
  contract_status: number;
  @GraphqlField()
  recipient_telephone: number;

  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}
