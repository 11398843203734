import * as React from "react";
import Content from "./Content";
// import SwitchLanguage from "../Header/component/SwitchLanguage";
import Layout from "../Layout";

import styles from "./index.scss";

const LoginPage: React.FC = () => {
  return (
    <Layout isFooterVisible={false} anonymousHeader>
      <div className={styles["login"]}>
        <Content />

        <div className={styles["login-f"]}>
          Copyright ©2018 - 2020 MegaHome LLC. All rights reserved.
        </div>
      </div>
    </Layout>
  );
};

export default LoginPage;
