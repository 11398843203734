import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, Dispatch } from "../../../store/configureStore";
import Icon from "../../../components/icon";
import { List, Popover, Row, Col, Cascader, Button, Spin } from "antd";
import RcImage from "../../../components/image";
import Modal from "../../../wrappers/modal";
import Select from "../../../wrappers/select";
import { Text } from "../../../wrappers/typography";
import InfiniteScroll from "react-infinite-scroller";
import debounce from "lodash/debounce";
import { useIntl } from "react-intl";
import { useUpdateEffect } from "ahooks";
import groupBy from "lodash/groupBy";
import transform from "lodash/transform";
import styles from "../style.scss";

const MyDesign = (props: {}) => {
  const { formatMessage: f } = useIntl();

  const dispatch = useDispatch<Dispatch>();
  const {
    currIdea,
    ideaList = [],
    filterList,
    hasMoreIdeas = true,
  } = useSelector((state: RootState) => state.DesignIdea);

  const {
    getIdeaListAsync: ideaListLoading,
    getRejectRemarkAsync: ideaRejectLoading,
  } = useSelector((state: RootState) => state.loading.effects.DesignIdea);

  const [ideaFilters, setIdeaFilters] = useState<{ [key: string]: string }>({});

  const [ideaPage, setIdeaPage] = useState(0);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [rejectRemark, setRejectRemark] = useState("");

  useUpdateEffect(() => {
    const params = {
      ...ideaFilters,
      page: ideaPage,
      pageSize: 20,
      reset: ideaPage === 1,
    };

    dispatch.DesignIdea.getIdeaListAsync(params);
  }, [ideaFilters, ideaPage]);

  const ideaListFilters = [
    {
      key: 0,
      value: "publish",
      text: "全部",
      data: [
        { label: "未发布", value: "0" },
        { label: "已发布", value: "6" },
      ],
    },
    {
      key: 1,
      value: "house_type",
      text: "户型",
      data: filterList?.HouseType.map(t => ({
        label: t.house_type_name,
        value: t.house_type,
      })),
    },
    {
      key: 2,
      value: "house_area",
      text: "面积",
      data: filterList?.HouseArea.map(t => ({
        label: t.area_name,
        value: `${t.area_low}-${t.area_high}`,
      })),
    },
    {
      key: 3,
      value: "room",
      text: "房间",
      data: filterList?.Room.map(t => ({
        label: t.room_name,
        value: t.room_id,
      })),
    },
    {
      key: 4,
      value: "style",
      text: "风格",
      data: filterList?.Style.map(t => ({
        label: t.style_name,
        value: t.style_id,
      })),
    },
    {
      key: 5,
      value: "color",
      text: "颜色",
      data: filterList?.Color.map(t => ({
        label: t.color_name,
        value: t.color_id,
      })),
    },
    {
      key: 6,
      value: "house_cost",
      text: "花费",
      data: filterList?.HouseCost.map(t => ({
        label: t.cost_name,
        value: `${t.cost_low}-${t.cost_high}`,
      })),
    },
    {
      key: 7,
      value: "position",
      text: "位置",
      data: transform(
        groupBy(filterList?.Location, "province"),
        (prev, value, key) => {
          prev.push({
            label: key,
            value: key,
            children: value.map(v => ({ label: v.city, value: v.city })),
          });
        },
        [] as { label: string; value: string; children: any }[],
      ),
    },
  ];

  const handleIdeaFilter = (
    filterProp: string,
    filterValue: string | string[],
  ) => {
    setIdeaPage(1);

    if (filterProp === "position") {
      const [province, city] = filterValue;

      setIdeaFilters(f => ({ ...f, city }));
    } else if (filterProp === "house_area") {
      const [area_low, area_high] = filterValue?.split("-") || [];

      setIdeaFilters(f => ({ ...f, area_low, area_high }));
    } else if (filterProp === "house_cost") {
      const [cost_low, cost_high] = filterValue?.split("-") || [];

      setIdeaFilters(f => ({ ...f, cost_low, cost_high }));
    } else {
      setIdeaFilters(f => ({ ...f, [filterProp]: filterValue }));
    }
  };

  const getRejectedRemark = debounce((designIdeaId: number) => {
    const data = {
      designIdeaId,
      callback: (res: string) => {
        setRejectRemark(res);
      },
    };
    dispatch.DesignIdea.getRejectRemarkAsync(data);
  }, 500);

  return (
    <>
      <List
        style={{ marginTop: "10px", paddingRight: "4px" }}
        grid={{ gutter: 10, column: 4 }}
        dataSource={ideaListFilters}
        renderItem={item => (
          <List.Item key={item.key}>
            {item.value === "position" ? (
              <Cascader
                options={item.data}
                placeholder={item.text}
                suffixIcon={
                  <Icon type="icont1_xiangxia" style={{ color: "#4b4b4b" }} />
                }
                onChange={val => handleIdeaFilter(item.value, val)}
                notFoundContent="暂无筛选项"
              ></Cascader>
            ) : (
              <Select
                allowClear
                placeholder={item.text}
                suffixIcon={
                  <Icon type="icont1_xiangxia" style={{ color: "#4b4b4b" }} />
                }
                onChange={val => handleIdeaFilter(item.value, val)}
                notFoundContent="暂无筛选项"
                borderRadius="5px"
                style={{ width: "100%" }}
              >
                {item.data?.map(d => (
                  <Select.Option value={d.value} key={d.value}>
                    {d.label}
                  </Select.Option>
                ))}
              </Select>
            )}
          </List.Item>
        )}
      />
      <div
        className={styles["materials-container-scroller"]}
        style={{ maxHeight: "calc(100vh - 175px)", marginTop: 0 }}
      >
        <InfiniteScroll
          // initialLoad={false}
          // pageStart={1}
          loadMore={debounce(() => setIdeaPage(p => p + 1), 1000)}
          hasMore={!ideaListLoading && hasMoreIdeas}
          useWindow={false}
          style={{ width: "100%" }}
        >
          <List
            locale={{
              emptyText: <Text color="#fff">暂无设计！</Text>,
            }}
            grid={{ gutter: 10, column: 2 }}
            dataSource={ideaList}
            renderItem={item => (
              <List.Item key={item.key}>
                <a
                  onClick={() =>
                    dispatch.DesignIdea.getIdeaDetailAsync(item.design_idea_id)
                  }
                  className={styles["idea-list-item"]}
                >
                  <RcImage
                    height={75}
                    width={127}
                    src={item.design_idea_picture}
                  >
                    <Icon
                      type="iconcuowu1-copy"
                      className={styles["idea-list-del"]}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSelectedId(item.design_idea_id);

                        Modal.confirm({
                          title: "删除确认",
                          content: "是否确认删除您的设计？",
                          okText: "确认",
                          cancelText: "取消",
                          onOk() {
                            dispatch.DesignIdea.deleteIdeaAsync({
                              design_idea_id: selectedId,
                              callback: () => {
                                setSelectedId(0);
                                setDeleteModalOpen(false);
                                if (selectedId === currIdea.design_idea_id) {
                                  // location.href = "/design-idea";
                                  dispatch.DesignIdea.setCurrIdea({
                                    products: [],
                                    coordinates: [],
                                  });
                                }
                              },
                            });
                          },
                          onCancel() {
                            setSelectedId(0);
                          },
                        });
                      }}
                    ></Icon>

                    {item.design_idea_status < 6 &&
                      item.design_idea_status !== 3 && (
                        <div className={styles["idea-list-wait"]}>待</div>
                      )}

                    {item.design_idea_status === 3 && (
                      <Popover
                        content={
                          <div style={{ maxWidth: "250px" }}>
                            {!ideaRejectLoading && rejectRemark}
                            {ideaRejectLoading && <Spin />}
                          </div>
                        }
                        placement="bottom"
                      >
                        <div
                          className={styles["idea-list-error"]}
                          onMouseOver={() =>
                            getRejectedRemark(item.design_idea_id)
                          }
                          onMouseOut={() => setRejectRemark("")}
                        >
                          审核未通过
                        </div>
                      </Popover>
                    )}
                    <div className={styles["idea-list-name"]}>
                      <span>{item.design_idea_name}</span>
                    </div>

                    <div className={styles["idea-list-goedit"]}>继续编辑</div>
                  </RcImage>
                </a>
              </List.Item>
            )}
          >
            {ideaListLoading && hasMoreIdeas && (
              <div className={styles["infinite-loading-container"]}>
                <Spin />
              </div>
            )}
          </List>
        </InfiniteScroll>
      </div>
    </>
  );
};

export default MyDesign;
