export default {
  Messages: "ニュース",
  CurrentOrder: "現在の注文",
  OrderHistory: "注文履歴",
  Review: "レビュー",
  YouDoNotHaveAnyMessageToShow: "表示するメッセージがありません。",
  YouHaveNotPLaceAnyOrderYetPeriod: "まだ注文していません。",
  ViewOrderDetails: "注文の詳細を表示",
  ThereIsNoHistoricalOrder:
    "あなたはまだ注文していません、歴史的な注文はありません、適切な製品を選んでください！",
  ToSpeedUptheCheckOut:
    "チェックアウトを高速化するには、別の住所を追加するには、「新しい住所を追加」をクリックしてフォームに入力し、「保存」ボタンをクリックします。既存のアドレスを編集または削除する場合は、既存のアドレスの適切なリンクをクリックしてください。",
  More: "もっと",
  Binding: "製本",
  BindingReferenceCode: "参照コードのバインド",
  ChangePassword: "パスワードを変更する",
  ReferenceCodeColon: "参照コード：",
  PersonalInformation: "個人情報",
  FirstName: "名",
  LastName: "姓",
  Gender: "性別",
  DateOfBirth: "誕生日",
  Email: "電子メール",
  CellphoneNumber: "携帯電話番号",
  CurrentPassword: "現在のパスワード",
  NewPassword: "新しいパスワード",
  ConfirmPassword: "パスワードを認証する",
  Password: "パスワード",
  PleaseInputValidDateWithFormat:
    "「XXXX-XX-XX」の形式で有効な日付を入力してください",
  PasswordDoesNotMatch: "パスワードが一致しません",
  BindingSuccessed: "バインド成功",
  UnbindingSuccessed: "拘束力のない成功",
  TheReferenceCodeReachesTheUpperLimitOf: "参照コードが上限の20に達しました。",
  ReferenceCodeHasBeenBound:
    "参照コードがバインドされています。もう一度お試しください。",
  InvalidReferenceCodePeriodPleaseEnterValidNumber:
    "無効な参照コード。 有効な数字と文字を入力してください。",
  Name: "名",
  Address: "住所",
  PhoneNumber: "電話番号",
  Alias: "エイリアス",
  Organization: "会社名",
  PaymentMethod: "支払方法",
  TotalInToal: "{total}合計",
  OrderDetails: "注文詳細",
  ConfirmTheOrder: "注文を確認する",
  PlseaseConfirmYourOrder: "ご注文を確認してください。",
  CancelTheOrder: "注文を取り消す",
  AreyouConfirmToCancelYourOrder: "注文をキャンセルしてもよろしいですか？",
  YouCanAddUpToTwentyAddress:
    "最大20個のアドレスを追加できます。 {num}個のアドレスを追加しました",
  AddYourAddressByClicking: "[新しい住所を追加]をクリックして住所を追加します",
  AddingShippingAddress: "配送先住所の追加",
  LineOne: "住所：最初の行",
  LineTwo: "住所：2行目",
  City: "市",
  StateSlashProvince: "州",
  PostalCode: "郵便番号",
  Country: "国",
  CountryCode: "国コード",
  Note: "メッセージを残す",
  CouponSlashReferenceCode: "クーポン/参照コード",
  EnterEmailAddress: "メールアドレスを入力してください",
  OrderStatus: "注文の状況",
  ConsigneeInformation: "荷受人情報",
  PaymentInformaton: "Payment Info",
  Quotation: "価格見積り",
  ReviewProduct: "製品のレビュー",
  TotalPrice: "合計 {価格}",
  PriceSlashItem: "{価格} /個",
  AssemblyManualLeftParenthesisComingSoonRightParenthesis:
    "組立説明書（近日公開）",
  CustomerService: "顧客サービス",
  WelcomeBack: "お帰りなさい",
  SignUp: "サインアップ",
  EmailVerification: "メール確認",
  ForgotPasswordDashStepTwo: "パスワードを忘れた-ステップ2",
  ForgotPasswordDashStepOne: "パスワードを忘れた-ステップ1",
  ResetPassword: "パスワードを再設定する",
  ForgotPassword: "パスワードをお忘れですか",
  InvalidEmailOrPassword: "無効なメールアドレスまたはパスワード",
  WelcomeToMegaHome: "MegaHome へようこそ",
  AlreadyHaveAnAccountQuestionMark: "すでにアカウントをお持ちですか？",
  PleaseLogin: "ログインしてください",
  EnterThePassword: "パスワードを入力してください",
  Industry: "業界",
  PleaseCheckYourEmail: "メールを確認してください",
  IfYouDoNotReciveTheEmail:
    "数分経ってもメールが届かない場合は、スパムフォルダを確認してください。",

  IHaveReadAndAcceptedMegahomeTermsOfService:
    "<a>MegaHome利用規約</a>を読み、同意しました。",
  WeHaveSentYouAConfirmationEmailEmail:
    "「<span> {email} </span>」に確認メールを送信しました。 メールボックスをチェックして、アカウントを確認してください。",
  WeHaveSentYouAResetLink:
    "リセットリンクをメール「<span> {email} </span>」で送信しました。 メールボックスをチェックして、リセットリンクをクリックしてください。",
  DidNotRevicedEmailQuestionMark: "メールを受信しませんでしたか？",
  ThisEmailAddressHasAlreadyBeenRegistered:
    "このメールアドレスはすでに登録されています。ログインしてください。",

  BackToMainMenu: "メインメニューに戻る",
  Back: "戻る",
  Homepage: "ホームページ",
  ComingSoon: "近日公開",
  ShopTheBrand: "店舗ブランド",
  Account: "アカウント",

  SignOut: "サインアウト",
  SendAgain: "もう一度送信",
  BackToHomepage: "ホームページに戻ります",
  TermOfService: "サービス利用規約",
  NextStep: "次の一歩",
  MarketplaceHome: "ホームページ",
  Category: "カテゴリー",
  Room: "部屋",
  NotAvailable: "撤回",
  ToReview: "レビューを書く",
  ByUsingThisSiteYouAgreeToThe:
    "このサイトを使用することにより、お客様は分析、パーソナライズされたコンテンツや広告のためのクッキーの使用に同意します。",
  FindOutMore: "詳細をご覧ください",
  Metric: "メートル法",
  Imperial: "インペリアル",
  Home: "Hogar",
  Firm: "企業",
  ReadAll: "すべて既読にする",
  DeleteAll: "すべて削除",
  AddNewAddress: "新しい住所を追加",
  Confirm: "確認する",
  Cancel: "キャンセル",
  SetAsDefault: "デフォルトに設定",
  Default: "デフォルト",
  Required: "必須",
  TleLengthOfTheInputExceedNumCharacters:
    "入力は少なくとも {num} 文字である必要があります",
  TheLengthOfTheInputMustBeAtLeast:
    "入力の長さは少なくとも {num} 文字である必要があります",
  PleaseEnterAValidEmailAddressExclamationMark:
    "有効なメールアドレスを入力してください！",
  Loading: "読み込み中",
  Male: "男性",
  Female: "女性",
  Other: "その他",
  ByShopName: "から",
  Odm: "ODM",
  Oem: "OEM",
  Customization: "カスタマイズ",
  GetThePriceCommaSpecificationsCommaShipping:
    "価格、仕様、送料などの情報を無料で入手できます！",
  SignUpNow: "今すぐサインアップ",
  Quantity: "数量",
  Submit: "提出する",
  Collection: "お気に入り",

  AddToCart: "カートに追加",
  PleaseSelectOptions: "オプションを選択してください",
  ContinueToCart: "ショッピングカートに入る",
  DisabledPeople: "無効",
  OopsCommaUploadFileExceedsMaximunSize:
    "アップロードしたファイルは10Mを超えています。もう一度選択してください。",
  OopsCommaUploadFileIsNotAValidImage:
    "アップロードしたファイルは有効な画像形式ではありません。JPGファイルとPNGファイルのみを使用できます。 もう一度アップロードしてください。",
  Cart: "カート",
  LeftParenthesisPricePerItemRigthParenthesis: "（アイテムあたり{price}）",
  AddedToCart: "カートに追加しました",
  Specifications: "仕様書",
  Shipping: "発送情報",
  Price: "価格",
  PrintLabel: "ラベル印刷",
  PrintQrCode: "QRコードを印刷する",
  YouCanSave: "あなたは保存することができます",
  Product: "商品",
  ToDo: "運営",
  Total: "合計",
  ContactUs: "お問い合わせください",
  Quote: "お問い合わせ",
  RemoveFromCart: "カートから削除されました",
  SelectAll: "すべて選択",
  SignIn: "ログイン",
  ContinueShopping: "買い物を続ける",
  Login: "ログイン",

  SectionsInMegahomeMarketplace:
    "<a href='/termsofservice/#disclaimer'>MegaHome Marketplace</a>カテゴリーから",
  ItemsInMegahomeMarketplace:
    "<a href='/termsofservice/#disclaimer'>MegaHome Marketplace</a> の製品",
  MegaHomeRecommended: "MegaHome推奨",
  Viewed: "閲覧時間",
  Size: "サイズ",
  Material: "材料",
  Color: "色",
  Style: "スタイル",
  MoreFilters: "より多くのフィルタ",
  Clear: "クリア",
  CountResults: "{count}の結果",
  NewArrivals: "新製品",
  Selected: "選ばれた",
  DashDashNoPreferenceDashDash: "-- 選択してください --",
  DesignIdeas: "コロケーションのアイデア",
  NumIdeas: " {num}のデザインアイデア",
  Hide: "隠す",
  Show: "表示",
  ConnectWithUs: "私達と接続",
  MegaHomeUsAddress: "39055 Hastings Street, Suite 208 Fremont, CA 94538",
  WeHaveRevicedYourFeedback:
    "あなたの意見をいただきました。できるだけ早くご連絡いたします。",
  SendAMessage: "メッセージを送る",
  TellUsAboutYourNeeds: "ニーズを報告してください",
  Inquire: "相談",
  Feedback: "フィードバック",
  Partnership: "協力関係",
  ItHelp: "技術サポート",
  Others: "その他",
  HotelVendor: "ホテルサプライヤー",
  Retailers: "小売業者",
  Designers: "デザイナー",
  Suppliers: "仕入先",
  About: "情報",
  AboutUs: "私たちに関しては",
  Join: "参加する",
  Marketing: "マーケティング",
  Disclaimer: "免責事項",
  Apply: "適用する",
  Return: "に戻る",
  LookingForMoreStylesQuestionMark: "他のスタイルをお探しですか？",
  FindMore: "もっと見つけます",
  Share: "私の考えを共有する",
  AddToCartSuccessfullyExclamationMark: "カートに追加しました！",
  ProductDetails: "製品詳細",
  Description: "説明",
  WarningColonLeftBracketCaliforniaPropositionSixtyFive:
    "警告：[California Proposition 65]製品の安全性については、サプライヤーに確認してください。",
  Features: "特徴",
  ProductFeatures: "製品の特徴",
  ComponentsData: "コンポーネントデータ",
  Compare: "比較する",
  Reviews: "レビュー",
  SimilarProducts: "類似製品",
  BuyTogether: "一緒に購入",
  ComparewithSimilarPorducts: "類似製品と比較する",
  CustomerRating: "顧客の評価",
  SortByMostRecently: "最近の並べ替え",
  SortByRating: "評価で並べ替え",
  ReviewProductFromOrder: "注文から製品を確認する",
  PleaseReviewOurProductsComma:
    "当社の製品、あなたの経験を確認し、他のユーザーと共有してください。",
  AsteriskSignYouDoNotHaveFinishedOrder:
    "*レビューする製品の注文が完了していません。",
  MoreReviews: "その他のレビュー",
  MadeInCountry: "{country}製",
  PriceIncluded: "料金に含まれるもの",
  MinPeriod: "最低",
  MaxPeriod: "最高",
  MyShopingCart: "ショッピングカート",
  CountItems: "{count}アイテム",
  PleaseConfirm: "確認してください",
  AreYouConfirmToRemoveTheseItems:
    "これらのアイテムをカートから削除してもよろしいですか？",
  YouHaveItemAddedWithDifferentCurrencyType:
    "異なる通貨タイプのアイテムが追加されました。 引用する通貨を1つだけ選択してください。",
  OopsExclamationMarkYouShoppingCartIsEmpty:
    "おっとっと！ ショッピングカートは空です。",
  SignInToSeeWhatYouHaveAddedOrSaved:
    "サインインして、追加または保存した内容を確認します。",
  WeHaveALotOfSpecialProductOffers:
    "多くの特別な製品を提供しています。または、当社のウェブサイトからインスピレーションを得ることができます。",
  SelectedColonCountItems: "選択：{count}個の製品",
  StartFrom: "から始まる",
  ContainersFittingEstimate: "コンテナフィッティングの見積もり",
  QuoteInformation: "お問い合わせ情報",
  SubmittedExclamationMark: "提出済み！",
  SelectFromSavedAddress: "保存したアドレスから選択",
  WeWillGetBackToYou: "できるだけ早くご連絡いたします！",

  OopsExclamationMark: "おっと！",
  WeCannotFindAnyResultThatMatchYour: "検索に一致する結果はありません:",
  ThePageTitleStillUnderConstructionPeriod:
    "ページ{title}はまだ作成中です。 お待ちいただいてありがとうございます！",
  SometingYouMayLikeColon: "あなたが好きかもしれないもの：",
  OopsExclamationMarkCurrentFilterOptionsDoNot:
    "おっとっと！ 現在のフィルターオプションには結果がありません。再選択してください。",
  SortAndFilter: "並べ替えとフィルター",
  Supplier: "サプライヤー",
  Shop: "店铺",
  ShopEstablishedDate: "ショップ設立日",
  ShopRecommendedProducts: "おすすめ商品を見る",
  FurnitureAndDecor: "家具＆インテリア",
  SearchInThisShop: "このショップで検索",
  Copyright: "著作権",
  SearchItemsFromShop: "{shop} で商品を検索します",
  Categories: "推奨カテゴリー",
  Products: "製品リスト",
  OptionNotAvailable: "利用できないオプション",
};
