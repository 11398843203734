import * as React from "react";
import { connect } from "react-redux";
import Layout from "../Layout";
import ShopContent from "./Content";
import ProductListContent from "../ProductList/Content";
import { RootState } from "../../store/configureStore";

const mapStateToProps = (state: RootState) => ({
  breadcrumb: state.ShopPage.breadcrumbs,
});
class Shop extends React.Component<ReturnType<typeof mapStateToProps>> {
  public render() {
    const { breadcrumb = [] } = this.props;
    return (
      <Layout isToBusiness>
        <ShopContent />
        <ProductListContent noMarketplace insideOtherPage />
      </Layout>
    );
  }
}

export default connect(mapStateToProps)(Shop);
