import React, { useContext } from "react";
import styles from "./index.scss";
import { RootState, Dispatch } from "../../store/configureStore";
import { useSelector, useDispatch } from "react-redux";
import { Carousel } from "antd";
import Button from "../../wrappers/button";
import { Player, BigPlayButton } from "video-react";
import { BusinessContext } from "../BusinessContext";
import Icon from "../../components/icon";
import ProductListContent from "../ProductList/Content";
import { useTheme } from "styled-components";
import replaceState from "../../helpers/reolaceState";

const ShopContent = React.memo(() => {
  const { primaryColor, bussinessPrimaryColor } = useTheme();
  const { isToBusiness } = useContext(BusinessContext);
  const { shop, top_cate_id = "0", sku_type, collection = [] } = useSelector(
    (state: RootState) => state.ShopPage,
  );
  const { isPrivate } = useSelector((state: RootState) => state.CommonData);
  let { shop_id: shopId, host_id } = useSelector(
    (state: RootState) => state.URLSearch,
  );
  const { ShopPage: rematch } = useDispatch<Dispatch>();

  let { shop_id } = shop || {};

  React.useEffect(() => {
    if (!shop && shopId) {
      rematch.getShopAsync(shopId);
    }

    sessionStorage.setItem(
      "ShopFilterParams",
      JSON.stringify({ shop_id, topCateId: top_cate_id }),
    );
  }, [shop_id, shopId]);

  React.useEffect(() => {
    rematch.getShopCollectionAsync();
  }, []);

  const isCollection = collection.includes(shop?.shop_id);

  const dispatcher = useDispatch<Dispatch>();

  return shop ? (
    <>
      <div className={styles["shop-box"]}>
        <div className={styles["shop-wrapper"]}>
          <div className={styles["shop-wrapper-decorator"]}>
            <Carousel
              dots
              autoplay
              className="shop-background-carousel"
              style={{
                cursor:
                  shop.shop_activity_url && shop.shop_activity_url !== ""
                    ? "pointer"
                    : "",
              }}
            >
              {JSON.parse(shop.shop_background).map(
                (b: string, index: number) => (
                  <div
                    key={index}
                    onClick={() => {
                      if (
                        shop.shop_activity_url &&
                        shop.shop_activity_url !== ""
                      ) {
                        replaceState.linkTo(shop.shop_activity_url, "_blank");
                      }
                    }}
                  >
                    <img src={b} className={styles["shop-background"]} />
                  </div>
                ),
              )}
            </Carousel>
            <div className={styles["shop-introduction"]}>
              <div className={styles["shop-introduction-logo"]}>
                {!!shop.shop_logo ? (
                  <div className={styles["shop-introduction-logo-img"]}>
                    <img src={shop.shop_logo}></img>
                  </div>
                ) : (
                  <div className={styles["shop-introduction-logo-default"]}>
                    <Icon type="icondianpu2"></Icon>
                  </div>
                )}
                {!shop.is_tob && !(isPrivate && host_id) && (
                  <div className={styles["shop-introduction-collection"]}>
                    <Button
                      onClick={() =>
                        dispatcher.Collections.submitCollectAsync({
                          action: isCollection ? "cancel" : "like",
                          object: "shop",
                          data_id: shop?.shop_id,
                          callback: (data_id: number) =>
                            dispatcher.ShopPage.submitCollectShop({
                              data_id,
                              action: isCollection ? "cancel" : "like",
                            }),
                        })
                      }
                      color={
                        isToBusiness ? bussinessPrimaryColor : primaryColor
                      }
                      icon={
                        <Icon
                          type={
                            isCollection ? "icont2_shoucang1" : "iconshoucang1"
                          }
                        />
                      }
                      shape="circle"
                      size="small"
                    />
                    <label>收藏店铺</label>
                  </div>
                )}
              </div>
              <div className={styles["shop-introduction-wrapper"]}>
                <div className={styles["shop-introduction-slogan"]}>
                  {shop.shop_slogan}
                </div>
                <div
                  className={styles["shop-introduction-desc"]}
                  dangerouslySetInnerHTML={{
                    __html: shop.shop_description,
                  }}
                ></div>
              </div>
            </div>
          </div>
          {shop.shop_video && (
            <div className={styles["shop-video"]}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Player>
                  <source src={shop.shop_video} />
                  <BigPlayButton position="center" />
                </Player>
              </div>
            </div>
          )}
        </div>
      </div>
      <ProductListContent
        handleInitData={dispatcher.PLPage.initShopSkuListAsync}
        extraParams={{ shop_id: shop.shop_id }}
      />
    </>
  ) : null;
});

export default ShopContent;
