/* global global:false */

/**
 * Top-level global namespace and associated helpers for MegaHome applications.
 * Don't import this directly unless your really know what you're doing. Instead, import the "megahome" module.
 *
 * @author    Hardys Hu <hardyshu@imegahome.com>
 * @copyright 2018 MegaHome LLC - All rights reserved
 */
// @ts-ignore
let megahome;
(() => {
  if (typeof window !== "undefined") {
    // @ts-ignore
    window.megahome = window.megahome || {};
    // @ts-ignore
    megahome = window.megahome;
    megahome.isClient = true;
  } else {
    megahome = {
      isClient: false,
    };
  }

  megahome.features = megahome.features || {};
  megahome.libra = megahome.libra || {};
  /**
   * Translate stuff.
   *
   * FAQs!
   *
   * Q - Is this function supposed to be so simple?
   * A - Yes! This is a placeholder function for clitools/minify/minify_functions.php.
   *
   * Q - Emergency! Why don't my translations show up in dev?
   * A - Don't worry. This is a placeholder function, and actual international JS files are build on build by clitools/minify/minify_functions.php
   *
   * Q - Why does it use a megahome namespace and not AMD?
   * A - This is a placeholder function for clitools/minify/minify_functions.php, which needs something to search for.
   *
   * Q - My translations are not showing up in staging/production??
   * A - Check the language resources tool (admin.megahome.com/checkout/admin/admin/misctools/language/edit_res.asp), and search for your key.
   *     If its there, redeploy resources with whitespace. If not, contact someone on international team.
   *
   * @memberOf megahome
   * @method translate
   * @param  {string} key         LNRS resource key
   * @param  {string} defaultText Default value if unset
   * @param  {array?} data        Optional replacement data
   * @return {string} your text
   */
  // @ts-ignore
  megahome.translate = (key, defaultText, data) => {
    // @ts-ignore
    if (megahome.appData && megahome.appData.debuglanguage) {
      return key;
    } else {
      // @ts-ignore
      const translations = megahome.translations || {};
      let replaceInto = translations[key] || defaultText;

      if (data != null) {
        if (typeof data === "string") {
          data = [data];
        }
        // @ts-ignore
        replaceInto = replaceInto.replace(/\{(\d+)\}/g, (match, index) => {
          return data[index - 1];
        });
      }

      return replaceInto;
    }
  };
  // @ts-ignore
  megahome.namespace = (...args) => {
    let names,
      obj = null;

    for (let i = 0, l = args.length; i < l; ++i) {
      names = args[i].split(".");
      // @ts-ignore
      obj = megahome;

      for (
        let name = names[0] === "megahome" ? 1 : 0, nl = names.length;
        name < nl;
        ++name
      ) {
        obj[names[name]] = obj[names[name]] || {};
        obj = obj[names[name]];
      }
    }

    return obj;
  };
  /**
   * takes a dot delimited string of properties to check for and get
   * returns null if property is not there or the value of the property
   * @param {string} props dot delimited list of properties
   * @param {object} context to check for the properties, defaults to megahome
   */
  // @ts-ignore
  megahome.get = (props, context, ...args: any[]) => {
    let current = context;

    if (args.length === 1) {
      // @ts-ignore
      current = megahome;
    } else if (context == null) {
      return null;
    }

    const steps = props.split(".");

    for (let i = 0; i < steps.length; i++) {
      current = current[steps[i]];

      if (current == null) {
        return null;
      }
    }

    return current;
  };

  megahome.namespace("runtime");
  megahome.namespace("appData");
  return megahome;
})();

export default megahome;
