import { createModel } from "@rematch/core";
import Shop, { ShopCategory, ShopFooter } from "../models/Shop";

export type StateType = Shop & {
  shop_category_data: ShopCategory[];
  footer_data: ShopFooter[];
  category_tree: string[];
  category_id: number;
};

export default createModel({
  state: {
    category_id: 2,
    shop_id: 1,
    shop_name: "Murong Jinxiu",
    slogan: "Wood Fashionated",
    description: "Wood Fashionated",
    logo:
      "https://megatest2.s3.ap-southeast-1.amazonaws.com/gaea/shop/000/000/01/190908/12-5d74865fbdda1.png",
    url: "/shops?shop_id=1",
    shop_category_data: [
      {
        category_id: 1,
        title: "Sofas",
        url: "/product-list/furniture/category/sofa/index.html",
        media_url:
          "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/furniture/home-sofas.jpg",
      },
      {
        category_id: 2,
        title: "Beds",
        url: "/product-list/furniture/category/bed/index.html",
        media_url:
          "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/furniture/home-beds.jpg",
      },
      {
        category_id: 3,
        title: "Tables",
        url: "/product-list/furniture/category/table-desks/index.html",
        media_url:
          "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/furniture/home-tables.jpg",
      },
      {
        category_id: 4,
        title: "Lights",
        url: "/product-list/furniture/category/light/index.html",
        media_url:
          "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/furniture/home-lights.jpg",
      },
      {
        category_id: 5,
        title: "Cabinets",
        url: "/product-list/furniture/category/cabinet/index.html",
        media_url:
          "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/furniture/home-cabinets.jpg",
      },
      {
        category_id: 6,
        title: "Decorations",
        url: "/product-list/furniture/category/decor/index.html",
        media_url:
          "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/furniture/home-decorations.jpg",
      },
      {
        category_id: 7,
        title: "Doors",
        url: "/product-list/furniture/category/door/index.html",
        media_url:
          "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/furniture/home-doors.jpg",
      },
      {
        category_id: 8,
        title: "Bath",
        url: "/product-list/furniture/category/bath/index.html",
        media_url:
          "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/furniture/home-bath.jpg",
      },
      {
        category_id: 9,
        title: "Chairs",
        url: "/product-list/furniture/category/chair/index.html",
        media_url:
          "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/furniture/home-chairs.jpg",
      },
      {
        category_id: 10,
        title: "More",
        url: "/product-list/furniture/index.html",
        media_url:
          "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/furniture/home-more.jpg",
      },
    ],
    footer_data: [
      {
        title: "关于MegaHome",
        children: [
          { title: "关于我们", link: "" },
          { title: "服务条款", link: "" },
          { title: "隐私政策", link: "" },
          { title: "平台服务条款", link: "" },
          { title: "免责声明", link: "" },
        ],
      },
      {
        title: "客户服务",
        children: [
          { title: "订单状态", link: "" },
          { title: "客户服务", link: "" },
        ],
      },
      {
        title: "加入",
        children: [
          { title: "加入我们", link: "" },
          { title: "商家入驻", link: "" },
          { title: "市场推广", link: "" },
          { title: "带单达人", link: "" },
        ],
      },
    ],
  } as StateType,
});
