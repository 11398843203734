import { createModel } from "@rematch/core";
import { GraphqlQuery, GraphqlMutation } from "../../helpers/request.helper";
import { getFirstItem } from "../../helpers/modelHelpers";
import {
  DesignIdeaList,
  DesignIdea,
  IdeaFilter,
} from "../../models/DesignIdea";
import { message } from "antd";
import produce from "immer";

export type IdeaList = {
  total: number;
  list: {
    design_idea_id: number;
    design_idea_name: string;
    design_idea_picture: string;
    url: string;
    shop_name: string;
    shop_url: string;
    is_collect: boolean;
    design_idea_description: string;
    like_num: number;
    coordinates: {
      shop_product_id: number;
      coordinate_x: number;
      coordinate_y: number;
      url: string;
    }[];
    designer_name: string;
    designer_avatar: string;
  }[];
  breadcrumbs: { url: string; title: string }[];
  ideaLikeList: any;
  pickList: any;
  hasMore: boolean;
};

export default createModel({
  state: {} as IdeaList,
  reducers: {
    setIdeas(state: IdeaList, payload: any) {
      if (payload.page === 1) {
        return {
          ...state,
          total: payload.total,
          list: payload.list,
        };
      } else {
        return {
          ...state,
          total: payload.total,
          list: [...state.list, ...payload.list],
        };
      }
    },
    setIdeaLikeData(state: IdeaList, ideaLikeList: any) {
      return {
        ...state,
        ideaLikeList: ideaLikeList,
      };
    },
    changeUserLike: (state: IdeaList, ideaId: number) =>
      produce(state, draft => {
        const index = draft.list.findIndex(rp => rp.design_idea_id === ideaId);
        draft.list[index].is_collect = !draft.list[index].is_collect;
      }),
    changeUserLikeIdea(state: IdeaList, id: number, type: boolean) {
      if (type) {
        return {
          ...state,
          ideaLikeList: state.ideaLikeList.filter(m => m !== id),
        };
      } else {
        return {
          ...state,
          ideaLikeList: [...state.ideaLikeList, id],
        };
      }
    },
    changeUserLikeIdeaData: (state: IdeaList, ideaId: number, type: boolean) =>
      produce(state, draft => {
        const index = draft.list.findIndex(rp => rp.design_idea_id === ideaId);
        draft.list[index].like_num = type
          ? draft.list[index].like_num - 1
          : draft.list[index].like_num + 1;
      }),
    setPickList: (state: IdeaList, pickList: IdeaFilter) => ({
      ...state,
      pickList,
    }),
    changeUserSearch: (state: IdeaList, payload: any) => {
      const { type, key, id, isCheck } = payload;

      return produce(state, draft => {
        const index = draft.pickList[type].findIndex(
          (rp: any) => rp[key] === id,
        );
        draft.pickList[type][index].isChecked = isCheck;
        console.log(draft.pickList[type][index]);
      });
    },
    setHasMore: (state: IdeaList, hasMore: boolean) => ({
      ...state,
      hasMore,
    }),
  },
  effects: {
    async setIdeaData(payload) {
      const data = new DesignIdeaList({});
      const res = await GraphqlQuery(
        "DesignIdea",
        data,
        { ...payload },
        "design",
      );

      if (res.length === 0) {
        this.setIdeas({
          page: payload.page,
          total: 0,
          list: [],
        });
      } else {
        this.setIdeas({
          page: payload.page,
          total: getFirstItem(res).total,
          list: getFirstItem(res).list,
        });
        const hasMoreIdeas = payload.page * 10 < getFirstItem(res).total;
        this.setHasMore(hasMoreIdeas);
      }
    },
    async setIdeaLike() {
      const res = await GraphqlQuery(
        "Customer",
        new DesignIdea({}),
        { action: "GetLikeIdeas" },
        "UserLike",
      );
      const data = res.map(m => m.design_idea_id);
      this.setIdeaLikeData(data);
    },
    async changeCollect({ ideaId, isLike }) {
      const type = isLike ? "cancel" : "like";
      const res = await GraphqlMutation("Collect", {
        action: type,
        object: "designIdea",
        data_id: ideaId,
      });
      message.success(res.message);
      this.changeUserLike(ideaId);
    },
    async changeLike({ ideaId, isLike }) {
      const type = isLike ? "CancelLikeDesignIdea" : "DoLikeDesignIdea";
      const res = await GraphqlMutation("Customer", {
        action: type,
        design_idea_id: ideaId,
      });
      message.success(res.message);
      this.changeUserLikeIdeaData(ideaId, isLike);
      this.changeUserLikeIdea(ideaId, isLike);
    },
    // 获取picklist
    async setPickListAsync(payload) {
      const { shop_id } = payload;
      const res = await GraphqlQuery(
        "PickList",
        new IdeaFilter(),
        {
          action: "designList",
          shop_id,
        },
        "design",
      );

      this.setPickList(getFirstItem(res));
    },
  },
});
