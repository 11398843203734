import React from "react";
import * as styles from "./index.scss";
import { FormattedMessage, useIntl } from "react-intl";
import { CommonProps } from "../../typings/component";
import IdeaCard from "../IdeaList/component/Card";
import { DesignIdea } from "../../models/DesignIdea";
import Waterfall from "../../components/waterfall";
import Carousel from "../../wrappers/carousel";

const ProductDesignIdea: React.FC<
  CommonProps & {
    designIdeas: DesignIdea[];
    onChangeUserLike: (data: any) => void;
    titlePosition?: "left" | "center" | "right";
  }
> = ({ designIdeas, isLogin, isMobile, onChangeUserLike }) => {
  if (designIdeas.length > 0) {
    return (
      <div>
        <h3 className={styles["ProductParams-details-title"]}>
          <FormattedMessage id="CollectionDesignRecommendation" />
        </h3>

        <Carousel
          arrows
          dots={false}
          slidesToScroll={1}
          slidesToShow={1}
          infinite={false}
          hoverable
          style={{ border: "1px solid #bdbcbc", borderRadius: "5px" }}
        >
          {designIdeas.map(item => (
            <Waterfall
              fullWidth
              gap={10}
              itemWidth={0}
              key={item.design_idea_id}
            >
              <IdeaCard
                isMobile={isMobile}
                src={item.design_idea_picture}
                key={item.design_idea_id}
                name={item.design_idea_name}
                shopName={item.shop_name}
                url={item.url}
                tags={item.coordinates}
                isCollect={item.is_collect}
                description={item.design_idea_description}
                onChangeUserLike={() =>
                  onChangeUserLike({
                    action: item.is_collect ? "cancel" : "like",
                    object: "designIdea",
                    data_id: item.design_idea_id,
                  })
                }
                shopUrl={item.shop_url}
                showMask={false}
                showTag
                showName
                outsideRef
                className={styles["ProductParams-designIdeas"]}
              />
            </Waterfall>
          ))}
        </Carousel>
      </div>
    );
  }

  return null;
};

export default React.memo(ProductDesignIdea);
