import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, Dispatch } from "../../store/configureStore";
import * as styles from "./index.scss";
import { useIntl } from "react-intl";
import { CommonProps } from "../../typings/component";
import Icon from "../../components/icon";
import AutoImage from "../../components/image";
import { useTheme } from "styled-components";
import { Row, Col, Tag } from "antd";
import { BusinessContext } from "../BusinessContext";
import Button, { PrimaryButton } from "../../wrappers/button";
import Modal from "../../wrappers/modal";
import List from "../../wrappers/list";
import { useMount } from "ahooks";
import * as queryString from "query-string";

const ReservationContent: React.FC<CommonProps> = () => {
  const dispatch = useDispatch<Dispatch>();
  const { formatMessage: f } = useIntl();
  const { primaryColor, secondaryColor } = useTheme();
  const { isToBusiness } = useContext(BusinessContext);
  const { host_id } = useSelector((state: RootState) => state.URLSearch);
  const { isPrivate } = useSelector((state: RootState) => state.CommonData);
  const { reservation, total } = useSelector(
    (state: RootState) => state.ReservationPage,
  );
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [showRcConfirmModal, setShowRcConfirmModal] = useState(false);
  const [selectedId, setSelectId] = useState(0);

  useMount(() => {
    const shop_id = queryString.parse(window.location.search).host_id;
    dispatch.ReservationPage.getExperienceAsync({
      shop_id,
      page: 1,
    });
  });

  const handleGetOtherList = (page: number) => {
    setCurrentPageNumber(page);
    dispatch.ReservationPage.getExperienceAsync({ page });
    window.scroll(0, 0);
  };
  return (
    <div className={styles["Reservation"]}>
      <List
        dataSource={reservation}
        split={false}
        grid={{ gutter: 10, column: 1 }}
        pagination={{
          current: currentPageNumber,
          total: Number(total),
          pageSize: 10,
          showSizeChanger: false,
          onChange: handleGetOtherList,
          hideOnSinglePage: true,
        }}
        renderItem={(item: any, index) => (
          <List.Item key={index}>
            <div className={styles["Reservation-box"]} key={index}>
              <Row>
                {`${f({
                  id: "MakeAnAppointmentTime",
                })} : ${item.arriving_time}`}
              </Row>
              <Row
                style={{
                  borderBottom: "1px solid #BDBCBC",
                  display: "flex",
                  paddingBottom: 20,
                }}
                gutter={10}
              >
                <div style={{ width: 80, marginRight: 20 }}>
                  <AutoImage
                    width={60}
                    height={60}
                    src={item.product_picture}
                  />
                </div>

                <div style={{ flex: 1 }}>
                  <p>{item.product_name}</p>
                  <p>
                    {item.brand_name && item.brand_name !== "" && (
                      <Tag color={secondaryColor} style={{ borderRadius: 30 }}>
                        {item.brand_name}
                      </Tag>
                    )}
                    {item.series_name && item.series_name !== "" && (
                      <Tag color={secondaryColor} style={{ borderRadius: 30 }}>
                        {item.series_name}
                      </Tag>
                    )}
                  </p>
                </div>
                <div style={{ flex: 1 }}>
                  <p>现价:{item.tag_price_value}</p>
                  {item.original_price > item.tag_price && (
                    <p>原价:{item.original_price_value}</p>
                  )}
                </div>
              </Row>
              <Row style={{ marginTop: "10px", display: "flex" }}>
                <div style={{ width: 80, marginRight: 20 }}>
                  <AutoImage width={60} height={60} src={item.provider_logo} />
                </div>
                <div style={{ flex: 1 }}>
                  <p>{item.provider_name}</p>
                  <p>
                    {`${item.business_start_time} - ${item.business_end_time}  地址 : ${item.address}`}
                  </p>
                </div>
                <div className={styles["Reservation-box-btn"]}>
                  <Button
                    shape={"circle"}
                    color={primaryColor}
                    icon={<Icon type="icondelete" />}
                    onClick={() => {
                      setSelectId(item.customer_experience_id);
                      setShowRcConfirmModal(true);
                    }}
                  ></Button>
                </div>
              </Row>
            </div>
          </List.Item>
        )}
      ></List>

      <Modal
        getContainer={() => document.querySelector(".ant-layout")}
        onCancel={() => setShowRcConfirmModal(false)}
        visible={showRcConfirmModal}
        width="420px"
        footer={false}
        closable={false}
      >
        <Row justify="space-between" style={{ padding: "13px 26px" }}>
          <Col>
            {f({
              id: "Prompt",
            })}
          </Col>
          <Col>
            <Icon
              type="icont1_close"
              onClick={() => setShowRcConfirmModal(false)}
              style={{ color: primaryColor }}
            />
          </Col>
        </Row>
        <Row
          style={{ margin: "30px", display: "flex", justifyContent: "center" }}
        >
          <PrimaryButton
            shape="circle"
            icon={<Icon type="icontanhao" />}
            size={"small"}
            bgColor={primaryColor}
          />
          <span style={{ marginLeft: "10px" }}>
            {f({
              id: "PleaseConfirmDelete",
            })}
          </span>
        </Row>
        <Row style={{ textAlign: "center", paddingBottom: "20px" }} gutter={10}>
          <Col span={6}> </Col>
          <Col span={6}>
            <PrimaryButton
              onClick={() => setShowRcConfirmModal(false)}
              block
              bgColor={secondaryColor}
            >
              {f({ id: "Cancel" })}
            </PrimaryButton>{" "}
          </Col>
          <Col span={6}>
            <PrimaryButton
              onClick={() => {
                dispatch.ReservationPage.handleReservationDelete(selectedId);
                setShowRcConfirmModal(false);
              }}
              block
              bgColor={primaryColor}
            >
              {f({ id: "Confirm" })}
            </PrimaryButton>
          </Col>
          <Col span={6}> </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default React.memo(ReservationContent);
