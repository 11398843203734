import { createModel } from "@rematch/core";
import { GraphqlQuery } from "../../helpers/request.helper";
import { getFirstItem } from "../../helpers/modelHelpers";
import Provider from "../../models/Provider";

export interface StateType {
  breadcrumbs: { url: string; title: string }[];
  collection: number[];
  provider: Provider;
}

export default createModel({
  state: {} as StateType,
  reducers: {
    initProvider: (state: StateType, provider: Provider) => ({
      ...state,
      provider,
    }),
  },

  effects: {
    async getProviderAsync(payload) {
      const { provider_id } = payload;
      const data = await GraphqlQuery(
        "Provider",
        new Provider({ provider_id }),
        {},
        "Provider",
      );

      this.initProvider(getFirstItem(data));
    },
  },
});
