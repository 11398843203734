// 个人中心页面
import React from "react";
import { useSelector } from "react-redux";
import Layout from "../Layout";
import { RootState } from "../../store/configureStore";
import InfoLayout from "./components/Layout";
import PersonalInfo from "./PersonalInfo";

const App: React.FC = () => {
  const {
    breadcrumbs = [
      {
        url: "/",
        title: "首页",
      },
      {
        url: "/account",
        title: "个人中心",
      },
    ],
  } = useSelector((state: RootState) => state.AccountPage);

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <InfoLayout>
        <PersonalInfo />
      </InfoLayout>
    </Layout>
  );
};

export default React.memo(App);
