import React, { useEffect, useState, useContext, useRef } from "react";
import * as styles from "./index.scss";
import Header from "./component/Header";
import CopyRight from "./component/CopyRight";
import classnames from "classnames";

const PageNotFound = () => {
  const [time, setTime] = useState(3);
  const intervalRef = useRef();

  useEffect(() => {
    const timer = setInterval(function () {
      setTime(time - 1);
      if (time === 0) {
        handleLink();
      }
    }, 1000);
    intervalRef.current = timer;
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [time]);

  const handleLink = () => {
    location.href = "/";
  };

  return (
    <div className={styles["Pages"]}>
      <Header />
      <div className={styles["Pages-content"]}>
        <div className={styles["Pages-content-image-systemerror"]}></div>
        <div
          className={classnames(styles[`Pages-content-text`], {
            [styles[`Pages-content-systext`]]: true,
          })}
        >
          <p className={styles["Pages-content-text-btitle"]}>哎呀！</p>
          <p className={styles["Pages-content-text-title"]}>
            服务器溜号了，请与管理员联系。
          </p>
          <p className={styles["Pages-content-text-back"]}>
            <span>
              <span className={styles["Pages-content-text-time"]}>{time}</span>{" "}
              秒后返回首页
            </span>
            <div
              className={styles["Pages-content-sysbtn"]}
              onClick={handleLink}
            >
              立即前往
            </div>
          </p>
        </div>
      </div>
      <CopyRight />
    </div>
  );
};

export default React.memo(PageNotFound);
