import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, Dispatch } from "../../store/configureStore";
import * as styles from "./index.scss";
import Icon from "../../components/icon";
import { useIntl } from "react-intl";
import Price from "../../components/highlight";
import { useTheme } from "styled-components";
import { BusinessContext } from "../BusinessContext";
import PaymentTypeModal from "./components/PaymentTypeModal";
import { Row, Col, Space, message } from "antd";
import Input from "../../wrappers/input";
import Checkbox from "../../wrappers/checkbox";
import List from "../../wrappers/list";
import Button, { PrimaryButton } from "../../wrappers/button";

const Withdrawal = () => {
  const dispatch = useDispatch<Dispatch>();
  const {
    statistics,
    accounts = [],
    withdrawalLog = [],
    withdrawalLogTotal,
    resultData,
  } = useSelector((state: RootState) => state.WithdrawalPage);
  const { formatMessage: f } = useIntl();
  const [page, setPage] = useState(1); // 1首页；2提现页面；3提现详情页面
  const [showPrice, setShowPrice] = useState(false); // true显示；false不显示;
  const [showLog, setShowlog] = useState(true); // true显示；false不显示;
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [type, setType] = useState(1); // 1平台；2店铺；3邀请isCheckd
  const [maxMoney, setMaxMoney] = useState(0);
  const [checkdId, setCheckdId] = useState(0);
  const [mountNumber, setMountNumber] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false);

  const { primaryColor, linkColor } = useTheme();

  useEffect(() => {
    dispatch.WithdrawalPage.getInitAsync();
    dispatch.WithdrawalPage.getAgentAccountAsync();
    dispatch.WithdrawalPage.getWithdrawalLog({});
  }, []);

  const refreshData = () => {
    dispatch.WithdrawalPage.getInitAsync();
    dispatch.WithdrawalPage.getAgentAccountAsync();
    dispatch.WithdrawalPage.getWithdrawalLog({});
  };

  const handleGetOtherList = (page: number) => {
    setCurrentPageNumber(page);
    dispatch.WithdrawalPage.getWithdrawalLog({ page });
    window.scroll(0, 600);
  };

  const handleGetRes = (id: number) => {
    dispatch.WithdrawalPage.getGetRes({ agent_withdraw_id: id });
  };

  const handleInputChange = (e: any) => {
    if (Number(e.target.value) === 0) return setMountNumber("");
    const reg = /^\d+(.\d{1,2})?$/,
      reg2 = /^\d+(.)?$/;
    if (!reg.test(e.target.value) && !reg2.test(e.target.value)) return;

    if (Number(e.target.value) > Number(maxMoney)) return;
    setMountNumber(e.target.value);
  };

  const handleSumbitWithdrawal = () => {
    if (checkdId === 0) return message.error("请选择提现卡");
    if (Number(mountNumber) === 0) return message.error("请输入提现金额");
    setBtnDisabled(true);
    const data = {
      agent_account_id: checkdId,
      withdraw_type: type,
      withdraw_amount: mountNumber,
      callback: (res: any) => {
        if (res) {
          const resData = JSON.parse(res);
          const datas = {
            agent_withdraw_id: resData.agent_withdraw_id,
            callback: (res: any) => {
              setPage(3);
            },
          };
          dispatch.WithdrawalPage.getGetRes(datas);
        } else {
          setBtnDisabled(false);
        }
      },
    };
    dispatch.WithdrawalPage.withdrawalSumbit(data);
  };

  // 首页综合数据
  const comprehensive = () => {
    return (
      <div className={styles[`Withdrawal-comprehensive`]}>
        <div className={styles[`Withdrawal-comprehensive-left`]}>
          <div className={styles[`Withdrawal-comprehensive-left-title`]}>
            可提现金额{" "}
            <Icon
              type={showPrice ? "iconxianshi" : "iconbuxianshi"}
              onClick={() => setShowPrice(!showPrice)}
            ></Icon>
          </div>
          <div className={styles[`Withdrawal-comprehensive-left-money`]}>
            ¥
            {showPrice
              ? statistics && Number(statistics.total).toFixed(2)
              : "*****"}
          </div>
        </div>
        <div className={styles[`Withdrawal-comprehensive-right`]}>
          <div
            className={styles[`Withdrawal-row`]}
            style={{ fontSize: "1.4em" }}
          >
            <span
              className={styles[`Withdrawal-vertical`]}
              style={{ background: primaryColor }}
            >
              &nbsp;
            </span>
            <span style={{ marginRight: 10 }}>收入来源</span>
            <Icon
              type={showPrice ? "iconxianshi" : "iconbuxianshi"}
              onClick={() => setShowPrice(!showPrice)}
            ></Icon>
          </div>
          <Row
            className={styles[`Withdrawal-row`]}
            style={{ fontSize: "1.2em", padding: "0px 5px" }}
          >
            <Col span={12}>
              平台：
              <span style={{ color: primaryColor }}>
                ¥
                {showPrice
                  ? statistics && Number(statistics.platform_amount).toFixed(2)
                  : "*****"}
              </span>
            </Col>
            <Col span={12} style={{ textAlign: "right" }}>
              <PrimaryButton
                onClick={() => {
                  if (
                    Number(statistics.platform_amount) <
                    Number(statistics.min_withdraw)
                  ) {
                    return message.error(
                      "金额不足提现，最小提现金额为¥" +
                        Number(statistics.min_withdraw).toFixed(2),
                    );
                  }
                  setPage(2);
                  setType(1);
                  setMaxMoney(Number(statistics.platform_amount));
                }}
                bgColor={primaryColor}
              >
                提现
              </PrimaryButton>
            </Col>
          </Row>
          <Row
            className={styles[`Withdrawal-row`]}
            style={{ fontSize: "1.2em", padding: "0px 5px" }}
          >
            <Col span={12}>
              品牌：
              <span style={{ color: primaryColor }}>
                ¥
                {showPrice
                  ? statistics && Number(statistics.brand_amount).toFixed(2)
                  : "*****"}
              </span>
            </Col>
            <Col span={12} style={{ textAlign: "right" }}>
              <PrimaryButton
                onClick={() => {
                  if (
                    Number(statistics.brand_amount) <
                    Number(statistics.min_withdraw)
                  ) {
                    return message.error(
                      "金额不足提现，最小提现金额为¥" +
                        Number(statistics.min_withdraw).toFixed(2),
                    );
                  }
                  setPage(2);
                  setType(2);
                  setMaxMoney(Number(statistics.brand_amount));
                }}
                bgColor={primaryColor}
              >
                提现
              </PrimaryButton>
            </Col>
          </Row>
          <Row
            className={styles[`Withdrawal-row`]}
            style={{ fontSize: "1.2em", border: "none", padding: "0px 5px" }}
          >
            <Col span={12}>
              邀请：
              <span style={{ color: primaryColor }}>
                ¥
                {showPrice
                  ? statistics && Number(statistics.reward_amount).toFixed(2)
                  : "*****"}
              </span>
            </Col>
            <Col span={12} style={{ textAlign: "right" }}>
              <PrimaryButton
                onClick={() => {
                  if (
                    Number(statistics.reward_amount) <
                    Number(statistics.min_withdraw)
                  ) {
                    return message.error(
                      "金额不足提现，最小提现金额为" +
                        Number(statistics.min_withdraw).toFixed(2),
                    );
                  }
                  setPage(2);
                  setType(3);
                  setMaxMoney(Number(statistics.reward_amount));
                }}
                bgColor={primaryColor}
              >
                提现
              </PrimaryButton>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  // 提现方式块
  const withdrawalType = () => {
    return (
      <>
        <div
          className={styles[`Withdrawal-row`]}
          style={{ fontSize: "1.4em", border: "none" }}
        >
          <span
            className={styles[`Withdrawal-vertical`]}
            style={{ background: primaryColor }}
          >
            &nbsp;
          </span>
          <span style={{ marginRight: 10 }}>
            {page === 1 ? "提现方式" : "提现到"}
          </span>

          <PaymentTypeModal type={1} />
        </div>
        {accounts.map((item: any) => {
          return (
            <div className={styles[`Withdrawal-type-row`]}>
              {page === 1 && (
                <Icon
                  type={item.account_type === "bank" ? "iconka" : ""}
                  style={{
                    fontSize: "1.6em",
                    marginRight: 10,
                    color: primaryColor,
                  }}
                ></Icon>
              )}
              {page === 2 && (
                <Checkbox
                  shape="circle"
                  borderWidth="2px"
                  checked={checkdId === item.agent_account_id ? true : false}
                  onChange={() => setCheckdId(item.agent_account_id)}
                />
              )}

              <span style={{ marginLeft: page === 2 ? 10 : 0 }}>
                {item.account_bank}卡：
              </span>
              <span style={{ marginRight: 10 }}>{item.account_number}</span>
              <PaymentTypeModal
                type={2}
                agentAccountId={item.agent_account_id}
              />
            </div>
          );
        })}
      </>
    );
  };

  // 提现记录
  const withdrawalLogBox = () => {
    return (
      <>
        <div
          className={styles[`Withdrawal-row`]}
          style={{ fontSize: "1.4em", border: "none" }}
        >
          <span
            className={styles[`Withdrawal-vertical`]}
            style={{ background: primaryColor }}
          >
            &nbsp;
          </span>
          <span style={{ marginRight: 10 }}>提现记录</span>
          <span style={{ float: "right" }}>
            <Icon
              type={showLog ? "icont1_jian" : "icont1_jia"}
              onClick={() => setShowlog(!showLog)}
            ></Icon>
          </span>
        </div>
        {showLog && (
          <>
            <List
              grid={{ gutter: 10, column: 2 }}
              dataSource={withdrawalLog}
              renderItem={(item: any) => (
                <List.Item key={item.key}>
                  <Col
                    span={24}
                    onClick={() => {
                      handleGetRes(item.agent_withdraw_id);
                      setPage(3);
                      window.scroll(0, 0);
                    }}
                  >
                    <div className={styles[`Withdrawal-log-box`]}>
                      <Col span={16}>
                        <span className={styles[`Withdrawal-log-box-title`]}>
                          提现
                        </span>
                        <span
                          style={{
                            color:
                              item.withdraw_status === 1
                                ? primaryColor
                                : "#BDBCBC",
                          }}
                        >
                          {item.withdraw_status_text}
                        </span>

                        <br />
                        {item.created_at}
                      </Col>
                      <Col
                        span={8}
                        style={{
                          height: "100%",
                          textAlign: "right",
                          color: primaryColor,
                        }}
                      >
                        -¥
                        <Price text={item.withdraw_amount} />
                      </Col>
                    </div>
                  </Col>
                </List.Item>
              )}
              pagination={{
                current: currentPageNumber,
                total: withdrawalLogTotal,
                pageSize: 20,
                showSizeChanger: false,
                onChange: handleGetOtherList,
                hideOnSinglePage: true,
              }}
            ></List>
          </>
        )}
      </>
    );
  };

  const resultContent = () => {
    return (
      <>
        <div
          className={styles[`Withdrawal-white`]}
          style={{ padding: "20px 40px" }}
        >
          {resultData && resultData.withdraw_status === 0 && (
            <>
              <div
                style={{ color: primaryColor }}
                className={styles[`Withdrawal-result-title`]}
              >
                提现失败
              </div>
              <div className={styles[`Withdrawal-result-remark`]}>
                {resultData.remark}
              </div>
            </>
          )}
          {resultData && resultData.withdraw_status === 1 && (
            <>
              <div
                style={{ color: primaryColor }}
                className={styles[`Withdrawal-result-title`]}
              >
                提现申请提交成功, 等待平台处理中...
              </div>
              <div className={styles[`Withdrawal-result-remark`]}>
                预计1-3个工作日内到账
              </div>
            </>
          )}
          {resultData && resultData.withdraw_status === 2 && (
            <>
              <div
                style={{ color: primaryColor }}
                className={styles[`Withdrawal-result-title`]}
              >
                已提现至您的账户
              </div>
            </>
          )}
        </div>
        <div
          className={styles[`Withdrawal-white`]}
          style={{ padding: "20px 40px", marginTop: 10 }}
        >
          <div className={styles[`Withdrawal-result-info`]}>
            <div className={styles[`Withdrawal-result-info-left`]}>
              提现金额：
            </div>
            <div className={styles[`Withdrawal-result-info-right`]}>
              {resultData && resultData.withdraw_amount_text}
            </div>
          </div>
          <div className={styles[`Withdrawal-result-info`]}>
            <div className={styles[`Withdrawal-result-info-left`]}>
              手续费：
            </div>
            <div className={styles[`Withdrawal-result-info-right`]}>
              {resultData && resultData.withdraw_fee_text}
            </div>
          </div>
          <div className={styles[`Withdrawal-result-info`]}>
            <div className={styles[`Withdrawal-result-info-left`]}>
              {resultData && resultData.withdraw_status === 2
                ? "实际到账"
                : "预计到账"}
              ：
            </div>
            <div className={styles[`Withdrawal-result-info-right`]}>
              {resultData && resultData.arrival_amount_text}
            </div>
          </div>
          <div className={styles[`Withdrawal-result-info`]}>
            <div className={styles[`Withdrawal-result-info-left`]}>
              提现方式：
            </div>
            <div className={styles[`Withdrawal-result-info-right`]}>
              {resultData && resultData.withdraw_method}
            </div>
          </div>
          <div className={styles[`Withdrawal-result-info`]}>
            <div className={styles[`Withdrawal-result-info-left`]}>
              提交时间：
            </div>
            <div className={styles[`Withdrawal-result-info-right`]}>
              {resultData && resultData.created_at}
            </div>
          </div>

          {resultData && resultData.withdraw_status === 2 && (
            <div className={styles[`Withdrawal-result-info`]}>
              <div className={styles[`Withdrawal-result-info-left`]}>
                到账时间：
              </div>
              <div className={styles[`Withdrawal-result-info-right`]}>
                {resultData && resultData.transfer_time}
              </div>
            </div>
          )}

          <Row className={styles[`Withdrawal-result-info-btn`]}>
            <PrimaryButton
              onClick={() => {
                window.scroll(0, 0);
                refreshData();
                setPage(1);
              }}
              bgColor={primaryColor}
            >
              完成
            </PrimaryButton>
          </Row>
        </div>
      </>
    );
  };

  const priceContent = () => {
    return (
      <>
        <div
          className={styles[`Withdrawal-row`]}
          style={{ fontSize: "1.4em", border: "none" }}
        >
          <span
            className={styles[`Withdrawal-vertical`]}
            style={{ background: primaryColor }}
          >
            &nbsp;
          </span>
          <span style={{ marginRight: 10 }}>
            {type === 1
              ? "平台提现金额"
              : type === 2
              ? "店铺提现金额"
              : "邀请提现金额"}
          </span>

          <span style={{ fontSize: "0.8em", float: "right" }}>
            <span style={{ color: "red" }}>*</span>
            <span style={{ color: "#4B4B4B" }}>平台将会收取转账手续费</span>
          </span>
        </div>
        <Input
          prefix={"¥"}
          placeholder="请输入提现金额"
          size="large"
          value={mountNumber}
          onChange={handleInputChange}
        ></Input>
        <Row style={{ marginTop: 20 }}>
          <Col span={16}>
            可提现金额：
            <span style={{ color: primaryColor }}>¥{maxMoney}</span>
          </Col>
          <Col span={8} style={{ textAlign: "right" }}>
            <u
              style={{ color: linkColor, cursor: "pointer" }}
              onClick={() => setMountNumber(String(maxMoney))}
            >
              全部提现
            </u>
          </Col>
        </Row>
        {Number(mountNumber) > 0 && (
          <>
            <Row>
              {`手续费：${mountNumber}*${statistics.fee_rate}% = ${(
                (statistics.fee_rate / 100) *
                Number(mountNumber)
              ).toFixed(2)}`}
            </Row>
            <Row>
              {`预计到账：¥  ${(
                Number(mountNumber) -
                Number((statistics.fee_rate / 100) * Number(mountNumber))
              ).toFixed(2)}`}
            </Row>
          </>
        )}
      </>
    );
  };

  return (
    <div className={styles[`Withdrawal`]}>
      {page === 1 && (
        <>
          <div className={styles[`Withdrawal-white`]} style={{ padding: 40 }}>
            {comprehensive()}
            <div style={{ marginTop: 40 }}>{withdrawalType()}</div>
          </div>
          <div
            className={styles[`Withdrawal-white`]}
            style={{ padding: "20px 40px", marginTop: 10 }}
          >
            {withdrawalLogBox()}
          </div>
        </>
      )}
      {page === 2 && (
        <Row gutter={10}>
          <Col span={12}>
            <div className={styles[`Withdrawal-white`]} style={{ padding: 40 }}>
              {withdrawalType()}
            </div>
          </Col>
          <Col span={12}>
            <div className={styles[`Withdrawal-white`]} style={{ padding: 40 }}>
              {priceContent()}
            </div>
            <Row style={{ textAlign: "right", marginTop: 20 }}>
              <Space>
                <Button
                  onClick={() => {
                    setPage(1);
                    setCheckdId(0);
                    setMountNumber("");
                  }}
                  color={primaryColor}
                >
                  取消提现
                </Button>
                <PrimaryButton
                  onClick={() => handleSumbitWithdrawal()}
                  disabled={btnDisabled}
                  bgColor={primaryColor}
                >
                  确认提现
                </PrimaryButton>
              </Space>
            </Row>
          </Col>
        </Row>
      )}
      {page === 3 && resultContent()}
    </div>
  );
};

export default Withdrawal;
