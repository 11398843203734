import * as React from "react";
import Layout from "../Layout";
import Content from "./Content";
import InfoLayout from "../UserCenter/components/Layout";
import { useSelector } from "react-redux";
import { RootState } from "../../store/configureStore";

const Message: React.FC = () => {
  const {
    breadcrumbs = [
      {
        url: "/",
        title: "首页",
      },
      {
        url: "/account/message",
        title: "消息",
      },
    ],
  } = useSelector((state: RootState) => state.MessagePage);

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <InfoLayout>
        <Content />
      </InfoLayout>
    </Layout>
  );
};

export default Message;
