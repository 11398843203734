import * as React from "react";
import { useSelector } from "react-redux";
import { OrderHistoryType } from "../model";
import AutoImage from "../../../components/image";
import { RootState, Dispatch } from "../../../store/configureStore";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import * as styles from "./index.scss";
import Icon from "../../../components/icon";
import { Row, Col, Tag, Card, Space } from "antd";
import List from "../../../wrappers/list";
import Button, { PrimaryButton } from "../../../wrappers/button";
import { Text, Link } from "../../../wrappers/typography";
import replaceState from "../../../helpers/reolaceState";
import { useTheme } from "styled-components";
import { Order, OrderProduct } from "../../../models/Order";
import { PaginationConfig } from "antd/lib/pagination";

interface ComponentProps {
  data: Order[];

  pagination: PaginationConfig;

  handleRefreshData: () => void;
  handleCancelOrder: (orderId: number) => void;
  handleConfirmGoods: (orderId: number, order_product_id: number) => void;
  handleAddToCart: (
    shopProductId: number,
    quantity: number,
    providerId: number,
  ) => void;
}

const OrderItem = (props: ComponentProps) => {
  const { formatMessage: f } = useIntl();
  const { primaryColor, secondaryColor } = useTheme();
  const { search = "" } = useSelector((state: RootState) => state.URLSearch);

  return (
    <List
      dataSource={props.data}
      split={false}
      grid={{ gutter: 15, column: 1 }}
      renderItem={(item: Order, index) => (
        <List.Item key={index}>
          <Card
            style={{ borderColor: "#bdbcbc" }}
            headStyle={{ borderBottomColor: "#bdbcbc" }}
            title={
              <Row
                align="middle"
                justify="space-between"
                style={{ fontSize: ".9em", fontWeight: 400 }}
              >
                <Link
                  color={primaryColor}
                  weight={500}
                  href={item.shop_url}
                  onClick={e => {
                    e.preventDefault();
                    replaceState.linkTo(item.shop_url, "_target");
                  }}
                >
                  <Space size={5}>
                    <Icon type="icondianpu2" />
                    {item.shop_name}
                  </Space>
                </Link>

                <Text>
                  {f({ id: "OrderNumber" })}:{item.order_number}
                </Text>
                <Text>{item.created_at}</Text>
                <div>
                  {f({ id: "Status" })}:{" "}
                  <Text
                    color={
                      item.order_status === 2 || item.order_status === 3
                        ? "#c21d44"
                        : item.order_status === 0
                        ? "#bdbcbc"
                        : ""
                    }
                  >
                    {item.order_status_text}
                  </Text>
                </div>
                <div>
                  {f({ id: "OrderPrice" })}:{" "}
                  <Text color={primaryColor}>{item.order_amount_text}</Text>
                </div>
                <div>
                  <PrimaryButton
                    block
                    onClick={() => {
                      replaceState.linkTo(
                        "/account/orders/" + item.order_number,
                      );
                    }}
                    bgColor={primaryColor}
                  >
                    {f({ id: "OrderDetails" })}
                  </PrimaryButton>
                </div>
              </Row>
            }
          >
            <List
              dataSource={item.product}
              grid={{ gutter: 15, column: 1 }}
              split
              renderItem={(m: OrderProduct, mIndex) => (
                <List.Item
                  key={mIndex}
                  style={{
                    borderBottom:
                      mIndex < item.product.length - 1
                        ? "1px dashed #eaeaea"
                        : "",
                  }}
                >
                  <Row align="middle">
                    <Col span={4}>
                      {Boolean(m.activity_type) && (
                        <div
                          className={styles["OrderList_activity"]}
                          style={{ background: primaryColor }}
                        >
                          {m.activity_type_text}
                        </div>
                      )}

                      <AutoImage
                        width={58}
                        height={50}
                        src={m.product_picture}
                      />
                    </Col>
                    <Col
                      span={6}
                      style={{ height: "150px", padding: "0 10px" }}
                    >
                      <p style={{ fontSize: 15 }}>{m.product_name}</p>
                      <p style={{ marginTop: "5px" }}>
                        {m.brand_name && m.brand_name !== "" && (
                          <Tag color={secondaryColor}>{m.brand_name}</Tag>
                        )}
                        {m.series_name && m.series_name !== "" && (
                          <Tag color={secondaryColor}>{m.series_name}</Tag>
                        )}
                      </p>
                      <p
                        style={{
                          position: "absolute",
                          bottom: "0",
                        }}
                      >
                        {m.attribute}
                      </p>
                    </Col>
                    <Col span={4} style={{ textAlign: "center" }}>
                      <p style={{ fontSize: "1.2em" }}>{m.tag_price_value}</p>
                      {/* <p
                                    style={{
                                      color: "#666",
                                    }}
                                  >
                                    原价
                                    {parseFloat(m.original_price) !== 0
                                      ? m.original_price_value
                                      : m.tag_price_value}
                                  </p> */}
                    </Col>
                    <Col span={4} style={{ textAlign: "center" }}>
                      <p>*{m.quantity}</p>
                    </Col>
                    <Col span={3}>
                      <span
                        style={{
                          color:
                            m.product_status === 2 || m.product_status === 3
                              ? "#c21d44"
                              : m.product_status === 0
                              ? "#bdbcbc"
                              : "",
                        }}
                      >
                        {m.product_status_text}
                      </span>
                    </Col>
                    <Col span={3}>
                      <Space direction="vertical" size={5}>
                        {mIndex === 0 && item.order_status === 1 && (
                          <Button
                            block
                            onClick={() => {
                              props.handleCancelOrder(item.order_id);
                            }}
                            color={primaryColor}
                          >
                            {f({
                              id: "CancelTheOrder",
                            })}
                          </Button>
                        )}
                        {item.product_status === 3 && (
                          <Button
                            block
                            onClick={() => {
                              props.handleConfirmGoods(
                                item.order_id,
                                m.order_product_id,
                              );
                            }}
                            color={primaryColor}
                          >
                            {f({
                              id: "ConfirmTheGoods",
                            })}
                          </Button>
                        )}
                        <Button
                          block
                          onClick={() => {
                            props.handleAddToCart(
                              m.shop_product_id,
                              m.quantity,
                              m.provider_id,
                            );
                          }}
                          color={primaryColor}
                        >
                          {f({
                            id: "BuyAgain",
                          })}
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </List.Item>
              )}
            ></List>
          </Card>
        </List.Item>
      )}
      pagination={props.pagination}
    ></List>
  );
};
export default OrderItem;
