import { createModel } from "@rematch/core";
import { GraphqlMutation, GraphqlQuery } from "../../helpers/request.helper";
import Messages from "../../components/messages";
import { PlatForm } from "../../models/PlatForm";
import { getFirstItem } from "../../helpers/modelHelpers";

interface LinkItem {
  title: string;
  link?: string;
  action?: string;
  children?: LinkItem[];
}

export type StateType = LinkItem[];

export default createModel({
  state: [
    {
      title: "关于数莓派",
      children: [
        {
          title: "关于我们",
          link: "/page/aboutus/",
        },
        {
          title: "服务条款",
          link: "/page/termsofservice/",
        },
        {
          title: "隐私政策",
          link: "/page/privacy/",
        },
        {
          title: "平台服务条款",
          link: "/page/platform/termsofservice/",
        },
        {
          title: "数莓派推广者(代理)规范条款",
          link: "/page/promotion/",
        },
      ],
    },
    {
      title: "客户服务",
      children: [
        {
          title: "订单状态",
          link: "/account/?type=order",
        },
        {
          title: "客户服务",
          link: "",
          action: "customer_service",
        },
      ],
    },
    {
      title: "加入",
      children: [
        {
          title: "商家入驻",
          link: "",
          action: "merchants_settled",
        },
        {
          title: "企业合作",
          link: "#",
        },
      ],
    },
  ] as StateType,
  effects: {
    async contactUs(payload) {
      const {
        callback,
        contact_name,
        contact_telephone,
        contact_email,
        contact_position,
        company,
      } = payload;
      const res = await GraphqlMutation("ContactUs", {
        action: "settle_in",
        contact_name: contact_name,
        contact_telephone: contact_telephone,
        contact_email: contact_email,
        contact_position: contact_position,
        company: company,
      });
      callback && callback(res);
    },
    async getPlatForm(payload) {
      const { callback } = payload;
      const data = new PlatForm();
      const res = await GraphqlQuery("Platform", data);
      callback && callback(getFirstItem(res));
    },
  },
});
