import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as styles from "./index.scss";
import { RootState, Dispatch } from "../../store/configureStore";
import { useIntl } from "react-intl";
import { Row, Col, Layout, Form, Divider, message } from "antd";
import { BusinessContext } from "../BusinessContext";
import Icon from "../../components/icon";
import PoweredBy from "../../static/img/poweredby@2x.png";
import GongAn from "../../static/img/gongan.png";
import Modal from "../../wrappers/modal";
import { PrimaryButton } from "../../wrappers/button";
import Input from "../../wrappers/input";
import { Link } from "../../wrappers/typography";
import { useTheme } from "styled-components";
import Smp from "../../static/img/smppp.png";

type platform = {
  business_time: string;
  platform_phone: string;
  platform_email: string;
  platform_address: string;
};

const { Footer } = Layout;

const LayoutFooter: React.FC = () => {
  const dispatch = useDispatch<Dispatch>();
  const { search = "" } = useSelector((state: RootState) => state.URLSearch);
  const { formatMessage: f } = useIntl();
  const { primaryColor, bussinessPrimaryColor } = useTheme();

  const { host_id } = useSelector((state: RootState) => state.URLSearch);
  const { isPrivate } = useSelector(
    (state: RootState) => state.CommonData || {},
  );

  const [customerServiceShow, setCustomerService] = useState(false);
  const [merchants, setMerchants] = useState(false);
  const [platFormData, setPlatForm] = useState<platform>();

  const nowDate = new Date(),
    nowYear = nowDate.getFullYear();

  const { isToBusiness } = useContext(BusinessContext);

  // tslint:disable-next-line:variable-name
  const footer_data = useSelector((state: RootState) => state.FooterPage || []);
  const formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 20,
    },
  };
  const handleSubmitForm = (val: any) => {
    const data = {
      ...val,
      callback: (res: any) => {
        message.success(res.message);
        setMerchants(false);
      },
    };
    dispatch.FooterPage.contactUs(data);
  };

  const getPlatForm = () => {
    const data = {
      callback: res => {
        setPlatForm(res);
        setCustomerService(true);
      },
    };

    dispatch.FooterPage.getPlatForm(data);
  };

  return (
    <Footer
      style={{
        backgroundColor: "#fff",
      }}
      className={styles["ChildTimeFooter"]}
    >
      {host_id && isPrivate ? (
        <>
          <Row justify={"center"}>
            <div className={styles[`Footer-market-img`]}>
              <img src={PoweredBy} />

              <span style={{ color: "#CACACA", fontSize: 20 }}>
                {" "}
                ｜
                <Icon
                  type="iconshumeipailogotuxing-02"
                  style={{ color: "#888888" }}
                ></Icon>{" "}
                数莓派
              </span>
            </div>
          </Row>
          <Row justify={"center"}>
            <Link href="/" target="_blank" underline>
              备案资质请前往数莓派
            </Link>
          </Row>
        </>
      ) : (
        <Row>
          <Col span={24}>
            <div className={styles[`Footer-contact-info`]}>
              {footer_data.map((v, i) => (
                <div key={i} className={styles["Footer-contact-info-item-box"]}>
                  <h2>{v.title}</h2>
                  {v.children?.map((item, index) => (
                    <div
                      key={index}
                      className={styles["Footer-contact-info-item"]}
                    >
                      {item.action &&
                        (item.action === "customer_service" ? (
                          <Link
                            onClick={() => {
                              if (!platFormData) {
                                getPlatForm();
                              } else {
                                setCustomerService(true);
                              }
                            }}
                          >
                            {item.title}
                          </Link>
                        ) : (
                          <Link onClick={() => setMerchants(true)}>
                            {item.title}
                          </Link>
                        ))}

                      {!item.action &&
                        (item.link !== "" ? (
                          <Link target="_blank" href={`${item.link}${search}`}>
                            {item.title}
                          </Link>
                        ) : (
                          <a target="_blank">{item.title}</a>
                        ))}
                    </div>
                  ))}
                </div>
              ))}
              <div className={styles["Footer-contact-info-item-box"]}>
                <h2>{f({ id: "Copyright" })}</h2>
                <div className={styles["Footer-contact-info-item"]}>
                  Copyright ©2018 - {nowYear} MegaHome LLC. All rights reserved
                </div>
                <div className={styles["Footer-contact-info-item"]}>
                  北京每家科技有限公司版权所有
                </div>
                <div className={styles["Footer-contact-info-item"]}>
                  <Link href="http://beian.miit.gov.cn/" target="_blank">
                    京ICP备18022364号
                  </Link>
                  <span style={{ marginLeft: 10 }}>ICP证</span>
                  <Link
                    href="https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/common/icp.pdf"
                    target="_blank"
                  >
                    京B2-20200985
                  </Link>
                </div>
                <div className={styles["Footer-contact-info-item"]}>
                  <img src={GongAn} />
                  <Link
                    href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802032124"
                    target="_blank"
                  >
                    京公网安备 11010802032124号
                  </Link>
                </div>
              </div>
              <div className={styles["Footer-contact-info-item-box"]}>
                <div className={styles["Footer-contact-info-item"]}>
                  <ul className={styles[`Footer-social-block`]}>
                    <li className="colored-icon">
                      <a href="https://weibo.com/u/7451680284" target="_blank">
                        <Icon
                          type="iconweibodenglufanbai"
                          style={{
                            color: isToBusiness
                              ? bussinessPrimaryColor
                              : primaryColor,
                          }}
                        />
                      </a>
                    </li>
                    <li className="colored-icon">
                      <a
                        href="https://www.zhihu.com/org/shu-mei-pai-1"
                        target="_blank"
                      >
                        <Icon
                          type="iconzhihu"
                          style={{
                            color: isToBusiness
                              ? bussinessPrimaryColor
                              : primaryColor,
                          }}
                        />
                      </a>
                    </li>
                    <li className="colored-icon">
                      <a
                        href="https://www.xiaohongshu.com/user/profile/5eb134790000000001006aed"
                        target="_blank"
                      >
                        <Icon
                          type="iconxiaohongshu"
                          style={{
                            color: isToBusiness
                              ? bussinessPrimaryColor
                              : primaryColor,
                          }}
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Col>
          <Col span={6} push={9}>
            <div className={styles["Footer-social-block-box"]}>
              <ul
                className={styles[`Footer-social-block`]}
                style={{ color: primaryColor }}
              ></ul>
            </div>

            <div className={styles[`Footer-copyright-box`]}>
              <div className={styles[`Footer-market-img`]}>
                <img src={PoweredBy} />
              </div>
            </div>
          </Col>
        </Row>
      )}

      <Modal
        onCancel={() => setCustomerService(false)}
        visible={customerServiceShow}
        width="486px"
        footer={null}
        title={f({ id: "CustomerService" })}
      >
        <div className={styles[`Footer-Service`]}>
          <div className={styles[`Footer-Service-content`]}>
            <Row className={styles[`Footer-Service-content-row`]}>
              <Icon type="icondianhua2"></Icon>
              电话 : {platFormData?.platform_phone}
            </Row>
            <Row className={styles[`Footer-Service-content-row`]}>
              <Icon type="iconmail-copy"></Icon>
              邮箱 : {platFormData?.platform_email}
            </Row>
            <Row className={styles[`Footer-Service-content-row`]}>
              <Icon type="icondizhi1"></Icon>
              地址 : {platFormData?.platform_address}
            </Row>
            <Divider />
            <Row>
              <Col span={9}>
                <img
                  src="https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/cs/psg.jpg"
                  alt=""
                  className={styles[`Footer-Service-content-row`]}
                />
              </Col>
              <Col span={15}>
                <div style={{ marginTop: 25 }}>产品咨询/优惠促销</div>
                <div style={{ marginTop: 20, color: "#4b4b4b" }}>
                  微信扫一扫，咨询产品信息
                </div>
                <div style={{ color: "#4b4b4b" }}>获取最新优惠。</div>
              </Col>
            </Row>
            <Divider />
          </div>
        </div>
      </Modal>

      <Modal
        onCancel={() => setMerchants(false)}
        visible={merchants}
        width="600px"
        footer={null}
        title={f({ id: "Tenants" })}
      >
        <div className={styles[`Footer-Service`]}>
          <Row style={{ padding: "0 25px", fontSize: "14px" }}>
            {f({ id: "LetOurProfessionalAccountManagerMakeTheSolutionForYou" })}
          </Row>
          <Form
            {...formItemLayout}
            initialValues={{ remember: true }}
            onFinish={handleSubmitForm}
            style={{ padding: "10px 35px" }}
          >
            <Form.Item name="contact_name" label={f({ id: "Name" })}>
              <Input placeholder={f({ id: "Name" })} />
            </Form.Item>
            <Form.Item name="contact_telephone" label={f({ id: "Phone" })}>
              <Input placeholder={f({ id: "Phone" })} />
            </Form.Item>
            <Form.Item
              name="contact_email"
              label={f({ id: "Email" })}
              rules={[{ required: true, message: f({ id: "EnterEmail" }) }]}
            >
              <Input placeholder={f({ id: "EnterEmail" })} />
            </Form.Item>
            <Form.Item
              name="contact_position"
              label={f({ id: "Position" })}
              rules={[{ required: true, message: f({ id: "EnterPosition" }) }]}
            >
              <Input placeholder={f({ id: "Position" })} />
            </Form.Item>

            <Form.Item
              name="company"
              label={f({ id: "FirmName" })}
              rules={[{ required: true, message: f({ id: "EnterFirmName" }) }]}
            >
              <Input placeholder={f({ id: "FirmName" })} />
            </Form.Item>
            <Row>
              <Col span={18}></Col>
              <Col span={6} style={{ textAlign: "right" }}>
                <PrimaryButton htmlType="submit" block bgColor={primaryColor}>
                  {f({ id: "EligibilityForExemption" })}
                </PrimaryButton>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </Footer>
  );
};

export default React.memo(LayoutFooter);
