/**
 * 复制内容到剪切板
 * @param {string} text 文本内容
 */
export function copyToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.style.position = "fixed";
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.width = "2em";
  textArea.style.height = "2em";
  textArea.style.padding = "0";
  textArea.style.border = "none";
  textArea.style.outline = "none";
  textArea.style.boxShadow = "none";
  textArea.style.background = "transparent";
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();

  try {
    return document.execCommand("copy");
  } catch (err) {
    throw err;
  } finally {
    document.body.removeChild(textArea);
  }
}

/**
 * 检测视窗宽度，与element ui的划分统一
 * @returns {string} xs|sm|md|lg|xl
 */
export function detectViewportWidth() {
  const width =
    document.body.clientWidth || document.documentElement.clientWidth;

  if (width < 768) {
    return "xs";
  } else if (width < 992) {
    return "sm";
  } else if (width < 1200) {
    return "md";
  } else if (width < 1920) {
    return "lg";
  } else return "xl";
}
