import React, { forwardRef, useContext } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/configureStore";
import * as styles from "./index.scss";
import Image from "../../components/image";
import { BusinessContext } from "../BusinessContext";
import replaceState from "../../helpers/reolaceState";
import { useIntl } from "react-intl";
import List from "../../wrappers/list";
import { useTheme } from "styled-components";

const RecommendContent = () => {
  const { RecommendData } = useSelector(
    (state: RootState) => state.RecommendPage || {},
  );
  const { formatMessage: f } = useIntl();

  const { isToBusiness } = useContext(BusinessContext);

  const { bussinessPrimaryColor } = useTheme();
  return (
    <div className={styles["Recommend"]}>
      <div className={styles["Recommend-header"]}>{RecommendData?.title}</div>

      {RecommendData?.type === "category" && (
        <div className={styles["Recommend-Category"]}>
          <List
            dataSource={RecommendData.child}
            split={false}
            grid={{ gutter: 10, column: 5 }}
            renderItem={(item: any, index) => (
              <List.Item key={index}>
                <a
                  onClick={() => {
                    replaceState.linkTo(item.link);
                  }}
                  key={index}
                  className={styles["Recommend-Category-item"]}
                >
                  <Image src={item.picture} width={1} height={1}>
                    <div className={styles["Recommend-Category-title"]}>
                      {item.title}
                    </div>
                  </Image>
                  {isToBusiness && (
                    <div
                      className={styles["Recommend-tag"]}
                      style={{
                        background: bussinessPrimaryColor,
                        top: 5,
                        right: 5,
                      }}
                    >
                      企业专享
                    </div>
                  )}
                </a>
              </List.Item>
            )}
          ></List>
        </div>
      )}

      {RecommendData?.type === "room" && (
        <div className={styles["Recommend-Room"]}>
          <List
            dataSource={RecommendData.child}
            split={false}
            grid={{ gutter: 10, column: 5 }}
            renderItem={(item: any, index) => (
              <List.Item key={index}>
                <a
                  key={index}
                  className={styles[`Recommend-Room-card-item`]}
                  onClick={() => {
                    replaceState.linkTo(item.link);
                  }}
                >
                  <Image src={item.picture} width={1} height={1} />
                  <div className={styles[`Recommend-Room-card-name`]}>
                    <span>{item.title}</span>
                  </div>
                  {isToBusiness && (
                    <div
                      className={styles["Recommend-tag"]}
                      style={{ background: bussinessPrimaryColor }}
                    >
                      企业专享
                    </div>
                  )}
                </a>
              </List.Item>
            )}
          ></List>
        </div>
      )}

      {RecommendData?.type === "style" && (
        <div className={styles["Recommend-Style"]}>
          <List
            dataSource={RecommendData.child}
            split={false}
            grid={{ gutter: 10, column: 5 }}
            renderItem={(item: any, index) => (
              <List.Item key={index}>
                <div className={styles["Recommend-Style-box"]}>
                  <a
                    onClick={() => {
                      replaceState.linkTo(item.link);
                    }}
                    className={styles["Recommend-Style-item"]}
                  >
                    <img src={item.picture} />
                    <span>{item.title}</span>
                  </a>
                  {!item.link && (
                    <div className={styles["Recommend-Style-mask"]}>
                      <div className={styles["Recommend-Style-mask-alert"]}>
                        {f({ id: "StayTuned" })}
                      </div>
                    </div>
                  )}
                  {isToBusiness && (
                    <div
                      className={styles["Recommend-tag"]}
                      style={{
                        background: bussinessPrimaryColor,
                        right: 7,
                        top: 10,
                      }}
                    >
                      企业专享
                    </div>
                  )}
                </div>
              </List.Item>
            )}
          ></List>
        </div>
      )}
    </div>
  );
};

export default RecommendContent;
