import { createModel } from "@rematch/core";

interface Locale {
  currentLang: string;
  langOptions: string[];

  langSwitch: boolean;

  pageKey: string;

  languageUrl: string;
}

export default createModel({
  state: {} as Locale,
  reducers: {
    changeLocale(state, payload) {
      return { ...state, currentLang: payload };
    },
  },
});
