import * as React from "react";
import { RootState } from "../../store/configureStore";
import { useSelector } from "react-redux";
import * as styles from "./index.scss";
import Header from "./component/Header";
import CopyRight from "./component/CopyRight";
import Icon from "../../components/icon";
import { Row, Col } from "antd";

const AboutUsContent = () => {
  const { AboutUs = "" } = useSelector((state: RootState) => state.SystemPage);
  return (
    <div className={styles["About"]}>
      <Header />
      <div className={styles["About-banner"]}>
        <img
          src="https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/common/about_us_banner.jpg"
          alt=""
        />
      </div>
      <div className={styles["About-content"]} id="About">
        <div className={styles["About-content-preface"]}>
          <h3>关于我们</h3>
          <p
            style={{
              lineHeight: "40px",
              color: "#4b4b4b",
              fontSize: "18px",
            }}
          >
            数莓派（shumeipie.com），泛家居行业垂直购物平台，始终秉持着“将最好的家居产品给最好的你”的理念，致力于为消费者提供高品质、高调性、高性价比的泛家居类产品。依托于家居行业特性，利用互联网科技能力，将线上销售与线下体验紧密结合，保障消费者需求在线上与线下都得以满足，也为家居品牌提供新的销售空间。
            目前数莓派平台产品已涵盖家居、办公、餐厨、智能、日用、装饰、灯具、洁具等各大家居场景和产品品类。将“更好的选择”延展到生活家居领域的方方面面。数莓派围绕着实用生活场景和实际采销需求上，精心打造并挑选家居好物的平台，优选供应商和产品，做到好产品安心买，好体验放心用。为提升用户家居生活品质而努力。
            数莓派作为一个开放但品控严格的家居购物品台，将会持续引入大批优质的，拥有设计、制造、销售、物流、售后等完整链条能力的第三方产品，并扶持第三方品牌独立发展，通过提供平台和工具等基础服务，帮助更多家居企业开拓市场、建立品牌，实现家居产业升级。同时，数莓派通过整合家居优质供应链，为家居产品消费、企业采购提供可靠的交易渠道。
          </p>
        </div>
        <Row gutter={40}>
          <Col span={12}>
            <img
              src="https://www.megahomeus.com/static/img/aboutUs-l.a708249.png"
              alt=""
            />
          </Col>
          <Col span={12}>
            <img
              src="https://www.megahomeus.com/static/img/aboutUs-r.9c2f892.png"
              alt=""
            />
          </Col>
        </Row>
        <Row gutter={20}>
          <div className={styles["About-content-details"]}>
            <Col span={6}>
              <div className={styles["About-content-details-box"]}>
                <div className={styles["About-content-details-box-logo"]}>
                  <Icon type="iconxinyongchengnuo"></Icon>
                </div>
                <h3>先行赔付承诺</h3>
                <hr></hr>
                <div>
                  所有商品出现质量问题，数莓派承担先行赔付，安全交易安心买{" "}
                </div>
              </div>
            </Col>
            <Col span={6}>
              <div className={styles["About-content-details-box"]}>
                <div className={styles["About-content-details-box-logo"]}>
                  <Icon type="iconyanxuanfengnong"></Icon>
                </div>
                <h3>严选模式</h3>
                <hr></hr>
                <div>
                  所有商品都经过专业标准检验，买手团队精心挑选，品质保障放心买。{" "}
                </div>
              </div>
            </Col>
            <Col span={6}>
              <div className={styles["About-content-details-box"]}>
                <div className={styles["About-content-details-box-logo"]}>
                  <Icon type="icontuihuanhuo"></Icon>
                </div>
                <h3>七天无理由退换货</h3>
                <hr></hr>
                <div>
                  商品在不影响二次销售的情况下，数莓派支持七天无理由退换货，售后保障无忧买。{" "}
                </div>
              </div>
            </Col>
            <Col span={6}>
              <div className={styles["About-content-details-box"]}>
                <div className={styles["About-content-details-box-logo"]}>
                  <Icon type="iconfuwu1"></Icon>
                </div>
                <h3>落地服务</h3>
                <hr></hr>
                <div>
                  支持线上预约门店体验商品，选择一流的配送、安装及修复的专业公司合作，专业服务省心买。{" "}
                </div>
              </div>
            </Col>
          </div>
        </Row>
      </div>
      <CopyRight />
    </div>
  );
};

export default React.memo(AboutUsContent);
