import React, { useState, useEffect } from "react";
import * as styles from "./index.scss";
import { useSelector } from "react-redux";

import { Row, Col, Spin, message, Space } from "antd";
import { useTheme } from "styled-components";
import Checkbox from "../../wrappers/checkbox";
import Input from "../../wrappers/input";
import { useIntl } from "react-intl";
import Price from "../../components/highlight";
import Icon from "../../components/icon";
import replaceState from "../../helpers/reolaceState";
import AgentForm from "../UserCenter/components/AgentForm";
import { RootState } from "../../store/configureStore";

const PageNotFound = () => {
  const { primaryColor, linkColor } = useTheme();
  const { formatMessage: f } = useIntl();
  const { agent_code } = useSelector((state: RootState) => state.URLSearch);

  const [type, setType] = useState(1);
  const [agentCode, setAgentCode] = useState("");
  const [checked, setChecked] = useState(false);
  const [payment, setPayment] = useState(1);

  useEffect(() => {
    if (agent_code) {
      setAgentCode(agent_code);
      replaceState.set({ agent_code: undefined });
    }
  }, [agent_code]);
  return (
    <div className={styles["VerifyAgent"]}>
      <div className={styles["VerifyAgent-content"]}>
        <Row className={styles["VerifyAgent-content-header"]}>成为代理</Row>
        <Row>
          <Col span={12} className={styles["VerifyAgent-content-lbox"]}>
            <Checkbox
              shape="circle"
              style={{
                marginRight: 5,
              }}
              checked={type === 1}
              onChange={(e: any) => {
                setType(e.target.checked ? 1 : 2);
              }}
            >
              我有推荐码(免费)
            </Checkbox>
            <Row className={styles["VerifyAgent-content-sheader"]}>推荐码</Row>
            <Input
              placeholder={f({
                id: "PleaseEnter",
              })}
              value={agentCode}
              onChange={e => setAgentCode(e.target.value)}
            ></Input>
            <Row className={styles["VerifyAgent-content-holder"]}>
              请输入您的推荐码，免费成为数莓派 (代理)。
            </Row>
          </Col>
          <Col span={12} className={styles["VerifyAgent-content-rbox"]}>
            <Checkbox
              shape="circle"
              style={{
                marginRight: 5,
              }}
              checked={type === 2}
              onChange={(e: any) => {
                setType(e.target.checked ? 2 : 1);
              }}
            >
              我没有推荐码
            </Checkbox>
            <Row className={styles["VerifyAgent-content-sheader"]}>
              数莓派推广者 (代理) 审核费用
            </Row>
            <Space className={styles["VerifyAgent-content-rbox-price"]}>
              <span
                style={{
                  fontSize: "1.8em",
                  color: primaryColor,
                }}
              >
                <Price text={"¥298.00"} />
              </span>
              <span
                style={{
                  fontSize: "1.2em",
                  color: "#4B4B4B",
                  textDecoration: "line-through",
                }}
              >
                <Price text={"¥598.00"} fontSize={"1.2em"} />
              </span>
            </Space>
            <Row className={styles["VerifyAgent-content-holder"]}>
              该审核费用用于审核您的推广者实名认证资质，为一次性费用。认证资质后即可享受优惠折扣和领取推荐奖励。
            </Row>
            <Row>
              <Checkbox
                shape="circle"
                checked={payment === 1}
                onChange={() => setPayment(1)}
              >
                <Icon
                  type="iconzhifubao"
                  style={{ fontSize: "1.2em", marginRight: 3 }}
                />
                支付宝支付
              </Checkbox>
              <Checkbox
                shape="circle"
                checked={payment === 2}
                onChange={() => setPayment(2)}
              >
                <Icon
                  type="iconweixinzhifu1"
                  style={{ fontSize: "1.2em", marginRight: 3 }}
                />
                微信支付
              </Checkbox>
            </Row>
          </Col>
        </Row>

        <Row className={styles["VerifyAgent-content-footer"]}>
          <Col span={18}>
            <Checkbox
              checked={checked}
              shape="circle"
              onChange={e => {
                setChecked(e.target.checked);
              }}
              style={{
                marginRight: 5,
              }}
            ></Checkbox>
            我已阅读并同意{" "}
            <span
              style={{
                color: linkColor,
                cursor: "pointer",
              }}
              onClick={() => replaceState.linkTo("/page/promotion/", "_blank")}
            >
              数莓派推广者(代理)规范条款{" "}
            </span>
            <u
              style={{
                color: linkColor,
                cursor: "pointer",
                fontSize: "15px",
                marginLeft: 10,
              }}
              onClick={() => replaceState.linkTo("/page/freelancer/", "_blank")}
            >
              自由职业者服务协议
            </u>{" "}
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            <AgentForm
              type={1}
              isSeparatePage={true}
              isDisabled={!checked}
              agentCode={agentCode}
              payType={type}
              paymentType={payment}
            ></AgentForm>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default React.memo(PageNotFound);
