import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, Dispatch } from "../../../store/configureStore";
import Icon from "../../../components/icon";
import { List, Breadcrumb } from "antd";
import { TextButton } from "../../../wrappers/button";
import { Text } from "../../../wrappers/typography";
import Renderer, { ItemType, RenderItem } from "../../../components/irender";
import { getFirstItem } from "../../../helpers/modelHelpers";
import AutoImage from "../../../components/image";
import tagPng from "../../../static/img/tag.png";
import { Product } from "../../../models/Product";
import styles from "../style.scss";

export interface RenderProps {
  render?: Renderer;
  getDragProps?: any;
  setRenderItems: (value: React.SetStateAction<RenderItem[]>) => void;
  setGroupList: (value: React.SetStateAction<string[][]>) => void;
  handleAddToCanvas: (
    item: Product,
    position?: number[],
    isBg?: boolean,
  ) => void;
}

const SystemMaterial: React.FC<RenderProps> = (props, ref) => {
  const {
    render,
    getDragProps,
    setRenderItems,
    setGroupList,
    handleAddToCanvas,
  } = props;

  const dispatch = useDispatch<Dispatch>();
  const { systemDirectory = [], systmMaterial = [] } = useSelector(
    (state: RootState) => state.DesignIdea,
  );

  const [breadcrumbs, setBreadcrumbs] = useState<any[]>([]);
  const [materialType, setMaterialType] = useState(0);

  useImperativeHandle(ref, () => ({
    initialize: () => {
      handleClickDirectory({
        level: 0,
        parent_tag_id: 0,
        picture_tag_id: -1,
        picture_tag_name: "目录",
      });
    },
  }));

  useEffect(() => {
    dispatch.DesignIdea.getSystemDirectoryAsync();

    setBreadcrumbs([
      {
        parent_tag_id: 0,
        picture_tag_id: -1,
        picture_tag_name: "目录",
        level: 0,
      },
    ]);
  }, []);

  const backgroundColorData = [
    "#F6B01B",
    "#AE52F4",
    "#5262F4",
    "#F6591B",
    "#F6591B",
  ];

  const handleAddMaterial = (type: string) => {
    let newItem;
    switch (type) {
      case "text":
        newItem = render?.add("New Text", {
          name: "New Text",
          type: ItemType.Text,
          isEditable: true,
          show: true,
          fontSize: 28,
          gradient: ["#666666", "#666666", "#666666"],
          center: [
            Math.floor(render?.canvas.width / 2),
            Math.floor(render?.canvas.height / 2),
          ],
        });
        break;
      case "tags":
        newItem = render?.add("New Tag", {
          name: "New Tag",
          type: "tag",
          isEditable: true,
          show: true,
          center: [
            Math.floor(render.canvas.width / 2),
            Math.floor(render.canvas.height / 2),
          ],
        });
        break;

      case "tag":
        const tagImg = new Image();
        tagImg.src = tagPng;
        tagImg.onload = () => {
          newItem = render?.add(tagImg, {
            width: tagImg.width,
            height: tagImg.height,
            type: "emptyValue",
            show: true,
            center: [
              Math.floor(render?.canvas.width / 2),
              Math.floor(render?.canvas.height / 2),
            ],
          });

          render?.addToSet(newItem.id);

          setRenderItems([...render?.objects]);

          setGroupList([...render?.sets]);
        };

        break;

      case "line":
        newItem = render?.add("", {
          name: "Line",
          type: "line",
          isEditable: true,
          show: true,
          center: [],
        });
        break;
      case "circle":
        newItem = render?.add("", {
          name: "Circle",
          type: "circle",
          isEditable: true,
          show: true,
          center: [],
        });
        break;
      case "rect":
        newItem = render?.add("", {
          name: "Rect",
          type: "rect",
          isEditable: true,
          show: true,
          isFillArea: false,
          center: [],
        });
        break;
      case "polygon":
        newItem = render?.add("", {
          name: "Polygon",
          type: "polygon",
          isEditable: true,
          show: true,
          isFillArea: true,
          gradient: ["#666666", "#666666", "#666666"],
          center: [
            Math.floor(render.canvas.width / 2),
            Math.floor(render.canvas.height / 2),
          ],
        });
        break;
      case "itemScaleX":
        newItem = render?.add("XXXcm", {
          name: "X Scale",
          type: "itemScaleX",
          isEditable: true,
          show: true,
          center: [
            Math.floor(render.canvas.width / 2),
            Math.floor(render.canvas.height / 2),
          ],
        });
        break;
      case "itemScaleY":
        newItem = render?.add("XXXcm", {
          name: "Y Scale",
          type: "itemScaleY",
          isEditable: true,
          show: true,
          center: [
            Math.floor(render.canvas.width / 2),
            Math.floor(render.canvas.height / 2),
          ],
        });
        break;
    }

    if (type !== "tag") {
      render?.addToSet(newItem.id);

      setRenderItems([...render?.objects]);

      setGroupList([...render?.sets]);
    }
  };

  let newBreadcrumbs = [
    {
      parent_tag_id: 0,
      picture_tag_id: -1,
      picture_tag_name: "目录",
      level: 0,
    },
  ];

  const handleClickDirectory = (item: any) => {
    const { picture_tag_id, level, parent_tag_id } = item;

    if (picture_tag_id === -1) {
      dispatch.DesignIdea.getSystemDirectoryAsync();
    } else {
      dispatch.DesignIdea.getSystemPictureAsync({
        picture_tag_id,
        level,
      });
    }

    if (item.picture_tag_id === -1) {
      setBreadcrumbs(newBreadcrumbs);
    } else {
      if (level > 1) {
        handleGetBreadcrumbs(parent_tag_id);
        newBreadcrumbs = [...newBreadcrumbs, item];
      } else {
        newBreadcrumbs = [...newBreadcrumbs, item];
      }
      newBreadcrumbs.sort(function (a, b) {
        return Number(a.level) - Number(b.level);
      });
      setBreadcrumbs(newBreadcrumbs);
    }
  };

  const handleGetBreadcrumbs = (pid: number) => {
    const parData = getFirstItem(
      systemDirectory.filter(m => m.picture_tag_id === pid),
    );
    newBreadcrumbs = [...newBreadcrumbs, parData];
    if (parData.level !== 1 && parData.parent_tag_id !== 0) {
      handleGetBreadcrumbs(parData.parent_tag_id);
    }
  };

  return (
    <div className={styles["materials-uploaded"]}>
      <Breadcrumb
        separator=">"
        style={{
          margin: "10px 0px",
        }}
        className="materials-breadcrumb"
      >
        {breadcrumbs.map((item: any, index: number) => (
          <Breadcrumb.Item>
            {index === breadcrumbs.length - 1 ? (
              <span>{item.picture_tag_name}</span>
            ) : (
              <a onClick={() => handleClickDirectory(item)}>
                {item.picture_tag_name}
              </a>
            )}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
      <List
        className={styles["materials-box"]}
        grid={{ gutter: 10, column: 4 }}
        dataSource={systmMaterial}
        renderItem={(item: any, index: number) => (
          <List.Item>
            {item.types !== "picture" && (
              <div
                className={styles["materials-catelog-item"]}
                style={{ borderRadius: 5 }}
              >
                <a
                  onClick={() => {
                    if (item.types) {
                      handleAddMaterial(item.types);
                    } else {
                      setMaterialType(item.tag_type);
                      handleClickDirectory(item);
                    }
                  }}
                  style={{
                    borderRadius: 5,
                    backgroundColor:
                      item.types === "text"
                        ? item.background
                        : backgroundColorData[
                            index % backgroundColorData.length
                          ],
                    border: item.types === "text" ? item.border : "",
                  }}
                >
                  <Icon type={item.icon}></Icon>
                  <Text color="#fff">{item.picture_tag_name}</Text>
                </a>
              </div>
            )}
            {item.types === "picture" && (
              <div
                className={`${styles["materials-uploaded-pic"]} ${styles["product-list-item"]}`}
                style={{
                  borderRadius: 5,
                  backgroundImage: materialType > 0 ? "none" : "",
                  background:
                    materialType > 0
                      ? materialType === 1
                        ? "black"
                        : "white"
                      : "",
                }}
              >
                <AutoImage
                  width={1}
                  height={1}
                  src={item.picture_url}
                  onClick={() => {
                    if (item.parent_tag_id !== 3) return;
                    handleAddToCanvas(
                      { ...item, product_picture: item.picture_url },
                      undefined,
                      true,
                    );
                  }}
                  {...getDragProps({
                    shop_product_id: 0,
                    product_picture: item.picture_url,
                    type: "uploaded",
                    isBg: Number(item.parent_tag_id) === 3 ? true : false,
                  })}
                >
                  <TextButton
                    onClick={e => {
                      handleAddToCanvas(
                        { ...item, product_picture: item.picture_url },
                        undefined,
                        item.parent_tag_id === 3,
                      );
                    }}
                    className={styles["product-item-btn"]}
                  >
                    <Icon
                      type="icondaoru"
                      style={{
                        transform: "rotate(180deg)",
                      }}
                    />
                  </TextButton>
                </AutoImage>
              </div>
            )}
          </List.Item>
        )}
      />
    </div>
  );
};

export default forwardRef<Renderer, RenderProps>(SystemMaterial);
