import * as React from "react";

/**
 * 原始组件通过 image_url_helper 模块提供的几个方法可以通过 imageId 计算出图片的src
 */

export type BaseImageProps = {
  style?: any;
  src: string;
  alt: string;
  srcset: string;
  sizes: string;
  // imageId: number,
  // commands: commandPropValidator,
  //    srcsetCommands: objectOf(commandPropValidator),
  // extension: 'jpg' | 'png'| 'gif',
  imageName: string;
  preserveCommandOrder: boolean;
  classes: string;
  draggable: true | false;
};

const BaseImage = (props: BaseImageProps) => {
  const {
    src,
    alt,
    srcset,
    sizes,
    //   imageId,
    //   commands,
    //   srcsetCommands,
    //   extension,
    imageName,
    preserveCommandOrder,
    classes,
    draggable,
    ...rest
  } = props;
  return (
    <img
      className={classes}
      height="100%"
      width="100%"
      alt={alt}
      src={src}
      srcSet={srcset}
      sizes={sizes}
      draggable={draggable}
      {...rest}
    />
  );
};

BaseImage.defaultProps = {
  src: "",
  alt: "",
  srcset: [],
  sizes: [],
  // imageId: IMAGE_NOT_FOUND_IREID,
  // commands: {},
  // srcsetCommands: {},
  // extension: 'jpg',
  imageName: "default_name",
  preserveCommandOrder: false,
  classes: "",
  draggable: false,
};

export default BaseImage;
