import * as React from "react";
import { StateType } from "../models";
import { Form, Row } from "antd";
import Icon from "../../../components/icon";
import { useIntl } from "react-intl";
import { useTheme } from "styled-components";
import { PrimaryButton } from "../../../wrappers/button";
import Input from "../../../wrappers/input";

interface ResetPasswordVerifyEmailProps {
  toEmailVerfication: (
    email: StateType["email"],
    emailverfication: StateType["emailVerficationType"],
  ) => void;
  insideModal?: boolean;
}

const ResetPasswordVerifyEmail: React.FC<ResetPasswordVerifyEmailProps> = ({
  toEmailVerfication,
  insideModal = true,
}) => {
  const { formatMessage: f } = useIntl();
  const { primaryColor, textColor } = useTheme();

  const onFinish = (data: any) => {
    toEmailVerfication(data.email, "forget");
  };

  return (
    <div>
      {!insideModal && (
        <Row style={{ padding: "10px 0 0 10px", color: textColor }}>
          {f({ id: "ForgotPasswordDashStepOne" })}
        </Row>
      )}

      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        style={{ padding: "45px" }}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: f({
                id: "PleaseEnterAValidEmailAddressExclamationMark",
              }),
            },
            {
              max: 60,
              message: f(
                { id: "TleLengthOfTheInputExceedNumCharacters" },
                {
                  num: 60,
                },
              ),
            },
            {
              type: "email",
              message: f({
                id: "PleaseEnterAValidEmailAddressExclamationMark",
              }),
            },
          ]}
        >
          <Input
            placeholder={f({
              id: "EnterEmailAddress",
            })}
          />
        </Form.Item>

        <Form.Item>
          <PrimaryButton htmlType="submit" block bgColor={primaryColor}>
            {f({ id: "Confirm" })}
            <Icon type="icont1_correct"></Icon>
          </PrimaryButton>
        </Form.Item>
      </Form>
    </div>
  );
};

export default React.memo(ResetPasswordVerifyEmail);
