import { createModel } from "@rematch/core";
import { getFirstItem } from "../../helpers/modelHelpers";
import {
  GraphqlQuery,
  GraphqlMutation,
  GraphqlQueryMulti,
} from "../../helpers/request.helper";
import ProductReview, {
  ProductReviewStatistic,
  ProductOrder,
  Media,
} from "../../models/ProductReview";
import { Product, ProductDetail, Sku, Activity } from "../../models/Product";
import Provider from "../../models/Provider";
import produce from "immer";
import { DesignIdea } from "../../models/DesignIdea";
import split from "lodash/split";
import { ShortUrl, Agent, ProductShareUrl } from "../../models/ShortUrl";
import queryString from "query-string";

export interface StateType {
  breadcrumbs: { url: string; title: string }[];

  breadcrumbsData: {
    shop_product_id: number;
    breadcrumbs: { url: string; title: string }[];
  }[];

  is_preview: boolean;

  sku: Sku;

  productDetails: ProductDetail;

  recommendProducts: Product[];

  recommendProductsCaches: {
    pid: number;
    RecommendProducts: Product[];
  }[];

  productReviewList: ProductReview[];

  moreReviewsVisable: boolean;

  productReviewStatistic: ProductReviewStatistic;

  productOrders: ProductOrder[];

  uploadMedias: Media[];

  productComparison: Product[];

  designIdeas: DesignIdea[];

  productProviders: Provider[];

  selectedProvider: Provider;

  productActivities: Activity[];
}

export default createModel({
  state: {} as StateType,
  reducers: {
    changeProductOption: (state: StateType, payload: number) => {
      const bcData = state.breadcrumbsData.find(
        bc => bc.shop_product_id === payload,
      );

      return bcData
        ? {
            ...state,
            breadcrumbs: bcData.breadcrumbs,
          }
        : state;
    },
    selectProvider: (state: StateType, selectedProvider?: Provider) => {
      // const { provider_id: provider } = selectedProvider;
      // const queryObj = queryString.parse(location.search);
      // history.pushState(
      //   { provider },
      //   "",
      //   `${location.pathname}?${queryString.stringify({
      //     ...queryObj,
      //     provider,
      //   })}`,
      // );

      return {
        ...state,
        selectedProvider,
      };
    },
    initDesignIdeas: (state: StateType, designIdeas: DesignIdea[]) => {
      return {
        ...state,
        designIdeas,
      };
    },
    initProductProvider: (state: StateType, productProviders: Provider[]) => {
      return {
        ...state,
        productProviders,
      };
    },
    placeProductInContrast: (
      state: StateType,
      payload: { shop_product_id: number; checked: boolean },
    ) => {
      const productComparison = payload.checked
        ? [
            ...(state.productComparison || []),
            {
              sku_id: state.sku?.sku_id,
              shop_id: state.sku?.shop_id,
              ...state.sku?.product.find(
                sp => sp.shop_product_id === payload.shop_product_id,
              ),
              ...state.productReviewStatistic,
              ...state.productDetails,
            },
          ]
        : state.productComparison.filter(
            p => p.shop_product_id !== payload.shop_product_id,
          );

      localStorage.setItem(
        "StoreProductContrast",
        JSON.stringify(productComparison || []),
      );

      return {
        ...state,
        productComparison,
      };
    },

    initProductComparison: (state: StateType, productComparison: Product[]) => {
      return {
        ...state,
        productComparison,
      };
    },
    clearReviewMedias: (state: StateType) => {
      return {
        ...state,
        uploadMedias: [],
      };
    },
    initReviewMedias: (state: StateType, media: Media) => {
      media.sort_number = (state.uploadMedias || []).length + 1;

      return {
        ...state,
        uploadMedias: [...(state.uploadMedias || []), media],
      };
    },
    initProductOrder: (state: StateType, productOrders: ProductOrder[]) => {
      return {
        ...state,
        productOrders,
      };
    },
    initProductReviewStatistic: (
      state: StateType,
      productReviewStatistic: ProductReviewStatistic,
    ) => {
      return {
        ...state,
        productReviewStatistic,
      };
    },
    initProductReview: (
      state: StateType,
      payload: {
        productReviewList: ProductReview[];
        moreReviewsVisable: boolean;
        resetLoad: boolean;
      },
    ) => {
      return {
        ...state,
        productReviewList: payload.resetLoad
          ? payload.productReviewList
          : [...(state.productReviewList || []), ...payload.productReviewList],
        moreReviewsVisable: payload.moreReviewsVisable,
      };
    },

    setSku: (state: StateType, { productDetails = [], sku }: any) => {
      return {
        ...state,
        productDetails: Array.isArray(productDetails)
          ? getFirstItem(productDetails, undefined)
          : productDetails,
        sku,
      };
    },
    setDefaultProduct: (state: StateType, { id }: { id: number }) => {
      return { ...state, default_product_id: id };
    },

    setProductMedia: (
      state: StateType,
      payload: {
        productDetails: StateType["productDetails"];
        product_id: number;
      },
    ) => ({
      ...state,
      productDetails: payload.productDetails,
      default_product_id: payload.product_id,
    }),

    setRecommendProducts: (
      state: StateType,
      payload: {
        recommendProducts: StateType["recommendProducts"];
        recommendProductsCaches: {
          pid: string;
          RecommendProducts: StateType["recommendProducts"];
        };
      },
    ) => ({
      ...state,
      recommendProducts: payload.recommendProducts,
      recommendProductsCaches: [
        ...(state.recommendProductsCaches || []),
        { ...payload.recommendProductsCaches },
      ],
    }),

    setProductMetric(
      state,
      payload: Omit<StateType["productDetails"], "mediaData">,
    ) {
      return {
        ...state,
        productDetails: { ...state.productDetails, ...payload },
      };
    },
    changeRecommendProductsMetric: (
      state: StateType,
      payload: ProductDetail[],
    ) =>
      produce(state, draft => {
        payload.forEach(pd => {
          const index = state.recommendProducts.findIndex(
            rp => rp.product_id === pd.product_id,
          );

          // draft.recommendProducts[index].shipData = pd.shipData;
          draft.recommendProducts[index].specificData = pd.specificData;
        });
      }),
    changeProductComparisonMetric: (
      state: StateType,
      payload: ProductDetail[],
    ) =>
      produce(state, draft => {
        payload.forEach(pd => {
          const index = state.productComparison.findIndex(
            rp => rp.product_id === pd.product_id,
          );

          // draft.productComparison[index].shipData = pd.shipData;
          draft.productComparison[index].specificData = pd.specificData;
        });

        localStorage.setItem(
          "StoreProductContrast",
          JSON.stringify(draft.productComparison || []),
        );
      }),
    submitCollectDesign: (state: StateType, ideaId: number) =>
      produce(state, draft => {
        const index = state.designIdeas.findIndex(
          item => item.design_idea_id === ideaId,
        );

        draft.designIdeas[index].is_collect = !draft.designIdeas[index]
          .is_collect;
      }),
    initProductActivity: (
      state: StateType,
      productActivities: ProductOrder[],
    ) => {
      return {
        ...state,
        productActivities,
      };
    },
  },

  effects: {
    async appointProviderAsync(payload) {
      const { callback, ...appoint } = payload;
      const { data } = await GraphqlMutation("experience", {
        ...appoint,
        action: "experience",
      });

      callback && callback(data);
    },
    async getDesignIdeaAsync(payload: number) {
      const model = new DesignIdea({ shop_product_id: payload });
      const data = await GraphqlQuery("GetDesignIdea", model);
      this.initDesignIdeas(data);
    },
    async addToCartAsync(payload) {
      const { callback, ...cart } = payload;
      const { data } = await GraphqlMutation("Cart", {
        ...cart,
        action: "add",
      });

      callback && callback(data);
    },
    async addTobCartAsync(payload) {
      const { callback, ...cart } = payload;
      const { data } = await GraphqlMutation("TobCart", {
        ...cart,
        action: "add",
      });

      callback && callback(data);
    },
    async getShortUrlAsync(payload) {
      const { url, callback } = payload;

      const model = new ShortUrl(url);
      const data = await GraphqlQuery("ShortUrl", model);

      callback && callback(getFirstItem(data));
    },
    async getAgentCodeAsync(payload) {
      const { productId, reference_code = "", callback } = payload;

      const model = new Agent(productId, reference_code);
      const data = await GraphqlQuery("getAgentCodeByProductId", model);

      callback && callback(getFirstItem(data));
    },
    async getProductReviewListAsync(payload) {
      const { query, ...args } = payload;
      const model = new ProductReview(query);
      const data = await GraphqlQuery("ProductReviews", model, args);
      const { page_size, page } = args;
      this.initProductReview({
        productReviewList: data,
        moreReviewsVisable: data.length === page_size,
        resetLoad: page === 1,
      });
    },
    async getProductProvidersAsync(payload) {
      const model = new Provider({ shop_product_id: payload });

      const data = await GraphqlQuery("ProviderProducts", model);
      this.initProductProvider(data);
    },
    async getProductReviewStatisticAsync(payload) {
      const model = new ProductReviewStatistic({ shop_product_id: payload });

      const data = await GraphqlQuery("ProductReviewStatistical", model);
      this.initProductReviewStatistic(getFirstItem(data));
    },
    async getProductOrderAsync(payload) {
      const model = new ProductOrder({ shop_product_id: payload });

      const data = await GraphqlQuery("ProductReviewOrders", model);
      this.initProductOrder(data);
    },
    async addProductReviewAsync(payload) {
      const { callback, shop_product_id, ...rate } = payload;
      const { data } = await GraphqlMutation("Customer", rate);

      await this.getProductReviewStatisticAsync(shop_product_id);
      await this.getProductOrderAsync(shop_product_id);

      this.clearReviewMedias();

      callback && callback();
    },
    async uploadMediaAsync(payload) {
      const { callback, ...rest } = payload;
      const { data } = await GraphqlMutation("Upload", rest);

      const [media_url, media_width = 64, media_height = 64] = split(data, ",");

      this.initReviewMedias({
        media_type: 1,
        media_url,
        media_width,
        media_height,
      });

      if (callback) {
        callback(media_url);
      }
    },
    async getSkuAsync({
      shop_sku_id,
      shop_product_id,
    }: {
      shop_sku_id: number;
      shop_product_id: number;
    }) {
      const data = await GraphqlQueryMulti(
        { method: "Sku", model: new Sku({ shop_sku_id }) },
        { method: "Product", model: new ProductDetail({ shop_product_id }) },
      );
      const sku = getFirstItem(data.Sku, {});
      this.setSku({
        sku,
        productDetails: data.Product,
      });
    },
    async getRecommendProductsAsync({ id }: { id: number }) {
      const model = new Product({ shop_product_id: id });
      const data = await GraphqlQuery(
        "RecommendProducts",
        model,
        {},
        "RecommendProduct",
      );

      const recommendProducts = data;

      this.setRecommendProducts({
        recommendProducts,
        recommendProductsCaches: {
          pid: id,
          RecommendProducts: recommendProducts,
        },
      });
    },
    async getProductMediaAsync({
      id,
      is_preview,
    }: {
      id: number;
      is_preview?: boolean;
    }) {
      const model = new ProductDetail(
        is_preview ? { product_id: id } : { shop_product_id: id },
      );

      const data = await GraphqlQuery("Product", model, {
        is_preview,
      });

      this.setProductMedia({
        productDetails: getFirstItem(data),
        product_id: id,
      });
    },

    async getActivityAsync({ shop_product_id }: { shop_product_id: number }) {
      const data = await GraphqlQueryMulti({
        method: "GetActivities",
        model: new Activity({ shop_product_id }),
      });
      this.initProductActivity(data.GetActivities);
    },

    async getPrivateQrUrlAsync(payload) {
      const { callback, ...rest } = payload;

      const model = new ProductShareUrl();
      const data = await GraphqlQuery("WebQRCode", model, rest);

      if (callback) {
        callback(getFirstItem(data).picture);
      }
    },
  },
});
