export default {
  Messages: "Noticias",
  CurrentOrder: "orden",
  OrderHistory: "Historial de pedidos",
  Review: "Comentarios",
  YouDoNotHaveAnyMessageToShow: "No tienes ningún mensaje para mostrar.",
  YouHaveNotPLaceAnyOrderYetPeriod: "Aún no has realizado ningún pedido.",
  ViewOrderDetails: "Ver detalles de la orden",
  ThereIsNoHistoricalOrder:
    "Todavía no ha realizado un pedido, no hay un pedido histórico, ¡vaya y elija el producto correcto!",
  ToSpeedUptheCheckOut:
    "Para acelerar el proceso de pago, para agregar otra dirección, simplemente haga clic en 'Agregar nueva dirección', complete el formulario y haga clic en el botón 'Guardar'.Si desea editar o eliminar cualquiera de sus direcciones existentes, simplemente haga clic en el enlace apropiado para su dirección existente.",
  More: "Más",
  Binding: "Unión",
  BindingReferenceCode: "Código de referencia vinculante",
  ChangePassword: "Cambiar contraseña",
  ReferenceCodeColon: "Código de referencia:",
  PersonalInformation: "Informacion personal",
  FirstName: "Nombre de pila",
  LastName: "Apellido",
  Gender: "Género",
  DateOfBirth: "Fecha de nacimiento",
  Email: "Correo electrónico",
  CellphoneNumber: "Número de teléfono celular",
  CurrentPassword: "Contraseña Actual",
  NewPassword: "Nueva contraseña",
  ConfirmPassword: "Confirmar contraseña",
  Password: "Contraseña",
  PleaseInputValidDateWithFormat:
    "Ingrese una fecha válida con el formato ‘XXXX-XX-XX’",
  PasswordDoesNotMatch: "Las contraseñas no coinciden",
  BindingSuccessed: "Éxito vinculante",
  UnbindingSuccessed: "Éxito no vinculante",
  TheReferenceCodeReachesTheUpperLimitOf:
    "El código de referencia alcanza el límite superior de 20.",
  ReferenceCodeHasBeenBound:
    "Se ha vinculado el código de referencia, inténtelo de nuevo.",
  InvalidReferenceCodePeriodPleaseEnterValidNumber:
    "Código de referencia inválido. Por favor, introduzca números y letras válidos.",
  Name: "Nombre",
  Address: "Habla a",
  PhoneNumber: "Número de teléfono",
  Alias: "Alias",
  Organization: "Nombre de la empresa",
  PaymentMethod: "Forma de pago",
  TotalInToal: "{total} En total",
  OrderDetails: "Detalles del pedido",
  ConfirmTheOrder: "Confirmar el pedido",
  PlseaseConfirmYourOrder: "Por favor confirme su pedido.",
  CancelTheOrder: "Cancelar el pedido",
  AreyouConfirmToCancelYourOrder: "¿Estás seguro de cancelar tu pedido?",
  YouCanAddUpToTwentyAddress:
    "Puede agregar hasta 20 direcciones. Has agregado {num} direcciones",
  AddYourAddressByClicking:
    "Agregue su dirección haciendo clic en 'Agregar nueva dirección'",
  AddingShippingAddress: "Agregar dirección de envío",
  LineOne: "Dirección: primera línea",
  LineTwo: "Dirección: segunda línea",
  City: "Ciudad",
  StateSlashProvince: "Provincia",
  PostalCode: "Código postal",
  Country: "País",
  CountryCode: "Código de país",
  Note: "Deja un mensaje",
  CouponSlashReferenceCode: "Cupón / Código de referencia",
  EnterEmailAddress: "Introducir la dirección de correo electrónico",
  OrderStatus: "Estado del pedido",
  ConsigneeInformation: "Información del consignatario",
  PaymentInformaton: "Información de pago",
  Quotation: "Cotización",
  ReviewProduct: "Revisar producto",
  TotalPrice: "Total {precio}",
  PriceSlashItem: "{precio} / artículo",
  AssemblyManualLeftParenthesisComingSoonRightParenthesis:
    "Manual de ensamblaje (próximamente)",
  CustomerService: "Servicio al Cliente",
  WelcomeBack: "Dar una buena acogida",
  SignUp: "Regístrate",
  EmailVerification: "verificacion de email",
  ForgotPasswordDashStepTwo: "Olvidé mi contraseña - Paso 2",
  ForgotPasswordDashStepOne: "Olvidé mi contraseña - Paso 1",
  ResetPassword: "Restablecer la contraseña",
  ForgotPassword: "Se te olvidó tu contraseña",
  InvalidEmailOrPassword: "Correo electrónico o contraseña no válidos",
  WelcomeToMegaHome: "Bienvenido a MegaHome",
  AlreadyHaveAnAccountQuestionMark: "¿Ya tienes una cuenta?",
  PleaseLogin: "Por favor Iniciar sesión",
  EnterThePassword: "Introduce la contraseña",
  Industry: "industria",
  PleaseCheckYourEmail: "Por favor revise su correo electrónico",
  IfYouDoNotReciveTheEmail:
    "Si no recibe el correo electrónico en unos minutos, verifique su carpeta de correo no deseado.",

  IHaveReadAndAcceptedMegahomeTermsOfService:
    "He leído y acepto los <a>Términos de servicio de MegaHome </a>.",
  WeHaveSentYouAConfirmationEmailEmail:
    "Le hemos enviado un correo electrónico de confirmación a ‘<span>{email}</span> ’. Verifique su casilla de correo electrónico y confirme la cuenta.",
  WeHaveSentYouAResetLink:
    "Le hemos enviado un enlace de restablecimiento por correo electrónico ‘<span>{email}</span> ’. Compruebe su casilla de correo electrónico y haga clic en el enlace de reinicio.",
  DidNotRevicedEmailQuestionMark: "¿No Recibió El Correo Electrónico?",
  ThisEmailAddressHasAlreadyBeenRegistered:
    "Esta dirección de correo electrónico ya se ha registrado, inicie sesión.",

  BackToMainMenu: "Regresar al menú principal",
  Back: "Volver",
  Homepage: "Página principal",
  ComingSoon: "Próximamente",
  ShopTheBrand: "Marca de la tienda",
  Account: "Cuenta",

  SignOut: "Desconectar",
  SendAgain: "Enviar de nuevo",
  BackToHomepage: "Volver a la página de inicio",
  TermOfService: "Términos de Servicio",
  NextStep: "Próximo paso",
  MarketplaceHome: "Homepage",
  Category: "Categoría",
  Room: "Habitación",
  NotAvailable: "No Disponible",
  ToReview: "Para revisar",
  ByUsingThisSiteYouAgreeToThe:
    "Al usar este sitio, usted acepta el uso de cookies para análisis, contenido personalizado y publicidad.",
  FindOutMore: "Saber más",
  Metric: "Métrica",
  Imperial: "Imperial",
  Home: "ホーム",
  Firm: "Firma",
  ReadAll: "Marcar Todo Como Leído",
  DeleteAll: "Eliminar Todo",
  AddNewAddress: "Agregar Nueva Dirección",
  Confirm: "Confirmar",
  Cancel: "Cancelar",
  SetAsDefault: "Establecer Como Predeterminado",
  Default: "Defecto",
  Required: "Requerido",
  TleLengthOfTheInputExceedNumCharacters:
    "La entrada debe tener al menos {num} caracteres de longitud",
  TheLengthOfTheInputMustBeAtLeast:
    "La longitud de la entrada debe tener al menos {num} caracteres",
  PleaseEnterAValidEmailAddressExclamationMark:
    "Por favor ingrese una dirección de correo electrónico válida!",
  Loading: "Cargando",
  Male: "Masculino",
  Female: "Mujeres",
  Other: "Otros",
  ByShopName: "Por",
  Odm: "ODM",
  Oem: "OEM",
  Customization: "Personalización",
  GetThePriceCommaSpecificationsCommaShipping:
    "¡Obtenga el precio, las especificaciones, el envío y más información gratis!",
  SignUpNow: "Regístrate ahora",
  Quantity: "Cantidad",
  Submit: "Enviar",
  Collection: "Favorito",

  AddToCart: "Añadir al Carrito",
  PleaseSelectOptions: "Por Favor Seleccione Una Opción",
  ContinueToCart: "Ingrese el Carrito de Compras",
  DisabledPeople: "Discapacitados",
  OopsCommaUploadFileExceedsMaximunSize:
    "El archivo que cargó supera los 10 millones. Por favor seleccione nuevamente.",
  OopsCommaUploadFileIsNotAValidImage:
    "El archivo que cargó no es un formato de imagen válido, solo puede usar archivos JPG y PNG. Sube de nuevo.",
  Cart: "Carro de Compras",
  LeftParenthesisPricePerItemRigthParenthesis: "({price} por ítem)",
  AddedToCart: "Agregado al Carrito",
  Specifications: "Especificación",
  Shipping: "Información de envío",
  Price: "Precio",
  PrintLabel: "Etiqueta de impresión",
  PrintQrCode: "Imprimir Código QR",
  YouCanSave: "Puedes Guardar",
  Product: "Producto",
  ToDo: "Operación",
  Total: "Suma",
  ContactUs: "Por Favor Contáctenos",
  Quote: "Citar",
  RemoveFromCart: "Eliminar Del Carrito",
  SelectAll: "Seleccionar Todo",
  SignIn: "Iniciar Sesión",
  ContinueShopping: "Seguir comprando",
  Login: "Iniciar Sesión",

  SectionsInMegahomeMarketplace:
    "Secciones en <a href='/termsofservice/#disclaimer'>Megahome Marketplace</a>",
  ItemsInMegahomeMarketplace:
    "Artículos en <a href='/termsofservice/#disclaimer'>Megahome Marketplace</a>",
  MegaHomeRecommended: "Recomendada",
  Viewed: "Horarios de navegación",
  Size: "Talla",
  Material: "Material",
  Color: "Color",
  Style: "Estilo",
  MoreFilters: "Más Filtros",
  Clear: "Claro",
  CountResults: "{count} Resultado",
  NewArrivals: "Nuevo producto",
  Selected: "Seleccionado",
  DashDashNoPreferenceDashDash: "-- Por Favor Elija --",
  DesignIdeas: "Ideas de Diseño",
  NumIdeas: " {num} ideas de diseño",
  Hide: "Ocultar",
  Show: "Display",
  ConnectWithUs: "Conectate con Nosotros",
  MegaHomeUsAddress: "39055 Hastings Street, Suite 208 Fremont, CA 94538",
  WeHaveRevicedYourFeedback:
    "Hemos recibido sus comentarios. Nos pondremos en contacto con usted lo antes posible.",
  SendAMessage: "Enviar mensaje",
  TellUsAboutYourNeeds: "Por favor informe sobre sus necesidades",
  Inquire: "Consulta",
  Feedback: "Realimentación",
  Partnership: "Asociación",
  ItHelp: "Soporte Técnico",
  Others: "Otros",
  HotelVendor: "Proveedor de Hotel",
  Retailers: "Minoristas",
  Designers: "Diseñadores",
  Suppliers: "Proveedores",
  About: "Información",
  AboutUs: "Sobre Nosotros",
  Join: "Únete a nosotros",
  Marketing: "Márketing",
  Disclaimer: "Descargo de Responsabilidad",
  Apply: "Aplicar",
  Return: "Retorno",
  LookingForMoreStylesQuestionMark: "¿Buscas más estilos?",
  FindMore: "Encuentre más",
  Share: "Comparte Mis Pensamientos",
  AddToCartSuccessfullyExclamationMark: "Añadir al carrito con éxito!",
  ProductDetails: "Detalles de producto",
  Description: "Descripción",
  WarningColonLeftBracketCaliforniaPropositionSixtyFive:
    "ADVERTENCIA: [Propuesta 65 de California] Confirme con el proveedor la seguridad del producto.",
  Features: "Caracteristicas",
  ProductFeatures: "Características del producto",
  ComponentsData: "Datos de componentes",
  Compare: "Comparar",
  Reviews: "Comentarios",
  SimilarProducts: "Productos Similares",
  BuyTogether: "Comprar juntos",
  ComparewithSimilarPorducts: "Comparar con productos similares",
  CustomerRating: "Puntuación de los clientes",
  SortByMostRecently: "Ordenar por más reciente",
  SortByRating: "Ordenar por clasificación",
  ReviewProductFromOrder: "Revisar producto del pedido",
  PleaseReviewOurProductsComma:
    "Revise nuestros productos, cuáles son sus experiencias y compártalas con otros usuarios.",
  AsteriskSignYouDoNotHaveFinishedOrder:
    "* No tiene producto de pedido terminado para revisar.",
  MoreReviews: "Más comentarios",
  MadeInCountry: "Hecho en {country}",
  PriceIncluded: "Precio incluido",
  MinPeriod: "mínimo",
  MaxPeriod: "Máximo",
  MyShopingCart: "Mi carrito de compras",
  CountItems: "{count} artículos",
  PleaseConfirm: "Por favor confirmar",
  AreYouConfirmToRemoveTheseItems:
    "¿Estás seguro de que deseas eliminar estos artículos del carrito?",
  YouHaveItemAddedWithDifferentCurrencyType:
    "Vaya, tienes elementos agregados con un tipo de moneda diferente. Elija solo una moneda para cotizar.",
  OopsExclamationMarkYouShoppingCartIsEmpty: "¡Uy! Su cesta está vacía.",
  SignInToSeeWhatYouHaveAddedOrSaved:
    "Inicie sesión para ver lo que ha agregado o guardado.",
  WeHaveALotOfSpecialProductOffers:
    "Tenemos muchas ofertas de productos especiales, o puede obtener inspiración de nuestro sitio web.",
  SelectedColonCountItems: "Seleccionado: {count} productos",
  StartFrom: "Empezar desde",
  ContainersFittingEstimate: "Estimación de ajuste de contenedores",
  QuoteInformation: "Información de consulta",
  SubmittedExclamationMark: "¡Presentada!",
  SelectFromSavedAddress: "Seleccionar de la dirección guardada",
  WeWillGetBackToYou: "¡Nos pondremos en contacto con usted lo antes posible!",

  OopsExclamationMark: "Uy!",
  WeCannotFindAnyResultThatMatchYour:
    "Ningún resultado coincide con su búsqueda:",
  ThePageTitleStillUnderConstructionPeriod:
    "La página {título} aún está en construcción. ¡Gracias por su paciencia!",
  SometingYouMayLikeColon: "Algo que te puede gustar:",
  OopsExclamationMarkCurrentFilterOptionsDoNot:
    "¡Uy! Las opciones de filtro actuales no tienen ningún resultado, vuelva a seleccionar.",
  SortAndFilter: "Filtro de clasificación",
  Supplier: "Proveedor",
  Shop: "Tienda",
  ShopEstablishedDate: "Fecha de establecimiento de la tienda",
  ShopRecommendedProducts: "Comprar productos recomendados",
  FurnitureAndDecor: "Muebles y Decoracion",
  SearchInThisShop: "Buscar en esta tienda",
  Copyright: "Derechos de autor",
  SearchItemsFromShop: "Busque productos en {shop}",
  Categories: "Categorias",
  Products: "Productos",
  OptionNotAvailable: "Opción no disponible",
};
