import React, { useState, useEffect, useContext, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "../../../store/configureStore";
import * as styles from "../index.scss";
import Icon from "../../../components/icon";
import AutoImage from "../../../components/image";
import { RootState } from "../../../store/configureStore";
import { useIntl } from "react-intl";
import { useTheme } from "styled-components";
import { BusinessContext } from "../../BusinessContext";
import { Tag, Row, Col } from "antd";
import Card from "../../../wrappers/card";
import { PaginationConfig } from "antd/lib/pagination";
import List from "../../../wrappers/list";

const orderList = (props: { type: number; pagination: PaginationConfig }) => {
  const { type, pagination } = props;
  const { formatMessage: f } = useIntl();

  const { commissions = [] } = useSelector(
    (state: RootState) => state.CommissionPage,
  );

  const { primaryColor, linkColor, textColor } = useTheme();

  return (
    <div className={styles["Commission-order"]}>
      <List
        dataSource={commissions}
        split={false}
        grid={{ gutter: 15, column: 1 }}
        pagination={pagination}
        renderItem={(item, index) => (
          <List.Item key={index}>
            <Card
              style={{ background: "white", marginBottom: 10 }}
              title={
                <Row gutter={10}>
                  <Col span={16}>
                    <span
                      style={{
                        color: primaryColor,
                        marginRight: "10px",
                      }}
                    >
                      <Icon
                        type="icondianpu2"
                        style={{ marginRight: "5px" }}
                      ></Icon>
                      {item.shop_name}
                    </span>
                    {Number(item.commission_type) === 2 && (
                      <span style={{ fontWeight: 400, margin: "0 10px" }}>
                        {`${item.recipient_name}  ${item.recipient_telephone}`}
                      </span>
                    )}

                    <span style={{ fontWeight: 400, color: "#888888" }}>
                      {`${item.created_at}  ${f({ id: "Create" })}`}
                    </span>
                    <span
                      style={{
                        fontWeight: 400,
                        margin: "0 10px",
                        color:
                          Number(item.order_status) === 2 ||
                          Number(item.order_status) === 3
                            ? "#c21d44"
                            : Number(item.order_status) === 0
                            ? "#bdbcbc"
                            : "",
                      }}
                    >
                      {item.order_status_text}
                    </span>
                  </Col>
                  <Col span={8} style={{ textAlign: "right" }}>
                    {type === 1 && (
                      <span
                        style={{
                          borderRadius: 30,
                          color: "white",
                          background: primaryColor,
                          padding: "7px 12px",
                        }}
                      >
                        {item.commission_type_text}
                      </span>
                    )}
                  </Col>
                </Row>
              }
            >
              <Row>
                <Col span={4}>
                  <AutoImage
                    width={58}
                    height={50}
                    src={item.product_picture}
                  />
                </Col>
                <Col span={8} style={{ height: "150px", padding: "0 10px" }}>
                  <p>{item.product_name}</p>
                  <p>
                    {item.brand_name && item.brand_name !== "" && (
                      <Tag color={primaryColor} style={{ borderRadius: 30 }}>
                        {item.brand_name}
                      </Tag>
                    )}
                    {item.series_name && item.series_name !== "" && (
                      <Tag color={primaryColor} style={{ borderRadius: 30 }}>
                        {item.series_name}
                      </Tag>
                    )}
                  </p>
                  <p style={{ position: "absolute", bottom: "0" }}>
                    {item.option}
                  </p>
                </Col>
                <Col span={4}>
                  <p>X{item.quantity}</p>
                </Col>
                <Col span={4}>
                  <p>
                    <span style={{ color: "#888888" }}>
                      {" "}
                      {f({ id: "PaymentAmount" })}：
                    </span>{" "}
                    {item.payment_amount_text}
                  </p>
                </Col>
                <Col span={4}>
                  <p>
                    {f({ id: "ExpectedIncome" })}：
                    <span style={{ color: primaryColor }}>
                      {item.commission_amount_text}
                    </span>
                  </p>
                </Col>
              </Row>
            </Card>
          </List.Item>
        )}
      ></List>
    </div>
  );
};
export default orderList;
