import { createModel } from "@rematch/core";
import { Collection } from "../models/Collection";
import { GraphqlQuery, GraphqlMutation } from "../helpers/request.helper";

export type CollectionState = number[];

export default createModel({
  state: [] as CollectionState,
  reducers: {
    initCollection: (state: CollectionState, collection: CollectionState) => {
      return collection || [];
    },
    submitCollectProduct: (
      state: CollectionState,
      { data_id, action }: { data_id: number; action: "like" | "cancel" },
    ) =>
      action === "like"
        ? [...state, Number(data_id)]
        : state.filter(item => item !== Number(data_id)),
  },
  effects: {
    async getCollectionAsync() {
      const data = await GraphqlQuery("CollectIds", new Collection(), {
        collect: "product",
      });

      const pids = data.map(c => c.data_id);

      this.initCollection(pids);
    },
    async submitCollectAsync(payload) {
      const { callback, ...model } = payload;

      await GraphqlMutation("Collect", model);

      if (model.object === "product") {
        this.submitCollectProduct(model);
      } else if (callback) {
        callback(model.data_id);
      }
    },
  },
});
