import { createModel } from "@rematch/core";
import Shop, { ShopCategory, ShopFooter } from "../models/Shop";

export type StateType = {};

export default createModel({
  state: [
    {
      data_id: 113,
      title: "家具1",
      picture:
        "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/113.jpg",
      level: 1,
      link: "/product-list/furniture/index.html",
      child: [
        {
          data_id: 71,
          title: "椅",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/gaea/system/-1/200921/17-5f68747e5dc8b.png",
          level: 2,
          link: "/product-list/furniture/chairs/index.html",
        },
        {
          data_id: 19,
          title: "沙发",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/20.jpg",
          level: 2,
          link: "/product-list/furniture/sofa/index.html",
        },
        {
          data_id: 30,
          title: "桌",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/30.jpg",
          level: 2,
          link: "/product-list/furniture/table-desk/index.html",
        },
        {
          data_id: 37,
          title: "柜",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/38.jpg",
          level: 2,
          link: "/product-list/furniture/cabinet-storage/index.html",
        },
        {
          data_id: 164,
          title: "休闲椅",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/164.jpg",
          level: 3,
          link: "/product-list/furniture/chairs/accent-chair/index.html",
        },
        {
          data_id: 29,
          title: "沙发凳",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/29.jpg",
          level: 3,
          link: "/product-list/furniture/sofa/footstool/index.html",
        },
        {
          data_id: 34,
          title: "茶几",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/34.jpg",
          level: 3,
          link: "/product-list/furniture/table-desk/coffee-table/index.html",
        },
        {
          data_id: 14,
          title: "床头柜",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/14.jpg",
          level: 3,
          link:
            "/product-list/furniture/cabinet-storage/bedside-table/index.html",
        },
        {
          data_id: 164,
          title: "休闲椅",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/164.jpg",
          level: 3,
          link: "/product-list/furniture/chairs/accent-chair/index.html",
        },
        {
          data_id: 29,
          title: "蒲团",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/29.jpg",
          level: 3,
          link: "/product-list/furniture/sofa/footstool/index.html",
        },
        {
          data_id: 34,
          title: "茶几",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/34.jpg",
          level: 3,
          link: "/product-list/furniture/table-desk/coffee-table/index.html",
        },
        {
          data_id: 14,
          title: "床头柜",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/14.jpg",
          level: 3,
          link:
            "/product-list/furniture/cabinet-storage/bedside-table/index.html",
        },
        {
          data_id: 164,
          title: "休闲椅",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/164.jpg",
          level: 3,
          link: "/product-list/furniture/chairs/accent-chair/index.html",
        },
        {
          data_id: 29,
          title: "蒲团",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/29.jpg",
          level: 3,
          link: "/product-list/furniture/sofa/footstool/index.html",
        },
        {
          data_id: 34,
          title: "茶几",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/34.jpg",
          level: 3,
          link: "/product-list/furniture/table-desk/coffee-table/index.html",
        },
        {
          data_id: 14,
          title: "床头柜",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/14.jpg",
          level: 3,
          link:
            "/product-list/furniture/cabinet-storage/bedside-table/index.html",
        },
        {
          data_id: 164,
          title: "休闲椅",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/164.jpg",
          level: 3,
          link: "/product-list/furniture/chairs/accent-chair/index.html",
        },
        {
          data_id: 29,
          title: "蒲团",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/29.jpg",
          level: 3,
          link: "/product-list/furniture/sofa/footstool/index.html",
        },
        {
          data_id: 34,
          title: "茶几",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/34.jpg",
          level: 3,
          link: "/product-list/furniture/table-desk/coffee-table/index.html",
        },
        {
          data_id: 14,
          title: "床头柜",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/14.jpg",
          level: 3,
          link:
            "/product-list/furniture/cabinet-storage/bedside-table/index.html",
        },
      ],
    },
    {
      data_id: 113,
      title: "家具2",
      picture:
        "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/113.jpg",
      level: 1,
      link: "/product-list/furniture/index.html",
      child: [
        {
          data_id: 71,
          title: "椅",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/gaea/system/-1/200921/17-5f68747e5dc8b.png",
          level: 2,
          link: "/product-list/furniture/chairs/index.html",
        },
        {
          data_id: 19,
          title: "沙发",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/20.jpg",
          level: 2,
          link: "/product-list/furniture/sofa/index.html",
        },
        {
          data_id: 30,
          title: "桌",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/30.jpg",
          level: 2,
          link: "/product-list/furniture/table-desk/index.html",
        },
        {
          data_id: 37,
          title: "柜",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/38.jpg",
          level: 2,
          link: "/product-list/furniture/cabinet-storage/index.html",
        },
        {
          data_id: 164,
          title: "休闲椅",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/164.jpg",
          level: 3,
          link: "/product-list/furniture/chairs/accent-chair/index.html",
        },
        {
          data_id: 29,
          title: "沙发凳",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/29.jpg",
          level: 3,
          link: "/product-list/furniture/sofa/footstool/index.html",
        },
        {
          data_id: 34,
          title: "茶几",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/34.jpg",
          level: 3,
          link: "/product-list/furniture/table-desk/coffee-table/index.html",
        },
        {
          data_id: 14,
          title: "床头柜",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/14.jpg",
          level: 3,
          link:
            "/product-list/furniture/cabinet-storage/bedside-table/index.html",
        },
      ],
    },
    {
      data_id: 113,
      title: "家具3",
      picture:
        "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/113.jpg",
      level: 1,
      link: "/product-list/furniture/index.html",
      child: [
        {
          data_id: 71,
          title: "椅",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/gaea/system/-1/200921/17-5f68747e5dc8b.png",
          level: 2,
          link: "/product-list/furniture/chairs/index.html",
        },
        {
          data_id: 19,
          title: "沙发",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/20.jpg",
          level: 2,
          link: "/product-list/furniture/sofa/index.html",
        },
        {
          data_id: 30,
          title: "桌",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/30.jpg",
          level: 2,
          link: "/product-list/furniture/table-desk/index.html",
        },
        {
          data_id: 37,
          title: "柜",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/38.jpg",
          level: 2,
          link: "/product-list/furniture/cabinet-storage/index.html",
        },
        {
          data_id: 164,
          title: "休闲椅",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/164.jpg",
          level: 3,
          link: "/product-list/furniture/chairs/accent-chair/index.html",
        },
        {
          data_id: 29,
          title: "脚凳",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/29.jpg",
          level: 3,
          link: "/product-list/furniture/sofa/footstool/index.html",
        },
        {
          data_id: 34,
          title: "茶几",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/34.jpg",
          level: 3,
          link: "/product-list/furniture/table-desk/coffee-table/index.html",
        },
        {
          data_id: 14,
          title: "床头柜",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/public/category/14.jpg",
          level: 3,
          link:
            "/product-list/furniture/cabinet-storage/bedside-table/index.html",
        },
      ],
    },
  ],
});
