import React, { useState, useEffect, useContext, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "../../store/configureStore";
import * as styles from "./index.scss";
import dayjs from "dayjs";
import { useIntl } from "react-intl";
import { RootState } from "../../store/configureStore";
import { AgentStatus } from "../../models/User";
import { AuditStatus } from "../../models/Agent";
import { BooleanLike } from "../../models/Common";
import AgentForm from "../UserCenter/components/AgentForm";
import { useTheme } from "styled-components";
import { BusinessContext } from "../BusinessContext";
import { Row, Col, Divider, Popover } from "antd";
import DatePicker from "../../wrappers/date-picker";
import Modal from "../../wrappers/modal";
import Input from "../../wrappers/input";
import Select from "../../wrappers/select";
import List from "../../wrappers/list";
import { PrimaryButton } from "../../wrappers/button";
import { Link } from "../../wrappers/typography";
import CommissionList from "./component/CommissionList";
import Invitation from "./component/Invitation";
import AutoImage from "../../components/image";
import Price from "../../components/highlight";
import Icon from "../../components/icon";
import replaceState from "../../helpers/reolaceState";

const Commission: React.FC = () => {
  const dispatch = useDispatch<Dispatch>();
  const { formatMessage: f } = useIntl();
  const { user, agent, confirmInfo } = useSelector(
    (state: RootState) => state.AccountPage,
  );
  const { host_id } = useSelector((state: RootState) => state.URLSearch);
  const { isPrivate } = useSelector((state: RootState) => state.CommonData);
  const { verifyPay } = useSelector((state: RootState) => state.User);

  const {
    commissions,
    agentShop,
    total,
    statistics,
    inCome,
    amounts,
    invitations,
    invitationsTotal,
    invitationStatistics,
  } = useSelector((state: RootState) => state.CommissionPage);

  const [page, setPage] = useState(1);
  const [CustomerModel, setCustomerModel] = useState(false);
  const [imgModal, setImgModal] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [navIndex, setNavIndex] = useState(0);
  const [selectedType, setSelectedType] = useState(1);
  const [startTime, setStartTime] = useState("");
  const [stopTime, setStopTime] = useState("");
  const [searchPhone, setSearchPhone] = useState("");
  const [commissionStatus, setStatus] = useState("-1");
  const [commissionType, setCommissionType] = useState("0");
  const [shopId, setShopId] = useState<number>();
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [headerData, setHeaderData] = useState([]);

  useEffect(() => {
    dispatch.AccountPage.getUserInfo({});
  }, []);

  useEffect(() => {
    if (user) {
      if (user?.agent_status === AgentStatus.Succeed) {
        setPage(2);
        dispatch.CommissionPage.getAmountAndShopAsync();
        dispatch.CommissionPage.getCommissionStatisticsAsync();
        dispatch.CommissionPage.getCommossionListAsync({});
        dispatch.CommissionPage.getInComeAsync({});
      }
    }
  }, [user]);

  const handleChangePage = (types: number) => {
    if (types < 3) {
      let type = "0";
      if (types === 2) {
        type = "2";
      }
      setStartTime("");
      setStopTime("");
      setSearchPhone("");
      setCommissionType(type);
      setStatus("-1");
      setShopId(undefined);
      const data = {
        start_time: "",
        end_time: "",
        commission_status: "-1",
        commission_type: type,
        telephone: "",
        page: 1,
        shop_id: "",
      };
      dispatch.CommissionPage.getCommossionListAsync(data);
      dispatch.CommissionPage.getInComeAsync(data);
    } else {
      dispatch.CommissionPage.getInvitationsAsync({ page: 1 });
      dispatch.CommissionPage.getInvitationStatisticsAsync();
    }
    setCurrentPageNumber(1);
  };

  // useEffect(() => {
  //   if (selectedType < 3) {
  //     let type = "0";
  //     if (selectedType === 2) {
  //       type = "2";
  //     }
  //     setStartTime("");
  //     setStopTime("");
  //     setSearchPhone("");
  //     setCommissionType(type);
  //     setStatus("-1");
  //     setShopId(undefined);
  //     const data = {
  //       start_time: "",
  //       end_time: "",
  //       commission_status: "-1",
  //       commission_type: type,
  //       telephone: "",
  //       page: 1,
  //       shop_id: "",
  //     };
  //     dispatch.CommissionPage.getCommossionListAsync(data);
  //     dispatch.CommissionPage.getInComeAsync(data);
  //   } else {
  //     dispatch.CommissionPage.getInvitationsAsync({ page: 1 });
  //     dispatch.CommissionPage.getInvitationStatisticsAsync();
  //   }
  //   setCurrentPageNumber(1);
  // }, [selectedType]);

  const { Option } = Select;

  const { primaryColor, linkColor, textColor } = useTheme();

  const rootAgentPage = () => {
    return (
      <div style={{ padding: 10, background: "white" }}>
        <div className={styles["Commission-agent-completion"]}>
          <div
            className={styles["Commission-agent-completion-customer-default"]}
          >
            <Icon type="icondianpu2"></Icon>
          </div>

          <div className={styles["Commission-agent-completion-content"]}>
            <p
              className={styles["Commission-agent-completion-title"]}
              style={{ color: primaryColor }}
            >
              {(confirmInfo && confirmInfo.root_name) || ""}
            </p>
            <p>
              {f({ id: "Address" })}：
              {(confirmInfo && confirmInfo.root_address) || ""}
            </p>
            <p>
              {f({ id: "ContactPhoneNumber" })}：
              {(confirmInfo && confirmInfo.platform_phone) || ""}
            </p>
          </div>
        </div>
        <Divider />
        <div>
          <Row style={{ marginBottom: 10 }}>
            <Col
              span={12}
              style={{ color: primaryColor }}
              className={styles["Commission-agent-userinfo-text"]}
            >
              {f({
                id:
                  "YouHaveBeenAddedAsTheDirectAgentPleaseCompleteTheTnformation",
              })}
            </Col>
            <Col
              span={12}
              style={{
                textAlign: "right",
                color: linkColor,
                cursor: "pointer",
              }}
              onClick={() => setCustomerModel(true)}
            >
              {f({ id: "WrongInformationContactCustomerServiceToModify" })}
            </Col>
          </Row>
          <div className={styles["Commission-agent-userinfo"]}>
            <Row
              gutter={10}
              className={styles["Commission-agent-userinfo-text"]}
            >
              <Col span={3} style={{ textAlign: "right" }}>
                {f({ id: "Name" })}:
              </Col>
              <Col span={20}>
                {(confirmInfo && confirmInfo.agent_name) || "-"}
              </Col>
            </Row>
            <Row
              gutter={10}
              className={styles["Commission-agent-userinfo-text"]}
            >
              <Col span={3} style={{ textAlign: "right" }}>
                {f({ id: "ContactPhoneNumber" })}:
              </Col>
              <Col span={20}>
                {(confirmInfo && confirmInfo.telephone) || "-"}
              </Col>
            </Row>
            <Row
              gutter={10}
              className={styles["Commission-agent-userinfo-text"]}
            >
              <Col span={3} style={{ textAlign: "right" }}>
                {f({ id: "IdCardNumber" })}:
              </Col>
              <Col span={20}>
                {(confirmInfo && confirmInfo.identity_card_number) || "-"}
              </Col>
            </Row>
            <Row style={{ display: "flex", justifyContent: "flex-end" }}>
              <AgentForm type={2} confirmInfo={confirmInfo} />
            </Row>
          </div>
        </div>

        <Modal
          visible={CustomerModel}
          onCancel={() => setCustomerModel(false)}
          width={500}
          footer={null}
        >
          <Row className={styles["Commission-agent-completion-customer"]}>
            {confirmInfo && !!confirmInfo.shop_logo ? (
              <img src={confirmInfo && confirmInfo.shop_logo}></img>
            ) : (
              <div
                className={
                  styles["Commission-agent-completion-customer-default"]
                }
              >
                <Icon type="icondianpu2"></Icon>
              </div>
            )}

            <div
              className={styles["Commission-agent-completion-customer-content"]}
            >
              <p
                className={styles["Commission-agent-completion-customer-title"]}
              >
                {(confirmInfo && confirmInfo.root_name) || "-"}
              </p>
              <p>
                <span
                  className={
                    styles["Commission-agent-completion-customer-span"]
                  }
                >
                  {f({ id: "RootContactPhone" })}：
                  {(confirmInfo && confirmInfo.contact_phone) || "-"}
                </span>
              </p>
              <p>
                <span
                  className={
                    styles["Commission-agent-completion-customer-span"]
                  }
                >
                  {f({ id: "PlatformPhone" })}：
                  {(confirmInfo && confirmInfo.platform_phone) || "-"}
                </span>
              </p>
            </div>
          </Row>
        </Modal>
      </div>
    );
  };

  // 图片预览
  const picPreview = (pic: string) => {
    return (
      <>
        <div
          onClick={() => {
            setImgModal(true);
            setPreviewImage(pic);
          }}
          style={{
            width: 160,
            height: 120,
            float: "left",
          }}
        >
          <AutoImage
            style={{ cursor: "pointer" }}
            width={1}
            height={1}
            src={pic}
          />
        </div>

        <Modal
          footer={false}
          visible={imgModal}
          onCancel={() => setImgModal(false)}
          width={400}
        >
          <img src={previewImage} alt="" style={{ width: "100%" }} />
        </Modal>
      </>
    );
  };

  // 认证中
  const certification = () => {
    return (
      <div className={styles["Commission-agent-certification"]}>
        <div
          style={{ color: primaryColor }}
          className={styles["Commission-agent-certification-title"]}
        >
          {(agent && agent.audit_status_text) || ""}
          <span style={{ float: "right" }}>
            <u
              style={{
                color: linkColor,
                cursor: "pointer",
                fontSize: "15px",
              }}
              onClick={() => replaceState.linkTo("/page/promotion/", "_blank")}
            >
              数莓派推广者(代理)规范条款{" "}
              <u
                style={{
                  color: linkColor,
                  cursor: "pointer",
                  fontSize: "15px",
                  marginLeft: 10,
                }}
                onClick={() =>
                  replaceState.linkTo("/page/freelancer/", "_blank")
                }
              >
                自由职业者服务协议
              </u>
            </u>
          </span>
        </div>
        <div className={styles["Commission-agent-certification-txt"]}>
          {agent &&
            agent.audit_status === AuditStatus.Auditing &&
            "正在认证中, 预计1-2个工作日内完成认证。"}
          {agent && agent.audit_status === AuditStatus.Reedit && agent.remark}
        </div>
        <div className={styles["Commission-agent-certification-txt"]}>
          {f({ id: "RecommendedCode" })}:
          {agent &&
          agent.referrer_agent_code &&
          agent.referrer_agent_code !== ""
            ? agent.referrer_agent_code
            : "暂无"}
        </div>
        {agent && agent.audit_status === AuditStatus.Reedit && (
          <Row>
            <Col span={24} style={{ textAlign: "right" }}>
              <AgentForm type={3} agent={agent} />
            </Col>
          </Row>
        )}

        <Divider />
        <div className={styles["Commission-agent-userinfo"]}>
          <div className={styles["Commission-agent-userinfo-title"]}>
            {f({ id: "PersonalInformation" })}
          </div>
          <Row gutter={10} className={styles["Commission-agent-userinfo-text"]}>
            <Col span={3} style={{ textAlign: "right" }}>
              {f({ id: "Name" })}:
            </Col>
            <Col span={20}>
              {agent && agent.is_designer === 1 && (
                <Icon
                  type="iconbadge"
                  style={{
                    fontSize: "1.3em",
                    color: primaryColor,
                    marginRight: 5,
                  }}
                ></Icon>
              )}
              {(agent && agent.agent_name) || "-"}
            </Col>
          </Row>
          <Row gutter={10} className={styles["Commission-agent-userinfo-text"]}>
            <Col span={3} style={{ textAlign: "right" }}>
              {f({ id: "ContactPhoneNumber" })}:
            </Col>
            <Col span={20}>{(agent && agent.telephone) || "-"}</Col>
          </Row>
          <Row gutter={10} className={styles["Commission-agent-userinfo-text"]}>
            <Col span={3} style={{ textAlign: "right" }}>
              {f({ id: "IdCardNumber" })}:
            </Col>
            <Col span={20}>{(agent && agent.identity_card_number) || "-"}</Col>
          </Row>
          {/* <Row gutter={10} className={styles["Commission-agent-userinfo-text"]}>
            <Col span={3} style={{ textAlign: "right" }}>
              {f({ id: "IdCardPhoto" })}:
            </Col>
            <Col span={20}>
              <div className={styles["Commission-agent-userinfo-img"]}>
                {agent &&
                  agent.identity_card_front &&
                  agent.identity_card_front !== "" &&
                  picPreview(agent.identity_card_front)}
                {agent &&
                  agent.identity_card_back &&
                  agent.identity_card_back !== "" &&
                  picPreview(agent.identity_card_back)}
                {agent &&
                  agent.identity_card_handheld &&
                  agent.identity_card_handheld !== "" &&
                  picPreview(agent.identity_card_handheld)}
              </div>
            </Col>
          </Row> */}

          {agent && agent.designer_case && agent.designer_case !== "" && (
            <Row
              gutter={10}
              className={styles["Commission-agent-userinfo-text"]}
              style={{ margin: "10px 0px" }}
            >
              <Col span={3} style={{ textAlign: "right" }}>
                设计案例:
              </Col>
              <Col span={20}>
                <div className={styles["Commission-agent-userinfo-img"]}>
                  {agent && agent.designer_case.indexOf("pdf") === -1 ? (
                    picPreview(agent.designer_case)
                  ) : (
                    <span
                      style={{ color: linkColor, cursor: "pointer" }}
                      onClick={() => {
                        window.open(agent.designer_case);
                      }}
                    >
                      点击下载
                    </span>
                  )}
                </div>
              </Col>
            </Row>
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (amounts && amounts.myCommission) {
      setHeaderData(amounts.myCommission.splice(1));
    }
  }, [amounts]);

  // header
  const header = () => {
    return (
      <Row gutter={10} style={{ margin: 0 }}>
        <Col span={8} style={{ paddingLeft: 0 }}>
          <div className={styles["Commission-content-header-box"]}>
            <h2 style={{ position: "relative" }}>
              {f({ id: "MyCommission" })}
              <span
                style={{
                  fontSize: "0.6em",
                  position: "absolute",
                  right: 0,
                  top: "7px",
                }}
              >
                {f({ id: "ReferenceCode" })}：{agent && agent.agent_code}
              </span>
            </h2>
            {amounts &&
              amounts.myOrder.map((item: any) => {
                return (
                  <p>
                    {item.title}：
                    <span style={{ color: primaryColor }}>
                      {item.amount_text}
                    </span>{" "}
                  </p>
                );
              })}
          </div>
        </Col>
        <Col span={8}>
          <div className={styles["Commission-content-header-box"]}>
            <h2>
              {amounts && (
                <>
                  {amounts.myCommission[0].title}
                  <span style={{ fontSize: "0.8em" }}>
                    ：{amounts.myCommission[0].amount_text}
                  </span>
                </>
              )}
            </h2>
            {headerData &&
              headerData.map((item: any) => {
                return (
                  <p>
                    {item.title}：
                    <span style={{ color: primaryColor }}>
                      {item.amount_text}
                    </span>{" "}
                  </p>
                );
              })}
            <span
              style={{
                background: primaryColor,
                cursor: "pointer",
                padding: 5,
                borderRadius: 5,
                color: "white",
                position: "absolute",
                top: 20,
                right: 5,
              }}
              onClick={() => replaceState.linkTo("/account/wallet")}
            >
              立即提现
            </span>
          </div>
        </Col>
        <Col span={8} style={{ paddingRight: 0 }}>
          <div className={styles["Commission-content-header-box"]}>
            <h2>{f({ id: "agentBrand" })}</h2>
            <div className={styles["Commission-content-header-box-agent"]}>
              {agentShop?.map((val: any) => {
                return (
                  <span
                    style={{ color: linkColor }}
                    className={styles["Commission-content-header-box-span"]}
                  >
                    {val.shop_name}
                  </span>
                );
              })}
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  // search
  const search = () => {
    return (
      <div className={styles["Commission-content-list-search"]}>
        <Row
          className={styles["Commission-content-list-search-content"]}
          gutter={10}
        >
          <Col span={8}>
            {f({ id: "OrderStatus" })}
            <Select
              placeholder={f({
                id: "PleaseSelectOptions",
              })}
              style={{
                width: "calc(100% - 66px)",
                display: "inline-block",
                marginLeft: 10,
              }}
              allowClear
              value={commissionStatus}
              onChange={val => setStatus(val)}
            >
              <Option value={"-1"}>{f({ id: "AllOrders" })}</Option>
              <Option value={"1"}>{f({ id: "IsAboutToArrive" })}</Option>
              <Option value={"99"}>{f({ id: "HaveToAccount" })}</Option>
              <Option value={"0"}>{f({ id: "HasTheFailure" })}</Option>
            </Select>
          </Col>
          <Col span={16}>
            <span style={{ marginLeft: 25 }}>{f({ id: "Date" })}</span>
            <DatePicker
              style={{
                width: "43%",
                marginLeft: 10,
              }}
              placeholder={f({ id: "PleaseEnterStartTime" })}
              onChange={value => {
                handleStartTimeSearch(value);
              }}
              value={startTime !== "" ? dayjs(startTime, "YYYY-MM-DD") : null}
            />
            <DatePicker
              style={{
                width: "43%",
                marginLeft: 10,
              }}
              placeholder={f({ id: "PleaseEnterStopTime" })}
              onChange={value => {
                handleStopTimeSearch(value);
              }}
              value={stopTime !== "" ? dayjs(stopTime, "YYYY-MM-DD") : null}
            />
          </Col>
        </Row>
        <Row
          className={styles["Commission-content-list-search-content"]}
          gutter={10}
        >
          <Col span={8}>
            {selectedType === 1 && (
              <>
                {f({ id: "AgentType" })}
                <Select
                  placeholder={f({
                    id: "PleaseSelectOptions",
                  })}
                  style={{
                    width: "calc(100% - 66px)",
                    display: "inline-block",
                    marginLeft: 10,
                  }}
                  allowClear
                  value={commissionType}
                  onChange={val => setCommissionType(val)}
                >
                  <Option value={"0"}>{f({ id: "All" })}</Option>
                  <Option value={"1"}>{f({ id: "BussinessAgent" })}</Option>
                  <Option value={"2"}>{f({ id: "BrandAgent" })}</Option>
                </Select>
              </>
            )}
            {selectedType === 2 && (
              <>
                {f({ id: "AgentBrand" })}
                <Select
                  placeholder={f({
                    id: "PleaseSelectOptions",
                  })}
                  style={{
                    width: "calc(100% - 66px)",
                    display: "inline-block",
                    marginLeft: 10,
                  }}
                  allowClear
                  value={shopId}
                  onChange={val => setShopId(val)}
                >
                  {agentShop?.map((value: any) => {
                    return (
                      <Option value={value.shop_id}>{value.shop_name}</Option>
                    );
                  })}
                </Select>
              </>
            )}
          </Col>
          <Col span={16}>
            {f({ id: "TelephonePrivate" })}
            <Input
              style={{
                width: "calc(100% - 157px)",
                margin: "0 10px",
              }}
              allowClear
              value={searchPhone}
              placeholder={f({ id: "PleaseEnterTheLastFourDigits" })}
              onChange={e => setSearchPhone(e.target.value)}
            ></Input>
            <PrimaryButton
              onClick={() => handleSearch()}
              bgColor={primaryColor}
            >
              搜索
            </PrimaryButton>
          </Col>
        </Row>
      </div>
    );
  };
  //筛选列表
  const handleSearch = () => {
    const data = {
      start_time: startTime,
      end_time: stopTime,
      commission_status: commissionStatus,
      commission_type: commissionType,
      telephone: searchPhone,
      page: 1,
      shop_id: shopId,
    };
    dispatch.CommissionPage.getCommossionListAsync(data);
    dispatch.CommissionPage.getInComeAsync(data);
  };
  const handleStartTimeSearch = (value: any) => {
    // 这里按时间，状态搜索
    if (value) {
      setStartTime(value.format("YYYY-MM-DD"));
    } else {
      setStartTime("");
    }
  };

  const handleStopTimeSearch = (value: any) => {
    // 这里按时间，状态搜索
    if (value) {
      setStopTime(value.format("YYYY-MM-DD"));
    } else {
      setStopTime("");
    }
  };

  const isHaveTelephone = user && !!user.phonenumber;
  // header数据
  const classificationData = [
    {
      icon: "icondingdan1",
      text: f({ id: "Order" }),
      type: 1,
      num: statistics ? statistics.orderTotal : 0,
    },
    {
      icon: "icondianpu-xian",
      text: f({ id: "Brand" }),
      type: 2,
      num: statistics ? statistics.shopTotal : 0,
    },
    {
      icon: "iconren",
      text: f({ id: "Invitation" }),
      type: 3,
      num: statistics ? statistics.inviteTotal : 0,
    },
  ];

  const handleGetOtherList = (page: number) => {
    setCurrentPageNumber(page);

    const data = {
      start_time: startTime,
      end_time: stopTime,
      commission_status: commissionStatus,
      commission_type: commissionType,
      telephone: searchPhone,
      page: page,
      shop_id: shopId,
    };
    dispatch.CommissionPage.getCommossionListAsync(data);
    dispatch.CommissionPage.getInComeAsync(data);
    window.scroll(0, 0);
  };

  const handleGetOtherInvitionList = (page: number) => {
    setCurrentPageNumber(page);

    dispatch.CommissionPage.getInvitationsAsync({ page: page });
    window.scroll(0, 0);
  };

  const pageItemRender: (
    page: number,
    type: "page" | "prev" | "next" | "jump-prev" | "jump-next",
    originalElement: React.ReactElement<HTMLElement>,
  ) => React.ReactNode = (page, type, originalElement) => {
    switch (type) {
      case "prev":
      case "next":
        return originalElement;
      case "jump-prev":
      case "jump-next":
        return <span>···</span>;

      default:
        return page === currentPageNumber ? (
          <a style={{ backgroundColor: primaryColor, color: "#fff" }}>{page}</a>
        ) : (
          <a style={{ color: textColor }}>{page}</a>
        );
    }
  };

  const pagination = {
    current: currentPageNumber,
    total: total,
    pageSize: 10,
    showSizeChanger: false,
    onChange: handleGetOtherList,
  };

  if (host_id && isPrivate) {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "1.2em",
        }}
      >
        如需查看我的带单具体信息，请前往平台带单中心并离开此页面查看。
        <Link
          onClick={() => {
            window.open("/account/commission");
          }}
        >
          立即前往&gt;{" "}
        </Link>
      </div>
    );
  }
  return (
    <div className={styles["Commission"]}>
      {page === 1 && (
        <>
          {user?.is_complete === BooleanLike.False && (
            <div>
              {user.agent_status === AgentStatus.NotApplied && (
                <div className={styles["Commission-agent-no"]}>
                  <div className={styles["Commission-agent-no-icon"]}>
                    {" "}
                    <Icon type="iconkaihuweichenggong"></Icon>
                  </div>

                  <div className={styles["Commission-agent-no-text"]}>
                    {f({ id: "YouAreNotAnAgentNowPleaseBecomeOneSoon" })}
                  </div>

                  {!verifyPay ? (
                    <PrimaryButton
                      icon={<Icon type="iconhuangguan222"></Icon>}
                      onClick={() =>
                        replaceState.linkTo("/account/verify/agent")
                      }
                      bgColor={primaryColor}
                    >
                      {f({ id: "AsAgent" })}
                    </PrimaryButton>
                  ) : (
                    <AgentForm
                      type={1}
                      isHaveTelephone={isHaveTelephone}
                      verifyPay={true}
                    />
                  )}
                </div>
              )}
              {user.agent_status === AgentStatus.Authenticating &&
                certification()}
            </div>
          )}
          {user?.is_complete === BooleanLike.True && (
            <div>{rootAgentPage()}</div>
          )}
        </>
      )}
      {page === 2 && (
        <div className={styles["Commission-content"]}>
          {header()}
          <div className={styles["Commission-content-list"]}>
            <Row className={styles["Commission-content-list-classification"]}>
              {classificationData.map((item: any, index: number) => {
                return (
                  <Col
                    span={24 / classificationData.length}
                    key={index}
                    style={{ textAlign: "center" }}
                  >
                    <div
                      className={
                        styles["Commission-content-list-classification-div"]
                      }
                      onClick={() => {
                        if (selectedType === item.type) return;
                        setNavIndex(index);
                        setSelectedType(item.type);
                        handleChangePage(item.type);
                      }}
                      style={{
                        borderBottom:
                          navIndex === index ? "2px solid " + primaryColor : "",
                      }}
                    >
                      <h3
                        style={{
                          color: navIndex === index ? primaryColor : "",
                        }}
                      >
                        <Icon type={item.icon}></Icon>
                        <span> {item.text}</span>
                        <span style={{ marginLeft: 10, fontSize: "0.8em" }}>
                          {item.num}
                        </span>
                      </h3>
                    </div>
                  </Col>
                );
              })}
            </Row>
            {selectedType < 3 && (
              <>
                {search()}
                <div className={styles["Commission-content-list-num"]}>
                  <div
                    className={styles["Commission-content-list-num-box"]}
                    style={{ border: "2px solid #BDBCBC", borderRadius: "5px" }}
                  >
                    订单数{"  "}
                    <span style={{ color: primaryColor }}>{total || 0}</span>
                  </div>

                  <div
                    className={styles["Commission-content-list-num-box"]}
                    style={{ border: "2px solid #BDBCBC", borderRadius: "5px" }}
                  >
                    付款金额{"  "}
                    <span style={{ color: primaryColor }}>
                      {"¥" +
                        Number(inCome ? inCome.orderAmount : "0").toFixed(2) ||
                        "¥0.00"}
                    </span>
                  </div>

                  <div
                    className={styles["Commission-content-list-num-box"]}
                    style={{ border: "2px solid #BDBCBC", borderRadius: "5px" }}
                  >
                    预计收入{"  "}
                    <span style={{ color: primaryColor }}>
                      {"¥" +
                        Number(inCome ? inCome.commissionAmount : "0").toFixed(
                          2,
                        ) || "¥0.00"}
                    </span>
                  </div>
                </div>
                <CommissionList type={selectedType} pagination={pagination} />
              </>
            )}
            {selectedType === 3 && (
              <div className={styles["Commission-content-invitation"]}>
                <Row gutter={20} style={{ marginBottom: 20 }}>
                  {invitationStatistics?.slice(0, 2).map((item: any) => {
                    return (
                      <Col span={24 / (invitationStatistics.length + 1)}>
                        <div
                          style={{
                            border: "2px solid #bdbcbc",
                          }}
                          className={
                            styles["Commission-content-invitation-box"]
                          }
                        >
                          {" "}
                          <p>{item.title}</p>
                          <p style={{ color: primaryColor }}>{item.value}</p>
                        </div>
                      </Col>
                    );
                  })}
                  {invitationStatistics && (
                    <Col span={24 / invitationStatistics.length}>
                      <Invitation />
                    </Col>
                  )}
                </Row>
                <Row gutter={10}>
                  <List
                    dataSource={invitations}
                    split={false}
                    grid={{ gutter: 20, column: 2 }}
                    pagination={{
                      current: currentPageNumber,
                      total: invitationsTotal,
                      pageSize: 20,
                      showSizeChanger: false,
                      onChange: handleGetOtherInvitionList,
                    }}
                    renderItem={(item: any, index) => (
                      <List.Item key={index}>
                        <div
                          className={
                            styles["Commission-content-invitation-peocard"]
                          }
                        >
                          <div>
                            <p>{item.agent_name}</p>
                            <p>{item.agent_code}</p>
                          </div>
                          <div style={{ textAlign: "center" }}>
                            {item.quantity}
                            {index === 0 && (
                              <Popover content={"好友已结算订单数"}>
                                <span
                                  style={{
                                    cursor: "pointer",
                                    borderRadius: "50%",
                                    width: "15px",
                                    height: "15px",
                                    textAlign: "center",
                                    lineHeight: "14px",
                                    fontSize: "0.6em",
                                    display: "inline-block",
                                    marginLeft: 7,
                                    border: "1px solid #4b4b4b",
                                  }}
                                >
                                  ?
                                </span>
                              </Popover>
                            )}
                          </div>
                          <div
                            style={{
                              textAlign: "right",
                              color: primaryColor,
                            }}
                          >
                            <Price text={item.amount_text} />
                            {index === 0 && (
                              <Popover content={"好友已结算平台带单佣金"}>
                                <span
                                  style={{
                                    cursor: "pointer",
                                    borderRadius: "50%",
                                    width: "15px",
                                    height: "15px",
                                    textAlign: "center",
                                    lineHeight: "14px",
                                    fontSize: "0.6em",
                                    display: "inline-block",
                                    marginLeft: 7,
                                    border: "1px solid #4b4b4b",
                                    color: "#4b4b4b",
                                  }}
                                >
                                  ?
                                </span>
                              </Popover>
                            )}
                          </div>
                        </div>
                      </List.Item>
                    )}
                  ></List>
                </Row>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default Commission;
