import { GraphqlField, GraphqlRefField } from "../helpers/decorators";
import BaseModel from "./BaseModel";

export class ColorFilter {
  @GraphqlField("default", "design", "pl")
  public color_id: number;
  @GraphqlField("default", "design", "pl")
  public color_name: string;
  @GraphqlField()
  public color_value: string;
  @GraphqlField()
  public sort_index: number;
  @GraphqlField()
  public search_key: string;
}
export class MaterialFilter {
  @GraphqlField()
  public material_id: number;
  @GraphqlField()
  public material_name: string;

  @GraphqlField()
  public material_category_id: number;
  @GraphqlField()
  public search_key: string;
}
export class MaterialCategoryFilter {
  @GraphqlField()
  public material_category_id: number;
  @GraphqlField()
  public material_category_name: string;
  @GraphqlField()
  public search_key: string;
}
export class StyleFilter {
  @GraphqlField("default", "design")
  public style_id: number;
  @GraphqlField("default", "design")
  public style_name: string;
  @GraphqlField()
  public search_key: string;
}
export class RoomFilter {
  @GraphqlField("default", "design")
  public room_id: number;
  @GraphqlField("default", "design")
  public room_name: string;
  @GraphqlField()
  public search_key: string;
}
export class SizeFilter {
  @GraphqlField()
  public size_id: number;
  @GraphqlField()
  public size_name: string;
}

export class PriceFilter {
  @GraphqlField()
  public price_low: number;
  @GraphqlField()
  public price_high: number;

  @GraphqlField()
  public price_name: string;
}

export class CategoryFilter {
  @GraphqlField()
  public category_id: number;
  @GraphqlField()
  public category_name: string;
  @GraphqlField()
  public category_picture: string;

  @GraphqlField()
  public level: number;
}

export class ProductFilter {
  @GraphqlRefField(ColorFilter, "default", "pl")
  public Color: ColorFilter[] = [];

  @GraphqlRefField(MaterialFilter, "default", "pl")
  public Materials: MaterialFilter[] = [];

  @GraphqlRefField(MaterialCategoryFilter, "default", "pl")
  public MaterialsCategory: MaterialCategoryFilter[] = [];

  @GraphqlRefField(StyleFilter, "default", "pl")
  public Style: StyleFilter[] = [];

  @GraphqlRefField(RoomFilter, "default", "pl")
  public Room: RoomFilter[] = [];

  @GraphqlRefField(SizeFilter, "default", "pl")
  public Size: SizeFilter[] = [];

  @GraphqlRefField(PriceFilter, "default", "pl")
  public ProductsPrice: PriceFilter[] = [];

  @GraphqlRefField(CategoryFilter, "pl")
  public Categorys: CategoryFilter[] = [];
}
