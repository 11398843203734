import * as React from "react";
import { useSelector } from "react-redux";
import { ToBOrderHistoryType } from "../models";
import AutoImage from "../../../components/image";
import { RootState, Dispatch } from "../../../store/configureStore";
import { useIntl } from "react-intl";
import Icon from "../../../components/icon";
// import "../../../components/style/extend/index.scss";
import { Row, Col, Tag, Card, Space } from "antd";
import List from "../../../wrappers/list";
import { PrimaryButton } from "../../../wrappers/button";
import { Text } from "../../../wrappers/typography";
import replaceState from "../../../helpers/reolaceState";
import { useTheme } from "styled-components";
import { Order } from "../../../models/Order";
import { PaginationConfig } from "antd/lib/pagination";
import styles from "../index.scss";
interface OrderListProps {
  data: ToBOrderHistoryType["orderList"];

  pagination: PaginationConfig;
}

const OrderList: React.FC<OrderListProps> = props => {
  const { formatMessage: f } = useIntl();
  const { bussinessPrimaryColor } = useTheme();
  return (
    <List
      dataSource={props.data}
      locale={{
        emptyText: (
          <div className={styles["ToBOrderHistory-empty"]}>
            {f({ id: "ThereIsNoHistoricalOrder" })}
          </div>
        ),
      }}
      activeColor={bussinessPrimaryColor}
      split={false}
      grid={{ gutter: 15, column: 1 }}
      renderItem={(item: Order, index) => (
        <List.Item key={index}>
          <Card
            style={{ borderColor: "#bdbcbc" }}
            headStyle={{ borderBottomColor: "#bdbcbc" }}
            title={
              <Row align="middle" justify="space-between">
                <Space
                  size={5}
                  style={{
                    color: bussinessPrimaryColor,
                  }}
                >
                  <Icon type="icondianpu2"></Icon>
                  {item.shop_name}
                </Space>
                <Text weight={400}>
                  {` ${f({ id: "OrderNumber" })}:${item.order_number} `}
                </Text>
                <Text weight={400}>{item.created_at}</Text>
                <div>
                  <PrimaryButton
                    block
                    bgColor={bussinessPrimaryColor}
                    onClick={() =>
                      replaceState.linkTo(
                        "/account/toborders/" + item.order_number,
                      )
                    }
                  >
                    {f({
                      id: "ViewDetails",
                    })}
                  </PrimaryButton>
                </div>
              </Row>
            }
          >
            <Row gutter={10}>
              <Col span={24}>
                <List
                  dataSource={item.product}
                  grid={{ gutter: 15, column: 1 }}
                  split
                  renderItem={(m: OrderProduct, mIndex) => (
                    <List.Item
                      key={mIndex}
                      style={{
                        borderBottom:
                          mIndex < item.product.length - 1
                            ? "1px dashed #eaeaea"
                            : "",
                      }}
                    >
                      <Row align="middle" key={mIndex}>
                        <Col span={5}>
                          <AutoImage
                            width={58}
                            height={50}
                            src={m.product_picture}
                          />
                        </Col>
                        <Col
                          span={6}
                          style={{ height: "160px", padding: "0 10px" }}
                        >
                          <p>{m.product_name}</p>
                          <p>
                            {m.brand_name && m.brand_name !== "" && (
                              <Tag color={bussinessPrimaryColor}>
                                {m.brand_name}
                              </Tag>
                            )}
                            {m.series_name && m.series_name !== "" && (
                              <Tag color={bussinessPrimaryColor}>
                                {m.series_name}
                              </Tag>
                            )}
                          </p>
                        </Col>
                        <Col span={5}>
                          {m.attribute &&
                            m.attribute.split(",").map((item: string) => {
                              return (
                                <>
                                  <Tag
                                    color={bussinessPrimaryColor}
                                    style={{ marginBottom: "5px" }}
                                  >
                                    {item}
                                  </Tag>
                                  <br />
                                </>
                              );
                            })}
                        </Col>
                        <Col span={4}>
                          <p>X{m.quantity}</p>
                        </Col>
                        <Col span={4}>
                          {!Number(m.has_tag_price_range) ? (
                            <>
                              ¥
                              {(parseFloat(m.tag_price) * m.quantity).toFixed(
                                2,
                              )}
                            </>
                          ) : (
                            <>{`¥${(
                              m.quantity * parseFloat(m.tag_price_low)
                            ).toFixed(2)} - ¥${(
                              m.quantity * parseFloat(m.tag_price_high)
                            ).toFixed(2)}`}</>
                          )}
                        </Col>
                      </Row>
                    </List.Item>
                  )}
                ></List>
              </Col>
            </Row>
          </Card>
        </List.Item>
      )}
      pagination={props.pagination}
    ></List>
  );
};
export default React.memo(OrderList);
