import * as React from "react";
import Layout from "../Layout";
import ShopActivityContent from "./Content";
import { RootState } from "../../store/configureStore";
import { useSelector } from "react-redux";

const ShopActivity: React.FC = () => {
  const { breadcrumbs = [] } = useSelector(
    (state: RootState) => state.ShopActivityPage,
  );
  return (
    <Layout breadcrumbs={breadcrumbs}>
      <ShopActivityContent />
    </Layout>
  );
};

export default ShopActivity;
