import styled from "styled-components";
import { Checkbox } from "antd";

const CheckboxStyled = styled(Checkbox)<{
  color?: string;
  shape?: "circle" | "square";
  borderWidth?: string;
}>`
  .ant-checkbox {
    &-inner {
      border-color: ${props => props.color || props.theme.primaryColor};
      border-radius: ${props => (props.shape === "circle" ? "50%" : "0")};
      border-width: ${props => props.borderWidth};
    }

    &-checked {
      .ant-checkbox-inner {
        background-color: ${props => props.color || props.theme.primaryColor};
      }
    }
  }

  &.ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${props => props.color || props.theme.primaryColor};
  }
`;

const DesignCheckbox = styled(CheckboxStyled).attrs(props => ({
  color: props.theme.designPrimaryColor,
  shape: "circle",
}))``;

export { DesignCheckbox };

export default CheckboxStyled;
