import { createModel } from "@rematch/core";

interface CommonData {
  isPrivate: boolean;
  shopUrl: string;
}

export default createModel({
  state: {
    isPrivate: false,
    shopUrl: "",
    shopLogo: "",
    shopName: "",
  } as CommonData,
  reducers: {},
});
