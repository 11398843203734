import React, { useRef, useState, useEffect, useContext } from "react";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";
import { RootState, Dispatch } from "../../store/configureStore";
import {
  useSize,
  useMount,
  useDebounce,
  useUpdateEffect,
  usePrevious,
} from "ahooks";
import Renderer, {
  ItemType,
  RenderItem,
  KeyValuePairs,
} from "../../components/irender";
import * as styles from "./index.scss";
import Icon from "../../components/icon";
import { useIntl } from "react-intl";
import { Row, Col, Avatar, Comment, Input, Space, message } from "antd";
import { BusinessContext } from "../BusinessContext";
import replaceState from "../../helpers/reolaceState";
import Carousel from "../../wrappers/carousel";
import List from "../../wrappers/list";
import Rate from "../../wrappers/rate";
import Button, { PrimaryButton } from "../../wrappers/button";
import ProductCard from "./components/productCard";
import horizontalRotateImg from "../../static/img/horizontalRotate.svg";
import verticalRotateImg from "../../static/img/verticalRotate.svg";
import rotateSvg from "../../static/img/rotate.svg";
import throttle from "lodash/throttle";
import IdeaQrcode from "./components/IdeaQrcode";
import { getFirstItem } from "../../helpers/modelHelpers";
import dilogoImg from "../../static/img/dilogo.png";
import { DesignIdea } from "../../models/DesignIdea";
import { useTheme } from "styled-components";

const { TextArea } = Input;

const DesignIdeaDetailContent = () => {
  const dispatch = useDispatch<Dispatch>();
  const { formatMessage: f } = useIntl();
  const { isToBusiness } = useContext(BusinessContext);
  const { primaryColor, bussinessPrimaryColor } = useTheme();

  const {
    User: { isLogin },
    IdeaDetalis: { designIdeaDetails, ideaReviewsData, ideaLikeList },
  } = useSelector((state: RootState) => state);

  const [currResolution, setCurrResolution] = useState({
    device: "响应设备",
    ratio: "DESKTOP",
    name: "Desktop HD",
    width: 1440,
    height: 1024,
  });
  const [render, setRender] = useState<Renderer>();

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const designerContainerRef = useRef<HTMLDivElement>();
  const size = useSize(designerContainerRef);
  const designerSize = useDebounce(size, { wait: 1000 });

  const canvasWidth = Number(designerSize.width ?? 0);

  const canvasHeight =
    (canvasWidth * currResolution.height) / currResolution.width;

  const [productGroup, setProductGroup] = useState<any[]>([]);
  const [hashCoordinates, setHashCoordinates] = useState([]);
  const [tagsList, setTagsList] = useState<any[]>([]);
  const [canvaseObject, setCanvaseObject] = useState<any>([]);
  const [canvaseObjectGroup, setCanvaseObjectGroup] = useState([]);
  const [typeArray, setTypeArray] = useState<any[]>([]);
  const [rating, setRating] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [reviewComment, setReviewComment] = useState("");
  const previousWidth = usePrevious(canvasWidth);

  const compare = (property: any) => {
    return function (a: any, b: any) {
      const value1 = a[property];
      const value2 = b[property];
      return value1 - value2;
    };
  };

  /**
   * 组合coordinates和products数据，按coordinate_index分组
   * @param data 设计详情数据
   */
  const handleProductGroup = (data: any) => {
    if (!data) return [];
    const tmp: any[] = [];
    const canvasObject = JSON.parse(data.canvas_object).objects;
    for (let index = 0; index < data.coordinates.length; index++) {
      let item = data.products.find(
        (m: any) =>
          m.shop_product_id === data.coordinates[index].shop_product_id,
      );
      if (!item) {
        item = canvasObject.find(
          (m: any) => m.id === data.coordinates[index].coordinate_key,
        );
      }
      const {
        coordinate_index,
        coordinate_key,
        coordinate_sort,
        shop_product_id,
      } = data.coordinates[index];

      if (item) {
        if (!Array.isArray(tmp[Number(coordinate_index)])) {
          tmp[Number(coordinate_index)] = [
            {
              ...item,
              coordinate_index,
              coordinate_key,
              coordinate_sort,
              shop_product_id,
            },
          ];
        } else {
          tmp[Number(coordinate_index)].push({
            ...item,
            coordinate_index,
            coordinate_key,
            coordinate_sort,
            shop_product_id,
          });
        }
      }
    }
    tmp.forEach(item => {
      item.sort(compare("coordinate_sort"));
    });

    return tmp;
  };
  /**
   * 处理 tags
   */

  const handleTagsList = (group: any, hashTable: any) => {
    return group.map((item: any) => {
      return {
        ...hashTable[`${item[0].coordinate_index}-${item[0].shop_product_id}`],
        url: item[0].url,
      };
    });
  };

  /**
   * 把定位坐标coordinates按照coordinate_index和shop_product_id分组
   */
  const getHashList = (data: any) => {
    const reust = {};
    if (!data) {
      return reust;
    } else {
      return data.coordinates.reduce((acc: any, curr: any) => {
        acc[`${curr.coordinate_index}-${curr.shop_product_id}`] = curr;
        return acc;
      }, reust);
    }
  };

  useMount(() => {
    const defaultSettings = {
      "rotate-operate-img": rotateSvg,
      "vertical-operate-img": verticalRotateImg,
      "horizontal-operate-img": horizontalRotateImg,
      drawWatermark: false,
      useSet: true,
    };

    const r = new Renderer(
      canvasRef.current as HTMLCanvasElement,
      (defaultSettings as unknown) as KeyValuePairs,
    );

    r.clear();
    r.restore();
    setRender(r);

    dispatch.IdeaDetalis.setIdeaLike();
  });
  useEffect(() => {
    const searchString = queryString.parse(location.search);
    if (!designIdeaDetails) {
      const data = {
        ideaId: searchString.id,
        callback: (res: any) => {
          setProductGroup(handleProductGroup(res));
          setHashCoordinates(getHashList(res));
          setTagsList(
            handleTagsList(handleProductGroup(res), getHashList(res)),
          );
        },
      };
      dispatch.IdeaDetalis.setData(data);
      dispatch.IdeaDetalis.getReviewsData({
        ideaId: searchString.id,
      });
    } else {
      setProductGroup(handleProductGroup(designIdeaDetails));
      setHashCoordinates(getHashList(designIdeaDetails));
      setTagsList(
        handleTagsList(
          handleProductGroup(designIdeaDetails),
          getHashList(designIdeaDetails),
        ),
      );
      dispatch.IdeaDetalis.getReviewsData({
        ideaId: designIdeaDetails.design_idea_id,
      });
    }
  }, []);
  // const horizontalImg = new Image(),
  //   verticalImg = new Image(),
  //   rotateImg = new Image(),

  useUpdateEffect(() => {
    if (canvasRef.current) {
      if (canvasWidth === previousWidth) return;

      render?.setResponsive(
        previousWidth === 0 ? currResolution.width : previousWidth,
        canvasWidth,
      );

      render?.restore();
    }
  }, [canvasWidth, previousWidth]);

  let backgroundImg;

  useEffect(() => {
    if (!designIdeaDetails) return;

    if (canvasRef.current) {
      const settings = JSON.parse(designIdeaDetails.canvas_data),
        canvas = JSON.parse(designIdeaDetails.canvas_object);
      // settings.useSet = false;

      const { canvasScale = 80 } = settings;

      setCurrResolution(r => ({
        ...r,
        width: (designIdeaDetails.width * canvasScale) / 100,
        height: (designIdeaDetails.height * canvasScale) / 100,
      }));

      if (designIdeaDetails.background_picture) {
        backgroundImg = new Image();
        backgroundImg.src =
          designIdeaDetails.background_picture + "?" + new Date().getTime();
        backgroundImg.crossOrigin = "";
        backgroundImg.crossOrigin = "anonymous";
        backgroundImg.onload = () => {
          render?.updateDataSet({ settings, "background-img": backgroundImg });
          render?.restore();
        };
      }

      canvas.objects.forEach((item: any, index: number) => {
        if (
          item.type === ItemType.Image ||
          (item.type === ItemType.EmptyValue && item.value)
        ) {
          const objImage = new Image();
          objImage.src = item.value + "?" + Math.random();
          objImage.crossOrigin = "";
          item.value = objImage;
          objImage.onload = () => {
            render?.restore();
          };
        }

        if (item.type === ItemType.EmptyValue) {
          item.show = false;
        }
      });

      render?.updateDataSet({ settings, "background-img": null });
      render?.updateObjects(canvas?.objects, canvas?.sets);

      setCanvaseObject(canvas.objects);
      setCanvaseObjectGroup(canvas.sets);
    }

    // tslint:disable-next-line:no-shadowed-variable
    const arrayData = [];
    if (designIdeaDetails.house_type && designIdeaDetails.house_type !== "") {
      arrayData.push({
        title: f({ id: "DoorModel" }),
        value: designIdeaDetails.house_type,
      });
    }
    if (designIdeaDetails.room_text && designIdeaDetails.room_text !== "") {
      arrayData.push({
        title: f({ id: "Room" }),
        value: designIdeaDetails.room_text,
      });
    }
    if (designIdeaDetails.style_text && designIdeaDetails.style_text !== "") {
      arrayData.push({
        title: f({ id: "Style" }),
        value: designIdeaDetails.style_text,
      });
    }
    if (designIdeaDetails.color_text && designIdeaDetails.color_text !== "") {
      arrayData.push({
        title: f({ id: "Color" }),
        value: designIdeaDetails.color_text,
      });
    }

    setTypeArray(arrayData);

    setProductGroup(handleProductGroup(designIdeaDetails));
    setHashCoordinates(getHashList(designIdeaDetails));
    setTagsList(
      handleTagsList(
        handleProductGroup(designIdeaDetails),
        getHashList(designIdeaDetails),
      ),
    );
  }, [designIdeaDetails, render]);

  const transitionNumber = (n: string) => Number(n);

  const handleChangeProduct = (
    firIndex: number,
    secIndex: number,
    item: any,
  ) => {
    const ids: any = canvaseObjectGroup[firIndex];
    const spId = productGroup[firIndex][secIndex].shop_product_id,
      newProduct = getFirstItem(
        designIdeaDetails.products.filter(
          m => m.shop_product_id === Number(spId),
        ),
      );
    if (newProduct && JSON.stringify(newProduct) !== "{}") {
      const newTagsList = tagsList.map((item: any, index: number) => {
        if (index === firIndex) {
          item.url = newProduct.url;
        }
        return item;
      });
      setTagsList(newTagsList);
    }

    render?.moveSetTargetId(
      productGroup[firIndex][secIndex].coordinate_key,
      firIndex,
      firIndex,
      0,
    );

    // canvaseObject.forEach((item: any) => {
    //   if (
    //     item.id !== canvaseObjectGroup[firIndex][secIndex] &&
    //     ids.includes(item.id)
    //   ) {
    //     item.show = false;
    //   } else if (
    //     item.id === canvaseObjectGroup[firIndex][secIndex] &&
    //     ids.includes(item.id)
    //   ) {
    //     item.show = item.type !== ItemType.EmptyValue;
    //   }
    // });

    // render?.restore(canvaseObject);
  };

  const hanldeProducrUserLike = (
    bl: boolean,
    parentIndex: number,
    childIndex: number,
  ) => {
    const newGroup = productGroup.map((item: any, index: number) => {
      if (index === parentIndex) {
        item.map((m: any, mIndex: number) => {
          if (mIndex === childIndex) {
            m.is_collect = bl;
          }
        });
      }
      return item;
    });
    setProductGroup(newGroup);

    const { shop_product_id } = productGroup[parentIndex][childIndex];
    dispatch.Collections.submitCollectAsync({
      action: !bl ? "cancel" : "like",
      object: "product",
      data_id: shop_product_id,
    });
  };
  const handleChangeIdeaIsLike = throttle((ideaId: number, isLike: boolean) => {
    if (isLogin) {
      dispatch.IdeaDetalis.changeUserLike({ ideaId, isLike });
    } else {
      dispatch.User.changeUserState({
        modalAction: "Login",
        isOpen: false,
      });
    }
  }, 1000);

  const handleGetOtherList = (page: number) => {
    setCurrentPageNumber(page);
    // const searchString = queryString.parse(location.search);
    dispatch.IdeaDetalis.getReviewsData({
      page: page,
      ideaId: designIdeaDetails.design_idea_id,
    });
    const height = document.documentElement.scrollHeight - 4680;
    window.scroll(0, height);
  };

  const handleSetComment = () => {
    if (isLogin) {
      const searchString = queryString.parse(location.search);
      const data = {
        design_idea_id: designIdeaDetails.design_idea_id,
        review_rating: rating,
        review_comment: reviewComment,
        callback: (res: string) => {
          message.success(res);
          dispatch.IdeaDetalis.getReviewsData({
            page: 1,
            ideaId: designIdeaDetails.design_idea_id,
          });
          const height = document.documentElement.scrollHeight - 4680;
          window.scroll(0, height);
          setReviewComment("");
          setRating(0);
        },
      };

      dispatch.IdeaDetalis.setReviewCommentAsync(data);
    } else {
      dispatch.User.changeUserState({
        modalAction: "Login",
        isOpen: false,
      });
    }
  };

  const handleChangeIdeaLike = (id: number, type: boolean) => {
    if (isLogin) {
      dispatch.IdeaDetalis.changeLike({
        ideaId: id,
        isLike: type,
      });
    } else {
      dispatch.User.changeUserState({
        modalAction: "Login",
        isOpen: false,
      });
    }
  };

  return (
    <div className={styles["ideaDetalis-box"]}>
      <Row gutter={10}>
        <Col span={16}>
          <div
            ref={designerContainerRef}
            style={{ position: "relative" }}
            className={cx(styles["ideaDetalis-tagbox"])}
          >
            {tagsList &&
              tagsList.map((item: any, index: any) => (
                <a
                  onClick={() =>
                    replaceState.linkTo(item.url, "_blank", {
                      agent_code: designIdeaDetails?.agent_code,
                    })
                  }
                  className={cx(
                    styles["ideaDetalis-ideacard-tags-box"],
                    "print-tag-box",
                  )}
                  key={index}
                  style={{
                    position: "absolute",
                    top: `calc(${
                      transitionNumber(item?.coordinate_y ?? "0") * 100
                    }% - 15px)`,
                    left: `calc(${
                      transitionNumber(item?.coordinate_x ?? "0") * 100
                    }% - 15px)`,
                    width: `30px`,
                    height: `30px`,
                  }}
                >
                  <div
                    className={cx(
                      styles["ideaDetalis-ideacard-tags"],
                      "print-tag",
                    )}
                  >
                    <Icon type="icont2_biaoqian" />
                  </div>
                </a>
              ))}

            <canvas
              ref={canvasRef}
              width={canvasWidth}
              height={canvasHeight}
              // onClick={handleCanvasClick}
            ></canvas>
          </div>
          {designIdeaDetails && !designIdeaDetails.isPreview && (
            <Space className={styles["ideaDetalis-actions"]}>
              <IdeaQrcode
                option={"{}"}
                logo={designIdeaDetails && designIdeaDetails.shop_logo}
                src={designIdeaDetails && designIdeaDetails.design_idea_picture}
                tagsList={tagsList}
                ideaName={
                  designIdeaDetails && designIdeaDetails.design_idea_name
                }
                shopName={designIdeaDetails && designIdeaDetails.shop_name}
                isMobile={designIdeaDetails && false}
                attributeData={typeArray}
              />
              <Button
                shape="circle"
                icon={
                  <Icon
                    type={
                      ideaLikeList?.some(
                        m => m === designIdeaDetails.design_idea_id,
                      )
                        ? "icondianzan"
                        : "icondianzan_xian"
                    }
                  />
                }
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  handleChangeIdeaLike(
                    designIdeaDetails.design_idea_id,
                    ideaLikeList?.some(
                      m => m === designIdeaDetails.design_idea_id,
                    ),
                  );
                }}
                color={isToBusiness ? bussinessPrimaryColor : primaryColor}
              />
              <Button
                shape="circle"
                icon={
                  <Icon
                    type={
                      designIdeaDetails?.is_collect
                        ? "icont2_shoucang1"
                        : "iconshoucang1"
                    }
                  />
                }
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  handleChangeIdeaIsLike(
                    designIdeaDetails.design_idea_id,
                    designIdeaDetails.is_collect,
                  );
                }}
                color={isToBusiness ? bussinessPrimaryColor : primaryColor}
              />
            </Space>
          )}
        </Col>
        <Col span={8} style={{ maxHeight: canvasHeight, overflow: "auto" }}>
          {productGroup.map((item: any, index: number) => (
            <div
              key={index}
              className={styles["ideaDetalis-Carousel-mask-box"]}
              style={{
                display:
                  item.length === 1 && item.some(a => !a.shop_product_id)
                    ? "none"
                    : "",
              }}
            >
              <Carousel
                arrows
                dots={false}
                slidesToScroll={1}
                slidesToShow={1}
                infinite={false}
                hoverable
                afterChange={i => handleChangeProduct(index, i, item)}
              >
                {item.map((v: any, i: number) => {
                  return (
                    <div key={v.shop_product_id}>
                      <div className={styles["ideaDetalis-Carousel-item"]}>
                        <ProductCard
                          {...v}
                          img={
                            // hashCoordinates[
                            //   `${v.coordinate_index}-${v.shop_product_id}`
                            // ]?.product_picture ||
                            v.product_picture || v.value
                          }
                          isPreview={
                            designIdeaDetails && designIdeaDetails.isPreview
                          }
                          onChangeProductIsLike={() =>
                            hanldeProducrUserLike(!v.is_collect, index, i)
                          }
                          isMobile={false}
                          shopUrl={v.shop_url}
                          agentCode={designIdeaDetails?.agent_code || null}
                          isMaterial={
                            hashCoordinates[
                              `${v.coordinate_index}-${v.shop_product_id}`
                            ]?.product_picture || v.product_picture
                          }
                        />
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          ))}
        </Col>
      </Row>
      <Row>
        <Col span={16} className={styles["ideaDetalis-info"]}>
          <div className={styles["ideaDetalis-info-name"]}>
            {designIdeaDetails && designIdeaDetails.design_idea_name}
          </div>

          <div
            style={{ display: "flex", alignItems: "center", marginBottom: 16 }}
          >
            <Avatar
              src={designIdeaDetails?.designer_avatar ?? dilogoImg}
              size={26}
            />

            <span style={{ marginLeft: 5, fontSize: "1.1em" }}>
              {designIdeaDetails?.designer_name ?? "未命名设计师"}
            </span>
          </div>
          {designIdeaDetails &&
            designIdeaDetails.design_idea_slogan &&
            designIdeaDetails.design_idea_slogan !== "" && (
              <div className={styles["ideaDetalis-info-slogan"]}>
                {designIdeaDetails.design_idea_slogan}
              </div>
            )}
          <div style={{ display: "flex", alignItems: "center" }}>
            {designIdeaDetails &&
              designIdeaDetails.house_area &&
              designIdeaDetails.house_area !== "" &&
              designIdeaDetails.house_area !== "0" && (
                <span style={{ fontSize: "1.2em", marginRight: 30 }}>
                  {" "}
                  <Icon
                    type="iconmianji"
                    className={styles["ideaDetalis-info-icon"]}
                  ></Icon>
                  {designIdeaDetails && designIdeaDetails.house_area}
                  平方米
                </span>
              )}

            {designIdeaDetails &&
              designIdeaDetails.city &&
              designIdeaDetails.city !== "" && (
                <span style={{ fontSize: "1.2em", marginRight: 30 }}>
                  {" "}
                  <Icon
                    type="icondizhi1"
                    className={styles["ideaDetalis-info-icon"]}
                  ></Icon>
                  {`${designIdeaDetails.province}${designIdeaDetails.city}`}
                </span>
              )}

            {designIdeaDetails &&
              designIdeaDetails.house_cost &&
              designIdeaDetails.house_cost !== "" &&
              designIdeaDetails.house_cost !== "0" && (
                <span style={{ fontSize: "1.2em", marginRight: 30 }}>
                  {" "}
                  <Icon
                    type="iconhuafei"
                    className={styles["ideaDetalis-info-icon"]}
                  ></Icon>
                  {designIdeaDetails && designIdeaDetails.house_cost}
                </span>
              )}
          </div>

          <Row className={styles["ideaDetalis-info-box"]}>
            {typeArray?.map((m: any, index: number) => {
              return (
                <Col span={24 / typeArray.length}>
                  <div
                    style={{
                      borderRight:
                        index !== typeArray.length - 1
                          ? "1px solid #DBDBDB"
                          : "",
                    }}
                  >
                    <div className={styles["ideaDetalis-info-box-title"]}>
                      {m.title}
                    </div>
                    <div className={styles["ideaDetalis-info-box-value"]}>
                      {m.value}
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
          {designIdeaDetails && (
            <div className={styles["ideaDetalis-info-dec"]}>
              <div
                dangerouslySetInnerHTML={{
                  __html: designIdeaDetails.design_idea_description,
                }}
              ></div>
            </div>
          )}
        </Col>
      </Row>
      {designIdeaDetails && !designIdeaDetails.isPreview && (
        <>
          <Row>
            <Col span={16} className={styles["ideaDetalis-toreview"]}>
              <div className={styles["ideaDetalis-toreview-title"]}>
                说点什么
              </div>
              <div className={styles["ideaDetalis-toreview-rate"]}>
                <span style={{ marginRight: 5 }}>评价星级 </span>
                <Rate
                  value={rating}
                  color={primaryColor}
                  onChange={setRating}
                />
              </div>
              <TextArea
                value={reviewComment}
                rows={4}
                placeholder="有什么想法快和大家分享吧！"
                onChange={e => setReviewComment(e.target.value)}
              />
              <div className={styles["ideaDetalis-toreview-footer"]}>
                <PrimaryButton
                  onClick={() => {
                    if (reviewComment === "") {
                      return message.error("请输入评价内容！");
                    }
                    handleSetComment();
                  }}
                  bgColor={primaryColor}
                >
                  发送
                </PrimaryButton>
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              span={16}
              style={{
                fontSize: "0.8em",
                color: "#bdbcbc",
                marginTop: 20,
              }}
            >
              *以下评论内容由数莓派用户自主生成上传，仅代表用户观点和主张，与数莓派立场无关。若您认为内容中存在不当言论或侵权行为等，请联系support@imegahome.com处理。
            </Col>
          </Row>

          <Row>
            <Col span={16} className={styles["ideaDetalis-review"]}>
              {ideaReviewsData?.length > 0 && (
                <List
                  style={{ marginBottom: "24px" }}
                  itemLayout="horizontal"
                  dataSource={ideaReviewsData}
                  renderItem={(item: DesignIdea) => (
                    <li className={styles["ideaDetalis-review-box"]}>
                      <Comment
                        author={
                          <>
                            <span style={{ marginRight: 10 }}>
                              {item.customer_username}
                            </span>

                            <Rate
                              value={item.review_rating}
                              color={primaryColor}
                              disabled
                            />
                          </>
                        }
                        avatar={item.customer_avatar}
                        content={item.review_comment}
                        datetime={item.review_time}
                        className="design-idea-comment"
                      />
                    </li>
                  )}
                  pagination={{
                    current: currentPageNumber,
                    total: ideaReviewsData[0].total,
                    pageSize: 10,
                    showSizeChanger: false,
                    onChange: handleGetOtherList,
                  }}
                />
              )}
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default DesignIdeaDetailContent;
