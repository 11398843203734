import React from "react";
import * as queryString from "query-string";
import { GraphqlMutation } from "../../../helpers/request.helper";
import { useIntl } from "react-intl";
import Icon from "../../../components/icon";
import { Row, Form } from "antd";
import Input from "../../../wrappers/input";
import { useTheme } from "styled-components";
import { PrimaryButton } from "../../../wrappers/button";

/**
 *
 *
 * 重置密码模块
 *
 */
interface ResetFormVaues {
  email: string;
  password: string;
  showPassword: boolean;
  confirm: string;
  showConfirm: boolean;
}

const ResetPassword: React.FC<{
  email: String; // 从邮箱链接跳转,从服务器获得到的email
}> = ({ email }) => {
  const { formatMessage: f } = useIntl();
  const { primaryColor } = useTheme();
  /**
   * 输入验证
   */
  const requiredText = f({ id: "Required" });

  const ResetPasswordRequest = async ({ email, password }: ResetFormVaues) => {
    const parsed = queryString.parse(window.location.search);

    if (parsed && parsed.token) {
      const res = await GraphqlMutation("Register", {
        action: "reset",
        email,
        password,
        token: parsed.token,
      });

      if (res.status_code === 200) {
        location.href = `${location.origin}/login`;
      }
    }
  };

  const onFinish = (values: any) => {
    ResetPasswordRequest({ ...values });
  };

  return (
    <div>
      <Row style={{ padding: "10px 0 0 10px" }}>
        {f({ id: "ResetPassword" })}
      </Row>
      <Form
        name="basic"
        initialValues={{ remember: true, email }}
        onFinish={onFinish}
        style={{ padding: "45px" }}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: f({
                id: "PleaseEnterAValidEmailAddressExclamationMark",
              }),
            },
            {
              max: 60,
              message: f(
                { id: "TleLengthOfTheInputExceedNumCharacters" },
                {
                  num: 60,
                },
              ),
            },
            {
              type: "email",
              message: f({
                id: "PleaseEnterAValidEmailAddressExclamationMark",
              }),
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            { required: true, message: requiredText },
            {
              min: 6,
              message: f(
                { id: "TheLengthOfTheInputMustBeAtLeast" },
                {
                  num: 6,
                  field: f({ id: "Password" }),
                },
              ),
            },
            {
              max: 20,
              message: f(
                { id: "TleLengthOfTheInputExceedNumCharacters" },
                {
                  num: 20,
                },
              ),
            },
            {
              pattern: /[0-9a-zA-Z_-]{1,}$/,
              message: f(
                { id: "TleLengthOfTheInputExceedNumCharacters" },
                {
                  num: 20,
                },
              ),
            },
          ]}
        >
          <Input
            type="password"
            placeholder={`${f({
              id: "Password",
            })} *`}
          />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          rules={[
            { required: true, message: requiredText },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(f({ id: "PasswordDoesNotMatch" }));
              },
            }),
          ]}
        >
          <Input
            type="password"
            placeholder={`${f({
              id: "ConfirmPassword",
            })} *`}
          />
        </Form.Item>
        <Form.Item>
          <PrimaryButton htmlType="submit" block bgColor={primaryColor}>
            {f({ id: "Confirm" })}
            <Icon type="icont1_correct"></Icon>
          </PrimaryButton>
        </Form.Item>
      </Form>
    </div>
  );
};

export default React.memo(ResetPassword);
