import React, { useContext, useState, useCallback, useEffect } from "react";
import classNames from "classnames";
import { Dispatch, RootState } from "../../store/configureStore";
import { useSelector, useDispatch } from "react-redux";
import * as styles from "./index.scss";
import {
  Product,
  Sku,
  ProductDetail,
  ProductCatalog,
} from "../../models/Product";
import Provider from "../../models/Provider";
import { useIntl } from "react-intl";
import { CommonProps } from "../../typings/component";
import * as queryString from "query-string";
import CartProductCard from "./Components/CartProductCard";
import QrCode from "./Components/QrCode";
import ProductDesignIdea from "./ProductDesignIdea";
import { DesignIdea } from "../../models/DesignIdea";

import { BusinessContext } from "../BusinessContext";
import Icon from "../../components/icon";
import Drawer from "../../wrappers/drawer";
import Input from "../../wrappers/input";
import Button, { PrimaryButton } from "../../wrappers/button";
import { Row, Col, Space } from "antd";
import { useTheme } from "styled-components";
import dayjs from "dayjs";

const ProductPurchase: React.FC<
  CommonProps & {
    productItem: Product;
    provider: Provider;
    sku: Sku;
    currency: string;
    collection: number[];
    products: Product[];
    reference_code: string;
    qrcodeimg: string;
    search: string;
    productDetails: ProductDetail;
    dispatch: Dispatch;
    designIdeas: DesignIdea[];
    handleQrCodeVisibleChange: any;
    qrCodeVisible?: boolean;
    productOptions: ProductCatalog[];
  }
> = ({
  productItem,
  provider,
  isLogin,
  sku,
  collection,
  products,
  reference_code,
  qrcodeimg,
  search = "",
  productDetails,
  dispatch,
  isMobile,
  designIdeas,
  qrCodeVisible,
  handleQrCodeVisibleChange,
  productOptions = [],
}) => {
  const { formatMessage: f } = useIntl();
  const { bussinessPrimaryColor, primaryColor, secondaryColor } = useTheme();

  const { isToBusiness } = useContext(BusinessContext);

  const { User: user } = useSelector((state: RootState) => state);

  const { isPrivate } = useSelector((state: RootState) => state.CommonData);

  const { productActivities } = useSelector(
    (state: RootState) => state.PDPPage,
  );

  const { agent_code, host_id } = useSelector(
    (state: RootState) => state.URLSearch,
  );

  const [minimum, setMinimum] = useState(1),
    [maximum, setMaximum] = useState(1e10);

  const [productNumber, setProductNumber] = useState(minimum);
  const [activityId, setActivityId] = useState<any>();
  const [isActStart, setIsActStart] = useState(false);
  const [isAct, setIsAct] = useState(false);

  useEffect(() => {
    if (provider && productActivities) {
      const activityInfo = productActivities.filter(
        item => Number(item.provider_id) === Number(provider.provider_id),
      );
      setActivityId(
        activityInfo.length > 0 ? activityInfo[0].activity_id : null,
      );
      setIsAct(activityInfo.length > 0);
      setMinimum(activityInfo.length > 0 ? 1 : provider.product_moq);
      setProductNumber(activityInfo.length > 0 ? 1 : provider.product_moq);
      setMaximum(
        activityInfo.length > 0
          ? activityInfo[0].activity_purchase_limit
          : provider.inventory_quantity,
      );
      if (activityInfo.length > 0) {
        const startTime = new Date(activityInfo[0].start_time);
        const nowTime = new Date(activityInfo[0].now_time);

        const isStart = Number(startTime) - Number(nowTime) < 0;
        setIsActStart(isStart);
      }
    }
  }, [provider, productActivities]);

  const handleChangeProductNumber = useCallback(
    (e: React.ChangeEvent<any>) => {
      const num = Number(e.target.value);
      if (num > 0) {
        if (num < minimum) {
          setProductNumber(minimum);
        } else if (num > maximum) {
          setProductNumber(maximum);
        } else {
          setProductNumber(num);
        }
      }
    },
    [provider, minimum, maximum],
  );

  const productId = productItem.shop_product_id;

  const handleSaveOrUnSave = useCallback(() => {
    if (isLogin) {
      dispatch.Collections.submitCollectAsync({
        action: collection.includes(productId) ? "cancel" : "like",
        object: "product",
        data_id: productId,
      });
    } else {
      dispatch.User.changeUserState({
        modalAction: "Login",
      });
    }
  }, [isLogin, collection, productItem, sku]);

  const [qrCodeValue, setQrCodeValue] = useState(""),
    [showQrCode, setShowQrCode] = useState(false),
    [qrCodeUrl, setQrCodeUrl] = useState("");

  const handlePrintShow = useCallback(() => {
    const linkData = queryString.parse(location.search);
    linkData.agent_code = user.isAgent ? user.agentCode : undefined;
    if (host_id && isPrivate) {
      const data = {
        action: "productDetail",
        shop_id: host_id,
        private: isPrivate,
        shop_product_id: productId,
        provider_id: provider?.provider_id ?? undefined,
        shop_sku_id: sku.shop_sku_id,
        agent_code: user.isAgent ? user.agentCode : undefined,
        callback: (res: string) => {
          setQrCodeUrl("data:image/png;base64," + res);
          setQrCodeValue("data:image/png;base64," + res);
          setShowQrCode(true);
        },
      };

      dispatch.PDPPage.getPrivateQrUrlAsync(data);
    } else {
      const url = location.pathname + "?" + queryString.stringify(linkData);
      setQrCodeUrl(url);
      dispatch.PDPPage.getShortUrlAsync({
        url,
        callback: (res: any) => {
          setQrCodeValue(res.short_url);
          setShowQrCode(true);
        },
      });
    }
  }, [user, host_id, isPrivate]);

  useEffect(() => {
    qrCodeVisible ? handlePrintShow() : setShowQrCode(false);
  }, [qrCodeVisible]);

  const [showCart, setShowCard] = useState(false),
    [cartProductId, setCartProductId] = useState<number | null>();

  const handleToCart = useCallback(() => {
    if (isLogin) {
      const data = {
        shop_product_id: productId,
        quantity: productNumber,
        agent_code,
        callback: () => {
          setShowCard(true);
          setCartProductId(productId);
        },
      };

      isToBusiness
        ? dispatch.PDPPage.addTobCartAsync(data)
        : dispatch.PDPPage.addToCartAsync({
            ...data,
            provider_id: provider?.provider_id,
            private: isPrivate,
            shop_id: host_id,
            activity_id: isAct && isActStart ? activityId : undefined,
          });
    } else {
      dispatch.User.changeUserState({
        modalAction: "Login",
      });
    }
  }, [
    productId,
    productNumber,
    provider,
    isToBusiness,
    agent_code,
    activityId,
  ]);

  const handleCloseCart = () => {
    setShowCard(false);
    setCartProductId(null);
  };

  const handleUserLikeChange = useCallback(
    data =>
      dispatch.Collections.submitCollectAsync({
        ...data,
        callback: (ideaId: number) =>
          dispatch.PDPPage.submitCollectDesign(ideaId),
      }),
    [],
  );

  if (productItem) {
    return (
      <div className={styles[`ProductPurchase-box`]}>
        <Row gutter={10} style={{ margin: "20px 0 30px 0" }}>
          <Col span={10}>
            <span>{f({ id: "Quantity" })}:</span>
          </Col>
          <Col span={14} style={{ textAlign: "right" }}>
            <Space size={5}>
              <PrimaryButton
                onClick={() => setProductNumber(productNumber - 1)}
                icon={<Icon type="icont1_jian" />}
                disabled={productNumber <= minimum}
                bgColor={
                  productNumber <= minimum
                    ? "#88888888"
                    : isToBusiness
                    ? bussinessPrimaryColor
                    : primaryColor
                }
              />
              <Input
                style={{
                  width: "50px",
                  border:
                    "1px solid " +
                    (isToBusiness ? bussinessPrimaryColor : primaryColor),
                  borderRadius: "5px",
                  textAlign: "center",
                }}
                value={productNumber}
                onChange={handleChangeProductNumber}
              />
              <PrimaryButton
                icon={<Icon type="icont1_jia" />}
                disabled={productNumber >= maximum}
                onClick={() => setProductNumber(productNumber + 1)}
                bgColor={
                  productNumber >= maximum
                    ? "#88888888"
                    : isToBusiness
                    ? bussinessPrimaryColor
                    : primaryColor
                }
              />
            </Space>
          </Col>
        </Row>

        {!sku.isPreview && (
          <>
            <div>
              <Row
                gutter={10}
                style={{ borderBottom: "1px solid #BDBCBC", height: "75px" }}
              >
                <Col span={10}>
                  <Button
                    onClick={handleSaveOrUnSave}
                    block
                    icon={
                      <Icon
                        type={
                          collection.includes(productId)
                            ? "icont2_shoucang1"
                            : "iconshoucang1"
                        }
                      />
                    }
                    color={isToBusiness ? bussinessPrimaryColor : primaryColor}
                    style={{
                      height: "50px",
                      fontSize: "1em",
                    }}
                  >
                    <span>{f({ id: "Collection" })}</span>
                  </Button>
                </Col>
                <Col span={14}>
                  <PrimaryButton
                    onClick={handleToCart}
                    disabled={!provider || productNumber <= 0}
                    icon={<Icon type="icongouwuchexian" />}
                    block
                    style={{
                      backgroundColor:
                        productNumber <= 0 || provider?.inventory_quantity <= 0
                          ? "#88888888"
                          : isToBusiness
                          ? bussinessPrimaryColor
                          : primaryColor,
                      height: "50px",
                      fontSize: "1em",
                    }}
                  >
                    {f({ id: isToBusiness ? "AddToPurcaseList" : "AddToCart" })}
                  </PrimaryButton>
                </Col>
              </Row>

              <Drawer
                visible={showCart}
                onClose={handleCloseCart}
                title={f({ id: "AddToPurcaseList" })}
                width={isMobile ? "95vw" : "500px"}
                mask
                color={isToBusiness ? bussinessPrimaryColor : secondaryColor}
              >
                <div className={styles["ProductParams-cart-list"]}>
                  <div>
                    {products
                      .filter(item => item.shop_product_id === cartProductId)
                      .map(item => (
                        <CartProductCard
                          key={item.shop_product_id}
                          item={item}
                          dispInfo={f({
                            id: isToBusiness
                              ? "AddedToPurcaseList"
                              : "AddedToCart",
                          })}
                          productOptions={productOptions.map(s =>
                            s.category_id === 1 || s.category_id === 6
                              ? s.category_sub_name
                              : s.attribution_value,
                          )}
                          series={sku.series_name}
                          brand={sku.brand_name}
                        />
                      ))}
                  </div>
                  <PrimaryButton
                    block
                    onClick={() =>
                      (location.href = `/${
                        isToBusiness ? "tob/cart" : "cart"
                      }/${search}`)
                    }
                    icon={
                      <Icon
                        type="icongouwuchexian"
                        style={{ color: "white" }}
                      ></Icon>
                    }
                    style={{
                      height: "50px",
                    }}
                    bgColor={
                      isToBusiness ? bussinessPrimaryColor : primaryColor
                    }
                  >
                    {f({
                      id: isToBusiness
                        ? "ContinueToPurchaseList"
                        : "ContinueToCart",
                    })}
                  </PrimaryButton>
                </div>
                <div className={styles["ProductParams-cart-designIdeas"]}>
                  <ProductDesignIdea
                    designIdeas={designIdeas}
                    onChangeUserLike={handleUserLikeChange}
                    isMobile={isMobile}
                    titlePosition="left"
                  />
                </div>
              </Drawer>
            </div>
            {productItem && productDetails && (
              <QrCode
                item={productItem}
                provider={provider}
                title={productItem.product_name}
                manufacturer={sku.shop_name}
                options={productItem.specification.map(s => ({
                  title: s.category_id === 0 ? s.category_sub_name : s.title,
                  value:
                    s.category_id === 1 || s.category_id === 6
                      ? s.category_sub_name
                      : s.attribution_value,
                }))}
                img={qrcodeimg}
                onClose={() => {
                  setQrCodeValue("");
                  setShowQrCode(false);
                  handleQrCodeVisibleChange(false);
                }}
                show={showQrCode}
                value={qrCodeValue}
                agentCodeGetData={user.agentCode}
                url={qrCodeUrl}
              />
            )}
          </>
        )}
      </div>
    );
  }
};

export default React.memo(ProductPurchase);
