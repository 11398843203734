import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import formatDistance from "date-fns/formatDistance";
import classnames from "classnames";
import * as styles from "./index.scss";
import Icon from "../../components/icon";

import { useTheme } from "styled-components";
import { BusinessContext } from "../BusinessContext";
import { useIntl } from "react-intl";
import { RootState, Dispatch } from "../../store/configureStore";
import Button from "../../wrappers/button";
import { Row, Col, Collapse } from "antd";
const { Panel } = Collapse;

type MessageItemProps = {
  id: number;
  title: string;
  content: string;
  isRead: boolean;
  date: string;
  classNamePrefix?: "" | "mobile";
};
const MessageItem: React.FC<MessageItemProps> = props => {
  const dispatch = useDispatch<Dispatch>();
  const { formatMessage: f } = useIntl();
  const { primaryColor } = useTheme();

  const { title, isRead, date, classNamePrefix, id, content } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleMessageToggle = () => {
    setIsOpen(!isOpen);
    if (!isOpen && isRead) {
      dispatch.MessagePage.changeMessageStatus({
        messageId: "" + id,
        action: "read",
      });
    }
  };

  const handleDelMessage = () => {
    dispatch.MessagePage.changeMessageStatus({
      messageId: id,
      action: "delete",
    });
  };

  // 解决staging上ios访问的问题
  const wrapperFormatDistance = (value: string) => {
    try {
      return formatDistance(new Date(), new Date(value), {});
    } catch (error) {
      return value;
    }
  };

  return (
    <Collapse
      onChange={handleMessageToggle}
      style={{
        marginBottom: "20px",
        borderRadius: "5px",
      }}
      defaultActiveKey={!isRead ? ["1"] : []}
    >
      <Panel
        key={"1"}
        showArrow={false}
        header={
          <Row>
            <Col span={20}>
              <span>
                {isRead && (
                  <div
                    className={
                      styles[
                        `${classNamePrefix}MessageConter-item-title-unread`
                      ]
                    }
                    style={{
                      background: primaryColor,
                    }}
                  />
                )}
                {title} | <span>{wrapperFormatDistance(date)}</span>
              </span>
            </Col>
            <Col span={4} style={{ textAlign: "right" }}>
              <Button
                onClick={event => {
                  event.stopPropagation();
                  handleDelMessage();
                }}
                shape={"circle"}
                size={"small"}
                icon={<Icon type="icondelete"></Icon>}
                color={primaryColor}
              ></Button>
            </Col>
          </Row>
        }
      >
        <div
          className={classnames(styles["MessageConter-item-content-open"])}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </Panel>
    </Collapse>
  );
};

export default React.memo(MessageItem);
