import React, {
  useRef,
  useCallback,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "../../../store/configureStore";
import { useUpdateEffect, useDrag } from "ahooks";
import {
  Row,
  Col,
  Button,
  Carousel,
  Space,
  Tag,
  Divider,
  List,
  Typography,
} from "antd";

import {
  DefaultPrevArrow,
  DefaultNextArrow,
} from "../../../components/carousel";
import Icon from "../../../components/icon";
import Image from "../../../components/image";
import { Link } from "../../../wrappers/typography";
import classNames from "classnames";
import { Product, ProductDetail } from "../../../models/Product";
import head from "lodash/head";
import take from "lodash/take";
import { useTheme } from "styled-components";
import styles from "../style.scss";

export interface ProductDetailProps {
  visible: boolean;

  onCancel: () => void;

  productId?: number;

  handleAddToCanvas: (item: Product) => void;

  isManagementEnd: boolean;
}

const DesignProductDetail: React.FC<ProductDetailProps> = ({
  onCancel,
  visible,
  productId,
  handleAddToCanvas,
  isManagementEnd,
}) => {
  const theme = useTheme();
  const getDragProps = useDrag();

  const dispatch = useDispatch<Dispatch>();
  const [product, setProduct] = useState<Product & ProductDetail>();
  const [recommendProducts, setRecommendProducts] = useState<Product[]>();
  const productCarouselRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  // const containerRef = useClickAway(onCancel);
  const { Text } = Typography;
  const { root_id } = useSelector((state: RootState) => state.URLSearch);

  useUpdateEffect(() => {
    if (productId) {
      setActiveIndex(0);
      const data = {
        shop_product_id_list: productId + "",
        root_id,
        callback: (result: (Product & ProductDetail)[]) => {
          setProduct(head(result));
        },
      };
      dispatch.DesignIdea.getProductDetailsAsync(data);

      if (!isManagementEnd) {
        const recommendData = {
          shop_product_id: productId,
          callback: (result: Product[]) => {
            setRecommendProducts(result);
          },
        };

        dispatch.DesignIdea.getDesignIdeaRecommendAsync(recommendData);
      }
    }
  }, [productId]);

  return (
    <div
      className={styles["product-detail-container"]}
      style={{ display: visible ? "" : "none" }}
      // onClick={e => {
      //   console.log(e);

      //   onCancel();
      // }}
    >
      <div className={styles["product-detail-wrapper"]}>
        <Row justify="space-between">
          <Col></Col>
          <Col>
            <Button
              type="link"
              icon={<Icon type="icont1_close" />}
              onClick={() => onCancel()}
            />
          </Col>
        </Row>

        {product && (
          <>
            {product.mediaData && (
              <div className={styles["product-detail-slick-container"]}>
                <div style={{ marginBottom: "10px" }}>
                  <Link href={product.url} target="_blank">
                    <Image
                      height={1}
                      width={2}
                      src={product.mediaData[activeIndex]?.media_url}
                    />
                  </Link>
                </div>
                {/* <Carousel
                  afterChange={setActiveIndex}
                  arrows
                  dots={false}
                  slidesToScroll={1}
                  slidesToShow={5}
                  infinite={false}
                  nextArrow={<DefaultNextArrow size="small" />}
                  prevArrow={<DefaultPrevArrow size="small" />}
                  className={classNames("design-product-slick")}
                  ref={productCarouselRef}
                > */}
                <Row gutter={5}>
                  {take(product.mediaData, 5).map((p, pIndex) => (
                    // <div key={pIndex}>
                    <Col flex="20%" key={pIndex}>
                      <a
                        onClick={() => setActiveIndex(pIndex)}
                        className={classNames(
                          styles["product-recommended-item"],
                          {
                            [styles["product-recommended-item-active"]]:
                              activeIndex === pIndex,
                          },
                        )}
                      >
                        <Image
                          height={1}
                          width={1}
                          src={p.media_url}
                          {...(p.is_transparency
                            ? getDragProps({
                                ...product,
                                product_picture: p.media_url,
                              })
                            : {})}
                        >
                          {p.is_transparency && (
                            <Button
                              type="link"
                              onClick={e => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleAddToCanvas({
                                  shop_product_id: productId,
                                  product_picture: p.media_url,
                                });
                              }}
                              className={styles["product-recommended-btn"]}
                            >
                              <Icon type="icondaoru" rotate={180} />
                            </Button>
                          )}
                        </Image>
                      </a>
                    </Col>
                    // </div>
                  ))}
                </Row>
                {/* </Carousel> */}
              </div>
            )}

            <div>
              <Space>
                <Link href={product.url} target="_blank">
                  {product.product_name}
                </Link>
                {product.tag_price_name && (
                  <Tag color="#C21D44">{product.tag_price_name}</Tag>
                )}
              </Space>

              <div className={styles["design-product-shop"]}>
                <Link color="#7493a6" target="_blank" href={product.shop_url}>
                  {product.shop_name}
                </Link>
              </div>

              <Space>
                <Text className={styles["design-product-price"]}>
                  {product.tag_price_value}
                </Text>
                {product.original_price > 0 && (
                  <Text className={styles["design-product-original"]}>
                    {product.original_price_value}
                  </Text>
                )}
              </Space>
            </div>
            <div className={styles["product-specification"]}>
              {product.specification.map(s => (
                <div>
                  <Text>{`${s.title}：${s.value}`}</Text>
                </div>
              ))}
            </div>

            {!isManagementEnd && (
              <>
                <Divider className={styles["designer-divider"]} plain>
                  相似产品
                </Divider>

                <List
                  grid={{ gutter: 5, column: 4 }}
                  dataSource={recommendProducts}
                  renderItem={item => (
                    <List.Item>
                      <a
                        target="_blank"
                        href={item.url}
                        className={styles["product-recommended-item"]}
                      >
                        <Image
                          height={1}
                          width={1}
                          src={item.product_picture}
                          {...getDragProps({ ...item })}
                        >
                          <Button
                            type="link"
                            onClick={e => {
                              e.stopPropagation();
                              e.preventDefault();
                              handleAddToCanvas(item);
                            }}
                            className={styles["product-recommended-btn"]}
                          >
                            <Icon type="icondaoru" rotate={180} />
                          </Button>
                        </Image>
                      </a>
                    </List.Item>
                  )}
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(DesignProductDetail);
