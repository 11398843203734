import { createFromIconfontCN } from "@ant-design/icons";

const IconFont = createFromIconfontCN({
  scriptUrl: [
    "//at.alicdn.com/t/font_1693683_sp9rjpgpn9.js",
    "//at.alicdn.com/t/font_1890670_pj8a5iixofd.js",
  ],
});

export default IconFont;
