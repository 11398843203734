import { GraphqlField, GraphqlRefField } from "../helpers/decorators";
import BaseModel from "./BaseModel";

export class ProductCategory {
  @GraphqlField()
  public category_id: number;

  @GraphqlField()
  public category_name: string;

  @GraphqlField()
  public category_picture?: string;

  @GraphqlField()
  public level?: number;

  @GraphqlRefField(ProductCategory)
  public children?: ProductCategory[] = [];
}

export class Room {
  @GraphqlField()
  public room_id: number;

  @GraphqlField()
  public room_name: string;

  @GraphqlField()
  public room_picture: string;
}

/**
 * 风格
 */
export class Style {
  @GraphqlField()
  public style_id: number;

  @GraphqlField()
  public style_name: string;

  @GraphqlField()
  public style_picture: string;
}

export default class ProductClassification {
  @GraphqlRefField(Room)
  public Room: Room[] = [];
  @GraphqlRefField(Style)
  public Style: Style[] = [];
  @GraphqlRefField(ProductCategory)
  public Categories: ProductCategory[] = [];
}
