import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useCountDown } from "ahooks";
import styles from "./index.scss";
import { RootState, Dispatch } from "../../store/configureStore";
import { Col, Row } from "antd";
import ProductListContent from "../ProductList/Content";
import { ShopActivity } from "../../models/Shop";
import Icon from "../../components/icon";
import dayjs from "dayjs";
import { getFirstItem } from "../../helpers/modelHelpers";

const ShopActivityContent = React.memo(() => {
  const dispatch = useDispatch<Dispatch>();
  const { activityInfo, shop_id } = useSelector(
    (state: RootState) => state.ShopActivityPage,
  );

  const [countdown, setTargetDate, formattedRes] = useCountDown();
  const { days, hours, minutes, seconds } = formattedRes;

  const [shopId, setShopId] = useState("");
  const [activityDetails, setActivityDetails] = useState<any>();

  useEffect(() => {
    const [, , info] = window.location.pathname.split("/");
    const shop = info.split("-")[1];
    setShopId(shop);
    if (!activityInfo) {
      dispatch.ShopActivityPage.initActivityAsync({ shop_id: shop });
    }
  }, []);

  useEffect(() => {
    if (activityInfo) {
      let info: ShopActivity;

      if (!activityDetails) {
        setActivityDetails(activityInfo[0]);
        info = activityInfo[0];
      } else {
        info = activityDetails;
      }
      const { now_time, end_time } = getFirstItem(
        activityInfo.filter(
          (item: ShopActivity) => item.activity_id === info.activity_id,
        ),
      );
      const mineTime = new Date(dayjs().format("YYYY-MM-DD HH:mm:ss"));

      const timeStamp =
        parseInt(String(new Date(mineTime).getTime() / 1000)) +
        (parseInt(String(new Date(end_time).getTime() / 1000)) -
          parseInt(String(new Date(now_time).getTime() / 1000)));

      setTargetDate(getformatTime(timeStamp));
    }
  }, [activityInfo]);

  const getformatTime = (timeStamp: any) => {
    const time = new Date(timeStamp * 1000);
    const Y = time.getFullYear();
    const M = (time.getMonth() + 1).toString().padStart(2, "0");
    const D = time.getDate().toString().padStart(2, "0");
    const h = time.getHours().toString().padStart(2, "0");
    const m = time.getMinutes().toString().padStart(2, "0");
    const s = time.getSeconds().toString().padStart(2, "0");
    return `${Y}-${M}-${D} ${h}:${m}:${s}`;
  };

  return (
    <div>
      <div className={styles["activity"]}>
        <div className={styles["activity_nav"]}>
          {activityInfo &&
            activityInfo.map((item: ShopActivity) => {
              return (
                <span
                  className={styles["activity_nav-span"]}
                  style={{
                    borderBottom:
                      item.activity_id === activityDetails?.activity_id
                        ? "2px solid white"
                        : "",
                  }}
                  onClick={() => {
                    setActivityDetails(item);
                    dispatch.ShopActivityPage.initActivityAsync({
                      shop_id: shopId,
                    });
                    dispatch.PLPage.initActivitySkuListAsync({
                      shop_id,
                      activity_id: item.activity_id,
                    });
                  }}
                >
                  {item.activity_type_text}
                </span>
              );
            })}
        </div>
        <div className={styles["activity_actpic"]}>
          <img src={activityDetails?.activity_web_picture} alt="" />
        </div>
        <Row className={styles["activity_footer"]}>
          <Col span={12}> {activityDetails?.activity_name}</Col>
          <Col span={12} className={styles["activity_footer-right"]}>
            <Icon type="iconshandian" />
            {` 距活动结束还剩：${days} 天 ${hours} 时 ${minutes} 分 ${seconds} 秒`}
          </Col>
        </Row>
      </div>
      {activityDetails && (
        <ProductListContent
          haveCategory={false}
          haveFilter={false}
          handleInitData={dispatch.PLPage.initActivitySkuListAsync}
          extraParams={{ shop_id, activity_id: activityDetails.activity_id }}
        />
      )}
    </div>
  );
});

export default ShopActivityContent;
