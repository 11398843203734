import React, {
  useRef,
  useCallback,
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "../../../store/configureStore";
import {
  DragDropContext,
  DropResult,
  Droppable,
  DraggableLocation,
  DroppableProvided,
  DraggableProvided,
  Draggable,
  DraggableStateSnapshot,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import horizontalRotateImg from "../../../static/img/horizontalRotate.svg";
import verticalRotateImg from "../../../static/img/verticalRotate.svg";
import rotateSvg from "../../../static/img/rotate.svg";
import groupTitleBar from "../../../static/img/group-title-bar.png";
import Renderer, {
  ItemType,
  RenderItem,
  KeyValuePairs,
} from "../../../components/irender";
import {
  Dropdown,
  Input,
  Divider,
  Slider,
  Space,
  message as Message,
  Tooltip,
  Row,
  Col,
  Spin,
  Upload,
  Card,
  Collapse,
  Breadcrumb,
  List,
  Radio,
  Avatar,
  Menu,
  Tabs,
} from "antd";
import ColorPicker from "../../../wrappers/color-picker";
import { DesignSelector as Select } from "../../../wrappers/select";
import { InputNumber } from "../../../wrappers/input";
import { PrimaryButton, TextButton } from "../../../wrappers/button";
import { Text, Paragraph, Link } from "../../../wrappers/typography";
import ImageCard, { FloatLayer } from "../../../wrappers/card";
import Icon from "../../../components/icon";
import {
  useRequest,
  useDrag,
  useDrop,
  useClickAway,
  useSize,
  useBoolean,
  useScroll,
  useEventTarget,
  usePrevious,
  useMount,
  useUpdateEffect,
  useDebounce,
  useThrottleFn,
  useCounter,
  useControllableValue,
  useKeyPress,
  useEventListener,
  useSessionStorageState,
} from "ahooks";
import split from "lodash/split";
import last from "lodash/last";
import replace from "lodash/replace";
import isEmpty from "lodash/isEmpty";
import groupBy from "lodash/groupBy";
import initial from "lodash/initial";
import trimEnd from "lodash/trimEnd";
import unionBy from "lodash/unionBy";
import take from "lodash/take";
import flatten from "lodash/flatten";
import omit from "lodash/omit";
import { replaceByItem } from "../../../helpers/sequence.helper";
// 引入编辑器组件
// import BraftEditor, { BuiltInControlType } from "braft-editor";
import { ContentUtils } from "braft-utils";
// 引入编辑器样式
import "../../../styles/editor.scss";
import { CanvasToImage } from "../../../helpers/canvas2image";

import { ProductCategory, Room } from "../../../models/ProductCategory";
import RcImage from "../../../components/image";
import tail from "lodash/tail";
import ProductDetail from "./ProductDetail";
import { Product } from "../../../models/Product";
import { useIntl } from "react-intl";
import { getBase64, dataURItoBlob } from "../../../helpers/modelHelpers";
import InfiniteScroll from "react-infinite-scroller";
import debounce from "lodash/debounce";
import head from "lodash/head";
import isArray from "lodash/isArray";
import SystemMaterial from "./SystemMaterial";
import UserUpdate from "./UserUpdate";
import GroupList from "./GroupList";
import DesignerSetting from "./DesignerSetting";
import MyDesign from "./MyDesign";
import dilogoImg from "../../../static/img/dilogo.png";
import { useTheme } from "styled-components";
import "./styles/render.scss";
import classNames from "classnames";
import styles from "../style.scss";
import queryString from "query-string";

interface DeviceResolution {
  device: string;
  ratio: string;
  name: string;
  width: number;
  height: number;
}

export interface RenderProps {
  showCanvas: boolean;

  showEditor: boolean;

  showItemDetails: (id: string) => void;

  navActiveIndex: number;

  isManagementEnd: boolean;
}

const Designer: React.FC<RenderProps> = (props, ref) => {
  const { designPrimaryColor, primaryColor } = useTheme();
  const { formatMessage: f } = useIntl();
  const { showCanvas, showEditor, isManagementEnd } = props;
  const [scroll, setScroll] = useState(0);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const toolbarRef = useRef<HTMLDivElement>(null);
  const userUpdateRef = useRef<HTMLDivElement>(null);
  const systemMaterialRef = useRef<HTMLDivElement>(null);
  const [editedItem, setEditedItem] = useState<RenderItem>();
  const [renderItems, setRenderItems] = useState<RenderItem[]>([]);
  const prevItem = usePrevious(editedItem);
  const designerContainerRef = useRef<HTMLDivElement>(null);
  const [render, setRender] = useState<Renderer>();
  const [
    displayerVisiable,
    {
      toggle: toggleDipslayer,
      setTrue: setDisplayerShow,
      setFalse: setDisplayerHidden,
    },
  ] = useBoolean(false);
  const [
    textDisplayerVisiable,
    {
      toggle: toggleTextDipslayer,
      setTrue: setTextDisplayerShow,
      setFalse: setTextDisplayerHidden,
    },
  ] = useBoolean(false);
  const [
    toolBarVisiable,
    {
      toggle: toggleToolbar,
      setTrue: setToolbarShow,
      setFalse: setToolbarHidden,
    },
  ] = useBoolean(false);
  const [
    deviceResolutionVisable,
    {
      setTrue: setDeviceResolutionShow,
      toggle: toggleDeviceResolution,
      setFalse: setDeviceResolutionHidden,
    },
  ] = useBoolean(false);
  const [
    productDetailVisiable,
    {
      toggle: toggleProductDetail,
      setTrue: setProductDetailShow,
      setFalse: setProductDetailHidden,
    },
  ] = useBoolean(false);
  const [
    canvasScale,
    { inc: increaseScale, dec: decreaseScale, set: setScale },
  ] = useCounter(80, { min: 10, max: 200 });

  const { host_id = null } = useSelector((state: RootState) => state.URLSearch);

  const handleDeleteItem = () => {
    render?.delete(editedItem?.id);

    if (editedItem?.additionalHash?.shop_product_id) {
      const productIndex = selectedProducts.findIndex(
        p => p.shop_product_id === editedItem.additionalHash.shop_product_id,
      );

      selectedProducts.splice(productIndex, 1);

      dispatch.DesignIdea.setSelectedProducts([...selectedProducts]);
    }

    setEditedItem(undefined);

    setRenderItems([...render?.objects]);

    setGroupList([...render?.sets]);
  };

  const canvasWrapperRef = useRef<HTMLDivElement>(null);

  useKeyPress("delete", event => {
    const localName = (event.target as HTMLElement).localName;

    if (localName === "input" || localName === "textarea" || !editedItem)
      return;

    handleDeleteItem();
  });

  const getDragProps = useDrag();

  const dimensionBarRef = useRef<HTMLDivElement>(null),
    propertyDisplayerRef = useRef<HTMLDivElement>(null),
    textDisplayerRef = useRef<HTMLDivElement>(null),
    groupContainRef = useRef<HTMLDivElement>(null);

  const dragElementMap = new Map([
    ["dimension", dimensionBarRef],
    ["property", propertyDisplayerRef],
    ["text", textDisplayerRef],
    ["group", groupContainRef],
  ]);

  const customDragProps = (key: string) => {
    return {
      ...getDragProps(key),
      onDragStart: (e: React.DragEvent) => {
        const dom = dragElementMap.get(key);

        if (dom?.current) {
          const { x, y } = dom.current.getBoundingClientRect();
          e.dataTransfer.setData(
            "custom",
            JSON.stringify({
              key,
              offsetX: e.clientX - x,
              offsetY: e.clientY - y,
            }),
          );
        }
      },
    };
  };

  const [groupList, setGroupList] = useState<string[][]>([]);

  const addProductImageToCanvas = (
    content: Product,
    position?: number[],
    isBg?: boolean,
  ) => {
    const center = position ?? [
      Math.floor(render.canvas.width / 2),
      Math.floor(render.canvas.height / 2),
    ];
    const { product_picture, shop_product_id } = content;
    setCanvasLoading(true);

    const newImage = new Image();
    newImage.src = product_picture + "?" + new Date().getTime();
    newImage.crossOrigin = "";
    newImage.onload = () => {
      setCanvasLoading(false);

      if (!isBg) {
        const newItem = render!.add(newImage, {
          name: "",
          type: ItemType.Image,
          isEditable: true,
          show: true,
          center,
          additionalHash: { shop_product_id, product_picture },
        } as RenderItem);

        render?.addToSet(newItem.id);

        render?.restore();

        if (shop_product_id) {
          dispatch.DesignIdea.setSelectedProducts([
            ...selectedProducts,
            content,
          ]);
        }

        setRenderItems([...render?.objects]);

        setGroupList([...render?.sets]);
      } else {
        render?.updateDataSet({
          "background-img": newImage,
        });

        setCurrResolution(r => ({
          ...r,
          width: newImage.width,
          height: newImage.height,
        }));

        render?.restore();
      }
    };
  };

  const designerBoxRef = useRef<HTMLDivElement>(null);

  const [canvasLoading, setCanvasLoading] = useState(false);

  const [dropProps, { isHovering }] = useDrop({
    onDom: (content: any, e) => {
      if (typeof content === "object") {
        if (content.key) {
          const { offsetX, offsetY, key } = content;

          const dom = dragElementMap.get(key);

          if (dom?.current && designerBoxRef?.current) {
            dom.current.style.top = `${Number(e?.clientY) - 50 - offsetY}px`;

            if (key === "group" || key === "dimension") {
              const dimension = designerBoxRef.current.getBoundingClientRect();
              const { width } = dom.current.getBoundingClientRect();
              dom.current.style.right = `${
                dimension.width - (Number(e?.clientX) - offsetX) - width
              }px`;
            } else {
              dom.current.style.left = `${Number(e?.clientX) - offsetX}px`;
            }
          }
        } else if (e?.target instanceof HTMLCanvasElement) {
          const canvas = e.target as HTMLCanvasElement;
          const { x, y } = canvas.getBoundingClientRect();
          const {
            shop_product_id,
            product_picture,
            type,
            isBg = false,
          } = content as Product;

          setCanvasLoading(true);

          if (!isBg) {
            const newImage = new Image();
            newImage.src = product_picture + "?" + new Date().getTime();
            newImage.crossOrigin = "";
            newImage.onload = () => {
              setCanvasLoading(false);

              const target = render?.checkTopTarget(e);

              const newItem = render!.add(newImage, {
                name: "",
                type: ItemType.Image,
                isEditable: true,
                show: true,
                center: [e.clientX - x, e.clientY - y],
                additionalHash: { shop_product_id, product_picture },
              } as RenderItem);

              if (target) {
                const [groupIndex, itemIndex] = render?.getSetTargetId(
                  target.id,
                );

                render?.addToSet(newItem.id, groupIndex, itemIndex);
              } else {
                render?.addToSet(newItem.id);
              }

              if (!type) {
                dispatch.DesignIdea.setSelectedProducts([
                  ...selectedProducts,
                  content,
                ]);
              }

              setRenderItems([...render?.objects]);

              setGroupList([...render?.sets]);
            };
          } else {
            const bgImg = new Image();
            bgImg.src = product_picture + "?" + new Date().getTime();
            bgImg.crossOrigin = "";
            bgImg.onload = () => {
              setCanvasLoading(false);

              render?.updateDataSet({
                "background-img": bgImg,
              });

              setCurrResolution(r => ({
                ...r,
                width: bgImg.width,
                height: bgImg.height,
              }));

              render?.restore();
            };
            dispatch.DesignIdea.setBgImage(product_picture);
          }
        }
      }
    },
  });

  const { run: handleDragover } = useThrottleFn(
    (e: React.DragEvent) => {
      render?.checkTopTarget(e);
    },
    { wait: 500 },
  );

  const dispatch = useDispatch<Dispatch>();
  const {
    currIdea,
    selectedProducts = [],
    tagProducts = {},
    action,
    gaeaDomain,
  } = useSelector((state: RootState) => state.DesignIdea);

  const classification = useSelector(
    (state: RootState) => state.ProductClassification,
  );
  const {
    skus = [],
    pickList,
    total = 0,
    hasMoreProducts = true,
  } = useSelector((state: RootState) => state.PLPage);

  const { getProductListAsync: productListLoading } = useSelector(
    (state: RootState) => state.loading.effects.PLPage,
  );
  const {
    submitIdeaAsync: submitIdeaLoading,
    uploadFileAsync: uploadFileLoading,
  } = useSelector((state: RootState) => state.loading.effects.DesignIdea);

  const [currCategory, setCurrCategory] = useState(classification.Categories);
  const [menuCategory, setMenuCategory] = useState(classification.Categories);
  const [categoryPlane, setCategoryPlane] = useState<
    (ProductCategory & { pid?: number })[]
  >([]);
  const [searchData, setSearch] = useState([]);
  const [menuType, setMenuType] = useState("category");

  const [breadcrumbs, setBreadcrumbs] = useState<ProductCategory[]>([]);

  const [
    productListVisiable,
    {
      toggle: toggleProductList,
      setTrue: setProductListShow,
      setFalse: setProductListHidden,
    },
  ] = useBoolean(false);
  const [keyword, setKeyword] = useState("");
  const [searchField, setSearchField] = useState("product_name");
  const [filters, setFilters] = useState<{ [key: string]: string }>({});
  const [orderby, setOrderby] = useState("");
  const [room, setRoom] = useState("");
  const [page, setPage] = useState(1);

  useUpdateEffect(() => {
    setCurrCategory(classification.Categories);
    setMenuCategory(classification.Categories);
    setBreadcrumbs([
      {
        category_id: 0,
        category_name: "顶级",
        level: 0,
        children: classification.Categories,
      },
    ]);

    classification.Categories.map(item => {
      handleGetCategoryPlane(item);
      setCategoryPlane(val => [...val, item]);
    });
  }, [classification]);

  const handleGetCategoryPlane = (data: any) => {
    data.children?.map(item => {
      const { children, ...category } = item;
      setCategoryPlane(val => [...val, { ...item, pid: data.category_id }]);
      if (children?.length > 0) {
        handleGetCategoryPlane(item);
      }
    });
  };

  const handleCategoryClick = useCallback((category: ProductCategory) => {
    setCurrCategory(category.children);
    setBreadcrumbs(list => [...list, category]);
    setFilters({});
    setPage(1);
    setOrderby("");
    setProductListShow();
  }, []);

  const { root_id, host_id: shop_id } = useSelector(
    (state: RootState) => state.URLSearch,
  );
  const { isPrivate } = useSelector((state: RootState) => state.CommonData);

  useUpdateEffect(() => {
    if (productListVisiable) {
      const [
        top_category_id,
        parent_category_id,
        product_category_id,
        fourth_category_id,
      ] = tail(breadcrumbs).map(c => c.category_id);
      const catelog = room
        ? { room }
        : {
            top_category_id,
            parent_category_id,
            product_category_id,
            fourth_category_id,
          };

      const new_sale = orderby ?? undefined;

      const params = {
        ...catelog,
        page,
        has_transparency: 1,
        ...filters,
        new_sale,
        reset: page === 1,
        shop_id,
        private: isPrivate,
      };

      if (keyword || isManagementEnd) {
        dispatch.PLPage.searchProductListAsync({
          action: "designidea",
          keyword,
          root_id,
          searchField,
          ...params,
        });
      } else {
        dispatch.PLPage.getProductListAsync({
          ...params,
        });
      }
    }
  }, [breadcrumbs, keyword, productListVisiable, filters, orderby, room, page]);

  const handleRoomClick = useCallback((room: Room) => {
    setBreadcrumbs([
      {
        category_id: 0,
        category_name: "顶级",
        level: 0,
        children: classification.Categories,
      },
      {
        category_id: room.room_id,
        category_name: room.room_name,
        category_picture: room.room_picture,
      },
    ]);

    setRoom(room.room_id.toString());

    setProductListShow();
  }, []);

  const handleSearchProduct = (keyword: string) => {
    if (!room && breadcrumbs.length < 2) {
      toggleProductList(!!keyword);
    }
    setKeyword(keyword);
    setPage(1);
    setOrderby("");
    setFilters({});
  };

  const handleBreadcrumbClick = useCallback(
    (category: ProductCategory, index: number) => {
      setCurrCategory(category.children || classification.Categories);
      setBreadcrumbs(take(breadcrumbs, index + 1));
      setFilters({});
      setPage(1);
      setOrderby("");
      setRoom("");
      setKeyword("");

      if (index === 0) {
        setProductListHidden();
      }
    },
    [breadcrumbs],
  );

  let defaultSettings;

  useMount(() => {
    const horizontalImg = new Image(),
      verticalImg = new Image(),
      rotateImg = new Image();
    horizontalImg.src = horizontalRotateImg;
    verticalImg.src = verticalRotateImg;
    rotateImg.src = rotateSvg;

    defaultSettings = {
      "rotate-operate-img": rotateImg,
      "vertical-operate-img": verticalImg,
      "horizontal-operate-img": horizontalImg,
      useSet: true,
      useCache: true,
      highlightColor: "#348fff",
      drawWatermark: false,
      onKeyPressUpdate: (
        items: RenderItem[],
        groups: string[][],
        id: string,
      ) => {
        const item = items.find(i => i.id === id);

        if (item) {
          setEditedItem({ ...item });
        } else {
          setEditedItem(undefined);
        }

        setRenderItems([...items]);
        setGroupList([...groups]);
      },
    };

    if (canvasRef.current) {
      const r = new Renderer(
        canvasRef.current as HTMLCanvasElement,
        defaultSettings as KeyValuePairs,
      );
      r.go();

      setRender(r);

      setTimeout(() => {
        const id = last(split(location.pathname, "/"));

        if (!isNaN(Number(id))) {
          const { root_id } = queryString.parse(location.search);

          dispatch.DesignIdea.getIdeaDetailAsync(
            isManagementEnd ? { design_idea_id: id, root_id } : id,
          );
        }
      }, 1000);
    }

    dispatch.ProductClassification.getClassificationAsync({
      has_transparency: 1,
    });
  });

  const handleCanvasClick = useCallback(() => {
    if (render?.currentTargetId) {
      const targetItem = render.getTargetById(render?.currentTargetId);

      setEditedItem(targetItem);
    } else {
      setEditedItem(undefined);
      setDisplayerHidden();
      setTextDisplayerHidden();
    }
  }, [render]);
  const [canvansSetting, setCanvansSetting] = useState<any>();

  const [deviceResolutionRatio, setDeviceResolutionRatio] = useState([
    {
      device: "苹果设备",
      ratio: "IPHONE",
      name: "iPhone 8",
      width: 375,
      height: 667,
    },
    {
      device: "苹果设备",
      ratio: "IPHONE",
      name: "iPhone 8 Plus",
      width: 414,
      height: 736,
    },
    {
      device: "苹果设备",
      ratio: "IPHONE",
      name: "iPhone SE",
      width: 320,
      height: 568,
    },
    {
      device: "苹果设备",
      ratio: "IPHONE",
      name: "iPhone 11",
      width: 414,
      height: 896,
    },
    {
      device: "苹果设备",
      ratio: "IPHONE",
      name: "iPhone 11 Pro",
      width: 375,
      height: 812,
    },
    {
      device: "苹果设备",
      ratio: "IPHONE",
      name: "iPhone 11 Pro Max",
      width: 414,
      height: 896,
    },
    {
      device: "苹果设备",
      ratio: "IPAD",
      name: "7.9 iPad mini",
      width: 768,
      height: 1024,
    },
    {
      device: "苹果设备",
      ratio: "IPAD",
      name: "10.2 iPad",
      width: 810,
      height: 1080,
    },
    {
      device: "苹果设备",
      ratio: "IPAD",
      name: "10.2 iPad Air",
      width: 834,
      height: 1112,
    },
    {
      device: "苹果设备",
      ratio: "IPAD",
      name: "11 iPad Pro",
      width: 834,
      height: 1194,
    },
    {
      device: "苹果设备",
      ratio: "IPAD",
      name: "12.9 iPad Pro",
      width: 1024,
      height: 1366,
    },
    {
      device: "安卓设备",
      ratio: "COMMON",
      name: "Galaxy S10e",
      width: 360,
      height: 760,
    },
    {
      device: "安卓设备",
      ratio: "COMMON",
      name: "Galaxy S10",
      width: 360,
      height: 760,
    },
    {
      device: "安卓设备",
      ratio: "COMMON",
      name: "Galaxy S10+",
      width: 360,
      height: 760,
    },
    {
      device: "响应设备",
      ratio: "MOBILE",
      name: "Mobile",
      width: 320,
      height: 1024,
    },
    {
      device: "响应设备",
      ratio: "TABLET",
      name: "Tablet",
      width: 768,
      height: 1024,
    },
    {
      device: "响应设备",
      ratio: "DESKTOP",
      name: "Desktop",
      width: 1024,
      height: 1024,
    },
    {
      device: "响应设备",
      ratio: "DESKTOP",
      name: "Desktop HD",
      width: 1024,
      height: 1440,
    },
    {
      device: "纸张大小",
      ratio: "",
      name: "A3",
      width: 842,
      height: 1191,
    },
    {
      device: "纸张大小",
      ratio: "",
      name: "A4",
      width: 595,
      height: 842,
    },
    {
      device: "自定义",
      ratio: "",
      name: "custom",
      width: 0,
      height: 0,
    },
  ]);

  const groupbyResolutions = groupBy(deviceResolutionRatio, "device");

  const [landscape, setLandscape] = useState("horizontal");

  const [currResolution, setCurrResolution] = useState(
    deviceResolutionRatio.find(rr => rr.name === "Desktop HD")!,
  );

  const handleChangeLandscape = (value: string) => {
    setLandscape(value);
    setDeviceResolutionRatio(rl =>
      rl.map(r => ({ ...r, width: r.height, height: r.width })),
    );
    setCurrResolution(r => ({ ...r, width: r.height, height: r.width }));
  };

  const [currDevice, setCurrDevice] = useState(currResolution?.device!);

  const canvasWidth = (currResolution.width * canvasScale) / 100,
    canvasHeight = (currResolution.height * canvasScale) / 100;

  const previousWidth = usePrevious(canvasWidth);

  const loadNewVar = useRef<boolean>();

  useUpdateEffect(() => {
    if (!loadNewVar.current) {
      render?.setResponsive(Number(previousWidth), canvasWidth);
      render?.restore();
    } else {
      loadNewVar.current = false;
    }
  }, [currResolution, canvasScale]);

  useUpdateEffect(() => {
    if (prevItem && editedItem) {
      render?.update(editedItem.id, { ...editedItem });
    }
  }, [editedItem, render, prevItem]);

  const defaultPropertyValues = {
    transparency: 100,
    blur: 0,
    brightness: 100,
    contrast: 100,
    grayscale: 0,
    saturation: 100,
    shadow: "0px 0px 0px #666666",
    hue: 0,
    invert: 0,
    degree: 0,
    verticalDegree: 0,
    horizontalDegree: 0,
  };

  const defaultTextPropertyValues = {
    value: "New Text",
    fontSize: 26,
    gradient: ["#666666"],
    fillStyle: "#666666",
    tagColor: "",
    backgroundColor: "",
  };

  const displayerProperties = [
    {
      title: "亮度",
      key: "brightness",
      max: 200,
    },
    {
      title: "对比度",
      key: "contrast",
      max: 300,
    },
    {
      title: "模糊",
      key: "blur",
      max: 50,
    },
    {
      title: "饱和度",
      key: "saturation",
      max: 300,
    },
    {
      title: "灰度",
      key: "grayscale",
      max: 100,
    },
    {
      title: "透明度",
      key: "transparency",
      max: 100,
    },
    {
      title: "反向",
      key: "invert",
      max: 100,
      min: -100,
    },
    {
      title: "色相",
      key: "hue",
      max: 100,
      slideClassName: "designer-gradient-slider",
    },
  ];

  const positionProperties = [
    {
      title: "中心点",
      key: "center",
    },
    {
      title: "宽度",
      key: "width",
    },
    {
      title: "高度",
      key: "height",
    },
    {
      title: "旋转",
      key: "degree",
    },
    {
      title: "垂直翻转",
      key: "verticalDegree",
    },
    {
      title: "水平翻转",
      key: "horizontalDegree",
    },
  ];

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      // console.log(result);

      // dropped nowhere
      if (!result.destination) {
        return;
      }

      const source: DraggableLocation = result.source;
      const destination: DraggableLocation = result.destination;

      // did not move anywhere - can bail early
      if (
        source.droppableId === destination.droppableId &&
        source.index === destination.index
      ) {
        return;
      }

      // reordering column
      if (result.type === "ITEM") {
        const [, groupIndex] = split(source.droppableId, "_");
        const [, destGroupIndex] = split(destination.droppableId, "_");

        render?.moveSetTargetId(
          result.draggableId,
          groupIndex,
          destGroupIndex,
          destination.index,
        );

        if (render?.sets[groupIndex].length === 0) {
          render.deleteSet(groupIndex);
        }

        setRenderItems([...render?.objects]);
        setGroupList([...render?.sets]);
      }

      // 可拖动组的index与sets的index相反
      if (result.type === "GROUP") {
        render?.moveSet(
          render?.sets?.length - 1 - source.index,
          render?.sets?.length - 1 - destination.index,
        );

        setGroupList([...render?.sets]);

        // setGroupList(items => reorder(items, source.index, destination.index));
      }
    },
    [renderItems],
  );

  const { Meta } = Card;

  const [showProductId, setShowProductId] = useState<number>();

  const handelChangeResolution = (resolution: DeviceResolution) => {
    setCurrResolution(resolution);
    setDeviceResolutionHidden();
  };

  const { data, loading, run: LoadEditor } = useRequest(() =>
    import("braft-editor"),
  );

  const { default: BraftEditor } = data || {};

  const editorRef = useRef<BraftEditor>(null);

  // useClickAway(e => {
  //   setEditorHidden();
  // }, document.querySelector(".designer-editor"));

  // useEventListener(
  //   "click",
  //   e => {
  //     setEditorShow();
  //   },
  //   document.querySelector(".designer-editor"),
  // );

  const handlePaste = (event: React.ClipboardEvent<HTMLElement>) => {
    console.log(event.clipboardData.items[1].getAsString(null));
  };

  const [editorState, setEditorState] = useState();

  const uploader = {
    key: "antd-uploader",
    type: "component",
    className: "editor-toolbar-uploader",
    component: (
      <Upload
        accept="image/*"
        showUploadList={false}
        customRequest={async param => {
          if (!param.file) {
            return false;
          }

          const base64 = await getBase64(param.file);

          dispatch.DesignIdea.uploadFileAsync({
            root_id,
            file: dataURItoBlob(base64),
            action: "design",
            base64,
            callback: (res: any) => {
              const content = ContentUtils.insertMedias(editorState, [
                {
                  type: "IMAGE",
                  url: isManagementEnd ? res.file : head(split(res.data, ",")),
                },
              ]);

              setEditorState(content);
              setEditorShow();
            },
          });
        }}
      >
        {/* 这里的按钮最好加上type="button"，以避免在表单容器中触发表单提交，用Antd的Button组件则无需如此 */}
        <button
          type="button"
          className="control-item button upload-button"
          data-title="插入图片"
        >
          <Icon type="iconsucai1" />
        </button>
      </Upload>
    ),
  };

  const editorControls = [
    "font-size",
    "headings",
    "letter-spacing",
    "line-height",
    "undo",
    "redo",

    "text-color",
    "bold",
    "italic",
    "underline",
    "remove-styles",
    "strike-through",

    "superscript",
    "subscript",

    "list-ol",
    "list-ul",

    "text-align",

    "text-indent",

    uploader,
    // "link",
    "hr",
    "blockquote",

    "code",
    "clear",
    "emoji",
    // "font-family",

    // "fullscreen",
    // "separator",

    // "table",未实现
  ] as BuiltInControlType[];

  const [
    editorControlVisable,
    {
      toggle: toggleEditorControl,
      setTrue: setEditorShow,
      setFalse: setEditorHidden,
    },
  ] = useBoolean(false);

  useUpdateEffect(() => {
    if (!isEmpty(currIdea) && action === "get") {
      const settings = JSON.parse(currIdea.canvas_data ?? "{}"),
        canvas = JSON.parse(currIdea.canvas_object ?? "{}");

      if (settings.resolution) {
        const selectedSolution = deviceResolutionRatio.find(
          dr => dr.name === settings.resolution,
        );
        setCurrResolution(res => ({
          ...(selectedSolution ?? res),
          width: currIdea.width,
          height: currIdea.height,
        }));

        setScale(settings.canvasScale);
      }

      setCanvansSetting(settings);

      if (!isEmpty(canvas)) {
        canvas.objects.forEach((item: RenderItem) => {
          if (
            item.type === ItemType.Image ||
            (item.type === ItemType.EmptyValue && item.value)
          ) {
            const objImage = new Image();
            objImage.src = item.value + "?" + new Date().getTime();
            objImage.crossOrigin = "";
            item.value = objImage;
            const co = currIdea.coordinates.find(
              c => c.coordinate_key === item.id,
            );
            item.additionalHash = {
              ...item.additionalHash,
              coordinate_id: co?.coordinate_id,
            };

            objImage.onload = () => {
              render?.restore();
            };
          }
        });
      }

      if (currIdea.background_picture) {
        const bgImg = new Image();
        bgImg.crossOrigin = "";
        bgImg.src = currIdea.background_picture + "?" + new Date().getTime();
        bgImg.onload = () => {
          render?.updateDataSet({ "background-img": bgImg });
          render?.restore();
        };
      }

      if (!isEmpty(canvas)) {
        render?.updateObjects(canvas?.objects, canvas?.sets);

        setRenderItems(canvas.objects);
        setGroupList(canvas.sets);
      } else {
        render?.updateObjects([], []);

        setRenderItems([]);
        setGroupList([]);
      }

      render?.updateDataSet({
        ...omit(
          settings,
          "rotate-operate-img",
          "vertical-operate-img",
          "horizontal-operate-img",
        ),
        "background-img": null,
      });

      // 加载新设计时不用setResponsive
      // render?.setResponsive(
      //   currIdea.width,
      //   canvasWidth > 0 ? canvasWidth : currIdea.width,
      // );
      loadNewVar.current = true;

      render?.restore();

      setEditorState(
        BraftEditor?.createEditorState(
          currIdea.design_idea_description?.replace(/\\\"/g, '"'),
        ),
      );

      history.pushState(
        currIdea.design_idea_id,
        "",
        currIdea.design_idea_id
          ? `/design-idea/edit/${currIdea.design_idea_id}?${
              host_id ? `host_id=${host_id}` : ""
            }`
          : `/design-idea/new`,
      );

      if (isManagementEnd) {
        window.parent.postMessage(
          { action: "pushEdit", pid: currIdea.design_idea_id },
          gaeaDomain,
        );
      }
    }
  }, [currIdea?.design_idea_id, render, action]);

  useImperativeHandle(ref, () => ({
    generatePicture: (data: any) => {
      const { format = "png", ...rest } = data;
      render?.updateDataSet({ ...rest });
      render?.restore();

      CanvasToImage.saveAsImage(
        canvasRef.current!,
        format,
        `${currIdea.design_idea_name}.${format}`,
        currResolution.width,
        currResolution.height,
      );
    },
    saveCanvas: async () => {
      render?.currentTargetId = null;

      render?.objects
        .filter(item => item.type === ItemType.EmptyValue)
        .forEach(item => render.update(item.id, { show: false }));

      setRenderItems(render?.objects);

      const base64 = CanvasToImage.toDataUrl(
        canvasRef.current!,
        "jpg",
        currResolution.width,
        currResolution.height,
      );

      let file;

      if (isManagementEnd) {
        file = await new Promise((resolve, eject) => {
          canvasRef.current?.toBlob(blob => resolve(blob));
        });
      }

      dispatch.DesignIdea.uploadFileAsync({
        action: "design",
        base64,
        root_id,
        file,
        callback: (res: any) => {
          const renderObjects = render?.objects.map(o => {
            let { value, show, type } = o;

            if (o.type === ItemType.Image) {
              value = o.additionalHash?.product_picture;
            } else if (o.type === ItemType.EmptyValue && value) {
              value = (o.value as HTMLImageElement).src;
            }

            return {
              ...o,
              value,
              show: type === ItemType.EmptyValue ? false : show,
            };
          });

          const renderSettings = {
            ...render?.dataSet,
            "background-img": render?.dataSet["background-img"]?.src,
            resolution: currResolution.name,
            canvasScale,
          };

          const wrapperItems = flatten(
            render?.sets.map((os, setIndex) =>
              os.map((s, itemIndex) => ({
                ...render.objects.find(o => o.id === s),
                setIndex,
                itemIndex,
              })),
            ),
          );

          const coordinates = wrapperItems
            .filter(o => o.type === ItemType.Image)
            .map(o => ({
              coordinate_id: o.additionalHash?.coordinate_id,
              coordinate_index: o.setIndex,
              coordinate_x: (o.center[0] / canvasWidth).toFixed(2),
              coordinate_y: (o.center[1] / canvasHeight).toFixed(2),
              coordinate_sort: o.itemIndex,
              coordinate_key: o.id,
              coordinate_type: 1,
              ...omit(o.additionalHash, "coordinate_id"),
            }));

          // 标签点逻辑：1个RenderItem对应多个单品
          let bgCoordinates: any[] = [];

          wrapperItems
            .filter(o => o.type === ItemType.EmptyValue && o.value)
            .forEach(o => {
              const nullTagItems = tagProducts[o.id];

              if (nullTagItems) {
                const mapedItems = nullTagItems.map((item, index) => ({
                  coordinate_index: o.setIndex,
                  coordinate_x: (o.center[0] / canvasWidth).toFixed(2),
                  coordinate_y: (o.center[1] / canvasHeight).toFixed(2),
                  coordinate_sort: index,
                  coordinate_key: o.id,
                  coordinate_type: 2,
                  shop_product_id: item.shop_product_id,
                  product_picture: item.product_picture,
                  coordinate_id: item.coordinate_id,
                }));

                bgCoordinates.splice(bgCoordinates.length, 0, ...mapedItems);
              }
            });

          const coordinatesList = coordinates.concat(...bgCoordinates),
            settingStrs = JSON.stringify(renderSettings),
            objectsStrs = JSON.stringify({
              objects: renderObjects,
              sets: render?.sets,
            }),
            editorStrs = editorState
              ? replace(editorState.toHTML(), / href="([^"]*)"/g, match => {
                  const href = match.substring(
                    match.indexOf('"') + 1,
                    match.lastIndexOf('"'),
                  );

                  if (
                    /^(http|https):\/\/[\w.]*(shumeipie|megahomeus|imegahome|megahomecn).com/.test(
                      href,
                    )
                  ) {
                    return match;
                  }

                  return "";
                })
              : "";

          dispatch.DesignIdea.submitIdeaAsync({
            action: "store",
            root_id,
            width: currResolution.width,
            height: currResolution.height,
            design_idea_id: currIdea.design_idea_id,
            design_idea_picture: isManagementEnd
              ? res.file
              : head(split(res.data, ",")),
            background_picture: head(
              split(renderSettings["background-img"], "?"),
            ),
            coordinates: isManagementEnd
              ? coordinatesList
              : JSON.stringify(coordinatesList).replace(/(\")/g, "\\$1"),
            canvas_data: isManagementEnd
              ? settingStrs
              : settingStrs.replace(/(\")/g, "\\$1"),
            canvas_object: isManagementEnd
              ? objectsStrs
              : objectsStrs.replace(/(\")/g, "\\$1"),
            design_idea_description: isManagementEnd
              ? editorStrs
              : editorStrs.replace(/(\")/g, "\\$1"),
            callback: () => {
              Message.success("保存画布成功！");
            },
          });
        },
      });
    },
  }));

  const baseTabs = [
    {
      key: 0,
      title: "单品",
      icon: "iconjiaju-fill",
    },
    {
      key: 1,
      title: "已上传",
      icon: "iconyishangchuan",
    },
    {
      key: 2,
      title: "素材",
      icon: "iconsucai1",
    },
    {
      key: 3,
      title: "设置",
      icon: "iconshezhi",
    },
  ];

  const tabs = isManagementEnd
    ? baseTabs
    : [
        ...baseTabs,
        {
          key: 4,
          title: "设计",
          icon: "iconwodesheji",
        },
      ];

  const [tabActiveIndex, setTabActiveIndex] = useControllableValue<number>(
    props,
    {
      valuePropName: "navActiveIndex",
    },
  );

  useEffect(() => {
    if (!classification || !classification.Categories) return;

    switch (tabActiveIndex) {
      case 0:
        setCurrCategory(classification.Categories);
        setMenuCategory(classification.Categories);
        setBreadcrumbs([]);

        classification.Categories.map(item => {
          handleGetCategoryPlane(item);
          setCategoryPlane(val => [...val, item]);
        });
        handleBreadcrumbClick(
          {
            category_id: 0,
            category_name: "顶级",
            children: undefined,
            level: 0,
          },
          0,
        );
        break;
      case 1:
        userUpdateRef.current?.initialize();
        break;
      case 2:
        systemMaterialRef.current?.initialize();
        break;
    }
  }, [tabActiveIndex]);

  const productListFilters = [
    {
      key: 0,
      value: "material",
      text: "材质",
      data: pickList?.Materials?.map(m => ({
        text: m.material_name,
        value: m.material_id,
      })),
    },
    {
      key: 1,
      value: "color",
      text: "颜色",
      data: pickList?.Color?.map(m => ({
        text: m.color_name,
        value: m.color_id,
      })),
    },
    {
      key: 2,
      value: "price",
      text: "价格",
      data: pickList?.ProductsPrice?.map(m => ({
        text: m.price_name,
        value: `${m.price_low}-${m.price_high}`,
      })),
    },
    {
      key: 3,
      value: "style",
      text: "风格",
      data: pickList?.Style?.map(m => ({
        text: m.style_name,
        value: m.style_id,
      })),
    },
    // {
    //   key: 4,
    //   value: "brand_id",
    //   text: "品牌",
    //   data: pickList?.Style.map(m => ({
    //     text: m.style_name,
    //     value: m.style_id,
    //   })),
    // },
  ];

  const handleProductFilter = (filterProp: string, filterValue: string) => {
    if (filterProp === "price") {
      if (!filterValue) {
        filterValue = `-`;
      }
      const [price_low, price_high] = filterValue.split("-") || "-";

      setPage(1);
      setFilters(f => ({ ...f, price_low, price_high }));

      return;
    }

    setPage(1);
    setFilters(f => ({ ...f, [filterProp]: filterValue }));
  };

  const arrayData = [
    {
      title: f({ id: "DoorModel" }),
      value: currIdea?.house_type_text,
    },
    {
      title: f({ id: "Room" }),
      value: currIdea?.room_text,
    },
    {
      title: f({ id: "Style" }),
      value: currIdea?.style_text,
    },
    {
      title: f({ id: "Color" }),
      value: currIdea?.color_text,
    },
  ];

  useMount(() => {
    LoadEditor();

    const handleScroll = () => setScroll(document.documentElement.scrollTop);

    document.addEventListener("scroll", handleScroll);

    return () => document.removeEventListener("scroll", handleScroll);
  });

  const getMenuList = (data: any) => {
    if (!data || data.length === 0) return;
    return data.map((item: any) => {
      if (item.children && item.children.length > 0) {
        return (
          <Menu.SubMenu
            key={item.category_id}
            title={
              <span
                onClick={() => {
                  getCategoryData(item);
                }}
                style={{
                  color: breadcrumbs.some(
                    z => z.category_id === item.category_id,
                  )
                    ? "white"
                    : "",
                }}
              >
                {item.category_name}
              </span>
            }
            style={{
              background: breadcrumbs.some(
                z => z.category_id === item.category_id,
              )
                ? "#4B27B6"
                : "",
            }}
          >
            {getMenuList(item.children)}
          </Menu.SubMenu>
        );
      } else {
        return (
          <Menu.Item
            key={item.category_id}
            onClick={() => {
              getCategoryData(item);
            }}
            style={{
              background: breadcrumbs.some(
                z => z.category_id === item.category_id,
              )
                ? "#4B27B6"
                : "",
              color: breadcrumbs.some(z => z.category_id === item.category_id)
                ? "white"
                : "",
            }}
          >
            {item.category_name}
          </Menu.Item>
        );
      }
    });
  };

  const [showMenu, setShowMenu] = useState(false);

  const getCategoryData = (data: any) => {
    const nowCategory = categoryPlane.find(
      (m: any) => m.category_id === data.category_id,
    );

    setSearch(item => [...unionBy(item, [data], "category_id")]);

    let nowData = nowCategory;
    const level = nowCategory.level;

    for (let a = level; a > 1; a--) {
      nowData = categoryPlane.find(
        (m: any) => m.level === a - 1 && m.category_id === nowData.pid,
      );

      getCategoryData(nowData);
    }
  };

  useUpdateEffect(() => {
    if (searchData.length === 0) return;
    const newData = searchData.reverse();
    setCurrCategory(newData[newData.length - 1].children);
    setBreadcrumbs([
      {
        category_id: 0,
        category_name: "顶级",
        level: 0,
        children: classification.Categories,
      },
      ...newData,
    ]);
    setFilters({});
    setPage(1);
    setOrderby("");
    setProductListShow();
    setSearch([]);
    setRoom("");
  }, [searchData]);

  const [siderCollapse, setSiderCollapse] = useState(false);

  const [selectedGroupIndex] = render?.getSetTargetId(editedItem?.id) || [];

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Row>
        <Col
          flex={siderCollapse ? "calc(100% - 50px)" : "66.7%"}
          style={{ overflow: "auto" }}
        >
          <Spin spinning={uploadFileLoading || submitIdeaLoading}>
            <div
              ref={designerContainerRef}
              className={styles["designer-container"]}
              {...dropProps}
            >
              <div
                ref={toolbarRef}
                className={styles["designer-toolbar-wrapper"]}
              >
                <div className={styles["designer-toolbar"]}>
                  <Tooltip title="复制" placement="bottom">
                    <Icon
                      type="iconfuzhi"
                      key="copy"
                      className={classNames({
                        [styles["designer-toolbar-disabled"]]: isEmpty(
                          editedItem,
                        ),
                      })}
                      onClick={() => {
                        render?.copySet(
                          render.getSetTargetId(editedItem?.id)[0],
                        );

                        if (editedItem?.additionalHash?.shop_product_id) {
                          dispatch.DesignIdea.setSelectedProducts([
                            ...selectedProducts,
                            selectedProducts.find(
                              p =>
                                Number(p.shop_product_id) ===
                                Number(
                                  editedItem?.additionalHash?.shop_product_id,
                                ),
                            )!,
                          ]);
                        }

                        setRenderItems(render?.objects);

                        setGroupList([...render?.sets]);
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="镜像" placement="bottom">
                    <Icon
                      type="iconjingxiang"
                      key=""
                      className={classNames({
                        [styles["designer-toolbar-disabled"]]: isEmpty(
                          editedItem,
                        ),
                      })}
                      onClick={() => {
                        render?.reverse(editedItem?.id);
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="文字" placement="bottom">
                    <Icon
                      type="iconwenzi"
                      className={classNames({
                        [styles["designer-toolbar-disabled"]]:
                          isEmpty(editedItem) ||
                          (editedItem?.type !== ItemType.Text &&
                            editedItem?.type !== ItemType.Tag &&
                            editedItem?.type !== ItemType.ItemScaleX &&
                            editedItem?.type !== ItemType.ItemScaleY),
                      })}
                      onClick={() => toggleTextDipslayer()}
                    />
                  </Tooltip>
                  <Tooltip title="滤镜" placement="bottom">
                    <Icon
                      type="icontiaozheng"
                      className={classNames({
                        [styles["designer-toolbar-disabled"]]: isEmpty(
                          editedItem,
                        ),
                      })}
                      onClick={() => toggleDipslayer()}
                    />
                  </Tooltip>
                  <Tooltip title="锁定" placement="bottom">
                    <Icon
                      type={!editedItem?.isEditable ? "iconsuo1" : "iconsuo"}
                      className={classNames({
                        [styles["designer-toolbar-disabled"]]: isEmpty(
                          editedItem,
                        ),
                      })}
                      onClick={() => {
                        render?.update(editedItem?.id, {
                          isEditable: !editedItem?.isEditable,
                        });

                        setRenderItems([...render?.objects]);
                      }}
                    />
                  </Tooltip>
                  <Divider type="vertical" />
                  <Tooltip title="上移" placement="bottom">
                    <Icon
                      type="iconshangyituceng"
                      className={classNames({
                        [styles["designer-toolbar-disabled"]]: isEmpty(
                          editedItem,
                        ),
                      })}
                      onClick={() => {
                        const [groupIndex] = render.getSetTargetId(
                          editedItem?.id,
                        );

                        render?.swapSet(
                          groupIndex,
                          groupIndex + 1 < groupList.length
                            ? groupIndex + 1
                            : groupList.length - 1,
                        );

                        render?.restore(null, true);

                        setGroupList([...render?.sets]);
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="下移" placement="bottom">
                    <Icon
                      type="iconxiayituceng"
                      className={classNames({
                        [styles["designer-toolbar-disabled"]]: isEmpty(
                          editedItem,
                        ),
                      })}
                      onClick={() => {
                        const [groupIndex] = render.getSetTargetId(
                          editedItem?.id,
                        );

                        render?.swapSet(
                          groupIndex,
                          groupIndex - 1 < 0 ? 0 : groupIndex - 1,
                        );

                        render?.restore(null, true);

                        setGroupList([...render?.sets]);
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="置顶" placement="bottom">
                    <Icon
                      type="icontucengzhiding"
                      className={classNames({
                        [styles["designer-toolbar-disabled"]]: isEmpty(
                          editedItem,
                        ),
                      })}
                      onClick={() => {
                        const [groupIndex] = render.getSetTargetId(
                          editedItem?.id,
                        );

                        render?.moveSetToTop(groupIndex);

                        render?.restore(null, true);

                        setGroupList([...render?.sets]);
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="置底" placement="bottom">
                    <Icon
                      type="icontucengzhidi"
                      className={classNames({
                        [styles["designer-toolbar-disabled"]]: isEmpty(
                          editedItem,
                        ),
                      })}
                      onClick={() => {
                        const [groupIndex] = render.getSetTargetId(
                          editedItem?.id,
                        );

                        render?.moveSetToBottom(groupIndex);

                        render?.restore(null, true);

                        setGroupList([...render?.sets]);
                      }}
                    />
                  </Tooltip>
                  <Divider type="vertical" />
                  {/*<Tooltip title="上一步" placement="bottom">
                  <Icon
                    type="iconfanhuishangyibu1"
                    className={classNames({
                      [styles["designer-toolbar-disabled"]]:
                        isEmpty(editedItem) || isEmpty(editedItem?.operations),
                    })}
                  />
                </Tooltip>
                <Tooltip title="下一步" placement="bottom">
                  <Icon
                    type="iconxiayibu-01"
                    className={classNames({
                      [styles["designer-toolbar-disabled"]]:
                        isEmpty(editedItem) || isEmpty(editedItem?.operations),
                    })}
                  />
                </Tooltip>
                 <Divider type="vertical" />*/}
                  <Tooltip title="新建组" placement="bottom">
                    <Icon
                      type="iconxinjianwenjianjia"
                      onClick={() => {
                        const newItem = render?.add(null, {
                          width: 1,
                          height: 1,
                          type: "emptyValue",
                          center: [
                            Math.floor(render?.canvas.width / 2),
                            Math.floor(render?.canvas.height / 2),
                          ],
                        });

                        render?.addToSet(newItem.id);

                        setRenderItems([...render?.objects]);

                        setGroupList([...render?.sets]);
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="新建项" placement="bottom">
                    <Icon
                      type="iconxinjiantuceng-01"
                      className={classNames({
                        [styles["designer-toolbar-disabled"]]: isEmpty(
                          editedItem,
                        ),
                      })}
                      onClick={() => {
                        const [groupIndex] = render?.getSetTargetId(
                          editedItem?.id,
                        );

                        const newItem = render?.add(null, {
                          width: 1,
                          height: 1,
                          type: "emptyValue",
                          center: [
                            Math.floor(render?.canvas.width / 2),
                            Math.floor(render?.canvas.height / 2),
                          ],
                        });

                        render?.addToSet(
                          newItem.id,
                          groupIndex,
                          groupList[groupIndex].length,
                        );

                        setRenderItems([...render?.objects]);

                        setGroupList([...render?.sets]);
                      }}
                    />
                  </Tooltip>
                  <Divider type="vertical" />
                  <Tooltip title="比例尺" placement="bottom">
                    <Icon
                      type="iconhengshubiaochi"
                      className={classNames({
                        [styles["designer-toolbar-disabled"]]: isEmpty(
                          editedItem,
                        ),
                      })}
                      onClick={() => {
                        const newItemX = render?.add("XXXcm", {
                          name: "X Scale",
                          type: "itemScaleX",
                          show: true,
                          bindingId: editedItem?.id,
                        });

                        const newItemY = render?.add("XXXcm", {
                          name: "Y Scale",
                          type: "itemScaleY",
                          show: true,
                          bindingId: editedItem?.id,
                        });

                        render?.addToSet(newItemX.id);

                        render?.addToSet(newItemY.id);

                        setRenderItems([...render?.objects]);

                        setGroupList([...render?.sets]);
                      }}
                    />
                  </Tooltip>
                  <Divider type="vertical" />
                  <Tooltip title="详情" placement="bottom">
                    <Icon
                      type="iconi1"
                      className={classNames({
                        [styles["designer-toolbar-disabled"]]:
                          isEmpty(editedItem) ||
                          !editedItem?.additionalHash?.shop_product_id,
                      })}
                      onClick={() => {
                        setShowProductId(
                          editedItem?.additionalHash.shop_product_id,
                        );
                        setProductDetailShow();
                      }}
                    />
                  </Tooltip>
                  <Divider type="vertical" />
                  <Tooltip title="删除" placement="bottom">
                    <Icon
                      type="iconshanchulajitong"
                      className={classNames({
                        [styles["designer-toolbar-disabled"]]: isEmpty(
                          editedItem,
                        ),
                      })}
                      onClick={handleDeleteItem}
                    />
                  </Tooltip>
                </div>
              </div>

              <div
                className={styles["designer-box"]}
                ref={designerBoxRef}
                // tabIndex={0}
                onKeyDown={e =>
                  e.keyCode >= 37 && e.keyCode <= 40 && e.preventDefault()
                }
              >
                <Spin spinning={canvasLoading}>
                  <div
                    ref={canvasWrapperRef}
                    className={styles["designer-canvas-wrapper"]}
                    onClick={() => setToolbarShow()}
                    style={{ display: showCanvas ? "" : "none" }}
                  >
                    <canvas
                      ref={canvasRef}
                      width={canvasWidth}
                      height={canvasHeight}
                      onClick={handleCanvasClick}
                      onDragOver={e => {
                        handleDragover({
                          clientX: e.clientX,
                          clientY: e.clientY,
                        } as React.DragEvent);
                      }}
                      id="designer-canvas"
                    ></canvas>
                  </div>
                </Spin>

                <div
                  className={styles["designer-dimension"]}
                  ref={dimensionBarRef}
                  {...customDragProps("dimension")}
                >
                  <Icon type="icont1_jian" onClick={() => decreaseScale(10)} />
                  <Input
                    value={canvasScale}
                    suffix="%"
                    onChange={e => setScale(Number(e.target.value))}
                    className="designer-scale-stepper"
                  />
                  <Icon type="icont1_jia" onClick={() => increaseScale(10)} />

                  <Divider type="vertical"></Divider>
                  <Icon type="iconshihepingmu" onClick={() => setScale(100)} />
                  <Dropdown
                    overlay={
                      <div className={styles["designer-resolution-container"]}>
                        <Card
                          headStyle={{
                            backgroundColor: "rgba(240, 240, 240, 1)",
                          }}
                          bodyStyle={{ padding: 0 }}
                          title={
                            <Space>
                              <Select
                                value={currDevice}
                                onChange={setCurrDevice}
                                style={{ width: "200px" }}
                                suffixIcon={<Icon type="icont1_xiangxia" />}
                              >
                                {Object.keys(groupbyResolutions).map(d => (
                                  <Select.Option value={d} key={d}>
                                    {d}
                                  </Select.Option>
                                ))}
                              </Select>
                              <Radio.Group
                                value={landscape}
                                buttonStyle="solid"
                                onChange={e =>
                                  handleChangeLandscape(e.target.value)
                                }
                                className="designer-radio-group"
                              >
                                <Radio.Button value="horizontal">
                                  <Icon
                                    type="iconshihepingmu"
                                    style={{ transform: "rotate(90deg)" }}
                                  ></Icon>
                                </Radio.Button>
                                <Radio.Button value="vertical">
                                  <Icon type="iconshihepingmu"></Icon>
                                </Radio.Button>
                              </Radio.Group>
                            </Space>
                          }
                        >
                          {Object.keys(
                            groupBy(groupbyResolutions[currDevice], "ratio"),
                          ).map(r => (
                            <List
                              key={r}
                              header={r}
                              className="designer-resolution-list"
                              dataSource={groupbyResolutions[currDevice].filter(
                                d => d.ratio === r,
                              )}
                              renderItem={item =>
                                item.device === "自定义" ? (
                                  <div
                                    className={
                                      styles["designer-resolution-custom"]
                                    }
                                  >
                                    <InputNumber
                                      placeholder="宽"
                                      style={{ width: "120px" }}
                                      defaultValue={currResolution.width}
                                      onBlur={e =>
                                        setCurrResolution(res => ({
                                          ...res,
                                          width: Number(e.target?.value),
                                        }))
                                      }
                                    ></InputNumber>
                                    <Text>*</Text>
                                    <InputNumber
                                      placeholder="高"
                                      style={{ width: "120px" }}
                                      defaultValue={currResolution.height}
                                      onBlur={e =>
                                        setCurrResolution(res => ({
                                          ...res,
                                          height: Number(e.target?.value),
                                        }))
                                      }
                                    ></InputNumber>
                                  </div>
                                ) : (
                                  <a
                                    onClick={() => handelChangeResolution(item)}
                                  >
                                    <List.Item
                                      key={item.name}
                                      className={classNames({
                                        "ant-list-item-selected":
                                          item.name === currResolution.name,
                                      })}
                                    >
                                      <Text>{item.name}</Text>
                                      <Text>{`${item.width}*${item.height}`}</Text>
                                    </List.Item>
                                  </a>
                                )
                              }
                            />
                          ))}
                        </Card>
                      </div>
                    }
                    placement="bottomRight"
                    trigger={["click"]}
                    visible={deviceResolutionVisable}
                    onVisibleChange={toggleDeviceResolution}
                  >
                    <Icon type="iconhuabu" />
                  </Dropdown>
                </div>

                <div
                  className={styles["designer-property-displayer"]}
                  ref={textDisplayerRef}
                  style={{
                    display:
                      textDisplayerVisiable &&
                      (editedItem?.type === ItemType.Text ||
                        editedItem?.type === ItemType.Tag ||
                        editedItem?.type === ItemType.ItemScaleX ||
                        editedItem?.type === ItemType.ItemScaleY)
                        ? ""
                        : "none",
                  }}
                  {...customDragProps("text")}
                >
                  <div className={styles["designer-property-displayer-title"]}>
                    {/* <Tooltip title="重置">
                    <Icon
                      type="iconfuwei"
                      disabled={!prevItem}
                      onClick={() =>
                        setEditedItem({
                          ...editedItem,
                          ...defaultTextPropertyValues,
                        })
                      }
                    />
                  </Tooltip> */}
                    <Icon
                      type="iconchazi"
                      onClick={() => setTextDisplayerHidden()}
                    />
                  </div>
                  {textDisplayerVisiable && editedItem && (
                    <div
                      onDragStart={e => (e.cancelable = true)}
                      onMouseDown={e =>
                        e.currentTarget.parentElement?.setAttribute(
                          "draggable",
                          false,
                        )
                      }
                      onMouseUp={e =>
                        e.currentTarget.parentElement?.setAttribute(
                          "draggable",
                          true,
                        )
                      }
                    >
                      <Row gutter={[10, 6]} justify="space-around">
                        <Col span={7}>文字内容</Col>
                        <Col span={17}>
                          <Input.TextArea
                            value={editedItem.value}
                            onChange={e =>
                              setEditedItem({
                                ...editedItem,
                                value: e.target.value,
                              })
                            }
                            placeholder="文字内容"
                            autoSize={{ minRows: 5, maxRows: 8 }}
                          />
                        </Col>
                      </Row>
                      <Row gutter={10} justify="space-around">
                        <Col span={7}>文字大小</Col>
                        <Col span={17}>
                          <Space size="small">
                            <InputNumber
                              value={editedItem.fontSize}
                              size="small"
                              onChange={val =>
                                setEditedItem({
                                  ...editedItem,
                                  fontSize: Number(val),
                                })
                              }
                            />
                            <Text>px</Text>
                          </Space>
                        </Col>
                      </Row>
                      <Row
                        gutter={10}
                        justify="space-around"
                        align="middle"
                        style={{
                          display:
                            editedItem.type === ItemType.Text ? "" : "none",
                          marginTop: "6px",
                        }}
                      >
                        <Col span={7}>文字颜色</Col>
                        <Col span={17}>
                          <ColorPicker
                            value={head(editedItem.gradient)}
                            onChange={value =>
                              setEditedItem({
                                ...editedItem,
                                gradient: [value],
                              })
                            }
                            placeholder="文字内容"
                            autoSize={{ minRows: 5, maxRows: 8 }}
                          />
                        </Col>
                      </Row>
                      <Row
                        gutter={10}
                        justify="space-around"
                        align="middle"
                        style={{
                          display:
                            editedItem.type === ItemType.ItemScaleX ||
                            editedItem.type === ItemType.ItemScaleY
                              ? ""
                              : "none",
                          marginTop: "5px",
                        }}
                      >
                        <Col span={7}>样式</Col>
                        <Col span={17}>
                          <ColorPicker
                            value={editedItem.fillStyle}
                            onChange={fillStyle =>
                              setEditedItem({
                                ...editedItem,
                                fillStyle,
                              })
                            }
                          />
                        </Col>
                      </Row>
                      <Row
                        gutter={10}
                        justify="space-around"
                        align="middle"
                        style={{
                          display:
                            editedItem.type === ItemType.Tag ? "" : "none",
                          marginTop: "6px",
                        }}
                      >
                        <Col span={7}>标签点颜色</Col>
                        <Col span={17}>
                          <ColorPicker
                            value={editedItem.tagColor}
                            onChange={tagColor =>
                              setEditedItem({
                                ...editedItem,
                                tagColor,
                              })
                            }
                          />
                        </Col>
                      </Row>
                      <Row
                        gutter={10}
                        justify="space-around"
                        align="middle"
                        style={{
                          display:
                            editedItem.type === ItemType.Tag ? "" : "none",
                          marginTop: "6px",
                        }}
                      >
                        <Col span={7}>标签背景</Col>
                        <Col span={17}>
                          <ColorPicker
                            value={editedItem.backgroundColor}
                            onChange={backgroundColor =>
                              setEditedItem({
                                ...editedItem,
                                backgroundColor,
                              })
                            }
                          />
                        </Col>
                      </Row>
                    </div>
                  )}
                </div>
                <div
                  className={styles["designer-property-displayer"]}
                  ref={propertyDisplayerRef}
                  style={{ display: displayerVisiable ? "" : "none" }}
                  {...customDragProps("property")}
                >
                  <div className={styles["designer-property-displayer-title"]}>
                    <Tooltip title="重置">
                      <Icon
                        type="iconfuwei"
                        disabled={!prevItem}
                        onClick={() =>
                          setEditedItem({
                            ...editedItem,
                            ...defaultPropertyValues,
                          })
                        }
                      />
                    </Tooltip>
                    <Icon
                      type="iconchazi"
                      onClick={() => setDisplayerHidden()}
                    />
                  </div>
                  {displayerVisiable && editedItem && (
                    <div
                      className={styles["table-like-container"]}
                      onMouseDown={e =>
                        e.currentTarget.parentElement?.setAttribute(
                          "draggable",
                          false,
                        )
                      }
                      onMouseUp={e =>
                        e.currentTarget.parentElement?.setAttribute(
                          "draggable",
                          true,
                        )
                      }
                    >
                      {displayerProperties.map(dp => (
                        <Row
                          gutter={10}
                          key={dp.key}
                          align="middle"
                          justify="space-around"
                        >
                          <Col span={6}>{dp.title}</Col>
                          <Col span={12}>
                            <Slider
                              min={dp.min || 0}
                              max={dp.max}
                              onChange={(val: number) =>
                                setEditedItem({
                                  ...editedItem,
                                  [dp.key]: val,
                                })
                              }
                              value={editedItem[dp.key]}
                              className={dp.slideClassName}
                            />
                          </Col>
                          <Col span={6}>
                            <InputNumber
                              value={editedItem[dp.key]}
                              size="small"
                              onChange={val =>
                                setEditedItem({
                                  ...editedItem,
                                  [dp.key]: val,
                                })
                              }
                              style={{ width: "100%" }}
                            />
                          </Col>
                        </Row>
                      ))}
                      <Row gutter={10} justify="space-around">
                        <Col span={6}>阴影</Col>
                        <Col span={18}>
                          <Row gutter={10}>
                            {initial(split(editedItem?.shadow, " ")).map(
                              (sp, index) => (
                                <Col span={8} key={index}>
                                  <Input
                                    value={trimEnd(sp, "px")}
                                    size="small"
                                    onChange={e =>
                                      setEditedItem({
                                        ...editedItem,
                                        shadow: replaceByItem(
                                          split(editedItem?.shadow, " "),
                                          `${e.target.value}px`,
                                          index,
                                        ).join(" "),
                                      })
                                    }
                                  />
                                </Col>
                              ),
                            )}
                          </Row>
                          <div style={{ marginTop: "5px" }}>
                            <ColorPicker
                              value={split(editedItem.shadow, " ")[3]}
                              onChange={value =>
                                setEditedItem({
                                  ...editedItem,
                                  shadow: replace(
                                    editedItem.shadow,
                                    /(#)[\w]*/,
                                    value,
                                  ),
                                })
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                      <Collapse
                        expandIconPosition="right"
                        className="designer-property-collapse"
                      >
                        <Collapse.Panel header="更多选项" key="1">
                          <div className="table-like-container">
                            {positionProperties.map(dp => (
                              <Row
                                gutter={[10, 5]}
                                key={dp.key}
                                align="middle"
                                justify="space-around"
                              >
                                <Col span={7}>{dp.title}</Col>
                                <Col span={17}>
                                  {isArray(editedItem[dp.key]) ? (
                                    <Row gutter={5}>
                                      {editedItem[dp.key].map((v, idx) => (
                                        <Col
                                          span={24 / editedItem[dp.key].length}
                                        >
                                          <Input
                                            value={v}
                                            size="small"
                                            onChange={e =>
                                              setEditedItem({
                                                ...editedItem,
                                                [dp.key]: replaceByItem(
                                                  editedItem[dp.key],
                                                  Number(e.target.value),
                                                  idx,
                                                ),
                                              })
                                            }
                                          />
                                        </Col>
                                      ))}
                                    </Row>
                                  ) : (
                                    <Input
                                      value={editedItem[dp.key]}
                                      size="small"
                                      onChange={e =>
                                        setEditedItem({
                                          ...editedItem,
                                          [dp.key]: e.target.value,
                                        })
                                      }
                                    />
                                  )}
                                </Col>
                              </Row>
                            ))}
                          </div>
                        </Collapse.Panel>
                      </Collapse>
                    </div>
                  )}
                </div>
                <div
                  ref={groupContainRef}
                  className={styles["designer-grouped-container"]}
                  {...customDragProps("group")}
                  style={{ display: editorControlVisable ? "none" : "" }}
                >
                  {groupList.length > 0 && (
                    <div className={styles["designer-grouped-dragger"]}>
                      <img src={groupTitleBar} />
                    </div>
                  )}

                  <Droppable
                    droppableId="board"
                    type="GROUP"
                    direction="vertical"
                  >
                    {(outerProvided: DroppableProvided) => (
                      <div
                        ref={outerProvided.innerRef}
                        {...outerProvided.droppableProps}
                      >
                        {groupList.reduce(
                          (prev, group, index, array) => [
                            <GroupList
                              dragIndex={array.length - 1 - index}
                              index={index}
                              group={group}
                              renderItems={renderItems}
                              setRenderItems={setRenderItems}
                              setGroupList={setGroupList}
                              setEditedItem={setEditedItem}
                              selected={selectedGroupIndex === index}
                              render={render}
                              key={index}
                            ></GroupList>,
                            ...prev,
                          ],
                          [] as React.ReactNode[],
                        )}
                        {outerProvided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>

                {showEditor && (
                  <div
                    className={styles["editor-container"]}
                    style={{ display: showEditor ? "" : "none" }}
                  >
                    <Divider className={styles["designer-divider"]} plain>
                      文本编辑
                    </Divider>

                    {currIdea && (
                      <div className={styles["designer-description"]}>
                        <Space direction="vertical">
                          <Text style={{ fontSize: "1.5em" }}>
                            {currIdea.design_idea_name}
                          </Text>
                          <Space>
                            <Avatar
                              src={currIdea.designer_avatar ?? dilogoImg}
                            />

                            <Text>
                              {currIdea.designer_name ?? "未命名设计师"}
                            </Text>
                          </Space>
                          <Paragraph>{currIdea.design_idea_slogan}</Paragraph>
                          <Space size="large">
                            <Space>
                              <Icon type="iconmianji"></Icon>
                              <Text>
                                {currIdea.house_area}
                                平方米
                              </Text>
                            </Space>
                            <Space>
                              <Icon type="icondizhi1"></Icon>
                              <Text>{`${currIdea.province ?? ""}${
                                currIdea.city ?? ""
                              }`}</Text>
                            </Space>
                            <Space>
                              <Icon type="iconhuafei"></Icon>
                              <Text>{currIdea.house_cost}</Text>
                            </Space>
                          </Space>
                        </Space>
                        <Divider></Divider>
                        <div className={styles["designer-description-table"]}>
                          {arrayData?.map((m: any, index: number) => (
                            <>
                              {index > 0 && (
                                <Divider
                                  type="vertical"
                                  style={{ color: "#dbdbdb" }}
                                ></Divider>
                              )}
                              <div className={"designer-description-tag"}>
                                <div className={"designer-description-title"}>
                                  {m.title}
                                </div>
                                <div>{m.value}</div>
                              </div>
                            </>
                          ))}
                        </div>
                        <Divider></Divider>
                      </div>
                    )}

                    {!loading && (
                      <BraftEditor
                        placeholder="开始文本编辑"
                        value={editorState}
                        onChange={setEditorState}
                        ref={editorRef}
                        contentStyle={{ height: "calc(100vh - 250px)" }}
                        controls={editorControls}
                        className="designer-editor"
                        controlBarStyle={{
                          display: editorControlVisable ? "" : "none",
                          top: editorControlVisable && scroll > 50 ? 0 : "",
                          width: siderCollapse ? "calc(100% - 50px)" : "",
                        }}
                        onFocus={setEditorShow}
                        onBlur={setEditorHidden}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </Spin>
        </Col>
        <Col
          flex={siderCollapse ? "50px" : "33.3%"}
          className={styles["materials-container"]}
        >
          <div className={styles["materials-inner"]}>
            <Tabs
              onChange={key => setTabActiveIndex(Number(key))}
              type="card"
              tabPosition="right"
              className={classNames("designer-nav-tabs", {
                "hide-nav-tabs": siderCollapse,
              })}
              activeKey={tabActiveIndex + ""}
              tabBarGutter={0}
              tabBarExtraContent={
                siderCollapse
                  ? {
                      left: (
                        <a
                          onClick={() => {
                            setSiderCollapse(collapse => !collapse);
                          }}
                        >
                          <Icon
                            type="iconshuangjiantou-you"
                            rotate={siderCollapse ? 180 : 0}
                          ></Icon>
                        </a>
                      ),
                    }
                  : null
              }
              renderTabBar={(
                props: any,
                DefaultTabBar: React.ComponentType,
              ) => (
                <>
                  <div
                    className={styles["materials-tabs-nav-slider"]}
                    style={{
                      display:
                        siderCollapse || tabActiveIndex >= baseTabs.length
                          ? "none"
                          : "",
                      transform: `translateY(${tabActiveIndex * 82}px)`,
                    }}
                  >
                    <div
                      className={styles["materials-tabs-nav-slider-top"]}
                      style={{ display: tabActiveIndex > 0 ? "" : "none" }}
                    >
                      <div
                        className={styles["materials-tabs-nav-slider-inner"]}
                      ></div>
                    </div>
                    <div className={styles["materials-tabs-nav-slider-bottom"]}>
                      <div
                        className={styles["materials-tabs-nav-slider-inner"]}
                      ></div>
                    </div>
                  </div>
                  <DefaultTabBar {...props} />
                </>
              )}
            >
              {tabs.map((t, index) => (
                <Tabs.TabPane
                  key={index}
                  tab={
                    <>
                      <Icon type={t.icon}></Icon>
                      <Text
                        color={tabActiveIndex === t.key ? "#fff" : "#cecece"}
                      >
                        {t.title}
                      </Text>
                    </>
                  }
                >
                  <div>
                    <a
                      onClick={() => {
                        setSiderCollapse(collapse => !collapse);
                      }}
                    >
                      <Icon
                        type="iconshuangjiantou-you"
                        rotate={siderCollapse ? 180 : 0}
                      ></Icon>
                    </a>
                  </div>

                  <div style={{ display: siderCollapse ? "none" : "" }}>
                    <Space
                      direction="vertical"
                      style={{
                        display: index === 0 ? "" : "none",
                        width: "100%",
                      }}
                    >
                      <Input.Group compact className="compact-input-group">
                        <Dropdown
                          visible={showMenu}
                          trigger={["click"]}
                          onVisibleChange={() => setShowMenu(vis => !vis)}
                          overlay={
                            <div className={styles["materials-inner-menu"]}>
                              <div style={{ backgroundColor: "#fff" }}>
                                <span
                                  style={{
                                    display: "inline-block",
                                    width: "50%",
                                    textAlign: "center",
                                    padding: "15px 0px",
                                    fontSize: "1.4em",
                                    cursor: "pointer",
                                    color:
                                      menuType === "category" ? "#4B27B6" : "",
                                  }}
                                  onMouseOver={() => setMenuType("category")}
                                >
                                  <Icon type="iconchanpinlei"></Icon>
                                  品类
                                </span>
                                <span
                                  onMouseOver={() => setMenuType("room")}
                                  style={{
                                    display: "inline-block",
                                    width: "50%",
                                    textAlign: "center",
                                    padding: "15px 0",
                                    fontSize: "1.4em",
                                    cursor: "pointer",
                                    color: menuType === "room" ? "#4B27B6" : "",
                                  }}
                                >
                                  <Icon type="iconfangjian1"></Icon>
                                  房间
                                </span>
                              </div>
                              {menuType === "category" && (
                                <Menu style={{ width: 180 }} mode="vertical">
                                  {menuCategory?.map(item =>
                                    item.children?.length > 0 ? (
                                      <Menu.SubMenu
                                        className={
                                          styles["materials-container-menu"]
                                        }
                                        style={{
                                          background: breadcrumbs.some(
                                            z =>
                                              z.category_id ===
                                              item.category_id,
                                          )
                                            ? "#4B27B6"
                                            : "",
                                        }}
                                        key={item.category_id}
                                        title={
                                          <span
                                            onClick={() => {
                                              getCategoryData(item);
                                            }}
                                            style={{
                                              color: breadcrumbs.some(
                                                z =>
                                                  z.category_id ===
                                                  item.category_id,
                                              )
                                                ? "white"
                                                : "",
                                            }}
                                          >
                                            {item.category_name}
                                          </span>
                                        }
                                      >
                                        {getMenuList(item.children)}
                                      </Menu.SubMenu>
                                    ) : (
                                      <Menu.Item
                                        key={item.category_id}
                                        onClick={() => {
                                          getCategoryData(item);
                                        }}
                                        style={{
                                          background: breadcrumbs.some(
                                            z =>
                                              z.category_id ===
                                              item.category_id,
                                          )
                                            ? "#4B27B6"
                                            : "",
                                          color: breadcrumbs.some(
                                            z =>
                                              z.category_id ===
                                              item.category_id,
                                          )
                                            ? "white"
                                            : "",
                                        }}
                                      >
                                        {item.category_name}
                                      </Menu.Item>
                                    ),
                                  )}
                                </Menu>
                              )}
                              {menuType === "room" && (
                                <Menu style={{ width: 180 }} mode="vertical">
                                  {classification.Room.map(item => {
                                    return (
                                      <Menu.Item
                                        key={item.room_id}
                                        onClick={() => handleRoomClick(item)}
                                        style={{
                                          background:
                                            room === String(item.room_id)
                                              ? "#4B27B6"
                                              : "",
                                          textAlign: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            color:
                                              room === String(item.room_id)
                                                ? "white"
                                                : "",
                                          }}
                                        >
                                          {item.room_name}
                                        </span>
                                      </Menu.Item>
                                    );
                                  })}
                                </Menu>
                              )}
                            </div>
                          }
                        >
                          <PrimaryButton
                            bgColor={designPrimaryColor}
                            icon={<Icon type="iconfenlei_xuanzhong" />}
                          ></PrimaryButton>
                        </Dropdown>

                        {isManagementEnd && (
                          <Select
                            value={searchField}
                            onChange={val => setSearchField(val)}
                          >
                            <Select.Option value="product_id">
                              单品ID
                            </Select.Option>
                            <Select.Option value="product_name">
                              单品名称
                            </Select.Option>
                            <Select.Option value="supplier_product_code">
                              商品代码
                            </Select.Option>
                          </Select>
                        )}

                        <Input
                          placeholder={`请输入${
                            breadcrumbs?.length > 1
                              ? breadcrumbs[breadcrumbs.length - 1]
                                  .category_name
                              : ""
                          }关键词`}
                          onPressEnter={e =>
                            handleSearchProduct(e.target.value)
                          }
                          style={{
                            width: "calc(100% - 32px)",
                          }}
                        />
                      </Input.Group>

                      {breadcrumbs.length > 1 && (
                        <Breadcrumb
                          separator=">"
                          className="materials-breadcrumb"
                        >
                          {breadcrumbs.map((b, index, array) => (
                            <Breadcrumb.Item>
                              {index === array.length - 1 ? (
                                b.category_name
                              ) : (
                                <a
                                  onClick={() =>
                                    handleBreadcrumbClick(b, index)
                                  }
                                >
                                  {b.category_name}
                                </a>
                              )}
                            </Breadcrumb.Item>
                          ))}
                        </Breadcrumb>
                      )}

                      <div
                        className={styles["materials-container-scroller"]}
                        style={{
                          overflowY: isManagementEnd ? "hidden" : "auto",
                          maxHeight: isManagementEnd
                            ? `calc(100vh - 140px)`
                            : "",
                        }}
                      >
                        <Space
                          direction="vertical"
                          style={{
                            width: "100%",
                          }}
                        >
                          {!isManagementEnd && !keyword && !room && (
                            <Collapse
                              defaultActiveKey={["1", "2", "3"]}
                              expandIconPosition="right"
                              className="design-picklist-collapse"
                            >
                              <Collapse.Panel header="品类" key="1">
                                <List
                                  locale={{
                                    emptyText: (
                                      <Text color="#fff">无下级分类！</Text>
                                    ),
                                  }}
                                  grid={{ gutter: 10, column: 4 }}
                                  dataSource={currCategory}
                                  renderItem={item => (
                                    <List.Item>
                                      <a
                                        onClick={() =>
                                          handleCategoryClick(item)
                                        }
                                      >
                                        <Card
                                          bodyStyle={{ display: "none" }}
                                          cover={
                                            <RcImage
                                              height={1}
                                              width={1}
                                              src={item.category_picture}
                                              alt={item.category_name}
                                              imageStyle={{
                                                borderRadius: "5px",
                                              }}
                                            >
                                              <FloatLayer
                                                $position="bottom"
                                                className={styles["card-name"]}
                                              >
                                                <Text>
                                                  {item.category_name}
                                                </Text>
                                              </FloatLayer>
                                            </RcImage>
                                          }
                                        ></Card>
                                      </a>
                                    </List.Item>
                                  )}
                                ></List>
                              </Collapse.Panel>
                              <Collapse.Panel
                                header="房间"
                                key="2"
                                style={{
                                  display: productListVisiable ? "none" : "",
                                }}
                              >
                                <List
                                  locale={{
                                    emptyText: (
                                      <Text color="#fff">暂无数据！</Text>
                                    ),
                                  }}
                                  grid={{ gutter: 10, column: 4 }}
                                  dataSource={classification.Room}
                                  renderItem={item => (
                                    <List.Item>
                                      <a onClick={() => handleRoomClick(item)}>
                                        <Card
                                          bordered={false}
                                          bodyStyle={{ display: "none" }}
                                          onClick={() => handleRoomClick(item)}
                                          cover={
                                            <RcImage
                                              height={1}
                                              width={1}
                                              src={item.room_picture}
                                              alt={item.room_name}
                                              imageStyle={{
                                                borderRadius: "5px",
                                              }}
                                            >
                                              <FloatLayer
                                                $position="bottom"
                                                className={styles["card-name"]}
                                              >
                                                <Text>{item.room_name}</Text>
                                              </FloatLayer>
                                            </RcImage>
                                          }
                                        ></Card>
                                      </a>
                                    </List.Item>
                                  )}
                                />
                              </Collapse.Panel>
                              {/* <Collapse.Panel
                    header="品牌"
                    key="3"
                    style={{ display: productListVisiable ? "none" : "" }}
                  >
                    <Row gutter={5}></Row>
                  </Collapse.Panel> */}
                            </Collapse>
                          )}

                          {productListVisiable && !isManagementEnd && (
                            <div className={styles["design-product-filter"]}>
                              <Dropdown
                                trigger={["click"]}
                                overlay={
                                  <Menu
                                    onClick={e => {
                                      if (e.key === orderby) {
                                        setOrderby("");
                                      } else {
                                        setOrderby(e.key);
                                      }
                                    }}
                                  >
                                    <Menu.Item key="0">
                                      {orderby === "0" && (
                                        <Icon type="icont1_correct" />
                                      )}
                                      综合推荐
                                    </Menu.Item>
                                    <Menu.Item key="1">
                                      {orderby === "1" && (
                                        <Icon type="icont1_correct" />
                                      )}
                                      最新上架
                                    </Menu.Item>
                                  </Menu>
                                }
                              >
                                <TextButton>
                                  排序
                                  <Icon type="icont2_arrowDown" />
                                </TextButton>
                              </Dropdown>
                              {productListFilters.map(pf => (
                                <Dropdown
                                  key={pf.key}
                                  trigger={["click"]}
                                  overlay={
                                    <Menu
                                      onClick={e => {
                                        if (
                                          e.key === filters[pf.value] ||
                                          e.key ===
                                            `${filters.price_low}-${filters.price_high}`
                                        ) {
                                          handleProductFilter(pf.value, null);
                                        } else {
                                          handleProductFilter(pf.value, e.key);
                                        }
                                      }}
                                    >
                                      {pf.data?.map(d => (
                                        <Menu.Item key={d.value}>
                                          {Number(filters[pf.value]) ===
                                            Number(d.value) && (
                                            <Icon type="icont1_correct" />
                                          )}
                                          {pf.value === "price" &&
                                            `${filters.price_low}-${filters.price_high}` ===
                                              d.value && (
                                              <Icon type="icont1_correct" />
                                            )}
                                          {d.text}
                                        </Menu.Item>
                                      ))}
                                    </Menu>
                                  }
                                >
                                  <TextButton>
                                    {pf.text}
                                    <Icon type="icont2_arrowDown" />
                                  </TextButton>
                                </Dropdown>
                              ))}
                            </div>
                          )}

                          {(productListVisiable || isManagementEnd) && (
                            <div
                              style={{
                                maxHeight: `calc(100vh - ${
                                  isManagementEnd ? 140 : 180
                                }px)`,
                                overflow: "hidden auto",
                              }}
                            >
                              <InfiniteScroll
                                initialLoad={false}
                                pageStart={1}
                                loadMore={debounce(
                                  () => setPage(p => p + 1),
                                  1000,
                                )}
                                hasMore={!productListLoading && hasMoreProducts}
                                useWindow={false}
                              >
                                <List
                                  locale={{
                                    emptyText: (
                                      <Text color="#fff">
                                        暂无数据，请选择分类或输入搜索！
                                      </Text>
                                    ),
                                  }}
                                  grid={{ gutter: 10, column: 3 }}
                                  dataSource={skus}
                                  renderItem={(item, index) => (
                                    <List.Item key={index}>
                                      <ImageCard
                                        bordered={false}
                                        cover={
                                          <a
                                            onClick={() => {
                                              setShowProductId(
                                                item.shop_product_id,
                                              );
                                              setProductDetailShow();
                                            }}
                                            className={
                                              styles["product-list-item"]
                                            }
                                          >
                                            <RcImage
                                              height={1}
                                              width={1}
                                              src={item.product_picture}
                                              {...getDragProps({
                                                ...item,
                                              })}
                                            >
                                              <TextButton
                                                onClick={e => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                  addProductImageToCanvas(item);
                                                }}
                                                className={
                                                  styles["product-item-btn"]
                                                }
                                              >
                                                <Icon
                                                  type="icondaoru"
                                                  style={{
                                                    transform: "rotate(180deg)",
                                                  }}
                                                />
                                              </TextButton>
                                            </RcImage>
                                          </a>
                                        }
                                        bodyStyle={{ padding: "10px" }}
                                      >
                                        <Meta
                                          title={item.product_name}
                                          style={{
                                            marginBottom: item.shop_name
                                              ? ""
                                              : "8px",
                                          }}
                                          description={
                                            <>
                                              <Link
                                                target="_blank"
                                                href={item.shop_url}
                                              >
                                                {item.shop_name}
                                              </Link>
                                              <Text color={primaryColor}>
                                                {item.tag_price_value}
                                              </Text>
                                              <Text delete>
                                                {Number(item.original_price) > 0
                                                  ? item.original_price_value
                                                  : "-"}
                                              </Text>
                                            </>
                                          }
                                        ></Meta>
                                      </ImageCard>
                                    </List.Item>
                                  )}
                                >
                                  {productListLoading && hasMoreProducts && (
                                    <div
                                      className={
                                        styles["infinite-loading-container"]
                                      }
                                    >
                                      <Spin />
                                    </div>
                                  )}
                                </List>
                              </InfiniteScroll>
                            </div>
                          )}
                        </Space>
                      </div>
                    </Space>
                    <ProductDetail
                      visible={productDetailVisiable}
                      onCancel={() => setProductDetailHidden()}
                      productId={showProductId}
                      handleAddToCanvas={addProductImageToCanvas}
                      isManagementEnd={isManagementEnd}
                    ></ProductDetail>

                    <div
                      style={{
                        display: index === 1 ? "" : "none",
                        width: "100%",
                      }}
                    >
                      <UserUpdate
                        getDragProps={getDragProps}
                        handleAddToCanvas={addProductImageToCanvas}
                        ref={userUpdateRef}
                        isManagementEnd={isManagementEnd}
                      />
                    </div>

                    <div
                      style={{
                        display: index === 2 ? "" : "none",
                      }}
                    >
                      <SystemMaterial
                        ref={systemMaterialRef}
                        render={render}
                        getDragProps={getDragProps}
                        setRenderItems={setRenderItems}
                        setGroupList={setGroupList}
                        handleAddToCanvas={addProductImageToCanvas}
                      />
                    </div>

                    <div
                      style={{
                        display: index === 3 ? "" : "none",
                      }}
                    >
                      <DesignerSetting
                        settings={canvansSetting}
                        render={render}
                        setCurrResolution={setCurrResolution}
                      />
                    </div>

                    <div
                      style={{
                        display: index === 4 ? "" : "none",
                        width: "100%",
                      }}
                    >
                      <MyDesign />
                    </div>
                  </div>
                </Tabs.TabPane>
              ))}
            </Tabs>
          </div>
        </Col>
      </Row>
    </DragDropContext>
  );
};

export default forwardRef<Renderer, RenderProps>(Designer);
