import classNames from "classnames";
import produce from "immer";
import * as React from "react";
import { createPortal } from "react-dom";
import Image from "../image";
import "./index.scss";

export type ImageZoomProps = {
  imgSrc: string;
  largeImgSrc?: string;
  // zoom Image Box
  zoomImagePadding: number;
  zoomImageWindowWidth: number;
  zoomImageWindowHeight: number;
  showZoomWindowClass?: string; // showZoomWindow class name

  useBackgroundImage?: boolean;
};

export type ImageZoomState = {
  showHoverBox: boolean;
  showZoomWindow: boolean;
  hoverBox: HoverBox;
  zoomInImage: ZoomInImage;
  warpBoxWidth: number;
  warpBoxHeight: number;
  zoomImageWidth: number;
  zoomImageHeight: number;
};

type HoverBox = {
  height: number;
  left: number;
  top: number;
  width: number;
};

type ZoomInImage = {
  left: number;
  top: number;
};

class ImageZoom extends React.PureComponent<ImageZoomProps, ImageZoomState> {
  public static defaultProps = {
    zoomImageWindowWidth: 470,
    zoomImageWindowHeight: 470,
    zoomImagePadding: 20, // 默认间距20

    useBackgroundImage: true,
  };

  public state: ImageZoomState = {
    zoomImageWidth: 1600,
    zoomImageHeight: 1600,
    showHoverBox: false,
    showZoomWindow: false,
    hoverBox: {
      height: 0,
      left: 0,
      top: 0,
      width: 0,
    },
    zoomInImage: {
      left: 0,
      top: 0,
    },
    warpBoxWidth: 0,
    warpBoxHeight: 0,
  };

  private zoomBox?: HTMLElement | null;
  private imageBox?: HTMLElement | null;

  // private zoomWindowNode?: HTMLElement | null; // zoomWindow 的 ref 默认 500 * 500的div

  public componentDidMount() {
    this.onWindowResize();
    window.addEventListener("resize", this.onWindowResize);
  }

  public componentWillUnmount() {
    window.removeEventListener("reset", this.onWindowResize);
  }

  private onWindowResize = () => {
    if (this.imageBox) {
      this.setState({
        zoomImageHeight: this.imageBox.offsetHeight
          ? (this.imageBox.offsetHeight / this.imageBox.offsetWidth) *
            this.state.zoomImageWidth
          : 0,
      });
    }

    if (this.zoomBox) {
      const { width, height } = this.zoomBox.getBoundingClientRect();
      this.setState(state =>
        produce(state, draftState => {
          draftState.warpBoxWidth = width;
          draftState.warpBoxHeight = height;
        }),
      );
    }
  };

  private getHoverBoxWH = () => {
    if (this.imageBox) {
      // 这里判断获得以下宽度然后根据zooimagebox的宽高比计算一下即可
      const width =
        (this.imageBox.clientWidth * this.props.zoomImageWindowWidth) /
        this.state.zoomImageWidth;
      return {
        width,

        height:
          (this.props.zoomImageWindowWidth / this.props.zoomImageWindowHeight) *
          width,
      };
    }
    return {
      width: 0,
      height: 0,
    };
  };

  /**
   * 负责返回传入参数元素在浏览器中相对位置
   */
  private getOffset = (el?: HTMLElement | null) => {
    if (el) {
      return el.getBoundingClientRect();
    }
    return {
      left: 0,
      top: 0,
      right: 0,
      height: 0,
      width: 0,
    };
  };

  private getHoverBoxPox = (event: React.MouseEvent<HTMLElement>) => {
    let x = 0,
      y = 0;
    const offset = this.getOffset(this.imageBox);
    // 此处获取到鼠标在div中的相对位置
    x = event.pageX - offset.left;
    y = event.pageY - offset.top;
    const { width, height } = this.getHoverBoxWH();
    let HoverBoxX = x - this.state.hoverBox.width / 2 - window.pageXOffset;
    let HoverBoxY = y - this.state.hoverBox.height / 2 - window.pageYOffset;
    if (
      this.imageBox &&
      HoverBoxX > this.imageBox.clientWidth - this.state.hoverBox.width
    ) {
      HoverBoxX = this.imageBox.clientWidth - this.state.hoverBox.width;
    }
    if (HoverBoxX < 0) {
      HoverBoxX = 0;
    }
    if (
      this.imageBox &&
      HoverBoxY > this.imageBox.clientHeight - this.state.hoverBox.height
    ) {
      HoverBoxY = this.imageBox.clientHeight - this.state.hoverBox.height;
    }
    if (HoverBoxY < 0) {
      HoverBoxY = 0;
    }
    return {
      HoverBoxX,
      HoverBoxY,
      width,
      height,
    };
  };

  private getZoomInImagePos = (event: React.MouseEvent<HTMLElement>) => {
    const offset = this.getOffset(this.imageBox);
    // 此处获取到鼠标在div中的相对位置
    const x = event.pageX - offset.left + window.pageXOffset;
    const y = event.pageY - offset.top - window.pageYOffset;
    const { width, height } = offset;
    // console.log(x, y)
    let left =
      -((x / width) * this.state.zoomImageWidth) +
      this.props.zoomImageWindowWidth / 2;
    let top =
      -((y / height) * this.state.zoomImageHeight) +
      this.props.zoomImageWindowHeight / 2;
    if (left > 0) {
      left = 0;
    }
    if (left < -(this.state.zoomImageWidth - this.props.zoomImageWindowWidth)) {
      left = -(this.state.zoomImageWidth - this.props.zoomImageWindowWidth);
    }
    if (top > 0) {
      top = 0;
    }
    if (
      top < -(this.state.zoomImageHeight - this.props.zoomImageWindowHeight)
    ) {
      top = -(this.state.zoomImageHeight - this.props.zoomImageWindowHeight);
    }

    return {
      left,
      top,
    };
  };

  /**
   * zoom 函数负责修改 zoom 鼠标指示框和放大后的组件位置
   */
  private zoom = (e: React.MouseEvent<HTMLElement>) => {
    const hoverBox = this.getHoverBoxPox(e);
    const zoom = this.getZoomInImagePos(e);
    this.setState(state =>
      produce(state, draftState => {
        draftState.showHoverBox = true;
        draftState.showZoomWindow = true;
        draftState.hoverBox.left = hoverBox.HoverBoxX;
        draftState.hoverBox.top = hoverBox.HoverBoxY;
        draftState.hoverBox.width = hoverBox.width;
        draftState.hoverBox.height = hoverBox.height;
        draftState.zoomInImage.left = zoom.left;
        draftState.zoomInImage.top = zoom.top;
      }),
    );
  };

  private deactivate = () => {
    this.setState(state =>
      produce(state, draftState => {
        draftState.showHoverBox = false;
        draftState.showZoomWindow = false;
      }),
    );
  };

  private countImageStyle = () => {
    const { warpBoxHeight, warpBoxWidth } = this.state;
    if (this.imageBox && warpBoxHeight && warpBoxWidth) {
      const { width, height } = this.getOffset(this.imageBox);

      if (width > height) {
        // 图片宽比高大那么是长方形的
        return { height: "auto", width: warpBoxWidth };
      } else {
        return { height: warpBoxHeight, width: "auto" };
      }
    }
    return {};
  };

  public render() {
    const {
      showZoomWindowClass,
      zoomImageWindowWidth,
      zoomImageWindowHeight,
      largeImgSrc,
      imgSrc,
      useBackgroundImage,
    } = this.props;
    const {
      showZoomWindow,
      zoomInImage,
      hoverBox,
      zoomImageWidth,
      zoomImageHeight,
    } = this.state;
    // const imgStyles = this.countImageStyle();
    return (
      <div
        className="ImageZoom-flex ImageZoom"
        ref={node => (this.zoomBox = node)}
      >
        {showZoomWindow &&
          this.zoomBox &&
          createPortal(
            <div
              // ref={node => (this.zoomWindowNode = node)}
              className={classNames(
                "ImageZoom-showZoomWindow",
                showZoomWindowClass,
              )}
              style={{
                width: zoomImageWindowWidth,
                height: zoomImageWindowHeight,
              }}
            >
              <div
                style={{
                  width: zoomImageWidth,
                  height: zoomImageHeight,
                  position: "absolute",
                  top: zoomInImage.top,
                  left: zoomInImage.left,
                }}
              >
                <Image
                  src={largeImgSrc || imgSrc}
                  width={zoomImageWidth}
                  height={zoomImageHeight}
                />
              </div>
            </div>,
            document.body,
            imgSrc,
          )}
        <div
          onMouseMove={this.zoom}
          onMouseLeave={this.deactivate}
          ref={node => (this.imageBox = node)}
          style={{
            position: "relative",
            maxWidth: "100%",
            maxHeight: "100%",
            backgroundImage: useBackgroundImage ? `url(${imgSrc})` : "",
          }}
          className="ImageZoom-wrapper"
        >
          {this.state.showHoverBox && (
            <div
              className="ImageZoom-hoverZoon"
              style={{
                left: hoverBox.left,
                top: hoverBox.top,
                width: hoverBox.width,
                height: hoverBox.height,
              }}
            />
          )}
          {!useBackgroundImage && (
            <img
              style={{ width: "100%", height: "100%" }}
              src={imgSrc}
              onLoad={this.onWindowResize}
            />
          )}
        </div>
      </div>
    );
  }
}

export default ImageZoom;
