import styled from "styled-components";
import { Button } from "antd";

// 网速较慢、服务器端渲染时保证样式没有闪变的情况将样式写成内联样式

/**
 * 默认按钮：用于没有主次之分的一组行动点
 */
const StyledButton = styled(Button).attrs(props => ({
  style: {
    color: props.color,
    borderColor: "currentColor",
    ...props.style,
  },
}))<{ color?: string }>``;
export default StyledButton;

/**
 * 主按钮，用于主行动点
 */
const PrimaryButton = styled(Button)
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
      !["bgColor"].includes(prop),
  })
  .attrs(props => ({
    type: "primary",
    style: { backgroundColor: props.bgColor, borderWidth: "0", ...props.style },
  }))<{
  bgColor?: string;
}>``;

/**
 * 文本按钮：用于最次级的行动点
 */
const TextButton = styled(Button).attrs(props => ({
  type: "text",
  style: { color: props.color ?? props.theme.textColor, ...props.style },
}))<{
  disabledColor?: string;
}>`
  &[disabled] {
    color: ${props => props.disabledColor} !important;

    &:hover {
      color: ${props => props.disabledColor};
    }
  }
`;

/**
 * 链接按钮：用于作为外链的行动点
 */
const LinkButton = styled(Button).attrs(props => ({
  type: "link",
  style: { color: props.color ?? props.theme.linkColor, ...props.style },
}))``;

export { PrimaryButton, TextButton, LinkButton };
