import React, { useContext, useState } from "react";
import classnames from "classnames";
import { RootState } from "../../../store/configureStore";
import { useSelector } from "react-redux";
import styles from "../index.scss";
import { useIntl } from "react-intl";
import { BusinessContext } from "../../BusinessContext";
import { Dropdown } from "antd";
import { useTheme } from "styled-components";
import NavigationMaxkBox from "./NavigationMaskBox";
import replaceState from "../../../helpers/reolaceState";
import { Navigation } from "../../../models/Navigation";
interface NavigationComponentProps {
  name: string;
  link: string;

  isShowComingSoon?: boolean;

  list: Navigation[];
}

const NavigationComponent: React.FC<NavigationComponentProps> = props => {
  const { bussinessPrimaryColor = "", primaryColor } = useTheme();
  const { isToBusiness } = useContext(BusinessContext);
  const [checkedId, setCheckedId] = useState(0);
  const [overId, setOverId] = useState<number>(-1);

  const { isShowComingSoon = false } = props;

  const { formatMessage: f } = useIntl();

  const menuOverlay = (
    <div className={styles["Header-navigation-overlay"]}>
      <div className={styles["Header-navigation-overlay-dropdown"]}>
        {isShowComingSoon && (
          <div className={styles["Header-navigation-construction"]}>
            * {f({ id: "ComingSoon" })}
          </div>
        )}
        <div style={{ display: "flex" }}>
          <div className={styles["Header-navigation-overlay-dropdown-content"]}>
            {props.list?.map(
              (
                item: { link: string; title: React.ReactNode },
                index: number,
              ) => {
                return (
                  <div
                    className={styles["Header-subnav"]}
                    style={{
                      background: index === checkedId ? "white" : "",
                      color: index === checkedId ? primaryColor : "#4B4B4B",
                      cursor: index === checkedId ? "pointer" : "",
                    }}
                    onMouseOver={() => setCheckedId(index)}
                    onClick={() => replaceState.linkTo(item.link)}
                  >
                    <div
                      className={styles["Header-subnav__navtag"]}
                      style={{
                        background: primaryColor,
                        display: index === checkedId ? "block" : "none",
                      }}
                    ></div>
                    {item.title}
                  </div>
                );
              },
            )}
          </div>
          <div className={styles["Header-navigation-navcontent__navspan"]}>
            {props.list[checkedId]?.child.map(
              (item: { title: string; link: string }, index: number) => {
                return (
                  <span
                    className={styles["Header-navigation-navcontent__navspan"]}
                    style={{
                      color: overId === index ? primaryColor : "",
                    }}
                    onClick={() => replaceState.linkTo(item.link)}
                    onMouseOver={() => setOverId(index)}
                    onMouseLeave={() => setOverId(-1)}
                  >
                    {item.title}
                  </span>
                );
              },
            )}
          </div>
        </div>
      </div>
    </div>
  );

  const [showMenus, setShowMenus] = useState(false);

  return (
    <>
      <li className={styles["Header-navigation-item"]}>
        <Dropdown
          overlay={menuOverlay}
          overlayClassName="fixed-position-popup"
          onVisibleChange={() => setShowMenus(vis => !vis)}
          visible={showMenus}
          className="layout-navigation-dropdown"
        >
          <a
            className={classnames([styles["Header-navigation-item-linke"]])}
            style={{ color: isToBusiness ? bussinessPrimaryColor : "" }}
          >
            <p>{props.name}</p>
          </a>
        </Dropdown>
      </li>
      <NavigationMaxkBox isOpen={showMenus}>
        <div className={styles["Header-navigation-mask"]} />
      </NavigationMaxkBox>
    </>
  );
};

export default React.memo(NavigationComponent);
