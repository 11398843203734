import React from "react";
import styled from "styled-components";
import { Drawer } from "antd";
import Icon from "../../components/icon";

const DrawerStyled = styled(Drawer).attrs(props => ({
  closeIcon: (
    <Icon
      type="iconyou"
      style={{
        color: props.color ?? props.theme.primaryColor,
      }}
    />
  ),
}))<{ color?: string; bgColor?: string }>`
  .ant-drawer-body {
    padding: 0;
    background-color: ${props => props.bgColor ?? "#f2f2f2"};
  }

  .ant-drawer-title {
    text-align: center;
  }

  .ant-drawer-close {
    padding: 12px;
    font-size: 1.8rem;
    left: 0;
    right: auto;
  }
`;

export default DrawerStyled;
