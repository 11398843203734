import { GraphqlField, GraphqlRefField } from "../helpers/decorators";
import BaseModel from "./BaseModel";

export class Media extends BaseModel {
  public sort_number: number = 0;
  @GraphqlField()
  public review_media_id: number = 0;
  @GraphqlField()
  public media_url: string = "";
}

export class ProductReviewStatistic extends BaseModel {
  public shop_product_id: number = 0;
  @GraphqlField()
  public review_rating: number = 0;
  @GraphqlField()
  public one_star: number = 0;
  @GraphqlField()
  public two_star: number = 0;
  @GraphqlField()
  public three_star: number = 0;
  @GraphqlField()
  public four_star: number = 0;
  @GraphqlField()
  public five_star: number = 0;
  @GraphqlField()
  public review_total: number = 0;

  get scores() {
    return [
      this.five_star,
      this.four_star,
      this.three_star,
      this.two_star,
      this.one_star,
    ];
  }

  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}

export class ProductOrder extends BaseModel {
  public shop_product_id: number = 0;
  @GraphqlField()
  public order_id: number = 0;
  @GraphqlField()
  public order_number: string = "";
  @GraphqlField()
  public created_at: string = "";
  @GraphqlField()
  public product_picture: string = "";
  @GraphqlField()
  public order_product_id: number = 0;
  @GraphqlField()
  public quantity: number = 0;

  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}

export default class ProductReview extends BaseModel {
  @GraphqlField()
  public review_id: number = 0;

  @GraphqlField()
  public review_rating: number = 0;
  @GraphqlField()
  public review_comment: string = "";
  @GraphqlField()
  public review_time: string = "";
  @GraphqlField()
  public customer_name: string = "";

  @GraphqlField()
  public customer_avatar: string = "";
  @GraphqlRefField(Media)
  public medias: Media[] = [];

  /**
   *
   */
  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}
