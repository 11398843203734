import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, Dispatch } from "../../../store/configureStore";
import * as styles from "./index.scss";
import { useIntl, FormattedMessage } from "react-intl";
import { Row, Col, message, Tag, Affix, Card } from "antd";
import Icon from "../../../components/icon";
import Rate from "../../../wrappers/rate";
import { PrimaryButton } from "../../../wrappers/button";
import { useTheme } from "styled-components";

const ShareDown = (props: {}) => {
  const dispatch = useDispatch<Dispatch>();
  const { formatMessage: f } = useIntl();

  const { shareData, accountName = "" } = useSelector(
    (state: RootState) => state.ShareCart,
  );

  const address = shareData?.length > 0 ? shareData[0].address : null;
  const show_agent_income =
    shareData?.length > 0 ? shareData[0].show_agent_income : false;

  useEffect(() => {
    if (!shareData) {
      const token = location.pathname.split("/");
      dispatch.ShareCart.getShareData({ token: token[token.length - 1] });
    }
  }, []);
  const handleAddtoCart = () => {
    let ids = "",
      quantity = "";
    shareData.map(item => {
      item.product.map((m: any) => {
        ids = ids + m.shop_product_id + "#";
        quantity = quantity + m.quantity + "#";
      });
    });
    const data = {
      shop_product_id: ids,
      quantity: quantity,
      agent_code: shareData[0].share_customer_agent_code,
      callback: (res: any) => {
        message.success(res.message);
        setTimeout(() => (location.href = "/tob/cart"), 2000);
      },
    };
    dispatch.ShareCart.addToCartAsync(data);
  };
  const handleGetQuantity = () => {
    if (!shareData) return;
    let num = 0;

    shareData.map(item => {
      item.product.map(m => {
        num = num + parseFloat(m.quantity);
      });
    });
    return num;
  };
  const handleGetPrice = () => {
    if (!shareData) return;
    let price = 0,
      priceLow = 0,
      pricehigh = 0,
      isRange = false;

    shareData.map(item => {
      item.product.map(m => {
        if (m.has_tag_price_range === 1) {
          isRange = true;
        }
      });
    });

    if (isRange) {
      shareData.map(item => {
        item.product.map(m => {
          if (m.has_tag_price_range === 1) {
            priceLow =
              priceLow + parseFloat(m.tag_price_low) * parseFloat(m.quantity);
            pricehigh =
              pricehigh + parseFloat(m.tag_price_high) * parseFloat(m.quantity);
          } else {
            priceLow =
              priceLow + parseFloat(m.tag_price) * parseFloat(m.quantity);
            pricehigh =
              pricehigh + parseFloat(m.tag_price) * parseFloat(m.quantity);
          }
        });
      });
    } else {
      shareData.map(item => {
        item.product.map(m => {
          price = price + parseFloat(m.tag_price) * parseFloat(m.quantity);
        });
      });
    }

    return isRange
      ? priceLow > 0 &&
          pricehigh > 0 &&
          `¥${priceLow.toFixed(2)} - ¥${pricehigh.toFixed(2)}`
      : price > 0 && "¥" + price.toFixed(2);
  };

  const { bussinessPrimaryColor } = useTheme();

  return (
    <div className={styles["ShareCart-box"]}>
      <Row gutter={10}>
        <Col span={16}>
          <div
            style={{
              padding: "10px",
              background: "white",
              borderRadius: "5px",
            }}
          >
            {shareData &&
              shareData.map((item: any, index: number) => (
                <Card
                  style={{ marginBottom: "10px" }}
                  title={
                    <Row gutter={10}>
                      <Col
                        span={12}
                        style={{
                          color: bussinessPrimaryColor,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          (location.href = `/tob/${item.shop_url}`)
                        }
                      >
                        <Icon
                          type="icondianpu2"
                          style={{
                            marginRight: "5px",
                          }}
                        ></Icon>
                        {item.shop_name}
                      </Col>
                    </Row>
                  }
                >
                  {item.product.map((m: Product, mIndex: number) => (
                    <Row
                      align={"middle"}
                      style={{
                        padding: "15px",
                        borderBottom:
                          item.product.length - 1 === mIndex
                            ? ""
                            : "1px solid #bdbcbc",
                      }}
                    >
                      <Col
                        span={24}
                        className={styles["agent-box"]}
                        style={{ paddingTop: mIndex === 0 ? 0 : "" }}
                      >
                        {Boolean(show_agent_income) && (
                          <>
                            <FormattedMessage id="ExpectedIncome" />：
                            <span style={{ color: bussinessPrimaryColor }}>
                              {m.agent_income}
                            </span>
                          </>
                        )}
                      </Col>
                      <Col span={5}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {m.url && m.url !== "" ? (
                            <a href={m.url}>
                              <img
                                src={m.product_picture}
                                style={{ width: "80%" }}
                              />
                            </a>
                          ) : (
                            <img
                              src={m.product_picture}
                              style={{ width: "80%" }}
                            />
                          )}
                        </div>
                      </Col>
                      {/* style={{ height: "170px" }} */}
                      <Col span={7}>
                        <p>
                          <span style={{ marginRight: 10, fontSize: 15 }}>
                            {m.product_name}
                          </span>
                          {m.customer_rating > 0 && (
                            <Rate
                              value={(m.customer_rating * 5) / 100}
                              disabled
                            />
                          )}
                        </p>
                        <p className={styles["table-MHS"]}>
                          {m.brand_name !== "" && (
                            <Tag color={bussinessPrimaryColor}>
                              {m.brand_name}
                            </Tag>
                          )}
                          {m.series_name !== "" && (
                            <Tag color={bussinessPrimaryColor}>
                              {m.series_name}
                            </Tag>
                          )}
                        </p>
                      </Col>

                      <Col span={4}>
                        {m.attribute &&
                          m.attribute.split(",").map((z: string) => {
                            return (
                              <>
                                <Tag
                                  color={bussinessPrimaryColor}
                                  style={{ marginBottom: "5px" }}
                                >
                                  {z}
                                </Tag>
                                <br />
                              </>
                            );
                          })}
                      </Col>
                      <Col span={4}>
                        <p>X{m.quantity}</p>
                      </Col>
                      <Col span={4}>
                        {!Number(m.has_tag_price_range)
                          ? Number(m.tag_price) > 0 && (
                              <>
                                ¥
                                {(parseFloat(m.tag_price) * m.quantity).toFixed(
                                  2,
                                )}
                              </>
                            )
                          : Number(m.tag_price_low) > 0 &&
                            Number(m.tag_price_high) > 0 && (
                              <>{`¥${(
                                m.quantity * parseFloat(m.tag_price_low)
                              ).toFixed(2)} - ¥${(
                                m.quantity * parseFloat(m.tag_price_high)
                              ).toFixed(2)}`}</>
                            )}
                      </Col>
                    </Row>
                  ))}
                </Card>
              ))}
          </div>
        </Col>
        <Col span={8}>
          <Affix offsetTop={85} className="top-layer-affix">
            <div className={styles["ShareCart-box-right"]}>
              <div className={styles["ShareCart-box-right-content"]}>
                <h3>您正在访问一个共享的购物车！</h3>
                <p>来自: {accountName}</p>
              </div>
              {address && address.length > 0 && (
                <div className={styles["ShareCart-box-right-content"]}>
                  <h3>{`${address[0].recipient_name} ${address[0].recipient_telephone}`}</h3>
                  <p>{address[0].recipient_email}</p>
                  <p>{address[0].recipient_company}</p>
                  <p>{address[0].address_details}</p>
                </div>
              )}
              <div className={styles["ShareCart-box-right-content"]}>
                <h3>价格信息</h3>
                <Row>
                  <Col span={12}>已选商品数</Col>
                  <Col span={12} style={{ textAlign: "right" }}>
                    {handleGetQuantity()}
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>总价</Col>
                  <Col span={12} style={{ textAlign: "right" }}>
                    {handleGetPrice()}
                  </Col>
                </Row>
              </div>
              <Row>
                <PrimaryButton
                  bgColor={bussinessPrimaryColor}
                  block
                  onClick={() => handleAddtoCart()}
                >
                  加入采购清单 &nbsp;
                  <Icon type="icont1_correct"></Icon>
                </PrimaryButton>
              </Row>
            </div>
          </Affix>
        </Col>
      </Row>
    </div>
  );
};

export default ShareDown;
