import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "../../store/configureStore";
import styles from "./style.scss";
import Designer from "./components/Designer";
import ProductList from "./components/ProductListModal";

import {
  Dropdown,
  Form,
  Input,
  Divider,
  Switch,
  Slider,
  Space,
  Cascader,
  message as Message,
  Tooltip,
  Row,
  Col,
  Layout,
} from "antd";

import Icon from "../../components/icon";
import { DesignCheckbox as Checkbox } from "../../wrappers/checkbox";
import { DesignSelector as Select } from "../../wrappers/select";
import { HorizontalLineInput, DesignInput } from "../../wrappers/input";
import { Text } from "../../wrappers/typography";
import ColorPicker from "../../wrappers/color-picker";
import Button, {
  PrimaryButton,
  TextButton as TempTextButton,
} from "../../wrappers/button";
import { Store } from "rc-field-form/lib/interface";
import mapKeys from "lodash/mapKeys";
import transform from "lodash/transform";
import addressData from "./pca";
import { useMount, useUpdateEffect } from "ahooks";
import replaceState from "../../helpers/reolaceState";
import { useTheme } from "styled-components";
import { DesignIdea } from "../../models/DesignIdea";

const DesignIdeaContent: React.FC<{ isManagementEnd: boolean }> = ({
  isManagementEnd,
}) => {
  const TextButton = (props: any) => (
    <TempTextButton
      color="#fff"
      disabledColor="rgba(255, 255, 255, 0.5)"
      {...props}
    ></TempTextButton>
  );
  const { Content, Header } = Layout;
  const theme = useTheme();

  const designerRef = useRef<HTMLDivElement>();
  const [showEditor, setShowEditor] = useState(false);
  const [showCanvas, setShowCanvas] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [showExport, setExportForm] = useState(false);
  const [showUnitSelect, setShowUnitSelect] = useState(false);
  const [showEditWatermark, setEditWatermark] = useState(true);
  const [watermarkStyle, setWatermarkStyle] = useState("#000");
  const dispatch = useDispatch<Dispatch>();
  const {
    currIdea,
    selectedProducts = [],
    tagProducts,
    houseTypeOptions = [],
    colorOptions = [],
    roomOptions = [],
    styleOptions = [],
    gaeaDomain,
  } = useSelector((state: RootState) => state.DesignIdea);

  const { root_id, shop_id: shopId, host_id } = useSelector(
    (state: RootState) => state.URLSearch,
  );

  const { isPrivate } = useSelector(
    (state: RootState) => state.CommonData || {},
  );
  const otherProducts = transform(
    tagProducts,
    (prev, value) => {
      prev.splice(prev.length, 0, ...value);
    },
    [],
  );

  useMount(() => {
    dispatch.DesignIdea.initOptionDataAsync();
    dispatch.DesignIdea.initFilterListAsync();
  });

  const handleIdeaSave = (values: Store) => {
    const { position = [], ...data } = values;

    const [province, city] = position;

    let house_type_text = "",
      color_text = "",
      style_text = "",
      room_text = "";

    if (values.house_type) {
      house_type_text = houseTypeOptions.find(
        o => o.value === values.house_type,
      )?.text;
    }

    if (values.color_list) {
      color_text = colorOptions.find(o => o.value === values.color_list)?.text;
    }

    if (values.style_list) {
      style_text = styleOptions.find(o => o.value === values.style_list)?.text;
    }

    if (values.room_list) {
      room_text = roomOptions.find(o => o.value === values.room_list)?.text;
    }
    const shop_id = shopId || host_id || null;
    dispatch.DesignIdea.submitIdeaAsync({
      design_idea_id: currIdea?.design_idea_id,
      ...data,
      action: currIdea?.design_idea_id ? "edit" : "add",
      province,
      city,
      house_type_text,
      color_text,
      style_text,
      room_text,
      root_id,
      shop_id,
      callback: (result: DesignIdea) => {
        Message.success("提交成功！");

        const { design_idea_id } = result;
        if (design_idea_id) {
          history.pushState(
            design_idea_id,
            "",
            `/design-idea/edit/${design_idea_id}?${
              shop_id ? `host_id=${shop_id}` : ""
            }`,
          );

          if (isManagementEnd) {
            window.parent.postMessage(
              { action: "pushEdit", pid: design_idea_id },
              gaeaDomain,
            );
          }
        }
      },
    });

    setShowForm(false);
  };

  const handleCanvasSave = () => {
    designerRef.current?.saveCanvas();
  };

  const handleCanvasNew = () => {
    isManagementEnd
      ? window.parent.postMessage({ action: "newIdea" }, gaeaDomain)
      : window.open(`/design-idea/new?${host_id ? `host_id=${host_id}` : ""}`);
  };

  const handleCanvasSaveNew = () => {
    designerRef.current?.saveCanvas();

    handleCanvasNew();
  };

  const handleGeneratePicture = (values: Store) => {
    const { size, opacity, ...dataSet } = values;

    designerRef.current?.generatePicture(
      mapKeys(
        {
          ...dataSet,
          opacity: opacity / 100,
          style: `${size}px ${watermarkStyle}`,
        },
        (value, key) => {
          if (key === "drawWatermark" || key === "format") {
            return key;
          }

          return `watermark-${key}`;
        },
      ),
    );

    setExportForm(false);
  };

  const [tabActiveIndex, setTabActiveIndex] = useState(0);

  const positionOptions = Object.getOwnPropertyNames(addressData).map(pi => ({
    value: pi,
    label: pi,
    children: Object.getOwnPropertyNames(addressData[pi]).map(di => ({
      label: di,
      value: di,
    })),
  }));

  const [form] = Form.useForm();

  const [houseTypeText, setHouseTypeText] = useState("户型");

  const formOverlay = (
    <div
      className={styles["header-design-form"]}
      onClick={e => e.stopPropagation()}
    >
      <Form
        form={form}
        onFinish={handleIdeaSave}
        initialValues={{
          ...currIdea,
          position: currIdea?.province
            ? [currIdea?.province, currIdea?.city]
            : [],
        }}
      >
        <Form.Item
          name="design_idea_name"
          rules={[{ required: true, message: "请输入设计名" }]}
        >
          <Input placeholder="设计未命名" />
        </Form.Item>
        <Form.Item noStyle>
          <Space align="start">
            <Form.Item noStyle>
              <Dropdown
                overlay={
                  <div className={styles["header-unit-form"]}>
                    <Form.Item name="house_type" style={{ width: "50%" }}>
                      <Select
                        placeholder="户型"
                        onChange={(val, op) => setHouseTypeText(op.children)}
                        notFoundContent="暂无筛选项"
                      >
                        {houseTypeOptions.map(op => (
                          <Select.Option value={op.value} key={op.value}>
                            {op.text}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item>
                      <Space>
                        <Form.Item name="house_type_bedroom" noStyle>
                          <Select defaultValue="0" notFoundContent="暂无筛选项">
                            {new Array(4).fill(0).map((v, index) => (
                              <Select.Option value={index + 1} key={v}>
                                {index + 1}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Text>室</Text>
                        <Form.Item name="house_type_livingroom" noStyle>
                          <Select defaultValue="0" notFoundContent="暂无筛选项">
                            {new Array(4).fill(0).map((v, index) => (
                              <Select.Option value={index + 1} key={v}>
                                {index + 1}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Text>厅</Text>
                        <Form.Item name="house_type_bathroom" noStyle>
                          <Select defaultValue="0" notFoundContent="暂无筛选项">
                            {new Array(4).fill(0).map((v, index) => (
                              <Select.Option value={index + 1} key={v}>
                                {index + 1}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Text>卫</Text>
                        <Form.Item name="house_type_kitchen" noStyle>
                          <Select defaultValue="0" notFoundContent="暂无筛选项">
                            {new Array(4).fill(0).map((v, index) => (
                              <Select.Option value={index + 1} key={v}>
                                {index + 1}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Text>厨</Text>
                      </Space>
                    </Form.Item>
                  </div>
                }
                visible={showUnitSelect}
                onVisibleChange={() => setShowUnitSelect(vis => !vis)}
                trigger={["click"]}
                className={styles["design-dropdown-button"]}
              >
                <Button icon={<Icon type="iconhuxing"></Icon>}>
                  {houseTypeText} <Icon type="icont1_xiangxia" />
                </Button>
              </Dropdown>
            </Form.Item>
            <Form.Item
              name="room_list"
              rules={[{ required: true, message: "请选择房间" }]}
            >
              <Select
                allowClear
                suffixIcon={<Icon type="icont1_xiangxia" />}
                notFoundContent="暂无筛选项"
                placeholder={
                  <>
                    <Icon type="iconfangjian"></Icon>&nbsp;&nbsp;
                    <Text>房间</Text>
                  </>
                }
                dropdownMatchSelectWidth={false}
              >
                {roomOptions?.map(v => (
                  <Select.Option value={v.value} key={v.value}>
                    {v.text}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="style_list" noStyle>
              <Select
                suffixIcon={<Icon type="icont1_xiangxia" />}
                placeholder={
                  <>
                    <Icon type="iconfengge"></Icon>&nbsp;&nbsp;
                    <Text>风格</Text>
                  </>
                }
                notFoundContent="暂无筛选项"
                dropdownMatchSelectWidth={false}
              >
                {styleOptions?.map(v => (
                  <Select.Option value={v.value} key={v.value}>
                    {v.text}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="color_list" noStyle>
              <Select
                suffixIcon={<Icon type="icont1_xiangxia" />}
                placeholder={
                  <>
                    <Icon type="iconyanse"></Icon>&nbsp;&nbsp;
                    <Text>颜色</Text>
                  </>
                }
                notFoundContent="暂无筛选项"
                dropdownMatchSelectWidth={false}
              >
                {colorOptions?.map(op => (
                  <Select.Option value={op.value} key={op.value}>
                    {op.text}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item
          name="house_cost"
          label={
            <span>
              <Icon type="iconhuafei"></Icon>&nbsp;
              <Text>花费</Text>
            </span>
          }
          labelAlign="left"
          wrapperCol={{ span: 12 }}
        >
          <HorizontalLineInput
            placeholder="请输入您的预算"
            prefix="¥"
            suffix=".00"
          />
        </Form.Item>
        <Form.Item
          name="position"
          label={
            <span>
              <Icon type="iconweizhi"></Icon>&nbsp;
              <Text>位置</Text>
            </span>
          }
          labelAlign="left"
          wrapperCol={{ span: 12 }}
        >
          <Cascader
            options={positionOptions}
            placeholder="请选择位置"
            notFoundContent="暂无筛选项"
          />
        </Form.Item>
        <Form.Item
          name="house_area"
          label={
            <span>
              <Icon type="iconmianji"></Icon>&nbsp;
              <Text>面积</Text>
            </span>
          }
          labelAlign="left"
          wrapperCol={{ span: 12 }}
        >
          <HorizontalLineInput placeholder="请输入面积" suffix="平方米" />
        </Form.Item>
        <Form.Item name="design_idea_slogan">
          <Input.TextArea placeholder="设计师寄语" maxLength={200} rows={4} />
        </Form.Item>
        <Form.Item name="is_original" valuePropName="checked">
          <Checkbox>是否原创</Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 16 }}>
          <Space>
            <Button
              htmlType="reset"
              onClick={() => setShowForm(vis => !vis)}
              color={theme.designPrimaryColor}
            >
              取消
            </Button>
            <PrimaryButton htmlType="submit" bgColor={theme.designPrimaryColor}>
              提交
            </PrimaryButton>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
  useUpdateEffect(() => {
    if (currIdea && currIdea.house_type_text) {
      setHouseTypeText(currIdea.house_type_text);
    }

    form.resetFields();
  }, [currIdea?.design_idea_id]);

  return (
    <>
      <Header className={styles["header"]}>
        <Space className={styles["header-left"]}>
          <Dropdown
            trigger={["click"]}
            overlay={formOverlay}
            visible={showForm}
            onVisibleChange={() => setShowForm(vis => !vis)}
            placement="bottomLeft"
          >
            <TextButton>
              {currIdea?.design_idea_name ?? "设计未命名"}
              <Icon type="icont2_arrowDown" />
            </TextButton>
          </Dropdown>
          <Tooltip title="新建" placement="bottom">
            <TextButton onClick={handleCanvasNew}>
              <Icon type="iconchuangjian" />
              <span className={styles["header-btn-tip"]}>新建</span>
            </TextButton>
          </Tooltip>
          <Tooltip title="保存并新建" placement="bottom">
            <TextButton
              onClick={handleCanvasSaveNew}
              disabled={!currIdea?.design_idea_id}
            >
              <Icon type="iconlingcunwei" />
              <span className={styles["header-btn-tip"]}>保存新建</span>
            </TextButton>
          </Tooltip>
          <Tooltip title="保存" placement="bottom">
            <TextButton
              onClick={handleCanvasSave}
              disabled={!currIdea?.design_idea_id}
            >
              <Icon type="iconbaocun" />
              <span className={styles["header-btn-tip"]}>保存</span>
            </TextButton>
          </Tooltip>
          <Divider type="vertical" style={{ opacity: 0.3 }}></Divider>
          <Dropdown
            disabled={!currIdea?.design_idea_id}
            trigger={["click"]}
            visible={showExport}
            onVisibleChange={() => setExportForm(vis => !vis)}
            placement="bottomLeft"
            overlay={
              <div
                className={styles["header-design-form"]}
                onClick={e => e.stopPropagation()}
              >
                <Form
                  name="watermark"
                  onFinish={handleGeneratePicture}
                  initialValues={{
                    drawWatermark: true,
                    font: "Calibri",
                    size: 100,
                    text: "数莓派",
                    degree: 0,
                    opacity: 100,
                  }}
                  labelCol={{ span: 9 }}
                  wrapperCol={{ span: 15 }}
                >
                  <Form.Item name="format" label="图片格式">
                    <Select
                      suffixIcon={<Icon type="icont1_xiangxia" />}
                      defaultValue="png"
                    >
                      <Select.Option value="png">png</Select.Option>
                      <Select.Option value="jpg">jpg</Select.Option>
                      <Select.Option value="jpeg">jpeg</Select.Option>
                      <Select.Option value="gif">gif</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item name="drawWatermark" valuePropName="checked">
                    <Checkbox
                      onChange={e => setEditWatermark(e.target.checked)}
                    >
                      为图片添加水印
                    </Checkbox>
                  </Form.Item>

                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <Form.Item name="text" label="水印文字">
                      <DesignInput />
                    </Form.Item>
                    <Form.Item name="size" label="水印字体大小">
                      <DesignInput suffix="px" />
                    </Form.Item>
                    <Form.Item label="水印透明度">
                      <Row align="middle" gutter={10}>
                        <Col span={16}>
                          <Form.Item name="opacity" noStyle>
                            <Slider min={0} max={100} />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item name="opacity" noStyle>
                            <Input
                              size="small"
                              style={{ borderRadius: "1px" }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item label="字体样式">
                      <Row gutter={10} align="middle">
                        <Col span={24}>
                          <Form.Item noStyle>
                            <ColorPicker
                              size="middle"
                              value={watermarkStyle}
                              onChange={setWatermarkStyle}
                              style={{ borderRadius: "1px" }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item label="水印旋转角度">
                      <Row align="middle">
                        <Col span={16}>
                          <Form.Item name="degree" noStyle>
                            <Slider min={-180} max={180} />
                          </Form.Item>
                        </Col>
                        <Col offset={1} span={7}>
                          <Form.Item name="degree" noStyle>
                            <Input
                              size="small"
                              style={{ borderRadius: "1px" }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <div
                      className={styles["header-design-form-mask"]}
                      style={{ display: showEditWatermark ? "none" : "" }}
                    ></div>
                  </div>
                  <Form.Item wrapperCol={{ offset: 12 }}>
                    <Space>
                      <Button
                        htmlType="reset"
                        onClick={() => setExportForm(vis => !vis)}
                        color={theme.designPrimaryColor}
                      >
                        取消
                      </Button>
                      <PrimaryButton
                        htmlType="submit"
                        bgColor={theme.designPrimaryColor}
                      >
                        确认
                      </PrimaryButton>
                    </Space>
                  </Form.Item>
                </Form>
              </div>
            }
          >
            <TextButton
              icon={<Icon type="icondaochudefuben" />}
              disabled={!currIdea?.design_idea_id}
            >
              生成图片
            </TextButton>
          </Dropdown>
          <TextButton
            icon={<Icon type="iconxianshi" />}
            disabled={!currIdea?.design_idea_id || !currIdea.canvas_object}
            onClick={() =>
              replaceState.linkTo(
                `${
                  isManagementEnd
                    ? `${gaeaDomain}/product/designidea`
                    : "/design-idea"
                }/preview/${currIdea?.design_idea_id}`,
                "_blank",
              )
            }
          >
            预览
          </TextButton>
          {!isManagementEnd && (
            <TextButton
              icon={<Icon type="iconweidenglu" />}
              disabled={
                !currIdea?.design_idea_id ||
                currIdea.design_idea_status === 6 ||
                !currIdea.canvas_object
              }
              onClick={() =>
                dispatch.DesignIdea.publishIdeaAsync({
                  design_idea_id: currIdea?.design_idea_id,
                  callback: () => Message.success("发布成功！"),
                })
              }
            >
              发布
            </TextButton>
          )}
        </Space>
        <Space className={styles["header-right"]} align="center">
          <Switch checked={showCanvas} onChange={val => setShowCanvas(val)} />
          <span>画布</span>
          <Switch checked={showEditor} onChange={val => setShowEditor(val)} />
          <span>文本编辑</span>
          <Divider type="vertical" style={{ opacity: 0.3 }}></Divider>
          <ProductList
            productList={[...selectedProducts, ...otherProducts]}
            isManagementEnd={isManagementEnd}
          />
          <Divider type="vertical" style={{ opacity: 0.3 }}></Divider>
          <TextButton
            icon={<Icon type="iconwodesheji"></Icon>}
            onClick={() =>
              isManagementEnd
                ? window.parent.postMessage({ action: "backList" }, gaeaDomain)
                : setTabActiveIndex(4)
            }
          >
            {isManagementEnd ? "设计列表" : "我的设计"}
          </TextButton>
          <PrimaryButton
            onClick={() =>
              isManagementEnd
                ? window.parent.postMessage(
                    { action: "backAccount" },
                    gaeaDomain,
                  )
                : replaceState.linkTo("/account")
            }
            size="large"
            icon={<Icon type="icongerenxian"></Icon>}
            className={styles["header-user"]}
            bgColor={theme.designPrimaryColor}
          ></PrimaryButton>
        </Space>
      </Header>

      <Content>
        <Designer
          ref={designerRef}
          showCanvas={showCanvas}
          showEditor={showEditor}
          navActiveIndex={tabActiveIndex}
          onChange={setTabActiveIndex}
          isManagementEnd={isManagementEnd}
        />
      </Content>
    </>
  );
};

export default DesignIdeaContent;
