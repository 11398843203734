import * as React from "react";
import Layout from "../../Layout";
import Content from "./Content";
import { RootState } from "../../../store/configureStore";

const mapStateToProps = (state: RootState) => ({
  breadcrumbs: state.Cart.breadcrumbs,
});

class ShareCart extends React.Component<ReturnType<typeof mapStateToProps>> {
  public render() {
    return (
      <Layout breadcrumbs={[]} isToBusiness>
        <Content />
      </Layout>
    );
  }
}

export default ShareCart;
