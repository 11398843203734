import { createModel } from "@rematch/core";
import { GraphqlMutation, GraphqlQuery } from "../../helpers/request.helper";
import { getFirstItem } from "../../helpers/modelHelpers";
import produce from "immer";
import { IdeaFilter, DesignIdeaList } from "../../models/DesignIdea";
export interface UserDesignType {
  breadcrumbs: { url: string; title: string }[];

  pickList: IdeaFilter;

  userDesignData: DesignIdeaList;
  total: number;
}

export default createModel({
  state: {} as UserDesignType,
  reducers: {
    setPickList: (state: UserDesignType, pickList: IdeaFilter) => ({
      ...state,
      pickList,
    }),
    setIdeaList: (
      state: UserDesignType,
      userDesignData: DesignIdeaList,
      total: number,
    ) => ({
      ...state,
      userDesignData,
      total,
    }),
  },
  effects: {
    // 获取picklist
    async setPickListAsync() {
      const res = await GraphqlQuery(
        "PickList",
        new IdeaFilter(),
        {
          action: "myDesign",
        },
        "design",
      );
      this.setPickList(getFirstItem(res));
    },
    async setIdeaOperationAsync(payload) {
      const { type, id, callback } = payload;
      const result = await GraphqlMutation("DesignIdea", {
        action: type,
        design_idea_id: id,
      });
      if (callback) {
        callback(result.message);
      }
    },
  },
});
