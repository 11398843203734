import React, { useContext, useState, useCallback, useEffect } from "react";
import { Dispatch, RootState } from "../../store/configureStore";
import { useSelector, useDispatch } from "react-redux";
import * as styles from "./index.scss";
import { Product, ProductDetail } from "../../models/Product";
import { useIntl } from "react-intl";
import { CommonProps } from "../../typings/component";
import { BusinessContext } from "../BusinessContext";
import Icon from "../../components/icon";
import Provider from "../../models/Provider";
import PDRating from "./Components/PDRating";

import { Tabs, Affix, Card } from "antd";
import { useTheme } from "styled-components";
import { isInViewPort } from "../../helpers/dom.helper";

const ProductDetails: React.FC<
  CommonProps & {
    isPreview: boolean;
    toreview: boolean;
    productItem: Product;
    productDetails: ProductDetail;
    provider?: Provider;
  }
> = ({
  productItem,
  isLogin,
  productDetails,
  isMobile,
  isPreview,
  toreview,
  provider,
}) => {
  const { formatMessage: f } = useIntl();
  const { primaryColor, secondaryColor, bussinessPrimaryColor } = useTheme();

  const [tagIndex, setTagIndex] = useState(0);

  const { isToBusiness } = useContext(BusinessContext);

  const pdDetailsTitleData =
    isToBusiness || isPreview
      ? [
          {
            icon: "icontezhengtupian",
            text: f({ id: "Features" }),
          },
          {
            icon: "iconmiaoshu",
            text: f({ id: "Description" }),
          },
          {
            icon: "iconguige",
            text: f({ id: "Specifications" }),
          },
        ]
      : [
          {
            icon: "icontezhengtupian",
            text: f({ id: "Features" }),
          },
          {
            icon: "iconmiaoshu",
            text: f({ id: "Description" }),
          },
          {
            icon: "iconguige",
            text: f({ id: "Specifications" }),
          },
          {
            icon: "iconwuliu",
            text: f({ id: "Logistics" }),
          },
          {
            icon: "iconxingxing",
            text: f({ id: "Review" }),
          },

          // {
          //   icon: "iconfuwu",
          //   text: f({ id: "Service" }),
          // },
          // {
          //   icon: "icontoubaoshengming",
          //   text: f({ id: "Disclaimer" }),
          // },
        ];

  const tabBtnColor = isToBusiness ? bussinessPrimaryColor : primaryColor;

  const deliveryDate =
      provider?.logistics_delivery_text || productItem.logistics_delivery_text,
    logisticsRemark =
      provider?.logistics_remark || productItem.logistics_remark;

  useEffect(() => {
    if (toreview && pdDetailsTitleData.length > 3) {
      setTagIndex(4);
    }
  }, [toreview]);

  useEffect(() => {
    const el = document.querySelector(
      `.ant-tabs-tabpane:nth-of-type(${tagIndex + 1})>.ant-card`,
    ) as HTMLElement;

    if (tagIndex > 0 && el && !isInViewPort(el)) {
      el.scrollIntoView({ block: "center" });
    }
  }, [tagIndex]);

  const { TabPane } = Tabs;

  const renderTabBar = (props: any, DefaultTabBar: React.ComponentType) => (
    <Affix offsetTop={85}>
      <DefaultTabBar {...props} />
    </Affix>
  );

  return (
    <>
      <div className="split-title">
        <div>{f({ id: "ProductDetails" })}</div>
      </div>
      <Tabs
        renderTabBar={renderTabBar}
        tabBarGutter={10}
        type="card"
        activeKey={tagIndex + ""}
        onChange={key => setTagIndex(Number(key))}
        className="product-details-tabs"
      >
        {pdDetailsTitleData.map((item, index) => (
          <TabPane
            key={index}
            tab={
              <span>
                <Icon type={item.icon} />
                {item.text}
              </span>
            }
          >
            <Card>
              {index === 0 && (
                <div className={styles["feature-img-box"]}>
                  {productDetails?.featureData?.map((item, index) => {
                    return <img key={index} src={item.value}></img>;
                  })}
                </div>
              )}
              {index === 1 && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: productItem && productItem.product_description,
                  }}
                />
              )}
              {index === 2 && (
                <table className="bifarious-separate-table">
                  <tbody>
                    {productItem.specification.map((s, v) => (
                      <tr key={v}>
                        <td>{s.title}</td>
                        <td
                          style={{
                            backgroundColor: isToBusiness
                              ? tabBtnColor
                              : secondaryColor,
                          }}
                        >
                          {s.category_id === 1 || s.category_id === 6
                            ? s.category_sub_name
                            : s.attribution_value}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {index === 3 && (
                <table className="bifarious-separate-table">
                  <tbody>
                    <tr>
                      <td>{f({ id: "DeliveryDate" })}</td>
                      {deliveryDate && (
                        <td
                          style={{
                            backgroundColor: isToBusiness
                              ? tabBtnColor
                              : secondaryColor,
                          }}
                        >
                          {deliveryDate}
                        </td>
                      )}
                    </tr>
                    <tr>
                      <td>{f({ id: "LogisticsRemark" })}</td>
                      {logisticsRemark && (
                        <td
                          style={{
                            backgroundColor: isToBusiness
                              ? tabBtnColor
                              : secondaryColor,
                          }}
                        >
                          {logisticsRemark}
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
              )}
              {index === 4 && (
                <PDRating
                  shop_product_id={productItem.shop_product_id}
                  isToReview={toreview}
                  isLogin={isLogin}
                  isMobile={isMobile}
                />
              )}
            </Card>
          </TabPane>
        ))}
      </Tabs>
    </>
  );
};

export default React.memo(ProductDetails);
