import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../../store/configureStore";
import classnames from "classnames";
import { useTheme } from "styled-components";
import { useContext } from "react";
import { BusinessContext } from "../../BusinessContext";
import Icon from "../../../components/icon";
import * as styles from "./index.scss";
import { getFirstItem } from "../../../helpers/modelHelpers";
import replaceState from "../../../helpers/reolaceState";
import { fibonacci } from "../../../helpers/sequence.helper";

import { Row, Col } from "antd";
import Modal from "../../../wrappers/modal";
import { PrimaryButton } from "../../../wrappers/button";

interface PayProps {
  address: {
    recipient_name: string;
    recipient_telephone: string;
    recipient_address: string;
  };
  handleGetQrcode: (payload: any) => void;
  handleGetPayType: (payload: any) => void;

  order_id: number;
  pirce: any;
  remark: string;
  payment: number;
  orderNumber: string;
  handleRefash: () => void;
}

const IdeaCard = (props: PayProps) => {
  const dispatch = useDispatch<Dispatch>();
  const { isPrivate } = useSelector((state: RootState) => state.CommonData);

  const [quoteId, setQuoteID] = React.useState(0);
  const [qrCodeUrl, setQrCodeUrl] = React.useState("");
  const [paytype, setPaytype] = React.useState(false);
  const [overdue, setOverdue] = React.useState(false);
  const [minute, setMinute] = React.useState(30);
  const [second, setSecond] = React.useState(0);
  const [time, setTime] = React.useState(3);
  const [apiPayResult, setApiPayResult] = React.useState(false);
  const [countdown, setCountdown] = React.useState(3);
  const [showAliModal, setShowAliModal] = React.useState(false);

  const {
    address,
    handleGetQrcode,
    order_id,
    pirce,
    remark = "",
    handleGetPayType,
    payment = 1,
    orderNumber = "",
    handleRefash,
  } = props;

  const { primaryColor } = useTheme();

  const timeRef = React.useRef(),
    overDueRef = React.useRef(),
    timeIntervalRef = React.useRef(),
    aliTimeOutRef = React.useRef();

  const qrcodeData = {
    order_id,
    private: Boolean(isPrivate),
    callback: res => {
      if (getFirstItem(res).url !== "") {
        setQrCodeUrl(getFirstItem(res).url);
        setOverdue(false);
        setQuoteID(order_id);

        let remaining = 9;

        // 延时10秒请求支付结果
        timeRef.current = setTimeout(() => {
          overDueRef.current = setInterval(() => {
            remaining = remaining - 1;
            if (remaining > 0) {
              if (paytype) return;
              handleGetPayTypeRes();
            }
          }, 5000);
        }, 5000);
        // 1分钟后过期
        timeIntervalRef.current = setTimeout(() => {
          setOverdue(true);
        }, 60000);
      } else {
        setQuoteID(0);
        handleRefash();
      }
    },
  };

  const handleGetPayTypeRes = () => {
    const data = {
      order_id,
      callback: res => {
        const result = payment === 1 ? res : getFirstItem(res).result;
        if (result) {
          setPaytype(true);
          // 三秒后刷新页面
          let times = 3;
          setInterval(() => {
            times = times - 1;
            if (times > 0) {
              setTime(times);
            } else {
              location = location;
            }
          }, 1000);
        }
      },
    };
    if (payment === 1) {
      handleGetPayType(data);
    } else {
      dispatch.OrderDetailsPage.handleGetPayType(data);
    }
  };

  const handleGetAliUrl = () => {
    const data = {
      order_id,
      private: Boolean(isPrivate),
      callback: res => {
        if (res !== "") {
          window.open(res);
          getAliPayrResult(order_id);
          setShowAliModal(true);
        } else {
          handleRefash();
          setShowAliModal(false);
        }
      },
    };
    dispatch.OrderDetailsPage.getZfbUrl(data);
  };

  const getAliPayrResult = (orderId: number, checkIndx: number = 7) => {
    aliTimeOutRef.current = setTimeout(() => {
      const data = {
        order_id: orderId,
        callback: res => {
          if (res) {
            let times = 3;
            setApiPayResult(true);
            setInterval(() => {
              if (times - 1 === 0) {
                replaceState.linkTo("/account/orders");
              } else {
                setCountdown(times - 1);
                times = times - 1;
              }
            }, 1000);
          }
        },
      };
      dispatch.OrderDetailsPage.getPayResult(data);

      getAliPayrResult(orderId, checkIndx + 1);
    }, fibonacci(checkIndx) * 1000);
  };

  const handleCancelPay = () => {
    setQuoteID(0);
    clearTimeout(timeRef.current);
    clearTimeout(overDueRef.current);
    clearInterval(timeIntervalRef.current);
    setOverdue(false);
  };

  return (
    <>
      <PrimaryButton
        block
        style={{
          height: "50px",
          fontSize: "1.em",
        }}
        bgColor={primaryColor}
        onClick={() => {
          if (payment === 1) {
            handleGetAliUrl();
          } else {
            handleGetQrcode(qrcodeData);
          }
        }}
      >
        立即支付
      </PrimaryButton>
      <Modal
        title="支付宝支付"
        visible={showAliModal}
        onCancel={() => {
          setShowAliModal(false);
          clearTimeout(aliTimeOutRef.current);
          dispatch.OrderDetailsPage.setOrderDetails(orderNumber);
        }}
        width="400px"
        footer={null}
      >
        <div className={styles[`compute-ali-modal`]}>
          {apiPayResult && (
            <p>
              支付成功！页面将在{" "}
              <span style={{ color: primaryColor }}>{` ${countdown} `}</span>
              秒后跳转
            </p>
          )}
          <div className={styles[`compute-ali-modal-content`]}>
            <Icon type="iconzhifubao" />
            应付金额
            <span
              style={{
                color: primaryColor,
                fontSize: "30px",
                margin: "0 10px",
              }}
            >
              {` ¥${pirce} `}
            </span>
            元
          </div>
          <Row gutter={10}>
            <Col span={12}>
              <PrimaryButton
                block
                onClick={() => {
                  // replaceState.linkTo("/account", "", {
                  //   type: "order",
                  // });
                  setShowAliModal(false);
                  clearTimeout(aliTimeOutRef.current);
                  dispatch.OrderDetailsPage.setOrderDetails(orderNumber);
                }}
                bgColor={primaryColor}
              >
                稍后支付
              </PrimaryButton>
            </Col>
            <Col span={12}>
              <PrimaryButton
                block
                onClick={() => {
                  // replaceState.linkTo("/account", "", {
                  //   type: "order",
                  // });
                  setShowAliModal(false);
                  clearTimeout(aliTimeOutRef.current);
                  dispatch.OrderDetailsPage.setOrderDetails(orderNumber);
                }}
                bgColor={primaryColor}
              >
                我已支付
              </PrimaryButton>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal
        title="微信支付"
        visible={quoteId > 0}
        onCancel={() => {
          handleCancelPay();

          // dispatch.OrderDetailsPage.setOrderDetails(orderNumber);
        }}
        width="800px"
        footer={null}
      >
        <div className={styles[`compute-modal`]}>
          <Row gutter={50}>
            <Col span={10} className={styles[`compute-modal-header`]}>
              二维码有效时长为
              <span style={{ color: primaryColor }}>60</span>
              秒，请尽快完成支付！过期后请刷新重新获取二维码!
            </Col>
            <Col span={14}></Col>
          </Row>
          <Row gutter={50} style={{ marginTop: "10px" }}>
            <Col span={10}>
              <div className={styles[`compute-modal-qrcode`]}>
                <img src={qrCodeUrl} alt="" />
                <div
                  className={classnames(styles[`compute-modal-qrcode-mask`], {
                    [styles[`compute-modal-qrcode-show`]]: paytype || overdue,
                  })}
                >
                  {paytype && (
                    <>
                      <Icon
                        type="iconduihao2"
                        style={{ color: "#07c160" }}
                      ></Icon>
                      <p>支付成功!</p>
                    </>
                  )}

                  {overdue && !paytype && (
                    <div onClick={() => handleGetQrcode(qrcodeData)}>
                      <Icon type="icont1_shuaxin"></Icon>
                      <p>已失效!</p>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles[`compute-modal-prompt`]}>
                <Icon type="iconsaomiao"></Icon>
                <div className={styles[`compute-modal-prompt-text`]}>
                  <p>请使用微信扫一扫</p>
                  <p>扫描二维码</p>
                </div>
              </div>
            </Col>
            <Col span={14}>
              <div className={styles[`compute-modal-content`]}>
                <p
                  style={{ color: primaryColor }}
                  className={styles[`compute-modal-content-header`]}
                >
                  {paytype && (
                    <>
                      <span>订单已支付成功!</span>
                      <span style={{ color: "#666666" }}>
                        将在
                        <span style={{ color: "#C21D44" }}> {time} </span>
                        秒后刷新页面
                      </span>
                    </>
                  )}
                </p>
                <p className={styles[`compute-modal-content-title`]}>
                  收货人信息
                </p>
                {address && (
                  <div>
                    {`${address.recipient_name} ${address.recipient_telephone}`}
                  </div>
                )}
                {address && (
                  <div>
                    {address.province}
                    {address.city}
                    {address.area}
                    {address.recipient_address}
                  </div>
                )}

                {remark !== "" && (
                  <>
                    <p className={styles[`compute-modal-content-title`]}>
                      备注信息
                    </p>
                    <p>{remark}</p>
                  </>
                )}
                <p className={styles[`compute-modal-content-price`]}>
                  {paytype ? "已付金额" : "应付金额"}
                  <span style={{ color: primaryColor, fontSize: "22px" }}>
                    {` ¥${pirce} `}
                  </span>
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default React.memo(IdeaCard);
