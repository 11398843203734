import { PDFDocument } from "pdf-lib";

export async function signPdf(
  existingPdfBytes: ArrayBuffer,
  pngImageBytes: string,
  signData: {
    x: number;
    y: number;
    page: number;
    key: string;
    text?: string;
  }[],
) {
  // Load a PDFDocument from the existing PDF bytes
  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const pngImage = await pdfDoc.embedPng(pngImageBytes);

  // Add a blank page to the document

  const width = 200,
    height = 70;

  // Draw the PNG image near the lower right corner of the JPG image

  signData.map(async item => {
    if (item.key !== "clientSignature") {
      const page = pdfDoc.getPage(item.page - 1);
      const canvasBox = document.createElement("canvas");
      canvasBox.height = 25;
      canvasBox.width = 450;
      const ctx = canvasBox.getContext("2d");
      ctx?.font = "9px  SimSun";
      ctx?.fillText(item.text, 0, 10);
      const drawImg = await pdfDoc.embedPng(canvasBox.toDataURL("image/png"));

      page.drawImage(drawImg, {
        x: Number((page.getWidth() * item.x).toFixed(2)),
        y: Number((page.getHeight() * item.y).toFixed(2)) - 10,
      });
    } else {
      const page = pdfDoc.getPage(item.page - 1);
      page.drawImage(pngImage, {
        x: Number((page.getWidth() * item.x).toFixed(2)),
        y: Number((page.getHeight() * item.y).toFixed(2)) - 35,
        width,
        height,
      });

      const date = new Date();
      const year = date.getFullYear(),
        month =
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1,
        day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

      page.drawText(`${year}.${month}.${day}`, {
        x: Number((page.getWidth() * item.x).toFixed(2)),
        y: Number((page.getHeight() * item.y).toFixed(2)) - 55,
        size: 14,
      });
    }
  });

  // Serialize the PDFDocument to bytes (a Uint8Array)
  const pdfBytes = await pdfDoc.save();

  // For example, `pdfBytes` can be:
  //   • Written to a file in Node
  //   • Downloaded from the browser
  //   • Rendered in an <iframe>
  return pdfBytes;
}

export function uint8arrayToBase64(u8Arr) {
  const CHUNK_SIZE = 0x8000; //arbitrary number
  let index = 0;
  const length = u8Arr.length;
  let result = "";
  let slice;
  while (index < length) {
    slice = u8Arr.subarray(index, Math.min(index + CHUNK_SIZE, length));
    result += String.fromCharCode.apply(null, slice);
    index += CHUNK_SIZE;
  }
  // web image base64图片格式: "data:image/png;base64," + b64encoded;
  // return  "data:image/png;base64," + btoa(result);
  return `data:application/pdf;base64,${btoa(result)}`;
}
