// 298
import * as React from "react";
import Layout from "../Layout";
import Content from "./Content";
import { RootState } from "../../store/configureStore";
import { useSelector } from "react-redux";
const VerifyAgent = () => {
  const {
    breadcrumbs = [
      {
        url: "/",
        title: "首页",
      },
      {
        url: "/account/verify/agent",
        title: "检查",
      },
    ],
  } = useSelector((state: RootState) => state.VerifyPage);
  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Content />
    </Layout>
  );
};

export default VerifyAgent;
