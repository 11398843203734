import * as React from "react";
import Layout from "../Layout";
import Content from "./Content";
import * as styles from "./index.scss";

class Home extends React.Component<{}> {
  public render() {
    return (
      <Layout className={styles["Layout-bgColor"]}>
        <Content />
      </Layout>
    );
  }
}
export default Home;
