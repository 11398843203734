import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as styles from "./index.scss";
import Icon from "../../components/icon";
import { CommonProps } from "../../typings/component";
import { useTheme } from "styled-components";
import { BusinessContext } from "../BusinessContext";
import { useIntl } from "react-intl";
import Button, { PrimaryButton } from "../../wrappers/button";
import List from "../../wrappers/list";
import { RootState, Dispatch } from "../../store/configureStore";
import { Row, Col, Space } from "antd";
import MessageItem from "./MessageItem";

const MessageContent: React.FC<CommonProps> = ({ classNamePrefix = "" }) => {
  const dispatch = useDispatch<Dispatch>();
  const { formatMessage: f } = useIntl();
  const { primaryColor } = useTheme();
  const { template } = useContext(BusinessContext);

  const { total, messages } = useSelector(
    (state: RootState) => state.MessagePage,
  );

  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  useEffect(() => {
    if (!messages) {
      dispatch.MessagePage.setMessage({ page: 1 });
    }
  }, []);

  const handleReadAllMessage = () => {
    if (Array.isArray(messages) && messages.length !== 0) {
      const msgId = messages
        .filter(item => Number(item.message_status) === 1)
        .map(item => item.message_id)
        .join("#");
      if (msgId !== "") {
        dispatch.MessagePage.changeMessageStatus({
          messageId: msgId,
          action: "readAll",
        });
      }
    }
  };

  const handleDleAllMessage = () => {
    if (messages && messages.length !== 0) {
      const idList = messages.map(item => item.message_id).join("#");
      dispatch.MessagePage.changeMessageStatus({
        messageId: idList,
        action: "deleteAll",
      });
    }
    return;
  };

  const handleGetOtherList = (page: number) => {
    setCurrentPageNumber(page);
    dispatch.MessagePage.setMessage({ page });
    window.scroll(0, 0);
  };

  return (
    <div className={styles["MessageConter"]}>
      <div
        style={{
          width: "100%",
          padding: 20,
        }}
      >
        <Row style={{ margin: "10px 0px" }}>
          <Space>
            <PrimaryButton
              onClick={() => handleReadAllMessage()}
              bgColor={primaryColor}
            >
              {f({ id: "ReadAll" })}
            </PrimaryButton>
            <Button onClick={() => handleDleAllMessage()} color={primaryColor}>
              {f({ id: "DeleteAll" })}
            </Button>
          </Space>
        </Row>
        <List
          locale={{
            emptyText: (
              <p className={styles[`MessageConter-empty`]}>
                {f({ id: "YouDoNotHaveAnyMessageToShow" })}
              </p>
            ),
          }}
          dataSource={messages}
          split={false}
          grid={{ gutter: 10, column: 1 }}
          renderItem={(item: any, index) => (
            <List.Item key={index}>
              <MessageItem
                classNamePrefix={classNamePrefix}
                id={item.message_id}
                key={item.message_id}
                isRead={Number(item.message_status) === 1}
                title={item.subject}
                content={item.content}
                date={item.send_at}
              />
            </List.Item>
          )}
          pagination={{
            current: currentPageNumber,
            total: total,
            pageSize: 10,
            showSizeChanger: false,
            onChange: handleGetOtherList,
            hideOnSinglePage: true,
          }}
        ></List>
      </div>
    </div>
  );
};
export default React.memo(MessageContent);
