import { GraphqlField } from "../helpers/decorators";
import BaseModel from "./BaseModel";

export default class Reservation extends BaseModel {
  @GraphqlField()
  public total: number;

  @GraphqlField()
  public customer_experience_id: number;

  @GraphqlField()
  public provider_id: number;
  @GraphqlField()
  public shop_product_id: number;
  @GraphqlField()
  public arriving_time: string;
  @GraphqlField()
  public business_start_time: string;
  @GraphqlField()
  public business_end_time: string;
  @GraphqlField()
  public provider_logo: string;
  @GraphqlField()
  public shop_id: number;
  @GraphqlField()
  public original_price_value: string;
  @GraphqlField()
  public shop_url: string;
  @GraphqlField()
  public tag_price_value: string;
  @GraphqlField()
  public product_picture: string;

  @GraphqlField()
  public tag_price: string;
  @GraphqlField()
  public original_price: string;
  @GraphqlField()
  public shop_name: string;
  @GraphqlField()
  public product_name: string;

  @GraphqlField()
  public series_name: string;
  @GraphqlField()
  public address: string;
  @GraphqlField()
  public provider_name: string;
  @GraphqlField()
  public brand_name: string;

  constructor(obj: any) {
    super();
    Object.assign(obj);
  }
}
