import React from "react";
import { Row, Col, Avatar } from "antd";
import Icon from "../../../components/icon";
import { useTheme } from "styled-components";

type ArrowNavProps = {
  hasArrow?: boolean;
  children: React.ReactNode;
  leftIcon: React.ReactElement;
  onClick: () => void;
  id?: string;
};

export default function ArrowNav({
  hasArrow = true,
  children,
  leftIcon,
  onClick,
  id,
}: ArrowNavProps) {
  const { bussinessPrimaryColor } = useTheme();
  return (
    <div
      id={id}
      style={{
        marginBottom: 10,
        backgroundColor: "white",
        padding: "10px",
        borderRadius: 5,
      }}
      onClick={onClick}
    >
      <Row justify="space-between" align="middle">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            width: "calc(100% - 20px)",
          }}
        >
          <Avatar
            size={"small"}
            icon={React.cloneElement(leftIcon, {
              color: bussinessPrimaryColor,
            })}
            style={{
              backgroundColor: bussinessPrimaryColor,
              marginRight: "5px",
            }}
          />
          {children}
        </div>

        <Col span={1}>
          {hasArrow ? (
            <Icon
              type="iconzuo"
              style={{ color: bussinessPrimaryColor }}
            ></Icon>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </div>
  );
}
