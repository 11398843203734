import { createModel } from "@rematch/core";
import ProductClassification from "../models/ProductCategory";
import {
  GraphqlQuery,
  GraphqlQueryMulti,
  GraphqlMutation,
} from "../helpers/request.helper";
import { request } from "../helpers/postMessageHelper";
import head from "lodash/head";

export default createModel({
  state: {} as ProductClassification,
  reducers: {
    initClassification: (
      state: ProductClassification,
      classification: ProductClassification,
    ) => {
      return classification;
    },
  },
  effects: {
    async getClassificationAsync(payload, root: any) {
      if (window.parent !== window && location.search.indexOf("host_id=") < 0) {
        const data = await request<ProductClassification>(
          root.DesignIdea.gaeaDomain,
          "designidea.getCategories",
        );

        this.initClassification(data);
      } else {
        const args = {
          private: root.CommonData.isPrivate,
          shop_id: root.URLSearch.host_id,
          ...payload,
        };

        const data = await GraphqlQuery(
          "Categories",
          new ProductClassification(),
          { action: "designidea", ...args },
        );

        this.initClassification(head(data));
      }
    },
  },
});
