import React, { useContext, useState, useEffect } from "react";
import { Dispatch, RootState } from "../../store/configureStore";
import * as styles from "./index.scss";
import { Product, Sku, Activity } from "../../models/Product";
import { AgentRate } from "../../models/Shop";
import { useIntl, FormattedMessage } from "react-intl";
import { CommonProps } from "../../typings/component";
import { BusinessContext } from "../BusinessContext";
import Provider from "../../models/Provider";
import { Tag, Space } from "antd";
import Price from "../../components/highlight";
import Icon from "../../components/icon";
import { Link } from "../../wrappers/typography";
import { useTheme } from "styled-components";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
const ProductBaseInfo: React.FC<
  CommonProps & {
    isPreview: boolean;
    agentRates?: AgentRate[];
    productItem: Product;
    sku: Sku;
    currency: string;
    isToReview: boolean;
    dispatch: Dispatch;
    search: string;
    provider: Provider;
    size?: "normal" | "small";
  }
> = ({
  productItem,
  isLogin,
  sku,
  dispatch,
  provider,
  isPreview,
  size,
  agentRates = [],
}) => {
  const { formatMessage: f } = useIntl();
  const {
    bussinessPrimaryColor = "",
    primaryColor = "",
    linkColor = "",
  } = useTheme();
  const { selectedProvider, productActivities } = useSelector(
    (state: RootState) => state.PDPPage,
  );
  const { isToBusiness } = useContext(BusinessContext);
  const [isActivity, setIsActivity] = useState(false);
  const [activityInfo, setActivityInfo] = useState<Activity>();
  const [isActStart, setIsActStart] = useState(false);

  useEffect(() => {
    if (selectedProvider && productActivities) {
      const info = productActivities.filter(
        item =>
          Number(item.provider_id) === Number(selectedProvider.provider_id),
      );
      setIsActivity(info.length > 0);
      if (info.length > 0) {
        const startTime = new Date(info[0].start_time);
        const nowTime = new Date(info[0].now_time);

        const isStart = Number(startTime) - Number(nowTime) < 0;
        setIsActStart(isStart);
        if (isStart) {
          setActivityInfo(info[0]);
        }
      }
    }
  }, [selectedProvider, productActivities]);

  const tag_price =
      isActivity && isActStart
        ? activityInfo?.activity_price
        : isPreview
        ? productItem.tag_price
        : provider?.tag_price,
    tag_price_low = productItem.tag_price_low,
    tag_price_high = productItem.tag_price_high;

  const tag_price_value =
      isActivity && isActStart
        ? activityInfo?.activity_price_value
        : isPreview || isToBusiness
        ? productItem.tag_price_value
        : provider?.tag_price_value,
    original_price = isPreview
      ? productItem.original_price
      : provider?.original_price,
    original_price_value = isPreview
      ? productItem.original_price_value
      : provider?.original_price_value,
    tag_price_name = isPreview
      ? productItem.tag_price_name
      : provider?.tag_price_name;

  let rate = agentRates.find(r => r.shop_id === sku?.shop_id);

  if (!rate) {
    rate = agentRates.find(r => r.shop_id === 0);
  }

  return (
    <div
      className={styles[`productInfo`]}
      style={{ padding: size === "small" ? "0" : "" }}
    >
      <div className={styles[`productInfo-box`]}>
        <div
          className={styles[`productInfo-price`]}
          style={{
            fontSize: size === "small" ? "1.4em" : "",
            color: isActivity && isActStart ? primaryColor : "",
          }}
        >
          <Price text={tag_price_value} />
        </div>
        {!productItem.has_tag_price_range && original_price > tag_price && (
          <div className={styles[`productInfo-origin-price-box`]}>
            <div
              className={styles[`productInfo-origin-price`]}
              style={{ color: isActivity && isActStart ? "" : linkColor }}
            >
              {original_price_value}
            </div>

            {tag_price_name && (
              <Tag color={isToBusiness ? bussinessPrimaryColor : primaryColor}>
                {tag_price_name}
              </Tag>
            )}
          </div>
        )}
        {rate &&
          !isActivity &&
          (!isNaN(tag_price) ||
            (!isNaN(tag_price_low) && !isNaN(tag_price_high))) && (
            <div className={styles[`productInfo-agent`]}>
              <FormattedMessage id="ExpectedIncome" />：
              <span style={{ color: primaryColor }}>
                {productItem.has_tag_price_range
                  ? `¥${((tag_price_low * rate.commission_rate) / 100).toFixed(
                      2,
                    )} - ¥${(
                      (tag_price_high * rate.commission_rate) /
                      100
                    ).toFixed(2)}`
                  : `¥${((tag_price * rate.commission_rate) / 100).toFixed(2)}`}
              </span>
            </div>
          )}
        {activityInfo &&
          rate &&
          isActivity &&
          isActStart &&
          activityInfo.commission_rate &&
          activityInfo.has_commission_flag &&
          rate.agent_type === 2 &&
          (!isNaN(tag_price) ||
            (!isNaN(tag_price_low) && !isNaN(tag_price_high))) && (
            <div className={styles[`productInfo-agent`]}>
              <FormattedMessage id="ExpectedIncome" />：
              <span style={{ color: primaryColor }}>
                {productItem.has_tag_price_range
                  ? `¥${(
                      (tag_price_low * Number(activityInfo.commission_rate)) /
                      100
                    ).toFixed(2)} - ¥${(
                      (tag_price_high * Number(activityInfo.commission_rate)) /
                      100
                    ).toFixed(2)}`
                  : `¥${(
                      (tag_price * Number(activityInfo.commission_rate)) /
                      100
                    ).toFixed(2)}`}
              </span>
            </div>
          )}
      </div>
      {!isLogin && (
        <div className={styles["ProductParams-loginPrompt"]}>
          <Link
            onClick={() =>
              dispatch.User.changeUserState({
                modalAction: "Login",
              })
            }
            color={primaryColor}
            style={{ fontSize: "22px" }}
          >
            <Space>
              <Icon
                type="icongeren"
                style={{
                  color: isToBusiness ? bussinessPrimaryColor : primaryColor,
                  fontSize: "1.1em",
                }}
              />
              {f({ id: "LoginNow" })}
            </Space>
          </Link>
        </div>
      )}
    </div>
  );
};

export default ProductBaseInfo;
