import * as React from "react";
import Layout from "../Layout";
import Content from "./Content";
import { useSelector } from "react-redux";
import { RootState } from "../../store/configureStore";

const CartPage: React.FC = () => {
  const {
    breadcrumbs = [
      {
        url: "/",
        title: "首页",
      },
      {
        url: "/cart",
        title: "购物车",
      },
    ],
  } = useSelector((state: RootState) => state.AccountPage);

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Content />
    </Layout>
  );
};

export default CartPage;
