import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef,
} from "react";
import { useSelector } from "react-redux";
import * as styles from "./index.scss";
import Carousel from "../../wrappers/carousel";
import Image from "../../components/image";
import Player from "video-react";
import ImageZoom from "../../components/image-zoom";
import classnames from "classnames";
import IdeaCard from "../IdeaList/component/Card";
import { BusinessContext } from "../BusinessContext";
import { Row, Col } from "antd";
import { ProductProperty } from "../../models/Product";
import { DesignIdea } from "../../models/DesignIdea";
import { useTheme } from "styled-components";
import { RootState } from "../../store/configureStore";
import dayjs from "dayjs";
import { useCountDown } from "ahooks";
type ProductcontentState = {
  mediaList: (ProductProperty | { type: number; value: DesignIdea })[];
  onChangeImg: (media: number) => void;
  onChangeUserLike: (data: any) => void;
  activeIndex?: number;
};

const ProductShow: React.FC<ProductcontentState> = ({
  onChangeUserLike,
  mediaList,
  onChangeImg,
  activeIndex = 0,
}) => {
  const [countdown, setTargetDate, formattedRes] = useCountDown();
  const { days, hours, minutes, seconds, milliseconds } = formattedRes;

  const { primaryColor, bussinessPrimaryColor } = useTheme();
  const { isToBusiness } = useContext(BusinessContext);

  const { selectedProvider, productActivities } = useSelector(
    (state: RootState) => state.PDPPage,
  );
  const [isAct, setIsAct] = useState(false);
  const [isActStart, setIsActStart] = useState(false);
  const [activityInfo, setActivityInfo] = useState<any>();

  useEffect(() => {
    if (
      isAct &&
      activityInfo &&
      isActStart &&
      days === 0 &&
      hours === 0 &&
      minutes === 0 &&
      seconds === 0
    ) {
      window.location.reload();
    }
  }, [days, hours, minutes, seconds]);

  useEffect(() => {
    if (selectedProvider && productActivities) {
      const activityInfos = productActivities.filter(
        item =>
          Number(item.provider_id) === Number(selectedProvider.provider_id),
      );
      setIsAct(activityInfos.length > 0);
      if (activityInfos.length > 0) {
        const startTime = new Date(activityInfos[0].start_time);
        const nowTimes = new Date(activityInfos[0].now_time);
        const isStart = Number(startTime) - Number(nowTimes) < 0;
        setIsActStart(isStart);
        if (isStart) {
          setActivityInfo(activityInfos[0]);

          const { now_time, end_time } = activityInfos[0];
          const mineTime = new Date(dayjs().format("YYYY-MM-DD HH:mm:ss"));

          const timeStamp =
            parseInt(String(new Date(mineTime).getTime() / 1000)) +
            (parseInt(String(new Date(end_time).getTime() / 1000)) -
              parseInt(String(new Date(now_time).getTime() / 1000)));

          setTargetDate(getformatTime(timeStamp));
        }
      }
    }
  }, [selectedProvider, productActivities]);

  const getformatTime = (timeStamp: any) => {
    const time = new Date(timeStamp * 1000);
    const Y = time.getFullYear();
    const M = (time.getMonth() + 1).toString().padStart(2, "0");
    const D = time.getDate().toString().padStart(2, "0");
    const h = time.getHours().toString().padStart(2, "0");
    const m = time.getMinutes().toString().padStart(2, "0");
    const s = time.getSeconds().toString().padStart(2, "0");
    return `${Y}-${M}-${D} ${h}:${m}:${s}`;
  };

  const productCarouselRef = useRef(null),
    mediaCarouselRef = useRef(null);

  const [carouselIndex, setCarouselIndex] = useState(0);

  const handleIndexChange = useCallback(
    index => {
      setCarouselIndex(index);
      onChangeImg(index);
      productCarouselRef.current?.goTo(index);
    },
    [mediaList],
  );

  useEffect(() => {
    handleIndexChange(activeIndex);
  }, [activeIndex]);

  const renderProductPicture = () => {
    return (
      <Carousel
        afterChange={setCarouselIndex}
        arrows
        dots={false}
        slidesToScroll={1}
        slidesToShow={1}
        infinite={false}
        hoverable
        className="product-detail-carousel"
        style={{ border: "1px solid #D4D4D4" }}
        ref={productCarouselRef}
      >
        {mediaList.map((media, pIndex) => {
          switch (media.type) {
            // case 2:
            //   return <Player playsInline src={media.value} />;

            // case 6:
            //   return (
            //     <div key={pIndex}>
            //       <div className="product-feature-content">
            //         {Array.isArray(media.value) &&
            //           media.value.map((item, index) => {
            //             return <img src={item.value} key={index} />;
            //           })}
            //       </div>
            //     </div>
            //   );

            case 7:
              const item = media.value;
              return (
                <div key={pIndex}>
                  <div className="product-idea-card">
                    <IdeaCard
                      src={item.design_idea_picture}
                      key={item.design_idea_id}
                      name={item.design_idea_name}
                      shopName={item.shop_name}
                      url={item.url}
                      tags={item.coordinates}
                      isCollect={item.is_collect}
                      onChangeUserLike={() =>
                        onChangeUserLike({
                          action: item.is_collect ? "cancel" : "like",
                          object: "designIdea",
                          data_id: item.design_idea_id,
                        })
                      }
                      shopUrl={`${isToBusiness ? "/tob" : ""}${item.shop_url}`}
                      showTag
                      outsideRef
                      showTitle={false}
                    />
                  </div>
                </div>
              );
            default:
              return (
                <div key={pIndex}>
                  <div className="product-main-picture">
                    <ImageZoom
                      imgSrc={media.value}
                      largeImgSrc={media.value}
                      useBackgroundImage={false}
                    />
                  </div>
                </div>
              );
          }
        })}
      </Carousel>
    );
  };

  const renderProductMedia = () => {
    return (
      <div className={styles["Productcontent-media-carousel"]}>
        <Carousel
          dots={false}
          slidesToShow={6}
          slidesToScroll={1}
          infinite={false}
          arrows
          ref={mediaCarouselRef}
          hoverable
        >
          {mediaList.map((item, index) => {
            switch (item.type) {
              case 7: {
                return (
                  <div key={index}>
                    <div
                      style={{
                        borderColor:
                          index === carouselIndex
                            ? isToBusiness
                              ? bussinessPrimaryColor
                              : primaryColor
                            : "",
                      }}
                      className={styles["Productcontent-media-carousel-item"]}
                      onClick={() => handleIndexChange(index)}
                    >
                      <Image
                        src={item.value.design_idea_picture}
                        width={1}
                        height={1}
                      />
                    </div>
                  </div>
                );
              }
              default: {
                return (
                  <div key={index}>
                    <div
                      style={{
                        borderColor:
                          index === carouselIndex
                            ? isToBusiness
                              ? bussinessPrimaryColor
                              : primaryColor
                            : "",
                      }}
                      className={styles["Productcontent-media-carousel-item"]}
                      onClick={() => handleIndexChange(index)}
                    >
                      <Image src={item.value} width={1} height={1}></Image>
                    </div>
                  </div>
                );
              }
            }
          })}
        </Carousel>
      </div>
    );
  };

  return (
    <Row gutter={10} className={styles["ProductShow"]}>
      <Col
        span={24}
        style={{
          position: "relative",
        }}
      >
        {renderProductPicture()}
        {isAct && isActStart && (
          <div
            className={styles["ProductShow_activity"]}
            style={{
              background: primaryColor,
            }}
          >
            <div className={styles["ProductShow_activity-left"]}>
              {activityInfo?.activity_type_text}
            </div>
            <div className={styles["ProductShow_activity-right"]}>
              <Row className={styles["ProductShow_activity-right-top"]}>
                距离结束还剩
              </Row>
              <Row className={styles["ProductShow_activity-right_time"]}>
                {`${days} 天 ${hours} 时 ${minutes} 分 ${seconds} 秒`}
              </Row>
            </div>
          </div>
        )}
      </Col>
      <Col span={24}>{renderProductMedia()}</Col>
    </Row>
  );
};

export default React.memo(ProductShow);
