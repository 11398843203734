import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "../../../store/configureStore";
import {
  DragDropContext,
  DropResult,
  Droppable,
  DraggableLocation,
  DroppableProvided,
  DraggableProvided,
  Draggable,
  DraggableStateSnapshot,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";

import Renderer, { RenderItem } from "../../../components/irender";
import Icon from "../../../components/icon";
import GroupItem from "./GroupItem";
import styles from "../style.scss";

export interface GroupListProps {
  dragIndex: number;
  index: number;

  renderItems: RenderItem[];

  setRenderItems: (value: React.SetStateAction<RenderItem[]>) => void;

  render?: Renderer;

  selected: boolean;

  group: string[];

  setGroupList: (value: React.SetStateAction<string[][]>) => void;

  setEditedItem: (value: React.SetStateAction<RenderItem>) => void;
}

const GroupList: React.FC<GroupListProps> = ({
  dragIndex,
  index,
  group,
  renderItems,
  render,
  setGroupList,
  setRenderItems,
  setEditedItem,
  selected,
}) => {
  const [expaned, setExpanded] = useState(true);

  return (
    <Draggable draggableId={`group_${index}`} index={dragIndex} key={dragIndex}>
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <div
            style={{
              outline: `2px solid ${selected ? "#348fff" : "transparent"}`,
            }}
          >
            <div
              className={styles["designer-grouped-title"]}
              {...provided.dragHandleProps}
            >
              <a
                className={styles["designer-grouped-title-show"]}
                onClick={() => {
                  const target = renderItems.find(i => i.id === group[0]);

                  render?.update(target?.id, {
                    show: !target?.show,
                  });
                  render?.restore();
                  setGroupList([...render?.sets]);
                  setRenderItems([...render?.objects]);
                }}
              >
                <Icon
                  type={
                    renderItems.findIndex(i => group.includes(i.id) && i.show) >
                    -1
                      ? "iconxianshi"
                      : "iconbuxianshi1"
                  }
                />
              </a>
              <div className={styles["designer-grouped-title-folder"]}>
                <a onClick={() => setExpanded(val => !val)}>
                  <Icon type="iconjiantou" rotate={expaned ? 0 : -90} />
                  <Icon type="iconsucai" style={{ marginLeft: "3px" }} />
                </a>
                <a
                  onClick={() => {
                    const target = renderItems.find(
                      i => group.includes(i.id) && i.show,
                    );
                    render?.update(target?.id, {
                      isEditable: !target?.isEditable,
                    });
                    render?.restore();
                    setGroupList([...render?.sets]);
                    setRenderItems([...render?.objects]);
                  }}
                >
                  <Icon
                    type={
                      renderItems.findIndex(
                        i => group.includes(i.id) && !i.isEditable,
                      ) > -1
                        ? "iconsuo1"
                        : "iconsuo"
                    }
                  />
                </a>
              </div>
            </div>
            <Droppable droppableId={`group_${index}`} type="ITEM">
              {(
                dropProvided: DroppableProvided,
                dropSnapshot: DroppableStateSnapshot,
              ) => (
                <div
                  ref={dropProvided.innerRef}
                  {...dropProvided.droppableProps}
                  style={{ display: expaned ? "" : "none" }}
                >
                  {group.map((id, idx) => {
                    const item = renderItems.find(o => o.id === id);
                    return (
                      <GroupItem
                        id={id}
                        index={idx}
                        item={item}
                        setGroupList={setGroupList}
                        setRenderItems={setRenderItems}
                        setEditedItem={setEditedItem}
                        render={render}
                        groupIndex={index}
                        key={idx}
                      ></GroupItem>
                    );
                  })}
                  {dropProvided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default React.memo(GroupList);
