/* tslint:disable */
/**
 *
 * MegaHome Scheduler
 * Schedules tasks to run at various times in the page lifecycle
 *
 * DO USE THIS: for tasks that can wait until the essential parts of the page are loaded
 * DO NOT USE THIS: in place of lazy loading or event specific tasks
 *
 *
 * Order of events
 * 1. megahomeDone
 * 2. postLoad
 * 3. delayed
 *
 *
 * Example Usage (public functions)
 * megahome_scheduler.queueMegahomeDoneTask(myTask);
 * megahome_scheduler.queuePostLoadTask(myTask, this);
 * megahome_scheduler.queueDelayedTask(myTask, this, 0);
 *
 *
 * @author    Hardys Hu <hardyshu@imegahome.com>
 * @copyright 2018 MegaHome LLC - All rights reserved
 */
import EventDispatch from "./event_dispatch";

export default (function () {
  var sortBy = require("lodash/sortBy");

  var WAYFAIR_DONE = "WAYFAIR_DONE";

  // var routerEventsPromise = require("lazy!megahome_router_events");

  // amount of time after page load to run delayed items
  var DELAY_TIME = 5000;
  var megahomeScheduler = {},
    // megahome done is to wire up event handlers and
    // handle functionality that's above the fold - needs to happen right away
    _megahomeDone = [],
    // delayed tasks are things that need the entire dom (including image sizes and such),
    // tasks that are below the fold, or not required for functionality on the page
    _postLoad = [],
    // delayed tasks are executed after everything has loaded and the user has remained on
    // the page indicating interest or potential interaction
    _delayed = [],
    // indicators to say when a queue has ran
    _megahomeDoneRan = false,
    _postLoadRan = false,
    _delayedRan = false; // ********* private functions **********

  /**
   * Adds a task to the specified queue
   *
   * @param {Array<Object>} the queue to add the function to
   * @param {Function} task - the function to run at "critical" time
   * @param {Object} context - the context in which to run the task
   * @param {Number} the priority of when the task is to fire - defaults to infinity
   */
  function _addEvent(queue, task, queueIndicator, context, priority) {
    if (typeof task !== "function") {
      // todo: log error with registering the task here
      console.warn(
        "megahome_scheduler _addEvent: Unable to queue a task that is not a function: " +
          task,
      );
      return;
    } // default context to window

    context = context || window; // if this queue was already run, execute the task immediately

    if (queueIndicator) {
      task.apply(context);
    } // default priority to infinity, allow for zero

    if (priority == null) {
      priority = Infinity;
    } // add the new task object onto the queue

    queue.push({
      task: task,
      context: context,
      priority: priority,
    });
  }
  /**
   * convenience method to run all functions in the megahomeDone queue
   */

  function _runMegahomeDone() {
    if (window.__phantomas != null) {
      window.__phantomas.setMetric(
        "scheduler.MegahomeDoneStart",
        window.performance.now(),
      );
    }

    _megahomeDoneRan = true;
    _runTasks(_megahomeDone);
  }
  /**
   * convenience method to run all functions in the postLoad queue
   */

  function _runPostLoad() {
    if (window.__phantomas != null) {
      window.__phantomas.setMetric(
        "scheduler.PostLoadStart",
        window.performance.now(),
      );
    }

    _postLoadRan = true;
    _runTasks(_postLoad);
  }
  /**
   * convenience method to run all functions in the delayed queue
   */

  function _runDelayed() {
    if (window.__phantomas != null) {
      window.__phantomas.setMetric(
        "scheduler.DelayedStart",
        window.performance.now(),
      );
    }

    _delayedRan = true;
    _runTasks(_delayed);
  }
  /**
   * run all functions in the given queue
   */
  function _runTasks(queue) {
    var item; // sort the queue by priority
    queue = sortBy(queue, function (task) {
      return task.priority;
    }); // execute all the functions in the queue using the proper context

    while (queue.length) {
      item = queue.shift(); // safely execute each task and log if there was any problem

      try {
        item.task.apply(item.context);
      } catch (e) {
        // log the error but don't prevent the rest of the tasks from running
        console.error(
          "megahome_scheduler - a queued function threw an error: " +
            e.message +
            ": `" +
            item.task.toString().replace(/\n+/g, "").substr(0, 100) +
            "`",
        );
      }
    }
  } // ********* public functions **********

  /**
   * convenience method - pass-through for _addEvent
   *
   * @param {Function} task - the function to run at "delayed" time
   * @param {Object} context - the context in which to run the task
   *                         - defaults to window
   * @param {Number} priority - priority of when the event is to fire
   *                          - defaults to infinity, lower number are fired first
   */
  megahomeScheduler.queueMegahomeDoneTask = function (task, context, priority) {
    _addEvent(_megahomeDone, task, _megahomeDoneRan, context, priority);
  };
  /**
   * convenience method - pass-through for _addEvent
   *
   * @param {Function} task - the function to run at "delayed" time
   * @param {Object} context - the context in which to run the task
   *                         - defaults to window
   * @param {Number} priority - priority of when the event is to fire
   *                          - defaults to infinity, lower number are fired first
   */
  megahomeScheduler.queuePostLoadTask = function (task, context, priority) {
    // if window load already ran but our queue did not we'll assume
    // that this task should be run on megahomeDone
    if (document.readyState === "complete" && !_postLoadRan) {
      megahomeScheduler.queueMegahomeDoneTask(task, context, priority);
    } else {
      _addEvent(_postLoad, task, _postLoadRan, context, priority);
    }
  };

  /**
   * convenience method - pass-through for _addEvent
   *
   * @param {Function} task - the function to run at "delayed" time
   * @param {Object} context - the context in which to run the task
   *                         - defaults to window
   * @param {Number} priority - priority of when the event is to fire
   *                          - defaults to infinity
   */
  megahomeScheduler.queueDelayedTask = function (task, context, priority) {
    _addEvent(_delayed, task, _delayedRan, context, priority);
  };
  /**
   * convenience method - pass-through for _addEvent
   *
   * @param {Function} task - the function to run at "delayed" time
   * @param {Object} context - the context in which to run the task
   *                         - defaults to window
   * @param {Number} priority - priority of when the event is to fire
   *                          - defaults to infinity
   */
  megahomeScheduler.queueMegahomeReadyTask = function (
    task,
    context,
    priority,
  ) {
    _addEvent(_delayed, task, _delayedRan, context, priority);
  }; // expose the number of events queued for testing purposes
  megahomeScheduler.getTaskCount = function () {
    return _delayed.length + _postLoad.length + _megahomeDone.length;
  }; // expose a destroy function for testing purposes
  megahomeScheduler.init = function () {
    _delayed = [];
    _postLoad = [];
    _megahomeDone = [];
  };

  if (typeof window !== "undefined") {
    // run MegaHome done event on MegaHome done
    EventDispatch.on(WAYFAIR_DONE, {}, function () {
      _runMegahomeDone();
    });
    /**
     * SPA hook - reinit the queues when we're going to a new page
     */

    // routerEventsPromise.done(routerEvents => {
    //   routerEvents.onRouteChangeStarted(function() {
    //     megahomeScheduler.init();
    //     _megahomeDoneRan = false;
    //     _postLoadRan = false;
    //   });
    // });
    // /**
    //  * SPA hook - run the megahomeDone and postLoad queue on load
    //  */

    // routerEventsPromise.done(routerEvents => {
    //   routerEvents.onViewLoaded(function() {
    //     _runMegahomeDone();

    //     _runPostLoad();
    //   });
    // }); // run postLoad after page load

    window.onload = function () {
      setTimeout(_runPostLoad, 0); // run delayed after a set amount of time after window load

      setTimeout(_runDelayed, DELAY_TIME);
    };
  } // ********* bind event handlers *********
  megahomeScheduler.init();
  return megahomeScheduler;
})();
