import React, { useState, useEffect } from "react";
import { AddressesState } from "./models";
import { useIntl } from "react-intl";
import Icon from "../../components/icon";
import { Row, Col, Cascader, Form } from "antd";
import Modal from "../../wrappers/modal";
import Checkbox from "../../wrappers/checkbox";
import { PrimaryButton } from "../../wrappers/button";
import Input from "../../wrappers/input";
import { addressDatas } from "../../helpers/addressData";
import { useTheme } from "styled-components";

const ThemeForm = ({ handleSubmit, addressData }: any) => {
  const { formatMessage } = useIntl();

  const [form] = Form.useForm();
  const [isDefault, setDefault] = useState(false);
  const [addresss, setAddress] = useState({});
  const [defaultAddresss, setdefaultAddresss] = useState([]);

  useEffect(() => {
    form.setFieldsValue({
      recipient_company: "",
      recipient_email: "",
      address_details: "",
    });
    if (addressData) {
      setDefault(addressData.is_default);
      form.setFieldsValue({
        province: addressData.province,
        city: addressData.city,
        area: addressData.area,
        recipient_telephone: addressData.recipient_telephone,
        recipient_name: addressData.recipient_name,
        recipient_company: addressData.recipient_company,
        recipient_email: addressData.recipient_email,
        address_details: addressData.address_details,
        is_default: addressData.is_default === 1 ? true : false,
        address_country: [
          addressData.province,
          addressData.city,
          addressData.area,
        ],
      });
      setAddress({
        province: addressData.province,
        city: addressData.city,
        area: addressData.area,
      });
    }
  }, [addressData]);

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleSubmitForm = (value: any) => {
    value.is_default = isDefault;
    const formValue = { ...value, ...addresss };
    delete formValue.address_country;
    handleSubmit(formValue);
  };

  const handleCheckBoxChange = (e: any) => {
    setDefault(e.target.checked);
  };

  const handleAddressChange = (value: any) => {
    if (value && value.length) {
      const addr = {
        province: value[0],
        city: value[1],
        area: value[2],
      };
      setAddress(addr);
    }
  };

  const { primaryColor = "", bussinessPrimaryColor = "" } = useTheme();
  const requiredText = formatMessage({
    id: "Required",
  });
  return (
    <Form
      name="moonlight"
      form={form}
      initialValues={{ remember: true }}
      onFinish={handleSubmitForm}
      onFinishFailed={onFinishFailed}
      style={{ padding: "20px" }}
    >
      <h4>{formatMessage({ id: "Contactor" })} </h4>
      <Form.Item
        name="recipient_name"
        rules={[{ required: true, message: requiredText }]}
      >
        <Input
          placeholder={`${formatMessage({
            id: "Consignee",
          })} *`}
        />
      </Form.Item>
      <h4>{formatMessage({ id: "CellphoneNumber" })}</h4>
      <Row gutter={10}>
        <Col span={24}>
          <Form.Item
            name="recipient_telephone"
            rules={[
              { required: true, message: requiredText },
              {
                pattern: /^1[3|4|5|7|8][0-9]{9}$/,
                message: formatMessage({ id: "PleaseEnterTelePhone" }),
              },
            ]}
          >
            <Input
              placeholder={`${formatMessage({
                id: "CellphoneNumber",
              })}*`}
            />
          </Form.Item>
        </Col>
      </Row>

      <h4>{formatMessage({ id: "Email" })}</h4>
      <Row gutter={10}>
        <Col span={24}>
          <Form.Item name="recipient_email">
            <Input
              placeholder={`${formatMessage({
                id: "Email",
              })}`}
            />
          </Form.Item>
        </Col>
      </Row>

      <h4>{formatMessage({ id: "FirmName" })}</h4>
      <Row gutter={10}>
        <Col span={24}>
          <Form.Item name="recipient_company">
            <Input
              placeholder={`${formatMessage({
                id: "FirmName",
              })}`}
            />
          </Form.Item>
        </Col>
      </Row>

      <h4>{formatMessage({ id: "Address" })} </h4>
      <Form.Item name="address_country">
        <Cascader
          options={addressDatas}
          defaultValue={defaultAddresss}
          placeholder={formatMessage({ id: "PleaseSelectOptions" })}
          onChange={val => {
            handleAddressChange(val);
          }}
          // style={{ background: "#EAEAEA" }}
        ></Cascader>
      </Form.Item>
      <Form.Item name="address_details">
        <Input
          placeholder={`${formatMessage({
            id: "DetailedAddressInfo",
          })}`}
        />
      </Form.Item>
      <Row>
        <Col span={24}>
          <Form.Item name="is_default">
            <Checkbox
              shape="circle"
              borderWidth="2px"
              color={bussinessPrimaryColor}
              checked={isDefault}
              // defaultChecked={isDefault ||isDefaultAddr}
              onChange={e => handleCheckBoxChange(e)}
            >
              {formatMessage({
                id: "SetAsDefault",
              })}
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={16}></Col>
        <Col span={8} style={{ textAlign: "right" }}>
          <PrimaryButton
            htmlType="submit"
            block
            bgColor={bussinessPrimaryColor}
          >
            {formatMessage({ id: "Submit" })}
            <Icon type="icont1_correct" />
          </PrimaryButton>
        </Col>
      </Row>
    </Form>
  );
};

export type AddressFormProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (payload: TobAddressValuesType) => void;
  addressData?: AddressesState["tobAddress"][0];
  classNamePrefix?: "" | "mobile";
};

export type TobAddressValuesType = {
  // 和后端字段保持一致
  province: string;
  city: string;
  area: string;
  recipient_name: string;
  recipient_telephone: string;
  address_details: string;
  is_default: boolean | number;
};

const AddressForm: React.FC<AddressFormProps> = ({
  classNamePrefix,
  onClose,
  onSubmit,
  isOpen,
  addressData,
}) => {
  const { formatMessage } = useIntl();
  const { bussinessPrimaryColor } = useTheme();

  const handleSubmit = (values: TobAddressValuesType) => {
    const data = { ...values, callback: onClose };

    if (values.is_default) {
      data.is_default = 1;
    } else {
      data.is_default = 0;
    }

    onSubmit(data);
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={onClose}
      width={560}
      title={formatMessage({
        id: addressData ? "EditShippingAddress" : "AddShippingAddress",
      })}
      footer={null}
      bgColor="#f7f7f7"
      color={bussinessPrimaryColor}
    >
      <ThemeForm handleSubmit={handleSubmit} addressData={addressData} />
    </Modal>
  );
};

export default AddressForm;
