import { createModel } from "@rematch/core";
import { GraphqlQuery, GraphqlQueryMulti } from "../../helpers/request.helper";
import { getFirstItem } from "../../helpers/modelHelpers";
import { ShopActivity } from "../../models/Shop";

export interface StateType {
  breadcrumbs: { url: string; title: string }[];
  collection: number[];
  activityInfo: ShopActivity;
  shop_id: number;
}

export default createModel({
  state: {} as StateType,
  reducers: {
    initProvider: (state: StateType, activityInfo: ShopActivity) => ({
      ...state,
      activityInfo,
    }),
  },

  effects: {
    /**
     * 初始化获取活动信息
     */
    async initActivityAsync(payload) {
      const { callback, ...rest } = payload;
      const data = await GraphqlQuery("ShopActivity", new ShopActivity({}), {
        action: "getActivities",
        ...rest,
      });
      this.initProvider(data);
      if (callback) {
        callback(data);
      }
    },
  },
});
