import { createModel } from "@rematch/core";

export interface PayResultType {
  order_number: string; // 订单编号
  recipient_name: string; // 收件人
  recipient_address: string; // 收件人地址
  recipient_telephone: string; // 收件人电话
  total_amount: string; // 总价
}

export default createModel({
  state: {
    order_number: "asidhaskdasdas",
    recipient_name: "收件人一号",
    recipient_address: "收件人的私密地址",
    recipient_telephone: "收件人的小电话",
    total_amount: "¥333333.36",
  } as PayResultType,
  reducers: {},
});
