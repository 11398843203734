import { GraphqlField, GraphqlRefField } from "../helpers/decorators";
import BaseModel from "./BaseModel";
import DeliveryScope from "./Delivery";

export enum SkuType {
  Retail = 1,
  GroupPurchase = 2,
}

export class Media extends BaseModel {
  @GraphqlField("default", "SearchList", "Provider")
  public media_type: string;
  @GraphqlField("default", "SearchList", "Provider")
  public media_url: string;

  @GraphqlField("default", "SearchList", "Provider")
  public product_media_id: number;

  @GraphqlField("Provider")
  public activity_type: number;
  @GraphqlField("Provider")
  public activity_type_text: string;
}

export class ProductProperty extends BaseModel {
  @GraphqlField("ProductList")
  public title: string;
  @GraphqlField("default", "ProductList")
  public value: string;

  public type: number;

  @GraphqlField("IdeaProduct")
  public media_url: string;

  @GraphqlField("IdeaProduct")
  public is_transparency: boolean;
}

export class ProductCatalog {
  @GraphqlField()
  public category_id: number;
  @GraphqlField("default", "IdeaProduct")
  public title: string;
  @GraphqlField()
  public category_sub_id: number;
  @GraphqlField()
  public category_sub_name: string;
  @GraphqlField()
  public category_value_id: number;
  @GraphqlField()
  public attribution_value: string;
  @GraphqlField()
  public is_option: boolean;

  public productId: number;
  public id: string;
  public price: string;
  public key: string;
  @GraphqlField("IdeaProduct")
  public value: string;
  public imgSrc: string;
}

export class ProductPrice extends BaseModel {
  @GraphqlField()
  public quantity_low: number;
  @GraphqlField()
  public quantity_high: number;
  @GraphqlField()
  public price: string;
}
export class Product extends BaseModel {
  @GraphqlField("Provider", "ShareCart")
  public provider_id: number; // 服务点id
  @GraphqlField(
    "default",
    "RecommendProduct",
    "SkuList",
    "SearchList",
    "tobCart",
    "cart",
    "ideaDetail",
    "ProductCollect",
    "ShareCart",
    "IdeaProduct",
    "Provider",
    "ShopActivity",
  )
  public shop_product_id: number; // 单品ID
  @GraphqlField("default", "IdeaProduct")
  public tag_price_name: string; //标签价格名
  @GraphqlField(
    "default",
    "RecommendProduct",
    "SkuList",
    "SearchList",
    "tobCart",
    "cart",
    "ideaDetail",
    "ProductCollect",
    "ShareCart",
    "IdeaProduct",
    "Provider",
    "ShopActivity",
  )
  public original_price_value: string; //划线价（原价）
  @GraphqlField(
    "default",
    "RecommendProduct",
    "SkuList",
    "SearchList",
    "ideaDetail",
    "ProductCollect",
    "ShareCart",
    "IdeaProduct",
    "Provider",
    "ShopActivity",
  )
  public tag_price_value: string; //标签价

  @GraphqlField(
    "default",
    "RecommendProduct",
    "SkuList",
    "SearchList",
    "tobCart",
    "cart",
    "ideaDetail",
    "ProductCollect",
    "IdeaProduct",
    "Provider",
    "ShopActivity",
  )
  public original_price: number;

  @GraphqlField(
    "default",
    "RecommendProduct",
    "SkuList",
    "SearchList",
    "tobCart",
    "cart",
    "ProductCollect",
    "ShareCart",
    "IdeaProduct",
    "ideaDetail",
    "Provider",
    "ShopActivity",
  )
  public tag_price: number;

  @GraphqlField(
    "default",
    "RecommendProduct",
    "SkuList",
    "SearchList",
    "tobCart",
    "cart",
    "ShareCart",
    "IdeaProduct",
    "ideaDetail",
  )
  public has_tag_price_range: number;

  @GraphqlField(
    "default",
    "RecommendProduct",
    "SkuList",
    "SearchList",
    "tobCart",
    "ideaDetail",
    "ShareCart",
    "IdeaProduct",
  )
  public tag_price_low: number;
  @GraphqlField(
    "default",
    "RecommendProduct",
    "SkuList",
    "SearchList",
    "tobCart",
    "ideaDetail",
    "ShareCart",
    "IdeaProduct",
  )
  public tag_price_high: number;
  @GraphqlField(
    "default",
    "RecommendProduct",
    "SkuList",
    "SearchList",
    "tobCart",
    "cart",
    "ideaDetail",
    "ProductCollect",
    "ShareCart",
    "IdeaProduct",
    "Provider",
    "Provider",
    "ShopActivity",
  )
  public product_name: string; // 单品名称
  @GraphqlField(
    "default",
    "RecommendProduct",
    "SkuList",
    "SearchList",
    "tobCart",
    "cart",
    "ideaDetail",
    "ProductCollect",
    "ShareCart",
    "IdeaProduct",
    "Provider",
    "ShopActivity",
  )
  public product_picture: string; // 单品默认URL
  @GraphqlField(
    "default",
    "RecommendProduct",
    "SearchList",
    "ideaDetail",
    "ProductCollect",
    "ShareCart",
    "IdeaProduct",
    "Provider",
    "ShopActivity",
    "cart",
  )
  public url: string; //单品链接地址
  @GraphqlField("default")
  public product_description: string;
  @GraphqlField("default")
  public feature_description: string; // 特征图描述
  @GraphqlField("default", "tobCart", "cart", "ideaDetail")
  public product_moq: number;

  @GraphqlRefField(ProductCatalog, "default", "IdeaProduct")
  public specification: ProductCatalog[] = [];

  @GraphqlField(
    "RecommendProduct",
    "SearchList",
    "tobCart",
    "cart",
    "ideaDetail",
    "ShareCart",
    "Provider",
    "ShopActivity",
  )
  public shop_sku_id: string;
  @GraphqlField(
    "RecommendProduct",
    "SearchList",
    "ideaDetail",
    "ProductCollect",
    "IdeaProduct",
  )
  public shop_id: number;
  @GraphqlField(
    "RecommendProduct",
    "SearchList",
    "ideaDetail",
    "ProductCollect",
    "IdeaProduct",
  )
  public shop_name: string;

  @GraphqlField(
    "RecommendProduct",
    "SearchList",
    "ideaDetail",
    "ProductCollect",
    "IdeaProduct",
    "cart",
  )
  public shop_url: string;

  @GraphqlField(
    "SkuList",
    "SearchList",
    "tobCart",
    "cart",
    "ProductCollect",
    "Provider",
    "ShopActivity",
  )
  public customer_rating: number;

  @GraphqlField(
    "SearchList",
    "tobCart",
    "cart",
    "ProductCollect",
    "ShareCart",
    "IdeaProduct",
    "Provider",
    "ShopActivity",
  )
  public series_name: string;

  @GraphqlField(
    "SearchList",
    "ProductCollect",
    "ShareCart",
    "IdeaProduct",
    "Provider",
    "ShopActivity",
  )
  public brand_name: string;

  @GraphqlField("SearchList")
  public is_new: string;

  @GraphqlField("SearchList", "ProductCollect")
  public is_discount: string;
  @GraphqlField("cart", "SkuList", "SearchList")
  public activity_type: number;
  @GraphqlField("cart", "SkuList", "SearchList")
  public activity_type_text: string;
  @GraphqlField("cart")
  public activity_price: string;

  @GraphqlField("cart")
  public has_commission_flag: number;
  @GraphqlField("cart")
  public commission_rate: string;

  @GraphqlField("SearchList", "ProductCollect")
  public sku_type: number;

  @GraphqlField("default", "cart")
  public logistics_delivery_text: string;

  @GraphqlField("default")
  public logistics_remark: string;

  @GraphqlRefField(
    Media,
    "SearchList",
    "ProductCollect",
    "Provider",
    "ShopActivity",
  )
  public media_list: Media[] = [];
  @GraphqlField("cart")
  public store_cart_id: number;
  @GraphqlField("tobCart")
  public tob_cart_id: number;

  @GraphqlField("tobCart", "cart", "ShareCart")
  public attribute: string;
  @GraphqlField("tobCart", "cart", "ShareCart")
  public quantity: number;
  @GraphqlField("tobCart", "cart")
  public inventory_quantity: number;
  @GraphqlField("tobCart")
  public agent_code: number;
  @GraphqlField("tobCart", "cart", "ProductCollect", "ShareCart")
  public is_active: number;
  @GraphqlRefField(ProductPrice, "tobCart", "cart")
  public product_price: ProductPrice[] = [];

  @GraphqlField("ideaDetail")
  public is_collect: number;

  @GraphqlField("ShareCart")
  public selected: boolean;
  @GraphqlField("cart")
  public provider_name: number;
  @GraphqlField("ShareCart")
  public agent_income: string;
  @GraphqlField("IdeaProduct")
  public product_option: string;

  @GraphqlRefField(ProductProperty, "IdeaProduct")
  public mediaData: ProductProperty[] = [];

  @GraphqlRefField(DeliveryScope, "cart")
  public delivery_info: DeliveryScope[] = [];
  /**
   *
   */
  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}
export class Sku extends BaseModel {
  @GraphqlField("default", "SkuList")
  public shop_sku_id: number;

  @GraphqlField("default", "SkuList")
  public shop_id: number;
  @GraphqlField("default", "SkuList")
  public shop_name: string;

  @GraphqlField("default", "SkuList")
  public shop_url: string;

  @GraphqlField()
  public sku_sn: string;
  @GraphqlField()
  public sku_name: string;
  @GraphqlField("default", "SkuList")
  public brand_name: string;
  @GraphqlField("default", "SkuList")
  public series_name: string;
  @GraphqlField("default", "SkuList")
  public top_category_id: number; //一级分类
  @GraphqlField("default", "SkuList")
  public parent_category_id: number; //二级分类
  @GraphqlField("default", "SkuList")
  public product_category_id: number; //三级分类
  @GraphqlField("default", "SkuList")
  public fourth_category_id: number; //四级分类
  @GraphqlField("default", "SkuList")
  public default_product_id: number; //默认单品ID

  @GraphqlField("SkuList", "tobCart", "cart")
  public url: string; //sku地址

  @GraphqlField("SkuList")
  public is_new: number;
  @GraphqlField("SkuList")
  public sku_type: number;

  @GraphqlRefField(Product, "default", "SkuList")
  public product: Product[] = [];

  /**
   *
   */
  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}

export class ProductDetail extends BaseModel {
  @GraphqlField("default", "ProductList")
  public shop_product_id: number; // 单品ID

  // @GraphqlRefField(ProductProperty)
  // public shipData: ProductProperty[] = [];

  @GraphqlRefField(ProductProperty)
  public featureData: ProductProperty[] = [];
  @GraphqlRefField(ProductProperty)
  public mediaData: ProductProperty[] = [];

  @GraphqlRefField(ProductProperty, "ProductList")
  public specification: ProductProperty[] = [];

  /**
   *
   */
  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}

export class Activity extends BaseModel {
  @GraphqlField()
  public activity_price: number; // 活动价格
  @GraphqlField()
  public activity_price_value: string; // 活动价格
  @GraphqlField()
  public activity_type_text: string; // 活动类型
  @GraphqlField()
  public activity_id: number; // 活动价格
  @GraphqlField()
  public activity_name: string; // 活动名称
  @GraphqlField()
  public start_time: string; // 活动开始时间
  @GraphqlField()
  public end_time: string; // 活动结束时间
  @GraphqlField()
  public provider_id: string; // 活动服务点id
  @GraphqlField()
  public activity_purchase_limit: number; // 活动限购数量
  @GraphqlField()
  public now_time: string; // 服务器时间
  @GraphqlField()
  public has_commission_flag: number; // 活动限购数量
  @GraphqlField()
  public commission_rate: number; // 活动限购数量

  /**
   *
   */
  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}
