import * as React from "react";
import Layout from "../Layout";
import Content from "./content";
import { RootState } from "../../store/configureStore";

const mapStateToProps = (state: RootState) => ({});

class PauResult extends React.Component<ReturnType<typeof mapStateToProps>> {
  public render() {
    return (
      <Layout breadcrumbs={[]}>
        <Content />
      </Layout>
    );
  }
}

export default PauResult;
