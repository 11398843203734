import React, { useContext } from "react";
import { useSelector } from "react-redux";

import classnames from "classnames";
import * as styles from "./index.scss";
import { RootState } from "../../../store/configureStore";
import { FormattedMessage } from "react-intl";
import { Row, Col, Tag, Space } from "antd";
import Icon from "../../../components/icon";
import { AgentRate } from "../../../models/Shop";
import Checkbox from "../../../wrappers/checkbox";
import Rate from "../../../wrappers/rate";
import Input from "../../../wrappers/input";
import Button, { PrimaryButton } from "../../../wrappers/button";
import { useTheme } from "styled-components";

interface ProductItemProps {
  isMobile: boolean;
  checked: boolean;
  onCheck: (e: any) => void;
  img: string;
  number: number;
  product_option: string;
  onChangeNumber: (n: number) => void;
  name: string;
  manufacturer: string;
  SKU: string;
  userLike: 0 | 1; // 用户是否收藏 0 是未收藏
  delCartItem: () => void; // 单项删除
  onChangeUserLike: (type: number) => void; // 改变单个商品收藏状态

  url: string; // 跳转链接
  isActive: number; // 1 为上架, 0 为下架
  symbol: string;
  shopUrl: string;

  agentCode: string;
  customerRating: number;
  attribute: string;
  originalPrice: string;
  originalPriceValue: string;
  tagPrice: string;
  brandName: string;
  seriesName: string;
  productMoq: number;
  hasTagPriceRange: number;
  quantity: number;
  tagPriceLow: string;
  tagPriceHigh: string;
  index: number;
  productLength: number;

  template?: string;

  agentRates?: AgentRate[];

  shopId: number;
}

const ProductItem = (props: ProductItemProps) => {
  const notAvailable = props.isActive === 0;
  const {
    isMobile,
    shopUrl = "",
    tagPriceLow,
    tagPriceHigh,
    tagPrice,
    hasTagPriceRange,
    agentRates = [],
    agentCode,
    shopId,
  } = props;

  const { search = "" } = useSelector((root: RootState) => root.URLSearch);

  const { primaryColor, secondaryColor, bussinessPrimaryColor } = useTheme();

  let rate = agentRates.find(r => r.shop_id === Number(shopId));

  if (!rate) {
    rate = agentRates.find(r => r.shop_id === 0);
  }

  return (
    <>
      <Row
        align="middle"
        style={{
          borderBottom:
            props.productLength - 1 === props.index ? "" : "1px solid #bdbcbc",
        }}
      >
        <Col
          span={24}
          className={styles["agent-box"]}
          style={{ paddingTop: props.index === 0 ? 0 : "" }}
        >
          {agentCode && (
            <>
              <FormattedMessage id="AgentCode" />：
              <span className={styles["agent-box-code"]}>{agentCode}</span>
            </>
          )}

          {rate && (
            <>
              <FormattedMessage id="ExpectedIncome" />：
              <span style={{ color: bussinessPrimaryColor }}>
                {!!hasTagPriceRange
                  ? `¥${(
                      (Number(tagPriceLow) *
                        rate.commission_rate *
                        props.number) /
                      100
                    ).toFixed(2)} - ¥${(
                      (Number(tagPriceHigh) *
                        rate.commission_rate *
                        props.number) /
                      100
                    ).toFixed(2)}`
                  : `¥${(
                      (Number(tagPrice) * rate.commission_rate * props.number) /
                      100
                    ).toFixed(2)}`}
              </span>
            </>
          )}
        </Col>
        <Col span={5}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              shape="circle"
              borderWidth="2px"
              color={bussinessPrimaryColor}
              style={{ marginRight: 10 }}
              disabled={notAvailable}
              checked={props.checked}
              onChange={props.onCheck}
            />
            <div className={classnames(styles[`table-img`])}>
              <a href={`${props.url}${search.replace("?", "&")}`}>
                <img src={props.img} />
              </a>
              {notAvailable && (
                <div
                  className={styles["mask"]}
                  style={{ color: secondaryColor }}
                >
                  <span>
                    <FormattedMessage id="NotAvailable" />
                  </span>
                </div>
              )}
            </div>
          </div>
        </Col>
        <Col span={7} style={{ height: "170px" }}>
          <p>
            <span style={{ marginRight: 10, fontSize: 15 }}>{props.name}</span>
            {props.customerRating > 0 && (
              <Rate
                value={(props.customerRating * 5) / 100}
                disabled
                color={bussinessPrimaryColor}
              />
            )}
          </p>
          {!notAvailable && (
            <p className={styles["table-MHS"]}>
              {props.brandName && props.brandName !== "" && (
                <Tag color={bussinessPrimaryColor}>{props.brandName}</Tag>
              )}
              {props.seriesName && props.seriesName !== "" && (
                <Tag color={bussinessPrimaryColor}>{props.seriesName}</Tag>
              )}
            </p>
          )}

          <p>{props.attribute}</p>
        </Col>
        <Col span={5}>
          <div
            className={styles["table-moonlight-input"]}
            style={{ padding: "0px" }}
          >
            <PrimaryButton
              disabled={notAvailable}
              icon={<Icon type="icont1_jian" />}
              onClick={() => props.onChangeNumber(props.number - 1)}
              bgColor={bussinessPrimaryColor}
            ></PrimaryButton>
            <Input
              disabled={notAvailable}
              value={props.number}
              onChange={e => props.onChangeNumber(Number(e.target.value))}
              style={{
                border: "1px solid " + bussinessPrimaryColor,
                margin: "0px 5px",
                width: "50px",
              }}
            />
            <PrimaryButton
              disabled={notAvailable}
              icon={<Icon type="icont1_jia" />}
              onClick={() => props.onChangeNumber(props.number + 1)}
              bgColor={bussinessPrimaryColor}
            ></PrimaryButton>
          </div>
        </Col>
        {!notAvailable ? (
          <Col span={3}>
            {Number(props.hasTagPriceRange) === 0 ? (
              <>¥{(parseFloat(props.tagPrice) * props.quantity).toFixed(2)}</>
            ) : (
              <>{`¥${(props.quantity * parseFloat(props.tagPriceLow)).toFixed(
                2,
              )} - ¥${(props.quantity * parseFloat(props.tagPriceHigh)).toFixed(
                2,
              )}`}</>
            )}
          </Col>
        ) : (
          <Col span={3}></Col>
        )}

        <Col span={4} style={{ textAlign: "center" }}>
          <Space size={5}>
            {!notAvailable && (
              <Button
                shape={"circle"}
                onClick={() => {
                  props.onChangeUserLike(props.userLike);
                }}
                icon={
                  <Icon
                    type={props.userLike ? "icont2_shoucang1" : "iconshoucang1"}
                  />
                }
                color={bussinessPrimaryColor}
              />
            )}
            <PrimaryButton
              shape={"circle"}
              onClick={props.delCartItem}
              bgColor={bussinessPrimaryColor}
              icon={<Icon type="icondelete" />}
            />
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(ProductItem);
