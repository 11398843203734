import React, { useContext } from "react";
import { Dispatch, RootState } from "../../store/configureStore";
import { useSelector, useDispatch } from "react-redux";
import {
  EmailVerification,
  LoginForm,
  RegisterForm,
  // ResetPassword,
  ResetPasswordVerifyEmail,
} from "./components";
import { useIntl } from "react-intl";
import { BusinessContext } from "../BusinessContext";
import Icon from "../../components/icon";
import { PrimaryButton } from "../../wrappers/button";
import Modal from "../../wrappers/modal";
import { Menu, Dropdown } from "antd";
import replaceState from "../../helpers/reolaceState";
import { useTheme } from "styled-components";
import * as Cookies from "js-cookie";
import { useMount } from "ahooks";

const LoginSignUp: React.FC = () => {
  const { formatMessage: f } = useIntl();
  const { isToBusiness } = useContext(BusinessContext);
  const { bussinessPrimaryColor, primaryColor } = useTheme();
  const {
    isLogin,
    email,
    emailVerficationType,
    password,
    modalAction,
  } = useSelector((state: RootState) => state.User);
  const { host_id } = useSelector((state: RootState) => state.URLSearch);
  const { isPrivate } = useSelector(
    (state: RootState) => state.CommonData || {},
  );
  const dispatch = useDispatch<Dispatch>();

  const renderModalContent = () => {
    const props = { footer: null, bgColor: "#f7f7f7", width: "486px" };

    switch (modalAction) {
      case "Login": {
        return (
          <Modal
            onCancel={() =>
              dispatch.User.changeUserState({
                isOpen: false,
                modalAction: null,
              })
            }
            visible={modalAction === "Login"}
            title={f({ id: "WelcomeBack" })}
            {...props}
          >
            <LoginForm
              toSiginUp={() =>
                dispatch.User.changeUserState({ modalAction: "SignUp" })
              }
              toResetPassword={() => {
                dispatch.User.changeUserState({
                  modalAction: "ResetPasswordVerifyEmail",
                });
              }}
            />
          </Modal>
        );
      }
      case "SignUp": {
        return (
          <Modal
            onCancel={() =>
              dispatch.User.changeUserState({
                isOpen: false,
                modalAction: null,
              })
            }
            visible={modalAction === "SignUp"}
            title={f({ id: "SignUp" })}
            {...props}
          >
            <RegisterForm
              toEmailVerfication={(
                email,
                emailVerficationType,
                password: string,
              ) =>
                dispatch.User.changeUserState({
                  modalAction: "EmailVerification",
                  email,
                  password,
                  emailVerficationType,
                })
              }
              toLogin={() =>
                dispatch.User.changeUserState({ modalAction: "Login" })
              }
            />
          </Modal>
        );
      }

      case "EmailVerification": {
        return (
          <Modal
            onCancel={() =>
              dispatch.User.changeUserState({
                password: undefined,
                isOpen: false,
                modalAction: null,
              })
            }
            visible={modalAction === "EmailVerification"}
            title={
              emailVerficationType === "register"
                ? f({ id: "EmailVerification" })
                : f({ id: "ForgotPasswordDashStepTwo" })
            }
            {...props}
          >
            <EmailVerification
              toSiginUp={() =>
                dispatch.User.changeUserState({ modalAction: "SignUp" })
              }
              email={email}
              password={password}
              emailVerficationType={emailVerficationType}
            />
          </Modal>
        );
      }
      case "ResetPasswordVerifyEmail": {
        return (
          <Modal
            onCancel={() =>
              dispatch.User.changeUserState({
                isOpen: false,
                modalAction: null,
              })
            }
            visible={modalAction === "ResetPasswordVerifyEmail"}
            title={f({
              id: "ForgotPasswordDashStepOne",
            })}
            {...props}
          >
            <ResetPasswordVerifyEmail
              toEmailVerfication={(email, emailVerficationType) =>
                dispatch.User.changeUserState({
                  modalAction: "EmailVerification",
                  email,
                  emailVerficationType,
                })
              }
            />
          </Modal>
        );
      }
      default:
        return null;
    }
  };

  const handleUserQuit = () => {
    Cookies.remove("store_time");
    Cookies.remove("store_token");
    dispatch.User.changeUserState({
      isLogin: false,
      modalAction: null,
    });
    window.location.reload();
  };

  const accountMenu = isLogin ? (
    <Menu
      onClick={e =>
        e.key === "SignOut" ? handleUserQuit() : replaceState.linkTo(e.key)
      }
    >
      {/* 暂时没有 推荐先隐藏 */}
      {/* <Item key="/account">{f({ id: "RecommendedDetails" })}</Item> */}
      {(!host_id || !isPrivate) && (
        <Menu.Item key="/account/experience">
          {f({ id: "MyMakeAnAppointment" })}
        </Menu.Item>
      )}

      <Menu.Item key="/account/orders">{f({ id: "MineOrder" })}</Menu.Item>
      {/* {__ENV__ === "local" && (
        <Menu.Item key="SignOut">{f({ id: "SignOut" })}</Menu.Item>
      )} */}
    </Menu>
  ) : (
    <Menu onClick={e => dispatch.User.changeUserState({ modalAction: e.key })}>
      <Menu.Item key="Login">{f({ id: "Login" })}</Menu.Item>
      <Menu.Item key="SignUp">{f({ id: "SignIn" })}</Menu.Item>
    </Menu>
  );

  useMount(() => {
    if (__ENV__ === "local") {
      const mhTime = Cookies.get("store_time");
      const mhToken = Cookies.get("store_token");
      if (mhTime && mhToken) {
        dispatch.User.changeUserState({ isLogin: true });
      }
    }
  });

  return (
    <>
      <Dropdown overlay={accountMenu}>
        <PrimaryButton
          onClick={() => {
            replaceState.linkTo("/account");
          }}
          shape="circle"
          icon={<Icon type="icongerenxian"></Icon>}
          bgColor={
            isLogin
              ? isToBusiness
                ? bussinessPrimaryColor
                : primaryColor
              : "#666"
          }
        ></PrimaryButton>
      </Dropdown>
      {renderModalContent()}
    </>
  );
};

export default React.memo(LoginSignUp);
