import "reflect-metadata";
import trim from "lodash/trim";
import isEmpty from "lodash/isEmpty";

/**
 * 递归迭代对象元数据
 * @param obj 对象
 */
function iterateMetadatas(
  obj: { [key: string]: any },
  metadataKey = "default",
  depth = 0,
) {
  let metadatas = Reflect.getMetadata(metadataKey, obj);

  if (isEmpty(metadatas)) {
    metadatas = Reflect.getMetadata("default", obj) || [];
  }

  const fields = metadatas.reduce((curr: string, next: string) => {
    let inners = "";

    if (typeof obj[next] === "object") {
      if (depth > 3) {
        inners = "";
      } else {
        if (obj[next] instanceof Array) {
          const refObj = Reflect.getMetadata(next, obj);
          inners = `${next} {
            ${iterateMetadatas(refObj, metadataKey, depth + 1)}
          }`;
        } else {
          inners = `${next} {
          ${iterateMetadatas(obj[next], metadataKey, depth + 1)}
        }`;
        }
      }
    } else {
      inners = next;
    }

    return curr + inners + "\n\t\t";
  }, "");

  return trim(fields);
}

/**
 * 以,分割组合对象的属性和属性值
 * @param model 对象
 */
function reduceObjectPropertyValues(model: any) {
  if (!model) return "";

  const properties = Object.getOwnPropertyNames(model);

  return properties.reduce((curr: string, next: string) => {
    const value = model[next];

    // null或undefined或object
    let accumulate = "";

    if (typeof value === "boolean") {
      // 布尔
      accumulate = `${next}:"${Number(value)}",`;
    } else if (value && typeof value !== "object") {
      // 数字或字符串
      accumulate = `${next}:"${value}",`;
    } else if (Array.isArray(value) && !isEmpty(value)) {
      // 数组
      accumulate = `${next}:"[${value}]",`;
    }

    return curr + accumulate;
  }, "");
}

/**
 * 拼接Graphql查询字符串
 * @param method 调用方法名
 * @param model 查询的模型
 * @param args 额外的参数
 */
export function buildQueryString(
  method: string,
  model: { [key: string]: any },
  args?: { [key: string]: any },
  metadataKey: string = "default",
  onlyInnerStr: boolean = false,
) {
  const fields = iterateMetadatas(model, metadataKey);

  let params = reduceObjectPropertyValues(model);

  params += reduceObjectPropertyValues(args);

  const paramsWrap = params ? `(${trim(params, ",")})` : "";

  const query = `${method}${paramsWrap}{
      ${trim(fields)}
    }`;

  if (onlyInnerStr) {
    return query;
  }

  return `query {
    ${query}
  }`;
}

/**
 * 拼接Graphql查询字符串
 * @param methodModel 调用方法名及其模型
 * @param args 额外的参数
 */
export function buildMultiQueryString(
  methodModelArgs: {
    method: string;
    model: { [key: string]: any };
    args?: { [key: string]: any };
    metadataKey?: string;
  }[],
) {
  let queryWrapper = "";

  methodModelArgs.forEach(mm => {
    const { method, model, args, metadataKey } = mm;

    const singleQueryStr = buildQueryString(
      method,
      model,
      args,
      metadataKey,
      true,
    );

    queryWrapper += `${singleQueryStr}\n\t\t`;
  });

  return `query {
     ${trim(queryWrapper)}
    }`;
}

/**
 * 拼接Graphql操作字符串
 * @param method 调用的方法名
 * @param model 操作的模型
 * @param actionResult 返回的结果
 */
export function buildPostString(
  method: string,
  model: { [key: string]: any },
  actionResult: { [key: string]: any },
) {
  const params = reduceObjectPropertyValues(model);

  const results = Object.getOwnPropertyNames(actionResult).reduce(
    (curr, next) => {
      return curr + next + "\n\t\t";
    },
    "",
  );

  return `mutation {
      ${method}(${trim(params, ",")}){
        ${trim(results)}
      }
    }`;
}
