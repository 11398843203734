// 用户设计列表
import * as React from "react";
import Layout from "../Layout";
import Content from "./Content";
import { RootState } from "../../store/configureStore";
import InfoLayout from "../UserCenter/components/Layout";
import { useSelector } from "react-redux";
const UserDesign = () => {
  const {
    breadcrumbs = [
      {
        url: "/",
        title: "首页",
      },
      {
        url: "/account/design-idea",
        title: "我的设计",
      },
    ],
  } = useSelector((state: RootState) => state.UserDesignPage);
  return (
    <Layout breadcrumbs={breadcrumbs}>
      <InfoLayout>
        <Content />
      </InfoLayout>
    </Layout>
  );
};

export default UserDesign;
