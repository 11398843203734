import { GraphqlField, GraphqlRefField } from "../helpers/decorators";
import BaseModel from "./BaseModel";

export default class Payment extends BaseModel {
  @GraphqlField("AliPay")
  public url: string;

  @GraphqlField()
  public result: string;
  @GraphqlField()
  public beyond_limit: string;

  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}
