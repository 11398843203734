/**
 * event_dispatch.js<br />
 * event-dispatch.js <br />
 * The Class EventDispatch. A global event bus. Not necessary to use Backbone
 * but available to solve some difficult problems including shared data,
 * capturing UI events for monitoring usage, and keeping components lonely.
 * <br />
 * The storage mechanism for handlers is effectively a map, which in JS is an
 * object tree. The 'path' is uid/eventType/namespace/verb. There is an 'all'
 * node at each level which functions as a bi-directional wildcard. A parameter
 * of null is equivalent to a value of 'all'. <br />
 * In terms of how handlers are stored, "addEventHandler('UI',
 * 'megahome.model.Product',null, click, handler)" would be stored at:
 * handlers.'ui'.'megahome.model.Product'.'all'.'click'. <br />
 * So a listener configured to 'null/UI/megahome.user/delete' would match
 * '123/null/null/delete' or 'null/null/megahome.user/null' but would not match
 * 'null/DAO/null/null' or 'null/UI/megahome/user/click'. <br />
 * <br />
 * <br />
 *
 * @deprecated - Use Backbone.Events instead.
 *
 * @author    Hardys Hu <hardyshu@imegahome.com>
 * @copyright 2018 MegaHome LLC - All rights reserved
 */
// @ts-ignore
import megahome from "./_megahome";
const isNull = require("lodash/isNull");
// @ts-ignore
let _instance = null;
let suspended = false;
/**
 * Instantiates a new EventDispatch.
 *
 * @constructor
 */

function EventDispatch() {
  // @ts-ignore
  this.handlers = {};
}
/** Suspend. */

/** Suspend. */

EventDispatch.prototype.suspend = () => {
  suspended = true;
};
/**
 * Suspend.
 *
 * @return {Boolean}
 */

EventDispatch.prototype.isSuspended = () => {
  return suspended;
};
/** Resume. */

/** Resume. */

EventDispatch.prototype.resume = () => {
  suspended = false;
}; // / new, simple API:

/**
 * Broadcast an event from its components. 'UI', 'DAO', 'Ajax'. 'megahome.user',
 * 'megahome.superbrowse'. listener is observing. 'delete', 'save'. listener.
 *
 * @param {String} eventType The high-level category of the event, e.g.
 * @param {String} namespace The general area of the event, e.g.
 * @param {String} uid The ID of the item the event is from or the
 * @param {String} verb The action the event was caused by, e.g. 'click',
 * @param {*} data Any data that should be made available to the
 * @param {Object} nativeEvent
 */

EventDispatch.prototype.broadcastEvent = function (
  // @ts-ignore
  eventType,
  // @ts-ignore
  namespace,
  // @ts-ignore
  uid,
  // @ts-ignore
  verb,
  // @ts-ignore
  data,
  // @ts-ignore
  nativeEvent,
) {
  this.broadcastEventObject(
    new Event(eventType, {
      // @ts-ignore
      namespace: namespace,
      uid: uid,
      verb: verb,
      data: data,
      nativeEvent: nativeEvent,
    }),
  );
};
/**
 * Broadcast a pre-constructed event. listeners.
 *
 * @param {Object} megahomeEvent An Event object to dispatch to matching
 */
// @ts-ignore
EventDispatch.prototype.broadcastEventObject = function (megahomeEvent) {
  if (suspended) {
    // tslint:disable-next-line: no-console
    console.log("Broadcast ignored, event dispatcher is suspended");
    return;
  } else if (!megahomeEvent) {
    // tslint:disable-next-line: no-console
    console.log("Broadcast ignored, event is missing");
    return;
  }

  const myUid = !!isNull(megahomeEvent.uid) ? "all" : megahomeEvent.uid,
    myEventType = megahomeEvent.eventType || "all",
    myNamespace = megahomeEvent.namespace || "all",
    myVerb = megahomeEvent.verb || "all";

  for (const uidKey in this.handlers) {
    if (this.handlers.hasOwnProperty(uidKey)) {
      if (String(myUid) === uidKey || myUid === "all" || uidKey === "all") {
        this._broadcastEventByUid(
          myEventType,
          myNamespace,
          myVerb,
          this.handlers[uidKey],
          megahomeEvent,
        );
      }
    }
  }
};
/**
 * Broadcasts an event within the context of a UID. All other parameters
 * should be pre-screened and cleaned.
 *
 * @private
 * @param {String} myEventType
 * @param {String} myNamespace
 * @param {String} myVerb
 * @param {Object} myHandlersByUid
 * @param {Object} megahomeEvent
 */

EventDispatch.prototype._broadcastEventByUid = function (
  // @ts-ignore
  myEventType,
  // @ts-ignore
  myNamespace,
  // @ts-ignore
  myVerb,
  // @ts-ignore
  myHandlersByUid,
  // @ts-ignore
  megahomeEvent,
) {
  if (!myHandlersByUid) {
    return;
  }

  for (const eventTypeKey in myHandlersByUid) {
    if (myHandlersByUid.hasOwnProperty(eventTypeKey)) {
      if (
        myEventType === "all" ||
        myEventType === eventTypeKey ||
        eventTypeKey === "all"
      ) {
        this._broadcastEventByEventType(
          myNamespace,
          myVerb,
          myHandlersByUid[eventTypeKey],
          megahomeEvent,
        );
      }
    }
  }
};
/**
 * Broadcasts an event within the context of a UID/eventType. All other
 * parameters should be pre-screened and cleaned.
 *
 * @private
 * @param {String} myNamespace
 * @param {String} myVerb
 * @param {Object} myHandlersByEventType
 * @param {Object} megahomeEvent
 */

EventDispatch.prototype._broadcastEventByEventType = function (
  // @ts-ignore
  myNamespace,
  // @ts-ignore
  myVerb,
  // @ts-ignore
  myHandlersByEventType,
  // @ts-ignore
  megahomeEvent,
) {
  if (!myHandlersByEventType) {
    return;
  }

  for (const namespaceKey in myHandlersByEventType) {
    if (myHandlersByEventType.hasOwnProperty(namespaceKey)) {
      if (
        myNamespace === "all" ||
        myNamespace === namespaceKey ||
        namespaceKey === "all"
      ) {
        this._broadcastEventByNamespace(
          myVerb,
          myHandlersByEventType[namespaceKey],
          megahomeEvent,
        );
      }
    }
  }
};
/**
 * Broadcasts an event within the context of a UID/eventType/namespace.
 * All other parameters should be pre-screened and cleaned.
 *
 * @private
 * @param {String} myVerb
 * @param {Object} myHandlersByNamespace
 * @param {Object} megahomeEvent
 */

EventDispatch.prototype._broadcastEventByNamespace = function (
  // @ts-ignore
  myVerb,
  // @ts-ignore
  myHandlersByNamespace,
  // @ts-ignore
  megahomeEvent,
) {
  if (!myHandlersByNamespace) {
    return;
  }

  for (const verbKey in myHandlersByNamespace) {
    if (myHandlersByNamespace.hasOwnProperty(verbKey)) {
      if (myVerb === "all" || myVerb === verbKey || verbKey === "all") {
        this._execHandlers(myHandlersByNamespace[verbKey], megahomeEvent);
      }
    }
  }
};
/**
 * Executes, serially, a list of handlers against an event.
 *
 * @private
 * @param {Object} myHandlers
 * @param {Object} megahomeEvent
 * @return {void}
 */
// @ts-ignore
EventDispatch.prototype._execHandlers = (myHandlers, megahomeEvent) => {
  if (!myHandlers) {
    return;
  }

  for (let i = 0; i < myHandlers.length; i++) {
    if (myHandlers[i] && myHandlers[i].enabled) {
      myHandlers[i].fn(megahomeEvent);

      if (myHandlers[i].once) {
        myHandlers[i].enabled = false; // TODO Find a way to prune this?
      }
    }
  }
};
/**
 * Fire event.
 *
 * @param {Object} event
 */
// @ts-ignore
EventDispatch.prototype.fireEvent = function (event) {
  if (suspended) {
    return;
  }

  if (event === null || event === undefined) {
    // tslint:disable-next-line: no-console
    console.log(
      "EventDispatch.fireEvent(null) >> Cannot take null as an argument.",
    );
    return;
  }
  // @ts-ignore
  if (window.megahome.constants.IS_AMD_DEBUG_MODE) {
    this.broadcastEventObject(event);
  } else {
    try {
      this.broadcastEventObject(event);
    } catch (err) {
      // tslint:disable-next-line: no-console
      console.log(
        "EventDispatch.fireEvent(" +
          event.toString() +
          ") Error: " +
          err.message,
        {
          error: err,
        },
      );
    }
  }
};
/**
 * Add event handler. 'UI', 'DAO', 'Ajax'. 'megahome.user', 'megahome.superbrowse'.
 * listener is observing. 'delete', 'save'. is fired.
 *
 * @param {String} eventType The high-level category of the event, e.g.
 * @param {String} namespace The general area of the event, e.g.
 * @param {String} uid The ID of the item the event is from or the
 * @param {String} verb The action the event was caused by, e.g. 'click',
 * @param {function} handler The function to invoke when a matching event
 * @param {Boolean} once Only fire this event once?
 */

EventDispatch.prototype.addEventHandler = function (
  // @ts-ignore
  eventType,
  // @ts-ignore
  namespace,
  // @ts-ignore
  uid,
  // @ts-ignore
  verb,
  // @ts-ignore
  handler,
  // @ts-ignore
  once,
) {
  // The 'path' is uid/eventType/namespace/verb
  const handlerObj = {
    fn: handler,
    enabled: true,
    once: once || false,
  };
  const myUid = isNull(uid) ? "all" : uid,
    myEventType = eventType || "all",
    myNamespace = namespace || "all",
    myVerb = verb || "all";

  if (!this.handlers[myUid]) {
    this.handlers[myUid] = {};
  }

  if (!this.handlers[myUid][myEventType]) {
    this.handlers[myUid][myEventType] = {};
  }

  if (!this.handlers[myUid][myEventType][myNamespace]) {
    this.handlers[myUid][myEventType][myNamespace] = {};
  }

  if (!this.handlers[myUid][myEventType][myNamespace][myVerb]) {
    this.handlers[myUid][myEventType][myNamespace][myVerb] = [handlerObj];
  } else {
    this.handlers[myUid][myEventType][myNamespace][myVerb].push(handlerObj);
  }

  return handlerObj;
};
/**
 * Add an event handler once. 'UI', 'DAO', 'Ajax'. 'megahome.user',
 * 'megahome.superbrowse'. listener is observing. 'delete', 'save'. is fired.
 *
 * @param {String} eventType The high-level category of the event, e.g.
 * @param {String} namespace The general area of the event, e.g.
 * @param {String} uid The ID of the item the event is from or the
 * @param {String} verb The action the event was caused by, e.g. 'click',
 * @param {function} handler The function to invoke when a matching event
 */

EventDispatch.prototype.addEventHandlerOnce = function (
  // @ts-ignore
  eventType,
  // @ts-ignore
  namespace,
  // @ts-ignore
  uid,
  // @ts-ignore
  verb,
  // @ts-ignore
  handler,
) {
  this.addEventHandler(eventType, namespace, uid, verb, handler, true);
};
/**
 * Remove event handler. 'UI', 'DAO', 'Ajax'. 'megahome.user', 'megahome.superbrowse'.
 * listener is observing. 'delete', 'save'. is fired.
 *
 * @param {String} eventType The high-level category of the event, e.g.
 * @param {String} namespace The general area of the event, e.g.
 * @param {String} uid The ID of the item the event is from or the
 * @param {String} verb The action the event was caused by, e.g. 'click',
 * @param {function} handler The function to invoke when a matching event
 */

EventDispatch.prototype.removeEventHandler = function (
  // @ts-ignore
  eventType,
  // @ts-ignore
  namespace,
  // @ts-ignore
  uid,
  // @ts-ignore
  verb,
  // @ts-ignore
  handler,
) {
  const myUid = !!isNull(uid) ? "all" : uid,
    myEventType = eventType || "all",
    myNamespace = namespace || "all",
    myVerb = verb || "all";

  if (
    this.handlers[myUid] &&
    this.handlers[myUid][myEventType] &&
    this.handlers[myUid][myEventType][myNamespace] &&
    this.handlers[myUid][myEventType][myNamespace][myVerb]
  ) {
    const myHandlers = this.handlers[myUid][myEventType][myNamespace][myVerb];

    if (handler.fn === undefined) {
      // We got a closure, not a Handler
      for (let i = 0, l = myHandlers.length; i < l; i++) {
        if (myHandlers[i].fn === handler) {
          // Matched closure
          this.handlers[myUid][myEventType][myNamespace][myVerb][i] = false;
        } else {
          // tslint:disable-next-line: no-console
          console.log("EventDispatch: " + "not matched");
        }
      }
    } else {
      // We got a Handler
      let index = -1;
      const handlersLen = myHandlers.length;

      for (index = 0; index < handlersLen; index++) {
        if (myHandlers[index] === handler) {
          this.handlers[myUid][myEventType][myNamespace][myVerb][index] = false;
        }
      }
    }

    if (this.handlers[myUid][myEventType][myNamespace][myVerb].length < 2) {
      delete this.handlers[myUid][myEventType][myNamespace][myVerb]; // TODO
      // Do
      // some
      // more
      // cleanup
      // here
    }
  }
};
/** Reset. */

/** Reset. */

EventDispatch.prototype.reset = function () {
  this.handlers = {};
  this.suspended = false;
};

if (_instance === null) {
  // @ts-ignore
  _instance = new EventDispatch();
}
/**
 * Merges options for events
 *
 * @private
 * @param options
 * @return {object} of merged options and defaults
 */
// @ts-ignore
function _mergeOptions(options) {
  return {
    verb: null,
    namespace: null,
    uid: null,
    data: {},
    nativeEvent: null,
    ...options,
  };
}
/**
 * On event listener options.namespace {object} options.data {object}
 * options.nativeEvent
 *
 * @private
 * @param eventType
 * @param {String} options hash options.verb
 * @param {function} cb for event
 */
// @ts-ignore
function _on(eventType, options, cb) {
  options = _mergeOptions(options);
  // @ts-ignore
  _instance.addEventHandler(
    eventType,
    options.namespace,
    options.uid,
    options.verb,
    cb,
    false,
  );
}
/**
 * On event listener (fires once) options.namespace {object} options.data
 * {object} options.nativeEvent
 *
 * @private
 * @param eventType
 * @param {String} options hash options.verb
 * @param {function} cb for event
 */
// @ts-ignore
function _one(eventType, options, cb) {
  options = _mergeOptions(options);
  // @ts-ignore
  _instance.addEventHandlerOnce(
    eventType,
    options.namespace,
    options.uid,
    options.verb,
    cb,
  );
}
/**
 * On event listener (fires once) options.namespace {object} options.data
 * {object} options.nativeEvent
 *
 * @private
 * @param eventType
 * @param {String} options hash options.verb
 * @param {function} cb for event
 */
// @ts-ignore
function _off(eventType, options, cb) {
  options = _mergeOptions(options);
  // @ts-ignore
  _instance.removeEventHandler(
    eventType,
    options.namespace,
    options.uid,
    options.verb,
    cb,
  );
}
/**
 * Triggers an event interacting w eventdispatch + megahome event options.namespace {object} options.data {object} options.nativeEvent
 * @private
 * @param {String} eventType
 * @param {String} options hash options.verb
 */
// @ts-ignore
function _trigger(eventType, options) {
  if (typeof eventType === "undefined") {
    return;
  }

  options = _mergeOptions(options);
  const triggerEvent = new Event(eventType, options);
  // @ts-ignore
  _instance.fireEvent(triggerEvent);
}
/**
 * Returns a function that triggers an event
 * Allows for a simple reusable trigger function without needed a utility function in each module
 * @param  {String} eventType The event type to trigger
 * @param  {String} verb      The verb to trigger
 * @param  {Object} defaults  Default data. Will be merged with argument to returned function
 * @return {Function}         The wrapped trigger function
 */
// @ts-ignore
function _getTrigger(eventType, verb, defaults) {
  defaults = defaults || {};
  // @ts-ignore
  return data => {
    _trigger(eventType, {
      verb: verb,
      data: { ...defaults, ...data },
    });
  };
}
/** test-api * */
// @ts-ignore
const eventDispatchTestApi = megahome.namespace("testApi.event_dispatch");
eventDispatchTestApi.EventDispatch = EventDispatch;
/** end-test-api * */

export default {
  on: _on,
  one: _one,
  off: _off,
  trigger: _trigger,
  getTrigger: _getTrigger,
};
