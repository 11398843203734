import React, { useEffect, useState, useContext, useRef } from "react";
import { RootState } from "../../store/configureStore";
import { useSelector } from "react-redux";
import * as styles from "./index.scss";
import Header from "./component/Header";
import CopyRight from "./component/CopyRight";

const PrivacyPolicy = () => {
  const { Privacy = "" } = useSelector((state: RootState) => state.SystemPage);
  return (
    <div className={styles["Privacy"]}>
      <Header />
      <div className={styles["Privacy-content"]} id="Privacy">
        <div dangerouslySetInnerHTML={{ __html: Privacy }}></div>
      </div>
      <CopyRight />
    </div>
  );
};

export default React.memo(PrivacyPolicy);
