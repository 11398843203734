// 我的钱包
import * as React from "react";
import Layout from "../Layout";
import Content from "./Content";
import { RootState } from "../../store/configureStore";
import InfoLayout from "../UserCenter/components/Layout";
import { useSelector } from "react-redux";
const Withdrawal = () => {
  const {
    breadcrumbs = [
      {
        url: "/",
        title: "首页",
      },
      {
        url: "/account",
        title: "个人中心",
      },
      {
        url: "/account/wallet",
        title: "我的钱包",
      },
    ],
  } = useSelector((state: RootState) => state.WithdrawalPage);
  return (
    <Layout breadcrumbs={breadcrumbs}>
      <InfoLayout>
        <Content />
      </InfoLayout>
    </Layout>
  );
};

export default Withdrawal;
