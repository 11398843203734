import zh from "./zh-cs";
import ct from "./zh-ct";
import en from "./en";
import es from "./es";
import ja from "./ja";
import ko from "./ko";
import ar from "./ar";

import React, { useEffect, useState } from "react";
import { IntlProvider, useIntl } from "react-intl";
import { RootState, Dispatch } from "../store/configureStore";
import { useSelector, useDispatch } from "react-redux";
import loadjs from "loadjs";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import cloneDeep from "lodash/cloneDeep";
import { GraphQLRequest } from "../helpers/request.helper";
import IntlContext from "./IntlContext";
import * as Cookie from "js-cookie";
import addHours from "date-fns/addHours";

const addLanguageData = (
  pageKey: string,
  languageData: { [index: string]: string },
) => {
  GraphQLRequest(
    "post",
    `
  mutation{
    addLanguage(page_key:"${pageKey}",language_data:"${JSON.stringify(
      languageData,
    )
      .replace(/\\/g, "\\\\")
      .replace(/"/g, '\\"')}"){
      result
      message
      }
  }
`,
  );
};

const Intl = (props: any) => {
  // const { formatMessage } = useIntl();
  const [allMessages, setAllMessages] = useState({
    zh,
    en,
    ct,
    es,
    ja,
    ko,
    ar,
  } as Record<string, any>);
  const {
    currentLang = "zh",
    pageKey = "",
    languageUrl = "",
    langSwitch,
  } = useSelector((state: RootState) => state.Locale);

  const { Locale: rematch } = useDispatch<Dispatch>();

  const messages = allMessages[currentLang];

  const syncServerData = () => {
    const language_data = pickBy(allMessages["en"], (value, key) =>
      key.startsWith(`${pageKey}-`),
    );

    if (Object.keys(language_data).length > 0) {
      addLanguageData(pageKey, language_data);
    }
  };

  useEffect(() => {
    const lang = localStorage.getItem("store-currentLang");
    const now = new Date();
    const expires = addHours(now, 8);

    if (!lang) {
      localStorage.setItem("store-currentLang", currentLang);
      Cookie.set("store_lang", currentLang, { expires });
    }
    // 只在首次渲染并且localStorage的值与redux值不同时执行
    else if (lang !== currentLang) {
      localStorage.setItem("store-currentLang", lang);
      Cookie.set("store_lang", lang, { expires });
      rematch.changeLocale(lang);
    }

    if (langSwitch) {
      loadjs(languageUrl, {
        success: () => {
          if (window.currentPageLang) {
            const cloneMessages = cloneDeep(allMessages);

            const langOptions = Object.keys(cloneMessages);

            const defaultLanguage =
              window.currentPageLang.default_language || {};

            // 切换语言开关开启
            const pageKeyValues = pickBy(cloneMessages["en"], (value, key) =>
              key.startsWith(`${pageKey}-`),
            );
            // 对比本地默认语言数据与服务器默认语言数据的差
            const diffKeyValues = omit(
              pageKeyValues,
              Object.keys(defaultLanguage),
            );

            if (Object.keys(diffKeyValues).length > 0) {
              addLanguageData(pageKey, diffKeyValues);
            }

            langOptions.forEach((lang: string) => {
              let serverLang = lang;
              switch (lang) {
                case "zh":
                  serverLang = "zh_cs";
                  break;
                case "ct":
                  serverLang = "zh_ct";
                  break;
              }

              const localLanguage = cloneMessages[lang],
                serverLanguage =
                  window.currentPageLang[`language_${serverLang}`] || {};

              // 1、某语言不存在则使用默认语言
              // 2、merge某语言不存在的key
              const mergeData = { ...defaultLanguage, ...serverLanguage };

              Object.assign(localLanguage, mergeData);
            });

            setAllMessages(cloneMessages);
          } else {
            syncServerData();
          }
        },
        error: syncServerData,
      });
    }
  }, []);

  return (
    <IntlProvider locale={currentLang} messages={messages}>
      {props.children}
    </IntlProvider>
  );
};

export default Intl;
