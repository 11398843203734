import { createModel } from "@rematch/core";

export type StateType = {
  // 0 不做任何操作 1登录 2注册
  modalAction:
    | "Login" // 登录
    | "SignUp" // 注册
    | "EmailVerification" // 邮箱验证模块,通用于注册成功之后的邮箱验证,忘记密码的邮箱验证
    | "ResetPasswordVerifyEmail" // 重置密码前先输入邮箱验证邮箱
    | "ResetPassword" // 邮箱验证成功重置密码
    | null;
  isLogin: boolean; // 是否登录
  isAgent: boolean;
  agentCode: string;
  userId: string; // 用户id
  isOpen: boolean; // 是否打开相关控件
  email: string; // 储存用户邮箱地址
  emailVerficationType: "register" | "forget" | undefined; // 储存邮箱验证模块类型是注册还是找回密码
  password: string; // 用来存储用户输入的password
  verifyPay: number; // 判断是否代理付费
};

export default createModel({
  state: {
    isLogin: true,
    userId: "henryxue",
    email: "henry@mega.com",
    isAgent: true,
    agentCode: "1R51JNVY9N8T",
    verifyPay: 1, // 1支付 0未支付
  } as StateType,
  reducers: {
    changeUserState(state: StateType, payload: Partial<StateType>) {
      return { ...state, ...payload };
    },
  },

  effects: {},
  selectors: {
    // total(state: StateType) {
    //   return state.agentCode.indexOf("8T") > -1;
    // },
  },
});
