import { createModel } from "@rematch/core";
import {
  GraphqlQueryMulti,
  GraphqlQuery,
  GraphqlMutation,
} from "../../helpers/request.helper";
import { getFirstItem } from "../../helpers/modelHelpers";
import Agent from "../../models/Agent";
import Commission from "../../models/Commission";
import { ListTotal } from "../../models/Common";

export interface CommissionPage {
  breadcrumbs: { url: string; title: string }[];
  agentShop: Agent;
  total: number;
  statistics: Agent;
  commissions: Agent;
  inCome: Agent;
  amounts: Agent;
  invitations: Agent;
  invitationsTotal: number;
  invitationStatistics: Agent;
}

export default createModel({
  state: {} as CommissionPage,
  reducers: {
    setAgentShops: (state: CommissionPage, payload: any) => ({
      ...state,
      agentShop: payload,
    }),
    setAmounts: (state: CommissionPage, payload: any) => ({
      ...state,
      amounts: payload,
    }),
    setCommissionData: (state: CommissionPage, payload: any) => ({
      ...state,
      total: payload.total,
      commissions: payload.commissions,
    }),
    setStatistics: (state: CommissionPage, payload: any) => ({
      ...state,
      statistics: payload,
    }),
    setInComes: (state: CommissionPage, payload: any) => ({
      ...state,
      inCome: payload,
    }),
    setInvitationsData: (state: CommissionPage, payload: any) => ({
      ...state,
      invitations: payload.invitations,
      invitationsTotal: payload.invitationsTotal,
    }),
    setInvitationStatistics: (state: CommissionPage, payload: any) => ({
      ...state,
      invitationStatistics: payload,
    }),
  },
  effects: {
    // 获取代理店铺
    async getAmountAndShopAsync() {
      const res = await GraphqlQueryMulti(
        {
          method: "AgentShop",
          model: new Agent({}),
          args: {},
          metadataKey: "AgentShop",
        },
        {
          method: "CommissionAmounts",
          model: new Commission({}),
          args: {},
          metadataKey: "Amount",
        },
      );

      this.setAgentShops(res.AgentShop);
      this.setAmounts(getFirstItem(res.CommissionAmounts));
    },
    // 获取带单列表数据
    async getCommossionListAsync(payload) {
      const { page, ...arg } = payload;
      const res = await GraphqlQueryMulti(
        {
          method: "Commissions",
          model: new Commission({}),
          args: { ...payload },
          metadataKey: "Commission",
        },
        {
          method: "CommissionTotal",
          model: new ListTotal(),
          args: { ...arg },
        },
      );
      this.setCommissionData({
        total: getFirstItem(res.CommissionTotal).total,
        commissions: res.Commissions,
      });
    },
    // 获取带单统计
    async getCommissionStatisticsAsync() {
      const data = new Commission({});
      const res = await GraphqlQuery(
        "CommissionStatistics",
        data,
        {},
        "Statistics",
      );
      this.setStatistics(getFirstItem(res));
    },

    // 获取带单收入
    async getInComeAsync(payload) {
      const { page, ...arg } = payload;
      const data = new Commission({});
      const res = await GraphqlQuery(
        "CommissionIncome",
        data,
        { ...arg },
        "Income",
      );
      this.setInComes(getFirstItem(res));
    },
    // 获取邀请数据
    async getInvitationsAsync(payload) {
      const { page = 1 } = payload;
      const res = await GraphqlQueryMulti(
        {
          method: "Invitations",
          model: new Commission({}),
          args: { page: page },
          metadataKey: "Invitations",
        },
        {
          method: "InvitationTotal",
          model: new ListTotal(),
          args: {},
        },
      );
      this.setInvitationsData({
        invitations: res.Invitations,
        invitationsTotal: getFirstItem(res.InvitationTotal).total,
      });
    },

    // 获取带单邀请汇总
    async getInvitationStatisticsAsync() {
      const data = new Commission({});
      const res = await GraphqlQuery(
        "InvitationStatistics",
        data,
        {},
        "InvitationStatistics",
      );
      this.setInvitationStatistics(res);
    },
  },
});
