import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, Dispatch } from "../../store/configureStore";
import AddressForm, { AddressValuesType } from "./AddressForm";
import TobAddressForm, { TobAddressValuesType } from "./TobAddressForm";
import * as styles from "./index.scss";
import { useIntl } from "react-intl";
import { CommonProps } from "../../typings/component";
import Icon from "../../components/icon";
import { Row, Col, Divider } from "antd";
import { useTheme } from "styled-components";
import { BusinessContext } from "../BusinessContext";
import Button, { PrimaryButton } from "../../wrappers/button";
import Card from "../../wrappers/card";
import List from "../../wrappers/list";

const AddressContent: React.FC<CommonProps> = ({ classNamePrefix = "" }) => {
  const dispatch = useDispatch<Dispatch>();
  const { formatMessage: f } = useIntl();
  const {
    primaryColor,
    secondaryColor,
    panelColor,
    linkColor,
    bussinessPrimaryColor,
  } = useTheme();
  const { isToBusiness } = useContext(BusinessContext);

  const { address, tobAddress } = useSelector(
    (state: RootState) => state.AddressPage,
  );
  const { isPrivate } = useSelector((state: RootState) => state.CommonData);
  const { host_id } = useSelector((state: RootState) => state.URLSearch);
  const [addressFormOpen, setFormOpen] = useState(false),
    [addRessFormChangeOpen, setChangeOpen] = useState(false),
    [changeAddressId, setAddressId] = useState(0),
    [selectedType, setSelectedType] = useState(0),
    [navIndex, setNavIndex] = useState(0);

  useEffect(() => {
    if (!address) {
      dispatch.AddressPage.getAddressDataAsync();
    }
  }, []);

  const handleOpenChangeForm = (addressId: string) => {
    setChangeOpen(true);
    setAddressId(Number(addressId));
  };

  const handleChangeAddressItem = (addressValues: AddressValuesType) => {
    const data = {
      ...addressValues,
      address_id: changeAddressId as any,
      callback: () => {
        if (addressValues.is_default === 1) {
          address.map((item, index) => {
            if (parseInt(item.address_id) !== changeAddressId) {
              item.is_default = 0;
            }
          });
        }
        setChangeOpen(false);
        setAddressId(0);
        dispatch.AddressPage.getAddressDataAsync();
      },
    };
    dispatch.AddressPage.setAddressAsync(data);
  };

  const handleAddAddressItem = (addressValues: AddressValuesType) => {
    const data = {
      ...addressValues,
      callback: () => {
        if (addressValues.is_default === 1) {
          address.map((item, index) => {
            item.is_default = 0;
          });
        }
        setFormOpen(false);
        dispatch.AddressPage.getAddressDataAsync();
      },
    };
    dispatch.AddressPage.setAddressAsync(data);
  };

  const handleChangeTobAddressItem = (addressValues: TobAddressValuesType) => {
    const data = {
      ...addressValues,
      tob_address_id: changeAddressId as any,
      callback: () => {
        if (addressValues.is_default === 1) {
          tobAddress.map((item, index) => {
            if (parseInt(item.tob_address_id) !== changeAddressId) {
              item.is_default = 0;
            }
          });
        }
        setChangeOpen(false);
        setAddressId(0);
        dispatch.AddressPage.setTobAddress();
      },
    };
    dispatch.AddressPage.addTobAddress(data);
  };

  const handleTobAddAddressItem = (addressValues: TobAddressValuesType) => {
    const data = {
      ...addressValues,
      callback: () => {
        if (addressValues.is_default === 1) {
          tobAddress.map((item, index) => {
            item.is_default = 0;
          });
        }
        setFormOpen(false);
        dispatch.AddressPage.setTobAddress();
      },
    };
    dispatch.AddressPage.addTobAddress(data);
  };

  const titleData = [
    {
      label: f({ id: "PersonalDeliveryAddress" }),
      value: 0,
    },
    {
      label: f({ id: "EnterprisePurchasingContactor" }),
      value: 1,
    },
  ];

  const handleListGet = (type: number) => {
    if (type === 0 && !address) {
      dispatch.AddressPage.getAddressDataAsync();
    } else if (type === 1 && !tobAddress) {
      dispatch.AddressPage.setTobAddress();
    }
  };

  return (
    <div className={styles["Address-box"]}>
      <div
        style={{
          width: "100%",
          padding: "0px 20px",
        }}
      >
        {!host_id && (
          <Row className={styles["Address-nav"]}>
            {titleData.map((item, index) => {
              return (
                <Col
                  span={24 / titleData.length}
                  key={index}
                  style={{ textAlign: "center" }}
                >
                  <div
                    className={styles["Address-nav-div"]}
                    onClick={() => {
                      if (selectedType === item.value) return;
                      setSelectedType(item.value);
                      setNavIndex(index);
                      handleListGet(item.value);
                    }}
                    style={{
                      borderBottom:
                        navIndex === index ? "2px solid " + primaryColor : "",
                    }}
                  >
                    <h3
                      style={{
                        color: navIndex === index ? primaryColor : "",
                      }}
                    >
                      {item.label}
                    </h3>
                  </div>
                </Col>
              );
            })}
          </Row>
        )}

        {navIndex === 0 && address && (
          <>
            <div style={{ margin: "10px 0px" }}>
              <PrimaryButton
                onClick={() => setFormOpen(true)}
                bgColor={primaryColor}
              >
                {f({ id: "AddNewAddress" })}
              </PrimaryButton>
              <span style={{ color: linkColor, marginLeft: "10px" }}>
                {f(
                  { id: "YouCanAddUpToTwentyAddress" },
                  { num: address.length },
                )}
              </span>
            </div>

            <div className="Grid">
              {address.length === 0 ? (
                <p className={styles[`${classNamePrefix}Address-empty`]}>
                  {f({
                    id: "AddYourAddressByClicking",
                  })}
                </p>
              ) : (
                <>
                  <List
                    dataSource={address}
                    split={false}
                    grid={{ gutter: 10, column: 1 }}
                    renderItem={(item: any, index) => (
                      <List.Item key={index}>
                        <Card
                          style={{
                            background: "white",
                            padding:
                              !item.address_alias &&
                              Number(item.is_default) !== 1
                                ? "30px 0"
                                : "10px 10px 30px 10px",
                          }}
                        >
                          {(item.address_alias ||
                            Number(item.is_default) === 1) && (
                            <Row
                              style={{
                                height: "35px",
                                lineHeight: "38px",
                              }}
                            >
                              <Col span={18}>
                                <>
                                  {item.address_alias && (
                                    <span
                                      style={{
                                        background: secondaryColor,
                                        color: "white",
                                        border: "none",
                                        borderRadius: "30px",
                                        padding: "4px 10px",
                                        fontSize: "1.1em",
                                        minWidth: "65px",
                                        textAlign: "center",
                                        marginRight: "5px",
                                      }}
                                    >
                                      {item.address_alias}
                                    </span>
                                  )}

                                  {Number(item.is_default) === 1 && (
                                    <>
                                      {f({
                                        id: "DefaultAddress",
                                      })}
                                    </>
                                  )}
                                </>
                              </Col>
                            </Row>
                          )}
                          <div
                            style={{
                              textAlign: "right",
                              position: "absolute",
                              top: "12px",
                              right: "25px",
                            }}
                          >
                            <PrimaryButton
                              onClick={() =>
                                handleOpenChangeForm(item.address_id)
                              }
                              shape={"circle"}
                              icon={<Icon type={"iconbianji"} />}
                              bgColor={primaryColor}
                            />
                            <Button
                              onClick={() =>
                                dispatch.AddressPage.deleteAddressAsync({
                                  addressId: item.address_id,
                                })
                              }
                              style={{
                                marginLeft: "5px",
                                background: "white",
                                color: primaryColor,
                                border: "1px solid " + primaryColor,
                              }}
                              shape={"circle"}
                              icon={<Icon type={"icondelete"} />}
                            />
                          </div>
                          {(item.address_alias ||
                            Number(item.is_default) === 1) && <Divider />}

                          <Row gutter={10}>
                            <span
                              style={{
                                display: "inline-block",
                                width: "80px",
                                textAlign: "right",
                              }}
                            >
                              {f({
                                id: "Consignee",
                              })}
                              ：
                            </span>
                            <span>{item.recipient_name}</span>
                          </Row>
                          <Row style={{ marginTop: "4px" }} gutter={10}>
                            <span
                              style={{
                                display: "inline-block",
                                width: "80px",
                                textAlign: "right",
                              }}
                            >
                              {f({
                                id: "CellphoneNumber",
                              })}
                              ：
                            </span>
                            <span>{item.recipient_telephone}</span>
                          </Row>
                          <Row style={{ marginTop: "4px" }} gutter={10}>
                            <span
                              style={{
                                display: "inline-block",
                                width: "80px",
                                textAlign: "right",
                              }}
                            >
                              {f({
                                id: "DetailedAddress",
                              })}
                              ：
                            </span>
                            <span>{`${item.province}${item.city}${item.area}${item.address_details}`}</span>
                          </Row>
                        </Card>
                      </List.Item>
                    )}
                  ></List>
                </>
              )}
            </div>
            <AddressForm
              classNamePrefix={classNamePrefix}
              isOpen={addressFormOpen}
              onClose={() => setFormOpen(false)}
              onSubmit={handleAddAddressItem}
            />

            {/* 用于修改地址的弹窗 */}

            <AddressForm
              classNamePrefix={classNamePrefix}
              isOpen={addRessFormChangeOpen}
              onClose={() => {
                setChangeOpen(false);
                setAddressId(0);
              }}
              onSubmit={handleChangeAddressItem}
              addressData={
                addRessFormChangeOpen
                  ? address.find(
                      item => Number(item.address_id) === changeAddressId,
                    )
                  : undefined
              }
            />
          </>
        )}

        {navIndex === 1 && tobAddress && (
          <>
            <div style={{ margin: "10px 0px" }}>
              <PrimaryButton
                onClick={() => setFormOpen(true)}
                bgColor={bussinessPrimaryColor}
              >
                {f({ id: "AddContactor" })}
              </PrimaryButton>
              <span style={{ color: linkColor, marginLeft: "10px" }}>
                {f(
                  { id: "YouCanAddUpToTwentyContactor" },
                  { num: tobAddress.length },
                )}
              </span>
            </div>

            <div className="Grid">
              {tobAddress.length === 0 ? (
                <p className={styles[`${classNamePrefix}Address-empty`]}>
                  {f({
                    id: "AddYourContactorByClicking",
                  })}
                </p>
              ) : (
                <List
                  dataSource={tobAddress}
                  split={false}
                  grid={{ gutter: 10, column: 1 }}
                  renderItem={(item: any, index) => (
                    <List.Item key={index}>
                      <Card style={{ background: "white" }}>
                        <Row>
                          <Col span={18}>
                            {Number(item.is_default) === 1 && (
                              <>
                                {f({
                                  id: "DefaultAddress",
                                })}
                              </>
                            )}
                          </Col>
                          <Col span={6} style={{ textAlign: "right" }}>
                            <PrimaryButton
                              onClick={() =>
                                handleOpenChangeForm(item.tob_address_id)
                              }
                              size="small"
                              shape={"circle"}
                              icon={<Icon type={"iconbianji"} />}
                              bgColor={bussinessPrimaryColor}
                            />
                            <Button
                              onClick={() =>
                                dispatch.AddressPage.delTobAddress({
                                  addressId: item.tob_address_id,
                                })
                              }
                              size="small"
                              style={{
                                marginLeft: "5px",
                                background: "white",
                                color: bussinessPrimaryColor,
                                border: "1px solid " + bussinessPrimaryColor,
                              }}
                              shape={"circle"}
                              icon={<Icon type={"icondelete"} />}
                            />
                          </Col>
                        </Row>

                        <Row style={{ marginTop: "30px" }} gutter={10}>
                          <Col span={2} style={{ textAlign: "right" }}>
                            {f({
                              id: "Contactor",
                            })}
                            :
                          </Col>
                          <Col span={22}>{item.recipient_name}</Col>
                        </Row>
                        <Row style={{ marginTop: "10px" }} gutter={10}>
                          <Col span={2} style={{ textAlign: "right" }}>
                            {f({
                              id: "CellphoneNumber",
                            })}
                            :
                          </Col>
                          <Col span={22}>{item.recipient_telephone}</Col>
                        </Row>
                        <Row style={{ marginTop: "10px" }} gutter={10}>
                          <Col span={2} style={{ textAlign: "right" }}>
                            {f({
                              id: "Email",
                            })}
                            :
                          </Col>
                          <Col span={22}>{item.recipient_email}</Col>
                        </Row>

                        <Row style={{ marginTop: "10px" }} gutter={10}>
                          <Col span={2} style={{ textAlign: "right" }}>
                            {f({
                              id: "FirmName",
                            })}
                            :
                          </Col>
                          <Col span={22}>{item.recipient_company}</Col>
                        </Row>
                        <Row style={{ marginTop: "10px" }} gutter={10}>
                          <Col span={2} style={{ textAlign: "right" }}>
                            {f({
                              id: "DetailedAddress",
                            })}
                            :
                          </Col>
                          <Col span={22}>
                            {`${item.province}${item.city}${item.area}${item.address_details}`}
                          </Col>
                        </Row>
                      </Card>
                    </List.Item>
                  )}
                ></List>
              )}
            </div>
            <TobAddressForm
              classNamePrefix={classNamePrefix}
              isOpen={addressFormOpen}
              onClose={() => setFormOpen(false)}
              onSubmit={handleTobAddAddressItem}
            />

            {/* 用于修改地址的弹窗 */}

            <TobAddressForm
              classNamePrefix={classNamePrefix}
              isOpen={addRessFormChangeOpen}
              onClose={() => {
                setChangeOpen(false);
                setAddressId(0);
              }}
              onSubmit={handleChangeTobAddressItem}
              addressData={
                addRessFormChangeOpen
                  ? tobAddress.find(
                      item => Number(item.tob_address_id) === changeAddressId,
                    )
                  : undefined
              }
            />
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(AddressContent);
