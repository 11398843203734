import { createModel } from "@rematch/core";
import {
  DesignIdea,
  DesignIdeaPicture,
  DesignIdeaData,
  DesignIdeaList,
  Coordinate,
  IdeaFilter,
} from "../models/DesignIdea";
import { Product, Sku } from "../models/Product";
import { Option, ListTotal, GaeaList } from "../models/Common";
import head from "lodash/head";
import {
  GraphqlQuery,
  GraphqlMutation,
  GraphqlQueryMulti,
} from "../helpers/request.helper";
import { request } from "../helpers/postMessageHelper";
import { getFirstItem } from "../helpers/modelHelpers";
import pick from "lodash/pick";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import findLastIndex from "lodash/findLastIndex";
import unionBy from "lodash/unionBy";
import { ActionResult } from "../models/BaseModel";

type ProductMap = {
  [key: string]: Product[];
};

export type DesignIdeaState = {
  gaeaDomain: string;
  ideaList: DesignIdea[];
  currIdea: DesignIdea;
  selectedProducts: Product[];
  coordinates: Coordinate[];
  tagProducts: ProductMap;
  houseTypeOptions: Option[];
  colorOptions: Option[];
  roomOptions: Option[];
  styleOptions: Option[];
  filterList: IdeaFilter;
  hasMoreIdeas: boolean;
  total: number;
  systemDirectory: DesignIdeaPicture[];
  systmMaterial: DesignIdeaPicture[];
  bgImg: string;
  action?: string;
  searchProductList: (Sku & {
    media_list: {
      media_url: string; // media url
      media_type: 1; // 类型 1 图片
      product_id: string; // 商品id
      product_name: string; // 商品名称
      manufacture_name_en: string; // 供应商名称
      price_low: string; // 单品最低价
      price_high: string; // 单品最高价
      shop_product_id: number;
      product_media_id: string;
    }[];
  })[];
  hasMoreSearchProducts: boolean;
};

export default createModel({
  state: { gaeaDomain: "http://gaeacn.com" } as DesignIdeaState,
  reducers: {
    initOptionData: (state: DesignIdeaState, data: DesignIdeaData) => {
      return {
        ...state,
        houseTypeOptions: data.house_type_list,
        colorOptions: data.color_lists,
        roomOptions: data.room_lists,
        styleOptions: data.style_lists,
      };
    },
    initFilterList: (state: DesignIdeaState, filterList: IdeaFilter) => {
      return {
        ...state,
        filterList,
      };
    },
    setIdeaList: (state: DesignIdeaState, payload) => {
      return {
        ...state,
        total: payload.total,
        ideaList: payload.reset
          ? payload.list
          : [...state.ideaList, ...payload.list],
        hasMoreIdeas: payload.hasMoreIdeas,
      };
    },
    setCurrIdea: (state: DesignIdeaState, currIdea: DesignIdea) => {
      const nullTagItems = currIdea.coordinates.filter(
        c => c.coordinate_type === 2,
      );

      const tagProducts: ProductMap = {};

      // 将产品按照render item的id进行分组
      nullTagItems.forEach(ti => {
        const itemIndex = findLastIndex(
          currIdea.products,
          p => p.shop_product_id === ti.shop_product_id,
        );

        const product = currIdea.products.splice(itemIndex, 1);

        const mapValue = tagProducts[ti.coordinate_key];

        tagProducts[ti.coordinate_key] = [...(mapValue ?? []), ...product];
      });

      return {
        ...state,
        currIdea,
        selectedProducts: [...currIdea.products],
        coordinates: currIdea.coordinates,
        tagProducts,
        action: "get",
      };
    },
    deleteIdea: (state: DesignIdeaState, id: number) => {
      return {
        ...state,
        ideaList: state.ideaList.filter(i => i.design_idea_id !== id),
      };
    },
    setSelectedProducts: (
      state: DesignIdeaState,
      selectedProducts: Product[],
    ) => {
      return { ...state, selectedProducts };
    },
    setTagProducts: (state: DesignIdeaState, tagProducts: ProductMap) => {
      return { ...state, tagProducts };
    },
    setDesignIdea: (state: DesignIdeaState, idea: DesignIdea) => {
      const { ideaList = [] } = state;

      return {
        ...state,
        ideaList: [
          { ...state.currIdea, ...idea },
          ...ideaList.filter(i => i.design_idea_id !== idea.design_idea_id),
        ],
        currIdea: { ...state.currIdea, ...idea },
        action: undefined,
      };
    },
    setSystemDirectory: (
      state: DesignIdeaState,
      systemDirectory: DesignIdeaPicture[],
    ) => {
      return { ...state, systemDirectory };
    },
    setSystmMaterial: (
      state: DesignIdeaState,
      systmMaterial: DesignIdeaPicture[],
    ) => {
      return { ...state, systmMaterial };
    },
    setSystemPicture: (state: DesignIdeaState, payload: any) => {
      return {
        ...state,
        systemDirectory: [...state.systemDirectory, ...payload],
      };
    },
    setBgImage: (state: DesignIdeaState, payload: any) => {
      return {
        ...state,
        bgImg: payload,
      };
    },
    setProductList: (state: DesignIdeaState, payload: any) => {
      return {
        ...state,
        searchProductList: payload.reset
          ? payload.skus
          : [...state.searchProductList, ...payload.skus],
        hasMoreSearchProducts: payload.hasMoreProducts,
      };
    },
  },
  effects: {
    async searchProductListAsync(payload: any, state: any) {
      const { page, reset } = payload;

      if (window.parent !== window && location.search.indexOf("host_id=") < 0) {
        const pageSize = 20;

        const data = await request<GaeaList<Product[]>>(
          state.DesignIdea.gaeaDomain,
          "designidea.getProducts",
          {
            pageSize,
            pageNumber: page,
            searchText: payload.keyword,
            searchField: "product_name",
            ...pick(payload, "root_id"),
          },
        );

        this.setProductList({
          skus: data.rows,
          total: data.total,
          hasMoreProducts: Number(page) * pageSize < data.total,
          reset,
        });
      } else {
        const data = await GraphqlQueryMulti(
          {
            method: "Search",
            model: new Product({}),
            args: omit(payload, "reset"),
            metadataKey: "SearchList",
          },
          {
            method: "SearchTotal",
            model: new ListTotal(),
            args: omit(payload, "page", "reset"),
          },
        );

        const pageInfo: ListTotal = getFirstItem(data.SearchTotal);

        this.setProductList({
          skus: data.Search.map((p: Product) => ({
            ...p,
            default_product_id: p.shop_product_id,
          })),
          // total: getFirstItem(data.SearchTotal).total,
          hasMoreProducts: Number(page) * pageInfo.pageSize < pageInfo.total,
          reset,
        });
      }
    },
    async initOptionDataAsync(payload: any, state: any) {
      if (window.parent !== window && location.search.indexOf("host_id=") < 0) {
        const data = await request<DesignIdeaData>(
          state.DesignIdea.gaeaDomain,
          "designidea.getOptions",
        );

        this.initOptionData(data);
      } else {
        const data = await GraphqlQuery("DesignIdeaData", new DesignIdeaData());

        this.initOptionData(head(data));
      }
    },
    async initFilterListAsync() {
      const data = await GraphqlQuery(
        "PickList",
        new IdeaFilter(),
        {
          action: "myDesign",
        },
        "design",
      );

      this.initFilterList(head(data));
    },
    async getIdeaListAsync(payload) {
      const { reset, ...args } = payload;

      const data = await GraphqlQuery(
        "CustomerDesignIdea",
        new DesignIdeaList({}),
        args,
        "CustomerDesignIdea",
      );

      if (!isEmpty(data)) {
        const { list = [], total = 0 } = head(data)!;
        const { page, pageSize } = payload;

        const hasMoreIdeas = page * pageSize < total;

        this.setIdeaList({ total, list, reset, hasMoreIdeas });
      } else {
        this.setIdeaList({ total, list: [], reset: true, hasMoreIdeas: false });
      }
    },
    async getIdeaDetailAsync(design_idea_id, state) {
      if (window.parent !== window && location.search.indexOf("host_id=") < 0) {
        const data = await request<DesignIdea>(
          state.DesignIdea.gaeaDomain,
          "designidea.getOne",
          design_idea_id,
        );

        this.setCurrIdea(data);
      } else {
        const data = await GraphqlQuery(
          "GetCustomerDesignIdea",
          new DesignIdea({}),
          {
            design_idea_id,
          },
          "ideaDetail",
        );

        this.setCurrIdea(head(data));
      }
    },
    async uploadFileAsync(payload, state) {
      const { callback, file, root_id, ...model } = payload;
      const res =
        window.parent !== window && location.search.indexOf("host_id=") < 0
          ? await request<ActionResult>(
              state.DesignIdea.gaeaDomain,
              "commonService.upload",
              { file, root_id },
              "8",
            )
          : await GraphqlMutation("Upload", model);
      if (callback) {
        callback(res);
      }
    },
    async submitIdeaAsync(payload, state: any) {
      const {
        callback,
        house_type_text,
        color_text,
        style_text,
        room_text,
        ...model
      } = payload;

      let method = "store";

      if (payload.action === "add") {
        method = "create";
      }
      if (payload.action === "edit") {
        method = "update";
      }

      const result =
        window.parent !== window && location.search.indexOf("host_id=") < 0
          ? await request<DesignIdeaData>(
              state.DesignIdea.gaeaDomain,
              "designidea." + method,
              model,
            )
          : await GraphqlMutation("DesignIdea", {
              ...model,
            });

      const resultObj =
        typeof result.data === "string"
          ? JSON.parse(result.data || "{}")
          : result;

      this.setDesignIdea({
        ...model,
        house_type_text,
        color_text,
        style_text,
        room_text,
        design_idea_status: 1,
        ...resultObj,
      });

      if (callback) {
        callback(resultObj);
      }
    },
    async deleteIdeaAsync(payload) {
      const { callback, design_idea_id } = payload;

      const result = await GraphqlMutation("DesignIdea", {
        action: "delete",
        design_idea_id,
      });

      this.deleteIdea(design_idea_id);
      if (callback) {
        callback();
      }
    },
    async publishIdeaAsync(payload) {
      const { callback, ...model } = payload;

      const result = await GraphqlMutation("DesignIdea", {
        action: "publish",
        ...model,
      });

      this.setDesignIdea({
        ...model,
        ...JSON.parse(result.data),
      });

      if (callback) {
        callback(result);
      }
    },
    async getProductDetailsAsync(payload, state: any) {
      const { shop_product_id_list, root_id, callback } = payload;

      const result =
        window.parent !== window && location.search.indexOf("host_id=") < 0
          ? await request<Product>(
              state.DesignIdea.gaeaDomain,
              "designidea.products",
              {
                shop_product_id_list: shop_product_id_list.split(","),
                root_id,
              },
            )
          : await GraphqlQuery(
              "GetShopProducts",
              new Product({}),
              {
                shop_product_id_list,
              },
              "IdeaProduct",
            );
      if (callback) {
        callback(result);
      }
    },
    async getDesignIdeaRecommendAsync(payload) {
      const { shop_product_id, callback } = payload;

      const result = await GraphqlQuery(
        "DesignIdeaRecommend",
        new Product({}),
        {
          shop_product_id,
        },
        "IdeaProduct",
      );
      if (callback) {
        callback(result);
      }
    },
    async getUpdatedDirectoryAsync(payload, state) {
      const { root_id, callback } = payload;

      const result =
        window.parent !== window && location.search.indexOf("host_id=") < 0
          ? await request<Product>(
              state.DesignIdea.gaeaDomain,
              "accountTag.list",
              { root_id },
            )
          : await GraphqlQuery(
              "PictureTag",
              new DesignIdeaPicture(),
              {},
              "PictureTag",
            );
      if (callback) {
        callback(result);
      }
    },
    async getUpdatedPictureAsync(payload, state) {
      const { picture_tag_id, root_id, callback } = payload;
      const result =
        window.parent !== window && location.search.indexOf("host_id=") < 0
          ? await request<Product>(
              state.DesignIdea.gaeaDomain,
              "accountPicture.list",
              { picture_tag_id, root_id },
            )
          : await GraphqlQuery(
              "Picture",
              new DesignIdeaPicture(),
              { picture_tag_id },
              "Picture",
            );
      if (callback) {
        callback(result);
      }
    },
    async setUpdatedDirectoryAsync(payload, state) {
      const { tagName, root_id, callback } = payload;
      const result =
        window.parent !== window && location.search.indexOf("host_id=") < 0
          ? await request<ActionResult>(
              state.DesignIdea.gaeaDomain,
              "accountTag.create",
              { root_id, picture_tag_name: tagName },
            )
          : await GraphqlMutation("PictureTag", {
              picture_tag_name: tagName,
              action: "saveTag",
            });
      if (callback) {
        callback(result);
      }
    },
    async setUpdatedPictureAsync(payload, state) {
      const { base64, callback, pictureTagId, ...model } = payload;

      let result;

      if (window.parent !== window && location.search.indexOf("host_id=") < 0) {
        const uploadResult = await request<ActionResult>(
          state.DesignIdea.gaeaDomain,
          "commonService.upload",
          model,
          "11",
        );

        result = await request<Product>(
          state.DesignIdea.gaeaDomain,
          "accountPicture.create",
          {
            picture_tag_id: pictureTagId,
            root_id: model.root_id,
            picture: { ...uploadResult, picture_url: uploadResult.file },
          },
        );
      } else {
        result = await GraphqlMutation("Picture", {
          action: "savePic",
          picture_type: 1,
          picture_tag_id: pictureTagId,
          base64: base64,
        });
      }

      if (callback) {
        callback(result);
      }
    },
    async deleteUploaded(payload, state) {
      const { callback, mutationType, root_id, ...arg } = payload;
      const result =
        window.parent !== window && location.search.indexOf("host_id=") < 0
          ? await request<ActionResult>(
              state.DesignIdea.gaeaDomain,
              `${
                mutationType === "Picture" ? "accountPicture" : "accountTag"
              }.delete`,
              { root_id, ...arg },
            )
          : await GraphqlMutation(mutationType, { ...arg });
      if (callback) {
        callback(result);
      }
    },
    async getRejectRemarkAsync(payload) {
      const { callback, designIdeaId } = payload;
      const result = await GraphqlQuery(
        "DesignIdeaRemark",
        new DesignIdea({}),
        { design_idea_id: designIdeaId },
        "DesignIdeaRemark",
      );
      if (callback) {
        callback(getFirstItem(result).remark);
      }
    },
    async getSystemDirectoryAsync(payload, state) {
      const result =
        window.parent !== window && location.search.indexOf("host_id=") < 0
          ? await request<DesignIdeaPicture[]>(
              state.DesignIdea.gaeaDomain,
              "commonData.tags",
            )
          : await GraphqlQuery(
              "SystemPictureTag",
              new DesignIdeaPicture(),
              {},
              "SystemPictureTag",
            );

      const newData = [
        {
          picture_tag_name: "文本",
          icon: "iconwenzi",
          background: "#292633",
          border: "1px dashed #fff",
          parent_tag_id: 0,
          picture_tag_id: -1,
          level: 1,
          types: "text",
        },
        {
          picture_tag_name: "标签点",
          icon: "icont2_biaoqian",
          parent_tag_id: 0,
          picture_tag_id: -2,
          level: 1,
          types: "tag",
        },
        {
          picture_tag_name: "图形",
          icon: "icontuxing",
          parent_tag_id: 0,
          picture_tag_id: -3,
          level: 1,
        },
        {
          picture_tag_id: 1000000,
          picture_tag_name: "直线",
          icon: "iconxian",
          parent_tag_id: -3,
          level: 2,
          types: "line",
        },
        {
          picture_tag_id: 1000001,
          picture_tag_name: "圆形",
          icon: "iconyuanxing",
          parent_tag_id: -3,
          level: 2,
          types: "circle",
        },
        {
          picture_tag_id: 1000002,
          picture_tag_name: "方形",
          icon: "iconchangfangxing",
          parent_tag_id: -3,
          level: 2,
          types: "rect",
        },
        {
          picture_tag_id: 1000003,
          picture_tag_name: "多边形",
          icon: "iconwubianxing",
          parent_tag_id: -3,
          level: 2,
          types: "polygon",
        },
        {
          picture_tag_name: "标签",
          icon: "iconbiaoqian1",
          parent_tag_id: 0,
          picture_tag_id: -3,
          level: 1,
          types: "tags",
        },
        {
          picture_tag_name: "水平标尺",
          icon: "iconbiaochi1",
          parent_tag_id: 0,
          picture_tag_id: -3,
          level: 1,
          types: "itemScaleX",
        },
        {
          picture_tag_name: "竖直标尺",
          icon: "iconshuxiangbiaochi",
          parent_tag_id: 0,
          picture_tag_id: -3,
          level: 1,
          types: "itemScaleY",
        },
        ...result,
      ];
      this.setSystemDirectory(newData);

      this.setSystmMaterial(newData.filter(m => Number(m.level) === 1));
    },
    async getSystemPictureAsync(payload, state) {
      const { picture_tag_id, level, callback } = payload;
      const result =
        window.parent !== window && location.search.indexOf("host_id=") < 0
          ? await request<DesignIdeaPicture[]>(
              state.DesignIdea.gaeaDomain,
              "commonData.pictures",
              { picture_tag_id },
            )
          : await GraphqlQuery(
              "SystemPicture",
              new DesignIdeaPicture(),
              { picture_tag_id },
              "SystemPicture",
            );

      if (!callback) {
        const newData = result.map(item => ({
          ...item,
          level: item.level + 1,
          parent_tag_id: picture_tag_id,
          types: "picture",
        }));

        this.setSystemPicture(newData);

        if (picture_tag_id === -1) {
          this.setSystmMaterial([
            ...state.DesignIdea.systemDirectory.filter(
              m => Number(m.level) === level + 1,
            ),
          ]);
        } else {
          const temp = state.DesignIdea.systemDirectory
            .filter(
              m =>
                Number(m.level) === level + 1 &&
                Number(m.parent_tag_id) === picture_tag_id,
            )
            .map(m => ({ ...m, picture_id: -m.picture_tag_id }));

          this.setSystmMaterial(unionBy(temp, newData, "picture_id"));
        }
      } else {
        callback(result);
      }
    },
    async handleUploadImg(payload, state) {
      const { callback, base64, root_id, file } = payload;

      let url;

      if (window.parent !== window && location.search.indexOf("host_id=") < 0) {
        const res = await request<ActionResult>(
          state.DesignIdea.gaeaDomain,
          "commonService.upload",
          { root_id, file },
          "8",
        );

        url = res.file;
      } else {
        const res = await GraphqlMutation("Upload", {
          base64: base64,
          action: "design",
        });

        [url] = res.data.split(",");
      }

      this.setBgImage(url);
      if (callback) {
        callback(url);
      }
    },
  },
});
