import * as React from "react";
import * as styles from "./index.scss";

const CopyRight = () => {
  return (
    <div className={styles["CopyRight"]}>
      <div>Copyright ©2018 - 2020 MegaHome LLC. All rights reserved.</div>
      <div>北京每家科技有限公司版权所有</div>
      <div className={styles["CopyRight-icp"]}>京ICP备18022364号</div>
    </div>
  );
};

export default React.memo(CopyRight);
