import React, { useState } from "react";
import * as Cookie from "js-cookie";
import {
  GraphQLRequest,
  GraphqlMutation,
} from "../../../helpers/request.helper";
import Icon from "../../../components/icon";
import { Row, Col, Form, message } from "antd";
import CheckBox from "../../../wrappers/checkbox";
import Select from "../../../wrappers/select";
import RcInput from "../../../wrappers/input";
import { Link } from "../../../wrappers/typography";
import { PrimaryButton } from "../../../wrappers/button";
import { useTheme } from "styled-components";
import { useIntl } from "react-intl";
import { useCountDown } from "ahooks";
import queryString from "query-string";

/**
 *
 * 登录模块
 *
 *
 */
type valuesType = {
  email: string;
  password: string;
  showPassword: boolean;
  phone: any;
  code: any;
};

interface LoginProps {
  toSiginUp: (email?: string) => void;
  toResetPassword: (password?: string) => void;
  insideModal?: boolean;
}

const LoginForm: React.FC<LoginProps> = ({
  toSiginUp,
  toResetPassword,
  insideModal = true,
}) => {
  const { formatMessage: f } = useIntl();
  const requiredText = f({ id: "Required" });

  const { primaryColor, textColor } = useTheme();

  const [phone, setPhone] = useState(""),
    [loginType, setLoginType] = useState(1),
    [btnLoading, setBtnLoading] = useState(false);

  const [countdown, setTargetDate] = useCountDown();

  const countdownSecond = Math.round(countdown / 1000);
  /**
   *
   * 获取验证码
   *
   */
  const handleCodeGet = async () => {
    if (!phone) return;

    const result = await GraphqlMutation("Login", { action: "code", phone });

    if (result.status_code === 200) {
      message.success(result.message);

      setTargetDate(Date.now() + 1000 * 60);
    }
  };

  /**
   *
   * 登录请求函数
   *
   */
  const LoginSubmitFn = async ({
    email,
    password,
    phone,
    code,
  }: valuesType) => {
    setBtnLoading(true);

    const type = loginType === 2 ? "email" : "phone",
      request =
        loginType === 2
          ? `email:"${email}",password:"${password}"`
          : `phone:"${phone}",code:"${code}"`;

    const response = await GraphQLRequest(
      "post",
      `
      mutation{
        Login(action:"${type}", ${request})
        {
          status_code
          message
          data
        }
      }
       `,
    );

    setBtnLoading(false);

    if (response.data.data.Login.status_code === 200) {
      // 登录检查成功
      Cookie.set("store_token", response.headers.store_token);
      Cookie.set("store_time", response.headers.store_time);

      if (insideModal) {
        window.location.reload();
      } else {
        const { link } = queryString.parse(location.search);
        location.href = link ? (link as string) : location.origin;
      }
    } else if (response.data.data.Login.status_code === 4003) {
      toResetPassword(password);

      toSiginUp(email);
    }
  };

  return (
    <div>
      {!insideModal && (
        <Row style={{ padding: "10px 0 0 10px", color: textColor }}>
          {f({ id: "WelcomeBack" })}
        </Row>
      )}

      <Form
        name="basic"
        onFinish={LoginSubmitFn}
        style={{ padding: "30px 48px 96px 48px" }}
      >
        <Row style={{ marginBottom: "20px" }}>
          <CheckBox
            shape="circle"
            checked={loginType === 1}
            onChange={() => setLoginType(1)}
          >
            {f({ id: "LoginFromPhone" })}
          </CheckBox>
          <CheckBox
            shape="circle"
            checked={loginType === 2}
            onChange={() => setLoginType(2)}
          >
            {f({ id: "LoginFromEmail" })}
          </CheckBox>
        </Row>
        {loginType === 1 && (
          <>
            <Form.Item style={{ marginBottom: "0px" }}>
              <Row gutter={10}>
                <Col span={9}>
                  <Form.Item>
                    <Select defaultValue={1}>
                      <Select.Option value={1}>中国大陆+86</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={15}>
                  <Form.Item
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: f({ id: "PleaseEnterTelePhone" }),
                      },
                    ]}
                  >
                    <RcInput
                      placeholder={f({ id: "EnterTelePhone" })}
                      onChange={e => setPhone(e.target.value)}
                    ></RcInput>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item
              name="code"
              rules={[
                { required: true, message: f({ id: "PleaseEnterCode" }) },
              ]}
            >
              <div className="login-code">
                <RcInput
                  addonBgColor="#eaeaea"
                  addonAfter={
                    countdownSecond > 0 ? (
                      countdownSecond
                    ) : (
                      <span
                        onClick={handleCodeGet}
                        style={{ cursor: "pointer" }}
                      >
                        获取验证码
                      </span>
                    )
                  }
                  placeholder={f({ id: "EnterCode" })}
                />
              </div>
            </Form.Item>
          </>
        )}

        {loginType === 2 && (
          <>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: requiredText },
                {
                  max: 60,
                  message: f(
                    { id: "TleLengthOfTheInputExceedNumCharacters" },
                    {
                      num: 60,
                      field: f({ id: "Email" }),
                    },
                  ),
                },
                {
                  type: "email",
                  message: f({
                    id: "PleaseEnterAValidEmailAddressExclamationMark",
                  }),
                },
              ]}
            >
              <RcInput
                placeholder={`${f({
                  id: "Email",
                })} *`}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: requiredText },
                {
                  min: 6,
                  message: f(
                    { id: "TheLengthOfTheInputMustBeAtLeast" },
                    {
                      num: 6,
                      field: f({ id: "Password" }),
                    },
                  ),
                },
                {
                  max: 20,
                  message: f(
                    { id: "TleLengthOfTheInputExceedNumCharacters" },
                    {
                      num: 20,
                      field: f({ id: "Password" }),
                    },
                  ),
                },
                {
                  pattern: /[0-9a-zA-Z_-]{1,}$/,
                  message: f(
                    { id: "TleLengthOfTheInputExceedNumCharacters" },
                    {
                      num: 20,
                      field: f({ id: "Password" }),
                    },
                  ),
                },
              ]}
            >
              <RcInput
                type="password"
                placeholder={`${f({
                  id: "Password",
                })} *`}
              />
            </Form.Item>
          </>
        )}

        <Form.Item>
          <PrimaryButton
            htmlType="submit"
            block
            loading={btnLoading}
            bgColor={primaryColor}
          >
            {f({ id: "Login" })}
            <Icon type="icont1_correct"></Icon>
          </PrimaryButton>
        </Form.Item>

        <Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span
              onClick={() =>
                insideModal
                  ? toSiginUp()
                  : (location.href = `/register/${location.search}`)
              }
              style={{
                cursor: "pointer",
                color: primaryColor,
              }}
            >
              {f({ id: "SignUp" })} &gt;
            </span>
            <Link
              color={textColor}
              onClick={() =>
                insideModal
                  ? toResetPassword()
                  : (location.href = `/forgot/${location.search}`)
              }
            >
              {f({ id: "ForgotPassword" })}
            </Link>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default React.memo(LoginForm);
