//
//  Color Converter Algorithm Web Tool
//  颜色转换算法
//
//  @author    Hardys Hu <hardyshu@imegahome.com>
//  @copyright 2018 MegaHome LLC - All rights reserved
//  @copyright 2018 北京每家科技有限公司，保留一切权利
//

/**
 * Convert the decimal to hex string value
 * 将10进制转16进制
 *
 * @param int decimal 十进制值 The decimal value
 *
 * @return string
 */
// Commented因为暂时没用上
// const decimalToHexString = (decimal: number): string => {
//   const hex = decimal.toString(16);

//   // 补全十六进制的位数
//   return hex.length == 1 ? "0" + hex : hex;
// }

/**
 * Convert the RGB value to Hex string
 * 将RGB颜色值转6进制
 *
 * @param int decimal 十进制值 The decimal value
 *
 * @return string
 */
// Commented因为暂时没用上
// const rgbToHexColorString = (red: number, green: number, blue: number): string => {
//   if (isNaN(red) || isNaN(green) || isNaN(blue)) {
//     return "#000000";
//   }
//   return (
//     "#" +
//     decimalToHexString(red) +
//     decimalToHexString(green) +
//     decimalToHexString(blue)
//   );
// }

/**
 * Convert the RGB value to hex string color value
 * 将RGB值转换为16进制的颜色字符串
 *
 * @param int    hexColor 十六进红色制值 The decimal red value
 * @param string value    是否为RGB值   The flag of the return value
 *
 * @return array
 */
const hexColorStringToRgb = (
  hexColor: string,
  value: string = "rgb",
): number[] => {
  const red: number = parseInt(hexColor.substring(1, 3), 16);
  const green: number = parseInt(hexColor.substring(3, 5), 16);
  const blue: number = parseInt(hexColor.substring(5, 7), 16);

  // 检测
  if (isNaN(red) || isNaN(green) || isNaN(blue)) {
    return [];
  }
  let colorValueList = [];
  switch (value) {
    case "r":
      colorValueList.push(red);
      break;
    case "g":
      colorValueList.push(green);
      break;
    case "b":
      colorValueList.push(blue);
      break;
    case "rgb":
      colorValueList = [red, green, blue];
      break;
    default:
      colorValueList = [red, green, blue];
  }
  return colorValueList;
};

/*****************************************/
/*               Algorithm               */
/*                算法部分                */
/*****************************************/

type ColorInfo = {
  zh: string;
  en: string;
  key: string;
  group: string;
};

/**
 * @const array COLOR_LIST The color we accept here at MegaHome
 * @const array COLOR_LIST 颜色列表
 */
// tslint:disable-next-line: variable-name
export const pl_color: Record<string, ColorInfo> = {
  "#000000": { zh: "黑色", en: "Black", key: "black", group: "black" },
  "#ffffff": { zh: "白色", en: "White", key: "white", group: "white" },
  "#d7c4ad": {
    zh: "未上色",
    en: "Unfinished",
    key: "unfinished",
    group: "unfinished",
  },
  "#e1e1e1": { zh: "银色", en: "Silver", key: "silver", group: "grey" },
  "#a0a0a0": { zh: "浅灰", en: "Light Grey", key: "light_grey", group: "grey" },
  "#999999": { zh: "灰色", en: "Grey", key: "grey", group: "grey" },
  "#3c3c3c": { zh: "深灰", en: "Dark Grey", key: "dark_grey", group: "grey" },
  "#787878": {
    zh: "太空灰",
    en: "Space Grey",
    key: "space_grey",
    group: "grey",
  },
  "#ffb5c5": { zh: "粉色", en: "Pink", key: "pink", group: "red" },
  "#cf1c1f": { zh: "猩红", en: "Scarlet", key: "scarlet", group: "red" },
  "#e15555": { zh: "红色", en: "Red", key: "red", group: "red" },
  "#9b2020": { zh: "品红", en: "Maroon", key: "maroon", group: "red" },
  "#855439": { zh: "棕色", en: "Brown", key: "brown", group: "red" },
  "#9e661e": { zh: "铜色", en: "Bronze", key: "bronze", group: "red" },
  "#ffdf00": { zh: "金色", en: "Gold", key: "gold", group: "yellow" },
  "#e3ce18": { zh: "黄色", en: "Yellow", key: "yellow", group: "yellow" },
  "#e4841c": { zh: "桔色", en: "Orange", key: "orange", group: "yellow" },
  "#f5f5dc": { zh: "米色", en: "Beige", key: "beige", group: "yellow" },
  "#caa472": { zh: "原色", en: "Natural", key: "natural", group: "natural" },
  "#ee82ee": { zh: "浅紫", en: "Violet", key: "violet", group: "purple" },
  "#800080": { zh: "紫色", en: "Purple", key: "purple", group: "purple" },
  "#55aaf0": { zh: "浅蓝", en: "Light Blue", key: "light_blue", group: "blue" },
  "#225ea8": { zh: "蓝色", en: "Blue", key: "blue", group: "blue" },
  "#084a86": { zh: "深蓝", en: "Navy", key: "navy", group: "blue" },
  "#bdf3f3": { zh: "青色", en: "Cyan", key: "cyan", group: "cyan" },
  "#22d5d5": { zh: "淡青", en: "Light Cyan", key: "light_cyan", group: "cyan" },
  "#a0af1e": {
    zh: "黄绿",
    en: "Yellow Green",
    key: "yellow_green",
    group: "green",
  },
  "#98ed93": { zh: "亮绿", en: "Lime", key: "lime", group: "green" },
  "#33a02c": { zh: "绿色", en: "Green", key: "green", group: "green" },
  "#0e5b02": {
    zh: "深绿",
    en: "Dark Green",
    key: "dark_green",
    group: "green",
  },
};

/**
 * Get the closest color from a given hex string color value
 * 将输入的十六进制RGB字符串值转换为最相近的十六进制的颜色字符串
 *
 * @param array color 十六进制颜色值 The hex string of the color value
 * @param array  range 颜色区间列表   The list of a search zone: [colorA, colorB]
 *
 * @return array
 */
const getClosestColor = (color: number[], range: string[] = []): string[] => {
  // The largest diff from 0-255 times three
  let lowest = 765;
  let output = ["#ffffff", "white"];

  // search from this range if given
  if (range !== undefined && range.length !== 0) {
    range.forEach((item: string) => {
      // key name to rgb value
      const plValue = hexColorStringToRgb(item);
      const sum =
        Math.abs(color[0] - plValue[0]) +
        Math.abs(color[1] - plValue[1]) +
        Math.abs(color[2] - plValue[2]);
      if (sum < lowest) {
        lowest = sum;
        output = [item, pl_color[item]["en"]];
      }
    });
  } else {
    for (const [key, value] of Object.entries(pl_color)) {
      // We do not count the grey colors here
      // 灰色系列为细分域所以不需要在此重复计算
      const greyColors = [
        "#e1e1e1",
        "#a0a0a0",
        "#999999",
        "#787878",
        "#787878",
      ];
      if (greyColors.includes(key)) {
        continue;
      }
      // key name to rgb value
      const plValue = hexColorStringToRgb(key);
      const sum =
        Math.abs(color[0] - plValue[0]) +
        Math.abs(color[1] - plValue[1]) +
        Math.abs(color[2] - plValue[2]);
      if (sum < lowest) {
        lowest = sum;
        output = [key, value.en];
      }
    }
  }
  return output;
};

/**
 * Get the closest color in a thirty values array used at MegaHome
 * 将输入的十六进制RGB字符串值转换为在MegaHome最相近的十六进制的颜色字符串并返回相关信息
 *
 * @param string color 十六进制颜色值 The hex string of the color value. e.g. #ffffff
 *
 * @return array
 */
const getColor = (color: string): string[] => {
  // Input validation
  // 非标准输入
  if (!color.includes("#") || color.length !== 7) {
    return ["#000000", "Black"];
  }
  const red = parseInt(color.substring(1, 3), 16);
  const green = parseInt(color.substring(3, 5), 16);
  const blue = parseInt(color.substring(5, 7), 16);

  // Number input validation
  // 非标准输入
  if (isNaN(red) || isNaN(green) || isNaN(blue)) {
    return ["#000000", "Black"];
  }

  // The comparation value of the RGB input
  // 参照值
  const lowValue = Math.min(red, green, blue);
  const highValue = Math.max(red, green, blue);

  // Enter to black section
  // 黑色
  if (red <= 65 && green <= 65 && blue <= 65) {
    return ["#000000", "Black"];
  }

  // Enter to white section
  // 白色
  if (red >= 240 && green >= 240 && blue >= 240) {
    return ["#ffffff", "White"];
  }

  // Enter to grey section
  // 灰色
  if (
    Math.abs(red - green) <= 16 &&
    Math.abs(red - blue) <= 16 &&
    Math.abs(blue - green) <= 16
  ) {
    // 灰色包含银色, 浅灰, 中灰， 深灰
    // Silver
    // 银色
    if ((red >= 200 && green >= 200 && blue >= 200) || lowValue >= 190) {
      return ["#e1e1e1", "Silver"];
    }

    // Light grey
    // 浅灰
    if ((red >= 160 && green >= 160 && blue >= 160) || lowValue >= 150) {
      return ["#a0a0a0", "Light Grey"];
    }

    // Dark grey
    // 深灰
    if ((red >= 120 && green >= 120 && blue >= 120) || lowValue >= 110) {
      return ["#787878", "Dark Grey"];
    }

    // Space Grey
    // 太空灰
    if ((red >= 60 && green >= 60 && blue >= 60) || lowValue >= 50) {
      return ["#787878", "Space Grey"];
    }

    // The common grey if nothing capthed here above
    // 由于灰色系属于细分，所以此处可以直接输出
    return ["#999999", "Grey"];
  }

  // Enter the yellow section
  // 黄色系
  if (lowValue === blue) {
    if (Math.abs(green - red) <= 100 && Math.min(green, red) - blue >= 60) {
      // Orange
      // 橙色
      if (red - green >= 40 && green <= 220 && blue <= 80) {
        return ["#e4841c", "Orange"];
      }

      // Bronze
      // 铜色
      if (
        red - green >= 20 &&
        blue <= 80 &&
        green >= 80 &&
        green <= 150 &&
        red >= 150
      ) {
        return ["#9e661e", "Bronze"];
      }

      // Gold
      // 金色
      if (red >= 220 && green >= 220 && blue <= 80) {
        return ["#ffdf00", "Gold"];
      }

      // Yellow
      // 黄色
      if (red >= 180 && green <= 190 && blue <= 100) {
        return ["#e3ce18", "Yellow"];
      }

      // Beige
      // 米色
      if (red >= 230 && green >= 230 && blue >= 120 && blue <= 200) {
        return ["#f5f5dc", "Beige"];
      }
    }
  }

  // The cyan section
  // 青色系
  if (lowValue === red) {
    // Cyan
    // 青色
    if (
      Math.abs(green - blue) <= 60 &&
      green >= 120 &&
      blue >= 140 &&
      red <= 80
    ) {
      return ["#bdf3f3", "Cyan"];
    }

    // Light cyan
    // 淡青色
    if (
      Math.abs(green - blue) <= 80 &&
      green >= 200 &&
      blue >= 180 &&
      red >= 120
    ) {
      return ["#22d5d5", "Light Cyan"];
    }
  }

  // The Purple section
  // 紫色系
  if (lowValue === green) {
    // Violet
    // 浅紫
    if (
      Math.abs(blue - red) <= 120 &&
      green >= 100 &&
      green <= 230 &&
      red >= 120 &&
      blue <= 245
    ) {
      return ["#ee82ee", "Violet"];
    }

    // Purple
    // 紫色
    if (
      Math.abs(blue - red) <= 80 &&
      green <= 110 &&
      Math.min(blue, red) - green >= 15
    ) {
      return ["#800080", "Purple"];
    }
  }

  // The red section
  // 红色系
  if (highValue === red) {
    if (Math.abs(green - blue) <= 20 && red - Math.max(green, blue) >= 60) {
      // Pink
      // 粉色
      if (red >= 230 && green >= 110 && blue >= 110) {
        return ["#ffb5c5", "Pink"];
      }

      // Scarlet
      // 猩红
      if (red >= 160 && red <= 230 && green <= 40 && blue <= 40) {
        return ["#cf1c1f", "Scarlet"];
      }

      // Red
      // 红色
      if (red >= 160 && red <= 230 && green <= 80 && blue <= 80) {
        return ["#e15555", "Red"];
      }

      // Maroon
      // 品红
      if (red >= 130 && red <= 160 && green >= 60 && blue >= 60) {
        return ["#9b2020", "Maroon"];
      }

      // Brown
      // 棕红
      if (red <= 130 && green <= 60 && blue <= 60) {
        return ["#855439", "Brown"];
      }
    }
  }

  // The green section
  // 绿色系
  if (highValue === green) {
    // Lime
    // 柠檬绿
    if (red <= 230 && red >= 100 && green >= 170 && blue <= 210) {
      return ["#98ed93", "Lime"];
    }

    // Green
    // 绿色
    if (red <= 150 && green >= 150 && blue <= 100) {
      return ["#33a02c", "Green"];
    }

    // Dark green
    // 深绿
    if (red <= 120 && green <= 180 && blue <= 110) {
      return ["#0e5b02", "Dark Green"];
    }
  }

  // The yellow green section
  // 黄绿色是一个人眼辨识度很高但又不容易被捕捉的区域
  if (red === green || Math.abs(red - green) <= 20) {
    // Yellow green
    if (Math.min(green, red) - blue >= 60) {
      return ["#a0af1e", "Yellow Green"];
    }
  }

  // The blue section
  // 蓝色系
  if (highValue === blue) {
    // Light blue
    // 浅蓝色
    if (Math.abs(green - red) >= 5 && green >= 160 && blue >= 210) {
      return ["#55aaf0", "Light Blue"];
    }

    // Blue
    // 蓝色
    if (
      red <= 80 &&
      green <= 60 &&
      Math.abs(green - red) <= 60 &&
      blue >= 220
    ) {
      return ["#225ea8", "Blue"];
    }

    // Navy
    // 深蓝色
    if (red <= 90 && green <= 120 && blue <= 180 && blue >= 80) {
      return ["#084a86", "Navy"];
    }
  }

  // co-sourced color handler
  // 同原色的处理
  if (Math.abs(red - green) <= 10) {
    if (Math.min(red, green) - blue >= 100) {
      // Yellow
      // 黄色
      return ["#a0af1e", "Yellow Green"];
    }
    if (blue - Math.min(red, green) >= 500 && green >= 100) {
      // Violet
      // 紫色
      return ["#ee82ee", "Violet"];
    }
  }

  // Pink and light cyan section
  // 紫色与青色部分
  if (Math.abs(blue - green) <= 10) {
    if (Math.min(blue, green) - red >= 100) {
      // Cyan
      return ["#22d5d5", "Light Cyan"];
    }
    if (red - Math.min(blue, green) >= 100 && green >= 100 && blue >= 50) {
      // Pink
      return ["#ffb5c5", "Pink"];
    }
  }

  // Purple and lime section
  // 紫色与柠檬色部分
  if (Math.abs(red - blue) <= 10) {
    if (Math.min(red, blue) - green >= 100) {
      // Purple
      return ["#800080", "Purple"];
    }
    if (green - Math.min(red, blue) >= 100 && green >= 180 && red <= 120) {
      // Lime
      return ["#98ed93", "Lime"];
    }
  }

  // Must be a yellow like range
  // 为捕捉到的黄色色谱类
  if (lowValue === blue) {
    return getClosestColor(
      [red, green, blue],
      [
        "#d7c4ad",
        "#855439",
        "#ffdf00",
        "#e3ce18",
        "#e4841c",
        "#9e661e",
        "#f5f5dc",
        "#caa472",
        "#a0af1e",
      ],
    );
  }

  // Must be a red like range
  // 为捕捉到的红色色谱类
  if (highValue === red) {
    return getClosestColor(
      [red, green, blue],
      [
        "#d7c4ad",
        "#ffb5c5",
        "#cf1c1f",
        "#e15555",
        "#9b2020",
        "#855439",
        "#e4841c",
        "#9e661e",
      ],
    );
  }

  // Must be a green like range
  // 为捕捉到的绿色色谱类
  if (highValue === green) {
    return getClosestColor(
      [red, green, blue],
      ["#caa472", "#a0af1e", "#98ed93", "#33a02c", "#0e5b02"],
    );
  }

  // Must be a blue like range
  // 为捕捉到的蓝色色谱类
  if (highValue === blue) {
    return getClosestColor(
      [red, green, blue],
      ["#55aaf0", "#225ea8", "#084a86"],
    );
  }

  // Find the closet color in the color pick list
  // 在颜色集合中找到最相近的颜色
  return getClosestColor([red, green, blue]);
};

export default getColor;
