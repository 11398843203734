export namespace CanvasToImage {
  /**
   * Converts a HTMLCanvasElement element to an image and saves it to the client system.
   *
   * @param cv the HTMLCanvasElement to convert to an image.
   * @param type the type of the image to be saved.
   * @param name the file name to save the image with.
   * @param w the width the canvas will be scaled to.
   * @param h the height the canvas will be scaled to.
   */
  export function saveAsImage(
    cv: HTMLCanvasElement,
    type: string = "png",
    name?: string,
    w?: number,
    h?: number,
  ): void {
    const url = getDataURL(cv, type, w, h);
    name
      ? saveFile(url, name)
      : saveFile(url.replace(type, "image/octet-stream"));
  }

  /**
   * Converts an HTMLCanvasElement to an HTMLImageElement.
   *
   * @param cv the HTMLCanvasElement to convert to an HTMLImageElement.
   * @param type the type of the image to be saved.
   * @param w the width the canvas will be scaled to.
   * @param h the height the canvas will be scaled to.
   * @returns the converted HTMLCanvasElement as an HTMLImageElement.
   */
  export function convertToImage(
    cv: HTMLCanvasElement,
    type: string = "png",
    w?: number,
    h?: number,
  ): HTMLImageElement {
    const url = getDataURL(cv, type, w, h);
    return getImage(url);
  }

  /**
   * Returns the HTMLCanvasElement as a data URL.
   *
   * @param cv the HTMLCanvasElement to return as a data URL.
   * @param type the type of the image to be saved.
   * @param w the width the canvas will be scaled to.
   * @param h the height the canvas will be scaled to.
   * @return the data URL of the HTMLCanvasElement.
   */
  export function toDataUrl(
    cv: HTMLCanvasElement,
    type: string = "png",
    w?: number,
    h?: number,
  ): string {
    let canvas = cv;
    if (typeof canvas == "string") {
      canvas = document.getElementById(canvas) as HTMLCanvasElement;
    }
    type = getType(type);
    return getDataURL(canvas, type, w, h);
  }

  /**
   * Copies HTMLCanvasElement to the clipboard.
   *
   * @param cv the canvas to copy.
   * @param w the width the canvas will be scaled to.
   * @param h h the height the canvas will be scaled to.
   */
  export async function copyToClipboard(
    cv: HTMLCanvasElement,
    w?: number,
    h?: number,
  ): Promise<void> {
    try {
      // Chrome supports Clipboard API.
      const url = getDataURL(cv, "png", w, h);
      const data = await fetch(url);
      const blob = await data.blob();
      await (navigator as NavigatorClipboard).clipboard.write([
        new ClipboardItem({
          [blob.type]: blob,
        }),
      ]);
      console.log("ClipboardAPI::Image copied.");
    } catch (err) {
      // Other browsers support document.execCommand('copy').
      try {
        const img = convertToImage(cv, "png", w, h);
        document.body.appendChild(img);
        // #3. Highlight the input's value
        const rng = document.createRange();
        rng.setStartBefore(img);
        rng.setEndAfter(img);
        rng.selectNode(img);
        const sel = window.getSelection();
        if (sel) {
          sel.addRange(rng);
          document.execCommand("copy");
        }
        document.body.removeChild(img);
        console.log("document.execCommand()::Image copied.");
      } catch (err) {
        console.error(err.name, err.message);
      }
    }
  }

  // Type declarations for Clipboard API
  // https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API
  /**
   * Extends the Clipboard API to support writing of images.
   */
  interface ClipboardExtended extends Clipboard {
    write(clipboardItem: ClipboardItem[]): Promise<void>;
  }

  /**
   * Contains a map defined by MIME type key and value as an image represented as a blob.
   */
  declare class ClipboardItem {
    constructor(data: { [mimeType: string]: Blob });
  }

  /**
   * Clipboard interface.
   */
  interface NavigatorClipboard extends Navigator {
    // Only available in a secure context.
    readonly clipboard: ClipboardExtended;
  }

  /**
   * Calls the HTMLCanvasElement API to convert to image data.
   *
   * @param cv the HTMLCanvasElement to convert to an image.
   * @param type the type of the image to be saved.
   * @param w the width the canvas will be scaled to.
   * @param h the height the canvas will be scaled to.
   * @returns the data url of the image.
   */
  function getDataURL(
    cv: HTMLCanvasElement,
    type: string,
    w?: number,
    h?: number,
  ): string {
    const canvas = scaleCanvas(cv, w, h);
    return canvas.toDataURL(type);
  }

  /**
   * Validates the user entered type using matchers and returns the appropriate MIME type.
   * If an invalid type is entered, defaults to 'image/png'.
   *
   * @param type the name of the MIME type to get.
   * @returns the MIME type of the image.
   */
  function getType(type: string): string {
    const matched: RegExpMatchArray | null = type
      .toLowerCase()
      .match(/png|jpeg|jpg|gif/);
    if (!(matched && matched.length >= 0)) {
      console.error(
        'CanvasToImage::Invalid MIME type! Defaulting to "image/png"',
      );
    }
    type = !!(matched && matched.length >= 0) ? matched[0] : "png";
    return `image/${type}`;
  }

  /**
   * Saves file to client machine.
   *
   * @param url the data url to save as a file.
   * @param name the file name of the file to save.
   */
  function saveFile(url: string, name?: string): void {
    if (name && name != "") {
      const link: HTMLAnchorElement = document.createElement("a");
      link.href = url;
      link.download = name;
      link.target = "_blank";
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } else {
      document.location.href = url;
    }
  }

  /**
   * Converts a data url to a HTMLImageElement.
   *
   * @param url the data url to convert.
   * @returns the converted HTMLImageElement.
   */
  function getImage(url: string): HTMLImageElement {
    const img = document.createElement("img") as HTMLImageElement;
    img.src = url;
    return img;
  }

  /**
   * Scales an HTMLCanvasElement given a width and a height. Defaults to the given HTMLCanvasElement
   * dimensions if no width or height are provided.
   *
   * @param cv the HTMLCanvasElement to scale.
   * @param w the width the canvas will be scaled to.
   * @param h the height the canvas will be scaled to.
   * @returns a scaled HTMLCanvasElement.
   */
  function scaleCanvas(
    cv: HTMLCanvasElement,
    w?: number,
    h?: number,
  ): HTMLCanvasElement {
    const width = w ? w : cv.width;
    const height = h ? h : cv.height;
    const canvas = document.createElement("canvas") as HTMLCanvasElement;
    const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(cv, 0, 0, cv.width, cv.height, 0, 0, width, height);
    return canvas;
  }
}
