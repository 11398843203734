import { createModel } from "@rematch/core";
import { GraphqlQuery } from "../../helpers/request.helper";
import { getFirstItem } from "../../helpers/modelHelpers";
import Shop, { ShopDetail } from "../../models/Shop";
import { ProductFilter } from "../../models/ProductFilter";
import { Collection } from "../../models/Collection";

export interface StateType {
  shop: Shop;

  breadcrumbs: { url: string; title: string }[];

  top_cate_id: number;

  sku_type: number;

  collection: number[];
}

export default createModel({
  state: {} as StateType,
  reducers: {
    initCollection: (state: StateType, collection: Collection[]) => {
      return { ...state, collection };
    },
    initShop: (state: StateType, shop: Shop, shopDetail: ShopDetail) => ({
      ...state,
      shop,
      shopDetail,
    }),
    submitCollectShop: (
      state: StateType,
      { data_id, action }: { data_id: number; action: "like" | "cancel" },
    ) => ({
      ...state,
      collection:
        action === "like"
          ? [...state.collection, data_id]
          : state.collection.filter(item => item !== data_id),
    }),
  },

  effects: {
    async getShopAsync(payload) {
      // const data = await GraphqlQueryMulti(
      //   { method: "PickList", model: new ProductFilter() },
      //   {
      //     method: "Shop",
      //     model: new Sku({}),
      //     args: { page },
      //     metadataKey: "SkuList",
      //   },
      // );
      const data = await GraphqlQuery("Shop", new Shop({ shop_id: payload }));

      // const detail = await GraphqlQuery(
      //   "getShopCategory",
      //   new ShopDetail({ shop_id: payload }),
      // );

      this.initShop(getFirstItem(data), undefined);
    },
    async getShopCollectionAsync() {
      const data = await GraphqlQuery("CollectIds", new Collection(), {
        collect: "shop",
      });

      const pids = data.map(c => c.data_id);

      this.initCollection(pids);
    },
  },
});
