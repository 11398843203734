import React, { useEffect, useState } from "react";
import ThemeProvider from "../../themes";
import { BusinessContext } from "../BusinessContext";
import replaceState from "../../helpers/reolaceState";
import Header from "../Header";
import Footer from "../Footer";
import { ConfigProvider, Layout } from "antd";
import "antd/dist/antd.css";
import zhCN from "antd/es/locale/zh_CN";
import "dayjs/locale/zh-cn";

const PageLayout: React.FC<{
  children?: React.ReactNode;
  breadcrumbs?: { url: string; title: string }[];
  className?: string;
  anonymousHeader?: boolean;
  isHeaderVisible?: boolean;
  isFooterVisible?: boolean;
  isToBusiness?: boolean;
}> = ({
  children,
  breadcrumbs,
  anonymousHeader,
  isHeaderVisible = true,
  isFooterVisible = true,
  isToBusiness = false,
  ...props
}) => {
  const template = "ChildTime",
    primaryColor = "#C21D44", // 后台定义为主要颜色
    secondaryColor = "#FDCBD7", // 后台定义为辅助颜色
    pageBackgroundColor = "#F9F9F9", // 页面背景色
    textColor = "#4B4B4B", // 文本颜色
    borderColor = "#BDBCBC",
    menuTextColor = "#fff",
    linkColor = "#7592A6",
    panelColor = "#FFFFFF",
    dialogBackgroundColor = "#ECEAE8",
    bussinessPrimaryColor = "#F48033"; //2B主色

  const [isTob, setIsTob] = useState(false);

  useEffect(() => {
    replaceState.set();
    setIsTob(location.pathname.startsWith("/tob"));
  }, []);

  const { Content } = Layout;

  return (
    <BusinessContext.Provider
      value={{
        isToBusiness: isToBusiness || isTob,
      }}
    >
      <ConfigProvider locale={zhCN}>
        <ThemeProvider>
          <Layout
            style={{
              backgroundColor: pageBackgroundColor,
              color: textColor,
            }}
            data-theme={template}
          >
            {isHeaderVisible && (
              <Header breadcrumbs={breadcrumbs} anonymous={anonymousHeader} />
            )}
            <Content>{children}</Content>
            {isFooterVisible && <Footer />}
          </Layout>
        </ThemeProvider>
      </ConfigProvider>
    </BusinessContext.Provider>
  );
};

export default React.memo(PageLayout);
