import styled from "styled-components";
import { Typography } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";

const Title = styled(Typography.Title).attrs(props => ({
  style: {
    color: props.color ?? props.theme.textColor,
    fontSize: props.size,
    ...props.style,
  },
}))<{ color?: string; size?: SizeType | string }>``;

const Text = styled(Typography.Text).attrs(props => ({
  style: {
    color: props.color ?? props.theme.textColor,
    fontSize: props.size,
    fontWeight: props.weight,
    ...props.style,
  },
}))<{ color?: string; size?: SizeType | string; weight?: number }>``;

const Paragraph = styled(Typography.Paragraph).attrs(props => ({
  style: {
    color: props.color ?? props.theme.textColor,
    fontSize: props.size,
    ...props.style,
  },
}))<{ color?: string; size?: SizeType | string }>``;

const Link = styled(Typography.Link).attrs(props => ({
  style: {
    color: props.color ?? props.theme.linkColor,
    fontSize: props.size,
    fontWeight: props.weight,
    ...props.style,
  },
}))<{ color?: string; size?: SizeType | string; weight?: number }>``;

export { Title, Text, Paragraph, Link };
