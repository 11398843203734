// 历史订单列表
import * as React from "react";
import Layout from "../Layout";
import Content from "./Content";
import InfoLayout from "../UserCenter/components/Layout";
import { useSelector } from "react-redux";
import { RootState } from "../../store/configureStore";

const OrderHistory: React.FC = () => {
  const {
    breadcrumbs = [
      {
        url: "/",
        title: "首页",
      },
      {
        url: "/account/orders",
        title: "订单",
      },
    ],
  } = useSelector((state: RootState) => state.OrderHistoryPage);

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <InfoLayout>
        <Content />
      </InfoLayout>
    </Layout>
  );
};

export default OrderHistory;
