import { GraphqlField, GraphqlRefField } from "../helpers/decorators";

export class ListTotal {
  @GraphqlField("default", "Provider")
  public total: number;
  @GraphqlField()
  public pageSize: number;
  @GraphqlField()
  public pageNumber: number;
}

export class File {
  @GraphqlField()
  public file: string;
}

/**
 * 模拟布尔值
 */
export enum BooleanLike {
  False = 0,
  True = 1,
}

export class Option {
  @GraphqlField()
  public text: string;
  @GraphqlField()
  public value: string;
}

export class GaeaList<T> {
  public rows: T[];

  public times: string;

  public total: number;
}

export class Verify {
  @GraphqlField()
  public flag: number;
}
