import { createModel } from "@rematch/core";
import produce from "immer";
import {
  GraphQLRequest,
  GraphqlQuery,
  GraphqlMutation,
} from "../../../helpers/request.helper";
import Messages from "../../components/messages";
import { ShareCart } from "../../../models/Cart";
import { getFirstItem } from "../../../helpers/modelHelpers";

export type StateType = {
  shareData: ShareCart[];
  accountName: string;
};

export default createModel({
  state: {} as StateType,
  reducers: {
    // 初始化请求数据
    setData(state: {}, shareData: any, accountName: string) {
      return { ...state, shareData, accountName };
    },
  },
  effects: {
    async getShareData(payload) {
      const { token } = payload;
      const data = new ShareCart({ token });
      const res = await GraphqlQuery("Share", data, {}, "ShareCart");
      this.setData(
        getFirstItem(res).share_data,
        getFirstItem(res).account_name,
      );
    },
    async addToCartAsync(payload) {
      const { callback, shop_product_id, quantity, agent_code } = payload;
      const res = await GraphqlMutation("TobCart", {
        shop_product_id: shop_product_id,
        quantity: quantity,
        action: "add",
        agent_code: agent_code,
      });

      callback && callback(res);
    },
    async getProtocolAsync({ url, callback }) {
      const buffer = await fetch(url).then(res => res.arrayBuffer());
      if (callback) {
        callback(new Uint8Array(buffer));
      }
    },
  },
});
