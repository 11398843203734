/**
 * 用于检查传入数组是否为空如果不为空则返回数组的第一个元素
 */

export const getFirstItem = <T>(arr: T[], defaultValue: any = null) => {
  if (Array.isArray(arr)) {
    if (arr.length !== 0) {
      return arr[0];
    } else {
      return defaultValue;
    }
  } else {
    // console.log(arr);
    throw Error("getFirstItem function param is not array");
  }
};

export function getBase64(file: Blob): Promise<any> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export function dataURItoBlob(dataURI: string): Blob {
  const byteString = atob(dataURI.split(",")[1]);
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (const i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
}
