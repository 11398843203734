import * as React from "react";
import Layout from "../Layout";
import Content from "./Content";
import { RootState } from "../../store/configureStore";
import { useSelector } from "react-redux";

const IdeaDetalis: React.FC = () => {
  const {
    breadcrumbs = [
      {
        url: "/",
        title: "首页",
      },
      {
        url: "/design-idea-list/index.html",
        title: "设计理念",
      },
      {
        url: "",
        title: "书房和办公",
      },
      {
        url: "",
        title: "321123",
      },
    ],
  } = useSelector((state: RootState) => state.IdeaDetalis);

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Content />
    </Layout>
  );
};

export default IdeaDetalis;
