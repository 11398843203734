import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import Layout from "../Layout";
import Content from "./Content";
import * as styles from "./index.scss";
import { RootState, Dispatch } from "../../store/configureStore";
import queryString from "query-string";
import { CommonProps } from "../../typings/component";

const App: React.FC<CommonProps> = () => {
  const {
    breadcrumbs = [
      {
        url: "/",
        title: "Homepage",
      },
      {
        title: "Top Category",
        url: "/product-list",
      },
      {
        title: "Category",
        url: "/product-list",
      },
      {
        url: "",
        title: "MHOS0284",
      },
    ],
    sku,
  } = useSelector((state: RootState) => state.PDPPage);
  const dispatch = useDispatch<Dispatch>();

  React.useEffect(() => {
    if (process.env.NODE_ENV === "development" && !sku) {
      const { sid, pid } = queryString.parse(window.location.search);

      dispatch.PDPPage.getSkuAsync({
        shop_sku_id: sid,
        shop_product_id: pid,
      });
    }
  }, []);

  return (
    <Layout
      className={styles["ProductDetails-box"]}
      breadcrumbs={breadcrumbs}
      isToBusiness
    >
      <Content />
    </Layout>
  );
};

export default React.memo(App);
