import React, { useState } from "react";
import { RootState, Dispatch } from "../../store/configureStore";
import * as styles from "./index.scss";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import ProductItem from "./components/ProductItem";
import ComputeModule from "./components/ComputeModule";
import Operation from "./components/Operation";
import { useIntl } from "react-intl";
import { Row, Col, Dropdown, Affix, Menu, message, Card, Space } from "antd";
import Icon from "../../components/icon";
import Checkbox from "../../wrappers/checkbox";
import Button, { PrimaryButton } from "../../wrappers/button";
import { useMount, useSetState } from "ahooks";
import { useTheme } from "styled-components";
import { Cart } from "../../models/Cart";

type ShoppingCartContentState = {
  showQuoterModal: boolean;
  prductCheckedList: any[][]; // 二维数组为了兼容批量收藏 [tob_cart_id,sku_id,product_id, shop_id, currency_id]
  productList:
    | (ShoppingCartContentProps["order"]["cart"][0] & {
        isChecked: boolean;
      })[]
    | [];
  confirmModel: boolean; // 删除确认 flag
  selectedProductList: {
    shop_product_id: number;
    tob_cart_id: number;
    quantity: number;
    price: number;
    isRange: number;
    isActive: number;
    priceData: {
      quantity_low: string; // 数量范围
      quantity_high: string; // 数量范围
      price: string; // 对应的价格
    }[];
  }[];
  fixed: boolean; // 判断右侧信息是否fixed
  selectedAddress: {
    recipient_name: string;
    recipient_email: string; // 联系邮箱
    recipient_company: string; // 联系公司
    recipient_telephone: string; // 联系电话
    address_details: string;
  };
  hideUserInfo: boolean;
  hidePriceInfo: boolean;
  hideAgentInfo: boolean;
  userInfo: any;
};

type ShoppingCartContentProps = {
  isMobile?: boolean;
};

const ShoppingCartContent: React.FC<ShoppingCartContentProps> = props => {
  const { bussinessPrimaryColor } = useTheme();
  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch<Dispatch>();
  const { cart, collectIds } = useSelector((root: RootState) => root.ToBCart);
  const { tobAddress: addressData } = useSelector(
    (root: RootState) => root.AddressPage,
  );
  const { AgentRate: agentRates, User: user } = useSelector(
    (root: RootState) => root,
  );

  const { isMobile = false } = props;

  const [state, setState] = useSetState<ShoppingCartContentState>({
    showQuoterModal: false,
    prductCheckedList: [],
    selectedProductList: [],
    confirmModel: false,
    productList: cart
      ? cart.map((item: any) => ({ ...item, isChecked: false }))
      : [],
    fixed: false,
    selectedAddress: {
      recipient_name: "",
      recipient_email: "", // 联系邮箱
      recipient_company: "",
      recipient_telephone: "", // 联系电话
      address_details: "",
    },
    hideUserInfo: false,
    hidePriceInfo: false,
    hideAgentInfo: true,
    userInfo: {},
  });

  useMount(() => {
    // 判断cart对象是否存在
    if (!cart) {
      // 如果不存在那么进行初始化请求
      const initData = {
        callback: (res: any) => {
          if (user.isAgent) {
            const idAry = cart?.map((m: any) => m.shop_id);
            dispatch.AgentRate.getAgentRateAsync({ shopIds: idAry?.join("#") });
          }
        },
      };
      dispatch.ToBCart.getCartAsync(initData);
    }

    dispatch.AddressPage.setTobAddress();

    const data = {
      callback: (res: any) => {
        setState({
          userInfo: res,
        });
      },
    };
    dispatch.AccountPage.getUserInfo(data);
  });

  const handleProductCheck = (datas: any, shopCart: Cart) => {
    if (
      state.selectedProductList.some(
        n => n.shop_product_id === datas.shop_product_id,
      )
    ) {
      const data = state.selectedProductList.filter(
        m => m.shop_product_id !== datas.shop_product_id,
      );
      setState({
        selectedProductList: [...data],
      });
    } else {
      const data = [
        {
          shop_product_id: datas.shop_product_id,
          tob_cart_id: datas.tob_cart_id,
          quantity: datas.quantity,
          price: datas.tag_price,
          isRange: datas.has_tag_price_range,
          priceData: datas.product_price,
          isActive: datas.is_active,
        },
      ];
      setState({
        selectedProductList: [...state.selectedProductList, ...data],
      });
    }
    cart.forEach(item => {
      item.product.forEach(m => {
        if (m.tob_cart_id === datas.tob_cart_id) {
          m.selected = !m.selected;
        }
      });
    });

    cart.forEach(item => {
      let isHave = false;
      item.product.forEach(m => {
        if (m.selected) {
          isHave = true;
        }
        item.selected = isHave;
      });
    });
  };
  const getIsChecked = (productId: any) => {
    return state.selectedProductList.some(
      item => item.shop_product_id === parseInt(productId),
    );
  };

  // 实现select all
  const handleSelectAll = () => {
    const nowSelectNumber = countCartListLength(cart);

    // 判断一下selectedProductList长度为和现有可选商品长度是否相等，是就清空选中，否就选中所有。
    if (state.selectedProductList.length === nowSelectNumber) {
      setState({
        selectedProductList: [],
      });

      cart.forEach((item: any) => {
        item.selected = false;
        item.product.forEach((m: any) => {
          m.selected = false;
        });
      });
    } else {
      const selectedData: any = [];
      cart.map((m: any) => {
        m.product.map((item: any) => {
          if (item.is_active) {
            selectedData.push({
              shop_product_id: item.shop_product_id,
              quantity: item.quantity,
              price: item.tag_price,
              tob_cart_id: item.tob_cart_id,
              isRange: item.has_tag_price_range,
              priceData: item.product_price,
              isActive: item.is_active,
            });
          }
        });
      });
      setState({
        selectedProductList: selectedData,
      });

      cart.forEach(item => {
        item.selected = true;
        item.product.forEach(m => {
          m.selected = true;
        });
      });
    }
  };

  // 批量或者单个删除
  const handeleDelCartItem = (cartId: number, type: boolean) => {
    // 删除商品后需要将state中的checkedList更新
    if (type) {
      dispatch.ToBCart.clearCartAsync(cartId);
    } else {
      const ids: any = [];
      state.selectedProductList.map(item => {
        ids.push(item.tob_cart_id);
      });
      dispatch.ToBCart.clearCartAsync(ids.join("#"));
    }
  };

  // 修改订单数量函数
  const handleChangeQuantity = (
    indexs: number,
    index: number,
    n: number,
    id: number,
    product_moq: number,
    tob_cart_id: number,
  ) => {
    if (n < product_moq) return;
    dispatch.ToBCart.changeQuantity({ indexs, index, number: n });
    handleChangeStateQuantity(n, id);
    dispatch.ToBCart.handleChangeOrderQuantity({
      quantity: n,
      tob_cart_id: tob_cart_id,
    });
  };

  /**
   * 计算 cart list 可以被选中的总数
   */
  const countCartListLength = (
    cart: ShoppingCartContentProps["order"]["cart"],
  ) => {
    let preN = 0;
    cart.map((item: any) => {
      item.product.map((m: any) => {
        if (m.is_active === 0) {
          return (preN = preN);
        } else {
          return (preN = preN + 1);
        }
      });
    });
    return preN;
  };

  /**
   * 获取单个商品收藏状态
   */

  const handleLikeGet = (shop_product_id: number) => {
    if (collectIds && collectIds.length > 0) {
      return collectIds.some(
        (n: any) => parseInt(n.data_id) === shop_product_id,
      );
    }
  };

  /**
   * 获取当前选中的价格
   */

  const handlePriceGet = () => {
    let data = 0;
    state.selectedProductList.map(item => {
      if (item.isRange === 0) {
        data = data + item.price * item.quantity;
      } else {
        const range = item.priceData.filter(
          m =>
            parseFloat(m.quantity_low) < item.quantity &&
            parseFloat(m.quantity_high) > item.quantity,
        );

        if (range.length === 0) {
          data = data + item.quantity * handleLastGet(item.priceData).price;
        } else {
          data = data + item.quantity * parseFloat(range[0].price);
        }
      }
    });
    return data.toFixed(2);
  };

  /**
   * 获取当前选中的数量合
   */

  const handleQuantityGet = () => {
    let data = 0;
    state.selectedProductList.map(item => {
      data = data + item.quantity;
    });
    return data;
  };

  /**
   * 切换商品数量,修改selectedProductList中的数量值
   */

  const handleChangeStateQuantity = (n: number, id: number) => {
    if (state.selectedProductList.some(item => item.shop_product_id === id)) {
      state.selectedProductList.map(item => {
        if (item.shop_product_id === id) {
          item.quantity = n;
        }
      });
    }
  };

  /**
   * 导出
   */

  const handleExportFile = () => {
    const tobCartIds = state.selectedProductList.map(item => {
      return item.isActive === 1 ? item.tob_cart_id : "";
    });
    const showPrice = state.hideUserInfo ? 0 : 1;
    window.open(
      "/tob/cart/export?tob_cart_id=" +
        tobCartIds.join(",") +
        "&show_price=" +
        showPrice,
    );
  };

  /**
   * 获取现在选择的id
   */

  const handleIdsGet = () => {
    let idString = "";
    if (state.selectedProductList.length > 1) {
      state.selectedProductList.map(item => {
        idString = idString + "#" + item.tob_cart_id;
      });
    } else {
      state.selectedProductList.map(item => {
        idString = idString + item.tob_cart_id;
      });
    }
    return idString;
  };

  const handleNumGet = () => {
    let quantityString = "";
    if (state.selectedProductList.length > 1) {
      state.selectedProductList.map(item => {
        quantityString = quantityString + "#" + item.quantity;
      });
    } else {
      state.selectedProductList.map(item => {
        quantityString = quantityString + item.quantity;
      });
    }
    return quantityString;
  };

  /**
   * 存储选择的联系信息打印导出用
   */

  const handleSaveAddress = (data: any) => {
    setState({
      selectedAddress: {
        recipient_company: data.recipient_company,
        recipient_email: data.recipient_email,
        recipient_name: data.recipient_name,
        recipient_telephone: data.recipient_telephone,
        address_details:
          data.province + data.city + data.area + data.address_details,
      },
    });
  };

  /**
   * 获取商品价格区间数组最后一个
   */
  const handleLastGet = (data: any) => {
    return data[data.length - 1];
  };

  return (
    <div className={styles["cart-box"]}>
      <Row gutter={10} style={{ width: "100%", marginTop: "10px" }}>
        <Col span={16}>
          {cart && cart.length !== 0 && (
            <>
              <Row
                gutter={5}
                style={{ width: "100%", marginBottom: "15px" }}
                justify="space-between"
              >
                <Col>
                  <Space size={5}>
                    <Checkbox
                      shape="circle"
                      color={bussinessPrimaryColor}
                      borderWidth="2px"
                      checked={
                        state.selectedProductList.length ===
                        countCartListLength(cart)
                      }
                      onChange={handleSelectAll}
                    >
                      {f({
                        id: "SelectAll",
                      })}
                    </Checkbox>

                    <Button
                      shape="circle"
                      size="small"
                      color={bussinessPrimaryColor}
                      onClick={() => handeleDelCartItem(0, false)}
                      disabled={state.selectedProductList.length === 0}
                      icon={<Icon type="icondelete" />}
                    />
                  </Space>
                </Col>
                <Col>
                  <Space size={5}>
                    <Dropdown
                      overlay={
                        <Menu>
                          <li
                            className={styles["cart-operation-menus"]}
                            key="UserInfo"
                          >
                            <Checkbox
                              shape="circle"
                              color={bussinessPrimaryColor}
                              borderWidth="2px"
                              checked={state.hideUserInfo}
                              onChange={() => {
                                setState({
                                  hideUserInfo: !state.hideUserInfo,
                                });
                              }}
                            >
                              {f({
                                id: "HidingPersonalInfomation",
                              })}
                            </Checkbox>
                          </li>
                          <li
                            className={styles["cart-operation-menus"]}
                            key="PriceInfo"
                          >
                            <Checkbox
                              shape="circle"
                              borderWidth="2px"
                              color={bussinessPrimaryColor}
                              checked={state.hidePriceInfo}
                              onChange={() => {
                                setState({
                                  hidePriceInfo: !state.hidePriceInfo,
                                });
                              }}
                            >
                              {f({
                                id: "HidingPriceInfomation",
                              })}
                            </Checkbox>
                          </li>
                          {user?.isAgent && (
                            <li
                              className={styles["cart-operation-menus"]}
                              key="AgentInfo"
                            >
                              <Checkbox
                                shape="circle"
                                borderWidth="2px"
                                color={bussinessPrimaryColor}
                                checked={state.hideAgentInfo}
                                onChange={() => {
                                  setState({
                                    hideAgentInfo: !state.hideAgentInfo,
                                  });
                                }}
                              >
                                {f({
                                  id: "HidingAgentIncome",
                                })}
                              </Checkbox>
                            </li>
                          )}
                        </Menu>
                      }
                      trigger={["click"]}
                      className="dropdown-left-select-menu"
                    >
                      <span>
                        <Icon
                          type="iconxiajiantouxiangxia-4"
                          style={{
                            color: bussinessPrimaryColor,
                          }}
                        />
                        显示/隐藏信息
                      </span>
                    </Dropdown>

                    <Button
                      shape="circle"
                      size="small"
                      color={bussinessPrimaryColor}
                      onClick={() => handleExportFile()}
                      disabled={state.selectedProductList.length === 0}
                      icon={<Icon type="icondaochu" />}
                    ></Button>
                    <span
                      className={styles["cart-box-header-span"]}
                      onClick={() => {
                        if (state.selectedProductList.length === 0) {
                          return message.error("请选择商品后重试");
                        }

                        handleExportFile();
                      }}
                    >
                      {f({
                        id: "ExportListing",
                      })}
                    </span>

                    <Operation
                      address={state.selectedAddress}
                      addressData={addressData}
                      cartData={cart}
                      isDisabled={state.selectedProductList.length === 0}
                      hidePriceInfo={state.hidePriceInfo}
                      hideUserInfo={state.hideUserInfo}
                      isAgent={user.isAgent}
                      userInfo={state.userInfo}
                      hideAgentIncome={state.hideAgentInfo}
                      agentRates={agentRates}
                    />
                  </Space>
                </Col>
              </Row>
              <Card bordered={false}>
                {cart?.map((items: any, indexs: number) => (
                  <Card
                    key={items.shop_id}
                    style={{ marginTop: "15px" }}
                    title={
                      <Row gutter={10}>
                        <Col span={12} style={{ color: bussinessPrimaryColor }}>
                          <Icon
                            type="icondianpu2"
                            style={{
                              marginRight: "5px",
                            }}
                          ></Icon>
                          {items.shop_name}
                        </Col>
                      </Row>
                    }
                  >
                    {items.product.map((item: any, index: number) => (
                      <ProductItem
                        key={index}
                        agentRates={agentRates}
                        shopId={items.shop_id}
                        productLength={items.product.length || 0}
                        index={index}
                        symbol={item.symbol}
                        isMobile={isMobile}
                        img={item.product_picture}
                        checked={getIsChecked(item.shop_product_id)}
                        number={item.quantity}
                        name={item.product_name}
                        product_option={item.product_option}
                        SKU={item.sku_sn}
                        agentCode={item.agent_code}
                        userLike={handleLikeGet(item.shop_product_id)}
                        url={item.url}
                        isActive={item.is_active}
                        attribute={item.attribute}
                        originalPrice={item.original_price}
                        originalPriceValue={item.original_price_value}
                        tagPrice={item.tag_price}
                        customerRating={item.customer_rating}
                        brandName={item.brand_name}
                        seriesName={item.series_name}
                        productMoq={item.product_moq}
                        quantity={item.quantity}
                        tagPriceLow={item.tag_price_low}
                        tagPriceHigh={item.tag_price_high}
                        hasTagPriceRange={item.has_tag_price_range}
                        onCheck={() => handleProductCheck(item, items)}
                        manufacturer={item.shop_name}
                        onChangeNumber={(n: number) =>
                          handleChangeQuantity(
                            indexs,
                            index,
                            n,
                            item.shop_product_id,
                            item.product_moq,
                            item.tob_cart_id,
                          )
                        }
                        onChangeUserLike={(type: number) =>
                          dispatch.ToBCart.changeCollect(
                            type,
                            item.shop_product_id,
                          )
                        }
                        delCartItem={() =>
                          handeleDelCartItem(item.tob_cart_id, true)
                        }
                        shopUrl={`/tob/${item.shop_url}`}
                      />
                    ))}
                  </Card>
                ))}
              </Card>
            </>
          )}

          {!cart ||
            (cart.length === 0 && (
              <>
                <div className={classNames(styles["cart-no-data"])}>
                  <Icon type="iconkonggouwuche"></Icon>
                  <p>
                    {f({
                      id: "OopsExclamationMarkYouShoppingCartIsEmpty",
                    })}
                  </p>
                </div>
              </>
            ))}
        </Col>
        <Col span={8}>
          <Affix offsetTop={85} className="top-layer-affix">
            <div
              style={{
                padding: "20px",
                backgroundColor: "#EAEAEA",
                borderRadius: 5,
              }}
            >
              <ComputeModule
                selected={state.selectedProductList.length}
                selectedIds={handleIdsGet()}
                address={addressData}
                quantitys={handleNumGet()}
                quantity={handleQuantityGet()}
                changeAddress={(data: any) => handleSaveAddress(data)}
                createOrder={(payload: any) =>
                  dispatch.ToBCart.createOrder(payload)
                }
              />
            </div>
          </Affix>
        </Col>
      </Row>
    </div>
  );
};

export default React.memo(ShoppingCartContent);
