import * as React from "react";
import Image from "../../../components/image";
import * as styles from "./index.scss";
import { useContext } from "react";
import { BusinessContext } from "../../BusinessContext";
import Icon from "../../../components/icon";
import { Product } from "../../../models/Product";
import { Tag } from "antd";
import { useTheme } from "styled-components";

export type CartProductCardProps = {
  item: Product;
  dispInfo?: string;
  productOptions: string[];
  series: string;
  brand: string;
};

function CartProductCard(props: CartProductCardProps) {
  const { item, dispInfo, productOptions = [], series, brand } = props;
  const {
    linkColor,
    secondaryColor,
    textColor,
    bussinessPrimaryColor,
  } = useTheme();

  const { isToBusiness } = useContext(BusinessContext);

  return (
    <div className={`${styles["CartProductCard-box"]} Grid`}>
      {item.product_picture && (
        <div className={styles["CartProductCard-img"]}>
          <Image src={item.product_picture} width={1} height={1} />
        </div>
      )}
      <div style={{ flex: "1 1" }}>
        <div className={`${styles["CartProductCard-prompt"]} Grid -middle`}>
          <span>{dispInfo}</span>
          <Icon
            type="icont1_correct"
            style={{
              color: isToBusiness ? bussinessPrimaryColor : secondaryColor,
            }}
          />
        </div>
        <div
          className={styles["CartProductCard-productName"]}
          style={{ color: textColor }}
        >
          {item.product_name}
        </div>
        <div
          className={styles["CartProductCard-productOption"]}
          style={{ color: linkColor }}
        >
          {productOptions.join(", ")}
        </div>
        <div className={styles["CartProductCard-productSeriesBrand"]}>
          {series && (
            <Tag color={isToBusiness ? bussinessPrimaryColor : secondaryColor}>
              {series}
            </Tag>
          )}
          {brand && (
            <Tag color={isToBusiness ? bussinessPrimaryColor : secondaryColor}>
              {brand}
            </Tag>
          )}
        </div>
      </div>
    </div>
  );
}

export default React.memo<CartProductCardProps>(CartProductCard);
