import { GraphqlField, GraphqlRefField } from "../helpers/decorators";
import BaseModel from "./BaseModel";
import { Product } from "./Product";
import { ColorFilter, RoomFilter, StyleFilter } from "./ProductFilter";
import { Option } from "./Common";

export class Coordinate extends BaseModel {
  @GraphqlField("default", "design", "ideaDetail", "DesignCollect")
  shop_product_id: number;

  @GraphqlField("default", "design", "ideaDetail", "DesignCollect")
  coordinate_x: number;

  @GraphqlField("default", "design", "ideaDetail", "DesignCollect")
  coordinate_y: number;

  @GraphqlField("default", "design", "DesignCollect")
  url: string;
  @GraphqlField("default", "design", "ideaDetail", "DesignCollect")
  coordinate_index: string;

  @GraphqlField("ideaDetail")
  width: string;
  @GraphqlField("ideaDetail")
  product_picture: string;
  @GraphqlField("ideaDetail")
  coordinate_sort: string;
  @GraphqlField("ideaDetail")
  height: string;

  @GraphqlField("ideaDetail")
  coordinate_key: string;

  @GraphqlField("ideaDetail")
  coordinate_id: string;

  @GraphqlField("ideaDetail")
  coordinate_type: number;
}

export class DesignIdea extends BaseModel {
  public shop_product_id: number;

  @GraphqlField(
    "default",
    "design",
    "ideaDetail",
    "DesignCollect",
    "UserLike",
    "CustomerDesignIdea",
  )
  public design_idea_id: number;

  @GraphqlField(
    "default",
    "design",
    "ideaDetail",
    "DesignCollect",
    "CustomerDesignIdea",
  )
  public design_idea_name: string;

  @GraphqlField("default", "design", "DesignCollect", "CustomerDesignIdea")
  public url: string;

  @GraphqlField(
    "default",
    "design",
    "DesignCollect",
    "CustomerDesignIdea",
    "ideaDetail",
  )
  public design_idea_picture: string;
  @GraphqlField("CustomerDesignIdea")
  public edit_url: string;
  @GraphqlField("DesignCollect")
  public shop_id: string;

  @GraphqlField("default", "design", "ideaDetail", "DesignCollect")
  public shop_name: string;

  @GraphqlField("default", "design", "ideaDetail")
  public shop_url: string;

  @GraphqlField("default", "design", "ideaDetail", "DesignCollect")
  public design_idea_description: string;

  @GraphqlField("default", "design", "ideaDetail", "DesignCollect")
  public is_collect: boolean;

  @GraphqlField("design")
  public like_num: string;

  @GraphqlField("ideaDetail")
  public background_picture: string;

  @GraphqlField("ideaDetail")
  public shop_logo: string;

  @GraphqlField("ideaDetail")
  public canvas_data: string;

  @GraphqlField("ideaDetail")
  public canvas_object: string;
  @GraphqlField("ideaDetail", "CustomerDesignIdea")
  public source_type: string;
  @GraphqlField("ideaDetail")
  public house_type: string;
  @GraphqlField("ideaDetail")
  public house_type_bedroom: string;
  @GraphqlField("ideaDetail")
  public house_type_livingroom: string;
  @GraphqlField("ideaDetail")
  public house_type_bathroom: string;
  @GraphqlField("ideaDetail")
  public house_type_kitchen: string;
  @GraphqlField("ideaDetail")
  public house_area: string;
  @GraphqlField("ideaDetail")
  public house_cost: string;
  @GraphqlField("ideaDetail")
  public province: string;

  @GraphqlField("ideaDetail", "DesignIdeaReviews")
  public city: string;
  @GraphqlField("ideaDetail")
  public design_idea_slogan: string;
  @GraphqlField("ideaDetail")
  public house_type_text: string;

  @GraphqlField("ideaDetail")
  public color_text: string;
  @GraphqlField("ideaDetail")
  public style_text: string;
  @GraphqlField("ideaDetail")
  public width: number;
  @GraphqlField("ideaDetail")
  public height: number;
  @GraphqlField("ideaDetail")
  public room_text: string;

  @GraphqlField("ideaDetail")
  public room_list: string;
  @GraphqlField("ideaDetail")
  public style_list: string;
  @GraphqlField("ideaDetail")
  public color_list: string;

  @GraphqlField("DesignIdeaReviews")
  public customer_username: string;
  @GraphqlField("DesignIdeaReviews")
  public review_rating: number;
  @GraphqlField("DesignIdeaReviews")
  public review_comment: string;
  @GraphqlField("DesignIdeaReviews")
  public customer_avatar: string;
  @GraphqlField("DesignIdeaReviews")
  public review_time: string;
  @GraphqlField("DesignIdeaReviews")
  public total: number;

  @GraphqlRefField(Coordinate, "default", "design", "ideaDetail")
  public coordinates: Coordinate[] = [];
  @GraphqlRefField(Product, "ideaDetail")
  public products: Product[] = [];

  @GraphqlField("CustomerDesignIdea", "ideaDetail")
  public design_idea_status: number;
  @GraphqlField("DesignIdeaRemark")
  public remark: number;

  @GraphqlField("design", "ideaDetail", "DesignCollect")
  public designer_name: string;
  @GraphqlField("design", "ideaDetail", "DesignCollect")
  public designer_avatar: string;
  @GraphqlField("ideaDetail")
  public agent_code: string;

  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}

export class DesignIdeaList extends BaseModel {
  public page: number;
  @GraphqlField("default", "design", "CustomerDesignIdea")
  public total: number;

  @GraphqlRefField(DesignIdea, "default", "design", "CustomerDesignIdea")
  public list: DesignIdea[] = [];

  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}

export class DesignIdeaPicture extends BaseModel {
  @GraphqlField("PictureTag", "Picture", "SystemPictureTag", "SystemPicture")
  public picture_tag_id: number;
  @GraphqlField("PictureTag", "SystemPictureTag")
  public picture_tag_name: string;
  @GraphqlField("PictureTag")
  public total: string;
  @GraphqlField("Picture", "SystemPicture")
  public picture_id: string;
  @GraphqlField("Picture", "SystemPicture")
  public picture_url: string;
  @GraphqlField("SystemPictureTag")
  public parent_tag_id: string;

  @GraphqlField("SystemPictureTag")
  public level: string;
  @GraphqlField("SystemPictureTag")
  public tag_type: string;
}

export class DesignIdeaData extends BaseModel {
  @GraphqlRefField(Option)
  public house_type_list: Option[] = [];
  @GraphqlRefField(Option)
  public color_lists: Option[] = [];
  @GraphqlRefField(Option)
  public room_lists: Option[] = [];
  @GraphqlRefField(Option)
  public style_lists: Option[] = [];
}

export class HouseTypeFilter {
  @GraphqlField()
  public house_type: number;
  @GraphqlField()
  public house_type_name: string;
}

export class LocationFilter {
  @GraphqlField()
  public province: string;

  @GraphqlField()
  public city: string;
}

export class CostFilter {
  @GraphqlField()
  public cost_low: number;
  @GraphqlField()
  public cost_high: number;

  @GraphqlField()
  public cost_name: string;
}

export class AreaFilter {
  @GraphqlField()
  public area_low: number;
  @GraphqlField()
  public area_high: number;

  @GraphqlField()
  public area_name: string;
}

export class StatusFilter {
  @GraphqlField()
  public value: number;
  @GraphqlField()
  public text: string;
}

export class IdeaFilter extends BaseModel {
  @GraphqlRefField(LocationFilter, "design")
  public Location: LocationFilter[] = [];
  @GraphqlRefField(ColorFilter, "design")
  public Color: ColorFilter[] = [];

  @GraphqlRefField(CostFilter, "design")
  public HouseCost: CostFilter[] = [];

  @GraphqlRefField(StyleFilter, "design")
  public Style: StyleFilter[] = [];

  @GraphqlRefField(RoomFilter, "design")
  public Room: RoomFilter[] = [];

  @GraphqlRefField(AreaFilter, "design")
  public HouseArea: AreaFilter[] = [];
  @GraphqlRefField(HouseTypeFilter, "design")
  public HouseType: HouseTypeFilter[] = [];
  @GraphqlRefField(StatusFilter, "design")
  public DesignIdeaStatus: StatusFilter[] = [];
}
