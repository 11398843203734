import React from "react";
import styled from "styled-components";
import { Steps } from "antd";

const StepsStyled = styled(Steps)<{
  color?: string;
}>`
  & .ant-steps-item-finish {
    .ant-steps-item-tail:after {
      background-color: ${props => props.color ?? props.theme.primaryColor};
    }
    .ant-steps-icon {
      .ant-steps-icon-dot {
        background: ${props => props.color ?? props.theme.primaryColor};
      }
    }
  }
`;

export default StepsStyled;
