import { createModel } from "@rematch/core";
import { Order, OrderTotal } from "../../models/Order";
import { GraphqlQuery, GraphqlMutation } from "../../helpers/request.helper";

export interface OrderHistoryType {
  breadcrumbs: { url: string; title: string }[];
  orderHistory: Order[];

  orderCount: number;
}

export default createModel({
  state: {} as OrderHistoryType,
  reducers: {
    INIT_DATA: (state: OrderHistoryType, orderHistory, orderCount) => ({
      ...state,
      orderHistory,
      orderCount,
    }),
    DELETE_ORDER: (state: OrderHistoryType, orderId: string) => ({
      ...state,
      orderHistory: state.orderHistory.filter(
        item => item.order_id !== orderId,
      ),
    }),
  },
  effects: {
    async setOrderData(payload, root: any) {
      const { page = 1, startTime, stopTime, orderNumber, ...rest } = payload;

      const args = {
        private: root.CommonData.isPrivate,
        shop_id: root.URLSearch.host_id,
        start_time: startTime,
        end_time: stopTime,
        order_number: orderNumber,
        ...rest,
      };

      const data = new Order({
        page: page,
        ...args,
      });
      const datas = new OrderTotal(args);
      const res = await GraphqlQuery("Orders", data, {}, "order");
      const total = await GraphqlQuery("OrderTotal", datas);

      this.INIT_DATA(res, payload.orderNumber ? 1 : total[0].total);
    },
    async handleCancelOrder(payload) {
      const { callback, order_id } = payload;
      const res = await GraphqlMutation("Order", {
        action: "cancel",
        order_id: order_id,
      });
      callback && callback(res);
    },
    async handleConfirmGoods(payload) {
      const { callback, order_id, order_product_id } = payload;
      const res = await GraphqlMutation("Order", {
        action: "confirm",
        order_id: order_id,
        order_product_id: order_product_id,
      });
      callback && callback(res);
    },
    async handleAddToCart(payload, root: any) {
      const {
        callback,
        shopProductId,
        quantity,
        provider_id,
        agent_code,
      } = payload;

      const extraParams = {
        private: root.CommonData.isPrivate,
        shop_id: root.URLSearch.host_id,
      };

      const res = await GraphqlMutation("Cart", {
        action: "add",
        shop_product_id: shopProductId,
        quantity: quantity,
        provider_id: provider_id,
        agent_code: agent_code,
        ...extraParams,
      });
      callback && callback(res);
    },
  },
});
