import { message as Message } from "antd";

export function request<T>(
  origin = "http://localhost:8080",
  methodPath: string,
  ...params: any[]
): Promise<T> {
  const stamp = Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, "");

  window.parent.postMessage({ methodPath, params, stamp }, origin);

  return new Promise((resolve, reject) => {
    const receiveMessage = (event: MessageEvent) => {
      if (event.origin !== origin) return;

      const { data, stamp: pkStamp } = event.data;

      if (pkStamp !== stamp) return;

      if (!data.status_code) {
        resolve(data);
      } else if (data.status_code === 200) {
        resolve(data.data);
      } else {
        Message.error(data.message);
        reject(data.message);
      }

      window.removeEventListener("message", receiveMessage);
    };

    window.addEventListener("message", receiveMessage, false);
  });
}
