export default class Model {
  [key: string]: any;
}

export class ActionResult {
  public status_code: number = 0;
  public message: string = "";

  public data: string = "";

  public file?: string;
}
