import { GraphqlField, GraphqlRefField } from "../helpers/decorators";
import BaseModel from "./BaseModel";
import { Product } from "./Product";
import Address from "./Address";

export class Cart extends BaseModel {
  @GraphqlField("tobCart", "cart", "ShareCart")
  public shop_id: number;
  @GraphqlField("tobCart", "cart", "ShareCart")
  public shop_name: string;
  @GraphqlField("ShareCart", "cart")
  public shop_url: string;
  @GraphqlField("ShareCart")
  public show_agent_income: boolean;
  @GraphqlField("ShareCart")
  public share_customer_agent_code: boolean;
  @GraphqlRefField(Address, "ShareCart")
  public address: Address[] = [];
  @GraphqlField("ShareCart")
  public selected: boolean;
  @GraphqlField("tobCart", "cart")
  public agent_code: string;

  @GraphqlRefField(Product, "tobCart", "cart", "ShareCart")
  public product: Product[] = [];

  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}

export class ShareCart extends BaseModel {
  public token: string;
  @GraphqlField("ShareCart")
  public account_name: string;
  @GraphqlRefField(Cart, "ShareCart")
  public share_data: Cart[] = [];

  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}
