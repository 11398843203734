import { createModel } from "@rematch/core";
import {
  GraphqlQueryMulti,
  GraphqlQuery,
  GraphqlMutation,
} from "../../helpers/request.helper";
import { getFirstItem } from "../../helpers/modelHelpers";
import produce from "immer";
import Withdrawal from "../../models/Withdrawal";
import { message } from "antd";
import { ListTotal } from "../../models/Common";

export interface WithdrawalType {
  breadcrumbs: { url: string; title: string }[];
  statistics: Withdrawal;
  accounts: Withdrawal;
  withdrawalLog: Withdrawal;
  withdrawalLogTotal: number;
  resultData: Withdrawal;
}

export default createModel({
  state: {} as WithdrawalType,
  reducers: {
    initStatistics: (state: WithdrawalType, statistics: Withdrawal) => ({
      ...state,
      statistics,
    }),
    setAccounts: (state: WithdrawalType, accounts: Withdrawal) => ({
      ...state,
      accounts,
    }),
    setWithdrawalLog: (
      state: WithdrawalType,
      withdrawalLog: any,
      withdrawalLogTotal: number,
    ) => ({
      ...state,
      withdrawalLog,
      withdrawalLogTotal,
    }),
    setApplyResult: (state: WithdrawalType, resultData: Withdrawal) => ({
      ...state,
      resultData,
    }),
  },
  effects: {
    async getInitAsync() {
      const res = await GraphqlQueryMulti({
        method: "WithdrawStatistics",
        model: new Withdrawal({}),
        args: {},
        metadataKey: "WithdrawStatistics",
      });
      // { method: "Product", model: new ProductDetail({ shop_product_id }) },
      this.initStatistics(getFirstItem(res.WithdrawStatistics));
    },
    async getAgentAccountAsync() {
      const res = await GraphqlQuery(
        "AgentAccount",
        new Withdrawal({}),
        { action: "accounts" },
        "AgentAccount",
      );
      this.setAccounts(res);
    },
    async changePaymentMethod(payload) {
      const { callback, ...erg } = payload;
      const res = await GraphqlMutation("saveAccount", { ...erg });
      message.success(res.message);
      callback && callback();
    },
    async getAgentAccountInfo(payload) {
      const { agent_account_id, callback } = payload;
      const res = await GraphqlQuery(
        "AgentAccount",
        new Withdrawal({}),
        { action: "show", agent_account_id },
        "AgentAccountShow",
      );
      callback && callback(getFirstItem(res));
    },
    async getWithdrawalLog(payload) {
      const { page = 1 } = payload;

      const res = await GraphqlQueryMulti(
        {
          method: "Withdraws",
          model: new Withdrawal({}),
          args: { action: "withdraws", page: page },
          metadataKey: "WithdrawsLog",
        },
        {
          method: "WithdrawTotal",
          model: new ListTotal(),
        },
      );

      this.setWithdrawalLog(
        res.Withdraws,
        getFirstItem(res.WithdrawTotal).total,
      );
    },
    async getGetRes(payload) {
      const { agent_withdraw_id, callback } = payload;
      const res = await GraphqlQuery(
        "Withdraws",
        new Withdrawal({}),
        { action: "result", agent_withdraw_id },
        "WithdrawsResult",
      );
      callback && callback();
      this.setApplyResult(getFirstItem(res));
    },
    async withdrawalSumbit(payload) {
      const { callback, ...erg } = payload;
      try {
        const res = await GraphqlMutation("ApplyWithdraw", { ...erg });
        message.success(res.message);
        callback && callback(res.data);
      } catch (err) {
        callback && callback(false);
      }
    },
  },
});
