import React from "react";
import styled from "styled-components";
import { Carousel } from "antd";
import { DefaultPrevArrow, DefaultNextArrow } from "./Arrow";
import { SizeType } from "antd/lib/config-provider/SizeContext";

const CarouselStyled = styled(Carousel).attrs(props =>
  props.arrows
    ? {
        prevArrow: (
          <DefaultPrevArrow
            size={props.arrowSize}
            bgColor={props.arrowBgColor ?? "#c6bdb9"}
          />
        ),
        nextArrow: (
          <DefaultNextArrow
            size={props.arrowSize}
            bgColor={props.arrowBgColor ?? "#c6bdb9"}
          />
        ),
      }
    : {},
)<{ arrowSize?: SizeType; arrowBgColor?: string; hoverable?: boolean }>`
  .slick-arrow {
    z-index: 1;
    opacity: 0.5;
    transform: translateY(-50%);
    width: auto;
    height: auto;

    &:hover {
      opacity: 1;
    }
  }

  .slick-prev {
    left: 0;
    display: ${props => (props.hoverable ? "none" : "")};
  }

  .slick-next {
    right: 0;
    display: ${props => (props.hoverable ? "none" : "")};
  }

  &:hover {
    .slick-next,
    .slick-prev {
      display: block;
    }
  }
`;

export default CarouselStyled;
