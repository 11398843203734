import { GraphqlField } from "../helpers/decorators";
import BaseModel from "./BaseModel";

export default class Withdrawal extends BaseModel {
  @GraphqlField("WithdrawStatistics")
  public platform_amount: string;
  @GraphqlField("WithdrawStatistics")
  public brand_amount: string;
  @GraphqlField("WithdrawStatistics")
  public reward_amount: string;
  @GraphqlField("WithdrawStatistics")
  public total: string;
  @GraphqlField("WithdrawStatistics")
  public platform_amount_text: string;
  @GraphqlField("WithdrawStatistics")
  public brand_amount_text: string;
  @GraphqlField("WithdrawStatistics")
  public reward_amount_text: string;
  @GraphqlField("WithdrawStatistics")
  public total_text: string;
  @GraphqlField("WithdrawStatistics")
  public min_withdraw: string;
  @GraphqlField("WithdrawStatistics")
  public fee_rate: number;

  @GraphqlField("AgentAccount", "AgentAccountShow")
  public agent_account_id: number;
  @GraphqlField("AgentAccount", "AgentAccountShow", "WithdrawsResult")
  public account_type: number;
  @GraphqlField("AgentAccount")
  public account_type_text: string;
  @GraphqlField("AgentAccount", "AgentAccountShow")
  public account_number: number;
  @GraphqlField("AgentAccount", "AgentAccountShow")
  public account_bank: string;
  @GraphqlField("AgentAccount", "AgentAccountShow")
  public account_bank_code: string;
  @GraphqlField("AgentAccountShow")
  public bank_of_deposit: string;
  @GraphqlField("AgentAccountShow")
  public account_username: string;

  @GraphqlField("WithdrawsLog", "WithdrawsResult")
  public agent_withdraw_id: number;
  @GraphqlField("WithdrawsLog", "WithdrawsResult")
  public withdraw_status: number;
  @GraphqlField("WithdrawsLog", "WithdrawsResult")
  public created_at: string;
  @GraphqlField("WithdrawsLog", "WithdrawsResult")
  public transfer_time: string;
  @GraphqlField("WithdrawsLog", "WithdrawsResult")
  public withdraw_amount: string;
  @GraphqlField("WithdrawsLog", "WithdrawsResult")
  public withdraw_amount_text: string;
  @GraphqlField("WithdrawsLog", "WithdrawsResult")
  public withdraw_status_text: string;
  @GraphqlField("WithdrawsResult")
  public withdraw_method: string;

  @GraphqlField("WithdrawsResult")
  public withdraw_account: string;
  @GraphqlField("WithdrawsResult")
  public withdraw_fee: string;
  @GraphqlField("WithdrawsResult")
  public withdraw_fee_text: string;
  @GraphqlField("WithdrawsResult")
  public arrival_amount: string;
  @GraphqlField("WithdrawsResult")
  public arrival_amount_text: string;
  @GraphqlField("WithdrawsResult")
  public remark: string;

  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}
