import { GraphqlField } from "../helpers/decorators";
import BaseModel from "./BaseModel";

export enum AuditStatus {
  Auditing = 2,
  Reedit,
  Agree,
}

export default class Agent extends BaseModel {
  public action: string;
  @GraphqlField("confirm")
  public application_id: number;

  @GraphqlField("agent", "AgentShop")
  public agent_id: number;

  @GraphqlField("applyResult")
  public agent_authentication_id: number;
  @GraphqlField("applyResult", "agent", "confirm")
  public agent_name: string;
  @GraphqlField("applyResult", "agent", "confirm")
  public agent_code: string;
  @GraphqlField("applyResult")
  public referrer_agent_code: string;
  // @GraphqlField("applyResult", "agent")
  // public identity_card_back: string;
  // @GraphqlField("applyResult", "agent")
  // public identity_card_front: string;
  @GraphqlField("applyResult", "agent", "confirm")
  public identity_card_number: string;
  @GraphqlField("applyResult", "agent")
  // public identity_card_handheld: string;

  // @GraphqlField("applyResult", "agent")
  public is_designer: number;
  @GraphqlField("applyResult", "agent")
  public designer_case: string;

  @GraphqlField("applyResult")
  public audit_status: AuditStatus;

  @GraphqlField("applyResult")
  public audit_status_text: string;
  @GraphqlField("applyResult", "agent", "confirm")
  public telephone: string;

  @GraphqlField("applyResult")
  public remark: string;
  @GraphqlField("agent")
  public account_type: string;
  @GraphqlField("agent")
  public account_bank: string;
  @GraphqlField("agent")
  public account_number: string;

  @GraphqlField("confirm")
  public root_name: string;
  @GraphqlField("confirm")
  public root_address: string;
  @GraphqlField("confirm")
  public contact_email: string;
  @GraphqlField("confirm")
  public platform_phone: string;
  @GraphqlField("confirm")
  public contact_phone: string;
  @GraphqlField("AgentShop")
  public shop_agent_id: string;
  @GraphqlField("AgentShop")
  public shop_name: string;
  @GraphqlField("AgentShop")
  public shop_slogan: string;
  @GraphqlField("AgentShop")
  public shop_id: string;
  @GraphqlField("AgentShop")
  public shop_url: string;

  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}
