const addressDatas = [
  {
    id: 1,
    pid: 0,
    name: "\u9ed1\u9f99\u6c5f\u7701",
    code: "230000",
    level: "province",
  },
  {
    id: 2,
    pid: 1,
    name: "\u4e03\u53f0\u6cb3\u5e02",
    code: "230900",
    level: "city",
  },
  {
    id: 3,
    pid: 2,
    name: "\u6843\u5c71\u533a",
    code: "230903",
    level: "district",
  },
  {
    id: 4,
    pid: 2,
    name: "\u52c3\u5229\u53bf",
    code: "230921",
    level: "district",
  },
  {
    id: 5,
    pid: 2,
    name: "\u65b0\u5174\u533a",
    code: "230902",
    level: "district",
  },
  {
    id: 6,
    pid: 2,
    name: "\u8304\u5b50\u6cb3\u533a",
    code: "230904",
    level: "district",
  },
  { id: 7, pid: 1, name: "\u9e64\u5c97\u5e02", code: "230400", level: "city" },
  {
    id: 8,
    pid: 7,
    name: "\u7ee5\u6ee8\u53bf",
    code: "230422",
    level: "district",
  },
  {
    id: 9,
    pid: 7,
    name: "\u5de5\u519c\u533a",
    code: "230403",
    level: "district",
  },
  {
    id: 10,
    pid: 7,
    name: "\u5174\u5c71\u533a",
    code: "230407",
    level: "district",
  },
  {
    id: 11,
    pid: 7,
    name: "\u5411\u9633\u533a",
    code: "230402",
    level: "district",
  },
  {
    id: 12,
    pid: 7,
    name: "\u5357\u5c71\u533a",
    code: "230404",
    level: "district",
  },
  {
    id: 13,
    pid: 7,
    name: "\u841d\u5317\u53bf",
    code: "230421",
    level: "district",
  },
  {
    id: 14,
    pid: 7,
    name: "\u5174\u5b89\u533a",
    code: "230405",
    level: "district",
  },
  {
    id: 15,
    pid: 7,
    name: "\u4e1c\u5c71\u533a",
    code: "230406",
    level: "district",
  },
  { id: 16, pid: 1, name: "\u4f0a\u6625\u5e02", code: "230700", level: "city" },
  {
    id: 17,
    pid: 16,
    name: "\u5609\u836b\u53bf",
    code: "230722",
    level: "district",
  },
  {
    id: 18,
    pid: 16,
    name: "\u5357\u5c94\u53bf",
    code: "230726",
    level: "district",
  },
  {
    id: 19,
    pid: 16,
    name: "\u4e4c\u7fe0\u533a",
    code: "230718",
    level: "district",
  },
  {
    id: 20,
    pid: 16,
    name: "\u4f0a\u7f8e\u533a",
    code: "230717",
    level: "district",
  },
  {
    id: 21,
    pid: 16,
    name: "\u53cb\u597d\u533a",
    code: "230719",
    level: "district",
  },
  {
    id: 22,
    pid: 16,
    name: "\u91d1\u6797\u533a",
    code: "230751",
    level: "district",
  },
  {
    id: 23,
    pid: 16,
    name: "\u6c64\u65fa\u53bf",
    code: "230723",
    level: "district",
  },
  {
    id: 24,
    pid: 16,
    name: "\u4e30\u6797\u53bf",
    code: "230724",
    level: "district",
  },
  {
    id: 25,
    pid: 16,
    name: "\u94c1\u529b\u5e02",
    code: "230781",
    level: "district",
  },
  {
    id: 26,
    pid: 16,
    name: "\u5927\u7b90\u5c71\u53bf",
    code: "230725",
    level: "district",
  },
  {
    id: 27,
    pid: 1,
    name: "\u5927\u5174\u5b89\u5cad\u5730\u533a",
    code: "232700",
    level: "city",
  },
  {
    id: 28,
    pid: 27,
    name: "\u6f20\u6cb3\u5e02",
    code: "232701",
    level: "district",
  },
  {
    id: 29,
    pid: 27,
    name: "\u5854\u6cb3\u53bf",
    code: "232722",
    level: "district",
  },
  {
    id: 30,
    pid: 27,
    name: "\u547c\u739b\u53bf",
    code: "232721",
    level: "district",
  },
  {
    id: 31,
    pid: 27,
    name: "\u52a0\u683c\u8fbe\u5947\u533a",
    code: "232718",
    level: "district",
  },
  { id: 32, pid: 1, name: "\u7ee5\u5316\u5e02", code: "231200", level: "city" },
  {
    id: 33,
    pid: 32,
    name: "\u7ee5\u68f1\u53bf",
    code: "231226",
    level: "district",
  },
  {
    id: 34,
    pid: 32,
    name: "\u6d77\u4f26\u5e02",
    code: "231283",
    level: "district",
  },
  {
    id: 35,
    pid: 32,
    name: "\u5e86\u5b89\u53bf",
    code: "231224",
    level: "district",
  },
  {
    id: 36,
    pid: 32,
    name: "\u671b\u594e\u53bf",
    code: "231221",
    level: "district",
  },
  {
    id: 37,
    pid: 32,
    name: "\u5317\u6797\u533a",
    code: "231202",
    level: "district",
  },
  {
    id: 38,
    pid: 32,
    name: "\u9752\u5188\u53bf",
    code: "231223",
    level: "district",
  },
  {
    id: 39,
    pid: 32,
    name: "\u5170\u897f\u53bf",
    code: "231222",
    level: "district",
  },
  {
    id: 40,
    pid: 32,
    name: "\u660e\u6c34\u53bf",
    code: "231225",
    level: "district",
  },
  {
    id: 41,
    pid: 32,
    name: "\u8087\u4e1c\u5e02",
    code: "231282",
    level: "district",
  },
  {
    id: 42,
    pid: 32,
    name: "\u5b89\u8fbe\u5e02",
    code: "231281",
    level: "district",
  },
  {
    id: 43,
    pid: 1,
    name: "\u54c8\u5c14\u6ee8\u5e02",
    code: "230100",
    level: "city",
  },
  {
    id: 44,
    pid: 43,
    name: "\u5df4\u5f66\u53bf",
    code: "230126",
    level: "district",
  },
  {
    id: 45,
    pid: 43,
    name: "\u4f9d\u5170\u53bf",
    code: "230123",
    level: "district",
  },
  {
    id: 46,
    pid: 43,
    name: "\u901a\u6cb3\u53bf",
    code: "230128",
    level: "district",
  },
  {
    id: 47,
    pid: 43,
    name: "\u6728\u5170\u53bf",
    code: "230127",
    level: "district",
  },
  { id: 48, pid: 43, name: "\u5bbe\u53bf", code: "230125", level: "district" },
  {
    id: 49,
    pid: 43,
    name: "\u65b9\u6b63\u53bf",
    code: "230124",
    level: "district",
  },
  {
    id: 50,
    pid: 43,
    name: "\u5e73\u623f\u533a",
    code: "230108",
    level: "district",
  },
  {
    id: 51,
    pid: 43,
    name: "\u5ef6\u5bff\u53bf",
    code: "230129",
    level: "district",
  },
  {
    id: 52,
    pid: 43,
    name: "\u5c1a\u5fd7\u5e02",
    code: "230183",
    level: "district",
  },
  {
    id: 53,
    pid: 43,
    name: "\u53cc\u57ce\u533a",
    code: "230113",
    level: "district",
  },
  {
    id: 54,
    pid: 43,
    name: "\u9999\u574a\u533a",
    code: "230110",
    level: "district",
  },
  {
    id: 55,
    pid: 43,
    name: "\u9053\u91cc\u533a",
    code: "230102",
    level: "district",
  },
  {
    id: 56,
    pid: 43,
    name: "\u4e94\u5e38\u5e02",
    code: "230184",
    level: "district",
  },
  {
    id: 57,
    pid: 43,
    name: "\u963f\u57ce\u533a",
    code: "230112",
    level: "district",
  },
  {
    id: 58,
    pid: 43,
    name: "\u547c\u5170\u533a",
    code: "230111",
    level: "district",
  },
  {
    id: 59,
    pid: 43,
    name: "\u677e\u5317\u533a",
    code: "230109",
    level: "district",
  },
  {
    id: 60,
    pid: 43,
    name: "\u5357\u5c97\u533a",
    code: "230103",
    level: "district",
  },
  {
    id: 61,
    pid: 43,
    name: "\u9053\u5916\u533a",
    code: "230104",
    level: "district",
  },
  {
    id: 62,
    pid: 1,
    name: "\u9f50\u9f50\u54c8\u5c14\u5e02",
    code: "230200",
    level: "city",
  },
  {
    id: 63,
    pid: 62,
    name: "\u8bb7\u6cb3\u5e02",
    code: "230281",
    level: "district",
  },
  {
    id: 64,
    pid: 62,
    name: "\u7518\u5357\u53bf",
    code: "230225",
    level: "district",
  },
  {
    id: 65,
    pid: 62,
    name: "\u514b\u5c71\u53bf",
    code: "230229",
    level: "district",
  },
  {
    id: 66,
    pid: 62,
    name: "\u514b\u4e1c\u53bf",
    code: "230230",
    level: "district",
  },
  {
    id: 67,
    pid: 62,
    name: "\u4f9d\u5b89\u53bf",
    code: "230223",
    level: "district",
  },
  {
    id: 68,
    pid: 62,
    name: "\u5bcc\u88d5\u53bf",
    code: "230227",
    level: "district",
  },
  {
    id: 69,
    pid: 62,
    name: "\u78be\u5b50\u5c71\u533a",
    code: "230207",
    level: "district",
  },
  {
    id: 70,
    pid: 62,
    name: "\u94c1\u950b\u533a",
    code: "230204",
    level: "district",
  },
  {
    id: 71,
    pid: 62,
    name: "\u5efa\u534e\u533a",
    code: "230203",
    level: "district",
  },
  {
    id: 72,
    pid: 62,
    name: "\u6885\u91cc\u65af\u8fbe\u65a1\u5c14\u65cf\u533a",
    code: "230208",
    level: "district",
  },
  {
    id: 73,
    pid: 62,
    name: "\u9f99\u6c5f\u53bf",
    code: "230221",
    level: "district",
  },
  {
    id: 74,
    pid: 62,
    name: "\u9f99\u6c99\u533a",
    code: "230202",
    level: "district",
  },
  {
    id: 75,
    pid: 62,
    name: "\u5bcc\u62c9\u5c14\u57fa\u533a",
    code: "230206",
    level: "district",
  },
  {
    id: 76,
    pid: 62,
    name: "\u6602\u6602\u6eaa\u533a",
    code: "230205",
    level: "district",
  },
  {
    id: 77,
    pid: 62,
    name: "\u62dc\u6cc9\u53bf",
    code: "230231",
    level: "district",
  },
  {
    id: 78,
    pid: 62,
    name: "\u6cf0\u6765\u53bf",
    code: "230224",
    level: "district",
  },
  { id: 79, pid: 1, name: "\u9ed1\u6cb3\u5e02", code: "231100", level: "city" },
  {
    id: 80,
    pid: 79,
    name: "\u5ae9\u6c5f\u5e02",
    code: "231183",
    level: "district",
  },
  {
    id: 81,
    pid: 79,
    name: "\u7231\u8f89\u533a",
    code: "231102",
    level: "district",
  },
  {
    id: 82,
    pid: 79,
    name: "\u5317\u5b89\u5e02",
    code: "231181",
    level: "district",
  },
  {
    id: 83,
    pid: 79,
    name: "\u5b59\u5434\u53bf",
    code: "231124",
    level: "district",
  },
  {
    id: 84,
    pid: 79,
    name: "\u900a\u514b\u53bf",
    code: "231123",
    level: "district",
  },
  {
    id: 85,
    pid: 79,
    name: "\u4e94\u5927\u8fde\u6c60\u5e02",
    code: "231182",
    level: "district",
  },
  {
    id: 86,
    pid: 1,
    name: "\u7261\u4e39\u6c5f\u5e02",
    code: "231000",
    level: "city",
  },
  {
    id: 87,
    pid: 86,
    name: "\u7231\u6c11\u533a",
    code: "231004",
    level: "district",
  },
  {
    id: 88,
    pid: 86,
    name: "\u7ee5\u82ac\u6cb3\u5e02",
    code: "231081",
    level: "district",
  },
  {
    id: 89,
    pid: 86,
    name: "\u4e1c\u5b89\u533a",
    code: "231002",
    level: "district",
  },
  {
    id: 90,
    pid: 86,
    name: "\u5b81\u5b89\u5e02",
    code: "231084",
    level: "district",
  },
  {
    id: 91,
    pid: 86,
    name: "\u4e1c\u5b81\u5e02",
    code: "231086",
    level: "district",
  },
  {
    id: 92,
    pid: 86,
    name: "\u9633\u660e\u533a",
    code: "231003",
    level: "district",
  },
  {
    id: 93,
    pid: 86,
    name: "\u6797\u53e3\u53bf",
    code: "231025",
    level: "district",
  },
  {
    id: 94,
    pid: 86,
    name: "\u7a46\u68f1\u5e02",
    code: "231085",
    level: "district",
  },
  {
    id: 95,
    pid: 86,
    name: "\u897f\u5b89\u533a",
    code: "231005",
    level: "district",
  },
  {
    id: 96,
    pid: 86,
    name: "\u6d77\u6797\u5e02",
    code: "231083",
    level: "district",
  },
  { id: 97, pid: 1, name: "\u9e21\u897f\u5e02", code: "230300", level: "city" },
  {
    id: 98,
    pid: 97,
    name: "\u57ce\u5b50\u6cb3\u533a",
    code: "230306",
    level: "district",
  },
  {
    id: 99,
    pid: 97,
    name: "\u6ef4\u9053\u533a",
    code: "230304",
    level: "district",
  },
  {
    id: 100,
    pid: 97,
    name: "\u68a8\u6811\u533a",
    code: "230305",
    level: "district",
  },
  {
    id: 101,
    pid: 97,
    name: "\u6052\u5c71\u533a",
    code: "230303",
    level: "district",
  },
  {
    id: 102,
    pid: 97,
    name: "\u9e21\u51a0\u533a",
    code: "230302",
    level: "district",
  },
  {
    id: 103,
    pid: 97,
    name: "\u9ebb\u5c71\u533a",
    code: "230307",
    level: "district",
  },
  {
    id: 104,
    pid: 97,
    name: "\u5bc6\u5c71\u5e02",
    code: "230382",
    level: "district",
  },
  {
    id: 105,
    pid: 97,
    name: "\u9e21\u4e1c\u53bf",
    code: "230321",
    level: "district",
  },
  {
    id: 106,
    pid: 97,
    name: "\u864e\u6797\u5e02",
    code: "230381",
    level: "district",
  },
  {
    id: 107,
    pid: 1,
    name: "\u4f73\u6728\u65af\u5e02",
    code: "230800",
    level: "city",
  },
  {
    id: 108,
    pid: 107,
    name: "\u540c\u6c5f\u5e02",
    code: "230881",
    level: "district",
  },
  {
    id: 109,
    pid: 107,
    name: "\u6866\u5ddd\u53bf",
    code: "230826",
    level: "district",
  },
  {
    id: 110,
    pid: 107,
    name: "\u6c64\u539f\u53bf",
    code: "230828",
    level: "district",
  },
  {
    id: 111,
    pid: 107,
    name: "\u4e1c\u98ce\u533a",
    code: "230805",
    level: "district",
  },
  {
    id: 112,
    pid: 107,
    name: "\u5411\u9633\u533a",
    code: "230803",
    level: "district",
  },
  {
    id: 113,
    pid: 107,
    name: "\u524d\u8fdb\u533a",
    code: "230804",
    level: "district",
  },
  {
    id: 114,
    pid: 107,
    name: "\u90ca\u533a",
    code: "230811",
    level: "district",
  },
  {
    id: 115,
    pid: 107,
    name: "\u6866\u5357\u53bf",
    code: "230822",
    level: "district",
  },
  {
    id: 116,
    pid: 107,
    name: "\u629a\u8fdc\u5e02",
    code: "230883",
    level: "district",
  },
  {
    id: 117,
    pid: 107,
    name: "\u5bcc\u9526\u5e02",
    code: "230882",
    level: "district",
  },
  {
    id: 118,
    pid: 1,
    name: "\u5927\u5e86\u5e02",
    code: "230600",
    level: "city",
  },
  {
    id: 119,
    pid: 118,
    name: "\u8ba9\u80e1\u8def\u533a",
    code: "230604",
    level: "district",
  },
  {
    id: 120,
    pid: 118,
    name: "\u7ea2\u5c97\u533a",
    code: "230605",
    level: "district",
  },
  {
    id: 121,
    pid: 118,
    name: "\u8087\u6e90\u53bf",
    code: "230622",
    level: "district",
  },
  {
    id: 122,
    pid: 118,
    name: "\u5927\u540c\u533a",
    code: "230606",
    level: "district",
  },
  {
    id: 123,
    pid: 118,
    name: "\u6797\u7538\u53bf",
    code: "230623",
    level: "district",
  },
  {
    id: 124,
    pid: 118,
    name: "\u675c\u5c14\u4f2f\u7279\u8499\u53e4\u65cf\u81ea\u6cbb\u53bf",
    code: "230624",
    level: "district",
  },
  {
    id: 125,
    pid: 118,
    name: "\u9f99\u51e4\u533a",
    code: "230603",
    level: "district",
  },
  {
    id: 126,
    pid: 118,
    name: "\u8428\u5c14\u56fe\u533a",
    code: "230602",
    level: "district",
  },
  {
    id: 127,
    pid: 118,
    name: "\u8087\u5dde\u53bf",
    code: "230621",
    level: "district",
  },
  {
    id: 128,
    pid: 1,
    name: "\u53cc\u9e2d\u5c71\u5e02",
    code: "230500",
    level: "city",
  },
  {
    id: 129,
    pid: 128,
    name: "\u5c16\u5c71\u533a",
    code: "230502",
    level: "district",
  },
  {
    id: 130,
    pid: 128,
    name: "\u53cb\u8c0a\u53bf",
    code: "230522",
    level: "district",
  },
  {
    id: 131,
    pid: 128,
    name: "\u5b9d\u5c71\u533a",
    code: "230506",
    level: "district",
  },
  {
    id: 132,
    pid: 128,
    name: "\u56db\u65b9\u53f0\u533a",
    code: "230505",
    level: "district",
  },
  {
    id: 133,
    pid: 128,
    name: "\u5cad\u4e1c\u533a",
    code: "230503",
    level: "district",
  },
  {
    id: 134,
    pid: 128,
    name: "\u5b9d\u6e05\u53bf",
    code: "230523",
    level: "district",
  },
  {
    id: 135,
    pid: 128,
    name: "\u9976\u6cb3\u53bf",
    code: "230524",
    level: "district",
  },
  {
    id: 136,
    pid: 128,
    name: "\u96c6\u8d24\u53bf",
    code: "230521",
    level: "district",
  },
  {
    id: 137,
    pid: 0,
    name: "\u5e7f\u4e1c\u7701",
    code: "440000",
    level: "province",
  },
  {
    id: 138,
    pid: 137,
    name: "\u6c55\u5934\u5e02",
    code: "440500",
    level: "city",
  },
  {
    id: 139,
    pid: 138,
    name: "\u6f84\u6d77\u533a",
    code: "440515",
    level: "district",
  },
  {
    id: 140,
    pid: 138,
    name: "\u6f6e\u5357\u533a",
    code: "440514",
    level: "district",
  },
  {
    id: 141,
    pid: 138,
    name: "\u5357\u6fb3\u53bf",
    code: "440523",
    level: "district",
  },
  {
    id: 142,
    pid: 138,
    name: "\u6fe0\u6c5f\u533a",
    code: "440512",
    level: "district",
  },
  {
    id: 143,
    pid: 138,
    name: "\u6f6e\u9633\u533a",
    code: "440513",
    level: "district",
  },
  {
    id: 144,
    pid: 138,
    name: "\u91d1\u5e73\u533a",
    code: "440511",
    level: "district",
  },
  {
    id: 145,
    pid: 138,
    name: "\u9f99\u6e56\u533a",
    code: "440507",
    level: "district",
  },
  {
    id: 146,
    pid: 137,
    name: "\u4f5b\u5c71\u5e02",
    code: "440600",
    level: "city",
  },
  {
    id: 147,
    pid: 146,
    name: "\u4e09\u6c34\u533a",
    code: "440607",
    level: "district",
  },
  {
    id: 148,
    pid: 146,
    name: "\u9ad8\u660e\u533a",
    code: "440608",
    level: "district",
  },
  {
    id: 149,
    pid: 146,
    name: "\u987a\u5fb7\u533a",
    code: "440606",
    level: "district",
  },
  {
    id: 150,
    pid: 146,
    name: "\u5357\u6d77\u533a",
    code: "440605",
    level: "district",
  },
  {
    id: 151,
    pid: 146,
    name: "\u7985\u57ce\u533a",
    code: "440604",
    level: "district",
  },
  {
    id: 152,
    pid: 137,
    name: "\u8087\u5e86\u5e02",
    code: "441200",
    level: "city",
  },
  {
    id: 153,
    pid: 152,
    name: "\u6000\u96c6\u53bf",
    code: "441224",
    level: "district",
  },
  {
    id: 154,
    pid: 152,
    name: "\u5c01\u5f00\u53bf",
    code: "441225",
    level: "district",
  },
  {
    id: 155,
    pid: 152,
    name: "\u5e7f\u5b81\u53bf",
    code: "441223",
    level: "district",
  },
  {
    id: 156,
    pid: 152,
    name: "\u56db\u4f1a\u5e02",
    code: "441284",
    level: "district",
  },
  {
    id: 157,
    pid: 152,
    name: "\u5fb7\u5e86\u53bf",
    code: "441226",
    level: "district",
  },
  {
    id: 158,
    pid: 152,
    name: "\u9f0e\u6e56\u533a",
    code: "441203",
    level: "district",
  },
  {
    id: 159,
    pid: 152,
    name: "\u7aef\u5dde\u533a",
    code: "441202",
    level: "district",
  },
  {
    id: 160,
    pid: 152,
    name: "\u9ad8\u8981\u533a",
    code: "441204",
    level: "district",
  },
  {
    id: 161,
    pid: 137,
    name: "\u60e0\u5dde\u5e02",
    code: "441300",
    level: "city",
  },
  {
    id: 162,
    pid: 161,
    name: "\u9f99\u95e8\u53bf",
    code: "441324",
    level: "district",
  },
  {
    id: 163,
    pid: 161,
    name: "\u60e0\u4e1c\u53bf",
    code: "441323",
    level: "district",
  },
  {
    id: 164,
    pid: 161,
    name: "\u535a\u7f57\u53bf",
    code: "441322",
    level: "district",
  },
  {
    id: 165,
    pid: 161,
    name: "\u60e0\u9633\u533a",
    code: "441303",
    level: "district",
  },
  {
    id: 166,
    pid: 161,
    name: "\u60e0\u57ce\u533a",
    code: "441302",
    level: "district",
  },
  {
    id: 167,
    pid: 137,
    name: "\u6df1\u5733\u5e02",
    code: "440300",
    level: "city",
  },
  {
    id: 168,
    pid: 167,
    name: "\u5b9d\u5b89\u533a",
    code: "440306",
    level: "district",
  },
  {
    id: 169,
    pid: 167,
    name: "\u5357\u5c71\u533a",
    code: "440305",
    level: "district",
  },
  {
    id: 170,
    pid: 167,
    name: "\u798f\u7530\u533a",
    code: "440304",
    level: "district",
  },
  {
    id: 171,
    pid: 167,
    name: "\u76d0\u7530\u533a",
    code: "440308",
    level: "district",
  },
  {
    id: 172,
    pid: 167,
    name: "\u7f57\u6e56\u533a",
    code: "440303",
    level: "district",
  },
  {
    id: 173,
    pid: 167,
    name: "\u576a\u5c71\u533a",
    code: "440310",
    level: "district",
  },
  {
    id: 174,
    pid: 167,
    name: "\u9f99\u5c97\u533a",
    code: "440307",
    level: "district",
  },
  {
    id: 175,
    pid: 167,
    name: "\u9f99\u534e\u533a",
    code: "440309",
    level: "district",
  },
  {
    id: 176,
    pid: 167,
    name: "\u5149\u660e\u533a",
    code: "440311",
    level: "district",
  },
  {
    id: 177,
    pid: 137,
    name: "\u6e5b\u6c5f\u5e02",
    code: "440800",
    level: "city",
  },
  {
    id: 178,
    pid: 177,
    name: "\u5ec9\u6c5f\u5e02",
    code: "440881",
    level: "district",
  },
  {
    id: 179,
    pid: 177,
    name: "\u5434\u5ddd\u5e02",
    code: "440883",
    level: "district",
  },
  {
    id: 180,
    pid: 177,
    name: "\u5f90\u95fb\u53bf",
    code: "440825",
    level: "district",
  },
  {
    id: 181,
    pid: 177,
    name: "\u96f7\u5dde\u5e02",
    code: "440882",
    level: "district",
  },
  {
    id: 182,
    pid: 177,
    name: "\u9ebb\u7ae0\u533a",
    code: "440811",
    level: "district",
  },
  {
    id: 183,
    pid: 177,
    name: "\u5761\u5934\u533a",
    code: "440804",
    level: "district",
  },
  {
    id: 184,
    pid: 177,
    name: "\u971e\u5c71\u533a",
    code: "440803",
    level: "district",
  },
  {
    id: 185,
    pid: 177,
    name: "\u9042\u6eaa\u53bf",
    code: "440823",
    level: "district",
  },
  {
    id: 186,
    pid: 177,
    name: "\u8d64\u574e\u533a",
    code: "440802",
    level: "district",
  },
  {
    id: 187,
    pid: 137,
    name: "\u73e0\u6d77\u5e02",
    code: "440400",
    level: "city",
  },
  {
    id: 188,
    pid: 187,
    name: "\u6597\u95e8\u533a",
    code: "440403",
    level: "district",
  },
  {
    id: 189,
    pid: 187,
    name: "\u91d1\u6e7e\u533a",
    code: "440404",
    level: "district",
  },
  {
    id: 190,
    pid: 187,
    name: "\u9999\u6d32\u533a",
    code: "440402",
    level: "district",
  },
  {
    id: 191,
    pid: 137,
    name: "\u6f6e\u5dde\u5e02",
    code: "445100",
    level: "city",
  },
  {
    id: 192,
    pid: 191,
    name: "\u9976\u5e73\u53bf",
    code: "445122",
    level: "district",
  },
  {
    id: 193,
    pid: 191,
    name: "\u6e58\u6865\u533a",
    code: "445102",
    level: "district",
  },
  {
    id: 194,
    pid: 191,
    name: "\u6f6e\u5b89\u533a",
    code: "445103",
    level: "district",
  },
  {
    id: 195,
    pid: 137,
    name: "\u8302\u540d\u5e02",
    code: "440900",
    level: "city",
  },
  {
    id: 196,
    pid: 195,
    name: "\u4fe1\u5b9c\u5e02",
    code: "440983",
    level: "district",
  },
  {
    id: 197,
    pid: 195,
    name: "\u9ad8\u5dde\u5e02",
    code: "440981",
    level: "district",
  },
  {
    id: 198,
    pid: 195,
    name: "\u5316\u5dde\u5e02",
    code: "440982",
    level: "district",
  },
  {
    id: 199,
    pid: 195,
    name: "\u7535\u767d\u533a",
    code: "440904",
    level: "district",
  },
  {
    id: 200,
    pid: 195,
    name: "\u8302\u5357\u533a",
    code: "440902",
    level: "district",
  },
  {
    id: 201,
    pid: 137,
    name: "\u6c5f\u95e8\u5e02",
    code: "440700",
    level: "city",
  },
  {
    id: 202,
    pid: 201,
    name: "\u9e64\u5c71\u5e02",
    code: "440784",
    level: "district",
  },
  {
    id: 203,
    pid: 201,
    name: "\u6c5f\u6d77\u533a",
    code: "440704",
    level: "district",
  },
  {
    id: 204,
    pid: 201,
    name: "\u5f00\u5e73\u5e02",
    code: "440783",
    level: "district",
  },
  {
    id: 205,
    pid: 201,
    name: "\u53f0\u5c71\u5e02",
    code: "440781",
    level: "district",
  },
  {
    id: 206,
    pid: 201,
    name: "\u6069\u5e73\u5e02",
    code: "440785",
    level: "district",
  },
  {
    id: 207,
    pid: 201,
    name: "\u65b0\u4f1a\u533a",
    code: "440705",
    level: "district",
  },
  {
    id: 208,
    pid: 201,
    name: "\u84ec\u6c5f\u533a",
    code: "440703",
    level: "district",
  },
  {
    id: 209,
    pid: 137,
    name: "\u6cb3\u6e90\u5e02",
    code: "441600",
    level: "city",
  },
  {
    id: 210,
    pid: 209,
    name: "\u548c\u5e73\u53bf",
    code: "441624",
    level: "district",
  },
  {
    id: 211,
    pid: 209,
    name: "\u9f99\u5ddd\u53bf",
    code: "441622",
    level: "district",
  },
  {
    id: 212,
    pid: 209,
    name: "\u8fde\u5e73\u53bf",
    code: "441623",
    level: "district",
  },
  {
    id: 213,
    pid: 209,
    name: "\u4e1c\u6e90\u53bf",
    code: "441625",
    level: "district",
  },
  {
    id: 214,
    pid: 209,
    name: "\u6e90\u57ce\u533a",
    code: "441602",
    level: "district",
  },
  {
    id: 215,
    pid: 209,
    name: "\u7d2b\u91d1\u53bf",
    code: "441621",
    level: "district",
  },
  {
    id: 216,
    pid: 137,
    name: "\u4e91\u6d6e\u5e02",
    code: "445300",
    level: "city",
  },
  {
    id: 217,
    pid: 216,
    name: "\u90c1\u5357\u53bf",
    code: "445322",
    level: "district",
  },
  {
    id: 218,
    pid: 216,
    name: "\u7f57\u5b9a\u5e02",
    code: "445381",
    level: "district",
  },
  {
    id: 219,
    pid: 216,
    name: "\u65b0\u5174\u53bf",
    code: "445321",
    level: "district",
  },
  {
    id: 220,
    pid: 216,
    name: "\u4e91\u57ce\u533a",
    code: "445302",
    level: "district",
  },
  {
    id: 221,
    pid: 216,
    name: "\u4e91\u5b89\u533a",
    code: "445303",
    level: "district",
  },
  {
    id: 222,
    pid: 137,
    name: "\u6c55\u5c3e\u5e02",
    code: "441500",
    level: "city",
  },
  {
    id: 223,
    pid: 222,
    name: "\u6d77\u4e30\u53bf",
    code: "441521",
    level: "district",
  },
  {
    id: 224,
    pid: 222,
    name: "\u9646\u6cb3\u53bf",
    code: "441523",
    level: "district",
  },
  {
    id: 225,
    pid: 222,
    name: "\u9646\u4e30\u5e02",
    code: "441581",
    level: "district",
  },
  {
    id: 226,
    pid: 222,
    name: "\u57ce\u533a",
    code: "441502",
    level: "district",
  },
  {
    id: 227,
    pid: 137,
    name: "\u9633\u6c5f\u5e02",
    code: "441700",
    level: "city",
  },
  {
    id: 228,
    pid: 227,
    name: "\u9633\u6625\u5e02",
    code: "441781",
    level: "district",
  },
  {
    id: 229,
    pid: 227,
    name: "\u6c5f\u57ce\u533a",
    code: "441702",
    level: "district",
  },
  {
    id: 230,
    pid: 227,
    name: "\u9633\u897f\u53bf",
    code: "441721",
    level: "district",
  },
  {
    id: 231,
    pid: 227,
    name: "\u9633\u4e1c\u533a",
    code: "441704",
    level: "district",
  },
  {
    id: 232,
    pid: 137,
    name: "\u63ed\u9633\u5e02",
    code: "445200",
    level: "city",
  },
  {
    id: 233,
    pid: 232,
    name: "\u666e\u5b81\u5e02",
    code: "445281",
    level: "district",
  },
  {
    id: 234,
    pid: 232,
    name: "\u63ed\u897f\u53bf",
    code: "445222",
    level: "district",
  },
  {
    id: 235,
    pid: 232,
    name: "\u60e0\u6765\u53bf",
    code: "445224",
    level: "district",
  },
  {
    id: 236,
    pid: 232,
    name: "\u63ed\u4e1c\u533a",
    code: "445203",
    level: "district",
  },
  {
    id: 237,
    pid: 232,
    name: "\u6995\u57ce\u533a",
    code: "445202",
    level: "district",
  },
  {
    id: 238,
    pid: 137,
    name: "\u6885\u5dde\u5e02",
    code: "441400",
    level: "city",
  },
  {
    id: 239,
    pid: 238,
    name: "\u8549\u5cad\u53bf",
    code: "441427",
    level: "district",
  },
  {
    id: 240,
    pid: 238,
    name: "\u5e73\u8fdc\u53bf",
    code: "441426",
    level: "district",
  },
  {
    id: 241,
    pid: 238,
    name: "\u5174\u5b81\u5e02",
    code: "441481",
    level: "district",
  },
  {
    id: 242,
    pid: 238,
    name: "\u4e94\u534e\u53bf",
    code: "441424",
    level: "district",
  },
  {
    id: 243,
    pid: 238,
    name: "\u4e30\u987a\u53bf",
    code: "441423",
    level: "district",
  },
  {
    id: 244,
    pid: 238,
    name: "\u5927\u57d4\u53bf",
    code: "441422",
    level: "district",
  },
  {
    id: 245,
    pid: 238,
    name: "\u6885\u53bf\u533a",
    code: "441403",
    level: "district",
  },
  {
    id: 246,
    pid: 238,
    name: "\u6885\u6c5f\u533a",
    code: "441402",
    level: "district",
  },
  {
    id: 247,
    pid: 137,
    name: "\u5e7f\u5dde\u5e02",
    code: "440100",
    level: "city",
  },
  {
    id: 248,
    pid: 247,
    name: "\u4ece\u5316\u533a",
    code: "440117",
    level: "district",
  },
  {
    id: 249,
    pid: 247,
    name: "\u5357\u6c99\u533a",
    code: "440115",
    level: "district",
  },
  {
    id: 250,
    pid: 247,
    name: "\u82b1\u90fd\u533a",
    code: "440114",
    level: "district",
  },
  {
    id: 251,
    pid: 247,
    name: "\u767d\u4e91\u533a",
    code: "440111",
    level: "district",
  },
  {
    id: 252,
    pid: 247,
    name: "\u756a\u79ba\u533a",
    code: "440113",
    level: "district",
  },
  {
    id: 253,
    pid: 247,
    name: "\u8354\u6e7e\u533a",
    code: "440103",
    level: "district",
  },
  {
    id: 254,
    pid: 247,
    name: "\u6d77\u73e0\u533a",
    code: "440105",
    level: "district",
  },
  {
    id: 255,
    pid: 247,
    name: "\u589e\u57ce\u533a",
    code: "440118",
    level: "district",
  },
  {
    id: 256,
    pid: 247,
    name: "\u8d8a\u79c0\u533a",
    code: "440104",
    level: "district",
  },
  {
    id: 257,
    pid: 247,
    name: "\u9ec4\u57d4\u533a",
    code: "440112",
    level: "district",
  },
  {
    id: 258,
    pid: 247,
    name: "\u5929\u6cb3\u533a",
    code: "440106",
    level: "district",
  },
  {
    id: 259,
    pid: 137,
    name: "\u97f6\u5173\u5e02",
    code: "440200",
    level: "city",
  },
  {
    id: 260,
    pid: 259,
    name: "\u59cb\u5174\u53bf",
    code: "440222",
    level: "district",
  },
  {
    id: 261,
    pid: 259,
    name: "\u5357\u96c4\u5e02",
    code: "440282",
    level: "district",
  },
  {
    id: 262,
    pid: 259,
    name: "\u6d48\u6c5f\u533a",
    code: "440204",
    level: "district",
  },
  {
    id: 263,
    pid: 259,
    name: "\u4ec1\u5316\u53bf",
    code: "440224",
    level: "district",
  },
  {
    id: 264,
    pid: 259,
    name: "\u4e73\u6e90\u7476\u65cf\u81ea\u6cbb\u53bf",
    code: "440232",
    level: "district",
  },
  {
    id: 265,
    pid: 259,
    name: "\u7fc1\u6e90\u53bf",
    code: "440229",
    level: "district",
  },
  {
    id: 266,
    pid: 259,
    name: "\u66f2\u6c5f\u533a",
    code: "440205",
    level: "district",
  },
  {
    id: 267,
    pid: 259,
    name: "\u6b66\u6c5f\u533a",
    code: "440203",
    level: "district",
  },
  {
    id: 268,
    pid: 259,
    name: "\u65b0\u4e30\u53bf",
    code: "440233",
    level: "district",
  },
  {
    id: 269,
    pid: 259,
    name: "\u4e50\u660c\u5e02",
    code: "440281",
    level: "district",
  },
  {
    id: 270,
    pid: 137,
    name: "\u6e05\u8fdc\u5e02",
    code: "441800",
    level: "city",
  },
  {
    id: 271,
    pid: 270,
    name: "\u8fde\u5dde\u5e02",
    code: "441882",
    level: "district",
  },
  {
    id: 272,
    pid: 270,
    name: "\u8fde\u5c71\u58ee\u65cf\u7476\u65cf\u81ea\u6cbb\u53bf",
    code: "441825",
    level: "district",
  },
  {
    id: 273,
    pid: 270,
    name: "\u8fde\u5357\u7476\u65cf\u81ea\u6cbb\u53bf",
    code: "441826",
    level: "district",
  },
  {
    id: 274,
    pid: 270,
    name: "\u82f1\u5fb7\u5e02",
    code: "441881",
    level: "district",
  },
  {
    id: 275,
    pid: 270,
    name: "\u4f5b\u5188\u53bf",
    code: "441821",
    level: "district",
  },
  {
    id: 276,
    pid: 270,
    name: "\u9633\u5c71\u53bf",
    code: "441823",
    level: "district",
  },
  {
    id: 277,
    pid: 270,
    name: "\u6e05\u65b0\u533a",
    code: "441803",
    level: "district",
  },
  {
    id: 278,
    pid: 270,
    name: "\u6e05\u57ce\u533a",
    code: "441802",
    level: "district",
  },
  {
    id: 279,
    pid: 137,
    name: "\u4e2d\u5c71\u5e02",
    code: "442000",
    level: "city",
  },
  {
    id: 304,
    pid: 137,
    name: "\u4e1c\u839e\u5e02",
    code: "441900",
    level: "city",
  },
  {
    id: 340,
    pid: 0,
    name: "\u6cb3\u5357\u7701",
    code: "410000",
    level: "province",
  },
  {
    id: 341,
    pid: 340,
    name: "\u6d1b\u9633\u5e02",
    code: "410300",
    level: "city",
  },
  {
    id: 342,
    pid: 341,
    name: "\u65b0\u5b89\u53bf",
    code: "410323",
    level: "district",
  },
  {
    id: 343,
    pid: 341,
    name: "\u683e\u5ddd\u53bf",
    code: "410324",
    level: "district",
  },
  {
    id: 344,
    pid: 341,
    name: "\u4f0a\u5ddd\u53bf",
    code: "410329",
    level: "district",
  },
  {
    id: 345,
    pid: 341,
    name: "\u5409\u5229\u533a",
    code: "410306",
    level: "district",
  },
  {
    id: 346,
    pid: 341,
    name: "\u6d1b\u5b81\u53bf",
    code: "410328",
    level: "district",
  },
  {
    id: 347,
    pid: 341,
    name: "\u5043\u5e08\u5e02",
    code: "410381",
    level: "district",
  },
  {
    id: 348,
    pid: 341,
    name: "\u700d\u6cb3\u56de\u65cf\u533a",
    code: "410304",
    level: "district",
  },
  {
    id: 349,
    pid: 341,
    name: "\u8001\u57ce\u533a",
    code: "410302",
    level: "district",
  },
  {
    id: 350,
    pid: 341,
    name: "\u897f\u5de5\u533a",
    code: "410303",
    level: "district",
  },
  {
    id: 351,
    pid: 341,
    name: "\u5b5f\u6d25\u53bf",
    code: "410322",
    level: "district",
  },
  {
    id: 352,
    pid: 341,
    name: "\u6d1b\u9f99\u533a",
    code: "410311",
    level: "district",
  },
  {
    id: 353,
    pid: 341,
    name: "\u6da7\u897f\u533a",
    code: "410305",
    level: "district",
  },
  {
    id: 354,
    pid: 341,
    name: "\u5b9c\u9633\u53bf",
    code: "410327",
    level: "district",
  },
  {
    id: 355,
    pid: 341,
    name: "\u6c5d\u9633\u53bf",
    code: "410326",
    level: "district",
  },
  {
    id: 356,
    pid: 341,
    name: "\u5d69\u53bf",
    code: "410325",
    level: "district",
  },
  {
    id: 357,
    pid: 340,
    name: "\u4e09\u95e8\u5ce1\u5e02",
    code: "411200",
    level: "city",
  },
  {
    id: 358,
    pid: 357,
    name: "\u6e56\u6ee8\u533a",
    code: "411202",
    level: "district",
  },
  {
    id: 359,
    pid: 357,
    name: "\u5362\u6c0f\u53bf",
    code: "411224",
    level: "district",
  },
  {
    id: 360,
    pid: 357,
    name: "\u6e11\u6c60\u53bf",
    code: "411221",
    level: "district",
  },
  {
    id: 361,
    pid: 357,
    name: "\u7075\u5b9d\u5e02",
    code: "411282",
    level: "district",
  },
  {
    id: 362,
    pid: 357,
    name: "\u9655\u5dde\u533a",
    code: "411203",
    level: "district",
  },
  {
    id: 363,
    pid: 357,
    name: "\u4e49\u9a6c\u5e02",
    code: "411281",
    level: "district",
  },
  {
    id: 364,
    pid: 340,
    name: "\u6f2f\u6cb3\u5e02",
    code: "411100",
    level: "city",
  },
  {
    id: 365,
    pid: 364,
    name: "\u53ec\u9675\u533a",
    code: "411104",
    level: "district",
  },
  {
    id: 366,
    pid: 364,
    name: "\u4e34\u988d\u53bf",
    code: "411122",
    level: "district",
  },
  {
    id: 367,
    pid: 364,
    name: "\u6e90\u6c47\u533a",
    code: "411102",
    level: "district",
  },
  {
    id: 368,
    pid: 364,
    name: "\u90fe\u57ce\u533a",
    code: "411103",
    level: "district",
  },
  {
    id: 369,
    pid: 364,
    name: "\u821e\u9633\u53bf",
    code: "411121",
    level: "district",
  },
  {
    id: 370,
    pid: 340,
    name: "\u8bb8\u660c\u5e02",
    code: "411000",
    level: "city",
  },
  {
    id: 371,
    pid: 370,
    name: "\u5efa\u5b89\u533a",
    code: "411003",
    level: "district",
  },
  {
    id: 372,
    pid: 370,
    name: "\u9b4f\u90fd\u533a",
    code: "411002",
    level: "district",
  },
  {
    id: 373,
    pid: 370,
    name: "\u957f\u845b\u5e02",
    code: "411082",
    level: "district",
  },
  {
    id: 374,
    pid: 370,
    name: "\u79b9\u5dde\u5e02",
    code: "411081",
    level: "district",
  },
  {
    id: 375,
    pid: 370,
    name: "\u9122\u9675\u53bf",
    code: "411024",
    level: "district",
  },
  {
    id: 376,
    pid: 370,
    name: "\u8944\u57ce\u53bf",
    code: "411025",
    level: "district",
  },
  {
    id: 377,
    pid: 340,
    name: "\u5357\u9633\u5e02",
    code: "411300",
    level: "city",
  },
  {
    id: 378,
    pid: 377,
    name: "\u897f\u5ce1\u53bf",
    code: "411323",
    level: "district",
  },
  {
    id: 379,
    pid: 377,
    name: "\u793e\u65d7\u53bf",
    code: "411327",
    level: "district",
  },
  {
    id: 380,
    pid: 377,
    name: "\u6850\u67cf\u53bf",
    code: "411330",
    level: "district",
  },
  {
    id: 381,
    pid: 377,
    name: "\u5367\u9f99\u533a",
    code: "411303",
    level: "district",
  },
  {
    id: 382,
    pid: 377,
    name: "\u5357\u53ec\u53bf",
    code: "411321",
    level: "district",
  },
  {
    id: 383,
    pid: 377,
    name: "\u5510\u6cb3\u53bf",
    code: "411328",
    level: "district",
  },
  {
    id: 384,
    pid: 377,
    name: "\u65b9\u57ce\u53bf",
    code: "411322",
    level: "district",
  },
  {
    id: 385,
    pid: 377,
    name: "\u5b9b\u57ce\u533a",
    code: "411302",
    level: "district",
  },
  {
    id: 386,
    pid: 377,
    name: "\u65b0\u91ce\u53bf",
    code: "411329",
    level: "district",
  },
  {
    id: 387,
    pid: 377,
    name: "\u9547\u5e73\u53bf",
    code: "411324",
    level: "district",
  },
  {
    id: 388,
    pid: 377,
    name: "\u6dc5\u5ddd\u53bf",
    code: "411326",
    level: "district",
  },
  {
    id: 389,
    pid: 377,
    name: "\u9093\u5dde\u5e02",
    code: "411381",
    level: "district",
  },
  {
    id: 390,
    pid: 377,
    name: "\u5185\u4e61\u53bf",
    code: "411325",
    level: "district",
  },
  {
    id: 391,
    pid: 340,
    name: "\u4fe1\u9633\u5e02",
    code: "411500",
    level: "city",
  },
  {
    id: 392,
    pid: 391,
    name: "\u6f62\u5ddd\u53bf",
    code: "411526",
    level: "district",
  },
  {
    id: 393,
    pid: 391,
    name: "\u6dee\u6ee8\u53bf",
    code: "411527",
    level: "district",
  },
  {
    id: 394,
    pid: 391,
    name: "\u7f57\u5c71\u53bf",
    code: "411521",
    level: "district",
  },
  {
    id: 395,
    pid: 391,
    name: "\u5149\u5c71\u53bf",
    code: "411522",
    level: "district",
  },
  {
    id: 396,
    pid: 391,
    name: "\u65b0\u53bf",
    code: "411523",
    level: "district",
  },
  {
    id: 397,
    pid: 391,
    name: "\u6d49\u6cb3\u533a",
    code: "411502",
    level: "district",
  },
  {
    id: 398,
    pid: 391,
    name: "\u56fa\u59cb\u53bf",
    code: "411525",
    level: "district",
  },
  {
    id: 399,
    pid: 391,
    name: "\u5546\u57ce\u53bf",
    code: "411524",
    level: "district",
  },
  {
    id: 400,
    pid: 391,
    name: "\u606f\u53bf",
    code: "411528",
    level: "district",
  },
  {
    id: 401,
    pid: 391,
    name: "\u5e73\u6865\u533a",
    code: "411503",
    level: "district",
  },
  {
    id: 402,
    pid: 340,
    name: "\u6d4e\u6e90\u5e02",
    code: "419001",
    level: "city",
  },
  {
    id: 419,
    pid: 340,
    name: "\u9a7b\u9a6c\u5e97\u5e02",
    code: "411700",
    level: "city",
  },
  {
    id: 420,
    pid: 419,
    name: "\u4e0a\u8521\u53bf",
    code: "411722",
    level: "district",
  },
  {
    id: 421,
    pid: 419,
    name: "\u897f\u5e73\u53bf",
    code: "411721",
    level: "district",
  },
  {
    id: 422,
    pid: 419,
    name: "\u65b0\u8521\u53bf",
    code: "411729",
    level: "district",
  },
  {
    id: 423,
    pid: 419,
    name: "\u6ccc\u9633\u53bf",
    code: "411726",
    level: "district",
  },
  {
    id: 424,
    pid: 419,
    name: "\u9a7f\u57ce\u533a",
    code: "411702",
    level: "district",
  },
  {
    id: 425,
    pid: 419,
    name: "\u786e\u5c71\u53bf",
    code: "411725",
    level: "district",
  },
  {
    id: 426,
    pid: 419,
    name: "\u6b63\u9633\u53bf",
    code: "411724",
    level: "district",
  },
  {
    id: 427,
    pid: 419,
    name: "\u9042\u5e73\u53bf",
    code: "411728",
    level: "district",
  },
  {
    id: 428,
    pid: 419,
    name: "\u6c5d\u5357\u53bf",
    code: "411727",
    level: "district",
  },
  {
    id: 429,
    pid: 419,
    name: "\u5e73\u8206\u53bf",
    code: "411723",
    level: "district",
  },
  {
    id: 430,
    pid: 340,
    name: "\u6fee\u9633\u5e02",
    code: "410900",
    level: "city",
  },
  {
    id: 431,
    pid: 430,
    name: "\u53f0\u524d\u53bf",
    code: "410927",
    level: "district",
  },
  {
    id: 432,
    pid: 430,
    name: "\u5357\u4e50\u53bf",
    code: "410923",
    level: "district",
  },
  {
    id: 433,
    pid: 430,
    name: "\u8303\u53bf",
    code: "410926",
    level: "district",
  },
  {
    id: 434,
    pid: 430,
    name: "\u534e\u9f99\u533a",
    code: "410902",
    level: "district",
  },
  {
    id: 435,
    pid: 430,
    name: "\u6e05\u4e30\u53bf",
    code: "410922",
    level: "district",
  },
  {
    id: 436,
    pid: 430,
    name: "\u6fee\u9633\u53bf",
    code: "410928",
    level: "district",
  },
  {
    id: 437,
    pid: 340,
    name: "\u7126\u4f5c\u5e02",
    code: "410800",
    level: "city",
  },
  {
    id: 438,
    pid: 437,
    name: "\u5b5f\u5dde\u5e02",
    code: "410883",
    level: "district",
  },
  {
    id: 439,
    pid: 437,
    name: "\u6e29\u53bf",
    code: "410825",
    level: "district",
  },
  {
    id: 440,
    pid: 437,
    name: "\u6c81\u9633\u5e02",
    code: "410882",
    level: "district",
  },
  {
    id: 441,
    pid: 437,
    name: "\u9a6c\u6751\u533a",
    code: "410804",
    level: "district",
  },
  {
    id: 442,
    pid: 437,
    name: "\u6b66\u965f\u53bf",
    code: "410823",
    level: "district",
  },
  {
    id: 443,
    pid: 437,
    name: "\u535a\u7231\u53bf",
    code: "410822",
    level: "district",
  },
  {
    id: 444,
    pid: 437,
    name: "\u4fee\u6b66\u53bf",
    code: "410821",
    level: "district",
  },
  {
    id: 445,
    pid: 437,
    name: "\u89e3\u653e\u533a",
    code: "410802",
    level: "district",
  },
  {
    id: 446,
    pid: 437,
    name: "\u5c71\u9633\u533a",
    code: "410811",
    level: "district",
  },
  {
    id: 447,
    pid: 437,
    name: "\u4e2d\u7ad9\u533a",
    code: "410803",
    level: "district",
  },
  {
    id: 448,
    pid: 340,
    name: "\u90d1\u5dde\u5e02",
    code: "410100",
    level: "city",
  },
  {
    id: 449,
    pid: 448,
    name: "\u767b\u5c01\u5e02",
    code: "410185",
    level: "district",
  },
  {
    id: 450,
    pid: 448,
    name: "\u4e2d\u539f\u533a",
    code: "410102",
    level: "district",
  },
  {
    id: 451,
    pid: 448,
    name: "\u65b0\u5bc6\u5e02",
    code: "410183",
    level: "district",
  },
  {
    id: 452,
    pid: 448,
    name: "\u65b0\u90d1\u5e02",
    code: "410184",
    level: "district",
  },
  {
    id: 453,
    pid: 448,
    name: "\u5de9\u4e49\u5e02",
    code: "410181",
    level: "district",
  },
  {
    id: 454,
    pid: 448,
    name: "\u8365\u9633\u5e02",
    code: "410182",
    level: "district",
  },
  {
    id: 455,
    pid: 448,
    name: "\u4e0a\u8857\u533a",
    code: "410106",
    level: "district",
  },
  {
    id: 456,
    pid: 448,
    name: "\u4e8c\u4e03\u533a",
    code: "410103",
    level: "district",
  },
  {
    id: 457,
    pid: 448,
    name: "\u91d1\u6c34\u533a",
    code: "410105",
    level: "district",
  },
  {
    id: 458,
    pid: 448,
    name: "\u60e0\u6d4e\u533a",
    code: "410108",
    level: "district",
  },
  {
    id: 459,
    pid: 448,
    name: "\u4e2d\u725f\u53bf",
    code: "410122",
    level: "district",
  },
  {
    id: 460,
    pid: 448,
    name: "\u7ba1\u57ce\u56de\u65cf\u533a",
    code: "410104",
    level: "district",
  },
  {
    id: 461,
    pid: 340,
    name: "\u5b89\u9633\u5e02",
    code: "410500",
    level: "city",
  },
  {
    id: 462,
    pid: 461,
    name: "\u6c64\u9634\u53bf",
    code: "410523",
    level: "district",
  },
  {
    id: 463,
    pid: 461,
    name: "\u6bb7\u90fd\u533a",
    code: "410505",
    level: "district",
  },
  {
    id: 464,
    pid: 461,
    name: "\u6797\u5dde\u5e02",
    code: "410581",
    level: "district",
  },
  {
    id: 465,
    pid: 461,
    name: "\u9f99\u5b89\u533a",
    code: "410506",
    level: "district",
  },
  {
    id: 466,
    pid: 461,
    name: "\u5185\u9ec4\u53bf",
    code: "410527",
    level: "district",
  },
  {
    id: 467,
    pid: 461,
    name: "\u5317\u5173\u533a",
    code: "410503",
    level: "district",
  },
  {
    id: 468,
    pid: 461,
    name: "\u5b89\u9633\u53bf",
    code: "410522",
    level: "district",
  },
  {
    id: 469,
    pid: 461,
    name: "\u6587\u5cf0\u533a",
    code: "410502",
    level: "district",
  },
  {
    id: 470,
    pid: 461,
    name: "\u6ed1\u53bf",
    code: "410526",
    level: "district",
  },
  {
    id: 471,
    pid: 340,
    name: "\u65b0\u4e61\u5e02",
    code: "410700",
    level: "city",
  },
  {
    id: 472,
    pid: 471,
    name: "\u8f89\u53bf\u5e02",
    code: "410782",
    level: "district",
  },
  {
    id: 473,
    pid: 471,
    name: "\u51e4\u6cc9\u533a",
    code: "410704",
    level: "district",
  },
  {
    id: 474,
    pid: 471,
    name: "\u957f\u57a3\u5e02",
    code: "410783",
    level: "district",
  },
  {
    id: 475,
    pid: 471,
    name: "\u7267\u91ce\u533a",
    code: "410711",
    level: "district",
  },
  {
    id: 476,
    pid: 471,
    name: "\u536b\u6ee8\u533a",
    code: "410703",
    level: "district",
  },
  {
    id: 477,
    pid: 471,
    name: "\u83b7\u5609\u53bf",
    code: "410724",
    level: "district",
  },
  {
    id: 478,
    pid: 471,
    name: "\u65b0\u4e61\u53bf",
    code: "410721",
    level: "district",
  },
  {
    id: 479,
    pid: 471,
    name: "\u5c01\u4e18\u53bf",
    code: "410727",
    level: "district",
  },
  {
    id: 480,
    pid: 471,
    name: "\u539f\u9633\u53bf",
    code: "410725",
    level: "district",
  },
  {
    id: 481,
    pid: 471,
    name: "\u5ef6\u6d25\u53bf",
    code: "410726",
    level: "district",
  },
  {
    id: 482,
    pid: 471,
    name: "\u536b\u8f89\u5e02",
    code: "410781",
    level: "district",
  },
  {
    id: 483,
    pid: 471,
    name: "\u7ea2\u65d7\u533a",
    code: "410702",
    level: "district",
  },
  {
    id: 484,
    pid: 340,
    name: "\u9e64\u58c1\u5e02",
    code: "410600",
    level: "city",
  },
  {
    id: 485,
    pid: 484,
    name: "\u9e64\u5c71\u533a",
    code: "410602",
    level: "district",
  },
  {
    id: 486,
    pid: 484,
    name: "\u5c71\u57ce\u533a",
    code: "410603",
    level: "district",
  },
  {
    id: 487,
    pid: 484,
    name: "\u6dc7\u53bf",
    code: "410622",
    level: "district",
  },
  {
    id: 488,
    pid: 484,
    name: "\u6dc7\u6ee8\u533a",
    code: "410611",
    level: "district",
  },
  {
    id: 489,
    pid: 484,
    name: "\u6d5a\u53bf",
    code: "410621",
    level: "district",
  },
  {
    id: 490,
    pid: 340,
    name: "\u5f00\u5c01\u5e02",
    code: "410200",
    level: "city",
  },
  {
    id: 491,
    pid: 490,
    name: "\u987a\u6cb3\u56de\u65cf\u533a",
    code: "410203",
    level: "district",
  },
  {
    id: 492,
    pid: 490,
    name: "\u7965\u7b26\u533a",
    code: "410212",
    level: "district",
  },
  {
    id: 493,
    pid: 490,
    name: "\u79b9\u738b\u53f0\u533a",
    code: "410205",
    level: "district",
  },
  {
    id: 494,
    pid: 490,
    name: "\u9f13\u697c\u533a",
    code: "410204",
    level: "district",
  },
  {
    id: 495,
    pid: 490,
    name: "\u9f99\u4ead\u533a",
    code: "410202",
    level: "district",
  },
  {
    id: 496,
    pid: 490,
    name: "\u5c09\u6c0f\u53bf",
    code: "410223",
    level: "district",
  },
  {
    id: 497,
    pid: 490,
    name: "\u5170\u8003\u53bf",
    code: "410225",
    level: "district",
  },
  {
    id: 498,
    pid: 490,
    name: "\u675e\u53bf",
    code: "410221",
    level: "district",
  },
  {
    id: 499,
    pid: 490,
    name: "\u901a\u8bb8\u53bf",
    code: "410222",
    level: "district",
  },
  {
    id: 500,
    pid: 340,
    name: "\u5468\u53e3\u5e02",
    code: "411600",
    level: "city",
  },
  {
    id: 501,
    pid: 500,
    name: "\u6c88\u4e18\u53bf",
    code: "411624",
    level: "district",
  },
  {
    id: 502,
    pid: 500,
    name: "\u9879\u57ce\u5e02",
    code: "411681",
    level: "district",
  },
  {
    id: 503,
    pid: 500,
    name: "\u5ddd\u6c47\u533a",
    code: "411602",
    level: "district",
  },
  {
    id: 504,
    pid: 500,
    name: "\u5546\u6c34\u53bf",
    code: "411623",
    level: "district",
  },
  {
    id: 505,
    pid: 500,
    name: "\u9e7f\u9091\u53bf",
    code: "411628",
    level: "district",
  },
  {
    id: 506,
    pid: 500,
    name: "\u90f8\u57ce\u53bf",
    code: "411625",
    level: "district",
  },
  {
    id: 507,
    pid: 500,
    name: "\u6dee\u9633\u533a",
    code: "411603",
    level: "district",
  },
  {
    id: 508,
    pid: 500,
    name: "\u897f\u534e\u53bf",
    code: "411622",
    level: "district",
  },
  {
    id: 509,
    pid: 500,
    name: "\u6276\u6c9f\u53bf",
    code: "411621",
    level: "district",
  },
  {
    id: 510,
    pid: 500,
    name: "\u592a\u5eb7\u53bf",
    code: "411627",
    level: "district",
  },
  {
    id: 511,
    pid: 340,
    name: "\u5e73\u9876\u5c71\u5e02",
    code: "410400",
    level: "city",
  },
  {
    id: 512,
    pid: 511,
    name: "\u77f3\u9f99\u533a",
    code: "410404",
    level: "district",
  },
  {
    id: 513,
    pid: 511,
    name: "\u536b\u4e1c\u533a",
    code: "410403",
    level: "district",
  },
  {
    id: 514,
    pid: 511,
    name: "\u9c81\u5c71\u53bf",
    code: "410423",
    level: "district",
  },
  {
    id: 515,
    pid: 511,
    name: "\u6e5b\u6cb3\u533a",
    code: "410411",
    level: "district",
  },
  {
    id: 516,
    pid: 511,
    name: "\u821e\u94a2\u5e02",
    code: "410481",
    level: "district",
  },
  {
    id: 517,
    pid: 511,
    name: "\u90cf\u53bf",
    code: "410425",
    level: "district",
  },
  {
    id: 518,
    pid: 511,
    name: "\u53f6\u53bf",
    code: "410422",
    level: "district",
  },
  {
    id: 519,
    pid: 511,
    name: "\u5b9d\u4e30\u53bf",
    code: "410421",
    level: "district",
  },
  {
    id: 520,
    pid: 511,
    name: "\u6c5d\u5dde\u5e02",
    code: "410482",
    level: "district",
  },
  {
    id: 521,
    pid: 511,
    name: "\u65b0\u534e\u533a",
    code: "410402",
    level: "district",
  },
  {
    id: 522,
    pid: 340,
    name: "\u5546\u4e18\u5e02",
    code: "411400",
    level: "city",
  },
  {
    id: 523,
    pid: 522,
    name: "\u5b81\u9675\u53bf",
    code: "411423",
    level: "district",
  },
  {
    id: 524,
    pid: 522,
    name: "\u6881\u56ed\u533a",
    code: "411402",
    level: "district",
  },
  {
    id: 525,
    pid: 522,
    name: "\u6c38\u57ce\u5e02",
    code: "411481",
    level: "district",
  },
  {
    id: 526,
    pid: 522,
    name: "\u7762\u9633\u533a",
    code: "411403",
    level: "district",
  },
  {
    id: 527,
    pid: 522,
    name: "\u7762\u53bf",
    code: "411422",
    level: "district",
  },
  {
    id: 528,
    pid: 522,
    name: "\u590f\u9091\u53bf",
    code: "411426",
    level: "district",
  },
  {
    id: 529,
    pid: 522,
    name: "\u865e\u57ce\u53bf",
    code: "411425",
    level: "district",
  },
  {
    id: 530,
    pid: 522,
    name: "\u6c11\u6743\u53bf",
    code: "411421",
    level: "district",
  },
  {
    id: 531,
    pid: 522,
    name: "\u67d8\u57ce\u53bf",
    code: "411424",
    level: "district",
  },
  {
    id: 532,
    pid: 0,
    name: "\u5185\u8499\u53e4\u81ea\u6cbb\u533a",
    code: "150000",
    level: "province",
  },
  {
    id: 533,
    pid: 532,
    name: "\u9102\u5c14\u591a\u65af\u5e02",
    code: "150600",
    level: "city",
  },
  {
    id: 534,
    pid: 533,
    name: "\u8fbe\u62c9\u7279\u65d7",
    code: "150621",
    level: "district",
  },
  {
    id: 535,
    pid: 533,
    name: "\u9102\u6258\u514b\u65d7",
    code: "150624",
    level: "district",
  },
  {
    id: 536,
    pid: 533,
    name: "\u51c6\u683c\u5c14\u65d7",
    code: "150622",
    level: "district",
  },
  {
    id: 537,
    pid: 533,
    name: "\u676d\u9526\u65d7",
    code: "150625",
    level: "district",
  },
  {
    id: 538,
    pid: 533,
    name: "\u4e4c\u5ba1\u65d7",
    code: "150626",
    level: "district",
  },
  {
    id: 539,
    pid: 533,
    name: "\u9102\u6258\u514b\u524d\u65d7",
    code: "150623",
    level: "district",
  },
  {
    id: 540,
    pid: 533,
    name: "\u4f0a\u91d1\u970d\u6d1b\u65d7",
    code: "150627",
    level: "district",
  },
  {
    id: 541,
    pid: 533,
    name: "\u5eb7\u5df4\u4ec0\u533a",
    code: "150603",
    level: "district",
  },
  {
    id: 542,
    pid: 533,
    name: "\u4e1c\u80dc\u533a",
    code: "150602",
    level: "district",
  },
  {
    id: 543,
    pid: 532,
    name: "\u5305\u5934\u5e02",
    code: "150200",
    level: "city",
  },
  {
    id: 544,
    pid: 543,
    name: "\u571f\u9ed8\u7279\u53f3\u65d7",
    code: "150221",
    level: "district",
  },
  {
    id: 545,
    pid: 543,
    name: "\u767d\u4e91\u9102\u535a\u77ff\u533a",
    code: "150206",
    level: "district",
  },
  {
    id: 546,
    pid: 543,
    name: "\u8fbe\u5c14\u7f55\u8302\u660e\u5b89\u8054\u5408\u65d7",
    code: "150223",
    level: "district",
  },
  {
    id: 547,
    pid: 543,
    name: "\u4e5d\u539f\u533a",
    code: "150207",
    level: "district",
  },
  {
    id: 548,
    pid: 543,
    name: "\u77f3\u62d0\u533a",
    code: "150205",
    level: "district",
  },
  {
    id: 549,
    pid: 543,
    name: "\u9752\u5c71\u533a",
    code: "150204",
    level: "district",
  },
  {
    id: 550,
    pid: 543,
    name: "\u4e1c\u6cb3\u533a",
    code: "150202",
    level: "district",
  },
  {
    id: 551,
    pid: 543,
    name: "\u6606\u90fd\u4ed1\u533a",
    code: "150203",
    level: "district",
  },
  {
    id: 552,
    pid: 543,
    name: "\u56fa\u9633\u53bf",
    code: "150222",
    level: "district",
  },
  {
    id: 553,
    pid: 532,
    name: "\u5df4\u5f66\u6dd6\u5c14\u5e02",
    code: "150800",
    level: "city",
  },
  {
    id: 554,
    pid: 553,
    name: "\u4e4c\u62c9\u7279\u4e2d\u65d7",
    code: "150824",
    level: "district",
  },
  {
    id: 555,
    pid: 553,
    name: "\u4e94\u539f\u53bf",
    code: "150821",
    level: "district",
  },
  {
    id: 556,
    pid: 553,
    name: "\u4e34\u6cb3\u533a",
    code: "150802",
    level: "district",
  },
  {
    id: 557,
    pid: 553,
    name: "\u78f4\u53e3\u53bf",
    code: "150822",
    level: "district",
  },
  {
    id: 558,
    pid: 553,
    name: "\u4e4c\u62c9\u7279\u524d\u65d7",
    code: "150823",
    level: "district",
  },
  {
    id: 559,
    pid: 553,
    name: "\u676d\u9526\u540e\u65d7",
    code: "150826",
    level: "district",
  },
  {
    id: 560,
    pid: 553,
    name: "\u4e4c\u62c9\u7279\u540e\u65d7",
    code: "150825",
    level: "district",
  },
  {
    id: 561,
    pid: 532,
    name: "\u4e4c\u6d77\u5e02",
    code: "150300",
    level: "city",
  },
  {
    id: 562,
    pid: 561,
    name: "\u4e4c\u8fbe\u533a",
    code: "150304",
    level: "district",
  },
  {
    id: 563,
    pid: 561,
    name: "\u6d77\u5357\u533a",
    code: "150303",
    level: "district",
  },
  {
    id: 564,
    pid: 561,
    name: "\u6d77\u52c3\u6e7e\u533a",
    code: "150302",
    level: "district",
  },
  {
    id: 565,
    pid: 532,
    name: "\u547c\u4f26\u8d1d\u5c14\u5e02",
    code: "150700",
    level: "city",
  },
  {
    id: 566,
    pid: 565,
    name: "\u989d\u5c14\u53e4\u7eb3\u5e02",
    code: "150784",
    level: "district",
  },
  {
    id: 567,
    pid: 565,
    name: "\u7259\u514b\u77f3\u5e02",
    code: "150782",
    level: "district",
  },
  {
    id: 568,
    pid: 565,
    name: "\u6839\u6cb3\u5e02",
    code: "150785",
    level: "district",
  },
  {
    id: 569,
    pid: 565,
    name: "\u963f\u8363\u65d7",
    code: "150721",
    level: "district",
  },
  {
    id: 570,
    pid: 565,
    name: "\u6d77\u62c9\u5c14\u533a",
    code: "150702",
    level: "district",
  },
  {
    id: 571,
    pid: 565,
    name: "\u9648\u5df4\u5c14\u864e\u65d7",
    code: "150725",
    level: "district",
  },
  {
    id: 572,
    pid: 565,
    name: "\u624e\u5170\u5c6f\u5e02",
    code: "150783",
    level: "district",
  },
  {
    id: 573,
    pid: 565,
    name: "\u83ab\u529b\u8fbe\u74e6\u8fbe\u65a1\u5c14\u65cf\u81ea\u6cbb\u65d7",
    code: "150722",
    level: "district",
  },
  {
    id: 574,
    pid: 565,
    name: "\u9102\u6e29\u514b\u65cf\u81ea\u6cbb\u65d7",
    code: "150724",
    level: "district",
  },
  {
    id: 575,
    pid: 565,
    name: "\u65b0\u5df4\u5c14\u864e\u53f3\u65d7",
    code: "150727",
    level: "district",
  },
  {
    id: 576,
    pid: 565,
    name: "\u624e\u8d49\u8bfa\u5c14\u533a",
    code: "150703",
    level: "district",
  },
  {
    id: 577,
    pid: 565,
    name: "\u6ee1\u6d32\u91cc\u5e02",
    code: "150781",
    level: "district",
  },
  {
    id: 578,
    pid: 565,
    name: "\u9102\u4f26\u6625\u81ea\u6cbb\u65d7",
    code: "150723",
    level: "district",
  },
  {
    id: 579,
    pid: 565,
    name: "\u65b0\u5df4\u5c14\u864e\u5de6\u65d7",
    code: "150726",
    level: "district",
  },
  {
    id: 580,
    pid: 532,
    name: "\u963f\u62c9\u5584\u76df",
    code: "152900",
    level: "city",
  },
  {
    id: 581,
    pid: 580,
    name: "\u989d\u6d4e\u7eb3\u65d7",
    code: "152923",
    level: "district",
  },
  {
    id: 582,
    pid: 580,
    name: "\u963f\u62c9\u5584\u53f3\u65d7",
    code: "152922",
    level: "district",
  },
  {
    id: 583,
    pid: 580,
    name: "\u963f\u62c9\u5584\u5de6\u65d7",
    code: "152921",
    level: "district",
  },
  {
    id: 584,
    pid: 532,
    name: "\u901a\u8fbd\u5e02",
    code: "150500",
    level: "city",
  },
  {
    id: 585,
    pid: 584,
    name: "\u970d\u6797\u90ed\u52d2\u5e02",
    code: "150581",
    level: "district",
  },
  {
    id: 586,
    pid: 584,
    name: "\u624e\u9c81\u7279\u65d7",
    code: "150526",
    level: "district",
  },
  {
    id: 587,
    pid: 584,
    name: "\u79d1\u5c14\u6c81\u5de6\u7ffc\u4e2d\u65d7",
    code: "150521",
    level: "district",
  },
  {
    id: 588,
    pid: 584,
    name: "\u5948\u66fc\u65d7",
    code: "150525",
    level: "district",
  },
  {
    id: 589,
    pid: 584,
    name: "\u5f00\u9c81\u53bf",
    code: "150523",
    level: "district",
  },
  {
    id: 590,
    pid: 584,
    name: "\u79d1\u5c14\u6c81\u533a",
    code: "150502",
    level: "district",
  },
  {
    id: 591,
    pid: 584,
    name: "\u5e93\u4f26\u65d7",
    code: "150524",
    level: "district",
  },
  {
    id: 592,
    pid: 584,
    name: "\u79d1\u5c14\u6c81\u5de6\u7ffc\u540e\u65d7",
    code: "150522",
    level: "district",
  },
  {
    id: 593,
    pid: 532,
    name: "\u5174\u5b89\u76df",
    code: "152200",
    level: "city",
  },
  {
    id: 594,
    pid: 593,
    name: "\u963f\u5c14\u5c71\u5e02",
    code: "152202",
    level: "district",
  },
  {
    id: 595,
    pid: 593,
    name: "\u79d1\u5c14\u6c81\u53f3\u7ffc\u4e2d\u65d7",
    code: "152222",
    level: "district",
  },
  {
    id: 596,
    pid: 593,
    name: "\u7a81\u6cc9\u53bf",
    code: "152224",
    level: "district",
  },
  {
    id: 597,
    pid: 593,
    name: "\u4e4c\u5170\u6d69\u7279\u5e02",
    code: "152201",
    level: "district",
  },
  {
    id: 598,
    pid: 593,
    name: "\u624e\u8d49\u7279\u65d7",
    code: "152223",
    level: "district",
  },
  {
    id: 599,
    pid: 593,
    name: "\u79d1\u5c14\u6c81\u53f3\u7ffc\u524d\u65d7",
    code: "152221",
    level: "district",
  },
  {
    id: 600,
    pid: 532,
    name: "\u9521\u6797\u90ed\u52d2\u76df",
    code: "152500",
    level: "city",
  },
  {
    id: 601,
    pid: 600,
    name: "\u897f\u4e4c\u73e0\u7a46\u6c81\u65d7",
    code: "152526",
    level: "district",
  },
  {
    id: 602,
    pid: 600,
    name: "\u9521\u6797\u6d69\u7279\u5e02",
    code: "152502",
    level: "district",
  },
  {
    id: 603,
    pid: 600,
    name: "\u4e1c\u4e4c\u73e0\u7a46\u6c81\u65d7",
    code: "152525",
    level: "district",
  },
  {
    id: 604,
    pid: 600,
    name: "\u963f\u5df4\u560e\u65d7",
    code: "152522",
    level: "district",
  },
  {
    id: 605,
    pid: 600,
    name: "\u82cf\u5c3c\u7279\u5de6\u65d7",
    code: "152523",
    level: "district",
  },
  {
    id: 606,
    pid: 600,
    name: "\u82cf\u5c3c\u7279\u53f3\u65d7",
    code: "152524",
    level: "district",
  },
  {
    id: 607,
    pid: 600,
    name: "\u4e8c\u8fde\u6d69\u7279\u5e02",
    code: "152501",
    level: "district",
  },
  {
    id: 608,
    pid: 600,
    name: "\u9576\u9ec4\u65d7",
    code: "152528",
    level: "district",
  },
  {
    id: 609,
    pid: 600,
    name: "\u6b63\u84dd\u65d7",
    code: "152530",
    level: "district",
  },
  {
    id: 610,
    pid: 600,
    name: "\u6b63\u9576\u767d\u65d7",
    code: "152529",
    level: "district",
  },
  {
    id: 611,
    pid: 600,
    name: "\u591a\u4f26\u53bf",
    code: "152531",
    level: "district",
  },
  {
    id: 612,
    pid: 600,
    name: "\u592a\u4ec6\u5bfa\u65d7",
    code: "152527",
    level: "district",
  },
  {
    id: 613,
    pid: 532,
    name: "\u547c\u548c\u6d69\u7279\u5e02",
    code: "150100",
    level: "city",
  },
  {
    id: 614,
    pid: 613,
    name: "\u548c\u6797\u683c\u5c14\u53bf",
    code: "150123",
    level: "district",
  },
  {
    id: 615,
    pid: 613,
    name: "\u56de\u6c11\u533a",
    code: "150103",
    level: "district",
  },
  {
    id: 616,
    pid: 613,
    name: "\u7389\u6cc9\u533a",
    code: "150104",
    level: "district",
  },
  {
    id: 617,
    pid: 613,
    name: "\u6b66\u5ddd\u53bf",
    code: "150125",
    level: "district",
  },
  {
    id: 618,
    pid: 613,
    name: "\u571f\u9ed8\u7279\u5de6\u65d7",
    code: "150121",
    level: "district",
  },
  {
    id: 619,
    pid: 613,
    name: "\u6258\u514b\u6258\u53bf",
    code: "150122",
    level: "district",
  },
  {
    id: 620,
    pid: 613,
    name: "\u8d5b\u7f55\u533a",
    code: "150105",
    level: "district",
  },
  {
    id: 621,
    pid: 613,
    name: "\u65b0\u57ce\u533a",
    code: "150102",
    level: "district",
  },
  {
    id: 622,
    pid: 613,
    name: "\u6e05\u6c34\u6cb3\u53bf",
    code: "150124",
    level: "district",
  },
  {
    id: 623,
    pid: 532,
    name: "\u8d64\u5cf0\u5e02",
    code: "150400",
    level: "city",
  },
  {
    id: 624,
    pid: 623,
    name: "\u963f\u9c81\u79d1\u5c14\u6c81\u65d7",
    code: "150421",
    level: "district",
  },
  {
    id: 625,
    pid: 623,
    name: "\u6797\u897f\u53bf",
    code: "150424",
    level: "district",
  },
  {
    id: 626,
    pid: 623,
    name: "\u5df4\u6797\u5de6\u65d7",
    code: "150422",
    level: "district",
  },
  {
    id: 627,
    pid: 623,
    name: "\u5df4\u6797\u53f3\u65d7",
    code: "150423",
    level: "district",
  },
  {
    id: 628,
    pid: 623,
    name: "\u514b\u4ec0\u514b\u817e\u65d7",
    code: "150425",
    level: "district",
  },
  {
    id: 629,
    pid: 623,
    name: "\u7fc1\u725b\u7279\u65d7",
    code: "150426",
    level: "district",
  },
  {
    id: 630,
    pid: 623,
    name: "\u677e\u5c71\u533a",
    code: "150404",
    level: "district",
  },
  {
    id: 631,
    pid: 623,
    name: "\u7ea2\u5c71\u533a",
    code: "150402",
    level: "district",
  },
  {
    id: 632,
    pid: 623,
    name: "\u5580\u5587\u6c81\u65d7",
    code: "150428",
    level: "district",
  },
  {
    id: 633,
    pid: 623,
    name: "\u5143\u5b9d\u5c71\u533a",
    code: "150403",
    level: "district",
  },
  {
    id: 634,
    pid: 623,
    name: "\u6556\u6c49\u65d7",
    code: "150430",
    level: "district",
  },
  {
    id: 635,
    pid: 623,
    name: "\u5b81\u57ce\u53bf",
    code: "150429",
    level: "district",
  },
  {
    id: 636,
    pid: 532,
    name: "\u4e4c\u5170\u5bdf\u5e03\u5e02",
    code: "150900",
    level: "city",
  },
  {
    id: 637,
    pid: 636,
    name: "\u56db\u5b50\u738b\u65d7",
    code: "150929",
    level: "district",
  },
  {
    id: 638,
    pid: 636,
    name: "\u5bdf\u54c8\u5c14\u53f3\u7ffc\u4e2d\u65d7",
    code: "150927",
    level: "district",
  },
  {
    id: 639,
    pid: 636,
    name: "\u5546\u90fd\u53bf",
    code: "150923",
    level: "district",
  },
  {
    id: 640,
    pid: 636,
    name: "\u5174\u548c\u53bf",
    code: "150924",
    level: "district",
  },
  {
    id: 641,
    pid: 636,
    name: "\u5316\u5fb7\u53bf",
    code: "150922",
    level: "district",
  },
  {
    id: 642,
    pid: 636,
    name: "\u5bdf\u54c8\u5c14\u53f3\u7ffc\u540e\u65d7",
    code: "150928",
    level: "district",
  },
  {
    id: 643,
    pid: 636,
    name: "\u5353\u8d44\u53bf",
    code: "150921",
    level: "district",
  },
  {
    id: 644,
    pid: 636,
    name: "\u4e30\u9547\u5e02",
    code: "150981",
    level: "district",
  },
  {
    id: 645,
    pid: 636,
    name: "\u5bdf\u54c8\u5c14\u53f3\u7ffc\u524d\u65d7",
    code: "150926",
    level: "district",
  },
  {
    id: 646,
    pid: 636,
    name: "\u96c6\u5b81\u533a",
    code: "150902",
    level: "district",
  },
  {
    id: 647,
    pid: 636,
    name: "\u51c9\u57ce\u53bf",
    code: "150925",
    level: "district",
  },
  {
    id: 648,
    pid: 0,
    name: "\u65b0\u7586\u7ef4\u543e\u5c14\u81ea\u6cbb\u533a",
    code: "650000",
    level: "province",
  },
  {
    id: 649,
    pid: 648,
    name: "\u53cc\u6cb3\u5e02",
    code: "659007",
    level: "city",
  },
  {
    id: 655,
    pid: 648,
    name: "\u5317\u5c6f\u5e02",
    code: "659005",
    level: "city",
  },
  {
    id: 660,
    pid: 648,
    name: "\u535a\u5c14\u5854\u62c9\u8499\u53e4\u81ea\u6cbb\u5dde",
    code: "652700",
    level: "city",
  },
  {
    id: 661,
    pid: 660,
    name: "\u6e29\u6cc9\u53bf",
    code: "652723",
    level: "district",
  },
  {
    id: 662,
    pid: 660,
    name: "\u963f\u62c9\u5c71\u53e3\u5e02",
    code: "652702",
    level: "district",
  },
  {
    id: 663,
    pid: 660,
    name: "\u535a\u4e50\u5e02",
    code: "652701",
    level: "district",
  },
  {
    id: 664,
    pid: 660,
    name: "\u7cbe\u6cb3\u53bf",
    code: "652722",
    level: "district",
  },
  {
    id: 665,
    pid: 648,
    name: "\u94c1\u95e8\u5173\u5e02",
    code: "659006",
    level: "city",
  },
  {
    id: 668,
    pid: 648,
    name: "\u53ef\u514b\u8fbe\u62c9\u5e02",
    code: "659008",
    level: "city",
  },
  {
    id: 675,
    pid: 648,
    name: "\u4e4c\u9c81\u6728\u9f50\u5e02",
    code: "650100",
    level: "city",
  },
  {
    id: 676,
    pid: 675,
    name: "\u8fbe\u5742\u57ce\u533a",
    code: "650107",
    level: "district",
  },
  {
    id: 677,
    pid: 675,
    name: "\u5929\u5c71\u533a",
    code: "650102",
    level: "district",
  },
  {
    id: 678,
    pid: 675,
    name: "\u6c34\u78e8\u6c9f\u533a",
    code: "650105",
    level: "district",
  },
  {
    id: 679,
    pid: 675,
    name: "\u7c73\u4e1c\u533a",
    code: "650109",
    level: "district",
  },
  {
    id: 680,
    pid: 675,
    name: "\u5934\u5c6f\u6cb3\u533a",
    code: "650106",
    level: "district",
  },
  {
    id: 681,
    pid: 675,
    name: "\u4e4c\u9c81\u6728\u9f50\u53bf",
    code: "650121",
    level: "district",
  },
  {
    id: 682,
    pid: 675,
    name: "\u6c99\u4f9d\u5df4\u514b\u533a",
    code: "650103",
    level: "district",
  },
  {
    id: 683,
    pid: 675,
    name: "\u65b0\u5e02\u533a",
    code: "650104",
    level: "district",
  },
  {
    id: 684,
    pid: 648,
    name: "\u6606\u7389\u5e02",
    code: "659009",
    level: "city",
  },
  {
    id: 696,
    pid: 648,
    name: "\u5df4\u97f3\u90ed\u695e\u8499\u53e4\u81ea\u6cbb\u5dde",
    code: "652800",
    level: "city",
  },
  {
    id: 697,
    pid: 696,
    name: "\u548c\u7855\u53bf",
    code: "652828",
    level: "district",
  },
  {
    id: 698,
    pid: 696,
    name: "\u548c\u9759\u53bf",
    code: "652827",
    level: "district",
  },
  {
    id: 699,
    pid: 696,
    name: "\u82e5\u7f8c\u53bf",
    code: "652824",
    level: "district",
  },
  {
    id: 700,
    pid: 696,
    name: "\u7109\u8006\u56de\u65cf\u81ea\u6cbb\u53bf",
    code: "652826",
    level: "district",
  },
  {
    id: 701,
    pid: 696,
    name: "\u535a\u6e56\u53bf",
    code: "652829",
    level: "district",
  },
  {
    id: 702,
    pid: 696,
    name: "\u4e14\u672b\u53bf",
    code: "652825",
    level: "district",
  },
  {
    id: 703,
    pid: 696,
    name: "\u5e93\u5c14\u52d2\u5e02",
    code: "652801",
    level: "district",
  },
  {
    id: 704,
    pid: 696,
    name: "\u8f6e\u53f0\u53bf",
    code: "652822",
    level: "district",
  },
  {
    id: 705,
    pid: 696,
    name: "\u5c09\u7281\u53bf",
    code: "652823",
    level: "district",
  },
  {
    id: 706,
    pid: 648,
    name: "\u548c\u7530\u5730\u533a",
    code: "653200",
    level: "city",
  },
  {
    id: 707,
    pid: 706,
    name: "\u6c11\u4e30\u53bf",
    code: "653227",
    level: "district",
  },
  {
    id: 708,
    pid: 706,
    name: "\u4e8e\u7530\u53bf",
    code: "653226",
    level: "district",
  },
  {
    id: 709,
    pid: 706,
    name: "\u548c\u7530\u5e02",
    code: "653201",
    level: "district",
  },
  {
    id: 710,
    pid: 706,
    name: "\u548c\u7530\u53bf",
    code: "653221",
    level: "district",
  },
  {
    id: 711,
    pid: 706,
    name: "\u7b56\u52d2\u53bf",
    code: "653225",
    level: "district",
  },
  {
    id: 712,
    pid: 706,
    name: "\u76ae\u5c71\u53bf",
    code: "653223",
    level: "district",
  },
  {
    id: 713,
    pid: 706,
    name: "\u6d1b\u6d66\u53bf",
    code: "653224",
    level: "district",
  },
  {
    id: 714,
    pid: 706,
    name: "\u58a8\u7389\u53bf",
    code: "653222",
    level: "district",
  },
  {
    id: 715,
    pid: 648,
    name: "\u5854\u57ce\u5730\u533a",
    code: "654200",
    level: "city",
  },
  {
    id: 716,
    pid: 715,
    name: "\u5854\u57ce\u5e02",
    code: "654201",
    level: "district",
  },
  {
    id: 717,
    pid: 715,
    name: "\u989d\u654f\u53bf",
    code: "654221",
    level: "district",
  },
  {
    id: 718,
    pid: 715,
    name: "\u548c\u5e03\u514b\u8d5b\u5c14\u8499\u53e4\u81ea\u6cbb\u53bf",
    code: "654226",
    level: "district",
  },
  {
    id: 719,
    pid: 715,
    name: "\u88d5\u6c11\u53bf",
    code: "654225",
    level: "district",
  },
  {
    id: 720,
    pid: 715,
    name: "\u6258\u91cc\u53bf",
    code: "654224",
    level: "district",
  },
  {
    id: 721,
    pid: 715,
    name: "\u4e4c\u82cf\u5e02",
    code: "654202",
    level: "district",
  },
  {
    id: 722,
    pid: 715,
    name: "\u6c99\u6e7e\u53bf",
    code: "654223",
    level: "district",
  },
  {
    id: 723,
    pid: 648,
    name: "\u963f\u52d2\u6cf0\u5730\u533a",
    code: "654300",
    level: "city",
  },
  {
    id: 724,
    pid: 723,
    name: "\u5e03\u5c14\u6d25\u53bf",
    code: "654321",
    level: "district",
  },
  {
    id: 725,
    pid: 723,
    name: "\u54c8\u5df4\u6cb3\u53bf",
    code: "654324",
    level: "district",
  },
  {
    id: 726,
    pid: 723,
    name: "\u5409\u6728\u4e43\u53bf",
    code: "654326",
    level: "district",
  },
  {
    id: 727,
    pid: 723,
    name: "\u9752\u6cb3\u53bf",
    code: "654325",
    level: "district",
  },
  {
    id: 728,
    pid: 723,
    name: "\u5bcc\u8574\u53bf",
    code: "654322",
    level: "district",
  },
  {
    id: 729,
    pid: 723,
    name: "\u963f\u52d2\u6cf0\u5e02",
    code: "654301",
    level: "district",
  },
  {
    id: 730,
    pid: 723,
    name: "\u798f\u6d77\u53bf",
    code: "654323",
    level: "district",
  },
  {
    id: 731,
    pid: 648,
    name: "\u660c\u5409\u56de\u65cf\u81ea\u6cbb\u5dde",
    code: "652300",
    level: "city",
  },
  {
    id: 732,
    pid: 731,
    name: "\u5947\u53f0\u53bf",
    code: "652325",
    level: "district",
  },
  {
    id: 733,
    pid: 731,
    name: "\u739b\u7eb3\u65af\u53bf",
    code: "652324",
    level: "district",
  },
  {
    id: 734,
    pid: 731,
    name: "\u547c\u56fe\u58c1\u53bf",
    code: "652323",
    level: "district",
  },
  {
    id: 735,
    pid: 731,
    name: "\u6728\u5792\u54c8\u8428\u514b\u81ea\u6cbb\u53bf",
    code: "652328",
    level: "district",
  },
  {
    id: 736,
    pid: 731,
    name: "\u961c\u5eb7\u5e02",
    code: "652302",
    level: "district",
  },
  {
    id: 737,
    pid: 731,
    name: "\u660c\u5409\u5e02",
    code: "652301",
    level: "district",
  },
  {
    id: 738,
    pid: 731,
    name: "\u5409\u6728\u8428\u5c14\u53bf",
    code: "652327",
    level: "district",
  },
  {
    id: 739,
    pid: 648,
    name: "\u77f3\u6cb3\u5b50\u5e02",
    code: "659001",
    level: "city",
  },
  {
    id: 748,
    pid: 648,
    name: "\u4e94\u5bb6\u6e20\u5e02",
    code: "659004",
    level: "city",
  },
  {
    id: 755,
    pid: 648,
    name: "\u4f0a\u7281\u54c8\u8428\u514b\u81ea\u6cbb\u5dde",
    code: "654000",
    level: "city",
  },
  {
    id: 756,
    pid: 755,
    name: "\u594e\u5c6f\u5e02",
    code: "654003",
    level: "district",
  },
  {
    id: 757,
    pid: 755,
    name: "\u4f0a\u5b81\u53bf",
    code: "654021",
    level: "district",
  },
  {
    id: 758,
    pid: 755,
    name: "\u65b0\u6e90\u53bf",
    code: "654025",
    level: "district",
  },
  {
    id: 759,
    pid: 755,
    name: "\u5de9\u7559\u53bf",
    code: "654024",
    level: "district",
  },
  {
    id: 760,
    pid: 755,
    name: "\u5c3c\u52d2\u514b\u53bf",
    code: "654028",
    level: "district",
  },
  {
    id: 761,
    pid: 755,
    name: "\u7279\u514b\u65af\u53bf",
    code: "654027",
    level: "district",
  },
  {
    id: 762,
    pid: 755,
    name: "\u662d\u82cf\u53bf",
    code: "654026",
    level: "district",
  },
  {
    id: 763,
    pid: 755,
    name: "\u5bdf\u5e03\u67e5\u5c14\u9521\u4f2f\u81ea\u6cbb\u53bf",
    code: "654022",
    level: "district",
  },
  {
    id: 764,
    pid: 755,
    name: "\u970d\u57ce\u53bf",
    code: "654023",
    level: "district",
  },
  {
    id: 765,
    pid: 755,
    name: "\u970d\u5c14\u679c\u65af\u5e02",
    code: "654004",
    level: "district",
  },
  {
    id: 766,
    pid: 755,
    name: "\u4f0a\u5b81\u5e02",
    code: "654002",
    level: "district",
  },
  {
    id: 767,
    pid: 648,
    name: "\u963f\u62c9\u5c14\u5e02",
    code: "659002",
    level: "city",
  },
  {
    id: 787,
    pid: 648,
    name: "\u5410\u9c81\u756a\u5e02",
    code: "650400",
    level: "city",
  },
  {
    id: 788,
    pid: 787,
    name: "\u912f\u5584\u53bf",
    code: "650421",
    level: "district",
  },
  {
    id: 789,
    pid: 787,
    name: "\u6258\u514b\u900a\u53bf",
    code: "650422",
    level: "district",
  },
  {
    id: 790,
    pid: 787,
    name: "\u9ad8\u660c\u533a",
    code: "650402",
    level: "district",
  },
  {
    id: 791,
    pid: 648,
    name: "\u5580\u4ec0\u5730\u533a",
    code: "653100",
    level: "city",
  },
  {
    id: 792,
    pid: 791,
    name: "\u758f\u52d2\u53bf",
    code: "653122",
    level: "district",
  },
  {
    id: 793,
    pid: 791,
    name: "\u9ea6\u76d6\u63d0\u53bf",
    code: "653127",
    level: "district",
  },
  {
    id: 794,
    pid: 791,
    name: "\u838e\u8f66\u53bf",
    code: "653125",
    level: "district",
  },
  {
    id: 795,
    pid: 791,
    name: "\u4f3d\u5e08\u53bf",
    code: "653129",
    level: "district",
  },
  {
    id: 796,
    pid: 791,
    name: "\u5854\u4ec0\u5e93\u5c14\u5e72\u5854\u5409\u514b\u81ea\u6cbb\u53bf",
    code: "653131",
    level: "district",
  },
  {
    id: 797,
    pid: 791,
    name: "\u53f6\u57ce\u53bf",
    code: "653126",
    level: "district",
  },
  {
    id: 798,
    pid: 791,
    name: "\u6cfd\u666e\u53bf",
    code: "653124",
    level: "district",
  },
  {
    id: 799,
    pid: 791,
    name: "\u5df4\u695a\u53bf",
    code: "653130",
    level: "district",
  },
  {
    id: 800,
    pid: 791,
    name: "\u758f\u9644\u53bf",
    code: "653121",
    level: "district",
  },
  {
    id: 801,
    pid: 791,
    name: "\u82f1\u5409\u6c99\u53bf",
    code: "653123",
    level: "district",
  },
  {
    id: 802,
    pid: 791,
    name: "\u5580\u4ec0\u5e02",
    code: "653101",
    level: "district",
  },
  {
    id: 803,
    pid: 791,
    name: "\u5cb3\u666e\u6e56\u53bf",
    code: "653128",
    level: "district",
  },
  {
    id: 804,
    pid: 648,
    name: "\u54c8\u5bc6\u5e02",
    code: "650500",
    level: "city",
  },
  {
    id: 805,
    pid: 804,
    name: "\u4f0a\u5dde\u533a",
    code: "650502",
    level: "district",
  },
  {
    id: 806,
    pid: 804,
    name: "\u5df4\u91cc\u5764\u54c8\u8428\u514b\u81ea\u6cbb\u53bf",
    code: "650521",
    level: "district",
  },
  {
    id: 807,
    pid: 804,
    name: "\u4f0a\u543e\u53bf",
    code: "650522",
    level: "district",
  },
  {
    id: 808,
    pid: 648,
    name: "\u56fe\u6728\u8212\u514b\u5e02",
    code: "659003",
    level: "city",
  },
  {
    id: 819,
    pid: 648,
    name: "\u514b\u5b5c\u52d2\u82cf\u67ef\u5c14\u514b\u5b5c\u81ea\u6cbb\u5dde",
    code: "653000",
    level: "city",
  },
  {
    id: 820,
    pid: 819,
    name: "\u4e4c\u6070\u53bf",
    code: "653024",
    level: "district",
  },
  {
    id: 821,
    pid: 819,
    name: "\u963f\u56fe\u4ec0\u5e02",
    code: "653001",
    level: "district",
  },
  {
    id: 822,
    pid: 819,
    name: "\u963f\u5408\u5947\u53bf",
    code: "653023",
    level: "district",
  },
  {
    id: 823,
    pid: 819,
    name: "\u963f\u514b\u9676\u53bf",
    code: "653022",
    level: "district",
  },
  {
    id: 824,
    pid: 648,
    name: "\u963f\u514b\u82cf\u5730\u533a",
    code: "652900",
    level: "city",
  },
  {
    id: 825,
    pid: 824,
    name: "\u6e29\u5bbf\u53bf",
    code: "652922",
    level: "district",
  },
  {
    id: 826,
    pid: 824,
    name: "\u963f\u514b\u82cf\u5e02",
    code: "652901",
    level: "district",
  },
  {
    id: 827,
    pid: 824,
    name: "\u5e93\u8f66\u5e02",
    code: "652902",
    level: "district",
  },
  {
    id: 828,
    pid: 824,
    name: "\u6c99\u96c5\u53bf",
    code: "652924",
    level: "district",
  },
  {
    id: 829,
    pid: 824,
    name: "\u62dc\u57ce\u53bf",
    code: "652926",
    level: "district",
  },
  {
    id: 830,
    pid: 824,
    name: "\u67ef\u576a\u53bf",
    code: "652929",
    level: "district",
  },
  {
    id: 831,
    pid: 824,
    name: "\u65b0\u548c\u53bf",
    code: "652925",
    level: "district",
  },
  {
    id: 832,
    pid: 824,
    name: "\u963f\u74e6\u63d0\u53bf",
    code: "652928",
    level: "district",
  },
  {
    id: 833,
    pid: 824,
    name: "\u4e4c\u4ec0\u53bf",
    code: "652927",
    level: "district",
  },
  {
    id: 834,
    pid: 648,
    name: "\u514b\u62c9\u739b\u4f9d\u5e02",
    code: "650200",
    level: "city",
  },
  {
    id: 835,
    pid: 834,
    name: "\u767d\u78b1\u6ee9\u533a",
    code: "650204",
    level: "district",
  },
  {
    id: 836,
    pid: 834,
    name: "\u514b\u62c9\u739b\u4f9d\u533a",
    code: "650203",
    level: "district",
  },
  {
    id: 837,
    pid: 834,
    name: "\u4e4c\u5c14\u79be\u533a",
    code: "650205",
    level: "district",
  },
  {
    id: 838,
    pid: 834,
    name: "\u72ec\u5c71\u5b50\u533a",
    code: "650202",
    level: "district",
  },
  {
    id: 839,
    pid: 648,
    name: "\u80e1\u6768\u6cb3\u5e02",
    code: "659010",
    level: "city",
  },
  {
    id: 844,
    pid: 0,
    name: "\u8fbd\u5b81\u7701",
    code: "210000",
    level: "province",
  },
  {
    id: 845,
    pid: 844,
    name: "\u9526\u5dde\u5e02",
    code: "210700",
    level: "city",
  },
  {
    id: 846,
    pid: 845,
    name: "\u4e49\u53bf",
    code: "210727",
    level: "district",
  },
  {
    id: 847,
    pid: 845,
    name: "\u9ed1\u5c71\u53bf",
    code: "210726",
    level: "district",
  },
  {
    id: 848,
    pid: 845,
    name: "\u51cc\u6d77\u5e02",
    code: "210781",
    level: "district",
  },
  {
    id: 849,
    pid: 845,
    name: "\u592a\u548c\u533a",
    code: "210711",
    level: "district",
  },
  {
    id: 850,
    pid: 845,
    name: "\u5317\u9547\u5e02",
    code: "210782",
    level: "district",
  },
  {
    id: 851,
    pid: 845,
    name: "\u51cc\u6cb3\u533a",
    code: "210703",
    level: "district",
  },
  {
    id: 852,
    pid: 845,
    name: "\u53e4\u5854\u533a",
    code: "210702",
    level: "district",
  },
  {
    id: 853,
    pid: 844,
    name: "\u5927\u8fde\u5e02",
    code: "210200",
    level: "city",
  },
  {
    id: 854,
    pid: 853,
    name: "\u65c5\u987a\u53e3\u533a",
    code: "210212",
    level: "district",
  },
  {
    id: 855,
    pid: 853,
    name: "\u91d1\u5dde\u533a",
    code: "210213",
    level: "district",
  },
  {
    id: 856,
    pid: 853,
    name: "\u74e6\u623f\u5e97\u5e02",
    code: "210281",
    level: "district",
  },
  {
    id: 857,
    pid: 853,
    name: "\u957f\u6d77\u53bf",
    code: "210224",
    level: "district",
  },
  {
    id: 858,
    pid: 853,
    name: "\u7518\u4e95\u5b50\u533a",
    code: "210211",
    level: "district",
  },
  {
    id: 859,
    pid: 853,
    name: "\u666e\u5170\u5e97\u533a",
    code: "210214",
    level: "district",
  },
  {
    id: 860,
    pid: 853,
    name: "\u5e84\u6cb3\u5e02",
    code: "210283",
    level: "district",
  },
  {
    id: 861,
    pid: 853,
    name: "\u4e2d\u5c71\u533a",
    code: "210202",
    level: "district",
  },
  {
    id: 862,
    pid: 853,
    name: "\u897f\u5c97\u533a",
    code: "210203",
    level: "district",
  },
  {
    id: 863,
    pid: 853,
    name: "\u6c99\u6cb3\u53e3\u533a",
    code: "210204",
    level: "district",
  },
  {
    id: 864,
    pid: 844,
    name: "\u4e39\u4e1c\u5e02",
    code: "210600",
    level: "city",
  },
  {
    id: 865,
    pid: 864,
    name: "\u51e4\u57ce\u5e02",
    code: "210682",
    level: "district",
  },
  {
    id: 866,
    pid: 864,
    name: "\u632f\u5b89\u533a",
    code: "210604",
    level: "district",
  },
  {
    id: 867,
    pid: 864,
    name: "\u4e1c\u6e2f\u5e02",
    code: "210681",
    level: "district",
  },
  {
    id: 868,
    pid: 864,
    name: "\u632f\u5174\u533a",
    code: "210603",
    level: "district",
  },
  {
    id: 869,
    pid: 864,
    name: "\u5bbd\u7538\u6ee1\u65cf\u81ea\u6cbb\u53bf",
    code: "210624",
    level: "district",
  },
  {
    id: 870,
    pid: 864,
    name: "\u5143\u5b9d\u533a",
    code: "210602",
    level: "district",
  },
  {
    id: 871,
    pid: 844,
    name: "\u629a\u987a\u5e02",
    code: "210400",
    level: "city",
  },
  {
    id: 872,
    pid: 871,
    name: "\u6e05\u539f\u6ee1\u65cf\u81ea\u6cbb\u53bf",
    code: "210423",
    level: "district",
  },
  {
    id: 873,
    pid: 871,
    name: "\u65b0\u5bbe\u6ee1\u65cf\u81ea\u6cbb\u53bf",
    code: "210422",
    level: "district",
  },
  {
    id: 874,
    pid: 871,
    name: "\u987a\u57ce\u533a",
    code: "210411",
    level: "district",
  },
  {
    id: 875,
    pid: 871,
    name: "\u4e1c\u6d32\u533a",
    code: "210403",
    level: "district",
  },
  {
    id: 876,
    pid: 871,
    name: "\u65b0\u629a\u533a",
    code: "210402",
    level: "district",
  },
  {
    id: 877,
    pid: 871,
    name: "\u671b\u82b1\u533a",
    code: "210404",
    level: "district",
  },
  {
    id: 878,
    pid: 871,
    name: "\u629a\u987a\u53bf",
    code: "210421",
    level: "district",
  },
  {
    id: 879,
    pid: 844,
    name: "\u846b\u82a6\u5c9b\u5e02",
    code: "211400",
    level: "city",
  },
  {
    id: 880,
    pid: 879,
    name: "\u9f99\u6e2f\u533a",
    code: "211403",
    level: "district",
  },
  {
    id: 881,
    pid: 879,
    name: "\u5efa\u660c\u53bf",
    code: "211422",
    level: "district",
  },
  {
    id: 882,
    pid: 879,
    name: "\u5174\u57ce\u5e02",
    code: "211481",
    level: "district",
  },
  {
    id: 883,
    pid: 879,
    name: "\u7ee5\u4e2d\u53bf",
    code: "211421",
    level: "district",
  },
  {
    id: 884,
    pid: 879,
    name: "\u8fde\u5c71\u533a",
    code: "211402",
    level: "district",
  },
  {
    id: 885,
    pid: 879,
    name: "\u5357\u7968\u533a",
    code: "211404",
    level: "district",
  },
  {
    id: 886,
    pid: 844,
    name: "\u94c1\u5cad\u5e02",
    code: "211200",
    level: "city",
  },
  {
    id: 887,
    pid: 886,
    name: "\u5f00\u539f\u5e02",
    code: "211282",
    level: "district",
  },
  {
    id: 888,
    pid: 886,
    name: "\u8c03\u5175\u5c71\u5e02",
    code: "211281",
    level: "district",
  },
  {
    id: 889,
    pid: 886,
    name: "\u6e05\u6cb3\u533a",
    code: "211204",
    level: "district",
  },
  {
    id: 890,
    pid: 886,
    name: "\u94f6\u5dde\u533a",
    code: "211202",
    level: "district",
  },
  {
    id: 891,
    pid: 886,
    name: "\u897f\u4e30\u53bf",
    code: "211223",
    level: "district",
  },
  {
    id: 892,
    pid: 886,
    name: "\u94c1\u5cad\u53bf",
    code: "211221",
    level: "district",
  },
  {
    id: 893,
    pid: 886,
    name: "\u660c\u56fe\u53bf",
    code: "211224",
    level: "district",
  },
  {
    id: 894,
    pid: 844,
    name: "\u6c88\u9633\u5e02",
    code: "210100",
    level: "city",
  },
  {
    id: 895,
    pid: 894,
    name: "\u65b0\u6c11\u5e02",
    code: "210181",
    level: "district",
  },
  {
    id: 896,
    pid: 894,
    name: "\u82cf\u5bb6\u5c6f\u533a",
    code: "210111",
    level: "district",
  },
  {
    id: 897,
    pid: 894,
    name: "\u548c\u5e73\u533a",
    code: "210102",
    level: "district",
  },
  {
    id: 898,
    pid: 894,
    name: "\u7687\u59d1\u533a",
    code: "210105",
    level: "district",
  },
  {
    id: 899,
    pid: 894,
    name: "\u4e8e\u6d2a\u533a",
    code: "210114",
    level: "district",
  },
  {
    id: 900,
    pid: 894,
    name: "\u5eb7\u5e73\u53bf",
    code: "210123",
    level: "district",
  },
  {
    id: 901,
    pid: 894,
    name: "\u6cd5\u5e93\u53bf",
    code: "210124",
    level: "district",
  },
  {
    id: 902,
    pid: 894,
    name: "\u6c88\u6cb3\u533a",
    code: "210103",
    level: "district",
  },
  {
    id: 903,
    pid: 894,
    name: "\u5927\u4e1c\u533a",
    code: "210104",
    level: "district",
  },
  {
    id: 904,
    pid: 894,
    name: "\u94c1\u897f\u533a",
    code: "210106",
    level: "district",
  },
  {
    id: 905,
    pid: 894,
    name: "\u8fbd\u4e2d\u533a",
    code: "210115",
    level: "district",
  },
  {
    id: 906,
    pid: 894,
    name: "\u6c88\u5317\u65b0\u533a",
    code: "210113",
    level: "district",
  },
  {
    id: 907,
    pid: 894,
    name: "\u6d51\u5357\u533a",
    code: "210112",
    level: "district",
  },
  {
    id: 908,
    pid: 844,
    name: "\u8425\u53e3\u5e02",
    code: "210800",
    level: "city",
  },
  {
    id: 909,
    pid: 908,
    name: "\u5927\u77f3\u6865\u5e02",
    code: "210882",
    level: "district",
  },
  {
    id: 910,
    pid: 908,
    name: "\u9c85\u9c7c\u5708\u533a",
    code: "210804",
    level: "district",
  },
  {
    id: 911,
    pid: 908,
    name: "\u76d6\u5dde\u5e02",
    code: "210881",
    level: "district",
  },
  {
    id: 912,
    pid: 908,
    name: "\u897f\u5e02\u533a",
    code: "210803",
    level: "district",
  },
  {
    id: 913,
    pid: 908,
    name: "\u7ad9\u524d\u533a",
    code: "210802",
    level: "district",
  },
  {
    id: 914,
    pid: 908,
    name: "\u8001\u8fb9\u533a",
    code: "210811",
    level: "district",
  },
  {
    id: 915,
    pid: 844,
    name: "\u76d8\u9526\u5e02",
    code: "211100",
    level: "city",
  },
  {
    id: 916,
    pid: 915,
    name: "\u5174\u9686\u53f0\u533a",
    code: "211103",
    level: "district",
  },
  {
    id: 917,
    pid: 915,
    name: "\u76d8\u5c71\u53bf",
    code: "211122",
    level: "district",
  },
  {
    id: 918,
    pid: 915,
    name: "\u5927\u6d3c\u533a",
    code: "211104",
    level: "district",
  },
  {
    id: 919,
    pid: 915,
    name: "\u53cc\u53f0\u5b50\u533a",
    code: "211102",
    level: "district",
  },
  {
    id: 920,
    pid: 844,
    name: "\u961c\u65b0\u5e02",
    code: "210900",
    level: "city",
  },
  {
    id: 921,
    pid: 920,
    name: "\u6e05\u6cb3\u95e8\u533a",
    code: "210905",
    level: "district",
  },
  {
    id: 922,
    pid: 920,
    name: "\u592a\u5e73\u533a",
    code: "210904",
    level: "district",
  },
  {
    id: 923,
    pid: 920,
    name: "\u961c\u65b0\u8499\u53e4\u65cf\u81ea\u6cbb\u53bf",
    code: "210921",
    level: "district",
  },
  {
    id: 924,
    pid: 920,
    name: "\u65b0\u90b1\u533a",
    code: "210903",
    level: "district",
  },
  {
    id: 925,
    pid: 920,
    name: "\u5f70\u6b66\u53bf",
    code: "210922",
    level: "district",
  },
  {
    id: 926,
    pid: 920,
    name: "\u6d77\u5dde\u533a",
    code: "210902",
    level: "district",
  },
  {
    id: 927,
    pid: 920,
    name: "\u7ec6\u6cb3\u533a",
    code: "210911",
    level: "district",
  },
  {
    id: 928,
    pid: 844,
    name: "\u672c\u6eaa\u5e02",
    code: "210500",
    level: "city",
  },
  {
    id: 929,
    pid: 928,
    name: "\u6853\u4ec1\u6ee1\u65cf\u81ea\u6cbb\u53bf",
    code: "210522",
    level: "district",
  },
  {
    id: 930,
    pid: 928,
    name: "\u6eaa\u6e56\u533a",
    code: "210503",
    level: "district",
  },
  {
    id: 931,
    pid: 928,
    name: "\u5357\u82ac\u533a",
    code: "210505",
    level: "district",
  },
  {
    id: 932,
    pid: 928,
    name: "\u5e73\u5c71\u533a",
    code: "210502",
    level: "district",
  },
  {
    id: 933,
    pid: 928,
    name: "\u660e\u5c71\u533a",
    code: "210504",
    level: "district",
  },
  {
    id: 934,
    pid: 928,
    name: "\u672c\u6eaa\u6ee1\u65cf\u81ea\u6cbb\u53bf",
    code: "210521",
    level: "district",
  },
  {
    id: 935,
    pid: 844,
    name: "\u8fbd\u9633\u5e02",
    code: "211000",
    level: "city",
  },
  {
    id: 936,
    pid: 935,
    name: "\u5f13\u957f\u5cad\u533a",
    code: "211005",
    level: "district",
  },
  {
    id: 937,
    pid: 935,
    name: "\u706f\u5854\u5e02",
    code: "211081",
    level: "district",
  },
  {
    id: 938,
    pid: 935,
    name: "\u8fbd\u9633\u53bf",
    code: "211021",
    level: "district",
  },
  {
    id: 939,
    pid: 935,
    name: "\u767d\u5854\u533a",
    code: "211002",
    level: "district",
  },
  {
    id: 940,
    pid: 935,
    name: "\u6587\u5723\u533a",
    code: "211003",
    level: "district",
  },
  {
    id: 941,
    pid: 935,
    name: "\u592a\u5b50\u6cb3\u533a",
    code: "211011",
    level: "district",
  },
  {
    id: 942,
    pid: 935,
    name: "\u5b8f\u4f1f\u533a",
    code: "211004",
    level: "district",
  },
  {
    id: 943,
    pid: 844,
    name: "\u978d\u5c71\u5e02",
    code: "210300",
    level: "city",
  },
  {
    id: 944,
    pid: 943,
    name: "\u53f0\u5b89\u53bf",
    code: "210321",
    level: "district",
  },
  {
    id: 945,
    pid: 943,
    name: "\u5cab\u5ca9\u6ee1\u65cf\u81ea\u6cbb\u53bf",
    code: "210323",
    level: "district",
  },
  {
    id: 946,
    pid: 943,
    name: "\u5343\u5c71\u533a",
    code: "210311",
    level: "district",
  },
  {
    id: 947,
    pid: 943,
    name: "\u94c1\u897f\u533a",
    code: "210303",
    level: "district",
  },
  {
    id: 948,
    pid: 943,
    name: "\u94c1\u4e1c\u533a",
    code: "210302",
    level: "district",
  },
  {
    id: 949,
    pid: 943,
    name: "\u7acb\u5c71\u533a",
    code: "210304",
    level: "district",
  },
  {
    id: 950,
    pid: 943,
    name: "\u6d77\u57ce\u5e02",
    code: "210381",
    level: "district",
  },
  {
    id: 951,
    pid: 844,
    name: "\u671d\u9633\u5e02",
    code: "211300",
    level: "city",
  },
  {
    id: 952,
    pid: 951,
    name: "\u5efa\u5e73\u53bf",
    code: "211322",
    level: "district",
  },
  {
    id: 953,
    pid: 951,
    name: "\u5317\u7968\u5e02",
    code: "211381",
    level: "district",
  },
  {
    id: 954,
    pid: 951,
    name: "\u671d\u9633\u53bf",
    code: "211321",
    level: "district",
  },
  {
    id: 955,
    pid: 951,
    name: "\u53cc\u5854\u533a",
    code: "211302",
    level: "district",
  },
  {
    id: 956,
    pid: 951,
    name: "\u9f99\u57ce\u533a",
    code: "211303",
    level: "district",
  },
  {
    id: 957,
    pid: 951,
    name: "\u5580\u5587\u6c81\u5de6\u7ffc\u8499\u53e4\u65cf\u81ea\u6cbb\u53bf",
    code: "211324",
    level: "district",
  },
  {
    id: 958,
    pid: 951,
    name: "\u51cc\u6e90\u5e02",
    code: "211382",
    level: "district",
  },
  {
    id: 959,
    pid: 0,
    name: "\u6e56\u5317\u7701",
    code: "420000",
    level: "province",
  },
  {
    id: 960,
    pid: 959,
    name: "\u5341\u5830\u5e02",
    code: "420300",
    level: "city",
  },
  {
    id: 961,
    pid: 960,
    name: "\u4e39\u6c5f\u53e3\u5e02",
    code: "420381",
    level: "district",
  },
  {
    id: 962,
    pid: 960,
    name: "\u90e7\u9633\u533a",
    code: "420304",
    level: "district",
  },
  {
    id: 963,
    pid: 960,
    name: "\u5f20\u6e7e\u533a",
    code: "420303",
    level: "district",
  },
  {
    id: 964,
    pid: 960,
    name: "\u8305\u7bad\u533a",
    code: "420302",
    level: "district",
  },
  {
    id: 965,
    pid: 960,
    name: "\u7af9\u6eaa\u53bf",
    code: "420324",
    level: "district",
  },
  {
    id: 966,
    pid: 960,
    name: "\u7af9\u5c71\u53bf",
    code: "420323",
    level: "district",
  },
  {
    id: 967,
    pid: 960,
    name: "\u623f\u53bf",
    code: "420325",
    level: "district",
  },
  {
    id: 968,
    pid: 960,
    name: "\u90e7\u897f\u53bf",
    code: "420322",
    level: "district",
  },
  {
    id: 969,
    pid: 959,
    name: "\u8944\u9633\u5e02",
    code: "420600",
    level: "city",
  },
  {
    id: 970,
    pid: 969,
    name: "\u5b9c\u57ce\u5e02",
    code: "420684",
    level: "district",
  },
  {
    id: 971,
    pid: 969,
    name: "\u4fdd\u5eb7\u53bf",
    code: "420626",
    level: "district",
  },
  {
    id: 972,
    pid: 969,
    name: "\u67a3\u9633\u5e02",
    code: "420683",
    level: "district",
  },
  {
    id: 973,
    pid: 969,
    name: "\u8c37\u57ce\u53bf",
    code: "420625",
    level: "district",
  },
  {
    id: 974,
    pid: 969,
    name: "\u5357\u6f33\u53bf",
    code: "420624",
    level: "district",
  },
  {
    id: 975,
    pid: 969,
    name: "\u8001\u6cb3\u53e3\u5e02",
    code: "420682",
    level: "district",
  },
  {
    id: 976,
    pid: 969,
    name: "\u8944\u57ce\u533a",
    code: "420602",
    level: "district",
  },
  {
    id: 977,
    pid: 969,
    name: "\u6a0a\u57ce\u533a",
    code: "420606",
    level: "district",
  },
  {
    id: 978,
    pid: 969,
    name: "\u8944\u5dde\u533a",
    code: "420607",
    level: "district",
  },
  {
    id: 979,
    pid: 959,
    name: "\u5b9c\u660c\u5e02",
    code: "420500",
    level: "city",
  },
  {
    id: 980,
    pid: 979,
    name: "\u8fdc\u5b89\u53bf",
    code: "420525",
    level: "district",
  },
  {
    id: 981,
    pid: 979,
    name: "\u5174\u5c71\u53bf",
    code: "420526",
    level: "district",
  },
  {
    id: 982,
    pid: 979,
    name: "\u5f53\u9633\u5e02",
    code: "420582",
    level: "district",
  },
  {
    id: 983,
    pid: 979,
    name: "\u79ed\u5f52\u53bf",
    code: "420527",
    level: "district",
  },
  {
    id: 984,
    pid: 979,
    name: "\u957f\u9633\u571f\u5bb6\u65cf\u81ea\u6cbb\u53bf",
    code: "420528",
    level: "district",
  },
  {
    id: 985,
    pid: 979,
    name: "\u70b9\u519b\u533a",
    code: "420504",
    level: "district",
  },
  {
    id: 986,
    pid: 979,
    name: "\u7307\u4ead\u533a",
    code: "420505",
    level: "district",
  },
  {
    id: 987,
    pid: 979,
    name: "\u4f0d\u5bb6\u5c97\u533a",
    code: "420503",
    level: "district",
  },
  {
    id: 988,
    pid: 979,
    name: "\u5b9c\u90fd\u5e02",
    code: "420581",
    level: "district",
  },
  {
    id: 989,
    pid: 979,
    name: "\u4e94\u5cf0\u571f\u5bb6\u65cf\u81ea\u6cbb\u53bf",
    code: "420529",
    level: "district",
  },
  {
    id: 990,
    pid: 979,
    name: "\u679d\u6c5f\u5e02",
    code: "420583",
    level: "district",
  },
  {
    id: 991,
    pid: 979,
    name: "\u5937\u9675\u533a",
    code: "420506",
    level: "district",
  },
  {
    id: 992,
    pid: 979,
    name: "\u897f\u9675\u533a",
    code: "420502",
    level: "district",
  },
  {
    id: 993,
    pid: 959,
    name: "\u8346\u95e8\u5e02",
    code: "420800",
    level: "city",
  },
  {
    id: 994,
    pid: 993,
    name: "\u4e1c\u5b9d\u533a",
    code: "420802",
    level: "district",
  },
  {
    id: 995,
    pid: 993,
    name: "\u949f\u7965\u5e02",
    code: "420881",
    level: "district",
  },
  {
    id: 996,
    pid: 993,
    name: "\u4eac\u5c71\u5e02",
    code: "420882",
    level: "district",
  },
  {
    id: 997,
    pid: 993,
    name: "\u6387\u5200\u533a",
    code: "420804",
    level: "district",
  },
  {
    id: 998,
    pid: 993,
    name: "\u6c99\u6d0b\u53bf",
    code: "420822",
    level: "district",
  },
  {
    id: 999,
    pid: 959,
    name: "\u9ec4\u5188\u5e02",
    code: "421100",
    level: "city",
  },
  {
    id: 1000,
    pid: 999,
    name: "\u9ebb\u57ce\u5e02",
    code: "421181",
    level: "district",
  },
  {
    id: 1001,
    pid: 999,
    name: "\u56e2\u98ce\u53bf",
    code: "421121",
    level: "district",
  },
  {
    id: 1002,
    pid: 999,
    name: "\u9ec4\u5dde\u533a",
    code: "421102",
    level: "district",
  },
  {
    id: 1003,
    pid: 999,
    name: "\u8572\u6625\u53bf",
    code: "421126",
    level: "district",
  },
  {
    id: 1004,
    pid: 999,
    name: "\u6d60\u6c34\u53bf",
    code: "421125",
    level: "district",
  },
  {
    id: 1005,
    pid: 999,
    name: "\u82f1\u5c71\u53bf",
    code: "421124",
    level: "district",
  },
  {
    id: 1006,
    pid: 999,
    name: "\u7ea2\u5b89\u53bf",
    code: "421122",
    level: "district",
  },
  {
    id: 1007,
    pid: 999,
    name: "\u9ec4\u6885\u53bf",
    code: "421127",
    level: "district",
  },
  {
    id: 1008,
    pid: 999,
    name: "\u6b66\u7a74\u5e02",
    code: "421182",
    level: "district",
  },
  {
    id: 1009,
    pid: 999,
    name: "\u7f57\u7530\u53bf",
    code: "421123",
    level: "district",
  },
  {
    id: 1010,
    pid: 959,
    name: "\u5929\u95e8\u5e02",
    code: "429006",
    level: "city",
  },
  {
    id: 1039,
    pid: 959,
    name: "\u5b5d\u611f\u5e02",
    code: "420900",
    level: "city",
  },
  {
    id: 1040,
    pid: 1039,
    name: "\u4e91\u68a6\u53bf",
    code: "420923",
    level: "district",
  },
  {
    id: 1041,
    pid: 1039,
    name: "\u5e94\u57ce\u5e02",
    code: "420981",
    level: "district",
  },
  {
    id: 1042,
    pid: 1039,
    name: "\u5b5d\u5357\u533a",
    code: "420902",
    level: "district",
  },
  {
    id: 1043,
    pid: 1039,
    name: "\u6c49\u5ddd\u5e02",
    code: "420984",
    level: "district",
  },
  {
    id: 1044,
    pid: 1039,
    name: "\u5b5d\u660c\u53bf",
    code: "420921",
    level: "district",
  },
  {
    id: 1045,
    pid: 1039,
    name: "\u5927\u609f\u53bf",
    code: "420922",
    level: "district",
  },
  {
    id: 1046,
    pid: 1039,
    name: "\u5b89\u9646\u5e02",
    code: "420982",
    level: "district",
  },
  {
    id: 1047,
    pid: 959,
    name: "\u6f5c\u6c5f\u5e02",
    code: "429005",
    level: "city",
  },
  {
    id: 1073,
    pid: 959,
    name: "\u6069\u65bd\u571f\u5bb6\u65cf\u82d7\u65cf\u81ea\u6cbb\u5dde",
    code: "422800",
    level: "city",
  },
  {
    id: 1074,
    pid: 1073,
    name: "\u5efa\u59cb\u53bf",
    code: "422822",
    level: "district",
  },
  {
    id: 1075,
    pid: 1073,
    name: "\u6069\u65bd\u5e02",
    code: "422801",
    level: "district",
  },
  {
    id: 1076,
    pid: 1073,
    name: "\u5229\u5ddd\u5e02",
    code: "422802",
    level: "district",
  },
  {
    id: 1077,
    pid: 1073,
    name: "\u9e64\u5cf0\u53bf",
    code: "422828",
    level: "district",
  },
  {
    id: 1078,
    pid: 1073,
    name: "\u6765\u51e4\u53bf",
    code: "422827",
    level: "district",
  },
  {
    id: 1079,
    pid: 1073,
    name: "\u5df4\u4e1c\u53bf",
    code: "422823",
    level: "district",
  },
  {
    id: 1080,
    pid: 1073,
    name: "\u5ba3\u6069\u53bf",
    code: "422825",
    level: "district",
  },
  {
    id: 1081,
    pid: 1073,
    name: "\u54b8\u4e30\u53bf",
    code: "422826",
    level: "district",
  },
  {
    id: 1082,
    pid: 959,
    name: "\u6b66\u6c49\u5e02",
    code: "420100",
    level: "city",
  },
  {
    id: 1083,
    pid: 1082,
    name: "\u9ec4\u9642\u533a",
    code: "420116",
    level: "district",
  },
  {
    id: 1084,
    pid: 1082,
    name: "\u65b0\u6d32\u533a",
    code: "420117",
    level: "district",
  },
  {
    id: 1085,
    pid: 1082,
    name: "\u8521\u7538\u533a",
    code: "420114",
    level: "district",
  },
  {
    id: 1086,
    pid: 1082,
    name: "\u6c49\u5357\u533a",
    code: "420113",
    level: "district",
  },
  {
    id: 1087,
    pid: 1082,
    name: "\u6b66\u660c\u533a",
    code: "420106",
    level: "district",
  },
  {
    id: 1088,
    pid: 1082,
    name: "\u6c5f\u590f\u533a",
    code: "420115",
    level: "district",
  },
  {
    id: 1089,
    pid: 1082,
    name: "\u4e1c\u897f\u6e56\u533a",
    code: "420112",
    level: "district",
  },
  {
    id: 1090,
    pid: 1082,
    name: "\u785a\u53e3\u533a",
    code: "420104",
    level: "district",
  },
  {
    id: 1091,
    pid: 1082,
    name: "\u6c49\u9633\u533a",
    code: "420105",
    level: "district",
  },
  {
    id: 1092,
    pid: 1082,
    name: "\u6c5f\u6c49\u533a",
    code: "420103",
    level: "district",
  },
  {
    id: 1093,
    pid: 1082,
    name: "\u6c5f\u5cb8\u533a",
    code: "420102",
    level: "district",
  },
  {
    id: 1094,
    pid: 1082,
    name: "\u9752\u5c71\u533a",
    code: "420107",
    level: "district",
  },
  {
    id: 1095,
    pid: 1082,
    name: "\u6d2a\u5c71\u533a",
    code: "420111",
    level: "district",
  },
  {
    id: 1096,
    pid: 959,
    name: "\u4ed9\u6843\u5e02",
    code: "429004",
    level: "city",
  },
  {
    id: 1122,
    pid: 959,
    name: "\u8346\u5dde\u5e02",
    code: "421000",
    level: "city",
  },
  {
    id: 1123,
    pid: 1122,
    name: "\u6c5f\u9675\u53bf",
    code: "421024",
    level: "district",
  },
  {
    id: 1124,
    pid: 1122,
    name: "\u76d1\u5229\u53bf",
    code: "421023",
    level: "district",
  },
  {
    id: 1125,
    pid: 1122,
    name: "\u6d2a\u6e56\u5e02",
    code: "421083",
    level: "district",
  },
  {
    id: 1126,
    pid: 1122,
    name: "\u77f3\u9996\u5e02",
    code: "421081",
    level: "district",
  },
  {
    id: 1127,
    pid: 1122,
    name: "\u677e\u6ecb\u5e02",
    code: "421087",
    level: "district",
  },
  {
    id: 1128,
    pid: 1122,
    name: "\u6c99\u5e02\u533a",
    code: "421002",
    level: "district",
  },
  {
    id: 1129,
    pid: 1122,
    name: "\u516c\u5b89\u53bf",
    code: "421022",
    level: "district",
  },
  {
    id: 1130,
    pid: 1122,
    name: "\u8346\u5dde\u533a",
    code: "421003",
    level: "district",
  },
  {
    id: 1131,
    pid: 959,
    name: "\u968f\u5dde\u5e02",
    code: "421300",
    level: "city",
  },
  {
    id: 1132,
    pid: 1131,
    name: "\u66fe\u90fd\u533a",
    code: "421303",
    level: "district",
  },
  {
    id: 1133,
    pid: 1131,
    name: "\u968f\u53bf",
    code: "421321",
    level: "district",
  },
  {
    id: 1134,
    pid: 1131,
    name: "\u5e7f\u6c34\u5e02",
    code: "421381",
    level: "district",
  },
  {
    id: 1135,
    pid: 959,
    name: "\u795e\u519c\u67b6\u6797\u533a",
    code: "429021",
    level: "city",
  },
  {
    id: 1144,
    pid: 959,
    name: "\u54b8\u5b81\u5e02",
    code: "421200",
    level: "city",
  },
  {
    id: 1145,
    pid: 1144,
    name: "\u5609\u9c7c\u53bf",
    code: "421221",
    level: "district",
  },
  {
    id: 1146,
    pid: 1144,
    name: "\u901a\u5c71\u53bf",
    code: "421224",
    level: "district",
  },
  {
    id: 1147,
    pid: 1144,
    name: "\u54b8\u5b89\u533a",
    code: "421202",
    level: "district",
  },
  {
    id: 1148,
    pid: 1144,
    name: "\u5d07\u9633\u53bf",
    code: "421223",
    level: "district",
  },
  {
    id: 1149,
    pid: 1144,
    name: "\u8d64\u58c1\u5e02",
    code: "421281",
    level: "district",
  },
  {
    id: 1150,
    pid: 1144,
    name: "\u901a\u57ce\u53bf",
    code: "421222",
    level: "district",
  },
  {
    id: 1151,
    pid: 959,
    name: "\u9102\u5dde\u5e02",
    code: "420700",
    level: "city",
  },
  {
    id: 1152,
    pid: 1151,
    name: "\u534e\u5bb9\u533a",
    code: "420703",
    level: "district",
  },
  {
    id: 1153,
    pid: 1151,
    name: "\u9102\u57ce\u533a",
    code: "420704",
    level: "district",
  },
  {
    id: 1154,
    pid: 1151,
    name: "\u6881\u5b50\u6e56\u533a",
    code: "420702",
    level: "district",
  },
  {
    id: 1155,
    pid: 959,
    name: "\u9ec4\u77f3\u5e02",
    code: "420200",
    level: "city",
  },
  {
    id: 1156,
    pid: 1155,
    name: "\u94c1\u5c71\u533a",
    code: "420205",
    level: "district",
  },
  {
    id: 1157,
    pid: 1155,
    name: "\u897f\u585e\u5c71\u533a",
    code: "420203",
    level: "district",
  },
  {
    id: 1158,
    pid: 1155,
    name: "\u4e0b\u9646\u533a",
    code: "420204",
    level: "district",
  },
  {
    id: 1159,
    pid: 1155,
    name: "\u5927\u51b6\u5e02",
    code: "420281",
    level: "district",
  },
  {
    id: 1160,
    pid: 1155,
    name: "\u9633\u65b0\u53bf",
    code: "420222",
    level: "district",
  },
  {
    id: 1161,
    pid: 1155,
    name: "\u9ec4\u77f3\u6e2f\u533a",
    code: "420202",
    level: "district",
  },
  {
    id: 1162,
    pid: 0,
    name: "\u9655\u897f\u7701",
    code: "610000",
    level: "province",
  },
  {
    id: 1163,
    pid: 1162,
    name: "\u5546\u6d1b\u5e02",
    code: "611000",
    level: "city",
  },
  {
    id: 1164,
    pid: 1163,
    name: "\u4e39\u51e4\u53bf",
    code: "611022",
    level: "district",
  },
  {
    id: 1165,
    pid: 1163,
    name: "\u9547\u5b89\u53bf",
    code: "611025",
    level: "district",
  },
  {
    id: 1166,
    pid: 1163,
    name: "\u67de\u6c34\u53bf",
    code: "611026",
    level: "district",
  },
  {
    id: 1167,
    pid: 1163,
    name: "\u5546\u5357\u53bf",
    code: "611023",
    level: "district",
  },
  {
    id: 1168,
    pid: 1163,
    name: "\u6d1b\u5357\u53bf",
    code: "611021",
    level: "district",
  },
  {
    id: 1169,
    pid: 1163,
    name: "\u5546\u5dde\u533a",
    code: "611002",
    level: "district",
  },
  {
    id: 1170,
    pid: 1163,
    name: "\u5c71\u9633\u53bf",
    code: "611024",
    level: "district",
  },
  {
    id: 1171,
    pid: 1162,
    name: "\u94dc\u5ddd\u5e02",
    code: "610200",
    level: "city",
  },
  {
    id: 1172,
    pid: 1171,
    name: "\u5b9c\u541b\u53bf",
    code: "610222",
    level: "district",
  },
  {
    id: 1173,
    pid: 1171,
    name: "\u738b\u76ca\u533a",
    code: "610202",
    level: "district",
  },
  {
    id: 1174,
    pid: 1171,
    name: "\u8000\u5dde\u533a",
    code: "610204",
    level: "district",
  },
  {
    id: 1175,
    pid: 1171,
    name: "\u5370\u53f0\u533a",
    code: "610203",
    level: "district",
  },
  {
    id: 1176,
    pid: 1162,
    name: "\u897f\u5b89\u5e02",
    code: "610100",
    level: "city",
  },
  {
    id: 1177,
    pid: 1176,
    name: "\u4e34\u6f7c\u533a",
    code: "610115",
    level: "district",
  },
  {
    id: 1178,
    pid: 1176,
    name: "\u9ad8\u9675\u533a",
    code: "610117",
    level: "district",
  },
  {
    id: 1179,
    pid: 1176,
    name: "\u84dd\u7530\u53bf",
    code: "610122",
    level: "district",
  },
  {
    id: 1180,
    pid: 1176,
    name: "\u9120\u9091\u533a",
    code: "610118",
    level: "district",
  },
  {
    id: 1181,
    pid: 1176,
    name: "\u957f\u5b89\u533a",
    code: "610116",
    level: "district",
  },
  {
    id: 1182,
    pid: 1176,
    name: "\u83b2\u6e56\u533a",
    code: "610104",
    level: "district",
  },
  {
    id: 1183,
    pid: 1176,
    name: "\u705e\u6865\u533a",
    code: "610111",
    level: "district",
  },
  {
    id: 1184,
    pid: 1176,
    name: "\u65b0\u57ce\u533a",
    code: "610102",
    level: "district",
  },
  {
    id: 1185,
    pid: 1176,
    name: "\u7891\u6797\u533a",
    code: "610103",
    level: "district",
  },
  {
    id: 1186,
    pid: 1176,
    name: "\u5468\u81f3\u53bf",
    code: "610124",
    level: "district",
  },
  {
    id: 1187,
    pid: 1176,
    name: "\u96c1\u5854\u533a",
    code: "610113",
    level: "district",
  },
  {
    id: 1188,
    pid: 1176,
    name: "\u960e\u826f\u533a",
    code: "610114",
    level: "district",
  },
  {
    id: 1189,
    pid: 1176,
    name: "\u672a\u592e\u533a",
    code: "610112",
    level: "district",
  },
  {
    id: 1190,
    pid: 1162,
    name: "\u6c49\u4e2d\u5e02",
    code: "610700",
    level: "city",
  },
  {
    id: 1191,
    pid: 1190,
    name: "\u4f5b\u576a\u53bf",
    code: "610730",
    level: "district",
  },
  {
    id: 1192,
    pid: 1190,
    name: "\u6d0b\u53bf",
    code: "610723",
    level: "district",
  },
  {
    id: 1193,
    pid: 1190,
    name: "\u7559\u575d\u53bf",
    code: "610729",
    level: "district",
  },
  {
    id: 1194,
    pid: 1190,
    name: "\u7565\u9633\u53bf",
    code: "610727",
    level: "district",
  },
  {
    id: 1195,
    pid: 1190,
    name: "\u897f\u4e61\u53bf",
    code: "610724",
    level: "district",
  },
  {
    id: 1196,
    pid: 1190,
    name: "\u5b81\u5f3a\u53bf",
    code: "610726",
    level: "district",
  },
  {
    id: 1197,
    pid: 1190,
    name: "\u52c9\u53bf",
    code: "610725",
    level: "district",
  },
  {
    id: 1198,
    pid: 1190,
    name: "\u5357\u90d1\u533a",
    code: "610703",
    level: "district",
  },
  {
    id: 1199,
    pid: 1190,
    name: "\u6c49\u53f0\u533a",
    code: "610702",
    level: "district",
  },
  {
    id: 1200,
    pid: 1190,
    name: "\u57ce\u56fa\u53bf",
    code: "610722",
    level: "district",
  },
  {
    id: 1201,
    pid: 1190,
    name: "\u9547\u5df4\u53bf",
    code: "610728",
    level: "district",
  },
  {
    id: 1202,
    pid: 1162,
    name: "\u6986\u6797\u5e02",
    code: "610800",
    level: "city",
  },
  {
    id: 1203,
    pid: 1202,
    name: "\u6986\u9633\u533a",
    code: "610802",
    level: "district",
  },
  {
    id: 1204,
    pid: 1202,
    name: "\u7c73\u8102\u53bf",
    code: "610827",
    level: "district",
  },
  {
    id: 1205,
    pid: 1202,
    name: "\u4f73\u53bf",
    code: "610828",
    level: "district",
  },
  {
    id: 1206,
    pid: 1202,
    name: "\u5e9c\u8c37\u53bf",
    code: "610822",
    level: "district",
  },
  {
    id: 1207,
    pid: 1202,
    name: "\u6a2a\u5c71\u533a",
    code: "610803",
    level: "district",
  },
  {
    id: 1208,
    pid: 1202,
    name: "\u795e\u6728\u5e02",
    code: "610881",
    level: "district",
  },
  {
    id: 1209,
    pid: 1202,
    name: "\u9756\u8fb9\u53bf",
    code: "610824",
    level: "district",
  },
  {
    id: 1210,
    pid: 1202,
    name: "\u5b50\u6d32\u53bf",
    code: "610831",
    level: "district",
  },
  {
    id: 1211,
    pid: 1202,
    name: "\u6e05\u6da7\u53bf",
    code: "610830",
    level: "district",
  },
  {
    id: 1212,
    pid: 1202,
    name: "\u5434\u5821\u53bf",
    code: "610829",
    level: "district",
  },
  {
    id: 1213,
    pid: 1202,
    name: "\u7ee5\u5fb7\u53bf",
    code: "610826",
    level: "district",
  },
  {
    id: 1214,
    pid: 1202,
    name: "\u5b9a\u8fb9\u53bf",
    code: "610825",
    level: "district",
  },
  {
    id: 1215,
    pid: 1162,
    name: "\u5ef6\u5b89\u5e02",
    code: "610600",
    level: "city",
  },
  {
    id: 1216,
    pid: 1215,
    name: "\u5b89\u585e\u533a",
    code: "610603",
    level: "district",
  },
  {
    id: 1217,
    pid: 1215,
    name: "\u5434\u8d77\u53bf",
    code: "610626",
    level: "district",
  },
  {
    id: 1218,
    pid: 1215,
    name: "\u5fd7\u4e39\u53bf",
    code: "610625",
    level: "district",
  },
  {
    id: 1219,
    pid: 1215,
    name: "\u5ef6\u5ddd\u53bf",
    code: "610622",
    level: "district",
  },
  {
    id: 1220,
    pid: 1215,
    name: "\u5b9d\u5854\u533a",
    code: "610602",
    level: "district",
  },
  {
    id: 1221,
    pid: 1215,
    name: "\u5b50\u957f\u5e02",
    code: "610681",
    level: "district",
  },
  {
    id: 1222,
    pid: 1215,
    name: "\u5ef6\u957f\u53bf",
    code: "610621",
    level: "district",
  },
  {
    id: 1223,
    pid: 1215,
    name: "\u6d1b\u5ddd\u53bf",
    code: "610629",
    level: "district",
  },
  {
    id: 1224,
    pid: 1215,
    name: "\u9ec4\u9675\u53bf",
    code: "610632",
    level: "district",
  },
  {
    id: 1225,
    pid: 1215,
    name: "\u5b9c\u5ddd\u53bf",
    code: "610630",
    level: "district",
  },
  {
    id: 1226,
    pid: 1215,
    name: "\u7518\u6cc9\u53bf",
    code: "610627",
    level: "district",
  },
  {
    id: 1227,
    pid: 1215,
    name: "\u5bcc\u53bf",
    code: "610628",
    level: "district",
  },
  {
    id: 1228,
    pid: 1215,
    name: "\u9ec4\u9f99\u53bf",
    code: "610631",
    level: "district",
  },
  {
    id: 1229,
    pid: 1162,
    name: "\u5b9d\u9e21\u5e02",
    code: "610300",
    level: "city",
  },
  {
    id: 1230,
    pid: 1229,
    name: "\u9e9f\u6e38\u53bf",
    code: "610329",
    level: "district",
  },
  {
    id: 1231,
    pid: 1229,
    name: "\u9647\u53bf",
    code: "610327",
    level: "district",
  },
  {
    id: 1232,
    pid: 1229,
    name: "\u5343\u9633\u53bf",
    code: "610328",
    level: "district",
  },
  {
    id: 1233,
    pid: 1229,
    name: "\u91d1\u53f0\u533a",
    code: "610303",
    level: "district",
  },
  {
    id: 1234,
    pid: 1229,
    name: "\u51e4\u53bf",
    code: "610330",
    level: "district",
  },
  {
    id: 1235,
    pid: 1229,
    name: "\u5c90\u5c71\u53bf",
    code: "610323",
    level: "district",
  },
  {
    id: 1236,
    pid: 1229,
    name: "\u6e2d\u6ee8\u533a",
    code: "610302",
    level: "district",
  },
  {
    id: 1237,
    pid: 1229,
    name: "\u592a\u767d\u53bf",
    code: "610331",
    level: "district",
  },
  {
    id: 1238,
    pid: 1229,
    name: "\u9648\u4ed3\u533a",
    code: "610304",
    level: "district",
  },
  {
    id: 1239,
    pid: 1229,
    name: "\u51e4\u7fd4\u53bf",
    code: "610322",
    level: "district",
  },
  {
    id: 1240,
    pid: 1229,
    name: "\u6276\u98ce\u53bf",
    code: "610324",
    level: "district",
  },
  {
    id: 1241,
    pid: 1229,
    name: "\u7709\u53bf",
    code: "610326",
    level: "district",
  },
  {
    id: 1242,
    pid: 1162,
    name: "\u6e2d\u5357\u5e02",
    code: "610500",
    level: "city",
  },
  {
    id: 1243,
    pid: 1242,
    name: "\u767d\u6c34\u53bf",
    code: "610527",
    level: "district",
  },
  {
    id: 1244,
    pid: 1242,
    name: "\u97e9\u57ce\u5e02",
    code: "610581",
    level: "district",
  },
  {
    id: 1245,
    pid: 1242,
    name: "\u5927\u8354\u53bf",
    code: "610523",
    level: "district",
  },
  {
    id: 1246,
    pid: 1242,
    name: "\u534e\u9634\u5e02",
    code: "610582",
    level: "district",
  },
  {
    id: 1247,
    pid: 1242,
    name: "\u534e\u5dde\u533a",
    code: "610503",
    level: "district",
  },
  {
    id: 1248,
    pid: 1242,
    name: "\u6f7c\u5173\u53bf",
    code: "610522",
    level: "district",
  },
  {
    id: 1249,
    pid: 1242,
    name: "\u6f84\u57ce\u53bf",
    code: "610525",
    level: "district",
  },
  {
    id: 1250,
    pid: 1242,
    name: "\u84b2\u57ce\u53bf",
    code: "610526",
    level: "district",
  },
  {
    id: 1251,
    pid: 1242,
    name: "\u4e34\u6e2d\u533a",
    code: "610502",
    level: "district",
  },
  {
    id: 1252,
    pid: 1242,
    name: "\u5408\u9633\u53bf",
    code: "610524",
    level: "district",
  },
  {
    id: 1253,
    pid: 1242,
    name: "\u5bcc\u5e73\u53bf",
    code: "610528",
    level: "district",
  },
  {
    id: 1254,
    pid: 1162,
    name: "\u54b8\u9633\u5e02",
    code: "610400",
    level: "city",
  },
  {
    id: 1255,
    pid: 1254,
    name: "\u65ec\u9091\u53bf",
    code: "610429",
    level: "district",
  },
  {
    id: 1256,
    pid: 1254,
    name: "\u5f6c\u5dde\u5e02",
    code: "610482",
    level: "district",
  },
  {
    id: 1257,
    pid: 1254,
    name: "\u4e7e\u53bf",
    code: "610424",
    level: "district",
  },
  {
    id: 1258,
    pid: 1254,
    name: "\u6c38\u5bff\u53bf",
    code: "610426",
    level: "district",
  },
  {
    id: 1259,
    pid: 1254,
    name: "\u6e2d\u57ce\u533a",
    code: "610404",
    level: "district",
  },
  {
    id: 1260,
    pid: 1254,
    name: "\u6b66\u529f\u53bf",
    code: "610431",
    level: "district",
  },
  {
    id: 1261,
    pid: 1254,
    name: "\u6cfe\u9633\u53bf",
    code: "610423",
    level: "district",
  },
  {
    id: 1262,
    pid: 1254,
    name: "\u957f\u6b66\u53bf",
    code: "610428",
    level: "district",
  },
  {
    id: 1263,
    pid: 1254,
    name: "\u5174\u5e73\u5e02",
    code: "610481",
    level: "district",
  },
  {
    id: 1264,
    pid: 1254,
    name: "\u6768\u9675\u533a",
    code: "610403",
    level: "district",
  },
  {
    id: 1265,
    pid: 1254,
    name: "\u4e09\u539f\u53bf",
    code: "610422",
    level: "district",
  },
  {
    id: 1266,
    pid: 1254,
    name: "\u6df3\u5316\u53bf",
    code: "610430",
    level: "district",
  },
  {
    id: 1267,
    pid: 1254,
    name: "\u79e6\u90fd\u533a",
    code: "610402",
    level: "district",
  },
  {
    id: 1268,
    pid: 1254,
    name: "\u793c\u6cc9\u53bf",
    code: "610425",
    level: "district",
  },
  {
    id: 1269,
    pid: 1162,
    name: "\u5b89\u5eb7\u5e02",
    code: "610900",
    level: "city",
  },
  {
    id: 1270,
    pid: 1269,
    name: "\u5b81\u9655\u53bf",
    code: "610923",
    level: "district",
  },
  {
    id: 1271,
    pid: 1269,
    name: "\u6c49\u9634\u53bf",
    code: "610921",
    level: "district",
  },
  {
    id: 1272,
    pid: 1269,
    name: "\u77f3\u6cc9\u53bf",
    code: "610922",
    level: "district",
  },
  {
    id: 1273,
    pid: 1269,
    name: "\u7d2b\u9633\u53bf",
    code: "610924",
    level: "district",
  },
  {
    id: 1274,
    pid: 1269,
    name: "\u6c49\u6ee8\u533a",
    code: "610902",
    level: "district",
  },
  {
    id: 1275,
    pid: 1269,
    name: "\u65ec\u9633\u53bf",
    code: "610928",
    level: "district",
  },
  {
    id: 1276,
    pid: 1269,
    name: "\u5e73\u5229\u53bf",
    code: "610926",
    level: "district",
  },
  {
    id: 1277,
    pid: 1269,
    name: "\u767d\u6cb3\u53bf",
    code: "610929",
    level: "district",
  },
  {
    id: 1278,
    pid: 1269,
    name: "\u5c9a\u768b\u53bf",
    code: "610925",
    level: "district",
  },
  {
    id: 1279,
    pid: 1269,
    name: "\u9547\u576a\u53bf",
    code: "610927",
    level: "district",
  },
  {
    id: 1280,
    pid: 0,
    name: "\u8d35\u5dde\u7701",
    code: "520000",
    level: "province",
  },
  {
    id: 1281,
    pid: 1280,
    name: "\u516d\u76d8\u6c34\u5e02",
    code: "520200",
    level: "city",
  },
  {
    id: 1282,
    pid: 1281,
    name: "\u949f\u5c71\u533a",
    code: "520201",
    level: "district",
  },
  {
    id: 1283,
    pid: 1281,
    name: "\u516d\u679d\u7279\u533a",
    code: "520203",
    level: "district",
  },
  {
    id: 1284,
    pid: 1281,
    name: "\u76d8\u5dde\u5e02",
    code: "520281",
    level: "district",
  },
  {
    id: 1285,
    pid: 1281,
    name: "\u6c34\u57ce\u53bf",
    code: "520221",
    level: "district",
  },
  {
    id: 1286,
    pid: 1280,
    name: "\u94dc\u4ec1\u5e02",
    code: "520600",
    level: "city",
  },
  {
    id: 1287,
    pid: 1286,
    name: "\u77f3\u9621\u53bf",
    code: "520623",
    level: "district",
  },
  {
    id: 1288,
    pid: 1286,
    name: "\u5370\u6c5f\u571f\u5bb6\u65cf\u82d7\u65cf\u81ea\u6cbb\u53bf",
    code: "520625",
    level: "district",
  },
  {
    id: 1289,
    pid: 1286,
    name: "\u5fb7\u6c5f\u53bf",
    code: "520626",
    level: "district",
  },
  {
    id: 1290,
    pid: 1286,
    name: "\u78a7\u6c5f\u533a",
    code: "520602",
    level: "district",
  },
  {
    id: 1291,
    pid: 1286,
    name: "\u6c5f\u53e3\u53bf",
    code: "520621",
    level: "district",
  },
  {
    id: 1292,
    pid: 1286,
    name: "\u4e07\u5c71\u533a",
    code: "520603",
    level: "district",
  },
  {
    id: 1293,
    pid: 1286,
    name: "\u677e\u6843\u82d7\u65cf\u81ea\u6cbb\u53bf",
    code: "520628",
    level: "district",
  },
  {
    id: 1294,
    pid: 1286,
    name: "\u6cbf\u6cb3\u571f\u5bb6\u65cf\u81ea\u6cbb\u53bf",
    code: "520627",
    level: "district",
  },
  {
    id: 1295,
    pid: 1286,
    name: "\u601d\u5357\u53bf",
    code: "520624",
    level: "district",
  },
  {
    id: 1296,
    pid: 1286,
    name: "\u7389\u5c4f\u4f97\u65cf\u81ea\u6cbb\u53bf",
    code: "520622",
    level: "district",
  },
  {
    id: 1297,
    pid: 1280,
    name: "\u9075\u4e49\u5e02",
    code: "520300",
    level: "city",
  },
  {
    id: 1298,
    pid: 1297,
    name: "\u7ee5\u9633\u53bf",
    code: "520323",
    level: "district",
  },
  {
    id: 1299,
    pid: 1297,
    name: "\u6e44\u6f6d\u53bf",
    code: "520328",
    level: "district",
  },
  {
    id: 1300,
    pid: 1297,
    name: "\u6850\u6893\u53bf",
    code: "520322",
    level: "district",
  },
  {
    id: 1301,
    pid: 1297,
    name: "\u4f59\u5e86\u53bf",
    code: "520329",
    level: "district",
  },
  {
    id: 1302,
    pid: 1297,
    name: "\u64ad\u5dde\u533a",
    code: "520304",
    level: "district",
  },
  {
    id: 1303,
    pid: 1297,
    name: "\u4ec1\u6000\u5e02",
    code: "520382",
    level: "district",
  },
  {
    id: 1304,
    pid: 1297,
    name: "\u8d64\u6c34\u5e02",
    code: "520381",
    level: "district",
  },
  {
    id: 1305,
    pid: 1297,
    name: "\u4e60\u6c34\u53bf",
    code: "520330",
    level: "district",
  },
  {
    id: 1306,
    pid: 1297,
    name: "\u9053\u771f\u4ee1\u4f6c\u65cf\u82d7\u65cf\u81ea\u6cbb\u53bf",
    code: "520325",
    level: "district",
  },
  {
    id: 1307,
    pid: 1297,
    name: "\u6b63\u5b89\u53bf",
    code: "520324",
    level: "district",
  },
  {
    id: 1308,
    pid: 1297,
    name: "\u52a1\u5ddd\u4ee1\u4f6c\u65cf\u82d7\u65cf\u81ea\u6cbb\u53bf",
    code: "520326",
    level: "district",
  },
  {
    id: 1309,
    pid: 1297,
    name: "\u51e4\u5188\u53bf",
    code: "520327",
    level: "district",
  },
  {
    id: 1310,
    pid: 1297,
    name: "\u6c47\u5ddd\u533a",
    code: "520303",
    level: "district",
  },
  {
    id: 1311,
    pid: 1297,
    name: "\u7ea2\u82b1\u5c97\u533a",
    code: "520302",
    level: "district",
  },
  {
    id: 1312,
    pid: 1280,
    name: "\u5b89\u987a\u5e02",
    code: "520400",
    level: "city",
  },
  {
    id: 1313,
    pid: 1312,
    name: "\u897f\u79c0\u533a",
    code: "520402",
    level: "district",
  },
  {
    id: 1314,
    pid: 1312,
    name: "\u7d2b\u4e91\u82d7\u65cf\u5e03\u4f9d\u65cf\u81ea\u6cbb\u53bf",
    code: "520425",
    level: "district",
  },
  {
    id: 1315,
    pid: 1312,
    name: "\u9547\u5b81\u5e03\u4f9d\u65cf\u82d7\u65cf\u81ea\u6cbb\u53bf",
    code: "520423",
    level: "district",
  },
  {
    id: 1316,
    pid: 1312,
    name: "\u5173\u5cad\u5e03\u4f9d\u65cf\u82d7\u65cf\u81ea\u6cbb\u53bf",
    code: "520424",
    level: "district",
  },
  {
    id: 1317,
    pid: 1312,
    name: "\u5e73\u575d\u533a",
    code: "520403",
    level: "district",
  },
  {
    id: 1318,
    pid: 1312,
    name: "\u666e\u5b9a\u53bf",
    code: "520422",
    level: "district",
  },
  {
    id: 1319,
    pid: 1280,
    name: "\u9ed4\u5357\u5e03\u4f9d\u65cf\u82d7\u65cf\u81ea\u6cbb\u5dde",
    code: "522700",
    level: "city",
  },
  {
    id: 1320,
    pid: 1319,
    name: "\u957f\u987a\u53bf",
    code: "522729",
    level: "district",
  },
  {
    id: 1321,
    pid: 1319,
    name: "\u72ec\u5c71\u53bf",
    code: "522726",
    level: "district",
  },
  {
    id: 1322,
    pid: 1319,
    name: "\u60e0\u6c34\u53bf",
    code: "522731",
    level: "district",
  },
  {
    id: 1323,
    pid: 1319,
    name: "\u5e73\u5858\u53bf",
    code: "522727",
    level: "district",
  },
  {
    id: 1324,
    pid: 1319,
    name: "\u7f57\u7538\u53bf",
    code: "522728",
    level: "district",
  },
  {
    id: 1325,
    pid: 1319,
    name: "\u8354\u6ce2\u53bf",
    code: "522722",
    level: "district",
  },
  {
    id: 1326,
    pid: 1319,
    name: "\u9f99\u91cc\u53bf",
    code: "522730",
    level: "district",
  },
  {
    id: 1327,
    pid: 1319,
    name: "\u8d35\u5b9a\u53bf",
    code: "522723",
    level: "district",
  },
  {
    id: 1328,
    pid: 1319,
    name: "\u4e09\u90fd\u6c34\u65cf\u81ea\u6cbb\u53bf",
    code: "522732",
    level: "district",
  },
  {
    id: 1329,
    pid: 1319,
    name: "\u74ee\u5b89\u53bf",
    code: "522725",
    level: "district",
  },
  {
    id: 1330,
    pid: 1319,
    name: "\u798f\u6cc9\u5e02",
    code: "522702",
    level: "district",
  },
  {
    id: 1331,
    pid: 1319,
    name: "\u90fd\u5300\u5e02",
    code: "522701",
    level: "district",
  },
  {
    id: 1332,
    pid: 1280,
    name: "\u6bd5\u8282\u5e02",
    code: "520500",
    level: "city",
  },
  {
    id: 1333,
    pid: 1332,
    name: "\u4e03\u661f\u5173\u533a",
    code: "520502",
    level: "district",
  },
  {
    id: 1334,
    pid: 1332,
    name: "\u5927\u65b9\u53bf",
    code: "520521",
    level: "district",
  },
  {
    id: 1335,
    pid: 1332,
    name: "\u9ed4\u897f\u53bf",
    code: "520522",
    level: "district",
  },
  {
    id: 1336,
    pid: 1332,
    name: "\u91d1\u6c99\u53bf",
    code: "520523",
    level: "district",
  },
  {
    id: 1337,
    pid: 1332,
    name: "\u7eb3\u96cd\u53bf",
    code: "520525",
    level: "district",
  },
  {
    id: 1338,
    pid: 1332,
    name: "\u7ec7\u91d1\u53bf",
    code: "520524",
    level: "district",
  },
  {
    id: 1339,
    pid: 1332,
    name: "\u5a01\u5b81\u5f5d\u65cf\u56de\u65cf\u82d7\u65cf\u81ea\u6cbb\u53bf",
    code: "520526",
    level: "district",
  },
  {
    id: 1340,
    pid: 1332,
    name: "\u8d6b\u7ae0\u53bf",
    code: "520527",
    level: "district",
  },
  {
    id: 1341,
    pid: 1280,
    name: "\u9ed4\u4e1c\u5357\u82d7\u65cf\u4f97\u65cf\u81ea\u6cbb\u5dde",
    code: "522600",
    level: "city",
  },
  {
    id: 1342,
    pid: 1341,
    name: "\u4e09\u7a57\u53bf",
    code: "522624",
    level: "district",
  },
  {
    id: 1343,
    pid: 1341,
    name: "\u6995\u6c5f\u53bf",
    code: "522632",
    level: "district",
  },
  {
    id: 1344,
    pid: 1341,
    name: "\u96f7\u5c71\u53bf",
    code: "522634",
    level: "district",
  },
  {
    id: 1345,
    pid: 1341,
    name: "\u5929\u67f1\u53bf",
    code: "522627",
    level: "district",
  },
  {
    id: 1346,
    pid: 1341,
    name: "\u65bd\u79c9\u53bf",
    code: "522623",
    level: "district",
  },
  {
    id: 1347,
    pid: 1341,
    name: "\u9547\u8fdc\u53bf",
    code: "522625",
    level: "district",
  },
  {
    id: 1348,
    pid: 1341,
    name: "\u4e39\u5be8\u53bf",
    code: "522636",
    level: "district",
  },
  {
    id: 1349,
    pid: 1341,
    name: "\u51ef\u91cc\u5e02",
    code: "522601",
    level: "district",
  },
  {
    id: 1350,
    pid: 1341,
    name: "\u9ece\u5e73\u53bf",
    code: "522631",
    level: "district",
  },
  {
    id: 1351,
    pid: 1341,
    name: "\u4ece\u6c5f\u53bf",
    code: "522633",
    level: "district",
  },
  {
    id: 1352,
    pid: 1341,
    name: "\u5251\u6cb3\u53bf",
    code: "522629",
    level: "district",
  },
  {
    id: 1353,
    pid: 1341,
    name: "\u9526\u5c4f\u53bf",
    code: "522628",
    level: "district",
  },
  {
    id: 1354,
    pid: 1341,
    name: "\u53f0\u6c5f\u53bf",
    code: "522630",
    level: "district",
  },
  {
    id: 1355,
    pid: 1341,
    name: "\u9ec4\u5e73\u53bf",
    code: "522622",
    level: "district",
  },
  {
    id: 1356,
    pid: 1341,
    name: "\u9ebb\u6c5f\u53bf",
    code: "522635",
    level: "district",
  },
  {
    id: 1357,
    pid: 1341,
    name: "\u5c91\u5de9\u53bf",
    code: "522626",
    level: "district",
  },
  {
    id: 1358,
    pid: 1280,
    name: "\u9ed4\u897f\u5357\u5e03\u4f9d\u65cf\u82d7\u65cf\u81ea\u6cbb\u5dde",
    code: "522300",
    level: "city",
  },
  {
    id: 1359,
    pid: 1358,
    name: "\u5174\u4e49\u5e02",
    code: "522301",
    level: "district",
  },
  {
    id: 1360,
    pid: 1358,
    name: "\u5174\u4ec1\u5e02",
    code: "522302",
    level: "district",
  },
  {
    id: 1361,
    pid: 1358,
    name: "\u5b89\u9f99\u53bf",
    code: "522328",
    level: "district",
  },
  {
    id: 1362,
    pid: 1358,
    name: "\u518c\u4ea8\u53bf",
    code: "522327",
    level: "district",
  },
  {
    id: 1363,
    pid: 1358,
    name: "\u8d1e\u4e30\u53bf",
    code: "522325",
    level: "district",
  },
  {
    id: 1364,
    pid: 1358,
    name: "\u666e\u5b89\u53bf",
    code: "522323",
    level: "district",
  },
  {
    id: 1365,
    pid: 1358,
    name: "\u671b\u8c1f\u53bf",
    code: "522326",
    level: "district",
  },
  {
    id: 1366,
    pid: 1358,
    name: "\u6674\u9686\u53bf",
    code: "522324",
    level: "district",
  },
  {
    id: 1367,
    pid: 1280,
    name: "\u8d35\u9633\u5e02",
    code: "520100",
    level: "city",
  },
  {
    id: 1368,
    pid: 1367,
    name: "\u5f00\u9633\u53bf",
    code: "520121",
    level: "district",
  },
  {
    id: 1369,
    pid: 1367,
    name: "\u767d\u4e91\u533a",
    code: "520113",
    level: "district",
  },
  {
    id: 1370,
    pid: 1367,
    name: "\u606f\u70fd\u53bf",
    code: "520122",
    level: "district",
  },
  {
    id: 1371,
    pid: 1367,
    name: "\u4fee\u6587\u53bf",
    code: "520123",
    level: "district",
  },
  {
    id: 1372,
    pid: 1367,
    name: "\u6e05\u9547\u5e02",
    code: "520181",
    level: "district",
  },
  {
    id: 1373,
    pid: 1367,
    name: "\u4e4c\u5f53\u533a",
    code: "520112",
    level: "district",
  },
  {
    id: 1374,
    pid: 1367,
    name: "\u5357\u660e\u533a",
    code: "520102",
    level: "district",
  },
  {
    id: 1375,
    pid: 1367,
    name: "\u82b1\u6eaa\u533a",
    code: "520111",
    level: "district",
  },
  {
    id: 1376,
    pid: 1367,
    name: "\u4e91\u5ca9\u533a",
    code: "520103",
    level: "district",
  },
  {
    id: 1377,
    pid: 1367,
    name: "\u89c2\u5c71\u6e56\u533a",
    code: "520115",
    level: "district",
  },
  {
    id: 1378,
    pid: 0,
    name: "\u4e0a\u6d77\u5e02",
    code: "310000",
    level: "province",
  },
  {
    id: 1379,
    pid: 1378,
    name: "\u4e0a\u6d77\u57ce\u533a",
    code: "310100",
    level: "city",
  },
  {
    id: 1380,
    pid: 1379,
    name: "\u5949\u8d24\u533a",
    code: "310120",
    level: "district",
  },
  {
    id: 1381,
    pid: 1379,
    name: "\u5d07\u660e\u533a",
    code: "310151",
    level: "district",
  },
  {
    id: 1382,
    pid: 1379,
    name: "\u91d1\u5c71\u533a",
    code: "310116",
    level: "district",
  },
  {
    id: 1383,
    pid: 1379,
    name: "\u6d66\u4e1c\u65b0\u533a",
    code: "310115",
    level: "district",
  },
  {
    id: 1384,
    pid: 1379,
    name: "\u9ec4\u6d66\u533a",
    code: "310101",
    level: "district",
  },
  {
    id: 1385,
    pid: 1379,
    name: "\u5b9d\u5c71\u533a",
    code: "310113",
    level: "district",
  },
  {
    id: 1386,
    pid: 1379,
    name: "\u666e\u9640\u533a",
    code: "310107",
    level: "district",
  },
  {
    id: 1387,
    pid: 1379,
    name: "\u5609\u5b9a\u533a",
    code: "310114",
    level: "district",
  },
  {
    id: 1388,
    pid: 1379,
    name: "\u6768\u6d66\u533a",
    code: "310110",
    level: "district",
  },
  {
    id: 1389,
    pid: 1379,
    name: "\u677e\u6c5f\u533a",
    code: "310117",
    level: "district",
  },
  {
    id: 1390,
    pid: 1379,
    name: "\u8679\u53e3\u533a",
    code: "310109",
    level: "district",
  },
  {
    id: 1391,
    pid: 1379,
    name: "\u9759\u5b89\u533a",
    code: "310106",
    level: "district",
  },
  {
    id: 1392,
    pid: 1379,
    name: "\u957f\u5b81\u533a",
    code: "310105",
    level: "district",
  },
  {
    id: 1393,
    pid: 1379,
    name: "\u95f5\u884c\u533a",
    code: "310112",
    level: "district",
  },
  {
    id: 1394,
    pid: 1379,
    name: "\u5f90\u6c47\u533a",
    code: "310104",
    level: "district",
  },
  {
    id: 1395,
    pid: 1379,
    name: "\u9752\u6d66\u533a",
    code: "310118",
    level: "district",
  },
  {
    id: 1396,
    pid: 0,
    name: "\u5c71\u4e1c\u7701",
    code: "370000",
    level: "province",
  },
  {
    id: 1397,
    pid: 1396,
    name: "\u804a\u57ce\u5e02",
    code: "371500",
    level: "city",
  },
  {
    id: 1398,
    pid: 1397,
    name: "\u51a0\u53bf",
    code: "371525",
    level: "district",
  },
  {
    id: 1399,
    pid: 1397,
    name: "\u8398\u53bf",
    code: "371522",
    level: "district",
  },
  {
    id: 1400,
    pid: 1397,
    name: "\u830c\u5e73\u533a",
    code: "371503",
    level: "district",
  },
  {
    id: 1401,
    pid: 1397,
    name: "\u4e1c\u660c\u5e9c\u533a",
    code: "371502",
    level: "district",
  },
  {
    id: 1402,
    pid: 1397,
    name: "\u4e34\u6e05\u5e02",
    code: "371581",
    level: "district",
  },
  {
    id: 1403,
    pid: 1397,
    name: "\u4e1c\u963f\u53bf",
    code: "371524",
    level: "district",
  },
  {
    id: 1404,
    pid: 1397,
    name: "\u9633\u8c37\u53bf",
    code: "371521",
    level: "district",
  },
  {
    id: 1405,
    pid: 1397,
    name: "\u9ad8\u5510\u53bf",
    code: "371526",
    level: "district",
  },
  {
    id: 1406,
    pid: 1396,
    name: "\u6dc4\u535a\u5e02",
    code: "370300",
    level: "city",
  },
  {
    id: 1407,
    pid: 1406,
    name: "\u535a\u5c71\u533a",
    code: "370304",
    level: "district",
  },
  {
    id: 1408,
    pid: 1406,
    name: "\u4e34\u6dc4\u533a",
    code: "370305",
    level: "district",
  },
  {
    id: 1409,
    pid: 1406,
    name: "\u9ad8\u9752\u53bf",
    code: "370322",
    level: "district",
  },
  {
    id: 1410,
    pid: 1406,
    name: "\u6853\u53f0\u53bf",
    code: "370321",
    level: "district",
  },
  {
    id: 1411,
    pid: 1406,
    name: "\u5f20\u5e97\u533a",
    code: "370303",
    level: "district",
  },
  {
    id: 1412,
    pid: 1406,
    name: "\u6c82\u6e90\u53bf",
    code: "370323",
    level: "district",
  },
  {
    id: 1413,
    pid: 1406,
    name: "\u6dc4\u5ddd\u533a",
    code: "370302",
    level: "district",
  },
  {
    id: 1414,
    pid: 1406,
    name: "\u5468\u6751\u533a",
    code: "370306",
    level: "district",
  },
  {
    id: 1415,
    pid: 1396,
    name: "\u4e34\u6c82\u5e02",
    code: "371300",
    level: "city",
  },
  {
    id: 1416,
    pid: 1415,
    name: "\u6c82\u6c34\u53bf",
    code: "371323",
    level: "district",
  },
  {
    id: 1417,
    pid: 1415,
    name: "\u8499\u9634\u53bf",
    code: "371328",
    level: "district",
  },
  {
    id: 1418,
    pid: 1415,
    name: "\u5e73\u9091\u53bf",
    code: "371326",
    level: "district",
  },
  {
    id: 1419,
    pid: 1415,
    name: "\u90ef\u57ce\u53bf",
    code: "371322",
    level: "district",
  },
  {
    id: 1420,
    pid: 1415,
    name: "\u8392\u5357\u53bf",
    code: "371327",
    level: "district",
  },
  {
    id: 1421,
    pid: 1415,
    name: "\u6c82\u5357\u53bf",
    code: "371321",
    level: "district",
  },
  {
    id: 1422,
    pid: 1415,
    name: "\u5170\u5c71\u533a",
    code: "371302",
    level: "district",
  },
  {
    id: 1423,
    pid: 1415,
    name: "\u7f57\u5e84\u533a",
    code: "371311",
    level: "district",
  },
  {
    id: 1424,
    pid: 1415,
    name: "\u4e34\u6cad\u53bf",
    code: "371329",
    level: "district",
  },
  {
    id: 1425,
    pid: 1415,
    name: "\u6cb3\u4e1c\u533a",
    code: "371312",
    level: "district",
  },
  {
    id: 1426,
    pid: 1415,
    name: "\u8d39\u53bf",
    code: "371325",
    level: "district",
  },
  {
    id: 1427,
    pid: 1415,
    name: "\u5170\u9675\u53bf",
    code: "371324",
    level: "district",
  },
  {
    id: 1428,
    pid: 1396,
    name: "\u9752\u5c9b\u5e02",
    code: "370200",
    level: "city",
  },
  {
    id: 1429,
    pid: 1428,
    name: "\u83b1\u897f\u5e02",
    code: "370285",
    level: "district",
  },
  {
    id: 1430,
    pid: 1428,
    name: "\u5e73\u5ea6\u5e02",
    code: "370283",
    level: "district",
  },
  {
    id: 1431,
    pid: 1428,
    name: "\u57ce\u9633\u533a",
    code: "370214",
    level: "district",
  },
  {
    id: 1432,
    pid: 1428,
    name: "\u5373\u58a8\u533a",
    code: "370215",
    level: "district",
  },
  {
    id: 1433,
    pid: 1428,
    name: "\u674e\u6ca7\u533a",
    code: "370213",
    level: "district",
  },
  {
    id: 1434,
    pid: 1428,
    name: "\u5d02\u5c71\u533a",
    code: "370212",
    level: "district",
  },
  {
    id: 1435,
    pid: 1428,
    name: "\u9ec4\u5c9b\u533a",
    code: "370211",
    level: "district",
  },
  {
    id: 1436,
    pid: 1428,
    name: "\u5e02\u5317\u533a",
    code: "370203",
    level: "district",
  },
  {
    id: 1437,
    pid: 1428,
    name: "\u80f6\u5dde\u5e02",
    code: "370281",
    level: "district",
  },
  {
    id: 1438,
    pid: 1428,
    name: "\u5e02\u5357\u533a",
    code: "370202",
    level: "district",
  },
  {
    id: 1439,
    pid: 1396,
    name: "\u5a01\u6d77\u5e02",
    code: "371000",
    level: "city",
  },
  {
    id: 1440,
    pid: 1439,
    name: "\u73af\u7fe0\u533a",
    code: "371002",
    level: "district",
  },
  {
    id: 1441,
    pid: 1439,
    name: "\u6587\u767b\u533a",
    code: "371003",
    level: "district",
  },
  {
    id: 1442,
    pid: 1439,
    name: "\u4e73\u5c71\u5e02",
    code: "371083",
    level: "district",
  },
  {
    id: 1443,
    pid: 1439,
    name: "\u8363\u6210\u5e02",
    code: "371082",
    level: "district",
  },
  {
    id: 1444,
    pid: 1396,
    name: "\u70df\u53f0\u5e02",
    code: "370600",
    level: "city",
  },
  {
    id: 1445,
    pid: 1444,
    name: "\u829d\u7f58\u533a",
    code: "370602",
    level: "district",
  },
  {
    id: 1446,
    pid: 1444,
    name: "\u84ec\u83b1\u533a",
    code: "370614",
    level: "district",
  },
  {
    id: 1447,
    pid: 1444,
    name: "\u83b1\u5dde\u5e02",
    code: "370683",
    level: "district",
  },
  {
    id: 1448,
    pid: 1444,
    name: "\u6d77\u9633\u5e02",
    code: "370687",
    level: "district",
  },
  {
    id: 1449,
    pid: 1444,
    name: "\u9f99\u53e3\u5e02",
    code: "370681",
    level: "district",
  },
  {
    id: 1450,
    pid: 1444,
    name: "\u83b1\u9633\u5e02",
    code: "370682",
    level: "district",
  },
  {
    id: 1451,
    pid: 1444,
    name: "\u62db\u8fdc\u5e02",
    code: "370685",
    level: "district",
  },
  {
    id: 1452,
    pid: 1444,
    name: "\u798f\u5c71\u533a",
    code: "370611",
    level: "district",
  },
  {
    id: 1453,
    pid: 1444,
    name: "\u83b1\u5c71\u533a",
    code: "370613",
    level: "district",
  },
  {
    id: 1454,
    pid: 1444,
    name: "\u6816\u971e\u5e02",
    code: "370686",
    level: "district",
  },
  {
    id: 1455,
    pid: 1444,
    name: "\u725f\u5e73\u533a",
    code: "370612",
    level: "district",
  },
  {
    id: 1456,
    pid: 1396,
    name: "\u6f4d\u574a\u5e02",
    code: "370700",
    level: "city",
  },
  {
    id: 1457,
    pid: 1456,
    name: "\u594e\u6587\u533a",
    code: "370705",
    level: "district",
  },
  {
    id: 1458,
    pid: 1456,
    name: "\u4e34\u6710\u53bf",
    code: "370724",
    level: "district",
  },
  {
    id: 1459,
    pid: 1456,
    name: "\u6f4d\u57ce\u533a",
    code: "370702",
    level: "district",
  },
  {
    id: 1460,
    pid: 1456,
    name: "\u5b89\u4e18\u5e02",
    code: "370784",
    level: "district",
  },
  {
    id: 1461,
    pid: 1456,
    name: "\u8bf8\u57ce\u5e02",
    code: "370782",
    level: "district",
  },
  {
    id: 1462,
    pid: 1456,
    name: "\u5bff\u5149\u5e02",
    code: "370783",
    level: "district",
  },
  {
    id: 1463,
    pid: 1456,
    name: "\u5bd2\u4ead\u533a",
    code: "370703",
    level: "district",
  },
  {
    id: 1464,
    pid: 1456,
    name: "\u660c\u9091\u5e02",
    code: "370786",
    level: "district",
  },
  {
    id: 1465,
    pid: 1456,
    name: "\u660c\u4e50\u53bf",
    code: "370725",
    level: "district",
  },
  {
    id: 1466,
    pid: 1456,
    name: "\u9752\u5dde\u5e02",
    code: "370781",
    level: "district",
  },
  {
    id: 1467,
    pid: 1456,
    name: "\u9ad8\u5bc6\u5e02",
    code: "370785",
    level: "district",
  },
  {
    id: 1468,
    pid: 1456,
    name: "\u574a\u5b50\u533a",
    code: "370704",
    level: "district",
  },
  {
    id: 1469,
    pid: 1396,
    name: "\u4e1c\u8425\u5e02",
    code: "370500",
    level: "city",
  },
  {
    id: 1470,
    pid: 1469,
    name: "\u5229\u6d25\u53bf",
    code: "370522",
    level: "district",
  },
  {
    id: 1471,
    pid: 1469,
    name: "\u5e7f\u9976\u53bf",
    code: "370523",
    level: "district",
  },
  {
    id: 1472,
    pid: 1469,
    name: "\u4e1c\u8425\u533a",
    code: "370502",
    level: "district",
  },
  {
    id: 1473,
    pid: 1469,
    name: "\u6cb3\u53e3\u533a",
    code: "370503",
    level: "district",
  },
  {
    id: 1474,
    pid: 1469,
    name: "\u57a6\u5229\u533a",
    code: "370505",
    level: "district",
  },
  {
    id: 1475,
    pid: 1396,
    name: "\u6ee8\u5dde\u5e02",
    code: "371600",
    level: "city",
  },
  {
    id: 1476,
    pid: 1475,
    name: "\u6ee8\u57ce\u533a",
    code: "371602",
    level: "district",
  },
  {
    id: 1477,
    pid: 1475,
    name: "\u6cbe\u5316\u533a",
    code: "371603",
    level: "district",
  },
  {
    id: 1478,
    pid: 1475,
    name: "\u90b9\u5e73\u5e02",
    code: "371681",
    level: "district",
  },
  {
    id: 1479,
    pid: 1475,
    name: "\u535a\u5174\u53bf",
    code: "371625",
    level: "district",
  },
  {
    id: 1480,
    pid: 1475,
    name: "\u65e0\u68e3\u53bf",
    code: "371623",
    level: "district",
  },
  {
    id: 1481,
    pid: 1475,
    name: "\u9633\u4fe1\u53bf",
    code: "371622",
    level: "district",
  },
  {
    id: 1482,
    pid: 1475,
    name: "\u60e0\u6c11\u53bf",
    code: "371621",
    level: "district",
  },
  {
    id: 1483,
    pid: 1396,
    name: "\u65e5\u7167\u5e02",
    code: "371100",
    level: "city",
  },
  {
    id: 1484,
    pid: 1483,
    name: "\u4e94\u83b2\u53bf",
    code: "371121",
    level: "district",
  },
  {
    id: 1485,
    pid: 1483,
    name: "\u4e1c\u6e2f\u533a",
    code: "371102",
    level: "district",
  },
  {
    id: 1486,
    pid: 1483,
    name: "\u8392\u53bf",
    code: "371122",
    level: "district",
  },
  {
    id: 1487,
    pid: 1483,
    name: "\u5c9a\u5c71\u533a",
    code: "371103",
    level: "district",
  },
  {
    id: 1488,
    pid: 1396,
    name: "\u67a3\u5e84\u5e02",
    code: "370400",
    level: "city",
  },
  {
    id: 1489,
    pid: 1488,
    name: "\u5cc4\u57ce\u533a",
    code: "370404",
    level: "district",
  },
  {
    id: 1490,
    pid: 1488,
    name: "\u5c71\u4ead\u533a",
    code: "370406",
    level: "district",
  },
  {
    id: 1491,
    pid: 1488,
    name: "\u859b\u57ce\u533a",
    code: "370403",
    level: "district",
  },
  {
    id: 1492,
    pid: 1488,
    name: "\u5e02\u4e2d\u533a",
    code: "370402",
    level: "district",
  },
  {
    id: 1493,
    pid: 1488,
    name: "\u6ed5\u5dde\u5e02",
    code: "370481",
    level: "district",
  },
  {
    id: 1494,
    pid: 1488,
    name: "\u53f0\u513f\u5e84\u533a",
    code: "370405",
    level: "district",
  },
  {
    id: 1495,
    pid: 1396,
    name: "\u5fb7\u5dde\u5e02",
    code: "371400",
    level: "city",
  },
  {
    id: 1496,
    pid: 1495,
    name: "\u5b81\u6d25\u53bf",
    code: "371422",
    level: "district",
  },
  {
    id: 1497,
    pid: 1495,
    name: "\u4e50\u9675\u5e02",
    code: "371481",
    level: "district",
  },
  {
    id: 1498,
    pid: 1495,
    name: "\u5fb7\u57ce\u533a",
    code: "371402",
    level: "district",
  },
  {
    id: 1499,
    pid: 1495,
    name: "\u6b66\u57ce\u53bf",
    code: "371428",
    level: "district",
  },
  {
    id: 1500,
    pid: 1495,
    name: "\u5e86\u4e91\u53bf",
    code: "371423",
    level: "district",
  },
  {
    id: 1501,
    pid: 1495,
    name: "\u590f\u6d25\u53bf",
    code: "371427",
    level: "district",
  },
  {
    id: 1502,
    pid: 1495,
    name: "\u9f50\u6cb3\u53bf",
    code: "371425",
    level: "district",
  },
  {
    id: 1503,
    pid: 1495,
    name: "\u79b9\u57ce\u5e02",
    code: "371482",
    level: "district",
  },
  {
    id: 1504,
    pid: 1495,
    name: "\u4e34\u9091\u53bf",
    code: "371424",
    level: "district",
  },
  {
    id: 1505,
    pid: 1495,
    name: "\u9675\u57ce\u533a",
    code: "371403",
    level: "district",
  },
  {
    id: 1506,
    pid: 1495,
    name: "\u5e73\u539f\u53bf",
    code: "371426",
    level: "district",
  },
  {
    id: 1507,
    pid: 1396,
    name: "\u6d4e\u5357\u5e02",
    code: "370100",
    level: "city",
  },
  {
    id: 1508,
    pid: 1507,
    name: "\u94a2\u57ce\u533a",
    code: "370117",
    level: "district",
  },
  {
    id: 1509,
    pid: 1507,
    name: "\u83b1\u829c\u533a",
    code: "370116",
    level: "district",
  },
  {
    id: 1510,
    pid: 1507,
    name: "\u7ae0\u4e18\u533a",
    code: "370114",
    level: "district",
  },
  {
    id: 1511,
    pid: 1507,
    name: "\u5929\u6865\u533a",
    code: "370105",
    level: "district",
  },
  {
    id: 1512,
    pid: 1507,
    name: "\u6d4e\u9633\u533a",
    code: "370115",
    level: "district",
  },
  {
    id: 1513,
    pid: 1507,
    name: "\u5386\u57ce\u533a",
    code: "370112",
    level: "district",
  },
  {
    id: 1514,
    pid: 1507,
    name: "\u5386\u4e0b\u533a",
    code: "370102",
    level: "district",
  },
  {
    id: 1515,
    pid: 1507,
    name: "\u5e73\u9634\u53bf",
    code: "370124",
    level: "district",
  },
  {
    id: 1516,
    pid: 1507,
    name: "\u5546\u6cb3\u53bf",
    code: "370126",
    level: "district",
  },
  {
    id: 1517,
    pid: 1507,
    name: "\u5e02\u4e2d\u533a",
    code: "370103",
    level: "district",
  },
  {
    id: 1518,
    pid: 1507,
    name: "\u69d0\u836b\u533a",
    code: "370104",
    level: "district",
  },
  {
    id: 1519,
    pid: 1507,
    name: "\u957f\u6e05\u533a",
    code: "370113",
    level: "district",
  },
  {
    id: 1520,
    pid: 1396,
    name: "\u83cf\u6cfd\u5e02",
    code: "371700",
    level: "city",
  },
  {
    id: 1521,
    pid: 1520,
    name: "\u9104\u57ce\u53bf",
    code: "371726",
    level: "district",
  },
  {
    id: 1522,
    pid: 1520,
    name: "\u7261\u4e39\u533a",
    code: "371702",
    level: "district",
  },
  {
    id: 1523,
    pid: 1520,
    name: "\u4e1c\u660e\u53bf",
    code: "371728",
    level: "district",
  },
  {
    id: 1524,
    pid: 1520,
    name: "\u5b9a\u9676\u533a",
    code: "371703",
    level: "district",
  },
  {
    id: 1525,
    pid: 1520,
    name: "\u90d3\u57ce\u53bf",
    code: "371725",
    level: "district",
  },
  {
    id: 1526,
    pid: 1520,
    name: "\u5de8\u91ce\u53bf",
    code: "371724",
    level: "district",
  },
  {
    id: 1527,
    pid: 1520,
    name: "\u6210\u6b66\u53bf",
    code: "371723",
    level: "district",
  },
  {
    id: 1528,
    pid: 1520,
    name: "\u66f9\u53bf",
    code: "371721",
    level: "district",
  },
  {
    id: 1529,
    pid: 1520,
    name: "\u5355\u53bf",
    code: "371722",
    level: "district",
  },
  {
    id: 1530,
    pid: 1396,
    name: "\u6cf0\u5b89\u5e02",
    code: "370900",
    level: "city",
  },
  {
    id: 1531,
    pid: 1530,
    name: "\u65b0\u6cf0\u5e02",
    code: "370982",
    level: "district",
  },
  {
    id: 1532,
    pid: 1530,
    name: "\u4e1c\u5e73\u53bf",
    code: "370923",
    level: "district",
  },
  {
    id: 1533,
    pid: 1530,
    name: "\u6cf0\u5c71\u533a",
    code: "370902",
    level: "district",
  },
  {
    id: 1534,
    pid: 1530,
    name: "\u5cb1\u5cb3\u533a",
    code: "370911",
    level: "district",
  },
  {
    id: 1535,
    pid: 1530,
    name: "\u80a5\u57ce\u5e02",
    code: "370983",
    level: "district",
  },
  {
    id: 1536,
    pid: 1530,
    name: "\u5b81\u9633\u53bf",
    code: "370921",
    level: "district",
  },
  {
    id: 1537,
    pid: 1396,
    name: "\u6d4e\u5b81\u5e02",
    code: "370800",
    level: "city",
  },
  {
    id: 1538,
    pid: 1537,
    name: "\u6c76\u4e0a\u53bf",
    code: "370830",
    level: "district",
  },
  {
    id: 1539,
    pid: 1537,
    name: "\u90b9\u57ce\u5e02",
    code: "370883",
    level: "district",
  },
  {
    id: 1540,
    pid: 1537,
    name: "\u6cd7\u6c34\u53bf",
    code: "370831",
    level: "district",
  },
  {
    id: 1541,
    pid: 1537,
    name: "\u66f2\u961c\u5e02",
    code: "370881",
    level: "district",
  },
  {
    id: 1542,
    pid: 1537,
    name: "\u4efb\u57ce\u533a",
    code: "370811",
    level: "district",
  },
  {
    id: 1543,
    pid: 1537,
    name: "\u5609\u7965\u53bf",
    code: "370829",
    level: "district",
  },
  {
    id: 1544,
    pid: 1537,
    name: "\u6881\u5c71\u53bf",
    code: "370832",
    level: "district",
  },
  {
    id: 1545,
    pid: 1537,
    name: "\u9c7c\u53f0\u53bf",
    code: "370827",
    level: "district",
  },
  {
    id: 1546,
    pid: 1537,
    name: "\u91d1\u4e61\u53bf",
    code: "370828",
    level: "district",
  },
  {
    id: 1547,
    pid: 1537,
    name: "\u5fae\u5c71\u53bf",
    code: "370826",
    level: "district",
  },
  {
    id: 1548,
    pid: 1537,
    name: "\u5156\u5dde\u533a",
    code: "370812",
    level: "district",
  },
  {
    id: 1549,
    pid: 0,
    name: "\u5b89\u5fbd\u7701",
    code: "340000",
    level: "province",
  },
  {
    id: 1550,
    pid: 1549,
    name: "\u6dee\u5317\u5e02",
    code: "340600",
    level: "city",
  },
  {
    id: 1551,
    pid: 1550,
    name: "\u675c\u96c6\u533a",
    code: "340602",
    level: "district",
  },
  {
    id: 1552,
    pid: 1550,
    name: "\u70c8\u5c71\u533a",
    code: "340604",
    level: "district",
  },
  {
    id: 1553,
    pid: 1550,
    name: "\u76f8\u5c71\u533a",
    code: "340603",
    level: "district",
  },
  {
    id: 1554,
    pid: 1550,
    name: "\u6fc9\u6eaa\u53bf",
    code: "340621",
    level: "district",
  },
  {
    id: 1555,
    pid: 1549,
    name: "\u94dc\u9675\u5e02",
    code: "340700",
    level: "city",
  },
  {
    id: 1556,
    pid: 1555,
    name: "\u90ca\u533a",
    code: "340711",
    level: "district",
  },
  {
    id: 1557,
    pid: 1555,
    name: "\u94dc\u5b98\u533a",
    code: "340705",
    level: "district",
  },
  {
    id: 1558,
    pid: 1555,
    name: "\u679e\u9633\u53bf",
    code: "340722",
    level: "district",
  },
  {
    id: 1559,
    pid: 1555,
    name: "\u4e49\u5b89\u533a",
    code: "340706",
    level: "district",
  },
  {
    id: 1560,
    pid: 1549,
    name: "\u5b89\u5e86\u5e02",
    code: "340800",
    level: "city",
  },
  {
    id: 1561,
    pid: 1560,
    name: "\u6f5c\u5c71\u5e02",
    code: "340882",
    level: "district",
  },
  {
    id: 1562,
    pid: 1560,
    name: "\u5bbf\u677e\u53bf",
    code: "340826",
    level: "district",
  },
  {
    id: 1563,
    pid: 1560,
    name: "\u5b9c\u79c0\u533a",
    code: "340811",
    level: "district",
  },
  {
    id: 1564,
    pid: 1560,
    name: "\u592a\u6e56\u53bf",
    code: "340825",
    level: "district",
  },
  {
    id: 1565,
    pid: 1560,
    name: "\u5cb3\u897f\u53bf",
    code: "340828",
    level: "district",
  },
  {
    id: 1566,
    pid: 1560,
    name: "\u8fce\u6c5f\u533a",
    code: "340802",
    level: "district",
  },
  {
    id: 1567,
    pid: 1560,
    name: "\u6850\u57ce\u5e02",
    code: "340881",
    level: "district",
  },
  {
    id: 1568,
    pid: 1560,
    name: "\u671b\u6c5f\u53bf",
    code: "340827",
    level: "district",
  },
  {
    id: 1569,
    pid: 1560,
    name: "\u6000\u5b81\u53bf",
    code: "340822",
    level: "district",
  },
  {
    id: 1570,
    pid: 1560,
    name: "\u5927\u89c2\u533a",
    code: "340803",
    level: "district",
  },
  {
    id: 1571,
    pid: 1549,
    name: "\u6c60\u5dde\u5e02",
    code: "341700",
    level: "city",
  },
  {
    id: 1572,
    pid: 1571,
    name: "\u9752\u9633\u53bf",
    code: "341723",
    level: "district",
  },
  {
    id: 1573,
    pid: 1571,
    name: "\u4e1c\u81f3\u53bf",
    code: "341721",
    level: "district",
  },
  {
    id: 1574,
    pid: 1571,
    name: "\u8d35\u6c60\u533a",
    code: "341702",
    level: "district",
  },
  {
    id: 1575,
    pid: 1571,
    name: "\u77f3\u53f0\u53bf",
    code: "341722",
    level: "district",
  },
  {
    id: 1576,
    pid: 1549,
    name: "\u9a6c\u978d\u5c71\u5e02",
    code: "340500",
    level: "city",
  },
  {
    id: 1577,
    pid: 1576,
    name: "\u535a\u671b\u533a",
    code: "340506",
    level: "district",
  },
  {
    id: 1578,
    pid: 1576,
    name: "\u5f53\u6d82\u53bf",
    code: "340521",
    level: "district",
  },
  {
    id: 1579,
    pid: 1576,
    name: "\u96e8\u5c71\u533a",
    code: "340504",
    level: "district",
  },
  {
    id: 1580,
    pid: 1576,
    name: "\u82b1\u5c71\u533a",
    code: "340503",
    level: "district",
  },
  {
    id: 1581,
    pid: 1576,
    name: "\u548c\u53bf",
    code: "340523",
    level: "district",
  },
  {
    id: 1582,
    pid: 1576,
    name: "\u542b\u5c71\u53bf",
    code: "340522",
    level: "district",
  },
  {
    id: 1583,
    pid: 1549,
    name: "\u961c\u9633\u5e02",
    code: "341200",
    level: "city",
  },
  {
    id: 1584,
    pid: 1583,
    name: "\u592a\u548c\u53bf",
    code: "341222",
    level: "district",
  },
  {
    id: 1585,
    pid: 1583,
    name: "\u988d\u4e1c\u533a",
    code: "341203",
    level: "district",
  },
  {
    id: 1586,
    pid: 1583,
    name: "\u4e34\u6cc9\u53bf",
    code: "341221",
    level: "district",
  },
  {
    id: 1587,
    pid: 1583,
    name: "\u988d\u6cc9\u533a",
    code: "341204",
    level: "district",
  },
  {
    id: 1588,
    pid: 1583,
    name: "\u988d\u5dde\u533a",
    code: "341202",
    level: "district",
  },
  {
    id: 1589,
    pid: 1583,
    name: "\u961c\u5357\u53bf",
    code: "341225",
    level: "district",
  },
  {
    id: 1590,
    pid: 1583,
    name: "\u988d\u4e0a\u53bf",
    code: "341226",
    level: "district",
  },
  {
    id: 1591,
    pid: 1583,
    name: "\u754c\u9996\u5e02",
    code: "341282",
    level: "district",
  },
  {
    id: 1592,
    pid: 1549,
    name: "\u9ec4\u5c71\u5e02",
    code: "341000",
    level: "city",
  },
  {
    id: 1593,
    pid: 1592,
    name: "\u9ec4\u5c71\u533a",
    code: "341003",
    level: "district",
  },
  {
    id: 1594,
    pid: 1592,
    name: "\u9edf\u53bf",
    code: "341023",
    level: "district",
  },
  {
    id: 1595,
    pid: 1592,
    name: "\u7941\u95e8\u53bf",
    code: "341024",
    level: "district",
  },
  {
    id: 1596,
    pid: 1592,
    name: "\u6b59\u53bf",
    code: "341021",
    level: "district",
  },
  {
    id: 1597,
    pid: 1592,
    name: "\u5fbd\u5dde\u533a",
    code: "341004",
    level: "district",
  },
  {
    id: 1598,
    pid: 1592,
    name: "\u4f11\u5b81\u53bf",
    code: "341022",
    level: "district",
  },
  {
    id: 1599,
    pid: 1592,
    name: "\u5c6f\u6eaa\u533a",
    code: "341002",
    level: "district",
  },
  {
    id: 1600,
    pid: 1549,
    name: "\u516d\u5b89\u5e02",
    code: "341500",
    level: "city",
  },
  {
    id: 1601,
    pid: 1600,
    name: "\u970d\u5c71\u53bf",
    code: "341525",
    level: "district",
  },
  {
    id: 1602,
    pid: 1600,
    name: "\u88d5\u5b89\u533a",
    code: "341503",
    level: "district",
  },
  {
    id: 1603,
    pid: 1600,
    name: "\u91d1\u5be8\u53bf",
    code: "341524",
    level: "district",
  },
  {
    id: 1604,
    pid: 1600,
    name: "\u91d1\u5b89\u533a",
    code: "341502",
    level: "district",
  },
  {
    id: 1605,
    pid: 1600,
    name: "\u53f6\u96c6\u533a",
    code: "341504",
    level: "district",
  },
  {
    id: 1606,
    pid: 1600,
    name: "\u970d\u90b1\u53bf",
    code: "341522",
    level: "district",
  },
  {
    id: 1607,
    pid: 1600,
    name: "\u8212\u57ce\u53bf",
    code: "341523",
    level: "district",
  },
  {
    id: 1608,
    pid: 1549,
    name: "\u6dee\u5357\u5e02",
    code: "340400",
    level: "city",
  },
  {
    id: 1609,
    pid: 1608,
    name: "\u51e4\u53f0\u53bf",
    code: "340421",
    level: "district",
  },
  {
    id: 1610,
    pid: 1608,
    name: "\u516b\u516c\u5c71\u533a",
    code: "340405",
    level: "district",
  },
  {
    id: 1611,
    pid: 1608,
    name: "\u6f58\u96c6\u533a",
    code: "340406",
    level: "district",
  },
  {
    id: 1612,
    pid: 1608,
    name: "\u5927\u901a\u533a",
    code: "340402",
    level: "district",
  },
  {
    id: 1613,
    pid: 1608,
    name: "\u8c22\u5bb6\u96c6\u533a",
    code: "340404",
    level: "district",
  },
  {
    id: 1614,
    pid: 1608,
    name: "\u7530\u5bb6\u5eb5\u533a",
    code: "340403",
    level: "district",
  },
  {
    id: 1615,
    pid: 1608,
    name: "\u5bff\u53bf",
    code: "340422",
    level: "district",
  },
  {
    id: 1616,
    pid: 1549,
    name: "\u868c\u57e0\u5e02",
    code: "340300",
    level: "city",
  },
  {
    id: 1617,
    pid: 1616,
    name: "\u6dee\u4e0a\u533a",
    code: "340311",
    level: "district",
  },
  {
    id: 1618,
    pid: 1616,
    name: "\u4e94\u6cb3\u53bf",
    code: "340322",
    level: "district",
  },
  {
    id: 1619,
    pid: 1616,
    name: "\u6000\u8fdc\u53bf",
    code: "340321",
    level: "district",
  },
  {
    id: 1620,
    pid: 1616,
    name: "\u79b9\u4f1a\u533a",
    code: "340304",
    level: "district",
  },
  {
    id: 1621,
    pid: 1616,
    name: "\u868c\u5c71\u533a",
    code: "340303",
    level: "district",
  },
  {
    id: 1622,
    pid: 1616,
    name: "\u9f99\u5b50\u6e56\u533a",
    code: "340302",
    level: "district",
  },
  {
    id: 1623,
    pid: 1616,
    name: "\u56fa\u9547\u53bf",
    code: "340323",
    level: "district",
  },
  {
    id: 1624,
    pid: 1549,
    name: "\u829c\u6e56\u5e02",
    code: "340200",
    level: "city",
  },
  {
    id: 1625,
    pid: 1624,
    name: "\u7e41\u660c\u53bf",
    code: "340222",
    level: "district",
  },
  {
    id: 1626,
    pid: 1624,
    name: "\u5f0b\u6c5f\u533a",
    code: "340203",
    level: "district",
  },
  {
    id: 1627,
    pid: 1624,
    name: "\u955c\u6e56\u533a",
    code: "340202",
    level: "district",
  },
  {
    id: 1628,
    pid: 1624,
    name: "\u65e0\u4e3a\u5e02",
    code: "340281",
    level: "district",
  },
  {
    id: 1629,
    pid: 1624,
    name: "\u4e09\u5c71\u533a",
    code: "340208",
    level: "district",
  },
  {
    id: 1630,
    pid: 1624,
    name: "\u9e20\u6c5f\u533a",
    code: "340207",
    level: "district",
  },
  {
    id: 1631,
    pid: 1624,
    name: "\u829c\u6e56\u53bf",
    code: "340221",
    level: "district",
  },
  {
    id: 1632,
    pid: 1624,
    name: "\u5357\u9675\u53bf",
    code: "340223",
    level: "district",
  },
  {
    id: 1633,
    pid: 1549,
    name: "\u6ec1\u5dde\u5e02",
    code: "341100",
    level: "city",
  },
  {
    id: 1634,
    pid: 1633,
    name: "\u660e\u5149\u5e02",
    code: "341182",
    level: "district",
  },
  {
    id: 1635,
    pid: 1633,
    name: "\u5929\u957f\u5e02",
    code: "341181",
    level: "district",
  },
  {
    id: 1636,
    pid: 1633,
    name: "\u51e4\u9633\u53bf",
    code: "341126",
    level: "district",
  },
  {
    id: 1637,
    pid: 1633,
    name: "\u5b9a\u8fdc\u53bf",
    code: "341125",
    level: "district",
  },
  {
    id: 1638,
    pid: 1633,
    name: "\u6765\u5b89\u53bf",
    code: "341122",
    level: "district",
  },
  {
    id: 1639,
    pid: 1633,
    name: "\u5357\u8c2f\u533a",
    code: "341103",
    level: "district",
  },
  {
    id: 1640,
    pid: 1633,
    name: "\u7405\u740a\u533a",
    code: "341102",
    level: "district",
  },
  {
    id: 1641,
    pid: 1633,
    name: "\u5168\u6912\u53bf",
    code: "341124",
    level: "district",
  },
  {
    id: 1642,
    pid: 1549,
    name: "\u5408\u80a5\u5e02",
    code: "340100",
    level: "city",
  },
  {
    id: 1643,
    pid: 1642,
    name: "\u5e90\u6c5f\u53bf",
    code: "340124",
    level: "district",
  },
  {
    id: 1644,
    pid: 1642,
    name: "\u5305\u6cb3\u533a",
    code: "340111",
    level: "district",
  },
  {
    id: 1645,
    pid: 1642,
    name: "\u5de2\u6e56\u5e02",
    code: "340181",
    level: "district",
  },
  {
    id: 1646,
    pid: 1642,
    name: "\u80a5\u897f\u53bf",
    code: "340123",
    level: "district",
  },
  {
    id: 1647,
    pid: 1642,
    name: "\u8700\u5c71\u533a",
    code: "340104",
    level: "district",
  },
  {
    id: 1648,
    pid: 1642,
    name: "\u5e90\u9633\u533a",
    code: "340103",
    level: "district",
  },
  {
    id: 1649,
    pid: 1642,
    name: "\u957f\u4e30\u53bf",
    code: "340121",
    level: "district",
  },
  {
    id: 1650,
    pid: 1642,
    name: "\u80a5\u4e1c\u53bf",
    code: "340122",
    level: "district",
  },
  {
    id: 1651,
    pid: 1642,
    name: "\u7476\u6d77\u533a",
    code: "340102",
    level: "district",
  },
  {
    id: 1652,
    pid: 1549,
    name: "\u5ba3\u57ce\u5e02",
    code: "341800",
    level: "city",
  },
  {
    id: 1653,
    pid: 1652,
    name: "\u5e7f\u5fb7\u5e02",
    code: "341882",
    level: "district",
  },
  {
    id: 1654,
    pid: 1652,
    name: "\u7ee9\u6eaa\u53bf",
    code: "341824",
    level: "district",
  },
  {
    id: 1655,
    pid: 1652,
    name: "\u65cc\u5fb7\u53bf",
    code: "341825",
    level: "district",
  },
  {
    id: 1656,
    pid: 1652,
    name: "\u5ba3\u5dde\u533a",
    code: "341802",
    level: "district",
  },
  {
    id: 1657,
    pid: 1652,
    name: "\u5b81\u56fd\u5e02",
    code: "341881",
    level: "district",
  },
  {
    id: 1658,
    pid: 1652,
    name: "\u90ce\u6eaa\u53bf",
    code: "341821",
    level: "district",
  },
  {
    id: 1659,
    pid: 1652,
    name: "\u6cfe\u53bf",
    code: "341823",
    level: "district",
  },
  {
    id: 1660,
    pid: 1549,
    name: "\u4eb3\u5dde\u5e02",
    code: "341600",
    level: "city",
  },
  {
    id: 1661,
    pid: 1660,
    name: "\u8c2f\u57ce\u533a",
    code: "341602",
    level: "district",
  },
  {
    id: 1662,
    pid: 1660,
    name: "\u5229\u8f9b\u53bf",
    code: "341623",
    level: "district",
  },
  {
    id: 1663,
    pid: 1660,
    name: "\u8499\u57ce\u53bf",
    code: "341622",
    level: "district",
  },
  {
    id: 1664,
    pid: 1660,
    name: "\u6da1\u9633\u53bf",
    code: "341621",
    level: "district",
  },
  {
    id: 1665,
    pid: 1549,
    name: "\u5bbf\u5dde\u5e02",
    code: "341300",
    level: "city",
  },
  {
    id: 1666,
    pid: 1665,
    name: "\u57c7\u6865\u533a",
    code: "341302",
    level: "district",
  },
  {
    id: 1667,
    pid: 1665,
    name: "\u6cd7\u53bf",
    code: "341324",
    level: "district",
  },
  {
    id: 1668,
    pid: 1665,
    name: "\u8427\u53bf",
    code: "341322",
    level: "district",
  },
  {
    id: 1669,
    pid: 1665,
    name: "\u7075\u74a7\u53bf",
    code: "341323",
    level: "district",
  },
  {
    id: 1670,
    pid: 1665,
    name: "\u7800\u5c71\u53bf",
    code: "341321",
    level: "district",
  },
  {
    id: 1671,
    pid: 0,
    name: "\u91cd\u5e86\u5e02",
    code: "500000",
    level: "province",
  },
  {
    id: 1672,
    pid: 1671,
    name: "\u91cd\u5e86\u90ca\u53bf",
    code: "500200",
    level: "city",
  },
  {
    id: 1673,
    pid: 1672,
    name: "\u5949\u8282\u53bf",
    code: "500236",
    level: "district",
  },
  {
    id: 1674,
    pid: 1672,
    name: "\u5deb\u6eaa\u53bf",
    code: "500238",
    level: "district",
  },
  {
    id: 1675,
    pid: 1672,
    name: "\u57ce\u53e3\u53bf",
    code: "500229",
    level: "district",
  },
  {
    id: 1676,
    pid: 1672,
    name: "\u4e30\u90fd\u53bf",
    code: "500230",
    level: "district",
  },
  {
    id: 1677,
    pid: 1672,
    name: "\u5f6d\u6c34\u82d7\u65cf\u571f\u5bb6\u65cf\u81ea\u6cbb\u53bf",
    code: "500243",
    level: "district",
  },
  {
    id: 1678,
    pid: 1672,
    name: "\u79c0\u5c71\u571f\u5bb6\u65cf\u82d7\u65cf\u81ea\u6cbb\u53bf",
    code: "500241",
    level: "district",
  },
  {
    id: 1679,
    pid: 1672,
    name: "\u4e91\u9633\u53bf",
    code: "500235",
    level: "district",
  },
  {
    id: 1680,
    pid: 1672,
    name: "\u5deb\u5c71\u53bf",
    code: "500237",
    level: "district",
  },
  {
    id: 1681,
    pid: 1672,
    name: "\u9149\u9633\u571f\u5bb6\u65cf\u82d7\u65cf\u81ea\u6cbb\u53bf",
    code: "500242",
    level: "district",
  },
  {
    id: 1682,
    pid: 1672,
    name: "\u57ab\u6c5f\u53bf",
    code: "500231",
    level: "district",
  },
  {
    id: 1683,
    pid: 1672,
    name: "\u5fe0\u53bf",
    code: "500233",
    level: "district",
  },
  {
    id: 1684,
    pid: 1672,
    name: "\u77f3\u67f1\u571f\u5bb6\u65cf\u81ea\u6cbb\u53bf",
    code: "500240",
    level: "district",
  },
  {
    id: 1685,
    pid: 1671,
    name: "\u91cd\u5e86\u57ce\u533a",
    code: "500100",
    level: "city",
  },
  {
    id: 1686,
    pid: 1685,
    name: "\u5408\u5ddd\u533a",
    code: "500117",
    level: "district",
  },
  {
    id: 1687,
    pid: 1685,
    name: "\u6f7c\u5357\u533a",
    code: "500152",
    level: "district",
  },
  {
    id: 1688,
    pid: 1685,
    name: "\u94dc\u6881\u533a",
    code: "500151",
    level: "district",
  },
  {
    id: 1689,
    pid: 1685,
    name: "\u957f\u5bff\u533a",
    code: "500115",
    level: "district",
  },
  {
    id: 1690,
    pid: 1685,
    name: "\u74a7\u5c71\u533a",
    code: "500120",
    level: "district",
  },
  {
    id: 1691,
    pid: 1685,
    name: "\u5927\u8db3\u533a",
    code: "500111",
    level: "district",
  },
  {
    id: 1692,
    pid: 1685,
    name: "\u8363\u660c\u533a",
    code: "500153",
    level: "district",
  },
  {
    id: 1693,
    pid: 1685,
    name: "\u6c38\u5ddd\u533a",
    code: "500118",
    level: "district",
  },
  {
    id: 1694,
    pid: 1685,
    name: "\u6e1d\u4e2d\u533a",
    code: "500103",
    level: "district",
  },
  {
    id: 1695,
    pid: 1685,
    name: "\u6b66\u9686\u533a",
    code: "500156",
    level: "district",
  },
  {
    id: 1696,
    pid: 1685,
    name: "\u5357\u5ddd\u533a",
    code: "500119",
    level: "district",
  },
  {
    id: 1697,
    pid: 1685,
    name: "\u4e5d\u9f99\u5761\u533a",
    code: "500107",
    level: "district",
  },
  {
    id: 1698,
    pid: 1685,
    name: "\u5927\u6e21\u53e3\u533a",
    code: "500104",
    level: "district",
  },
  {
    id: 1699,
    pid: 1685,
    name: "\u4e07\u5dde\u533a",
    code: "500101",
    level: "district",
  },
  {
    id: 1700,
    pid: 1685,
    name: "\u6daa\u9675\u533a",
    code: "500102",
    level: "district",
  },
  {
    id: 1701,
    pid: 1685,
    name: "\u7da6\u6c5f\u533a",
    code: "500110",
    level: "district",
  },
  {
    id: 1702,
    pid: 1685,
    name: "\u6881\u5e73\u533a",
    code: "500155",
    level: "district",
  },
  {
    id: 1703,
    pid: 1685,
    name: "\u5f00\u5dde\u533a",
    code: "500154",
    level: "district",
  },
  {
    id: 1704,
    pid: 1685,
    name: "\u6c5f\u6d25\u533a",
    code: "500116",
    level: "district",
  },
  {
    id: 1705,
    pid: 1685,
    name: "\u9ed4\u6c5f\u533a",
    code: "500114",
    level: "district",
  },
  {
    id: 1706,
    pid: 1685,
    name: "\u5357\u5cb8\u533a",
    code: "500108",
    level: "district",
  },
  {
    id: 1707,
    pid: 1685,
    name: "\u5df4\u5357\u533a",
    code: "500113",
    level: "district",
  },
  {
    id: 1708,
    pid: 1685,
    name: "\u6c99\u576a\u575d\u533a",
    code: "500106",
    level: "district",
  },
  {
    id: 1709,
    pid: 1685,
    name: "\u5317\u789a\u533a",
    code: "500109",
    level: "district",
  },
  {
    id: 1710,
    pid: 1685,
    name: "\u6e1d\u5317\u533a",
    code: "500112",
    level: "district",
  },
  {
    id: 1711,
    pid: 1685,
    name: "\u6c5f\u5317\u533a",
    code: "500105",
    level: "district",
  },
  {
    id: 1712,
    pid: 0,
    name: "\u897f\u85cf\u81ea\u6cbb\u533a",
    code: "540000",
    level: "province",
  },
  {
    id: 1713,
    pid: 1712,
    name: "\u65e5\u5580\u5219\u5e02",
    code: "540200",
    level: "city",
  },
  {
    id: 1714,
    pid: 1713,
    name: "\u4ef2\u5df4\u53bf",
    code: "540232",
    level: "district",
  },
  {
    id: 1715,
    pid: 1713,
    name: "\u6602\u4ec1\u53bf",
    code: "540226",
    level: "district",
  },
  {
    id: 1716,
    pid: 1713,
    name: "\u8c22\u901a\u95e8\u53bf",
    code: "540227",
    level: "district",
  },
  {
    id: 1717,
    pid: 1713,
    name: "\u8428\u560e\u53bf",
    code: "540236",
    level: "district",
  },
  {
    id: 1718,
    pid: 1713,
    name: "\u5357\u6728\u6797\u53bf",
    code: "540221",
    level: "district",
  },
  {
    id: 1719,
    pid: 1713,
    name: "\u6851\u73e0\u5b5c\u533a",
    code: "540202",
    level: "district",
  },
  {
    id: 1720,
    pid: 1713,
    name: "\u62c9\u5b5c\u53bf",
    code: "540225",
    level: "district",
  },
  {
    id: 1721,
    pid: 1713,
    name: "\u4ec1\u5e03\u53bf",
    code: "540229",
    level: "district",
  },
  {
    id: 1722,
    pid: 1713,
    name: "\u8428\u8fe6\u53bf",
    code: "540224",
    level: "district",
  },
  {
    id: 1723,
    pid: 1713,
    name: "\u5409\u9686\u53bf",
    code: "540234",
    level: "district",
  },
  {
    id: 1724,
    pid: 1713,
    name: "\u767d\u6717\u53bf",
    code: "540228",
    level: "district",
  },
  {
    id: 1725,
    pid: 1713,
    name: "\u6c5f\u5b5c\u53bf",
    code: "540222",
    level: "district",
  },
  {
    id: 1726,
    pid: 1713,
    name: "\u5b9a\u65e5\u53bf",
    code: "540223",
    level: "district",
  },
  {
    id: 1727,
    pid: 1713,
    name: "\u8042\u62c9\u6728\u53bf",
    code: "540235",
    level: "district",
  },
  {
    id: 1728,
    pid: 1713,
    name: "\u4e9a\u4e1c\u53bf",
    code: "540233",
    level: "district",
  },
  {
    id: 1729,
    pid: 1713,
    name: "\u5c97\u5df4\u53bf",
    code: "540237",
    level: "district",
  },
  {
    id: 1730,
    pid: 1713,
    name: "\u5eb7\u9a6c\u53bf",
    code: "540230",
    level: "district",
  },
  {
    id: 1731,
    pid: 1713,
    name: "\u5b9a\u7ed3\u53bf",
    code: "540231",
    level: "district",
  },
  {
    id: 1732,
    pid: 1712,
    name: "\u5c71\u5357\u5e02",
    code: "540500",
    level: "city",
  },
  {
    id: 1733,
    pid: 1732,
    name: "\u6851\u65e5\u53bf",
    code: "540523",
    level: "district",
  },
  {
    id: 1734,
    pid: 1732,
    name: "\u624e\u56ca\u53bf",
    code: "540521",
    level: "district",
  },
  {
    id: 1735,
    pid: 1732,
    name: "\u4e43\u4e1c\u533a",
    code: "540502",
    level: "district",
  },
  {
    id: 1736,
    pid: 1732,
    name: "\u52a0\u67e5\u53bf",
    code: "540528",
    level: "district",
  },
  {
    id: 1737,
    pid: 1732,
    name: "\u743c\u7ed3\u53bf",
    code: "540524",
    level: "district",
  },
  {
    id: 1738,
    pid: 1732,
    name: "\u8d21\u560e\u53bf",
    code: "540522",
    level: "district",
  },
  {
    id: 1739,
    pid: 1732,
    name: "\u6d6a\u5361\u5b50\u53bf",
    code: "540531",
    level: "district",
  },
  {
    id: 1740,
    pid: 1732,
    name: "\u66f2\u677e\u53bf",
    code: "540525",
    level: "district",
  },
  {
    id: 1741,
    pid: 1732,
    name: "\u63aa\u7f8e\u53bf",
    code: "540526",
    level: "district",
  },
  {
    id: 1742,
    pid: 1732,
    name: "\u9519\u90a3\u53bf",
    code: "540530",
    level: "district",
  },
  {
    id: 1743,
    pid: 1732,
    name: "\u9686\u5b50\u53bf",
    code: "540529",
    level: "district",
  },
  {
    id: 1744,
    pid: 1732,
    name: "\u6d1b\u624e\u53bf",
    code: "540527",
    level: "district",
  },
  {
    id: 1745,
    pid: 1712,
    name: "\u90a3\u66f2\u5e02",
    code: "540600",
    level: "city",
  },
  {
    id: 1746,
    pid: 1745,
    name: "\u5b89\u591a\u53bf",
    code: "540624",
    level: "district",
  },
  {
    id: 1747,
    pid: 1745,
    name: "\u8042\u8363\u53bf",
    code: "540623",
    level: "district",
  },
  {
    id: 1748,
    pid: 1745,
    name: "\u5df4\u9752\u53bf",
    code: "540628",
    level: "district",
  },
  {
    id: 1749,
    pid: 1745,
    name: "\u7533\u624e\u53bf",
    code: "540625",
    level: "district",
  },
  {
    id: 1750,
    pid: 1745,
    name: "\u73ed\u6208\u53bf",
    code: "540627",
    level: "district",
  },
  {
    id: 1751,
    pid: 1745,
    name: "\u6bd4\u5982\u53bf",
    code: "540622",
    level: "district",
  },
  {
    id: 1752,
    pid: 1745,
    name: "\u7d22\u53bf",
    code: "540626",
    level: "district",
  },
  {
    id: 1753,
    pid: 1745,
    name: "\u5609\u9ece\u53bf",
    code: "540621",
    level: "district",
  },
  {
    id: 1754,
    pid: 1745,
    name: "\u8272\u5c3c\u533a",
    code: "540602",
    level: "district",
  },
  {
    id: 1755,
    pid: 1745,
    name: "\u5c3c\u739b\u53bf",
    code: "540629",
    level: "district",
  },
  {
    id: 1756,
    pid: 1745,
    name: "\u53cc\u6e56\u53bf",
    code: "540630",
    level: "district",
  },
  {
    id: 1757,
    pid: 1712,
    name: "\u660c\u90fd\u5e02",
    code: "540300",
    level: "city",
  },
  {
    id: 1758,
    pid: 1757,
    name: "\u4e01\u9752\u53bf",
    code: "540324",
    level: "district",
  },
  {
    id: 1759,
    pid: 1757,
    name: "\u5361\u82e5\u533a",
    code: "540302",
    level: "district",
  },
  {
    id: 1760,
    pid: 1757,
    name: "\u6c5f\u8fbe\u53bf",
    code: "540321",
    level: "district",
  },
  {
    id: 1761,
    pid: 1757,
    name: "\u7c7b\u4e4c\u9f50\u53bf",
    code: "540323",
    level: "district",
  },
  {
    id: 1762,
    pid: 1757,
    name: "\u8fb9\u575d\u53bf",
    code: "540330",
    level: "district",
  },
  {
    id: 1763,
    pid: 1757,
    name: "\u8d21\u89c9\u53bf",
    code: "540322",
    level: "district",
  },
  {
    id: 1764,
    pid: 1757,
    name: "\u6d1b\u9686\u53bf",
    code: "540329",
    level: "district",
  },
  {
    id: 1765,
    pid: 1757,
    name: "\u5bdf\u96c5\u53bf",
    code: "540325",
    level: "district",
  },
  {
    id: 1766,
    pid: 1757,
    name: "\u516b\u5bbf\u53bf",
    code: "540326",
    level: "district",
  },
  {
    id: 1767,
    pid: 1757,
    name: "\u5de6\u8d21\u53bf",
    code: "540327",
    level: "district",
  },
  {
    id: 1768,
    pid: 1757,
    name: "\u8292\u5eb7\u53bf",
    code: "540328",
    level: "district",
  },
  {
    id: 1769,
    pid: 1712,
    name: "\u62c9\u8428\u5e02",
    code: "540100",
    level: "city",
  },
  {
    id: 1770,
    pid: 1769,
    name: "\u5f53\u96c4\u53bf",
    code: "540122",
    level: "district",
  },
  {
    id: 1771,
    pid: 1769,
    name: "\u6797\u5468\u53bf",
    code: "540121",
    level: "district",
  },
  {
    id: 1772,
    pid: 1769,
    name: "\u5c3c\u6728\u53bf",
    code: "540123",
    level: "district",
  },
  {
    id: 1773,
    pid: 1769,
    name: "\u8fbe\u5b5c\u533a",
    code: "540104",
    level: "district",
  },
  {
    id: 1774,
    pid: 1769,
    name: "\u58a8\u7af9\u5de5\u5361\u53bf",
    code: "540127",
    level: "district",
  },
  {
    id: 1775,
    pid: 1769,
    name: "\u5806\u9f99\u5fb7\u5e86\u533a",
    code: "540103",
    level: "district",
  },
  {
    id: 1776,
    pid: 1769,
    name: "\u66f2\u6c34\u53bf",
    code: "540124",
    level: "district",
  },
  {
    id: 1777,
    pid: 1769,
    name: "\u57ce\u5173\u533a",
    code: "540102",
    level: "district",
  },
  {
    id: 1778,
    pid: 1712,
    name: "\u6797\u829d\u5e02",
    code: "540400",
    level: "city",
  },
  {
    id: 1779,
    pid: 1778,
    name: "\u6ce2\u5bc6\u53bf",
    code: "540424",
    level: "district",
  },
  {
    id: 1780,
    pid: 1778,
    name: "\u5de5\u5e03\u6c5f\u8fbe\u53bf",
    code: "540421",
    level: "district",
  },
  {
    id: 1781,
    pid: 1778,
    name: "\u58a8\u8131\u53bf",
    code: "540423",
    level: "district",
  },
  {
    id: 1782,
    pid: 1778,
    name: "\u5df4\u5b9c\u533a",
    code: "540402",
    level: "district",
  },
  {
    id: 1783,
    pid: 1778,
    name: "\u7c73\u6797\u53bf",
    code: "540422",
    level: "district",
  },
  {
    id: 1784,
    pid: 1778,
    name: "\u5bdf\u9685\u53bf",
    code: "540425",
    level: "district",
  },
  {
    id: 1785,
    pid: 1778,
    name: "\u6717\u53bf",
    code: "540426",
    level: "district",
  },
  {
    id: 1786,
    pid: 1712,
    name: "\u963f\u91cc\u5730\u533a",
    code: "542500",
    level: "city",
  },
  {
    id: 1787,
    pid: 1786,
    name: "\u6539\u5219\u53bf",
    code: "542526",
    level: "district",
  },
  {
    id: 1788,
    pid: 1786,
    name: "\u672d\u8fbe\u53bf",
    code: "542522",
    level: "district",
  },
  {
    id: 1789,
    pid: 1786,
    name: "\u63aa\u52e4\u53bf",
    code: "542527",
    level: "district",
  },
  {
    id: 1790,
    pid: 1786,
    name: "\u666e\u5170\u53bf",
    code: "542521",
    level: "district",
  },
  {
    id: 1791,
    pid: 1786,
    name: "\u5676\u5c14\u53bf",
    code: "542523",
    level: "district",
  },
  {
    id: 1792,
    pid: 1786,
    name: "\u9769\u5409\u53bf",
    code: "542525",
    level: "district",
  },
  {
    id: 1793,
    pid: 1786,
    name: "\u65e5\u571f\u53bf",
    code: "542524",
    level: "district",
  },
  {
    id: 1794,
    pid: 0,
    name: "\u6e56\u5357\u7701",
    code: "430000",
    level: "province",
  },
  {
    id: 1795,
    pid: 1794,
    name: "\u5cb3\u9633\u5e02",
    code: "430600",
    level: "city",
  },
  {
    id: 1796,
    pid: 1795,
    name: "\u541b\u5c71\u533a",
    code: "430611",
    level: "district",
  },
  {
    id: 1797,
    pid: 1795,
    name: "\u4e91\u6eaa\u533a",
    code: "430603",
    level: "district",
  },
  {
    id: 1798,
    pid: 1795,
    name: "\u534e\u5bb9\u53bf",
    code: "430623",
    level: "district",
  },
  {
    id: 1799,
    pid: 1795,
    name: "\u5cb3\u9633\u697c\u533a",
    code: "430602",
    level: "district",
  },
  {
    id: 1800,
    pid: 1795,
    name: "\u6c68\u7f57\u5e02",
    code: "430681",
    level: "district",
  },
  {
    id: 1801,
    pid: 1795,
    name: "\u6e58\u9634\u53bf",
    code: "430624",
    level: "district",
  },
  {
    id: 1802,
    pid: 1795,
    name: "\u4e34\u6e58\u5e02",
    code: "430682",
    level: "district",
  },
  {
    id: 1803,
    pid: 1795,
    name: "\u5cb3\u9633\u53bf",
    code: "430621",
    level: "district",
  },
  {
    id: 1804,
    pid: 1795,
    name: "\u5e73\u6c5f\u53bf",
    code: "430626",
    level: "district",
  },
  {
    id: 1805,
    pid: 1794,
    name: "\u76ca\u9633\u5e02",
    code: "430900",
    level: "city",
  },
  {
    id: 1806,
    pid: 1805,
    name: "\u8d44\u9633\u533a",
    code: "430902",
    level: "district",
  },
  {
    id: 1807,
    pid: 1805,
    name: "\u6843\u6c5f\u53bf",
    code: "430922",
    level: "district",
  },
  {
    id: 1808,
    pid: 1805,
    name: "\u8d6b\u5c71\u533a",
    code: "430903",
    level: "district",
  },
  {
    id: 1809,
    pid: 1805,
    name: "\u5b89\u5316\u53bf",
    code: "430923",
    level: "district",
  },
  {
    id: 1810,
    pid: 1805,
    name: "\u6c85\u6c5f\u5e02",
    code: "430981",
    level: "district",
  },
  {
    id: 1811,
    pid: 1805,
    name: "\u5357\u53bf",
    code: "430921",
    level: "district",
  },
  {
    id: 1812,
    pid: 1794,
    name: "\u8861\u9633\u5e02",
    code: "430400",
    level: "city",
  },
  {
    id: 1813,
    pid: 1812,
    name: "\u8861\u5c71\u53bf",
    code: "430423",
    level: "district",
  },
  {
    id: 1814,
    pid: 1812,
    name: "\u8861\u4e1c\u53bf",
    code: "430424",
    level: "district",
  },
  {
    id: 1815,
    pid: 1812,
    name: "\u8861\u9633\u53bf",
    code: "430421",
    level: "district",
  },
  {
    id: 1816,
    pid: 1812,
    name: "\u5357\u5cb3\u533a",
    code: "430412",
    level: "district",
  },
  {
    id: 1817,
    pid: 1812,
    name: "\u8012\u9633\u5e02",
    code: "430481",
    level: "district",
  },
  {
    id: 1818,
    pid: 1812,
    name: "\u96c1\u5cf0\u533a",
    code: "430406",
    level: "district",
  },
  {
    id: 1819,
    pid: 1812,
    name: "\u73e0\u6656\u533a",
    code: "430405",
    level: "district",
  },
  {
    id: 1820,
    pid: 1812,
    name: "\u77f3\u9f13\u533a",
    code: "430407",
    level: "district",
  },
  {
    id: 1821,
    pid: 1812,
    name: "\u8861\u5357\u53bf",
    code: "430422",
    level: "district",
  },
  {
    id: 1822,
    pid: 1812,
    name: "\u7941\u4e1c\u53bf",
    code: "430426",
    level: "district",
  },
  {
    id: 1823,
    pid: 1812,
    name: "\u84b8\u6e58\u533a",
    code: "430408",
    level: "district",
  },
  {
    id: 1824,
    pid: 1812,
    name: "\u5e38\u5b81\u5e02",
    code: "430482",
    level: "district",
  },
  {
    id: 1825,
    pid: 1794,
    name: "\u5a04\u5e95\u5e02",
    code: "431300",
    level: "city",
  },
  {
    id: 1826,
    pid: 1825,
    name: "\u51b7\u6c34\u6c5f\u5e02",
    code: "431381",
    level: "district",
  },
  {
    id: 1827,
    pid: 1825,
    name: "\u65b0\u5316\u53bf",
    code: "431322",
    level: "district",
  },
  {
    id: 1828,
    pid: 1825,
    name: "\u6d9f\u6e90\u5e02",
    code: "431382",
    level: "district",
  },
  {
    id: 1829,
    pid: 1825,
    name: "\u53cc\u5cf0\u53bf",
    code: "431321",
    level: "district",
  },
  {
    id: 1830,
    pid: 1825,
    name: "\u5a04\u661f\u533a",
    code: "431302",
    level: "district",
  },
  {
    id: 1831,
    pid: 1794,
    name: "\u5e38\u5fb7\u5e02",
    code: "430700",
    level: "city",
  },
  {
    id: 1832,
    pid: 1831,
    name: "\u77f3\u95e8\u53bf",
    code: "430726",
    level: "district",
  },
  {
    id: 1833,
    pid: 1831,
    name: "\u6fa7\u53bf",
    code: "430723",
    level: "district",
  },
  {
    id: 1834,
    pid: 1831,
    name: "\u6d25\u5e02\u5e02",
    code: "430781",
    level: "district",
  },
  {
    id: 1835,
    pid: 1831,
    name: "\u4e34\u6fa7\u53bf",
    code: "430724",
    level: "district",
  },
  {
    id: 1836,
    pid: 1831,
    name: "\u6c49\u5bff\u53bf",
    code: "430722",
    level: "district",
  },
  {
    id: 1837,
    pid: 1831,
    name: "\u9f0e\u57ce\u533a",
    code: "430703",
    level: "district",
  },
  {
    id: 1838,
    pid: 1831,
    name: "\u6b66\u9675\u533a",
    code: "430702",
    level: "district",
  },
  {
    id: 1839,
    pid: 1831,
    name: "\u6843\u6e90\u53bf",
    code: "430725",
    level: "district",
  },
  {
    id: 1840,
    pid: 1831,
    name: "\u5b89\u4e61\u53bf",
    code: "430721",
    level: "district",
  },
  {
    id: 1841,
    pid: 1794,
    name: "\u5f20\u5bb6\u754c\u5e02",
    code: "430800",
    level: "city",
  },
  {
    id: 1842,
    pid: 1841,
    name: "\u6b66\u9675\u6e90\u533a",
    code: "430811",
    level: "district",
  },
  {
    id: 1843,
    pid: 1841,
    name: "\u6148\u5229\u53bf",
    code: "430821",
    level: "district",
  },
  {
    id: 1844,
    pid: 1841,
    name: "\u6851\u690d\u53bf",
    code: "430822",
    level: "district",
  },
  {
    id: 1845,
    pid: 1841,
    name: "\u6c38\u5b9a\u533a",
    code: "430802",
    level: "district",
  },
  {
    id: 1846,
    pid: 1794,
    name: "\u6000\u5316\u5e02",
    code: "431200",
    level: "city",
  },
  {
    id: 1847,
    pid: 1846,
    name: "\u6d2a\u6c5f\u5e02",
    code: "431281",
    level: "district",
  },
  {
    id: 1848,
    pid: 1846,
    name: "\u82b7\u6c5f\u4f97\u65cf\u81ea\u6cbb\u53bf",
    code: "431228",
    level: "district",
  },
  {
    id: 1849,
    pid: 1846,
    name: "\u901a\u9053\u4f97\u65cf\u81ea\u6cbb\u53bf",
    code: "431230",
    level: "district",
  },
  {
    id: 1850,
    pid: 1846,
    name: "\u8fb0\u6eaa\u53bf",
    code: "431223",
    level: "district",
  },
  {
    id: 1851,
    pid: 1846,
    name: "\u6c85\u9675\u53bf",
    code: "431222",
    level: "district",
  },
  {
    id: 1852,
    pid: 1846,
    name: "\u6e86\u6d66\u53bf",
    code: "431224",
    level: "district",
  },
  {
    id: 1853,
    pid: 1846,
    name: "\u9756\u5dde\u82d7\u65cf\u4f97\u65cf\u81ea\u6cbb\u53bf",
    code: "431229",
    level: "district",
  },
  {
    id: 1854,
    pid: 1846,
    name: "\u4f1a\u540c\u53bf",
    code: "431225",
    level: "district",
  },
  {
    id: 1855,
    pid: 1846,
    name: "\u65b0\u6643\u4f97\u65cf\u81ea\u6cbb\u53bf",
    code: "431227",
    level: "district",
  },
  {
    id: 1856,
    pid: 1846,
    name: "\u9ebb\u9633\u82d7\u65cf\u81ea\u6cbb\u53bf",
    code: "431226",
    level: "district",
  },
  {
    id: 1857,
    pid: 1846,
    name: "\u4e2d\u65b9\u53bf",
    code: "431221",
    level: "district",
  },
  {
    id: 1858,
    pid: 1846,
    name: "\u9e64\u57ce\u533a",
    code: "431202",
    level: "district",
  },
  {
    id: 1859,
    pid: 1794,
    name: "\u6e58\u897f\u571f\u5bb6\u65cf\u82d7\u65cf\u81ea\u6cbb\u5dde",
    code: "433100",
    level: "city",
  },
  {
    id: 1860,
    pid: 1859,
    name: "\u6c38\u987a\u53bf",
    code: "433127",
    level: "district",
  },
  {
    id: 1861,
    pid: 1859,
    name: "\u4fdd\u9756\u53bf",
    code: "433125",
    level: "district",
  },
  {
    id: 1862,
    pid: 1859,
    name: "\u82b1\u57a3\u53bf",
    code: "433124",
    level: "district",
  },
  {
    id: 1863,
    pid: 1859,
    name: "\u5409\u9996\u5e02",
    code: "433101",
    level: "district",
  },
  {
    id: 1864,
    pid: 1859,
    name: "\u53e4\u4e08\u53bf",
    code: "433126",
    level: "district",
  },
  {
    id: 1865,
    pid: 1859,
    name: "\u6cf8\u6eaa\u53bf",
    code: "433122",
    level: "district",
  },
  {
    id: 1866,
    pid: 1859,
    name: "\u9f99\u5c71\u53bf",
    code: "433130",
    level: "district",
  },
  {
    id: 1867,
    pid: 1859,
    name: "\u51e4\u51f0\u53bf",
    code: "433123",
    level: "district",
  },
  {
    id: 1868,
    pid: 1794,
    name: "\u957f\u6c99\u5e02",
    code: "430100",
    level: "city",
  },
  {
    id: 1869,
    pid: 1868,
    name: "\u5f00\u798f\u533a",
    code: "430105",
    level: "district",
  },
  {
    id: 1870,
    pid: 1868,
    name: "\u5cb3\u9e93\u533a",
    code: "430104",
    level: "district",
  },
  {
    id: 1871,
    pid: 1868,
    name: "\u5929\u5fc3\u533a",
    code: "430103",
    level: "district",
  },
  {
    id: 1872,
    pid: 1868,
    name: "\u6d4f\u9633\u5e02",
    code: "430181",
    level: "district",
  },
  {
    id: 1873,
    pid: 1868,
    name: "\u957f\u6c99\u53bf",
    code: "430121",
    level: "district",
  },
  {
    id: 1874,
    pid: 1868,
    name: "\u671b\u57ce\u533a",
    code: "430112",
    level: "district",
  },
  {
    id: 1875,
    pid: 1868,
    name: "\u5b81\u4e61\u5e02",
    code: "430182",
    level: "district",
  },
  {
    id: 1876,
    pid: 1868,
    name: "\u8299\u84c9\u533a",
    code: "430102",
    level: "district",
  },
  {
    id: 1877,
    pid: 1868,
    name: "\u96e8\u82b1\u533a",
    code: "430111",
    level: "district",
  },
  {
    id: 1878,
    pid: 1794,
    name: "\u6e58\u6f6d\u5e02",
    code: "430300",
    level: "city",
  },
  {
    id: 1879,
    pid: 1878,
    name: "\u6e58\u6f6d\u53bf",
    code: "430321",
    level: "district",
  },
  {
    id: 1880,
    pid: 1878,
    name: "\u96e8\u6e56\u533a",
    code: "430302",
    level: "district",
  },
  {
    id: 1881,
    pid: 1878,
    name: "\u5cb3\u5858\u533a",
    code: "430304",
    level: "district",
  },
  {
    id: 1882,
    pid: 1878,
    name: "\u97f6\u5c71\u5e02",
    code: "430382",
    level: "district",
  },
  {
    id: 1883,
    pid: 1878,
    name: "\u6e58\u4e61\u5e02",
    code: "430381",
    level: "district",
  },
  {
    id: 1884,
    pid: 1794,
    name: "\u682a\u6d32\u5e02",
    code: "430200",
    level: "city",
  },
  {
    id: 1885,
    pid: 1884,
    name: "\u8377\u5858\u533a",
    code: "430202",
    level: "district",
  },
  {
    id: 1886,
    pid: 1884,
    name: "\u6538\u53bf",
    code: "430223",
    level: "district",
  },
  {
    id: 1887,
    pid: 1884,
    name: "\u8336\u9675\u53bf",
    code: "430224",
    level: "district",
  },
  {
    id: 1888,
    pid: 1884,
    name: "\u708e\u9675\u53bf",
    code: "430225",
    level: "district",
  },
  {
    id: 1889,
    pid: 1884,
    name: "\u6e0c\u53e3\u533a",
    code: "430212",
    level: "district",
  },
  {
    id: 1890,
    pid: 1884,
    name: "\u82a6\u6dde\u533a",
    code: "430203",
    level: "district",
  },
  {
    id: 1891,
    pid: 1884,
    name: "\u5929\u5143\u533a",
    code: "430211",
    level: "district",
  },
  {
    id: 1892,
    pid: 1884,
    name: "\u77f3\u5cf0\u533a",
    code: "430204",
    level: "district",
  },
  {
    id: 1893,
    pid: 1884,
    name: "\u91b4\u9675\u5e02",
    code: "430281",
    level: "district",
  },
  {
    id: 1894,
    pid: 1794,
    name: "\u90b5\u9633\u5e02",
    code: "430500",
    level: "city",
  },
  {
    id: 1895,
    pid: 1894,
    name: "\u9686\u56de\u53bf",
    code: "430524",
    level: "district",
  },
  {
    id: 1896,
    pid: 1894,
    name: "\u5927\u7965\u533a",
    code: "430503",
    level: "district",
  },
  {
    id: 1897,
    pid: 1894,
    name: "\u7ee5\u5b81\u53bf",
    code: "430527",
    level: "district",
  },
  {
    id: 1898,
    pid: 1894,
    name: "\u5317\u5854\u533a",
    code: "430511",
    level: "district",
  },
  {
    id: 1899,
    pid: 1894,
    name: "\u53cc\u6e05\u533a",
    code: "430502",
    level: "district",
  },
  {
    id: 1900,
    pid: 1894,
    name: "\u90b5\u9633\u53bf",
    code: "430523",
    level: "district",
  },
  {
    id: 1901,
    pid: 1894,
    name: "\u57ce\u6b65\u82d7\u65cf\u81ea\u6cbb\u53bf",
    code: "430529",
    level: "district",
  },
  {
    id: 1902,
    pid: 1894,
    name: "\u90b5\u4e1c\u5e02",
    code: "430582",
    level: "district",
  },
  {
    id: 1903,
    pid: 1894,
    name: "\u65b0\u90b5\u53bf",
    code: "430522",
    level: "district",
  },
  {
    id: 1904,
    pid: 1894,
    name: "\u65b0\u5b81\u53bf",
    code: "430528",
    level: "district",
  },
  {
    id: 1905,
    pid: 1894,
    name: "\u6b66\u5188\u5e02",
    code: "430581",
    level: "district",
  },
  {
    id: 1906,
    pid: 1894,
    name: "\u6d1e\u53e3\u53bf",
    code: "430525",
    level: "district",
  },
  {
    id: 1907,
    pid: 1794,
    name: "\u90f4\u5dde\u5e02",
    code: "431000",
    level: "city",
  },
  {
    id: 1908,
    pid: 1907,
    name: "\u5b89\u4ec1\u53bf",
    code: "431028",
    level: "district",
  },
  {
    id: 1909,
    pid: 1907,
    name: "\u6c5d\u57ce\u53bf",
    code: "431026",
    level: "district",
  },
  {
    id: 1910,
    pid: 1907,
    name: "\u6c38\u5174\u53bf",
    code: "431023",
    level: "district",
  },
  {
    id: 1911,
    pid: 1907,
    name: "\u82cf\u4ed9\u533a",
    code: "431003",
    level: "district",
  },
  {
    id: 1912,
    pid: 1907,
    name: "\u8d44\u5174\u5e02",
    code: "431081",
    level: "district",
  },
  {
    id: 1913,
    pid: 1907,
    name: "\u5317\u6e56\u533a",
    code: "431002",
    level: "district",
  },
  {
    id: 1914,
    pid: 1907,
    name: "\u6842\u4e1c\u53bf",
    code: "431027",
    level: "district",
  },
  {
    id: 1915,
    pid: 1907,
    name: "\u4e34\u6b66\u53bf",
    code: "431025",
    level: "district",
  },
  {
    id: 1916,
    pid: 1907,
    name: "\u5b9c\u7ae0\u53bf",
    code: "431022",
    level: "district",
  },
  {
    id: 1917,
    pid: 1907,
    name: "\u5609\u79be\u53bf",
    code: "431024",
    level: "district",
  },
  {
    id: 1918,
    pid: 1907,
    name: "\u6842\u9633\u53bf",
    code: "431021",
    level: "district",
  },
  {
    id: 1919,
    pid: 1794,
    name: "\u6c38\u5dde\u5e02",
    code: "431100",
    level: "city",
  },
  {
    id: 1920,
    pid: 1919,
    name: "\u51b7\u6c34\u6ee9\u533a",
    code: "431103",
    level: "district",
  },
  {
    id: 1921,
    pid: 1919,
    name: "\u7941\u9633\u53bf",
    code: "431121",
    level: "district",
  },
  {
    id: 1922,
    pid: 1919,
    name: "\u4e1c\u5b89\u53bf",
    code: "431122",
    level: "district",
  },
  {
    id: 1923,
    pid: 1919,
    name: "\u96f6\u9675\u533a",
    code: "431102",
    level: "district",
  },
  {
    id: 1924,
    pid: 1919,
    name: "\u65b0\u7530\u53bf",
    code: "431128",
    level: "district",
  },
  {
    id: 1925,
    pid: 1919,
    name: "\u5b81\u8fdc\u53bf",
    code: "431126",
    level: "district",
  },
  {
    id: 1926,
    pid: 1919,
    name: "\u53cc\u724c\u53bf",
    code: "431123",
    level: "district",
  },
  {
    id: 1927,
    pid: 1919,
    name: "\u84dd\u5c71\u53bf",
    code: "431127",
    level: "district",
  },
  {
    id: 1928,
    pid: 1919,
    name: "\u9053\u53bf",
    code: "431124",
    level: "district",
  },
  {
    id: 1929,
    pid: 1919,
    name: "\u6c5f\u6c38\u53bf",
    code: "431125",
    level: "district",
  },
  {
    id: 1930,
    pid: 1919,
    name: "\u6c5f\u534e\u7476\u65cf\u81ea\u6cbb\u53bf",
    code: "431129",
    level: "district",
  },
  {
    id: 1931,
    pid: 0,
    name: "\u6d77\u5357\u7701",
    code: "460000",
    level: "province",
  },
  {
    id: 1932,
    pid: 1931,
    name: "\u4e1c\u65b9\u5e02",
    code: "469007",
    level: "city",
  },
  {
    id: 1945,
    pid: 1931,
    name: "\u5c6f\u660c\u53bf",
    code: "469022",
    level: "city",
  },
  {
    id: 1956,
    pid: 1931,
    name: "\u4e07\u5b81\u5e02",
    code: "469006",
    level: "city",
  },
  {
    id: 1974,
    pid: 1931,
    name: "\u4e34\u9ad8\u53bf",
    code: "469024",
    level: "city",
  },
  {
    id: 1987,
    pid: 1931,
    name: "\u660c\u6c5f\u9ece\u65cf\u81ea\u6cbb\u53bf",
    code: "469026",
    level: "city",
  },
  {
    id: 1999,
    pid: 1931,
    name: "\u5b9a\u5b89\u53bf",
    code: "469021",
    level: "city",
  },
  {
    id: 2013,
    pid: 1931,
    name: "\u767d\u6c99\u9ece\u65cf\u81ea\u6cbb\u53bf",
    code: "469025",
    level: "city",
  },
  {
    id: 2028,
    pid: 1931,
    name: "\u743c\u6d77\u5e02",
    code: "469002",
    level: "city",
  },
  {
    id: 2045,
    pid: 1931,
    name: "\u743c\u4e2d\u9ece\u65cf\u82d7\u65cf\u81ea\u6cbb\u53bf",
    code: "469030",
    level: "city",
  },
  {
    id: 2061,
    pid: 1931,
    name: "\u510b\u5dde\u5e02",
    code: "460400",
    level: "city",
  },
  {
    id: 2085,
    pid: 1931,
    name: "\u4e50\u4e1c\u9ece\u65cf\u81ea\u6cbb\u53bf",
    code: "469027",
    level: "city",
  },
  {
    id: 2102,
    pid: 1931,
    name: "\u6f84\u8fc8\u53bf",
    code: "469023",
    level: "city",
  },
  {
    id: 2120,
    pid: 1931,
    name: "\u4e09\u4e9a\u5e02",
    code: "460200",
    level: "city",
  },
  {
    id: 2121,
    pid: 2120,
    name: "\u5929\u6daf\u533a",
    code: "460204",
    level: "district",
  },
  {
    id: 2122,
    pid: 2120,
    name: "\u6d77\u68e0\u533a",
    code: "460202",
    level: "district",
  },
  {
    id: 2123,
    pid: 2120,
    name: "\u5409\u9633\u533a",
    code: "460203",
    level: "district",
  },
  {
    id: 2124,
    pid: 2120,
    name: "\u5d16\u5dde\u533a",
    code: "460205",
    level: "district",
  },
  {
    id: 2125,
    pid: 1931,
    name: "\u9675\u6c34\u9ece\u65cf\u81ea\u6cbb\u53bf",
    code: "469028",
    level: "city",
  },
  {
    id: 2140,
    pid: 1931,
    name: "\u6587\u660c\u5e02",
    code: "469005",
    level: "city",
  },
  {
    id: 2161,
    pid: 1931,
    name: "\u4e09\u6c99\u5e02",
    code: "460300",
    level: "city",
  },
  {
    id: 2162,
    pid: 2161,
    name: "\u897f\u6c99\u533a",
    code: "460301",
    level: "district",
  },
  {
    id: 2163,
    pid: 2161,
    name: "\u5357\u6c99\u533a",
    code: "460302",
    level: "district",
  },
  {
    id: 2164,
    pid: 1931,
    name: "\u6d77\u53e3\u5e02",
    code: "460100",
    level: "city",
  },
  {
    id: 2165,
    pid: 2164,
    name: "\u7f8e\u5170\u533a",
    code: "460108",
    level: "district",
  },
  {
    id: 2166,
    pid: 2164,
    name: "\u9f99\u534e\u533a",
    code: "460106",
    level: "district",
  },
  {
    id: 2167,
    pid: 2164,
    name: "\u743c\u5c71\u533a",
    code: "460107",
    level: "district",
  },
  {
    id: 2168,
    pid: 2164,
    name: "\u79c0\u82f1\u533a",
    code: "460105",
    level: "district",
  },
  {
    id: 2169,
    pid: 1931,
    name: "\u4fdd\u4ead\u9ece\u65cf\u82d7\u65cf\u81ea\u6cbb\u53bf",
    code: "469029",
    level: "city",
  },
  {
    id: 2183,
    pid: 1931,
    name: "\u4e94\u6307\u5c71\u5e02",
    code: "469001",
    level: "city",
  },
  {
    id: 2192,
    pid: 0,
    name: "\u9752\u6d77\u7701",
    code: "630000",
    level: "province",
  },
  {
    id: 2193,
    pid: 2192,
    name: "\u679c\u6d1b\u85cf\u65cf\u81ea\u6cbb\u5dde",
    code: "632600",
    level: "city",
  },
  {
    id: 2194,
    pid: 2193,
    name: "\u7518\u5fb7\u53bf",
    code: "632623",
    level: "district",
  },
  {
    id: 2195,
    pid: 2193,
    name: "\u739b\u6c81\u53bf",
    code: "632621",
    level: "district",
  },
  {
    id: 2196,
    pid: 2193,
    name: "\u8fbe\u65e5\u53bf",
    code: "632624",
    level: "district",
  },
  {
    id: 2197,
    pid: 2193,
    name: "\u4e45\u6cbb\u53bf",
    code: "632625",
    level: "district",
  },
  {
    id: 2198,
    pid: 2193,
    name: "\u73ed\u739b\u53bf",
    code: "632622",
    level: "district",
  },
  {
    id: 2199,
    pid: 2193,
    name: "\u739b\u591a\u53bf",
    code: "632626",
    level: "district",
  },
  {
    id: 2200,
    pid: 2192,
    name: "\u6d77\u5357\u85cf\u65cf\u81ea\u6cbb\u5dde",
    code: "632500",
    level: "city",
  },
  {
    id: 2201,
    pid: 2200,
    name: "\u8d35\u5fb7\u53bf",
    code: "632523",
    level: "district",
  },
  {
    id: 2202,
    pid: 2200,
    name: "\u5171\u548c\u53bf",
    code: "632521",
    level: "district",
  },
  {
    id: 2203,
    pid: 2200,
    name: "\u8d35\u5357\u53bf",
    code: "632525",
    level: "district",
  },
  {
    id: 2204,
    pid: 2200,
    name: "\u540c\u5fb7\u53bf",
    code: "632522",
    level: "district",
  },
  {
    id: 2205,
    pid: 2200,
    name: "\u5174\u6d77\u53bf",
    code: "632524",
    level: "district",
  },
  {
    id: 2206,
    pid: 2192,
    name: "\u7389\u6811\u85cf\u65cf\u81ea\u6cbb\u5dde",
    code: "632700",
    level: "city",
  },
  {
    id: 2207,
    pid: 2206,
    name: "\u79f0\u591a\u53bf",
    code: "632723",
    level: "district",
  },
  {
    id: 2208,
    pid: 2206,
    name: "\u6cbb\u591a\u53bf",
    code: "632724",
    level: "district",
  },
  {
    id: 2209,
    pid: 2206,
    name: "\u66f2\u9ebb\u83b1\u53bf",
    code: "632726",
    level: "district",
  },
  {
    id: 2210,
    pid: 2206,
    name: "\u7389\u6811\u5e02",
    code: "632701",
    level: "district",
  },
  {
    id: 2211,
    pid: 2206,
    name: "\u6742\u591a\u53bf",
    code: "632722",
    level: "district",
  },
  {
    id: 2212,
    pid: 2206,
    name: "\u56ca\u8c26\u53bf",
    code: "632725",
    level: "district",
  },
  {
    id: 2213,
    pid: 2192,
    name: "\u9ec4\u5357\u85cf\u65cf\u81ea\u6cbb\u5dde",
    code: "632300",
    level: "city",
  },
  {
    id: 2214,
    pid: 2213,
    name: "\u5c16\u624e\u53bf",
    code: "632322",
    level: "district",
  },
  {
    id: 2215,
    pid: 2213,
    name: "\u540c\u4ec1\u53bf",
    code: "632321",
    level: "district",
  },
  {
    id: 2216,
    pid: 2213,
    name: "\u6cb3\u5357\u8499\u53e4\u65cf\u81ea\u6cbb\u53bf",
    code: "632324",
    level: "district",
  },
  {
    id: 2217,
    pid: 2213,
    name: "\u6cfd\u5e93\u53bf",
    code: "632323",
    level: "district",
  },
  {
    id: 2218,
    pid: 2192,
    name: "\u6d77\u897f\u8499\u53e4\u65cf\u85cf\u65cf\u81ea\u6cbb\u5dde",
    code: "632800",
    level: "city",
  },
  {
    id: 2219,
    pid: 2218,
    name: "\u5929\u5cfb\u53bf",
    code: "632823",
    level: "district",
  },
  {
    id: 2220,
    pid: 2218,
    name: "\u5fb7\u4ee4\u54c8\u5e02",
    code: "632802",
    level: "district",
  },
  {
    id: 2221,
    pid: 2218,
    name: "\u90fd\u5170\u53bf",
    code: "632822",
    level: "district",
  },
  {
    id: 2222,
    pid: 2218,
    name: "\u683c\u5c14\u6728\u5e02",
    code: "632801",
    level: "district",
  },
  {
    id: 2223,
    pid: 2218,
    name:
      "\u6d77\u897f\u8499\u53e4\u65cf\u85cf\u65cf\u81ea\u6cbb\u5dde\u76f4\u8f96",
    code: "632825",
    level: "district",
  },
  {
    id: 2224,
    pid: 2218,
    name: "\u4e4c\u5170\u53bf",
    code: "632821",
    level: "district",
  },
  {
    id: 2225,
    pid: 2218,
    name: "\u832b\u5d16\u5e02",
    code: "632803",
    level: "district",
  },
  {
    id: 2226,
    pid: 2192,
    name: "\u6d77\u4e1c\u5e02",
    code: "630200",
    level: "city",
  },
  {
    id: 2227,
    pid: 2226,
    name: "\u5316\u9686\u56de\u65cf\u81ea\u6cbb\u53bf",
    code: "630224",
    level: "district",
  },
  {
    id: 2228,
    pid: 2226,
    name: "\u6c11\u548c\u56de\u65cf\u571f\u65cf\u81ea\u6cbb\u53bf",
    code: "630222",
    level: "district",
  },
  {
    id: 2229,
    pid: 2226,
    name: "\u4e50\u90fd\u533a",
    code: "630202",
    level: "district",
  },
  {
    id: 2230,
    pid: 2226,
    name: "\u5faa\u5316\u6492\u62c9\u65cf\u81ea\u6cbb\u53bf",
    code: "630225",
    level: "district",
  },
  {
    id: 2231,
    pid: 2226,
    name: "\u5e73\u5b89\u533a",
    code: "630203",
    level: "district",
  },
  {
    id: 2232,
    pid: 2226,
    name: "\u4e92\u52a9\u571f\u65cf\u81ea\u6cbb\u53bf",
    code: "630223",
    level: "district",
  },
  {
    id: 2233,
    pid: 2192,
    name: "\u897f\u5b81\u5e02",
    code: "630100",
    level: "city",
  },
  {
    id: 2234,
    pid: 2233,
    name: "\u6e5f\u6e90\u53bf",
    code: "630123",
    level: "district",
  },
  {
    id: 2235,
    pid: 2233,
    name: "\u57ce\u4e1c\u533a",
    code: "630102",
    level: "district",
  },
  {
    id: 2236,
    pid: 2233,
    name: "\u5927\u901a\u56de\u65cf\u571f\u65cf\u81ea\u6cbb\u53bf",
    code: "630121",
    level: "district",
  },
  {
    id: 2237,
    pid: 2233,
    name: "\u57ce\u897f\u533a",
    code: "630104",
    level: "district",
  },
  {
    id: 2238,
    pid: 2233,
    name: "\u6e5f\u4e2d\u533a",
    code: "630106",
    level: "district",
  },
  {
    id: 2239,
    pid: 2233,
    name: "\u57ce\u4e2d\u533a",
    code: "630103",
    level: "district",
  },
  {
    id: 2240,
    pid: 2233,
    name: "\u57ce\u5317\u533a",
    code: "630105",
    level: "district",
  },
  {
    id: 2241,
    pid: 2192,
    name: "\u6d77\u5317\u85cf\u65cf\u81ea\u6cbb\u5dde",
    code: "632200",
    level: "city",
  },
  {
    id: 2242,
    pid: 2241,
    name: "\u6d77\u664f\u53bf",
    code: "632223",
    level: "district",
  },
  {
    id: 2243,
    pid: 2241,
    name: "\u95e8\u6e90\u56de\u65cf\u81ea\u6cbb\u53bf",
    code: "632221",
    level: "district",
  },
  {
    id: 2244,
    pid: 2241,
    name: "\u521a\u5bdf\u53bf",
    code: "632224",
    level: "district",
  },
  {
    id: 2245,
    pid: 2241,
    name: "\u7941\u8fde\u53bf",
    code: "632222",
    level: "district",
  },
  {
    id: 2246,
    pid: 0,
    name: "\u798f\u5efa\u7701",
    code: "350000",
    level: "province",
  },
  {
    id: 2247,
    pid: 2246,
    name: "\u5b81\u5fb7\u5e02",
    code: "350900",
    level: "city",
  },
  {
    id: 2248,
    pid: 2247,
    name: "\u5bff\u5b81\u53bf",
    code: "350924",
    level: "district",
  },
  {
    id: 2249,
    pid: 2247,
    name: "\u5c4f\u5357\u53bf",
    code: "350923",
    level: "district",
  },
  {
    id: 2250,
    pid: 2247,
    name: "\u798f\u9f0e\u5e02",
    code: "350982",
    level: "district",
  },
  {
    id: 2251,
    pid: 2247,
    name: "\u798f\u5b89\u5e02",
    code: "350981",
    level: "district",
  },
  {
    id: 2252,
    pid: 2247,
    name: "\u53e4\u7530\u53bf",
    code: "350922",
    level: "district",
  },
  {
    id: 2253,
    pid: 2247,
    name: "\u5468\u5b81\u53bf",
    code: "350925",
    level: "district",
  },
  {
    id: 2254,
    pid: 2247,
    name: "\u8549\u57ce\u533a",
    code: "350902",
    level: "district",
  },
  {
    id: 2255,
    pid: 2247,
    name: "\u971e\u6d66\u53bf",
    code: "350921",
    level: "district",
  },
  {
    id: 2256,
    pid: 2247,
    name: "\u67d8\u8363\u53bf",
    code: "350926",
    level: "district",
  },
  {
    id: 2257,
    pid: 2246,
    name: "\u5357\u5e73\u5e02",
    code: "350700",
    level: "city",
  },
  {
    id: 2258,
    pid: 2257,
    name: "\u6d66\u57ce\u53bf",
    code: "350722",
    level: "district",
  },
  {
    id: 2259,
    pid: 2257,
    name: "\u6b66\u5937\u5c71\u5e02",
    code: "350782",
    level: "district",
  },
  {
    id: 2260,
    pid: 2257,
    name: "\u5efa\u9633\u533a",
    code: "350703",
    level: "district",
  },
  {
    id: 2261,
    pid: 2257,
    name: "\u5149\u6cfd\u53bf",
    code: "350723",
    level: "district",
  },
  {
    id: 2262,
    pid: 2257,
    name: "\u677e\u6eaa\u53bf",
    code: "350724",
    level: "district",
  },
  {
    id: 2263,
    pid: 2257,
    name: "\u5efa\u74ef\u5e02",
    code: "350783",
    level: "district",
  },
  {
    id: 2264,
    pid: 2257,
    name: "\u987a\u660c\u53bf",
    code: "350721",
    level: "district",
  },
  {
    id: 2265,
    pid: 2257,
    name: "\u90b5\u6b66\u5e02",
    code: "350781",
    level: "district",
  },
  {
    id: 2266,
    pid: 2257,
    name: "\u653f\u548c\u53bf",
    code: "350725",
    level: "district",
  },
  {
    id: 2267,
    pid: 2257,
    name: "\u5ef6\u5e73\u533a",
    code: "350702",
    level: "district",
  },
  {
    id: 2268,
    pid: 2246,
    name: "\u8386\u7530\u5e02",
    code: "350300",
    level: "city",
  },
  {
    id: 2269,
    pid: 2268,
    name: "\u4ed9\u6e38\u53bf",
    code: "350322",
    level: "district",
  },
  {
    id: 2270,
    pid: 2268,
    name: "\u8354\u57ce\u533a",
    code: "350304",
    level: "district",
  },
  {
    id: 2271,
    pid: 2268,
    name: "\u79c0\u5c7f\u533a",
    code: "350305",
    level: "district",
  },
  {
    id: 2272,
    pid: 2268,
    name: "\u57ce\u53a2\u533a",
    code: "350302",
    level: "district",
  },
  {
    id: 2273,
    pid: 2268,
    name: "\u6db5\u6c5f\u533a",
    code: "350303",
    level: "district",
  },
  {
    id: 2274,
    pid: 2246,
    name: "\u9f99\u5ca9\u5e02",
    code: "350800",
    level: "city",
  },
  {
    id: 2275,
    pid: 2274,
    name: "\u6f33\u5e73\u5e02",
    code: "350881",
    level: "district",
  },
  {
    id: 2276,
    pid: 2274,
    name: "\u4e0a\u676d\u53bf",
    code: "350823",
    level: "district",
  },
  {
    id: 2277,
    pid: 2274,
    name: "\u6b66\u5e73\u53bf",
    code: "350824",
    level: "district",
  },
  {
    id: 2278,
    pid: 2274,
    name: "\u6c38\u5b9a\u533a",
    code: "350803",
    level: "district",
  },
  {
    id: 2279,
    pid: 2274,
    name: "\u65b0\u7f57\u533a",
    code: "350802",
    level: "district",
  },
  {
    id: 2280,
    pid: 2274,
    name: "\u957f\u6c40\u53bf",
    code: "350821",
    level: "district",
  },
  {
    id: 2281,
    pid: 2274,
    name: "\u8fde\u57ce\u53bf",
    code: "350825",
    level: "district",
  },
  {
    id: 2282,
    pid: 2246,
    name: "\u798f\u5dde\u5e02",
    code: "350100",
    level: "city",
  },
  {
    id: 2283,
    pid: 2282,
    name: "\u8fde\u6c5f\u53bf",
    code: "350122",
    level: "district",
  },
  {
    id: 2284,
    pid: 2282,
    name: "\u7f57\u6e90\u53bf",
    code: "350123",
    level: "district",
  },
  {
    id: 2285,
    pid: 2282,
    name: "\u95fd\u6e05\u53bf",
    code: "350124",
    level: "district",
  },
  {
    id: 2286,
    pid: 2282,
    name: "\u95fd\u4faf\u53bf",
    code: "350121",
    level: "district",
  },
  {
    id: 2287,
    pid: 2282,
    name: "\u4ed3\u5c71\u533a",
    code: "350104",
    level: "district",
  },
  {
    id: 2288,
    pid: 2282,
    name: "\u6c38\u6cf0\u53bf",
    code: "350125",
    level: "district",
  },
  {
    id: 2289,
    pid: 2282,
    name: "\u798f\u6e05\u5e02",
    code: "350181",
    level: "district",
  },
  {
    id: 2290,
    pid: 2282,
    name: "\u957f\u4e50\u533a",
    code: "350112",
    level: "district",
  },
  {
    id: 2291,
    pid: 2282,
    name: "\u5e73\u6f6d\u53bf",
    code: "350128",
    level: "district",
  },
  {
    id: 2292,
    pid: 2282,
    name: "\u53f0\u6c5f\u533a",
    code: "350103",
    level: "district",
  },
  {
    id: 2293,
    pid: 2282,
    name: "\u9f13\u697c\u533a",
    code: "350102",
    level: "district",
  },
  {
    id: 2294,
    pid: 2282,
    name: "\u9a6c\u5c3e\u533a",
    code: "350105",
    level: "district",
  },
  {
    id: 2295,
    pid: 2282,
    name: "\u664b\u5b89\u533a",
    code: "350111",
    level: "district",
  },
  {
    id: 2296,
    pid: 2246,
    name: "\u4e09\u660e\u5e02",
    code: "350400",
    level: "city",
  },
  {
    id: 2297,
    pid: 2296,
    name: "\u6cf0\u5b81\u53bf",
    code: "350429",
    level: "district",
  },
  {
    id: 2298,
    pid: 2296,
    name: "\u6885\u5217\u533a",
    code: "350402",
    level: "district",
  },
  {
    id: 2299,
    pid: 2296,
    name: "\u5b81\u5316\u53bf",
    code: "350424",
    level: "district",
  },
  {
    id: 2300,
    pid: 2296,
    name: "\u6e05\u6d41\u53bf",
    code: "350423",
    level: "district",
  },
  {
    id: 2301,
    pid: 2296,
    name: "\u6c38\u5b89\u5e02",
    code: "350481",
    level: "district",
  },
  {
    id: 2302,
    pid: 2296,
    name: "\u4e09\u5143\u533a",
    code: "350403",
    level: "district",
  },
  {
    id: 2303,
    pid: 2296,
    name: "\u5927\u7530\u53bf",
    code: "350425",
    level: "district",
  },
  {
    id: 2304,
    pid: 2296,
    name: "\u5c24\u6eaa\u53bf",
    code: "350426",
    level: "district",
  },
  {
    id: 2305,
    pid: 2296,
    name: "\u5efa\u5b81\u53bf",
    code: "350430",
    level: "district",
  },
  {
    id: 2306,
    pid: 2296,
    name: "\u6c99\u53bf",
    code: "350427",
    level: "district",
  },
  {
    id: 2307,
    pid: 2296,
    name: "\u660e\u6eaa\u53bf",
    code: "350421",
    level: "district",
  },
  {
    id: 2308,
    pid: 2296,
    name: "\u5c06\u4e50\u53bf",
    code: "350428",
    level: "district",
  },
  {
    id: 2309,
    pid: 2246,
    name: "\u6cc9\u5dde\u5e02",
    code: "350500",
    level: "city",
  },
  {
    id: 2310,
    pid: 2309,
    name: "\u5fb7\u5316\u53bf",
    code: "350526",
    level: "district",
  },
  {
    id: 2311,
    pid: 2309,
    name: "\u6c38\u6625\u53bf",
    code: "350525",
    level: "district",
  },
  {
    id: 2312,
    pid: 2309,
    name: "\u6d1b\u6c5f\u533a",
    code: "350504",
    level: "district",
  },
  {
    id: 2313,
    pid: 2309,
    name: "\u6cc9\u6e2f\u533a",
    code: "350505",
    level: "district",
  },
  {
    id: 2314,
    pid: 2309,
    name: "\u4e30\u6cfd\u533a",
    code: "350503",
    level: "district",
  },
  {
    id: 2315,
    pid: 2309,
    name: "\u77f3\u72ee\u5e02",
    code: "350581",
    level: "district",
  },
  {
    id: 2316,
    pid: 2309,
    name: "\u5357\u5b89\u5e02",
    code: "350583",
    level: "district",
  },
  {
    id: 2317,
    pid: 2309,
    name: "\u60e0\u5b89\u53bf",
    code: "350521",
    level: "district",
  },
  {
    id: 2318,
    pid: 2309,
    name: "\u664b\u6c5f\u5e02",
    code: "350582",
    level: "district",
  },
  {
    id: 2319,
    pid: 2309,
    name: "\u91d1\u95e8\u53bf",
    code: "350527",
    level: "district",
  },
  {
    id: 2320,
    pid: 2309,
    name: "\u9ca4\u57ce\u533a",
    code: "350502",
    level: "district",
  },
  {
    id: 2321,
    pid: 2309,
    name: "\u5b89\u6eaa\u53bf",
    code: "350524",
    level: "district",
  },
  {
    id: 2322,
    pid: 2246,
    name: "\u53a6\u95e8\u5e02",
    code: "350200",
    level: "city",
  },
  {
    id: 2323,
    pid: 2322,
    name: "\u6e56\u91cc\u533a",
    code: "350206",
    level: "district",
  },
  {
    id: 2324,
    pid: 2322,
    name: "\u601d\u660e\u533a",
    code: "350203",
    level: "district",
  },
  {
    id: 2325,
    pid: 2322,
    name: "\u7fd4\u5b89\u533a",
    code: "350213",
    level: "district",
  },
  {
    id: 2326,
    pid: 2322,
    name: "\u6d77\u6ca7\u533a",
    code: "350205",
    level: "district",
  },
  {
    id: 2327,
    pid: 2322,
    name: "\u96c6\u7f8e\u533a",
    code: "350211",
    level: "district",
  },
  {
    id: 2328,
    pid: 2322,
    name: "\u540c\u5b89\u533a",
    code: "350212",
    level: "district",
  },
  {
    id: 2329,
    pid: 2246,
    name: "\u6f33\u5dde\u5e02",
    code: "350600",
    level: "city",
  },
  {
    id: 2330,
    pid: 2329,
    name: "\u534e\u5b89\u53bf",
    code: "350629",
    level: "district",
  },
  {
    id: 2331,
    pid: 2329,
    name: "\u5357\u9756\u53bf",
    code: "350627",
    level: "district",
  },
  {
    id: 2332,
    pid: 2329,
    name: "\u9f99\u6d77\u5e02",
    code: "350681",
    level: "district",
  },
  {
    id: 2333,
    pid: 2329,
    name: "\u6f33\u6d66\u53bf",
    code: "350623",
    level: "district",
  },
  {
    id: 2334,
    pid: 2329,
    name: "\u4e1c\u5c71\u53bf",
    code: "350626",
    level: "district",
  },
  {
    id: 2335,
    pid: 2329,
    name: "\u8bcf\u5b89\u53bf",
    code: "350624",
    level: "district",
  },
  {
    id: 2336,
    pid: 2329,
    name: "\u8297\u57ce\u533a",
    code: "350602",
    level: "district",
  },
  {
    id: 2337,
    pid: 2329,
    name: "\u9f99\u6587\u533a",
    code: "350603",
    level: "district",
  },
  {
    id: 2338,
    pid: 2329,
    name: "\u4e91\u9704\u53bf",
    code: "350622",
    level: "district",
  },
  {
    id: 2339,
    pid: 2329,
    name: "\u5e73\u548c\u53bf",
    code: "350628",
    level: "district",
  },
  {
    id: 2340,
    pid: 2329,
    name: "\u957f\u6cf0\u53bf",
    code: "350625",
    level: "district",
  },
  {
    id: 2341,
    pid: 0,
    name: "\u5b81\u590f\u56de\u65cf\u81ea\u6cbb\u533a",
    code: "640000",
    level: "province",
  },
  {
    id: 2342,
    pid: 2341,
    name: "\u77f3\u5634\u5c71\u5e02",
    code: "640200",
    level: "city",
  },
  {
    id: 2343,
    pid: 2342,
    name: "\u5927\u6b66\u53e3\u533a",
    code: "640202",
    level: "district",
  },
  {
    id: 2344,
    pid: 2342,
    name: "\u60e0\u519c\u533a",
    code: "640205",
    level: "district",
  },
  {
    id: 2345,
    pid: 2342,
    name: "\u5e73\u7f57\u53bf",
    code: "640221",
    level: "district",
  },
  {
    id: 2346,
    pid: 2341,
    name: "\u4e2d\u536b\u5e02",
    code: "640500",
    level: "city",
  },
  {
    id: 2347,
    pid: 2346,
    name: "\u4e2d\u5b81\u53bf",
    code: "640521",
    level: "district",
  },
  {
    id: 2348,
    pid: 2346,
    name: "\u6d77\u539f\u53bf",
    code: "640522",
    level: "district",
  },
  {
    id: 2349,
    pid: 2346,
    name: "\u6c99\u5761\u5934\u533a",
    code: "640502",
    level: "district",
  },
  {
    id: 2350,
    pid: 2341,
    name: "\u56fa\u539f\u5e02",
    code: "640400",
    level: "city",
  },
  {
    id: 2351,
    pid: 2350,
    name: "\u9686\u5fb7\u53bf",
    code: "640423",
    level: "district",
  },
  {
    id: 2352,
    pid: 2350,
    name: "\u5f6d\u9633\u53bf",
    code: "640425",
    level: "district",
  },
  {
    id: 2353,
    pid: 2350,
    name: "\u539f\u5dde\u533a",
    code: "640402",
    level: "district",
  },
  {
    id: 2354,
    pid: 2350,
    name: "\u6cfe\u6e90\u53bf",
    code: "640424",
    level: "district",
  },
  {
    id: 2355,
    pid: 2350,
    name: "\u897f\u5409\u53bf",
    code: "640422",
    level: "district",
  },
  {
    id: 2356,
    pid: 2341,
    name: "\u94f6\u5ddd\u5e02",
    code: "640100",
    level: "city",
  },
  {
    id: 2357,
    pid: 2356,
    name: "\u897f\u590f\u533a",
    code: "640105",
    level: "district",
  },
  {
    id: 2358,
    pid: 2356,
    name: "\u6c38\u5b81\u53bf",
    code: "640121",
    level: "district",
  },
  {
    id: 2359,
    pid: 2356,
    name: "\u91d1\u51e4\u533a",
    code: "640106",
    level: "district",
  },
  {
    id: 2360,
    pid: 2356,
    name: "\u8d3a\u5170\u53bf",
    code: "640122",
    level: "district",
  },
  {
    id: 2361,
    pid: 2356,
    name: "\u5174\u5e86\u533a",
    code: "640104",
    level: "district",
  },
  {
    id: 2362,
    pid: 2356,
    name: "\u7075\u6b66\u5e02",
    code: "640181",
    level: "district",
  },
  {
    id: 2363,
    pid: 2341,
    name: "\u5434\u5fe0\u5e02",
    code: "640300",
    level: "city",
  },
  {
    id: 2364,
    pid: 2363,
    name: "\u9752\u94dc\u5ce1\u5e02",
    code: "640381",
    level: "district",
  },
  {
    id: 2365,
    pid: 2363,
    name: "\u540c\u5fc3\u53bf",
    code: "640324",
    level: "district",
  },
  {
    id: 2366,
    pid: 2363,
    name: "\u76d0\u6c60\u53bf",
    code: "640323",
    level: "district",
  },
  {
    id: 2367,
    pid: 2363,
    name: "\u7ea2\u5bfa\u5821\u533a",
    code: "640303",
    level: "district",
  },
  {
    id: 2368,
    pid: 2363,
    name: "\u5229\u901a\u533a",
    code: "640302",
    level: "district",
  },
  {
    id: 2369,
    pid: 0,
    name: "\u5e7f\u897f\u58ee\u65cf\u81ea\u6cbb\u533a",
    code: "450000",
    level: "province",
  },
  {
    id: 2370,
    pid: 2369,
    name: "\u767e\u8272\u5e02",
    code: "451000",
    level: "city",
  },
  {
    id: 2371,
    pid: 2370,
    name: "\u4e50\u4e1a\u53bf",
    code: "451028",
    level: "district",
  },
  {
    id: 2372,
    pid: 2370,
    name: "\u9686\u6797\u5404\u65cf\u81ea\u6cbb\u53bf",
    code: "451031",
    level: "district",
  },
  {
    id: 2373,
    pid: 2370,
    name: "\u897f\u6797\u53bf",
    code: "451030",
    level: "district",
  },
  {
    id: 2374,
    pid: 2370,
    name: "\u51cc\u4e91\u53bf",
    code: "451027",
    level: "district",
  },
  {
    id: 2375,
    pid: 2370,
    name: "\u53f3\u6c5f\u533a",
    code: "451002",
    level: "district",
  },
  {
    id: 2376,
    pid: 2370,
    name: "\u5e73\u679c\u5e02",
    code: "451082",
    level: "district",
  },
  {
    id: 2377,
    pid: 2370,
    name: "\u90a3\u5761\u53bf",
    code: "451026",
    level: "district",
  },
  {
    id: 2378,
    pid: 2370,
    name: "\u9756\u897f\u5e02",
    code: "451081",
    level: "district",
  },
  {
    id: 2379,
    pid: 2370,
    name: "\u7530\u6797\u53bf",
    code: "451029",
    level: "district",
  },
  {
    id: 2380,
    pid: 2370,
    name: "\u7530\u9633\u533a",
    code: "451003",
    level: "district",
  },
  {
    id: 2381,
    pid: 2370,
    name: "\u5fb7\u4fdd\u53bf",
    code: "451024",
    level: "district",
  },
  {
    id: 2382,
    pid: 2370,
    name: "\u7530\u4e1c\u53bf",
    code: "451022",
    level: "district",
  },
  {
    id: 2383,
    pid: 2369,
    name: "\u5317\u6d77\u5e02",
    code: "450500",
    level: "city",
  },
  {
    id: 2384,
    pid: 2383,
    name: "\u94c1\u5c71\u6e2f\u533a",
    code: "450512",
    level: "district",
  },
  {
    id: 2385,
    pid: 2383,
    name: "\u94f6\u6d77\u533a",
    code: "450503",
    level: "district",
  },
  {
    id: 2386,
    pid: 2383,
    name: "\u6d77\u57ce\u533a",
    code: "450502",
    level: "district",
  },
  {
    id: 2387,
    pid: 2383,
    name: "\u5408\u6d66\u53bf",
    code: "450521",
    level: "district",
  },
  {
    id: 2388,
    pid: 2369,
    name: "\u94a6\u5dde\u5e02",
    code: "450700",
    level: "city",
  },
  {
    id: 2389,
    pid: 2388,
    name: "\u94a6\u5357\u533a",
    code: "450702",
    level: "district",
  },
  {
    id: 2390,
    pid: 2388,
    name: "\u94a6\u5317\u533a",
    code: "450703",
    level: "district",
  },
  {
    id: 2391,
    pid: 2388,
    name: "\u6d66\u5317\u53bf",
    code: "450722",
    level: "district",
  },
  {
    id: 2392,
    pid: 2388,
    name: "\u7075\u5c71\u53bf",
    code: "450721",
    level: "district",
  },
  {
    id: 2393,
    pid: 2369,
    name: "\u67f3\u5dde\u5e02",
    code: "450200",
    level: "city",
  },
  {
    id: 2394,
    pid: 2393,
    name: "\u4e09\u6c5f\u4f97\u65cf\u81ea\u6cbb\u53bf",
    code: "450226",
    level: "district",
  },
  {
    id: 2395,
    pid: 2393,
    name: "\u878d\u6c34\u82d7\u65cf\u81ea\u6cbb\u53bf",
    code: "450225",
    level: "district",
  },
  {
    id: 2396,
    pid: 2393,
    name: "\u878d\u5b89\u53bf",
    code: "450224",
    level: "district",
  },
  {
    id: 2397,
    pid: 2393,
    name: "\u9e7f\u5be8\u53bf",
    code: "450223",
    level: "district",
  },
  {
    id: 2398,
    pid: 2393,
    name: "\u67f3\u5317\u533a",
    code: "450205",
    level: "district",
  },
  {
    id: 2399,
    pid: 2393,
    name: "\u57ce\u4e2d\u533a",
    code: "450202",
    level: "district",
  },
  {
    id: 2400,
    pid: 2393,
    name: "\u67f3\u57ce\u53bf",
    code: "450222",
    level: "district",
  },
  {
    id: 2401,
    pid: 2393,
    name: "\u9c7c\u5cf0\u533a",
    code: "450203",
    level: "district",
  },
  {
    id: 2402,
    pid: 2393,
    name: "\u67f3\u6c5f\u533a",
    code: "450206",
    level: "district",
  },
  {
    id: 2403,
    pid: 2393,
    name: "\u67f3\u5357\u533a",
    code: "450204",
    level: "district",
  },
  {
    id: 2404,
    pid: 2369,
    name: "\u6765\u5bbe\u5e02",
    code: "451300",
    level: "city",
  },
  {
    id: 2405,
    pid: 2404,
    name: "\u8c61\u5dde\u53bf",
    code: "451322",
    level: "district",
  },
  {
    id: 2406,
    pid: 2404,
    name: "\u5ffb\u57ce\u53bf",
    code: "451321",
    level: "district",
  },
  {
    id: 2407,
    pid: 2404,
    name: "\u91d1\u79c0\u7476\u65cf\u81ea\u6cbb\u53bf",
    code: "451324",
    level: "district",
  },
  {
    id: 2408,
    pid: 2404,
    name: "\u5174\u5bbe\u533a",
    code: "451302",
    level: "district",
  },
  {
    id: 2409,
    pid: 2404,
    name: "\u6b66\u5ba3\u53bf",
    code: "451323",
    level: "district",
  },
  {
    id: 2410,
    pid: 2404,
    name: "\u5408\u5c71\u5e02",
    code: "451381",
    level: "district",
  },
  {
    id: 2411,
    pid: 2369,
    name: "\u5357\u5b81\u5e02",
    code: "450100",
    level: "city",
  },
  {
    id: 2412,
    pid: 2411,
    name: "\u4e0a\u6797\u53bf",
    code: "450125",
    level: "district",
  },
  {
    id: 2413,
    pid: 2411,
    name: "\u6b66\u9e23\u533a",
    code: "450110",
    level: "district",
  },
  {
    id: 2414,
    pid: 2411,
    name: "\u9a6c\u5c71\u53bf",
    code: "450124",
    level: "district",
  },
  {
    id: 2415,
    pid: 2411,
    name: "\u6a2a\u53bf",
    code: "450127",
    level: "district",
  },
  {
    id: 2416,
    pid: 2411,
    name: "\u5bbe\u9633\u53bf",
    code: "450126",
    level: "district",
  },
  {
    id: 2417,
    pid: 2411,
    name: "\u826f\u5e86\u533a",
    code: "450108",
    level: "district",
  },
  {
    id: 2418,
    pid: 2411,
    name: "\u9095\u5b81\u533a",
    code: "450109",
    level: "district",
  },
  {
    id: 2419,
    pid: 2411,
    name: "\u6c5f\u5357\u533a",
    code: "450105",
    level: "district",
  },
  {
    id: 2420,
    pid: 2411,
    name: "\u5174\u5b81\u533a",
    code: "450102",
    level: "district",
  },
  {
    id: 2421,
    pid: 2411,
    name: "\u897f\u4e61\u5858\u533a",
    code: "450107",
    level: "district",
  },
  {
    id: 2422,
    pid: 2411,
    name: "\u9752\u79c0\u533a",
    code: "450103",
    level: "district",
  },
  {
    id: 2423,
    pid: 2411,
    name: "\u9686\u5b89\u53bf",
    code: "450123",
    level: "district",
  },
  {
    id: 2424,
    pid: 2369,
    name: "\u68a7\u5dde\u5e02",
    code: "450400",
    level: "city",
  },
  {
    id: 2425,
    pid: 2424,
    name: "\u8499\u5c71\u53bf",
    code: "450423",
    level: "district",
  },
  {
    id: 2426,
    pid: 2424,
    name: "\u957f\u6d32\u533a",
    code: "450405",
    level: "district",
  },
  {
    id: 2427,
    pid: 2424,
    name: "\u9f99\u5729\u533a",
    code: "450406",
    level: "district",
  },
  {
    id: 2428,
    pid: 2424,
    name: "\u5c91\u6eaa\u5e02",
    code: "450481",
    level: "district",
  },
  {
    id: 2429,
    pid: 2424,
    name: "\u82cd\u68a7\u53bf",
    code: "450421",
    level: "district",
  },
  {
    id: 2430,
    pid: 2424,
    name: "\u4e07\u79c0\u533a",
    code: "450403",
    level: "district",
  },
  {
    id: 2431,
    pid: 2424,
    name: "\u85e4\u53bf",
    code: "450422",
    level: "district",
  },
  {
    id: 2432,
    pid: 2369,
    name: "\u6842\u6797\u5e02",
    code: "450300",
    level: "city",
  },
  {
    id: 2433,
    pid: 2432,
    name: "\u5174\u5b89\u53bf",
    code: "450325",
    level: "district",
  },
  {
    id: 2434,
    pid: 2432,
    name: "\u4e34\u6842\u533a",
    code: "450312",
    level: "district",
  },
  {
    id: 2435,
    pid: 2432,
    name: "\u6c38\u798f\u53bf",
    code: "450326",
    level: "district",
  },
  {
    id: 2436,
    pid: 2432,
    name: "\u606d\u57ce\u7476\u65cf\u81ea\u6cbb\u53bf",
    code: "450332",
    level: "district",
  },
  {
    id: 2437,
    pid: 2432,
    name: "\u96c1\u5c71\u533a",
    code: "450311",
    level: "district",
  },
  {
    id: 2438,
    pid: 2432,
    name: "\u4e03\u661f\u533a",
    code: "450305",
    level: "district",
  },
  {
    id: 2439,
    pid: 2432,
    name: "\u9633\u6714\u53bf",
    code: "450321",
    level: "district",
  },
  {
    id: 2440,
    pid: 2432,
    name: "\u8354\u6d66\u5e02",
    code: "450381",
    level: "district",
  },
  {
    id: 2441,
    pid: 2432,
    name: "\u5e73\u4e50\u53bf",
    code: "450330",
    level: "district",
  },
  {
    id: 2442,
    pid: 2432,
    name: "\u9f99\u80dc\u5404\u65cf\u81ea\u6cbb\u53bf",
    code: "450328",
    level: "district",
  },
  {
    id: 2443,
    pid: 2432,
    name: "\u79c0\u5cf0\u533a",
    code: "450302",
    level: "district",
  },
  {
    id: 2444,
    pid: 2432,
    name: "\u8c61\u5c71\u533a",
    code: "450304",
    level: "district",
  },
  {
    id: 2445,
    pid: 2432,
    name: "\u704c\u9633\u53bf",
    code: "450327",
    level: "district",
  },
  {
    id: 2446,
    pid: 2432,
    name: "\u5168\u5dde\u53bf",
    code: "450324",
    level: "district",
  },
  {
    id: 2447,
    pid: 2432,
    name: "\u7075\u5ddd\u53bf",
    code: "450323",
    level: "district",
  },
  {
    id: 2448,
    pid: 2432,
    name: "\u53e0\u5f69\u533a",
    code: "450303",
    level: "district",
  },
  {
    id: 2449,
    pid: 2432,
    name: "\u8d44\u6e90\u53bf",
    code: "450329",
    level: "district",
  },
  {
    id: 2450,
    pid: 2369,
    name: "\u5d07\u5de6\u5e02",
    code: "451400",
    level: "city",
  },
  {
    id: 2451,
    pid: 2450,
    name: "\u5929\u7b49\u53bf",
    code: "451425",
    level: "district",
  },
  {
    id: 2452,
    pid: 2450,
    name: "\u6c5f\u5dde\u533a",
    code: "451402",
    level: "district",
  },
  {
    id: 2453,
    pid: 2450,
    name: "\u6276\u7ee5\u53bf",
    code: "451421",
    level: "district",
  },
  {
    id: 2454,
    pid: 2450,
    name: "\u5927\u65b0\u53bf",
    code: "451424",
    level: "district",
  },
  {
    id: 2455,
    pid: 2450,
    name: "\u51ed\u7965\u5e02",
    code: "451481",
    level: "district",
  },
  {
    id: 2456,
    pid: 2450,
    name: "\u9f99\u5dde\u53bf",
    code: "451423",
    level: "district",
  },
  {
    id: 2457,
    pid: 2450,
    name: "\u5b81\u660e\u53bf",
    code: "451422",
    level: "district",
  },
  {
    id: 2458,
    pid: 2369,
    name: "\u9632\u57ce\u6e2f\u5e02",
    code: "450600",
    level: "city",
  },
  {
    id: 2459,
    pid: 2458,
    name: "\u6e2f\u53e3\u533a",
    code: "450602",
    level: "district",
  },
  {
    id: 2460,
    pid: 2458,
    name: "\u9632\u57ce\u533a",
    code: "450603",
    level: "district",
  },
  {
    id: 2461,
    pid: 2458,
    name: "\u4e1c\u5174\u5e02",
    code: "450681",
    level: "district",
  },
  {
    id: 2462,
    pid: 2458,
    name: "\u4e0a\u601d\u53bf",
    code: "450621",
    level: "district",
  },
  {
    id: 2463,
    pid: 2369,
    name: "\u8d3a\u5dde\u5e02",
    code: "451100",
    level: "city",
  },
  {
    id: 2464,
    pid: 2463,
    name: "\u5bcc\u5ddd\u7476\u65cf\u81ea\u6cbb\u53bf",
    code: "451123",
    level: "district",
  },
  {
    id: 2465,
    pid: 2463,
    name: "\u662d\u5e73\u53bf",
    code: "451121",
    level: "district",
  },
  {
    id: 2466,
    pid: 2463,
    name: "\u949f\u5c71\u53bf",
    code: "451122",
    level: "district",
  },
  {
    id: 2467,
    pid: 2463,
    name: "\u5e73\u6842\u533a",
    code: "451103",
    level: "district",
  },
  {
    id: 2468,
    pid: 2463,
    name: "\u516b\u6b65\u533a",
    code: "451102",
    level: "district",
  },
  {
    id: 2469,
    pid: 2369,
    name: "\u7389\u6797\u5e02",
    code: "450900",
    level: "city",
  },
  {
    id: 2470,
    pid: 2469,
    name: "\u798f\u7ef5\u533a",
    code: "450903",
    level: "district",
  },
  {
    id: 2471,
    pid: 2469,
    name: "\u5174\u4e1a\u53bf",
    code: "450924",
    level: "district",
  },
  {
    id: 2472,
    pid: 2469,
    name: "\u535a\u767d\u53bf",
    code: "450923",
    level: "district",
  },
  {
    id: 2473,
    pid: 2469,
    name: "\u9646\u5ddd\u53bf",
    code: "450922",
    level: "district",
  },
  {
    id: 2474,
    pid: 2469,
    name: "\u5bb9\u53bf",
    code: "450921",
    level: "district",
  },
  {
    id: 2475,
    pid: 2469,
    name: "\u7389\u5dde\u533a",
    code: "450902",
    level: "district",
  },
  {
    id: 2476,
    pid: 2469,
    name: "\u5317\u6d41\u5e02",
    code: "450981",
    level: "district",
  },
  {
    id: 2477,
    pid: 2369,
    name: "\u8d35\u6e2f\u5e02",
    code: "450800",
    level: "city",
  },
  {
    id: 2478,
    pid: 2477,
    name: "\u8983\u5858\u533a",
    code: "450804",
    level: "district",
  },
  {
    id: 2479,
    pid: 2477,
    name: "\u6e2f\u5317\u533a",
    code: "450802",
    level: "district",
  },
  {
    id: 2480,
    pid: 2477,
    name: "\u6e2f\u5357\u533a",
    code: "450803",
    level: "district",
  },
  {
    id: 2481,
    pid: 2477,
    name: "\u6842\u5e73\u5e02",
    code: "450881",
    level: "district",
  },
  {
    id: 2482,
    pid: 2477,
    name: "\u5e73\u5357\u53bf",
    code: "450821",
    level: "district",
  },
  {
    id: 2483,
    pid: 2369,
    name: "\u6cb3\u6c60\u5e02",
    code: "451200",
    level: "city",
  },
  {
    id: 2484,
    pid: 2483,
    name: "\u7f57\u57ce\u4eeb\u4f6c\u65cf\u81ea\u6cbb\u53bf",
    code: "451225",
    level: "district",
  },
  {
    id: 2485,
    pid: 2483,
    name: "\u5357\u4e39\u53bf",
    code: "451221",
    level: "district",
  },
  {
    id: 2486,
    pid: 2483,
    name: "\u5929\u5ce8\u53bf",
    code: "451222",
    level: "district",
  },
  {
    id: 2487,
    pid: 2483,
    name: "\u73af\u6c5f\u6bdb\u5357\u65cf\u81ea\u6cbb\u53bf",
    code: "451226",
    level: "district",
  },
  {
    id: 2488,
    pid: 2483,
    name: "\u5b9c\u5dde\u533a",
    code: "451203",
    level: "district",
  },
  {
    id: 2489,
    pid: 2483,
    name: "\u51e4\u5c71\u53bf",
    code: "451223",
    level: "district",
  },
  {
    id: 2490,
    pid: 2483,
    name: "\u91d1\u57ce\u6c5f\u533a",
    code: "451202",
    level: "district",
  },
  {
    id: 2491,
    pid: 2483,
    name: "\u90fd\u5b89\u7476\u65cf\u81ea\u6cbb\u53bf",
    code: "451228",
    level: "district",
  },
  {
    id: 2492,
    pid: 2483,
    name: "\u5927\u5316\u7476\u65cf\u81ea\u6cbb\u53bf",
    code: "451229",
    level: "district",
  },
  {
    id: 2493,
    pid: 2483,
    name: "\u4e1c\u5170\u53bf",
    code: "451224",
    level: "district",
  },
  {
    id: 2494,
    pid: 2483,
    name: "\u5df4\u9a6c\u7476\u65cf\u81ea\u6cbb\u53bf",
    code: "451227",
    level: "district",
  },
  {
    id: 2495,
    pid: 0,
    name: "\u6c5f\u82cf\u7701",
    code: "320000",
    level: "province",
  },
  {
    id: 2496,
    pid: 2495,
    name: "\u8fde\u4e91\u6e2f\u5e02",
    code: "320700",
    level: "city",
  },
  {
    id: 2497,
    pid: 2496,
    name: "\u4e1c\u6d77\u53bf",
    code: "320722",
    level: "district",
  },
  {
    id: 2498,
    pid: 2496,
    name: "\u8fde\u4e91\u533a",
    code: "320703",
    level: "district",
  },
  {
    id: 2499,
    pid: 2496,
    name: "\u704c\u4e91\u53bf",
    code: "320723",
    level: "district",
  },
  {
    id: 2500,
    pid: 2496,
    name: "\u704c\u5357\u53bf",
    code: "320724",
    level: "district",
  },
  {
    id: 2501,
    pid: 2496,
    name: "\u8d63\u6986\u533a",
    code: "320707",
    level: "district",
  },
  {
    id: 2502,
    pid: 2496,
    name: "\u6d77\u5dde\u533a",
    code: "320706",
    level: "district",
  },
  {
    id: 2503,
    pid: 2495,
    name: "\u5bbf\u8fc1\u5e02",
    code: "321300",
    level: "city",
  },
  {
    id: 2504,
    pid: 2503,
    name: "\u6cad\u9633\u53bf",
    code: "321322",
    level: "district",
  },
  {
    id: 2505,
    pid: 2503,
    name: "\u6cd7\u9633\u53bf",
    code: "321323",
    level: "district",
  },
  {
    id: 2506,
    pid: 2503,
    name: "\u6cd7\u6d2a\u53bf",
    code: "321324",
    level: "district",
  },
  {
    id: 2507,
    pid: 2503,
    name: "\u5bbf\u8c6b\u533a",
    code: "321311",
    level: "district",
  },
  {
    id: 2508,
    pid: 2503,
    name: "\u5bbf\u57ce\u533a",
    code: "321302",
    level: "district",
  },
  {
    id: 2509,
    pid: 2495,
    name: "\u5357\u4eac\u5e02",
    code: "320100",
    level: "city",
  },
  {
    id: 2510,
    pid: 2509,
    name: "\u6d66\u53e3\u533a",
    code: "320111",
    level: "district",
  },
  {
    id: 2511,
    pid: 2509,
    name: "\u96e8\u82b1\u53f0\u533a",
    code: "320114",
    level: "district",
  },
  {
    id: 2512,
    pid: 2509,
    name: "\u6c5f\u5b81\u533a",
    code: "320115",
    level: "district",
  },
  {
    id: 2513,
    pid: 2509,
    name: "\u6ea7\u6c34\u533a",
    code: "320117",
    level: "district",
  },
  {
    id: 2514,
    pid: 2509,
    name: "\u516d\u5408\u533a",
    code: "320116",
    level: "district",
  },
  {
    id: 2515,
    pid: 2509,
    name: "\u9ad8\u6df3\u533a",
    code: "320118",
    level: "district",
  },
  {
    id: 2516,
    pid: 2509,
    name: "\u5efa\u90ba\u533a",
    code: "320105",
    level: "district",
  },
  {
    id: 2517,
    pid: 2509,
    name: "\u9f13\u697c\u533a",
    code: "320106",
    level: "district",
  },
  {
    id: 2518,
    pid: 2509,
    name: "\u79e6\u6dee\u533a",
    code: "320104",
    level: "district",
  },
  {
    id: 2519,
    pid: 2509,
    name: "\u6816\u971e\u533a",
    code: "320113",
    level: "district",
  },
  {
    id: 2520,
    pid: 2509,
    name: "\u7384\u6b66\u533a",
    code: "320102",
    level: "district",
  },
  {
    id: 2521,
    pid: 2495,
    name: "\u5357\u901a\u5e02",
    code: "320600",
    level: "city",
  },
  {
    id: 2522,
    pid: 2521,
    name: "\u5982\u768b\u5e02",
    code: "320682",
    level: "district",
  },
  {
    id: 2523,
    pid: 2521,
    name: "\u6d77\u95e8\u5e02",
    code: "320684",
    level: "district",
  },
  {
    id: 2524,
    pid: 2521,
    name: "\u6d77\u5b89\u5e02",
    code: "320685",
    level: "district",
  },
  {
    id: 2525,
    pid: 2521,
    name: "\u5982\u4e1c\u53bf",
    code: "320623",
    level: "district",
  },
  {
    id: 2526,
    pid: 2521,
    name: "\u542f\u4e1c\u5e02",
    code: "320681",
    level: "district",
  },
  {
    id: 2527,
    pid: 2521,
    name: "\u6e2f\u95f8\u533a",
    code: "320611",
    level: "district",
  },
  {
    id: 2528,
    pid: 2521,
    name: "\u901a\u5dde\u533a",
    code: "320612",
    level: "district",
  },
  {
    id: 2529,
    pid: 2521,
    name: "\u5d07\u5ddd\u533a",
    code: "320602",
    level: "district",
  },
  {
    id: 2530,
    pid: 2495,
    name: "\u6dee\u5b89\u5e02",
    code: "320800",
    level: "city",
  },
  {
    id: 2531,
    pid: 2530,
    name: "\u6dee\u9634\u533a",
    code: "320804",
    level: "district",
  },
  {
    id: 2532,
    pid: 2530,
    name: "\u76f1\u7719\u53bf",
    code: "320830",
    level: "district",
  },
  {
    id: 2533,
    pid: 2530,
    name: "\u91d1\u6e56\u53bf",
    code: "320831",
    level: "district",
  },
  {
    id: 2534,
    pid: 2530,
    name: "\u6d2a\u6cfd\u533a",
    code: "320813",
    level: "district",
  },
  {
    id: 2535,
    pid: 2530,
    name: "\u6d9f\u6c34\u53bf",
    code: "320826",
    level: "district",
  },
  {
    id: 2536,
    pid: 2530,
    name: "\u6e05\u6c5f\u6d66\u533a",
    code: "320812",
    level: "district",
  },
  {
    id: 2537,
    pid: 2530,
    name: "\u6dee\u5b89\u533a",
    code: "320803",
    level: "district",
  },
  {
    id: 2538,
    pid: 2495,
    name: "\u626c\u5dde\u5e02",
    code: "321000",
    level: "city",
  },
  {
    id: 2539,
    pid: 2538,
    name: "\u9097\u6c5f\u533a",
    code: "321003",
    level: "district",
  },
  {
    id: 2540,
    pid: 2538,
    name: "\u5e7f\u9675\u533a",
    code: "321002",
    level: "district",
  },
  {
    id: 2541,
    pid: 2538,
    name: "\u4eea\u5f81\u5e02",
    code: "321081",
    level: "district",
  },
  {
    id: 2542,
    pid: 2538,
    name: "\u9ad8\u90ae\u5e02",
    code: "321084",
    level: "district",
  },
  {
    id: 2543,
    pid: 2538,
    name: "\u5b9d\u5e94\u53bf",
    code: "321023",
    level: "district",
  },
  {
    id: 2544,
    pid: 2538,
    name: "\u6c5f\u90fd\u533a",
    code: "321012",
    level: "district",
  },
  {
    id: 2545,
    pid: 2495,
    name: "\u6cf0\u5dde\u5e02",
    code: "321200",
    level: "city",
  },
  {
    id: 2546,
    pid: 2545,
    name: "\u9756\u6c5f\u5e02",
    code: "321282",
    level: "district",
  },
  {
    id: 2547,
    pid: 2545,
    name: "\u6cf0\u5174\u5e02",
    code: "321283",
    level: "district",
  },
  {
    id: 2548,
    pid: 2545,
    name: "\u5174\u5316\u5e02",
    code: "321281",
    level: "district",
  },
  {
    id: 2549,
    pid: 2545,
    name: "\u59dc\u5830\u533a",
    code: "321204",
    level: "district",
  },
  {
    id: 2550,
    pid: 2545,
    name: "\u9ad8\u6e2f\u533a",
    code: "321203",
    level: "district",
  },
  {
    id: 2551,
    pid: 2545,
    name: "\u6d77\u9675\u533a",
    code: "321202",
    level: "district",
  },
  {
    id: 2552,
    pid: 2495,
    name: "\u76d0\u57ce\u5e02",
    code: "320900",
    level: "city",
  },
  {
    id: 2553,
    pid: 2552,
    name: "\u961c\u5b81\u53bf",
    code: "320923",
    level: "district",
  },
  {
    id: 2554,
    pid: 2552,
    name: "\u6ee8\u6d77\u53bf",
    code: "320922",
    level: "district",
  },
  {
    id: 2555,
    pid: 2552,
    name: "\u5c04\u9633\u53bf",
    code: "320924",
    level: "district",
  },
  {
    id: 2556,
    pid: 2552,
    name: "\u5efa\u6e56\u53bf",
    code: "320925",
    level: "district",
  },
  {
    id: 2557,
    pid: 2552,
    name: "\u4ead\u6e56\u533a",
    code: "320902",
    level: "district",
  },
  {
    id: 2558,
    pid: 2552,
    name: "\u76d0\u90fd\u533a",
    code: "320903",
    level: "district",
  },
  {
    id: 2559,
    pid: 2552,
    name: "\u4e1c\u53f0\u5e02",
    code: "320981",
    level: "district",
  },
  {
    id: 2560,
    pid: 2552,
    name: "\u5927\u4e30\u533a",
    code: "320904",
    level: "district",
  },
  {
    id: 2561,
    pid: 2552,
    name: "\u54cd\u6c34\u53bf",
    code: "320921",
    level: "district",
  },
  {
    id: 2562,
    pid: 2495,
    name: "\u9547\u6c5f\u5e02",
    code: "321100",
    level: "city",
  },
  {
    id: 2563,
    pid: 2562,
    name: "\u626c\u4e2d\u5e02",
    code: "321182",
    level: "district",
  },
  {
    id: 2564,
    pid: 2562,
    name: "\u6da6\u5dde\u533a",
    code: "321111",
    level: "district",
  },
  {
    id: 2565,
    pid: 2562,
    name: "\u4e39\u5f92\u533a",
    code: "321112",
    level: "district",
  },
  {
    id: 2566,
    pid: 2562,
    name: "\u4eac\u53e3\u533a",
    code: "321102",
    level: "district",
  },
  {
    id: 2567,
    pid: 2562,
    name: "\u4e39\u9633\u5e02",
    code: "321181",
    level: "district",
  },
  {
    id: 2568,
    pid: 2562,
    name: "\u53e5\u5bb9\u5e02",
    code: "321183",
    level: "district",
  },
  {
    id: 2569,
    pid: 2495,
    name: "\u82cf\u5dde\u5e02",
    code: "320500",
    level: "city",
  },
  {
    id: 2570,
    pid: 2569,
    name: "\u5f20\u5bb6\u6e2f\u5e02",
    code: "320582",
    level: "district",
  },
  {
    id: 2571,
    pid: 2569,
    name: "\u864e\u4e18\u533a",
    code: "320505",
    level: "district",
  },
  {
    id: 2572,
    pid: 2569,
    name: "\u592a\u4ed3\u5e02",
    code: "320585",
    level: "district",
  },
  {
    id: 2573,
    pid: 2569,
    name: "\u59d1\u82cf\u533a",
    code: "320508",
    level: "district",
  },
  {
    id: 2574,
    pid: 2569,
    name: "\u5434\u6c5f\u533a",
    code: "320509",
    level: "district",
  },
  {
    id: 2575,
    pid: 2569,
    name: "\u76f8\u57ce\u533a",
    code: "320507",
    level: "district",
  },
  {
    id: 2576,
    pid: 2569,
    name: "\u82cf\u5dde\u5de5\u4e1a\u56ed\u533a",
    code: "320571",
    level: "district",
  },
  {
    id: 2577,
    pid: 2569,
    name: "\u5e38\u719f\u5e02",
    code: "320581",
    level: "district",
  },
  {
    id: 2578,
    pid: 2569,
    name: "\u5434\u4e2d\u533a",
    code: "320506",
    level: "district",
  },
  {
    id: 2579,
    pid: 2569,
    name: "\u6606\u5c71\u5e02",
    code: "320583",
    level: "district",
  },
  {
    id: 2580,
    pid: 2495,
    name: "\u65e0\u9521\u5e02",
    code: "320200",
    level: "city",
  },
  {
    id: 2581,
    pid: 2580,
    name: "\u6881\u6eaa\u533a",
    code: "320213",
    level: "district",
  },
  {
    id: 2582,
    pid: 2580,
    name: "\u65b0\u5434\u533a",
    code: "320214",
    level: "district",
  },
  {
    id: 2583,
    pid: 2580,
    name: "\u6c5f\u9634\u5e02",
    code: "320281",
    level: "district",
  },
  {
    id: 2584,
    pid: 2580,
    name: "\u6ee8\u6e56\u533a",
    code: "320211",
    level: "district",
  },
  {
    id: 2585,
    pid: 2580,
    name: "\u5b9c\u5174\u5e02",
    code: "320282",
    level: "district",
  },
  {
    id: 2586,
    pid: 2580,
    name: "\u9521\u5c71\u533a",
    code: "320205",
    level: "district",
  },
  {
    id: 2587,
    pid: 2580,
    name: "\u60e0\u5c71\u533a",
    code: "320206",
    level: "district",
  },
  {
    id: 2588,
    pid: 2495,
    name: "\u5e38\u5dde\u5e02",
    code: "320400",
    level: "city",
  },
  {
    id: 2589,
    pid: 2588,
    name: "\u91d1\u575b\u533a",
    code: "320413",
    level: "district",
  },
  {
    id: 2590,
    pid: 2588,
    name: "\u6ea7\u9633\u5e02",
    code: "320481",
    level: "district",
  },
  {
    id: 2591,
    pid: 2588,
    name: "\u65b0\u5317\u533a",
    code: "320411",
    level: "district",
  },
  {
    id: 2592,
    pid: 2588,
    name: "\u6b66\u8fdb\u533a",
    code: "320412",
    level: "district",
  },
  {
    id: 2593,
    pid: 2588,
    name: "\u949f\u697c\u533a",
    code: "320404",
    level: "district",
  },
  {
    id: 2594,
    pid: 2588,
    name: "\u5929\u5b81\u533a",
    code: "320402",
    level: "district",
  },
  {
    id: 2595,
    pid: 2495,
    name: "\u5f90\u5dde\u5e02",
    code: "320300",
    level: "city",
  },
  {
    id: 2596,
    pid: 2595,
    name: "\u65b0\u6c82\u5e02",
    code: "320381",
    level: "district",
  },
  {
    id: 2597,
    pid: 2595,
    name: "\u6cc9\u5c71\u533a",
    code: "320311",
    level: "district",
  },
  {
    id: 2598,
    pid: 2595,
    name: "\u94dc\u5c71\u533a",
    code: "320312",
    level: "district",
  },
  {
    id: 2599,
    pid: 2595,
    name: "\u4e91\u9f99\u533a",
    code: "320303",
    level: "district",
  },
  {
    id: 2600,
    pid: 2595,
    name: "\u9f13\u697c\u533a",
    code: "320302",
    level: "district",
  },
  {
    id: 2601,
    pid: 2595,
    name: "\u7762\u5b81\u53bf",
    code: "320324",
    level: "district",
  },
  {
    id: 2602,
    pid: 2595,
    name: "\u90b3\u5dde\u5e02",
    code: "320382",
    level: "district",
  },
  {
    id: 2603,
    pid: 2595,
    name: "\u6c9b\u53bf",
    code: "320322",
    level: "district",
  },
  {
    id: 2604,
    pid: 2595,
    name: "\u4e30\u53bf",
    code: "320321",
    level: "district",
  },
  {
    id: 2605,
    pid: 2595,
    name: "\u8d3e\u6c6a\u533a",
    code: "320305",
    level: "district",
  },
  {
    id: 2606,
    pid: 0,
    name: "\u6d59\u6c5f\u7701",
    code: "330000",
    level: "province",
  },
  {
    id: 2607,
    pid: 2606,
    name: "\u821f\u5c71\u5e02",
    code: "330900",
    level: "city",
  },
  {
    id: 2608,
    pid: 2607,
    name: "\u5cb1\u5c71\u53bf",
    code: "330921",
    level: "district",
  },
  {
    id: 2609,
    pid: 2607,
    name: "\u5d4a\u6cd7\u53bf",
    code: "330922",
    level: "district",
  },
  {
    id: 2610,
    pid: 2607,
    name: "\u666e\u9640\u533a",
    code: "330903",
    level: "district",
  },
  {
    id: 2611,
    pid: 2607,
    name: "\u5b9a\u6d77\u533a",
    code: "330902",
    level: "district",
  },
  {
    id: 2612,
    pid: 2606,
    name: "\u5609\u5174\u5e02",
    code: "330400",
    level: "city",
  },
  {
    id: 2613,
    pid: 2612,
    name: "\u5e73\u6e56\u5e02",
    code: "330482",
    level: "district",
  },
  {
    id: 2614,
    pid: 2612,
    name: "\u5357\u6e56\u533a",
    code: "330402",
    level: "district",
  },
  {
    id: 2615,
    pid: 2612,
    name: "\u5609\u5584\u53bf",
    code: "330421",
    level: "district",
  },
  {
    id: 2616,
    pid: 2612,
    name: "\u6d77\u76d0\u53bf",
    code: "330424",
    level: "district",
  },
  {
    id: 2617,
    pid: 2612,
    name: "\u6850\u4e61\u5e02",
    code: "330483",
    level: "district",
  },
  {
    id: 2618,
    pid: 2612,
    name: "\u79c0\u6d32\u533a",
    code: "330411",
    level: "district",
  },
  {
    id: 2619,
    pid: 2612,
    name: "\u6d77\u5b81\u5e02",
    code: "330481",
    level: "district",
  },
  {
    id: 2620,
    pid: 2606,
    name: "\u6e29\u5dde\u5e02",
    code: "330300",
    level: "city",
  },
  {
    id: 2621,
    pid: 2620,
    name: "\u745e\u5b89\u5e02",
    code: "330381",
    level: "district",
  },
  {
    id: 2622,
    pid: 2620,
    name: "\u82cd\u5357\u53bf",
    code: "330327",
    level: "district",
  },
  {
    id: 2623,
    pid: 2620,
    name: "\u6d1e\u5934\u533a",
    code: "330305",
    level: "district",
  },
  {
    id: 2624,
    pid: 2620,
    name: "\u5e73\u9633\u53bf",
    code: "330326",
    level: "district",
  },
  {
    id: 2625,
    pid: 2620,
    name: "\u9f99\u6e2f\u5e02",
    code: "330383",
    level: "district",
  },
  {
    id: 2626,
    pid: 2620,
    name: "\u6587\u6210\u53bf",
    code: "330328",
    level: "district",
  },
  {
    id: 2627,
    pid: 2620,
    name: "\u6cf0\u987a\u53bf",
    code: "330329",
    level: "district",
  },
  {
    id: 2628,
    pid: 2620,
    name: "\u6c38\u5609\u53bf",
    code: "330324",
    level: "district",
  },
  {
    id: 2629,
    pid: 2620,
    name: "\u4e50\u6e05\u5e02",
    code: "330382",
    level: "district",
  },
  {
    id: 2630,
    pid: 2620,
    name: "\u9e7f\u57ce\u533a",
    code: "330302",
    level: "district",
  },
  {
    id: 2631,
    pid: 2620,
    name: "\u74ef\u6d77\u533a",
    code: "330304",
    level: "district",
  },
  {
    id: 2632,
    pid: 2620,
    name: "\u9f99\u6e7e\u533a",
    code: "330303",
    level: "district",
  },
  {
    id: 2633,
    pid: 2606,
    name: "\u53f0\u5dde\u5e02",
    code: "331000",
    level: "city",
  },
  {
    id: 2634,
    pid: 2633,
    name: "\u8def\u6865\u533a",
    code: "331004",
    level: "district",
  },
  {
    id: 2635,
    pid: 2633,
    name: "\u7389\u73af\u5e02",
    code: "331083",
    level: "district",
  },
  {
    id: 2636,
    pid: 2633,
    name: "\u6912\u6c5f\u533a",
    code: "331002",
    level: "district",
  },
  {
    id: 2637,
    pid: 2633,
    name: "\u4e34\u6d77\u5e02",
    code: "331082",
    level: "district",
  },
  {
    id: 2638,
    pid: 2633,
    name: "\u6e29\u5cad\u5e02",
    code: "331081",
    level: "district",
  },
  {
    id: 2639,
    pid: 2633,
    name: "\u4e09\u95e8\u53bf",
    code: "331022",
    level: "district",
  },
  {
    id: 2640,
    pid: 2633,
    name: "\u5929\u53f0\u53bf",
    code: "331023",
    level: "district",
  },
  {
    id: 2641,
    pid: 2633,
    name: "\u9ec4\u5ca9\u533a",
    code: "331003",
    level: "district",
  },
  {
    id: 2642,
    pid: 2633,
    name: "\u4ed9\u5c45\u53bf",
    code: "331024",
    level: "district",
  },
  {
    id: 2643,
    pid: 2606,
    name: "\u5b81\u6ce2\u5e02",
    code: "330200",
    level: "city",
  },
  {
    id: 2644,
    pid: 2643,
    name: "\u5317\u4ed1\u533a",
    code: "330206",
    level: "district",
  },
  {
    id: 2645,
    pid: 2643,
    name: "\u8c61\u5c71\u53bf",
    code: "330225",
    level: "district",
  },
  {
    id: 2646,
    pid: 2643,
    name: "\u9547\u6d77\u533a",
    code: "330211",
    level: "district",
  },
  {
    id: 2647,
    pid: 2643,
    name: "\u6c5f\u5317\u533a",
    code: "330205",
    level: "district",
  },
  {
    id: 2648,
    pid: 2643,
    name: "\u5949\u5316\u533a",
    code: "330213",
    level: "district",
  },
  {
    id: 2649,
    pid: 2643,
    name: "\u5b81\u6d77\u53bf",
    code: "330226",
    level: "district",
  },
  {
    id: 2650,
    pid: 2643,
    name: "\u6d77\u66d9\u533a",
    code: "330203",
    level: "district",
  },
  {
    id: 2651,
    pid: 2643,
    name: "\u911e\u5dde\u533a",
    code: "330212",
    level: "district",
  },
  {
    id: 2652,
    pid: 2643,
    name: "\u4f59\u59da\u5e02",
    code: "330281",
    level: "district",
  },
  {
    id: 2653,
    pid: 2643,
    name: "\u6148\u6eaa\u5e02",
    code: "330282",
    level: "district",
  },
  {
    id: 2654,
    pid: 2606,
    name: "\u4e3d\u6c34\u5e02",
    code: "331100",
    level: "city",
  },
  {
    id: 2655,
    pid: 2654,
    name: "\u9f99\u6cc9\u5e02",
    code: "331181",
    level: "district",
  },
  {
    id: 2656,
    pid: 2654,
    name: "\u83b2\u90fd\u533a",
    code: "331102",
    level: "district",
  },
  {
    id: 2657,
    pid: 2654,
    name: "\u677e\u9633\u53bf",
    code: "331124",
    level: "district",
  },
  {
    id: 2658,
    pid: 2654,
    name: "\u4e91\u548c\u53bf",
    code: "331125",
    level: "district",
  },
  {
    id: 2659,
    pid: 2654,
    name: "\u9042\u660c\u53bf",
    code: "331123",
    level: "district",
  },
  {
    id: 2660,
    pid: 2654,
    name: "\u666f\u5b81\u7572\u65cf\u81ea\u6cbb\u53bf",
    code: "331127",
    level: "district",
  },
  {
    id: 2661,
    pid: 2654,
    name: "\u9752\u7530\u53bf",
    code: "331121",
    level: "district",
  },
  {
    id: 2662,
    pid: 2654,
    name: "\u5e86\u5143\u53bf",
    code: "331126",
    level: "district",
  },
  {
    id: 2663,
    pid: 2654,
    name: "\u7f19\u4e91\u53bf",
    code: "331122",
    level: "district",
  },
  {
    id: 2664,
    pid: 2606,
    name: "\u6e56\u5dde\u5e02",
    code: "330500",
    level: "city",
  },
  {
    id: 2665,
    pid: 2664,
    name: "\u5fb7\u6e05\u53bf",
    code: "330521",
    level: "district",
  },
  {
    id: 2666,
    pid: 2664,
    name: "\u5357\u6d54\u533a",
    code: "330503",
    level: "district",
  },
  {
    id: 2667,
    pid: 2664,
    name: "\u5b89\u5409\u53bf",
    code: "330523",
    level: "district",
  },
  {
    id: 2668,
    pid: 2664,
    name: "\u957f\u5174\u53bf",
    code: "330522",
    level: "district",
  },
  {
    id: 2669,
    pid: 2664,
    name: "\u5434\u5174\u533a",
    code: "330502",
    level: "district",
  },
  {
    id: 2670,
    pid: 2606,
    name: "\u8862\u5dde\u5e02",
    code: "330800",
    level: "city",
  },
  {
    id: 2671,
    pid: 2670,
    name: "\u5e38\u5c71\u53bf",
    code: "330822",
    level: "district",
  },
  {
    id: 2672,
    pid: 2670,
    name: "\u5f00\u5316\u53bf",
    code: "330824",
    level: "district",
  },
  {
    id: 2673,
    pid: 2670,
    name: "\u67ef\u57ce\u533a",
    code: "330802",
    level: "district",
  },
  {
    id: 2674,
    pid: 2670,
    name: "\u6c5f\u5c71\u5e02",
    code: "330881",
    level: "district",
  },
  {
    id: 2675,
    pid: 2670,
    name: "\u8862\u6c5f\u533a",
    code: "330803",
    level: "district",
  },
  {
    id: 2676,
    pid: 2670,
    name: "\u9f99\u6e38\u53bf",
    code: "330825",
    level: "district",
  },
  {
    id: 2677,
    pid: 2606,
    name: "\u676d\u5dde\u5e02",
    code: "330100",
    level: "city",
  },
  {
    id: 2678,
    pid: 2677,
    name: "\u4e0a\u57ce\u533a",
    code: "330102",
    level: "district",
  },
  {
    id: 2679,
    pid: 2677,
    name: "\u5bcc\u9633\u533a",
    code: "330111",
    level: "district",
  },
  {
    id: 2680,
    pid: 2677,
    name: "\u6850\u5e90\u53bf",
    code: "330122",
    level: "district",
  },
  {
    id: 2681,
    pid: 2677,
    name: "\u5efa\u5fb7\u5e02",
    code: "330182",
    level: "district",
  },
  {
    id: 2682,
    pid: 2677,
    name: "\u6df3\u5b89\u53bf",
    code: "330127",
    level: "district",
  },
  {
    id: 2683,
    pid: 2677,
    name: "\u6ee8\u6c5f\u533a",
    code: "330108",
    level: "district",
  },
  {
    id: 2684,
    pid: 2677,
    name: "\u4e34\u5b89\u533a",
    code: "330112",
    level: "district",
  },
  {
    id: 2685,
    pid: 2677,
    name: "\u8427\u5c71\u533a",
    code: "330109",
    level: "district",
  },
  {
    id: 2686,
    pid: 2677,
    name: "\u6c5f\u5e72\u533a",
    code: "330104",
    level: "district",
  },
  {
    id: 2687,
    pid: 2677,
    name: "\u4f59\u676d\u533a",
    code: "330110",
    level: "district",
  },
  {
    id: 2688,
    pid: 2677,
    name: "\u897f\u6e56\u533a",
    code: "330106",
    level: "district",
  },
  {
    id: 2689,
    pid: 2677,
    name: "\u62f1\u5885\u533a",
    code: "330105",
    level: "district",
  },
  {
    id: 2690,
    pid: 2677,
    name: "\u4e0b\u57ce\u533a",
    code: "330103",
    level: "district",
  },
  {
    id: 2691,
    pid: 2606,
    name: "\u91d1\u534e\u5e02",
    code: "330700",
    level: "city",
  },
  {
    id: 2692,
    pid: 2691,
    name: "\u6d66\u6c5f\u53bf",
    code: "330726",
    level: "district",
  },
  {
    id: 2693,
    pid: 2691,
    name: "\u6c38\u5eb7\u5e02",
    code: "330784",
    level: "district",
  },
  {
    id: 2694,
    pid: 2691,
    name: "\u4e1c\u9633\u5e02",
    code: "330783",
    level: "district",
  },
  {
    id: 2695,
    pid: 2691,
    name: "\u6b66\u4e49\u53bf",
    code: "330723",
    level: "district",
  },
  {
    id: 2696,
    pid: 2691,
    name: "\u5170\u6eaa\u5e02",
    code: "330781",
    level: "district",
  },
  {
    id: 2697,
    pid: 2691,
    name: "\u78d0\u5b89\u53bf",
    code: "330727",
    level: "district",
  },
  {
    id: 2698,
    pid: 2691,
    name: "\u4e49\u4e4c\u5e02",
    code: "330782",
    level: "district",
  },
  {
    id: 2699,
    pid: 2691,
    name: "\u91d1\u4e1c\u533a",
    code: "330703",
    level: "district",
  },
  {
    id: 2700,
    pid: 2691,
    name: "\u5a7a\u57ce\u533a",
    code: "330702",
    level: "district",
  },
  {
    id: 2701,
    pid: 2606,
    name: "\u7ecd\u5174\u5e02",
    code: "330600",
    level: "city",
  },
  {
    id: 2702,
    pid: 2701,
    name: "\u5d4a\u5dde\u5e02",
    code: "330683",
    level: "district",
  },
  {
    id: 2703,
    pid: 2701,
    name: "\u8d8a\u57ce\u533a",
    code: "330602",
    level: "district",
  },
  {
    id: 2704,
    pid: 2701,
    name: "\u67ef\u6865\u533a",
    code: "330603",
    level: "district",
  },
  {
    id: 2705,
    pid: 2701,
    name: "\u8bf8\u66a8\u5e02",
    code: "330681",
    level: "district",
  },
  {
    id: 2706,
    pid: 2701,
    name: "\u65b0\u660c\u53bf",
    code: "330624",
    level: "district",
  },
  {
    id: 2707,
    pid: 2701,
    name: "\u4e0a\u865e\u533a",
    code: "330604",
    level: "district",
  },
  {
    id: 2708,
    pid: 0,
    name: "\u6c5f\u897f\u7701",
    code: "360000",
    level: "province",
  },
  {
    id: 2709,
    pid: 2708,
    name: "\u629a\u5dde\u5e02",
    code: "361000",
    level: "city",
  },
  {
    id: 2710,
    pid: 2709,
    name: "\u4e1c\u4e61\u533a",
    code: "361003",
    level: "district",
  },
  {
    id: 2711,
    pid: 2709,
    name: "\u5d07\u4ec1\u53bf",
    code: "361024",
    level: "district",
  },
  {
    id: 2712,
    pid: 2709,
    name: "\u8d44\u6eaa\u53bf",
    code: "361028",
    level: "district",
  },
  {
    id: 2713,
    pid: 2709,
    name: "\u4e34\u5ddd\u533a",
    code: "361002",
    level: "district",
  },
  {
    id: 2714,
    pid: 2709,
    name: "\u5b9c\u9ec4\u53bf",
    code: "361026",
    level: "district",
  },
  {
    id: 2715,
    pid: 2709,
    name: "\u9ece\u5ddd\u53bf",
    code: "361022",
    level: "district",
  },
  {
    id: 2716,
    pid: 2709,
    name: "\u5357\u57ce\u53bf",
    code: "361021",
    level: "district",
  },
  {
    id: 2717,
    pid: 2709,
    name: "\u91d1\u6eaa\u53bf",
    code: "361027",
    level: "district",
  },
  {
    id: 2718,
    pid: 2709,
    name: "\u4e50\u5b89\u53bf",
    code: "361025",
    level: "district",
  },
  {
    id: 2719,
    pid: 2709,
    name: "\u5357\u4e30\u53bf",
    code: "361023",
    level: "district",
  },
  {
    id: 2720,
    pid: 2709,
    name: "\u5e7f\u660c\u53bf",
    code: "361030",
    level: "district",
  },
  {
    id: 2721,
    pid: 2708,
    name: "\u4e5d\u6c5f\u5e02",
    code: "360400",
    level: "city",
  },
  {
    id: 2722,
    pid: 2721,
    name: "\u5f6d\u6cfd\u53bf",
    code: "360430",
    level: "district",
  },
  {
    id: 2723,
    pid: 2721,
    name: "\u6e56\u53e3\u53bf",
    code: "360429",
    level: "district",
  },
  {
    id: 2724,
    pid: 2721,
    name: "\u90fd\u660c\u53bf",
    code: "360428",
    level: "district",
  },
  {
    id: 2725,
    pid: 2721,
    name: "\u67f4\u6851\u533a",
    code: "360404",
    level: "district",
  },
  {
    id: 2726,
    pid: 2721,
    name: "\u6b66\u5b81\u53bf",
    code: "360423",
    level: "district",
  },
  {
    id: 2727,
    pid: 2721,
    name: "\u4fee\u6c34\u53bf",
    code: "360424",
    level: "district",
  },
  {
    id: 2728,
    pid: 2721,
    name: "\u5e90\u5c71\u5e02",
    code: "360483",
    level: "district",
  },
  {
    id: 2729,
    pid: 2721,
    name: "\u6fc2\u6eaa\u533a",
    code: "360402",
    level: "district",
  },
  {
    id: 2730,
    pid: 2721,
    name: "\u6d54\u9633\u533a",
    code: "360403",
    level: "district",
  },
  {
    id: 2731,
    pid: 2721,
    name: "\u6c38\u4fee\u53bf",
    code: "360425",
    level: "district",
  },
  {
    id: 2732,
    pid: 2721,
    name: "\u5171\u9752\u57ce\u5e02",
    code: "360482",
    level: "district",
  },
  {
    id: 2733,
    pid: 2721,
    name: "\u5fb7\u5b89\u53bf",
    code: "360426",
    level: "district",
  },
  {
    id: 2734,
    pid: 2721,
    name: "\u745e\u660c\u5e02",
    code: "360481",
    level: "district",
  },
  {
    id: 2735,
    pid: 2708,
    name: "\u8d63\u5dde\u5e02",
    code: "360700",
    level: "city",
  },
  {
    id: 2736,
    pid: 2735,
    name: "\u5174\u56fd\u53bf",
    code: "360732",
    level: "district",
  },
  {
    id: 2737,
    pid: 2735,
    name: "\u5b81\u90fd\u53bf",
    code: "360730",
    level: "district",
  },
  {
    id: 2738,
    pid: 2735,
    name: "\u77f3\u57ce\u53bf",
    code: "360735",
    level: "district",
  },
  {
    id: 2739,
    pid: 2735,
    name: "\u5d07\u4e49\u53bf",
    code: "360725",
    level: "district",
  },
  {
    id: 2740,
    pid: 2735,
    name: "\u4e8e\u90fd\u53bf",
    code: "360731",
    level: "district",
  },
  {
    id: 2741,
    pid: 2735,
    name: "\u5bfb\u4e4c\u53bf",
    code: "360734",
    level: "district",
  },
  {
    id: 2742,
    pid: 2735,
    name: "\u5927\u4f59\u53bf",
    code: "360723",
    level: "district",
  },
  {
    id: 2743,
    pid: 2735,
    name: "\u5b89\u8fdc\u53bf",
    code: "360726",
    level: "district",
  },
  {
    id: 2744,
    pid: 2735,
    name: "\u5168\u5357\u53bf",
    code: "360729",
    level: "district",
  },
  {
    id: 2745,
    pid: 2735,
    name: "\u745e\u91d1\u5e02",
    code: "360781",
    level: "district",
  },
  {
    id: 2746,
    pid: 2735,
    name: "\u4f1a\u660c\u53bf",
    code: "360733",
    level: "district",
  },
  {
    id: 2747,
    pid: 2735,
    name: "\u4fe1\u4e30\u53bf",
    code: "360722",
    level: "district",
  },
  {
    id: 2748,
    pid: 2735,
    name: "\u4e0a\u72b9\u53bf",
    code: "360724",
    level: "district",
  },
  {
    id: 2749,
    pid: 2735,
    name: "\u5b9a\u5357\u53bf",
    code: "360728",
    level: "district",
  },
  {
    id: 2750,
    pid: 2735,
    name: "\u5357\u5eb7\u533a",
    code: "360703",
    level: "district",
  },
  {
    id: 2751,
    pid: 2735,
    name: "\u9f99\u5357\u53bf",
    code: "360727",
    level: "district",
  },
  {
    id: 2752,
    pid: 2735,
    name: "\u8d63\u53bf\u533a",
    code: "360704",
    level: "district",
  },
  {
    id: 2753,
    pid: 2735,
    name: "\u7ae0\u8d21\u533a",
    code: "360702",
    level: "district",
  },
  {
    id: 2754,
    pid: 2708,
    name: "\u9e70\u6f6d\u5e02",
    code: "360600",
    level: "city",
  },
  {
    id: 2755,
    pid: 2754,
    name: "\u8d35\u6eaa\u5e02",
    code: "360681",
    level: "district",
  },
  {
    id: 2756,
    pid: 2754,
    name: "\u4f59\u6c5f\u533a",
    code: "360603",
    level: "district",
  },
  {
    id: 2757,
    pid: 2754,
    name: "\u6708\u6e56\u533a",
    code: "360602",
    level: "district",
  },
  {
    id: 2758,
    pid: 2708,
    name: "\u666f\u5fb7\u9547\u5e02",
    code: "360200",
    level: "city",
  },
  {
    id: 2759,
    pid: 2758,
    name: "\u6d6e\u6881\u53bf",
    code: "360222",
    level: "district",
  },
  {
    id: 2760,
    pid: 2758,
    name: "\u73e0\u5c71\u533a",
    code: "360203",
    level: "district",
  },
  {
    id: 2761,
    pid: 2758,
    name: "\u660c\u6c5f\u533a",
    code: "360202",
    level: "district",
  },
  {
    id: 2762,
    pid: 2758,
    name: "\u4e50\u5e73\u5e02",
    code: "360281",
    level: "district",
  },
  {
    id: 2763,
    pid: 2708,
    name: "\u4e0a\u9976\u5e02",
    code: "361100",
    level: "city",
  },
  {
    id: 2764,
    pid: 2763,
    name: "\u5a7a\u6e90\u53bf",
    code: "361130",
    level: "district",
  },
  {
    id: 2765,
    pid: 2763,
    name: "\u5fb7\u5174\u5e02",
    code: "361181",
    level: "district",
  },
  {
    id: 2766,
    pid: 2763,
    name: "\u4f59\u5e72\u53bf",
    code: "361127",
    level: "district",
  },
  {
    id: 2767,
    pid: 2763,
    name: "\u6a2a\u5cf0\u53bf",
    code: "361125",
    level: "district",
  },
  {
    id: 2768,
    pid: 2763,
    name: "\u5f0b\u9633\u53bf",
    code: "361126",
    level: "district",
  },
  {
    id: 2769,
    pid: 2763,
    name: "\u5e7f\u4e30\u533a",
    code: "361103",
    level: "district",
  },
  {
    id: 2770,
    pid: 2763,
    name: "\u94c5\u5c71\u53bf",
    code: "361124",
    level: "district",
  },
  {
    id: 2771,
    pid: 2763,
    name: "\u7389\u5c71\u53bf",
    code: "361123",
    level: "district",
  },
  {
    id: 2772,
    pid: 2763,
    name: "\u9131\u9633\u53bf",
    code: "361128",
    level: "district",
  },
  {
    id: 2773,
    pid: 2763,
    name: "\u4e07\u5e74\u53bf",
    code: "361129",
    level: "district",
  },
  {
    id: 2774,
    pid: 2763,
    name: "\u5e7f\u4fe1\u533a",
    code: "361104",
    level: "district",
  },
  {
    id: 2775,
    pid: 2763,
    name: "\u4fe1\u5dde\u533a",
    code: "361102",
    level: "district",
  },
  {
    id: 2776,
    pid: 2708,
    name: "\u840d\u4e61\u5e02",
    code: "360300",
    level: "city",
  },
  {
    id: 2777,
    pid: 2776,
    name: "\u5b89\u6e90\u533a",
    code: "360302",
    level: "district",
  },
  {
    id: 2778,
    pid: 2776,
    name: "\u82a6\u6eaa\u53bf",
    code: "360323",
    level: "district",
  },
  {
    id: 2779,
    pid: 2776,
    name: "\u4e0a\u6817\u53bf",
    code: "360322",
    level: "district",
  },
  {
    id: 2780,
    pid: 2776,
    name: "\u83b2\u82b1\u53bf",
    code: "360321",
    level: "district",
  },
  {
    id: 2781,
    pid: 2776,
    name: "\u6e58\u4e1c\u533a",
    code: "360313",
    level: "district",
  },
  {
    id: 2782,
    pid: 2708,
    name: "\u5b9c\u6625\u5e02",
    code: "360900",
    level: "city",
  },
  {
    id: 2783,
    pid: 2782,
    name: "\u5949\u65b0\u53bf",
    code: "360921",
    level: "district",
  },
  {
    id: 2784,
    pid: 2782,
    name: "\u5b9c\u4e30\u53bf",
    code: "360924",
    level: "district",
  },
  {
    id: 2785,
    pid: 2782,
    name: "\u9ad8\u5b89\u5e02",
    code: "360983",
    level: "district",
  },
  {
    id: 2786,
    pid: 2782,
    name: "\u4e30\u57ce\u5e02",
    code: "360981",
    level: "district",
  },
  {
    id: 2787,
    pid: 2782,
    name: "\u4e0a\u9ad8\u53bf",
    code: "360923",
    level: "district",
  },
  {
    id: 2788,
    pid: 2782,
    name: "\u94dc\u9f13\u53bf",
    code: "360926",
    level: "district",
  },
  {
    id: 2789,
    pid: 2782,
    name: "\u8881\u5dde\u533a",
    code: "360902",
    level: "district",
  },
  {
    id: 2790,
    pid: 2782,
    name: "\u4e07\u8f7d\u53bf",
    code: "360922",
    level: "district",
  },
  {
    id: 2791,
    pid: 2782,
    name: "\u6a1f\u6811\u5e02",
    code: "360982",
    level: "district",
  },
  {
    id: 2792,
    pid: 2782,
    name: "\u9756\u5b89\u53bf",
    code: "360925",
    level: "district",
  },
  {
    id: 2793,
    pid: 2708,
    name: "\u5357\u660c\u5e02",
    code: "360100",
    level: "city",
  },
  {
    id: 2794,
    pid: 2793,
    name: "\u8fdb\u8d24\u53bf",
    code: "360124",
    level: "district",
  },
  {
    id: 2795,
    pid: 2793,
    name: "\u9752\u4e91\u8c31\u533a",
    code: "360104",
    level: "district",
  },
  {
    id: 2796,
    pid: 2793,
    name: "\u5357\u660c\u53bf",
    code: "360121",
    level: "district",
  },
  {
    id: 2797,
    pid: 2793,
    name: "\u897f\u6e56\u533a",
    code: "360103",
    level: "district",
  },
  {
    id: 2798,
    pid: 2793,
    name: "\u9752\u5c71\u6e56\u533a",
    code: "360111",
    level: "district",
  },
  {
    id: 2799,
    pid: 2793,
    name: "\u4e1c\u6e56\u533a",
    code: "360102",
    level: "district",
  },
  {
    id: 2800,
    pid: 2793,
    name: "\u65b0\u5efa\u533a",
    code: "360112",
    level: "district",
  },
  {
    id: 2801,
    pid: 2793,
    name: "\u7ea2\u8c37\u6ee9\u533a",
    code: "360113",
    level: "district",
  },
  {
    id: 2802,
    pid: 2793,
    name: "\u5b89\u4e49\u53bf",
    code: "360123",
    level: "district",
  },
  {
    id: 2803,
    pid: 2708,
    name: "\u5409\u5b89\u5e02",
    code: "360800",
    level: "city",
  },
  {
    id: 2804,
    pid: 2803,
    name: "\u5409\u5b89\u53bf",
    code: "360821",
    level: "district",
  },
  {
    id: 2805,
    pid: 2803,
    name: "\u6c38\u4e30\u53bf",
    code: "360825",
    level: "district",
  },
  {
    id: 2806,
    pid: 2803,
    name: "\u5409\u6c34\u53bf",
    code: "360822",
    level: "district",
  },
  {
    id: 2807,
    pid: 2803,
    name: "\u6c38\u65b0\u53bf",
    code: "360830",
    level: "district",
  },
  {
    id: 2808,
    pid: 2803,
    name: "\u9042\u5ddd\u53bf",
    code: "360827",
    level: "district",
  },
  {
    id: 2809,
    pid: 2803,
    name: "\u5409\u5dde\u533a",
    code: "360802",
    level: "district",
  },
  {
    id: 2810,
    pid: 2803,
    name: "\u4e07\u5b89\u53bf",
    code: "360828",
    level: "district",
  },
  {
    id: 2811,
    pid: 2803,
    name: "\u9752\u539f\u533a",
    code: "360803",
    level: "district",
  },
  {
    id: 2812,
    pid: 2803,
    name: "\u5b89\u798f\u53bf",
    code: "360829",
    level: "district",
  },
  {
    id: 2813,
    pid: 2803,
    name: "\u65b0\u5e72\u53bf",
    code: "360824",
    level: "district",
  },
  {
    id: 2814,
    pid: 2803,
    name: "\u5ce1\u6c5f\u53bf",
    code: "360823",
    level: "district",
  },
  {
    id: 2815,
    pid: 2803,
    name: "\u4e95\u5188\u5c71\u5e02",
    code: "360881",
    level: "district",
  },
  {
    id: 2816,
    pid: 2803,
    name: "\u6cf0\u548c\u53bf",
    code: "360826",
    level: "district",
  },
  {
    id: 2817,
    pid: 2708,
    name: "\u65b0\u4f59\u5e02",
    code: "360500",
    level: "city",
  },
  {
    id: 2818,
    pid: 2817,
    name: "\u5206\u5b9c\u53bf",
    code: "360521",
    level: "district",
  },
  {
    id: 2819,
    pid: 2817,
    name: "\u6e1d\u6c34\u533a",
    code: "360502",
    level: "district",
  },
  // {
  //   id: 2820,
  //   pid: 0,
  //   name: "\u53f0\u6e7e\u7701",
  //   code: "710000",
  //   level: "province",
  // },
  {
    id: 2821,
    pid: 0,
    name: "\u6cb3\u5317\u7701",
    code: "130000",
    level: "province",
  },
  {
    id: 2822,
    pid: 2821,
    name: "\u5510\u5c71\u5e02",
    code: "130200",
    level: "city",
  },
  {
    id: 2823,
    pid: 2822,
    name: "\u9075\u5316\u5e02",
    code: "130281",
    level: "district",
  },
  {
    id: 2824,
    pid: 2822,
    name: "\u8fc1\u897f\u53bf",
    code: "130227",
    level: "district",
  },
  {
    id: 2825,
    pid: 2822,
    name: "\u8fc1\u5b89\u5e02",
    code: "130283",
    level: "district",
  },
  {
    id: 2826,
    pid: 2822,
    name: "\u6ee6\u5357\u53bf",
    code: "130224",
    level: "district",
  },
  {
    id: 2827,
    pid: 2822,
    name: "\u66f9\u5983\u7538\u533a",
    code: "130209",
    level: "district",
  },
  {
    id: 2828,
    pid: 2822,
    name: "\u6ee6\u5dde\u5e02",
    code: "130284",
    level: "district",
  },
  {
    id: 2829,
    pid: 2822,
    name: "\u4e50\u4ead\u53bf",
    code: "130225",
    level: "district",
  },
  {
    id: 2830,
    pid: 2822,
    name: "\u7389\u7530\u53bf",
    code: "130229",
    level: "district",
  },
  {
    id: 2831,
    pid: 2822,
    name: "\u8def\u5357\u533a",
    code: "130202",
    level: "district",
  },
  {
    id: 2832,
    pid: 2822,
    name: "\u4e30\u5357\u533a",
    code: "130207",
    level: "district",
  },
  {
    id: 2833,
    pid: 2822,
    name: "\u8def\u5317\u533a",
    code: "130203",
    level: "district",
  },
  {
    id: 2834,
    pid: 2822,
    name: "\u4e30\u6da6\u533a",
    code: "130208",
    level: "district",
  },
  {
    id: 2835,
    pid: 2822,
    name: "\u53e4\u51b6\u533a",
    code: "130204",
    level: "district",
  },
  {
    id: 2836,
    pid: 2822,
    name: "\u5f00\u5e73\u533a",
    code: "130205",
    level: "district",
  },
  {
    id: 2837,
    pid: 2821,
    name: "\u5eca\u574a\u5e02",
    code: "131000",
    level: "city",
  },
  {
    id: 2838,
    pid: 2837,
    name: "\u5927\u5382\u56de\u65cf\u81ea\u6cbb\u53bf",
    code: "131028",
    level: "district",
  },
  {
    id: 2839,
    pid: 2837,
    name: "\u5927\u57ce\u53bf",
    code: "131025",
    level: "district",
  },
  {
    id: 2840,
    pid: 2837,
    name: "\u9999\u6cb3\u53bf",
    code: "131024",
    level: "district",
  },
  {
    id: 2841,
    pid: 2837,
    name: "\u6587\u5b89\u53bf",
    code: "131026",
    level: "district",
  },
  {
    id: 2842,
    pid: 2837,
    name: "\u6c38\u6e05\u53bf",
    code: "131023",
    level: "district",
  },
  {
    id: 2843,
    pid: 2837,
    name: "\u56fa\u5b89\u53bf",
    code: "131022",
    level: "district",
  },
  {
    id: 2844,
    pid: 2837,
    name: "\u5e7f\u9633\u533a",
    code: "131003",
    level: "district",
  },
  {
    id: 2845,
    pid: 2837,
    name: "\u4e09\u6cb3\u5e02",
    code: "131082",
    level: "district",
  },
  {
    id: 2846,
    pid: 2837,
    name: "\u5b89\u6b21\u533a",
    code: "131002",
    level: "district",
  },
  {
    id: 2847,
    pid: 2837,
    name: "\u9738\u5dde\u5e02",
    code: "131081",
    level: "district",
  },
  {
    id: 2848,
    pid: 2821,
    name: "\u79e6\u7687\u5c9b\u5e02",
    code: "130300",
    level: "city",
  },
  {
    id: 2849,
    pid: 2848,
    name: "\u9752\u9f99\u6ee1\u65cf\u81ea\u6cbb\u53bf",
    code: "130321",
    level: "district",
  },
  {
    id: 2850,
    pid: 2848,
    name: "\u5362\u9f99\u53bf",
    code: "130324",
    level: "district",
  },
  {
    id: 2851,
    pid: 2848,
    name: "\u5c71\u6d77\u5173\u533a",
    code: "130303",
    level: "district",
  },
  {
    id: 2852,
    pid: 2848,
    name: "\u629a\u5b81\u533a",
    code: "130306",
    level: "district",
  },
  {
    id: 2853,
    pid: 2848,
    name: "\u6d77\u6e2f\u533a",
    code: "130302",
    level: "district",
  },
  {
    id: 2854,
    pid: 2848,
    name: "\u5317\u6234\u6cb3\u533a",
    code: "130304",
    level: "district",
  },
  {
    id: 2855,
    pid: 2848,
    name: "\u660c\u9ece\u53bf",
    code: "130322",
    level: "district",
  },
  {
    id: 2856,
    pid: 2821,
    name: "\u5f20\u5bb6\u53e3\u5e02",
    code: "130700",
    level: "city",
  },
  {
    id: 2857,
    pid: 2856,
    name: "\u5f20\u5317\u53bf",
    code: "130722",
    level: "district",
  },
  {
    id: 2858,
    pid: 2856,
    name: "\u5eb7\u4fdd\u53bf",
    code: "130723",
    level: "district",
  },
  {
    id: 2859,
    pid: 2856,
    name: "\u6cbd\u6e90\u53bf",
    code: "130724",
    level: "district",
  },
  {
    id: 2860,
    pid: 2856,
    name: "\u5c1a\u4e49\u53bf",
    code: "130725",
    level: "district",
  },
  {
    id: 2861,
    pid: 2856,
    name: "\u5d07\u793c\u533a",
    code: "130709",
    level: "district",
  },
  {
    id: 2862,
    pid: 2856,
    name: "\u4e07\u5168\u533a",
    code: "130708",
    level: "district",
  },
  {
    id: 2863,
    pid: 2856,
    name: "\u8d64\u57ce\u53bf",
    code: "130732",
    level: "district",
  },
  {
    id: 2864,
    pid: 2856,
    name: "\u6000\u6765\u53bf",
    code: "130730",
    level: "district",
  },
  {
    id: 2865,
    pid: 2856,
    name: "\u4e0b\u82b1\u56ed\u533a",
    code: "130706",
    level: "district",
  },
  {
    id: 2866,
    pid: 2856,
    name: "\u6dbf\u9e7f\u53bf",
    code: "130731",
    level: "district",
  },
  {
    id: 2867,
    pid: 2856,
    name: "\u5ba3\u5316\u533a",
    code: "130705",
    level: "district",
  },
  {
    id: 2868,
    pid: 2856,
    name: "\u6865\u897f\u533a",
    code: "130703",
    level: "district",
  },
  {
    id: 2869,
    pid: 2856,
    name: "\u6865\u4e1c\u533a",
    code: "130702",
    level: "district",
  },
  {
    id: 2870,
    pid: 2856,
    name: "\u6000\u5b89\u53bf",
    code: "130728",
    level: "district",
  },
  {
    id: 2871,
    pid: 2856,
    name: "\u851a\u53bf",
    code: "130726",
    level: "district",
  },
  {
    id: 2872,
    pid: 2856,
    name: "\u9633\u539f\u53bf",
    code: "130727",
    level: "district",
  },
  {
    id: 2873,
    pid: 2821,
    name: "\u6ca7\u5dde\u5e02",
    code: "130900",
    level: "city",
  },
  {
    id: 2874,
    pid: 2873,
    name: "\u4efb\u4e18\u5e02",
    code: "130982",
    level: "district",
  },
  {
    id: 2875,
    pid: 2873,
    name: "\u732e\u53bf",
    code: "130929",
    level: "district",
  },
  {
    id: 2876,
    pid: 2873,
    name: "\u65b0\u534e\u533a",
    code: "130902",
    level: "district",
  },
  {
    id: 2877,
    pid: 2873,
    name: "\u8fd0\u6cb3\u533a",
    code: "130903",
    level: "district",
  },
  {
    id: 2878,
    pid: 2873,
    name: "\u6d77\u5174\u53bf",
    code: "130924",
    level: "district",
  },
  {
    id: 2879,
    pid: 2873,
    name: "\u5357\u76ae\u53bf",
    code: "130927",
    level: "district",
  },
  {
    id: 2880,
    pid: 2873,
    name: "\u4e1c\u5149\u53bf",
    code: "130923",
    level: "district",
  },
  {
    id: 2881,
    pid: 2873,
    name: "\u5434\u6865\u53bf",
    code: "130928",
    level: "district",
  },
  {
    id: 2882,
    pid: 2873,
    name: "\u9ec4\u9a85\u5e02",
    code: "130983",
    level: "district",
  },
  {
    id: 2883,
    pid: 2873,
    name: "\u9752\u53bf",
    code: "130922",
    level: "district",
  },
  {
    id: 2884,
    pid: 2873,
    name: "\u6ca7\u53bf",
    code: "130921",
    level: "district",
  },
  {
    id: 2885,
    pid: 2873,
    name: "\u6cca\u5934\u5e02",
    code: "130981",
    level: "district",
  },
  {
    id: 2886,
    pid: 2873,
    name: "\u6cb3\u95f4\u5e02",
    code: "130984",
    level: "district",
  },
  {
    id: 2887,
    pid: 2873,
    name: "\u8083\u5b81\u53bf",
    code: "130926",
    level: "district",
  },
  {
    id: 2888,
    pid: 2873,
    name: "\u5b5f\u6751\u56de\u65cf\u81ea\u6cbb\u53bf",
    code: "130930",
    level: "district",
  },
  {
    id: 2889,
    pid: 2873,
    name: "\u76d0\u5c71\u53bf",
    code: "130925",
    level: "district",
  },
  {
    id: 2890,
    pid: 2821,
    name: "\u90af\u90f8\u5e02",
    code: "130400",
    level: "city",
  },
  {
    id: 2891,
    pid: 2890,
    name: "\u6b66\u5b89\u5e02",
    code: "130481",
    level: "district",
  },
  {
    id: 2892,
    pid: 2890,
    name: "\u4e34\u6f33\u53bf",
    code: "130423",
    level: "district",
  },
  {
    id: 2893,
    pid: 2890,
    name: "\u9b4f\u53bf",
    code: "130434",
    level: "district",
  },
  {
    id: 2894,
    pid: 2890,
    name: "\u5cf0\u5cf0\u77ff\u533a",
    code: "130406",
    level: "district",
  },
  {
    id: 2895,
    pid: 2890,
    name: "\u80a5\u4e61\u533a",
    code: "130407",
    level: "district",
  },
  {
    id: 2896,
    pid: 2890,
    name: "\u6d89\u53bf",
    code: "130426",
    level: "district",
  },
  {
    id: 2897,
    pid: 2890,
    name: "\u9e21\u6cfd\u53bf",
    code: "130431",
    level: "district",
  },
  {
    id: 2898,
    pid: 2890,
    name: "\u66f2\u5468\u53bf",
    code: "130435",
    level: "district",
  },
  {
    id: 2899,
    pid: 2890,
    name: "\u78c1\u53bf",
    code: "130427",
    level: "district",
  },
  {
    id: 2900,
    pid: 2890,
    name: "\u590d\u5174\u533a",
    code: "130404",
    level: "district",
  },
  {
    id: 2901,
    pid: 2890,
    name: "\u4e1b\u53f0\u533a",
    code: "130403",
    level: "district",
  },
  {
    id: 2902,
    pid: 2890,
    name: "\u6c38\u5e74\u533a",
    code: "130408",
    level: "district",
  },
  {
    id: 2903,
    pid: 2890,
    name: "\u90af\u5c71\u533a",
    code: "130402",
    level: "district",
  },
  {
    id: 2904,
    pid: 2890,
    name: "\u6210\u5b89\u53bf",
    code: "130424",
    level: "district",
  },
  {
    id: 2905,
    pid: 2890,
    name: "\u9986\u9676\u53bf",
    code: "130433",
    level: "district",
  },
  {
    id: 2906,
    pid: 2890,
    name: "\u90b1\u53bf",
    code: "130430",
    level: "district",
  },
  {
    id: 2907,
    pid: 2890,
    name: "\u5927\u540d\u53bf",
    code: "130425",
    level: "district",
  },
  {
    id: 2908,
    pid: 2890,
    name: "\u5e7f\u5e73\u53bf",
    code: "130432",
    level: "district",
  },
  {
    id: 2909,
    pid: 2821,
    name: "\u90a2\u53f0\u5e02",
    code: "130500",
    level: "city",
  },
  {
    id: 2910,
    pid: 2909,
    name: "\u5185\u4e18\u53bf",
    code: "130523",
    level: "district",
  },
  {
    id: 2911,
    pid: 2909,
    name: "\u67cf\u4e61\u53bf",
    code: "130524",
    level: "district",
  },
  {
    id: 2912,
    pid: 2909,
    name: "\u65b0\u6cb3\u53bf",
    code: "130530",
    level: "district",
  },
  {
    id: 2913,
    pid: 2909,
    name: "\u5357\u548c\u533a",
    code: "130527",
    level: "district",
  },
  {
    id: 2914,
    pid: 2909,
    name: "\u5357\u5bab\u5e02",
    code: "130581",
    level: "district",
  },
  {
    id: 2915,
    pid: 2909,
    name: "\u5e73\u4e61\u53bf",
    code: "130532",
    level: "district",
  },
  {
    id: 2916,
    pid: 2909,
    name: "\u6c99\u6cb3\u5e02",
    code: "130582",
    level: "district",
  },
  {
    id: 2917,
    pid: 2909,
    name: "\u5e7f\u5b97\u53bf",
    code: "130531",
    level: "district",
  },
  {
    id: 2918,
    pid: 2909,
    name: "\u6e05\u6cb3\u53bf",
    code: "130534",
    level: "district",
  },
  {
    id: 2919,
    pid: 2909,
    name: "\u4e34\u57ce\u53bf",
    code: "130522",
    level: "district",
  },
  {
    id: 2920,
    pid: 2909,
    name: "\u5a01\u53bf",
    code: "130533",
    level: "district",
  },
  {
    id: 2921,
    pid: 2909,
    name: "\u4e34\u897f\u53bf",
    code: "130535",
    level: "district",
  },
  {
    id: 2922,
    pid: 2909,
    name: "\u5b81\u664b\u53bf",
    code: "130528",
    level: "district",
  },
  {
    id: 2923,
    pid: 2909,
    name: "\u9686\u5c27\u53bf",
    code: "130525",
    level: "district",
  },
  {
    id: 2924,
    pid: 2909,
    name: "\u4efb\u6cfd\u533a",
    code: "130526",
    level: "district",
  },
  {
    id: 2925,
    pid: 2909,
    name: "\u5de8\u9e7f\u53bf",
    code: "130529",
    level: "district",
  },
  {
    id: 2926,
    pid: 2909,
    name: "\u4fe1\u90fd\u533a",
    code: "130503",
    level: "district",
  },
  {
    id: 2927,
    pid: 2909,
    name: "\u8944\u90fd\u533a",
    code: "130502",
    level: "district",
  },
  {
    id: 2928,
    pid: 2821,
    name: "\u77f3\u5bb6\u5e84\u5e02",
    code: "130100",
    level: "city",
  },
  {
    id: 2929,
    pid: 2928,
    name: "\u6b63\u5b9a\u53bf",
    code: "130123",
    level: "district",
  },
  {
    id: 2930,
    pid: 2928,
    name: "\u85c1\u57ce\u533a",
    code: "130109",
    level: "district",
  },
  {
    id: 2931,
    pid: 2928,
    name: "\u5e73\u5c71\u53bf",
    code: "130131",
    level: "district",
  },
  {
    id: 2932,
    pid: 2928,
    name: "\u7075\u5bff\u53bf",
    code: "130126",
    level: "district",
  },
  {
    id: 2933,
    pid: 2928,
    name: "\u65e0\u6781\u53bf",
    code: "130130",
    level: "district",
  },
  {
    id: 2934,
    pid: 2928,
    name: "\u9ad8\u9091\u53bf",
    code: "130127",
    level: "district",
  },
  {
    id: 2935,
    pid: 2928,
    name: "\u5143\u6c0f\u53bf",
    code: "130132",
    level: "district",
  },
  {
    id: 2936,
    pid: 2928,
    name: "\u8d5e\u7687\u53bf",
    code: "130129",
    level: "district",
  },
  {
    id: 2937,
    pid: 2928,
    name: "\u6865\u897f\u533a",
    code: "130104",
    level: "district",
  },
  {
    id: 2938,
    pid: 2928,
    name: "\u9e7f\u6cc9\u533a",
    code: "130110",
    level: "district",
  },
  {
    id: 2939,
    pid: 2928,
    name: "\u88d5\u534e\u533a",
    code: "130108",
    level: "district",
  },
  {
    id: 2940,
    pid: 2928,
    name: "\u957f\u5b89\u533a",
    code: "130102",
    level: "district",
  },
  {
    id: 2941,
    pid: 2928,
    name: "\u664b\u5dde\u5e02",
    code: "130183",
    level: "district",
  },
  {
    id: 2942,
    pid: 2928,
    name: "\u4e95\u9649\u53bf",
    code: "130121",
    level: "district",
  },
  {
    id: 2943,
    pid: 2928,
    name: "\u4e95\u9649\u77ff\u533a",
    code: "130107",
    level: "district",
  },
  {
    id: 2944,
    pid: 2928,
    name: "\u8f9b\u96c6\u5e02",
    code: "130181",
    level: "district",
  },
  {
    id: 2945,
    pid: 2928,
    name: "\u65b0\u4e50\u5e02",
    code: "130184",
    level: "district",
  },
  {
    id: 2946,
    pid: 2928,
    name: "\u65b0\u534e\u533a",
    code: "130105",
    level: "district",
  },
  {
    id: 2947,
    pid: 2928,
    name: "\u6df1\u6cfd\u53bf",
    code: "130128",
    level: "district",
  },
  {
    id: 2948,
    pid: 2928,
    name: "\u8d75\u53bf",
    code: "130133",
    level: "district",
  },
  {
    id: 2949,
    pid: 2928,
    name: "\u683e\u57ce\u533a",
    code: "130111",
    level: "district",
  },
  {
    id: 2950,
    pid: 2928,
    name: "\u884c\u5510\u53bf",
    code: "130125",
    level: "district",
  },
  {
    id: 2951,
    pid: 2821,
    name: "\u8861\u6c34\u5e02",
    code: "131100",
    level: "city",
  },
  {
    id: 2952,
    pid: 2951,
    name: "\u6b66\u9091\u53bf",
    code: "131122",
    level: "district",
  },
  {
    id: 2953,
    pid: 2951,
    name: "\u6b66\u5f3a\u53bf",
    code: "131123",
    level: "district",
  },
  {
    id: 2954,
    pid: 2951,
    name: "\u67a3\u5f3a\u53bf",
    code: "131121",
    level: "district",
  },
  {
    id: 2955,
    pid: 2951,
    name: "\u6843\u57ce\u533a",
    code: "131102",
    level: "district",
  },
  {
    id: 2956,
    pid: 2951,
    name: "\u6df1\u5dde\u5e02",
    code: "131182",
    level: "district",
  },
  {
    id: 2957,
    pid: 2951,
    name: "\u961c\u57ce\u53bf",
    code: "131128",
    level: "district",
  },
  {
    id: 2958,
    pid: 2951,
    name: "\u5b89\u5e73\u53bf",
    code: "131125",
    level: "district",
  },
  {
    id: 2959,
    pid: 2951,
    name: "\u9976\u9633\u53bf",
    code: "131124",
    level: "district",
  },
  {
    id: 2960,
    pid: 2951,
    name: "\u5180\u5dde\u533a",
    code: "131103",
    level: "district",
  },
  {
    id: 2961,
    pid: 2951,
    name: "\u666f\u53bf",
    code: "131127",
    level: "district",
  },
  {
    id: 2962,
    pid: 2951,
    name: "\u6545\u57ce\u53bf",
    code: "131126",
    level: "district",
  },
  {
    id: 2963,
    pid: 2821,
    name: "\u4fdd\u5b9a\u5e02",
    code: "130600",
    level: "city",
  },
  {
    id: 2964,
    pid: 2963,
    name: "\u6d9e\u6e90\u53bf",
    code: "130630",
    level: "district",
  },
  {
    id: 2965,
    pid: 2963,
    name: "\u5b89\u65b0\u53bf",
    code: "130632",
    level: "district",
  },
  {
    id: 2966,
    pid: 2963,
    name: "\u6613\u53bf",
    code: "130633",
    level: "district",
  },
  {
    id: 2967,
    pid: 2963,
    name: "\u5f90\u6c34\u533a",
    code: "130609",
    level: "district",
  },
  {
    id: 2968,
    pid: 2963,
    name: "\u987a\u5e73\u53bf",
    code: "130636",
    level: "district",
  },
  {
    id: 2969,
    pid: 2963,
    name: "\u9ad8\u9633\u53bf",
    code: "130628",
    level: "district",
  },
  {
    id: 2970,
    pid: 2963,
    name: "\u7ade\u79c0\u533a",
    code: "130602",
    level: "district",
  },
  {
    id: 2971,
    pid: 2963,
    name: "\u6ee1\u57ce\u533a",
    code: "130607",
    level: "district",
  },
  {
    id: 2972,
    pid: 2963,
    name: "\u5b9a\u5dde\u5e02",
    code: "130682",
    level: "district",
  },
  {
    id: 2973,
    pid: 2963,
    name: "\u5b89\u56fd\u5e02",
    code: "130683",
    level: "district",
  },
  {
    id: 2974,
    pid: 2963,
    name: "\u83b2\u6c60\u533a",
    code: "130606",
    level: "district",
  },
  {
    id: 2975,
    pid: 2963,
    name: "\u6d9e\u6c34\u53bf",
    code: "130623",
    level: "district",
  },
  {
    id: 2976,
    pid: 2963,
    name: "\u6dbf\u5dde\u5e02",
    code: "130681",
    level: "district",
  },
  {
    id: 2977,
    pid: 2963,
    name: "\u535a\u91ce\u53bf",
    code: "130637",
    level: "district",
  },
  {
    id: 2978,
    pid: 2963,
    name: "\u8821\u53bf",
    code: "130635",
    level: "district",
  },
  {
    id: 2979,
    pid: 2963,
    name: "\u961c\u5e73\u53bf",
    code: "130624",
    level: "district",
  },
  {
    id: 2980,
    pid: 2963,
    name: "\u6e05\u82d1\u533a",
    code: "130608",
    level: "district",
  },
  {
    id: 2981,
    pid: 2963,
    name: "\u671b\u90fd\u53bf",
    code: "130631",
    level: "district",
  },
  {
    id: 2982,
    pid: 2963,
    name: "\u66f2\u9633\u53bf",
    code: "130634",
    level: "district",
  },
  {
    id: 2983,
    pid: 2963,
    name: "\u5510\u53bf",
    code: "130627",
    level: "district",
  },
  {
    id: 2984,
    pid: 2963,
    name: "\u9ad8\u7891\u5e97\u5e02",
    code: "130684",
    level: "district",
  },
  {
    id: 2985,
    pid: 2963,
    name: "\u5bb9\u57ce\u53bf",
    code: "130629",
    level: "district",
  },
  {
    id: 2986,
    pid: 2963,
    name: "\u5b9a\u5174\u53bf",
    code: "130626",
    level: "district",
  },
  {
    id: 2987,
    pid: 2963,
    name: "\u96c4\u53bf",
    code: "130638",
    level: "district",
  },
  {
    id: 2988,
    pid: 2821,
    name: "\u627f\u5fb7\u5e02",
    code: "130800",
    level: "city",
  },
  {
    id: 2989,
    pid: 2988,
    name: "\u56f4\u573a\u6ee1\u65cf\u8499\u53e4\u65cf\u81ea\u6cbb\u53bf",
    code: "130828",
    level: "district",
  },
  {
    id: 2990,
    pid: 2988,
    name: "\u4e30\u5b81\u6ee1\u65cf\u81ea\u6cbb\u53bf",
    code: "130826",
    level: "district",
  },
  {
    id: 2991,
    pid: 2988,
    name: "\u9686\u5316\u53bf",
    code: "130825",
    level: "district",
  },
  {
    id: 2992,
    pid: 2988,
    name: "\u5bbd\u57ce\u6ee1\u65cf\u81ea\u6cbb\u53bf",
    code: "130827",
    level: "district",
  },
  {
    id: 2993,
    pid: 2988,
    name: "\u9e70\u624b\u8425\u5b50\u77ff\u533a",
    code: "130804",
    level: "district",
  },
  {
    id: 2994,
    pid: 2988,
    name: "\u5e73\u6cc9\u5e02",
    code: "130881",
    level: "district",
  },
  {
    id: 2995,
    pid: 2988,
    name: "\u5174\u9686\u53bf",
    code: "130822",
    level: "district",
  },
  {
    id: 2996,
    pid: 2988,
    name: "\u6ee6\u5e73\u53bf",
    code: "130824",
    level: "district",
  },
  {
    id: 2997,
    pid: 2988,
    name: "\u53cc\u6ee6\u533a",
    code: "130803",
    level: "district",
  },
  {
    id: 2998,
    pid: 2988,
    name: "\u53cc\u6865\u533a",
    code: "130802",
    level: "district",
  },
  {
    id: 2999,
    pid: 2988,
    name: "\u627f\u5fb7\u53bf",
    code: "130821",
    level: "district",
  },
  {
    id: 3000,
    pid: 0,
    name: "\u9999\u6e2f\u7279\u522b\u884c\u653f\u533a",
    code: "810000",
    level: "province",
  },
  {
    id: 3001,
    pid: 3000,
    name: "\u6c99\u7530\u533a",
    code: "810016",
    level: "district",
  },
  {
    id: 3002,
    pid: 3000,
    name: "\u6cb9\u5c16\u65fa\u533a",
    code: "810005",
    level: "district",
  },
  {
    id: 3003,
    pid: 3000,
    name: "\u89c2\u5858\u533a",
    code: "810009",
    level: "district",
  },
  {
    id: 3004,
    pid: 3000,
    name: "\u5c6f\u95e8\u533a",
    code: "810011",
    level: "district",
  },
  {
    id: 3005,
    pid: 3000,
    name: "\u5927\u57d4\u533a",
    code: "810014",
    level: "district",
  },
  {
    id: 3006,
    pid: 3000,
    name: "\u5357\u533a",
    code: "810004",
    level: "district",
  },
  {
    id: 3007,
    pid: 3000,
    name: "\u4e5d\u9f99\u57ce\u533a",
    code: "810007",
    level: "district",
  },
  {
    id: 3008,
    pid: 3000,
    name: "\u897f\u8d21\u533a",
    code: "810015",
    level: "district",
  },
  {
    id: 3009,
    pid: 3000,
    name: "\u6df1\u6c34\u57d7\u533a",
    code: "810006",
    level: "district",
  },
  {
    id: 3010,
    pid: 3000,
    name: "\u9ec4\u5927\u4ed9\u533a",
    code: "810008",
    level: "district",
  },
  {
    id: 3011,
    pid: 3000,
    name: "\u4e2d\u897f\u533a",
    code: "810001",
    level: "district",
  },
  {
    id: 3012,
    pid: 3000,
    name: "\u5317\u533a",
    code: "810013",
    level: "district",
  },
  {
    id: 3013,
    pid: 3000,
    name: "\u5143\u6717\u533a",
    code: "810012",
    level: "district",
  },
  {
    id: 3014,
    pid: 3000,
    name: "\u6e7e\u4ed4\u533a",
    code: "810002",
    level: "district",
  },
  {
    id: 3015,
    pid: 3000,
    name: "\u79bb\u5c9b\u533a",
    code: "810018",
    level: "district",
  },
  {
    id: 3016,
    pid: 3000,
    name: "\u4e1c\u533a",
    code: "810003",
    level: "district",
  },
  {
    id: 3017,
    pid: 3000,
    name: "\u8343\u6e7e\u533a",
    code: "810010",
    level: "district",
  },
  {
    id: 3018,
    pid: 3000,
    name: "\u8475\u9752\u533a",
    code: "810017",
    level: "district",
  },
  {
    id: 3019,
    pid: 0,
    name: "\u6fb3\u95e8\u7279\u522b\u884c\u653f\u533a",
    code: "820000",
    level: "province",
  },
  {
    id: 3020,
    pid: 3019,
    name: "\u82b1\u5730\u739b\u5802\u533a",
    code: "820001",
    level: "district",
  },
  {
    id: 3021,
    pid: 3019,
    name: "\u8def\u51fc\u586b\u6d77\u533a",
    code: "820007",
    level: "district",
  },
  {
    id: 3022,
    pid: 3019,
    name: "\u671b\u5fb7\u5802\u533a",
    code: "820003",
    level: "district",
  },
  {
    id: 3023,
    pid: 3019,
    name: "\u82b1\u738b\u5802\u533a",
    code: "820002",
    level: "district",
  },
  {
    id: 3024,
    pid: 3019,
    name: "\u5927\u5802\u533a",
    code: "820004",
    level: "district",
  },
  {
    id: 3025,
    pid: 3019,
    name: "\u5723\u65b9\u6d4e\u5404\u5802\u533a",
    code: "820008",
    level: "district",
  },
  {
    id: 3026,
    pid: 3019,
    name: "\u5609\u6a21\u5802\u533a",
    code: "820006",
    level: "district",
  },
  {
    id: 3027,
    pid: 3019,
    name: "\u98ce\u987a\u5802\u533a",
    code: "820005",
    level: "district",
  },
  {
    id: 3028,
    pid: 0,
    name: "\u7518\u8083\u7701",
    code: "620000",
    level: "province",
  },
  {
    id: 3029,
    pid: 3028,
    name: "\u91d1\u660c\u5e02",
    code: "620300",
    level: "city",
  },
  {
    id: 3030,
    pid: 3029,
    name: "\u6c38\u660c\u53bf",
    code: "620321",
    level: "district",
  },
  {
    id: 3031,
    pid: 3029,
    name: "\u91d1\u5ddd\u533a",
    code: "620302",
    level: "district",
  },
  {
    id: 3032,
    pid: 3028,
    name: "\u767d\u94f6\u5e02",
    code: "620400",
    level: "city",
  },
  {
    id: 3033,
    pid: 3032,
    name: "\u9756\u8fdc\u53bf",
    code: "620421",
    level: "district",
  },
  {
    id: 3034,
    pid: 3032,
    name: "\u5e73\u5ddd\u533a",
    code: "620403",
    level: "district",
  },
  {
    id: 3035,
    pid: 3032,
    name: "\u767d\u94f6\u533a",
    code: "620402",
    level: "district",
  },
  {
    id: 3036,
    pid: 3032,
    name: "\u666f\u6cf0\u53bf",
    code: "620423",
    level: "district",
  },
  {
    id: 3037,
    pid: 3032,
    name: "\u4f1a\u5b81\u53bf",
    code: "620422",
    level: "district",
  },
  {
    id: 3038,
    pid: 3028,
    name: "\u9152\u6cc9\u5e02",
    code: "620900",
    level: "city",
  },
  {
    id: 3039,
    pid: 3038,
    name: "\u91d1\u5854\u53bf",
    code: "620921",
    level: "district",
  },
  {
    id: 3040,
    pid: 3038,
    name: "\u8083\u5317\u8499\u53e4\u65cf\u81ea\u6cbb\u53bf",
    code: "620923",
    level: "district",
  },
  {
    id: 3041,
    pid: 3038,
    name: "\u7389\u95e8\u5e02",
    code: "620981",
    level: "district",
  },
  {
    id: 3042,
    pid: 3038,
    name: "\u6566\u714c\u5e02",
    code: "620982",
    level: "district",
  },
  {
    id: 3043,
    pid: 3038,
    name: "\u8083\u5dde\u533a",
    code: "620902",
    level: "district",
  },
  {
    id: 3044,
    pid: 3038,
    name: "\u74dc\u5dde\u53bf",
    code: "620922",
    level: "district",
  },
  {
    id: 3045,
    pid: 3038,
    name: "\u963f\u514b\u585e\u54c8\u8428\u514b\u65cf\u81ea\u6cbb\u53bf",
    code: "620924",
    level: "district",
  },
  {
    id: 3046,
    pid: 3028,
    name: "\u9647\u5357\u5e02",
    code: "621200",
    level: "city",
  },
  {
    id: 3047,
    pid: 3046,
    name: "\u793c\u53bf",
    code: "621226",
    level: "district",
  },
  {
    id: 3048,
    pid: 3046,
    name: "\u5b95\u660c\u53bf",
    code: "621223",
    level: "district",
  },
  {
    id: 3049,
    pid: 3046,
    name: "\u6b66\u90fd\u533a",
    code: "621202",
    level: "district",
  },
  {
    id: 3050,
    pid: 3046,
    name: "\u5eb7\u53bf",
    code: "621224",
    level: "district",
  },
  {
    id: 3051,
    pid: 3046,
    name: "\u4e24\u5f53\u53bf",
    code: "621228",
    level: "district",
  },
  {
    id: 3052,
    pid: 3046,
    name: "\u5fbd\u53bf",
    code: "621227",
    level: "district",
  },
  {
    id: 3053,
    pid: 3046,
    name: "\u6210\u53bf",
    code: "621221",
    level: "district",
  },
  {
    id: 3054,
    pid: 3046,
    name: "\u6587\u53bf",
    code: "621222",
    level: "district",
  },
  {
    id: 3055,
    pid: 3046,
    name: "\u897f\u548c\u53bf",
    code: "621225",
    level: "district",
  },
  {
    id: 3056,
    pid: 3028,
    name: "\u5e73\u51c9\u5e02",
    code: "620800",
    level: "city",
  },
  {
    id: 3057,
    pid: 3056,
    name: "\u7075\u53f0\u53bf",
    code: "620822",
    level: "district",
  },
  {
    id: 3058,
    pid: 3056,
    name: "\u5d06\u5cd2\u533a",
    code: "620802",
    level: "district",
  },
  {
    id: 3059,
    pid: 3056,
    name: "\u5e84\u6d6a\u53bf",
    code: "620825",
    level: "district",
  },
  {
    id: 3060,
    pid: 3056,
    name: "\u5d07\u4fe1\u53bf",
    code: "620823",
    level: "district",
  },
  {
    id: 3061,
    pid: 3056,
    name: "\u9759\u5b81\u53bf",
    code: "620826",
    level: "district",
  },
  {
    id: 3062,
    pid: 3056,
    name: "\u534e\u4ead\u5e02",
    code: "620881",
    level: "district",
  },
  {
    id: 3063,
    pid: 3056,
    name: "\u6cfe\u5ddd\u53bf",
    code: "620821",
    level: "district",
  },
  {
    id: 3064,
    pid: 3028,
    name: "\u6b66\u5a01\u5e02",
    code: "620600",
    level: "city",
  },
  {
    id: 3065,
    pid: 3064,
    name: "\u53e4\u6d6a\u53bf",
    code: "620622",
    level: "district",
  },
  {
    id: 3066,
    pid: 3064,
    name: "\u51c9\u5dde\u533a",
    code: "620602",
    level: "district",
  },
  {
    id: 3067,
    pid: 3064,
    name: "\u5929\u795d\u85cf\u65cf\u81ea\u6cbb\u53bf",
    code: "620623",
    level: "district",
  },
  {
    id: 3068,
    pid: 3064,
    name: "\u6c11\u52e4\u53bf",
    code: "620621",
    level: "district",
  },
  {
    id: 3069,
    pid: 3028,
    name: "\u5170\u5dde\u5e02",
    code: "620100",
    level: "city",
  },
  {
    id: 3070,
    pid: 3069,
    name: "\u6c38\u767b\u53bf",
    code: "620121",
    level: "district",
  },
  {
    id: 3071,
    pid: 3069,
    name: "\u768b\u5170\u53bf",
    code: "620122",
    level: "district",
  },
  {
    id: 3072,
    pid: 3069,
    name: "\u897f\u56fa\u533a",
    code: "620104",
    level: "district",
  },
  {
    id: 3073,
    pid: 3069,
    name: "\u7ea2\u53e4\u533a",
    code: "620111",
    level: "district",
  },
  {
    id: 3074,
    pid: 3069,
    name: "\u5b89\u5b81\u533a",
    code: "620105",
    level: "district",
  },
  {
    id: 3075,
    pid: 3069,
    name: "\u57ce\u5173\u533a",
    code: "620102",
    level: "district",
  },
  {
    id: 3076,
    pid: 3069,
    name: "\u4e03\u91cc\u6cb3\u533a",
    code: "620103",
    level: "district",
  },
  {
    id: 3077,
    pid: 3069,
    name: "\u6986\u4e2d\u53bf",
    code: "620123",
    level: "district",
  },
  {
    id: 3078,
    pid: 3028,
    name: "\u5609\u5cea\u5173\u5e02",
    code: "620200",
    level: "city",
  },
  {
    id: 3084,
    pid: 3028,
    name: "\u7518\u5357\u85cf\u65cf\u81ea\u6cbb\u5dde",
    code: "623000",
    level: "city",
  },
  {
    id: 3085,
    pid: 3084,
    name: "\u5353\u5c3c\u53bf",
    code: "623022",
    level: "district",
  },
  {
    id: 3086,
    pid: 3084,
    name: "\u590f\u6cb3\u53bf",
    code: "623027",
    level: "district",
  },
  {
    id: 3087,
    pid: 3084,
    name: "\u5408\u4f5c\u5e02",
    code: "623001",
    level: "district",
  },
  {
    id: 3088,
    pid: 3084,
    name: "\u4e34\u6f6d\u53bf",
    code: "623021",
    level: "district",
  },
  {
    id: 3089,
    pid: 3084,
    name: "\u739b\u66f2\u53bf",
    code: "623025",
    level: "district",
  },
  {
    id: 3090,
    pid: 3084,
    name: "\u8fed\u90e8\u53bf",
    code: "623024",
    level: "district",
  },
  {
    id: 3091,
    pid: 3084,
    name: "\u821f\u66f2\u53bf",
    code: "623023",
    level: "district",
  },
  {
    id: 3092,
    pid: 3084,
    name: "\u788c\u66f2\u53bf",
    code: "623026",
    level: "district",
  },
  {
    id: 3093,
    pid: 3028,
    name: "\u4e34\u590f\u56de\u65cf\u81ea\u6cbb\u5dde",
    code: "622900",
    level: "city",
  },
  {
    id: 3094,
    pid: 3093,
    name:
      "\u79ef\u77f3\u5c71\u4fdd\u5b89\u65cf\u4e1c\u4e61\u65cf\u6492\u62c9\u65cf\u81ea\u6cbb\u53bf",
    code: "622927",
    level: "district",
  },
  {
    id: 3095,
    pid: 3093,
    name: "\u4e1c\u4e61\u65cf\u81ea\u6cbb\u53bf",
    code: "622926",
    level: "district",
  },
  {
    id: 3096,
    pid: 3093,
    name: "\u6c38\u9756\u53bf",
    code: "622923",
    level: "district",
  },
  {
    id: 3097,
    pid: 3093,
    name: "\u4e34\u590f\u5e02",
    code: "622901",
    level: "district",
  },
  {
    id: 3098,
    pid: 3093,
    name: "\u4e34\u590f\u53bf",
    code: "622921",
    level: "district",
  },
  {
    id: 3099,
    pid: 3093,
    name: "\u5e7f\u6cb3\u53bf",
    code: "622924",
    level: "district",
  },
  {
    id: 3100,
    pid: 3093,
    name: "\u548c\u653f\u53bf",
    code: "622925",
    level: "district",
  },
  {
    id: 3101,
    pid: 3093,
    name: "\u5eb7\u4e50\u53bf",
    code: "622922",
    level: "district",
  },
  {
    id: 3102,
    pid: 3028,
    name: "\u5f20\u6396\u5e02",
    code: "620700",
    level: "city",
  },
  {
    id: 3103,
    pid: 3102,
    name: "\u9ad8\u53f0\u53bf",
    code: "620724",
    level: "district",
  },
  {
    id: 3104,
    pid: 3102,
    name: "\u8083\u5357\u88d5\u56fa\u65cf\u81ea\u6cbb\u53bf",
    code: "620721",
    level: "district",
  },
  {
    id: 3105,
    pid: 3102,
    name: "\u6c11\u4e50\u53bf",
    code: "620722",
    level: "district",
  },
  {
    id: 3106,
    pid: 3102,
    name: "\u5c71\u4e39\u53bf",
    code: "620725",
    level: "district",
  },
  {
    id: 3107,
    pid: 3102,
    name: "\u4e34\u6cfd\u53bf",
    code: "620723",
    level: "district",
  },
  {
    id: 3108,
    pid: 3102,
    name: "\u7518\u5dde\u533a",
    code: "620702",
    level: "district",
  },
  {
    id: 3109,
    pid: 3028,
    name: "\u5e86\u9633\u5e02",
    code: "621000",
    level: "city",
  },
  {
    id: 3110,
    pid: 3109,
    name: "\u6b63\u5b81\u53bf",
    code: "621025",
    level: "district",
  },
  {
    id: 3111,
    pid: 3109,
    name: "\u9547\u539f\u53bf",
    code: "621027",
    level: "district",
  },
  {
    id: 3112,
    pid: 3109,
    name: "\u534e\u6c60\u53bf",
    code: "621023",
    level: "district",
  },
  {
    id: 3113,
    pid: 3109,
    name: "\u73af\u53bf",
    code: "621022",
    level: "district",
  },
  {
    id: 3114,
    pid: 3109,
    name: "\u5b81\u53bf",
    code: "621026",
    level: "district",
  },
  {
    id: 3115,
    pid: 3109,
    name: "\u897f\u5cf0\u533a",
    code: "621002",
    level: "district",
  },
  {
    id: 3116,
    pid: 3109,
    name: "\u5e86\u57ce\u53bf",
    code: "621021",
    level: "district",
  },
  {
    id: 3117,
    pid: 3109,
    name: "\u5408\u6c34\u53bf",
    code: "621024",
    level: "district",
  },
  {
    id: 3118,
    pid: 3028,
    name: "\u5b9a\u897f\u5e02",
    code: "621100",
    level: "city",
  },
  {
    id: 3119,
    pid: 3118,
    name: "\u6e2d\u6e90\u53bf",
    code: "621123",
    level: "district",
  },
  {
    id: 3120,
    pid: 3118,
    name: "\u4e34\u6d2e\u53bf",
    code: "621124",
    level: "district",
  },
  {
    id: 3121,
    pid: 3118,
    name: "\u5cb7\u53bf",
    code: "621126",
    level: "district",
  },
  {
    id: 3122,
    pid: 3118,
    name: "\u6f33\u53bf",
    code: "621125",
    level: "district",
  },
  {
    id: 3123,
    pid: 3118,
    name: "\u5b89\u5b9a\u533a",
    code: "621102",
    level: "district",
  },
  {
    id: 3124,
    pid: 3118,
    name: "\u9647\u897f\u53bf",
    code: "621122",
    level: "district",
  },
  {
    id: 3125,
    pid: 3118,
    name: "\u901a\u6e2d\u53bf",
    code: "621121",
    level: "district",
  },
  {
    id: 3126,
    pid: 3028,
    name: "\u5929\u6c34\u5e02",
    code: "620500",
    level: "city",
  },
  {
    id: 3127,
    pid: 3126,
    name: "\u7518\u8c37\u53bf",
    code: "620523",
    level: "district",
  },
  {
    id: 3128,
    pid: 3126,
    name: "\u79e6\u5b89\u53bf",
    code: "620522",
    level: "district",
  },
  {
    id: 3129,
    pid: 3126,
    name: "\u79e6\u5dde\u533a",
    code: "620502",
    level: "district",
  },
  {
    id: 3130,
    pid: 3126,
    name: "\u9ea6\u79ef\u533a",
    code: "620503",
    level: "district",
  },
  {
    id: 3131,
    pid: 3126,
    name: "\u5f20\u5bb6\u5ddd\u56de\u65cf\u81ea\u6cbb\u53bf",
    code: "620525",
    level: "district",
  },
  {
    id: 3132,
    pid: 3126,
    name: "\u6e05\u6c34\u53bf",
    code: "620521",
    level: "district",
  },
  {
    id: 3133,
    pid: 3126,
    name: "\u6b66\u5c71\u53bf",
    code: "620524",
    level: "district",
  },
  {
    id: 3134,
    pid: 0,
    name: "\u56db\u5ddd\u7701",
    code: "510000",
    level: "province",
  },
  {
    id: 3135,
    pid: 3134,
    name: "\u5df4\u4e2d\u5e02",
    code: "511900",
    level: "city",
  },
  {
    id: 3136,
    pid: 3135,
    name: "\u5df4\u5dde\u533a",
    code: "511902",
    level: "district",
  },
  {
    id: 3137,
    pid: 3135,
    name: "\u5e73\u660c\u53bf",
    code: "511923",
    level: "district",
  },
  {
    id: 3138,
    pid: 3135,
    name: "\u901a\u6c5f\u53bf",
    code: "511921",
    level: "district",
  },
  {
    id: 3139,
    pid: 3135,
    name: "\u5357\u6c5f\u53bf",
    code: "511922",
    level: "district",
  },
  {
    id: 3140,
    pid: 3135,
    name: "\u6069\u9633\u533a",
    code: "511903",
    level: "district",
  },
  {
    id: 3141,
    pid: 3134,
    name: "\u6210\u90fd\u5e02",
    code: "510100",
    level: "city",
  },
  {
    id: 3142,
    pid: 3141,
    name: "\u5f6d\u5dde\u5e02",
    code: "510182",
    level: "district",
  },
  {
    id: 3143,
    pid: 3141,
    name: "\u90fd\u6c5f\u5830\u5e02",
    code: "510181",
    level: "district",
  },
  {
    id: 3144,
    pid: 3141,
    name: "\u5927\u9091\u53bf",
    code: "510129",
    level: "district",
  },
  {
    id: 3145,
    pid: 3141,
    name: "\u84b2\u6c5f\u53bf",
    code: "510131",
    level: "district",
  },
  {
    id: 3146,
    pid: 3141,
    name: "\u9752\u767d\u6c5f\u533a",
    code: "510113",
    level: "district",
  },
  {
    id: 3147,
    pid: 3141,
    name: "\u7b80\u9633\u5e02",
    code: "510185",
    level: "district",
  },
  {
    id: 3148,
    pid: 3141,
    name: "\u5d07\u5dde\u5e02",
    code: "510184",
    level: "district",
  },
  {
    id: 3149,
    pid: 3141,
    name: "\u91d1\u5802\u53bf",
    code: "510121",
    level: "district",
  },
  {
    id: 3150,
    pid: 3141,
    name: "\u909b\u5d03\u5e02",
    code: "510183",
    level: "district",
  },
  {
    id: 3151,
    pid: 3141,
    name: "\u65b0\u6d25\u533a",
    code: "510132",
    level: "district",
  },
  {
    id: 3152,
    pid: 3141,
    name: "\u6e29\u6c5f\u533a",
    code: "510115",
    level: "district",
  },
  {
    id: 3153,
    pid: 3141,
    name: "\u90eb\u90fd\u533a",
    code: "510117",
    level: "district",
  },
  {
    id: 3154,
    pid: 3141,
    name: "\u53cc\u6d41\u533a",
    code: "510116",
    level: "district",
  },
  {
    id: 3155,
    pid: 3141,
    name: "\u6b66\u4faf\u533a",
    code: "510107",
    level: "district",
  },
  {
    id: 3156,
    pid: 3141,
    name: "\u91d1\u725b\u533a",
    code: "510106",
    level: "district",
  },
  {
    id: 3157,
    pid: 3141,
    name: "\u65b0\u90fd\u533a",
    code: "510114",
    level: "district",
  },
  {
    id: 3158,
    pid: 3141,
    name: "\u9f99\u6cc9\u9a7f\u533a",
    code: "510112",
    level: "district",
  },
  {
    id: 3159,
    pid: 3141,
    name: "\u6210\u534e\u533a",
    code: "510108",
    level: "district",
  },
  {
    id: 3160,
    pid: 3141,
    name: "\u9752\u7f8a\u533a",
    code: "510105",
    level: "district",
  },
  {
    id: 3161,
    pid: 3141,
    name: "\u9526\u6c5f\u533a",
    code: "510104",
    level: "district",
  },
  {
    id: 3162,
    pid: 3134,
    name: "\u5fb7\u9633\u5e02",
    code: "510600",
    level: "city",
  },
  {
    id: 3163,
    pid: 3162,
    name: "\u4ec0\u90a1\u5e02",
    code: "510682",
    level: "district",
  },
  {
    id: 3164,
    pid: 3162,
    name: "\u4e2d\u6c5f\u53bf",
    code: "510623",
    level: "district",
  },
  {
    id: 3165,
    pid: 3162,
    name: "\u5e7f\u6c49\u5e02",
    code: "510681",
    level: "district",
  },
  {
    id: 3166,
    pid: 3162,
    name: "\u65cc\u9633\u533a",
    code: "510603",
    level: "district",
  },
  {
    id: 3167,
    pid: 3162,
    name: "\u7f57\u6c5f\u533a",
    code: "510604",
    level: "district",
  },
  {
    id: 3168,
    pid: 3162,
    name: "\u7ef5\u7af9\u5e02",
    code: "510683",
    level: "district",
  },
  {
    id: 3169,
    pid: 3134,
    name: "\u5e7f\u5143\u5e02",
    code: "510800",
    level: "city",
  },
  {
    id: 3170,
    pid: 3169,
    name: "\u662d\u5316\u533a",
    code: "510811",
    level: "district",
  },
  {
    id: 3171,
    pid: 3169,
    name: "\u5251\u9601\u53bf",
    code: "510823",
    level: "district",
  },
  {
    id: 3172,
    pid: 3169,
    name: "\u671d\u5929\u533a",
    code: "510812",
    level: "district",
  },
  {
    id: 3173,
    pid: 3169,
    name: "\u82cd\u6eaa\u53bf",
    code: "510824",
    level: "district",
  },
  {
    id: 3174,
    pid: 3169,
    name: "\u65fa\u82cd\u53bf",
    code: "510821",
    level: "district",
  },
  {
    id: 3175,
    pid: 3169,
    name: "\u9752\u5ddd\u53bf",
    code: "510822",
    level: "district",
  },
  {
    id: 3176,
    pid: 3169,
    name: "\u5229\u5dde\u533a",
    code: "510802",
    level: "district",
  },
  {
    id: 3177,
    pid: 3134,
    name: "\u9042\u5b81\u5e02",
    code: "510900",
    level: "city",
  },
  {
    id: 3178,
    pid: 3177,
    name: "\u5927\u82f1\u53bf",
    code: "510923",
    level: "district",
  },
  {
    id: 3179,
    pid: 3177,
    name: "\u5c04\u6d2a\u5e02",
    code: "510981",
    level: "district",
  },
  {
    id: 3180,
    pid: 3177,
    name: "\u84ec\u6eaa\u53bf",
    code: "510921",
    level: "district",
  },
  {
    id: 3181,
    pid: 3177,
    name: "\u5b89\u5c45\u533a",
    code: "510904",
    level: "district",
  },
  {
    id: 3182,
    pid: 3177,
    name: "\u8239\u5c71\u533a",
    code: "510903",
    level: "district",
  },
  {
    id: 3183,
    pid: 3134,
    name: "\u8d44\u9633\u5e02",
    code: "512000",
    level: "city",
  },
  {
    id: 3184,
    pid: 3183,
    name: "\u5b89\u5cb3\u53bf",
    code: "512021",
    level: "district",
  },
  {
    id: 3185,
    pid: 3183,
    name: "\u96c1\u6c5f\u533a",
    code: "512002",
    level: "district",
  },
  {
    id: 3186,
    pid: 3183,
    name: "\u4e50\u81f3\u53bf",
    code: "512022",
    level: "district",
  },
  {
    id: 3187,
    pid: 3134,
    name: "\u7ef5\u9633\u5e02",
    code: "510700",
    level: "city",
  },
  {
    id: 3188,
    pid: 3187,
    name: "\u6e38\u4ed9\u533a",
    code: "510704",
    level: "district",
  },
  {
    id: 3189,
    pid: 3187,
    name: "\u6893\u6f7c\u53bf",
    code: "510725",
    level: "district",
  },
  {
    id: 3190,
    pid: 3187,
    name: "\u4e09\u53f0\u53bf",
    code: "510722",
    level: "district",
  },
  {
    id: 3191,
    pid: 3187,
    name: "\u6c5f\u6cb9\u5e02",
    code: "510781",
    level: "district",
  },
  {
    id: 3192,
    pid: 3187,
    name: "\u76d0\u4ead\u53bf",
    code: "510723",
    level: "district",
  },
  {
    id: 3193,
    pid: 3187,
    name: "\u5317\u5ddd\u7f8c\u65cf\u81ea\u6cbb\u53bf",
    code: "510726",
    level: "district",
  },
  {
    id: 3194,
    pid: 3187,
    name: "\u5b89\u5dde\u533a",
    code: "510705",
    level: "district",
  },
  {
    id: 3195,
    pid: 3187,
    name: "\u6daa\u57ce\u533a",
    code: "510703",
    level: "district",
  },
  {
    id: 3196,
    pid: 3187,
    name: "\u5e73\u6b66\u53bf",
    code: "510727",
    level: "district",
  },
  {
    id: 3197,
    pid: 3134,
    name: "\u5e7f\u5b89\u5e02",
    code: "511600",
    level: "city",
  },
  {
    id: 3198,
    pid: 3197,
    name: "\u90bb\u6c34\u53bf",
    code: "511623",
    level: "district",
  },
  {
    id: 3199,
    pid: 3197,
    name: "\u6b66\u80dc\u53bf",
    code: "511622",
    level: "district",
  },
  {
    id: 3200,
    pid: 3197,
    name: "\u5e7f\u5b89\u533a",
    code: "511602",
    level: "district",
  },
  {
    id: 3201,
    pid: 3197,
    name: "\u534e\u84e5\u5e02",
    code: "511681",
    level: "district",
  },
  {
    id: 3202,
    pid: 3197,
    name: "\u524d\u950b\u533a",
    code: "511603",
    level: "district",
  },
  {
    id: 3203,
    pid: 3197,
    name: "\u5cb3\u6c60\u53bf",
    code: "511621",
    level: "district",
  },
  {
    id: 3204,
    pid: 3134,
    name: "\u8fbe\u5dde\u5e02",
    code: "511700",
    level: "city",
  },
  {
    id: 3205,
    pid: 3204,
    name: "\u5927\u7af9\u53bf",
    code: "511724",
    level: "district",
  },
  {
    id: 3206,
    pid: 3204,
    name: "\u6e20\u53bf",
    code: "511725",
    level: "district",
  },
  {
    id: 3207,
    pid: 3204,
    name: "\u5ba3\u6c49\u53bf",
    code: "511722",
    level: "district",
  },
  {
    id: 3208,
    pid: 3204,
    name: "\u5f00\u6c5f\u53bf",
    code: "511723",
    level: "district",
  },
  {
    id: 3209,
    pid: 3204,
    name: "\u4e07\u6e90\u5e02",
    code: "511781",
    level: "district",
  },
  {
    id: 3210,
    pid: 3204,
    name: "\u901a\u5ddd\u533a",
    code: "511702",
    level: "district",
  },
  {
    id: 3211,
    pid: 3204,
    name: "\u8fbe\u5ddd\u533a",
    code: "511703",
    level: "district",
  },
  {
    id: 3212,
    pid: 3134,
    name: "\u5357\u5145\u5e02",
    code: "511300",
    level: "city",
  },
  {
    id: 3213,
    pid: 3212,
    name: "\u84ec\u5b89\u53bf",
    code: "511323",
    level: "district",
  },
  {
    id: 3214,
    pid: 3212,
    name: "\u897f\u5145\u53bf",
    code: "511325",
    level: "district",
  },
  {
    id: 3215,
    pid: 3212,
    name: "\u8425\u5c71\u53bf",
    code: "511322",
    level: "district",
  },
  {
    id: 3216,
    pid: 3212,
    name: "\u5357\u90e8\u53bf",
    code: "511321",
    level: "district",
  },
  {
    id: 3217,
    pid: 3212,
    name: "\u5609\u9675\u533a",
    code: "511304",
    level: "district",
  },
  {
    id: 3218,
    pid: 3212,
    name: "\u4eea\u9647\u53bf",
    code: "511324",
    level: "district",
  },
  {
    id: 3219,
    pid: 3212,
    name: "\u9ad8\u576a\u533a",
    code: "511303",
    level: "district",
  },
  {
    id: 3220,
    pid: 3212,
    name: "\u987a\u5e86\u533a",
    code: "511302",
    level: "district",
  },
  {
    id: 3221,
    pid: 3212,
    name: "\u9606\u4e2d\u5e02",
    code: "511381",
    level: "district",
  },
  {
    id: 3222,
    pid: 3134,
    name: "\u5185\u6c5f\u5e02",
    code: "511000",
    level: "city",
  },
  {
    id: 3223,
    pid: 3222,
    name: "\u8d44\u4e2d\u53bf",
    code: "511025",
    level: "district",
  },
  {
    id: 3224,
    pid: 3222,
    name: "\u4e1c\u5174\u533a",
    code: "511011",
    level: "district",
  },
  {
    id: 3225,
    pid: 3222,
    name: "\u5a01\u8fdc\u53bf",
    code: "511024",
    level: "district",
  },
  {
    id: 3226,
    pid: 3222,
    name: "\u9686\u660c\u5e02",
    code: "511083",
    level: "district",
  },
  {
    id: 3227,
    pid: 3222,
    name: "\u5e02\u4e2d\u533a",
    code: "511002",
    level: "district",
  },
  {
    id: 3228,
    pid: 3134,
    name: "\u5b9c\u5bbe\u5e02",
    code: "511500",
    level: "city",
  },
  {
    id: 3229,
    pid: 3228,
    name: "\u73d9\u53bf",
    code: "511526",
    level: "district",
  },
  {
    id: 3230,
    pid: 3228,
    name: "\u5174\u6587\u53bf",
    code: "511528",
    level: "district",
  },
  {
    id: 3231,
    pid: 3228,
    name: "\u957f\u5b81\u53bf",
    code: "511524",
    level: "district",
  },
  {
    id: 3232,
    pid: 3228,
    name: "\u6c5f\u5b89\u53bf",
    code: "511523",
    level: "district",
  },
  {
    id: 3233,
    pid: 3228,
    name: "\u5357\u6eaa\u533a",
    code: "511503",
    level: "district",
  },
  {
    id: 3234,
    pid: 3228,
    name: "\u7fe0\u5c4f\u533a",
    code: "511502",
    level: "district",
  },
  {
    id: 3235,
    pid: 3228,
    name: "\u9ad8\u53bf",
    code: "511525",
    level: "district",
  },
  {
    id: 3236,
    pid: 3228,
    name: "\u7b60\u8fde\u53bf",
    code: "511527",
    level: "district",
  },
  {
    id: 3237,
    pid: 3228,
    name: "\u5c4f\u5c71\u53bf",
    code: "511529",
    level: "district",
  },
  {
    id: 3238,
    pid: 3228,
    name: "\u53d9\u5dde\u533a",
    code: "511504",
    level: "district",
  },
  {
    id: 3239,
    pid: 3134,
    name: "\u6cf8\u5dde\u5e02",
    code: "510500",
    level: "city",
  },
  {
    id: 3240,
    pid: 3239,
    name: "\u9f99\u9a6c\u6f6d\u533a",
    code: "510504",
    level: "district",
  },
  {
    id: 3241,
    pid: 3239,
    name: "\u6cf8\u53bf",
    code: "510521",
    level: "district",
  },
  {
    id: 3242,
    pid: 3239,
    name: "\u7eb3\u6eaa\u533a",
    code: "510503",
    level: "district",
  },
  {
    id: 3243,
    pid: 3239,
    name: "\u53d9\u6c38\u53bf",
    code: "510524",
    level: "district",
  },
  {
    id: 3244,
    pid: 3239,
    name: "\u53e4\u853a\u53bf",
    code: "510525",
    level: "district",
  },
  {
    id: 3245,
    pid: 3239,
    name: "\u6c5f\u9633\u533a",
    code: "510502",
    level: "district",
  },
  {
    id: 3246,
    pid: 3239,
    name: "\u5408\u6c5f\u53bf",
    code: "510522",
    level: "district",
  },
  {
    id: 3247,
    pid: 3134,
    name: "\u963f\u575d\u85cf\u65cf\u7f8c\u65cf\u81ea\u6cbb\u5dde",
    code: "513200",
    level: "city",
  },
  {
    id: 3248,
    pid: 3247,
    name: "\u963f\u575d\u53bf",
    code: "513231",
    level: "district",
  },
  {
    id: 3249,
    pid: 3247,
    name: "\u9ed1\u6c34\u53bf",
    code: "513228",
    level: "district",
  },
  {
    id: 3250,
    pid: 3247,
    name: "\u4e5d\u5be8\u6c9f\u53bf",
    code: "513225",
    level: "district",
  },
  {
    id: 3251,
    pid: 3247,
    name: "\u7406\u53bf",
    code: "513222",
    level: "district",
  },
  {
    id: 3252,
    pid: 3247,
    name: "\u82e5\u5c14\u76d6\u53bf",
    code: "513232",
    level: "district",
  },
  {
    id: 3253,
    pid: 3247,
    name: "\u91d1\u5ddd\u53bf",
    code: "513226",
    level: "district",
  },
  {
    id: 3254,
    pid: 3247,
    name: "\u7ea2\u539f\u53bf",
    code: "513233",
    level: "district",
  },
  {
    id: 3255,
    pid: 3247,
    name: "\u6c76\u5ddd\u53bf",
    code: "513221",
    level: "district",
  },
  {
    id: 3256,
    pid: 3247,
    name: "\u9a6c\u5c14\u5eb7\u5e02",
    code: "513201",
    level: "district",
  },
  {
    id: 3257,
    pid: 3247,
    name: "\u677e\u6f58\u53bf",
    code: "513224",
    level: "district",
  },
  {
    id: 3258,
    pid: 3247,
    name: "\u8302\u53bf",
    code: "513223",
    level: "district",
  },
  {
    id: 3259,
    pid: 3247,
    name: "\u5c0f\u91d1\u53bf",
    code: "513227",
    level: "district",
  },
  {
    id: 3260,
    pid: 3247,
    name: "\u58e4\u5858\u53bf",
    code: "513230",
    level: "district",
  },
  {
    id: 3261,
    pid: 3134,
    name: "\u81ea\u8d21\u5e02",
    code: "510300",
    level: "city",
  },
  {
    id: 3262,
    pid: 3261,
    name: "\u6cbf\u6ee9\u533a",
    code: "510311",
    level: "district",
  },
  {
    id: 3263,
    pid: 3261,
    name: "\u5927\u5b89\u533a",
    code: "510304",
    level: "district",
  },
  {
    id: 3264,
    pid: 3261,
    name: "\u8363\u53bf",
    code: "510321",
    level: "district",
  },
  {
    id: 3265,
    pid: 3261,
    name: "\u81ea\u6d41\u4e95\u533a",
    code: "510302",
    level: "district",
  },
  {
    id: 3266,
    pid: 3261,
    name: "\u5bcc\u987a\u53bf",
    code: "510322",
    level: "district",
  },
  {
    id: 3267,
    pid: 3261,
    name: "\u8d21\u4e95\u533a",
    code: "510303",
    level: "district",
  },
  {
    id: 3268,
    pid: 3134,
    name: "\u96c5\u5b89\u5e02",
    code: "511800",
    level: "city",
  },
  {
    id: 3269,
    pid: 3268,
    name: "\u5b9d\u5174\u53bf",
    code: "511827",
    level: "district",
  },
  {
    id: 3270,
    pid: 3268,
    name: "\u8365\u7ecf\u53bf",
    code: "511822",
    level: "district",
  },
  {
    id: 3271,
    pid: 3268,
    name: "\u77f3\u68c9\u53bf",
    code: "511824",
    level: "district",
  },
  {
    id: 3272,
    pid: 3268,
    name: "\u6c49\u6e90\u53bf",
    code: "511823",
    level: "district",
  },
  {
    id: 3273,
    pid: 3268,
    name: "\u5929\u5168\u53bf",
    code: "511825",
    level: "district",
  },
  {
    id: 3274,
    pid: 3268,
    name: "\u96e8\u57ce\u533a",
    code: "511802",
    level: "district",
  },
  {
    id: 3275,
    pid: 3268,
    name: "\u82a6\u5c71\u53bf",
    code: "511826",
    level: "district",
  },
  {
    id: 3276,
    pid: 3268,
    name: "\u540d\u5c71\u533a",
    code: "511803",
    level: "district",
  },
  {
    id: 3277,
    pid: 3134,
    name: "\u7709\u5c71\u5e02",
    code: "511400",
    level: "city",
  },
  {
    id: 3278,
    pid: 3277,
    name: "\u6d2a\u96c5\u53bf",
    code: "511423",
    level: "district",
  },
  {
    id: 3279,
    pid: 3277,
    name: "\u4e39\u68f1\u53bf",
    code: "511424",
    level: "district",
  },
  {
    id: 3280,
    pid: 3277,
    name: "\u4ec1\u5bff\u53bf",
    code: "511421",
    level: "district",
  },
  {
    id: 3281,
    pid: 3277,
    name: "\u5f6d\u5c71\u533a",
    code: "511403",
    level: "district",
  },
  {
    id: 3282,
    pid: 3277,
    name: "\u9752\u795e\u53bf",
    code: "511425",
    level: "district",
  },
  {
    id: 3283,
    pid: 3277,
    name: "\u4e1c\u5761\u533a",
    code: "511402",
    level: "district",
  },
  {
    id: 3284,
    pid: 3134,
    name: "\u4e50\u5c71\u5e02",
    code: "511100",
    level: "city",
  },
  {
    id: 3285,
    pid: 3284,
    name: "\u6c99\u6e7e\u533a",
    code: "511111",
    level: "district",
  },
  {
    id: 3286,
    pid: 3284,
    name: "\u5939\u6c5f\u53bf",
    code: "511126",
    level: "district",
  },
  {
    id: 3287,
    pid: 3284,
    name: "\u4e95\u7814\u53bf",
    code: "511124",
    level: "district",
  },
  {
    id: 3288,
    pid: 3284,
    name: "\u91d1\u53e3\u6cb3\u533a",
    code: "511113",
    level: "district",
  },
  {
    id: 3289,
    pid: 3284,
    name: "\u728d\u4e3a\u53bf",
    code: "511123",
    level: "district",
  },
  {
    id: 3290,
    pid: 3284,
    name: "\u4e94\u901a\u6865\u533a",
    code: "511112",
    level: "district",
  },
  {
    id: 3291,
    pid: 3284,
    name: "\u6c90\u5ddd\u53bf",
    code: "511129",
    level: "district",
  },
  {
    id: 3292,
    pid: 3284,
    name: "\u5ce8\u8fb9\u5f5d\u65cf\u81ea\u6cbb\u53bf",
    code: "511132",
    level: "district",
  },
  {
    id: 3293,
    pid: 3284,
    name: "\u9a6c\u8fb9\u5f5d\u65cf\u81ea\u6cbb\u53bf",
    code: "511133",
    level: "district",
  },
  {
    id: 3294,
    pid: 3284,
    name: "\u5e02\u4e2d\u533a",
    code: "511102",
    level: "district",
  },
  {
    id: 3295,
    pid: 3284,
    name: "\u5ce8\u7709\u5c71\u5e02",
    code: "511181",
    level: "district",
  },
  {
    id: 3296,
    pid: 3134,
    name: "\u6500\u679d\u82b1\u5e02",
    code: "510400",
    level: "city",
  },
  {
    id: 3297,
    pid: 3296,
    name: "\u7c73\u6613\u53bf",
    code: "510421",
    level: "district",
  },
  {
    id: 3298,
    pid: 3296,
    name: "\u897f\u533a",
    code: "510403",
    level: "district",
  },
  {
    id: 3299,
    pid: 3296,
    name: "\u76d0\u8fb9\u53bf",
    code: "510422",
    level: "district",
  },
  {
    id: 3300,
    pid: 3296,
    name: "\u4ec1\u548c\u533a",
    code: "510411",
    level: "district",
  },
  {
    id: 3301,
    pid: 3296,
    name: "\u4e1c\u533a",
    code: "510402",
    level: "district",
  },
  {
    id: 3302,
    pid: 3134,
    name: "\u51c9\u5c71\u5f5d\u65cf\u81ea\u6cbb\u5dde",
    code: "513400",
    level: "city",
  },
  {
    id: 3303,
    pid: 3302,
    name: "\u4f1a\u7406\u53bf",
    code: "513425",
    level: "district",
  },
  {
    id: 3304,
    pid: 3302,
    name: "\u96f7\u6ce2\u53bf",
    code: "513437",
    level: "district",
  },
  {
    id: 3305,
    pid: 3302,
    name: "\u559c\u5fb7\u53bf",
    code: "513432",
    level: "district",
  },
  {
    id: 3306,
    pid: 3302,
    name: "\u662d\u89c9\u53bf",
    code: "513431",
    level: "district",
  },
  {
    id: 3307,
    pid: 3302,
    name: "\u5e03\u62d6\u53bf",
    code: "513429",
    level: "district",
  },
  {
    id: 3308,
    pid: 3302,
    name: "\u897f\u660c\u5e02",
    code: "513401",
    level: "district",
  },
  {
    id: 3309,
    pid: 3302,
    name: "\u666e\u683c\u53bf",
    code: "513428",
    level: "district",
  },
  {
    id: 3310,
    pid: 3302,
    name: "\u6728\u91cc\u85cf\u65cf\u81ea\u6cbb\u53bf",
    code: "513422",
    level: "district",
  },
  {
    id: 3311,
    pid: 3302,
    name: "\u5195\u5b81\u53bf",
    code: "513433",
    level: "district",
  },
  {
    id: 3312,
    pid: 3302,
    name: "\u4f1a\u4e1c\u53bf",
    code: "513426",
    level: "district",
  },
  {
    id: 3313,
    pid: 3302,
    name: "\u8d8a\u897f\u53bf",
    code: "513434",
    level: "district",
  },
  {
    id: 3314,
    pid: 3302,
    name: "\u7518\u6d1b\u53bf",
    code: "513435",
    level: "district",
  },
  {
    id: 3315,
    pid: 3302,
    name: "\u7f8e\u59d1\u53bf",
    code: "513436",
    level: "district",
  },
  {
    id: 3316,
    pid: 3302,
    name: "\u76d0\u6e90\u53bf",
    code: "513423",
    level: "district",
  },
  {
    id: 3317,
    pid: 3302,
    name: "\u91d1\u9633\u53bf",
    code: "513430",
    level: "district",
  },
  {
    id: 3318,
    pid: 3302,
    name: "\u5fb7\u660c\u53bf",
    code: "513424",
    level: "district",
  },
  {
    id: 3319,
    pid: 3302,
    name: "\u5b81\u5357\u53bf",
    code: "513427",
    level: "district",
  },
  {
    id: 3320,
    pid: 3134,
    name: "\u7518\u5b5c\u85cf\u65cf\u81ea\u6cbb\u5dde",
    code: "513300",
    level: "city",
  },
  {
    id: 3321,
    pid: 3320,
    name: "\u7518\u5b5c\u53bf",
    code: "513328",
    level: "district",
  },
  {
    id: 3322,
    pid: 3320,
    name: "\u77f3\u6e20\u53bf",
    code: "513332",
    level: "district",
  },
  {
    id: 3323,
    pid: 3320,
    name: "\u7a3b\u57ce\u53bf",
    code: "513337",
    level: "district",
  },
  {
    id: 3324,
    pid: 3320,
    name: "\u65b0\u9f99\u53bf",
    code: "513329",
    level: "district",
  },
  {
    id: 3325,
    pid: 3320,
    name: "\u5fb7\u683c\u53bf",
    code: "513330",
    level: "district",
  },
  {
    id: 3326,
    pid: 3320,
    name: "\u96c5\u6c5f\u53bf",
    code: "513325",
    level: "district",
  },
  {
    id: 3327,
    pid: 3320,
    name: "\u767d\u7389\u53bf",
    code: "513331",
    level: "district",
  },
  {
    id: 3328,
    pid: 3320,
    name: "\u9053\u5b5a\u53bf",
    code: "513326",
    level: "district",
  },
  {
    id: 3329,
    pid: 3320,
    name: "\u5df4\u5858\u53bf",
    code: "513335",
    level: "district",
  },
  {
    id: 3330,
    pid: 3320,
    name: "\u7089\u970d\u53bf",
    code: "513327",
    level: "district",
  },
  {
    id: 3331,
    pid: 3320,
    name: "\u5eb7\u5b9a\u5e02",
    code: "513301",
    level: "district",
  },
  {
    id: 3332,
    pid: 3320,
    name: "\u7406\u5858\u53bf",
    code: "513334",
    level: "district",
  },
  {
    id: 3333,
    pid: 3320,
    name: "\u4e61\u57ce\u53bf",
    code: "513336",
    level: "district",
  },
  {
    id: 3334,
    pid: 3320,
    name: "\u4e5d\u9f99\u53bf",
    code: "513324",
    level: "district",
  },
  {
    id: 3335,
    pid: 3320,
    name: "\u5f97\u8363\u53bf",
    code: "513338",
    level: "district",
  },
  {
    id: 3336,
    pid: 3320,
    name: "\u8272\u8fbe\u53bf",
    code: "513333",
    level: "district",
  },
  {
    id: 3337,
    pid: 3320,
    name: "\u4e39\u5df4\u53bf",
    code: "513323",
    level: "district",
  },
  {
    id: 3338,
    pid: 3320,
    name: "\u6cf8\u5b9a\u53bf",
    code: "513322",
    level: "district",
  },
  {
    id: 3339,
    pid: 0,
    name: "\u5409\u6797\u7701",
    code: "220000",
    level: "province",
  },
  {
    id: 3340,
    pid: 3339,
    name: "\u767d\u57ce\u5e02",
    code: "220800",
    level: "city",
  },
  {
    id: 3341,
    pid: 3340,
    name: "\u6d2e\u5357\u5e02",
    code: "220881",
    level: "district",
  },
  {
    id: 3342,
    pid: 3340,
    name: "\u5927\u5b89\u5e02",
    code: "220882",
    level: "district",
  },
  {
    id: 3343,
    pid: 3340,
    name: "\u901a\u6986\u53bf",
    code: "220822",
    level: "district",
  },
  {
    id: 3344,
    pid: 3340,
    name: "\u6d2e\u5317\u533a",
    code: "220802",
    level: "district",
  },
  {
    id: 3345,
    pid: 3340,
    name: "\u9547\u8d49\u53bf",
    code: "220821",
    level: "district",
  },
  {
    id: 3346,
    pid: 3339,
    name: "\u957f\u6625\u5e02",
    code: "220100",
    level: "city",
  },
  {
    id: 3347,
    pid: 3346,
    name: "\u5fb7\u60e0\u5e02",
    code: "220183",
    level: "district",
  },
  {
    id: 3348,
    pid: 3346,
    name: "\u6986\u6811\u5e02",
    code: "220182",
    level: "district",
  },
  {
    id: 3349,
    pid: 3346,
    name: "\u53cc\u9633\u533a",
    code: "220112",
    level: "district",
  },
  {
    id: 3350,
    pid: 3346,
    name: "\u519c\u5b89\u53bf",
    code: "220122",
    level: "district",
  },
  {
    id: 3351,
    pid: 3346,
    name: "\u5bbd\u57ce\u533a",
    code: "220103",
    level: "district",
  },
  {
    id: 3352,
    pid: 3346,
    name: "\u4e5d\u53f0\u533a",
    code: "220113",
    level: "district",
  },
  {
    id: 3353,
    pid: 3346,
    name: "\u7eff\u56ed\u533a",
    code: "220106",
    level: "district",
  },
  {
    id: 3354,
    pid: 3346,
    name: "\u516c\u4e3b\u5cad\u5e02",
    code: "220184",
    level: "district",
  },
  {
    id: 3355,
    pid: 3346,
    name: "\u671d\u9633\u533a",
    code: "220104",
    level: "district",
  },
  {
    id: 3356,
    pid: 3346,
    name: "\u4e8c\u9053\u533a",
    code: "220105",
    level: "district",
  },
  {
    id: 3357,
    pid: 3346,
    name: "\u5357\u5173\u533a",
    code: "220102",
    level: "district",
  },
  {
    id: 3358,
    pid: 3339,
    name: "\u5409\u6797\u5e02",
    code: "220200",
    level: "city",
  },
  {
    id: 3359,
    pid: 3358,
    name: "\u4e30\u6ee1\u533a",
    code: "220211",
    level: "district",
  },
  {
    id: 3360,
    pid: 3358,
    name: "\u8212\u5170\u5e02",
    code: "220283",
    level: "district",
  },
  {
    id: 3361,
    pid: 3358,
    name: "\u6866\u7538\u5e02",
    code: "220282",
    level: "district",
  },
  {
    id: 3362,
    pid: 3358,
    name: "\u86df\u6cb3\u5e02",
    code: "220281",
    level: "district",
  },
  {
    id: 3363,
    pid: 3358,
    name: "\u9f99\u6f6d\u533a",
    code: "220203",
    level: "district",
  },
  {
    id: 3364,
    pid: 3358,
    name: "\u6c38\u5409\u53bf",
    code: "220221",
    level: "district",
  },
  {
    id: 3365,
    pid: 3358,
    name: "\u78d0\u77f3\u5e02",
    code: "220284",
    level: "district",
  },
  {
    id: 3366,
    pid: 3358,
    name: "\u660c\u9091\u533a",
    code: "220202",
    level: "district",
  },
  {
    id: 3367,
    pid: 3358,
    name: "\u8239\u8425\u533a",
    code: "220204",
    level: "district",
  },
  {
    id: 3368,
    pid: 3339,
    name: "\u677e\u539f\u5e02",
    code: "220700",
    level: "city",
  },
  {
    id: 3369,
    pid: 3368,
    name: "\u6276\u4f59\u5e02",
    code: "220781",
    level: "district",
  },
  {
    id: 3370,
    pid: 3368,
    name: "\u5b81\u6c5f\u533a",
    code: "220702",
    level: "district",
  },
  {
    id: 3371,
    pid: 3368,
    name: "\u4e7e\u5b89\u53bf",
    code: "220723",
    level: "district",
  },
  {
    id: 3372,
    pid: 3368,
    name: "\u524d\u90ed\u5c14\u7f57\u65af\u8499\u53e4\u65cf\u81ea\u6cbb\u53bf",
    code: "220721",
    level: "district",
  },
  {
    id: 3373,
    pid: 3368,
    name: "\u957f\u5cad\u53bf",
    code: "220722",
    level: "district",
  },
  {
    id: 3374,
    pid: 3339,
    name: "\u8fbd\u6e90\u5e02",
    code: "220400",
    level: "city",
  },
  {
    id: 3375,
    pid: 3374,
    name: "\u897f\u5b89\u533a",
    code: "220403",
    level: "district",
  },
  {
    id: 3376,
    pid: 3374,
    name: "\u4e1c\u4e30\u53bf",
    code: "220421",
    level: "district",
  },
  {
    id: 3377,
    pid: 3374,
    name: "\u4e1c\u8fbd\u53bf",
    code: "220422",
    level: "district",
  },
  {
    id: 3378,
    pid: 3374,
    name: "\u9f99\u5c71\u533a",
    code: "220402",
    level: "district",
  },
  {
    id: 3379,
    pid: 3339,
    name: "\u5ef6\u8fb9\u671d\u9c9c\u65cf\u81ea\u6cbb\u5dde",
    code: "222400",
    level: "city",
  },
  {
    id: 3380,
    pid: 3379,
    name: "\u6566\u5316\u5e02",
    code: "222403",
    level: "district",
  },
  {
    id: 3381,
    pid: 3379,
    name: "\u73f2\u6625\u5e02",
    code: "222404",
    level: "district",
  },
  {
    id: 3382,
    pid: 3379,
    name: "\u56fe\u4eec\u5e02",
    code: "222402",
    level: "district",
  },
  {
    id: 3383,
    pid: 3379,
    name: "\u548c\u9f99\u5e02",
    code: "222406",
    level: "district",
  },
  {
    id: 3384,
    pid: 3379,
    name: "\u6c6a\u6e05\u53bf",
    code: "222424",
    level: "district",
  },
  {
    id: 3385,
    pid: 3379,
    name: "\u5ef6\u5409\u5e02",
    code: "222401",
    level: "district",
  },
  {
    id: 3386,
    pid: 3379,
    name: "\u5b89\u56fe\u53bf",
    code: "222426",
    level: "district",
  },
  {
    id: 3387,
    pid: 3379,
    name: "\u9f99\u4e95\u5e02",
    code: "222405",
    level: "district",
  },
  {
    id: 3388,
    pid: 3339,
    name: "\u767d\u5c71\u5e02",
    code: "220600",
    level: "city",
  },
  {
    id: 3389,
    pid: 3388,
    name: "\u957f\u767d\u671d\u9c9c\u65cf\u81ea\u6cbb\u53bf",
    code: "220623",
    level: "district",
  },
  {
    id: 3390,
    pid: 3388,
    name: "\u6c5f\u6e90\u533a",
    code: "220605",
    level: "district",
  },
  {
    id: 3391,
    pid: 3388,
    name: "\u9756\u5b87\u53bf",
    code: "220622",
    level: "district",
  },
  {
    id: 3392,
    pid: 3388,
    name: "\u629a\u677e\u53bf",
    code: "220621",
    level: "district",
  },
  {
    id: 3393,
    pid: 3388,
    name: "\u6d51\u6c5f\u533a",
    code: "220602",
    level: "district",
  },
  {
    id: 3394,
    pid: 3388,
    name: "\u4e34\u6c5f\u5e02",
    code: "220681",
    level: "district",
  },
  {
    id: 3395,
    pid: 3339,
    name: "\u901a\u5316\u5e02",
    code: "220500",
    level: "city",
  },
  {
    id: 3396,
    pid: 3395,
    name: "\u901a\u5316\u53bf",
    code: "220521",
    level: "district",
  },
  {
    id: 3397,
    pid: 3395,
    name: "\u4e8c\u9053\u6c5f\u533a",
    code: "220503",
    level: "district",
  },
  {
    id: 3398,
    pid: 3395,
    name: "\u4e1c\u660c\u533a",
    code: "220502",
    level: "district",
  },
  {
    id: 3399,
    pid: 3395,
    name: "\u67f3\u6cb3\u53bf",
    code: "220524",
    level: "district",
  },
  {
    id: 3400,
    pid: 3395,
    name: "\u6885\u6cb3\u53e3\u5e02",
    code: "220581",
    level: "district",
  },
  {
    id: 3401,
    pid: 3395,
    name: "\u8f89\u5357\u53bf",
    code: "220523",
    level: "district",
  },
  {
    id: 3402,
    pid: 3395,
    name: "\u96c6\u5b89\u5e02",
    code: "220582",
    level: "district",
  },
  {
    id: 3403,
    pid: 3339,
    name: "\u56db\u5e73\u5e02",
    code: "220300",
    level: "city",
  },
  {
    id: 3404,
    pid: 3403,
    name: "\u94c1\u4e1c\u533a",
    code: "220303",
    level: "district",
  },
  {
    id: 3405,
    pid: 3403,
    name: "\u53cc\u8fbd\u5e02",
    code: "220382",
    level: "district",
  },
  {
    id: 3406,
    pid: 3403,
    name: "\u4f0a\u901a\u6ee1\u65cf\u81ea\u6cbb\u53bf",
    code: "220323",
    level: "district",
  },
  {
    id: 3407,
    pid: 3403,
    name: "\u68a8\u6811\u53bf",
    code: "220322",
    level: "district",
  },
  {
    id: 3408,
    pid: 3403,
    name: "\u94c1\u897f\u533a",
    code: "220302",
    level: "district",
  },
  {
    id: 3409,
    pid: 0,
    name: "\u5929\u6d25\u5e02",
    code: "120000",
    level: "province",
  },
  {
    id: 3410,
    pid: 3409,
    name: "\u5929\u6d25\u57ce\u533a",
    code: "120100",
    level: "city",
  },
  {
    id: 3411,
    pid: 3410,
    name: "\u6cb3\u4e1c\u533a",
    code: "120102",
    level: "district",
  },
  {
    id: 3412,
    pid: 3410,
    name: "\u6cb3\u5317\u533a",
    code: "120105",
    level: "district",
  },
  {
    id: 3413,
    pid: 3410,
    name: "\u548c\u5e73\u533a",
    code: "120101",
    level: "district",
  },
  {
    id: 3414,
    pid: 3410,
    name: "\u5b9d\u577b\u533a",
    code: "120115",
    level: "district",
  },
  {
    id: 3415,
    pid: 3410,
    name: "\u4e1c\u4e3d\u533a",
    code: "120110",
    level: "district",
  },
  {
    id: 3416,
    pid: 3410,
    name: "\u6d25\u5357\u533a",
    code: "120112",
    level: "district",
  },
  {
    id: 3417,
    pid: 3410,
    name: "\u6ee8\u6d77\u65b0\u533a",
    code: "120116",
    level: "district",
  },
  {
    id: 3418,
    pid: 3410,
    name: "\u9759\u6d77\u533a",
    code: "120118",
    level: "district",
  },
  {
    id: 3419,
    pid: 3410,
    name: "\u84df\u5dde\u533a",
    code: "120119",
    level: "district",
  },
  {
    id: 3420,
    pid: 3410,
    name: "\u6b66\u6e05\u533a",
    code: "120114",
    level: "district",
  },
  {
    id: 3421,
    pid: 3410,
    name: "\u5357\u5f00\u533a",
    code: "120104",
    level: "district",
  },
  {
    id: 3422,
    pid: 3410,
    name: "\u6cb3\u897f\u533a",
    code: "120103",
    level: "district",
  },
  {
    id: 3423,
    pid: 3410,
    name: "\u5b81\u6cb3\u533a",
    code: "120117",
    level: "district",
  },
  {
    id: 3424,
    pid: 3410,
    name: "\u7ea2\u6865\u533a",
    code: "120106",
    level: "district",
  },
  {
    id: 3425,
    pid: 3410,
    name: "\u5317\u8fb0\u533a",
    code: "120113",
    level: "district",
  },
  {
    id: 3426,
    pid: 3410,
    name: "\u897f\u9752\u533a",
    code: "120111",
    level: "district",
  },
  {
    id: 3427,
    pid: 0,
    name: "\u5c71\u897f\u7701",
    code: "140000",
    level: "province",
  },
  {
    id: 3428,
    pid: 3427,
    name: "\u8fd0\u57ce\u5e02",
    code: "140800",
    level: "city",
  },
  {
    id: 3429,
    pid: 3428,
    name: "\u4e07\u8363\u53bf",
    code: "140822",
    level: "district",
  },
  {
    id: 3430,
    pid: 3428,
    name: "\u4e34\u7317\u53bf",
    code: "140821",
    level: "district",
  },
  {
    id: 3431,
    pid: 3428,
    name: "\u95fb\u559c\u53bf",
    code: "140823",
    level: "district",
  },
  {
    id: 3432,
    pid: 3428,
    name: "\u76d0\u6e56\u533a",
    code: "140802",
    level: "district",
  },
  {
    id: 3433,
    pid: 3428,
    name: "\u82ae\u57ce\u53bf",
    code: "140830",
    level: "district",
  },
  {
    id: 3434,
    pid: 3428,
    name: "\u590f\u53bf",
    code: "140828",
    level: "district",
  },
  {
    id: 3435,
    pid: 3428,
    name: "\u5e73\u9646\u53bf",
    code: "140829",
    level: "district",
  },
  {
    id: 3436,
    pid: 3428,
    name: "\u6c38\u6d4e\u5e02",
    code: "140881",
    level: "district",
  },
  {
    id: 3437,
    pid: 3428,
    name: "\u7edb\u53bf",
    code: "140826",
    level: "district",
  },
  {
    id: 3438,
    pid: 3428,
    name: "\u57a3\u66f2\u53bf",
    code: "140827",
    level: "district",
  },
  {
    id: 3439,
    pid: 3428,
    name: "\u65b0\u7edb\u53bf",
    code: "140825",
    level: "district",
  },
  {
    id: 3440,
    pid: 3428,
    name: "\u7a37\u5c71\u53bf",
    code: "140824",
    level: "district",
  },
  {
    id: 3441,
    pid: 3428,
    name: "\u6cb3\u6d25\u5e02",
    code: "140882",
    level: "district",
  },
  {
    id: 3442,
    pid: 3427,
    name: "\u4e34\u6c7e\u5e02",
    code: "141000",
    level: "city",
  },
  {
    id: 3443,
    pid: 3442,
    name: "\u53e4\u53bf",
    code: "141025",
    level: "district",
  },
  {
    id: 3444,
    pid: 3442,
    name: "\u5b89\u6cfd\u53bf",
    code: "141026",
    level: "district",
  },
  {
    id: 3445,
    pid: 3442,
    name: "\u5927\u5b81\u53bf",
    code: "141030",
    level: "district",
  },
  {
    id: 3446,
    pid: 3442,
    name: "\u6c7e\u897f\u53bf",
    code: "141034",
    level: "district",
  },
  {
    id: 3447,
    pid: 3442,
    name: "\u96b0\u53bf",
    code: "141031",
    level: "district",
  },
  {
    id: 3448,
    pid: 3442,
    name: "\u5409\u53bf",
    code: "141028",
    level: "district",
  },
  {
    id: 3449,
    pid: 3442,
    name: "\u4faf\u9a6c\u5e02",
    code: "141081",
    level: "district",
  },
  {
    id: 3450,
    pid: 3442,
    name: "\u6c38\u548c\u53bf",
    code: "141032",
    level: "district",
  },
  {
    id: 3451,
    pid: 3442,
    name: "\u970d\u5dde\u5e02",
    code: "141082",
    level: "district",
  },
  {
    id: 3452,
    pid: 3442,
    name: "\u4e61\u5b81\u53bf",
    code: "141029",
    level: "district",
  },
  {
    id: 3453,
    pid: 3442,
    name: "\u66f2\u6c83\u53bf",
    code: "141021",
    level: "district",
  },
  {
    id: 3454,
    pid: 3442,
    name: "\u84b2\u53bf",
    code: "141033",
    level: "district",
  },
  {
    id: 3455,
    pid: 3442,
    name: "\u6d6e\u5c71\u53bf",
    code: "141027",
    level: "district",
  },
  {
    id: 3456,
    pid: 3442,
    name: "\u8944\u6c7e\u53bf",
    code: "141023",
    level: "district",
  },
  {
    id: 3457,
    pid: 3442,
    name: "\u6d2a\u6d1e\u53bf",
    code: "141024",
    level: "district",
  },
  {
    id: 3458,
    pid: 3442,
    name: "\u5c27\u90fd\u533a",
    code: "141002",
    level: "district",
  },
  {
    id: 3459,
    pid: 3442,
    name: "\u7ffc\u57ce\u53bf",
    code: "141022",
    level: "district",
  },
  {
    id: 3460,
    pid: 3427,
    name: "\u592a\u539f\u5e02",
    code: "140100",
    level: "city",
  },
  {
    id: 3461,
    pid: 3460,
    name: "\u4e07\u67cf\u6797\u533a",
    code: "140109",
    level: "district",
  },
  {
    id: 3462,
    pid: 3460,
    name: "\u664b\u6e90\u533a",
    code: "140110",
    level: "district",
  },
  {
    id: 3463,
    pid: 3460,
    name: "\u5c16\u8349\u576a\u533a",
    code: "140108",
    level: "district",
  },
  {
    id: 3464,
    pid: 3460,
    name: "\u5a04\u70e6\u53bf",
    code: "140123",
    level: "district",
  },
  {
    id: 3465,
    pid: 3460,
    name: "\u674f\u82b1\u5cad\u533a",
    code: "140107",
    level: "district",
  },
  {
    id: 3466,
    pid: 3460,
    name: "\u8fce\u6cfd\u533a",
    code: "140106",
    level: "district",
  },
  {
    id: 3467,
    pid: 3460,
    name: "\u9633\u66f2\u53bf",
    code: "140122",
    level: "district",
  },
  {
    id: 3468,
    pid: 3460,
    name: "\u6e05\u5f90\u53bf",
    code: "140121",
    level: "district",
  },
  {
    id: 3469,
    pid: 3460,
    name: "\u5c0f\u5e97\u533a",
    code: "140105",
    level: "district",
  },
  {
    id: 3470,
    pid: 3460,
    name: "\u53e4\u4ea4\u5e02",
    code: "140181",
    level: "district",
  },
  {
    id: 3471,
    pid: 3427,
    name: "\u9633\u6cc9\u5e02",
    code: "140300",
    level: "city",
  },
  {
    id: 3472,
    pid: 3471,
    name: "\u5e73\u5b9a\u53bf",
    code: "140321",
    level: "district",
  },
  {
    id: 3473,
    pid: 3471,
    name: "\u90ca\u533a",
    code: "140311",
    level: "district",
  },
  {
    id: 3474,
    pid: 3471,
    name: "\u77ff\u533a",
    code: "140303",
    level: "district",
  },
  {
    id: 3475,
    pid: 3471,
    name: "\u76c2\u53bf",
    code: "140322",
    level: "district",
  },
  {
    id: 3476,
    pid: 3471,
    name: "\u57ce\u533a",
    code: "140302",
    level: "district",
  },
  {
    id: 3477,
    pid: 3427,
    name: "\u6714\u5dde\u5e02",
    code: "140600",
    level: "city",
  },
  {
    id: 3478,
    pid: 3477,
    name: "\u5e73\u9c81\u533a",
    code: "140603",
    level: "district",
  },
  {
    id: 3479,
    pid: 3477,
    name: "\u53f3\u7389\u53bf",
    code: "140623",
    level: "district",
  },
  {
    id: 3480,
    pid: 3477,
    name: "\u6714\u57ce\u533a",
    code: "140602",
    level: "district",
  },
  {
    id: 3481,
    pid: 3477,
    name: "\u5e94\u53bf",
    code: "140622",
    level: "district",
  },
  {
    id: 3482,
    pid: 3477,
    name: "\u5c71\u9634\u53bf",
    code: "140621",
    level: "district",
  },
  {
    id: 3483,
    pid: 3477,
    name: "\u6000\u4ec1\u5e02",
    code: "140681",
    level: "district",
  },
  {
    id: 3484,
    pid: 3427,
    name: "\u5ffb\u5dde\u5e02",
    code: "140900",
    level: "city",
  },
  {
    id: 3485,
    pid: 3484,
    name: "\u504f\u5173\u53bf",
    code: "140932",
    level: "district",
  },
  {
    id: 3486,
    pid: 3484,
    name: "\u9759\u4e50\u53bf",
    code: "140926",
    level: "district",
  },
  {
    id: 3487,
    pid: 3484,
    name: "\u539f\u5e73\u5e02",
    code: "140981",
    level: "district",
  },
  {
    id: 3488,
    pid: 3484,
    name: "\u5ca2\u5c9a\u53bf",
    code: "140929",
    level: "district",
  },
  {
    id: 3489,
    pid: 3484,
    name: "\u4ee3\u53bf",
    code: "140923",
    level: "district",
  },
  {
    id: 3490,
    pid: 3484,
    name: "\u4e94\u5be8\u53bf",
    code: "140928",
    level: "district",
  },
  {
    id: 3491,
    pid: 3484,
    name: "\u5b81\u6b66\u53bf",
    code: "140925",
    level: "district",
  },
  {
    id: 3492,
    pid: 3484,
    name: "\u6cb3\u66f2\u53bf",
    code: "140930",
    level: "district",
  },
  {
    id: 3493,
    pid: 3484,
    name: "\u4e94\u53f0\u53bf",
    code: "140922",
    level: "district",
  },
  {
    id: 3494,
    pid: 3484,
    name: "\u5b9a\u8944\u53bf",
    code: "140921",
    level: "district",
  },
  {
    id: 3495,
    pid: 3484,
    name: "\u5ffb\u5e9c\u533a",
    code: "140902",
    level: "district",
  },
  {
    id: 3496,
    pid: 3484,
    name: "\u4fdd\u5fb7\u53bf",
    code: "140931",
    level: "district",
  },
  {
    id: 3497,
    pid: 3484,
    name: "\u795e\u6c60\u53bf",
    code: "140927",
    level: "district",
  },
  {
    id: 3498,
    pid: 3484,
    name: "\u7e41\u5cd9\u53bf",
    code: "140924",
    level: "district",
  },
  {
    id: 3499,
    pid: 3427,
    name: "\u664b\u4e2d\u5e02",
    code: "140700",
    level: "city",
  },
  {
    id: 3500,
    pid: 3499,
    name: "\u6614\u9633\u53bf",
    code: "140724",
    level: "district",
  },
  {
    id: 3501,
    pid: 3499,
    name: "\u548c\u987a\u53bf",
    code: "140723",
    level: "district",
  },
  {
    id: 3502,
    pid: 3499,
    name: "\u5bff\u9633\u53bf",
    code: "140725",
    level: "district",
  },
  {
    id: 3503,
    pid: 3499,
    name: "\u5de6\u6743\u53bf",
    code: "140722",
    level: "district",
  },
  {
    id: 3504,
    pid: 3499,
    name: "\u6986\u793e\u53bf",
    code: "140721",
    level: "district",
  },
  {
    id: 3505,
    pid: 3499,
    name: "\u4ecb\u4f11\u5e02",
    code: "140781",
    level: "district",
  },
  {
    id: 3506,
    pid: 3499,
    name: "\u7075\u77f3\u53bf",
    code: "140729",
    level: "district",
  },
  {
    id: 3507,
    pid: 3499,
    name: "\u6986\u6b21\u533a",
    code: "140702",
    level: "district",
  },
  {
    id: 3508,
    pid: 3499,
    name: "\u7941\u53bf",
    code: "140727",
    level: "district",
  },
  {
    id: 3509,
    pid: 3499,
    name: "\u592a\u8c37\u533a",
    code: "140703",
    level: "district",
  },
  {
    id: 3510,
    pid: 3499,
    name: "\u5e73\u9065\u53bf",
    code: "140728",
    level: "district",
  },
  {
    id: 3511,
    pid: 3427,
    name: "\u5415\u6881\u5e02",
    code: "141100",
    level: "city",
  },
  {
    id: 3512,
    pid: 3511,
    name: "\u4ea4\u53e3\u53bf",
    code: "141130",
    level: "district",
  },
  {
    id: 3513,
    pid: 3511,
    name: "\u65b9\u5c71\u53bf",
    code: "141128",
    level: "district",
  },
  {
    id: 3514,
    pid: 3511,
    name: "\u5c9a\u53bf",
    code: "141127",
    level: "district",
  },
  {
    id: 3515,
    pid: 3511,
    name: "\u4e34\u53bf",
    code: "141124",
    level: "district",
  },
  {
    id: 3516,
    pid: 3511,
    name: "\u79bb\u77f3\u533a",
    code: "141102",
    level: "district",
  },
  {
    id: 3517,
    pid: 3511,
    name: "\u67f3\u6797\u53bf",
    code: "141125",
    level: "district",
  },
  {
    id: 3518,
    pid: 3511,
    name: "\u4e2d\u9633\u53bf",
    code: "141129",
    level: "district",
  },
  {
    id: 3519,
    pid: 3511,
    name: "\u5174\u53bf",
    code: "141123",
    level: "district",
  },
  {
    id: 3520,
    pid: 3511,
    name: "\u77f3\u697c\u53bf",
    code: "141126",
    level: "district",
  },
  {
    id: 3521,
    pid: 3511,
    name: "\u4ea4\u57ce\u53bf",
    code: "141122",
    level: "district",
  },
  {
    id: 3522,
    pid: 3511,
    name: "\u6587\u6c34\u53bf",
    code: "141121",
    level: "district",
  },
  {
    id: 3523,
    pid: 3511,
    name: "\u6c7e\u9633\u5e02",
    code: "141182",
    level: "district",
  },
  {
    id: 3524,
    pid: 3511,
    name: "\u5b5d\u4e49\u5e02",
    code: "141181",
    level: "district",
  },
  {
    id: 3525,
    pid: 3427,
    name: "\u5927\u540c\u5e02",
    code: "140200",
    level: "city",
  },
  {
    id: 3526,
    pid: 3525,
    name: "\u6d51\u6e90\u53bf",
    code: "140225",
    level: "district",
  },
  {
    id: 3527,
    pid: 3525,
    name: "\u7075\u4e18\u53bf",
    code: "140224",
    level: "district",
  },
  {
    id: 3528,
    pid: 3525,
    name: "\u5e7f\u7075\u53bf",
    code: "140223",
    level: "district",
  },
  {
    id: 3529,
    pid: 3525,
    name: "\u9633\u9ad8\u53bf",
    code: "140221",
    level: "district",
  },
  {
    id: 3530,
    pid: 3525,
    name: "\u4e91\u5dde\u533a",
    code: "140215",
    level: "district",
  },
  {
    id: 3531,
    pid: 3525,
    name: "\u4e91\u5188\u533a",
    code: "140214",
    level: "district",
  },
  {
    id: 3532,
    pid: 3525,
    name: "\u5e73\u57ce\u533a",
    code: "140213",
    level: "district",
  },
  {
    id: 3533,
    pid: 3525,
    name: "\u5929\u9547\u53bf",
    code: "140222",
    level: "district",
  },
  {
    id: 3534,
    pid: 3525,
    name: "\u65b0\u8363\u533a",
    code: "140212",
    level: "district",
  },
  {
    id: 3535,
    pid: 3525,
    name: "\u5de6\u4e91\u53bf",
    code: "140226",
    level: "district",
  },
  {
    id: 3536,
    pid: 3427,
    name: "\u957f\u6cbb\u5e02",
    code: "140400",
    level: "city",
  },
  {
    id: 3537,
    pid: 3536,
    name: "\u6f5e\u57ce\u533a",
    code: "140406",
    level: "district",
  },
  {
    id: 3538,
    pid: 3536,
    name: "\u957f\u5b50\u53bf",
    code: "140428",
    level: "district",
  },
  {
    id: 3539,
    pid: 3536,
    name: "\u9ece\u57ce\u53bf",
    code: "140426",
    level: "district",
  },
  {
    id: 3540,
    pid: 3536,
    name: "\u4e0a\u515a\u533a",
    code: "140404",
    level: "district",
  },
  {
    id: 3541,
    pid: 3536,
    name: "\u5c6f\u7559\u533a",
    code: "140405",
    level: "district",
  },
  {
    id: 3542,
    pid: 3536,
    name: "\u6c81\u6e90\u53bf",
    code: "140431",
    level: "district",
  },
  {
    id: 3543,
    pid: 3536,
    name: "\u5e73\u987a\u53bf",
    code: "140425",
    level: "district",
  },
  {
    id: 3544,
    pid: 3536,
    name: "\u8944\u57a3\u53bf",
    code: "140423",
    level: "district",
  },
  {
    id: 3545,
    pid: 3536,
    name: "\u6f5e\u5dde\u533a",
    code: "140403",
    level: "district",
  },
  {
    id: 3546,
    pid: 3536,
    name: "\u6c81\u53bf",
    code: "140430",
    level: "district",
  },
  {
    id: 3547,
    pid: 3536,
    name: "\u6b66\u4e61\u53bf",
    code: "140429",
    level: "district",
  },
  {
    id: 3548,
    pid: 3536,
    name: "\u58f6\u5173\u53bf",
    code: "140427",
    level: "district",
  },
  {
    id: 3549,
    pid: 3427,
    name: "\u664b\u57ce\u5e02",
    code: "140500",
    level: "city",
  },
  {
    id: 3550,
    pid: 3549,
    name: "\u9ad8\u5e73\u5e02",
    code: "140581",
    level: "district",
  },
  {
    id: 3551,
    pid: 3549,
    name: "\u57ce\u533a",
    code: "140502",
    level: "district",
  },
  {
    id: 3552,
    pid: 3549,
    name: "\u6cfd\u5dde\u53bf",
    code: "140525",
    level: "district",
  },
  {
    id: 3553,
    pid: 3549,
    name: "\u6c81\u6c34\u53bf",
    code: "140521",
    level: "district",
  },
  {
    id: 3554,
    pid: 3549,
    name: "\u9633\u57ce\u53bf",
    code: "140522",
    level: "district",
  },
  {
    id: 3555,
    pid: 3549,
    name: "\u9675\u5ddd\u53bf",
    code: "140524",
    level: "district",
  },
  {
    id: 3556,
    pid: 0,
    name: "\u4e91\u5357\u7701",
    code: "530000",
    level: "province",
  },
  {
    id: 3557,
    pid: 3556,
    name: "\u662d\u901a\u5e02",
    code: "530600",
    level: "city",
  },
  {
    id: 3558,
    pid: 3557,
    name: "\u6c34\u5bcc\u5e02",
    code: "530681",
    level: "district",
  },
  {
    id: 3559,
    pid: 3557,
    name: "\u7ee5\u6c5f\u53bf",
    code: "530626",
    level: "district",
  },
  {
    id: 3560,
    pid: 3557,
    name: "\u5a01\u4fe1\u53bf",
    code: "530629",
    level: "district",
  },
  {
    id: 3561,
    pid: 3557,
    name: "\u6c38\u5584\u53bf",
    code: "530625",
    level: "district",
  },
  {
    id: 3562,
    pid: 3557,
    name: "\u5927\u5173\u53bf",
    code: "530624",
    level: "district",
  },
  {
    id: 3563,
    pid: 3557,
    name: "\u5de7\u5bb6\u53bf",
    code: "530622",
    level: "district",
  },
  {
    id: 3564,
    pid: 3557,
    name: "\u662d\u9633\u533a",
    code: "530602",
    level: "district",
  },
  {
    id: 3565,
    pid: 3557,
    name: "\u76d0\u6d25\u53bf",
    code: "530623",
    level: "district",
  },
  {
    id: 3566,
    pid: 3557,
    name: "\u9c81\u7538\u53bf",
    code: "530621",
    level: "district",
  },
  {
    id: 3567,
    pid: 3557,
    name: "\u5f5d\u826f\u53bf",
    code: "530628",
    level: "district",
  },
  {
    id: 3568,
    pid: 3557,
    name: "\u9547\u96c4\u53bf",
    code: "530627",
    level: "district",
  },
  {
    id: 3569,
    pid: 3556,
    name: "\u7ea2\u6cb3\u54c8\u5c3c\u65cf\u5f5d\u65cf\u81ea\u6cbb\u5dde",
    code: "532500",
    level: "city",
  },
  {
    id: 3570,
    pid: 3569,
    name: "\u6cf8\u897f\u53bf",
    code: "532527",
    level: "district",
  },
  {
    id: 3571,
    pid: 3569,
    name: "\u5f25\u52d2\u5e02",
    code: "532504",
    level: "district",
  },
  {
    id: 3572,
    pid: 3569,
    name: "\u5efa\u6c34\u53bf",
    code: "532524",
    level: "district",
  },
  {
    id: 3573,
    pid: 3569,
    name: "\u77f3\u5c4f\u53bf",
    code: "532525",
    level: "district",
  },
  {
    id: 3574,
    pid: 3569,
    name: "\u5f00\u8fdc\u5e02",
    code: "532502",
    level: "district",
  },
  {
    id: 3575,
    pid: 3569,
    name: "\u4e2a\u65e7\u5e02",
    code: "532501",
    level: "district",
  },
  {
    id: 3576,
    pid: 3569,
    name: "\u8499\u81ea\u5e02",
    code: "532503",
    level: "district",
  },
  {
    id: 3577,
    pid: 3569,
    name: "\u7ea2\u6cb3\u53bf",
    code: "532529",
    level: "district",
  },
  {
    id: 3578,
    pid: 3569,
    name: "\u5c4f\u8fb9\u82d7\u65cf\u81ea\u6cbb\u53bf",
    code: "532523",
    level: "district",
  },
  {
    id: 3579,
    pid: 3569,
    name: "\u7eff\u6625\u53bf",
    code: "532531",
    level: "district",
  },
  {
    id: 3580,
    pid: 3569,
    name: "\u5143\u9633\u53bf",
    code: "532528",
    level: "district",
  },
  {
    id: 3581,
    pid: 3569,
    name: "\u91d1\u5e73\u82d7\u65cf\u7476\u65cf\u50a3\u65cf\u81ea\u6cbb\u53bf",
    code: "532530",
    level: "district",
  },
  {
    id: 3582,
    pid: 3569,
    name: "\u6cb3\u53e3\u7476\u65cf\u81ea\u6cbb\u53bf",
    code: "532532",
    level: "district",
  },
  {
    id: 3583,
    pid: 3556,
    name: "\u66f2\u9756\u5e02",
    code: "530300",
    level: "city",
  },
  {
    id: 3584,
    pid: 3583,
    name: "\u4f1a\u6cfd\u53bf",
    code: "530326",
    level: "district",
  },
  {
    id: 3585,
    pid: 3583,
    name: "\u9a6c\u9f99\u533a",
    code: "530304",
    level: "district",
  },
  {
    id: 3586,
    pid: 3583,
    name: "\u7f57\u5e73\u53bf",
    code: "530324",
    level: "district",
  },
  {
    id: 3587,
    pid: 3583,
    name: "\u5e08\u5b97\u53bf",
    code: "530323",
    level: "district",
  },
  {
    id: 3588,
    pid: 3583,
    name: "\u9646\u826f\u53bf",
    code: "530322",
    level: "district",
  },
  {
    id: 3589,
    pid: 3583,
    name: "\u5ba3\u5a01\u5e02",
    code: "530381",
    level: "district",
  },
  {
    id: 3590,
    pid: 3583,
    name: "\u5bcc\u6e90\u53bf",
    code: "530325",
    level: "district",
  },
  {
    id: 3591,
    pid: 3583,
    name: "\u9e92\u9e9f\u533a",
    code: "530302",
    level: "district",
  },
  {
    id: 3592,
    pid: 3583,
    name: "\u6cbe\u76ca\u533a",
    code: "530303",
    level: "district",
  },
  {
    id: 3593,
    pid: 3556,
    name: "\u6012\u6c5f\u5088\u50f3\u65cf\u81ea\u6cbb\u5dde",
    code: "533300",
    level: "city",
  },
  {
    id: 3594,
    pid: 3593,
    name: "\u8d21\u5c71\u72ec\u9f99\u65cf\u6012\u65cf\u81ea\u6cbb\u53bf",
    code: "533324",
    level: "district",
  },
  {
    id: 3595,
    pid: 3593,
    name: "\u798f\u8d21\u53bf",
    code: "533323",
    level: "district",
  },
  {
    id: 3596,
    pid: 3593,
    name: "\u5170\u576a\u767d\u65cf\u666e\u7c73\u65cf\u81ea\u6cbb\u53bf",
    code: "533325",
    level: "district",
  },
  {
    id: 3597,
    pid: 3593,
    name: "\u6cf8\u6c34\u5e02",
    code: "533301",
    level: "district",
  },
  {
    id: 3598,
    pid: 3556,
    name: "\u7389\u6eaa\u5e02",
    code: "530400",
    level: "city",
  },
  {
    id: 3599,
    pid: 3598,
    name: "\u6613\u95e8\u53bf",
    code: "530425",
    level: "district",
  },
  {
    id: 3600,
    pid: 3598,
    name: "\u6f84\u6c5f\u5e02",
    code: "530481",
    level: "district",
  },
  {
    id: 3601,
    pid: 3598,
    name: "\u5ce8\u5c71\u5f5d\u65cf\u81ea\u6cbb\u53bf",
    code: "530426",
    level: "district",
  },
  {
    id: 3602,
    pid: 3598,
    name: "\u534e\u5b81\u53bf",
    code: "530424",
    level: "district",
  },
  {
    id: 3603,
    pid: 3598,
    name: "\u65b0\u5e73\u5f5d\u65cf\u50a3\u65cf\u81ea\u6cbb\u53bf",
    code: "530427",
    level: "district",
  },
  {
    id: 3604,
    pid: 3598,
    name: "\u6c5f\u5ddd\u533a",
    code: "530403",
    level: "district",
  },
  {
    id: 3605,
    pid: 3598,
    name: "\u7ea2\u5854\u533a",
    code: "530402",
    level: "district",
  },
  {
    id: 3606,
    pid: 3598,
    name: "\u901a\u6d77\u53bf",
    code: "530423",
    level: "district",
  },
  {
    id: 3607,
    pid: 3598,
    name:
      "\u5143\u6c5f\u54c8\u5c3c\u65cf\u5f5d\u65cf\u50a3\u65cf\u81ea\u6cbb\u53bf",
    code: "530428",
    level: "district",
  },
  {
    id: 3608,
    pid: 3556,
    name: "\u4fdd\u5c71\u5e02",
    code: "530500",
    level: "city",
  },
  {
    id: 3609,
    pid: 3608,
    name: "\u817e\u51b2\u5e02",
    code: "530581",
    level: "district",
  },
  {
    id: 3610,
    pid: 3608,
    name: "\u9686\u9633\u533a",
    code: "530502",
    level: "district",
  },
  {
    id: 3611,
    pid: 3608,
    name: "\u660c\u5b81\u53bf",
    code: "530524",
    level: "district",
  },
  {
    id: 3612,
    pid: 3608,
    name: "\u65bd\u7538\u53bf",
    code: "530521",
    level: "district",
  },
  {
    id: 3613,
    pid: 3608,
    name: "\u9f99\u9675\u53bf",
    code: "530523",
    level: "district",
  },
  {
    id: 3614,
    pid: 3556,
    name: "\u5927\u7406\u767d\u65cf\u81ea\u6cbb\u5dde",
    code: "532900",
    level: "city",
  },
  {
    id: 3615,
    pid: 3614,
    name: "\u5251\u5ddd\u53bf",
    code: "532931",
    level: "district",
  },
  {
    id: 3616,
    pid: 3614,
    name: "\u9e64\u5e86\u53bf",
    code: "532932",
    level: "district",
  },
  {
    id: 3617,
    pid: 3614,
    name: "\u6d31\u6e90\u53bf",
    code: "532930",
    level: "district",
  },
  {
    id: 3618,
    pid: 3614,
    name: "\u5bbe\u5ddd\u53bf",
    code: "532924",
    level: "district",
  },
  {
    id: 3619,
    pid: 3614,
    name: "\u4e91\u9f99\u53bf",
    code: "532929",
    level: "district",
  },
  {
    id: 3620,
    pid: 3614,
    name: "\u5927\u7406\u5e02",
    code: "532901",
    level: "district",
  },
  {
    id: 3621,
    pid: 3614,
    name: "\u7965\u4e91\u53bf",
    code: "532923",
    level: "district",
  },
  {
    id: 3622,
    pid: 3614,
    name: "\u6f3e\u6fde\u5f5d\u65cf\u81ea\u6cbb\u53bf",
    code: "532922",
    level: "district",
  },
  {
    id: 3623,
    pid: 3614,
    name: "\u6c38\u5e73\u53bf",
    code: "532928",
    level: "district",
  },
  {
    id: 3624,
    pid: 3614,
    name: "\u5dcd\u5c71\u5f5d\u65cf\u56de\u65cf\u81ea\u6cbb\u53bf",
    code: "532927",
    level: "district",
  },
  {
    id: 3625,
    pid: 3614,
    name: "\u5f25\u6e21\u53bf",
    code: "532925",
    level: "district",
  },
  {
    id: 3626,
    pid: 3614,
    name: "\u5357\u6da7\u5f5d\u65cf\u81ea\u6cbb\u53bf",
    code: "532926",
    level: "district",
  },
  {
    id: 3627,
    pid: 3556,
    name: "\u4e3d\u6c5f\u5e02",
    code: "530700",
    level: "city",
  },
  {
    id: 3628,
    pid: 3627,
    name: "\u7389\u9f99\u7eb3\u897f\u65cf\u81ea\u6cbb\u53bf",
    code: "530721",
    level: "district",
  },
  {
    id: 3629,
    pid: 3627,
    name: "\u53e4\u57ce\u533a",
    code: "530702",
    level: "district",
  },
  {
    id: 3630,
    pid: 3627,
    name: "\u5b81\u8497\u5f5d\u65cf\u81ea\u6cbb\u53bf",
    code: "530724",
    level: "district",
  },
  {
    id: 3631,
    pid: 3627,
    name: "\u6c38\u80dc\u53bf",
    code: "530722",
    level: "district",
  },
  {
    id: 3632,
    pid: 3627,
    name: "\u534e\u576a\u53bf",
    code: "530723",
    level: "district",
  },
  {
    id: 3633,
    pid: 3556,
    name: "\u8fea\u5e86\u85cf\u65cf\u81ea\u6cbb\u5dde",
    code: "533400",
    level: "city",
  },
  {
    id: 3634,
    pid: 3633,
    name: "\u5fb7\u94a6\u53bf",
    code: "533422",
    level: "district",
  },
  {
    id: 3635,
    pid: 3633,
    name: "\u9999\u683c\u91cc\u62c9\u5e02",
    code: "533401",
    level: "district",
  },
  {
    id: 3636,
    pid: 3633,
    name: "\u7ef4\u897f\u5088\u50f3\u65cf\u81ea\u6cbb\u53bf",
    code: "533423",
    level: "district",
  },
  {
    id: 3637,
    pid: 3556,
    name: "\u897f\u53cc\u7248\u7eb3\u50a3\u65cf\u81ea\u6cbb\u5dde",
    code: "532800",
    level: "city",
  },
  {
    id: 3638,
    pid: 3637,
    name: "\u666f\u6d2a\u5e02",
    code: "532801",
    level: "district",
  },
  {
    id: 3639,
    pid: 3637,
    name: "\u52d0\u6d77\u53bf",
    code: "532822",
    level: "district",
  },
  {
    id: 3640,
    pid: 3637,
    name: "\u52d0\u814a\u53bf",
    code: "532823",
    level: "district",
  },
  {
    id: 3641,
    pid: 3556,
    name: "\u6606\u660e\u5e02",
    code: "530100",
    level: "city",
  },
  {
    id: 3642,
    pid: 3641,
    name: "\u4e1c\u5ddd\u533a",
    code: "530113",
    level: "district",
  },
  {
    id: 3643,
    pid: 3641,
    name: "\u5bfb\u7538\u56de\u65cf\u5f5d\u65cf\u81ea\u6cbb\u53bf",
    code: "530129",
    level: "district",
  },
  {
    id: 3644,
    pid: 3641,
    name: "\u4e94\u534e\u533a",
    code: "530102",
    level: "district",
  },
  {
    id: 3645,
    pid: 3641,
    name: "\u897f\u5c71\u533a",
    code: "530112",
    level: "district",
  },
  {
    id: 3646,
    pid: 3641,
    name: "\u5b9c\u826f\u53bf",
    code: "530125",
    level: "district",
  },
  {
    id: 3647,
    pid: 3641,
    name: "\u77f3\u6797\u5f5d\u65cf\u81ea\u6cbb\u53bf",
    code: "530126",
    level: "district",
  },
  {
    id: 3648,
    pid: 3641,
    name: "\u5448\u8d21\u533a",
    code: "530114",
    level: "district",
  },
  {
    id: 3649,
    pid: 3641,
    name: "\u664b\u5b81\u533a",
    code: "530115",
    level: "district",
  },
  {
    id: 3650,
    pid: 3641,
    name: "\u5b89\u5b81\u5e02",
    code: "530181",
    level: "district",
  },
  {
    id: 3651,
    pid: 3641,
    name: "\u5bcc\u6c11\u53bf",
    code: "530124",
    level: "district",
  },
  {
    id: 3652,
    pid: 3641,
    name: "\u7984\u529d\u5f5d\u65cf\u82d7\u65cf\u81ea\u6cbb\u53bf",
    code: "530128",
    level: "district",
  },
  {
    id: 3653,
    pid: 3641,
    name: "\u5b98\u6e21\u533a",
    code: "530111",
    level: "district",
  },
  {
    id: 3654,
    pid: 3641,
    name: "\u76d8\u9f99\u533a",
    code: "530103",
    level: "district",
  },
  {
    id: 3655,
    pid: 3641,
    name: "\u5d69\u660e\u53bf",
    code: "530127",
    level: "district",
  },
  {
    id: 3656,
    pid: 3556,
    name: "\u666e\u6d31\u5e02",
    code: "530800",
    level: "city",
  },
  {
    id: 3657,
    pid: 3656,
    name: "\u666f\u4e1c\u5f5d\u65cf\u81ea\u6cbb\u53bf",
    code: "530823",
    level: "district",
  },
  {
    id: 3658,
    pid: 3656,
    name:
      "\u9547\u6c85\u5f5d\u65cf\u54c8\u5c3c\u65cf\u62c9\u795c\u65cf\u81ea\u6cbb\u53bf",
    code: "530825",
    level: "district",
  },
  {
    id: 3659,
    pid: 3656,
    name: "\u58a8\u6c5f\u54c8\u5c3c\u65cf\u81ea\u6cbb\u53bf",
    code: "530822",
    level: "district",
  },
  {
    id: 3660,
    pid: 3656,
    name: "\u666f\u8c37\u50a3\u65cf\u5f5d\u65cf\u81ea\u6cbb\u53bf",
    code: "530824",
    level: "district",
  },
  {
    id: 3661,
    pid: 3656,
    name: "\u5b81\u6d31\u54c8\u5c3c\u65cf\u5f5d\u65cf\u81ea\u6cbb\u53bf",
    code: "530821",
    level: "district",
  },
  {
    id: 3662,
    pid: 3656,
    name: "\u601d\u8305\u533a",
    code: "530802",
    level: "district",
  },
  {
    id: 3663,
    pid: 3656,
    name: "\u897f\u76df\u4f64\u65cf\u81ea\u6cbb\u53bf",
    code: "530829",
    level: "district",
  },
  {
    id: 3664,
    pid: 3656,
    name: "\u6f9c\u6ca7\u62c9\u795c\u65cf\u81ea\u6cbb\u53bf",
    code: "530828",
    level: "district",
  },
  {
    id: 3665,
    pid: 3656,
    name: "\u6c5f\u57ce\u54c8\u5c3c\u65cf\u5f5d\u65cf\u81ea\u6cbb\u53bf",
    code: "530826",
    level: "district",
  },
  {
    id: 3666,
    pid: 3656,
    name:
      "\u5b5f\u8fde\u50a3\u65cf\u62c9\u795c\u65cf\u4f64\u65cf\u81ea\u6cbb\u53bf",
    code: "530827",
    level: "district",
  },
  {
    id: 3667,
    pid: 3556,
    name: "\u6587\u5c71\u58ee\u65cf\u82d7\u65cf\u81ea\u6cbb\u5dde",
    code: "532600",
    level: "city",
  },
  {
    id: 3668,
    pid: 3667,
    name: "\u4e18\u5317\u53bf",
    code: "532626",
    level: "district",
  },
  {
    id: 3669,
    pid: 3667,
    name: "\u5e7f\u5357\u53bf",
    code: "532627",
    level: "district",
  },
  {
    id: 3670,
    pid: 3667,
    name: "\u781a\u5c71\u53bf",
    code: "532622",
    level: "district",
  },
  {
    id: 3671,
    pid: 3667,
    name: "\u897f\u7574\u53bf",
    code: "532623",
    level: "district",
  },
  {
    id: 3672,
    pid: 3667,
    name: "\u6587\u5c71\u5e02",
    code: "532601",
    level: "district",
  },
  {
    id: 3673,
    pid: 3667,
    name: "\u9ebb\u6817\u5761\u53bf",
    code: "532624",
    level: "district",
  },
  {
    id: 3674,
    pid: 3667,
    name: "\u9a6c\u5173\u53bf",
    code: "532625",
    level: "district",
  },
  {
    id: 3675,
    pid: 3667,
    name: "\u5bcc\u5b81\u53bf",
    code: "532628",
    level: "district",
  },
  {
    id: 3676,
    pid: 3556,
    name: "\u695a\u96c4\u5f5d\u65cf\u81ea\u6cbb\u5dde",
    code: "532300",
    level: "city",
  },
  {
    id: 3677,
    pid: 3676,
    name: "\u5927\u59da\u53bf",
    code: "532326",
    level: "district",
  },
  {
    id: 3678,
    pid: 3676,
    name: "\u5143\u8c0b\u53bf",
    code: "532328",
    level: "district",
  },
  {
    id: 3679,
    pid: 3676,
    name: "\u59da\u5b89\u53bf",
    code: "532325",
    level: "district",
  },
  {
    id: 3680,
    pid: 3676,
    name: "\u725f\u5b9a\u53bf",
    code: "532323",
    level: "district",
  },
  {
    id: 3681,
    pid: 3676,
    name: "\u5357\u534e\u53bf",
    code: "532324",
    level: "district",
  },
  {
    id: 3682,
    pid: 3676,
    name: "\u7984\u4e30\u53bf",
    code: "532331",
    level: "district",
  },
  {
    id: 3683,
    pid: 3676,
    name: "\u695a\u96c4\u5e02",
    code: "532301",
    level: "district",
  },
  {
    id: 3684,
    pid: 3676,
    name: "\u53cc\u67cf\u53bf",
    code: "532322",
    level: "district",
  },
  {
    id: 3685,
    pid: 3676,
    name: "\u6b66\u5b9a\u53bf",
    code: "532329",
    level: "district",
  },
  {
    id: 3686,
    pid: 3676,
    name: "\u6c38\u4ec1\u53bf",
    code: "532327",
    level: "district",
  },
  {
    id: 3687,
    pid: 3556,
    name: "\u4e34\u6ca7\u5e02",
    code: "530900",
    level: "city",
  },
  {
    id: 3688,
    pid: 3687,
    name: "\u51e4\u5e86\u53bf",
    code: "530921",
    level: "district",
  },
  {
    id: 3689,
    pid: 3687,
    name: "\u4e91\u53bf",
    code: "530922",
    level: "district",
  },
  {
    id: 3690,
    pid: 3687,
    name: "\u6c38\u5fb7\u53bf",
    code: "530923",
    level: "district",
  },
  {
    id: 3691,
    pid: 3687,
    name: "\u9547\u5eb7\u53bf",
    code: "530924",
    level: "district",
  },
  {
    id: 3692,
    pid: 3687,
    name: "\u4e34\u7fd4\u533a",
    code: "530902",
    level: "district",
  },
  {
    id: 3693,
    pid: 3687,
    name: "\u803f\u9a6c\u50a3\u65cf\u4f64\u65cf\u81ea\u6cbb\u53bf",
    code: "530926",
    level: "district",
  },
  {
    id: 3694,
    pid: 3687,
    name:
      "\u53cc\u6c5f\u62c9\u795c\u65cf\u4f64\u65cf\u5e03\u6717\u65cf\u50a3\u65cf\u81ea\u6cbb\u53bf",
    code: "530925",
    level: "district",
  },
  {
    id: 3695,
    pid: 3687,
    name: "\u6ca7\u6e90\u4f64\u65cf\u81ea\u6cbb\u53bf",
    code: "530927",
    level: "district",
  },
  {
    id: 3696,
    pid: 3556,
    name: "\u5fb7\u5b8f\u50a3\u65cf\u666f\u9887\u65cf\u81ea\u6cbb\u5dde",
    code: "533100",
    level: "city",
  },
  {
    id: 3697,
    pid: 3696,
    name: "\u76c8\u6c5f\u53bf",
    code: "533123",
    level: "district",
  },
  {
    id: 3698,
    pid: 3696,
    name: "\u6881\u6cb3\u53bf",
    code: "533122",
    level: "district",
  },
  {
    id: 3699,
    pid: 3696,
    name: "\u9647\u5ddd\u53bf",
    code: "533124",
    level: "district",
  },
  {
    id: 3700,
    pid: 3696,
    name: "\u8292\u5e02",
    code: "533103",
    level: "district",
  },
  {
    id: 3701,
    pid: 3696,
    name: "\u745e\u4e3d\u5e02",
    code: "533102",
    level: "district",
  },
  {
    id: 3702,
    pid: 0,
    name: "\u5317\u4eac\u5e02",
    code: "110000",
    level: "province",
  },
  {
    id: 3703,
    pid: 3702,
    name: "\u5317\u4eac\u57ce\u533a",
    code: "110100",
    level: "city",
  },
  {
    id: 3704,
    pid: 3703,
    name: "\u5ef6\u5e86\u533a",
    code: "110119",
    level: "district",
  },
  {
    id: 3705,
    pid: 3703,
    name: "\u6000\u67d4\u533a",
    code: "110116",
    level: "district",
  },
  {
    id: 3706,
    pid: 3703,
    name: "\u95e8\u5934\u6c9f\u533a",
    code: "110109",
    level: "district",
  },
  {
    id: 3707,
    pid: 3703,
    name: "\u987a\u4e49\u533a",
    code: "110113",
    level: "district",
  },
  {
    id: 3708,
    pid: 3703,
    name: "\u5e73\u8c37\u533a",
    code: "110117",
    level: "district",
  },
  {
    id: 3709,
    pid: 3703,
    name: "\u660c\u5e73\u533a",
    code: "110114",
    level: "district",
  },
  {
    id: 3710,
    pid: 3703,
    name: "\u4e1c\u57ce\u533a",
    code: "110101",
    level: "district",
  },
  {
    id: 3711,
    pid: 3703,
    name: "\u671d\u9633\u533a",
    code: "110105",
    level: "district",
  },
  {
    id: 3712,
    pid: 3703,
    name: "\u6d77\u6dc0\u533a",
    code: "110108",
    level: "district",
  },
  {
    id: 3713,
    pid: 3703,
    name: "\u77f3\u666f\u5c71\u533a",
    code: "110107",
    level: "district",
  },
  {
    id: 3714,
    pid: 3703,
    name: "\u623f\u5c71\u533a",
    code: "110111",
    level: "district",
  },
  {
    id: 3715,
    pid: 3703,
    name: "\u4e30\u53f0\u533a",
    code: "110106",
    level: "district",
  },
  {
    id: 3716,
    pid: 3703,
    name: "\u897f\u57ce\u533a",
    code: "110102",
    level: "district",
  },
  {
    id: 3717,
    pid: 3703,
    name: "\u5927\u5174\u533a",
    code: "110115",
    level: "district",
  },
  {
    id: 3718,
    pid: 3703,
    name: "\u901a\u5dde\u533a",
    code: "110112",
    level: "district",
  },
  {
    id: 3719,
    pid: 3703,
    name: "\u5bc6\u4e91\u533a",
    code: "110118",
    level: "district",
  },
];

module.exports = {
  addressDatas,
};
