import { createModel } from "@rematch/core";
import { GraphqlQuery } from "../helpers/request.helper";
import BaseModel from "../models/BaseModel";
import { GraphqlField, GraphqlRefField } from "../helpers/decorators";

export class ThemeVar {
  @GraphqlField()
  public key: string;

  @GraphqlField()
  public value: string;
}

export class Theme extends BaseModel {
  //主题模板，目前第一批在做的有三个
  @GraphqlField()
  public template: "Moonlight" | "ChildTime" | undefined;

  //主题变量
  @GraphqlRefField(ThemeVar)
  public data: ThemeVar[] = [];

  /**
   *
   */
  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}

export default createModel({
  state: {} as Theme,
  reducers: {
    setTheme(state, payload) {
      return { ...state, ...payload };
    },
  },
  effects: {
    async getThemeAsync(template: string) {
      const theme = await GraphqlQuery("getTheme", new Theme({ template }));
      this.setTheme(theme[0]);
    },
  },
});
