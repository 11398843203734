import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useReducer,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, Dispatch } from "../../../store/configureStore";
import * as style from "../index.scss";
import * as styles from "./index.scss";
import { useIntl, FormattedMessage } from "react-intl";
import { Row, Col, message, Tag, Divider, Rate, Card, Space } from "antd";
import Icon from "../../../components/icon";
import cartlogoImg from "../../../static/img/cartlogo.png";
import Modal from "../../../wrappers/modal";
import Button, { PrimaryButton } from "../../../wrappers/button";
import { Link } from "../../../wrappers/typography";
import Select from "../../../wrappers/select";

import Print from "react-to-print";

import { copyToClipboard } from "../../../helpers/DomUtil";

import { AgentRate } from "../../../models/Shop";
import { Product } from "../../../models/Product";
import { useTheme } from "styled-components";

const PrintCart = (props: {
  address: {
    recipient_name: string;
    recipient_email: string;
    recipient_company: string;
    recipient_telephone: string;
    address_details: string;
  };
  addressData: any;
  cartData: any;
  isDisabled: boolean;
  hidePriceInfo: boolean;
  hideAgentIncome: boolean;
  hideUserInfo: boolean;
  userInfo: any;
  agentRates: AgentRate[];
  isAgent: boolean;
}) => {
  const dispatch = useDispatch<Dispatch>();
  const { formatMessage: f } = useIntl();
  // const OperationBox = React.createRef<HTMLDivElement>();
  const OperationBox = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState("print");
  const [shareDay, setShareDay] = useState(1);
  const [shareUrl, setShareUrl] = useState(" ");
  const { user } = useSelector((state: RootState) => state.AccountPage);

  const {
    address,
    addressData = [],
    cartData,
    isDisabled,
    hidePriceInfo,
    hideUserInfo,
    userInfo,
    hideAgentIncome = true,
    agentRates,
    isAgent,
  } = props;

  const { bussinessPrimaryColor, linkColor } = useTheme();

  if (address.recipient_company === "" && addressData.length > 0) {
    address = {
      ...addressData[0],
      address_details:
        addressData[0].province +
        addressData[0].city +
        addressData[0].area +
        addressData[0].address_details,
    };
  }

  const getAgentIncome = (product: Product, shopId: number) => {
    let rate = agentRates.find(r => r.shop_id === Number(shopId));

    if (!rate) {
      rate = agentRates.find(r => r.shop_id === 0);
    }

    if (!rate) return "";

    return product.has_tag_price_range
      ? `¥${(
          (Number(product.tag_price_low) *
            rate.commission_rate *
            product.quantity) /
          100
        ).toFixed(2)} - ¥${(
          (Number(product.tag_price_high) *
            rate.commission_rate *
            product.quantity) /
          100
        ).toFixed(2)}`
      : `¥${(
          (Number(product.tag_price) *
            rate.commission_rate *
            product.quantity) /
          100
        ).toFixed(2)}`;
  };

  const getAgentShopIncome = (products: Product[], shopId: number) => {
    let rate = agentRates.find(r => r.shop_id === Number(shopId));

    if (!rate) {
      rate = agentRates.find(r => r.shop_id === 0);
    }

    if (!rate) return "";

    const accResult = products.reduce(
      (prev, product) => {
        const [low, high] = prev;

        const accLow =
          low +
          (Number(
            product.has_tag_price_range
              ? product.tag_price_low
              : product.tag_price,
          ) *
            rate.commission_rate *
            product.quantity) /
            100;

        const acchigh =
          high +
          (Number(
            product.has_tag_price_range
              ? product.tag_price_high
              : product.tag_price,
          ) *
            rate.commission_rate *
            product.quantity) /
            100;

        return [accLow, acchigh];
      },
      [0, 0],
    );

    const [priceLow, priceHigh] = accResult;

    return priceLow === priceHigh
      ? `¥${priceLow.toFixed(2)}`
      : `¥${priceLow.toFixed(2)} - ¥${priceHigh.toFixed(2)}`;
  };

  const handleGetShareUrl = () => {
    const shareData = cartData
      .filter(c => c.selected)
      .map(c => ({
        ...c,
        show_agent_income: Number(!hideAgentIncome),
        address: hideUserInfo ? [] : [address],
        share_customer_agent_code: user.agent_code,
        product: c.product
          .filter(n => n.selected && n.is_active === 1)
          .map(p => ({
            ...p,
            tag_price: hidePriceInfo ? 0 : p.tag_price,
            tag_price_high: hidePriceInfo ? 0 : p.tag_price_high,
            tag_price_low: hidePriceInfo ? 0 : p.tag_price_low,
            tag_price_value: hidePriceInfo ? "" : p.tag_price_value,
            agent_income: getAgentIncome(p, c.shop_id),
          })),
      }));

    const data = {
      expired_at: shareDay,
      share_data: JSON.stringify(shareData).replace(/(\")/g, "\\$1"),
      callback: res => {
        const datas = {
          url: res.data,
          callback: ress => {
            setShareUrl(ress[0].short_url);
          },
        };
        dispatch.ToBCart.getSmallUrl(datas);
      },
    };
    dispatch.ToBCart.getShareUrl(data);
  };

  return (
    <Space size={5}>
      <Button
        shape={"circle"}
        size={"small"}
        color={bussinessPrimaryColor}
        onClick={() => {
          setIsOpen(true);
          setType("print");
        }}
        disabled={isDisabled}
        icon={<Icon type="iconchuanzhen" />}
      ></Button>
      <span
        className={style["cart-box-header-span"]}
        onClick={() => {
          if (isDisabled) return message.error("请选择商品后重试");
          setIsOpen(true);
          setType("print");
        }}
      >
        {f({
          id: "PrintListing",
        })}
      </span>
      <Button
        shape="circle"
        size="small"
        color={bussinessPrimaryColor}
        onClick={() => {
          setIsOpen(true);
          setType("share");
        }}
        disabled={isDisabled}
        icon={<Icon type="icont3_erweima" />}
      ></Button>
      <span
        className={style["cart-box-header-span"]}
        onClick={() => {
          if (isDisabled) return message.error("请选择商品后重试");
          setIsOpen(true);
          setType("share");
        }}
      >
        {f({
          id: "ShareListing",
        })}
      </span>

      <Modal
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        width="80%"
        title={f({ id: type === "print" ? "PrintListing" : "ShareListing" })}
        color={bussinessPrimaryColor}
        footer={null}
      >
        <div ref={OperationBox} className={styles["Operation-box"]}>
          <img
            src={cartlogoImg}
            alt=""
            className={styles["Operation-box-img"]}
          />
          <Divider style={{ background: "#c6bdb9" }} />
          {type === "share" && (
            <>
              <h3>您正在访问一个企业采购清单</h3>
              <h3>
                {`来自: ${
                  userInfo.customer_name !== null &&
                  userInfo.customer_name !== ""
                    ? userInfo.customer_name
                    : "您的好友"
                }  账号: ${
                  userInfo.email !== "" ? userInfo.email : userInfo.phonenumber
                }`}
              </h3>
            </>
          )}

          {!hideUserInfo && (
            <div className={styles["Operation-box-contact"]}>
              <div>
                <Icon
                  type="icondizhi"
                  style={{ color: bussinessPrimaryColor }}
                />
              </div>
              <div className={styles["Operation-box-contact-content"]}>
                <p>{`${address.recipient_name}  ${address.recipient_telephone}`}</p>
                <p>{address.recipient_email}</p>
                <p>{address.recipient_company}</p>
                <p>{address.address_details}</p>
              </div>
            </div>
          )}

          <div className={styles["Operation-box-product"]}>
            {cartData
              ?.filter(c => c.selected)
              .map((item: any, index: number) => (
                <Card
                  style={{ marginTop: "15px" }}
                  title={
                    <Row gutter={10}>
                      <Col span={5} style={{ color: bussinessPrimaryColor }}>
                        <Icon
                          type="icondianpu2"
                          style={{
                            marginRight: "5px",
                          }}
                        ></Icon>
                        {item.shop_name}
                      </Col>
                      {/*<Col span={3}>{userInfo.customer_name}</Col>
                           <Col span={4}>
                            {userInfo.agent_code && (
                              <>
                                <FormattedMessage id="AgentCode" />：
                                <span className={styles["agent-box-code"]}>
                                  {userInfo.agent_code}
                                </span>
                              </>
                            )}
                          </Col> */}
                      <Col style={{ float: "right" }}>
                        {isAgent && !hideAgentIncome && (
                          <>
                            <FormattedMessage id="ExpectedIncome" />：
                            <span style={{ color: bussinessPrimaryColor }}>
                              {getAgentShopIncome(item.product, item.shop_id)}
                            </span>
                          </>
                        )}
                      </Col>
                    </Row>
                  }
                >
                  {item.product?.map((m: any, mIndex: number) => {
                    if (m.selected && m.is_active === 1) {
                      return (
                        <Row
                          align="middle"
                          style={{
                            borderBottom:
                              item.product.length - 1 === mIndex
                                ? ""
                                : "1px solid #bdbcbc",
                          }}
                        >
                          <Col span={5}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={m.product_picture}
                                style={{ width: "80%" }}
                              />
                            </div>
                          </Col>
                          <Col span={7} style={{ height: "170px" }}>
                            <p>
                              <span style={{ marginRight: 10, fontSize: 15 }}>
                                {m.product_name}
                              </span>
                              {m.customer_rating > 0 && (
                                <Rate
                                  value={(m.customer_rating * 5) / 100}
                                  disabled
                                />
                              )}
                            </p>
                            <p className={styles["table-MHS"]}>
                              {m.brand_name !== "" && (
                                <Tag color={bussinessPrimaryColor}>
                                  {m.brand_name}
                                </Tag>
                              )}
                              {m.series_name !== "" && (
                                <Tag color={bussinessPrimaryColor}>
                                  {m.series_name}
                                </Tag>
                              )}
                            </p>
                          </Col>

                          <Col span={4}>
                            {m.attribute &&
                              m.attribute.split(",").map((z: string) => {
                                return (
                                  <>
                                    <Tag
                                      color={bussinessPrimaryColor}
                                      style={{ marginBottom: "5px" }}
                                    >
                                      {z}
                                    </Tag>
                                    <br />
                                  </>
                                );
                              })}
                          </Col>
                          <Col span={4}>
                            <p>X{m.quantity}</p>
                          </Col>

                          <Col span={4}>
                            {!hidePriceInfo && (
                              <div>
                                {!Number(m.has_tag_price_range) ? (
                                  <>
                                    ¥
                                    {(
                                      parseFloat(m.tag_price) * m.quantity
                                    ).toFixed(2)}
                                  </>
                                ) : (
                                  <>{`¥${(
                                    m.quantity * parseFloat(m.tag_price_low)
                                  ).toFixed(2)} - ¥${(
                                    m.quantity * parseFloat(m.tag_price_high)
                                  ).toFixed(2)}`}</>
                                )}
                              </div>
                            )}
                            {isAgent && !hideAgentIncome && (
                              <>
                                <FormattedMessage id="ExpectedIncome" />：
                                <span style={{ color: bussinessPrimaryColor }}>
                                  {getAgentIncome(m, item.shop_id)}
                                </span>
                              </>
                            )}
                          </Col>
                        </Row>
                      );
                    }
                  })}
                </Card>
              ))}
          </div>
          <div className={styles["Operation-box-copyright"]}>
            <p>
              Copyright ©2018 - 2020 MegaHome LLC. All rights reserved.
              北京每家科技有限公司版权所有
            </p>
            <p>
              <Link href="http://beian.miit.gov.cn/">
                京ICP备18022364号
              </Link>
            </p>
          </div>
        </div>
        {type !== "print" && (
          <div className={styles["Operation-export"]}>
            <Space direction="vertical">
              <Space>
                默认语言：
                <Select
                  style={{ width: "200px", marginRight: "10px" }}
                  disabled={true}
                  defaultValue={"1"}
                >
                  <Select.Option value={"1"}>中文简体</Select.Option>
                </Select>
                有效时间：
                <Select
                  style={{ width: "200px" }}
                  defaultValue={"1"}
                  onChange={value => {
                    setShareDay(value);
                  }}
                >
                  <Select.Option value={"1"}>1天</Select.Option>
                  <Select.Option value={"2"}>2天</Select.Option>
                  <Select.Option value={"3"}>3天</Select.Option>
                  <Select.Option value={"5"}>5天</Select.Option>
                  <Select.Option value={"10"}>10天</Select.Option>
                </Select>
                <PrimaryButton
                  bgColor={bussinessPrimaryColor}
                  onClick={() => handleGetShareUrl()}
                >
                  生成链接
                </PrimaryButton>
              </Space>
              <Row gutter={20}>
                <Col
                  span={16}
                  style={{
                    background: "#eaeaea",
                    padding: "5px 10px",
                    minHeight: "35px",
                    display: "inline-block",
                  }}
                >
                  {shareUrl}
                </Col>
                <Col span={8}>
                  <PrimaryButton
                    bgColor={bussinessPrimaryColor}
                    onClick={() => {
                      copyToClipboard(shareUrl);
                      message.success("复制成功");
                    }}
                  >
                    复制地址
                  </PrimaryButton>
                </Col>
              </Row>
            </Space>
          </div>
        )}
        {type === "print" && (
          <div className={styles["Operation-box-button"]}>
            <Print
              trigger={() => {
                return (
                  <PrimaryButton bgColor={bussinessPrimaryColor}>
                    打印
                  </PrimaryButton>
                );
              }}
              content={() => OperationBox.current}
            />
          </div>
        )}
      </Modal>
    </Space>
  );
};

export default PrintCart;
