import * as React from "react";
import * as styles from "./index.scss";
import classNames from "classnames";
import ProductItem from "./components/ProductItem";
import ComputeModule from "./components/ComputeModule";

import { RootState, Dispatch } from "../../store/configureStore";
import { useIntl } from "react-intl";
import { Row, Col, Affix, Card, Space, List } from "antd";
import Icon from "../../components/icon";
import Checkbox from "../../wrappers/checkbox";
import Button from "../../wrappers/button";
import { Link } from "../../wrappers/typography";
import { CommonProps } from "../../typings/component";
import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";
import flatMap from "lodash/flatMap";
import last from "lodash/last";
import { Product } from "../../models/Product";
import { useTheme } from "styled-components";
import DeliveryScope from "../../models/Delivery";
import { useUpdateEffect, useMount } from "ahooks";
import * as queryString from "query-string";

import replaceState from "../../helpers/reolaceState";

const ShoppingCartContent: React.FC<CommonProps> = ({ isMobile }) => {
  const { formatMessage: f } = useIntl();
  const { primaryColor, template } = useTheme();
  // const { host_id } = useSelector((state: RootState) => state.URLSearch);
  // const { isPrivate } = useSelector((state: RootState) => state.CommonData);
  const dispatch = useDispatch<Dispatch>();

  const { cart = [], quoteID, qrCodeUrl, selectedAddress } = useSelector(
    (state: RootState) => state.Cart,
  );
  const {
    User,
    Collections: collectIds = [],
    AgentRate: agentRates = [],
    AddressPage: { address = [] },
  } = useSelector((state: RootState) => state);

  const [selectedProductList, setSelectedProductList] = useState<Product[]>([]);

  useMount(() => {
    // 判断cart对象是否存在
    if (isEmpty(cart)) {
      // 如果不存在那么进行初始化请求
      dispatch.Cart.initCartDataAsync();
    }

    dispatch.AddressPage.getAddressDataAsync();
    dispatch.Collections.getCollectionAsync();
  });

  useEffect(() => {
    if (cart && cart.length > 0 && User.isAgent) {
      const idAry = cart.map(m => m.shop_id);
      dispatch.AgentRate.getAgentRateAsync({ shopIds: idAry.join("#") });
    }
  }, [cart]);

  const handleProductCheck = (item: Product, shop_id: number) => {
    if (
      selectedProductList.some(
        n =>
          n.shop_product_id === item.shop_product_id &&
          n.provider_id === item.provider_id &&
          n.store_cart_id === item.store_cart_id,
      )
    ) {
      const data = selectedProductList.filter(
        m =>
          !(
            m.shop_product_id === item.shop_product_id &&
            m.provider_id === item.provider_id &&
            m.store_cart_id === item.store_cart_id
          ),
      );
      setSelectedProductList([...data]);
    } else {
      setSelectedProductList([...selectedProductList, { ...item, shop_id }]);
    }
  };
  const getIsChecked = (product: Product) => {
    return selectedProductList.some(
      item =>
        item.shop_product_id === product.shop_product_id &&
        item.provider_id === product.provider_id &&
        item.store_cart_id === product.store_cart_id,
    );
  };

  /**
   * 计算 cart list 可以被选中的总数
   */
  const countCartListLength = () => {
    return flatMap(cart, c => c.product).filter(item => item.is_active === 1)
      .length;
  };

  /**
   * 获取是否支持配送
   */
  const getDeliveryAvailable = useCallback(
    (deliveryInfo: DeliveryScope[]) => {
      if (
        !selectedAddress.district_adcode ||
        !deliveryInfo ||
        deliveryInfo.length === 0
      ) {
        return false;
      }

      const province = selectedAddress.district_adcode.substr(0, 2),
        city = selectedAddress.district_adcode.substr(0, 4),
        district = selectedAddress.district_adcode;

      const { delivery_type } = deliveryInfo[0];

      if (delivery_type === 1) {
        return deliveryInfo.some(
          d =>
            d.district_level === "country" ||
            (d.district_level === "province" &&
              d.district_adcode.startsWith(province)) ||
            (d.district_level === "city" &&
              d.district_adcode.startsWith(city)) ||
            (d.district_level === "district" &&
              d.district_adcode.startsWith(district)),
        );
      }

      if (delivery_type === 2) {
        return deliveryInfo.every(
          d =>
            (d.district_level === "country" && false) ||
            (d.district_level === "province" &&
              !d.district_adcode.startsWith(province)) ||
            (d.district_level === "city" &&
              !d.district_adcode.startsWith(city)) ||
            (d.district_level === "district" &&
              !d.district_adcode.startsWith(district)),
        );
      }

      return false;
    },
    [selectedAddress],
  );

  useUpdateEffect(() => {
    setSelectedProductList(pl =>
      pl.filter(item => getDeliveryAvailable(item.delivery_info)),
    );
  }, [selectedAddress]);

  // 实现select all
  const handleSelectAll = () => {
    const nowSelectNumber = countCartListLength();

    // 判断一下selectedProductList长度为和现有可选商品长度是否相等，是就清空选中，否就选中所有。
    if (selectedProductList.length === nowSelectNumber) {
      setSelectedProductList([]);
    } else {
      const selectedData = flatMap(cart, c =>
        c.product.map(p => ({ ...p, shop_id: c.shop_id })),
      ).filter(
        item =>
          item.is_active === 1 && getDeliveryAvailable(item.delivery_info),
      );

      setSelectedProductList(selectedData);
    }
  };

  // 批量或者单个删除
  const handeleDelCartItem = (cartId: number, type: boolean) => {
    // 删除商品后需要将state中的checkedList更新
    if (type) {
      dispatch.Cart.delCart(cartId);

      setSelectedProductList(list =>
        list.filter(v => v.store_cart_id !== cartId),
      );
    } else {
      const ids = selectedProductList.map(item => item.store_cart_id);

      dispatch.Cart.delCart(ids.join("#"));

      setSelectedProductList([]);
    }
  };

  /**
   * 切换商品数量,修改selectedProductList中的数量值
   */

  const handleChangeStateQuantity = (n: number, id: number) => {
    const item = selectedProductList.find(p => p.shop_product_id === id);
    if (item) {
      item.quantity = n;
    }
  };

  // 修改订单数量函数
  const handleChangeQuantity = (
    indexs: number,
    index: number,
    n: number,
    id: number,
    product_moq: number,
    store_cart_id: number,
  ) => {
    dispatch.Cart.handleChangeOrderQuantity({
      quantity: n,
      store_cart_id: store_cart_id,
      callback: (res: any) => {
        if (res) {
          dispatch.Cart.changeQuantity({ indexs, index, number: n });

          handleChangeStateQuantity(n, id);
        }
      },
    });
  };

  // 修改订单数量函数
  const changeInputNumber = (
    indexs: number,
    index: number,
    n: number,
    id: number,
  ) => {
    dispatch.Cart.changeQuantity({ indexs, index, number: n });

    handleChangeStateQuantity(n, id);
  };

  // blur调取切换数量接口修改订单数量函数
  const handleBlurChangeQuantity = (
    indexs: number,
    index: number,
    n: number,
    id: number,
    product_moq: number,
    store_cart_id: number,
  ) => {
    if (n < product_moq) {
      // && product_moq !== 0
      dispatch.Cart.changeQuantity({
        indexs,
        index,
        number: product_moq > 0 ? product_moq : 1,
      });
      handleChangeStateQuantity(product_moq > 0 ? product_moq : 1, id);
    } else {
      dispatch.Cart.handleChangeOrderQuantity({
        quantity: n,
        store_cart_id: store_cart_id,
        callback: (res: any) => {
          if (res) {
            dispatch.Cart.changeQuantity({ indexs, index, number: n });
            handleChangeStateQuantity(n, id);
          } else {
            handleChangeStateQuantity(product_moq > 0 ? product_moq : 1, id);
            dispatch.Cart.changeQuantity({
              indexs,
              index,
              number: product_moq > 0 ? product_moq : 1,
            });
            dispatch.Cart.handleChangeOrderQuantity({
              quantity: product_moq > 0 ? product_moq : 1,
              store_cart_id: store_cart_id,
            });
          }
        },
      });
    }
  };

  /**
   * 获取单个商品收藏状态
   */

  const handleLikeGet = (shop_product_id: number) => {
    return collectIds.includes(shop_product_id);
  };

  const totalPrice = selectedProductList.reduce((curr, item) => {
    let tagPrice = 0;

    if (item.has_tag_price_range === 0) {
      tagPrice = Boolean(item.activity_type)
        ? Number(item.activity_price) * item.quantity
        : item.tag_price * item.quantity;
    } else {
      const range = item.product_price.filter(
        m =>
          Number(m.quantity_low) < item.quantity &&
          Number(m.quantity_high) > item.quantity,
      );

      if (range.length === 0) {
        tagPrice = item.quantity * Number(last(item.product_price)!.price);
      } else {
        tagPrice = item.quantity * Number(range[0].price);
      }
    }

    return (Number(curr) + tagPrice).toFixed(2);
  }, "0");

  const totalQuantity = selectedProductList.reduce(
    (prev, curr) => prev + curr.quantity,
    0,
  );

  const selectedIds = selectedProductList
    .map(item => item.store_cart_id)
    .join("#");

  /**
   * 获取单个商品的价格
   */
  const handleOnePriceGet = (data: Product) => {
    if (data.has_tag_price_range === 0) {
      return data.tag_price.toFixed(2);
    } else {
      if (!data.product_price || data.product_price.length === 0) return "0.0";
      const range = data.product_price.find(
        item =>
          item.quantity_low < data.quantity &&
          item.quantity_high > data.quantity,
      );

      if (!range) {
        return last(data.product_price)!.price;
      }

      return Number(range.price).toFixed(2);
    }
  };

  const platformAgent = agentRates.find(r => r.shop_id === 0);

  const totalIncome = selectedProductList.reduce((curr, next) => {
    const agentRate =
      agentRates.find(r => r.shop_id === Number(next.shop_id)) || platformAgent;

    if (!agentRate) return curr;
    console.log(next, agentRate, "next");

    let result;

    if (
      Boolean(next.activity_type) &&
      agentRate.agent_type === 2 &&
      Boolean(next.has_commission_flag)
    ) {
      result =
        Number(curr) +
        (Number(next.activity_price) *
          agentRate.commission_rate *
          next.quantity) /
          100;
    } else if (!Boolean(next.activity_type)) {
      result =
        Number(curr) +
        (Number(next.tag_price) * agentRate.commission_rate * next.quantity) /
          100;
    } else {
      result = Number(curr) + 0;
    }

    return result.toFixed(2);
  }, "0");

  return (
    <div className={styles["cart-box"]}>
      <Row gutter={10} style={{ width: "100%", marginTop: "10px" }}>
        <Col span={16}>
          {cart.length > 0 && (
            <>
              <Row gutter={10}>
                <Col span={24}>
                  <Row justify={"space-between"} gutter={5}>
                    <Col>
                      <Space>
                        <label
                          className={classNames(
                            styles["cart-ls-title-checkBox"],
                            {
                              [styles[`cart-m-ls-title-checkBox`]]: isMobile,
                            },
                          )}
                        >
                          <Checkbox
                            shape="circle"
                            borderWidth="2px"
                            checked={
                              selectedProductList.length ===
                              countCartListLength()
                            }
                            onChange={handleSelectAll}
                          />
                          {f({
                            id: "SelectAll",
                          })}
                        </label>
                        <Button
                          shape="circle"
                          size="small"
                          color={primaryColor}
                          onClick={() => handeleDelCartItem(0, false)}
                          disabled={selectedProductList.length === 0}
                          icon={<Icon type="icondelete" />}
                        />
                      </Space>
                    </Col>
                    <Col>
                      {/* <PrimaryButton
                                shape={"circle"}
                                size={"small"}
                                style={{
                                  border: "1px solid" + primaryColor,
                                  background: "white",
                                  color: primaryColor,
                                  boxSizing: "border-box",
                                }}
                                onClick={() =>
                                  this.setState({ confirmModel: true })
                                }
                                disabled={
                                  this.state.prductCheckedList.length === 0 ||
                                  this.state.prductCheckedList.length === 0
                                }
                                icon={<Icon type="icont3_erweima" />}
                              ></PrimaryButton> */}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <List
                dataSource={cart}
                split={false}
                grid={{ gutter: 15 }}
                style={{
                  backgroundColor: "#fff",
                  padding: "10px",
                  borderRadius: "5px",
                }}
                renderItem={(items, indexs) => (
                  <List.Item>
                    <Card
                      style={{ borderColor: "#bdbcbc" }}
                      headStyle={{ borderBottomColor: "#bdbcbc" }}
                      bodyStyle={{ padding: "0 10px 20px" }}
                      title={
                        <Row gutter={10}>
                          <Col span={12} style={{ color: primaryColor }}>
                            <Link
                              target="_blank"
                              href={items.shop_url}
                              underline
                              color={primaryColor}
                              onClick={e => {
                                e.preventDefault();
                                replaceState.linkTo(items.shop_url, "_target");
                              }}
                            >
                              <Icon
                                type="icondianpu2"
                                style={{
                                  marginRight: "5px",
                                }}
                              ></Icon>
                              {items.shop_name}
                            </Link>
                          </Col>
                        </Row>
                      }
                    >
                      {items.product.map((item: any, index: number) => (
                        <ProductItem
                          item={item}
                          handleDeliveryAvailable={() =>
                            getDeliveryAvailable(item.delivery_info)
                          }
                          agentRates={agentRates}
                          shopId={items.shop_id}
                          symbol={item.symbol}
                          isMobile={isMobile}
                          img={item.product_picture}
                          checked={getIsChecked(item)}
                          number={item.quantity}
                          name={item.product_name}
                          key={item.store_cart_id}
                          product_option={item.product_option}
                          SKU={item.sku_sn}
                          agentCode={item.agent_code}
                          userLike={handleLikeGet(item.shop_product_id)}
                          url={item.url}
                          isActive={item.is_active}
                          attribute={item.attribute}
                          originalPrice={item.original_price}
                          originalPriceValue={item.original_price_value}
                          tagPrice={handleOnePriceGet(item)}
                          customerRating={item.customer_rating}
                          brandName={item.brand_name}
                          seriesName={item.series_name}
                          productMoq={item.product_moq}
                          logisticsDeliveryText={item.logistics_delivery_text}
                          onCheck={() =>
                            handleProductCheck(item, items.shop_id)
                          }
                          manufacturer={item.shop_name}
                          onChangeNumber={(n: number) =>
                            handleChangeQuantity(
                              indexs,
                              index,
                              n,
                              item.shop_product_id,
                              item.product_moq,
                              item.store_cart_id,
                            )
                          }
                          onBlurChange={(n: number) => {
                            handleBlurChangeQuantity(
                              indexs,
                              index,
                              n,
                              item.shop_product_id,
                              item.product_moq,
                              item.store_cart_id,
                            );
                          }}
                          onChangeInput={(n: number) => {
                            changeInputNumber(
                              indexs,
                              index,
                              n,
                              item.shop_product_id,
                            );
                          }}
                          onChangeUserLike={type =>
                            dispatch.Collections.submitCollectAsync({
                              action: type ? "cancel" : "like",
                              object: "product",
                              data_id: item.shop_product_id,
                            })
                          }
                          delCartItem={() =>
                            handeleDelCartItem(item.store_cart_id, true)
                          }
                          shopUrl={item.shop_url}
                          template={template}
                          index={index}
                          proLenght={items.product.length}
                        />
                      ))}
                    </Card>
                  </List.Item>
                )}
              />
            </>
          )}

          {!cart ||
            (cart.length === 0 && (
              <>
                <div className={classNames(styles["cart-no-data"])}>
                  <Icon type="iconkonggouwuche"></Icon>
                  <p>
                    {f({
                      id: "OopsExclamationMarkYouShoppingCartIsEmpty",
                    })}
                  </p>
                </div>
              </>
            ))}
        </Col>
        <Col span={8}>
          <Affix offsetTop={85}>
            <div
              style={{
                padding: "20px",
                backgroundColor: "#EAEAEA",
                borderRadius: 5,
              }}
            >
              <ComputeModule
                totalIncome={Number(totalIncome)}
                isMobile={isMobile}
                selected={selectedProductList.length}
                onAddOrder={payload => dispatch.Cart.handleAddOrder(payload)}
                onCloseModal={() => dispatch.Cart.changeQuoteId(0)}
                selectedIds={selectedIds}
                address={address}
                pirce={totalPrice}
                quantity={totalQuantity}
                quoteID={quoteID}
                qrCodeUrl={qrCodeUrl}
                getQrCode={() => dispatch.Cart.RESQUEST_QRCODE(quoteID)}
                getZfbUrl={payload => dispatch.Cart.getZfbUrl(payload)}
                getPayResult={payload => dispatch.Cart.getPayResult(payload)}
              />
            </div>
          </Affix>
        </Col>
      </Row>
    </div>
  );
};

export default ShoppingCartContent;
