import { createModel } from "@rematch/core";

interface Params {
  search: string;
  [n: string]: string;
}

export default createModel({
  state: {} as Params,
  reducers: {
    CHANGE_PARAMS(state, payload) {
      return { ...state, ...payload };
    },
  },
});
