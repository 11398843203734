import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../../store/configureStore";
import Icon from "../../../components/icon";
import {
  Input,
  Space,
  Row,
  Col,
  Tag,
  List,
  Button,
  message as Messages,
} from "antd";
import Checkbox from "../../../wrappers/checkbox";
import Modal from "../../../wrappers/modal";
import AutoImage from "../../../components/image";
import Print from "react-to-print";
import * as styles from "../style.scss";
import { AnyArray } from "immer/dist/internal";
import { Product } from "../../../models/Product";

const ProductListModal = (props: {
  productList: Product[];
  isManagementEnd: boolean;
}) => {
  const dispatch = useDispatch<Dispatch>();
  const { productList = [], isManagementEnd } = props;
  const {
    User: { agentCode },
  } = useSelector((state: RootState) => state);
  const { root_id } = useSelector((state: RootState) => state.URLSearch);
  const { gaeaDomain } = useSelector((state: RootState) => state.DesignIdea);

  const [isOpen, setIsOpen] = useState(false);
  const [productData, setProductData] = useState<AnyArray>([]);
  const [showPrice, setShowPrice] = useState(false);
  const [isCheckedAll, setCheckedAll] = useState(true);

  const primaryColor = "#C21D44";
  const modalDOM = React.createRef<HTMLDivElement>();

  const handleShowModal = () => {
    // if (productList.length === 0) return Messages.error("请添加商品后重试！");
    const ids = productList.map(p => p.shop_product_id);

    const data = {
      shop_product_id_list: ids.join(","),
      root_id,
      callback: (res: any) => {
        setIsOpen(true);
        res.forEach((m: any) => {
          m.number = 1;
          m.isChecked = true;
        });
        setProductData(res);
      },
    };
    dispatch.DesignIdea.getProductDetailsAsync(data);
  };

  const handleChangeNumber = (id: number, num: number) => {
    const newData = productData.map((m: any) => {
      if (m.shop_product_id === id) {
        m.number = num;
      }
      return m;
    });
    setProductData(newData);
  };

  const handleCheck = (id: number, type: boolean) => {
    const newData = productData.map((m: any) => {
      if (m.shop_product_id === id) {
        m.isChecked = type;
      }
      return m;
    });

    const isCheck = productData.some(m => m.isChecked === false);
    setCheckedAll(!isCheck);

    setProductData(newData);
  };
  const handleCheckedAll = (e: any) => {
    const type = e.target.checked;
    const newData = productData.map((m: any) => {
      m.isChecked = type;
      return m;
    });
    setProductData(newData);
    setCheckedAll(type);
  };

  const handleGetPriceTotal = () => {
    if (!productData) return;
    const checkedData = productData.filter((m: any) => m.isChecked);
    if (checkedData.length === 0) {
      return `¥0.00`;
    }
    let priceLow = 0,
      priceHigh = 0,
      price = 0;
    const isTagPrice = checkedData.some(m => m.has_tag_price_range === 1);

    if (isTagPrice) {
      checkedData.forEach((m: any) => {
        if (m.has_tag_price_range === 1) {
          priceLow = priceLow + m.number * Number(m.tag_price_low);
          priceHigh = priceHigh + m.number * Number(m.tag_price_high);
        } else {
          priceLow = priceLow + m.number * Number(m.tag_price);
          priceHigh = priceHigh + m.number * Number(m.tag_price);
        }
      });
      return `¥${priceLow.toFixed(2)} - ¥${priceHigh.toFixed(2)}`;
    } else {
      checkedData.forEach((m: any) => {
        price = price + m.number * Number(m.tag_price);
      });
      return `¥${price.toFixed(2)}`;
    }
  };

  const priceTotal = productList.reduce(
    (prev, next) => {
      const [priceLow, priceHigh] = prev;
      return [
        priceLow +
          Number(
            next.has_tag_price_range
              ? next.tag_price_low
                ? next.tag_price_low
                : 0
              : next.tag_price
              ? next.tag_price
              : 0,
          ),
        priceHigh +
          Number(
            next.has_tag_price_range
              ? next.tag_price_high
                ? next.tag_price_high
                : 0
              : next.tag_price
              ? next.tag_price
              : 0,
          ),
      ];
    },
    [0, 0],
  );

  const exportPdf = async () => {
    if (productData.filter((m: any) => m.isChecked).length === 0) {
      return Messages.error("请先勾选商品");
    }

    const html2canvas = (await import("html2canvas")).default;
    const jsPDF = (await import("jspdf")).default;

    const element = document.getElementById("export"); // 这个dom元素是要导出pdf的div容器
    const w = element?.offsetWidth || 1200; // 获得该容器的宽
    const h = element?.offsetWidth || 800; // 获得该容器的高
    const offsetTop = element?.offsetTop || 20; // 获得该容器到文档顶部的距离
    const offsetLeft = element?.offsetLeft || 20; // 获得该容器到文档最左的距离
    const canvas = document.createElement("canvas");
    let abs = 0;
    // tslint:disable-next-line:variable-name
    const win_i = document.body.clientWidth; // 获得当前可视窗口的宽度（不包含滚动条）
    // tslint:disable-next-line:variable-name
    let win_o;
    if (typeof window !== "undefined") {
      win_o = window.innerWidth;
    } else {
      win_o = document.documentElement.clientHeight; // 获得当前窗口的宽度（包含滚动条）
    }
    if (win_o > win_i) {
      abs = (win_o - win_i) / 2; // 获得滚动条长度的一半
    }
    canvas.width = w * 2; // 将画布宽&&高放大两倍
    canvas.height = h * 2;
    const context = canvas.getContext("2d");
    context?.scale(2, 2);
    context?.translate(-offsetLeft - abs, -offsetTop);
    // 这里默认横向没有滚动条的情况，因为offset.left(),有无滚动条的时候存在差值，因此
    // translate的时候，要把这个差值去掉
    html2canvas(element, {
      allowTaint: true,
      useCORS: true,
      scale: 2, // 提升画面质量，但是会增加文件大小
      // tslint:disable-next-line:only-arrow-functions
    }).then(function (canvas) {
      const contentWidth = canvas.width;
      const contentHeight = canvas.height;
      // 一页pdf显示html页面生成的canvas高度;
      const pageHeight = (contentWidth / 592.28) * 841.89;
      // 未生成pdf的html页面高度
      let leftHeight = contentHeight;
      // 页面偏移
      let position = 0;
      // a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
      const imgWidth = 595.28;
      const imgHeight = (592.28 / contentWidth) * contentHeight;

      const pageData = canvas.toDataURL("image/jpeg", 1.0);

      const pdf = new jsPDF("", "pt", "a4");

      // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
      // 当内容未超过pdf一页显示的范围，无需分页
      if (leftHeight < pageHeight) {
        pdf.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
      } else {
        // 分页
        while (leftHeight > 0) {
          pdf.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
          leftHeight -= pageHeight;
          position -= 841.89;
          // 避免添加空白页
          if (leftHeight > 0) {
            pdf.addPage();
          }
        }
      }
      pdf.save("商品清单.pdf");
    });
  };

  const ExportExcel = () => {
    const productDatas = productData.filter((m: any) => m.isChecked);

    if (productDatas.length === 0) {
      return Messages.error("请先勾选商品");
    }

    if (showPrice) {
      productDatas.forEach(m => {
        m.product_price = "";
        m.product_amount = "";
      });
    } else {
      productDatas.forEach(m => {
        m.product_price =
          m.has_tag_price_range === 1
            ? "¥" + m.tag_price_low + " - ¥" + m.tag_price_high
            : m.tag_price;
        m.product_amount =
          m.has_tag_price_range === 1
            ? "¥" +
              m.tag_price_low * m.number +
              " - ¥" +
              m.tag_price_high * m.number
            : m.tag_price * m.number;
      });
    }

    if (isManagementEnd) {
      window.parent.postMessage(
        { action: "exportProducts", exportData: productDatas },
        gaeaDomain,
      );
    } else {
      let temp = document.createElement("form");
      temp.action = "/design-idea/export/";
      temp.method = "post";
      temp.target = "_blank";
      temp.enctype = "application/x-www-form-urlencoded";

      temp.style.display = "none";

      const uploadData = {
        export_data: JSON.stringify(productDatas),
        _token: document.getElementsByTagName("meta")["csrf-token"].content,
      };
      for (var x in uploadData) {
        var opt = document.createElement("textarea");
        opt.name = x;
        opt.value = uploadData[x];
        temp.appendChild(opt);
      }

      document.body.appendChild(temp);
      temp.submit();
    }
  };
  return (
    <>
      <span
        style={{
          cursor: "pointer",
        }}
        onClick={() => handleShowModal()}
      >
        清单总价：
        {priceTotal[1] > priceTotal[0]
          ? `¥${priceTotal[0].toFixed(2)} - ¥${priceTotal[1].toFixed(2)}`
          : `¥${priceTotal[0].toFixed(2)}`}
      </span>
      <Modal
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        width={1280}
        getContainer=".ant-layout"
        style={{ top: 20 }}
        footer={null}
      >
        <div
          style={{
            position: "absolute",
            left: "-3000px",
            width: "100%",
          }}
          id="export"
        >
          <div
            className={styles["ProductListModal-header"]}
            style={{
              position: "static",
            }}
          >
            <Row style={{ width: "90%" }}>
              <Col span={12} style={{ textAlign: "center" }}>
                商品信息
              </Col>
              <Col span={2} style={{ textAlign: "right" }}>
                参数
              </Col>
              <Col span={5} style={{ textAlign: "center" }}>
                数量
              </Col>
              {!showPrice && (
                <Col span={5} style={{ textAlign: "center" }}>
                  价格
                </Col>
              )}
            </Row>
            <Icon
              type="icont1_close"
              onClick={() => setIsOpen(false)}
              style={{
                color: primaryColor,
                position: "absolute",
                right: 20,
                top: 20,
              }}
            />
          </div>
          <div
            className={styles["ProductListModal-content"]}
            style={{
              maxHeight: "none",
              overflow: "visible",
              padding: "20px 5%",
            }}
          >
            <List
              dataSource={productData.filter((m: any) => m.isChecked)}
              renderItem={(item: any) => (
                <List.Item
                  className={styles["ProductListModal-content-list-item"]}
                >
                  {agentCode && agentCode !== "" && (
                    <span
                      style={{
                        position: "absolute",
                        right: 10,
                        top: 10,
                      }}
                    >
                      代理码：{agentCode}
                    </span>
                  )}
                  <Col
                    span={1}
                    className={styles["ProductListModal-visible"]}
                  ></Col>
                  <Col span={3} style={{ padding: 15 }}>
                    <img
                      src={item.product_picture + "?" + new Date().getTime()}
                      crossOrigin="anonymous"
                      alt=""
                      style={{
                        width: "80%",
                        height: "80%",
                      }}
                    />
                  </Col>
                  <Col span={5} style={{ height: 170 }}>
                    <p style={{ margin: 0, fontSize: "1.4em" }}>
                      {item.product_name}
                    </p>
                    <p style={{ marginTop: "5px" }}>
                      {item.brandName && item.brandName !== "" && (
                        <Tag color={primaryColor}>{item.brandName}</Tag>
                      )}
                      {item.seriesName && item.seriesName !== "" && (
                        <Tag color={primaryColor}>{item.seriesName}</Tag>
                      )}
                    </p>
                    <p style={{ marginTop: 20 }}>
                      {item.product_option &&
                        item.product_option !== "" &&
                        item.product_option.split(",").map((m: string) => {
                          return (
                            <>
                              {" "}
                              <Tag
                                color={primaryColor}
                                style={{ marginBottom: 5 }}
                              >
                                {m}
                              </Tag>
                              <br />
                            </>
                          );
                        })}
                    </p>
                  </Col>
                  <Col span={5}>
                    {item.specification &&
                      item.specification.slice(0, 6).map((m: any) => {
                        return (
                          <p
                            style={{ marginBottom: 10 }}
                          >{`${m.title}：${m.value}`}</p>
                        );
                      })}
                  </Col>
                  <Col span={4} style={{ textAlign: "center" }}>
                    {item.number}
                  </Col>
                  <Col span={6} style={{ textAlign: "center" }}>
                    {!showPrice && (
                      <>
                        <span style={{ fontSize: "1.2em" }}>
                          {item.has_tag_price_range === 1
                            ? `总价：¥${(
                                Number(item.tag_price_low) * item.number
                              ).toFixed(2)} - ¥${(
                                Number(item.tag_price_high) * item.number
                              ).toFixed(2)}`
                            : `总价：¥${(
                                Number(item.tag_price) * item.number
                              ).toFixed(2)}`}
                        </span>
                        <br />
                        {item.has_tag_price_range === 1
                          ? `(单价：¥${item.tag_price_low} - ¥${item.tag_price_high})`
                          : `(单价：¥${item.tag_price})`}
                      </>
                    )}
                  </Col>
                </List.Item>
              )}
            ></List>
            <Space direction="vertical" size="small"></Space>
          </div>
          {!showPrice && (
            <div
              className={styles["ProductListModal-footer"]}
              style={{ position: "static", textAlign: "right" }}
            >
              <span
                style={{
                  fontSize: "1.4em",
                }}
              >
                总价：
                <span style={{ color: primaryColor }}>
                  {handleGetPriceTotal()}
                </span>
              </span>
            </div>
          )}
        </div>
        <div className={styles["ProductListModal-hidden"]} ref={modalDOM}>
          <div className={styles["ProductListModal-header"]}>
            <Row>
              <Col span={12} style={{ textAlign: "center" }}>
                商品信息
              </Col>
              <Col span={2} style={{ textAlign: "right" }}>
                参数
              </Col>
              <Col span={5} style={{ textAlign: "center" }}>
                数量
              </Col>
              {!showPrice && <Col span={5}>价格</Col>}
            </Row>
            <Icon
              type="icont1_close"
              onClick={() => setIsOpen(false)}
              style={{
                color: primaryColor,
                position: "absolute",
                right: 20,
                top: 20,
              }}
            />
          </div>
          <div className={styles["ProductListModal-content"]}>
            <form></form>
            <List
              dataSource={productData.filter((m: any) => m.isChecked)}
              renderItem={(item: any) => (
                <List.Item
                  className={styles["ProductListModal-content-list-item"]}
                >
                  {agentCode && agentCode !== "" && (
                    <span
                      style={{
                        position: "absolute",
                        right: 10,
                        top: 10,
                      }}
                    >
                      代理码：{agentCode}
                    </span>
                  )}
                  <Col span={1} className={styles["ProductListModal-visible"]}>
                    <Checkbox
                      checked={item.isChecked}
                      onChange={e => {
                        handleCheck(item.shop_product_id, e.target.checked);
                      }}
                    ></Checkbox>
                  </Col>
                  <Col span={3} style={{ padding: 15 }}>
                    <AutoImage
                      width={1}
                      height={1}
                      src={item.product_picture}
                    />
                  </Col>
                  <Col span={5} style={{ height: 170 }}>
                    <p style={{ margin: 0, fontSize: "1.4em" }}>
                      {item.product_name}
                    </p>
                    <p style={{ marginTop: "5px" }}>
                      {item.brandName && item.brandName !== "" && (
                        <Tag color={primaryColor}>{item.brandName}</Tag>
                      )}
                      {item.seriesName && item.seriesName !== "" && (
                        <Tag color={primaryColor}>{item.seriesName}</Tag>
                      )}
                    </p>

                    <p style={{ marginTop: 30 }}>
                      {item.product_option &&
                        item.product_option !== "" &&
                        item.product_option.split(",").map((m: string) => {
                          return (
                            <>
                              {" "}
                              <Tag
                                color={primaryColor}
                                style={{ marginBottom: 5 }}
                              >
                                {m}
                              </Tag>
                            </>
                          );
                        })}
                    </p>
                  </Col>
                  <Col span={5}>
                    {item.specification &&
                      item.specification.slice(0, 6).map((m: any) => {
                        return (
                          <p
                            style={{ marginBottom: 10 }}
                          >{`${m.title}：${m.value}`}</p>
                        );
                      })}
                  </Col>
                  <Col span={3} style={{ textAlign: "center" }}>
                    <div className={styles["ProductListModal-visible"]}>
                      <Button
                        disabled={item.number === 1}
                        icon={<Icon type="icont1_jian" />}
                        onClick={() =>
                          handleChangeNumber(
                            item.shop_product_id,
                            item.number - 1,
                          )
                        }
                        style={{
                          color: "white",
                          background:
                            item.number <= 1 ? "#eaeaea" : primaryColor,
                        }}
                      ></Button>
                      <Input
                        value={item.number}
                        onBlur={e =>
                          handleChangeNumber(
                            item.shop_product_id,
                            Number(e.target.value),
                          )
                        }
                        onChange={e =>
                          handleChangeNumber(
                            item.shop_product_id,
                            Number(e.target.value),
                          )
                        }
                        style={{
                          border: "1px solid " + primaryColor,
                          margin: "0px 5px",
                          width: "50px",
                          textAlign: "center",
                        }}
                      />
                      <Button
                        icon={<Icon type="icont1_jia" />}
                        onClick={() =>
                          handleChangeNumber(
                            item.shop_product_id,
                            item.number + 1,
                          )
                        }
                        style={{ color: "white", background: primaryColor }}
                      ></Button>
                    </div>
                    <div className={styles["ProductListModal-visiblefalse"]}>
                      {item.number}
                    </div>
                  </Col>
                  {!showPrice && (
                    <Col span={6} style={{ textAlign: "center" }}>
                      <span style={{ fontSize: "1.2em" }}>
                        {item.has_tag_price_range === 1
                          ? `总价：¥${(
                              Number(item.tag_price_low) * item.number
                            ).toFixed(2)} - ¥${(
                              Number(item.tag_price_high) * item.number
                            ).toFixed(2)}`
                          : `总价：¥${(
                              Number(item.tag_price) * item.number
                            ).toFixed(2)}`}
                      </span>
                      <br />
                      {item.has_tag_price_range === 1
                        ? `(单价：¥${item.tag_price_low} - ¥${item.tag_price_high})`
                        : `(单价：¥${item.tag_price})`}
                    </Col>
                  )}
                </List.Item>
              )}
            ></List>
            <Space direction="vertical" size="small"></Space>
          </div>
          <div className={styles["ProductListModal-footer"]}>
            <div style={{ textAlign: "right" }}>
              <span
                style={{
                  fontSize: "1.4em",
                }}
              >
                总价：
                <span style={{ color: primaryColor }}>
                  {handleGetPriceTotal()}
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className={styles["ProductListModal-show"]}>
          <div className={styles["ProductListModal-header"]}>
            <Row style={{ width: "90%" }}>
              <Col span={12} style={{ textAlign: "center" }}>
                商品信息
              </Col>
              <Col span={2} style={{ textAlign: "right" }}>
                参数
              </Col>
              <Col span={5} style={{ textAlign: "center" }}>
                数量
              </Col>
              <Col span={5} style={{ textAlign: "center" }}>
                价格
              </Col>
            </Row>
            {/* <Icon
              type="icont1_close"
              onClick={() => setIsOpen(false)}
              style={{
                color: primaryColor,
                position: "absolute",
                right: 20,
                top: 20,
              }}
            /> */}
          </div>
          <div className={styles["ProductListModal-content"]}>
            <List
              dataSource={productData}
              renderItem={(item: any) => (
                <List.Item
                  className={styles["ProductListModal-content-list-item"]}
                >
                  {agentCode && agentCode !== "" && (
                    <span
                      style={{
                        position: "absolute",
                        right: 10,
                        top: 10,
                      }}
                    >
                      代理码：{agentCode}
                    </span>
                  )}
                  <Col span={1} className={styles["ProductListModal-visible"]}>
                    <Checkbox
                      checked={item.isChecked}
                      onChange={e => {
                        handleCheck(item.shop_product_id, e.target.checked);
                      }}
                    ></Checkbox>
                  </Col>
                  <Col span={3} style={{ padding: 15 }}>
                    <AutoImage
                      width={1}
                      height={1}
                      src={item.product_picture}
                    />
                  </Col>
                  <Col span={5} style={{ height: 170 }}>
                    <p style={{ margin: 0, fontSize: "1.4em" }}>
                      {item.product_name}
                    </p>
                    <p style={{ marginTop: "5px" }}>
                      {item.brandName && item.brandName !== "" && (
                        <Tag color={primaryColor}>{item.brandName}</Tag>
                      )}
                      {item.seriesName && item.seriesName !== "" && (
                        <Tag color={primaryColor}>{item.seriesName}</Tag>
                      )}
                    </p>
                    <p style={{ marginTop: 30 }}>
                      {item.product_option &&
                        item.product_option !== "" &&
                        item.product_option.split(",").map((m: string) => {
                          return (
                            <>
                              {" "}
                              <Tag
                                color={primaryColor}
                                style={{ marginBottom: 5 }}
                              >
                                {m}
                              </Tag>
                            </>
                          );
                        })}
                    </p>
                  </Col>
                  <Col span={5}>
                    {item.specification &&
                      item.specification.slice(0, 6).map((m: any) => {
                        return (
                          <p
                            style={{ marginBottom: 10 }}
                          >{`${m.title}：${m.value}`}</p>
                        );
                      })}
                  </Col>
                  <Col span={4} style={{ textAlign: "center" }}>
                    <div className={styles["ProductListModal-visible"]}>
                      <Button
                        disabled={item.number === 1}
                        icon={<Icon type="icont1_jian" />}
                        onClick={() =>
                          handleChangeNumber(
                            item.shop_product_id,
                            item.number - 1,
                          )
                        }
                        style={{
                          color: "white",
                          background:
                            item.number <= 1 ? "#eaeaea" : primaryColor,
                        }}
                      ></Button>
                      <Input
                        value={item.number}
                        onBlur={e =>
                          handleChangeNumber(
                            item.shop_product_id,
                            Number(e.target.value),
                          )
                        }
                        onChange={e =>
                          handleChangeNumber(
                            item.shop_product_id,
                            Number(e.target.value),
                          )
                        }
                        style={{
                          border: "1px solid " + primaryColor,
                          margin: "0px 5px",
                          width: "50px",
                          textAlign: "center",
                        }}
                      />
                      <Button
                        icon={<Icon type="icont1_jia" />}
                        onClick={() =>
                          handleChangeNumber(
                            item.shop_product_id,
                            item.number + 1,
                          )
                        }
                        style={{ color: "white", background: primaryColor }}
                      ></Button>
                    </div>
                    <div className={styles["ProductListModal-visiblefalse"]}>
                      {item.number}
                    </div>
                  </Col>
                  <Col span={6} style={{ textAlign: "center" }}>
                    <span style={{ fontSize: "1.2em" }}>
                      {item.has_tag_price_range === 1
                        ? `总价：¥${(
                            Number(item.tag_price_low) * item.number
                          ).toFixed(2)} - ¥${(
                            Number(item.tag_price_high) * item.number
                          ).toFixed(2)}`
                        : `总价：¥${(
                            Number(item.tag_price) * item.number
                          ).toFixed(2)}`}
                    </span>
                    <br />
                    {item.has_tag_price_range === 1
                      ? `(单价：¥${item.tag_price_low} - ¥${item.tag_price_high})`
                      : `(单价：¥${item.tag_price})`}
                  </Col>
                </List.Item>
              )}
            ></List>
            <Space direction="vertical" size="small"></Space>
          </div>
          <div className={styles["ProductListModal-footer"]}>
            <Row>
              <Col span={8} className={styles["ProductListModal-visible"]}>
                <Checkbox
                  onChange={e => {
                    handleCheckedAll(e);
                    e.stopPropagation();
                  }}
                  checked={isCheckedAll}
                >
                  全选
                </Checkbox>
                <Checkbox
                  checked={showPrice}
                  onChange={e => setShowPrice(e.target.checked)}
                >
                  导出时隐藏价格
                </Checkbox>
              </Col>
              <Col span={16} style={{ textAlign: "right" }}>
                <div className={styles["ProductListModal-visible"]}>
                  <span
                    className={styles["ProductListModal-hover"]}
                    onClick={() => ExportExcel()}
                  >
                    <Icon type="icondaochuexcel"></Icon>
                    <span className={styles["ProductListModal-footer-span"]}>
                      导出表格
                    </span>
                  </span>
                  <span
                    className={styles["ProductListModal-hover"]}
                    onClick={() => exportPdf()}
                  >
                    <Icon type="icondaochupdf"></Icon>
                    <span className={styles["ProductListModal-footer-span"]}>
                      导出PDF
                    </span>
                  </span>
                  <span>
                    <Print
                      trigger={() => {
                        return (
                          <span className={styles["ProductListModal-hover"]}>
                            <Icon type="iconchuanzhen"></Icon>
                            <span
                              className={styles["ProductListModal-footer-span"]}
                            >
                              打印清单
                            </span>
                          </span>
                        );
                      }}
                      content={() => modalDOM.current}
                    />
                  </span>
                </div>
                <span
                  style={{
                    fontSize: "1.4em",
                  }}
                >
                  总价：
                  <span style={{ color: primaryColor }}>
                    {handleGetPriceTotal()}
                  </span>
                </span>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProductListModal;
