import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, Dispatch } from "../../store/configureStore";
import * as styles from "./index.scss";
import { useUpdateEffect } from "ahooks";
import Icon from "../../components/icon";
import AutoImage from "../../components/image";
import { useIntl } from "react-intl";
import { useTheme } from "styled-components";
import { BusinessContext } from "../BusinessContext";
import { getFirstItem } from "../../helpers/modelHelpers";
import replaceState from "../../helpers/reolaceState";
import transform from "lodash/transform";
import groupBy from "lodash/groupBy";
import debounce from "lodash/debounce";
import { Row, Col, Popover, Spin, Cascader, message as Message } from "antd";
import Card from "../../wrappers/card";
import Modal from "../../wrappers/modal";
import List from "../../wrappers/list";
import Select from "../../wrappers/select";
import Button, { PrimaryButton } from "../../wrappers/button";

const UserDesign = () => {
  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch<Dispatch>();
  const { pickList } = useSelector((state: RootState) => state.UserDesignPage);
  const { ideaList = [], total } = useSelector(
    (state: RootState) => state.DesignIdea,
  );

  const {
    getRejectRemarkAsync: ideaRejectLoading,
    getIdeaListAsync: isListLoading,
  } = useSelector((state: RootState) => state.loading.effects.DesignIdea);

  const [ideaFilters, setIdeaFilters] = useState<{ [key: string]: string }>({});
  useEffect(() => {
    dispatch.UserDesignPage.setPickListAsync();
    dispatch.DesignIdea.getIdeaListAsync({
      reset: true,
      page: 1,
      pageSize: 12,
    });
  }, []);

  const { template } = useContext(BusinessContext);
  const { primaryColor, textColor, linkColor } = useTheme();
  const { Option } = Select;

  const { Meta } = Card;
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [selectedType, setSelectedType] = useState(0);
  const [rejectRemark, setRejectRemark] = useState("");

  const handleGetOtherList = (page: number) => {
    setCurrentPageNumber(page);

    dispatch.DesignIdea.getIdeaListAsync({
      reset: true,
      page: page,
      pageSize: 12,
    });
    window.scroll(0, 0);
  };

  const pageItemRender: (
    page: number,
    type: "page" | "prev" | "next" | "jump-prev" | "jump-next",
    originalElement: React.ReactElement<HTMLElement>,
  ) => React.ReactNode = (page, type, originalElement) => {
    switch (type) {
      case "prev":
      case "next":
        return originalElement;
      case "jump-prev":
      case "jump-next":
        return <span>···</span>;

      default:
        return page === currentPageNumber ? (
          <a style={{ backgroundColor: primaryColor, color: "#fff" }}>{page}</a>
        ) : (
          <a style={{ color: textColor }}>{page}</a>
        );
    }
  };

  const ideaListFilters = [
    {
      key: 0,
      value: "publish",
      text: "全部",
      data: pickList?.DesignIdeaStatus?.map(t => ({
        label: t.text,
        value: t.value,
      })),
    },
    {
      key: 1,
      value: "house_type",
      text: "户型",
      data: pickList?.HouseType?.map(t => ({
        label: t.house_type_name,
        value: t.house_type,
      })),
    },
    {
      key: 2,
      value: "house_area",
      text: "面积",
      data: pickList?.HouseArea?.map(t => ({
        label: t.area_name,
        value: `${t.area_low}-${t.area_high}`,
      })),
    },
    {
      key: 3,
      value: "room",
      text: "房间",
      data: pickList?.Room?.map(t => ({
        label: t.room_name,
        value: t.room_id,
      })),
    },
    {
      key: 4,
      value: "style",
      text: "风格",
      data: pickList?.Style?.map(t => ({
        label: t.style_name,
        value: t.style_id,
      })),
    },
    {
      key: 5,
      value: "color",
      text: "颜色",
      data: pickList?.Color?.map(t => ({
        label: t.color_name,
        value: t.color_id,
      })),
    },
    {
      key: 6,
      value: "house_cost",
      text: "花费",
      data: pickList?.HouseCost?.map(t => ({
        label: t.cost_name,
        value: `${t.cost_low}-${t.cost_high}`,
      })),
    },
    {
      key: 7,
      value: "position",
      text: "位置",
      data: transform(
        groupBy(pickList?.Location, "province"),
        (prev, value, key) => {
          prev.push({
            label: key,
            value: key,
            children: value.map(v => ({ label: v.city, value: v.city })),
          });
        },
        [] as { label: string; value: string; children: any }[],
      ),
    },
  ];
  useUpdateEffect(() => {
    const params = {
      ...ideaFilters,
      page: 1,
      reset: true,
      pageSize: 12,
    };
    dispatch.DesignIdea.getIdeaListAsync(params);
  }, [ideaFilters]);

  const handleIdeaFilter = (
    filterProp: string,
    filterValue: string | string[],
  ) => {
    if (filterProp === "position") {
      const [province, city] = filterValue;

      setIdeaFilters(f => ({ ...f, city }));
    } else if (filterProp === "house_area") {
      const [area_low, area_high] = filterValue?.split("-") || [];

      setIdeaFilters(f => ({ ...f, area_low, area_high }));
    } else if (filterProp === "house_cost") {
      const [cost_low, cost_high] = filterValue?.split("-") || [];

      setIdeaFilters(f => ({ ...f, cost_low, cost_high }));
    } else {
      setIdeaFilters(f => ({ ...f, [filterProp]: filterValue }));
    }
  };

  const handleIdeaOperation = (type: string, id: number) => {
    const data = {
      type,
      id,
      callback: (message: string) => {
        Message.success(message);
        setModalOpen(false);
        const params = {
          ...ideaFilters,
          page: currentPageNumber,
          reset: true,
          pageSize: 12,
        };
        dispatch.DesignIdea.getIdeaListAsync(params);
      },
    };
    dispatch.UserDesignPage.setIdeaOperationAsync(data);
  };

  const getRejectedRemark = debounce((designIdeaId: number) => {
    const data = {
      designIdeaId,
      callback: (res: string) => {
        setRejectRemark(res);
      },
    };
    dispatch.DesignIdea.getRejectRemarkAsync(data);
  }, 500);

  return (
    <div className={styles["UserDesign"]}>
      <div className={styles["UserDesign-picklist"]}>
        <List
          grid={{ gutter: 10, column: 8 }}
          dataSource={ideaListFilters}
          renderItem={(item: any) => (
            <List.Item key={item.key}>
              {item.value === "position" ? (
                <Cascader
                  options={item.data}
                  placeholder={item.text}
                  suffixIcon={<Icon type="icont1_xiangxia" />}
                  onChange={val => handleIdeaFilter(item.value, val)}
                  notFoundContent="暂无筛选项"
                ></Cascader>
              ) : (
                <Select
                  style={{ width: "100%", background: "white" }}
                  allowClear
                  placeholder={item.text}
                  suffixIcon={<Icon type="icont1_xiangxia" />}
                  onChange={val => handleIdeaFilter(item.value, val)}
                  notFoundContent="暂无筛选项"
                >
                  {item.data?.map(d => (
                    <Option value={d.value}>{d.label}</Option>
                  ))}
                </Select>
              )}
            </List.Item>
          )}
        />
      </div>
      <div className={styles["UserDesign-content"]}>
        {isListLoading && (
          <div className={styles["UserDesign-content-loading"]}>
            <Spin />
          </div>
        )}
        {!isListLoading && (
          <>
            {" "}
            <List
              grid={{ gutter: 10, column: 4 }}
              dataSource={ideaList}
              renderItem={(item: any) => (
                <List.Item>
                  <div className={styles["UserDesign-content-box"]}>
                    <Card
                      cover={
                        <AutoImage
                          width={1}
                          height={1}
                          src={item.design_idea_picture}
                        />
                      }
                      className="design-category-card"
                      onClick={() => {
                        replaceState.linkTo(
                          item.design_idea_status < 6
                            ? item.edit_url
                            : item.url,
                        );
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <Meta title={item.design_idea_name}></Meta>
                    </Card>
                    {Number(item.design_idea_status) === 1 && (
                      <div
                        className={styles["UserDesign-content-mask"]}
                        onClick={() => {
                          replaceState.linkTo(
                            item.design_idea_status < 6
                              ? item.edit_url
                              : item.url,
                          );
                        }}
                      >
                        <div style={{ display: "block", textAlign: "center" }}>
                          <p
                            className={styles["UserDesign-content-mask-title"]}
                          >
                            待发布
                          </p>
                          <u
                            className={
                              styles["UserDesign-content-mask-content"]
                            }
                            onClick={e => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleIdeaOperation(
                                "publish",
                                item.design_idea_id,
                              );
                            }}
                          >
                            去发布
                          </u>
                        </div>
                      </div>
                    )}
                    {Number(item.design_idea_status) === 2 && (
                      <div
                        className={styles["UserDesign-content-mask"]}
                        onClick={() => {
                          replaceState.linkTo(
                            item.design_idea_status < 6
                              ? item.edit_url
                              : item.url,
                          );
                        }}
                      >
                        <div style={{ display: "block", textAlign: "center" }}>
                          <p
                            className={styles["UserDesign-content-mask-title"]}
                          >
                            审核中
                          </p>
                        </div>
                      </div>
                    )}
                    {Number(item.design_idea_status) === 3 && (
                      <div
                        className={styles["UserDesign-content-rejectmask"]}
                        onClick={() => {
                          replaceState.linkTo(
                            item.design_idea_status < 6
                              ? item.edit_url
                              : item.url,
                          );
                        }}
                      >
                        <div style={{ display: "block", textAlign: "center" }}>
                          <p
                            className={styles["UserDesign-content-mask-title"]}
                          >
                            审核未通过
                          </p>

                          <Popover
                            content={
                              <div style={{ maxWidth: "250px" }}>
                                <h3>抱歉，您的设计未通过审核！</h3>
                                {!ideaRejectLoading && rejectRemark}
                                {ideaRejectLoading && <Spin />}
                              </div>
                            }
                            placement="bottom"
                          >
                            <u
                              className={
                                styles["UserDesign-content-mask-content"]
                              }
                              onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                              onMouseOver={() =>
                                getRejectedRemark(item.design_idea_id)
                              }
                              onMouseOut={() => setRejectRemark("")}
                            >
                              查看原因
                            </u>
                          </Popover>
                        </div>
                      </div>
                    )}

                    {Number(item.design_idea_status) === 5 && (
                      <div
                        className={styles["UserDesign-content-mask"]}
                        onClick={() => {
                          replaceState.linkTo(
                            item.design_idea_status < 6
                              ? item.edit_url
                              : item.url,
                          );
                        }}
                      >
                        <div style={{ display: "block", textAlign: "center" }}>
                          <p
                            className={styles["UserDesign-content-mask-title"]}
                          >
                            已撤销
                          </p>
                          <u
                            className={
                              styles["UserDesign-content-mask-content"]
                            }
                            onClick={e => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleIdeaOperation(
                                "publish",
                                item.design_idea_id,
                              );
                            }}
                          >
                            去发布
                          </u>
                        </div>
                      </div>
                    )}

                    <div className={styles["UserDesign-content-box-btn"]}>
                      {Number(item.design_idea_status) === 6 && (
                        <PrimaryButton
                          shape="circle"
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            setSelectedType(1);
                            setModalOpen(true);
                            setSelectedId(item.design_idea_id);
                          }}
                          size="small"
                          style={{
                            marginRight: 5,
                          }}
                          bgColor={primaryColor}
                          icon={<Icon type="icont1_shuaxin" />}
                        />
                      )}

                      {Number(item.design_idea_status) < 6 && (
                        <PrimaryButton
                          shape="circle"
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            setSelectedType(2);
                            setModalOpen(true);
                            setSelectedId(item.design_idea_id);
                          }}
                          size="small"
                          style={{
                            marginRight: 5,
                          }}
                          bgColor={primaryColor}
                          icon={<Icon type="iconshanchulajitong" />}
                        />
                      )}

                      <PrimaryButton
                        shape="circle"
                        size="small"
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          replaceState.linkTo(item.edit_url);
                        }}
                        bgColor={primaryColor}
                        icon={<Icon type="iconshejishi" />}
                      />
                    </div>
                  </div>
                </List.Item>
              )}
              pagination={{
                current: currentPageNumber,
                total: total,
                pageSize: 12,
                showSizeChanger: false,
                onChange: handleGetOtherList,
                hideOnSinglePage: true,
              }}
            />
            <Modal
              getContainer={() => document.querySelector(".ant-layout")}
              visible={isModalOpen}
              onCancel={() => setModalOpen(false)}
              width={250}
              style={{ top: "30vh" }}
              footer={null}
            >
              <div style={{ padding: "20px", textAlign: "center" }}>
                {" "}
                <div style={{ marginBottom: 20 }}>
                  是否确认{selectedType === 1 ? "撤销" : "删除"}您的设计？
                </div>
                <Row gutter={30}>
                  <Col span={12}>
                    <Button
                      block
                      onClick={() => {
                        handleIdeaOperation(
                          selectedType === 1 ? "unpublish" : "delete",
                          selectedId,
                        );
                      }}
                      color={"#C21D44"}
                    >
                      是
                    </Button>
                  </Col>
                  <Col span={12}>
                    {" "}
                    <PrimaryButton
                      block
                      onClick={() => {
                        setSelectedId(0);
                        setModalOpen(false);
                      }}
                      bgColor={primaryColor}
                    >
                      否
                    </PrimaryButton>
                  </Col>
                </Row>
              </div>
            </Modal>
          </>
        )}
      </div>
    </div>
  );
};

export default UserDesign;
