import { createModel } from "@rematch/core";
import { GraphqlMutation, GraphqlQuery } from "../../helpers/request.helper";
import Reservation from "../../models/Reservation";

export interface ReservationState {
  breadcrumbs: { url: string; title: string }[];
  reservation: Reservation[];

  total: number;
}

export default createModel({
  state: {} as ReservationState,
  reducers: {
    INIR_DATA: (state: ReservationState, reservation, total) => ({
      ...state,
      reservation,
      total,
    }),
    DELETE_RESERVATION: (state: ReservationState, id) => ({
      ...state,
      reservation: state.reservation.filter(
        item => item.customer_experience_id !== id,
      ),
    }),
  },
  effects: {
    async getExperienceAsync(payload) {
      const data = await GraphqlQuery(
        "Experience",
        new Reservation({}),
        payload,
      );

      if (data.length > 0) {
        this.INIR_DATA(data, data[0].total);
      } else {
        this.INIR_DATA([], 0);
      }
    },
    // 删除预约
    async handleReservationDelete(id) {
      const res = await GraphqlMutation("experience", {
        action: "deleteExperience",
        customer_experience_id: id,
      });
      this.getExperienceAsync({ page: 1 });
      // this.DELETE_RESERVATION(id);
    },
  },
});
