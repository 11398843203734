import React from "react";
import styled from "styled-components";
import { Rate } from "antd";

const RateStyled = styled(Rate)<{
  color?: string;
  gutter?: string;
  zeroColor?: string;
}>`
  &.ant-rate {
    padding: 0;
    color: ${props => props.color ?? props.theme.primaryColor};

    .ant-rate-star:not(:last-child) {
      margin-right: ${props => props.gutter ?? "3px"};
    }

    .ant-rate-star-half {
      .ant-rate-star-second {
        color: ${props => props.zeroColor};
      }
    }

    .ant-rate-star-zero {
      .ant-rate-star-first,
      .ant-rate-star-second {
        color: ${props => props.zeroColor};
      }
    }
  }
`;

export default RateStyled;
