import { GraphqlField } from "../helpers/decorators";
import BaseModel from "./BaseModel";

export class Message extends BaseModel {
  public page: number;

  @GraphqlField()
  message_id: number;
  @GraphqlField()
  message_status: number;
  @GraphqlField()
  subject: string;
  @GraphqlField()
  content: string;
  @GraphqlField()
  send_at: string;

  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}
