import { GraphqlField, GraphqlRefField } from "../helpers/decorators";
import BaseModel from "./BaseModel";
export class Amount extends BaseModel {
  @GraphqlField("Amount")
  public title: string;
  @GraphqlField("Amount")
  public amount: string;
  @GraphqlField("Amount")
  public amount_text: string;
}
export default class Commission extends BaseModel {
  @GraphqlField("Commission")
  public commission_order_id: number;

  @GraphqlField("Commission")
  public shop_id: number;
  @GraphqlField("Commission")
  public shop_name: string;
  @GraphqlField("Commission")
  public recipient_name: string;
  @GraphqlField("Commission")
  public recipient_telephone: string;
  @GraphqlField("Commission")
  public shop_sku_id: number;
  @GraphqlField("Commission")
  public brand_name: number;
  @GraphqlField("Commission")
  public series_name: number;
  @GraphqlField("Commission")
  public shop_product_id: number;
  @GraphqlField("Commission")
  public product_name: string;
  @GraphqlField("Commission")
  public product_picture: string;
  @GraphqlField("Commission")
  public option: string;

  @GraphqlField("Commission", "Invitations")
  public quantity: number;
  @GraphqlField("Commission")
  public product_price: string;
  @GraphqlField("Commission")
  public product_amount: string;
  @GraphqlField("Commission")
  public payment_amount: string;
  @GraphqlField("Commission")
  public commission_amount: string;
  @GraphqlField("Commission")
  public order_status: number;
  @GraphqlField("Commission")
  public order_status_text: number;
  @GraphqlField("Commission")
  public commission_type: number;
  @GraphqlField("Commission")
  public commission_type_text: string;
  @GraphqlField("Commission")
  public created_at: string;
  @GraphqlField("Commission")
  public provider_name: string;
  @GraphqlField("Commission")
  public payment_amount_text: string;
  @GraphqlField("Commission")
  public commission_amount_text: string;

  @GraphqlField("Statistics")
  public orderTotal: string;
  @GraphqlField("Statistics")
  public shopTotal: string;
  @GraphqlField("Statistics")
  public inviteTotal: string;
  @GraphqlField("Income")
  public orderAmount: string;
  @GraphqlField("Income")
  public commissionAmount: string;

  @GraphqlRefField(Amount, "Amount")
  public myOrder: Amount[] = [];
  @GraphqlRefField(Amount, "Amount")
  public myCommission: Amount[] = [];

  @GraphqlField("Invitations")
  public agent_name: string;
  @GraphqlField("Invitations")
  public agent_code: string;

  @GraphqlField("Invitations")
  public amount_text: string;
  @GraphqlField("InvitationStatistics")
  public title: string;
  @GraphqlField("InvitationStatistics")
  public value: string;

  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}
