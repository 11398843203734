import { createModel } from "@rematch/core";
import { GraphqlQuery, GraphqlQueryMulti } from "../../helpers/request.helper";
import { request } from "../../helpers/postMessageHelper";
import { getFirstItem } from "../../helpers/modelHelpers";
import { Sku, Product, ProductDetail } from "../../models/Product";
import { ListTotal, GaeaList } from "../../models/Common";
import { ProductFilter } from "../../models/ProductFilter";
import omit from "lodash/omit";
import pick from "lodash/pick";

export type FilterRequeseParams = {
  color?: string;
  page?: string;
  size?: string;
  style?: string;
  material?: string;
  price_low?: string;
  price_high?: string;
  manufactureId?: string;
  view?: string;
  recommend?: string;
  [key: string]: string | undefined;
};
export interface PlStateType {
  breadcrumbs: { url: string; title: string }[];
  isSearch?: boolean; // 当前是否为搜索页面

  total: number;
  style: string;
  room: string; // 服务端渲染存在
  top_category_id: string; // 服务端渲染存在
  parent_category_id: string; // 服务端渲染存在
  product_category_id: string; // 服务端渲染存在
  fourth_category_id: string; // 服务端渲染存在
  pageSize: number; // 服务端渲染存在
  sku_type: number;
  skus: Product[];

  pickList: ProductFilter & {
    [key: string]: any;
    color: null | string; // 默认选中颜色 单选颜色 color_id
    otherColor: null | string; // 更多颜色中选择的颜色;
    userSelectColor: null | string; // 用户从选择板中选中的颜色
    size: null | string; // 默认选中大小 // 可以用来控制筛选项 size_id
    style: null | string; // 默认选中风格 // 客户端侧边栏实现 /暂不支持选中
    material: null | string; // 默认选中材质 // 可以用来控制筛选项 material_category_id
    manufactureId: null | string; // 默认选中厂家
    sorting: null | string; // 排序 recommend | view 客户端使用可以不传  // 可以用来控制筛选项
    price: null | string; // 选中项 price_low 的值  客户端使用可以不传 // 可以用来控制筛选项
    AllColor: {
      // 该字段仅存在于服务端渲染, 用于 用户选择根据颜色值进行筛选
      color_id: number; //
      color_name: string; // 英文颜色
      color_group: string; // 颜色分组
      color_value: string; // 实际的颜色值
    }[];
    Categorys: null | string; // 默认选中全部 // 可以用来控制筛选项 size_id
  };

  productDetails: ProductDetail;

  hasMoreProducts: boolean;
}
export default createModel({
  state: {} as PlStateType,
  reducers: {
    initSkuData: (state: PlStateType, payload: any) => ({
      ...state,
      skus: payload.skus,
      pickList: payload.pickList
        ? { ...payload.pickList, AllColor: payload.pickList.Color }
        : {}, // 2019.2.27 后端新增  AllColor, 客户端无法请求到, 经询问字段与 ProductsColor 一致,为了方便起见进行重新命名,
      // 此字段用于 FIlters 内 color  和 pl 筛选项的 color 区分开来
      total: payload.total,
    }),
    initProductData: (state: PlStateType, payload: any) => ({
      ...state,
      skus: payload.reset ? payload.skus : [...state.skus, ...payload.skus],
      pickList: payload.pickList
        ? { ...payload.pickList, AllColor: payload.pickList.Color }
        : {}, // 2019.2.27 后端新增  AllColor, 客户端无法请求到, 经询问字段与 ProductsColor 一致,为了方便起见进行重新命名,
      // 此字段用于 FIlters 内 color  和 pl 筛选项的 color 区分开来
      total: payload.total,
      hasMoreProducts: payload.hasMoreProducts,
    }),
    filterSkuList: (
      state: PlStateType,
      {
        skus,
        filterItem = {},
        total,
      }: { skus: PlStateType["skus"]; filterItem: any; total: number },
    ) => {
      return {
        ...state,
        skus: skus,
        pickList: { ...state.pickList, ...filterItem },
        total,
      };
    },
    setSkuList: (state: PlStateType, skus: PlStateType["skus"]) => ({
      ...state,
      skus: skus,
    }),
    setProductDetails: (
      state: PlStateType,
      productDetails: PlStateType["productDetails"],
    ) => ({
      ...state,
      productDetails,
    }),
    setCategory: (state: PlStateType, payload: any) => {
      const newState = {
        ...state,
      };
      newState[payload.key] = payload.id;
      return {
        ...newState,
      };
    },
  },

  effects: {
    /**
     * 产品列表初始化
     * @param param
     * @param root
     */
    async initSkuListAsync(
      {
        page = 1,
        pickMethod = "PickList",
        listMethod = "SkuList",
        totalMethod = "SkusTotal",
        callback,
        ...rest
      },
      root: any,
    ) {
      const args = {
        private: root.CommonData.isPrivate,
        shop_id: root.URLSearch.host_id,
        ...rest,
      };
      const data = await GraphqlQueryMulti(
        {
          method: pickMethod,
          model: new ProductFilter(),
          args: {
            action: "pl",
            ...args,
          },
          metadataKey: "pl",
        },
        {
          method: listMethod,
          model: new Sku({}),
          args: { page, ...args },
          metadataKey: "SkuList",
        },
        {
          method: totalMethod,
          model: new ListTotal(),
          args,
        },
      );

      this.initSkuData({
        skus: data[listMethod],
        pickList: getFirstItem(data[pickMethod]),
        total: getFirstItem(data[totalMethod]).total,
      });

      if (callback) {
        callback();
      }
    },

    /**
     * 店铺产品列表初始化
     * @param param
     * @param root
     */
    async initShopSkuListAsync(payload) {
      this.initSkuListAsync({
        ...payload,
        pickMethod: "ShopPickList",
        listMethod: "ShopSkuList",
        totalMethod: "ShopSkusTotal",
      });
    },

    /**
     * 服务点产品列表初始化
     * @param param
     * @param root
     */
    async initProviderSkuListAsync(payload, root) {
      const { callback, ...rest } = payload;

      const args = { shop_id: root.URLSearch.host_id, ...rest };

      const data = await GraphqlQueryMulti(
        {
          method: "ProviderProductPickList",
          model: new ProductFilter(),
          args,
        },
        {
          method: "ProviderProductList",
          model: new Product({}),
          args: { page: 1, ...args },
          metadataKey: "Provider",
        },
        {
          method: "ProviderProductTotal",
          model: new ListTotal(),
          args,
          metadataKey: "Provider",
        },
      );

      this.initSkuData({
        skus: data.ProviderProductList.map((p: Product) => ({
          ...p,
          default_product_id: p.shop_product_id,
        })),
        pickList: getFirstItem(data.ProviderProductPickList),
        total: getFirstItem(data.ProviderProductTotal).total,
      });
      if (callback) {
        callback();
      }
    },

    /**
     * 搜索产品列表初始化
     * @param payload
     * @param root
     */
    async searchSkuListAsync(
      {
        page = 1,
        pickMethod = "PickList",
        listMethod = "Search",
        totalMethod = "SearchTotal",
        callback,
        ...rest
      },
      root: any,
    ) {
      const args = {
        private: root.CommonData.isPrivate,
        shop_id: root.URLSearch.host_id,
        ...rest,
      };

      const data = await GraphqlQueryMulti(
        {
          method: pickMethod,
          model: new ProductFilter(),
          args: {
            action: "search",
            ...args,
          },
        },
        {
          method: listMethod,
          model: new Product({}),
          args: { page, ...args },
          metadataKey: "SearchList",
        },
        {
          method: totalMethod,
          model: new ListTotal(),
          args,
        },
      );

      this.initSkuData({
        skus: data[listMethod].map((p: Product) => ({
          ...p,
          default_product_id: p.shop_product_id,
        })),
        pickList: getFirstItem(data[pickMethod]),
        total: getFirstItem(data[totalMethod]).total,
      });
    },

    /**
     * 搜索产品列表初始化
     * @param param
     * @param root
     */
    async searchShopSkuListAsync(payload) {
      this.searchSkuListAsync({
        ...payload,
        pickMethod: "ShopPickList",
        listMethod: "ShopSearch",
        totalMethod: "ShopSearchTotal",
      });
    },

    /**
     * 产品列表翻页，包括pl、服务点、搜索
     * @param param
     */

    async getSkuListMoreAsync(payload) {
      const {
        page = 1,
        keyword,
        provider_id,
        activity_id,
        callback,
        ...rest
      } = payload;

      let data: Product[] | Sku[] = [];
      if (activity_id) {
        const skus = await GraphqlQuery(
          "ShopActivity",
          new Product({}),
          {
            action: "getActivityProducts",
            page,
            activity_id,
            shop_id: payload.shop_id,
          },
          "ShopActivity",
        );

        data = skus.map((p: Product) => ({
          ...p,
          default_product_id: p.shop_product_id,
        }));
      } else if (provider_id) {
        const args = omit(rest, "private");

        const skus = await GraphqlQuery(
          "ProviderProductList",
          new Product({}),
          { page, provider_id, ...args },
          "Provider",
        );

        data = skus.map((p: Product) => ({
          ...p,
          default_product_id: p.shop_product_id,
        }));
      } else if (keyword) {
        const skus = await GraphqlQuery(
          `${payload.shop_id ? "Shop" : ""}Search`,
          new Product(rest),
          { page, keyword },
          "SearchList",
        );

        data = skus.map((p: Product) => ({
          ...p,
          default_product_id: p.shop_product_id,
        }));
      } else {
        data = await GraphqlQuery(
          `${payload.shop_id ? "Shop" : ""}SkuList`,
          new Sku(rest),
          { page },
          "SkuList",
        );
      }

      this.setSkuList(data);

      if (callback) {
        callback(data);
      }
    },

    /**
     * 产品列表筛选，包括pl、服务点、搜索
     * @param payload
     */

    async filterSkuListAsync(payload: { [key: string]: any; filterItem: any }) {
      const {
        page = 1,
        topCateId,
        callBack,
        filterItem,
        order_by,
        ...rest
      } = payload;

      if (payload.provider_id) {
        const args = omit(rest, "private");

        const data = await GraphqlQueryMulti(
          {
            method: "ProviderProductList",
            model: new Product({}),
            args: { page, order_by, ...args },
            metadataKey: "Provider",
          },
          {
            method: "ProviderProductTotal",
            model: new ListTotal(),
            args,
            metadataKey: "Provider",
          },
        );

        const skus = data.ProviderProductList.map((p: Product) => ({
          ...p,
          default_product_id: p.shop_product_id,
        }));

        if (callBack) {
          payload.callBack(skus);
        }
        this.filterSkuList({
          skus,
          filterItem,
          total: getFirstItem(data.ProviderProductTotal).total,
        });
      } else if (payload.keyword) {
        const listMethod = `${payload.shop_id ? "Shop" : ""}Search`,
          totalMethod = `${payload.shop_id ? "Shop" : ""}SearchTotal`;

        const data = await GraphqlQueryMulti(
          {
            method: listMethod,
            model: new Product(rest),
            args: { page, order_by },
            metadataKey: "SearchList",
          },
          {
            method: totalMethod,
            model: new ListTotal(),
            args: rest,
          },
        );

        const skus = data[listMethod].map((p: Product) => ({
          ...p,
          default_product_id: p.shop_product_id,
        }));

        if (callBack) {
          payload.callBack(skus);
        }
        this.filterSkuList({
          skus,
          filterItem,
          total: getFirstItem(data[totalMethod]).total,
        });
      } else {
        const listMethod = `${payload.shop_id ? "Shop" : ""}SkuList`,
          totalMethod = `${payload.shop_id ? "Shop" : ""}SkusTotal`;

        const data = await GraphqlQueryMulti(
          {
            method: listMethod,
            model: new Sku(rest),
            args: { page, order_by },
            metadataKey: "SkuList",
          },
          {
            method: totalMethod,
            model: new ListTotal(),
            args: rest,
          },
        );

        if (callBack) {
          callBack(data.SkuList);
        }

        this.filterSkuList({
          skus: data[listMethod],
          filterItem,
          total: getFirstItem(data[totalMethod]).total,
        });
      }
    },

    /**
     * 搭配设计空标签选择产品搜索
     * @param payload
     * @param state
     */
    async searchProductListAsync(payload: any, state: any) {
      const {
        page,
        reset,
        keyword,
        top_category_id,
        parent_category_id,
        product_category_id,
        fourth_category_id,
        has_transparency,
      } = payload;

      if (window.parent !== window && location.search.indexOf("host_id=") < 0) {
        const pageSize = 30;

        const data = await request<GaeaList<Product[]>>(
          state.DesignIdea.gaeaDomain,
          "designidea.getProducts",
          {
            pageSize,
            pageNumber: page,
            top_category_id,
            parent_category_id,
            product_category_id,
            fourth_category_id,
            searchText: keyword,
            ...pick(payload, "root_id", "room", "searchField"),
          },
        );

        this.initProductData({
          skus: data.rows,
          total: data.total,
          hasMoreProducts: Number(page) * pageSize < data.total,
          reset,
        });
      } else {
        let listMethod = "Search",
          filterMethod = "PickList",
          totalMethod = "SearchTotal";

        if (payload.shop_id) {
          listMethod = `${payload.shop_id ? "Shop" : ""}Search`;
          filterMethod = `${payload.shop_id ? "Shop" : ""}PickList`;
          totalMethod = `${payload.shop_id ? "Shop" : ""}SearchTotal`;
        }

        const data = await GraphqlQueryMulti(
          {
            method: filterMethod,
            model: new ProductFilter(),
            args: {
              action: "search",
              keyword,
              top_category_id,
              parent_category_id,
              product_category_id,
              fourth_category_id,
              has_transparency,
              ...pick(payload, ["shop_id", "private"]),
            },
          },
          {
            method: listMethod,
            model: new Product({}),
            args: omit(payload, "reset", "searchField"),
            metadataKey: "SearchList",
          },
          {
            method: totalMethod,
            model: new ListTotal(),
            args: omit(payload, "page", "reset", "searchField"),
          },
        );

        const pageInfo: ListTotal = getFirstItem(data[totalMethod]);

        this.initProductData({
          skus: data[listMethod].map((p: Product) => ({
            ...p,
            default_product_id: p.shop_product_id,
          })),
          pickList: getFirstItem(data[filterMethod]),
          total: pageInfo.total,
          hasMoreProducts: Number(page) * pageInfo.pageSize < pageInfo.total,
          reset,
        });
      }
    },

    /**
     * 搭配设计产品列表
     * @param payload
     * @param rootState
     */
    async getProductListAsync(payload: any, rootState: any) {
      const {
        page,
        reset,
        top_category_id,
        parent_category_id,
        product_category_id,
        fourth_category_id,
        has_transparency,
      } = payload;

      const data = await GraphqlQueryMulti(
        {
          method: "PickList",
          model: new ProductFilter(),
          args: {
            action: "productList",
            top_category_id,
            parent_category_id,
            product_category_id,
            fourth_category_id,
            has_transparency,
            ...pick(payload, ["shop_id", "private"]),
          },
        },
        {
          method: "ProductList",
          model: new Product({}),
          args: omit(payload, "reset"),
          metadataKey: "SearchList",
        },
        {
          method: "ProductListTotal",
          model: new ListTotal(),
          args: omit(payload, "page", "reset"),
        },
      );

      const pageInfo: ListTotal = getFirstItem(data.ProductListTotal);

      this.initProductData({
        skus: data.ProductList.map((p: Product) => ({
          ...p,
          default_product_id: p.shop_product_id,
        })),
        pickList: getFirstItem(data.PickList),
        total: pageInfo.total,
        hasMoreProducts: Number(page) * pageInfo.pageSize < pageInfo.total,
        reset,
      });
    },

    /**
     * 搭配设计获取产品详情
     * @param list
     * @param rootState
     */
    async getProductDetailsAsync(list, rootState) {
      if (!rootState.PLPage.skus || rootState.PLPage.skus.length === 0) return;

      const shop_product_id_list = (rootState.PLPage.skus || []).map(
        (item: Sku) => item.default_product_id,
      );

      const data = await GraphqlQuery(
        "Product",
        new ProductDetail({}),
        { shop_product_id_list },
        "ProductList",
      );

      this.setProductDetails(
        data.reduce((obj: any, cut: any) => {
          obj[cut.shop_product_id] = cut;
          return obj;
        }, {}),
      );
    },

    /**
     * 活动产品列表初始化
     * @param param
     */
    async initActivitySkuListAsync(payload) {
      const { callback, ...rest } = payload;

      const ShopActivity = await GraphqlQuery(
        "ShopActivity",
        new Product({}),
        { action: "getActivityProducts", page: 1, ...rest },
        "ShopActivity",
      );
      const ProductTotal = await GraphqlQuery("ShopActivity", new ListTotal(), {
        action: "ProductTotal",
        ...rest,
      });

      this.initSkuData({
        skus: ShopActivity.map((p: Product) => ({
          ...p,
          default_product_id: p.shop_product_id,
        })),
        total: getFirstItem(ProductTotal).total,
      });
      if (callback) {
        callback();
      }
    },
  },
});
