import * as React from "react";
import Layout from "../Layout";
import Content from "./Content";
import { RootState } from "../../store/configureStore";

const mapStateToProps = (state: RootState) => ({
  breadcrumbs: state.ToBCart.breadcrumbs,
});

class TobCart extends React.Component<ReturnType<typeof mapStateToProps>> {
  public render() {
    const {
      breadcrumbs = [
        {
          url: "/",
          title: "首页",
        },
        {
          url: "/cart",
          title: "企业采购清单",
        },
      ],
    } = this.props;
    return (
      <Layout breadcrumbs={breadcrumbs}>
        <Content />
      </Layout>
    );
  }
}

export default TobCart;
