import * as React from "react";
import * as ReactDOM from "react-dom";

type State = {
  el: null | HTMLDivElement;
};

class NavigationMaxkBox extends React.Component<
  { children: React.ReactChild; isOpen: boolean },
  State
> {
  private appbox: HTMLElement | null;

  public state: State = {
    el: null,
  };

  public componentDidMount() {
    const el = document.createElement("div");
    this.appbox = document.getElementById("app");

    this.setState({
      el: el,
    });

    if (this.appbox) {
      this.appbox.appendChild(el);
    }
  }

  public componentWillUnmount() {
    if (this.appbox && this.state.el) {
      this.appbox.removeChild(this.state.el);
    }
  }

  public render() {
    if (this.state.el === null) {
      return null;
    } else {
      return (
        this.props.isOpen &&
        ReactDOM.createPortal(this.props.children, this.state.el)
      );
    }
  }
}

export default NavigationMaxkBox;
