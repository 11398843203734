import React from "react";
import classanmes from "classnames";
import * as styles from "./index.scss";

type ProductFilterProps = {
  title: string | React.ReactNode;
  value: string | React.ReactNode;
  image?: string;
  className?: string;
  onClick?: (e: any) => void;
};

function ProductFilter(props: ProductFilterProps) {
  const { title, value, image, className, ...other } = props;

  return (
    <div className={classanmes(styles[`ProductFilter`], className)} {...other}>
      <div>{value}</div>

      <div style={{ color: "#7493A6" }}>{title}</div>
    </div>
  );
}
export default ProductFilter;
