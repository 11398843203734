import * as React from "react";
import { GraphqlMutation } from "../../../helpers/request.helper";
import { StateType } from "../models";
import { Row } from "antd";
import { useCountDown, useMount } from "ahooks";
import { useIntl } from "react-intl";
import { useTheme } from "styled-components";
import { PrimaryButton } from "../../../wrappers/button";

/**
 *
 * 邮箱验证模块
 *
 */

const EmailVerification: React.FC<{
  email: StateType["email"];
  toSiginUp?: () => void;
  emailVerficationType: StateType["emailVerficationType"];
  password: StateType["password"];
  insideModal?: boolean;
}> = ({
  email,
  toSiginUp,
  emailVerficationType,
  password,
  insideModal = true,
}) => {
  const { formatMessage: f } = useIntl();
  const { primaryColor } = useTheme();

  const [countdown, setTargetDate] = useCountDown();

  const countdownSecond = Math.round(countdown / 1000);

  const emailRequests = async () => {
    const res = await GraphqlMutation("Register", {
      action: password ? "resend" : "forgot",
      email,
      password: password ?? undefined,
    });

    //  返回状态码 1103 是失败  其他的情况都是成功
    if (res.status_code === 200) {
      setTargetDate(Date.now() + 1000 * 60);
    } else {
      // 失败那么倒计时编程0 让用户重试
      setTargetDate(undefined);
    }
  };

  useMount(() => {
    if (!email) {
      toSiginUp();
    } else {
      if (password) {
        setTargetDate(Date.now() + 1000 * 60);
      } else {
        emailRequests();
      }
    }
  });

  return (
    <div>
      {!insideModal && (
        <Row style={{ padding: "10px 0 0 10px" }}>
          {emailVerficationType === "register"
            ? f({ id: "EmailVerification" })
            : f({ id: "ForgotPasswordDashStepTwo" })}
        </Row>
      )}

      <div style={{ padding: "20px 45px" }}>
        <Row style={{ marginBottom: "20px" }}>
          {f({ id: "PleaseCheckYourEmail" })}
          <span style={{ color: primaryColor, marginLeft: "10px" }}>
            "{email}"
          </span>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          {f({ id: "IfYouDoNotReciveTheEmail" })}
        </Row>
        <Row>
          <PrimaryButton
            disabled={countdownSecond > 0}
            onClick={emailRequests}
            block
            bgColor={primaryColor}
          >
            {countdownSecond > 0 && `(${countdownSecond})`}
            {f({ id: "SendAgain" })}
          </PrimaryButton>
        </Row>
      </div>
    </div>
  );
};

export default React.memo(EmailVerification);
