import { createModel } from "@rematch/core";
import {
  GraphQLRequest,
  GraphqlQuery,
  GraphqlMutation,
} from "../../helpers/request.helper";
import { getFirstItem } from "../../helpers/modelHelpers";
import {
  OrderDetails,
  OrderLogistics,
  OrderQrcode,
  OrderPayType,
  OrderCollect,
} from "../../models/Order";
import Payment from "../../models/Payment";
import { Order } from "../../models/Order";

import produce from "immer";

export interface OrderDetailState {
  breadcrumbs: { url: string; title: string }[];
  order: Order;

  orderStatus: {
    order_status: string; // 订单状态
    updated_at: string; // 最后修改时间
  }[];

  collectIds: {
    data_id: number;
  }[];
}

export default createModel({
  state: {} as OrderDetailState,
  reducers: {
    INIT_DATA: (state: OrderDetailState, { order }) => ({
      ...state,
      order: order,
    }),
    setOrder: (state: OrderDetailState, order: Order) => ({
      ...state,
      order,
    }),
    setCollectData: (state: OrderDetailState, { collectIds }) => ({
      ...state,
      collectIds: collectIds,
    }),
    changeCollectData(state: OrderDetailState, type: boolean, dataId: number) {
      let collectIds = [];
      if (type) {
        collectIds = state.collectIds.filter(
          item => Number(item.data_id) !== dataId,
        );
      } else {
        const data = [{ data_id: dataId }];
        collectIds = Array.from(new Set([...state.collectIds, ...data]));
      }
      return { ...state, collectIds };
    },
    changeLogistics: (
      state: OrderDetailState,
      payload: { orderProductId: number; firstData: any },
    ) =>
      produce(state, draft => {
        const index = draft.order.product.findIndex(
          rp => rp.order_product_id === payload.orderProductId,
        );
        draft.order.product[index].logistics = payload.firstData;
      }),
  },
  effects: {
    async getOrderDetailAsync(orderNumber: string, root: any) {
      const data = new OrderDetails({
        order_number: orderNumber,
        order_id: "0",
        private: root.CommonData.isPrivate,
        shop_id: root.URLSearch.host_id,
      });
      const res = await GraphqlQuery("Orders", data);
      this.INIT_DATA({
        order: getFirstItem(res),
      });
    },
    async getCollect() {
      const data = new OrderCollect({ collect: "product" });
      const res = await GraphqlQuery("CollectIds", data);
      this.setCollectData({
        collectIds: res,
      });
    },
    async handleChangeCollect(payload: any) {
      const { dataId, type } = payload;
      const action = type ? "cancel" : "like";
      const res = await GraphqlMutation("Collect", {
        action: action,
        object: "product",
        data_id: dataId,
      });
      this.changeCollectData(type ? true : false, dataId);
    },
    async handleLogisticsGet(orderProductId: number) {
      const model = new OrderLogistics({ order_product_id: orderProductId });
      const data = await GraphqlQuery("OrderShipping", model);
      const firstData = getFirstItem(data);
      this.changeLogistics({
        orderProductId,
        firstData,
      });
    },

    // 生成订单后获取二维码的url
    async getQrCodeAsync(payload) {
      const { callback, ...rest } = payload;
      const model = new OrderQrcode({});
      const data = await GraphqlQuery("PayCode", model, rest);
      if (callback) {
        callback(data);
      }
    },
    // 支付开始展示后需要调用支付结果接口
    async handleGetPayType(payload) {
      const { callback, order_id } = payload;
      const model = new OrderPayType({ order_id: order_id, action: "default" });
      const data = await GraphqlQuery("PayResult", model);
      if (callback) {
        callback(data);
      }
    },
    async getZfbUrl(payload: any) {
      const { callback, ...rest } = payload;
      const data = new Payment({});
      const res = await GraphqlQuery("AliPay", data, rest, "AliPay");
      callback && callback(getFirstItem(res).url);
    },
    async getPayResult(payload: any) {
      const { order_id, callback } = payload;
      const data = new Payment({ action: "alipay", order_id: order_id });
      const res = await GraphqlQuery("PayResult", data);
      callback && callback(getFirstItem(res).result);
    },
  },
});
