import React, { useState, ReactNode } from "react";
import styled from "styled-components";
import { Input, Space, Popover } from "antd";
import { ChromePicker } from "react-color";
import { useControllableValue } from "ahooks";
import { SizeType } from "antd/lib/config-provider/SizeContext";

export interface ColorPickerProps {
  onChange?: (value: string) => void;
  value?: string;
  defaultValue?: string;

  size?: SizeType;

  className?: string;

  style?: React.CSSProperties;
}

const ColorPicker: React.FC<ColorPickerProps> = props => {
  const [state, setState] = useControllableValue<string>(props);
  const [tempValue, setTempValue] = useState(state);
  const [defaultValue] = useState(state);

  const { size, className, style } = props;

  const [colorPickerVisiable, setColorPickerVisiable] = useState(false);

  const sizes = {
    middle: "30px",
    small: "24px",
  };

  const defaultElement = (
    <div
      style={{
        width: sizes[size],
        height: sizes[size],
        cursor: "pointer",
        backgroundColor: state,
      }}
    ></div>
  );

  return (
    <Space>
      <Input
        value={tempValue}
        onChange={e => {
          setTempValue(e.target.value);
        }}
        size={size}
        onBlur={e => {
          if (/(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(e.target.value)) {
            setState(e.target.value);
          } else {
            setTempValue(defaultValue);
            setState(defaultValue);
          }
        }}
        className={className}
        style={style}
      />
      <Popover
        content={
          <ChromePicker
            color={state}
            onChange={val => {
              setTempValue(val.hex);
              setState(val.hex);
            }}
          />
        }
        trigger="click"
        visible={colorPickerVisiable}
        onVisibleChange={setColorPickerVisiable}
        placement="bottom"
        getPopupContainer={triggerNode => triggerNode.parentElement!}
        overlayClassName="henryxue"
      >
        <div>{props.children || defaultElement}</div>
      </Popover>
    </Space>
  );
};

ColorPicker.defaultProps = {
  size: "small",
};

export default ColorPicker;
