import { createModel } from "@rematch/core";
import {
  GraphQLRequest,
  GraphqlQuery,
  GraphqlMutation,
} from "../../helpers/request.helper";
import { getFirstItem } from "../../helpers/modelHelpers";
import produce from "immer";
// import VerifyAgentInfo from "../../models/VerifyAgent";

export interface VerifyType {
  breadcrumbs: { url: string; title: string }[];
}

export default createModel({
  state: {} as VerifyType,
  reducers: {},
  effects: {},
});
