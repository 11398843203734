export default {
  Messages: "消息",
  CurrentOrder: "當前訂單",
  OrderHistory: "歷史訂單",
  Review: "評價",
  YouDoNotHaveAnyMessageToShow: "您沒有任何訊息要顯示。",
  YouHaveNotPLaceAnyOrderYetPeriod: "您未下任何訂單。",
  ViewOrderDetails: "查看訂單詳情",
  ThereIsNoHistoricalOrder: "您目前未下訂單，沒有歷史訂單，請選擇合適的產品！",
  ToSpeedUptheCheckOut:
    "要加快結帳速度，或要添加其他地址，只需單擊“添加新地址”，填寫表格，然後單擊“保存”按鈕。如果要編輯或刪除任何現有地址，只需單擊現有地址的相應鏈接。",
  More: "更多",
  Binding: "綁定",
  BindingReferenceCode: "綁定代碼",
  ChangePassword: "修改密碼",
  ReferenceCodeColon: "參考代碼：",
  PersonalInformation: "個人信息",
  FirstName: "名字",
  LastName: "姓",
  Gender: "性別",
  DateOfBirth: "生日",
  Email: "電子郵件",
  CellphoneNumber: "手機號碼",
  CurrentPassword: "當前密碼",
  NewPassword: "新密碼",
  ConfirmPassword: "確認密碼",
  Password: "密碼",
  PleaseInputValidDateWithFormat: "請輸入有效日期，格式為“ XXXX-XX-XX”",
  PasswordDoesNotMatch: "密碼不匹配",
  BindingSuccessed: "綁定成功",
  UnbindingSuccessed: "解綁成功",
  TheReferenceCodeReachesTheUpperLimitOf: "參考代碼達到20個的上限。",
  ReferenceCodeHasBeenBound: "參考代碼已被綁定，請重試。",
  InvalidReferenceCodePeriodPleaseEnterValidNumber:
    "無效的參考代碼。 請輸入有效的數字和字母。",
  Name: "名字",
  Address: "地址",
  PhoneNumber: "電話號碼",
  Alias: "別名",
  Organization: "企業名稱",
  PaymentMethod: "付款方式",
  TotalInToal: "總計{total}",
  OrderDetails: "訂單詳細信息",
  ConfirmTheOrder: "確認訂單",
  PlseaseConfirmYourOrder: "請確認您的訂單。",
  CancelTheOrder: "取消訂單",
  AreyouConfirmToCancelYourOrder: "您確定要取消訂單嗎？",
  YouCanAddUpToTwentyAddress: "您最多可以添加20個地址。您已添加{num}個地址",
  AddYourAddressByClicking: "點擊“添加新地址”按鈕，添加您的地址",
  AddingShippingAddress: "添加送貨地址",
  LineOne: "地址：第一行",
  LineTwo: "地址：第二行",
  City: "城市",
  StateSlashProvince: "州/省",
  PostalCode: "郵政編碼",
  Country: "國家",
  CountryCode: "國家代碼",
  Note: "留言",
  CouponSlashReferenceCode: "優惠券/參考代碼",
  EnterEmailAddress: "請輸入電郵地址",
  OrderStatus: "訂單狀態",
  ConsigneeInformation: "收貨人信息",
  PaymentInformaton: "付款信息",
  Quotation: "報價單",
  ReviewProduct: "評論產品",
  TotalPrice: "總計{價格}",
  PriceSlashItem: "{價格} /件",
  AssemblyManualLeftParenthesisComingSoonRightParenthesis:
    "組立説明書（近日公開）",
  CustomerService: "客戶服務",
  WelcomeBack: "歡迎回來",
  SignUp: "註冊",
  EmailVerification: "電子郵件驗證",
  ForgotPasswordDashStepTwo: "忘記密碼-步驟2",
  ForgotPasswordDashStepOne: "忘記密碼-步驟1",
  ResetPassword: "重設密碼",
  ForgotPassword: "忘記密碼",
  InvalidEmailOrPassword: "無效的電子郵箱和密碼",
  WelcomeToMegaHome: "歡迎來到MegaHome",
  AlreadyHaveAnAccountQuestionMark: "已經有帳號了？",
  PleaseLogin: "請登錄",
  EnterThePassword: "輸入密碼",
  Industry: "行業",
  PleaseCheckYourEmail: "請查看您的郵箱",
  IfYouDoNotReciveTheEmail:
    "如果您在幾分鐘內未收到我們的郵件，請查看您的垃圾郵箱。",

  IHaveReadAndAcceptedMegahomeTermsOfService:
    "我已閱讀並接受<a>MegaHome服務條款</a>。",
  WeHaveSentYouAConfirmationEmailEmail:
    "我們已向您發送確認電子郵件至“ <span> {email} </span>”。 請檢查您的電子郵件並確認帳戶。",
  WeHaveSentYouAResetLink:
    "我們已通過電子郵件“ <span> {email} </span>”向您發送了一個重置鏈接。 請檢查您的電子郵件地址，然後單擊重置鏈接。",
  DidNotRevicedEmailQuestionMark: "沒有收到郵件？",
  ThisEmailAddressHasAlreadyBeenRegistered: "您輸入的郵件地址已註冊，請登錄。",

  BackToMainMenu: "返回主菜單",
  Back: "後退",
  Homepage: "首頁",
  ComingSoon: "即將到來",
  ShopTheBrand: "店鋪品牌",
  Account: "賬戶",

  SignOut: "登出",
  SendAgain: "重新發送",
  BackToHomepage: "返回首頁",
  TermOfService: "服務條款",
  NextStep: "下一步",
  MarketplaceHome: "主頁",
  Category: "類別",
  Room: "房間",
  NotAvailable: "已下架",
  ToReview: "去評價",
  ByUsingThisSiteYouAgreeToThe:
    "使用本網站即表示您同意將Cookies用於分析，個性化內容和廣告分發。",
  FindOutMore: "了解更多",
  Metric: "公制",
  Imperial: "英制",
  Home: "家",
  Firm: "公司",
  ReadAll: "全部已讀",
  DeleteAll: "刪除全部",
  AddNewAddress: "添加新地址",
  Confirm: "確認",
  Cancel: "取消",
  SetAsDefault: "設為默認",
  Default: "默認",
  Required: "必填項",
  TleLengthOfTheInputExceedNumCharacters: "輸入內容的長度不能超過{num}個字符",
  TheLengthOfTheInputMustBeAtLeast: "輸入內容的長度至少為{num}個字符",
  PleaseEnterAValidEmailAddressExclamationMark: "請輸入一個有效電子郵箱地址！",
  Loading: "加載中",
  Male: "男",
  Female: "女",
  Other: "其他",
  ByShopName: "来自",
  Odm: "ODM",
  Oem: "OEM",
  Customization: "定制化",
  GetThePriceCommaSpecificationsCommaShipping:
    "免費獲取產品價格，規則，運輸以及更多信息！",
  SignUpNow: "立即註冊",
  Quantity: "數量",
  Submit: "提交",
  Collection: "收藏",

  AddToCart: "加入購物車",
  PleaseSelectOptions: "請選擇",
  ContinueToCart: "進入購物車查看",
  DisabledPeople: "殘障人士",
  OopsCommaUploadFileExceedsMaximunSize: "您上傳的文件超過了10M。請重新上傳。",
  OopsCommaUploadFileIsNotAValidImage:
    "您上傳的文件不是有效的圖片格式，您只能使用JPG和PNG文件。請重新上傳。",
  Cart: "購物車",
  LeftParenthesisPricePerItemRigthParenthesis: "({price} per item)",
  AddedToCart: "已添加到購物車",
  Specifications: "產品規格",
  Shipping: "運送方式",
  Price: "價格",
  PrintLabel: "打印標籤",
  PrintQrCode: "打印二維碼",
  YouCanSave: "您可以節省",
  Product: "商品",
  ToDo: "操作",
  Total: "總計",
  ContactUs: "聯繫我們",
  Quote: "發起詢價",
  RemoveFromCart: "從購物車中刪除",
  SelectAll: "全部選中",
  SignIn: "登入",
  ContinueShopping: "繼續瀏覽",
  Login: "登錄",

  SectionsInMegahomeMarketplace:
    "来自 <a href='/termsofservice/#disclaimer'>MegaHome Marketplace</a> 分类",
  ItemsInMegahomeMarketplace:
    "來自<a href='/termsofservice/#disclaimer'>MegaHome Marketplace</a>的產品",
  MegaHomeRecommended: "MegaHome推薦",
  Viewed: "瀏覽次數",
  Size: "尺寸",
  Material: "材質",
  Color: "顏色",
  Style: "風格",
  MoreFilters: "更多篩選",
  Clear: "清除",
  CountResults: "{count}個結果",
  NewArrivals: "新品",
  Selected: "已選擇",
  DashDashNoPreferenceDashDash: "-- 請選擇 --",
  DesignIdeas: "設計理念",
  NumIdeas: " {num}個設計理念",
  Hide: "隱藏",
  Show: "展示",
  ConnectWithUs: "與我們一起",
  MegaHomeUsAddress: "39055 Hastings Street, Suite 208 Fremont, CA 94538",
  WeHaveRevicedYourFeedback: "我們已收到您的反饋，我們將盡快與您聯繫。",
  SendAMessage: "發送消息",
  TellUsAboutYourNeeds: "告訴我們您的需求",
  Inquire: "咨詢",
  Feedback: "反饋",
  Partnership: "商務合作",
  ItHelp: "技術支持",
  Others: "其他",
  HotelVendor: "酒店供應商",
  Retailers: "零售店",
  Designers: "設計師",
  Suppliers: "供應商",
  About: "關於",
  AboutUs: "關於我們",
  Join: "加入我們",
  Marketing: "市場推廣",
  Disclaimer: "免責聲明",
  Apply: "應用",
  Return: "返回",
  LookingForMoreStylesQuestionMark: "查看更多風格？",
  FindMore: "發現更多",
  Share: "分享",
  AddToCartSuccessfullyExclamationMark: "成功添加至購物車！",
  ProductDetails: "產品詳情",
  Description: "描述",
  WarningColonLeftBracketCaliforniaPropositionSixtyFive:
    "警告：[加利福尼亞州65號提案]請與供應商確認產品安全。",
  Features: "特徵",
  ProductFeatures: "產品特徵",
  ComponentsData: "部件數據",
  Compare: "比較",
  Reviews: "評價",
  SimilarProducts: "相似產品",
  BuyTogether: "一起購買",
  ComparewithSimilarPorducts: "與同類產品比較",
  CustomerRating: "客戶評分",
  SortByMostRecently: "按最新排序",
  SortByRating: "按評分排序",
  ReviewProductFromOrder: "評價訂單中的產品",
  PleaseReviewOurProductsComma:
    "請對我們的產品進行評價，並將您的經驗分享給其他客戶。",
  AsteriskSignYouDoNotHaveFinishedOrder: "*您沒有可以評價的訂單產品。",
  MoreReviews: "更多評論",
  MadeInCountry: "{country}製造",
  PriceIncluded: "價格已含",
  MinPeriod: "最低",
  MaxPeriod: "最高",
  MyShopingCart: "我的購物車",
  CountItems: "{count}個產品",
  PleaseConfirm: "請確認",
  AreYouConfirmToRemoveTheseItems: "您確定要從購物車中刪除這些物品嗎？",
  YouHaveItemAddedWithDifferentCurrencyType:
    "抱歉，您添加了具有不同貨幣類型的商品。 請僅選擇一種貨幣進行報價。",
  OopsExclamationMarkYouShoppingCartIsEmpty: "抱歉，您的購物車是空的。",
  SignInToSeeWhatYouHaveAddedOrSaved: "登錄后查看您添加或保存的內容。",
  WeHaveALotOfSpecialProductOffers:
    "我們可以提供很多定制產品的報價,以及您可以從我們的網站上獲取更多的靈感。",
  SelectedColonCountItems: "已選擇：{count}個產品",
  StartFrom: "起始",
  ContainersFittingEstimate: "集裝箱裝載量估計",
  QuoteInformation: "詢價信息",
  SubmittedExclamationMark: "已提交！",
  SelectFromSavedAddress: "從保存的地址中選擇",
  WeWillGetBackToYou: "我們將盡快與您聯繫！",

  OopsExclamationMark: "糟糕！",
  WeCannotFindAnyResultThatMatchYour: "我們無法找到您的搜索結果:",
  ThePageTitleStillUnderConstructionPeriod:
    "當前{title}頁面仍在建設中，感謝您的等待！",
  SometingYouMayLikeColon: "您可能也會喜歡:",
  OopsExclamationMarkCurrentFilterOptionsDoNot:
    "糟糕，當前的選項沒有任何結果，請重新選擇。",
  SortAndFilter: "排序和篩選",
  Supplier: "供應商",
  Shop: "店鋪",
  ShopEstablishedDate: "成立日期",
  ShopRecommendedProducts: "精選產品",
  FurnitureAndDecor: "家具與裝飾品",
  SearchInThisShop: "當前店鋪內搜索",
  Copyright: "版权所有",
  SearchItemsFromShop: "在{shop}中搜索產品",
  Categories: "推薦品類",
  Products: "產品列表",
  OptionNotAvailable: "暫不可選",
};
