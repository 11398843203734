export default {
  Messages: "Messages",
  CurrentOrder: "Current Order",
  OrderHistory: "Order History",
  Review: "Review",
  YouDoNotHaveAnyMessageToShow: "You do not have any message to show.",
  YouHaveNotPLaceAnyOrderYetPeriod: "You haven't place any order yet.",
  ViewOrderDetails: "View Order Details",
  ThereIsNoHistoricalOrder:
    "You have not placed an order yet, there is no historical order, go and pick the right product!",
  ToSpeedUptheCheckOut: `To speed up the checkout, to add another address, simply click on "Add New Address", fill out the form, and click the "Save" button.If you want to edit or delete any of your existing addresses, just click the appropriate link for your existing address.`,
  More: "More",
  Binding: "Binding",
  BindingReferenceCode: "Binding Reference Code",
  ChangePassword: "Update password",
  ReferenceCodeColon: "Reference Code: ",
  PersonalInformation: "Personal information",
  FirstName: "First Name",
  LastName: "Last Name",
  Gender: "Gender",
  DateOfBirth: "Date of Birth",
  Email: "E-mail",
  CellphoneNumber: "Cellphone Number",
  CurrentPassword: "Current Password",
  NewPassword: "New Password",
  ConfirmPassword: "Confirm",
  Password: "Password",
  PleaseInputValidDateWithFormat:
    "Please input valid date with format 'XXXX-XX-XX'",
  PasswordDoesNotMatch: "Password does not match",
  BindingSuccessed: "Binding success",
  UnbindingSuccessed: "Unbinding success",
  TheReferenceCodeReachesTheUpperLimitOf:
    "The Reference Code reaches the upper limit of 20.",
  ReferenceCodeHasBeenBound: "Reference code has been bound, please try again.",
  InvalidReferenceCodePeriodPleaseEnterValidNumber:
    "Invalid Reference Code.  Please enter valid numbers and letters.",
  Name: "Name",
  Address: "Address",
  PhoneNumber: "Phone Number",
  Alias: "Alias",
  Organization: "Organization",
  PaymentMethod: "Payment Method",
  TotalInToal: "{total} In Total",
  OrderDetails: "Order Details",
  ConfirmTheOrder: "Confirm the Order",
  PlseaseConfirmYourOrder: "Please confirm your order.",
  CancelTheOrder: "Cancel the Order",
  AreyouConfirmToCancelYourOrder: "Are you sure to cancel your order.",
  YouCanAddUpToTwentyAddress:
    "You can add up to 20 addresses. You have added {num}.",
  AddYourAddressByClicking: 'Add your address by clicking "Add New Address".',
  AddShippingAddress: "Add Shipping Address",
  EditShippingAddress: "Edit Shipping Address",
  LineOne: "Line 1",
  LineTwo: "Line 2",
  City: "City",
  StateSlashProvince: "State/Province",
  PostalCode: "Postal Code",
  Country: "Country",
  CountryCode: "Country Code",
  Note: "Note",
  CouponSlashReferenceCode: "Coupon/Reference Code",
  EnterEmailAddress: "Enter email address",
  OrderStatus: "Order Status",
  ConsigneeInformation: "Consignee Information",
  PaymentInformaton: "Payment Infomation",
  Quotation: "Quotation",
  ReviewProduct: "Review Product",
  TotalPrice: "Total {price}",
  PriceSlashItem: "{price}/ item",
  AssemblyManualLeftParenthesisComingSoonRightParenthesis:
    "Assembly Manual(Coming Soon)",
  CustomerService: "Customer Service",
  WelcomeBack: "Welcome back",
  SignUp: "Sign Up",
  EmailVerification: "Email Verification",
  ForgotPasswordDashStepTwo: "Forgot Password - Step 2",
  ForgotPasswordDashStepOne: "Forgot Password - Step 1",
  ResetPassword: "Reset Password",
  ForgotPassword: "Forgot Password",
  InvalidEmailOrPassword: "Invalid Email or Password",
  WelcomeToMegaHome: "Welcome to MegaHome",
  AlreadyHaveAnAccountQuestionMark: "Already have an account?",
  PleaseLogin: "Please login",
  EnterThePassword: "Enter the password ",
  Industry: "Industry",
  PleaseCheckYourEmail: "Please check your email",
  IfYouDoNotReciveTheEmail:
    "If you don't revice the email in a few minutes, please check your spam folder.",

  IHaveReadAndAcceptedMegahomeTermsOfService:
    "I have read and accepted <a>MegaHome Terms of Service</a>.",
  WeHaveSentYouAConfirmationEmailEmail:
    'We have sent you a confirmation email to " <span>{email}</span> ". Please check your Email Box and confirm the account.',
  WeHaveSentYouAResetLink:
    'We have sent you a reset link via email " <span>{email}</span> ". Please check your Email Box and click the reset link.',
  DidNotRevicedEmailQuestionMark: "Did not receive Email",
  ThisEmailAddressHasAlreadyBeenRegistered:
    "This email address has already been registered, please login.",

  BackToMainMenu: "Back to Main Menu",
  Back: "Back",
  Homepage: "Homepage",
  ComingSoon: "Coming Soon",
  ShopTheBrand: "Shop the Brand",
  Account: "Account",

  SignOut: "Sign Out",
  SendAgain: "Send Again",
  BackToHomepage: "Back to Homepage",
  TermOfService: "Terms of Service",
  NextStep: "Next Step",
  MarketplaceHome: "Marketplace Home",
  Category: "Category",
  Room: "Room",
  NotAvailable: "Not Available",
  NotToDelivery: "Not To Delivery",
  ToReview: "To Review",
  ByUsingThisSiteYouAgreeToThe:
    "By using this site you agree to the use of cookies for analytics, personalised content and ads.",
  FindOutMore: "Find out more",
  Metric: "Metric",
  Imperial: "Imperial",
  Home: "Home",
  Firm: "Firm",
  ReadAll: "Read all",
  DeleteAll: "Delete all",
  AddNewAddress: "Add New Address",
  Confirm: "Confirm",
  Cancel: "Cancel",
  SetAsDefault: "Set as default",
  Default: "Default",
  Required: "Required",
  TleLengthOfTheInputExceedNumCharacters:
    "The length of the input cannot exceed {num} characters",
  TheLengthOfTheInputMustBeAtLeast:
    "The length of the input must be at least {num} characters",
  PleaseEnterAValidEmailAddressExclamationMark:
    "Please enter a valid email address",
  Loading: "Loading",
  Male: "Male",
  Female: "Female",
  Other: "Other",
  ByShopName: "By",
  Odm: "ODM",
  Oem: "OEM",
  Customization: "Customization",
  GetThePriceCommaSpecificationsCommaShipping:
    "Get the price, specifications, shipping and more information for free!",
  SignUpNow: "Sign UP",
  Quantity: "Quantity",
  Submit: "Submit",
  Collection: "Collection",
  AddToCart: "Add to Cart",
  PleaseSelectOptions: "Please select options",
  ContinueToCart: "Continue to Cart",
  DisabledPeople: "Disabled People",
  OopsCommaUploadFileExceedsMaximunSize:
    "Oops, uploaded file exceeds maximum size of 10M. Please try again.",
  OopsCommaUploadFileIsNotAValidImage:
    "Oops, uploaded file is not a valid image. Only JPG, PNG files are allowed. Please try again.",
  Cart: "Cart",
  LeftParenthesisPricePerItemRigthParenthesis: "({price} per item)",
  AddedToCart: "Added to cart",
  Specifications: "Specifications",
  Shipping: "Shipping",
  Price: "Price",
  PrintLabel: "Print Label ",
  PrintQrCode: "Print QR Code",
  YouCanSave: "You Can Save",
  Product: "Product",
  ToDo: "To Do",
  Total: "Total",
  ContactUs: "Contact Us",
  Quote: "Quote",
  RemoveFromCart: "Remove From Cart",
  SelectAll: "Select All",
  SignIn: "Sign in",
  ContinueShopping: "Continue Shopping",
  Login: "Login",

  SectionsInMegahomeMarketplace:
    "Sections in <a href='/termsofservice/#disclaimer'>MegaHome Marketplace</a>",
  ItemsInMegahomeMarketplace:
    "Items in <a href='/termsofservice/#disclaimer'>MegaHome Marketplace</a>",
  MegaHomeRecommended: "Recommended",
  Viewed: "Viewed",
  Size: "Size",
  Material: "Material",
  Color: "Color",
  Style: "Style",
  MoreFilters: "More Filters",
  Clear: "Clear",
  CountResults: "{count} Results",
  NewArrivals: "New Arrivals",
  Selected: "Selected",
  DashDashNoPreferenceDashDash: "-- No Preference --",
  DesignIdeas: "Design Ideas",
  NumIdeas: " {num} ideas",
  Hide: "Hide",
  Show: "Show",
  ConnectWithUs: "Connect With Us",
  MegaHomeUsAddress: "39055 Hastings Street, Suite 208 Fremont, CA 94538",
  WeHaveRevicedYourFeedback:
    "We have received your feedback. We will get back to you as soon as possible.",
  SendAMessage: "Send a message",
  TellUsAboutYourNeeds: "Tell us about your project",
  Inquire: "Inquire",
  Feedback: "Feedback",
  Partnership: "Partnership",
  ItHelp: "IT Help",
  Others: "Others",
  HotelVendor: "Hotel Vendor",
  Retailers: "Retailers",
  Designers: "Designers",
  Suppliers: "Suppliers",
  About: "About",
  AboutUs: "About us",
  Join: "Join",
  Marketing: "Marketing",
  Disclaimer: "Disclaimer",
  Apply: "Apply",
  Return: "Return",
  LookingForMoreStylesQuestionMark: "Looking for more styles?",
  FindMore: "Find More",
  Share: "Share My Thoughts",
  AddToCartSuccessfullyExclamationMark: "Add to Cart Successfully!",
  ProductDetails: "Product Details",
  Description: "Description",
  WarningColonLeftBracketCaliforniaPropositionSixtyFive:
    "WARNING: [California Proposition 65] Please confirm with the supplier for product safety",
  Features: "Features",
  ProductFeatures: "Product Features",
  ComponentsData: "Components Data",
  Compare: "Compare",
  Reviews: "Reviews",
  SimilarProducts: "Similar Products",
  BuyTogether: "Buy Together",
  ComparewithSimilarPorducts: "Compare with Similar Products",
  CustomerRating: "Customer Rating",
  SortByMostRecently: "Sort by Most Recently",
  SortByRating: "Sort by Rating",
  ReviewProductFromOrder: "Review Product from Order",
  PleaseReviewOurProductsComma:
    "Please review our products, what are your experiences and share them with other users.",
  AsteriskSignYouDoNotHaveFinishedOrder:
    "*You do not have finished order product to review.",
  MoreReviews: "More Reviews",
  MadeInCountry: "Made in {country}",
  PriceIncluded: "Price Included ",
  MinPeriod: "Min.",
  MaxPeriod: "Max.",
  MyShopingCart: "My Shopping Cart",
  CountItems: "{count} Items",
  PleaseConfirm: "Please Confirm",
  AreYouConfirmToRemoveTheseItems:
    "Are you confirm to remove these items from cart?",
  YouHaveItemAddedWithDifferentCurrencyType:
    "Oops, you have item(s) added with different currency type. Please only choose one currency to quote.",
  OopsExclamationMarkYouShoppingCartIsEmpty:
    "Oops! Your shopping cart is empty.",
  SignInToSeeWhatYouHaveAddedOrSaved:
    "Sign in to see what you have added or saved",
  WeHaveALotOfSpecialProductOffers:
    "We have a lot of special product offers, and you can get inspiration from our website.",
  SelectedColonCountItems: "Selected: {count} Items",
  StartFrom: "Start From",
  ContainersFittingEstimate: "Containers Fitting Estimate",
  QuoteInformation: "Quote Information",
  SubmittedExclamationMark: "Submitted！",
  SelectFromSavedAddress: "Select From Saved Address",
  WeWillGetBackToYou: "We will get back to you as soon as possible!",

  OopsExclamationMark: "Oops!",
  WeCannotFindAnyResultThatMatchYour:
    "We can't find any result that match your search for ",
  ThePageTitleStillUnderConstructionPeriod:
    "The page {title} still under construction. Thank you for your patience!",
  SometingYouMayLikeColon: "Something you may like:",
  OopsExclamationMarkCurrentFilterOptionsDoNot:
    "Oops! Current filter options do not have any result, please re-select.",
  SortAndFilter: "More Filters",
  Supplier: "Supplier",
  Shop: "Shop",
  ShopEstablishedDate: "Date of Establishment",
  ShopRecommendedProducts: "Recommended Products",
  FurnitureAndDecor: "Furniture & Decor",
  SearchInThisShop: "Search in this shop",
  Copyright: "Copyright",
  SearchItemsFromShop: "Search items from {shop}",
  Categories: "Categories",
  Products: "Products",
  OptionNotAvailable: "Option Not Available",

  New: "新",
  Discount: "折",
  Logistics: "物流",
  Service: "服务",
  SortingWay: "排序方式",
  CollectionDesignRecommendation: "搭配设计推荐",
  ServicePoint: "服务点",
  MakeAnAppointment: "预约",
  AppointmentServicePoin: "预约服务点",
  Contact: "联系方式",
  MakeAnAppointmentDate: "预约日期",
  MakeAnAppointmentTime: "预约时间",
  PleaseDonNotContactMe: "请勿主动联系我",
  RecommendedDetails: "推荐详情",
  MyMakeAnAppointment: "我的预约",
  MyWallet: "我的钱包",
  LoggedOut: "退出登陆",
  Consignee: "收货人",
  DetailedAddress: "详细地址",
  DetailedAddressInfo: "详细地址：如街道，门牌号，小区，楼栋号，单位号等",
  AddressTag: "地址标签",
  AllOrders: "全部订单",
  ForThePayment: "待付款",
  ForTheGoods: "待收货",
  ToEvaluate: "待评价",
  OrderNumber: "订单编号",
  TimeFiltering: "按时间筛选",
  PleaseEnterStartTime: "请选择开始时间",
  PleaseEnterStopTime: "请选择结束时间",
  PleaseEnterOrderNumberSearch: "请输入订单号搜索",
  ReferenceCode: "代理码",
  OrderPrice: "订单金额",
  NoAccount: "没有账号",
  LoginFromEmail: "邮箱登陆",
  LoginFromPhone: "手机登陆",
  EnterTelePhone: "输入手机号",
  PleaseEnterTelePhone: "请输入手机号",
  EnterCode: "输入验证码",
  PleaseEnterCode: "请输入验证码",
  CourierNumber: "快递单号",
  InDistribution: "配送方式",
  ProductInfomation: "商品信息",
  FreightPrice: "运费价格",
  DiscountPrice: "折扣价格",
  ActualPrice: "实付价格",

  Inventory: "库存",
  DeliveryTo: "寄送至",
  CurrentSelect: "当前选择",
  ShowOriginPrice: "显示原价",
  MineOrder: "我的订单",

  DiscountLabel: "折扣价",
  payLabel: "支付",
  Phone: "电话",
  AddYourContactorByClicking: '点击"添加联系人"按钮，添加您的联系人',

  EnterEmail: "输入邮箱",
  EnterPassword: "输入密码",
  Status: "状态",
  DefaultAddress: "默认地址",
  AddedToPurcaseList: "成功加入采购清单",

  Recommended: "综合推荐",
  NewReleases: "最新上架",
  Partenrship: "商务合作",

  DeliveryDate: "发货时间",
  LogisticsRemark: "物流说明",
  WaitSend: "待发货",
  Tenants: "商家入驻",
  Position: "职位",
  FirmName: "公司名称",
  EnterPosition: "输入职位",
  EnterFirmName: "输入公司名称",
  EligibilityForExemption: "申请限免资格",
  LetOurProfessionalAccountManagerMakeTheSolutionForYou:
    "让我们专业的客户经理为您制定解决方案",
  PleaseConfirmDelete: "确认删除？",
  Prompt: "提示",
  ConfirmTheGoods: "确认收货",
  BuyAgain: "再次购买",
  LogisticsInformation: "物流信息",
  DistributionorName: "配送员姓名",
  DistributionorPhone: "配送员电话",
  EstimatedTimeOfSrrival: "预计到达时间",
  ApplyAfterSale: "申请售后",
  ImmediatelyReview: "立即评价",
  Refund: "退款",
  ReturnGoodsRefund: "退货退款",
  AfterSaleType: "售后类型",
  AllRefund: "全部退款",
  SmallRefund: "部分退款",
  All: "全部",
  PartOfThe: "部分",
  ApplyProject: "申请项目",
  AfterSaleSeason: "申请原因",
  PleaseEnterAfterSaleSeason: "请输入申请原因",
  SubmitApply: "提交申请",
  ApplyQuantity: "申请数量",
  Credentials: "凭证",
  CredentialsNumber: "凭证({num}/{total})",
  BusinessAfterSaleStatus: "商家审核状态",
  CancelApply: "取消申请",
  PerfectInformation: "完善信息",
  BusinessPickupAddress: "商家取货地址",
  ThisAddressIsTheDoorToFoorPickUpAddressOfTheMerchant:
    "该地址是商家上门取货地址",
  Contactor: "联系人",
  PleaseEnter: "请输入",
  ConfirmAddress: "确认地址",
  BusinessAddress: "商家地址",
  ContactPhoneNumber: "联系电话",
  ShippingAddress: "收货地址",
  ThisAddressIsTheAddressYouDendBackToTheMerchant: "该地址是你寄回给商家的地址",
  LogisticsType: "物流类型",
  Courier: "快递",
  LogisticsTypeCourierNumber: "快递/物流编号",
  LogisticsCourierFirmName: "快递/物流公司名称",
  AddPicture: "添加图片",
  ToBOrderList: "企业采购清单",
  ToBOrderHistory: "企业采购历史",
  ViewDetails: "查看详情",
  PersonalDeliveryAddress: "个人收货地址",
  EnterprisePurchasingAddress: "企业采购地址",
  EnterprisePurchasingContactor: "企业采购联系人",
  ImmediatelyQuote: "立即询价",
  PleaseFillInTheContactInformation: "请填写联系方式",
  ShareListing: "分享清单",
  PrintListing: "打印清单",
  ExportListing: "导出清单",
  SearchItems: "搜索商品",
  PriceInfo: "价格信息",
  SelectedNumber: "已选商品数",
  PriceTotal: "总价",
  BackToList: "返回列表",
  StoreCustomerService: "店铺客服",
  CustomerServiceTime: "客服时间",
  PlatformCustomerService: "平台客服",
  ViewAfterSaleDetails: "查看售后详情",
  AfterSale: "售后",
  HidingPersonalInfomation: "隐藏个人信息",
  HidingPriceInfomation: "隐藏价格信息",
  ShareOrder: "分享订单",
  PaySuccess: "支付成功",
  PageVill: "页面将在",
  ThenLink: "后跳转",
  ShippingInfo: "收货信息",
  AddContactor: "添加联系人",
  YouCanAddUpToTwentyContactor:
    "您最多可以添加20个联系人。 您已添加{num}个联系人",
  PleaseSelectUserContact: "请选择联系方式",
  BackToOrderDetails: "返回订单详情",
  OriginPrice: "原价",
  StayTuned: "敬请期待",
  ApplyAmount: "申请金额",
  ThereIsNoOrder: "还没有订单，快去看看商品吧！",
  AgentInformation: "代理信息",
  AsAgent: "成为代理",
  YouAreNotAnAgentNowPleaseBecomeOneSoon: "您现在还不是代理, 请快成为代理吧!",
  UpdateInformation: "更新信息",
  AgentCertification: "代理认证",
  IdCardNumber: "身份证号",
  IdCardPhoto: "身份证照片",
  UploadImg: "上传图片",
  PhotoPage: "照片面",
  NationalEmblemPage: "国徽面",
  Handheld: "手持",
  RecommendedCode: "推荐码",
  AgainCertification: "重新认证",
  PaymentAccount: "收款账户",
  AgentCode: "代理码",
  PlatformAgent: "平台代理",
  ShopAgent: "品牌代理",
  CompletionInformation: "补全信息",
  PleaseEnterIdCardNumber: "请输入身份证号",
  MyTakeList: "我的带单",
  UploadFileSizeCannotExceedNumberMb: "上传文件大小不能超过{number}MB!",
  Order: "订单",
  Brand: "品牌",
  Invitation: "邀请",
};
