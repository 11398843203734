import * as React from "react";
import Layout from "../Layout";
import Content from "./Content";
import { RootState } from "../../store/configureStore";
import { useSelector } from "react-redux";

const MegaBook: React.FC = () => {
  const {
    breadcrumbs = [
      {
        url: "/",
        title: "首页",
      },
      {
        url: "/account",
        title: "个人中心",
      },
      {
        url: "/account/megabook",
        title: "收藏",
      },
    ],
  } = useSelector((state: RootState) => state.MegaBookPage);

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Content />
    </Layout>
  );
};

export default MegaBook;
