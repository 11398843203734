import { createModel } from "@rematch/core";
import Shop, { AgentRate } from "../models/Shop";
import { GraphqlQuery, GraphqlMutation } from "../helpers/request.helper";

export type AgentRateState = AgentRate[];

export default createModel({
  state: [] as AgentRateState,
  reducers: {
    initList: (state: AgentRateState, collection: AgentRateState) => {
      return collection || [];
    },
  },
  effects: {
    async getAgentRateAsync(payload) {
      const { shopIds } = payload;
      const data = await GraphqlQuery(
        "AgentRate",
        new Shop({}),
        { shop_id: shopIds },
        "AgentRate",
      );

      this.initList(data);
    },
  },
});
