import { createModel } from "@rematch/core";
import { Order, OrderTotal, TobOrderDetails } from "../../models/Order";
import { getFirstItem } from "../../helpers/modelHelpers";
import {
  GraphQLRequest,
  GraphqlQuery,
  GraphqlMutation,
} from "../../helpers/request.helper";
import { ShortUrl } from "../../models/ShortUrl";

export interface ToBOrderHistoryType {
  breadcrumbs: { url: string; title: string }[];
  orderList: Order[];

  orderCount: number;
}

export default createModel({
  state: {} as ToBOrderHistoryType,
  reducers: {
    INIT_DATA: (state: ToBOrderHistoryType, orderList, orderCount) => ({
      ...state,
      orderList,
      orderCount,
    }),
    setDetail: (state: ToBOrderHistoryType, detailData) => ({
      ...state,
      detailData,
    }),
  },
  effects: {
    async setOrderData(payload) {
      const {
        page = 1,
        action = "all",
        startTime,
        stopTime,
        orderNumber,
      } = payload;
      const data = new Order({
        page: page,
        action: action,
        start_time: startTime,
        end_time: stopTime,
        order_number: orderNumber,
      });
      const datas = new OrderTotal({
        action: action,
        start_time: startTime,
        end_time: stopTime,
        order_number: orderNumber,
      });
      const res = await GraphqlQuery("TobOrders", data, {}, "toborder");
      const total = await GraphqlQuery("TobOrderTotal", datas);

      this.INIT_DATA(res, orderNumber ? 1 : total[0].total);
    },
    async setOrderDetails(payload) {
      const { tobOrderId } = payload;
      const data = new TobOrderDetails({ tob_order_id: tobOrderId });
      const res = await GraphqlQuery("TobOrders", data);
      this.setDetail(getFirstItem(res));
    },
    async getShortUrl(payload) {
      const { url, callback } = payload;
      const model = new ShortUrl(url);
      const data = await GraphqlQuery("ShortUrl", model);
      callback && callback(data);
    },
    async getShareUrl(payload) {
      const { callback, share_data, expired_at, data_id } = payload;
      const res = await GraphqlMutation("Share", {
        action: "share",
        share_type: "toborder",
        data_id: data_id,
        share_data: share_data,
        expired_at: expired_at,
      });
      callback && callback(res);
    },
  },
});
