import * as React from "react";
import { PrimaryButton } from "../../wrappers/button";
import * as styles from "./index.scss";
import { useCountDown, useUpdateEffect } from "ahooks";

const VerifyEmailContent: React.FC<{
  classNamePrefix?: "" | "mobile";
  render: () => React.ReactNode;
  href: string;
}> = ({ classNamePrefix = "", render, href }) => {
  const [countdown] = useCountDown({
    targetDate: Date.now() + 1000 * 3,
  });

  const countdownSecond = Math.round(countdown / 1000);

  useUpdateEffect(() => {
    if (countdownSecond === 0) {
      window.location.href = href;
    }
  }, [countdownSecond]);

  return (
    <div className={styles["VerifyEmail"]}>
      <div className={styles[`${classNamePrefix}VerifyEmail-title`]}>
        {render()}
      </div>
      <div className={styles[`${classNamePrefix}VerifyEmail-tint`]}>
        <p>
          Redirecting to log in page in{" "}
          <span className={styles["VerifyEmail-time"]}>
            {countdownSecond}s...
          </span>
        </p>
        <PrimaryButton
          onClick={() => (window.location.href = "/")}
          className={styles[`${classNamePrefix}VerifyEmail-button`]}
          bgColor="#faad14"
        >
          Redirect Now
        </PrimaryButton>
      </div>
    </div>
  );
};

export default VerifyEmailContent;
