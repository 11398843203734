import { createModel } from "@rematch/core";
import { GraphqlQuery } from "../../helpers/request.helper";
import { DesignIdeaList, DesignIdea } from "../../models/DesignIdea";
import head from "lodash/head";

export interface HomePage {
  BannerData: {
    key: string;
    title: string;
    child: { title: string; picture: string; link: string }[];
  };
  StyleData: {
    key: string;
    title: string;
    child: { title: string; picture: string; link: string }[];
  };
  DesignIdeaData: {
    key: string;
    title: string;
    child: DesignIdea[];
  };
  CategoryData: {
    key: string;
    title: string;
    child: { title: string; picture: string; link: string }[];
  };
  RoomData: {
    key: string;
    title: string;
    child: { title: string; picture: string; link: string }[];
  };

  PartnerData: {
    key: string;
    title: string;
    child: { title: string; picture: string; link: string }[];
  };
  FeatureData: {
    key: string;
    title: string;
    child: { title: string; key: string; icon: string; description: string }[];
  };
  ActiveData: {
    key: string;
    title: string;
    child: { title: string; key: string; icon: string; picture: string }[];
  };
}
export default createModel({
  state: {
    BannerData: {
      key: "banner",
      title: "Banner",
      child: [
        {
          title: "Banner",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/index/web/banner/banner.jpg",
          link: "www.baidu.com",
        },
        {
          title: "Banner",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/gaea/system/activity/201019/09-5f8cf14fb5bca.jpg",
          link: "www.baidu.com",
        },
        {
          title: "Banner",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/index/web/banner/banner.jpg",
          link: "www.baidu.com",
        },
      ],
    },
    DesignIdeaData: { key: "design", title: "搭配设计", child: [] },
    StyleData: {
      key: "style",
      title: "风格",
      child: [
        {
          title: "Nordic",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/style/home-nordic.jpg",
          link:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/style/home-nordic.jpg",
        },
        {
          title: "Nordic",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/style/home-nordic.jpg",
          link: "",
        },
        {
          title: "Nordic",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/style/home-nordic.jpg",
          link: "",
        },
      ],
    },
    CategoryData: {
      key: "category",
      title: "品类分类",
      child: [
        {
          title: "Sofas",
          link: "/product-list/furniture/category/sofa/index.html",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/furniture/home-sofas.jpg",
        },
        {
          title: "Beds",
          link: "/product-list/furniture/category/bed/index.html",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/furniture/home-beds.jpg",
        },
        {
          title: "Tables",
          link: "/product-list/furniture/category/table-desks/index.html",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/furniture/home-tables.jpg",
        },
        {
          title: "Lights",
          link: "/product-list/furniture/category/light/index.html",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/furniture/home-lights.jpg",
        },
        {
          title: "Cabinets",
          link: "/product-list/furniture/category/cabinet/index.html",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/furniture/home-cabinets.jpg",
        },
        {
          title: "Decorations",
          link: "/product-list/furniture/category/decor/index.html",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/furniture/home-decorations.jpg",
        },
        {
          title: "Doors",
          link: "/product-list/furniture/category/door/index.html",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/furniture/home-doors.jpg",
        },
        {
          title: "Bath",
          link: "/product-list/furniture/category/bath/index.html",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/furniture/home-bath.jpg",
        },
        {
          title: "Chairs",
          link: "/product-list/furniture/category/chair/index.html",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/furniture/home-chairs.jpg",
        },
        {
          title: "More",
          link: "/product-list/furniture/index.html",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/furniture/home-more.jpg",
        },
      ],
    },
    RoomData: {
      key: "room",
      title: "房间分类",
      child: [
        {
          title: "Bedroom",
          link: "/product-list/furniture/room/bedroom/index.html",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/room/home-bedroom.png",
        },
        {
          title: "Living Room",
          link: "/product-list/furniture/room/living-room/index.html",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/room/home-livingroom.png",
        },
        {
          title: "Kitchen",
          link: "/product-list/furniture/room/kitchen/index.html",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/room/home-kitchen.png",
        },
        {
          title: "Dining Room",
          link: "/product-list/furniture/room/dining-room/index.html",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/room/home-diningroom.png",
        },
        {
          title: "Bathroom",
          link: "/product-list/furniture/room/bathroom/index.html",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/room/home-bathroom.png",
        },
        {
          title: "Study",
          link: "/product-list/furniture/room/study/index.html",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/room/home-study.png",
        },
        {
          title: "Closet",
          link: "/product-list/furniture/room/closet/index.html",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/room/home-closet.png",
        },
        {
          title: "Outdoor",
          link: "/product-list/furniture/room/outdoor/index.html",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/room/home-outdoor.png",
        },
        {
          title: "Outdoor",
          link: "/product-list/furniture/room/outdoor/index.html",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/room/home-outdoor.png",
        },
        {
          title: "Outdoor",
          link: "/product-list/furniture/room/outdoor/index.html",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/room/home-outdoor.png",
        },
      ],
    },
    PartnerData: {
      key: "Partner",
      title: "赞助合作伙伴儿",
      child: [
        {
          title: "Megahome",
          link: "/product-list/furniture/room/bedroom/index.html",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/megahomeus/pc/index-showroom/1.png",
        },
        {
          title: "Megahome",
          link: "/product-list/furniture/room/living-room/index.html",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/megahomeus/pc/index-showroom/1.png",
        },
        {
          title: "Megahome",
          link: "/product-list/furniture/room/kitchen/index.html",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/megahomeus/pc/index-showroom/1.png",
        },
        {
          title: "Megahome",
          link: "/product-list/furniture/room/dining-room/index.html",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/megahomeus/pc/index-showroom/1.png",
        },
      ],
    },
    FeatureData: {
      key: "feture",
      title: "Feature",
      child: [
        {
          key: "QualityAssurance",
          title: "免费送货",
          description: "满 ¥500.00，运费我们承担",
          icon: "icontezhengtupian",
        },
        {
          key: "QualityAssurance",
          title: "最高60%的折扣",
          description: "在我们的限时活动中享受折扣",
          icon: "icontezhengtupian",
        },
        {
          key: "QualityAssurance",
          title: "一切为了您的家",
          description: "购买我们的家居装饰和家具让您的家更美好,让您的家更美好",
          icon: "icontezhengtupian",
        },
        {
          key: "QualityAssurance",
          title: "节省更多的钱",
          description: "收单使用信用卡支付可以节省,超过2500元",
          icon: "icontezhengtupian",
        },
      ],
    },
    ActiveData: {
      key: "active",
      title: "Active",
      child: [
        {
          title: "有所",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/index/web/banner/banner.jpg",
          link: "www.baidu.com",
        },
        {
          title: "Banner",
          picture:
            "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/index/web/banner/banner.jpg",
          link: "www.baidu.com",
        },
      ],
    },
  } as HomePage,
  reducers: {
    setDesignIdea(state, designs) {
      return {
        ...state,
        DesignIdeaData: { ...state.DesignIdeaData, child: designs.list },
      };
    },
  },
  effects: {
    async getDesignIdeaAsync() {
      const list = await GraphqlQuery("DesignIdea", new DesignIdeaList({}), {
        page: 1,
        pageSize: 4,
        type: "homepage",
      });

      if (list.length > 0) {
        this.setDesignIdea(head(list));
      }
    },
  },
});
