import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, Dispatch } from "../../../store/configureStore";
import AutoImage from "../../../components/image";
import { PrimaryButton } from "../../../wrappers/button";
import Modal from "../../../wrappers/modal";
import Icon from "../../../components/icon";
import {
  Input,
  Slider,
  Row,
  Col,
  Collapse,
  List,
  Popover,
  message as Messages,
} from "antd";
import ColorPicker from "../../../wrappers/color-picker";
import ColorButton from "../../../static/img/Color.png";
import { getBase64, dataURItoBlob } from "../../../helpers/modelHelpers";
import { useTheme } from "styled-components";
import styles from "../style.scss";

const DesignerSetting = (props: {
  settings: any;
  render: any;
  setCurrResolution: (
    value: React.SetStateAction<{
      device: string;
      ratio: string;
      name: string;
      width: number;
      height: number;
    }>,
  ) => void;
}) => {
  const theme = useTheme();
  const { settings, render, setCurrResolution } = props;
  const dispatch = useDispatch<Dispatch>();
  const uploadFile = useRef<HTMLInputElement>(null);
  const { bgImg = "" } = useSelector((state: RootState) => state.DesignIdea);
  const { root_id } = useSelector((state: RootState) => state.URLSearch);

  useEffect(() => {
    if (settings) {
      dispatch.DesignIdea.setBgImage(settings["background-img"]);
      setColor(settings["background-color"]);
      setDeformationValue(settings.reverseDegree);
      setRotatingValue(settings.rotateDegree);
      setDefaultImg(settings["background-img"]);
    }
  }, [settings]);

  const [searchTypeVisiable, setSearchTypeVisiable] = useState(false);
  const [color, setColor] = useState("#fff");
  const [rotatingValue, setRotatingValue] = useState(0);
  const [deformationValue, setDeformationValue] = useState(0);

  const [isOpen, setIsOpen] = useState(false);
  const [userMaterial, setUserMaterial] = useState<any[]>([]);
  const [systemMaterial, setSystemMaterial] = useState<any[]>([]);
  const [uploadType, setUploadType] = useState("");
  const [clickIndex, setClickIndex] = useState(-1);
  const [defaultImg, setDefaultImg] = useState("");

  const handleFileUpdate = (event: any) => {
    const file = event.target.files[0];
    // if (
    //   !["image/jpeg", "image/png", "image/gif", "image/jpg"].includes(file.type)
    // ) {
    //   return Messages.error("请上传正确的图片类型");
    // }
    if (file.size / 1024 / 1024 > 5) {
      return Messages.error("上传图片最大为5m");
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    // tslint:disable-next-line:only-arrow-functions
    reader.onloadend = function (e) {
      if (e) {
        dispatch.DesignIdea.setBgImage(e.target.result);
        setSearchTypeVisiable(false);
      }
    };
  };

  const handleShowModal = (type: string) => {
    setUploadType(type);
    if (type === "system" && systemMaterial.length === 0) {
      dispatch.DesignIdea.getSystemPictureAsync({
        picture_tag_id: 3,
        callback: (res: any) => {
          setSystemMaterial(res);
          setIsOpen(true);
        },
      });
    } else if (type === "user") {
      dispatch.DesignIdea.getUpdatedPictureAsync({
        root_id,
        picture_tag_id: -2,
        callback: (res: any) => {
          setUserMaterial(res);
          setIsOpen(true);
        },
      });
    } else {
      setIsOpen(true);
    }
    setSearchTypeVisiable(false);
  };

  const searchUploadTypeContent = () => {
    return (
      <div className={styles["designer-setting-popover"]}>
        <div
          className={styles["designer-setting-useroption"]}
          onClick={() => {
            uploadFile.current.click();
          }}
        >
          本地上传
        </div>
        <div
          className={styles["designer-setting-option"]}
          onClick={() => handleShowModal("system")}
        >
          平台素材上传
        </div>
        <div
          className={styles["designer-setting-option"]}
          onClick={() => handleShowModal("user")}
        >
          个人素材上传
        </div>
        <input
          style={{ display: "none" }}
          ref={uploadFile}
          name="update"
          type="file"
          id="input"
          accept="image/*"
          onChange={handleFileUpdate}
        />
        <Modal
          visible={isOpen}
          onCancel={() => setIsOpen(false)}
          width={800}
          color={theme.designPrimaryColor}
          style={{ top: 30 }}
          footer={[
            <PrimaryButton
              key="submit"
              bgColor={theme.designPrimaryColor}
              onClick={() => {
                if (clickIndex === -1) {
                  dispatch.DesignIdea.setBgImage("");

                  setIsOpen(false);
                } else {
                  const data =
                    uploadType === "system" ? systemMaterial : userMaterial;
                  dispatch.DesignIdea.setBgImage(data[clickIndex].picture_url);

                  setIsOpen(false);
                }
              }}
            >
              确定
            </PrimaryButton>,
          ]}
          title="背景选择"
          bodyStyle={{ padding: "10px 26px", maxHeight: 500, overflow: "auto" }}
        >
          <List
            grid={{ gutter: 10, column: 5 }}
            dataSource={uploadType === "system" ? systemMaterial : userMaterial}
            renderItem={(item: any, index: number) => (
              <List.Item
                style={{
                  cursor: "pointer",
                  borderRadius: 5,
                  border:
                    index === clickIndex
                      ? "2px solid #4B27B6"
                      : "1px solid #EAEAEA",
                }}
                onClick={() => {
                  if (clickIndex === index) {
                    setClickIndex(-1);
                  } else {
                    setClickIndex(index);
                  }
                }}
              >
                <AutoImage
                  width={1}
                  height={1}
                  src={item.picture_url}
                ></AutoImage>
              </List.Item>
            )}
          ></List>
        </Modal>
      </div>
    );
  };

  let backgroundImg;
  const handleSumbit = () => {
    const isBase = handleIsBase(bgImg);
    if (isBase) {
      dispatch.DesignIdea.handleUploadImg({
        root_id,
        base64: bgImg,
        file: dataURItoBlob(bgImg),
        callback: (url: string) => {
          backgroundImg = new Image();
          backgroundImg.src = url + "?" + new Date().getTime();
          backgroundImg.crossOrigin = "";
          backgroundImg.onload = () => {
            render?.updateDataSet({
              "background-img": backgroundImg,
              "background-color": color,
              // tslint:disable-next-line:object-literal-key-quotes
              rotateDegree: rotatingValue,
              // tslint:disable-next-line:object-literal-key-quotes
              reverseDegree: deformationValue,
            });

            setCurrResolution(r => ({
              ...r,
              width: backgroundImg.width,
              height: backgroundImg.height,
            }));

            render?.restore();
          };
        },
      });
    } else {
      if (bgImg === "") {
        render?.updateDataSet({
          "background-img": null,
          "background-color": color,
          // tslint:disable-next-line:object-literal-key-quotes
          rotateDegree: rotatingValue,
          // tslint:disable-next-line:object-literal-key-quotes
          reverseDegree: deformationValue,
        });
        render?.restore();
      } else {
        backgroundImg = new Image();
        backgroundImg.src = bgImg + "?" + new Date().getTime();
        backgroundImg.crossOrigin = "";
        backgroundImg.onload = () => {
          render?.updateDataSet({
            "background-img": bgImg === "" ? null : backgroundImg,
            "background-color": color,
            // tslint:disable-next-line:object-literal-key-quotes
            rotateDegree: rotatingValue,
            // tslint:disable-next-line:object-literal-key-quotes
            reverseDegree: deformationValue,
          });

          setCurrResolution(r => ({
            ...r,
            width: backgroundImg.width,
            height: backgroundImg.height,
          }));

          render?.restore();
        };
      }
    }
  };

  const handleIsBase = (data: string) => {
    if (data.indexOf("data:") !== -1 && data.indexOf("base64") !== -1) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={styles["designer-setting"]} style={{ marginTop: "10px" }}>
      <Collapse expandIconPosition="right" defaultActiveKey={["1"]}>
        <Collapse.Panel header="画布背景" key="1">
          <div className={styles["designer-setting-content"]}>
            <div className={styles["designer-setting-box"]}>
              <div className={styles["designer-setting-box-title"]}>
                背景颜色
              </div>

              <ColorPicker value={color} onChange={setColor}>
                <a>
                  <img src={ColorButton} />
                </a>
              </ColorPicker>
            </div>

            <div className={styles["designer-setting-box"]}>
              <div className={styles["designer-setting-box-title"]}>
                旋转速度
              </div>
              <Slider
                className={styles["designer-setting-box-input"]}
                step={0.01}
                min={1}
                max={100}
                onChange={(val: number) => setRotatingValue(val)}
                value={typeof rotatingValue === "number" ? rotatingValue : 0}
              />
              <div className={styles["designer-setting-box-inputright"]}>
                <Input
                  value={rotatingValue}
                  onChange={e => setRotatingValue(Number(e.target.value))}
                ></Input>
              </div>
            </div>
          </div>
          <div className={styles["designer-setting-content"]}>
            <div
              className={styles["designer-setting-box"]}
              style={{ marginRight: 5 }}
            >
              <div className={styles["designer-setting-box-title"]}>
                背景图片
              </div>
              <div className={styles["designer-setting-imgbox"]}>
                {bgImg !== "" && (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <AutoImage width={1} height={1} src={bgImg}></AutoImage>
                  </div>
                )}
                {bgImg === "" && <>暂无图片</>}

                <Popover
                  content={searchUploadTypeContent()}
                  trigger="click"
                  placement="bottom"
                  visible={searchTypeVisiable}
                  onVisibleChange={val => {
                    setSearchTypeVisiable(val);
                  }}
                  getPopupContainer={triggerNode => triggerNode.parentElement!}
                >
                  <PrimaryButton
                    icon={<Icon type="iconfuwei"></Icon>}
                    shape="circle"
                    size="small"
                    onClick={() => {
                      setSearchTypeVisiable(true);
                    }}
                    style={{
                      position: "absolute",
                      right: "35px",
                      bottom: "5px",
                    }}
                    bgColor={theme.designPrimaryColor}
                  ></PrimaryButton>
                </Popover>

                <PrimaryButton
                  icon={<Icon type="iconshanchulajitong"></Icon>}
                  shape="circle"
                  size="small"
                  onClick={() => {
                    setClickIndex(-1);
                    dispatch.DesignIdea.setBgImage(defaultImg);
                  }}
                  style={{
                    position: "absolute",
                    right: "5px",
                    bottom: "5px",
                  }}
                  bgColor={theme.designPrimaryColor}
                ></PrimaryButton>
              </div>
            </div>

            <div className={styles["designer-setting-box"]}>
              <div className={styles["designer-setting-box-title"]}>
                变形速度
              </div>
              <Slider
                className={styles["designer-setting-box-input"]}
                min={1}
                max={100}
                onChange={(val: number) => setDeformationValue(val)}
                value={
                  typeof deformationValue === "number" ? deformationValue : 0
                }
              />
              <div className={styles["designer-setting-box-inputright"]}>
                <Input
                  value={deformationValue}
                  onChange={e => setDeformationValue(Number(e.target.value))}
                ></Input>
              </div>
            </div>
          </div>
          <Row>
            <Col style={{ textAlign: "right" }} span={24}>
              <PrimaryButton
                onClick={() => handleSumbit()}
                bgColor={theme.designPrimaryColor}
              >
                确认
              </PrimaryButton>
            </Col>
          </Row>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default DesignerSetting;
