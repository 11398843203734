import { GraphqlField, GraphqlRefField } from "../helpers/decorators";
import BaseModel from "./BaseModel";
import DeliveryScope from "./Delivery";

export default class ProviderProduct extends BaseModel {
  @GraphqlField("default", "Provider")
  public provider_id: number; //服务店id

  @GraphqlField()
  public tag_price_name: string; //标签价
  @GraphqlField()
  public tag_price: number; //标签价
  @GraphqlField()
  public tag_price_value: string; //标签价
  @GraphqlField()
  public original_price: number; //原件（划线价）
  @GraphqlField()
  public original_price_value: string; //原件（划线价）
  @GraphqlField("default", "Provider")
  public address: string; //地址
  @GraphqlField()
  public inventory_quantity: number; //库存数量
  @GraphqlField("default", "Provider")
  public provider_name: string; //服务店名称
  @GraphqlField("default", "Provider")
  public business_start_time: string; //早
  @GraphqlField("default", "Provider")
  public business_end_time: string; //晚
  @GraphqlField("default", "Provider")
  public provider_logo: string; //LOGO
  @GraphqlField()
  public has_customer_experience: boolean; //是否可预约 true是 false否
  @GraphqlField()
  public city: string; //城市
  @GraphqlField()
  public longitude: string; //经度
  @GraphqlField()
  public latitude: string; //纬度

  @GraphqlField()
  public product_moq: number;

  @GraphqlField()
  public logistics_delivery_text: string;

  @GraphqlField()
  public logistics_remark: string;
  @GraphqlField()
  public provider_url: string;

  @GraphqlField("Provider")
  public main_scope: string; // 主营

  @GraphqlField("Provider")
  public provider_introduction: string; // 简介

  @GraphqlField("Provider")
  public established_date: string; // 成立日期

  @GraphqlRefField(DeliveryScope)
  public delivery_info: DeliveryScope[] = [];
  /**
   *
   */
  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}
