import HomePage from "../pages/Home/models";
// import FAQPage from "../pages/FAQ/models";
import PDPPage from "../pages/ProductDetails/models";
import Cart from "../pages/Cart/rematch";
import User from "../pages/LogInSignUp/models";
import PLPage from "../pages/ProductList/models";
import ShopPage from "../pages/Shop/models";
// import AccountPage from "../pages/Account/models";
import AccountPage from "../pages/UserCenter/rematch";
import MessagePage from "../pages/Message/models";
import AddressPage from "../pages/Address/models";
import OrderDetailsPage from "../pages/OrderDetails/models";
import OrderHistoryPage from "../pages/OrderHistory/model";
import MegaBookPage from "../pages/MegaBook/models";
import IdeaList from "../pages/IdeaList/models";
import IdeaDetalis from "../pages/IdeaDetalis/models";
// import UnsubEmail from "../pages/UnsubscribeEmail/models";
import URLSearch from "./URLSearch";
import Navigation from "../pages/Header/models";
import Locale from "./Locale";
import Theme from "./Themify";
import ThemedShop from "./ThemedShop";
import CommonData from "./Common";
// import ContactUser from "../pages/ContactUs/models";
import FooterPage from "../pages/Footer/models";
import SystemPage from "../pages/Alone/models";
import ReservationPage from "../pages/Reservation/models";
import AfterSalePage from "../pages/AfterSale/models";
import ToBOrderHistoryPage from "../pages/ToBOrderHistory/models";
import Collections from "./Collection";
import ProductClassification from "./ProductClassification";
import DesignIdea from "./DesignIdea";
import ToBCart from "../pages/ToBCart/models";
import ShareCart from "../pages/ToBCart/ShareCart/models";
import ShareTobOrder from "../pages/ToBOrderHistory/ShareOrder/models";
import PayResultPage from "../pages/PayResult/models";
import ToBOrderDetailsPage from "../pages/ToBOrderHistory/OrderDetails/models";
import AgentRate from "./AgentRate";
import CommissionPage from "../pages/Commission/models";
import WithdrawalPage from "../pages/Withdrawal/models";
import UserDesignPage from "../pages/UserDesign/models";
import RecommendPage from "../pages/Recommend/models";
import VerifyPage from "../pages/VerifyAgent/models";
import ProviderPage from "../pages/Provider/models";
import ThemeShopCategory from "./ThemeShopCategory";
import ShopActivityPage from "../pages/ShopActivity/models";

export default {
  HomePage,
  Navigation,
  // FAQPage,
  PDPPage,
  Cart,
  User,
  PLPage,
  AccountPage,
  MessagePage,
  AddressPage,
  OrderDetailsPage,
  OrderHistoryPage,
  MegaBookPage,
  IdeaList,
  IdeaDetalis,
  // UnsubEmail,

  URLSearch, // url参数
  Locale, // 多语言
  Theme,

  ShopPage,
  // ContactUser,
  ThemedShop,
  CommonData,
  FooterPage,
  SystemPage,
  ReservationPage,
  AfterSalePage,
  ToBOrderHistoryPage,
  ToBCart,
  Collections,
  ProductClassification,
  DesignIdea,
  ShareCart,
  ShareTobOrder,
  PayResultPage,
  ToBOrderDetailsPage,
  AgentRate,
  CommissionPage,
  WithdrawalPage,
  UserDesignPage,
  RecommendPage,
  VerifyPage,
  ProviderPage,
  ThemeShopCategory,
  ShopActivityPage,
};
