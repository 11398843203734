import React from "react";
import split from "lodash/split";
import trimStart from "lodash/trimStart";

const Highlight: React.FC<{
  seperator?: string;
  text: string;
  fontSize?: string;
  highlightFontSize?: string;
}> = ({
  seperator = ".",
  text,
  fontSize = "",
  highlightFontSize = "1.2em",
}) => {
  // const target = Number(trimStart(text, "$")).toFixed(2);

  const contents = split(text, seperator);

  if (contents.length > 1)
    return (
      <>
        <span style={{ fontSize: highlightFontSize }}>{contents[0]}</span>
        <span style={{ fontSize }}>{seperator + contents[1]}</span>
      </>
    );

  return <span>{text}</span>;
};

export default Highlight;
