import { GraphqlField } from "../helpers/decorators";

export class ShortUrl {
  @GraphqlField()
  public short_url: number;

  /**
   *
   */
  constructor(public url: string) {}
}

export class Agent {
  @GraphqlField()
  public agent_code: number;

  /**
   *
   */
  constructor(public product_id: number, public reference_codes: string) {}
}

export class ProductShareUrl {
  @GraphqlField()
  public picture: string;
}
