import React, { useContext, useEffect, useRef, useCallback } from "react";
import { RootState, Dispatch } from "../../store/configureStore";
import { useSelector, useDispatch } from "react-redux";
import { CommonProps } from "../../typings/component";
import Image from "../../components/image";
import classNames from "classnames";

import * as styles from "./index.scss";
import { Product } from "../../models/Product";
import { useIntl } from "react-intl";
import { BusinessContext } from "../BusinessContext";
import Icon from "../../components/icon";
import Button, { PrimaryButton } from "../../wrappers/button";
import Card from "../../wrappers/card";
import Rate from "../../wrappers/rate";
import { message as Messages, Space } from "antd";
import replaceState from "../../helpers/reolaceState";
import { useTheme } from "styled-components";

const ProductCompareComp = (
  props: CommonProps & {
    allowDelete?: boolean;
    handleClose?: () => void;
    dataSource?: "localStorage" | "serverPull";
  },
) => {
  const { isMobile, allowDelete = false } = props;
  const { formatMessage: f } = useIntl();
  const { primaryColor, secondaryColor, bussinessPrimaryColor } = useTheme();

  const { isToBusiness } = useContext(BusinessContext);

  const { isLogin = true } = useSelector((state: RootState) => state.User);

  const { Collections: collection = [] } = useSelector(
    (state: RootState) => state,
  );

  const { productComparison: products = [], selectedProvider } = useSelector(
    (state: RootState) => state.PDPPage,
  );

  const { isPrivate } = useSelector((state: RootState) => state.CommonData);

  const { pid, host_id } = useSelector((state: RootState) => state.URLSearch);

  // const products: Product[] =
  //   props.dataSource === "serverPull"
  //     ? recommendProducts.length > 0
  //       ? [
  //           {
  //             sku_id,
  //             shop_id,
  //             ...(productList.find(sp => sp.shop_product_id === Number(pid)) ||
  //               {}),
  //             ...productReviewStatistic,
  //             ...productDetails,
  //           },
  //           ...recommendProducts,
  //         ]
  //       : []
  //     : // recommendProducts
  //       productComparison;

  const { PDPPage: rematch } = useDispatch<Dispatch>();
  const dispatch = useDispatch<Dispatch>();

  const toggleUserLike = useCallback(
    (p: Product) => {
      dispatch.Collections.submitCollectAsync({
        action: collection.includes(p.shop_product_id) ? "cancel" : "like",
        object: "product",
        data_id: p.shop_product_id,
      });
    },
    [collection],
  );

  const handleAddToCart = useCallback(
    async (product: Product) => {
      await rematch.addToCartAsync({
        shop_product_id: product.shop_product_id,
        quantity: 1,
        provider_id: selectedProvider.provider_id,
        private: isPrivate,
        shop_id: host_id,
        callback: () => {
          Messages.success(
            f({ id: "AddToCartSuccessfullyExclamationMark" }),
            3,
          );
        },
      });
    },
    [selectedProvider],
  );

  const handleDelete = useCallback(
    (shop_product_id: number) => {
      if (products.length === 1) {
        props.handleClose && props.handleClose();
      }
      rematch.placeProductInContrast({
        shop_product_id,
        checked: false,
      });
    },
    [products],
  );

  const tableRef = useRef<HTMLTableElement>(null),
    tableFixedRef = useRef<HTMLTableElement>(null);

  return isLogin && products.length > 0 ? (
    <div className={styles[`ThemedProductDetails-compare`]}>
      <div
        className={styles["ProductDetails-compare-wrapper"]}
        style={{ maxHeight: allowDelete ? "80vh" : "auto" }}
      >
        <div className={styles[`ThemedProductDetails-compare-main`]}>
          <table
            style={{
              width: isMobile
                ? `${30.06667 * products.length + 25.33333}vw`
                : `${20 * products.length + 10}vw`,
            }}
            ref={tableRef}
          >
            <colgroup>
              <col className={styles["ProductDetails-compare-titlecol"]} />
              {products &&
                products.map((p: Product) => (
                  <col
                    className={styles[`ThemedProductDetails-compare-itemcol`]}
                    key={p.shop_product_id}
                  />
                ))}
            </colgroup>
            <thead className={styles["ProductDetails-compare-mediabody"]}>
              <tr>
                <td />
                {products &&
                  products.map((p: Product) => (
                    <td
                      key={p.shop_product_id}
                      className={
                        styles["ProductDetails-compare-mediabody-item"]
                      }
                    >
                      <Card
                        bordered={false}
                        size="small"
                        className="figure-multiline-card"
                        cover={
                          <a onClick={() => replaceState.linkTo(p.url)}>
                            <Image
                              src={p.product_picture}
                              width={1}
                              height={1}
                            ></Image>
                          </a>
                        }
                      >
                        <Card.Meta
                          title={p.product_name}
                          description={
                            <div
                              style={{ textAlign: "center", marginTop: "10px" }}
                            >
                              <Space>
                                <Button
                                  onClick={e => {
                                    e.stopPropagation();
                                    toggleUserLike(p);
                                  }}
                                  shape="circle"
                                  color={
                                    isToBusiness
                                      ? bussinessPrimaryColor
                                      : primaryColor
                                  }
                                  icon={
                                    <Icon
                                      type={
                                        collection.includes(p.shop_product_id)
                                          ? "icont2_shoucang1"
                                          : "iconshoucang1"
                                      }
                                    />
                                  }
                                />
                                {isLogin && (
                                  <PrimaryButton
                                    onClick={e => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                      handleDelete(p.shop_product_id);
                                    }}
                                    icon={<Icon type="icondelete" />}
                                    shape="circle"
                                    bgColor={
                                      isToBusiness
                                        ? bussinessPrimaryColor
                                        : primaryColor
                                    }
                                  />
                                )}
                                <PrimaryButton
                                  onClick={e => {
                                    e.stopPropagation();
                                    handleAddToCart(p);
                                  }}
                                  icon={<Icon type="icongouwuchexian" />}
                                  bgColor={
                                    isToBusiness
                                      ? bussinessPrimaryColor
                                      : primaryColor
                                  }
                                >
                                  {f({
                                    id: "AddToCart",
                                  })}
                                </PrimaryButton>
                              </Space>
                            </div>
                          }
                        />
                      </Card>
                    </td>
                  ))}
              </tr>
            </thead>
            <tbody className={styles["ProductDetails-compare-detailbody"]}>
              <tr>
                <td>{f({ id: "CustomerRating" })}</td>
                {products &&
                  products.map((p: Product) => (
                    <td
                      key={p.shop_product_id}
                      className={
                        styles["ProductDetails-compare-detailbody-rating"]
                      }
                    >
                      <Rate
                        allowHalf
                        value={(p.review_rating * 5) / 100}
                        disabled
                        color={
                          isToBusiness ? bussinessPrimaryColor : primaryColor
                        }
                        gutter="3px"
                      />
                      <span
                        className={
                          styles["ProductDetails-compare-detailbody-rating-num"]
                        }
                      >
                        {p.review_total}
                      </span>
                    </td>
                  ))}
              </tr>
              <tr>
                <td>{f({ id: "Price" })}</td>
                {products &&
                  products.map((p: Product) => (
                    <td
                      key={p.shop_product_id}
                      className={
                        styles["ProductDetails-compare-detailbody-price"]
                      }
                    >
                      {p.tag_price_value}
                    </td>
                  ))}
              </tr>
              <tr>
                <td>{f({ id: "Specifications" })}</td>
                {products &&
                  products.map((p: Product) => (
                    <td
                      key={p.shop_product_id}
                      className={
                        styles["ProductDetails-compare-detailbody-specific"]
                      }
                    >
                      <table>
                        <tbody>
                          {p.specification &&
                            p.specification.map((s, index) => (
                              <tr key={index}>
                                <td>{s.title}</td>
                                <td>
                                  <span
                                    style={{
                                      padding: "2px 4px",
                                      borderRadius: "5px",
                                      backgroundColor: isToBusiness
                                        ? bussinessPrimaryColor
                                        : secondaryColor,
                                    }}
                                  >
                                    {s.category_id === 1 || s.category_id === 6
                                      ? s.category_sub_name
                                      : s.attribution_value}
                                  </span>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </td>
                  ))}
              </tr>
              <tr>
                <td>{f({ id: "Description" })}</td>
                {products &&
                  products.map((p: Product) => (
                    <td
                      key={p.shop_product_id}
                      className={
                        styles["ProductDetails-compare-detailbody-feature"]
                      }
                      dangerouslySetInnerHTML={{
                        __html: p.product_description,
                      }}
                    />
                  ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  ) : (
    <div />
  );
};

export default React.memo(ProductCompareComp);
