import React, { useState, useEffect, useContext, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, Dispatch } from "../../../store/configureStore";
import * as styles from "./index.scss";
import { useIntl } from "react-intl";
import { Row, Col, Tag, Card, Space } from "antd";
import Icon from "../../../components/icon";
import Rate from "../../../wrappers/rate";
import { PrimaryButton } from "../../../wrappers/button";
import replaceState from "../../../helpers/reolaceState";
import Share from "../component/share";
import { useTheme } from "styled-components";

const ToBOrderDetails = () => {
  const dispatch = useDispatch<Dispatch>();
  const { formatMessage: f } = useIntl();
  const { bussinessPrimaryColor } = useTheme();

  const { detailData } = useSelector(
    (state: RootState) => state.ToBOrderDetailsPage,
  );

  useEffect(() => {
    // 请求企业采购清单
    const [, , , tobOrderNumber] = window.location.pathname.split("/");
    dispatch.ToBOrderDetailsPage.setOrderDetails({ tobOrderNumber });
  }, []);

  const handleGetQuantity = () => {
    if (!detailData.product || detailData.product.length === 0) return 0;
    let num = 0;
    detailData.product.map(item => {
      num = num + item.quantity;
    });
    return num;
  };

  const handleGetPrice = () => {
    if (!detailData.product || detailData.product.length === 0) return "¥0.0";
    if (detailData.product.some(n => n.has_tag_price_range === 1)) {
      // 按照区间来
      let low = 0,
        high = 0;
      detailData.product.map(item => {
        if (Boolean(item.has_tag_price_range)) {
          low = low + item.quantity * parseFloat(item.tag_price_low);
          high = high + item.quantity * parseFloat(item.tag_price_high);
        } else {
          low = low + item.quantity * parseFloat(item.tag_price);
          high = high + item.quantity * parseFloat(item.tag_price);
        }
      });
      return `¥${low} - ¥${high}`;
    } else {
      let num = 0;
      detailData.product.map(item => {
        num = num + item.quantity * parseFloat(item.tag_price);
      });
      return "¥" + num.toFixed(2);
    }
  };

  return (
    <div className={styles["ToBOrderDetails"]}>
      <Card bordered={false}>
        {detailData && (
          <Row gutter={10}>
            <Col span={18}>
              <Row gutter={10} style={{ height: "50px", lineHeight: "50px" }}>
                <Col span={12}>
                  <Space
                    size={5}
                    style={{ color: bussinessPrimaryColor, marginRight: 30 }}
                  >
                    <Icon type="icondianpu2"></Icon>
                    {detailData.shop_name}
                  </Space>

                  {detailData.created_at}
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  <Share
                    dataId={detailData.tob_order_id}
                    address={detailData.address ? detailData.address[0] : {}}
                    productData={detailData.product || []}
                  />
                </Col>
              </Row>
              {detailData.product?.map((item: any, index: number) => {
                return (
                  <Card style={{ marginBottom: "10px" }}>
                    <Row align="middle">
                      {item.agent_code && (
                        <span
                          style={{ position: "absolute", right: 20, top: 10 }}
                        >
                          {f({ id: "AgentCode" })}：
                          <span className={styles["agent-box-code"]}>
                            {item.agent_code}
                          </span>
                        </span>
                      )}
                      <Col span={5}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            className={styles["ToBOrderDetails-product-img"]}
                          >
                            <img src={item.product_picture} />
                          </div>
                        </div>
                      </Col>
                      <Col span={7} style={{ height: "170px" }}>
                        <p>
                          <span style={{ marginRight: 10, fontSize: 15 }}>
                            {item.product_name}
                          </span>
                          {item.customer_rating > 0 && (
                            <Rate
                              value={(item.customer_rating * 5) / 100}
                              disabled
                              color={bussinessPrimaryColor}
                            />
                          )}
                        </p>
                        <p className={styles["table-MHS"]}>
                          {item.brand_name && item.brand_name !== "" && (
                            <Tag color={bussinessPrimaryColor}>
                              {item.brand_name}
                            </Tag>
                          )}
                          {item.brand_name && item.series_name !== "" && (
                            <Tag color={bussinessPrimaryColor}>
                              {item.brand_name}
                            </Tag>
                          )}
                        </p>
                        <p>{item.attribute}</p>
                      </Col>
                      <Col span={4}>
                        {item.attribute &&
                          item.attribute.split(",").map((a: string) => {
                            return (
                              <>
                                <Tag
                                  color={bussinessPrimaryColor}
                                  style={{ marginBottom: "5px" }}
                                >
                                  {a}
                                </Tag>
                                <br />
                              </>
                            );
                          })}
                      </Col>
                      <Col span={3} style={{ textAlign: "center" }}>
                        X{item.quantity}
                      </Col>
                      <Col span={4} style={{ textAlign: "center" }}>
                        {!Number(item.has_tag_price_range) ? (
                          <>
                            ¥
                            {(
                              parseFloat(item.tag_price) * item.quantity
                            ).toFixed(2)}
                          </>
                        ) : (
                          <>{`¥${(
                            item.quantity * parseFloat(item.tag_price_low)
                          ).toFixed(2)} - ¥${(
                            item.quantity * parseFloat(item.tag_price_high)
                          ).toFixed(2)}`}</>
                        )}
                      </Col>
                    </Row>
                  </Card>
                );
              })}
            </Col>
            <Col span={6}>
              <div className={styles["ToBOrderDetails-contact"]}>
                <div>
                  <Icon
                    type="icondizhi"
                    style={{ color: bussinessPrimaryColor }}
                  />
                </div>
                <div className={styles["ToBOrderDetails-contact-content"]}>
                  {detailData.address && (
                    <>
                      <p>{`${detailData.address[0].recipient_name}  ${detailData.address[0].recipient_telephone}`}</p>
                      <p>{detailData.address[0].recipient_email}</p>
                      <p>{detailData.address[0].recipient_company}</p>
                      <p>{detailData.address[0].recipient_address}</p>
                    </>
                  )}
                </div>
              </div>
              <div className={styles["ToBOrderDetails-price"]}>
                <h3>{f({ id: "PriceInfo" })}</h3>
                <Row>
                  <Col span={10}>{f({ id: "SelectedNumber" })}</Col>
                  <Col span={14} style={{ textAlign: "right" }}>
                    {handleGetQuantity()}
                  </Col>
                </Row>
                <Row>
                  <Col span={5}>{f({ id: "PriceTotal" })}</Col>
                  <Col span={19} style={{ textAlign: "right" }}>
                    {handleGetPrice()}
                  </Col>
                </Row>
              </div>

              <div className={styles["ToBOrderDetails-price"]}>
                <Row>
                  <Col span={10}>{f({ id: "PlatformCustomerService" })}</Col>
                  <Col span={14} style={{ textAlign: "right" }}>
                    {detailData.platform_phone}
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>{f({ id: "CustomerServiceTime" })}</Col>
                  <Col span={18} style={{ textAlign: "right" }}>
                    {detailData.business_time}
                  </Col>
                </Row>
              </div>

              <Row>
                <PrimaryButton
                  block
                  onClick={() => replaceState.linkTo("/account/toborders")}
                  bgColor={bussinessPrimaryColor}
                >
                  {f({ id: "BackToList" })}
                </PrimaryButton>
              </Row>
            </Col>
          </Row>
        )}
      </Card>
    </div>
  );
};

export default ToBOrderDetails;
