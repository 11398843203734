import { createModel } from "@rematch/core";
import {
  GraphqlQueryMulti,
  GraphqlMutation,
} from "../../helpers/request.helper";
import { getFirstItem } from "../../helpers/modelHelpers";
import { ListTotal } from "../../models/Common";
import { MegeBook } from "../../models/MegaBook";
import { Product } from "../../models/Product";
import { DesignIdea } from "../../models/DesignIdea";
import Shop from "../../models/Shop";

import produce from "immer";
export interface MegaBookState {
  breadcrumbs: { url: string; title: string }[];
  ProductCollect: MegeBook;
  CollectTotal: ListTotal;

  DesignCollect: MegeBook;

  ShopCollect: MegeBook;

  user: {
    // 用于展示的用户名称
    first_name: string;
    last_name: string;
    avatar_url: string;
  };

  DesignIdeaCollectList: {
    idea_name: string;
    idea_picture: string;
    coordinates_xy: string;
    shop_name: string;
    url: string;
    idea_id: number;
    is_active: boolean;
    is_collect: boolean;
    shop_url: string;
  }[];
}

export default createModel({
  state: {} as MegaBookState,
  reducers: {
    setProductData: (state: MegaBookState, data: MegaBookState) => ({
      ...state,
      ...data,
    }),
    setDesignData: (state: MegaBookState, data: MegaBookState) => ({
      ...state,
      ...data,
    }),
    setShopData: (state: MegaBookState, data: MegaBookState) => ({
      ...state,
      ...data,
    }),
    INIT_USER_NAME: (state: MegaBookState, user: MegaBookState["user"]) => ({
      ...state,
      user,
    }),
    changeDesignIdea: (state: MegaBookState, ideaId: number) =>
      produce(state, draft => {
        const index = draft.DesignCollect.findIndex(
          rp => rp.design_idea_id === ideaId,
        );
        draft.DesignCollect[index].is_collect = !draft.DesignCollect[index]
          .is_collect;
      }),
    changeShopIdea: (state: MegaBookState, shopId: number) =>
      produce(state, draft => {
        const index = draft.ShopCollect.findIndex(rp => rp.shop_id === shopId);
        draft.ShopCollect[index].like = !draft.ShopCollect[index].like;
      }),
  },
  effects: {
    async setProductCollect(payload) {
      const data = await GraphqlQueryMulti(
        {
          method: "ProductCollect",
          model: new Product({}),
          args: payload,
          metadataKey: "ProductCollect",
        },
        {
          method: "CollectTotal",
          model: new ListTotal(),
          args: { action: "product" },
        },
      );

      this.setProductData({
        ProductCollect: data.ProductCollect,
        CollectTotal: getFirstItem(data.CollectTotal),
      });
    },
    async setDesignCollect(payload) {
      const data = await GraphqlQueryMulti(
        {
          method: "DesignCollect",
          model: new DesignIdea({}),
          args: payload,
          metadataKey: "DesignCollect",
        },
        {
          method: "CollectTotal",
          model: new ListTotal(),
          args: { action: "design" },
        },
      );

      this.setProductData({
        DesignCollect: data.DesignCollect,
        CollectTotal: getFirstItem(data.CollectTotal),
      });
    },
    async setShopCollect(page: number) {
      const data = await GraphqlQueryMulti(
        {
          method: "ShopCollect",
          model: new Shop({}),
          args: { page: page ? page : 1 },
          metadataKey: "ShopCollect",
        },
        {
          method: "CollectTotal",
          model: new ListTotal(),
          args: { action: "shop" },
        },
      );
      data.ShopCollect.map(item => {
        item.like = true;
      });
      this.setProductData({
        ShopCollect: data.ShopCollect,
        CollectTotal: getFirstItem(data.CollectTotal),
      });
    },
    async changeUserLike(payload) {
      const { dataId, nowtype, objType } = payload;
      const type = nowtype ? "cancel" : "like";
      const res = await GraphqlMutation("Collect", {
        action: type,
        object: objType,
        data_id: dataId,
      });
      if (objType === "designIdea") {
        this.changeDesignIdea(dataId);
      } else {
        this.changeShopIdea(dataId);
      }
    },
  },
});
