import React, { useState } from "react";
import { GraphqlMutation } from "../../../helpers/request.helper";
import { StateType } from "../models";
import { Form, Row } from "antd";
import { RuleRender } from "antd/lib/form";
import Icon from "../../../components/icon";
import Checkbox from "../../../wrappers/checkbox";
import Input from "../../../wrappers/input";
import { PrimaryButton } from "../../../wrappers/button";
import { useIntl } from "react-intl";
import { useTheme } from "styled-components";

/**
 *
 *
 * 注册模块
 */

type RegisterValueType = {
  email: string;
  password: string;
  showPassword: boolean;
  confirm: string;
  showConfirm: boolean;
  organization: string;
  industry: string;
  isReadTermsOfService: boolean;
};

type RegisterFormProps = {
  toLogin?: () => void;
  toEmailVerfication: (
    registerEmail: string,
    type: StateType["emailVerficationType"],
    password: string,
  ) => void;
  insideModal?: boolean;
};

const RegisterForm: React.FC<RegisterFormProps> = ({
  toEmailVerfication,
  toLogin,
  insideModal = true,
}) => {
  const { formatMessage: f } = useIntl();
  const { primaryColor, textColor } = useTheme();

  const [inputPassword, setInputPassword] = useState(false),
    [isReadOfService, setReadOfService] = useState(true),
    [inputedEmail, setEmail] = useState("");

  const RegisterSubmitFn = async ({ email, password }: RegisterValueType) => {
    const result = await GraphqlMutation("Register", {
      action: "email",
      email,
      password,
    });

    if (result.status_code === 200) {
      // 验证成功之后跳转;
      toEmailVerfication(email, "register", password);
    }
  };

  const validateEmailRegistered: RuleRender = () => ({
    validator: async (rule, value, callback) => {
      if (!value) return;

      await GraphqlMutation("Register", {
        action: "verify",
        email: value,
      });
    },
  });

  const requiredText = f({ id: "Required" });

  const industryData = [
    {
      value: `1`,
      text: f({
        id: "HotelVendor",
      }),
    },
    {
      value: `2`,
      text: f({
        id: "Retailers",
      }),
    },
    {
      value: `3`,
      text: f({
        id: "Designers",
      }),
    },
    {
      value: `4`,
      text: f({
        id: "Suppliers",
      }),
    },
    {
      value: `5`,
      text: f({
        id: "Others",
      }),
    },
  ];

  const onFinish = (values: any) => {
    if (!inputPassword) {
      setEmail(values.email);
      setInputPassword(true);
    } else {
      values.email = inputedEmail;
      RegisterSubmitFn({ ...values });
    }
  };

  return (
    <div>
      {!insideModal && (
        <Row style={{ padding: "10px 0 0 10px" }}>{f({ id: "SignUp" })}</Row>
      )}
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        style={{ padding: "45px" }}
      >
        {inputPassword ? (
          <>
            <Form.Item>
              <span>{f({ id: "EnterThePassword" })}</span>
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: requiredText },
                {
                  min: 6,
                  message: f(
                    { id: "TheLengthOfTheInputMustBeAtLeast" },
                    {
                      num: 6,
                      field: f({
                        id: "Password",
                      }),
                    },
                  ),
                },
                {
                  max: 20,
                  message: f(
                    { id: "TleLengthOfTheInputExceedNumCharacters" },
                    {
                      num: 20,
                    },
                  ),
                },
                {
                  pattern: /[0-9a-zA-Z_-]{1,}$/,
                  message: f(
                    { id: "TleLengthOfTheInputExceedNumCharacters" },
                    {
                      num: 20,
                    },
                  ),
                },
              ]}
            >
              <Input
                type="password"
                placeholder={`${f({
                  id: "Password",
                })} *`}
              />
            </Form.Item>

            <Form.Item
              name="confirmPassword"
              rules={[
                { required: true, message: requiredText },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      f({
                        id: "PasswordDoesNotMatch",
                      }),
                    );
                  },
                }),
              ]}
            >
              <Input
                type="password"
                placeholder={`${f({
                  id: "ConfirmPassword",
                })} *`}
              />
            </Form.Item>

            <Form.Item>
              <PrimaryButton
                htmlType="submit"
                block
                bgColor={isReadOfService ? "#bdbcbc" : primaryColor}
                disabled={isReadOfService}
              >
                <span style={{ color: "white" }}>
                  {f({ id: "SignUp" })}
                  <Icon type="icont1_correct"></Icon>
                </span>
              </PrimaryButton>
            </Form.Item>

            <Form.Item>
              <Checkbox
                shape="circle"
                onChange={e => setReadOfService(!e.target.checked)}
                style={{ marginRight: "5px" }}
              ></Checkbox>
              <span>
                {f(
                  {
                    id: "IHaveReadAndAcceptedMegahomeTermsOfService",
                  },
                  {
                    a: (...chunks) => (
                      <a
                        href="/page/termsofservice/"
                        target="_blank"
                        style={{ color: "#4a8ddd" }}
                      >
                        {chunks}
                      </a>
                    ),
                  },
                )}
              </span>
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item
              name="email"
              validateFirst
              rules={[
                {
                  required: true,
                  message: f({
                    id: "PleaseEnterAValidEmailAddressExclamationMark",
                  }),
                },
                {
                  max: 60,
                  message: f(
                    { id: "TleLengthOfTheInputExceedNumCharacters" },
                    {
                      num: 60,
                    },
                  ),
                },
                {
                  type: "email",
                  message: f({
                    id: "PleaseEnterAValidEmailAddressExclamationMark",
                  }),
                },
                validateEmailRegistered,
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item>
              <PrimaryButton htmlType="submit" block bgColor={primaryColor}>
                {f({ id: "NextStep" })}
                <Icon type="icont1_correct"></Icon>
              </PrimaryButton>
            </Form.Item>
            <Form.Item>
              <span>{f({ id: "AlreadyHaveAnAccountQuestionMark" })}</span>

              <a
                onClick={() =>
                  insideModal
                    ? toLogin()
                    : (location.href = `/login/${location.search}`)
                }
                style={{
                  color: primaryColor,
                }}
              >
                {f({ id: "PleaseLogin" })} &gt;
              </a>
            </Form.Item>
          </>
        )}
      </Form>
      {inputPassword && !insideModal && (
        <Row style={{ padding: "0 0 10px  10px" }}>
          <a
            style={{ color: primaryColor }}
            onClick={() => setInputPassword(false)}
          >
            {"<"} {f({ id: "Back" })}
          </a>
        </Row>
      )}
    </div>
  );
};

export default RegisterForm;
