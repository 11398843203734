import * as React from "react";
import classnames from "classnames";
import { useSelector } from "react-redux";
import styles from "../index.scss";
import { RootState } from "../../../store/configureStore";
import trimStart from "lodash/trimStart";
import Icon from "../../../components/icon";

interface FeaterImgProps {
  imgLoad?: () => void;
  src: string;

  tagsList: {
    coordinate_y: string;
    coordinate_x: string;
    product_picture: string;
    height: string;
    width: string;
    url: string;
  }[];
  style?: React.CSSProperties;
  className?: string;

  children?: React.ReactElement<{}>[] | React.ReactElement<{}>;
}

const FeaterImg = React.memo((props: FeaterImgProps) => {
  const { search = "" } = useSelector((root: RootState) => root.URLSearch);

  const { src, tagsList, style = {}, imgLoad, children } = props;

  const transitionNumber = (n: string) => Number(n);

  const [imgLoaded, setImgLoaded] = React.useState(false);

  React.useEffect(() => {
    setImgLoaded(true);
  }, []);

  return (
    <div
      style={{
        position: "relative",
        boxSizing: "border-box",
        border: "1px solid #f2f2f2",
      }}
    >
      <img
        width="100%"
        src={src}
        onLoad={() => {
          setImgLoaded(true);
          imgLoad && imgLoad();
        }}
      />
      {imgLoaded &&
        tagsList.map((item, index) => (
          <a
            href={`${item.url}${search ? "&" + trimStart(search, "?") : ""}`}
            target="_blank"
            className={classnames(
              styles["ideaDetalis-ideacard-tags-box"],
              "print-tag-box",
            )}
            key={index}
            style={{
              position: "absolute",
              top: `${transitionNumber(item.coordinate_y) * 100}%`,
              left: `${transitionNumber(item.coordinate_x) * 100}%`,
              width: `${transitionNumber(item.width) * 100}%`,
              height: `${transitionNumber(item.height) * 100}%`,
              ...style,
            }}
          >
            {/* {item.product_picture && (
                <img
                  className={classnames(
                    styles["ideaDetalis-ideacard-tags-img"],
                    "print-tag-img",
                  )}
                  src={item.product_picture}
                  alt="product"
                />
              )} */}
            <div
              className={classnames(
                styles["ideaDetalis-ideacard-tags"],
                "print-tag",
              )}
            >
              <Icon type="icont2_biaoqian" />
            </div>
          </a>
        ))}

      {children}
    </div>
  );
});

export default React.memo(FeaterImg);
