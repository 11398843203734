import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, Dispatch } from "../../../../store/configureStore";
import AddressForm, { AddressValuesType } from "../../../Address/AddressForm";
import * as styles from "./index.scss";
import { useIntl } from "react-intl";
import Icon from "../../../../components/icon";
import { PrimaryButton } from "../../../../wrappers/button";
import { Row, Col } from "antd";
import { useTheme } from "styled-components";

interface AddressProps {
  changeAddress: any;
  classNamePrefix?: string;
}
const AddressContent: React.FC<AddressProps> = ({
  classNamePrefix = "",
  changeAddress,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const { primaryColor, borderColor } = useTheme();
  const { formatMessage: f } = useIntl();

  const { address, breadcrumbs = [] } = useSelector(
    (state: RootState) => state.AddressPage,
  );

  const [addressFormOpen, setFormOpen] = useState(false),
    [addRessFormChangeOpen, setChangeOpen] = useState(false),
    [changeAddressId, setAddressId] = useState(0);

  const handleOpenChangeForm = (addressId: string) => {
    setChangeOpen(true);
    setAddressId(Number(addressId));
  };

  const handleChangeAddressItem = (addressValues: AddressValuesType) => {
    const data = {
      ...addressValues,
      address_id: changeAddressId as any,
      callback: () => {
        if (addressValues.is_default === 1) {
          address.map((item, index) => {
            if (parseInt(item.address_id) !== changeAddressId) {
              item.is_default = 0;
            }
          });
        }
        setChangeOpen(false);
        setAddressId(0);
        dispatch.AddressPage.getAddressDataAsync();
      },
    };
    dispatch.AddressPage.setAddressAsync(data);
  };

  const handleAddAddressItem = (addressValues: AddressValuesType) => {
    const data = {
      ...addressValues,
      callback: () => {
        if (addressValues.is_default === 1) {
          address.map((item, index) => {
            item.is_default = 0;
          });
        }
        setFormOpen(false);
        dispatch.AddressPage.getAddressDataAsync();
      },
    };
    dispatch.AddressPage.setAddressAsync(data);
  };

  return (
    <div className={styles["Address-box"]} style={{ padding: "24px" }}>
      <div>
        {address &&
          address.map((item, index) => {
            return (
              <Row>
                <Col
                  span={24}
                  style={{ marginBottom: "10px" }}
                  onClick={() => {
                    changeAddress(index);
                  }}
                >
                  <div
                    style={{
                      background: "white",
                      padding: "20px",
                      border: "2px solid",
                      borderRadius: "5px",
                      cursor: "pointer",
                      borderColor:
                        Number(item.is_select) === 1
                          ? primaryColor
                          : borderColor,
                    }}
                  >
                    <Row align={"middle"}>
                      <Col span={18} style={{ fontSize: "1.4em" }}>
                        <div>
                          {item.recipient_name}
                          {"  "}
                          {item.recipient_telephone}
                        </div>
                        <div>
                          {item.province}
                          {item.city}
                          {item.area}
                          {item.address_details}
                        </div>
                      </Col>
                      <Col span={6} style={{ textAlign: "right" }}>
                        <PrimaryButton
                          size="large"
                          onClick={e => {
                            e.stopPropagation();
                            handleOpenChangeForm(item.address_id);
                          }}
                          shape={"circle"}
                          icon={<Icon type={"iconbianji"} />}
                          bgColor={primaryColor}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            );
          })}
        <Row style={{ textAlign: "right" }}>
          <Col span={14}></Col>
          <Col span={10}>
            <PrimaryButton
              block
              onClick={() => setFormOpen(true)}
              style={{
                height: "50px",
                fontSize: "1.4em",
              }}
              bgColor={primaryColor}
            >
              {f({ id: "AddNewAddress" })}
            </PrimaryButton>
          </Col>
        </Row>
      </div>
      <AddressForm
        classNamePrefix={classNamePrefix}
        isOpen={addressFormOpen}
        onClose={() => setFormOpen(false)}
        onSubmit={handleAddAddressItem}
      />
      {/* 用于修改地址的弹窗 */}
      <AddressForm
        classNamePrefix={classNamePrefix}
        isOpen={addRessFormChangeOpen}
        onClose={() => {
          setChangeOpen(false);
          setAddressId(0);
        }}
        onSubmit={handleChangeAddressItem}
        addressData={
          addRessFormChangeOpen
            ? address.find(item => Number(item.address_id) === changeAddressId)
            : undefined
        }
      />
    </div>
  );
};

export default React.memo(AddressContent);
