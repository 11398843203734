import { createModel } from "@rematch/core";
import {
  GraphqlQueryMulti,
  GraphqlMutation,
} from "../../helpers/request.helper";
import { Message } from "../../models/Message";
import { ListTotal } from "../../models/Common";
import { getFirstItem } from "../../helpers/modelHelpers";
export interface MessageState {
  breadcrumbs: { url: string; title: string }[];
  messages: Message[];
  total: number;
}

export default createModel({
  state: {
    breadcrumbs: [{ title: "Account" }, { title: "Message Center" }],
  } as MessageState,
  reducers: {
    setMessageData: (
      state: MessageState,
      messages: MessageState["messages"],
    ) => ({
      ...state,
      messages,
    }),
    setMessageTotal: (state: MessageState, total: MessageState["total"]) => ({
      ...state,
      total,
    }),
    // 1未读 2已读
    readMessage: (state: MessageState, payload: { message_id: string }) => {
      const idList = String(payload.message_id).split("#");
      return {
        ...state,
        messages: state.messages.map(item => {
          if (idList.includes(String(item.message_id))) {
            item.message_status = 2;
            return item;
          }
          return item;
        }),
      };
    },
    deleteMessage: (state: MessageState, payload: { messageId: string }) => {
      const isList = String(payload.messageId).split("#");
      return {
        ...state,
        messages: state.messages.filter(
          item => !isList.includes(String(item.message_id)),
        ),
      };
    },
  },
  effects: {
    async setMessage(payload) {
      const { page = 1 } = payload;
      const res = await GraphqlQueryMulti(
        {
          method: "Messages",
          model: new Message({ page }),
          args: {},
        },
        {
          method: "MessageTotal",
          model: new ListTotal(),
          args: { action: "allMessage" },
        },
      );
      this.setMessageData(res.Messages);
      this.setMessageTotal(getFirstItem(res.MessageTotal).total);
    },

    async changeMessageStatus(payload) {
      const { action, messageId } = payload;
      const res = await GraphqlMutation("Message", {
        action: action,
        message_id: messageId,
      });

      if (action !== "delete" && action !== "deleteAll") {
        this.readMessage({ message_id: messageId });
      } else {
        this.deleteMessage({ messageId });
      }
    },
  },
});
