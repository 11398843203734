export function fibonacci(num: number): number {
  if (num < 3) return 1;

  return fibonacci(num - 1) + fibonacci(num - 2);
}

// a little function to help us with reordering the result
export function reorder<T>(
  list: T[],
  startIndex: number,
  endIndex: number,
): T[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

export function replaceByItem<T>(list: T[], item: T, index: number): T[] {
  const result = Array.from(list);
  const [removed] = result.splice(index, 1, item);

  return result;
}
