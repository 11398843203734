export type KeyValuePairs = {
  imageSmoothingEnabled: boolean;
  shiftPixel: number;
  useCache: boolean;
  showGrouAll: boolean;
  rotateDegree: number;
  reverseDegree: number;
  drawWatermark: boolean;
  [key: string]: any;
};

export type Operations = {
  leftUpper: number[];
  middleUpper: number[];
  rightUpper: number[];
  leftMiddle: number[];
  rightMiddle: number[];
  leftLower: number[];
  middleLower: number[];
  rightLower: number[];
  verticalTran: number[];
  horizontalTran: number[];
  leftUpperRotation: number[];
  rightUpperRotation: number[];
  rightLowerRotation: number[];
  leftLowerRotation: number[];
};

export enum ItemType {
  Text = "text",
  ItemScaleX = "itemScaleX",
  ItemScaleY = "itemScaleY",
  ImageData = "imageData",
  Image = "image",
  Line = "line",
  Rect = "rect",
  Circle = "circle",
  Polygon = "polygon",
  Tag = "tag",
  EmptyValue = "emptyValue",
}

export class RenderItem {
  public id: string;
  public additionalHash: any;
  public width?: number;
  public height?: number;
  public show: boolean;
  public center: number[];
  public fontSize?: number;
  public bindingId?: string;
  public name: string;
  public type: ItemType;
  public value: any;
  public index?: number;
  public groupId: number;
  public fontFamily: string;
  public fillStyle: string;
  public isFillArea: boolean;
  public lineWidth: number;
  public tagColor: string;
  public backgroundColor: string;
  public gradient: string[];
  public isEditable: boolean;
  public polygon: number[];
  public degree: number;
  public verticalDegree: number;
  public horizontalDegree: number;
  public transparency: number;
  public blur: number;
  public brightness: number;
  public contrast: number;
  public grayscale: number;
  public saturation: number;
  public shadow: string;
  public hue: number;
  public invert: number;

  public drawRect: any[];

  public targetRect: number[];

  public operations?: Operations;
}

export default class Renderer {
  public objects: RenderItem[];

  public sets?: string[][];

  public useSet: boolean;

  public canvas: HTMLCanvasElement;
  public ctx: CanvasRenderingContext2D | null;

  public dataSet: KeyValuePairs = {} as KeyValuePairs;

  public shiftPixel: number;

  public useCache: boolean;

  public isDrawingShape: boolean;

  public drawingShape: any;

  public currentTargetId: string | null;

  public cachedCanvasData: any;

  public groupOperateIds: string[];

  constructor(canvas, dataSet, objects = [], sets = []) {
    this.objects = objects;
    this.sets = sets;
    this.canvas = canvas;
    this.ctx = canvas.getContext("2d");

    dataSet.canvasWidth = this.canvas.width;
    dataSet.canvasHeight = this.canvas.height;
    this.initDataSet(dataSet);
    this.ctx.imageSmoothingEnabled = this.dataSet["imageSmoothingEnabled"];

    this.shiftPixel = this.dataSet["shiftPixel"];
    this.useCache = this.dataSet["useCache"];
    this.useSet = this.dataSet["useSet"];
    this.drawingShape = null;
    this.isDrawingShape = false;
    this.cachedCanvasData = null;
    this.currentTargetId = null;
    this.steps = [];
    this.groupOperateIds = [];
    this.groupRect = [];
  }

  initDataSet(dataSet) {
    let defaultDataSet = {
      useCache: false,
      useSet: false,
      enableDelete: false,
      showGrouAll: false,
      shiftPixel: 5,
      historySteps: 20,
      rotateDegree: 2,
      reverseDegree: 0.05,
      drawWatermark: true,
      canvasWidth: 0,
      canvasHeight: 0,
      highlightColor: "#5fdaff",
      imageSmoothingEnabled: true,
      "background-color": "#ffffff",
      "background-img": null,
      "rotate-operate-img": null,
      "vertical-operate-img": null,
      "horizontal-operate-img": null,
      "component-operate-color": "#f48033",
      "component-frame-color": "rgba(88,88,88,0.8)",
      "component-frame-lineWidth": 1,
      "scale-style": "rgba(210,210,210,0.8)",
      "scale-lineWidth": 2,
      "scale-length": 10,
      "tag-style": "rgba(76,76,76,0.8)",
      "tag-text-style": "rgba(255,255,255,1)",
      "watermark-text": "Hardys Render",
      "watermark-font": "10px Calibri",
      "watermark-style": "rgba(76,76,76,1)",
      "watermark-degree": -45,
      "watermark-opacity": "0.02",
      onKeyPressUpdate: function (objects, sets, id) {
        return;
      },
    };

    for (const property in dataSet) {
      if (defaultDataSet.hasOwnProperty(property)) {
        defaultDataSet[property] = dataSet[property];
      }
    }
    this.dataSet = defaultDataSet;
  }

  updateDataSet(dataSet) {
    for (const property in dataSet) {
      if (this.dataSet.hasOwnProperty(property)) {
        this.dataSet[property] = dataSet[property];
      }
    }
  }

  updateObjects(objects, sets = null) {
    this.objects = objects;
    if (sets) {
      this.sets = sets;
    }
  }

  setResponsive(originalCanvasWidth, newCanvasWidth) {
    const widthRate = (newCanvasWidth / originalCanvasWidth).toFixed(4);
    const heightRate = widthRate;

    let item = {};
    for (let i = 0; i < this.objects.length; i++) {
      item = this.objects[i];
      if (!!item["id"]) {
        item["center"] = [
          Math.floor(item["center"][0] * widthRate),
          Math.floor(item["center"][1] * widthRate),
        ];
        item["fontSize"] = Math.floor(item["fontSize"] * heightRate);
        if (item["type"] !== "emptyValue") {
          item["width"] = Math.floor(item["width"] * widthRate);
          item["height"] = Math.floor(item["height"] * heightRate);

          item["drawRect"] = [
            Math.floor(item["drawRect"][0] * widthRate),
            Math.floor(item["drawRect"][1] * heightRate),
            Math.floor(item["drawRect"][2] * widthRate),
            Math.floor(item["drawRect"][3] * heightRate),
            item["drawRect"][4],
            item["drawRect"][5],
          ];

          item["targetRect"] = [
            Math.floor(item["targetRect"][0] * widthRate),
            Math.floor(item["targetRect"][1] * heightRate),
            Math.floor(item["targetRect"][2] * widthRate),
            Math.floor(item["targetRect"][3] * heightRate),
          ];
        } else {
          item["drawRect"] = [
            item["center"][0] - Math.floor(item["width"] / 2),
            item["center"][1] - Math.floor(item["height"] / 2),
            item["center"][0] + Math.floor(item["width"] / 2),
            item["center"][1] + Math.floor(item["height"] / 2),
            item["drawRect"][4],
            item["drawRect"][5],
          ];

          item["targetRect"] = item["drawRect"];
        }

        // 清空操作点
        item["operations"] = {
          leftUpper: [],
          middleUpper: [],
          rightUpper: [],
          leftMiddle: [],
          rightMiddle: [],
          leftLower: [],
          middleLower: [],
          rightLower: [],
          verticalTran: [],
          horizontalTran: [],
          leftUpperRotation: [],
          rightUpperRotation: [],
          rightLowerRotation: [],
          leftLowerRotation: [],
        };
      }
    }
  }

  go() {
    this.clear();
    this.interactive(this.canvas);
  }

  export() {
    return this.objects;
  }

  clear() {
    if (!!this.dataSet["background-img"]) {
      const imgObject = this.dataSet["background-img"];
      this.ctx.drawImage(
        imgObject,
        0,
        0,
        this.canvas.width,
        this.canvas.height,
      );
    } else if (!!this.dataSet["background-color"]) {
      this.ctx.fillStyle = this.dataSet["background-color"];
      this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
      this.ctx.fill();
    } else {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }
  }

  add(object, settings) {
    const centerX =
      settings.center && settings.center[0]
        ? settings.center[0]
        : Math.round(this.canvas.width / 2);
    const centerY =
      settings.center && settings.center[1]
        ? settings.center[1]
        : Math.round(this.canvas.height / 2);
    let newId =
      "id" + new Date().getTime().toString().substring(8) + this.objects.length;
    let item = {
      id: newId,
      bindingId: settings.bindingId ? settings.bindingId : null,
      name: settings.name ? settings.name : "",
      type: settings.type ? settings.type : "",
      value: object,
      index: this.objects.length,
      additionalHash: settings.additionalHash ? settings.additionalHash : null,
      fontSize: settings.fontSize ? settings.fontSize : 16,
      fontFamily: settings.fontFamily ? settings.fontFamily : "Calibri",
      fillStyle: settings.fillStyle ? settings.fillStyle : "#666666",
      isFillArea: settings.isFillArea ? settings.isFillArea : false,
      lineWidth: settings.lineWidth ? settings.lineWidth : 2,
      tagColor: settings.tagColor ? settings.tagColor : "#686868",
      backgroundColor: settings.backgroundColor
        ? settings.backgroundColor
        : "#686868",
      gradient: settings.gradient ? settings.gradient : [],
      show: !!settings.show,
      isEditable:
        settings.isEditable !== undefined ? settings.isEditable : true,
      center: [centerX, centerY],
      width: settings.width
        ? settings.width
        : object.width
        ? object.width
        : 100,
      height: settings.height
        ? settings.height
        : object.height
        ? object.height
        : 40,
      polygon: settings.polygon ? settings.polygon : [5, 36, 15],
      degree: 0,
      verticalDegree: 0,
      horizontalDegree: 0,
      transparency: 100,
      blur: 0,
      brightness: 100,
      contrast: 100,
      grayscale: 0,
      saturation: 100,
      shadow: "0px 0px 0px #666666",
      hue: 0,
      invert: 0,
      drawRect: [0, 0, 0, 0, false, false],
      targetRect: [0, 0, 0, 0],
      operations: {
        leftUpper: [],
        middleUpper: [],
        rightUpper: [],
        leftMiddle: [],
        rightMiddle: [],
        leftLower: [],
        middleLower: [],
        rightLower: [],
        verticalTran: [],
        horizontalTran: [],
        leftUpperRotation: [],
        rightUpperRotation: [],
        rightLowerRotation: [],
        leftLowerRotation: [],
      },
    };

    item = this.initItemAttributes(item, settings);

    if (
      item.type === "line" ||
      item.type === "rect" ||
      item.type === "circle"
    ) {
      item.gradient = [item.fillStyle, item.fillStyle, item.fillStyle];
      this.drawingShape = item;
      this.isDrawingShape = true;
    } else {
      this.objects.push(item);
      this.restore(this.objects, true);
      //todo this.updateStep();
    }

    return item;
  }

  copy(id, shift = [10, -10]) {
    let target = this.getTargetById(id);
    if (!!target) {
      target.center = [
        target.center[0] + shift[0],
        target.center[1] + shift[1],
      ];

      return this.add(target.value, target);
    }

    return null;
  }

  update(id, settings) {
    for (let i = 0; i < this.objects.length; i++) {
      if (this.objects[i]["id"] === id) {
        const tempType = this.objects[i]["type"];

        for (const property in settings) {
          if (this.objects[i].hasOwnProperty(property)) {
            this.objects[i][property] = settings[property];
          }
        }

        if (settings.type && settings.type !== tempType) {
          this.objects[i] = this.initItemAttributes(this.objects[i], settings);
        }

        this.restore(this.objects, true);
        //todo this.updateStep();

        return true;
      }
    }
    return false;
  }

  delete(id) {
    for (let i = 0; i < this.objects.length; i++) {
      const item = this.objects[i];
      if (item.id === id) {
        this.objects.splice(i, 1)[0];
        this.cachedCanvasData = null;
        for (let j = i; j < this.objects.length; j++) {
          this.objects[j]["index"] = j;
        }

        // Delete from Set
        if (this.useSet) {
          this.deleteSetTargetId(id);
        }
        this.restore(this.objects, true);
        //todo this.updateStep();

        return true;
      }
    }

    return false;
  }

  reverse(id) {
    for (let i = 0; i < this.objects.length; i++) {
      if (this.objects[i].id === id) {
        const tempValue = this.objects[i].drawRect;
        const tempDrawRect = this.objects[i].drawRect;
        const tempTargetRect = this.objects[i].targetRect;
        let newDrawRect = [];

        newDrawRect[0] = tempDrawRect[4] ? tempTargetRect[0] : tempValue[2];
        newDrawRect[1] = tempValue[1];
        newDrawRect[2] = tempDrawRect[4] ? tempTargetRect[2] : tempValue[0];
        newDrawRect[3] = tempValue[3];
        newDrawRect[4] = !tempDrawRect[4];
        newDrawRect[5] = tempDrawRect[5];

        this.objects[i].drawRect = newDrawRect;
        this.restore(this.objects);
        // todo this.updateStep();

        return true;
      }
    }

    return false;
  }

  swap(indexFrom, indexTo) {
    indexTo = parseInt(indexTo);
    indexFrom = parseInt(indexFrom);
    const tempTarget = this.objects[indexTo];
    this.objects[indexTo] = this.objects[indexFrom];
    this.objects[indexFrom] = tempTarget;

    // Update the canvas 更新画布
    this.restore(this.objects, true);
    //todo this.updateStep();
  }

  moveToTop(index) {
    if (
      this.objects.length === 0 ||
      index < 0 ||
      index >= this.objects.length
    ) {
      return false;
    }
    const tempTarget = this.objects[index];
    for (let i = index + 1; i < this.objects.length; i++) {
      this.objects[i - 1] = this.objects[i];
    }
    this.objects[this.objects.length - 1] = tempTarget;

    // Update the canvas 更新画布
    this.restore(this.objects, true);
    //todo this.updateStep();

    return true;
  }

  moveToBottom(index) {
    if (
      this.objects.length === 0 ||
      index < 0 ||
      index >= this.objects.length
    ) {
      return false;
    }
    const tempTarget = this.objects[index];
    for (let i = index; i > 0; i--) {
      this.objects[i] = this.objects[i - 1];
    }
    this.objects[0] = tempTarget;

    // Update the canvas 更新画布
    this.restore(this.objects, true);
    //todo this.updateStep();

    return true;
  }

  // Set manipulations
  // Set 操作

  appendEmptySet() {
    this.sets.push([]);
  }

  addToSet(id, setIndex = null, idIndex = null) {
    let targetSet = this.sets[setIndex];
    if (!!targetSet) {
      if (idIndex >= targetSet.length) {
        targetSet.push(id);
      } else {
        targetSet.push(targetSet[targetSet.length - 1]);
        for (let i = targetSet.length - 2; i >= idIndex; i--) {
          targetSet[i] = targetSet[i - 1];
        }
        targetSet[idIndex] = id;
      }
      this.sets[setIndex] = targetSet;

      // 更新位置信息
      this.updateSets();
    } else if (setIndex === null && idIndex === null) {
      this.sets.push([id]);
    }
  }

  getSetTargetId(id) {
    for (let i = 0; i < this.sets.length; i++) {
      if (this.sets[i].includes(id)) {
        // [setIndex, idIndex]
        return [i, this.sets[i].indexOf(id)];
      }
    }

    return [null, null];
  }

  deleteSetTargetId(id) {
    for (let i = 0; i < this.sets.length; i++) {
      // 当前set存在该id
      if (this.sets[i].includes(id)) {
        if (this.sets[i].length === 1) {
          // 删除当前set
          this.sets.splice(i, 1);
          return;
        } else if (this.sets[i].indexOf(id) === 0 && !!this.sets[i][1]) {
          // 删除当前set中id并将按序后的target显示
          this.update(this.sets[i][1], { show: true });
          this.sets[i].splice(this.sets[i].indexOf(id), 1);
        } else {
          // 删除当前set
          this.sets[i].splice(this.sets[i].indexOf(id), 1);
        }
      }
    }
  }

  deleteSet(setIndex) {
    // 当前set存在
    if (!!this.sets[setIndex]) {
      this.sets.splice(setIndex, 1);

      return true;
    } else {
      return false;
    }
  }

  moveSetTargetId(id, fromSetIndex, toSetIndex, idIndex) {
    let targetSet = this.sets[fromSetIndex];
    if (!!targetSet) {
      if (targetSet.includes(id)) {
        // 取出当前set的id
        targetSet.splice(targetSet.indexOf(id), 1);
        this.sets[fromSetIndex] = targetSet;

        // 插入到目的set
        let toSet = this.sets[toSetIndex];
        if (idIndex >= toSet.length) {
          toSet.push(id);
        } else {
          toSet.push(toSet[toSet.length - 1]);
          for (let i = toSet.length - 2; i >= idIndex; i--) {
            toSet[i] = toSet[i - 1];
          }
          toSet[idIndex] = id;
        }
        this.sets[toSetIndex] = toSet;

        // 更新位置信息
        this.updateSets();
        return true;
      }
    }

    return false;
  }

  copySet(setIndex, shift = [10, -10]) {
    let tempTarget = null;

    tempTarget = this.getTargetById(this.sets[setIndex][0]);
    if (!!tempTarget) {
      tempTarget.center = [
        tempTarget.center[0] + shift[0],
        tempTarget.center[1] + shift[1],
      ];
      tempTarget.show = true;

      const newTarget = this.add(tempTarget.value, tempTarget);
      if (!!newTarget) {
        this.sets.push([newTarget.id]);
      }
    }

    this.restore(this.objects, true);
    //todo this.updateStep();
  }

  updateSets() {
    if (this.sets.length !== 0) {
      for (let i = 0; i <= this.sets.length; i++) {
        const set = this.sets[i];
        const firstTarget = !!set ? this.getTargetById(set[0], true) : null;
        if (!!firstTarget) {
          this.update(firstTarget.id, { show: true });
          for (let j = 1; j < set.length; j++) {
            // Update剩余target
            this.update(set[j], { show: false, center: firstTarget.center });
          }
        }
      }
      this.restore(this.objects, true);
      //todo this.updateStep();
    }
  }

  swapSet(indexFrom, indexTo) {
    indexTo = parseInt(indexTo);
    indexFrom = parseInt(indexFrom);
    const tempSet = this.sets[indexTo];
    this.sets[indexTo] = this.sets[indexFrom];
    this.sets[indexFrom] = tempSet;

    // Update the canvas 更新画布
    this.restore(this.objects, true);
    //todo this.updateStep();
  }

  moveSet(indexOriginal, indexInsertInto) {
    indexOriginal = parseInt(indexOriginal);
    indexInsertInto = parseInt(indexInsertInto);

    if (indexInsertInto >= this.sets.length) {
      return;
    }
    this.sets.splice(indexInsertInto, 0, this.sets.splice(indexOriginal, 1)[0]);

    // Update the canvas 更新画布
    this.restore(this.objects, true);
    //todo this.updateStep();
  }

  moveSetToTop(index) {
    if (this.sets.length === 0 || index < 0 || index >= this.sets.length) {
      return false;
    }
    const tempSet = this.sets[index];
    this.sets.splice(index, 1);
    this.sets.push(tempSet);

    // Update the canvas 更新画布
    this.restore(this.objects, true);
    //todo this.updateStep();
    return true;
  }

  moveSetToBottom(index) {
    if (this.sets.length === 0 || index < 0 || index >= this.sets.length) {
      return false;
    }
    const tempSet = this.sets[index];
    for (let i = index; i > 0; i--) {
      this.sets[i] = this.sets[i - 1];
    }
    this.sets[0] = tempSet;

    // Update the canvas 更新画布
    this.restore(this.objects, true);
    //todo this.updateStep();
    return true;
  }

  redo(currentStep) {
    if (currentStep < this.steps.length - 1) {
      this.sets = this.steps[currentStep + 1][0];
      this.objects = this.steps[currentStep + 1][1];
    }
    this.restore(this.objects, true);

    return currentStep + 1 == this.steps.length - 1;
  }

  undo(currentStep) {
    if (currentStep > 1) {
      this.sets = this.steps[currentStep - 1][0];
      this.objects = this.steps[currentStep - 1][1];
    } else if (currentStep === 1) {
      this.sets = [];
      this.objects = [];
    }
    this.restore(this.objects, true);

    return currentStep - 1 == 0;
  }

  isSameArray(array1, array2) {
    if (!!array1 || !!array2) {
      return false;
    }
    for (let i = 0; i < array1.length - 1; i++) {
      for (const property in array1[i]) {
        if (
          !!array2[i] &&
          array1[i].hasOwnProperty(property) &&
          array2[i].hasOwnProperty(property)
        ) {
          if (array1[i][property] !== array2[i][property]) {
            return false;
          }
        }
      }
    }

    return true;
  }

  updateStep(currentStep = null) {
    // 无变动则不更新
    if (this.isSameArray(this.objects, this.sets[this.sets.length - 1])) {
      return;
    }
    if (!!currentStep && currentStep < this.dataSet["historySteps"] - 1) {
      this.steps[currentStep + 1] = [this.sets, this.objects];
      this.steps.splice(currentStep + 2);
    } else if (this.steps.length < this.dataSet["historySteps"]) {
      this.steps.push([this.sets, this.objects]);
    } else {
      for (let i = 0; i < this.steps.length - 1; i++) {
        this.steps[i] = this.steps[i + 1];
      }
      this.steps[this.sets.length - 1] = [this.sets, this.objects];
    }
  }

  initItemAttributes(item, settings) {
    const centerX =
      settings.center && settings.center[0]
        ? settings.center[0]
        : item.center[0];
    const centerY =
      settings.center && settings.center[1]
        ? settings.center[1]
        : item.center[1];
    if (settings.type === "text") {
      const measureText = Math.floor(
        (this.ctx.measureText(item.value).width * parseInt(item.fontSize)) / 10,
      );
      item.drawRect = [
        item.center[0],
        item.center[1],
        item.center[0] + measureText,
        item.center[1] + parseInt(item.fontSize),
        false,
        false,
      ];
      item.targetRect = [
        item.center[0],
        item.center[1],
        item.center[0] + measureText,
        item.center[1] + parseInt(item.fontSize),
      ];
    } else {
      console.log(item.width, item.height);

      item.drawRect = item.targetRect = [
        centerX - Math.floor(item.width / 2),
        centerY - Math.floor(item.height / 2),
        centerX + Math.floor(item.width / 2),
        centerY + Math.floor(item.height / 2),
      ];
      item.drawRect[4] = false;
      item.drawRect[5] = false;
    }

    // Binding Setup 只对绑定的对象操作
    if (!!item.bindingId) {
      const bindingTarget = this.getBindingTarget(item.bindingId);
      const scaleLength = this.dataSet["scale-length"];
      item.width = bindingTarget.width ? bindingTarget.width : 1;
      item.height = bindingTarget.height ? bindingTarget.height : 1;
      item.name = bindingTarget.name + settings.type;
      if (settings.type === "itemScaleX") {
        item.drawRect[0] = bindingTarget.drawRect[0];
        item.drawRect[1] =
          bindingTarget.drawRect[1] - scaleLength - this.shiftPixel;
        item.drawRect[2] = bindingTarget.drawRect[2];
        item.drawRect[3] = bindingTarget.drawRect[1] - this.shiftPixel;
      } else if (settings.type === "itemScaleY") {
        item.drawRect[0] =
          bindingTarget.drawRect[0] - scaleLength - this.shiftPixel;
        item.drawRect[1] = bindingTarget.drawRect[1];
        item.drawRect[2] = bindingTarget.drawRect[0] - this.shiftPixel;
        item.drawRect[3] = bindingTarget.drawRect[3];
      }
      item.center = [
        item.drawRect[0] +
          Math.floor((item.drawRect[2] - item.drawRect[0]) / 2),
        item.drawRect[1] +
          Math.floor((item.drawRect[3] - item.drawRect[1]) / 2),
      ];
    }

    return item;
  }

  updateTargetPosition(target, drawRect) {
    // 更新中心宽高信息 Update center and width/height
    target.center = [
      target.targetRect[0] +
        Math.floor(Math.abs(drawRect[2] - drawRect[0]) / 2),
      target.targetRect[1] +
        Math.floor(Math.abs(drawRect[3] - drawRect[1]) / 2),
    ];

    target.width = Math.floor(Math.abs(drawRect[2] - drawRect[0]));
    target.height = Math.floor(Math.abs(drawRect[3] - drawRect[1]));

    if (
      target.targetRect[1] === target.targetRect[3] &&
      target.type === "text"
    ) {
      target.targetRect[1] -= parseInt(target.fontSize);
    }

    // 更新定界框 Update the target rect
    let newRect = target.targetRect;
    let temp = null;

    if (
      target.targetRect[0] &&
      target.targetRect[2] &&
      target.targetRect[0] > target.targetRect[2]
    ) {
      temp = target.targetRect[0];
      newRect[0] = target.targetRect[2];
      newRect[2] = temp;
    }
    if (
      target.targetRect[1] &&
      target.targetRect[3] &&
      target.targetRect[1] > target.targetRect[3]
    ) {
      temp = target.targetRect[1];
      newRect[1] = target.targetRect[3];
      newRect[3] = temp;
    }

    target.targetRect = newRect;

    return target;
  }

  cacheCanvas() {
    if (this.useCache) {
      this.cachedCanvasData = null;
      this.restore(this.objects);
      this.cachedCanvasData = this.ctx.getImageData(
        0,
        0,
        this.canvas.width,
        this.canvas.height,
      );
    }
  }

  restore(objects = null, skipCache = false) {
    if (!objects) {
      objects = this.objects;
    }

    if (!!this.cachedCanvasData && !skipCache && this.useCache) {
      // 已经存在暂存内容
      this.ctx.putImageData(this.cachedCanvasData, 0, 0);
    } else {
      // 不存在暂存内容
      this.clear();

      if (this.useSet && this.sets.length !== 0) {
        // 按照set次序绘制
        for (let i = 0; i < this.sets.length; i++) {
          const set = this.sets[i];
          const tempTarget = this.getTargetById(set[0]);
          if (!!tempTarget) {
            this.drawTarget(tempTarget, tempTarget.drawRect);
            if (
              !!this.currentTargetId &&
              this.currentTargetId === tempTarget["id"] &&
              this.groupOperateIds.length === 0
            ) {
              this.drawComponentFrame(
                tempTarget,
                tempTarget.targetRect,
                "",
                false,
                true,
              );
            }
          }
        }
      } else {
        // 按照target次序绘制
        for (let i = 0; i < objects.length; i++) {
          const item = objects[i];
          if (item["show"]) {
            this.drawTarget(item, item.drawRect);
          }
          if (
            !!this.currentTargetId &&
            this.currentTargetId === item["id"] &&
            this.groupOperateIds.length === 0
          ) {
            this.drawComponentFrame(item, item.targetRect, "", false, true);
          }
        }
      }

      // 画群组
      this.drawGroupComponentFrame();

      // 画水印
      if (!!this.dataSet["drawWatermark"]) {
        this.drawWatermark();
      }
      this.cachedCanvasData = null;
    }
  }

  addGroupOperateId(id) {
    for (let i = 0; i < this.groupOperateIds.length; i++) {
      if (this.groupOperateIds[i] === id) {
        return false;
      }
    }
    const tempTarget = this.getTargetById(id);
    if (!tempTarget || !tempTarget.show || !tempTarget.isEditable) {
      return false;
    }
    this.groupOperateIds.push(id);
    return true;
  }

  moveTargetById(id, rect) {
    let target = this.getTargetById(id);
    if (!!target) {
      const oldDrawRect = target.drawRect;
      const oldTargetRect = target.targetRect;
      target.drawRect = [
        oldDrawRect[0] + rect[0],
        oldDrawRect[1] + rect[1],
        oldDrawRect[2] + rect[0],
        oldDrawRect[3] + rect[1],
      ];
      target.targetRect = [
        oldTargetRect[0] + rect[0],
        oldTargetRect[1] + rect[1],
        oldTargetRect[2] + rect[0],
        oldTargetRect[3] + rect[1],
      ];
      target = this.updateTargetPosition(target, target.drawRect);
      if (this.useSet) {
        this.updateSets();
      }
      this.objects[target.index] = target;
      this.objects[target.index]["show"] = true;
    }
  }

  moveGroupById(idArray, rect) {
    for (let i = 0; i < idArray.length; i++) {
      this.moveTargetById(idArray[i], rect);
    }
    this.restore(this.objects, true);
  }

  popTargetById(id) {
    for (let i = 0; i < this.objects.length; i++) {
      const item = this.objects[i];
      if (item.show && item.id === id) {
        return this.objects.splice(i, 1)[0];
      }
    }
    return null;
  }

  getTargetById(id, skipShowCheck = false) {
    for (let i = 0; i < this.objects.length; i++) {
      const item = this.objects[i];
      if ((skipShowCheck || item.show) && item.id === id) {
        return item;
      }
    }
    return null;
  }

  getTargetOperations(rect) {
    let operations = {};
    const radius = this.shiftPixel + this.ctx.lineWidth;

    // Left upper
    operations.leftUpper = [
      rect[0] - this.shiftPixel,
      rect[1] - this.shiftPixel,
      rect[0] + this.shiftPixel,
      rect[1] + this.shiftPixel,
    ];

    // Middle upper
    operations.middleUpper = [
      rect[0] + Math.floor((rect[2] - rect[0]) / 2) - this.shiftPixel,
      rect[1] - this.shiftPixel,
      rect[0] + Math.floor((rect[2] - rect[0]) / 2) + this.shiftPixel,
      rect[1] + this.shiftPixel,
    ];

    // Right upper
    operations.rightUpper = [
      rect[2] - this.shiftPixel,
      rect[1] - this.shiftPixel,
      rect[2] + this.shiftPixel,
      rect[1] + this.shiftPixel,
    ];

    // Right middle
    operations.rightMiddle = [
      rect[2] - this.shiftPixel,
      rect[1] + Math.floor((rect[3] - rect[1]) / 2) - this.shiftPixel,
      rect[2] + this.shiftPixel,
      rect[1] + Math.floor((rect[3] - rect[1]) / 2) + this.shiftPixel,
    ];

    // 竖直翻转操作
    let pointX = rect[2] + radius + 12;
    let pointY = rect[1] + Math.floor((rect[3] - rect[1]) / 2);
    operations.verticalTran = [
      pointX - 6,
      pointY - 12,
      pointX + 6,
      pointY + 12,
    ];

    // Right lower
    operations.rightLower = [
      rect[2] - this.shiftPixel,
      rect[3] - this.shiftPixel,
      rect[2] + this.shiftPixel,
      rect[3] + this.shiftPixel,
    ];

    // Middle lower
    operations.middleLower = [
      rect[0] + Math.floor((rect[2] - rect[0]) / 2) - this.shiftPixel,
      rect[3] - this.shiftPixel,
      rect[0] + Math.floor((rect[2] - rect[0]) / 2) + this.shiftPixel,
      rect[3] + this.shiftPixel,
    ];

    // 水平翻转操作
    pointX = rect[0] + Math.floor((rect[2] - rect[0]) / 2);
    pointY = rect[3] + radius + 12;
    operations.horizontalTran = [
      pointX - 12,
      pointY - 6,
      pointX + 12,
      pointY + 6,
    ];

    // Left lower
    operations.leftLower = [
      rect[0] - this.shiftPixel,
      rect[3] - this.shiftPixel,
      rect[0] + this.shiftPixel,
      rect[3] + this.shiftPixel,
    ];

    // Left Middle
    operations.leftMiddle = [
      rect[0] - this.shiftPixel,
      rect[1] + Math.floor((rect[3] - rect[1]) / 2) - this.shiftPixel,
      rect[0] + this.shiftPixel,
      rect[1] + Math.floor((rect[3] - rect[1]) / 2) + this.shiftPixel,
    ];

    // 旋转操作 4个区域
    // rotate operation buttons
    operations.leftUpperRotation = [
      rect[0] - radius - 20,
      rect[1] - radius - 20,
      rect[0] - radius - 2,
      rect[1] - radius - 2,
    ];
    operations.rightUpperRotation = [
      rect[2] + radius + 2,
      rect[1] - radius - 20,
      rect[2] + radius + 20,
      rect[1] - radius - 2,
    ];
    operations.rightLowerRotation = [
      rect[2] + radius + 2,
      rect[3] + radius + 2,
      rect[2] + radius + 20,
      rect[3] + radius + 20,
    ];
    operations.leftLowerRotation = [
      rect[0] - radius - 20,
      rect[3] + radius + 2,
      rect[0] - radius - 2,
      rect[3] + radius + 20,
    ];

    return operations;
  }

  getGroupTargetIds(rect) {
    let ids = [];
    for (let i = 0; i < this.objects.length; i++) {
      const item = this.objects[i];
      if (
        item.isEditable &&
        item.show &&
        ((rect[0] < item.targetRect[0] &&
          item.targetRect[0] < rect[2] &&
          rect[1] < item.targetRect[1] &&
          item.targetRect[1] < rect[3]) ||
          (rect[0] < item.targetRect[2] &&
            item.targetRect[2] < rect[2] &&
            rect[1] < item.targetRect[3] &&
            item.targetRect[3] < rect[3]))
      ) {
        ids.push(item.id);
      }
    }

    return ids;
  }

  getColorByClick(e, isArray = false) {
    const x = this.getMousePos(e).x;
    const y = this.getMousePos(e).y;
    const data = this.ctx.getImageData(x, y, 1, 1).data;
    return isArray
      ? [data[0], data[1], data[2], data[3]]
      : "rgba(" +
          data[0] +
          ", " +
          data[1] +
          ", " +
          data[2] +
          ", " +
          data[3] / 255 +
          ")";
  }

  getColorCoordinator(x, y, isArray = false) {
    const data = this.ctx.getImageData(x, y, 1, 1).data;
    return isArray
      ? [data[0], data[1], data[2], data[3]]
      : "rgba(" +
          data[0] +
          ", " +
          data[1] +
          ", " +
          data[2] +
          ", " +
          data[3] / 255 +
          ")";
  }

  // 获得鼠标的相对位置
  getMousePos(e) {
    const rect = this.canvas.getBoundingClientRect();

    return {
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    };
  }

  getBindingTarget(bindingId) {
    for (let i = 0; i < this.objects.length; i++) {
      const item = this.objects[i];
      if (item.id === bindingId) {
        return item;
      }
    }

    return null;
  }

  getOperate(e, target, drawPoint = true) {
    const x = this.getMousePos(e).x;
    const y = this.getMousePos(e).y;
    if (target.show) {
      for (let [key, value] of Object.entries(target.operations)) {
        if (
          value[0] - this.shiftPixel < x &&
          x < value[2] + this.shiftPixel &&
          value[1] - this.shiftPixel < y &&
          y < value[3] + this.shiftPixel
        ) {
          // Interactive
          if (
            drawPoint &&
            key !== "horizontalTran" &&
            key !== "verticalTran" &&
            key !== "leftUpperRotation" &&
            key !== "rightUpperRotation" &&
            key !== "rightLowerRotation" &&
            key !== "leftLowerRotation"
          ) {
            this.ctx.fillStyle = this.dataSet["component-operate-color"];
            const radius = this.shiftPixel + this.ctx.lineWidth;

            // Fill the operate point
            this.ctx.beginPath();
            this.ctx.arc(
              value[0] + Math.floor((value[2] - value[0]) / 2),
              value[1] + Math.floor((value[3] - value[1]) / 2),
              radius,
              0,
              2 * Math.PI,
            );
            this.ctx.fill();
            this.ctx.closePath();
            this.ctx.fillStyle = this.dataSet["component-frame-color"];
          }

          return key;
        }
      }
    }
  }

  getTopTarget(e, shouldRemove = true) {
    const x = this.getMousePos(e).x;
    const y = this.getMousePos(e).y;
    let topIndex = -1;
    let returnedIndex = -1;
    let isCatched = false;
    for (let i = 0; i < this.objects.length; i++) {
      const item = this.objects[i];
      if (
        item.show &&
        item.isEditable &&
        item.targetRect[0] - this.shiftPixel < x &&
        x < item.targetRect[2] + this.shiftPixel &&
        item.targetRect[1] - this.shiftPixel < y &&
        y < item.targetRect[3] + this.shiftPixel
      ) {
        isCatched = true;
        if (item.index > topIndex) {
          topIndex = item.index;
          returnedIndex = i;
        }
      }
    }

    if (isCatched && shouldRemove) {
      return this.objects.splice(returnedIndex, 1)[0];
    } else if (isCatched) {
      return this.objects[returnedIndex];
    } else {
      return null;
    }
  }

  checkTopTarget(e, forceRestore = true) {
    const x = this.getMousePos(e).x;
    const y = this.getMousePos(e).y;
    let topIndex = -1;
    let returnedTarget = null;
    let isCatched = false;
    for (let i = 0; i < this.objects.length; i++) {
      const item = this.objects[i];
      if (
        item.show &&
        item.isEditable &&
        item.targetRect[0] - this.shiftPixel < x &&
        x < item.targetRect[2] + this.shiftPixel &&
        item.targetRect[1] - this.shiftPixel < y &&
        y < item.targetRect[3] + this.shiftPixel
      ) {
        isCatched = true;
        if (item.index > topIndex) {
          topIndex = item.index;
          returnedTarget = item;
        }
      }
    }

    if (forceRestore) {
      this.clear();
      this.restore(this.objects, true);
    }

    if (isCatched && !!returnedTarget) {
      const rect = returnedTarget.targetRect;
      this.ctx.save();
      this.ctx.lineWidth = 5;
      this.ctx.strokeStyle = this.dataSet["highlightColor"];
      this.ctx.beginPath();
      this.ctx.moveTo(rect[0] - 2, rect[1]);
      this.ctx.lineTo(rect[2] + 2, rect[1]);
      this.ctx.moveTo(rect[2], rect[1]);
      this.ctx.lineTo(rect[2], rect[3]);
      this.ctx.moveTo(rect[2] + 2, rect[3]);
      this.ctx.lineTo(rect[0] - 2, rect[3]);
      this.ctx.moveTo(rect[0], rect[3]);
      this.ctx.lineTo(rect[0], rect[1]);
      this.ctx.stroke();
      this.ctx.restore();
      return returnedTarget;
    } else {
      return null;
    }
  }

  getObjectImageData(target) {
    // Clear
    console.log(target.value);
    console.log(target.value.data);
    this.cachedCanvasData = this.ctx.getImageData(
      0,
      0,
      this.canvas.width,
      this.canvas.height,
    );

    // this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    //
    const backgroundColor = this.getColorCoordinator(0, 0, true);
    // console.log(backgroundColor);
    this.ctx.putImageData(this.cachedCanvasData, 0, 0);
    this.ctx.drawImage(target.value, 0, 0, target.width, target.height);
    const imageData = this.ctx.getImageData(0, 0, target.width, target.height);
    //
    let data = imageData.data;
    for (let i = 0; i < data.length; i += 4) {
      if (
        data[i] === backgroundColor[0] &&
        data[i + 1] === backgroundColor[1] &&
        data[i + 2] === backgroundColor[2]
      ) {
        // data[i] = 255;
        // data[i + 1] = 0;
        // data[i + 2] = 0;
        data[i + 3] = 0;
      }
    }
    //
    //this.ctx.putImageData(this.cachedCanvasData, 0, 0);

    return imageData;
  }

  convertTargetToImageData(target) {
    target.type = "imageData";
    target.value = this.getObjectImageData(target);
    target.widht = target.value.width;
    target.height = target.value.height;
    // for (let i = 0; i < this.objects.length; i++) {
    //   if (target.id === this.objects[i].id) {
    //     this.objects[i] = target;
    //     this.restore(this.objects, true);
    //
    //     return true;
    //   }
    // }

    return false;
  }

  invertImage(imageData) {
    let data = imageData;
    const dataLength = data.length;
    for (let i = 0; i < dataLength; i += 4) {
      // red
      data[i] = 255 - data[i];
      // green
      data[i + 1] = 255 - data[i + 1];
      // blue
      data[i + 2] = 255 - data[i + 2];
    }
    return data;
  }

  setUseCache(useCache = false) {
    this.useCache = useCache;
  }

  setCanvasFilterText(target) {
    const dropShadow =
      target.shadow === "0px 0px 0px #666666"
        ? ""
        : " drop-shadow(" + target.shadow + ")";
    const filter =
      "blur(" +
      target.blur +
      "px) brightness(" +
      target.brightness +
      "%) contrast(" +
      target.contrast +
      "%) grayscale(" +
      +target.grayscale +
      "%) saturate(" +
      target.saturation +
      "%) hue-rotate(" +
      target.hue +
      "deg) invert(" +
      target.invert +
      "%)" +
      dropShadow;

    return filter;
  }

  setImageBrightness(target, imageData) {
    let data = imageData.data;
    const brightness = target.brightness * 255,
      dataLength = data.length;

    for (let i = 0; i < dataLength; i += 4) {
      // red
      data[i] += brightness;
      // green
      data[i + 1] += brightness;
      // blue
      data[i + 2] += brightness;
    }
  }

  setImageTransparency(target, imageData) {
    let data = imageData.data;
    const transparency = (target.transparency / 100).toFixed(2),
      dataLength = data.length;

    for (let i = 0; i < dataLength; i += 4) {
      // Alpha
      data[i + 3] = (transparency * data[i + 3]).toFixed(2);
    }

    return data;
  }

  setImageContrast(target, imageData) {
    const adjust = Math.pow((target.contrast + 100) / 100, 2);

    let data = imageData.data,
      red = 150,
      green = 150,
      blue = 150;

    const dataLength = data.length;

    for (let i = 0; i < dataLength; i += 4) {
      // The red channel
      red = ((data[i] / 255 - 0.5) * adjust + 0.5) * 255;

      // The green channel
      green = ((data[i + 1] / 255 - 0.5) * adjust + 0.5) * 255;

      // The blue channel
      blue = ((data[i + 2] / 255 - 0.5) * adjust + 0.5) * 255;

      red = red < 0 ? 0 : red > 255 ? 255 : red;
      green = green < 0 ? 0 : green > 255 ? 255 : green;
      blue = blue < 0 ? 0 : blue > 255 ? 255 : blue;

      data[i] = red;
      data[i + 1] = green;
      data[i + 2] = blue;
    }

    return data;
  }

  // var tempCanvas = document.createElement('canvas');
  // var tempContext = tempCanvas.getContext('2d');
  //
  // tempContext.drawImage(image, 0, 0);
  // var imageData = tempContext.getImageData(0, 0, image.width, image.height);
  //
  // //modify here the imageData as you need
  //
  // var img = new Image();
  // img.src = imageData;
  // context.drawImage(img, 0, 0); //the true context of the canvas
  setImageHSV(target, imageData) {}

  setImageHighlight(target, imageData) {}

  setImageShadow(target, imageData) {}

  setImageDataGrayscale(imageData) {
    let data = imageData;
    const dataLength = data.length;
    for (var i = 0; i < dataLength; i += 4) {
      var avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
      // red
      data[i] = avg;
      // green
      data[i + 1] = avg;
      // blue
      data[i + 2] = avg;
    }
    return data;
  }

  setCanvasDegree(degree, rect, xScale = 1, yScale = 1) {
    this.ctx.translate(
      rect[0] + (rect[2] - rect[0]) / 2,
      rect[1] + (rect[3] - rect[1]) / 2,
    );
    this.ctx.rotate((xScale * yScale * degree * Math.PI) / 180);
    this.ctx.translate(
      -rect[0] - (rect[2] - rect[0]) / 2,
      -rect[1] - (rect[3] - rect[1]) / 2,
    );
  }

  /**
   * 图像变形核心算法，保留多种效果的叠加
   * @param {object} target
   * @param {array}  rect             xStart, yStart, xEnd,yEnd
   * @param {number} degree
   * @param {number} verticalDegree
   * @param {number} horizontalDegree
   *
   * @return void
   */
  drawTarget(
    target,
    rect,
    degree = 0,
    horizontalDegree = 0,
    verticalDegree = 0,
  ) {
    if (target.degree != 0 && degree === 0) {
      degree = target.degree;
    }
    if (target.horizontalDegree != 0 && horizontalDegree === 0) {
      horizontalDegree = target.horizontalDegree;
    }
    if (target.verticalDegree != 0 && verticalDegree === 0) {
      verticalDegree = target.verticalDegree;
    }
    this.ctx.save();

    let xScale = 1,
      yScale = 1;
    let xSkew = 0,
      ySkew = 0,
      xTran = 0,
      yTran = 0;
    let drawWidth = Math.abs(rect[2] - rect[0]);
    let drawHeight = Math.abs(rect[3] - rect[1]);
    // if (target.name == 'frame') {console.log('------------------------ ')};
    // if (target.name == 'frame') {console.log('2 - 0 : ' + Math.abs(rect[2] - rect[0]))};
    // if (target.name == 'frame') {console.log('rect in : ' + rect)};

    let drawImageX = rect[0],
      drawImageY = rect[1];
    if (rect[4] && rect[0] > 0) {
      xScale = -1 * xScale;
      drawImageX = -1 * rect[0];
    }
    if (rect[5] && rect[1] > 0) {
      yScale = -1 * yScale;
      drawImageY = -1 * rect[1];
    }

    if (verticalDegree != 0 || horizontalDegree != 0) {
      const reverseDegree = this.dataSet["reverseDegree"];
      const xCenter = Math.floor(rect[0] + (rect[2] - rect[0]) / 2);
      const yCenter = Math.floor(rect[1] + (rect[3] - rect[1]) / 2);
      xSkew = reverseDegree * verticalDegree;
      ySkew = reverseDegree * horizontalDegree;
      xScale =
        reverseDegree * horizontalDegree < 0
          ? xScale * (1 + reverseDegree * horizontalDegree)
          : xScale * (1 - reverseDegree * horizontalDegree);
      yScale =
        reverseDegree * verticalDegree < 0
          ? yScale * (1 + reverseDegree * verticalDegree)
          : yScale * (1 - reverseDegree * verticalDegree);

      xTran =
        Math.floor(xCenter * reverseDegree * horizontalDegree) < 0
          ? -Math.floor(xCenter * reverseDegree * horizontalDegree) -
            Math.floor(yCenter * reverseDegree * verticalDegree)
          : Math.floor(xCenter * reverseDegree * horizontalDegree) -
            Math.floor(yCenter * reverseDegree * verticalDegree);

      yTran =
        Math.floor(yCenter * reverseDegree * verticalDegree) < 0
          ? -Math.floor(yCenter * reverseDegree * verticalDegree) -
            Math.floor(xCenter * reverseDegree * horizontalDegree)
          : Math.floor(yCenter * reverseDegree * verticalDegree) -
            Math.floor(xCenter * reverseDegree * horizontalDegree);
    }

    if (degree != 0) {
      this.setCanvasDegree(degree, rect, xScale, yScale);
    }

    this.ctx.transform(xScale, ySkew, xSkew, yScale, xTran, yTran);
    this.ctx.filter = this.setCanvasFilterText(target);
    this.ctx.globalAlpha = (target.transparency / 100).toFixed(2);
    // console.log('targetRect: ' + target.targetRect)
    // console.log('drawRect: ' + target.drawRect)

    // if (target.name == 'frame') {console.log('targetRect: ' + target.targetRect)};
    // if (target.name == 'frame') {console.log('target.drawRect: ' + target.drawRect)};
    switch (target.type) {
      case "image":
        this.ctx.drawImage(
          target.value,
          drawImageX,
          drawImageY,
          drawWidth,
          drawHeight,
        );
        break;
      case "text":
        this.drawText(target, rect);
        break;
      case "line":
        this.drawLine(target, rect);
        break;
      case "rect":
        this.drawRect(target, rect);
        break;
      case "circle":
        this.drawCircle(target, rect);
        break;
      case "polygon":
        this.drawPolygon(target, rect);
        break;
      case "tag":
        this.drawTag(target, rect);
        break;
      case "itemScaleX":
        this.drawItemScaleX(target, rect);
        break;
      case "itemScaleY":
        this.drawItemScaleY(target, rect);
        break;
      case "emptyValue":
        if (target.value) {
          this.ctx.drawImage(
            target.value,
            drawImageX,
            drawImageY,
            drawWidth,
            drawHeight,
          );
        }
        break;
      case "imageData":
        if (target.transparency !== 100) {
          const newImageData = target.value;
          this.setImageTransparency(target, newImageData);
          this.ctx.putImageData(
            newImageData,
            target.drawRect[0],
            target.drawRect[1],
          );
        } else {
          this.ctx.putImageData(
            target.value,
            target.drawRect[0],
            target.drawRect[1],
          );
        }
        break;
      default:
    }
    this.ctx.resetTransform();
    this.ctx.restore();
  }

  drawSelectArea(rect) {
    this.ctx.lineWidth = 1;
    this.ctx.strokeStyle = this.ctx.fillStyle = this.dataSet[
      "component-operate-color"
    ];
    this.ctx.save();
    this.ctx.globalAlpha = 0.5;
    this.ctx.beginPath();
    this.ctx.fillRect(
      rect[0],
      rect[1],
      Math.abs(rect[2] - rect[0]),
      Math.abs(rect[3] - rect[1]),
    );
    this.ctx.closePath();
    this.ctx.fill();
    this.ctx.globalAlpha = 1;
    this.ctx.beginPath();
    this.ctx.strokeRect(
      rect[0],
      rect[1],
      Math.abs(rect[2] - rect[0]),
      Math.abs(rect[3] - rect[1]),
    );
    this.ctx.closePath();
    this.ctx.stroke();
    this.ctx.restore();
  }

  drawWatermark() {
    const watermark = this.dataSet["watermark-text"];
    const opacity = this.dataSet["watermark-opacity"];
    const degree = this.dataSet["watermark-degree"];

    this.ctx.save();
    this.ctx.fillStyle = this.dataSet["watermark-style"];
    this.ctx.font = this.dataSet["watermark-font"];
    this.ctx.globalAlpha = opacity;
    this.ctx.rotate((degree * Math.PI) / 180);

    const watermarkLength = Math.floor(this.ctx.measureText(watermark).width);
    let row = 1,
      shift = 0;

    const adjustX = Math.abs(
      Math.round(Math.sin((degree * Math.PI) / 180) * this.canvas.height),
    );
    const adjustY = Math.abs(
      Math.round(Math.sin((degree * Math.PI) / 180) * this.canvas.width),
    );
    let startX = 0,
      startY = 0,
      endX = 0,
      endY = 0;
    if (0 > degree && degree >= -90) {
      startX = -1 * adjustX;
      endY = adjustY;
    } else if (90 >= degree && degree > 0) {
      endX = adjustX;
      startY = -1 * adjustY;
    }

    for (
      let watermarkY = parseInt(this.ctx.font) + 10 + startY;
      watermarkY < this.canvas.height + endY;
      watermarkY += 100
    ) {
      shift = row % 2 === 1 ? 0 : watermarkLength;
      for (
        let watermarkX = 10 + startX;
        watermarkX < this.canvas.width + endX;
        watermarkX = watermarkX + watermarkLength * 2
      ) {
        this.ctx.fillText(watermark, watermarkX + shift, watermarkY);
      }
      row++;
    }
    this.ctx.restore();
  }

  drawRotateOperate(rect) {
    if (!!this.dataSet["rotate-operate-img"]) {
      this.ctx.drawImage(
        this.dataSet["rotate-operate-img"],
        rect[0],
        rect[1],
        18,
        18,
      );
    } else {
      this.ctx.save();
      this.ctx.beginPath();
      this.ctx.setLineDash([]);
      this.ctx.lineWidth = 5;
      this.ctx.fillStyle = "#ffffff";
      this.ctx.arc(rect[0] + 6, rect[1] + 6, 10, 0, 2 * Math.PI);
      this.ctx.fill();
      this.ctx.closePath();
      this.ctx.beginPath();
      this.ctx.lineWidth = 3;
      this.ctx.strokeStyle = "#666666";
      this.ctx.arc(rect[0] + 6, rect[1] + 6, 6, 0, 2 * Math.PI);
      this.ctx.stroke();
      this.ctx.closePath();
      this.ctx.beginPath();
      this.ctx.lineWidth = 3;
      this.ctx.strokeStyle = "#ffffff";
      this.ctx.moveTo(rect[0], rect[1]);
      this.ctx.lineTo(rect[2] - 6, rect[3] - 6);
      this.ctx.stroke();
      this.ctx.closePath();
      this.ctx.restore();
    }
  }

  drawHorizontalOperate(radius, rect) {
    let pointX = rect[0] + Math.floor((rect[2] - rect[0]) / 2);
    let pointY = rect[3] + radius + 12;

    if (!!this.dataSet["vertical-operate-img"]) {
      this.ctx.drawImage(
        this.dataSet["vertical-operate-img"],
        pointX - 10,
        pointY - 10,
        20,
        20,
      );
    } else {
      // 1. 竖直虚线
      this.ctx.beginPath();
      this.ctx.lineWidth = 1;
      this.ctx.setLineDash([3, 1]);

      let gradient = this.ctx.createLinearGradient(
        pointX - 12,
        pointY - 6,
        pointX + 12,
        pointY + 6,
      );
      gradient.addColorStop("0", "#bbbbbb");
      gradient.addColorStop("0.5", "#666666");
      gradient.addColorStop("1", "#bbbbbb");
      this.ctx.strokeStyle = gradient;
      this.ctx.moveTo(pointX, pointY - 6);
      this.ctx.lineTo(pointX, pointY + 6);
      this.ctx.stroke();
      this.ctx.closePath();
      // 2. left arrow
      this.ctx.setLineDash([]);
      this.ctx.beginPath();
      this.ctx.moveTo(pointX - 4, pointY - 2);
      this.ctx.lineTo(pointX - 12, pointY - 2);
      this.ctx.moveTo(pointX - 12, pointY - 2);
      this.ctx.lineTo(pointX - 8, pointY + 2);
      // 3. right arrow
      this.ctx.moveTo(pointX + 4, pointY - 2);
      this.ctx.lineTo(pointX + 12, pointY - 2);
      this.ctx.moveTo(pointX + 12, pointY - 2);
      this.ctx.lineTo(pointX + 8, pointY + 2);
      this.ctx.stroke();
      this.ctx.closePath();
    }
  }

  drawVerticalOperate(radius, rect) {
    let pointX = rect[2] + radius + 12;
    let pointY = rect[1] + Math.floor((rect[3] - rect[1]) / 2);

    if (!!this.dataSet["horizontal-operate-img"]) {
      this.ctx.drawImage(
        this.dataSet["horizontal-operate-img"],
        pointX - 10,
        pointY - 10,
        20,
        20,
      );
    } else {
      // 1. 水平虚线
      this.ctx.beginPath();
      this.ctx.lineWidth = 1;
      this.ctx.setLineDash([3, 1]);
      let gradient = this.ctx.createLinearGradient(
        pointX - 6,
        pointY - 12,
        pointX + 6,
        pointY + 12,
      );
      gradient.addColorStop("0", "#bbbbbb");
      gradient.addColorStop("0.5", "#666666");
      gradient.addColorStop("1", "#bbbbbb");
      this.ctx.strokeStyle = gradient;
      this.ctx.moveTo(pointX - 6, pointY);
      this.ctx.lineTo(pointX + 6, pointY);
      this.ctx.stroke();
      this.ctx.closePath();
      // 2. up arrow
      this.ctx.setLineDash([]);
      this.ctx.beginPath();
      this.ctx.moveTo(pointX - 2, pointY - 4);
      this.ctx.lineTo(pointX - 2, pointY - 12);
      this.ctx.moveTo(pointX - 2, pointY - 12);
      this.ctx.lineTo(pointX + 2, pointY - 8);
      // 3. down arrow
      this.ctx.moveTo(pointX - 2, pointY + 4);
      this.ctx.lineTo(pointX - 2, pointY + 12);
      this.ctx.moveTo(pointX - 2, pointY + 12);
      this.ctx.lineTo(pointX + 2, pointY + 8);
      this.ctx.stroke();
      this.ctx.closePath();
    }
  }

  drawLine(target, rect) {
    let gradient;
    if (target.gradient.length !== 0) {
      gradient = this.ctx.createLinearGradient(
        rect[0],
        rect[1],
        rect[2],
        rect[3],
      );
      for (let i = 0; i < target.gradient.length; i++) {
        gradient.addColorStop(
          (i / target.gradient.length).toFixed(2).toString(),
          target.gradient[i],
        );
      }
    }

    this.ctx.strokeStyle = gradient ? gradient : target.fillStyle;
    this.ctx.lineWidth = target.lineWidth;
    this.ctx.beginPath();
    this.ctx.moveTo(rect[0], rect[1]);
    this.ctx.lineTo(rect[2], rect[3]);
    this.ctx.closePath();
    this.ctx.stroke();
  }

  drawRect(target, rect) {
    let gradient;
    if (target.gradient.length !== 0) {
      gradient = this.ctx.createLinearGradient(
        rect[0],
        rect[1],
        rect[2],
        rect[3],
      );
      for (let i = 0; i < target.gradient.length; i++) {
        gradient.addColorStop(
          (i / target.gradient.length).toFixed(2).toString(),
          target.gradient[i],
        );
      }
    }

    this.ctx.lineWidth = target.lineWidth;
    if (target.isFillArea) {
      this.ctx.fillStyle = gradient ? gradient : target.fillStyle;
      this.ctx.beginPath();
      this.ctx.fillRect(
        rect[0],
        rect[1],
        Math.abs(rect[2] - rect[0]),
        Math.abs(rect[3] - rect[1]),
      );
      this.ctx.closePath();
      this.ctx.fill();
    } else {
      this.ctx.strokeStyle = gradient ? gradient : target.fillStyle;
      this.ctx.beginPath();
      this.ctx.strokeRect(
        rect[0],
        rect[1],
        Math.abs(rect[2] - rect[0]),
        Math.abs(rect[3] - rect[1]),
      );
      this.ctx.closePath();
      this.ctx.stroke();
    }
  }

  drawCircle(target, rect) {
    let gradient;
    if (target.gradient.length !== 0) {
      gradient = this.ctx.createLinearGradient(
        rect[0],
        rect[1],
        rect[2],
        rect[3],
      );
      for (let i = 0; i < target.gradient.length; i++) {
        gradient.addColorStop(
          (i / target.gradient.length).toFixed(2).toString(),
          target.gradient[i],
        );
      }
    }
    this.ctx.lineWidth = target.lineWidth;
    if (target.isFillArea) {
      this.ctx.fillStyle = gradient ? gradient : target.fillStyle;
      this.ctx.beginPath();
      this.ctx.ellipse(
        rect[0] + Math.floor(Math.abs(rect[2] - rect[0]) / 2),
        rect[1] + Math.floor(Math.abs(rect[3] - rect[1]) / 2),
        Math.floor(Math.abs(rect[2] - rect[0]) / 2),
        Math.floor(Math.abs(rect[3] - rect[1]) / 2),
        0,
        0,
        2 * Math.PI,
      );
      this.ctx.closePath();
      this.ctx.fill();
    } else {
      this.ctx.strokeStyle = gradient ? gradient : target.fillStyle;
      this.ctx.beginPath();
      this.ctx.ellipse(
        rect[0] + Math.floor(Math.abs(rect[2] - rect[0]) / 2),
        rect[1] + Math.floor(Math.abs(rect[3] - rect[1]) / 2),
        Math.floor(Math.abs(rect[2] - rect[0]) / 2),
        Math.floor(Math.abs(rect[3] - rect[1]) / 2),
        0,
        0,
        2 * Math.PI,
      );
      this.ctx.closePath();
      this.ctx.stroke();
    }
  }

  drawPolygon(target, rect) {
    // drawStar(75, 100, 5, 30, 15);
    // drawStar(175, 100, 12, 30, 10);
    // drawStar(75, 200, 6, 30, 15);
    // drawStar(175, 200, 20, 30, 25);
    const centerX =
      target.center && target.center[0]
        ? target.center[0]
        : Math.floor(Math.abs((rect[2] - rect[0]) / 2));
    const centerY =
      target.center && target.center[1]
        ? target.center[1]
        : Math.floor(Math.abs((rect[3] - rect[1]) / 2));
    const spikes = target.polygon[0];
    const outerRadius = target.polygon[1];
    const innerRadius = target.polygon[2];
    let x, y;
    let rotation = (Math.PI / 2) * 3;
    let step = Math.PI / spikes;

    this.ctx.beginPath();
    this.ctx.moveTo(centerX, centerY - outerRadius);
    for (let i = 0; i < spikes; i++) {
      x = centerX + Math.cos(rotation) * outerRadius;
      y = centerY + Math.sin(rotation) * outerRadius;
      this.ctx.lineTo(x, y);
      rotation += step;

      x = centerX + Math.cos(rotation) * innerRadius;
      y = centerY + Math.sin(rotation) * innerRadius;
      this.ctx.lineTo(x, y);
      rotation += step;
    }
    this.ctx.lineTo(centerX, centerY - outerRadius);
    this.ctx.closePath();

    let gradient;
    if (target.gradient.length !== 0) {
      gradient = this.ctx.createLinearGradient(
        rect[0],
        rect[1],
        rect[2],
        rect[3],
      );
      for (let i = 0; i < target.gradient.length; i++) {
        gradient.addColorStop(
          (i / target.gradient.length).toFixed(2).toString(),
          target.gradient[i],
        );
      }
    }

    this.ctx.lineWidth = target.lineWidth;
    if (target.isFillArea) {
      this.ctx.fillStyle = gradient ? gradient : target.fillStyle;
      this.ctx.fill();
    } else {
      this.ctx.strokeStyle = gradient ? gradient : target.fillStyle;
      this.ctx.stroke();
    }
  }

  drawText(target, rect) {
    let gradient;
    if (target.gradient.length !== 0) {
      gradient = this.ctx.createLinearGradient(
        rect[0],
        rect[1],
        rect[2],
        rect[3],
      );
      for (let i = 0; i < target.gradient.length; i++) {
        gradient.addColorStop(
          (i / target.gradient.length).toFixed(2).toString(),
          target.gradient[i],
        );
      }
    }

    let fontSize = Math.floor(
      ((rect[2] - rect[0]) * 10) / this.ctx.measureText(target.value).width,
    );
    target.fontSize =
      fontSize < rect[3] - rect[1] ? fontSize : rect[3] - rect[1];

    this.ctx.fillStyle = gradient ? gradient : target.fillStyle;
    this.ctx.font = target.fontSize + "px " + target.fontFamily;
    this.ctx.fillText(target.value, rect[0], rect[3]);
  }

  drawTag(target, rect) {
    const defaultStyle = target.backgroundColor
      ? target.backgroundColor
      : target.fillStyle
      ? target.fillStyle
      : this.dataSet["tag-style"];
    const dotStyle = target.tagColor ? target.tagColor : defaultStyle;
    const textStyle = this.dataSet["tag-text-style"];
    const textLength = Math.floor(
      (this.ctx.measureText(target.value).width * parseInt(target.fontSize)) /
        10,
    );
    const radius = this.shiftPixel;

    const circle = [
      rect[0] + radius,
      rect[1] + Math.floor((rect[3] - rect[1]) / 4),
    ];

    const tagRect = [
      rect[0] + 4 * radius,
      rect[1],
      rect[0] + textLength + 40 > rect[2] ? rect[0] + textLength + 40 : rect[2],
      rect[3],
    ];

    const tip = {
      a: [
        rect[0] + 2 * radius + Math.floor(radius / 2),
        rect[1] + Math.floor((rect[3] - rect[1]) / 4),
      ],
      b: [
        rect[0] + 4 * radius,
        rect[1] + Math.floor((rect[3] - rect[1]) / 4) - radius < tagRect[1]
          ? tagRect[1]
          : rect[1] + Math.floor((rect[3] - rect[1]) / 4) - radius,
      ],
      c: [
        rect[0] + 4 * radius,
        rect[1] + Math.floor((rect[3] - rect[1]) / 4) + radius > tagRect[3]
          ? tagRect[3]
          : rect[1] + Math.floor((rect[3] - rect[1]) / 4) + radius,
      ],
    };

    this.ctx.fillStyle = dotStyle;
    let fontSize = Math.floor(
      ((rect[2] - rect[0] - 4 * radius - 20) * 10) /
        this.ctx.measureText(target.value).width,
    );
    target.fontSize =
      fontSize < rect[3] - rect[1] - parseInt(target.fontSize)
        ? fontSize
        : rect[3] - rect[1] - parseInt(target.fontSize);
    this.ctx.font =
      !!target.fontSize && !!target.fontFamily
        ? target.fontSize + "px " + target.fontFamily
        : "16px Calibri";

    // Circle
    this.ctx.beginPath();
    this.ctx.arc(circle[0], circle[1], radius, 0, 2 * Math.PI);
    this.ctx.closePath();
    this.ctx.fill();

    this.ctx.fillStyle = this.ctx.strokeStyle = defaultStyle;
    this.ctx.beginPath();
    this.ctx.lineJoin = "round";
    this.ctx.lineWidth = radius;
    this.ctx.moveTo(tip.a[0], tip.a[1]);
    this.ctx.lineTo(tip.b[0], tip.b[1]);
    this.ctx.lineTo(tip.c[0], tip.c[1]);
    this.ctx.lineTo(tip.a[0], tip.a[1]);
    this.ctx.strokeRect(
      tagRect[0] + radius / 2,
      tagRect[1] + radius / 2,
      tagRect[2] - tagRect[0] - radius,
      tagRect[3] - tagRect[1] - radius,
    );
    this.ctx.fillRect(
      tagRect[0] + radius / 2,
      tagRect[1] + radius / 2,
      tagRect[2] - tagRect[0] - radius,
      tagRect[3] - tagRect[1] - radius,
    );
    this.ctx.closePath();
    this.ctx.fill();

    // Text
    this.ctx.fillStyle = textStyle;
    this.ctx.fillText(
      target.value,
      tagRect[0] + Math.floor((tagRect[2] - tagRect[0] - textLength) / 2),
      rect[1] +
        Math.floor((rect[3] - rect[1]) / 2 + parseInt(target.fontSize) / 2),
    );
  }

  drawItemScaleX(target, rect) {
    const defaultStyle = target.fillStyle
      ? target.fillStyle
      : this.dataSet["scale-style"];
    const defaultLinewidth = this.dataSet["scale-lineWidth"];
    const textLength = Math.floor(
      (this.ctx.measureText(target.value).width * parseInt(target.fontSize)) /
        10,
    );
    this.ctx.strokeStyle = this.ctx.fillStyle = defaultStyle;
    this.ctx.font =
      !!target.fontSize && !!target.fontFamily
        ? target.fontSize + "px " + target.fontFamily
        : "16px Calibri";

    // Line
    this.ctx.beginPath();
    this.ctx.lineWidth = defaultLinewidth;
    this.ctx.moveTo(rect[0], rect[1]);
    this.ctx.lineTo(rect[0], rect[3]);

    this.ctx.moveTo(
      rect[0],
      rect[1] + Math.floor(Math.abs(rect[3] - rect[1]) / 2),
    );
    this.ctx.lineTo(
      rect[0] +
        Math.floor(Math.abs(rect[2] - rect[0]) / 2 - textLength / 2 - 10),
      rect[1] + Math.floor(Math.abs(rect[3] - rect[1]) / 2),
    );

    this.ctx.moveTo(
      rect[0] +
        Math.floor(Math.abs(rect[2] - rect[0]) / 2 + textLength / 2 + 10),
      rect[1] + Math.floor(Math.abs(rect[3] - rect[1]) / 2),
    );
    this.ctx.lineTo(
      rect[2],
      rect[1] + Math.floor(Math.abs(rect[3] - rect[1]) / 2),
    );

    this.ctx.moveTo(rect[2], rect[1]);
    this.ctx.lineTo(rect[2], rect[3]);
    this.ctx.stroke();

    // Text
    this.ctx.fillText(
      target.value,
      rect[0] + Math.floor(Math.abs(rect[2] - rect[0]) / 2 - textLength / 2),
      rect[1] +
        Math.floor(Math.abs(rect[3] - rect[1]) / 2) +
        parseInt(target.fontSize) / 2,
    );
  }

  drawItemScaleY(target, rect) {
    const defaultStyle = target.fillStyle
      ? target.fillStyle
      : this.dataSet["scale-style"];
    const defaultLinewidth = this.dataSet["scale-lineWidth"];
    const textLength = Math.floor(
      (this.ctx.measureText(target.value).width * parseInt(target.fontSize)) /
        10,
    );
    this.ctx.strokeStyle = this.ctx.fillStyle = defaultStyle;
    this.ctx.font =
      !!target.fontSize && !!target.fontFamily
        ? target.fontSize + "px " + target.fontFamily
        : "16px Calibri";

    // Line
    this.ctx.beginPath();
    this.ctx.lineWidth = defaultLinewidth;
    this.ctx.moveTo(rect[0], rect[1]);
    this.ctx.lineTo(rect[2], rect[1]);

    this.ctx.moveTo(
      rect[0] + Math.floor(Math.abs(rect[2] - rect[0]) / 2),
      rect[1],
    );
    this.ctx.lineTo(
      rect[0] + Math.floor(Math.abs(rect[2] - rect[0]) / 2),
      rect[1] +
        Math.floor(
          Math.abs(rect[3] - rect[1]) / 2 - parseInt(target.fontSize) / 2 - 10,
        ),
    );

    this.ctx.moveTo(
      rect[0] + Math.floor(Math.abs(rect[2] - rect[0]) / 2),
      rect[1] +
        Math.floor(
          Math.abs(rect[3] - rect[1]) / 2 + parseInt(target.fontSize) / 2 + 10,
        ),
    );
    this.ctx.lineTo(
      rect[0] + Math.floor(Math.abs(rect[2] - rect[0]) / 2),
      rect[3],
    );

    this.ctx.moveTo(rect[0], rect[3]);
    this.ctx.lineTo(rect[2], rect[3]);
    this.ctx.stroke();

    // Text
    this.ctx.fillText(
      target.value,
      rect[0] + Math.floor(Math.abs(rect[2] - rect[0]) / 2 - textLength / 2),
      rect[1] +
        Math.floor(
          Math.abs(rect[3] - rect[1]) / 2 + parseInt(target.fontSize) / 2,
        ),
    );
  }

  drawGroupComponentFrame() {
    if (this.groupOperateIds.length !== 0) {
      // 画群组
      let startX = this.canvas.width;
      let startY = this.canvas.height;
      let endX = 0;
      let endY = 0;
      for (let i = 0; i < this.groupOperateIds.length; i++) {
        const item = this.getTargetById(this.groupOperateIds[i]);
        if (!!item) {
          startX = startX < item.targetRect[0] ? startX : item.targetRect[0];
          startY = startY < item.targetRect[1] ? startY : item.targetRect[1];
          endX = endX > item.targetRect[2] ? endX : item.targetRect[2];
          endY = endY > item.targetRect[3] ? endY : item.targetRect[3];
        }
      }
      const item = {
        show: true,
        id: "group",
        targetRect: [startX, startY, endX, endY],
      };
      this.drawComponentFrame(item, item.targetRect, "", false, true);
      return true;
    } else {
      return false;
    }
  }

  /* Draw the target frame
   * 绘制目标对象的操作边框
   * 声明: 请勿公开展示或私自粘贴、分享、讨论本源代码, 源码及其衍生和变种的代码都已申请著作权，专利，并受到法律保护
   *
   */
  drawComponentFrame(
    target,
    rect,
    fill = "",
    isForceToDraw = false,
    isSelected = false,
  ) {
    if (
      (target.show || isForceToDraw) &&
      (target.id !== this.currentTargetId || isSelected) &&
      target.type !== "emptyValue"
    ) {
      this.ctx.save();
      const defaultStyle = isSelected
        ? this.dataSet["component-operate-color"]
        : this.dataSet["component-frame-color"];
      const defaultLinewidth = this.dataSet["component-frame-lineWidth"];
      this.ctx.strokeStyle = defaultStyle;
      this.ctx.fillStyle = this.dataSet["component-operate-color"];
      this.ctx.setLineDash([]);

      const radius = this.shiftPixel + this.ctx.lineWidth;

      // Left upper
      this.ctx.beginPath();
      this.ctx.lineWidth = defaultLinewidth;
      this.ctx.arc(rect[0], rect[1], radius, 0, 2 * Math.PI);
      fill === "leftUpper" ? this.ctx.fill() : this.ctx.stroke();
      this.ctx.closePath();

      // Middle upper
      this.ctx.beginPath();
      this.ctx.arc(
        rect[0] + Math.floor((rect[2] - rect[0]) / 2),
        rect[1],
        radius,
        0,
        2 * Math.PI,
      );
      fill === "middleUpper" ? this.ctx.fill() : this.ctx.stroke();
      this.ctx.closePath();

      // Right upper
      this.ctx.beginPath();
      this.ctx.arc(rect[2], rect[1], radius, 0, 2 * Math.PI);
      fill === "rightUpper" ? this.ctx.fill() : this.ctx.stroke();
      this.ctx.closePath();

      // Right middle
      this.ctx.beginPath();
      this.ctx.arc(
        rect[2],
        rect[1] + Math.floor((rect[3] - rect[1]) / 2),
        radius,
        0,
        2 * Math.PI,
      );
      fill === "rightMiddle" ? this.ctx.fill() : this.ctx.stroke();
      this.ctx.closePath();

      // 竖直翻转操作
      this.drawVerticalOperate(radius, rect);

      // 恢复
      this.ctx.lineWidth = defaultLinewidth;
      this.ctx.strokeStyle = defaultStyle;
      // verticalTran
      let pointX = rect[2] + radius + 12;
      let pointY = rect[1] + Math.floor((rect[3] - rect[1]) / 2);

      // Right lower
      this.ctx.setLineDash([]);
      this.ctx.beginPath();
      this.ctx.arc(rect[2], rect[3], radius, 0, 2 * Math.PI);
      fill === "rightLower" ? this.ctx.fill() : this.ctx.stroke();
      this.ctx.closePath();

      // Middle lower
      this.ctx.beginPath();
      this.ctx.arc(
        rect[0] + Math.floor((rect[2] - rect[0]) / 2),
        rect[3],
        radius,
        0,
        2 * Math.PI,
      );
      fill === "middleLower" ? this.ctx.fill() : this.ctx.stroke();
      this.ctx.closePath();

      // 水平翻转操作
      this.drawHorizontalOperate(radius, rect);

      // 恢复
      this.ctx.lineWidth = defaultLinewidth;
      this.ctx.strokeStyle = defaultStyle;
      // verticalTran
      pointX = rect[0] + Math.floor((rect[2] - rect[0]) / 2);
      pointY = rect[3] + radius + 12;

      // Left lower
      this.ctx.setLineDash([]);
      this.ctx.beginPath();
      this.ctx.arc(rect[0], rect[3], radius, 0, 2 * Math.PI);
      fill === "leftLower" ? this.ctx.fill() : this.ctx.stroke();
      this.ctx.closePath();

      // Left Middle
      this.ctx.beginPath();
      this.ctx.arc(
        rect[0],
        rect[1] + Math.floor((rect[3] - rect[1]) / 2),
        radius,
        0,
        2 * Math.PI,
      );
      fill === "leftMiddle" ? this.ctx.fill() : this.ctx.stroke();
      this.ctx.closePath();

      // 1 左顶部线段
      this.ctx.setLineDash([3, 1]);
      this.ctx.beginPath();
      this.ctx.moveTo(rect[0] + this.shiftPixel, rect[1]);
      this.ctx.lineTo(
        rect[0] + Math.floor((rect[2] - rect[0]) / 2) - this.shiftPixel,
        rect[1],
      );

      // 2 右顶部线段
      this.ctx.moveTo(
        rect[0] + Math.floor((rect[2] - rect[0]) / 2) + this.shiftPixel,
        rect[1],
      );
      this.ctx.lineTo(rect[2] - this.shiftPixel, rect[1]);

      // 3 右上半线段
      this.ctx.moveTo(rect[2], rect[1] + this.shiftPixel);
      this.ctx.lineTo(
        rect[2],
        rect[1] + Math.floor((rect[3] - rect[1]) / 2) - this.shiftPixel,
      );

      // 4 右下半线段
      this.ctx.moveTo(
        rect[2],
        rect[1] + Math.floor((rect[3] - rect[1]) / 2) + this.shiftPixel,
      );
      this.ctx.lineTo(rect[2], rect[3] - this.shiftPixel);

      // 5 右边底部线段
      this.ctx.moveTo(rect[2] - this.shiftPixel, rect[3]);
      this.ctx.lineTo(
        rect[0] + Math.floor((rect[2] - rect[0]) / 2) + this.shiftPixel,
        rect[3],
      );

      // 6 左底部线段
      this.ctx.moveTo(
        rect[0] + Math.floor((rect[2] - rect[0]) / 2) - this.shiftPixel,
        rect[3],
      );
      this.ctx.lineTo(rect[0] + this.shiftPixel, rect[3]);

      // 7 左上半线段
      this.ctx.moveTo(rect[0], rect[3] - this.shiftPixel);
      this.ctx.lineTo(
        rect[0],
        rect[1] + Math.floor((rect[3] - rect[1]) / 2) + this.shiftPixel,
      );

      // 8 左下半线段
      this.ctx.moveTo(
        rect[0],
        rect[1] + Math.floor((rect[3] - rect[1]) / 2) - this.shiftPixel,
      );
      this.ctx.lineTo(rect[0], rect[1] + this.shiftPixel);
      this.ctx.stroke();
      this.ctx.restore();

      return this.getTargetOperations(rect);
    } else if (target.type === "emptyValue") {
      this.ctx.save();
      this.ctx.lineWidth = this.dataSet["component-frame-lineWidth"];
      this.ctx.strokeStyle = isSelected
        ? this.dataSet["component-operate-color"]
        : this.dataSet["component-frame-color"];

      // 1
      this.ctx.setLineDash([3, 1]);
      this.ctx.beginPath();
      this.ctx.moveTo(rect[0], rect[1]);
      this.ctx.lineTo(rect[2], rect[1]);

      // 2
      this.ctx.moveTo(rect[2], rect[1]);
      this.ctx.lineTo(rect[2], rect[3]);

      // 3
      this.ctx.moveTo(rect[2], rect[3]);
      this.ctx.lineTo(rect[0], rect[3]);

      // 4
      this.ctx.moveTo(rect[0], rect[3]);
      this.ctx.lineTo(rect[0], rect[1]);

      this.ctx.stroke();
      this.ctx.restore();

      return this.getTargetOperations(rect);
    }
  }

  isMouseInOperationSection(e, target) {
    const x = this.getMousePos(e).x;
    const y = this.getMousePos(e).y;
    if (!target || !target.targetRect || !target.operations) {
      return false;
    } else {
      const targetRect = target.targetRect;
      if (
        // left upper
        !!target.operations.leftUpperRotation &&
        targetRect[0] - target.operations.leftUpperRotation[0] <= x &&
        targetRect[0] >= x &&
        targetRect[1] - target.operations.leftUpperRotation[1] <= y &&
        targetRect[1] >= y
      ) {
        return true;
      } else if (
        // right upper
        !!target.operations.rightUpperRotation &&
        targetRect[2] <= x &&
        targetRect[2] + target.operations.rightUpperRotation[2] >= x &&
        targetRect[1] - target.operations.rightUpperRotation[1] <= y &&
        targetRect[1] >= y
      ) {
        return true;
      }
      if (
        // right lower
        !!target.operations.rightLowerRotation &&
        targetRect[2] <= x &&
        targetRect[2] + target.operations.rightLowerRotation[2] >= x &&
        targetRect[3] <= y &&
        targetRect[3] + target.operations.rightLowerRotation[3] >= y
      ) {
        return true;
      }
      if (
        // left lower
        !!target.operations.leftLowerRotation &&
        targetRect[0] - target.operations.leftLowerRotation[0] <= x &&
        targetRect[0] >= x &&
        targetRect[3] <= y &&
        targetRect[3] + target.operations.leftLowerRotation[3] >= y
      ) {
        return true;
      }
      if (
        // vertical
        !!target.operations.verticalTran &&
        targetRect[2] <= x &&
        targetRect[2] + target.operations.verticalTran[2] >= x &&
        targetRect[1] +
          Math.floor((targetRect[3] - targetRect[1]) / 2) -
          Math.floor(
            (target.operations.verticalTran[3] -
              target.operations.verticalTran[1]) /
              2,
          ) <=
          y &&
        targetRect[1] +
          Math.floor((targetRect[3] - targetRect[1]) / 2) +
          Math.floor(
            (target.operations.verticalTran[3] -
              target.operations.verticalTran[1]) /
              2,
          ) >=
          y
      ) {
        return true;
      }
      if (
        // horizontal
        !!target.operations.horizontalTran &&
        targetRect[0] +
          Math.floor((targetRect[2] - targetRect[0]) / 2) -
          Math.floor(
            (target.operations.horizontalTran[2] -
              target.operations.horizontalTran[0]) /
              2,
          ) <=
          x &&
        targetRect[0] +
          Math.floor((targetRect[2] - targetRect[0]) / 2) +
          Math.floor(
            (target.operations.horizontalTran[2] -
              target.operations.horizontalTran[0]) /
              2,
          ) >=
          x &&
        targetRect[3] <= y &&
        targetRect[3] + target.operations.horizontalTran[3] >= y
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  formatDrawRect(drawRect) {
    drawRect[4] = drawRect[0] > drawRect[2];
    drawRect[5] = drawRect[1] > drawRect[3];

    return drawRect;
  }

  interactive(canvas) {
    // 添加鼠标按下事件 移动事件等
    // 对搜索当前object 和操作点信息(缩放旋转)
    let shouldTargetMove = false;
    let shouldTargetOperate = false;
    let shouldTargetRotate = false;
    let shouldHorizontalReverse = false;
    let shouldVerticalReverse = false;
    let shouldRestore = false;
    let shouldDraweShape = false;
    let shouldDrawLeftUpper = true;
    let shouldDrawLeftLower = true;
    let shouldDrawRightUpper = true;
    let shouldDrawRightLower = true;
    let keyShiftDown = false;
    let keyCtrlDown = false;
    let drawFrameId = "";
    let selectAreaRect = [];
    let rotateButton;
    let operationTarget;
    let mouseDownPoint;
    let drawRect;
    let targetRect;
    let target;
    let operate;
    let diffX = 0;
    let diffY = 0;

    //todo
    let resetOperationFlags = (x, y) => {
      if (x > canvas.width || y > canvas.height || x < 0 || y < 0) {
        shouldTargetMove = false;
        shouldTargetOperate = false;
        shouldTargetRotate = false;
        shouldHorizontalReverse = false;
        shouldVerticalReverse = false;
        shouldRestore = false;
        shouldDraweShape = false;
        if (this.currentTargetId) {
          const currentTarget = this.getTargetById(this.currentTargetId);
          if (!!currentTarget) {
            this.objects[currentTarget.index]["show"] = true;
          }
        }
      }
    };

    canvas.onmousedown = e => {
      mouseDownPoint = {
        x: this.getMousePos(e).x,
        y: this.getMousePos(e).y,
      };

      // Case 操作1: 新绘制形状
      if (this.isDrawingShape && !!this.drawingShape) {
        this.drawingShape.show = true;
        shouldDraweShape = true;

        canvas.onmouseup = () => {
          // 更新中心点和宽高信息 Update center, width and height
          this.drawingShape = this.updateTargetPosition(
            this.drawingShape,
            this.drawingShape.drawRect,
          );
          if (this.useSet) {
            this.updateSets();
          }
          this.objects.push(this.drawingShape);
          //todo this.updateStep();
          this.isDrawingShape = false;
          this.drawingShape = null;
          shouldDraweShape = false;
        };

        // Case 操作2: 多选
      } else if (keyCtrlDown || keyShiftDown) {
        // 获取target对象
        target = this.getTopTarget(e, false); //todo
        if (!!target) {
          // 已选中的先添加
          if (!!this.currentTargetId) {
            this.addGroupOperateId(this.currentTargetId);
          }
          this.addGroupOperateId(target.id);

          canvas.onmouseup = () => {
            this.restore(this.objects, true);
            //todo this.updateStep();
            canvas.onmouseup = null;
          };
        } else {
          keyCtrlDown = false;
          keyShiftDown = false;
        }

        // Case 操作3: 操作对象
      } else {
        // 获取target对象
        target = this.getTopTarget(e, false); //todo

        if (!!target) {
          // 操作3 case1: target操作 清空group
          operate = this.getOperate(e, target);
          this.currentTargetId = target.id;
          this.groupOperateIds = [];

          if (!!operate && target.type !== "emptyValue") {
            shouldTargetOperate = true;
          } else {
            shouldTargetMove = true;
          }
          this.objects[target.index]["show"] = false;

          // 鼠标与对象的相对位置
          diffX = this.getMousePos(e).x - target.targetRect[0];
          diffY = this.getMousePos(e).y - target.targetRect[1];

          // 当前画布加入缓存,将要操作的目标对象拿出单独处理
          this.cacheCanvas();
          this.drawTarget(target, target.drawRect);
          shouldRestore = true;

          // 鼠标抬起清除绑定事件并存储
          canvas.onmouseup = () => {
            shouldTargetMove = false;
            shouldTargetOperate = false;
            resetOperationFlags(mouseDownPoint.x, mouseDownPoint.y); //todo
            drawFrameId = "";

            // Up事件可能在画布外
            if (!!target) {
              // todo Up事件可能在画布外
              target.drawRect = !!drawRect ? drawRect : target.drawRect;
              target.targetRect = !!targetRect ? targetRect : target.targetRect;

              // Update center, width and height 更新中心点和宽高信息
              target = this.updateTargetPosition(target, target.drawRect);
              if (this.useSet) {
                this.updateSets();
              }

              //todo this.objects.push(target);
              this.objects[target.index] = target;
              this.objects[target.index]["show"] = true;
            }

            target = null;
            operate = null;
            targetRect = null;
            drawRect = null;
            keyShiftDown = false;
            keyCtrlDown = false;
            canvas.onmouseup = null;

            // 完成操作更新并清除
            this.restore(this.objects, true);
            //todo this.updateStep();
            shouldRestore = false;
          };
        } else if (
          !!operationTarget &&
          operationTarget.type !== "emptyValue" &&
          (this.getOperate(e, operationTarget) === "leftUpperRotation" ||
            this.getOperate(e, operationTarget) === "rightUpperRotation" ||
            this.getOperate(e, operationTarget) === "rightLowerRotation" ||
            this.getOperate(e, operationTarget) === "leftLowerRotation")
        ) {
          // 操作3 case2: 大小调整操作
          shouldTargetRotate = true;
          rotateButton = this.getOperate(e, operationTarget);
          this.currentTargetId = operationTarget.id;
          this.groupOperateIds = [];

          canvas.onmouseup = () => {
            //todo operationTarget = null;
            //todo this.updateStep();
            rotateButton = "";
            shouldTargetRotate = false;
            canvas.onmouseup = null;
          };
        } else if (
          !!operationTarget &&
          this.getOperate(e, operationTarget) === "horizontalTran" &&
          operationTarget.type !== "emptyValue"
        ) {
          // 操作3 case3: 水平扭转操作
          shouldHorizontalReverse = true;
          this.currentTargetId = operationTarget.id;
          this.groupOperateIds = [];
          canvas.onmouseup = () => {
            // todo operationTarget = null;
            //todo this.updateStep();
            shouldHorizontalReverse = false;
            canvas.onmouseup = null;
          };
        } else if (
          !!operationTarget &&
          this.getOperate(e, operationTarget) === "verticalTran" &&
          operationTarget.type !== "emptyValue"
        ) {
          // 操作3 case4: 竖直扭转操作
          shouldVerticalReverse = true;
          this.currentTargetId = operationTarget.id;
          this.groupOperateIds = [];
          canvas.onmouseup = () => {
            // todo operationTarget = null;
            //todo this.updateStep();
            shouldVerticalReverse = false;
            canvas.onmouseup = null;
          };
        } else {
          // 操作3 case5: 没有点中任何内容

          // 解决mouseup在画布外问题
          if (this.currentTargetId) {
            const previousTarget = this.getTargetById(
              this.currentTargetId,
              true,
            );
            this.objects[previousTarget.index]["show"] = true;
          }
          this.currentTargetId = null;
          this.groupOperateIds = [];
          operationTarget = null;
          this.restore(this.objects, true);
          this.cacheCanvas();

          selectAreaRect = [
            mouseDownPoint.x,
            mouseDownPoint.y,
            mouseDownPoint.x,
            mouseDownPoint.y,
          ];
          canvas.onmouseup = () => {
            // 计算选区的对象
            if (selectAreaRect.length !== 0) {
              this.groupOperateIds = this.getGroupTargetIds(selectAreaRect);

              // 当只有一个的时候 按照单选处理
              if (this.groupOperateIds.length === 1) {
                this.currentTargetId = this.groupOperateIds[0];
                this.groupOperateIds = [];
              }
              this.restore(this.objects, true);
            }
            //todo this.updateStep();
            selectAreaRect = [];
            canvas.onmouseup = null;
          };
        }
      }
    };

    //canvas.onmousemove
    let test = e => {};

    canvas.onmousemove = e => {
      if (selectAreaRect.length !== 0) {
        // CASE 选区选择
        this.restore(this.objects);
        const stopX = this.getMousePos(e).x;
        const stopY = this.getMousePos(e).y;

        selectAreaRect =
          mouseDownPoint.x > stopX
            ? [stopX, mouseDownPoint.y, mouseDownPoint.x, stopY]
            : [mouseDownPoint.x, mouseDownPoint.y, stopX, stopY];
        if (mouseDownPoint.y > stopY) {
          selectAreaRect[1] = stopY;
          selectAreaRect[3] = mouseDownPoint.y;
        }

        this.drawSelectArea(selectAreaRect);
      } else if (this.groupOperateIds.length !== 0) {
      } else if (shouldTargetMove && !!target) {
        // CASE 先清除之前内容然后重新绘制
        drawRect = target.drawRect;
        targetRect = target.targetRect;
        const x = this.getMousePos(e).x;
        const y = this.getMousePos(e).y;

        this.restore(this.objects);

        targetRect = [
          x - diffX,
          y - diffY,
          x - diffX + Math.floor(targetRect[2] - targetRect[0]),
          y - diffY + Math.floor(targetRect[3] - targetRect[1]),
        ];

        // 更新移动的Draw Rect
        drawRect = getMoveDrawRect(drawRect, targetRect, x, y, diffX, diffY);

        // drawRect =
        target.targetRect = targetRect;
        target = this.updateTargetPosition(target, drawRect);
        if (this.useSet) {
          this.updateSets();
        }

        this.drawTarget(target, drawRect);

        // 移动中移出画布
        if (x > canvas.width || y > canvas.height || x < 0 || y < 0) {
          shouldTargetMove = false;
          target.drawRect = drawRect;
          target.targetRect = targetRect;
          target = this.updateTargetPosition(target, drawRect);
          this.objects[target.index] = target;
          this.objects[target.index]["show"] = true;
        }

        // 变形操作 Transform Operation
      } else if (shouldTargetOperate && !!target) {
        // CASE 变形操作
        drawRect = target.drawRect;
        targetRect = target.targetRect;
        let x = this.getMousePos(e).x;
        let y = this.getMousePos(e).y;
        const xLength = Math.abs(drawRect[0] - drawRect[2]);
        const yLength = Math.abs(drawRect[1] - drawRect[3]);

        this.restore(this.objects);

        const isHorizontalReversed = !!drawRect[4];
        const isVerticalReversed = !!drawRect[5];
        let rect;
        switch (operate) {
          case "leftMiddle":
            canvas.style.cursor = "ew-resize";

            // 反复水平反转，基于当前定界框去更新drawRect
            rect = [x, targetRect[1], targetRect[2], targetRect[3]];
            drawRect = [x, targetRect[1], targetRect[2], targetRect[3]];
            drawRect = getOperatingDrawRect(
              rect,
              drawRect,
              isHorizontalReversed,
              isVerticalReversed,
            );

            if (x > targetRect[2]) {
              targetRect = [targetRect[2], targetRect[1], x, targetRect[3]];
            } else {
              targetRect = [x, targetRect[1], targetRect[2], targetRect[3]];
            }
            this.drawTarget(target, drawRect);
            break;
          case "rightMiddle":
            canvas.style.cursor = "ew-resize";

            // 反复水平反转，基于当前定界框去更新drawRect
            rect = [targetRect[0], targetRect[1], x, targetRect[3]];
            drawRect = [targetRect[0], targetRect[1], x, targetRect[3]];
            drawRect = getOperatingDrawRect(
              rect,
              drawRect,
              isHorizontalReversed,
              isVerticalReversed,
            );

            if (x < targetRect[0]) {
              targetRect = [x, targetRect[1], targetRect[0], targetRect[3]];
            } else {
              targetRect = [targetRect[0], targetRect[1], x, targetRect[3]];
            }

            this.drawTarget(target, drawRect);
            break;
          case "middleUpper":
            canvas.style.cursor = "ns-resize";

            // 反复垂直反转，基于当前定界框去更新drawRect
            rect = [targetRect[0], y, targetRect[2], targetRect[3]];
            drawRect = [targetRect[0], y, targetRect[2], targetRect[3]];
            drawRect = getOperatingDrawRect(
              rect,
              drawRect,
              isHorizontalReversed,
              isVerticalReversed,
            );
            if (y > targetRect[3]) {
              targetRect = [targetRect[0], targetRect[3], targetRect[2], y];
            } else {
              targetRect = [targetRect[0], y, targetRect[2], targetRect[3]];
            }

            this.drawTarget(target, drawRect);
            break;
          case "middleLower":
            canvas.style.cursor = "ns-resize";

            // 反复垂直反转，基于当前定界框去更新drawRect
            rect = [targetRect[0], targetRect[1], targetRect[2], y];
            drawRect = [targetRect[0], targetRect[1], targetRect[2], y];
            drawRect = getOperatingDrawRect(
              rect,
              drawRect,
              isHorizontalReversed,
              isVerticalReversed,
            );
            if (y < targetRect[1]) {
              targetRect = [targetRect[0], y, targetRect[2], targetRect[1]];
            } else {
              targetRect = [targetRect[0], targetRect[1], targetRect[2], y];
            }

            this.drawTarget(target, drawRect);
            break;
          case "leftUpper":
            canvas.style.cursor = "nwse-resize";

            if (keyShiftDown) {
              if (x > drawRect[2] && y > drawRect[3]) {
                rect = targetRect = [drawRect[2], drawRect[3], x, y];
                drawRect = targetRect;
              } else if (y > drawRect[3]) {
                rect = targetRect = [drawRect[0], drawRect[3], drawRect[2], y];
                drawRect = targetRect;
              } else if (x > drawRect[2]) {
                rect = targetRect = [drawRect[2], drawRect[1], x, drawRect[3]];
                drawRect = targetRect;
              } else {
                rect = targetRect = [x, y, drawRect[2], drawRect[3]];
                drawRect = targetRect;
              }
            } else {
              // X移动偏移大于y情况，依照y值比例变动
              if (Math.abs(x - drawRect[2]) > Math.abs(y - drawRect[3])) {
                x = (Math.abs(y - drawRect[3]) / yLength) * xLength;
                x = drawRect[2] - Math.floor(x);
                // Y移动偏移大于x情况，依照y值比例变动
              } else {
                y = (Math.abs(x - drawRect[2]) / xLength) * yLength;
                y = drawRect[3] - Math.floor(y);
              }
              rect = targetRect = [x, y, drawRect[2], drawRect[3]];
              drawRect = targetRect;
            }

            // 反复垂直反转，基于当前定界框去更新drawRect
            drawRect = getOperatingDrawRect(
              rect,
              drawRect,
              isHorizontalReversed,
              isVerticalReversed,
            );
            this.drawTarget(target, drawRect);
            break;
          case "leftLower":
            canvas.style.cursor = "nesw-resize";
            if (keyShiftDown) {
              if (x > drawRect[2] && y < drawRect[1]) {
                targetRect = [drawRect[2], y, x, drawRect[3]];
              } else if (y < drawRect[1]) {
                targetRect = [x, y, drawRect[2], drawRect[1]];
              } else if (x > drawRect[2]) {
                targetRect = [drawRect[2], drawRect[1], x, y];
              } else {
                targetRect = [x, drawRect[1], drawRect[2], y];
              }
            } else {
              // X移动偏移大于y情况，依照y值比例变动
              if (Math.abs(x - drawRect[0]) > Math.abs(y - drawRect[3])) {
                x = (Math.abs(y - drawRect[1]) / yLength) * xLength;
                x = drawRect[2] - Math.floor(x);
                // Y移动偏移大于x情况，依照y值比例变动
              } else {
                y = (Math.abs(x - drawRect[2]) / xLength) * yLength;
                y = drawRect[1] + Math.floor(y);
              }
              targetRect = [x, drawRect[1], drawRect[2], y];
            }
            drawRect = this.formatDrawRect([x, drawRect[1], drawRect[2], y]);
            this.drawTarget(target, drawRect);
            break;
          case "rightUpper":
            canvas.style.cursor = "nesw-resize";
            if (keyShiftDown) {
              if (x < drawRect[0] && y > drawRect[3]) {
                targetRect = [x, drawRect[3], drawRect[0], y];
              } else if (x < drawRect[0]) {
                targetRect = [x, y, drawRect[0], drawRect[3]];
              } else if (y > drawRect[3]) {
                targetRect = [drawRect[0], drawRect[3], x, y];
              } else {
                targetRect = [drawRect[0], y, x, drawRect[3]];
              }
            } else {
              // X移动偏移大于y情况，依照y值比例变动
              if (Math.abs(x - drawRect[2]) > Math.abs(y - drawRect[1])) {
                x = (Math.abs(y - drawRect[3]) / yLength) * xLength;
                x = drawRect[0] + Math.floor(x);
                // Y移动偏移大于x情况，依照y值比例变动
              } else {
                y = (Math.abs(x - drawRect[0]) / xLength) * yLength;
                y = drawRect[3] - Math.floor(y);
              }
              targetRect = [drawRect[0], y, x, drawRect[3]];
            }
            drawRect = this.formatDrawRect([drawRect[0], y, x, drawRect[3]]);
            this.drawTarget(target, drawRect);
            break;
          case "rightLower":
            canvas.style.cursor = "nwse-resize";
            if (keyShiftDown) {
              if (x < drawRect[0] && y < drawRect[1]) {
                targetRect = [x, y, drawRect[0], drawRect[1]];
              } else if (x < drawRect[0]) {
                targetRect = [x, drawRect[1], drawRect[0], y];
              } else if (y < drawRect[1]) {
                targetRect = [drawRect[0], y, x, drawRect[1]];
              } else {
                targetRect = [drawRect[0], drawRect[1], x, y];
              }
            } else {
              if (Math.abs(x - drawRect[2]) > Math.abs(y - drawRect[3])) {
                x = (Math.abs(y - drawRect[1]) / yLength) * xLength;
                x = drawRect[0] + Math.floor(x);
                // Y移动偏移大于x情况，依照y值比例变动
              } else {
                y = (Math.abs(x - drawRect[0]) / xLength) * yLength;
                y = drawRect[1] + Math.floor(y);
              }
              targetRect = [drawRect[0], drawRect[1], x, y];
            }
            drawRect = this.formatDrawRect([drawRect[0], drawRect[1], x, y]);
            this.drawTarget(target, drawRect);
            break;
          default:
        }
        this.drawComponentFrame(target, targetRect, operate);

        // 旋转操作 Rotation
      } else if (shouldTargetRotate && !!operationTarget) {
        // CASE 旋转操作
        canvas.style.cursor = "grabbing";
        let x = this.getMousePos(e).x;
        let y = this.getMousePos(e).y;
        target = this.getTargetById(operationTarget.id);
        if (!!target) {
          this.objects[target.index]["show"] = false;
          let degree = target.degree ? target.degree : 0;
          const rotateDegree = this.dataSet["rotateDegree"];
          if (
            rotateButton === "rightLowerRotation" ||
            rotateButton === "leftLowerRotation"
          ) {
            // 向左拖拽
            if (x < mouseDownPoint.x) {
              degree = (degree + rotateDegree) % 360;
              mouseDownPoint.x = x;
              // 向右侧拖拽
            } else if (x > mouseDownPoint.x) {
              degree = (degree - rotateDegree) % 360;
              mouseDownPoint.x = x;
            }

            // 向上拖拽
            if (y < mouseDownPoint.y) {
              degree =
                rotateButton === "rightLowerRotation"
                  ? (degree - rotateDegree) % 360
                  : (degree + rotateDegree) % 360;
              mouseDownPoint.y = y;
              // 向下拖拽
            } else if (y > mouseDownPoint.y) {
              degree =
                rotateButton === "rightLowerRotation"
                  ? (degree + rotateDegree) % 360
                  : (degree - rotateDegree) % 360;
              mouseDownPoint.y = y;
            }
          } else {
            // 向右拖拽
            if (x < mouseDownPoint.x) {
              degree = (degree - rotateDegree) % 360;
              mouseDownPoint.x = x;

              // 向右侧拖拽
            } else if (x > mouseDownPoint.x) {
              degree = (degree + rotateDegree) % 360;
              mouseDownPoint.x = x;
            }
            // 向上拖拽
            if (y < mouseDownPoint.y) {
              degree =
                rotateButton === "leftUpperRotation"
                  ? (degree + rotateDegree) % 360
                  : (degree - rotateDegree) % 360;
              mouseDownPoint.y = y;
              // 向下拖拽
            } else if (y > mouseDownPoint.y) {
              degree =
                rotateButton === "rightUpperRotation"
                  ? (degree + rotateDegree) % 360
                  : (degree - rotateDegree) % 360;
              mouseDownPoint.y = y;
            }
          }
          this.restore(this.objects);
          this.drawTarget(
            target,
            [
              target.drawRect[0],
              target.drawRect[1],
              target.drawRect[2],
              target.drawRect[3],
            ],
            degree,
          );
          this.drawComponentFrame(target, target.targetRect, "", true);
          target.degree = degree;
          //todo this.objects.push(target);
          this.objects[target.index] = target;
          this.objects[target.index]["show"] = true;
        }

        // 其他移动操作 Other Moving Reactions
      } else if (shouldHorizontalReverse && !!operationTarget) {
        // CASE 水平旋转
        canvas.style.cursor = "grabbing";
        let x = this.getMousePos(e).x;
        target = this.getTargetById(operationTarget.id); //todo pop - . get
        if (!!target) {
          let degree = target.degree ? target.degree : 0;
          let horizontalDegree = target.horizontalDegree
            ? target.horizontalDegree
            : 0;
          let verticalDegree = target.verticalDegree
            ? target.verticalDegree
            : 0;

          // 向左侧拖拽
          if (x < mouseDownPoint.x) {
            horizontalDegree += 0.1;
            mouseDownPoint.x = x;

            // 向右侧拖拽
          } else if (x > mouseDownPoint.x) {
            horizontalDegree -= 0.1;
            mouseDownPoint.x = x;
          }
          this.restore(this.objects);
          this.drawTarget(
            target,
            [
              target.drawRect[0],
              target.drawRect[1],
              target.drawRect[2],
              target.drawRect[3],
            ],
            degree,
            horizontalDegree,
            verticalDegree,
          );
          this.drawComponentFrame(target, target.targetRect);
          target.horizontalDegree = horizontalDegree;

          this.objects[target.index] = target;
          this.objects[target.index]["show"] = true;
        }
      } else if (shouldVerticalReverse && !!operationTarget) {
        // CASE 竖直旋转
        canvas.style.cursor = "grabbing";
        let y = this.getMousePos(e).y;
        target = this.getTargetById(operationTarget.id); // todo pop -> get
        if (!!target) {
          let degree = target.degree ? target.degree : 0;
          let horizontalDegree = target.horizontalDegree
            ? target.horizontalDegree
            : 0;
          let verticalDegree = target.verticalDegree
            ? target.verticalDegree
            : 0;

          // 向上拖拽
          if (y < mouseDownPoint.y) {
            verticalDegree += 0.1;
            mouseDownPoint.y = y;

            // 向下拖拽
          } else if (y > mouseDownPoint.y) {
            verticalDegree -= 0.1;
            mouseDownPoint.y = y;
          }
          this.restore(this.objects);
          this.drawTarget(
            target,
            [
              target.drawRect[0],
              target.drawRect[1],
              target.drawRect[2],
              target.drawRect[3],
            ],
            degree,
            horizontalDegree,
            verticalDegree,
          );
          this.drawComponentFrame(target, target.targetRect);
          target.verticalDegree = verticalDegree;

          this.objects[target.index] = target;
          this.objects[target.index]["show"] = true;
        }
      } else if (shouldDraweShape && !!this.drawingShape) {
        canvas.style.cursor = "move";
        this.restore(this.objects);
        let x = this.getMousePos(e).x;
        let y = this.getMousePos(e).y;
        if (keyShiftDown) {
          if (Math.abs(x - mouseDownPoint.x) > Math.abs(y - mouseDownPoint.y)) {
            // X移动偏移大于y情况，依照y值比例变动
            if (this.drawingShape.type === "line") {
              y = mouseDownPoint.y;
            } else if (
              this.drawingShape.type === "rect" ||
              this.drawingShape.type === "circle"
            ) {
              x = mouseDownPoint.x + Math.abs(y - mouseDownPoint.y);
            }
          } else {
            // Y移动偏移大于x情况，依照y值比例变动
            if (this.drawingShape.type === "line") {
              x = mouseDownPoint.x;
            } else if (
              this.drawingShape.type === "rect" ||
              this.drawingShape.type === "circle"
            ) {
              y = mouseDownPoint.y + Math.abs(x - mouseDownPoint.x);
            }
          }
        }

        this.drawingShape.drawRect = this.drawingShape.targetRect = [
          mouseDownPoint.x,
          mouseDownPoint.y,
          x,
          y,
        ];
        this.drawTarget(this.drawingShape, this.drawingShape.drawRect);
        this.drawComponentFrame(
          this.drawingShape,
          this.drawingShape.targetRect,
        );
      } else {
        let target = this.getTopTarget(e, false);

        // 存在对象
        if (!!target) {
          // 1. Draw the frame 画出定界框
          if (
            !!drawFrameId &&
            drawFrameId !== target.id &&
            this.currentTargetId !== target.id &&
            !this.groupOperateIds.includes(target.id)
          ) {
            // Case: 已存在定界框, 消除旧的定界框
            this.restore(this.objects, true);

            target.operations = this.drawComponentFrame(
              target,
              target.targetRect,
            );
            drawFrameId = target.id;
            shouldRestore = true;
          } else if (
            !drawFrameId &&
            this.currentTargetId !== target.id &&
            !this.groupOperateIds.includes(target.id)
          ) {
            // Case: 不存在定界框, 重新绘制
            target.operations = this.drawComponentFrame(
              target,
              target.targetRect,
            );
            drawFrameId = target.id;
            shouldRestore = true;
          } else if (this.currentTargetId === target.id) {
            // Case: 只需要当前选中taget的operations
            target.operations = this.getTargetOperations(target.targetRect);
          }

          // 2. 更新操作对象和鼠标样式
          operationTarget = target;
          canvas.style.cursor = "move";

          // 3. 当在操作点上时更新鼠标样式和旋转按键
          let operateKey = this.getOperate(e, target, false);
          if (operateKey) {
            switch (operateKey) {
              case "leftMiddle":
                canvas.style.cursor = "ew-resize";
                break;
              case "rightMiddle":
                canvas.style.cursor = "ew-resize";
                break;
              case "middleUpper":
                canvas.style.cursor = "ns-resize";
                break;
              case "middleLower":
                canvas.style.cursor = "ns-resize";
                break;
              case "leftUpper":
                canvas.style.cursor = "nwse-resize";
                if (shouldDrawLeftUpper) {
                  this.drawRotateOperate(target.operations.leftUpperRotation);
                  shouldDrawLeftUpper = false;
                  shouldRestore = true;
                }
                break;
              case "leftLower":
                canvas.style.cursor = "nesw-resize";
                if (shouldDrawLeftLower) {
                  this.drawRotateOperate(target.operations.leftLowerRotation);
                  shouldDrawLeftLower = false;
                  shouldRestore = true;
                }
                break;
              case "rightUpper":
                canvas.style.cursor = "nesw-resize";
                if (shouldDrawRightUpper) {
                  this.drawRotateOperate(target.operations.rightUpperRotation);
                  shouldDrawRightUpper = false;
                  shouldRestore = true;
                }
                break;
              case "rightLower":
                canvas.style.cursor = "nwse-resize";
                if (shouldDrawRightLower) {
                  this.drawRotateOperate(target.operations.rightLowerRotation);
                  shouldDrawRightLower = false;
                  shouldRestore = true;
                }
                break;
              default:
            }
          } else {
            // 4. 已经存在旋转按键消除旋转按键
            if (
              !shouldDrawLeftUpper ||
              !shouldDrawLeftLower ||
              !shouldDrawRightUpper ||
              !shouldDrawRightLower
            ) {
              if (shouldRestore) {
                this.restore(this.objects, true);
              }
              this.drawComponentFrame(target, target.targetRect);
              drawFrameId = target.id;
              shouldRestore = true;
              shouldDrawLeftUpper = shouldDrawLeftLower = shouldDrawRightUpper = shouldDrawRightLower = true;
            }
          }
        } else {
          if (
            !!operationTarget &&
            this.isMouseInOperationSection(e, operationTarget)
          ) {
            // Draw the frame when mouse on operation buttons 当鼠标在操作区时画出定界框
            if (!drawFrameId) {
              this.drawComponentFrame(
                operationTarget,
                operationTarget.targetRect,
              );
              drawFrameId = operationTarget.id;
            }
            canvas.style.cursor = "grab";
            shouldRestore = true;
          } else {
            // Restore the canvas 没有任何绘制需要的时候更新画布, 消除定界框
            if (shouldRestore) {
              this.restore(this.objects, true);
            }
            operationTarget = null;
            drawFrameId = "";
            shouldDrawLeftUpper = shouldDrawLeftLower = shouldDrawRightUpper = shouldDrawRightLower = true;
            canvas.style.cursor = "default";
          }
        }
      }
    };

    // canvas.onmousemove = (e) =>  {
    //   setInterval(mouseMove(e), 5);
    // }

    let getMoveDrawRect = (drawRect, targetRect, x, y, diffX, diffY) => {
      const xShift = Math.abs(targetRect[0] - targetRect[2]);
      const yShift = Math.abs(targetRect[1] - targetRect[3]);

      // 基于当前定界框去更新
      drawRect[0] = drawRect[4] ? x - diffX + xShift : targetRect[0];
      drawRect[1] = drawRect[5] ? y - diffY + yShift : targetRect[1];
      drawRect[2] = drawRect[4] ? drawRect[0] + xShift : targetRect[2];
      drawRect[3] = drawRect[5] ? drawRect[1] + yShift : targetRect[3];

      return drawRect;
    };

    let getOperatingDrawRect = (
      rect,
      drawRect,
      isHorizontalReversed = false,
      isVerticalReversed = false,
    ) => {
      const targetWidth = Math.abs(rect[0] - rect[2]);
      const targetHeight = Math.abs(rect[1] - rect[3]);
      const newHorizontalReverse = rect[2] < rect[0];
      const newVerticalReverse = rect[3] < rect[1];

      // 水平扭转更新
      if (isHorizontalReversed) {
        drawRect[0] = newHorizontalReverse ? rect[2] : rect[0] + targetWidth;
        drawRect[2] = newHorizontalReverse
          ? rect[0]
          : drawRect[0] + targetWidth;
        drawRect[4] = newHorizontalReverse ? false : !!isHorizontalReversed;
      } else {
        drawRect[0] = rect[0];
        drawRect[2] = newHorizontalReverse
          ? drawRect[0] + targetWidth
          : rect[2];
        drawRect[4] = newHorizontalReverse ? true : !!isHorizontalReversed;
      }
      // 数直扭转更新
      if (isVerticalReversed) {
        drawRect[1] = newVerticalReverse ? rect[3] : rect[1] + targetHeight;
        drawRect[3] = newVerticalReverse ? rect[1] : drawRect[1] + targetHeight;
        drawRect[5] = newVerticalReverse ? false : !!isVerticalReversed;
      } else {
        drawRect[1] = rect[1];
        drawRect[3] = newVerticalReverse ? drawRect[1] + targetHeight : rect[3];
        drawRect[5] = newVerticalReverse ? true : !!isVerticalReversed;
      }

      return drawRect;
    };

    let keyPressUpdate = () => {
      if (
        this.dataSet["onKeyPressUpdate"] &&
        typeof this.dataSet["onKeyPressUpdate"] === "function"
      ) {
        this.dataSet["onKeyPressUpdate"](
          this.objects,
          this.sets,
          this.currentTargetId,
        );
      }
    };

    let keyMap = {};
    window.addEventListener(
      "keydown",
      e => {
        e = e || event; // to deal with IE
        keyMap[e.keyCode] = e.type == "keydown";

        if (e.keyCode === 16) {
          // Shift
          keyShiftDown = true;
        } else if (e.keyCode === 17 || e.keyCode === 93) {
          // Control
          keyCtrlDown = true;
        } else if (
          this.dataSet["enableDelete"] &&
          (e.keyCode === 46 || e.keyCode === 8) &&
          !!this.currentTargetId
        ) {
          // Delete
          this.delete(this.currentTargetId);
          keyPressUpdate();
          this.currentTargetId = null;
        } else if (e.keyCode === 38 && !!this.currentTargetId) {
          // up arrow
          this.moveTargetById(this.currentTargetId, [0, -1]);
          keyPressUpdate();
          this.restore(this.objects, true);
        } else if (e.keyCode === 37 && !!this.currentTargetId) {
          // left arrow
          this.moveTargetById(this.currentTargetId, [-1, 0]);
          keyPressUpdate();
          this.restore(this.objects, true);
        } else if (e.keyCode === 39 && !!this.currentTargetId) {
          // right arrow
          this.moveTargetById(this.currentTargetId, [1, 0]);
          keyPressUpdate();
          this.restore(this.objects, true);
        } else if (e.keyCode === 40 && !!this.currentTargetId) {
          // down arrow
          this.moveTargetById(this.currentTargetId, [0, 1]);
          keyPressUpdate();
          this.restore(this.objects, true);
        } else if (keyMap[90] && keyMap[93]) {
          // ctrl + z
        } else if (keyMap[93] && keyMap[86] && !!this.currentTargetId) {
          const newId = this.copy(this.currentTargetId);
          if (this.useSet) {
            this.addToSet(newId);
          }
          keyPressUpdate();
        } else {
          keyMap = {};
        }
        console.log(e.keyCode);
      },
      false,
    );

    // window.addEventListener('input', (e) => {
    //   if (!!this.currentTargetId) {
    //     const currentTarget = this.getTargetById(this.currentTargetId);
    //     if (currentTarget.type === 'text' || currentTarget.type === 'tag') {
    //       this.update(this.currentTargetId, {value:currentTarget.value + e.data});
    //     }
    //   }
    // });

    window.addEventListener(
      "keyup",
      e => {
        if (e.keyCode === 16) {
          keyShiftDown = false;
        } else if (e.keyCode === 17 || e.keyCode === 93) {
          keyCtrlDown = false;
        }
      },
      false,
    );
  }
}
