import React from "react";
import {
  DefaultTheme,
  ThemeProvider,
  createGlobalStyle,
} from "styled-components";

const defaultTheme: DefaultTheme = {
  template: "ChildTime",
  name: "童年",
  primaryColor: "#C21D44",
  secondaryColor: "#FDCBD7",
  pageBackgroundColor: "#F9F9F9",
  textColor: "#4B4B4B",
  borderColor: "#BDBCBC",
  menuTextColor: "#fff",
  linkColor: "#7592A6",
  panelColor: "#FFFFFF",
  dialogBackgroundColor: "#ECEAE8",
  bussinessPrimaryColor: "#F48033",
  designPrimaryColor: "#4B27B6",
};

const moonlightTheme: DefaultTheme = {
  template: "Moonlight",
  name: "月光",
  primaryColor: "#C21D44",
  secondaryColor: "#FDCBD7",
  pageBackgroundColor: "#F9F9F9",
  textColor: "#4B4B4B",
  borderColor: "#BDBCBC",
  menuTextColor: "#fff",
  linkColor: "#7592A6",
  panelColor: "#FFFFFF",
  dialogBackgroundColor: "#ECEAE8",
  bussinessPrimaryColor: "#F48033",
  designPrimaryColor: "#4B27B6",
};

const GlobalStyle = createGlobalStyle`
  body {
    color: ${props => props.theme.textColor};
    font-family: -apple-system,
    BlinkMacSystemFont,
    "Microsoft YaHei",
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";
  }

  a {
    color: ${props => props.theme.textColor};
    &:active,
    &:hover {
      color: ${props => props.theme.textColor};
    }
  }

  .ant-btn{
    &:hover{
      opacity: 0.6;
    }
  }

  .ant-btn,.ant-card,.ant-collapse,.ant-tag,.ant-input,.ant-select .ant-select-selector.ant-select-single,.ant-cascader-picker,.ant-cascader-input{
    border-radius: 5px;
  }

  .ant-card{
    border-color:#d4d4d4;
  }

  .ant-btn-circle{
    border-radius: 50%;
  }

  .ant-modal-confirm-btns{
    .ant-btn{
      color:${props => props.theme.primaryColor};
      border-color:${props => props.theme.primaryColor};
    }
    .ant-btn-primary{
      background:${props => props.theme.primaryColor};
      color:#fff;
    }
  }
`;

const WrapperThemeProvider: React.FC = props => {
  // const themes = [defaultTheme, moonlightTheme];

  // const [value, setValue] = useLocalStorageState("user-theme", defaultTheme);

  return (
    <ThemeProvider theme={defaultTheme}>
      {/* <Select
        value={value.template}
        onChange={v => setValue(themes.find(t => t.template === v))}
      >
        {themes.map(t => (
          <Select.Option value={t.template}>{t.name}</Select.Option>
        ))}
      </Select> */}
      <GlobalStyle></GlobalStyle>
      {props.children}
    </ThemeProvider>
  );
};

export default WrapperThemeProvider;
