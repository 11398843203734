import React, { useEffect, useState, useContext } from "react";
import { Dispatch, RootState } from "../../../store/configureStore";
import { useSelector, useDispatch } from "react-redux";
import * as styles from "../index.scss";
import { CommonProps } from "../../../typings/component";
import { useIntl } from "react-intl";
import { Row, Col } from "antd";
import Icon from "../../../components/icon";
import replaceState from "../../../helpers/reolaceState";
import * as Cookies from "js-cookie";
import { useTheme } from "styled-components";
import * as queryString from "query-string";
import { useMount } from "ahooks";

const UserCenterLayout: React.FC<CommonProps> = props => {
  const { formatMessage: f } = useIntl();
  const { children } = props;
  const dispatch = useDispatch<Dispatch>();
  const { primaryColor } = useTheme();
  const { host_id } = useSelector((state: RootState) => state.URLSearch);
  const { isPrivate } = useSelector(
    (state: RootState) => state.CommonData || {},
  );
  const [activeIndex, setActiveIndex] = useState(0);
  const [navData, setNavData] = useState<any>([]);

  const defaultNavData = [
    {
      text: f({ id: "PersonalInformation" }),
      icon: "icongeren",
      url: "/account",
    },
    // { text: f({ id: "RecommendedDetails" }), icon: "icon32_chushituijian" },
    {
      text: f({ id: "Collection" }),
      icon: "icont2_shoucang1",
      url: "/account/megabook",
    },
    {
      text: f({ id: "MyMakeAnAppointment" }),
      icon: "iconyuyue",
      url: "/account/experience",
    },
    {
      text: f({ id: "MineOrder" }),
      icon: "icondingdan",
      url: "/account/orders",
    },
    { text: f({ id: "Messages" }), icon: "iconxinxi", url: "/account/message" },
    {
      text: f({ id: "Address" }),
      icon: "icont1_dizhi",
      url: "/account/address",
    },
    {
      text: f({ id: "MyTakeList" }),
      icon: "iconhuangguan222",
      url: "/account/commission",
    },
    {
      text: f({ id: "MyDesignIdea" }),
      icon: "iconshejishi",
      url: "/account/design-idea",
    },
    {
      text: f({ id: "MyWallet" }),
      icon: "iconqianbao",
      url: "/account/wallet",
    },
    {
      text: f({ id: "ToBOrderList" }),
      icon: "iconliebiao",
      url: "/tob/cart",
    },
    {
      text: f({ id: "ToBOrderHistory" }),
      icon: "iconlishijilu",
      url: "/account/toborders",
    },
    { text: f({ id: "LoggedOut" }), icon: "icontuichu" },
  ];

  const isolationNavData = [
    {
      text: f({ id: "PersonalInformation" }),
      icon: "icongeren",
      url: "/account",
    },
    // { text: f({ id: "RecommendedDetails" }), icon: "icon32_chushituijian" },
    {
      text: f({ id: "Collection" }),
      icon: "icont2_shoucang1",
      url: "/account/megabook",
    },
    {
      text: f({ id: "MyMakeAnAppointment" }),
      icon: "iconyuyue",
      url: "/account/experience",
    },
    {
      text: f({ id: "MineOrder" }),
      icon: "icondingdan",
      url: "/account/orders",
    },
    { text: f({ id: "Messages" }), icon: "iconxinxi", url: "/account/message" },
    {
      text: f({ id: "Address" }),
      icon: "icont1_dizhi",
      url: "/account/address",
    },
    {
      text: f({ id: "MyTakeList" }),
      icon: "iconhuangguan222",
      url: "/account/commission",
    },
    {
      text: f({ id: "MyDesignIdea" }),
      icon: "iconshejishi",
      url: "/account/design-idea",
    },
    { text: f({ id: "LoggedOut" }), icon: "icontuichu" },
  ];
  const handleUserQuit = () => {
    Cookies.remove("store_time");
    Cookies.remove("store_token");
    dispatch.User.changeUserState({
      isLogin: false,
      userId: null,
      email: null,
      modalAction: null,
    });
    window.location.reload();
  };

  useMount(() => {
    setActiveIndex(
      navData.findIndex((n: { url: string }) => n.url === location.pathname),
    );

    const shop_id = queryString.parse(window.location.search).host_id;
    setNavData(isPrivate && shop_id ? isolationNavData : defaultNavData);
  });

  return (
    <div className={styles["Account-content-box"]}>
      <Row gutter={20}>
        <Col sm={8} lg={6} xl={5} xxl={5}>
          <div className={styles[`Account-nav`]}>
            {navData.map(
              (
                item: { url: string; icon: any; text: React.ReactNode },
                index: string | number | null | undefined,
              ) => (
                <div
                  className={styles[`Account-nav-div`]}
                  style={{
                    color: activeIndex === index ? primaryColor : "",
                    background: activeIndex === index ? "#F3F3F3" : "",
                    borderBottom:
                      index === 0 || index === (isPrivate && host_id ? 7 : 10)
                        ? "1px solid #bdbcbc"
                        : "",
                  }}
                  onClick={() =>
                    item.url ? replaceState.linkTo(item.url) : handleUserQuit()
                  }
                  key={index}
                >
                  <div>
                    <Icon type={item.icon} />
                    <span>{item.text}</span>
                  </div>
                </div>
              ),
            )}
          </div>
        </Col>
        <Col
          sm={16}
          lg={18}
          xl={19}
          xxl={19}
          style={{ background: "white", padding: "0px" }}
        >
          {children}
        </Col>
      </Row>
    </div>
  );
};

export default UserCenterLayout;
