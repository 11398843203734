import React, { useState, useEffect, useContext, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, Dispatch } from "../../store/configureStore";
import OrderList from "./component/OrderList";
import { useIntl } from "react-intl";
import DatePicker from "../../wrappers/date-picker";
import dayjs from "dayjs";
import { Row, Col, Card } from "antd";
import { PaginationConfig } from "antd/lib/pagination";

const ToBOrderHistory = () => {
  const dispatch = useDispatch<Dispatch>();
  const { formatMessage: f } = useIntl();

  const { orderCount, orderList } = useSelector(
    (state: RootState) => state.ToBOrderHistoryPage,
  );

  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [startTime, setStartTime] = useState("");
  const [stopTime, setStopTime] = useState("");

  useEffect(() => {
    // 请求企业采购清单
    if (!orderList) {
      dispatch.ToBOrderHistoryPage.setOrderData({});
    }
  }, []);

  const handleGetOtherList = (page: number) => {
    setCurrentPageNumber(page);
    dispatch.ToBOrderHistoryPage.setOrderData({
      page: page,
      stopTime: stopTime,
      startTime: startTime,
    });
    window.scroll(0, 0);
  };

  const handleStartTimeSearch = (value: any) => {
    // 这里按时间，状态搜索
    if (value) {
      setStartTime(value.format("YYYY-MM-DD"));
      dispatch.ToBOrderHistoryPage.setOrderData({
        page: 1,
        stopTime: stopTime,
        startTime: value.format("YYYY-MM-DD"),
      });
    } else {
      setStartTime("");
      dispatch.ToBOrderHistoryPage.setOrderData({
        page: 1,
        stopTime: stopTime,
        startTime: "",
      });
    }
  };
  const handleStopTimeSearch = (value: any) => {
    // 这里按时间，状态搜索
    if (value) {
      setStopTime(value.format("YYYY-MM-DD"));
      dispatch.ToBOrderHistoryPage.setOrderData({
        page: 1,
        stopTime: value.format("YYYY-MM-DD"),
        startTime: startTime,
      });
    } else {
      setStopTime("");
      dispatch.ToBOrderHistoryPage.setOrderData({
        page: 1,
        stopTime: "",
        startTime: startTime,
      });
    }
  };

  const pagination: PaginationConfig = {
    current: currentPageNumber,
    total: orderCount,
    pageSize: 10,
    showSizeChanger: false,
    onChange: handleGetOtherList,
    hideOnSinglePage: true,
  };

  return (
    <Card bordered={false}>
      <Row gutter={10} style={{ padding: "30px 0" }} align="middle">
        <Col>{f({ id: "TimeFiltering" })}</Col>
        <Col flex="200px">
          <DatePicker
            style={{
              width: "100%",
            }}
            placeholder={f({ id: "PleaseEnterStartTime" })}
            onChange={value => {
              handleStartTimeSearch(value);
            }}
            value={startTime !== "" ? dayjs(startTime, "YYYY-MM-DD") : null}
          />
        </Col>
        <Col flex="200px">
          <DatePicker
            style={{
              width: "100%",
            }}
            placeholder={f({ id: "PleaseEnterStopTime" })}
            onChange={value => {
              handleStopTimeSearch(value);
            }}
            value={stopTime !== "" ? dayjs(stopTime, "YYYY-MM-DD") : null}
          />
        </Col>
      </Row>
      {orderList && <OrderList data={orderList} pagination={pagination} />}
    </Card>
  );
};

export default ToBOrderHistory;
