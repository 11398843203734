import styled from "styled-components";
import { InputNumber as RcInputNumber, Input } from "antd";

const InputNumberStyled = styled(RcInputNumber)`
  .ant-input-number-handler-wrap {
    display: none;
  }
`;

const HorizontalLineInput = styled(Input)`
  .ant-input,
  &.ant-input-affix-wrapper {
    border-width: 0;
    border-bottom-width: 1px;
    background-color: transparent;
  }
`;

const InputStyled = styled(Input).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !["borderRadius"].includes(prop),
})<{
  backgroundColor?: string;
  borderRadius?: string;
  addonBgColor?: string;
}>`
  .ant-input,
  &.ant-input-affix-wrapper {
    border-radius: ${props => props.borderRadius};
    background-color: ${props => props.backgroundColor};
  }
  &.ant-input-group-wrapper {
    .ant-input-group-addon {
      background-color: ${props => props.addonBgColor};
      & + .ant-input {
        border-radius: ${props => props.borderRadius} 0 0
          ${props => props.borderRadius};
      }
    }
    .ant-input + .ant-input-group-addon {
      border-radius: 0 ${props => props.borderRadius}
        ${props => props.borderRadius} 0;
    }
  }
`;

InputStyled.defaultProps = {
  borderRadius: "5px",
};

const DesignInput = styled(InputStyled).attrs({
  backgroundColor: "#f0f0f0",
  borderRadius: "17px",
})``;

export { HorizontalLineInput, InputNumberStyled as InputNumber, DesignInput };

export default InputStyled;
