import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./index.scss";
import Image from "../../../components/image";
import classname from "classnames";
import { RootState, Dispatch } from "../../../store/configureStore";
import { useSelector, useDispatch } from "react-redux";
import { getBase64 } from "../../../helpers/modelHelpers";
import ProductReview, {
  ProductReviewStatistic,
  ProductOrder,
  Media,
} from "../../../models/ProductReview";
import { useIntl } from "react-intl";
import { CommonProps } from "../../../typings/component";
import { Row, Col, message as Messages, Spin, Progress, Space } from "antd";
import Icon from "../../../components/icon";

import Modal from "../../../wrappers/modal";
import Carousel from "../../../wrappers/carousel";
import Rate from "../../../wrappers/rate";
import Select from "../../../wrappers/select";
import Upload from "../../../wrappers/upload";
import { PrimaryButton } from "../../../wrappers/button";
import { useTheme } from "styled-components";
import { RcFile, RcCustomRequestOptions } from "antd/lib/upload/interface";

const PicturePreview = React.memo((props: { comment: ProductReview }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isOpen, setOpen] = useState(false);

  const { comment } = props;

  const imgCarouselRef = useRef<Carousel>(null);

  useEffect(() => {
    if (imgCarouselRef.current && isOpen) {
      imgCarouselRef.current.goTo(activeIndex, true);
    }
  }, [isOpen, activeIndex]);

  return (
    <>
      <div className={styles["PDRating-addcomment-filebox"]}>
        {comment.medias &&
          comment.medias.map((media, index) => (
            <div
              key={media.review_media_id}
              className={styles["PDRating-addcomment-filebox-img"]}
              style={{ border: "none", cursor: "pointer" }}
              onClick={() => {
                setOpen(true);
                setActiveIndex(index);
              }}
            >
              <img src={media.media_url} />
            </div>
          ))}
      </div>

      <Modal
        visible={isOpen}
        closable
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <Carousel
          arrows
          initialSlide={activeIndex}
          hoverable
          className={styles["PDRating-bigImg"]}
          ref={imgCarouselRef}
        >
          {comment.medias &&
            comment.medias.map(media => (
              <div key={media.review_media_id}>
                <div className={styles["PDRating-bigImg-item"]}>
                  <img src={media.media_url} />
                </div>
              </div>
            ))}
        </Carousel>
      </Modal>
    </>
  );
});

const UserComment = React.memo(
  (props: {
    comments: ProductReview[];
    moreReviewsVisable: boolean;
    handleMoreReviewClick: (e: React.MouseEvent) => void;
  }) => {
    const { comments = [], moreReviewsVisable } = props;
    const { primaryColor, linkColor } = useTheme();
    const { formatMessage: f } = useIntl();

    return (
      <>
        {comments.map(comment => (
          <>
            <div
              className={classname(styles["PDRating-comment"], {})}
              key={comment.review_id}
            >
              <Row className={styles["PDRating-comment-title"]}>
                <Col
                  span={14}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div className={styles["PDRating-comment-title-h"]}>
                    <Image src={comment.customer_avatar} width={1} height={1} />
                  </div>
                  {comment.customer_name}
                </Col>

                <Col span={10} style={{ textAlign: "right" }}>
                  {comment.review_time}
                </Col>
              </Row>
              <Row>
                <Rate
                  className={styles["PDRating-comment-title-r"]}
                  value={comment.review_rating}
                  color={primaryColor}
                  disabled
                />
              </Row>
              <Row style={{ marginTop: "20px" }}>
                <p>{comment.review_comment}</p>
              </Row>
              <Row>
                <PicturePreview comment={comment} />
              </Row>
            </div>
          </>
        ))}
        {moreReviewsVisable && (
          <div
            className={styles["PDRating-more-reviews"]}
            onClick={props.handleMoreReviewClick}
          >
            <div
              className={styles["PDRating-more-reviews-text"]}
              style={{ color: linkColor }}
            >
              {f({ id: "MoreReviews" })}
            </div>
            <Icon type="icont3_gengduo" />
          </div>
        )}
      </>
    );
  },
);

const AddComment = React.memo(
  (props: {
    orders: ProductOrder[];
    medias: Media[];
    handleSubmit: (data: any) => void;
    handleUploadFile: (file: string, callback: (url: string) => void) => void;
  }) => {
    const { formatMessage: f } = useIntl();
    const { primaryColor } = useTheme();
    const { orders = [], medias = [] } = props;

    const [rating, setRating] = React.useState(5);
    const [comment, setComment] = React.useState("");
    const [order, setOrder] = React.useState(0);

    React.useEffect(() => {
      if (orders.length > 0) {
        setOrder(orders[0].order_product_id);
      }
    }, [orders]);

    const [fileList, setFileList] = useState<RcFile[]>([]);
    const [uploading, setUploading] = useState(false);

    const handleUploadFile = async (info: RcCustomRequestOptions) => {
      const file = info.file;

      if (file.size / 1024 / 1024 > 5) {
        return Messages.error(
          f({ id: "OopsCommaUploadFileExceedsMaximunSize" }),
        );
      }

      setUploading(true);

      const base64 = await getBase64(file);

      props.handleUploadFile(base64, url => {
        setFileList(list => [...list, { ...file, url }]);
        setUploading(false);
      });
    };
    const handleSubmit = React.useCallback(() => {
      props.handleSubmit({
        action: "ReviewOrderProduct",
        order_product_id: order,
        review_rating: rating,
        review_comment: comment,
        medias: JSON.stringify(medias)
          .replace(/\\/g, "\\\\")
          .replace(/"/g, '\\"'),
      });
    }, [medias, order, rating, comment]);

    return (
      <>
        {orders.length > 0 && (
          <div className={styles["PDRating-addcomment"]}>
            <p className={styles["PDRating-addcomment-title"]}>
              {f({ id: "ReviewProductFromOrder" })}
            </p>

            <div className={styles["PDRating-select"]}>
              <Select
                style={{ width: "100%" }}
                value={order}
                onChange={val => setOrder(Number(val))}
              >
                {orders.map((item, index) => {
                  return (
                    <Select.Option value={item.order_product_id} key={index}>
                      {`${item.order_number} - ${item.created_at}`}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
            <Rate
              className={styles["PDRating-addcomment-rating"]}
              value={rating}
              color={primaryColor}
              onChange={setRating}
            />

            <div className={styles["PDRating-addcomment-filebox"]}>
              <Upload
                customRequest={handleUploadFile}
                accept="image/*"
                multiple
                fileList={fileList}
                width="100px"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: false,
                }}
              >
                <Spin spinning={uploading}>
                  {medias.length < 4 ? (
                    <div>
                      <Icon type="icont1_jia" style={{ fontSize: "1.5rem" }} />
                    </div>
                  ) : null}
                </Spin>
              </Upload>
            </div>

            <textarea
              placeholder={f({
                id: "PleaseReviewOurProductsComma",
              })}
              className={styles["PDRating-addcomment-input"]}
              value={comment}
              onChange={e => setComment(e.target.value)}
            />
            <Row justify="end">
              <PrimaryButton
                onClick={handleSubmit}
                disabled={comment.length > 500}
                bgColor={primaryColor}
              >
                {f({ id: "Submit" })}
                <Icon type="icont1_correct"></Icon>
              </PrimaryButton>
            </Row>
          </div>
        )}

        {/* {orders.length === 0 && (
          <div
            className={styles["PDRating-noorders"]}
            style={{ color: primaryColor }}
          >
            {f({ id: "AsteriskSignYouDoNotHaveFinishedOrder" })}
          </div>
        )} */}
      </>
    );
  },
);

const PDRating: React.FC<
  CommonProps & {
    shop_product_id: number;
    isToReview: boolean;
  }
> = props => {
  const { formatMessage: f } = useIntl();
  const { primaryColor } = useTheme();

  const {
    shop_product_id,
    isToReview = false,
    isLogin = false,
    // intl: { formatMessage: f },
  } = props;

  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(1);

  const {
    productReviewStatistic,
    productReviewList,
    productOrders = [],
    moreReviewsVisable = true,
    uploadMedias = [],
  } = useSelector((state: RootState) => state.PDPPage);
  const { PDPPage: rematch } = useDispatch<Dispatch>();

  const rateStar = productReviewStatistic
    ? (productReviewStatistic.review_rating * 5) / 100
    : 0;

  const [commentOrder, setCommentOrder] = React.useState("review_time");

  React.useEffect(() => {
    if (!productReviewList) {
      rematch.getProductReviewListAsync({
        query: { shop_product_id },
        page: 1,
        page_size: pageSize,
        order_by: commentOrder,
      });

      rematch.getProductOrderAsync(shop_product_id);
    }
  }, [shop_product_id]);

  React.useEffect(() => {
    if (
      (!productReviewStatistic ||
        Object.keys(productReviewStatistic).length === 0) &&
      shop_product_id
    ) {
      rematch.getProductReviewStatisticAsync(shop_product_id);
    }
  }, [shop_product_id, isToReview]);

  const handleOrderChange = React.useCallback(
    (val: string) => {
      setCommentOrder(val);
      setPage(1);

      rematch.getProductReviewListAsync({
        query: { shop_product_id },
        page: 1,
        page_size: pageSize,
        order_by: val,
      });
    },
    [shop_product_id, pageSize],
  );

  const handleSubmit = React.useCallback(
    (data: any) => {
      rematch.addProductReviewAsync({
        ...data,
        shop_product_id,
        callback: () => {
          rematch.getProductReviewListAsync({
            query: { shop_product_id },
            page: 1,
            page_size: pageSize,
            order_by: commentOrder,
          });
        },
      });
    },
    [shop_product_id, pageSize, commentOrder],
  );

  const handleMoreReviewClick = () => {
    rematch.getProductReviewListAsync({
      query: { shop_product_id },
      page: page + 1,
      page_size: pageSize,
      order_by: commentOrder,
    });

    setPage(page + 1);
  };

  const handleUploadFile = React.useCallback((base64, callback) => {
    rematch.uploadMediaAsync({
      action: "review",
      base64,
      callback,
    });
  }, []);

  const prStatistics = new ProductReviewStatistic(productReviewStatistic);

  return (
    <div>
      <Row className={styles["PDRating-box"]}>
        <Rate value={rateStar} disabled className={styles["PDRating-rating"]} />
      </Row>
      <Row>
        {prStatistics.scores?.map((score, index) => (
          <Col span={14} key={index} className={styles["PDRating-score"]}>
            <span>{prStatistics.scores.length - index}</span>
            <Icon type="iconxingxing1" style={{ color: primaryColor }} />

            <Progress
              strokeColor={primaryColor}
              trailColor="#c6c4c2"
              strokeWidth={24}
              strokeLinecap="square"
              percent={(score * 100) / prStatistics.review_total}
              format={num => num}
              showInfo={false}
              className="rate-star-ratio"
              style={{ margin: "0 8px" }}
            ></Progress>
            {score}
          </Col>
        ))}
      </Row>
      <Row>
        <Select
          placeholder={f({
            id: "SortingWay",
          })}
          style={{ width: "100%" }}
          value={commentOrder}
          onChange={val => handleOrderChange(val as string)}
        >
          <Select.Option value="review_time" key="time">
            {f({ id: "SortByMostRecently" })}
          </Select.Option>
          <Select.Option value="review_rating" key="rating">
            {f({ id: "SortByRating" })}
          </Select.Option>
        </Select>
      </Row>
      <Row>
        <UserComment
          comments={productReviewList}
          handleMoreReviewClick={handleMoreReviewClick}
          moreReviewsVisable={moreReviewsVisable}
        />
      </Row>
      <Row>
        <AddComment
          orders={productOrders}
          handleSubmit={handleSubmit}
          handleUploadFile={handleUploadFile}
          medias={uploadMedias}
        />
      </Row>
    </div>
  );
};

export default React.memo(PDRating);
