import * as React from "react";
import * as styles from "../index.scss";
import { connect, useSelector } from "react-redux";
import NavigationComponent from "./NavigationComponent";
import IsolationNavigationComponent from "./IsolationNavigation";
import * as RoomImg from "../../../static/img/header-store-l.png";
import * as CategoryImg from "../../../static/img/header-store-C.png";
import * as mgImg from "../../../static/img/header-store.png";
// import { Room, Category } from "../data";
import { RootState } from "../../../store/configureStore";
import { Room, Category } from "../data";
import { useIntl } from "react-intl";
import { BusinessContext } from "../../BusinessContext";
import replaceState from "../../../helpers/reolaceState";

const mapSateToProps = (state: RootState) => ({
  URLSearch: state.URLSearch,
  CategoryFooter: state.Navigation.category || {
    title: "我的分类",
    link: "/product-list",
    picture: CategoryImg,
    logo: mgImg,
  },
  RoomFooter: state.Navigation.room || {
    title: "我的房间",
    link: "",
    picture: RoomImg,
    logo:
      "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/pop/room_logo.png",
  },
  Category_menu: state.Navigation.category_menu || Category,
  Room_menu: state.Navigation.room_menu || Room,
  ThemeShopCategory: state.ThemeShopCategory || [],
});

function Navigation(props: ReturnType<typeof mapSateToProps>) {
  const { formatMessage: f } = useIntl();
  const { search = "" } = props.URLSearch;
  const {
    CategoryFooter,
    RoomFooter,
    Room_menu,
    Category_menu,
    ThemeShopCategory,
  } = props;

  const { host_id } = useSelector((state: RootState) => state.URLSearch);
  const { isPrivate, shopUrl } = useSelector(
    (state: RootState) => state.CommonData || {},
  );

  const HandleSort = (property: any) => {
    return function (a: any, b: any) {
      const value1 = a[property];
      const value2 = b[property];
      return value2 - value1;
    };
  };

  const handleGetHeaderData = (data: any) => {
    const newData = [[], [], []];
    data.forEach((item: any) => newData[item.column_index - 1]?.push(item));

    newData.forEach((item: any) => {
      item.sort(HandleSort("sort_index"));
    });
    return newData;
  };

  const CategoryData = handleGetHeaderData(Category_menu),
    RoomData = handleGetHeaderData(Room_menu);

  const {
    isToBusiness,
    cutomizedVars: { bussinessPrimaryColor = "" } = {},
  } = React.useContext(BusinessContext);

  return (
    <ul className={styles["Header-navigation"]}>
      <li className={styles["Header-navigation-item"]}>
        <a
          className={styles["Header-navigation-item-linke"]}
          style={{ color: isToBusiness ? bussinessPrimaryColor : "" }}
          onClick={() => {
            replaceState.linkTo(host_id && isPrivate ? shopUrl : `/`);
          }}
        >
          {f({ id: "Homepage" })}
        </a>
      </li>

      {host_id && isPrivate ? (
        <>
          <IsolationNavigationComponent
            list={ThemeShopCategory}
            name={f({ id: "Category" })}
            link="/"
            isShowComingSoon={false}
          />
        </>
      ) : (
        <>
          <NavigationComponent
            list={CategoryData}
            name={f({ id: "Category" })}
            link="/"
            isShowComingSoon={false}
            footer={CategoryFooter}
            pkid="Category"
          />
          <NavigationComponent
            list={RoomData}
            name={f({ id: "Room" })}
            link="/"
            isShowComingSoon={false}
            footer={RoomFooter}
            pkid="Room"
          />
        </>
      )}
      {!isToBusiness && (
        <li className={styles["Header-navigation-item"]}>
          <a
            className={styles["Header-navigation-item-linke"]}
            href={`/design-idea-list/index.html${search}`}
            style={{ color: isToBusiness ? bussinessPrimaryColor : "" }}
          >
            {f({ id: "DesignIdeas" })}
          </a>
        </li>
      )}
      {!isToBusiness && (
        <li className={styles["Header-navigation-item"]}>
          <a
            className={styles["Header-navigation-item-linke"]}
            href={`/design-idea/new${search}`}
            style={{ color: isToBusiness ? bussinessPrimaryColor : "" }}
          >
            {f({ id: "StartDesign" })}
          </a>
        </li>
      )}
    </ul>
  );
}
export default connect(mapSateToProps)(React.memo(Navigation));
