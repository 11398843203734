import * as React from "react";
import Layout from "../Layout";
import Content from "./Content";
import InfoLayout from "../UserCenter/components/Layout";
import { useSelector } from "react-redux";
import { RootState } from "../../store/configureStore";

const ToBOrderHistory: React.FC = () => {
  const {
    breadcrumbs = [
      {
        url: "/",
        title: "首页",
      },
      {
        url: "/account",
        title: "个人中心",
      },
      {
        url: "/account/toborders",
        title: "2b订单列表",
      },
    ],
  } = useSelector((state: RootState) => state.ToBOrderHistoryPage);
  return (
    <Layout breadcrumbs={breadcrumbs}>
      <InfoLayout>
        <Content />
      </InfoLayout>
    </Layout>
  );
};

export default ToBOrderHistory;
