import { createModel } from "@rematch/core";

import produce from "immer";

export type Recommend = {
  breadcrumbs: { url: string; title: string }[];
  RecommendData: {
    title: string;
    type: string;
    child: { title: string; picture: string; link: string }[];
  };
};

export default createModel({
  state: {
    RecommendData: {
      // title: "全部品类",
      // type: "category",
      // child: [
      //   {
      //     title: "Sofas",
      //     link: "/product-list/furniture/category/sofa/index.html",
      //     picture:
      //       "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/furniture/home-sofas.jpg",
      //   },
      //   {
      //     title: "Beds",
      //     link: "/product-list/furniture/category/bed/index.html",
      //     picture:
      //       "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/furniture/home-beds.jpg",
      //   },
      //   {
      //     title: "Tables",
      //     link: "/product-list/furniture/category/table-desks/index.html",
      //     picture:
      //       "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/furniture/home-tables.jpg",
      //   },
      //   {
      //     title: "Lights",
      //     link: "/product-list/furniture/category/light/index.html",
      //     picture:
      //       "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/furniture/home-lights.jpg",
      //   },
      //   {
      //     title: "Cabinets",
      //     link: "/product-list/furniture/category/cabinet/index.html",
      //     picture:
      //       "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/furniture/home-cabinets.jpg",
      //   },
      //   {
      //     title: "Decorations",
      //     link: "/product-list/furniture/category/decor/index.html",
      //     picture:
      //       "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/furniture/home-decorations.jpg",
      //   },
      //   {
      //     title: "Doors",
      //     link: "/product-list/furniture/category/door/index.html",
      //     picture:
      //       "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/furniture/home-doors.jpg",
      //   },
      //   {
      //     title: "Bath",
      //     link: "/product-list/furniture/category/bath/index.html",
      //     picture:
      //       "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/furniture/home-bath.jpg",
      //   },
      //   {
      //     title: "Chairs",
      //     link: "/product-list/furniture/category/chair/index.html",
      //     picture:
      //       "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/furniture/home-chairs.jpg",
      //   },
      // ],
      title: "全部房间",
      type: "room",
      child: [
        {
          title: "Bedroom",
          link: "/product-list/furniture/room/bedroom/index.html",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/room/home-bedroom.png",
        },
        {
          title: "Living Room",
          link: "/product-list/furniture/room/living-room/index.html",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/room/home-livingroom.png",
        },
        {
          title: "Kitchen",
          link: "/product-list/furniture/room/kitchen/index.html",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/room/home-kitchen.png",
        },
        {
          title: "Dining Room",
          link: "/product-list/furniture/room/dining-room/index.html",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/room/home-diningroom.png",
        },
        {
          title: "Bathroom",
          link: "/product-list/furniture/room/bathroom/index.html",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/room/home-bathroom.png",
        },
        {
          title: "Study",
          link: "/product-list/furniture/room/study/index.html",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/room/home-study.png",
        },
        {
          title: "Closet",
          link: "/product-list/furniture/room/closet/index.html",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/room/home-closet.png",
        },
        {
          title: "Outdoor",
          link: "/product-list/furniture/room/outdoor/index.html",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/room/home-outdoor.png",
        },
        {
          title: "Outdoor",
          link: "/product-list/furniture/room/outdoor/index.html",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/room/home-outdoor.png",
        },
        {
          title: "Outdoor",
          link: "/product-list/furniture/room/outdoor/index.html",
          picture:
            "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/room/home-outdoor.png",
        },
      ],
      // title: "风格",
      // type: "style",
      // child: [
      //   {
      //     title: "Nordic",
      //     picture:
      //       "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/style/home-nordic.jpg",
      //     link:
      //       "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/style/home-nordic.jpg",
      //   },
      //   {
      //     title: "Nordic",
      //     picture:
      //       "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/style/home-nordic.jpg",
      //     link:
      //       "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/style/home-nordic.jpg",
      //   },
      //   {
      //     title: "Nordic",
      //     picture:
      //       "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/style/home-nordic.jpg",
      //     link:
      //       "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/style/home-nordic.jpg",
      //   },
      //   {
      //     title: "Nordic",
      //     picture:
      //       "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/style/home-nordic.jpg",
      //     link:
      //       "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/style/home-nordic.jpg",
      //   },
      //   {
      //     title: "Nordic",
      //     picture:
      //       "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/style/home-nordic.jpg",
      //     link:
      //       "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/style/home-nordic.jpg",
      //   },
      //   {
      //     title: "Nordic",
      //     picture:
      //       "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/style/home-nordic.jpg",
      //     link:
      //       "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/style/home-nordic.jpg",
      //   },
      //   {
      //     title: "Nordic",
      //     picture:
      //       "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/style/home-nordic.jpg",
      //     link: "",
      //   },
      //   {
      //     title: "Nordic",
      //     picture:
      //       "https://megahome-us-store.s3-us-west-1.amazonaws.com/public/homepage/style/home-nordic.jpg",
      //     link: "",
      //   },
      // ],
    },
  } as Recommend,
  reducers: {},
  effects: {},
});
