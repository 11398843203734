import React, { useEffect, useState } from "react";
import styles from "./index.scss";
import { RootState, Dispatch } from "../../store/configureStore";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row, Avatar, Divider, Tag } from "antd";
import { useTheme } from "styled-components";
import dilogoImg from "../../static/img/dilogo.png";
import ProductListContent from "../ProductList/Content";

const ProviderContent = React.memo(() => {
  const dispatch = useDispatch<Dispatch>();
  const { secondaryColor } = useTheme();
  const [providerId, setProviderId] = useState("");
  const [isShutSoon, setIsShutSoon] = useState(false);

  const { provider } = useSelector((state: RootState) => state.ProviderPage);

  useEffect(() => {
    const [, , info] = window.location.pathname.split("/");
    const provider_id = info.split("-")[1];
    setProviderId(provider_id);

    if (!provider) {
      dispatch.ProviderPage.getProviderAsync({ provider_id });
    }

    sessionStorage.setItem(
      "ProviderFilterParams",
      JSON.stringify({ provider_id }),
    );
  }, []);

  useEffect(() => {
    if (
      !provider ||
      !provider.business_end_time ||
      provider.business_end_time === ""
    ) {
      return;
    }

    const nowDate = new Date(),
      nowTime = nowDate.getTime(),
      Year = nowDate.getFullYear(),
      month = nowDate.getMonth() + 1,
      day = nowDate.getDate(),
      endTimeStr = `${Year}-${month}-${day} ${provider.business_end_time}`,
      endTime = new Date(endTimeStr).getTime();

    setIsShutSoon(endTime - nowTime < 1800000 && endTime - nowTime > 0);
  }, [provider]);

  return provider ? (
    <>
      <div className={styles["provider"]}>
        <div className={styles["provider-box"]}>
          <div className={styles["provider__logo"]}>
            <Avatar
              size={150}
              shape={"square"}
              src={
                provider?.provider_logo && provider?.provider_logo !== ""
                  ? provider?.provider_logo
                  : dilogoImg
              }
            />
          </div>
          <div className={styles["provider__info"]}>
            <Row>
              <Col span={10}>{provider?.provider_name}</Col>
              <Col span={14}>服务点主营：{provider?.main_scope}</Col>
            </Row>
            <Row>
              <Col span={10}>成立时间：{provider?.established_date}</Col>
              <Col span={14}>
                描述：
                <span
                  dangerouslySetInnerHTML={{
                    __html: provider?.provider_introduction,
                  }}
                ></span>
              </Col>
            </Row>
            <Divider
              style={{
                margin: "10px 0px",
                color: "#DDDDDD",
              }}
            />
            <Row>
              营业时间：{provider?.business_start_time} -{" "}
              {provider?.business_end_time}
              {isShutSoon && (
                <span
                  className={styles["provider__tag"]}
                  style={{
                    background: secondaryColor,
                  }}
                >
                  即将闭店
                </span>
              )}
            </Row>
            <Row>地址：{provider?.address}</Row>
          </div>
          {/* 先注掉，后期May be用 */}
          {/* <div
          className={styles["Provider_appointment"]}
          style={{ background: primaryColor }}
        >
          预约
        </div> */}
        </div>
      </div>

      <ProductListContent
        haveCategory={false}
        handleInitData={dispatch.PLPage.initProviderSkuListAsync}
        extraParams={{ provider_id: provider.provider_id }}
      />
    </>
  ) : null;
});

export default ProviderContent;
