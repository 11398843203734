import { createModel } from "@rematch/core";
import produce from "immer";
import {
  GraphQLRequest,
  GraphqlQuery,
  GraphqlMutation,
} from "../../../helpers/request.helper";
import { ShareOrder } from "../../../models/Order";
import { getFirstItem } from "../../../helpers/modelHelpers";

export type StateType = {
  shareData: ShareOrder;
};

export default createModel({
  state: {} as StateType,
  reducers: {
    // 初始化请求数据
    setData(state: {}, shareData: any) {
      return { ...state, shareData };
    },
  },
  effects: {
    async getShareData(payload) {
      const { token } = payload;
      const data = new ShareOrder({ token });
      const res = await GraphqlQuery("Share", data);
      this.setData(getFirstItem(res));
    },

    async addToCartAsync(payload) {
      const { callback, shop_product_id, quantity, agent_code } = payload;
      const res = await GraphqlMutation("TobCart", {
        shop_product_id: shop_product_id,
        quantity: quantity,
        action: "add",
        agent_code: agent_code,
      });

      callback && callback(res);
    },
    async getProtocolAsync({ url, callback }) {
      const buffer = await fetch(url).then(res => res.arrayBuffer());
      if (callback) {
        callback(new Uint8Array(buffer));
      }
    },
    async saveOrderAsync(payload) {
      const { callback, ...etr } = payload;
      const res = await GraphqlMutation("Contract", {
        ...etr,
      });

      if (callback) {
        callback(res);
      }
    },
  },
});
