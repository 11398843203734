import { GraphqlField } from "../helpers/decorators";
import BaseModel from "./BaseModel";

export default class Address extends BaseModel {
  @GraphqlField("Address")
  public address_id: number;

  @GraphqlField("ToBAddress")
  public tob_address_id: number;

  @GraphqlField("Address", "ToBAddress")
  public province: string;
  @GraphqlField("Address", "ToBAddress")
  public city: string;
  @GraphqlField("Address", "ToBAddress")
  public area: string;
  @GraphqlField("Address", "ToBAddress", "ShareCart")
  public address_details: string;
  @GraphqlField("Address", "ToBAddress", "ShareCart")
  public recipient_name: string;
  @GraphqlField("Address", "ToBAddress", "ShareCart")
  public recipient_telephone: string;
  @GraphqlField("Address")
  public address_alias: string;
  @GraphqlField("Address", "ToBAddress")
  public is_default: string;
  @GraphqlField("Address")
  public district_adcode: string;
  @GraphqlField("ToBAddress", "ShareCart")
  public recipient_company: string;
  @GraphqlField("ToBAddress", "ShareCart")
  public recipient_email: string;

  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}
