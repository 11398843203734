import React, { useContext } from "react";
import * as styles from "./index.scss";
import { Product } from "../../models/Product";
import { useIntl } from "react-intl";
import { CommonProps } from "../../typings/component";
import { Text, Link } from "../../wrappers/typography";
import Card from "../../wrappers/card";
import take from "lodash/take";
import Image from "../../components/image";
import { BusinessContext } from "../BusinessContext";
import { List } from "antd";
import { useTheme } from "styled-components";
import reolaceState from "../../helpers/reolaceState";

const SimilarProducts: React.FC<
  CommonProps & {
    productId: number;
    reference_code: string;
    recommendProductsCaches: {
      pid: number;
      RecommendProducts: Product[];
    }[];
  }
> = ({ productId, recommendProductsCaches, isMobile }) => {
  const { formatMessage: f } = useIntl();
  const { linkColor } = useTheme();

  const rp = recommendProductsCaches.find(rps => rps.pid === productId);

  const recommendProducts = rp ? rp.RecommendProducts : [];

  const { isToBusiness } = useContext(BusinessContext);

  return (
    <List
      header={
        <span className={styles["ProductParams-details-title"]}>
          {f({ id: "SimilarProducts" })}
        </span>
      }
      split={false}
      grid={{ column: 4, gutter: 8 }}
      dataSource={take(recommendProducts, isMobile ? 2 : 4)}
      renderItem={(item, index) => (
        <List.Item key={index}>
          <Card
            size="small"
            bodyStyle={{ padding: "10px 5px" }}
            className="figure-multiline-card"
            cover={
              <a
                href={item.url}
                target="_blank"
                onClick={e => {
                  e.preventDefault();
                  reolaceState.linkTo(item.url, "_blank");
                }}
              >
                <Image src={item.product_picture} width={1} height={1} />
              </a>
            }
          >
            <Card.Meta
              title={item.product_name}
              description={
                <>
                  <Link
                    href={`${isToBusiness ? "/tob" : ""}${item.shop_url}`}
                    onClick={e => {
                      e.preventDefault();
                      reolaceState.linkTo(
                        `${isToBusiness ? "/tob" : ""}${item.shop_url}`,
                        "_blank",
                      );
                    }}
                    target="_blank"
                  >
                    {item.shop_name}
                  </Link>
                  <Text>{item.tag_price_value}</Text>
                  <Text delete style={{ color: linkColor }}>
                    {item.has_tag_price_range
                      ? ""
                      : item.original_price > item.tag_price
                      ? item.original_price_value
                      : ""}
                  </Text>
                </>
              }
            />
          </Card>
        </List.Item>
      )}
    ></List>
  );
};

export default React.memo(SimilarProducts);
