export default {
  // Minions新增占用字段，仅中文添加量
  DiscountLabel: "折扣价",
  payLabel: "支付",

  Messages: "消息",
  CurrentOrder: "当前订单",
  OrderHistory: "历史订单",
  MineOrder: "我的订单",
  Review: "评价",
  YouDoNotHaveAnyMessageToShow: "您没有任何讯息要显示。",
  YouHaveNotPLaceAnyOrderYetPeriod: "您未下任何订单。",
  ViewOrderDetails: "查看订单详情",
  ThereIsNoHistoricalOrder: "您目前未下订单，没有历史订单，请选择合适的产品！",
  ToSpeedUptheCheckOut: `要加快结帐速度，或要添加其他地址，只需单击“添加新地址”，填写表格，然后单击“保存”按钮。如果要编辑或删除任何现有地址，只需单击现有地址的相应链接。`,
  More: "更多",
  Binding: "绑定",
  BindingReferenceCode: "绑定代理码",
  ChangePassword: "修改密码",
  ReferenceCodeColon: "参考代码：",
  PersonalInformation: "个人信息",
  FirstName: "名字",
  LastName: "姓",
  Gender: "性别",
  DateOfBirth: "生日",
  CellphoneNumber: "手机号码",
  CurrentPassword: "当前密码",
  NewPassword: "新密码",
  ConfirmPassword: "确认密码",
  Password: "密码",
  PleaseInputValidDateWithFormat: "请输入有效日期，格式为“XXXX-XX-XX”",
  PasswordDoesNotMatch: "密码不匹配",
  BindingSuccessed: "绑定成功",
  UnbindingSuccessed: "解绑成功",
  TheReferenceCodeReachesTheUpperLimitOf: "参考代码达到20个的上限。",
  ReferenceCodeHasBeenBound: "参考代码已被绑定，请重试。",
  InvalidReferenceCodePeriodPleaseEnterValidNumber:
    "无效的参考代码。 请输入有效的数字和字母。",
  Name: "姓名",
  Address: "地址",
  Phone: "电话",
  PhoneNumber: "电话号码",
  Alias: "别名",
  Organization: "企业名称",
  PaymentMethod: "付款方式",
  TotalInToal: "总计{total}",
  OrderDetails: "订单详情",
  ConfirmTheOrder: "确认订单",
  PlseaseConfirmYourOrder: "请确认您的订单!",
  CancelTheOrder: "取消订单",
  AreyouConfirmToCancelYourOrder: "您确定要取消订单吗？",
  YouCanAddUpToTwentyAddress: "您最多可以添加20个地址。 您已添加{num}个地址",
  AddYourAddressByClicking: '点击"添加新地址"按钮，添加您的地址',
  AddYourContactorByClicking: '点击"添加联系人"按钮，添加您的联系人',
  AddShippingAddress: "添加送货地址",
  EditShippingAddress: "编辑送货地址",
  City: "城市",
  StateSlashProvince: "州/省",
  PostalCode: "邮政编码",
  Country: "国家",
  CountryCode: "国家代码",
  Note: "备注",
  CouponSlashReferenceCode: "优惠券/参考代码",
  EnterEmailAddress: "请输入电子邮箱",
  EnterEmail: "输入邮箱",
  EnterPassword: "输入密码",
  OrderStatus: "订单状态",
  Status: "状态",
  ConsigneeInformation: "收件人信息",
  PaymentInformaton: "付款信息",
  Quotation: "报价单",
  ReviewProduct: "产品评价",
  TotalPrice: "总计 {price}",
  PriceSlashItem: "{price} /项",
  AssemblyManualLeftParenthesisComingSoonRightParenthesis:
    "组装手册（即将发布）",
  WelcomeBack: "欢迎回来",
  SignUp: "注册",
  EmailVerification: "电子邮箱验证",
  ForgotPasswordDashStepTwo: "忘记密码-步骤2",
  ForgotPasswordDashStepOne: "忘记密码-步骤1",
  ResetPassword: "重置密码",
  ForgotPassword: "忘记密码",
  InvalidEmailOrPassword: "无效的电子邮箱和密码",
  WelcomeToMegaHome: "欢迎来到MegaHome",
  PleaseCheckYourEmail: "请查看您的邮箱",
  IfYouDoNotReciveTheEmail:
    "如果您在几分钟内未收到我们的邮件，请查看您的垃圾邮箱",
  AlreadyHaveAnAccountQuestionMark: "已经有帐号了？",
  PleaseLogin: "请登录",
  EnterThePassword: "请输入密码",
  Industry: "行业",
  IHaveReadAndAcceptedMegahomeTermsOfService:
    "我已阅读并接受<a>MegaHome服务条款</a>。",
  WeHaveSentYouAConfirmationEmailEmail:
    '我们已向您发送确认电子邮件至"<span> {email} </span>"。 请检查您的电子邮件并确认帐户。',
  WeHaveSentYouAResetLink:
    '我们已向您发送确认电子邮件至"<span> {email} </span>"向您发送了一个重置链接。 请检查您的电子邮件地址，然后单击重置链接。',

  SectionsInMegahomeMarketplace:
    '来自<a href="/termsofservice/#disclaimer">MegaHome Marketplace</a>分类',
  ItemsInMegahomeMarketplace:
    '来自<a href="/termsofservice/#disclaimer">MegaHome Marketplace</a>商品',

  DidNotRevicedEmailQuestionMark: "沒有收到邮件？",
  ThisEmailAddressHasAlreadyBeenRegistered: "您输入的邮件地址已注册，请登录。",

  BackToMainMenu: "返回主菜单",
  Back: "后退",
  Homepage: "首页",
  ComingSoon: "即将到来",
  ShopTheBrand: "店铺品牌",
  Account: "账户",
  SignOut: "登出",
  SendAgain: "重新发送",
  BackToHomepage: "回到首页",
  TermOfService: "服务条款",
  NextStep: "下一步",
  MarketplaceHome: "主页",
  Category: "品类",
  Room: "房间",
  NotAvailable: "已下架",
  NotToDelivery: "该商品不支持当前地区配送",
  ToReview: "去评价",
  ByUsingThisSiteYouAgreeToThe:
    "使用本网站即表示您同意将Cookies用于分析，个性化内容和广告分发。",
  FindOutMore: "发现更多",
  Metric: "公制",
  Imperial: "英制",
  Home: "家",
  Firm: "公司",
  School: "学校",
  ReadAll: "全部已读",
  DeleteAll: "全部删除",
  AddNewAddress: "添加新地址",
  Confirm: "确认",
  Cancel: "取消",
  SetAsDefault: "设为默认",
  Default: "默认",
  DefaultAddress: "默认地址",
  Required: "必填项",
  TleLengthOfTheInputExceedNumCharacters: "输入内容的长度不能超过{num}个字符",
  TheLengthOfTheInputMustBeAtLeast: "输入内容的长度至少为{num}个字符",
  PleaseEnterAValidEmailAddressExclamationMark: "请输入有效邮箱！",
  Loading: "加载中",
  Male: "男",
  Female: "女",
  Other: "其它",
  Secret: "保密",
  ByShopName: "来自",
  Odm: "ODM",
  Oem: "OEM",
  Customization: "定制化",
  GetThePriceCommaSpecificationsCommaShipping:
    "免费获得产品价格，规格，物流和更多信息！",
  SignUpNow: "立即注册",
  LoginNow: "立即登录",
  Quantity: "数量",
  Submit: "提交",
  Collection: "收藏",
  AddToCart: "加入购物车",
  AddToPurcaseList: "加入采购单",
  PleaseSelectOptions: "请选择",
  ContinueToCart: "前往购物车",
  ContinueToPurchaseList: "前往采购清单",
  DisabledPeople: "残障人士",
  OopsCommaUploadFileExceedsMaximunSize: "您上传的文件超过了10M。请重新上传。",
  OopsCommaUploadFileIsNotAValidImage:
    "您上传的文件不是有效的图片格式，您只能使用JPG和PNG文件。请重新上传。",
  Cart: "购物车",
  LeftParenthesisPricePerItemRigthParenthesis: "({price}/每件)",
  AddedToCart: "已加入购物车",
  AddedToPurcaseList: "成功加入采购清单",
  Specifications: "产品规格",
  Shipping: "运输信息",
  Price: "价格",
  PrintLabel: "打印标签",
  PrintQrCode: "打印二维码",
  YouCanSave: "你可以节省",
  Product: "产品",
  ToDo: "操作",
  Total: "总计",

  ContactUs: "联系我们",
  Quote: "发起询价",
  RemoveFromCart: "从购物车中删除",
  SelectAll: "全选",
  SignIn: "注册",
  ContinueShopping: "继续购物",
  Login: "登录",
  // merge
  // merge

  Recommended: "综合推荐",
  Viewed: "浏览次数",
  NewReleases: "最新上架",
  Size: "尺寸",
  Material: "材质",
  Color: "颜色",
  Style: "风格",
  MoreFilters: "更多筛选",
  Clear: "清空",
  CountResults: "{count}个结果",
  NewArrivals: "新品",
  Selected: "已选择",
  DashDashNoPreferenceDashDash: "-- 请选择 --",
  DesignIdeas: "设计理念",
  NumIdeas: " {num}个设计理念",
  Hide: "隐藏",
  Show: "显示",
  ConnectWithUs: "与我们一起",
  MegaHomeUsAddress: "39055 Hastings Street, Suite 208 Fremont, CA 94538",
  WeHaveRevicedYourFeedback: "我们已收到您的反馈，我们会尽快与您联系。",
  SendAMessage: "发送消息",
  TellUsAboutYourNeeds: "告诉我们您的需求",
  Inquire: "咨询",
  Feedback: "反馈",
  Partenrship: "商务合作",
  ItHelp: "技术支持",
  Others: "其它",
  HotelVendor: "酒店供应商",
  Retailers: "零售商",
  Designers: "设计师",
  Suppliers: "供应商",
  About: "关于",
  AboutUs: "关于我们",
  Join: "加入",
  Marketing: "市场推广",
  Disclaimer: "免责声明",
  Apply: "应用",
  Return: "返回",
  LookingForMoreStylesQuestionMark: "查看更多样式？",
  FindMore: "发现更多",

  Share: "分享",
  AddToCartSuccessfullyExclamationMark: "成功添加至购物车！",
  ProductDetails: "产品详情",
  Description: "描述",
  WarningColonLeftBracketCaliforniaPropositionSixtyFive:
    "警告：[加利福尼亚州65号提案]请与供应商确认产品安全。",
  Features: "特征",
  ProductFeatures: "产品特征",
  ComponentsData: "部件数据",
  Compare: "相似产品对比",
  Reviews: "评价",
  SimilarProducts: "相似产品",
  BuyTogether: "一起购买",
  ComparewithSimilarPorducts: "与同类产品比较",
  CustomerRating: "客户评分",
  SortByMostRecently: "按最新排序",
  SortByRating: "按评分排序",
  ReviewProductFromOrder: "评价订单中的产品",
  PleaseReviewOurProductsComma:
    "请对我们的产品进行评价，并将您的经验分享给其他客户。",
  AsteriskSignYouDoNotHaveFinishedOrder: "*您没有可以评价的订单产品。",
  MoreReviews: "更多评价",
  MadeInCountry: "{country} 制造",
  PriceIncluded: "价格已含",
  MinPeriod: "最低",
  MaxPeriod: "最高",
  MyShopingCart: "我的购物车",
  CountItems: "{count}个产品",
  AreYouConfirmToRemoveTheseItems: "您确定要从购物车中删除这些物品吗？",
  YouHaveItemAddedWithDifferentCurrencyType:
    "抱歉，您添加了具有不同货币类型的商品。 请仅选择一种货币进行报价。",
  OopsExclamationMarkYouShoppingCartIsEmpty: "抱歉，您的购物车是空的。",
  SignInToSeeWhatYouHaveAddedOrSaved: "登录后查看您添加或保存的内容。",
  WeHaveALotOfSpecialProductOffers:
    "我们可以提供很多特殊定制的报价，或从我们的网站上获取更多的灵感。",
  SelectedColonCountItems: "已选择：{count} 个产品",
  StartFrom: "起始",
  ContainersFittingEstimate: "集装箱装载量估计",
  QuoteInformation: "询价信息",
  SubmittedExclamationMark: "已提交！",
  SelectFromSavedAddress: "从保存的地址中选择",
  WeWillGetBackToYou: "我们将尽快与您联系！",

  OopsExclamationMark: "糟糕！",
  WeCannotFindAnyResultThatMatchYour: "我们无法找到您的搜索结果:",
  ThePageTitleStillUnderConstructionPeriod:
    "当前{title}页面仍在建设中，感谢您的等待！",
  SometingYouMayLikeColon: "您可能也喜欢:",
  OopsExclamationMarkCurrentFilterOptionsDoNot:
    "糟糕，当前的选项没有任何结果，请重新选择。",
  SortAndFilter: "排序和筛选",
  Supplier: "供应商",
  Shop: "店铺",
  ShopEstablishedDate: "成立日期",
  ShopRecommendedProducts: "精选产品",
  FurnitureAndDecor: "家具与装饰品",
  SearchInThisShop: "当前店铺内搜索",
  Copyright: "版权所有",
  SearchItemsFromShop: "在{shop}中搜索产品",
  Categories: "推荐品类",
  Products: "产品列表",
  OptionNotAvailable: "暂不可选",
  New: "新",
  Discount: "折",
  Logistics: "物流",
  DeliveryDate: "发货时间",
  LogisticsRemark: "物流说明",
  Service: "服务",
  SortingWay: "排序方式",
  CollectionDesignRecommendation: "搭配设计推荐",
  ServicePoint: "服务点",
  MakeAnAppointment: "预约",
  AppointmentServicePoin: "预约服务点",
  Contact: "联系方式",
  MakeAnAppointmentDate: "预约日期",
  MakeAnAppointmentTime: "预约时间",
  PleaseDonNotContactMe: "请勿主动联系我",
  RecommendedDetails: "推荐详情",
  MyMakeAnAppointment: "我的预约",
  MyWallet: "我的钱包",
  LoggedOut: "退出登录",
  Consignee: "收货人",
  DetailedAddress: "详细地址",
  DetailedAddressInfo: "详细地址：如街道，门牌号，小区，楼栋号，单位号等",
  AddressTag: "地址标签",
  AllOrders: "全部订单",
  ForThePayment: "待付款",
  WaitSend: "待发货",
  ForTheGoods: "待收货",
  ToEvaluate: "待评价",
  OrderNumber: "订单编号",
  TimeFiltering: "按时间筛选",
  PleaseEnterStartTime: "请选择开始时间",
  PleaseEnterStopTime: "请选择结束时间",
  PleaseEnterOrderNumberSearch: "请输入订单号搜索",
  ReferenceCode: "代理码",
  OrderPrice: "订单金额",
  NoAccount: "没有账号",
  LoginFromEmail: "邮箱登录",
  LoginFromPhone: "手机登录",
  EnterTelePhone: "输入手机号",
  PleaseEnterTelePhone: "请输入手机号",
  EnterCode: "输入验证码",
  PleaseEnterCode: "请输入验证码",
  CourierNumber: "快递单号",
  InDistribution: "配送方式",
  ProductInfomation: "商品信息",
  FreightPrice: "运费价格",
  DiscountPrice: "折扣价格",
  ActualPrice: "实付价格",

  Inventory: "库存",
  DeliveryTo: "配送至",
  CurrentSelect: "当前选择",
  ShowOriginPrice: "显示价格",
  CustomerService: "客户服务",
  Tenants: "商家入驻",
  Position: "职位",
  FirmName: "公司名称",
  EnterPosition: "输入职位",
  EnterFirmName: "输入公司名称",
  Email: "邮箱",
  EligibilityForExemption: "申请限免资格",
  LetOurProfessionalAccountManagerMakeTheSolutionForYou:
    "让我们专业的客户经理为您制定解决方案",
  PleaseConfirmDelete: "确认删除？",
  Prompt: "提示",
  ConfirmTheGoods: "确认收货",
  BuyAgain: "再次购买",
  LogisticsInformation: "物流信息",
  DistributionorName: "配送员姓名",
  DistributionorPhone: "配送员电话",
  EstimatedTimeOfSrrival: "预计到达时间",
  ApplyAfterSale: "申请售后",
  ImmediatelyReview: "立即评价",
  Refund: "退款",
  ReturnGoodsRefund: "退货退款",
  AfterSaleType: "售后类型",
  AllRefund: "全部退款",
  SmallRefund: "部分退款",
  All: "全部",
  PartOfThe: "部分",
  ApplyProject: "申请项目",
  AfterSaleSeason: "申请原因",
  PleaseEnterAfterSaleSeason: "请输入申请原因",
  SubmitApply: "提交申请",
  ApplyQuantity: "申请数量",
  Credentials: "凭证",
  CredentialsNumber: "凭证({num}/{total})",
  BusinessAfterSaleStatus: "商家审核状态",
  CancelApply: "取消申请",
  PerfectInformation: "完善信息",
  BusinessPickupAddress: "商家取货地址",
  ThisAddressIsTheDoorToFoorPickUpAddressOfTheMerchant:
    "该地址是商家上门取货地址",
  Contactor: "联系人",
  PleaseEnter: "请输入",
  ConfirmAddress: "确认地址",
  BusinessAddress: "商家地址",
  ContactPhoneNumber: "联系电话",
  ShippingAddress: "收货地址",
  ThisAddressIsTheAddressYouDendBackToTheMerchant: "该地址是你寄回给商家的地址",
  LogisticsType: "物流类型",
  Courier: "快递",
  LogisticsTypeCourierNumber: "快递/物流编号",
  LogisticsCourierFirmName: "快递/物流公司名称",
  AddPicture: "添加图片",
  ToBOrderList: "企业采购清单",
  ToBOrderHistory: "企业采购历史",
  ViewDetails: "查看详情",
  PersonalDeliveryAddress: "个人收货地址",
  EnterprisePurchasingAddress: "企业采购地址",
  EnterprisePurchasingContactor: "企业采购联系人",
  ImmediatelyQuote: "立即询价",
  PleaseFillInTheContactInformation: "请填写联系方式",
  ShareListing: "分享清单",
  PrintListing: "打印清单",
  ExportListing: "导出清单",
  SearchItems: "搜索商品",
  PriceInfo: "价格信息",
  SelectedNumber: "已选商品数",
  PriceTotal: "总价",
  BackToList: "返回列表",
  StoreCustomerService: "店铺客服",
  CustomerServiceTime: "客服时间",
  PlatformCustomerService: "平台客服",
  ViewAfterSaleDetails: "查看售后详情",
  AfterSale: "售后",
  HidingPersonalInfomation: "隐藏个人信息",
  HidingPriceInfomation: "隐藏价格信息",
  HidingAgentIncome: "隐藏代理收益",
  ShareOrder: "分享订单",
  PaySuccess: "支付成功",
  PageVill: "页面将在",
  ThenLink: "后跳转",
  ShippingInfo: "收货信息",
  AddContactor: "添加联系人",
  YouCanAddUpToTwentyContactor:
    "您最多可以添加20个联系人。 您已添加{num}个联系人",
  PleaseSelectUserContact: "请选择联系方式",
  BackToOrderDetails: "返回订单详情",
  OriginPrice: "原价",
  StayTuned: "敬请期待",
  ApplyAmount: "申请金额",
  ThereIsNoOrder: "还没有订单，快去看看商品吧！",
  AgentInformation: "代理信息",
  AsAgent: "成为代理",
  YouAreNotAnAgentNowPleaseBecomeOneSoon: "您现在还不是代理, 请快成为代理吧!",
  UpdateInformation: "更新信息",
  AgentCertification: "代理认证",
  IdCardNumber: "身份证号",
  IdCardPhoto: "身份证照片",
  UploadImg: "上传图片",
  PhotoPage: "照片面",
  NationalEmblemPage: "国徽面",
  Handheld: "手持",
  RecommendedCode: "推荐码",
  AgainCertification: "重新认证",
  PaymentAccount: "收款账户",
  AgentCode: "代理码",
  PlatformAgent: "平台代理",
  ShopAgent: "品牌代理",
  CompletionInformation: "补全信息",
  PleaseEnterIdCardNumber: "请输入身份证号",
  MyTakeList: "我的带单",
  UploadFileSizeCannotExceedNumberMb: "上传文件大小不能超过{number}MB!",
  Order: "订单",
  Brand: "品牌",
  Invitation: "邀请",
  IsAboutToArrive: "即将到账",
  HaveToAccount: "已到账",
  HasTheFailure: "已失效",
  BussinessAgent: "平台代理",
  BrandAgent: "品牌代理",
  MyCommission: "我的带单",
  YouHaveBeenAddedAsTheDirectAgentPleaseCompleteTheTnformation:
    "已添加你为直属代理，请补全信息",
  WrongInformationContactCustomerServiceToModify: "信息有误，联系客服修改",
  RootContactPhone: "根账户电话",
  PlatformPhone: "平台电话",
  Date: "日期",
  AgentType: "代理类型",
  AgentBrand: "代理品牌",
  TelephonePrivate: "电话尾号",
  PleaseEnterTheLastFourDigits: "请输入电话后四位",
  Create: "创建",
  PaymentAmount: "付款金额",
  ExpectedIncome: "预计收入",
  CopyLink: "复制链接",
  AddWithdrawalMethod: "添加提现方式",
  EditWithdrawalMethod: "编辑提现方式",
  Bank: "银行",
  DepositBank: "开户行",
  BankCardNumber: "银行卡号",
  Add: "添加",
  Edit: "修改",
  agentBrand: "代理品牌",
  PeopleSurfac: "人像面",
  HandheldPeopleSurfac: "手持人像面",
  DoorModel: "户型",
  CreateFolder: "新建文件夹",
  MyDesignIdea: "我的设计",
  StartDesign: "开始设计",
  ConfirmInformation: "确认信息",
};
