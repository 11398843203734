import React, { useEffect, useState, useCallback, useContext } from "react";
import { Dispatch, RootState } from "../../store/configureStore";
import { useSelector, useDispatch } from "react-redux";
import { CommonProps } from "../../typings/component";
import { DesignIdea } from "../../models/DesignIdea";
import { BusinessContext } from "../BusinessContext";
import { Row, Col, Tag, Space, PageHeader, message } from "antd";
import { Product } from "../../models/Product";
import { useIntl } from "react-intl";
import { useTheme } from "styled-components";

import Checkbox from "../../wrappers/checkbox";
import Modal from "../../wrappers/modal";
import { Link } from "../../wrappers/typography";

import { TagType } from "antd/lib/tag";

import ProductParams from "./ProductParams";
import ProductCompareModal from "./ProductCompareModal";
import ProductDetails from "./ProductDetails";
import ProductShow from "./ProductShow";
import replaceState from "../../helpers/reolaceState";

const ProductDetailContent: React.FC<CommonProps> = ({ isMobile }) => {
  const { formatMessage: f } = useIntl();
  const {
    primaryColor,
    linkColor,
    secondaryColor,
    bussinessPrimaryColor,
  } = useTheme();
  const { isLogin } = useSelector((state: RootState) => state.User);
  const dispatch = useDispatch<Dispatch>();

  const {
    productReviewStatistic,
    sku,
    productDetails,
    designIdeas = [],
    productComparison = [],
    is_preview,
    selectedProvider,
  } = useSelector((state: RootState) => state.PDPPage);

  const { product: products = [], default_product_id } = sku || {};

  const { mediaData: partData = [] } = productDetails || {};
  const { PDPPage: rematch } = useDispatch<Dispatch>();

  const mediaData = [
    ...partData,
    ...designIdeas.map((value: DesignIdea) => ({ type: 7, value })),
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  const { pid, toreview } = useSelector((state: RootState) => state.URLSearch);

  const productId = Number(pid || default_product_id);

  useEffect(() => {
    if (productId && !is_preview) {
      dispatch.PDPPage.getDesignIdeaAsync(productId);
      dispatch.PDPPage.getActivityAsync({ shop_product_id: productId });

      setActiveIndex(0);
    }
  }, [productId, is_preview]);

  const currentQrImg = useCallback(() => {
    if (mediaData.length > 0) {
      if (mediaData[activeIndex] && mediaData[activeIndex].type !== 6) {
        return mediaData[activeIndex].value;
      }

      return mediaData[0].value;
    }

    return "";
  }, [mediaData, activeIndex]);

  const productItem = products.find(
    (p: Product) => p.shop_product_id === productId,
  );

  const { isToBusiness } = useContext(BusinessContext);

  const handleContrastCheck = useCallback(
    (e: React.SyntheticEvent<{}>, checked: boolean) => {
      dispatch.PDPPage.placeProductInContrast({
        shop_product_id: productItem?.shop_product_id,
        checked,
      });
    },
    [productItem],
  );

  const [contrastModel, setContrastModel] = useState(false);

  const contrastCheck =
    productItem &&
    productComparison
      .map(p => p.shop_product_id)
      .includes(productItem.shop_product_id);

  useEffect(() => {
    if (
      !is_preview &&
      (!productReviewStatistic ||
        Object.keys(productReviewStatistic).length === 0) &&
      productId
    ) {
      rematch.getProductReviewStatisticAsync(productId);
    }
  }, [is_preview]);

  const seriesBrandTags: React.ReactElement<TagType>[] = [];

  if (sku?.series_name) {
    seriesBrandTags.push(
      <Tag
        color={isToBusiness ? bussinessPrimaryColor : secondaryColor}
        key="series"
      >
        {sku.series_name}
      </Tag>,
    );
  }

  if (sku?.brand_name) {
    seriesBrandTags.push(
      <Tag
        color={isToBusiness ? bussinessPrimaryColor : secondaryColor}
        key="brand"
      >
        {sku.brand_name}
      </Tag>,
    );
  }

  return productItem ? (
    <PageHeader
      className="page-content-header"
      title={productItem.product_name}
      subTitle={
        <Link
          href={`${isToBusiness ? "/tob" : ""}${sku.shop_url}`}
          target="_blank"
          onClick={e => {
            e.preventDefault();
            replaceState.linkTo(
              `${isToBusiness ? "/tob" : ""}${sku.shop_url}`,
              "_target",
            );
          }}
        >
          {sku.shop_name}
        </Link>
      }
      tags={seriesBrandTags}
      extra={[
        <div key="compare">
          {!isToBusiness && isLogin && (
            <Space size={5}>
              <Checkbox
                shape="circle"
                borderWidth="2px"
                color={isToBusiness ? bussinessPrimaryColor : primaryColor}
                onClick={e => handleContrastCheck(e, !contrastCheck)}
                checked={contrastCheck}
                disabled={!contrastCheck && productComparison.length >= 4}
              />
              <a
                onClick={e => {
                  if (productComparison.length > 0) setContrastModel(true);
                  else message.info("请勾选要对比的产品！");
                }}
                style={{
                  color: isToBusiness ? bussinessPrimaryColor : linkColor,
                  fontSize: "1.2em",
                }}
              >
                {f({ id: "Compare" })}
              </a>
            </Space>
          )}
        </div>,
      ]}
    >
      <Row gutter={10}>
        <Col span={14} style={{ marginBottom: "15px" }}>
          <ProductShow
            mediaList={mediaData}
            onChangeImg={media => setActiveIndex(media)}
            onChangeUserLike={data =>
              dispatch.Collections.submitCollectAsync({
                ...data,
                callback: (ideaId: number) =>
                  dispatch.PDPPage.submitCollectDesign(ideaId),
              })
            }
            activeIndex={activeIndex}
          ></ProductShow>

          {productItem && (
            <ProductDetails
              productItem={productItem}
              productDetails={productDetails}
              isLogin={isLogin}
              isPreview={is_preview}
              provider={selectedProvider}
              toreview={Boolean(toreview)}
            />
          )}
        </Col>

        <Col span={10}>
          <ProductParams qrcodeimg={currentQrImg()} />
        </Col>
      </Row>
      <Modal
        onCancel={() => setContrastModel(false)}
        visible={contrastModel}
        width="90%"
        title={f({ id: "ComparewithSimilarPorducts" })}
        footer={null}
      >
        <ProductCompareModal handleClose={() => setContrastModel(false)} />
      </Modal>
    </PageHeader>
  ) : null;
};

export default React.memo(ProductDetailContent);
