import classNames from "classnames";
import * as styles from "./index.scss";
import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import { Dispatch, RootState } from "../../store/configureStore";
import { useSelector, useDispatch } from "react-redux";
import replaceState from "../../helpers/reolaceState";
import { matchDistrictByCode } from "../../helpers/address.helper";
import { Product, ProductCatalog } from "../../models/Product";
import { CommonProps } from "../../typings/component";
import ProductBaseInfo from "./ProductBaseInfo";
import ProductPurchase from "./ProductPurchase";
import SimilarProducts from "./SimilarProducts";
import ProductDesignIdea from "./ProductDesignIdea";
import { FormattedMessage, useIntl } from "react-intl";
import { BusinessContext } from "../BusinessContext";
import Icon from "../../components/icon";
import Image from "../../components/image";
import ProductFilter from "./Components/ProductFilter";
import {
  List,
  Avatar,
  Dropdown,
  Menu,
  Tag,
  Input,
  Row,
  Col,
  Form,
  Affix,
  Space,
  Card,
  Cascader,
} from "antd";
import DatePicker from "../../wrappers/date-picker";
import Modal from "../../wrappers/modal";
import Drawer from "../../wrappers/drawer";
import Checkbox from "../../wrappers/checkbox";
import Carousel from "../../wrappers/carousel";
import Button, { PrimaryButton } from "../../wrappers/button";
import { Link, Text } from "../../wrappers/typography";

import Provider from "../../models/Provider";
import Address from "../../models/Address";

import pickBy from "lodash/pickBy";
import flatten from "lodash/flatten";
import flatMap from "lodash/flatMap";
import groupBy from "lodash/groupBy";
import uniqBy from "lodash/uniqBy";
import xorBy from "lodash/xorBy";
import head from "lodash/head";
import intersectionBy from "lodash/intersectionBy";
import classnames from "classnames";
import { useTheme } from "styled-components";
import { Map } from "react-amap";
import { addressDatas } from "../../helpers/chinaAddressData";
import { useUpdateEffect } from "ahooks";
import { CascaderOptionType } from "antd/lib/cascader";
import dayjs from "dayjs";
interface NewFormProp {
  onClose: () => void;
}

const { TextArea } = Input;

const ProductParamsContent: React.FC<
  CommonProps & {
    qrcodeimg: string;
  }
> = props => {
  const { formatMessage: f } = useIntl();
  const {
    bussinessPrimaryColor,
    primaryColor,
    secondaryColor,
    panelColor,
    linkColor,
  } = useTheme();
  const { isToBusiness } = useContext(BusinessContext);
  const { isMobile, qrcodeimg } = props;
  const [isOpen, setOpen] = useState(false);
  const [isAddressOpen, setAddressOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { address: userAddresses = [] } = useSelector(
    (state: RootState) => state.AddressPage,
  );
  const [selectedAddress, setSelectedAddress] = useState<Address>();
  const [manualSelect, setManualSelect] = useState(false);
  const [currLocation, setCurrLocation] = useState<{
    province: string;
    city: string;
    district: string;
    township: string;
    adcode: string;
  }>();

  const [optionOpen, setOptionOpen] = useState(false);
  const [optionTitle, setOptionTitle] = useState("");
  const [optionList, setOptionList] = useState<ProductCatalog[]>([]);
  const dispatch = useDispatch<Dispatch>();
  const { PDPPage: rematch } = useDispatch<Dispatch>();

  const {
    Collections: collection = [],
    URLSearch: agent_code = "",
  } = useSelector((state: RootState) => state);

  const { AgentRate } = useSelector((state: RootState) => state);

  const { isPrivate } = useSelector((state: RootState) => state.CommonData);

  const {
    sku,
    recommendProductsCaches = [],
    productDetails,
    designIdeas = [],
    productProviders,
    selectedProvider,
    is_preview,
    productActivities,
  } = useSelector((state: RootState) => state.PDPPage);
  const {
    pid,
    toreview,
    currency,
    reference_code = "",
    search = "",
    provider,
    host_id,
  } = useSelector((state: RootState) => state.URLSearch);

  const [filteredProviders, setFilteredProviders] = useState(productProviders);

  const { isLogin, isAgent } = useSelector((state: RootState) => state.User);

  const { product: products = [], default_product_id } = sku || {};

  const { mediaData: partData = [] } = productDetails || {};

  const productId = Number(pid || default_product_id);

  const productItem = products.find(
    (p: Product) => p.shop_product_id === productId,
  );
  const [isActStart, setIsActStart] = useState(false);
  const [activityId, setActivityId] = useState<any>();
  const [isAct, setIsAct] = useState(false);
  useEffect(() => {
    if (productId && !is_preview) {
      rematch.getProductReviewStatisticAsync(productId);
      if (!isToBusiness) {
        rematch.getProductProvidersAsync(productId);
      }
    }
  }, [productId, is_preview, isToBusiness]);

  useEffect(() => {
    if (isLogin && !is_preview) {
      dispatch.Collections.getCollectionAsync();

      dispatch.AddressPage.getAddressDataAsync();
    }
  }, [isLogin, is_preview]);

  useUpdateEffect(() => {
    if (!is_preview) {
      let defaultAddr: Address | undefined;

      if (userAddresses.length > 0) {
        defaultAddr = userAddresses.find(a => a.is_default) || userAddresses[0];

        if (currLocation) {
          const matchedAddress = matchDistrictByCode(
            userAddresses,
            currLocation.adcode,
            "district_adcode",
          );

          if (matchedAddress) {
            defaultAddr = matchedAddress;
          }
        }

        setSelectedAddress(defaultAddr);
      } else if (currLocation) {
        defaultAddr = new Address({
          province: currLocation.province,
          city:
            currLocation.city + currLocation.district + currLocation.township,
          area: "",
          district_adcode: currLocation.adcode,
        });

        setSelectedAddress(defaultAddr);
      }
    }
  }, [userAddresses.length, currLocation]);

  useUpdateEffect(() => {
    if (!is_preview && productProviders) {
      setFilteredProviders(productProviders);

      let productProvider = head(productProviders);

      if (selectedAddress && selectedAddress.district_adcode) {
        const province = selectedAddress.district_adcode.substr(0, 2),
          city = selectedAddress.district_adcode.substr(0, 4),
          district = selectedAddress.district_adcode;

        const matchedProviders = productProviders.filter(pp => {
          const deliveryInfo = pp.delivery_info;

          if (!deliveryInfo || deliveryInfo.length === 0) return false;

          const { delivery_type } = deliveryInfo[0];

          if (delivery_type === 1) {
            return deliveryInfo.some(
              d =>
                d.district_level === "country" ||
                (d.district_level === "province" &&
                  d.district_adcode.startsWith(province)) ||
                (d.district_level === "city" &&
                  d.district_adcode.startsWith(city)) ||
                (d.district_level === "district" &&
                  d.district_adcode.startsWith(district)),
            );
          }

          if (delivery_type === 2) {
            return deliveryInfo.every(
              d =>
                (d.district_level === "country" && false) ||
                (d.district_level === "province" &&
                  !d.district_adcode.startsWith(province)) ||
                (d.district_level === "city" &&
                  !d.district_adcode.startsWith(city)) ||
                (d.district_level === "district" &&
                  !d.district_adcode.startsWith(district)),
            );
          }

          return false;
        });

        setFilteredProviders(matchedProviders);

        const defaultProvider = head(matchedProviders);

        productProvider =
          provider && !manualSelect
            ? matchedProviders.find(
                pp => pp.provider_id === Number(provider),
              ) || defaultProvider
            : defaultProvider;

        setManualSelect(true);
      }

      dispatch.PDPPage.selectProvider(productProvider);
    }
  }, [selectedAddress, productProviders]);

  useEffect(() => {
    // pid变化时
    if (productId && !is_preview) {
      const rp = recommendProductsCaches.find(rps => rps.pid === productId);

      if (rp) {
        dispatch.PDPPage.setRecommendProducts({
          recommendProducts: flatten(rp.RecommendProducts),
          recommendProductsCaches: {},
        });
      } else {
        dispatch.PDPPage.getRecommendProductsAsync({
          id: productId,
        });
      }
    }
  }, [productId, isLogin, is_preview]);

  useEffect(() => {
    const storageContrast =
      localStorage.getItem("StoreProductContrast") || "[]";

    if (storageContrast && storageContrast !== "undefined") {
      dispatch.PDPPage.initProductComparison(JSON.parse(storageContrast));
    }
  }, []);

  useEffect(() => {
    if (isAgent) {
      dispatch.AgentRate.getAgentRateAsync({ shopIds: String(sku.shop_id) });
    }
  }, [sku]);

  const [showQrCode, setShowQrCode] = useState(false);

  const handleQrCodeVisibleChange = (type: any) => {
    setShowQrCode(type);
  };

  const handleSubmitForm = useCallback(
    (value: any) => {
      const payload = {
        ...value,
        shop_product_id: productId,
        provider_id: selectedProvider?.provider_id,
        arriving_time: value.arriving_time.format("YYYY-MM-DD HH:mm:ss"),
        is_not_disturb: Number(value.is_not_disturb),
        callback: () => {
          setIsModalOpen(false);
        },
      };

      rematch.appointProviderAsync(payload);
    },
    [productId, selectedProvider],
  );

  //预约服务点弹窗
  const AppointmentServicePoinFrom = React.memo<NewFormProp>(({ onClose }) => {
    const { formatMessage: f } = useIntl();
    const requiredText = f({ id: "Required" });
    const formItemLayout = {
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 20,
      },
    };
    return (
      <Form
        name="AppointmentServicePoinFrom"
        {...formItemLayout}
        initialValues={{ is_not_disturb: false }}
        onFinish={handleSubmitForm}
      >
        <Form.Item
          name="customer_name"
          rules={[{ required: true, message: requiredText }]}
          label={f({
            id: "Name",
          })}
        >
          <Input
            placeholder={`${f({
              id: "Name",
            })} *`}
          />
        </Form.Item>
        <Row>
          <Col span={4}></Col>
          <Col span={20}>
            <Form.Item name="is_not_disturb" valuePropName="checked">
              <Checkbox shape="circle">
                {f({
                  id: "PleaseDonNotContactMe",
                })}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="customer_phone"
          label={f({
            id: "Contact",
          })}
          rules={[
            { required: true, message: requiredText },
            {
              pattern: /^1[3|4|5|7|8][0-9]{9}$/,
              message: f({ id: "PleaseEnterTelePhone" }),
            },
          ]}
        >
          <Input
            placeholder={f({
              id: "PleaseEnterTelePhone",
            })}
          />
        </Form.Item>

        <Form.Item
          name="arriving_time"
          label={f({
            id: "MakeAnAppointmentTime",
          })}
          rules={[{ required: true, message: requiredText }]}
        >
          <DatePicker
            showTime
            style={{
              width: "100%",
              background: panelColor,
            }}
            format="YYYY-MM-DD HH:mm"
          />
        </Form.Item>

        <Form.Item
          name="customer_remark"
          label={f({
            id: "Note",
          })}
        >
          <TextArea
            placeholder={`${f({
              id: "Note",
            })}`}
          />
        </Form.Item>
        <Row>
          <Col span={24} style={{ textAlign: "right" }}>
            <Space>
              <PrimaryButton onClick={onClose} bgColor={secondaryColor}>
                {f({ id: "Return" })}
                <Icon type="icont1_shuaxin"></Icon>
              </PrimaryButton>
              <PrimaryButton htmlType="submit" bgColor={primaryColor}>
                {f({ id: "Submit" })}
                <Icon type="icont1_correct"></Icon>
              </PrimaryButton>
            </Space>
          </Col>
        </Row>
      </Form>
    );
  });

  const boxStyle = { overflow: "initial", height: "auto" };

  const tempProducts = products.map(p => ({
    ...p,
    specification: p.specification.map(s => ({
      ...s,
      price:
        p.shop_product_id === productId
          ? selectedProvider?.tag_price_value
          : "",
      imgSrc: p.product_picture,
      productId: p.shop_product_id,
      key: s.category_id === 0 ? s.category_sub_name : s.category_id,
      value:
        s.category_id === 1 || s.category_id === 6
          ? s.category_sub_name
          : s.attribution_value,
      id:
        s.category_id === 0
          ? `${s.category_sub_name}-${s.attribution_value}`
          : `${s.category_id}-${s.category_sub_name}-${s.attribution_value}`,
    })),
  }));

  // 先拍平，再按key分组，最后按value去除重复
  const allSpecifications = flatMap<Product, ProductCatalog>(
    tempProducts,
    item => item.specification.filter(s => s.is_option),
  );

  let dicSpecifications = groupBy(allSpecifications, "key");

  // value有两个值时才算option
  Object.keys(dicSpecifications).forEach(
    key => (dicSpecifications[key] = uniqBy(dicSpecifications[key], "value")),
  );

  // option成立原则
  // 1、所有单品都有的属性才能是option
  // 2、属性取值多于1个才能是option
  dicSpecifications = pickBy(
    dicSpecifications,
    (value, key) =>
      value.length > 1 &&
      tempProducts.every(p =>
        p.specification.map(s => s.key + "").includes(key),
      ),
  );

  const [currentOptions, setCurrentOptions] = useState<ProductCatalog[]>([]);

  useEffect(() => {
    setCurrentOptions(
      allSpecifications.filter(
        s =>
          s.productId === productItem?.shop_product_id &&
          dicSpecifications[s.key + ""],
      ),
    );
  }, [productItem]);

  const removedOptions = flatMap(
    pickBy(dicSpecifications, (value, key) => value.length === 1),
  );

  // 方法：为后续计算的方便性，查找满足原则的属性，然后从单品里删除
  tempProducts.forEach(p => {
    p.specification = p.specification.filter(
      s => !removedOptions.includes((ro: ProductCatalog) => ro.id === s.id),
    );
  });

  const handleChangeProductOption = useCallback(
    (catelog: ProductCatalog[], product?: Product) => {
      setOptionOpen(false);
      setOptionList([]);
      setCurrentOptions(catelog);

      if (product && product.shop_product_id !== productItem?.shop_product_id) {
        replaceState.set({ pid: product.shop_product_id });
        dispatch.PDPPage.getActivityAsync({
          shop_product_id: product.shop_product_id,
        });

        dispatch.PDPPage.getProductMediaAsync({
          id: product.shop_product_id,
          is_preview,
        });

        dispatch.PDPPage.changeProductOption(product.shop_product_id);
      }
    },
    [currentOptions, is_preview],
  );

  const handleOptionModalShow = useCallback((options: ProductCatalog[]) => {
    setOptionOpen(true);
    setOptionTitle(options[0].title);
    setOptionList(options);
  }, []);

  const [minimum, setMinimum] = useState(1),
    [maximum, setMaximum] = useState(1e10);

  const [productNumber, setProductNumber] = useState(minimum);

  const handleToCart = useCallback(() => {
    if (isLogin) {
      const data = {
        shop_product_id: productId,
        quantity: productNumber,
        agentCode: agent_code,
        callback: () => {
          setIsCanLinkCart(true);
        },
      };

      isToBusiness
        ? dispatch.PDPPage.addTobCartAsync(data)
        : dispatch.PDPPage.addToCartAsync({
            ...data,
            provider_id: selectedProvider?.provider_id,
            private: isPrivate,
            shop_id: host_id,
            activity_id: isAct && isActStart ? activityId : undefined,
          });
    } else {
      dispatch.User.changeUserState({
        modalAction: "Login",
      });
    }
  }, [selectedProvider, productId, isToBusiness, productNumber, activityId]);

  const [isCanLinkCart, setIsCanLinkCart] = useState(false);

  const handleSaveOrUnSave = useCallback(() => {
    if (isLogin) {
      dispatch.Collections.submitCollectAsync({
        action: collection.includes(productId) ? "cancel" : "like",
        object: "product",
        data_id: productId,
      });
    } else {
      dispatch.User.changeUserState({
        modalAction: "Login",
      });
    }
  }, [isLogin, collection, productItem, sku]);

  useEffect(() => {
    if (selectedProvider && productActivities) {
      const activityInfo = productActivities.filter(
        item =>
          Number(item.provider_id) === Number(selectedProvider.provider_id),
      );
      setActivityId(
        activityInfo.length > 0 ? activityInfo[0].activity_id : null,
      );
      setIsAct(activityInfo.length > 0);
      setMinimum(activityInfo.length > 0 ? 1 : selectedProvider.product_moq);
      setProductNumber(
        activityInfo.length > 0 ? 1 : selectedProvider.product_moq,
      );
      setMaximum(
        activityInfo.length > 0
          ? activityInfo[0].activity_purchase_limit
          : selectedProvider.inventory_quantity,
      );
      if (activityInfo.length > 0) {
        const startTime = new Date(activityInfo[0].start_time);
        const nowTime = new Date(activityInfo[0].now_time);
        const isStart = Number(startTime) - Number(nowTime) < 0;
        setIsActStart(isStart);
      }
    }
  }, [selectedProvider, productActivities]);

  const handleChangeProductNumber = useCallback(
    (e: React.ChangeEvent<any>) => {
      const num = Number(e.target.value);
      if (num > 0) {
        if (num < minimum) {
          setProductNumber(minimum);
        } else if (num > maximum) {
          setProductNumber(maximum);
        } else {
          setProductNumber(num);
        }
      }
    },
    [selectedProvider, minimum, maximum],
  );

  const [otherAddrVisable, setOtherAddrVisable] = useState(false);

  const [addressOptions, setAddressOptions] = useState(
    addressDatas
      .filter(a => a.level === "province")
      .map(a => ({ value: a.id, label: a.name, isLeaf: false })),
  );

  const loadData = (selectedOptions?: CascaderOptionType[]) => {
    if (!selectedOptions) return;

    const targetOption = selectedOptions[selectedOptions.length - 1];

    targetOption.children = addressDatas
      .filter(a => a.pid === targetOption.value)
      .map(a => ({
        value: a.id,
        label: a.name,
        isLeaf: a.level === "district",
      }));

    setAddressOptions(options => [...options]);
  };

  const getProviderPriceValue = (providerId: number, value: string) => {
    return productActivities.filter(m => m.provider_id === providerId).length >
      0
      ? productActivities.filter(m => m.provider_id === providerId)[0]
          .activity_price_value
      : value;
  };
  return (
    <div className={styles["ProductParams-box"]}>
      <div>
        {productItem && (
          <>
            <div className={classNames(styles.ProductParams)} style={boxStyle}>
              <div
                className={styles["ProductParams-Params-box"]}
                style={{
                  backgroundColor: panelColor,
                }}
              >
                {!is_preview && (
                  <PrimaryButton
                    onClick={() => {
                      setShowQrCode(!showQrCode);
                    }}
                    icon={
                      <Icon type="icont3_erweima" style={{ color: "white" }} />
                    }
                    className={styles["ProductParams-Params-box-qrcode-btn"]}
                    shape="circle"
                    size="large"
                    bgColor={
                      isToBusiness ? bussinessPrimaryColor : primaryColor
                    }
                  />
                )}
                <ProductBaseInfo
                  productItem={productItem}
                  isToReview={Boolean(toreview)}
                  currency={currency}
                  sku={sku}
                  dispatch={dispatch}
                  isLogin={isLogin}
                  search={search}
                  provider={selectedProvider}
                  isPreview={is_preview}
                  agentRates={AgentRate}
                />

                {/* {!isToBusiness && (
                  <Row style={{ marginBottom: is_preview ? "20px" : "" }}>
                    <Rating
                      value={rateStar}
                      disabled
                      className={styles["PDRating-rating"]}
                      color={
                        isToBusiness ? bussinessPrimaryColor : primaryColor
                      }
                    />
                    <span
                      style={{ marginLeft: "10px", display: "inline-block" }}
                    >
                      {productReviewStatistic
                        ? productReviewStatistic.review_rating
                        : 0}
                      {" / "}
                      <span
                        style={{
                          color: linkColor,
                          textDecoration: "underline",
                        }}
                      >
                        {productReviewStatistic
                          ? productReviewStatistic.review_total
                          : 0}{" "}
                        <FormattedMessage id="Review" />
                      </span>
                    </span>
                  </Row>
                )} */}

                {!is_preview && !isToBusiness && (
                  <>
                    <div style={{ marginBottom: "10px" }}>
                      <Space size={3}>
                        <FormattedMessage id="DeliveryTo" />{" "}
                        <Link
                          onClick={() => {
                            setAddressOpen(true);
                            setOtherAddrVisable(false);
                          }}
                        >
                          {selectedAddress
                            ? selectedAddress.province +
                              selectedAddress.city +
                              selectedAddress.area
                            : ""}
                        </Link>
                        <Link onClick={() => setAddressOpen(true)}>
                          <Icon type="iconxiaxiang" rotate={-90} />
                        </Link>
                        <Map
                          amapkey="5ae0a1ac9ed0e8251826c5528cccb4ad"
                          events={{
                            created: () => {
                              window.AMap.plugin(
                                ["AMap.Geolocation", "AMap.Geocoder"],
                                () => {
                                  const geolocation = new AMap.Geolocation({
                                    timeout: 10000, // 超过10秒后停止定位，默认：5s
                                  });

                                  geolocation.getCurrentPosition(
                                    (status, result) => {
                                      if (status === "complete") {
                                        const geocoder = new AMap.Geocoder({
                                          city: "010", // 城市设为北京，默认：“全国”
                                          radius: 1000, // 范围，默认：500
                                        });

                                        geocoder.getAddress(
                                          result.position,
                                          (status, result) => {
                                            if (
                                              status === "complete" &&
                                              result.regeocode
                                            ) {
                                              setCurrLocation(
                                                result.regeocode
                                                  .addressComponent,
                                              );
                                            }
                                          },
                                        );
                                      }
                                    },
                                  );
                                },
                              );
                            },
                          }}
                        />
                      </Space>
                      <Drawer
                        mask
                        visible={isAddressOpen}
                        onClose={() => setAddressOpen(false)}
                        title={otherAddrVisable ? "选择其它地址" : "配送至"}
                        width={500}
                      >
                        <div
                          className={styles["ProductParams-Drawer-conter-box"]}
                        >
                          <List
                            className="card-selector-list"
                            grid={{ gutter: 10, column: 1 }}
                            dataSource={userAddresses}
                            renderItem={(addr: Address, index) => (
                              <List.Item
                                key={index}
                                onClick={() => {
                                  setSelectedAddress(addr);
                                  setManualSelect(true);
                                  setAddressOpen(false);
                                }}
                                style={{
                                  border:
                                    selectedAddress === addr
                                      ? "1px solid " + primaryColor
                                      : "",
                                }}
                              >
                                <div className="list-item-box">
                                  <div>
                                    {addr.province +
                                      addr.city +
                                      addr.area +
                                      addr.address_details}
                                  </div>
                                </div>
                              </List.Item>
                            )}
                            footer={
                              <div style={{ textAlign: "right" }}>
                                <PrimaryButton
                                  bgColor={primaryColor}
                                  onClick={() => setOtherAddrVisable(true)}
                                >
                                  选择其它地址
                                </PrimaryButton>
                              </div>
                            }
                            style={{ display: otherAddrVisable ? "none" : "" }}
                          />
                          <Cascader
                            options={addressOptions}
                            loadData={loadData}
                            placeholder={f({ id: "PleaseSelectOptions" })}
                            onChange={val => {
                              const [provinceId, cityId, districtId] = val;

                              const province = addressDatas.find(
                                  a => a.id === provinceId,
                                ),
                                city = addressDatas.find(a => a.id === cityId),
                                district = addressDatas.find(
                                  a => a.id === districtId,
                                );

                              setSelectedAddress(
                                new Address({
                                  province: province?.name,
                                  city: city?.name,
                                  area: district ? district.name : "",
                                  district_adcode: district
                                    ? district.code
                                    : city?.code,
                                }),
                              );

                              setManualSelect(true);

                              setAddressOpen(false);
                            }}
                            size="large"
                            allowClear={false}
                            style={{
                              width: "100%",
                              display: otherAddrVisable ? "" : "none",
                            }}
                          ></Cascader>
                        </div>
                      </Drawer>
                    </div>
                    <ProductFilter
                      className={styles["ProductParams-ProductFilter"]}
                      title={
                        selectedProvider ? (
                          <div style={{ textTransform: "capitalize" }}>
                            <FormattedMessage id="ServicePoint" /> (
                            {filteredProviders.length || ""})
                          </div>
                        ) : null
                      }
                      value={
                        selectedProvider ? (
                          <div>
                            {selectedProvider.provider_name}（
                            <FormattedMessage id="Inventory" />{" "}
                            {selectedProvider.inventory_quantity}）
                          </div>
                        ) : (
                          <div style={{ color: primaryColor }}>
                            当前区域没有服务点，请切换寄送至区域
                          </div>
                        )
                      }
                      onClick={() => selectedProvider && setOpen(true)}
                    />
                  </>
                )}

                {Object.keys(dicSpecifications).length > 0 && (
                  <div className={styles["ProductParams-ProductOptions"]}>
                    <label>
                      <FormattedMessage id="CurrentSelect" />
                    </label>
                    {currentOptions?.map((s, index) => (
                      <Tag
                        key={index}
                        color={
                          isToBusiness ? bussinessPrimaryColor : secondaryColor
                        }
                      >
                        {s.category_id === 1 || s.category_id === 6
                          ? s.category_sub_name
                          : s.attribution_value}
                      </Tag>
                    ))}
                  </div>
                )}

                <div>
                  {Object.keys(dicSpecifications).map((key, index, array) =>
                    array.length === 1 || key === "1" ? (
                      <div
                        className={styles[`ProductParams-ProductFilter-color`]}
                        key={index}
                      >
                        {dicSpecifications[key].map(item => {
                          const specificationCount = Object.keys(
                            dicSpecifications,
                          ).length;

                          const allSelected =
                            currentOptions.length === specificationCount;

                          // 取消选中
                          const selectedOption = currentOptions.find(
                            s => s.id === item.id,
                          );
                          const otherOptions = currentOptions.filter(
                            co => co.key !== item.key,
                          );

                          const unionItems = [item, ...otherOptions];

                          const availiableProduct = tempProducts.find(
                            p =>
                              xorBy(
                                intersectionBy(
                                  p.specification,
                                  unionItems,
                                  "id",
                                ),
                                unionItems,
                                "id",
                              ).length === 0,
                          );

                          return (
                            <Card
                              size="small"
                              bordered={false}
                              style={{
                                width: array.length === 1 ? "84px" : "42px",
                              }}
                              bodyStyle={{
                                padding: "6px",
                                textAlign: "center",
                              }}
                              cover={
                                <a
                                  onClick={() =>
                                    handleChangeProductOption(
                                      selectedOption
                                        ? otherOptions
                                        : allSelected && !availiableProduct
                                        ? [item]
                                        : unionItems,
                                      selectedOption ||
                                        currentOptions.length <
                                          specificationCount - 1
                                        ? undefined
                                        : availiableProduct,
                                    )
                                  }
                                  title={
                                    item.category_id === 1 ||
                                    item.category_id === 6
                                      ? item.category_sub_name
                                      : item.attribution_value
                                  }
                                  key={item.productId}
                                >
                                  <Avatar
                                    src={item.imgSrc}
                                    shape="square"
                                    size={array.length === 1 ? 84 : 42}
                                    style={{
                                      border: selectedOption
                                        ? `1px solid ${primaryColor}`
                                        : "",
                                      opacity: availiableProduct ? "1" : "0.5",
                                    }}
                                  />
                                </a>
                              }
                            >
                              <Card.Meta
                                title={
                                  item.category_id === 1 ||
                                  item.category_id === 6
                                    ? item.category_sub_name
                                    : item.attribution_value
                                }
                                style={{
                                  display: array.length === 1 ? "" : "none",
                                }}
                              />
                            </Card>
                          );
                        })}
                      </div>
                    ) : (
                      <ProductFilter
                        key={index}
                        className={styles["ProductParams-ProductFilter"]}
                        title={
                          <div>
                            {`${dicSpecifications[key][0].title}(${dicSpecifications[key].length})`}
                          </div>
                        }
                        value={
                          <div>
                            {
                              currentOptions.find(co => String(co.key) === key)
                                ?.value
                            }
                          </div>
                        }
                        onClick={() =>
                          handleOptionModalShow(dicSpecifications[key])
                        }
                      />
                    ),
                  )}

                  <Drawer
                    mask
                    visible={optionOpen}
                    onClose={() => {
                      setOptionList([]);
                      setOptionOpen(false);
                    }}
                    title={optionTitle}
                    width={500}
                    bodyStyle={{ padding: 0 }}
                    color={isToBusiness ? bussinessPrimaryColor : primaryColor}
                  >
                    <div className={styles["ProductParams-Drawer-conter-box"]}>
                      <List
                        className="card-selector-list"
                        grid={{ gutter: 10, column: 1 }}
                        dataSource={optionList}
                        renderItem={(item, index) => {
                          const specificationCount = Object.keys(
                            dicSpecifications,
                          ).length;

                          const allSelected =
                            currentOptions.length === specificationCount;

                          const selectedOption = currentOptions.find(
                            s => s.id === item.id,
                          );

                          const otherOptions = currentOptions.filter(
                            co => co.key !== item.key,
                          );

                          const unionItems = [item, ...otherOptions];

                          // 判断集合是否相同：xor的结果是否为0
                          // 集合包含集合：两个集合的交集与第二个集合相同
                          const availiableProduct = tempProducts.find(
                            p =>
                              xorBy(
                                intersectionBy(
                                  p.specification,
                                  unionItems,
                                  "id",
                                ),
                                unionItems,
                                "id",
                              ).length === 0,
                          );

                          return (
                            <List.Item
                              key={index}
                              onClick={() =>
                                handleChangeProductOption(
                                  selectedOption
                                    ? otherOptions
                                    : allSelected && !availiableProduct
                                    ? [item]
                                    : unionItems,
                                  selectedOption ||
                                    currentOptions.length <
                                      specificationCount - 1
                                    ? undefined
                                    : availiableProduct,
                                )
                              }
                              style={{
                                border: selectedOption
                                  ? "1px solid " +
                                    (isToBusiness
                                      ? bussinessPrimaryColor
                                      : primaryColor)
                                  : "",
                                opacity: availiableProduct ? "1" : "0.5",
                              }}
                            >
                              <div className="list-item-box">
                                <div>
                                  {item.value}
                                  {availiableProduct
                                    ? ""
                                    : `（${f({ id: "OptionNotAvailable" })}）`}
                                  {selectedOption && (
                                    <Icon
                                      type="iconduihao2"
                                      style={{
                                        color: isToBusiness
                                          ? bussinessPrimaryColor
                                          : primaryColor,
                                      }}
                                    ></Icon>
                                  )}
                                </div>
                                <div>{item.price}</div>
                              </div>
                            </List.Item>
                          );
                        }}
                      />
                    </div>
                  </Drawer>
                </div>

                {!is_preview && (
                  <ProductPurchase
                    productItem={productItem}
                    currency={currency}
                    sku={sku}
                    collection={collection}
                    products={products}
                    productDetails={productDetails}
                    reference_code={reference_code}
                    qrcodeimg={qrcodeimg}
                    search={search}
                    dispatch={dispatch}
                    isLogin={isLogin}
                    designIdeas={designIdeas}
                    qrCodeVisible={showQrCode}
                    handleQrCodeVisibleChange={handleQrCodeVisibleChange}
                    provider={selectedProvider}
                    productOptions={currentOptions}
                  />
                )}

                <Drawer
                  mask
                  visible={isOpen}
                  onClose={() => setOpen(false)}
                  title={<FormattedMessage id="ServicePoint" />}
                  width={500}
                >
                  <div className={styles["ProductParams-Drawer-conter-box"]}>
                    <Space size={3} style={{ marginBottom: "16px" }}>
                      <Icon type="iconweizhi" />
                      <Text>
                        {selectedAddress
                          ? selectedAddress.province +
                            selectedAddress.city +
                            selectedAddress.area
                          : ""}
                      </Text>
                    </Space>
                    <List
                      className="card-selector-list"
                      grid={{ gutter: 10, column: 1 }}
                      dataSource={filteredProviders}
                      renderItem={(item: Provider, index) => (
                        <List.Item
                          key={index}
                          onClick={() => {
                            replaceState.set({ provider: item.provider_id });
                            dispatch.PDPPage.selectProvider(item);
                            dispatch.PDPPage.getActivityAsync({
                              shop_product_id: productId,
                            });

                            setOpen(false);
                          }}
                          style={{
                            border:
                              selectedProvider.provider_id === item.provider_id
                                ? "1px solid " + primaryColor
                                : "",
                          }}
                          actions={[
                            !isToBusiness && (
                              <PrimaryButton
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                  replaceState.linkTo(
                                    item.provider_url,
                                    "_blank",
                                  );
                                }}
                                bgColor={primaryColor}
                              >
                                查看服务点
                              </PrimaryButton>
                            ),
                            item.has_customer_experience && (
                              <a
                                onClick={e => {
                                  e.stopPropagation();
                                  setOpen(false);
                                  setIsModalOpen(true);
                                }}
                              >
                                <Avatar
                                  shape="circle"
                                  style={{
                                    backgroundColor: primaryColor,
                                  }}
                                  size="large"
                                >
                                  <FormattedMessage id="MakeAnAppointment" />
                                </Avatar>
                              </a>
                            ),
                          ]}
                        >
                          <List.Item.Meta
                            avatar={
                              <Avatar src={item.provider_logo} size={55} />
                            }
                            title={
                              <>
                                <Link
                                  color={linkColor}
                                  onClick={e => {
                                    e.stopPropagation();
                                    replaceState.linkTo(
                                      item.provider_url,
                                      "_blank",
                                    );
                                  }}
                                >
                                  {item.provider_name}
                                </Link>
                                <div>
                                  {item.business_start_time} -{" "}
                                  {item.business_end_time}
                                </div>
                              </>
                            }
                          />
                          <Row>
                            <label>
                              <FormattedMessage id="Address" /> {item.address}
                            </label>
                          </Row>
                          <Row>
                            <Space size="large">
                              <label>
                                <FormattedMessage id="Inventory" />{" "}
                                {item.inventory_quantity}
                              </label>
                              <label>
                                <FormattedMessage id="Price" />{" "}
                                <label style={{ color: primaryColor }}>
                                  {getProviderPriceValue(
                                    item.provider_id,
                                    item.tag_price_value,
                                  )}
                                </label>
                              </label>
                            </Space>
                          </Row>
                        </List.Item>
                      )}
                    />
                  </div>
                </Drawer>
              </div>

              {!is_preview && (
                <div
                  className={styles["ProductParams-details"]}
                  style={{
                    backgroundColor: panelColor,
                  }}
                >
                  <SimilarProducts
                    productId={productId}
                    reference_code={reference_code}
                    recommendProductsCaches={recommendProductsCaches}
                    isLogin={isLogin}
                  />
                  <ProductDesignIdea
                    designIdeas={designIdeas}
                    onChangeUserLike={data =>
                      dispatch.Collections.submitCollectAsync({
                        ...data,
                        callback: (ideaId: number) =>
                          dispatch.PDPPage.submitCollectDesign(ideaId),
                      })
                    }
                  />
                </div>
              )}

              <Modal
                visible={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                width={!isMobile ? 650 : "100vw"}
                title={f({ id: "AppointmentServicePoin" })}
                footer={null}
                bodyStyle={{ padding: "30px 48px 96px 48px" }}
              >
                <AppointmentServicePoinFrom
                  onClose={() => setIsModalOpen(false)}
                />
              </Modal>
            </div>
            <Affix offsetTop={55}>
              <Row
                className={styles["Productcontent-fixed-card"]}
                align="middle"
              >
                <Col span={12}>
                  <Carousel
                    arrows
                    dots={false}
                    slidesToScroll={1}
                    slidesToShow={1}
                    infinite={false}
                    hoverable
                  >
                    {partData.map((item, index) => {
                      return (
                        <div key={index}>
                          <Image src={item.value} width={1} height={1}></Image>
                        </div>
                      );
                    })}
                  </Carousel>
                </Col>

                <Col
                  span={12}
                  className={styles["Productcontent-fixed-card-content"]}
                >
                  <div>
                    <div
                      className={
                        styles["Productcontent-fixed-card-content-name"]
                      }
                    >
                      {productItem.product_name}
                    </div>
                    <Link
                      href={`${isToBusiness ? "/tob" : ""}${sku.shop_url}`}
                      target="_blank"
                      onClick={e => {
                        e.preventDefault();
                        replaceState.linkTo(
                          `${isToBusiness ? "/tob" : ""}${sku.shop_url}`,
                          "_target",
                        );
                      }}
                    >
                      {sku.shop_name}
                    </Link>
                  </div>
                  <ProductBaseInfo
                    productItem={productItem}
                    isToReview={Boolean(toreview)}
                    currency={currency}
                    sku={sku}
                    dispatch={dispatch}
                    isLogin={isLogin}
                    search={search}
                    provider={selectedProvider}
                    size="small"
                    agentRates={AgentRate}
                    isPreview={is_preview}
                  />
                  {/* {!isToBusiness && (
                    <Row style={{ marginTop: "5px" }}>
                      <Rating
                        value={rateStar}
                        disabled
                        className={styles["PDRating-rating"]}
                        color={
                          isToBusiness ? bussinessPrimaryColor : primaryColor
                        }
                      />
                      <span
                        style={{
                          marginLeft: "10px",
                          display: "inline-block",
                          fontSize: "0.8em",
                        }}
                      >
                        {productReviewStatistic
                          ? productReviewStatistic.review_rating
                          : 0}
                        {" / "}
                        <span
                          style={{
                            color: linkColor,
                            textDecoration: "underline",
                          }}
                        >
                          {productReviewStatistic
                            ? productReviewStatistic.review_total
                            : 0}{" "}
                          {f({ id: "Review" })}
                        </span>
                      </span>
                    </Row>
                  )} */}
                  <Row gutter={10} style={{ margin: "15px 0 20px" }}>
                    <Col span={10} xl={6}>
                      <span
                        style={{ fontSize: "1em", verticalAlign: "middle" }}
                      >
                        {f({ id: "Quantity" })}
                      </span>
                    </Col>
                    <Col span={14} xl={18} style={{ textAlign: "right" }}>
                      <Space size={5}>
                        <PrimaryButton
                          onClick={() => setProductNumber(productNumber - 1)}
                          icon={<Icon type="icont1_jian" />}
                          disabled={productNumber <= minimum}
                          bgColor={
                            productNumber <= minimum
                              ? "#88888888"
                              : isToBusiness
                              ? bussinessPrimaryColor
                              : primaryColor
                          }
                        />
                        <Input
                          style={{
                            width: "50px",
                            border:
                              "1px solid " +
                              (isToBusiness
                                ? bussinessPrimaryColor
                                : primaryColor),
                            borderRadius: "5px",
                            textAlign: "center",
                          }}
                          value={productNumber}
                          onChange={handleChangeProductNumber}
                        />
                        <PrimaryButton
                          icon={<Icon type="icont1_jia" />}
                          disabled={productNumber >= maximum}
                          onClick={() => setProductNumber(productNumber + 1)}
                          bgColor={
                            productNumber >= maximum
                              ? "#88888888"
                              : isToBusiness
                              ? bussinessPrimaryColor
                              : primaryColor
                          }
                        />
                      </Space>
                    </Col>
                  </Row>
                  {!is_preview && (
                    <Row gutter={10} style={{ marginBottom: "10px" }}>
                      <Col span={10}>
                        <Button
                          onClick={handleSaveOrUnSave}
                          block
                          icon={
                            <Icon
                              type={
                                collection.includes(productId)
                                  ? "icont2_shoucang1"
                                  : "iconshoucang1"
                              }
                            />
                          }
                          color={
                            isToBusiness ? bussinessPrimaryColor : primaryColor
                          }
                        >
                          <span>{f({ id: "Collection" })}</span>
                        </Button>
                      </Col>
                      <Col span={14}>
                        <PrimaryButton
                          onClick={handleToCart}
                          icon={<Icon type="icongouwuchexian" />}
                          disabled={productNumber <= 0}
                          bgColor={
                            isToBusiness ? bussinessPrimaryColor : primaryColor
                          }
                          block
                        >
                          {f({
                            id: isToBusiness ? "AddToPurcaseList" : "AddToCart",
                          })}
                        </PrimaryButton>
                      </Col>
                    </Row>
                  )}

                  {isCanLinkCart && (
                    <PrimaryButton
                      block
                      onClick={() => (location.href = `/cart/${search}`)}
                      bgColor={
                        isToBusiness ? bussinessPrimaryColor : primaryColor
                      }
                    >
                      <Icon
                        type="icongouwuchexian"
                        style={{ color: "white" }}
                      ></Icon>
                      {f({ id: "ContinueToCart" })}
                    </PrimaryButton>
                  )}
                </Col>
              </Row>
            </Affix>
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(ProductParamsContent);
