import React from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";

import * as styles from "./index.scss";
import { RootState, Dispatch } from "../../../store/configureStore";
import { FormattedMessage } from "react-intl";
import ArrowNav from "./ArrowNav";
import AddressContent from "./Address/index";
import Icon from "../../../components/icon";
import Checkbox from "../../../wrappers/checkbox";
import Drawer from "../../../wrappers/drawer";
import { PrimaryButton } from "../../../wrappers/button";
import { Row, Col, message, Input } from "antd";
import Modal from "../../../wrappers/modal";
import replaceState from "../../../helpers/reolaceState";
import { fibonacci } from "../../../helpers/sequence.helper";
import { useSetState } from "ahooks";
import { useTheme } from "styled-components";
import { useUpdateEffect, useCountDown } from "ahooks";

type ComputeModuleProps = {
  isMobile?: boolean;
  onAddOrder: (payload: {
    store_cart_id: any;
    address_id: number;
    type: number;
    remark?: string;
    shop_id?: string;
    private?: boolean;
    callback?: (res: any) => void;
    closeLoading?: (res: any) => void;
  }) => void;
  getZfbUrl: (payload: {
    quote_id: number;
    private: any;
    callback: (res: any) => void;
  }) => void;
  getPayResult: (payload: {
    quote_id: number;
    callback: (res: any) => void;
  }) => void;
  selectedIds: string;
  selected?: number;
  pirce: any;
  totalIncome?: number;
  quantity: number;
  address?: any;
  quoteID?: number;
  onCloseModal: any;
  qrCodeUrl?: string;
  getQrCode: any;
};

type ComputeModuleState = {
  btnclick: boolean;
  drawerVisible: boolean;
  nowSelect: number;
  payment: number;
  remark: string;
  showAliModel: boolean;
  apiPayResult: boolean;
  countdown: number;
  payLoading: boolean;
};

const { TextArea } = Input;

const ComputeModule: React.FC<ComputeModuleProps> = props => {
  const { search = "", host_id } = useSelector(
    (root: RootState) => root.URLSearch,
  );
  const { minute, second, paytype, time, overdue } = useSelector(
    (root: RootState) => root.Cart,
  );

  const { isPrivate } = useSelector((state: RootState) => state.CommonData);

  const dispatch = useDispatch<Dispatch>();

  const [state, setState] = useSetState<ComputeModuleState>({
    btnclick: false,
    drawerVisible: false,
    nowSelect: 0,
    payment: 1, // 1：支付宝， 2:微信
    remark: "",
    showAliModel: false,
    apiPayResult: false,
    countdown: 3,
    payLoading: false,
  });

  /**
   * 记录选择的支付方式
   */

  const handleCheckBoxChange = (type: number) => {
    setState({
      payment: type,
    });
  };

  /**
   * 存储备注信息
   */

  const handleTextAreaChange = (e: any) => {
    setState({
      remark: e.target.value,
    });
  };

  /**
   * 获取zfb链接并开始获取结果
   */

  const handleGetZFBUrl = (quoteId: number) => {
    const data = {
      quote_id: quoteId,
      private: Boolean(isPrivate),
      callback: res => {
        if (res !== "") {
          window.open(res);
          getAliPayrResult(quoteId);
          setState({
            showAliModel: true,
          });
        } else {
          replaceState.linkTo("/account/orders");
        }
        setState({ payLoading: false });
      },
    };
    props.getZfbUrl(data);
  };

  const [countdown, setTargetDate] = useCountDown();

  const countdownSecond = Math.round(countdown / 1000);

  useUpdateEffect(() => {
    if (countdownSecond === 0) {
      replaceState.linkTo("/account/orders");
    }
  }, [countdownSecond]);

  const getAliPayrResult = (quoteId: number, checkIndx: number = 7) => {
    // 斐波那契数列1、1、2、3、5、8、13、21、34、……
    // 从第七个数开始，隔n秒后执行一次查询

    setTimeout(() => {
      const data = {
        quote_id: quoteId,
        callback: (res: any) => {
          if (res) {
            setState({
              apiPayResult: true,
            });

            setTargetDate(Date.now() + 1000 * 3);
          }
        },
      };
      props.getPayResult(data);

      getAliPayrResult(quoteId, checkIndx + 1);
    }, fibonacci(checkIndx) * 1000);
  };

  const {
    isMobile,
    pirce,
    quantity,
    selectedIds,
    quoteID = 0,
    qrCodeUrl = "",
    totalIncome,
    address,
  } = props;

  const quoteId = quoteID;

  const { primaryColor } = useTheme();

  address?.map((item: any, index: number) => {
    if (index === state.nowSelect) {
      item.is_select = 1;
    }
  });

  const handleAddressChange = (nowIndex: number) => {
    address.map((item: any, index: number) => {
      if (index === nowIndex) {
        item.is_select = 1;
      } else {
        item.is_select = 0;
      }
    });

    dispatch.Cart.setSelectedAddress(address[nowIndex]);
  };

  useUpdateEffect(() => {
    if (address.length > 0) {
      dispatch.Cart.setSelectedAddress(address[0]);
    }
  }, [address]);

  return (
    <>
      <ArrowNav
        id={"address-arrow"}
        leftIcon={<Icon type="icont1_dizhi" />}
        onClick={() => {
          setState({
            drawerVisible: true,
          });
        }}
      >
        {address && address.length > 0 && (
          <>
            <div>
              <div style={{ fontSize: "1.1rem" }}>{`${
                address[state.nowSelect].recipient_name
              } ${address[state.nowSelect].recipient_telephone}`}</div>
              <div>
                {address[state.nowSelect].province}
                {address[state.nowSelect].city}
                {address[state.nowSelect].area}
                {address[state.nowSelect].address_details}
              </div>
            </div>
          </>
        )}
        {(!address || address.length === 0) && (
          <span className={classnames(styles[`compute-nocontact`])}>
            <FormattedMessage id="PleaseFillInTheContactInformation" />
          </span>
        )}
      </ArrowNav>
      {/* <ArrowNav
                leftIcon={<Icon type="iconyouhui" />}
                onClick={() => {}}
              >
                <div>优惠券信息</div>
              </ArrowNav> */}
      {!!totalIncome && (
        <div
          style={{ borderRadius: 5 }}
          className={classnames(styles["compute-price"])}
        >
          <Icon
            style={{ color: primaryColor }}
            type="iconqian"
            className={styles["compute-price-agent-icon"]}
          />
          <FormattedMessage id="ExpectedIncome" />：{" "}
          <span style={{ color: primaryColor }}>{`¥${totalIncome}`}</span>
        </div>
      )}
      <div
        style={{ borderRadius: 5 }}
        className={classnames(styles["compute-price"])}
      >
        <p className={styles[`compute-price-title`]}>价格信息</p>
        <Row className={classnames(styles["compute-price-row"])}>
          <Col span={12}>已选商品数</Col>
          <Col span={12} style={{ textAlign: "right" }}>
            {quantity}
          </Col>
        </Row>
        <Row className={classnames(styles["compute-price-row"])}>
          <Col span={12}>总价</Col>
          <Col span={12} style={{ textAlign: "right" }}>
            {` ¥${pirce} `}
          </Col>
        </Row>
        <Row className={classnames(styles["compute-price-row"])}>
          <Col span={12}>运费</Col>
          <Col span={12} style={{ textAlign: "right" }}>
            {` ¥${0} `}
          </Col>
        </Row>
        <Row className={classnames(styles["compute-price-row"])}>
          <Col span={12}>价格抵扣</Col>
          <Col span={12} style={{ textAlign: "right" }}>
            {` ¥${0} `}
          </Col>
        </Row>

        <Row className={classnames(styles["compute-price-divider"])}>
          <Col span={12}>付款价</Col>
          <Col span={12} style={{ textAlign: "right" }}>
            {pirce && ` ¥${parseFloat(pirce).toFixed(2)} `}
          </Col>
        </Row>
      </div>
      <div
        style={{ borderRadius: 5 }}
        className={classnames(styles["compute-price"])}
      >
        <p className={styles[`compute-price-title`]}>备注信息</p>
        <TextArea
          placeholder="选填，请输入订单备注信息"
          onChange={e => handleTextAreaChange(e)}
        ></TextArea>
      </div>

      <div
        style={{ borderRadius: 5 }}
        className={classnames(styles["compute-price"])}
      >
        <p className={styles[`compute-price-title`]}>支付方式</p>
        <p className={classnames(styles["compute-price-payment"])}>
          <Checkbox
            shape="circle"
            borderWidth="2px"
            checked={state.payment === 1}
            onChange={() => handleCheckBoxChange(1)}
          >
            <Icon type="iconzhifubao" />
            支付宝支付
          </Checkbox>
          <Checkbox
            shape="circle"
            borderWidth="2px"
            onChange={() => handleCheckBoxChange(2)}
            checked={state.payment === 2}
          >
            <Icon type="iconweixinzhifu1" />
            微信支付
          </Checkbox>
        </p>
      </div>

      <PrimaryButton
        onClick={() => {
          if (!address || address.length === 0 || !address[state.nowSelect]) {
            return message.error("请选择联系方式");
          }
          setState({ payLoading: true });
          props.onAddOrder({
            store_cart_id: selectedIds,
            address_id: address[state.nowSelect].address_id,
            type: state.payment === 1 ? 1 : 2,
            remark: state.remark,
            private: Boolean(isPrivate),
            shop_id: host_id,
            callback: res => {
              handleGetZFBUrl(res);
            },
            closeLoading: () => {
              setState({ payLoading: false });
            },
          });
        }}
        bgColor={props.selected === 0 ? "#888888" : primaryColor}
        disabled={props.selected === 0}
        block
        className={classnames(styles[`compute-computer-btm`])}
        loading={state.payLoading}
      >
        <FormattedMessage id="payLabel" />
        &nbsp;
        <Icon type="icont1_correct"></Icon>
      </PrimaryButton>

      <Modal
        visible={state.showAliModel}
        onCancel={() => {
          setState({
            showAliModel: false,
          });
          replaceState.linkTo("/account/orders");
        }}
        width="400px"
        footer={null}
        title="支付宝支付"
      >
        <div className={styles[`compute-ali-modal`]}>
          {state.apiPayResult && (
            <p>
              支付成功！页面将在{" "}
              <span
                style={{ color: primaryColor }}
              >{` ${countdownSecond} `}</span>
              秒后跳转
            </p>
          )}
          <div className={styles[`compute-ali-modal-content`]}>
            <Icon type="iconzhifubao" />
            应付金额
            <span
              style={{
                color: primaryColor,
                fontSize: "30px",
                margin: "0 10px",
              }}
            >
              {` ¥${pirce} `}
            </span>
            元
          </div>
          <Row gutter={10}>
            <Col span={12}>
              <PrimaryButton
                block
                onClick={() => {
                  replaceState.linkTo("/account/orders");
                }}
                bgColor={primaryColor}
              >
                稍后支付
              </PrimaryButton>
            </Col>
            <Col span={12}>
              <PrimaryButton
                block
                onClick={() => {
                  replaceState.linkTo("/account/orders");
                }}
                bgColor={primaryColor}
              >
                我已支付
              </PrimaryButton>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal
        visible={quoteId > 0}
        onCancel={() => {
          props.onCloseModal();
          replaceState.linkTo("/account/orders");
        }}
        width="800px"
        footer={null}
        title="微信支付"
      >
        <div className={styles[`compute-modal`]}>
          <Row gutter={50}>
            <Col span={10} className={styles[`compute-modal-header`]}>
              二维码有效时长为
              <span style={{ color: primaryColor }}>60</span>
              秒，请尽快完成支付！过期后请刷新重新获取二维码!
            </Col>
            <Col span={14}></Col>
          </Row>
          <Row gutter={50} style={{ marginTop: "10px" }}>
            <Col span={10}>
              <div className={styles[`compute-modal-qrcode`]}>
                <img src={qrCodeUrl} alt="" />
                <div
                  className={classnames(styles[`compute-modal-qrcode-mask`], {
                    [styles[`compute-modal-qrcode-show`]]: paytype || overdue,
                  })}
                >
                  {paytype && (
                    <>
                      <Icon
                        type="iconduihao2"
                        style={{ color: "#07c160" }}
                      ></Icon>
                      <p>支付成功!</p>
                    </>
                  )}

                  {overdue && !paytype && (
                    <div onClick={() => props.getQrCode()}>
                      <Icon type="icont1_shuaxin"></Icon>
                      <p>已失效!</p>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles[`compute-modal-prompt`]}>
                <Icon type="iconsaomiao"></Icon>
                <div className={styles[`compute-modal-prompt-text`]}>
                  <p>请使用微信扫一扫</p>
                  <p>扫描二维码</p>
                </div>
              </div>
            </Col>
            <Col span={14}>
              <div className={styles[`compute-modal-content`]}>
                <p
                  style={{ color: primaryColor }}
                  className={styles[`compute-modal-content-header`]}
                >
                  {!paytype && (
                    <>
                      订单提交成功，请尽快支付！
                      <span style={{ color: "#666666" }}>剩余时间 : </span>
                      {minute}分{second}秒
                    </>
                  )}
                  {paytype && (
                    <>
                      <span>订单已支付成功!</span>
                      <span style={{ color: "#666666" }}>
                        将在
                        <span style={{ color: "#C21D44" }}> {time} </span>
                        秒后跳转至订单列表
                      </span>
                    </>
                  )}
                </p>
                <p className={styles[`compute-modal-content-title`]}>
                  收货人信息
                </p>
                {address && address.length > 0 && (
                  <div>
                    {`${address[state.nowSelect].recipient_name} ${
                      address[state.nowSelect].recipient_telephone
                    }`}
                  </div>
                )}
                {address && address.length > 0 && (
                  <div>
                    {address[state.nowSelect].province}
                    {address[state.nowSelect].city}
                    {address[state.nowSelect].area}
                    {address[state.nowSelect].address_details}
                  </div>
                )}

                {state.remark !== "" && (
                  <>
                    <p className={styles[`compute-modal-content-title`]}>
                      备注信息
                    </p>
                    <p>{state.remark}</p>
                  </>
                )}
                <p className={styles[`compute-modal-content-price`]}>
                  {paytype ? "已付金额" : "应付金额"}
                  <span style={{ color: primaryColor, fontSize: "22px" }}>
                    {` ¥${pirce} `}
                  </span>
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>

      <Drawer
        visible={state.drawerVisible}
        onClose={() => {
          setState({
            drawerVisible: false,
          });
        }}
        title={"选择地址"}
        width={isMobile ? "95vw" : "600px"}
      >
        <AddressContent
          changeAddress={(index: number) => {
            setState({
              nowSelect: index,
              drawerVisible: false,
            });
            handleAddressChange(index);
          }}
        />
      </Drawer>
    </>
  );
};

export default React.memo(ComputeModule);
