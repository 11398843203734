import React, { useState, useEffect, useContext, useRef } from "react";
import { useSelector } from "react-redux";
import * as Loadable from "react-loadable";
import * as styles from "../index.scss";
import { message } from "antd";
import Icon from "../../../components/icon";
import Print from "react-to-print";
import { useIntl } from "react-intl";
import { RootState } from "../../../store/configureStore";
import { BusinessContext } from "../../BusinessContext";
import { copyToClipboard } from "../../../helpers/DomUtil";
import Modal from "../../../wrappers/modal";
import { PrimaryButton } from "../../../wrappers/button";
import { useTheme } from "styled-components";

const LoadableComponent = Loadable({
  loader: () => import("@megahome/qrcode/dist/index.js"),
  loading: () => null,
});

const Invitation = () => {
  const { formatMessage: f } = useIntl();

  const { user } = useSelector((state: RootState) => state.AccountPage);
  const { primaryColor } = useTheme();
  const [modalVisible, setModalVisible] = useState(false);

  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const picRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    const url = location.origin + "/invitation/" + user.agent_code;
    setQrCodeUrl(url);
  }, []);
  return (
    <div className={styles["Commission-invitation"]}>
      <div
        onClick={() => setModalVisible(true)}
        style={{
          width: "100%",
          height: 66,
          color: "white",
          background: primaryColor,
          textAlign: "center",
          lineHeight: "66px",
          borderRadius: "5px",
          cursor: "pointer",
          fontSize: "1.2em",
        }}
      >
        快去邀请好友{">"}
      </div>
      <Modal
        footer={null}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        width={800}
      >
        <Print
          trigger={() => {
            return (
              <PrimaryButton
                style={{
                  position: "absolute",
                  bottom: "-40px",
                  right: 20,
                }}
                bgColor={primaryColor}
              >
                打印图片
              </PrimaryButton>
            );
          }}
          content={() => picRef.current}
        />

        <div style={{ padding: "45px 20px" }}>
          <div
            ref={picRef}
            className={styles["Commission-invitation-print"]}
            style={{ display: "flex" }}
          >
            <div
              className={styles["Commission-invitation-print-div"]}
              style={{ marginLeft: 135, marginRight: 15 }}
            >
              <LoadableComponent value={qrCodeUrl} width={200} height={200} />
            </div>
            <div className={styles["Commission-invitation-print-div"]}>
              <div className={styles["Commission-invitation-print-qrcode"]}>
                邀请口令：
                <span style={{ color: primaryColor }}>{user.agent_code}</span>
              </div>
              <div style={{ fontSize: "1.1em", lineHeight: "18px" }}>
                你的好友
                <span style={{ fontWeight: 500 }}>
                  {user.customer_lastname}**
                </span>{" "}
                又在数莓派上赚到了！
              </div>
              <div style={{ fontSize: "1.3em" }}>快来一起赚钱吧！</div>
            </div>
          </div>

          <div className={styles["Commission-invitation-footer"]}>
            <div
              style={{
                marginTop: "10px",
                display: "flex",
              }}
            >
              <div
                style={{
                  background: "#eaeaea",
                  padding: "5px 10px",
                  minHeight: "35px",
                  flex: 1,
                }}
              >
                {qrCodeUrl}
              </div>
              <div>
                <PrimaryButton
                  style={{
                    marginLeft: 10,
                  }}
                  onClick={() => {
                    copyToClipboard(qrCodeUrl);
                    message.success("复制成功");
                  }}
                  bgColor={primaryColor}
                >
                  复制地址
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default Invitation;
