import { createModel } from "@rematch/core";
import {
  GraphQLRequest,
  GraphqlQuery,
  GraphqlMutation,
} from "../../helpers/request.helper";
import { getFirstItem } from "../../helpers/modelHelpers";
import produce from "immer";
import AfterSaleInfo from "../../models/AfterSale";

export interface AfterSaleType {
  breadcrumbs: { url: string; title: string }[];
}

export default createModel({
  state: {} as AfterSaleType,
  reducers: {},
  effects: {
    // 上传凭证图
    async handleUploadImg(payload) {
      const { callback, base64 } = payload;
      const res = await GraphqlMutation("Upload", {
        base64: base64,
        action: "afterSale",
      });
      callback && callback(res.data);
    },
    // 申请售后
    async handleApplyAfterSale(payload) {
      const {
        callback,
        order_product_id,
        after_sale_reason,
        quantity,
        after_sale_type,
        after_sale_files,
      } = payload;
      const res = await GraphqlMutation("After", {
        order_product_id: order_product_id,
        action: "apply",
        after_sale_reason: after_sale_reason,
        quantity: quantity,
        after_sale_type: after_sale_type,
        after_sale_files: after_sale_files,
      });
      callback && callback(res);
    },
    // 获取售后信息
    async handleGetAfterSaleInfo(payload) {
      const { callback, order_product_id } = payload;
      const model = new AfterSaleInfo({ order_product_id: order_product_id });
      const res = await GraphqlQuery("After", model);
      callback && callback(getFirstItem(res));
    },
    // 确认地址
    async handleConfirmAddress(payload) {
      const {
        callback,
        return_order_id,
        shipping_method,
        logistics_type,
        logistics_express_company,
        logistics_express_number,
        pickup_contact_telephone,
        pickup_contact_address,
        pickup_contact,
      } = payload;
      let model = {};
      if (shipping_method === "self") {
        model = {
          return_order_id: return_order_id,
          shipping_method: shipping_method,
          action: "updateAddress",
          pickup_contact_telephone: pickup_contact_telephone,
          pickup_contact_address: pickup_contact_address,
          pickup_contact: pickup_contact,
        };
      } else {
        model = {
          return_order_id: return_order_id,
          shipping_method: shipping_method,
          action: "updateAddress",
          logistics_type: logistics_type,
          logistics_express_company: logistics_express_company,
          logistics_express_number: logistics_express_number,
        };
      }
      const res = await GraphqlMutation("After", model);
      callback && callback(res);
    },
    // 取消申请
    async handleCancelApply(payload) {
      const { callback, order_product_id } = payload;
      const res = await GraphqlMutation("After", {
        order_product_id: order_product_id,
        action: "cancel",
      });
      callback && callback(res);
    },
  },
});
