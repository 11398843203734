import { createModel } from "@rematch/core";
import { GraphqlQuery } from "../../helpers/request.helper";
import { AutoComplete } from "../../models/User";

type NavItem = {
  key: string;
  title: string;
  url?: string;
  picture?: string;
  logo?: string;
  link?: string;
  child?: NavItem[];
};

export type Navigation = {
  category: NavItem;
  room: NavItem;
  room_menu: NavItem[];
  category_menu: NavItem[];
  searchOption: { text: string }[];
  categorys: {
    key: string;
    title: string;
    link: string;
    column_index: number;
    sort_index: number;
    is_wrapped: number;
    child: { key: string; title: string; link: string }[];
  }[];
};

export default createModel({
  state: ({
    category: {
      key: "Introducing",
      title: "有所家居",
      link: "/shop/ysjj-8/index.html",
      picture:
        "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/index/web/shop/yousuo_product.png",
      logo:
        "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/index/web/shop/yousuo_logo.png",
    },
    room: {
      key: "Introducing",
      title: "良工家具",
      link: "/shop/lgjj-2/index.html",
      picture:
        "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/index/web/shop/lianggong_product.png",
      logo:
        "https://shumeipie.s3.cn-northwest-1.amazonaws.com.cn/index/web/shop/lianggong_logo.png",
    },
    room_menu: [
      {
        key: "",
        title: "客厅起居室",
        link: "/product-listliving-room/index.html",
        column_index: 1,
        sort_index: 1,
        is_wrapped: null,
        child: [
          {
            key: "furniture",
            title: "家具",
            link: "/product-list/furniture/index.html",
          },
          {
            key: "table_lamp",
            title: "台灯",
            link: "/product-list/lighting/table-lamp/index.html",
          },
          {
            key: "floor_lamp",
            title: "落地灯",
            link: "/product-list/lighting/floor-lamp/index.html",
          },
          {
            key: "wall_lighting",
            title: "墙灯",
            link: "/product-list/lighting/wall-lighting/index.html",
          },
          {
            key: "outdoor_lighting",
            title: "户外灯",
            link: "/product-list/lighting/outdoor-lighting/index.html",
          },
          {
            key: "bathroom_vanity_lighting",
            title: "浴室灯",
            link: "/product-list/lighting/bathroom-vanity-lighting/index.html",
          },
        ],
      },
      {
        key: "",
        title: "餐厅",
        link: "/product-listdining-room/index.html",
        column_index: 1,
        sort_index: 2,
        is_wrapped: null,
        child: [
          {
            key: "bar_stool",
            title: "吧台椅",
            link: "/product-list/furniture/chairs/bar-stool/index.html",
          },
          {
            key: "dining_chair",
            title: "餐厅椅",
            link: "/product-list/furniture/chairs/dining-chair/index.html",
          },
          {
            key: "dining_table",
            title: "餐桌",
            link: "/product-list/furniture/table-desk/dining-table/index.html",
          },
        ],
      },
      {
        key: "",
        title: "卧室",
        link: "/product-listbedroom/index.html",
        column_index: 2,
        sort_index: 1,
        is_wrapped: null,
        child: [
          {
            key: "rug",
            title: "毯",
            link: "/product-list/rug/index.html",
          },
        ],
      },
      {
        key: "",
        title: "壁橱和储物室",
        link: "/product-listcloset/index.html",
        column_index: 2,
        sort_index: 2,
        is_wrapped: null,
        child: [
          {
            key: "display_cabinet",
            title: "展示柜",
            link:
              "/product-list/furniture/cabinet-storage/display-cabinet/index.html",
          },
          {
            key: "cube_storage",
            title: "方格柜",
            link:
              "/product-list/furniture/cabinet-storage/cube-storage/index.html",
          },
          {
            key: "kitchen_cabinet",
            title: "橱柜",
            link:
              "/product-list/furniture/cabinet-storage/kitchen-cabinet/index.html",
          },
          {
            key: "outdoor_storage",
            title: "户外存储",
            link:
              "/product-list/furniture/cabinet-storage/outdoor-storage/index.html",
          },
          {
            key: "living_room_storage",
            title: "储物柜",
            link:
              "/product-list/furniture/cabinet-storage/living-room-storage/index.html",
          },
          {
            key: "shoe_cabinets",
            title: "鞋柜",
            link:
              "/product-list/furniture/cabinet-storage/shoe-cabinets/index.html",
          },
        ],
      },
      {
        key: "",
        title: "书房和办公",
        link: "/product-liststudy/index.html",
        column_index: 3,
        sort_index: 1,
        is_wrapped: null,
        child: [
          {
            key: "guest_chair",
            title: "会客椅",
            link: "/product-list/furniture/chairs/guest-chair/index.html",
          },
        ],
      },
    ],
    category_menu: [
      {
        key: "",
        title: "家具",
        link: "/product-list/furniture/index.html",
        column_index: 1,
        sort_index: 1,
        is_wrapped: null,
        child: [
          {
            key: "shelving",
            title: "架子",
            link: "/product-list/furniture/shelving/index.html",
          },
          {
            key: "office_chair",
            title: "办公椅",
            link: "/product-list/furniture/chairs/office-chair/index.html",
          },
          {
            key: "dining_chair",
            title: "餐厅椅",
            link: "/product-list/furniture/chairs/dining-chair/index.html",
          },
          {
            key: "bar_stool",
            title: "吧台椅",
            link: "/product-list/furniture/chairs/bar-stool/index.html",
          },
          {
            key: "folding_chair",
            title: "折叠椅",
            link: "/product-list/furniture/chairs/folding-chair/index.html",
          },
          {
            key: "guest_chair",
            title: "会客椅",
            link: "/product-list/furniture/chairs/guest-chair/index.html",
          },
          {
            key: "accent_chair",
            title: "休闲椅",
            link: "/product-list/furniture/chairs/accent-chair/index.html",
          },
          {
            key: "stool",
            title: "板凳",
            link: "/product-list/furniture/chairs/stool/index.html",
          },
          {
            key: "dressing_stool",
            title: "化妆椅",
            link: "/product-list/furniture/chairs/dressing-stool/index.html",
          },
          {
            key: "kids_chair",
            title: "幼儿椅",
            link: "/product-list/furniture/chairs/kids-chair/index.html",
          },
          {
            key: "lounge_chair",
            title: "躺椅",
            link: "/product-list/furniture/chairs/lounge-chair/index.html",
          },
        ],
      },
      {
        key: "",
        title: "卫浴",
        link: "/product-list/bath/index.html",
        column_index: 1,
        sort_index: 0,
        is_wrapped: null,
        child: [
          {
            key: "bathroom_accessories",
            title: "浴室配件",
            link: "/product-list/bath/bathroom-accessories/index.html",
          },
          {
            key: "bathroom_mirror",
            title: "浴室镜",
            link: "/product-list/bath/bathroom-mirror/index.html",
          },
        ],
      },
      {
        key: "",
        title: "门",
        link: "/product-list/door/index.html",
        column_index: 2,
        sort_index: 0,
        is_wrapped: null,
        child: [
          {
            key: "office_door",
            title: "办公门",
            link: "/product-list/door/office-door/index.html",
          },
          {
            key: "room_door",
            title: "房间门",
            link: "/product-list/door/room-door/index.html",
          },
          {
            key: "front_door",
            title: "外立门",
            link: "/product-list/door/front-door/index.html",
          },
        ],
      },
      {
        key: "",
        title: "装饰和抱枕",
        link: "/product-list/decor-pillow-accessories/index.html",
        column_index: 3,
        sort_index: 0,
        is_wrapped: null,
        child: [
          {
            key: "home_accent",
            title: "钟表,摆件和家居饰物",
            link:
              "/product-list/decor-pillow-accessories/home-accent/index.html",
          },
          {
            key: "textile",
            title: "纺织品",
            link: "/product-list/decor-pillow-accessories/textile/index.html",
          },
          {
            key: "picture_frame",
            title: "相框",
            link:
              "/product-list/decor-pillow-accessories/home-accent/picture-frame/index.html",
          },
          {
            key: "wall_decor",
            title: "墙饰",
            link:
              "/product-list/decor-pillow-accessories/home-accent/wall-decor/index.html",
          },
          {
            key: "table_accent",
            title: "桌面装饰",
            link:
              "/product-list/decor-pillow-accessories/home-accent/table-accent/index.html",
          },
          {
            key: "wall_mirrors",
            title: "墙镜",
            link:
              "/product-list/decor-pillow-accessories/home-accent/wall-mirrors/index.html",
          },
          {
            key: "floor_mirrors",
            title: "落地镜",
            link:
              "/product-list/decor-pillow-accessories/home-accent/floor-mirrors/index.html",
          },
          {
            key: "outdoor_decor",
            title: "户外装饰",
            link:
              "/product-list/decor-pillow-accessories/home-accent/outdoor-decor/index.html",
          },
          {
            key: "clock",
            title: "钟表",
            link:
              "/product-list/decor-pillow-accessories/home-accent/clock/index.html",
          },
          {
            key: "mat",
            title: "垫子",
            link:
              "/product-list/decor-pillow-accessories/home-accent/mat/index.html",
          },
          {
            key: "aromatherapy",
            title: "香薰",
            link:
              "/product-list/decor-pillow-accessories/home-accent/aromatherapy/index.html",
          },
          {
            key: "floral-organ",
            title: "花器",
            link:
              "/product-list/decor-pillow-accessories/home-accent/floral-organ/index.html",
          },
        ],
      },
    ],
  } as unknown) as Navigation,
  reducers: {
    setSearchOption: (state: Navigation, searchOption) => ({
      ...state,
      searchOption,
    }),
  },
  effects: {
    // 自动补全
    async handleAutoComplete(payload) {
      let { keyword } = payload;

      keyword = keyword.replace(/\s*/g, "");
      if (keyword === "" || !keyword) return;
      const res = await GraphqlQuery("Suggester", new AutoComplete(), {
        keyword,
      });

      if (keyword !== "") {
        if (res.length === 0) {
          res.unshift({ text: keyword });
        } else {
          let isHaveText = false;
          res.forEach((item: any) => {
            if (item.text === keyword) {
              isHaveText = true;
            }
          });
          if (!isHaveText) {
            res.unshift({ text: keyword });
          }
        }
      }
      this.setSearchOption(res);
    },
  },
});
