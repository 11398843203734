import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import Layout from "../Layout";
import ProductListContent from "./Content";
import { RootState, Dispatch } from "../../store/configureStore";

const ProducList: React.FC<{}> = () => {
  const {
    breadcrumbs = [
      {
        url: "/",
        title: "每家家居",
      },
      {
        title: "椅子",
        url: "",
      },
    ],
  } = useSelector((state: RootState) => state.PLPage);

  return (
    <Layout breadcrumbs={breadcrumbs} isToBusiness>
      <ProductListContent />
    </Layout>
  );
};

export default React.memo(ProducList);
