import React, { useState, useEffect, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, Dispatch } from "../../../store/configureStore";
import * as styles from "./index.scss";
import { useIntl } from "react-intl";
import { Row, Col, message, Tag, Divider, Card, Space } from "antd";
import Icon from "../../../components/icon";
import cartlogoImg from "../../../static/img/cartlogo.png";
import Modal from "../../../wrappers/modal";
import Rate from "../../../wrappers/rate";
import Select from "../../../wrappers/select";
import { PrimaryButton } from "../../../wrappers/button";
import { Link } from "../../../wrappers/typography";
import { copyToClipboard } from "../../../helpers/DomUtil";
import { useTheme } from "styled-components";

const PrintCart = (props: {
  address: {
    recipient_name: string;
    recipient_email: string;
    recipient_company: string;
    recipient_telephone: string;
    recipient_address: string;
  };
  productData: any;
  dataId: number;
}) => {
  const dispatch = useDispatch<Dispatch>();
  const { formatMessage: f } = useIntl();
  // const OperationBox = React.createRef<HTMLDivElement>();
  const OperationBox = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [shareDay, setShareDay] = useState(1);
  const [shareUrl, setShareUrl] = useState(" ");
  const { agentCode } = useSelector((state: RootState) => state.User);
  let { address, productData, dataId } = props;

  const { bussinessPrimaryColor, linkColor } = useTheme();

  const handleGetShareUrl = () => {
    const updateData = productData.map(m => {
      return {
        ...m,
        share_customer_agent_code: agentCode ? agentCode : "",
      };
    });
    const data = {
      data_id: dataId,
      expired_at: shareDay,
      share_data: JSON.stringify(updateData).replace(/(\")/g, "\\$1"),
      callback: res => {
        const datas = {
          url: res.data,
          callback: ress => {
            setShareUrl(ress[0].short_url);
          },
        };
        dispatch.ToBOrderHistoryPage.getShortUrl(datas);
      },
    };
    dispatch.ToBOrderHistoryPage.getShareUrl(data);
  };

  return (
    <>
      <Space size={5}>
        <PrimaryButton
          shape="circle"
          size="small"
          onClick={() => setIsOpen(true)}
          icon={<Icon type="icont3_erweima" />}
          bgColor={bussinessPrimaryColor}
        />
        {f({ id: "Share" })}
      </Space>
      <Modal
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        width={1000}
        title={f({ id: "ShareOrder" })}
        footer={null}
        color={bussinessPrimaryColor}
      >
        <div ref={OperationBox} className={styles["Operation-box"]}>
          <img
            src={cartlogoImg}
            alt=""
            className={styles["Operation-box-img"]}
          />
          <Divider style={{ background: "#c6bdb9" }} />
          <h3>您正在访问一个订单详情</h3>
          <h3>来自您的好友</h3>
          <div className={styles["Operation-box-contact"]}>
            <div>
              <Icon type="icondizhi" style={{ color: bussinessPrimaryColor }} />
            </div>
            <div className={styles["Operation-box-contact-content"]}>
              <p>{`${address.recipient_name}  ${address.recipient_telephone}`}</p>
              <p>{address.recipient_email}</p>
              <p>{address.recipient_company}</p>
              <p>{address.recipient_address}</p>
            </div>
          </div>

          <div className={styles["Operation-box-product"]}>
            {productData &&
              productData.map((m: any, mIndex: number) => {
                return (
                  <Card style={{ marginTop: "15px" }}>
                    <Row align={"middle"}>
                      <Col span={5}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={m.product_picture}
                            style={{ width: "80%" }}
                          />
                        </div>
                      </Col>
                      <Col span={7} style={{ height: "170px" }}>
                        <p>
                          <span style={{ marginRight: 10, fontSize: 15 }}>
                            {m.product_name}
                          </span>
                          {m.customer_rating > 0 && (
                            <Rate
                              value={(m.customer_rating * 5) / 100}
                              disabled
                            />
                          )}
                        </p>
                        <p className={styles["table-MHS"]}>
                          {m.brand_name !== "" && (
                            <Tag color={bussinessPrimaryColor}>
                              {m.brand_name}
                            </Tag>
                          )}
                          {m.series_name !== "" && (
                            <Tag color={bussinessPrimaryColor}>
                              {m.series_name}
                            </Tag>
                          )}
                        </p>
                      </Col>

                      <Col span={4}>
                        {m.attribute &&
                          m.attribute.split(",").map((z: string) => {
                            return (
                              <>
                                <Tag
                                  color={bussinessPrimaryColor}
                                  style={{ marginBottom: "5px" }}
                                >
                                  {z}
                                </Tag>
                                <br />
                              </>
                            );
                          })}
                      </Col>
                      <Col span={4}>
                        <p>X{m.quantity}</p>
                      </Col>
                      <Col span={3}>
                        {!Number(m.has_tag_price_range) ? (
                          <>
                            ¥{(parseFloat(m.tag_price) * m.quantity).toFixed(2)}
                          </>
                        ) : (
                          <>{`¥${(
                            m.quantity * parseFloat(m.tag_price_low)
                          ).toFixed(2)} - ¥${(
                            m.quantity * parseFloat(m.tag_price_high)
                          ).toFixed(2)}`}</>
                        )}
                      </Col>
                    </Row>
                  </Card>
                );
              })}
          </div>
          <div className={styles["Operation-box-copyright"]}>
            <p>
              Copyright ©2018 - 2020 MegaHome LLC. All rights reserved.
              北京每家科技有限公司版权所有
            </p>
            <p>
              <Link href="http://beian.miit.gov.cn/">
                京ICP备18022364号
              </Link>
            </p>
          </div>
        </div>
        <div className={styles["Operation-export"]}>
          <Space direction="vertical">
            <Space>
              默认语言：
              <Select
                style={{ width: "200px", marginRight: "10px" }}
                disabled={true}
                defaultValue={"1"}
              >
                <Select.Option value={"1"}>中文简体</Select.Option>
              </Select>
              有效时间：
              <Select
                style={{ width: "200px" }}
                defaultValue={"1"}
                onChange={value => {
                  setShareDay(value);
                }}
              >
                <Select.Option value={"1"}>1天</Select.Option>
                <Select.Option value={"2"}>2天</Select.Option>
                <Select.Option value={"3"}>3天</Select.Option>
                <Select.Option value={"5"}>5天</Select.Option>
                <Select.Option value={"10"}>10天</Select.Option>
              </Select>
              <PrimaryButton
                bgColor={bussinessPrimaryColor}
                onClick={() => handleGetShareUrl()}
              >
                生成链接
              </PrimaryButton>
            </Space>
            <Row gutter={10}>
              <Col
                span={16}
                style={{
                  background: "#eaeaea",
                  padding: "5px 10px",
                  minHeight: "35px",
                  display: "inline-block",
                }}
              >
                {shareUrl}
              </Col>
              <Col span={8}>
                <PrimaryButton
                  bgColor={bussinessPrimaryColor}
                  onClick={() => {
                    copyToClipboard(shareUrl);
                    message.success("复制成功");
                  }}
                >
                  复制地址
                </PrimaryButton>
              </Col>
            </Row>
          </Space>
        </div>
      </Modal>
    </>
  );
};

export default PrintCart;
