import React, { useState, useEffect, useContext, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, Dispatch } from "../../store/configureStore";
import * as styles from "./index.scss";
import { useIntl } from "react-intl";
import replaceState from "../../helpers/reolaceState";
import { useTheme } from "styled-components";
import { Row, Col } from "antd";

const OrderHistoryContent = (props: {}) => {
  const dispatch = useDispatch<Dispatch>();
  const { formatMessage: f } = useIntl();
  const {
    order_number,
    recipient_name,
    recipient_address,
    recipient_telephone,
    total_amount,
  } = useSelector((state: RootState) => state.PayResultPage);
  const { primaryColor } = useTheme();

  const [boxheight, setHeight] = useState(300);
  const [times, setTime] = useState(5);
  let time = 5;
  useEffect(() => {
    //填写数据
    setHeight(document.documentElement.clientHeight - 473);
    changeTime();
  }, []);

  const changeTime = () => {
    setTimeout(() => {
      if (time - 1 === 0) {
        replaceState.linkTo("/account/orders");
      }
      if (time - 1 === 0) return;
      time = time - 1;
      setTime(time - 1);
      changeTime();
    }, 1000);
  };

  return (
    <div className={styles[`PayResult-box`]} style={{ height: boxheight }}>
      <div className={styles[`PayResult-box-content`]}>
        <div
          className={styles[`PayResult-box-content-left`]}
          style={{ background: primaryColor }}
        >
          <div className={styles[`PayResult-box-content-left-header`]}>
            {f({ id: "PaySuccess" })}
          </div>
          <div>{total_amount}元</div>
          <span
            className={styles[`PayResult-box-content-left-view`]}
            onClick={() => {
              replaceState.linkTo("/account/orders");
            }}
          >
            {f({ id: "ViewDetails" })}
          </span>
        </div>
        <div className={styles[`PayResult-box-content-right`]}>
          <p>
            {f({ id: "PageVill" })}{" "}
            <span style={{ color: primaryColor }}>{times}</span>{" "}
            {f({ id: "ThenLink" })}
          </p>
          <Row>
            <Col span={5}> {f({ id: "OrderNumber" })}：</Col>
            <Col span={19}>{order_number}</Col>
          </Row>
          <Row>
            <Col span={5}>{f({ id: "ShippingInfo" })}：</Col>
            <Col span={19}>
              {`${recipient_name} ${recipient_telephone}`}
              <br />
              {recipient_address}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default OrderHistoryContent;
