import { createModel } from "@rematch/core";
import { GraphQLRequest } from "../../helpers/request.helper";
import { RootState } from "../../store/configureStore";
import { AddressValuesType } from "./AddressForm";
import { GraphqlQuery, GraphqlMutation } from "../../helpers/request.helper";
import Address from "../../models/Address";
import { message } from "antd";

export interface AddressesState {
  breadcrumbs: { url: string; title: string }[];
  address: Address[];

  tobAddress: Address[];
}

export default createModel({
  state: {} as AddressesState,
  reducers: {
    INIR_DATA: (state: AddressesState, address: Address[]) => ({
      ...state,
      address,
    }),
    DELETE_ADDRESS: (state: AddressesState, delId) => ({
      ...state,
      address: state.address.filter(item => item.address_id !== delId),
    }),
    setTobAddres: (state: AddressesState, tobAddress) => ({
      ...state,
      tobAddress,
    }),
    deleteTobAddress: (state: AddressesState, delId) => ({
      ...state,
      tobAddress: state.tobAddress.filter(
        item => item.tob_address_id !== delId,
      ),
    }),
  },
  effects: {
    async getAddressDataAsync() {
      const data = new Address({});
      const res = await GraphqlQuery("Addresses", data, {}, "Address");
      this.INIR_DATA(res);
    },
    async deleteAddressAsync(payload) {
      const { addressId } = payload;
      const res = await GraphqlMutation("Address", {
        action: "delete",
        address_id: addressId,
      });
      message.success(res.message);
      this.DELETE_ADDRESS(addressId);
    },
    async setAddressAsync(payload) {
      const { callback, ...rest } = payload;
      const res = await GraphqlMutation("Address", {
        action: "save",
        ...rest,
      });
      message.success(res.message);
      callback && callback();
    },
    async setTobAddress() {
      const data = new Address({});
      const res = await GraphqlQuery("TobAddresses", data, {}, "ToBAddress");
      this.setTobAddres(res);
    },
    async addTobAddress(payload) {
      const { callback, ...rest } = payload;
      const res = await GraphqlMutation("TobAddress", {
        action: "save",
        ...rest,
      });
      message.success(res.message);
      callback && callback();
    },
    async delTobAddress(payload) {
      const { addressId } = payload;
      const res = await GraphqlMutation("TobAddress", {
        action: "delete",
        tob_address_id: addressId,
      });
      message.success(res.message);
      this.deleteTobAddress(addressId);
    },
  },
});
