import { createModel } from "@rematch/core";
import produce from "immer";
import {
  GraphQLRequest,
  GraphqlQuery,
  GraphqlMutation,
} from "../../helpers/request.helper";
import { message } from "antd";
import { Cart, ShareCart } from "../../models/Cart";
import { Collection } from "../../models/Collection";
import replaceState from "../../helpers/reolaceState";
import { ShortUrl } from "../../models/ShortUrl";

export type StateType = {
  cart: Cart;
  breadcrumbs: { url: string; title: string }[];
  collectIds: { data_id: string }[];
};

export default createModel({
  state: {} as StateType,
  reducers: {
    // 初始化请求数据
    setData(
      state: {},
      cart: StateType["cart"],
      collectIds: StateType["collectIds"],
    ) {
      return { ...state, cart, collectIds };
    },
    // 收藏操作后对应数据修改
    changeCollect(state: StateType, type: number, dataId: number) {
      let collectIds = [];
      if (type) {
        collectIds = state.collectIds.filter(
          item => parseFloat(item.data_id) !== dataId,
        );
      } else {
        const data = [{ data_id: dataId }];
        // collectIds = [...state.collectIds, ...data];
        collectIds = Array.from(new Set([...state.collectIds, ...data]));
      }
      return { ...state, collectIds };
    },
    // 修改商品数量
    changeQuantity(
      state: StateType,
      payload: { indexs: number; index: number; number: number },
    ) {
      if (payload.number >= 1 && payload.number <= 9999) {
        return produce(state, draft => {
          draft.cart[payload.indexs].product[payload.index].quantity =
            payload.number;
        });
      } else {
        return state;
      }
    },
  },
  effects: {
    async getCartAsync(payload) {
      const { callback } = payload;
      const cartData = await GraphqlQuery(
        "TobCarts",
        new Cart({}),
        {},
        "tobCart",
      );
      const collectData = await GraphqlQuery("CollectIds", new Collection(), {
        collect: "product",
      });
      this.setData(cartData, collectData);
      callback && callback();
    },
    async changeProductCollect({
      type,
      dataId,
    }: {
      type: number;
      dataId: number;
    }) {
      const action = type ? "cancel" : "like";
      const data = await GraphqlMutation("Collect", {
        action: action,
        object: "product",
        data_id: dataId,
      });
      this.changeCollect(type ? true : false, dataId);
    },
    async handleChangeOrderQuantity(payload) {
      const { quantity, tob_cart_id } = payload;
      const data = await GraphqlMutation("TobCart", {
        tob_cart_id: tob_cart_id,
        quantity: quantity,
        action: "update",
      });
    },
    async clearCartAsync(cartId: number) {
      const res = await GraphqlMutation("TobCart", {
        action: "remove",
        tob_cart_id: cartId,
      });
      message.success(res.message);
      // this.delCart(("" + cartId).split(","));
      this.getCartAsync();
    },
    async createOrder(payload) {
      const { tobCartId, tobAddressId, quantitys } = payload;
      const res = await GraphqlMutation("TobOrder", {
        action: "add",
        tob_cart_id: tobCartId,
        tob_address_id: tobAddressId,
        // quantity: quantitys,
      });
      replaceState.linkTo("/account/toborders");
    },
    async getSmallUrl(payload) {
      const { url, callback } = payload;
      const model = new ShortUrl(url);
      const data = await GraphqlQuery("ShortUrl", model);
      callback && callback(data);
    },
    async getShareUrl(payload) {
      const { callback, share_data, expired_at } = payload;
      const res = await GraphqlMutation("Share", {
        action: "share",
        share_type: "tobcart",
        share_data: share_data,
        expired_at: expired_at,
      });
      callback && callback(res);
    },

    async getShareData(payload) {
      const { token } = payload;
      const data = new ShareCart({ token });
      const res = await GraphqlQuery("Share", data, {}, "ShareCart");
      console.log(res);
    },
  },
});
