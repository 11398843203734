import React from "react";
import { Row, Col, Avatar } from "antd";
import Icon from "../../../components/icon";
import { useTheme } from "styled-components";

type ArrowNavProps = {
  hasArrow?: boolean;
  children: React.ReactNode;
  leftIcon: React.ReactElement;
  onClick: () => void;
  id?: string;
};

export default function ArrowNav({
  hasArrow = true,
  children,
  leftIcon,
  onClick,
  id,
}: ArrowNavProps) {
  const { primaryColor } = useTheme();

  return (
    <div
      id={id}
      style={{
        marginBottom: 10,
        backgroundColor: "white",
        padding: "10px",
        borderRadius: 5,
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Row justify={"space-between"} align={"middle"}>
        <Col>
          <Avatar
            size={"small"}
            icon={React.cloneElement(leftIcon, { color: primaryColor })}
            style={{ backgroundColor: primaryColor, marginRight: "5px" }}
          />
        </Col>
        <Col span={20}>{children}</Col>
        <Col>
          {hasArrow ? (
            <Icon type="iconzuo" style={{ color: primaryColor }}></Icon>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </div>
  );
}
