import React from "react";
import styled from "styled-components";
import { Card } from "antd";
import { CSSTypes } from "html2canvas/dist/types/css/types";

const ImageMultilineCard = styled(Card)<{
  $borderColor?: string;
  $rows?: number;
  $titleSize?: number;
  $hidePlaceholder?: boolean;
  $hoverableBody?: boolean;
}>`
  border-color: ${props => props.$borderColor ?? "#bdbcbc"};

  &.ant-card-bordered .ant-card-cover {
    margin: 0;
  }

  ${props =>
    props.$hoverableBody
      ? `
        .ant-card-body {
          position:absolute;
          display:none;
          z-index:1;
          width:100%;
        }
        &:hover .ant-card-body {
          display: block;
        }
        `
      : ""}

  .ant-card-meta {
    &-title {
      color: ${props => props.theme.textColor};
      word-break: break-all;
      width: 100%;
      display: -webkit-box;
      -webkit-line-clamp: ${props => props.$rows ?? 2};
      -webkit-box-orient: vertical;
      white-space: normal;
      margin: 0 !important;
      ${props =>
        props.$hidePlaceholder
          ? ""
          : `min-height: ${(props.$rows ?? 2) * (props.$titleSize ?? 25)}px;`}
    }
    &-description {
      width: 100%;
      display: flex;
      flex-flow: column;
    }
  }
`;

export default ImageMultilineCard;

const FloatLayer = styled.div<{
  $position?: "top" | "middle" | "bottom";
  $stretchX?: boolean;
  $stretchY?: boolean;
  $hoverable?: boolean;
  $hoverDisplay?: React.CSSProperties["display"];
}>`
  ${props => (props.$position ? "position:absolute;" : "")}
  ${props => (props.$position === "top" ? "top: 0;" : "")}
  ${props => (props.$position === "bottom" ? "bottom: 0;" : "")}
  ${props =>
    props.$position === "middle" ? "top: 50%;transform: translateY(-50%);" : ""}
  ${props => (props.$stretchX ? "left: 0;right: 0;" : "")}
  ${props => (props.$stretchY ? "top: 0;bottom: 0;" : "")}

  ${props => (props.$hoverable ? "display:none;" : "")}

  ${ImageMultilineCard}:hover & {
    display:${props => props.$hoverDisplay ?? "flex"};
  }
`;

const PanelCard = styled(Card).attrs(props => ({
  style: {
    color: props.color ?? props.theme.panelColor,
    ...props.style,
  },
}))<{ color?: string }>``;

export { FloatLayer, PanelCard };
