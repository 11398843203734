import React, { useEffect, useState, useCallback, useContext } from "react";
import { Dispatch, RootState } from "../../store/configureStore";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import * as styles from "./index.scss";
import PLSelect from "./components/PLSelect";
import CardComponent from "./components/Card";
import { CommonProps } from "../../typings/component";
import * as queryString from "query-string";
import { useIntl } from "react-intl";
import isEmpty from "lodash/isEmpty";
import debounce from "lodash/debounce";
import chunk from "lodash/chunk";

import { BusinessContext } from "../BusinessContext";
import { Row, Col, Affix } from "antd";
import Icon from "../../components/icon";
import Image from "../../components/image";

import Carousel from "../../wrappers/carousel";
import List from "../../wrappers/list";
import { useMount, useUpdateEffect } from "ahooks";
import { useTheme } from "styled-components";
import replaceState, { ParamsType } from "../../helpers/reolaceState";
import { Product, ProductDetail } from "../../models/Product";

import Carousel from "../../wrappers/carousel";
import List from "../../wrappers/list";
import { useTheme } from "styled-components";
type selectListData = {
  value: React.ReactText;
  htmlValue: string;
  checked: boolean;
  level?: number;
}[];

interface PlselectState {
  ProductsCategory: selectListData;
  ProductsSize: selectListData;
  ProductsMaterialsCategory: {
    value: number;
    htmlValue: string;
    child: {
      checked?: boolean;
      material_id: number;
      material_category_id: number;
      material_name: string;
    }[];
  }[];
  ProductsColor: selectListData;
  ProductsStyle: selectListData;
  AllStyle: selectListData;
  Manufacture: selectListData;
  AllColor: {
    checked: boolean;
    color_id: number;
    color_name: string;
    color_group: string;
    color_value: string;
  }[];
  OtherColorName: string;
  OtherColorValue: string;
  Price: null | string;
  ProductsPrice: selectListData;
}

interface ProductListProps {
  haveCategory?: boolean;
  haveFilter?: boolean;

  /**
   * 初始化数据时调用的接口
   */
  handleInitData?: (payload: any) => void;
  /**
   * 搜索数据时调用的接口
   */
  handleSearchData?: (payload: any) => void;

  /**
   * 筛选数据时调用的接口
   */
  handleFilterData?: (payload: any) => void;

  /**
   * 加载更多数据时调用的接口
   */

  handleLoadMoreData?: (payload: any) => void;

  extraParams?: ParamsType;
}

const ProductList: React.FC<CommonProps & ProductListProps> = ({
  isMobile,
  haveCategory = true,
  haveFilter = true,
  handleInitData,
  handleFilterData,
  handleLoadMoreData,
  handleSearchData,
  extraParams = {},
}) => {
  const { formatMessage: f } = useIntl();
  const {
    bussinessPrimaryColor,
    primaryColor,
    pageBackgroundColor,
  } = useTheme();
  const { isToBusiness } = useContext(BusinessContext);

  const dispatch = useDispatch<Dispatch>();

  const {
    skus,
    pickList,
    total = 0,
    isSearch: renderSearch = false,
    breadcrumbs = [],
    room = "0",
    style = "0",
    top_category_id = "0",
    product_category_id = "0",
    parent_category_id = "0",
    fourth_category_id = "0",
    pageSize = 20,
    sku_type,
    productDetails,
  } = useSelector((state: RootState) => state.PLPage || {});
  const { Collections: collection = [] } = useSelector(
    (state: RootState) => state,
  );
  const { keyword = "", host_id, layout } = useSelector(
    (state: RootState) => state.URLSearch,
  );
  const { isLogin } = useSelector((state: RootState) => state.User);
  const { isPrivate } = useSelector((state: RootState) => state.CommonData);

  const isList = layout === "list";
  const isSearch = renderSearch || !!keyword;
  const isMoreFilters = pickList
    ? Object.keys(pickList).some(p => !!pickList[p])
    : false;

  const [showDrawer, setShowDrawer] = useState(false);

  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const [categoryData, setCategoryData] = useState<any[]>([]);
  const [isHaveCategory, setIsHaveCategory] = useState(false);
  const [categoryKeyName, setCategoryKeyName] = useState("");

  useUpdateEffect(() => {
    if (layout === "list") {
      dispatch.PLPage.getProductDetailsAsync();
    }
  }, [layout]);

  useUpdateEffect(() => {
    if (skus) {
      setCurrentPageNumber(1);
    }
  }, [skus]);

  const [isSearchIng, setisSearchIng] = useState(false);

  const handleSelect = (payload: { filterItem?: any; [key: string]: any }) => {
    // 待检查列表
    const checkedList = getSelectParams(payload);

    setisSearchIng(true);
    setShowDrawer(false);

    const requestParams = {
      top_category_id: keyword !== undefined ? undefined : top_category_id + "",
      ...checkedList,
      ...payload,
      sku_type,
      private: isPrivate,
      shop_id: host_id,
      ...extraParams,
      callBack: (productlist: any) => {
        // 筛选项完成之后关闭 drawer  修改页码为1
        setCurrentPageNumber(1);
        dispatch.PLPage.getProductDetailsAsync(productlist);
        setisSearchIng(false);
      },
    };

    handleFilterData
      ? handleFilterData(requestParams)
      : dispatch.PLPage.filterSkuListAsync(requestParams);
  };

  /**
   * 原时间1500，Judd确认修改为500
   */
  const debounceTime = 500;
  /**
   * 价格筛选项
   * 得到最低价格,然后遍历查找到对应项
   */
  const handlePrice = debounce((price_low: string) => {
    if (price_low === null) {
      return handleSelect({
        price: null,
        filterItem: {
          price: null,
        },
      });
    }

    if (pickList && pickList.ProductsPrice) {
      const item = pickList.ProductsPrice.find(
        obj => Number(obj.price_low) === Number(price_low),
      );

      if (item) {
        return handleSelect({
          price_low: item.price_low,
          price_high: item.price_high,
          filterItem: {
            price: item.price_low,
          },
        });
      }
    }
  }, debounceTime);
  /**
   * 大小筛选项
   */
  const handleSize = debounce((id: string | null) => {
    return handleSelect({
      size: id,
      filterItem: { size: id },
    });
  }, debounceTime);

  /**
   * style筛选项
   */
  const handleStyle = debounce((id: string | null) => {
    return handleSelect({
      style: id,
      filterItem: { style: id },
    });
  }, debounceTime);

  /**
   * 获取category筛选项key名
   */

  const handleGetCategotyKey = (level: number) => {
    switch (level) {
      case 1:
        return "top_category_id";
        break;
      case 2:
        return "parent_category_id";
        break;
      case 3:
        return "product_category_id";
        break;
      case 4:
        return "fourth_category_id";
        break;
    }
  };

  /**
   * category筛选项
   */
  const handleCategory = debounce((data: any | null) => {
    if (data.length === 0) {
      dispatch.PLPage.setCategory({
        key: categoryKeyName,
        id: "",
      });
      return handleSelect({});
    }
    const ids = data.map((m: any) => {
      return m.id;
    });
    const id = ids.join("#");
    const keyName = handleGetCategotyKey(Number(data[0].level)),
      filterData = {
        filterItem: {},
      };
    filterData[keyName] = id;
    filterData.filterItem[keyName] = id;
    dispatch.PLPage.setCategory({
      key: keyName,
      id: id,
    });
    setCategoryKeyName(keyName);
    return handleSelect(filterData);
  }, debounceTime);

  /**
   * 颜色筛选
   */
  const handleColor = debounce((id: string, moreColor?: string) => {
    // 这个函数会接受到所有的select组件选择的id那么还需要判断一下是否存在其他colorid
    let newId = "";
    if (id !== "") {
      newId = id;
    }
    if (pickList.otherColor) {
      if (newId !== "") {
        newId = Array.from(
          new Set([...id.split("#"), ...pickList.otherColor.split("#")]),
        ).join("#");
      } else {
        newId = pickList.otherColor;
      }
    }

    return handleSelect({
      color: moreColor !== undefined ? moreColor : newId,
      filterItem: {
        color: id,
      },
    });
  }, debounceTime);

  /**
   * 材质筛选项
   */
  const handleMaterial = debounce((id: string) => {
    return handleSelect({
      material: id,
      filterItem: {
        material: id,
      },
    });
  }, debounceTime);

  /**
   * 推荐排序
   */
  const handleRecommendedAndView = (value: "recommend" | "view" | null) => {
    if (value === null || value === "recommend") {
      return handleSelect({
        order_by: null,
        filterItem: { sorting: "recommend" },
      });
    }

    return handleSelect({
      order_by: "desc",
      filterItem: { sorting: "order_by" },
    });
  };

  const getSelectParams = useCallback(
    ({ filterItem, ...other }: { filterItem?: any; [key: string]: any }) => {
      const checkList: [
        "color",
        "size",
        "sorting",
        "price",
        "material",
        "style",
        "manufactureId",
      ] = [
        "color",
        "size",
        "sorting",
        "price",
        "material",
        "style",
        "manufactureId",
      ];

      // 从redux中获取还有没有其他的筛选项
      const params = checkList.reduce((acc: any, curr) => {
        // 先判断是否存在目标
        if (pickList && (pickList[curr] || curr === "color")) {
          // 如果存在, 并排除值为空
          // 如果是价格需要特殊处理一下
          if (curr === "price") {
            if (filterItem && filterItem.price === null) {
              return acc;
            }

            const priceList = pickList.ProductsPrice.find(
              item => item.price_low === pickList[curr],
            );

            if (priceList && !isEmpty(priceList)) {
              return {
                ...acc,
                price_high: priceList.price_high,
                price_low: priceList.price_low,
              };
            }
            return {
              ...acc,
              ...priceList,
            };
          }
          if (curr === "color") {
            let colorId = "";
            if (
              pickList.color ||
              pickList.otherColor ||
              pickList.userSelectColor
            ) {
              // 三者至少存在一个
              if (pickList.color) {
                const t1 = pickList.color.split("#");
                if (pickList.otherColor) {
                  const t2 = pickList.otherColor.split("#");
                  colorId = Array.from(new Set([...t1, ...t2])).join("#");
                } else {
                  colorId = pickList.color;
                }
              } else {
                if (pickList.otherColor) {
                  colorId = pickList.otherColor;
                }
              }
              // if (pickList.userSelectColor) {
              //   if (colorId) {
              //     colorId = `${colorId}#${pickList.userSelectColor}`;
              //   } else {
              //     colorId = pickList.userSelectColor;
              //   }
              // }
            }
            acc.color = colorId;
            return acc;
          }
          // 如果是 顺序 筛选也需要处理一下 顺序排列里面sorting 这个字段,所以直接返回不添加,在后面会使用payload里面的值
          if (curr === "sorting" && pickList["sorting"]) {
            // 暂时只有 desc (由高到低排序)
            if (pickList["sorting"] === "recommend") {
              return acc;
            }

            return { ...acc, [pickList["sorting"]]: "desc" };
          }
          acc[curr] = pickList[curr];
          return acc;
        }
        return acc;
      }, {});

      // 上面拿到筛选所有的筛选条件之后,在判断 redux 是否存在下面三个值 如果存在那么请求也要带上的.
      // 如果不存,还要赋值为0,(有意思.)

      const qs = queryString.parse(window.location.search || "");
      if (qs.keyword) {
        params.keyword = qs.keyword;
      } else {
        params.room = room || "0";
        params.style = params.style ?? style;
        params.top_category_id = top_category_id || "0";
        params.parent_category_id = parent_category_id || "0";
        params.product_category_id = product_category_id || "0";
        params.fourth_category_id = fourth_category_id || "0";
      }
      return params;
    },
    [pickList],
  );

  /**
   * 获取分页数据
   * @param page
   * @param pageSize
   */
  const handleGetOtherList = (page: number, pageSize?: number) => {
    const qs = queryString.parse(window.location.search);
    const params = getSelectParams({});
    const requestParams = {
      page,
      // pageSize,
      sku_type,
      keyword: qs.keyword,
      private: isPrivate,
      shop_id: host_id,
      ...params,
      ...extraParams,
      callback: (plList: any) => {
        setCurrentPageNumber(page);
        window.scroll(0, 0);
        dispatch.PLPage.getProductDetailsAsync(plList);
      },
    };

    handleLoadMoreData
      ? handleLoadMoreData(requestParams)
      : dispatch.PLPage.getSkuListMoreAsync(requestParams);
  };

  useMount(() => {
    const search: {
      keyword?: string;
      layout?: string;
      host_id?: string;
    } = queryString.parse(window.location.search);

    const callback = () => {
      if (search.layout === "list") {
        dispatch.PLPage.getProductDetailsAsync();
      }
    };

    // 如果 props plpage 为空对象没有skus 则判断服务器渲染失败调用客户端请求
    if (!skus) {
      // 判断url中是否存在查询字符串,如果不存在那么就进行普通的初始化,否则就使用查询字符串进行查询
      if (search.keyword) {
        const keyword = search.keyword
          ? search.keyword.split(",").join(" ")
          : "";

        const requestParams = {
          keyword,
          ...extraParams,
          callback,
        };

        handleSearchData
          ? handleSearchData(requestParams)
          : dispatch.PLPage.searchSkuListAsync(requestParams);
      } else {
        const requestParams = {
          sku_type,
          ...extraParams,
          callback,
        };

        handleInitData
          ? handleInitData(requestParams)
          : dispatch.PLPage.initSkuListAsync(requestParams);
      }
    } else {
      // 服务器端渲染，并且要求展示为列表时
      callback();
    }

    // 初始化collection列表
    if (isLogin) {
      dispatch.Collections.getCollectionAsync();
    }
  });

  useEffect(() => {
    if (!pickList) {
      setCategoryData([]);
    } else if (!isHaveCategory && pickList.Categorys?.length > 0) {
      const categorys = ProductsCategoryItems(pickList);
      setCategoryData(categorys);
      setIsHaveCategory(true);
    }
  }, [pickList]);

  // --------以下为品类处理----------
  const handleArray = (
    arr: any,
    callback: (
      item: any,
    ) => { value: string | number; htmlValue: string; checked: boolean },
  ) => {
    if (!arr || arr.length === 0) return [];
    return arr.map(callback);
  };

  const ProductsCategoryItems = (dataList: any) =>
    handleArray(dataList.Categorys, item => ({
      value: item.category_id,
      level: item.level,
      checked: false,
      htmlValue: item.category_name,
      url: item.category_picture,
    }));

  const handleCategorysParmas = (
    categoryList: PlselectState["ProductsCategory"],
  ) => {
    const tem: any[] = [];
    for (let i = 0; i < categoryList.length; i++) {
      if (categoryList[i].checked) {
        tem.push({
          id: categoryList[i].value,
          level: categoryList[i].level,
        });
      }
    }

    return tem;
  };

  const handleCategoryChecked = (item: any) => {
    const categorys = categoryData.map(m => {
      if (m.value === item.value) {
        m.checked = !m.checked;
      }
      return m;
    });
    setCategoryData(categorys);
    handleCategory(handleCategorysParmas(categorys));
  };

  const getIsChecked = (id: number) => {
    return categoryData.filter(m => m.checked).some(m => m.value === id);
  };
  return (
    <div>
      {skus && skus.length === 0 && !isMoreFilters && (
        <div className={styles["ProductListContent-search-empty"]}>
          <div className={styles["ProductListContent-search-empty-oops"]}>
            {f({ id: "OopsExclamationMark" })}
          </div>
          <p className={styles["ProductListContent-search-empty-content"]}>
            {isSearch ? (
              <>
                {f({ id: "WeCannotFindAnyResultThatMatchYour" })}
                <span
                  className={styles["ProductListContent-search-empty-result"]}
                >
                  {keyword}
                </span>{" "}
              </>
            ) : (
              <>
                {f(
                  { id: "ThePageTitleStillUnderConstructionPeriod" },
                  {
                    title:
                      breadcrumbs.length > 0
                        ? breadcrumbs[breadcrumbs.length - 1].title
                        : "",
                  },
                )}
              </>
            )}
          </p>
        </div>
      )}

      {haveCategory && categoryData && categoryData.length > 0 && (
        <div className={styles["themeid-shop-category"]}>
          <h3 className={styles["ProductListContent-header"]}>
            {f({ id: "Category" })}
          </h3>
          <Carousel
            arrows
            dots={false}
            slidesToScroll={1}
            slidesToShow={1}
            infinite={false}
            style={{ padding: "30px 20px" }}
          >
            {chunk(categoryData, 5).map((g, index) => (
              <div key={index}>
                <Row gutter={50} style={{ padding: "0 12px" }}>
                  {g.map((category, catIndex) => (
                    <Col style={{ width: "20%" }}>
                      <a
                        className={styles["themeid-shop-category-wrapper"]}
                        key={catIndex}
                        onClick={() => handleCategoryChecked(category)}
                      >
                        <div
                          className={styles["themeid-shop-category-item"]}
                          style={{
                            boxSizing: "border-box",
                            border: getIsChecked(category.value)
                              ? "2px solid " + primaryColor
                              : " ",
                          }}
                        >
                          <Image src={category.url} width={1} height={1}>
                            <div
                              className={
                                styles["themeid-shop-category-item-title"]
                              }
                            >
                              {category.htmlValue}
                            </div>
                          </Image>
                        </div>
                      </a>
                    </Col>
                  ))}
                </Row>
              </div>
            ))}
          </Carousel>
        </div>
      )}

      <div className={styles["ProductListContent-box"]}>
        {skus && (
          <>
            <h3 className={styles["ProductListContent-header"]}>
              {f({ id: "Products" })}
            </h3>
            {haveFilter && (
              <Affix offsetTop={68} className="top-layer-affix">
                <div
                  className={styles["ProductListContent-select-box"]}
                  style={{
                    backgroundColor: pageBackgroundColor,
                  }}
                >
                  <PLSelect
                    renderIcon={() => (
                      <div>
                        <Icon
                          type="icongrid"
                          onClick={() => replaceState.set({ layout: "card" })}
                          className={classNames(
                            styles[`ProductListContent-select-icon`],
                            {
                              [styles[
                                `ProductListContent-select-swicth-open`
                              ]]: !isList,
                            },
                          )}
                          style={{
                            color: !isList
                              ? isToBusiness
                                ? bussinessPrimaryColor
                                : primaryColor
                              : "",
                          }}
                        />
                        <Icon
                          type="iconlist"
                          onClick={() => replaceState.set({ layout: "list" })}
                          className={classNames(
                            styles[`ProductListContent-select-icon`],
                            {
                              [styles[
                                `ProductListContent-select-swicth-open`
                              ]]: isList,
                            },
                          )}
                          style={{
                            color: isList
                              ? isToBusiness
                                ? bussinessPrimaryColor
                                : primaryColor
                              : "",
                          }}
                        />
                      </div>
                    )}
                    dataList={pickList}
                    onChnageRecommend={handleRecommendedAndView}
                    handleSize={handleSize}
                    handleStyle={handleStyle}
                    handleMaterial={handleMaterial}
                    handleProductcolor={handleColor}
                    handlePrice={handlePrice}
                    onChangeFilter={handleSelect}
                    isSearchIng={isSearchIng}
                    categoryData={categoryData}
                    results={skus && skus.length !== 0 && total}
                    isOpen={showDrawer}
                    onClose={() => setShowDrawer(false)}
                    onOpen={() => setShowDrawer(true)}
                    classNamePrefix={isMobile ? "mobile" : ""}
                    isSearch={isSearch}
                  />
                </div>
              </Affix>
            )}
          </>
        )}

        <List
          grid={{ gutter: 10, column: isList ? 1 : 4 }}
          dataSource={skus}
          locale={{
            emptyText: (
              <div className={styles["ProductListContent-filters-empty"]}>
                {f({ id: "OopsExclamationMarkCurrentFilterOptionsDoNot" })}
              </div>
            ),
          }}
          renderItem={(item, index) => (
            <List.Item key={index} style={{ marginBottom: "48px" }}>
              <CardComponent
                isLogin={isLogin}
                collection={collection}
                onChangeUserLike={({ shop_product_id: data_id, isLike }) =>
                  dispatch.Collections.submitCollectAsync({
                    action: isLike ? "cancel" : "like",
                    object: "product",
                    data_id,
                  })
                }
                {...item}
                classNamePrefix={isMobile ? "mobile" : ""}
                isSearch={isSearch}
                // isProvider={isProvider}
                productDetails={
                  productDetails && productDetails[item.default_product_id]
                }
                layout={layout}
              />
            </List.Item>
          )}
          activeColor={isToBusiness ? bussinessPrimaryColor : primaryColor}
          pagination={{
            onChange: handleGetOtherList,
            pageSize,
            total,
            current: currentPageNumber,
            showSizeChanger: false,
            hideOnSinglePage: true,
          }}
        />
      </div>
    </div>
  );
};

export default React.memo(ProductList);
