import { GraphqlField } from "../helpers/decorators";
import BaseModel from "./BaseModel";

export class PlatForm extends BaseModel {
  @GraphqlField()
  public business_time: string;
  @GraphqlField()
  public platform_phone: string;
  @GraphqlField()
  public platform_email: string;
  @GraphqlField()
  public platform_address: string;
}
