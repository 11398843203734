import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../../store/configureStore";
import ProductCard from "../../ProductList/components/Card";

import { useIntl } from "react-intl";
import List from "../../../wrappers/list";

interface ProductProps {
  list: any;
  onUserLike: (
    isLike: boolean,
    productId: number,
    skuId: number,
    object: string,
    callBack: (isLike: boolean) => void,
  ) => void;
  collection: number[];
}

const Product = (props: ProductProps) => {
  const { formatMessage: f } = useIntl();
  const { MegaBookPage: rematch } = useDispatch<Dispatch>();
  const [currentPageNumber, SetCurrentPageNumber] = useState(1);
  const { host_id } = useSelector((state: RootState) => state.URLSearch);

  const { CollectTotal } = useSelector(
    (state: RootState) => state.MegaBookPage,
  );

  const handleGetOtherList = (page: number) => {
    const data = { page: page, shop_id: host_id };

    SetCurrentPageNumber(page);
    rematch.setProductCollect(data);
    window.scroll(0, 0);
  };

  const dispatch = useDispatch<Dispatch>();

  const { collection = [] } = props;

  return (
    <List
      dataSource={props.list}
      split={false}
      grid={{ gutter: 10, column: 4 }}
      renderItem={(item: any, index) => (
        <List.Item key={index}>
          <ProductCard
            key={item.product_id}
            isLogin
            collection={collection}
            onChangeUserLike={({ shop_product_id: data_id, isLike }) =>
              dispatch.Collections.submitCollectAsync({
                action: isLike ? "cancel" : "like",
                object: "product",
                data_id,
              })
            }
            {...item}
            classNamePrefix=""
            isSearch
            noBorder
          />
        </List.Item>
      )}
      pagination={{
        current: currentPageNumber,
        total: CollectTotal?.total ?? 0,
        pageSize: 10,
        showSizeChanger: false,
        onChange: handleGetOtherList,
        hideOnSinglePage: true,
      }}
    ></List>
  );
};

export default React.memo(Product);
