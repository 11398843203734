import * as React from "react";
import * as ReactDOM from "react-dom";
import * as ReactDOMServer from "react-dom/server";
import { Provider } from "react-redux";
import * as hypernova from "hypernova";
import LocaleProvider from "../locales";

function buildProvider(connectedComponent: React.ReactElement<{}>, store: any) {
  return (
    <Provider store={store}>
      <LocaleProvider>
        {React.isValidElement(connectedComponent)
          ? connectedComponent
          : React.createElement(connectedComponent)}
      </LocaleProvider>
    </Provider>
  );
}
// prettier-ignore
function buildComponent(ConnectedComponent: any, props: any) {
  return <ConnectedComponent {...props} />;
}
// prettier-ignore
export const renderReactRedux = (name: string, connectedComponent: any, configureStore: any, isComponentProps: boolean = false) => {
  return hypernova({
    server: function server() {
      return (props: any) => {
        try {
          const element = isComponentProps ? buildComponent(connectedComponent, props) : buildProvider(connectedComponent, configureStore(props));
          const html = ReactDOMServer.renderToString(element);

          return hypernova.serialize(name, html, props);
        } catch (err) {
          // tslint:disable-next-line: no-console
          console.log("error: ", err);
        }
      };
    },
    client: function client() {
      try {
        const payloads = hypernova.load(name);
        if (payloads) {
          payloads.forEach((payload: any) => {
            const node = payload.node;
            const data = payload.data;
            const element = isComponentProps ? buildComponent(connectedComponent, data) : buildProvider(connectedComponent, configureStore(data));
            ReactDOM.hydrate(element, node);
          });
        }
        return connectedComponent;
      } catch (err) {
        // tslint:disable-next-line: no-console
        console.log("error: ", err);
      }
    }
  });
};
