import React, { useContext } from "react";
import { PlStateType } from "../models";
import styles from "./index.scss";
import ColorScreening from "./ColorScreening";
import { produce } from "immer";
import flatMap from "lodash/flatMap";
import classname from "classnames";
import { BusinessContext } from "../../BusinessContext";

import Checkbox from "../../../wrappers/checkbox";
import Drawer from "../../../wrappers/drawer";
import Button, { PrimaryButton } from "../../../wrappers/button";
import Icon from "../../../components/icon";
import { Collapse, Dropdown, Menu, Tag } from "antd";
import { useTheme } from "styled-components";
import { useIntl } from "react-intl";
import { useSetState } from "ahooks";

interface PlSelectsProps {
  dataList: PlStateType["pickList"];
  categoryData: any;
  onChnageRecommend: (value: "recommend" | "release" | null) => void;

  handleSize: (id: string) => void;

  handleStyle: (id: string) => void;

  handleMaterial: (id: string) => void;

  handleProductcolor: (id: string, moreColor?: string) => void;

  handlePrice: (price_low: any) => void;

  onChangeFilter: (payload: { [key: string]: any; filterItem?: any }) => void;
  isSearchIng: boolean;

  results: any;

  classNamePrefix: "" | "mobile";
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;

  renderIcon: () => JSX.Element;
  isSearch: any;

  formatMessage: (descriptor: any, values?: any) => string;
}
type selectListData = {
  value: React.ReactText;
  htmlValue: string;
  checked: boolean;
  level?: number;
}[];

interface PlselectState {
  ProductsCategory: selectListData;
  ProductsSize: selectListData;
  ProductsMaterialsCategory: {
    value: number;
    htmlValue: string;
    child: {
      checked?: boolean;
      material_id: number;
      material_category_id: number;
      material_name: string;
    }[];
  }[];
  ProductsColor: selectListData;
  ProductsStyle: selectListData;
  AllStyle: selectListData;
  AllColor: {
    checked: boolean;
    color_id: number;
    color_name: string;
    color_group: string;
    color_value: string;
  }[];

  OtherColorName: string;
  OtherColorValue: string;
  Price: null | string;

  collaseValue: string[] | string;
}
const { Panel } = Collapse;
const { Item } = Menu;

const PlSelects: React.FC<PlSelectsProps> = props => {
  const { formatMessage: f } = useIntl();
  const { primaryColor, bussinessPrimaryColor, secondaryColor } = useTheme();
  const { isToBusiness } = useContext(BusinessContext);

  const dataList = props.dataList || {};
  const { sorting = "recommend" } = dataList;

  const ProductsStyleItems = dataList.Style?.map(item => ({
    value: item.style_id,
    checked: false,
    htmlValue: item.style_name,
  }));

  const [state, setState] = useSetState<PlselectState>({
    ProductsCategory: dataList.Categorys?.map(item => ({
      value: item.category_id,
      level: item.level,
      checked: false,
      htmlValue: item.category_name,
    })),
    ProductsSize: dataList.Size?.map(item => ({
      value: item.size_id,
      checked: false,
      htmlValue: item.size_name,
    })),
    ProductsMaterialsCategory: dataList.MaterialsCategory?.map(item => ({
      value: item.material_category_id,
      htmlValue: item.material_category_name,
      child: dataList.Materials.filter(
        i => i.material_category_id === item.material_category_id,
      ),
    })),
    ProductsColor: dataList.Color?.map(item => ({
      value: item.color_id,
      checked: false,
      htmlValue: item.color_name,
    })),
    ProductsStyle: ProductsStyleItems,
    AllStyle: ProductsStyleItems,
    AllColor: dataList.AllColor?.map(item => ({
      ...item,
      checked: false,
    })),
    Price: null,
    OtherColorName: "", // 其他颜色
    OtherColorValue: "",
    collaseValue: ["Color"],
  });

  const handleParams = (list: any[], id: string): string => {
    return list.reduce((acc, curr) => {
      if (curr.checked) {
        // 如果选中判断 arr
        if (acc === "") {
          return acc + curr[id];
        } else {
          return acc + `#${curr[id]}`;
        }
      }
      return acc;
    }, "");
  };

  const handleSize = (bl: boolean, index: number) => {
    setState(state => ({
      ProductsSize: produce(state.ProductsSize, draftState => {
        draftState[index].checked = bl;
      }),
    }));

    props.handleSize(handleParams(state.ProductsSize, "value"));
  };

  const hadnleMaterialsParmas = (
    materialList: PlselectState["ProductsMaterialsCategory"],
  ) => {
    const tem: number[] = [];
    for (let i = 0; i < materialList.length; i++) {
      for (let y = 0; y < materialList[i].child.length; y++) {
        if (materialList[i].child[y].checked) {
          tem.push(materialList[i].child[y].material_id);
        }
      }
    }

    return tem.join("#");
  };

  const handleMaterial = (
    parentIndex: number,
    childIndex: number,
    bl: boolean,
  ) => {
    setState(state => {
      const ProductsMaterialsCategory = produce(
        state.ProductsMaterialsCategory,
        draftState => {
          draftState[parentIndex].child[childIndex].checked = bl;
        },
      );

      props.handleMaterial(hadnleMaterialsParmas(ProductsMaterialsCategory));

      return {
        ProductsMaterialsCategory,
      };
    });
  };

  const handleCategorysParmas = (
    categoryList: PlselectState["ProductsCategory"],
  ) => {
    const tem: any[] = [];
    for (let i = 0; i < categoryList.length; i++) {
      if (categoryList[i].checked) {
        tem.push({
          id: categoryList[i].value,
          level: categoryList[i].level,
        });
      }
    }

    return tem;
  };

  const handleProductsColor = (bl: boolean, index: number) => {
    const colorId = state.ProductsColor[index].value;
    const allColorIndex = state.AllColor.findIndex(c => c.color_id === colorId);

    const colorPickerValues =
      !bl && state.ProductsColor[index].htmlValue === state.OtherColorName
        ? {
            OtherColorName: "",
            OtherColorValue: "",
          }
        : {
            OtherColorName: state.OtherColorName,
            OtherColorValue: state.OtherColorValue,
          };

    setState(state => {
      const ProductsColor = produce(state.ProductsColor, draftState => {
        draftState[index].checked = bl;
      });

      props.handleProductcolor(handleParams(ProductsColor, "value"));

      return {
        ProductsColor,
        AllColor:
          allColorIndex > -1
            ? produce(state.AllColor, draftState => {
                draftState[allColorIndex].checked = bl;
              })
            : state.AllColor,
        ...colorPickerValues,
      };
    });
  };

  const handleCloseColorTag = () => {
    const AllColor = state.AllColor.map(c => {
      const exist = state.ProductsColor.some(pc => pc.value === c.color_id);

      return { ...c, checked: exist ? false : c.checked };
    });

    const colorPickerValues = state.ProductsColor.some(
      pc => pc.htmlValue === state.OtherColorName,
    )
      ? {
          OtherColorName: "",
          OtherColorValue: "",
        }
      : {
          OtherColorName: state.OtherColorName,
          OtherColorValue: state.OtherColorValue,
        };

    setState({
      ProductsColor: state.ProductsColor.map(item => ({
        ...item,
        checked: false,
      })),
      AllColor,
      ...colorPickerValues,
    });

    const moreColor = handleParams(AllColor, "color_id");

    props.handleProductcolor(
      "",
      moreColor ? `${moreColor}#${state.OtherColorName}` : state.OtherColorName,
    );
  };

  const handlePreSubmit = () => {
    const ProductsColor = state.ProductsColor.map(c => {
        // AllColor包含ProductsColor
        const temp = state.AllColor.find(ac => ac.color_id === c.value);
        const otherChecked = c.htmlValue === state.OtherColorName;

        return {
          ...c,
          checked: temp ? temp.checked : c.checked || otherChecked,
        };
      }),
      ProductsStyle = state.AllStyle;

    setState({
      ProductsColor,
      ProductsStyle,
    });

    handleSubmit(ProductsColor, ProductsStyle);
  };

  /**
   * 获取category筛选项key名
   */

  const handleGetCategotyKey = (level: number) => {
    switch (level) {
      case 1:
        return "top_category_id";
        break;
      case 2:
        return "parent_category_id";
        break;
      case 3:
        return "product_category_id";
        break;
      case 4:
        return "fourth_category_id";
        break;
    }
  };

  const handleSubmit = (selectedColors, selectedStyles) => {
    // 收集用户所选项
    const style = handleParams(selectedStyles, "value");
    const color = handleParams(selectedColors, "value");
    const moreColor = handleParams(state.AllColor, "color_id");
    const category = handleCategorysParmas(props.categoryData);
    let categoryIds = [],
      keyValue;
    if (category.length > 0) {
      categoryIds = category.map((m: any) => m.id);
      keyValue = handleGetCategotyKey(category[0].level);
    }
    const subPayload: {
      size?: string;
      material?: string;
      style?: string;
      price_low?: string;
      price_high?: string;
      manufactureId?: string;
      color?: string;
      top_category_id?: string;
      parent_category_id?: string;
      product_category_id?: string;
      fourth_category_id?: string;
      filterItem: { [name: string]: string | null };
    } = { filterItem: {} };
    // 风格和制造商没什么特殊的
    subPayload.style = style;
    subPayload.filterItem.style = style;

    if (category.length > 0) {
      subPayload[keyValue] = categoryIds.join("#");
      subPayload.filterItem[keyValue] = categoryIds.join("#");
    }

    // 单独处理颜色

    // // 首先判断其他多选项中有没有更多选择颜色
    // if (props.dataList.color) {
    //   const t1 = props.dataList.color.split("#");
    //   if (moreColor !== "") {
    //     const t2 = moreColor.split("#");
    //     subPayload.color = Array.from(new Set([...t1, ...t2])).join("#");
    //   } else {
    //     subPayload.color = props.dataList.color;
    //   }
    // } else {
    //   subPayload.color = moreColor;
    // }
    subPayload.color = moreColor;

    // 去掉颜色选择器的自定义值
    // if (state.OtherColorName) {
    //   if (subPayload.color) {
    //     subPayload.color = `${subPayload.color}#${state.OtherColorName}`;
    //   } else {
    //     subPayload.color = state.OtherColorName;
    //   }
    // }

    // if (color !== "") {
    //   const t1 = color.split("#");
    //   if (subPayload.color !== "") {
    //     const t2 = subPayload.color.split("#");
    //     subPayload.color = Array.from(new Set([...t1, ...t2])).join("#");
    //   } else {
    //     subPayload.color = color;
    //   }
    // }

    subPayload.filterItem.color = color;
    // subPayload.filterItem.otherColor = moreColor;
    subPayload.filterItem.userSelectColor = state.OtherColorName;
    if (props.classNamePrefix !== "") {
      const size = handleParams(state.ProductsSize, "value");
      const material = hadnleMaterialsParmas(state.ProductsMaterialsCategory);

      if (state.Price) {
        const pirceitem = props.dataList.ProductsPrice.find(
          item => Number(item.price_low) === Number(state.Price),
        );

        if (pirceitem) {
          subPayload.price_low = String(pirceitem.price_low);
          subPayload.price_high = String(pirceitem.price_high);
        }
      }
      subPayload.filterItem.price = state.Price;
      subPayload.size = size;
      subPayload.filterItem.size = size;
      subPayload.material = material;
      subPayload.filterItem.material = material;
      if (category.length > 0) {
        subPayload[keyValue] = categoryIds.join("#");
        subPayload.filterItem[keyValue] = categoryIds.join("#");
      }
    }

    const price = props.dataList.ProductsPrice.find(
      item => Number(item.price_low) === Number(props.dataList.price),
    );
    if (price) {
      subPayload.price_low = String(price.price_low);
      subPayload.price_high = String(price.price_high);
    }

    if (Object.keys(subPayload).length !== 0) {
      props.onChangeFilter(subPayload as {});
    }
  };

  const renderTags: <T extends { checked: boolean }, K extends keyof T>(
    arr: T[] | string | null,
    key: K,
    label: string,
    callback: () => void,
    multi?: boolean,
    text?: string,
  ) => React.ReactNode = (arr, key, label, callback, multi, text) => {
    const backgroundColor = isToBusiness
      ? bussinessPrimaryColor
      : secondaryColor;

    if ((Array.isArray(arr) && arr.some(item => item.checked)) || multi) {
      return (
        <Tag
          closable
          onClose={callback}
          color={backgroundColor}
          className="customer-close-icon"
          closeIcon={
            <Button
              size="small"
              shape="circle"
              color={backgroundColor}
              icon={<Icon type="icont1_close" />}
              loading={props.isSearchIng}
            />
          }
          style={{ fontSize: "1.05em", padding: "3px" }}
        >
          {label}：
          {Array.isArray(arr)
            ? arr
                .reduce((acc: any[], cur) => {
                  if (cur.checked) {
                    acc.push(cur[key]);
                  }
                  return acc;
                }, [])
                .join(" / ")
            : text}
        </Tag>
      );
    }

    return null;
  };

  const getPriceName = () => {
    const tem = props.dataList.ProductsPrice.find(
      item => item.price_low == state.Price,
    );
    if (tem) return tem.price_name;
    return "";
  };

  let backupFilters: Partial<PlselectState>;

  const openDrawer = () => {
    backupFilters = {
      ProductsMaterialsCategory: state.ProductsMaterialsCategory,
      ProductsSize: state.ProductsSize,
      ProductsStyle: state.ProductsStyle,
      AllColor: state.AllColor,
      OtherColorName: state.OtherColorName,
      OtherColorValue: state.OtherColorValue,
      Price: state.Price,
    };

    props.onOpen();
  };

  const closeDrawer = () => {
    setState({ ...backupFilters });

    props.onClose();
  };

  const panelStyle: React.CSSProperties = {
    backgroundColor: "#fff",
    fontSize: "1.2em",
    marginBottom: "13px",
    borderRadius: "5px",
  };

  return (
    <div className={styles[`Plselect`]}>
      <div className={styles[`Plselect-select`]}>
        {props.renderIcon()}
        <div className={styles["Plselect-select-t"]}>
          <div className={styles["Plselect-select-items"]}>
            {!props.isSearch && (
              <Dropdown
                overlay={
                  <Menu
                    onClick={e =>
                      sorting !== e.key && props.onChnageRecommend(e.key)
                    }
                  >
                    <Item key="recommend">
                      {f({
                        id: "Recommended",
                      })}
                    </Item>
                    <Item key="order_by">
                      {f({
                        id: "NewReleases",
                      })}
                    </Item>
                  </Menu>
                }
                trigger={["click"]}
                className="dropdown-select-menu"
              >
                <span>
                  {sorting === "recommend"
                    ? f({ id: `Recommended` })
                    : f({ id: `NewReleases` })}
                  <Icon type="icont2_arrowDown" />
                </span>
              </Dropdown>
            )}
            {state.ProductsSize?.length > 0 && (
              <Dropdown
                overlay={
                  <Menu>
                    {state.ProductsSize.map((ps, index) => (
                      <li className="ant-dropdown-menu-item" key={ps.value}>
                        <Checkbox
                          shape="circle"
                          borderWidth="2px"
                          color={
                            isToBusiness ? bussinessPrimaryColor : primaryColor
                          }
                          checked={ps.checked}
                          onChange={e => handleSize(e.target.checked, index)}
                        >
                          {ps.htmlValue}
                        </Checkbox>
                      </li>
                    ))}
                  </Menu>
                }
                trigger={["click"]}
                className="dropdown-select-menu"
              >
                <span>
                  {f({
                    id: "Size",
                  })}
                  <Icon type="icont2_arrowDown" />
                </span>
              </Dropdown>
            )}
            {state.ProductsMaterialsCategory?.length > 0 && (
              <Dropdown
                overlay={
                  <Menu>
                    {state.ProductsMaterialsCategory.map((ps, index) => (
                      <>
                        <p style={{ padding: "0 10px" }}>{ps.htmlValue}</p>
                        {ps.child.map((c, ci) => (
                          <li
                            className="ant-dropdown-menu-item"
                            key={c.material_id}
                          >
                            <Checkbox
                              shape="circle"
                              borderWidth="2px"
                              color={
                                isToBusiness
                                  ? bussinessPrimaryColor
                                  : primaryColor
                              }
                              checked={c.checked}
                              onChange={e =>
                                handleMaterial(index, ci, e.target.checked)
                              }
                            >
                              {c.material_name}
                            </Checkbox>
                          </li>
                        ))}
                      </>
                    ))}
                  </Menu>
                }
                trigger={["click"]}
                className="dropdown-select-menu"
              >
                <span>
                  {f({
                    id: "Material",
                  })}
                  <Icon type="icont2_arrowDown" />
                </span>
              </Dropdown>
            )}
            {state.ProductsColor?.length > 0 && (
              <Dropdown
                overlay={
                  <Menu>
                    {state.ProductsColor.map((ps, index) => (
                      <li className="ant-dropdown-menu-item" key={ps.value}>
                        <Checkbox
                          shape="circle"
                          borderWidth="2px"
                          color={
                            isToBusiness ? bussinessPrimaryColor : primaryColor
                          }
                          checked={ps.checked}
                          onChange={e =>
                            handleProductsColor(e.target.checked, index)
                          }
                        >
                          {ps.htmlValue}
                        </Checkbox>
                      </li>
                    ))}
                  </Menu>
                }
                trigger={["click"]}
                className="dropdown-select-menu"
              >
                <span>
                  {f({
                    id: "Color",
                  })}
                  <Icon type="icont2_arrowDown" />
                </span>
              </Dropdown>
            )}
            {props.dataList.ProductsPrice?.length > 0 && (
              <Dropdown
                overlay={
                  <Menu
                    onClick={e => {
                      setState({
                        Price: e.key === "no" ? null : e.key,
                      });

                      props.handlePrice(e.key === "no" ? null : e.key);
                    }}
                  >
                    <Item key="no">
                      {f({
                        id: "DashDashNoPreferenceDashDash",
                      })}
                    </Item>
                    {props.dataList.ProductsPrice.map((ps, index) => (
                      <Item key={ps.price_low}>{ps.price_name}</Item>
                    ))}
                  </Menu>
                }
                trigger={["click"]}
                className="dropdown-select-menu"
              >
                <span>
                  {f({
                    id: "Price",
                  })}

                  <Icon type="icont2_arrowDown" />
                </span>
              </Dropdown>
            )}

            <div onClick={openDrawer} className="dropdown-select-menu">
              <span>
                {f({ id: "MoreFilters" })}
                <Icon type="icont1_shaixuan1" />
              </span>
            </div>

            <Drawer
              visible={props.isOpen}
              onClose={closeDrawer}
              title={f({
                id: "SortAndFilter",
              })}
              width="500px"
              bodyStyle={{
                padding: "18px 14px",
              }}
              color={isToBusiness ? bussinessPrimaryColor : secondaryColor}
            >
              <Collapse
                onChange={collaseValue => setState({ collaseValue })}
                defaultActiveKey={state.collaseValue}
                expandIconPosition="right"
                expandIcon={panelProps => (
                  <Icon
                    type={panelProps.isActive ? "icont1_jian" : "icont1_jia"}
                    style={{ color: "#c6bdb9", fontSize: "1em" }}
                  />
                )}
                ghost
              >
                {state.ProductsStyle?.length > 0 && (
                  <Panel
                    header={f({
                      id: "Style",
                    })}
                    key="Style"
                    style={panelStyle}
                  >
                    <div className={styles["Plselect-drawer-item-box"]}>
                      {state.AllStyle?.map((item, i) => (
                        <div key={i}>
                          <Checkbox
                            shape="circle"
                            borderWidth="2px"
                            onChange={e => {
                              setState(state => ({
                                AllStyle: produce(
                                  state.AllStyle,
                                  draftState => {
                                    draftState[i].checked = e.target.checked;
                                  },
                                ),
                              }));
                            }}
                            checked={item.checked}
                            color={
                              isToBusiness
                                ? bussinessPrimaryColor
                                : primaryColor
                            }
                          >
                            {item.htmlValue}
                          </Checkbox>
                        </div>
                      ))}
                    </div>
                  </Panel>
                )}
                {state.AllColor?.length > 0 && (
                  <Panel
                    header={f({
                      id: "Color",
                    })}
                    key="Color"
                    style={panelStyle}
                  >
                    <ColorScreening
                      list={state.AllColor}
                      onChange={(bl, id) => {
                        const index = state.AllColor.findIndex(
                          c => c.color_id === id,
                        );
                        setState(state => ({
                          AllColor: produce(state.AllColor, draftState => {
                            draftState[index].checked = bl;
                          }),
                        }));
                      }}
                      onChangeOtherColor={(colorValue, colorName) => {
                        const index = state.AllColor.findIndex(
                          c => c.color_name === colorName,
                        );

                        setState(state => ({
                          AllColor:
                            index > -1
                              ? produce(state.AllColor, draftState => {
                                  draftState[index].checked = true;
                                })
                              : state.AllColor,
                          OtherColorValue: colorValue,
                          OtherColorName: colorName,
                        }));
                      }}
                      colorName={state.OtherColorName}
                      colorValue={state.OtherColorValue}
                    />
                  </Panel>
                )}
              </Collapse>

              <div className={classname(styles[`Plselect-drawer-floor`])}>
                <Button
                  onClick={closeDrawer}
                  size="large"
                  color={isToBusiness ? bussinessPrimaryColor : primaryColor}
                >
                  {f({ id: "Return" })}
                  <Icon type="icont1_shuaxin" />
                </Button>

                <PrimaryButton
                  onClick={handlePreSubmit}
                  size="large"
                  bgColor={isToBusiness ? bussinessPrimaryColor : primaryColor}
                >
                  {f({ id: "Apply" })}
                  <Icon type="icont1_correct" />
                </PrimaryButton>
              </div>
            </Drawer>
          </div>

          {props.results && (
            <span
              className={classname(styles["Plselect-results"])}
              style={{ marginRight: "0px" }}
            >
              {f({ id: "CountResults" }, { count: props.results })}
            </span>
          )}
        </div>
      </div>
      <div className={styles["Plselect-tagBox"]}>
        {renderTags(
          flatMap(state.ProductsMaterialsCategory, "child"),
          "material_name",
          f({ id: "Material" }),
          () => {
            setState({
              ProductsMaterialsCategory: state.ProductsMaterialsCategory.map(
                item => ({
                  ...item,
                  child: item.child.map(v => ({
                    ...v,
                    checked: undefined,
                  })),
                }),
              ),
            });
            props.handleMaterial("");
          },
        )}

        {renderTags(
          state.ProductsColor,
          "htmlValue",
          f({ id: "Color" }),
          handleCloseColorTag,
        )}

        {state.Price &&
          renderTags(
            state.Price,
            "checked",
            f({ id: "Price" }),
            () => {
              setState({ Price: null });
              props.handlePrice(null);
            },
            true,
            getPriceName(),
          )}

        {renderTags(
          state.ProductsStyle,
          "htmlValue",
          f({ id: "Style" }),
          () => {
            setState({
              ProductsStyle: state.ProductsStyle.map(item => ({
                ...item,
                checked: false,
              })),
              AllStyle: state.AllStyle.map(item => ({
                ...item,
                checked: false,
              })),
            });
            props.handleStyle("");
          },
        )}
      </div>
    </div>
  );
};

export default React.memo(PlSelects);
