import { createModel } from "@rematch/core";

export type StateType = {
  Privacy: string;
  AboutUs: string;
};

export default createModel({
  state: {
    Privacy: "<h1 style={{textAlign:'center'}}>这是隐私授权</h1>",
    AboutUs: "<h1 style={{textAlign:'center'}}>这是关于我们</h1>",
  } as StateType,
});
