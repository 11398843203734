import React, { useEffect, useRef, useContext } from "react";
import * as styles from "./index.scss";
import classNames from "classnames";
import * as Loadable from "react-loadable";
import Print from "react-to-print";
import LogoImg from "../../../static/img/smplogonew.png";
import Checkbox from "../../../wrappers/checkbox";
import { PrimaryButton } from "../../../wrappers/button";
import Input from "../../../wrappers/input";
import { Row, Col, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch, RootState } from "../../../store/configureStore";

import { copyToClipboard } from "../../../helpers/DomUtil";
import { BusinessContext } from "../../BusinessContext";
import Provider from "../../../models/Provider";
import { Product } from "../../../models/Product";
import { useMount, useUpdateEffect, useSetState, useClickAway } from "ahooks";
import { useIntl } from "react-intl";
import { useTheme } from "styled-components";

// @ts-ignore
const LoadableComponent = Loadable({
  loader: () => import(/** QrCodeComponent */ "@megahome/qrcode/dist/index.js"),
  loading: () => null,
});

export type QrCodeProps = {
  show: boolean;
  onClose: () => void;
  value: string;
  width?: number;
  height?: number;
  title?: string;
  manufacturer?: string;
  options?: {
    title: string;
    value: string;
  }[];
  fromAddress?: {
    title: string;
    value: string;
  }[];
  img?: string;
  agentCodeGetData?: string;

  item: Product;
  provider: Provider;
  url: string;
};

interface State {
  isInputDisabled: boolean;
  isPriceShow: boolean;
  isOriginPriceShow: boolean;

  price_low: number;
  price_high: number;
}

const QrCode: React.FC<QrCodeProps> = props => {
  const { formatMessage: f } = useIntl();
  const { primaryColor, bussinessPrimaryColor, linkColor } = useTheme();
  const { isToBusiness } = useContext(BusinessContext);
  const { tag_price_low, tag_price_high } = props.item;

  const { isPrivate } = useSelector((state: RootState) => state.CommonData);

  const { host_id } = useSelector((state: RootState) => state.URLSearch);
  const containerRef = useRef<HTMLDivElement>(null),
    boxRef = useRef<HTMLDivElement>(null),
    qrcodeLabelDOM = useRef<HTMLDivElement>(null),
    qrcodeDOM = useRef<HTMLDivElement>(null);

  const [state, setState] = useSetState<State>({
    isPriceShow: true,
    isOriginPriceShow: true,
    isInputDisabled: false,
    price_low: tag_price_low,
    price_high: tag_price_high,
  });

  useClickAway(() => {
    props.onClose();
  }, containerRef);

  useUpdateEffect(() => {
    if (props.item) {
      setState({
        isInputDisabled: false,
        price_low: tag_price_low,
        price_high: tag_price_high,
      });
    }
  }, [props.item]);

  const rules = /(^(\$|\￥)[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^\$(0){1}$)|(^\$[0-9]\.[0-9]([0-9])?$)/;

  const handleLowBlur = () => {
    const printLowData = state.price_low + "";

    if (!rules.test(printLowData)) {
      setState({ price_low: tag_price_low });
    } else {
      const priceValue = Number(printLowData),
        propsPriceLowValue = Number(tag_price_low),
        propsPriceHighValue = Number(tag_price_high),
        statePriceHighValue = Number(state.price_high);

      if (
        priceValue < propsPriceLowValue ||
        priceValue > propsPriceHighValue ||
        priceValue > statePriceHighValue
      ) {
        setState({ price_low: tag_price_low });

        return;
      }
    }
  };

  const handleHighBlur = () => {
    const printHighData = state.price_high + "";

    if (!rules.test(printHighData)) {
      setState({ price_high: tag_price_high });
    } else {
      const priceValue = Number(printHighData),
        propsPriceLowValue = Number(tag_price_low),
        propsPriceHighValue = Number(tag_price_high),
        statePriceLowValue = Number(state.price_low);
      if (
        priceValue < propsPriceLowValue ||
        priceValue > propsPriceHighValue ||
        priceValue < statePriceLowValue
      ) {
        setState({ price_high: tag_price_high });
      }
    }
  };

  const handlePriceShow = () => {
    setState({ isInputDisabled: !state.isInputDisabled });

    setState({ isPriceShow: !state.isPriceShow });
  };

  const handlePriceLowChange = (e: any) => {
    if (e.target.value === "") {
      return setState({ price_low: tag_price_low });
    } else {
      setState({ price_low: e.target.value });
    }
  };
  const handlePriceHighChange = (e: any) => {
    if (e.target.value === "") {
      return setState({ price_high: tag_price_high });
    } else {
      setState({ price_high: e.target.value });
    }
  };

  const nowDate = new Date(),
    nowYear = nowDate.getFullYear();

  return props.show ? (
    <div
      ref={containerRef}
      onClick={() => {}} // 用于修复 IOS 的addEventListener 不生效问题
      className={classNames(styles[`QrCode`], styles[`QrCode-pc`])}
    >
      <div
        style={{
          background: "#EAEAEA",
        }}
      >
        <div
          ref={qrcodeLabelDOM}
          className={classNames(styles["QrCode-box"], styles["QrCode-box-pc"])}
          style={{
            background: "#fff",
            color: "444444",
            padding: "40px 20px 22px",
          }}
        >
          {host_id && isPrivate ? (
            <></>
          ) : (
            <div
              className={styles["QrCode-Logo"]}
              style={{ width: "200px", height: "30px", marginBottom: "28px" }}
            >
              <img src={LogoImg} height="30" />
            </div>
          )}
          <p
            className={styles["QrCode-title"]}
            style={{
              fontSize: "26px",
              margin: "25px 0px 0px",
              lineHeight: "28px",
            }}
          >
            {props.title || "--"}
          </p>

          <p
            className={styles["QrCode-manufacturer"]}
            style={{
              fontSize: "18px",
              margin: "10px 0px 25px",
            }}
          >
            {f({ id: "ByShopName" })}{" "}
            <span
              style={{
                color: linkColor,
              }}
            >
              {" "}
              {props.manufacturer || "--"}
            </span>
          </p>

          {props.item.has_tag_price_range ? (
            <span style={{ fontSize: "1.3rem", marginBottom: "15px" }}>
              <span
                style={{
                  color: isToBusiness ? bussinessPrimaryColor : primaryColor,
                }}
              >
                {state.isPriceShow ? props.item?.tag_price_value : ""}
              </span>
            </span>
          ) : (
            state.isOriginPriceShow && (
              <div style={{ marginBottom: "15px" }}>
                <span style={{ fontSize: "1.4em" }}>
                  {props.provider?.tag_price_value ||
                    props.item?.tag_price_value}
                </span>
                {`  `}
                {(props.provider
                  ? props.provider.original_price > props.provider.tag_price
                  : props.item.original_price > props.item.tag_price) && (
                  <span
                    style={{
                      color: linkColor,
                      textDecoration: "line-through",
                      marginLeft: "5px",
                    }}
                  >
                    {props.provider?.original_price_value ||
                      props.item?.original_price_value}
                  </span>
                )}
              </div>
            )
          )}

          <Row>
            <Col span={8}>
              {props.img && (
                <img
                  style={{
                    width: "100%",
                    objectFit: "contain",
                    boxSizing: "border-box",
                    border: "1px solid #f2f2f2",
                  }}
                  src={props.img}
                />
              )}
              <div
                className={classNames(
                  styles["QrCode-qr-box"],
                  styles["QrCode-qr-box-pc"],
                )}
                style={{ fontSize: "14px", margin: "10px 0" }}
              >
                <div ref={qrcodeDOM} className={styles[`QrCode-item`]}>
                  {host_id && isPrivate ? (
                    <img src={props.value} alt="" style={{ width: "100%" }} />
                  ) : (
                    <LoadableComponent
                      value={props.value}
                      width={1}
                      height={1}
                    />
                  )}
                </div>
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <span>{props.agentCodeGetData}</span>
                </div>
              </div>
            </Col>
            <Col span={16}>
              <ul
                className={styles["QrCode-options"]}
                style={{
                  margin: 0,
                  fontSize: "16px",
                  marginLeft: "15px",
                }}
              >
                {props.options &&
                  props.options.map((item, index) => (
                    <li key={index} style={{ lineHeight: 1.5 }}>
                      {item.title}: {item.value}
                    </li>
                  ))}
              </ul>
            </Col>
          </Row>

          <div
            className={classNames(styles["QrCode-footer"])}
            style={{
              textAlign: "center",
              color: "#888",
              fontSize: 14,
              margin: 0,
            }}
          >
            <p style={{ margin: 0 }}>
              Copyright ©2018 - {nowYear} MegaHome LLC. All rights reserved.
            </p>
          </div>
        </div>
      </div>
      <div ref={boxRef}>
        {props.item.has_tag_price_range ? (
          <div
            className={classNames(
              styles["QrCode-chprice"],
              styles["QrCode-chprice-pc"],
            )}
            style={{ display: "flex" }}
          >
            <div className={styles["QrCode-chprice-con"]}>
              <Checkbox
                shape="circle"
                color={isToBusiness ? bussinessPrimaryColor : primaryColor}
                checked={state.isPriceShow}
                onChange={handlePriceShow}
              />
              <span>{f({ id: "Price" })}</span>
              <Input
                value={state.price_low}
                placeholder={f({
                  id: "MinPeriod",
                })}
                onChange={handlePriceLowChange}
                onBlur={handleLowBlur}
                disabled={state.isInputDisabled}
              />
              <Input
                value={state.price_high}
                placeholder={f({
                  id: "MaxPeriod",
                })}
                onChange={handlePriceHighChange}
                onBlur={handleHighBlur}
                disabled={state.isInputDisabled}
              />
            </div>
          </div>
        ) : (
          <div
            className={classNames(
              styles["QrCode-chprice"],
              styles["QrCode-chprice-pc"],
            )}
            style={{ display: "flex" }}
          >
            <div>
              <Checkbox
                shape="circle"
                color={isToBusiness ? bussinessPrimaryColor : primaryColor}
                checked={state.isOriginPriceShow}
                onChange={() =>
                  setState({
                    isOriginPriceShow: !state.isOriginPriceShow,
                  })
                }
              ></Checkbox>
              <span className={styles["QrCode-chprice-origin-title"]}>
                {f({ id: "ShowOriginPrice" })}
              </span>
            </div>
          </div>
        )}
        {host_id && isPrivate ? null : (
          <div className={classNames(styles["QrCode-copylink"])}>
            <div style={{ marginRight: 10, flex: 1 }}>{`${props.value}`}</div>
            <PrimaryButton
              onClick={() => {
                copyToClipboard(props.value);
                message.success("复制成功");
              }}
              bgColor={primaryColor}
            >
              {f({ id: "CopyLink" })}
            </PrimaryButton>
          </div>
        )}
        <Row gutter={10} style={{ margin: "0px", padding: "10px  0px" }}>
          <Col span={8}>
            <Print
              trigger={() => {
                return (
                  <PrimaryButton
                    block
                    style={{
                      height: "50px",
                      fontSize: "1em",
                    }}
                    bgColor={
                      isToBusiness ? bussinessPrimaryColor : primaryColor
                    }
                  >
                    {f({ id: "PrintLabel" })}
                  </PrimaryButton>
                );
              }}
              content={() => qrcodeLabelDOM.current}
            />
          </Col>
          <Col span={16}>
            <Print
              trigger={() => (
                <PrimaryButton
                  block
                  style={{
                    height: "50px",
                    fontSize: "1em",
                  }}
                  bgColor={isToBusiness ? bussinessPrimaryColor : primaryColor}
                >
                  {f({ id: "PrintQrCode" })}
                </PrimaryButton>
              )}
              content={() => qrcodeDOM.current}
            />
          </Col>
        </Row>
      </div>
    </div>
  ) : null;
};

export default QrCode;
