import * as React from "react";
import Layout from "../Layout";
import Content from "./Content";
import { RootState } from "../../store/configureStore";
import { useSelector } from "react-redux";

const Recommend: React.FC = () => {
  const { breadcrumbs = [] } = useSelector(
    (state: RootState) => state.RecommendPage,
  );

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Content />
    </Layout>
  );
};

export default Recommend;
