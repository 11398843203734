import * as React from "react";
import Layout from "../Layout";
import Content from "./Content";
import { useMount } from "ahooks";
import { Modal } from "antd";

const DesignIdea: React.FC<{ isManagementEnd: boolean }> = ({
  isManagementEnd,
}) => {
  useMount(() => {
    const userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
    const isFirefox = userAgent.indexOf("Firefox") > -1; //判断是否Firefox浏览器
    const isChrome =
      userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Safari") > -1; //判断Chrome浏览器

    if (!(isChrome || isFirefox)) {
      Modal.warning({
        title: "兼容性提示",
        content:
          "您所使用的浏览器在设计时有些特性不支持，请使用Chrome浏览器打开此页面进行设计！",
      });
    }
  });

  return (
    <Layout isFooterVisible={false} isHeaderVisible={false}>
      <Content isManagementEnd={isManagementEnd} />
    </Layout>
  );
};

DesignIdea.defaultProps = {
  isManagementEnd: false,
};

export default React.memo(DesignIdea);
