import React, { useState, useCallback, useRef } from "react";
import * as Loadable from "react-loadable";
import Print from "react-to-print";
import cx from "classnames";
import Modal from "../../../wrappers/modal";
import { PrimaryButton } from "../../../wrappers/button";
import { Link } from "../../../wrappers/typography";
import Icon from "../../../components/icon";
import { Dispatch, RootState } from "../../../store/configureStore";
import { useSelector, useDispatch } from "react-redux";
import FeatureImg from "./FeatureImg";
import styles from "../index.scss";
import { FormattedMessage } from "react-intl";
import { useTheme } from "styled-components";

// @ts-ignore
const LoadableComponent = Loadable({
  loader: () => import(/** QrCodeComponent */ "@megahome/qrcode/dist/index.js"),
  loading: () => null,
});

interface QrcodeProps {
  option: string;
  ideaName: string;
  shopName: string;
  src: string;
  logo: string;
  tagsList: {
    coordinate_y: string;
    coordinate_x: string;
    product_picture: string;
    height: string;
    width: string;
    url: string;
  }[];

  imgLoad?: () => void;

  isMobile?: boolean;
  attributeData?: any;
}

const QrCode = React.memo((props: QrcodeProps) => {
  const { primaryColor } = useTheme();
  const [isOpen, setOpen] = React.useState(false);
  const NodeBox = useRef<HTMLDivElement>(null);
  const NodeQrcode = useRef<HTMLDivElement>(null);

  const {
    IdeaDetalis: { designIdeaDetails },
  } = useSelector((state: RootState) => state);

  const { theme } = useSelector((state: RootState) => state.URLSearch);
  const {
    imgLoad,
    src,
    logo,
    option = "",
    tagsList,
    ideaName,
    shopName,
    isMobile,
    attributeData,
  } = props;
  const nowDate = new Date(),
    nowYear = nowDate.getFullYear();

  const handleOpen = (e: React.MouseEvent<HTMLOrSVGElement, MouseEvent>) => {
    setOpen(bl => {
      return !bl;
    });
  };

  const dispatch = useDispatch<Dispatch>();

  const [qrCodeValue, setQrCodeValue] = useState("");

  const requestShortUrl = useCallback(e => {
    dispatch.PDPPage.getShortUrlAsync({
      url: location.href,
      callback: res => {
        setQrCodeValue(res.short_url);
        handleOpen(e);
      },
    });
  }, []);

  const options = JSON.parse(option) || {};

  /**
   * print 功能需要下面的行内样式,修改小心修改
   */

  return (
    <>
      <PrimaryButton
        shape="circle"
        icon={<Icon type="icont3_erweima" />}
        onClick={e => {
          e.stopPropagation();
          requestShortUrl(e);
        }}
        bgColor={primaryColor}
      />
      <Modal
        visible={isOpen}
        onCancel={() => setOpen(false)}
        width={800}
        style={{ top: 20 }}
        footer={null}
        bgColor="#8b8b8b"
      >
        <div
          ref={NodeBox}
          style={{
            padding: "30px 30px 10px",
            background: "#fff",
            position: "relative",
            width: "auto",
          }}
          className={styles["ideaDetalis-qrcode-content"]}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <div style={{ width: 100, height: 100 }}>
              {logo && logo !== "" && (
                <img
                  src={logo}
                  alt="logo"
                  height="100px"
                  style={{ marginBottom: 10 }}
                />
              )}
            </div>
            <div style={{ flex: 1, marginLeft: 20 }}>
              <div style={{ fontSize: "1.8em", marginTop: 30 }}>
                {designIdeaDetails?.design_idea_name}
              </div>
              <div style={{ fontSize: "1.2em" }}>
                {designIdeaDetails?.designer_name}
              </div>
            </div>
          </div>

          <div style={{ display: "flex", marginTop: 10 }}>
            <div
              style={{
                width: 800,
                marginRight: 30,
              }}
            >
              <FeatureImg src={src} tagsList={tagsList} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <div
                style={{
                  fontSize: "1.4em",
                }}
              >
                {attributeData?.map(
                  (item: { title: string; value: string }) => {
                    return <div>·{` ${item.title}：${item.value}`}</div>;
                  },
                )}
              </div>
              {!isMobile && (
                <div>
                  {Object.keys(options).map((item, index) => (
                    <p style={{ margin: "8px 0" }} key={index}>
                      {item} :{" "}
                      {options[item].reduce((acc: string, cent: string) => {
                        if (acc === "") {
                          return acc + cent;
                        } else {
                          return acc + ` / ${cent}`;
                        }
                      }, "")}
                    </p>
                  ))}
                </div>
              )}

              <div ref={NodeQrcode}>
                <LoadableComponent
                  value={qrCodeValue}
                  width={160}
                  height={160}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              marginTop: 30,
              color: "#666666",
              fontSize: "1.2em",
            }}
          >
            {/* <p>
              Copyright ©2018 - {nowYear}{" "}
              <Link>{theme ? shopName : "MegaHome LLC"}</Link> . All rights
              reserved.
            </p> */}
            <p>©{nowYear}【数莓派】. All rights reserved.</p>
          </div>
        </div>

        {
          <div className={cx(styles["ideaDetalis-qrcode-print-btn"])}>
            <Print
              trigger={() => {
                return (
                  <PrimaryButton bgColor={primaryColor}>
                    <FormattedMessage id="PrintLabel" />
                  </PrimaryButton>
                );
              }}
              content={() => NodeBox.current}
            />
            <Print
              trigger={() => {
                return (
                  <PrimaryButton bgColor={primaryColor}>
                    <FormattedMessage id="PrintQrCode" />
                  </PrimaryButton>
                );
              }}
              content={() => NodeQrcode.current}
            />
          </div>
        }
      </Modal>
    </>
  );
});

export default QrCode;
