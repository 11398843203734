import { GraphqlField } from "../helpers/decorators";

export default class DeliveryScope {
  @GraphqlField()
  public delivery_type: number; // 1配送区域 2不配送区域
  @GraphqlField()
  public district_adcode: string; // 行政区编码 6位 678990 其中 前两位是省 中间两位是市 后两位是县区
  @GraphqlField()
  public district_level: string;
}
