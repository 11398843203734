import { createModel } from "@rematch/core";
import { GraphqlQuery, GraphqlMutation } from "../../helpers/request.helper";
import { getFirstItem } from "../../helpers/modelHelpers";
import { DesignIdea } from "../../models/DesignIdea";
import { message } from "antd";
export type IdeaDetalis = {
  breadcrumbs: { url: string; title: string }[];
  designIdeaDetails: DesignIdea;
  ideaReviewsData: DesignIdea[];
  ideaLikeList: any;
};

export default createModel({
  state: {} as IdeaDetalis,
  reducers: {
    setIdeaData(state: IdeaDetalis, payload: IdeaDetalis["designIdeaDetails"]) {
      return { ...state, designIdeaDetails: payload };
    },
    setIdeaReviewsData(
      state: IdeaDetalis,
      payload: IdeaDetalis["ideaReviewsData"],
    ) {
      return { ...state, ideaReviewsData: payload };
    },
    changeCollect(state: IdeaDetalis) {
      return {
        ...state,
        designIdeaDetails: {
          ...state.designIdeaDetails,
          is_collect: !state.designIdeaDetails.is_collect,
        },
      };
    },
    setIdeaLikeData(state: IdeaDetalis, ideaLikeList: any) {
      return {
        ...state,
        ideaLikeList: ideaLikeList,
      };
    },
    changeUserLikeIdea(state: IdeaDetalis, id: number, type: boolean) {
      if (type) {
        return {
          ...state,
          ideaLikeList: state.ideaLikeList.filter(m => m !== id),
        };
      } else {
        return {
          ...state,
          ideaLikeList: [...state.ideaLikeList, id],
        };
      }
    },
  },
  effects: {
    async setData(payload) {
      const { ideaId, callback } = payload;
      const data = new DesignIdea({});
      const res = await GraphqlQuery(
        "DesignIdeaDetails",
        data,
        { design_idea_id: ideaId },
        "ideaDetail",
      );
      if (callback) {
        callback(getFirstItem(res));
      }
      this.setIdeaData(getFirstItem(res));
    },
    async getReviewsData(payload) {
      const { ideaId, page = 1 } = payload;
      const res = await GraphqlQuery(
        "DesignIdeaReviews",
        new DesignIdea({}),
        {
          design_idea_id: ideaId,
          page: page,
          pageSize: 20,
        },
        "DesignIdeaReviews",
      );
      this.setIdeaReviewsData(res);
    },
    async setReviewCommentAsync(payload) {
      const { callback, ...arg } = payload;
      const res = await GraphqlMutation("Customer", {
        action: "ReviewDesignIdea",
        ...arg,
      });
      if (callback) {
        callback(res.message);
      }
    },
    async changeUserLike(payload) {
      const { ideaId, isLike } = payload;
      const type = isLike ? "cancel" : "like";
      const res = await GraphqlMutation("Collect", {
        action: type,
        object: "designIdea",
        data_id: ideaId,
      });
      this.changeCollect();
    },
    async setIdeaLike() {
      const res = await GraphqlQuery(
        "Customer",
        new DesignIdea({}),
        { action: "GetLikeIdeas" },
        "UserLike",
      );
      const data = res.map(m => m.design_idea_id);
      this.setIdeaLikeData(data);
    },
    async changeLike({ ideaId, isLike }) {
      const type = isLike ? "CancelLikeDesignIdea" : "DoLikeDesignIdea";
      const res = await GraphqlMutation("Customer", {
        action: type,
        design_idea_id: ideaId,
      });
      message.success(res.message);
      this.changeUserLikeIdea(ideaId, isLike);
    },
  },
});
