import React, { useEffect, useState, useContext, useRef } from "react";
import * as styles from "./index.scss";
import Header from "./component/Header";
import CopyRight from "./component/CopyRight";

const PageNotFound = () => {
  const [time, setTime] = useState(3);
  const intervalRef = useRef();

  useEffect(() => {
    const timer = setInterval(function () {
      setTime(time - 1);
      if (time === 0) {
        handleLink();
        clearInterval(intervalRef.current);
      }
    }, 1000);
    intervalRef.current = timer;
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [time]);

  const handleLink = () => {
    location.href = "/";
  };

  return (
    <div className={styles["Pages"]}>
      <Header />
      <div className={styles["Pages-content"]}>
        <div className={styles["Pages-content-image-notfound"]}></div>
        <div className={styles["Pages-content-text"]}>
          <p className={styles["Pages-content-text-title"]}>
            哦！找不到页面...
          </p>
          <p className={styles["Pages-content-text-back"]}>
            <span className={styles["Pages-content-text-time"]}>{time}</span>{" "}
            秒后返回首页
          </p>
        </div>
        <div className={styles["Pages-content-btn"]} onClick={handleLink}>
          立即前往
        </div>
      </div>
      <CopyRight />
    </div>
  );
};

export default React.memo(PageNotFound);
